import GridContainer from "components/Grid/GridContainer";
import TextWithLabel from "components/Text/TextWithLabel";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { Fragment } from "react";
import { orders } from "translations";

const OrderInfo = (props) => {
    const { orderData } = props;
    if (!orderData) return <Fragment />;
    const data = {
        ...orderData,
        delivery: {
            startsAt: orderData.deliveryStartsAt,
            endsAt: orderData.deliveryEndsAt,
        },
    };
    // prettier-ignore
    const fields = [
        { label: `orders:${orders.fullName}`,                    value: data.fullName,                    format: formatTypes.fullName                                                                },
        { label: `orders:${orders.order}`,                       value: data.orderId,                     format: formatTypes.text                                                                    },
        { label: `orders:${orders.documentId}`,                  value: data.documentId,                  format: formatTypes.text                                                                    },
        { label: `orders:${orders.placedAt}`,                    value: data.placedAt,                    format: formatTypes.time,           options: { timeFormat: timePrecisonFormats.seconds    } },
        { label: `orders:${orders.value}`,                       value: data.price,                       format: formatTypes.currency                                                                },
        { label: `orders:${orders.deliveryAt}`,                  value: data.delivery,                    format: formatTypes.deliveryWindow                                                          },
        { label: `orders:${orders.delivery}`,                    value: data.shippingAddress,             format: formatTypes.shippingAddress                                                         },
        { label: `orders:${orders.isEarlyEntranceAllowedShort}`, value: data.isEarlyEntranceAllowed,      format: formatTypes.boolean                                                                 },
    ];

    return (
        <GridContainer
            spacing={4}
            iconName="receipt"
            title={`orders:${orders.orderData}`}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >
            {fields.map((item) => (
                <TextWithLabel
                    key={item.label}
                    label={item.label}
                    format={item.format}
                    options={item.options}
                    value={item.value}
                />
            ))}
        </GridContainer>
    );
};

export default OrderInfo;
