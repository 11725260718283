import defaultListState from "store/defaultListState";
import { types as rootTypes } from "../action";
import { types } from "./action";

const initialState = defaultListState;

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case rootTypes.CUSTOMER_CHANGED:
            return initialState;
        case types.GET_CUSTOMER_BALANCE_CORRECTIONS_LIST:
            return { ...state, isLoading: true };
        case types.GET_CUSTOMER_BALANCE_CORRECTIONS_LIST_SUCCESS:
            return { ...state, ...payload, isLoading: false };
        case types.GET_CUSTOMER_BALANCE_CORRECTIONS_LIST_FAILURE:
            return { ...state, isLoading: false };
        default:
            return state;
    }
};
