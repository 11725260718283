import { actions as cachedActions } from "store/cache/actions";
import { cacheNames } from "utils/cache/cacheProvider";

const getCommerceUsers = (userIds) => async (dispatch) => {
    let groupedUserIds = [],
        groupMaxSize = 250;

    for (let i = 0; i < userIds.length; i += groupMaxSize) {
        groupedUserIds.push(userIds.slice(i, i + groupMaxSize));
    }

    // eslint-disable-next-line array-callback-return
    groupedUserIds.map((ids) => {
        dispatch(
            cachedActions.getCached({
                ids: ids,
                params: { pageSize: 250 },
                cacheName: cacheNames.commerceUserStore,
                endpoint: `api/v1/users/query`,
                idPropertyName: "userId",
                queryPropetyName: "userIds",
                itemsPropertyName: "items",
            })
        );
    });
};

export const actions = {
    getCommerceUsers,
};
