import defaultListState from "store/defaultListState";
import { combineReducers } from "redux";
import types from "./types";

const formInitialState = {
    isLoading: false,
};

const listInitialState = defaultListState;

const form = (state = formInitialState, { type, payload }) => {
    switch (type) {
        case types.formInit:
            return { ...formInitialState };
        case types.getDeferredPayment.request:
        case types.createDeferredPayment.request:
        case types.submitDeferredPayment.request:
        case types.editDeferredPayment.request:
        case types.makeDecision.request:
            return {
                ...listInitialState,
                isLoading: true,
            };
        case types.getDeferredPayment.success:
            return {
                ...payload.items[0],
                isLoading: false,
            };
        case types.makeDecision.success:
        case types.getDeferredPayment.failure:
        case types.makeDecision.failure:
            return {
                isLoading: false,
            };
        default:
            return { ...state };
    }
};

const list = (state = listInitialState, { type, payload }) => {
    switch (type) {
        case types.getDeferredPayments.request:
            return {
                ...listInitialState,
                isLoading: true,
            };
        case types.getDeferredPayments.success:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case types.getDeferredPayments.failure:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return { ...state };
    }
};

export const deferredPayments = combineReducers({ form, list });
