import defaultListState from "store/defaultListState";
import types from "./types";

const initialState = {
    filters: {},
    ...defaultListState,
    isReportDownloading: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case types.setFilters:
            return { ...state, filters: payload };
        case types.findArchivedDocuments.request:
            return { ...state, isLoading: true };
        case types.findArchivedDocuments.success:
            return { ...state, ...payload, isLoading: false };
        case types.findArchivedDocuments.failure:
            return { ...state, isLoading: true };
        case types.downloadReport.request:
            return { ...state, isReportDownloading: true };
        case types.downloadReport.success:
        case types.downloadReport.failure:
            return { ...state, isReportDownloading: false };
        default:
            return state;
    }
};
