import { types } from "store/customers/customer/action";
import defaultListState from "store/defaultListState";
import * as orders from "./action";

const initialState = defaultListState;

export const orderList = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case types.CUSTOMER_CHANGED:
            return initialState;
        case orders.ORDERS_GET_LIST:
            return { ...initialState, isLoading: true };
        case orders.ORDERS_GET_LIST_SUCCESS:
            return { ...state, ...payload, isLoading: false };
        case orders.ORDERS_GET_LIST_FAILURE:
            return { ...state, isLoading: false };
        default:
            return { ...state };
    }
};
