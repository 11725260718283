import defaultListState from "store/defaultListState";
import { types } from "./types";

const initialState = defaultListState;

export const alerts = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.getAlerts.request:
            return { ...state, isLoading: true };
        case types.getAlerts.success:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case types.getAlerts.failure:
            return { ...state, isLoading: false };
        default:
            return state;
    }
};
