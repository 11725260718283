import defaultListState from "store/defaultListState";
import { combineReducers } from "redux";
import types from "./types";

const initialState = {
    contracts: defaultListState,
    subsidiaryGains: defaultListState,
    refundations: defaultListState,
};

const contracts = (state = initialState.contracts, { type, payload }) => {
    switch (type) {
        case types.findContractsForApproval.request:
            return { ...state, isLoading: true };
        case types.findContractsForApproval.success:
            return { ...state, isLoading: false, ...payload };
        case types.findContractsForApproval.failure:
            return { ...state, isLoading: true };
        case types.changeContractsPagination:
            return { ...state, ...payload };
        default:
            return state;
    }
};

const subsidiaryGains = (state = initialState.subsidiaryGains, { type, payload }) => {
    switch (type) {
        case types.findSubsidiaryGainForApproval.request:
            return { ...state, isLoading: true };
        case types.findSubsidiaryGainForApproval.success:
            return { ...state, isLoading: false, ...payload };
        case types.findSubsidiaryGainForApproval.failure:
            return { ...state, isLoading: true };
        case types.changeSubsidiaryGainPagination:
            return { ...state, ...payload };
        default:
            return state;
    }
};

const refundations = (state = initialState.refundations, { type, payload }) => {
    switch (type) {
        case types.findRefundationsForApproval.request:
            return { ...state, isLoading: true };
        case types.findRefundationsForApproval.success:
            return { ...state, isLoading: false, ...payload };
        case types.findRefundationsForApproval.failure:
            return { ...state, isLoading: true };
        case types.changeRefundationsPagination:
            return { ...state, ...payload };
        default:
            return state;
    }
};
export const decisionList = combineReducers({
    contracts,
    subsidiaryGains,
    refundations,
});
