import { DeleteRequest, GetRequest, PostRequest, PutRequest, get, post, put, remove } from "utils/extensions";
import types from "./types";

const endpoint = (vendorId, creditLimitId) => `api/vendors/${vendorId}/credit-limits${creditLimitId ? `/${creditLimitId}` : ""}`;

export const initForm = (vendorId, creditLimitId) => async (dispatch) => {
    await dispatch({ type: types.formInit });

    if (creditLimitId) {
        return dispatch(get(new GetRequest(endpoint(vendorId, creditLimitId), types.getCreditLimit.all)));
    }
};

export const createOrUpdate = (values, vendorId, creditLimitId) => async (dispatch) => {
    if (!creditLimitId) {
        return dispatch(
            post(
                new PostRequest(endpoint(vendorId), types.createOrUpdate.all, {
                    body: values,
                })
            )
        );
    } else {
        return dispatch(
            put(
                new PutRequest(endpoint(vendorId, creditLimitId), types.createOrUpdate.all, {
                    body: values,
                })
            )
        );
    }
};

export const removeCreditLimit = (vendorId, creditLimitId) => async (dispatch) => {
    return dispatch(remove(new DeleteRequest(endpoint(vendorId, creditLimitId), types.deleteCreditLimit.all)));
};

export const retryCreditLimitOperation = (vendorId, creditLimitId) => async (dispatch) => {
    return dispatch(put(new PutRequest(endpoint(vendorId, creditLimitId) + "/retry", types.deleteCreditLimit.all, {})));
};
