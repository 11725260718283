import { Button } from "components/Button";
import { initForm, requestVendorDecision } from "store/vendors/subsidiaryGains";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";

const RequestVendorDecision = ({ canRequestVendorDecision, status }) => {
    const [isRequesting, setIsRequesting] = useState(false);
    const { vendorId, subsidiaryGainId } = useParams();
    const { t } = useTranslation("vendor");
    const dispatch = useDispatch();

    return canRequestVendorDecision ? (
        <Button
            actionType="primary"
            type="button"
            fullWidth
            loading={isRequesting}
            onClick={async () => {
                setIsRequesting(true);
                await dispatch(requestVendorDecision(vendorId, subsidiaryGainId));

                dispatch(initForm(vendorId, subsidiaryGainId));
            }}
        >
            {status === "Completed" ? t(vendor.requestVendorConfirmation) : t(vendor.requestVendorDecision)}
        </Button>
    ) : (
        <></>
    );
};

export default RequestVendorDecision;
