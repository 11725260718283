import Layout from "components/Grid/Layout";
import Actions, { createAction } from "components/Tabs/Actions";
import { actions as orderActions } from "store/order/order/action";
import { actions as orderAlertsActions } from "store/orderAlerts/action";
import { splitOrderId } from "utils/splitIds";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { orders } from "translations";
import { useTranslation } from "utils-ts/hooks";
import OrderContainer from "../../order/order/OrderContainer";
import SuspiciousOrders from "./SuspiciousOrders";
import SuspiciousProducts from "./SuspiciousProducts";

const OrderAlert = ({ orderAlert, getCompleteOrder, order, dismissAlert }) => {
    const { t } = useTranslation("orders");
    const { id, suspiciousProducts, suspiciousOrders } = orderAlert;
    const { customerId, orderNumber } = splitOrderId(id);
    React.useEffect(() => {
        getCompleteOrder(customerId, orderNumber);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getCompleteOrder]);
    return (
        <Layout
            main
            headerText={t(orders.orderAlert, { orderId: order.orderId })}
        >
            <Actions actions={[createAction(t(orders.dismissAlert), () => dismissAlert(id), "primary")]} />
            <OrderContainer order={order} />
            {suspiciousProducts && <SuspiciousProducts suspiciousProducts={suspiciousProducts} />}
            {suspiciousOrders && (
                <SuspiciousOrders
                    customerId={customerId}
                    suspiciousOrders={suspiciousOrders}
                />
            )}
        </Layout>
    );
};

const mapStateToProps = (state) => {
    const {
        orderAlerts: { orderAlert },
        order,
    } = state;
    return { orderAlert, order };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ ...orderActions, ...orderAlertsActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OrderAlert);
