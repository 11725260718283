import { useFormContext } from "react-hook-form";
import { api } from "api";
import { Role } from "routing-ts/roles";
import { usePrefixContext } from "components-ts/forms/contexts";
import FormAutocomplete from "../FormAutocomplete";

type FormUserAutocompleteProps = {
    label: string;
    name: string;
    roles: Array<Role>;
    readOnly?: boolean;
    multiple?: true;
    disableEmpty?: boolean;
    showDisabled?: false;
};

const FormUserAutocomplete: React.FC<FormUserAutocompleteProps> = ({
    name,
    label,
    readOnly,
    multiple,
    disableEmpty,
    roles,
    showDisabled = false,
}) => {
    const form = useFormContext();
    const prefix = usePrefixContext();
    const value = form.getValues(prefix !== undefined ? `${prefix}.${name}` : name);
    const { roleUsers, isLoading } = api.identity.roles.useGetUsersWithRoleForSelect(roles, showDisabled || readOnly === true, value);

    return (
        <FormAutocomplete
            name={name}
            label={label}
            readOnly={readOnly}
            multiple={multiple}
            disableEmpty={disableEmpty}
            items={roleUsers}
            isLoadingItems={isLoading}
        />
    );
};

export default FormUserAutocomplete;
