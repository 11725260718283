import { Layout } from "components/Grid";
import { Preloader } from "components/Preloader";
import { dynamicSegmentFormSelector, getDynamicSegment } from "store/dynamicSegments";
import { useCommonTranslation, useParams } from "utils/hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paths } from "routing-ts/ManagerPaths";
import DynamicSegmentForm from "./DynamicSegmentForm";

const DynamicSegment = () => {
    const { dynamicSegmentId } = useParams();
    const { t, common } = useCommonTranslation();
    const { isLoading, name } = useSelector(dynamicSegmentFormSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getDynamicSegment(dynamicSegmentId));
    }, [dispatch, dynamicSegmentId]);

    return (
        <Layout
            main
            headerText={t(common.dynamicSegment, { id: dynamicSegmentId })}
            navigationProps={{
                backProps: {
                    path: Paths.DynamicSegments,
                },
            }}
            customTitle={name && `${t(common.dynamicSegment)} - ${name}`}
        >
            {isLoading ? <Preloader /> : <DynamicSegmentForm />}
        </Layout>
    );
};

export default DynamicSegment;
