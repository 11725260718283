import defaultListState from "store/defaultListState";
import types from "./types";

const initialState = defaultListState;
const { list } = types;

export const deliveryComplaints = (state = initialState, { type, payload }) => {
    switch (type) {
        case list.request:
            return { ...state, isLoading: true };
        case list.success:
            return { ...state, isLoading: false, ...payload };
        case list.failure:
            return { ...state, isLoading: false };
        default:
            return state;
    }
};
