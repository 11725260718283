import { default as deliveryComplaintTypes } from "store/deliveryComplaints/types";
import { types } from "./action";

const initialState = {
    orderIdentity: "",
    orderId: "",
    billingAddress: {},
    shippingAddress: {},
    warehouse: "",
    invoice: {},
    isLoading: true,
};

const onGetOrder = (state) => ({ ...state, isLoading: true });

const onGetOrderSuccess = (state, payload) => {
    const { invoice, ...rest } = payload;
    const { lines, ...invoiceRest } = invoice;
    const mappedlines = lines.map((line) => ({
        ...line,
        correctedItems: (line.correctedItems || []).map((item) => ({
            ...item,
            correctRealItems: line.packSize > 1 ? Math.round(item.correctRealQuantity * line.packSize) || "" : "",
            type: item.reasonCode.includes("Q_") ? "Quantity" : item.reasonCode.includes("S_") ? "Value" : "missingProducts",
        })),
    }));
    const result = {
        ...rest,
        invoice: {
            ...invoiceRest,
            lines: mappedlines,
        },
    };

    return {
        ...state,
        ...result,
        isLoading: false,
    };
};

const onGetOrderFailure = (state) => ({ ...state, isLoading: false });

export const orderComplaint = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case types.GET_ORDER_FOR_COMPLAINT:
            return onGetOrder(state);
        case types.GET_ORDER_FOR_COMPLAINT_SUCCESS:
            return onGetOrderSuccess(state, payload);
        case types.GET_ORDER_FOR_COMPLAINT_FAILURE:
            return onGetOrderFailure(state);
        case deliveryComplaintTypes.select:
            return {
                ...state,
                deliveryComplaint: action.payload,
            };
        case types.CLEAN_UP:
            return { ...initialState };
        default:
            return state;
    }
};
