import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import { formatTypes } from "utils/formating";
import { useAppLocation, useCommonTranslation } from "utils/hooks";
import { common } from "translations";

const ProductRations = ({ rations = [] }) => {
    const { t } = useCommonTranslation();
    const appLocation = useAppLocation();

    const columns = [
        {
            key: "rationId",
            value: common.id,
            type: formatTypes.link,
            options: {
                target: "_blank",
                url: (id) => `${appLocation}/pricing/product-rations/form/${id}`,
            },
        },
        {
            key: "limit",
            value: common.limit,
            type: formatTypes.text,
        },
    ];

    return (
        <SimpleTable
            stripped
            hover
            columns={columns.map((c) => ({ ...c, value: t(c.value) }))}
            data={rations}
        />
    );
};

export default ProductRations;
