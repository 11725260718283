import { Namespace } from "translations/Translation";
//due to using in ExtendedValidator (outside of React component) we must use i18n.js instead of import from "i18next"
import i18next from "../../i18n";

export const tValidation = (resource: string, params: {} = {}, namespace: Namespace = "validation"): string => {
    if (!i18next.isInitialized) {
        console.error("i18n not initialized, so tValidation will return undefined instead of translation");
    }

    return i18next.t(resource, { ns: namespace, ...params });
};

export { default as validation } from "translations/keys/validation";
