import { AccountingPeriodAlert } from "components/Alerts";
import { DivisionSelect } from "components/Controls";
import CustomerListInput from "components/Controls/CustomerListInput";
import { FileArray } from "components/Files";
import { Form, FormField, FormFieldArray } from "components/Form";
import roles from "consts/roles";
import { reduxForm, stopAsyncValidation } from "redux-form";
import divisions from "resource/divisions.json";
import { findAllAccountingPeriods } from "store/accountingPeriods";
import { getAnalyticGroups, getProductsBrands, useAnalyticGroupsAndProductsBrandsEffect } from "store/autocomplete";
import { activePromotionPeriodsSelector } from "store/autocomplete";
import { actions } from "store/cache/products/actions";
import { createOrUpdate, getRefundationFile, refundationFormSelector } from "store/vendors/refundations";
import { useFormValueSelector, useUserAccountingPeriods } from "utils/hooks";
import { isAccountingPeriodOpen, shouldDisableDate } from "utils/utilsFunctions";
import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import moment from "moment";
import { Layout } from "components";
import { useUser } from "context";
import _ from "lodash";
import { common, vendor } from "translations";
import { usePush, useTranslation } from "utils-ts/hooks";
import { IsVendorReadOnly } from "views/vendors/IsVendorReadOnly";
import { Paths } from "routing-ts/ManagerPaths";
import { validators } from "../validators";
import ChangeReason from "./ChangeReason";
import ImportRefundation from "./ImportRefundation";
import RefundationDecision from "./RefundationDecision";
import RefundationPromotionRestriction from "./RefundationPromotionRestriction";
import RefundationSettlements from "./RefundationSettlements";
import RefundationStatus from "./RefundationStatus";
import RefundationValue from "./RefundationValue";

const formName = "refundation-form";

const settlementBases = [
    { value: "SaleValue", name: "Ilość sprzedaży" },
    { value: "PurchaseValue", name: "Ilość zakupu" },
];

const RefundationForm = ({
    id,
    changeStatus,
    allProducts,
    changeReason,
    form,
    handleSubmit,
    handleDecision,
    handleSave,
    pristine,
    vendorId,
    change,
}) => {
    const { isInRoleOrAdmin, profile } = useUser();
    const { replace } = usePush();
    const { t } = useTranslation(["vendor", "common"]);
    const allPromotionPeriods = useSelector(activePromotionPeriodsSelector);

    const { dateFrom, dateTo, description, settlementBase, divisions, values, files, settlementFile } = useSelector(refundationFormSelector);

    const dispatch = useDispatch();
    const currentValues = useFormValueSelector(
        form,
        [
            "isDate",
            "dateFrom",
            "dateTo",
            "description",
            "settlementBase",
            "divisions",
            "values",
            "files",
            "settlementFile",
            "isRestrictedToPromotion",
            "promotionId",
            "isRestrictedToClients",
            "clientsIds",
        ],
        {}
    );
    const { accountingPeriods } = useUserAccountingPeriods(vendorId);
    const {
        isDate,
        dateTo: changedDateTo,
        settlementFile: changedSettlementFile,
        isRestrictedToPromotion,
        promotionId,
        isRestrictedToClients,
        clientsIds,
    } = currentValues;
    const isDecisionRequired = id && changeStatus && changeStatus === "RequiresDecision";
    const readOnly = isDecisionRequired || IsVendorReadOnly() || (id && !isAccountingPeriodOpen(accountingPeriods, dateFrom));
    const isAfterDateTo = moment().isAfter(moment(changedDateTo));
    const isAuditor = isInRoleOrAdmin(roles.Auditor);
    const promotionPeriods = allPromotionPeriods.filter(
        (p) => isDecisionRequired || (!shouldDisableDate(accountingPeriods, p.dateFrom) && !shouldDisableDate(accountingPeriods, p.dateTo))
    );
    const currentPromotionPeriod =
        allPromotionPeriods.find((p) => moment(p.dateFrom).isSameOrBefore(moment(), "days") && moment().isSameOrBefore(moment(p.dateTo), "days")) ||
        promotionPeriods.slice(-1)[0];

    useEffect(() => {
        dispatch(getProductsBrands());
        dispatch(getAnalyticGroups());
        dispatch(findAllAccountingPeriods());
    }, [dispatch]);

    useEffect(() => {
        dispatch(actions.getProducts(allProducts));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, !_.isEmpty(allProducts)]);

    const handleDownload = (file) => {
        dispatch(getRefundationFile(vendorId, id, file));
    };

    useAnalyticGroupsAndProductsBrandsEffect({ disable: IsVendorReadOnly() });

    const changeIsDate = (_, newValue) => {
        if (newValue === true) {
            change("isDate", true);
            change("promotionPeriodId", "");
            change("dateFrom", dateFrom);
            change("dateTo", dateTo);
        } else {
            const ppId = promotionPeriods.find(
                (pp) => moment(dateFrom).isSameOrAfter(moment(pp.dateFrom), "days") && moment(dateFrom).isSameOrBefore(moment(pp.dateTo), "days")
            )?.id;
            changePromotionPeriod(ppId || currentPromotionPeriod?.id);
        }
    };

    const changePromotionPeriod = (value) => {
        const selectedPromotionPeriod = allPromotionPeriods.find((p) => p.id == value);
        change("promotionPeriodId", selectedPromotionPeriod?.id);
        change("dateFrom", selectedPromotionPeriod?.dateFrom);
        change("dateTo", selectedPromotionPeriod?.dateTo);
    };

    const inputFromPeriod = (promotionPeriod) => {
        if (!promotionPeriod) {
            return {
                name: "",
                value: "",
                onChange: changePromotionPeriod,
            };
        }
        return {
            name: `${promotionPeriod?.name} (${moment(promotionPeriod?.dateFrom).format("YYYY-MM-DD")}/${moment(promotionPeriod?.dateTo).format(
                "yyyy-MM-DD"
            )})`,
            value: promotionPeriod?.id,
            onChange: changePromotionPeriod,
        };
    };

    return (
        <>
            <AccountingPeriodAlert
                accountingPeriods={accountingPeriods}
                date={dateTo}
            />
            <ImportRefundation readOnly={readOnly} />
            <RefundationStatus />
            <Form
                preSubmit={() => {
                    dispatch(stopAsyncValidation(formName, { clear: true }));
                }}
                onSubmit={handleSubmit(async (formValues) => {
                    let { values, isRestrictedToPromotion, promotionId, isRestrictedToClients, clientsIds, isDate, ...rest } = formValues;
                    if (Array.isArray(values) && values.length > 0) {
                        values = values.map((v) => {
                            return {
                                ...v,
                                quantity:
                                    v.quantity && v.quantity.length > 0
                                        ? Number.parseFloat(v.quantity)
                                        : Number.isFinite(v.quantity)
                                        ? v.quantity
                                        : undefined,
                            };
                        });
                    }

                    const { payload } = await dispatch(
                        createOrUpdate(
                            vendorId,
                            id,
                            {
                                values,
                                isRestrictedToPromotion,
                                promotionId: isRestrictedToPromotion ? promotionId : undefined,
                                isRestrictedToClients,
                                clientsIds: isRestrictedToClients ? clientsIds : undefined,
                                isDate: isDate ?? false,
                                ...rest,
                            },
                            {
                                userId: profile.sub,
                                email: profile.email,
                            }
                        )
                    );

                    if (!id) {
                        replace(Paths.Vendor.RefundationForm, {
                            vendorId,
                            refundationId: payload.id,
                        });
                    } else if (handleSave) {
                        await handleSave();
                    }
                })}
                pristine={pristine}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <FormField
                        name="isDate"
                        label={t(`common:${common.isDate}`)}
                        type="boolean"
                        onChange={changeIsDate}
                        useDiff={isDecisionRequired}
                        readOnly={readOnly}
                    />
                    {!!isDate === false || (isDecisionRequired && (!isDate.current || !isDate.proposal)) ? (
                        <FormField
                            name="promotionPeriodId"
                            label={t(`common:${common.promotionPeriod}`)}
                            type="select"
                            items={(readOnly ? allPromotionPeriods : promotionPeriods).map((p) => {
                                return inputFromPeriod(p);
                            })}
                            readOnly={readOnly}
                            hideDefaultItem={true}
                            onValueChange={changePromotionPeriod}
                            useDiff={isDecisionRequired}
                        />
                    ) : (
                        (isDate === true || (isDecisionRequired && (!!isDate.current || !!isDate.proposal))) && (
                            <>
                                <FormField
                                    useDiff={isDecisionRequired}
                                    name={"dateFrom"}
                                    label={t(vendor.dateFrom)}
                                    type="date"
                                    validate={validators.validateFrom}
                                    readOnly={readOnly}
                                    shouldDisableDate={(date) => shouldDisableDate(accountingPeriods, date)}
                                />
                                <FormField
                                    useDiff={isDecisionRequired}
                                    name={"dateTo"}
                                    label={t(vendor.dateTo)}
                                    type="date"
                                    validate={validators.validateTo}
                                    readOnly={readOnly}
                                    shouldDisableDate={(date) => shouldDisableDate(accountingPeriods, date)}
                                />
                            </>
                        )
                    )}
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <DivisionSelect
                        useDiff={isDecisionRequired}
                        readOnly={readOnly}
                        validate={validators.arrayRequired}
                        selectedDivisions={divisions}
                    />
                    <FormField
                        useDiff={isDecisionRequired}
                        name="settlementBase"
                        label={t(vendor.settlementBase)}
                        type="select"
                        readOnly={readOnly}
                        items={settlementBases}
                        validate={validators.required}
                    />

                    {id && (
                        <FormField
                            name={"refundationNumber"}
                            label={t(vendor.refundationNumber)}
                            type={"text"}
                            readOnly={true}
                        />
                    )}
                </Grid>

                <RefundationPromotionRestriction
                    isRestrictedToPromotion={isRestrictedToPromotion}
                    promotionId={promotionId}
                    readOnly={readOnly}
                    useDiff={isDecisionRequired}
                    isAfterDateTo={isAfterDateTo}
                />

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <FormField
                        name="isRestrictedToClients"
                        label={t(vendor.isRestrictedToClients)}
                        type="boolean"
                        useDiff={isDecisionRequired}
                        readOnly={readOnly}
                    />

                    {isRestrictedToClients && (
                        <CustomerListInput
                            name="clientsIds"
                            users={clientsIds}
                            useDiff={isDecisionRequired}
                            readOnly={readOnly}
                            validate={isAfterDateTo ? validators.arrayRequired : undefined}
                        />
                    )}
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <FormField
                        useDiff={isDecisionRequired}
                        name={"description"}
                        label={t(vendor.description)}
                        type={"text"}
                        multiline
                        style={{ width: "100%" }}
                        readOnly={readOnly}
                    />
                </Grid>

                <Layout headerText={t(vendor.refundationValues)}>
                    <FormFieldArray
                        name="values"
                        component={RefundationValue}
                        readOnly={readOnly}
                        useDiff={isDecisionRequired}
                        validate={readOnly ? undefined : validators.arrayRequired}
                        isQuantityLimit={isRestrictedToPromotion || isRestrictedToClients}
                    />
                </Layout>

                <Layout headerText={t(vendor.confirmationFiles)}>
                    <FormFieldArray
                        accept="application/pdf"
                        name="files"
                        component={FileArray}
                        useDiff={isDecisionRequired}
                        readOnly={readOnly}
                        handleDownload={handleDownload}
                        validate={readOnly ? undefined : validators.arrayRequired}
                        canRemoveFromBlob={!isDecisionRequired && (files || []).filter((o) => o.isNew == false).length >= 2}
                    />
                </Layout>

                <RefundationSettlements
                    changedDateTo={changedDateTo}
                    isDecisionRequired={isDecisionRequired}
                    changedSettlementFile={changedSettlementFile}
                    dispatch={dispatch}
                    change={change}
                    isAuditor={isAuditor}
                    vendorId={vendorId}
                    id={id}
                />

                <ChangeReason
                    changeReason={changeReason}
                    isDecisionRequired={isDecisionRequired}
                    pristine={pristine}
                    initialValues={{
                        dateFrom,
                        dateTo,
                        description,
                        settlementBase,
                        divisions,
                        values,
                        files,
                        settlementFile,
                    }}
                    currentValues={currentValues}
                />
            </Form>
            <RefundationDecision
                isDecisionRequired={isDecisionRequired}
                handleDecision={handleDecision}
            />
        </>
    );
};

const stateToProps = (state, props) => {
    const form = refundationFormSelector(state);
    const { isImporting } = form;

    return {
        changeStatus: form.changeStatus,
        allProducts: form.allProducts,
        id: form.id,
        isImporting,
        changeReason: form?.changeReason?.proposal,
        initialValues: {
            ...form,
            vendorId: props.vendorId,
            divisions: form.id ? form.divisions : divisions.filter((o) => !o.disabled).map((d) => d.value),
        },
    };
};

export default connect(stateToProps)(
    reduxForm({
        form: formName,
        enableReinitialize: true,
        asyncValidate: validators.validateRefundationAsync,
        shouldAsyncValidate: (props) => {
            if (props.syncValidationPasses) {
                if (props.trigger === "submit") {
                    return true;
                }
            }

            return false;
        },
    })(RefundationForm)
);
