import types from "./types";

const initialState = {
    isSearchLoading: false,
    isAccepting: false,
    isDownloading: false,
    items: [],
};

export const correctionRequestReport = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.getCorrectionRequestReport.request:
            return { ...state, items: [], isSearchLoading: true };
        case types.getCorrectionRequestReport.success:
            return { ...state, items: payload, isSearchLoading: false };
        case types.getCorrectionRequestReport.failure:
            return { ...state, items: payload, isSearchLoading: false };

        case types.getCorrectionRequestReportFile.request:
            return { ...state, isDownloading: true };
        case types.getCorrectionRequestReportFile.success:
            return { ...state, isDownloading: false };
        case types.getCorrectionRequestReportFile.failure:
            return { ...state, isDownloading: false };

        case types.acceptCorrectionRequests.request:
            return { ...state, isAccepting: true };
        case types.acceptCorrectionRequests.success:
            return { ...state, isAccepting: false };
        case types.acceptCorrectionRequests.failure:
            return { ...state, isAccepting: false };

        case types.changeSettlementType:
            return { ...state, ...payload };

        default:
            return state;
    }
};
