import { Button, IconButton } from "components/Button";
import { ArrayValidationError } from "components/Controls";
import { FormField } from "components/Form";
import { decimalNormalize, integerNormalize } from "components/FormHelpers/ControlFormaters";
import { renderFieldOrText } from "components/FormHelpers/Controls";
import Layout from "components/Grid/Layout";
import { FormSection, change } from "redux-form";
import { actions } from "store/cache/products/actions";
import { cachedProductsSelector } from "store/offers/selectors";
import { makeStyles } from "tss-react/mui";
import { firstOrEmpty, toSelectList } from "utils/extensions/arrayExtensions";
import { useCommonTranslation } from "utils/hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { validators } from "../../validators";

const useStyles = makeStyles()((theme) => ({
    product: {
        width: 350,
        padding: theme.spacing(0.5),
    },
    input: {
        width: 200,
        padding: theme.spacing(0.5),
    },
    checkbox: {
        width: 100,
        padding: theme.spacing(0.5),
    },
}));

const Products = ({ fields, products, meta }) => {
    const { t, common } = useCommonTranslation();
    const cachedProducts = useSelector(cachedProductsSelector);
    const dispatch = useDispatch();
    const { classes } = useStyles();

    useEffect(() => {
        if (products && products.length > 0) {
            dispatch(actions.getProducts(products.map((p) => p.productId).filter(Boolean)));
        }
    }, [dispatch, products]);

    return (
        <Layout
            isEnlarged
            headerText={t(common.products)}
        >
            <ArrayValidationError {...meta} />
            <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
                direction="column"
            >
                {fields.map((field, index) => {
                    const { productId, type } = products[index] || {};
                    const { product = {} } = firstOrEmpty(cachedProducts, (x) => x.productId === productId);
                    const { brand = "", name = "", imageUrl } = product;
                    const item = {
                        imageUrl,
                        text: ` (${productId}) ${brand} - ${name}`,
                    };
                    return (
                        <FormSection
                            key={index}
                            name={field}
                        >
                            <Grid
                                item
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="flex-start"
                            >
                                <FormField
                                    className={classes.product}
                                    component={renderFieldOrText}
                                    name={"productId"}
                                    label={t(common.productId)}
                                    type={"textWithImage"}
                                    item={item}
                                    onAdd={(value) => dispatch(actions.getProducts([value]))}
                                    validate={validators.productId}
                                />
                                <FormField
                                    className={classes.input}
                                    component={renderFieldOrText}
                                    name={"type"}
                                    label={t(common.limit)}
                                    type={"select"}
                                    items={toSelectList(["discountPercent", "discountValue", "newPrice"], t)}
                                    onChange={(_, type) => {
                                        dispatch(change("discount-form", `${field}.modifier`, { [type]: 0 }));
                                    }}
                                    validate={validators.modifierType}
                                />
                                <FormSection name="modifier">
                                    <FormField
                                        className={classes.input}
                                        hidden={type !== "discountPercent"}
                                        name={"discountPercent"}
                                        component={renderFieldOrText}
                                        type={"text"}
                                        label={t(common.discountPercent)}
                                        format={integerNormalize}
                                        validate={validators.discountPercent}
                                    />

                                    <FormField
                                        className={classes.input}
                                        hidden={type !== "discountValue"}
                                        name={"discountValue"}
                                        component={renderFieldOrText}
                                        type={"text"}
                                        label={t(common.discountValue)}
                                        format={decimalNormalize}
                                        validate={validators.discountValue}
                                    />

                                    <FormField
                                        className={classes.input}
                                        hidden={type !== "newPrice"}
                                        name={"newPrice"}
                                        component={renderFieldOrText}
                                        type={"text"}
                                        label={t(common.newPrice)}
                                        format={decimalNormalize}
                                        validate={validators.newPrice}
                                    />
                                </FormSection>
                                <FormField
                                    className={classes.checkbox}
                                    name={"isProductLimitsDisabled"}
                                    component={renderFieldOrText}
                                    type={"boolean"}
                                    label={t(common.isProductLimitsDisabled)}
                                />
                                <FormField
                                    className={classes.checkbox}
                                    name={"isWaterLimitsDisabled"}
                                    component={renderFieldOrText}
                                    type={"boolean"}
                                    label={t(common.isWaterLimitsDisabled)}
                                />

                                <IconButton
                                    icon="delete"
                                    onClick={() => fields.remove(index)}
                                />
                            </Grid>
                        </FormSection>
                    );
                })}
                <Grid
                    item
                    style={{ marginLeft: "auto" }}
                >
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => fields.push({ type: "discountPercent", limit: "" })}
                    >
                        {t(common.add)}
                    </Button>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default Products;
