import { Button } from "components/Button";
import AcceptRejectDialog from "components/Dialog/AcceptRejectDialog";
import { FormField } from "components/Form";
import TextWithLabel from "components/Text/TextWithLabel";
import roles from "consts/roles";
import { useUser } from "context/UserContext/UserContext";
import { FormSection } from "redux-form";
import { getUserInfoWithRole, userInfoWithRoleSelector } from "store/autocomplete";
import { actions } from "store/cache/userInfos/actions";
import { sgFormSelector } from "store/vendors/subsidiaryGains";
import { initForm, updateResponsibleUserSG } from "store/vendors/subsidiaryGains/action";
import { withFormName } from "utils/hoc";
import { useChange, useFormValueSelector, useParams } from "utils/hooks";
import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { common, vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";

const formName = "subsidiary-gain-form";

const ResponsibleUser = ({ responsibleUserInfo, readOnly }) => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const { vendorId, subsidiaryGainId } = useParams();
    const dispatch = useDispatch();
    const change = useChange(formName);
    const { t } = useTranslation(["vendor", "common"]);
    const { responsibleUser, status } = useFormValueSelector(formName, ["responsibleUser", "status"]);
    const readOnlyDueToInvoice = status === "InvoiceRequested";

    const { isInRoleOrAdmin } = useUser();
    const { getUserInfos } = actions;
    useEffect(() => {
        if (responsibleUser) {
            dispatch(getUserInfos([responsibleUser.userId]));
        }
    }, [responsibleUser, dispatch, getUserInfos]);

    const isManager = isInRoleOrAdmin(roles.SalesManager);
    let items = [];
    useEffect(() => {
        if (isManager) {
            dispatch(getUserInfoWithRole(roles.Catman));
            dispatch(getUserInfoWithRole(roles.SalesAssistant));
            dispatch(getUserInfoWithRole(roles.SalesShopping));
            dispatch(getUserInfoWithRole(roles.SalesManager));
            dispatch(getUserInfoWithRole(roles.TradeMarketingManager));
        }
    }, [subsidiaryGainId, isManager, dispatch]);
    const users = useSelector(userInfoWithRoleSelector);
    if (
        users &&
        users[roles.Catman] &&
        users[roles.SalesAssistant] &&
        users[roles.SalesShopping] &&
        users[roles.SalesManager] &&
        users[roles.TradeMarketingManager]
    ) {
        items = [
            ...users[roles.Catman],
            ...users[roles.SalesAssistant],
            ...users[roles.SalesShopping],
            ...users[roles.SalesManager],
            ...users[roles.TradeMarketingManager],
        ];
    }

    if (!items.find((u) => u.userId === responsibleUser?.userId)) {
        items.push(responsibleUserInfo);
    }

    if (!Boolean(subsidiaryGainId)) {
        return <></>;
    }

    let responsibleUserText = "";
    if (responsibleUserInfo && responsibleUserInfo.fullName) {
        responsibleUserText = `${responsibleUserInfo?.fullName?.firstName} ${responsibleUserInfo?.fullName?.lastName}`;
    }

    return (
        <>
            <>
                <Grid item>
                    <TextWithLabel
                        value={responsibleUserText}
                        translatedLabel={t(vendor.responsibleUser)}
                    />
                </Grid>
                {isManager && !readOnlyDueToInvoice && (
                    <Grid item>
                        <Button
                            actionType="secondary"
                            type="button"
                            fullWidth
                            onClick={() => {
                                setModalOpen(true);
                            }}
                            disabled={readOnly}
                        >
                            Zamień
                        </Button>
                    </Grid>
                )}
            </>
            <AcceptRejectDialog
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                handleAccept={async () => {
                    await dispatch(updateResponsibleUserSG(vendorId, subsidiaryGainId, responsibleUser));
                    setModalOpen(false);
                    await dispatch(initForm(vendorId, subsidiaryGainId));
                }}
                handleReject={() => setModalOpen(false)}
                maxWidth="md"
                title={t(vendor.changeResponsibleUser)}
                buttonAcceptText={t(`common:${common.change}`)}
                buttonRejectText={t(`common:${common.cancel}`)}
            >
                <FormSection
                    name="responsibleUser"
                    component={React.Fragment}
                >
                    <FormField
                        label={t(vendor.responsibleUser)}
                        type="autocomplete"
                        name="userId"
                        items={items.map((u) => u?.userId)}
                        getOptionLabel={(option) => {
                            if (option.fullName) {
                                return `${option.fullName.firstName} ${option.fullName.lastName}`;
                            } else {
                                const user = items.find((i) => i.userId === option);
                                return user ? `${user.fullName.firstName} ${user.fullName.lastName}` : option;
                            }
                        }}
                        getOptionSelected={(option, value) => {
                            return option === value;
                        }}
                        onChange={(_, val) => {
                            const user = items.find((i) => i.userId === val);
                            change("responsibleUser.userId", user.userId);
                            change("responsibleUser.email", user.email);
                        }}
                    />

                    <FormField
                        type="text"
                        name="email"
                        readOnly
                        hidden
                    />
                </FormSection>
            </AcceptRejectDialog>
        </>
    );
};

export default connect((state) => {
    const form = sgFormSelector(state);
    const { responsibleUser = {} } = form;
    const { userId } = responsibleUser;
    const responsibleUserInfo = state.cache.userInfos.find((x) => x.userId === userId);

    return { responsibleUserInfo };
})(withFormName(ResponsibleUser));
