import { OrgChart } from "d3-org-chart";
import { makeStyles } from "tss-react/mui";
import { useDebouncedCallback } from "use-debounce";
import React, { useEffect, useLayoutEffect, useRef } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid, Icon, Theme } from "@mui/material";
import { api } from "api";
import { common } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { TextField } from "components-ts/controls";
import { SpinningPreloader } from "components-ts/preloaders";
import { Spacing, View } from "components-ts/view";

const useStyles = makeStyles()((theme: Theme) => ({
    header: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.contrastText,
        display: "inline-block",
        minHeight: "35px",
        width: "200px",
        borderRadius: "4px 4px 0 0",
        position: "relative",
        textAlign: "center",
        verticalAlign: "middle",
    },
    content: {
        display: "inline-block",
        minHeight: "26px",
        width: "198px",
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: "0 0 4px 4px",
        textAlign: "center",
        verticalAlign: "middle",
    },
}));

type NodeType = {
    id: string;
    parentId: string | undefined;
    name: string;
    subTitle: string;
};

const FriscoElement = "FRISCO";

const CompanyStructure: React.FC = () => {
    const { data = [], status } = api.operations.autocomplete.useFindEmployees();
    const [search, setSearch] = React.useState("");
    const [searchChanged, setSearchChanged] = React.useState(false);
    const [chartGenerated, setChartGenerated] = React.useState(false);
    const d3Container = useRef(null);
    const chart = new OrgChart<NodeType>();
    const { classes, theme } = useStyles();
    const { t } = useTranslation("common");

    const employees = data.filter((d) => !d.isDeactivated && (d.managerId !== undefined || d.jobTitle));

    const datum = [
        { id: FriscoElement, name: FriscoElement } as NodeType,
        ...employees.map(
            (e) =>
                ({
                    id: e.id,
                    name: e.name.split("(")[0],
                    subTitle: e.jobTitle,
                    parentId: e.managerId !== undefined && employees.some((m) => m.id === e.managerId) ? e.managerId : FriscoElement,
                }) as NodeType
        ),
    ];

    // We need to manipulate DOM
    useLayoutEffect(() => {
        if (datum && d3Container.current && !chartGenerated) {
            chart
                .container(d3Container.current)
                .data(datum)
                .compact(false)
                .nodeId((node) => node.id)
                .nodeWidth((d) => 200)
                .nodeHeight((d) => 80)
                .nodeContent((node) => {
                    return `<div>
                    <div class="${classes.header}">${node.data.name}</div>
                    <div class="${classes.content}">${node.data.subTitle || ""}</div>
                    </div>`;
                })
                .onNodeClick((node) => {
                    if (chart && node.id) {
                        chart.setExpanded(node.id, true).setCentered(node.id).render();
                    }
                })
                .render();

            setChartGenerated(true);
        }
    }, [datum, d3Container.current]);

    useEffect(() => {
        if (d3Container.current && searchChanged) {
            chart
                .container(d3Container.current)
                .data(datum)
                .compact(false)
                .nodeId((node) => node.id)
                .nodeWidth((d) => 200)
                .nodeHeight((d) => 80)
                .nodeContent((node) => {
                    return `<div>
                    <div class="${classes.header}">${node.data.name}</div>
                    <div class="${classes.content}">${node.data.subTitle || ""}</div>
                    </div>`;
                })
                .onNodeClick((node) => {
                    if (chart && node.id) {
                        chart.setExpanded(node.id, true).setCentered(node.id).render();
                    }
                })
                .render();

            if (search && search.length > 0) {
                const data = chart.data();
                chart.clearHighlighting();

                let count = 0;
                data?.forEach((d) => {
                    if (d.name.toLowerCase().includes(search.toLowerCase()) || d.subTitle?.toLowerCase().includes(search.toLowerCase())) {
                        chart.setHighlighted(d.id);
                        count++;
                    }
                });

                if (count > 1) {
                    chart.render().fit();
                } else {
                    chart.render();
                }
            } else {
                chart.clearHighlighting().setCentered(FriscoElement).render();
            }

            setSearchChanged(false);
        }
    }, [d3Container.current, searchChanged]);

    const updateSearchChanged = useDebouncedCallback(setSearchChanged, 500);
    if (status == "pending") {
        return <SpinningPreloader />;
    }

    return (
        <View
            headerText={{ resource: common.companyStructure }}
            isMainView
        >
            <Spacing spacing={2}>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={2}
                >
                    <Grid item>
                        <TextField
                            label={t(common.search)}
                            value={search}
                            onChange={function (newValue: string): void {
                                setSearch(newValue);
                                updateSearchChanged(true);
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <div ref={d3Container} />
                    </Grid>
                </Grid>
            </Spacing>
        </View>
    );
};

export default CompanyStructure;
