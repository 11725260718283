import React, { Fragment } from "react";
import { Grid } from "@mui/material";
import BillingAddress from "./order/BillingAddress";
import ShippingAddress from "./order/ShippingAddress";
import User from "./order/User";
import VariousData from "./order/VariousData";

const OrderContainer = ({ order, commerceOrder, warehouse }) => {
    const { user, shippingAddress, billingAddress, ...rest } = order;
    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
        >
            <Grid
                item
                xs={7}
            >
                <User user={user} />
                <ShippingAddress
                    shippingAddress={{
                        ...shippingAddress,
                        waybillNumber: order.waybillNumber,
                    }}
                />
                <BillingAddress billingAddress={billingAddress} />
            </Grid>
            <Grid
                item
                xs={4}
            >
                {commerceOrder ? (
                    <VariousData
                        {...rest}
                        warehouse={warehouse}
                    />
                ) : (
                    <Fragment />
                )}
            </Grid>
        </Grid>
    );
};

export default OrderContainer;
