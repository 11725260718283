import { withStyles } from "tss-react/mui";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material";
import OrderContainer from "../../order/order/OrderContainer";
import SimpleProductList from "./SimpleProductList";

const SuspiciousOrder = ({ order, classes }) => {
    return (
        <Accordion className={classes.panel}>
            <AccordionSummary className={classes.panelSummary}>
                <Typography color="inherit">{order.orderId}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="stretch"
                >
                    <Grid item>
                        <OrderContainer order={order} />
                    </Grid>
                    <Grid item>
                        <SimpleProductList products={order.productLines} />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default withStyles(SuspiciousOrder, (theme) => ({
    panel: {
        marginTop: theme.spacing(2),
    },
    panelSummary: {
        borderRadius: "7px 7px 0px 0px",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));
