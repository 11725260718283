import NavigateBefore from "@mui/icons-material/NavigateBefore";
import Fab from "@mui/material/Fab";
import { usePush } from "utils-ts/hooks";

const NavigateBack = ({ path, state, className }) => {
    const { push, goBack } = usePush();

    const handleClick = () => {
        if (path) {
            push(path, undefined, state);
        } else {
            goBack();
        }
    };

    return (
        <Fab
            onClick={handleClick}
            className={className}
            size="small"
            color="primary"
        >
            <NavigateBefore />
        </Fab>
    );
};

export default NavigateBack;
