import { makeStyles } from "tss-react/mui";
import React from "react";
import { Icon } from "@mui/material";
import { Grid, Typography } from "@mui/material/";
import Divider from "@mui/material/Divider";
import { GridHeaderProps } from "control-types";
import { useTranslation } from "utils-ts/hooks";
import IconButton from "components-ts/controls/buttons/IconButton";

const useStyles = makeStyles()((theme) => ({
    typhography: {
        fontWeight: "bold",
        textTransform: "uppercase",
        color: theme.palette.secondary.main,
    },
    header: {
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(2),
    },
    icon: {
        marginTop: theme.spacing(2.4),
        color: theme.palette.secondary.main,
    },
    marginBottom: {
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
        height: 1.1,
    },
}));

const GridHeader: React.FC<GridHeaderProps> = ({
    title,
    subtitle,
    iconName,
    hideBottomLine = false,
    translateTitle = true,
    translateSubtitle = true,
    handleCollapse = undefined,
    isCollapsed = false,
}) => {
    const { classes } = useStyles();
    const { t } = useTranslation("common");
    const [collapsed, toggleCollapsed] = React.useState(isCollapsed);

    return title ? (
        <div>
            <Grid
                container
                direction="row"
                alignItems="center"
                style={{ justifyContent: "space-between" }}
            >
                <Grid item>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                    >
                        {Boolean(iconName) && (
                            <Grid item>
                                <Typography
                                    variant="body1"
                                    className={classes.typhography}
                                >
                                    <Icon className={classes.icon}>{iconName}</Icon>
                                </Typography>
                            </Grid>
                        )}
                        <Grid
                            item
                            className={iconName ? classes.header : ""}
                        >
                            <Typography
                                variant="body1"
                                className={classes.typhography}
                            >
                                {translateTitle ? t(title) : title.toString()}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    className={classes.icon}
                >
                    {!!handleCollapse && (
                        <IconButton
                            icon={collapsed ? "expand_less" : "expand_more"}
                            onClick={() => {
                                toggleCollapsed(!collapsed);
                                handleCollapse(!collapsed);
                            }}
                            useWrapper={true}
                        />
                    )}
                </Grid>
            </Grid>
            {subtitle && (
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                >
                    <Grid item>
                        <Typography
                            variant="caption"
                            className={classes.typhography}
                        >
                            {translateSubtitle ? t(subtitle) : subtitle.toString()}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {!hideBottomLine && (
                <Divider
                    variant="fullWidth"
                    className={classes.marginBottom}
                />
            )}
        </div>
    ) : (
        <></>
    );
};

export default GridHeader;
