import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import { formatTypes } from "utils/formating";
import { useAppLocation, useCommonTranslation } from "utils/hooks";

const ProductCategories = ({ categories = [] }) => {
    const { t, common } = useCommonTranslation();
    const appLocation = useAppLocation();

    const columns = [
        {
            key: "categoryId",
            value: common.id,
            type: formatTypes.link,
            options: {
                target: "_blank",
                url: (id) => `${appLocation}/content/categories/${id}`,
            },
        },
        {
            key: "depth",
            value: common.depth,
            type: formatTypes.text,
        },
        {
            key: "namePl",
            value: common.namePl,
            type: formatTypes.text,
        },
        {
            key: "nameEn",
            value: common.nameEn,
            type: formatTypes.text,
        },
    ];

    return (
        <SimpleTable
            stripped
            hover
            columns={columns.map((c) => ({ ...c, value: t(c.value) }))}
            data={categories.map((c) => ({
                ...c,
                namePl: c.name.pl,
                nameEn: c.name.en,
            }))}
        />
    );
};

export default ProductCategories;
