import React from "react";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import { AlertsRoles, contentProductReadRoles, contentWriteRoles, initProductRoles, initProductWithVendorRoles } from "./ContentRoles";


const AlertsPage = React.lazy(() =>
    import("views/content").then((module) => ({
        default: module.AlertsPage,
    }))
);
const BrandsList = React.lazy(() =>
    import("views/content").then((module) => ({
        default: module.BrandsList,
    }))
);
const BrandsForm = React.lazy(() =>
    import("views/content").then((module) => ({
        default: module.BrandsForm,
    }))
);
const InitProductPage = React.lazy(() =>
    import("views/content").then((module) => ({
        default: module.InitProductPage,
    }))
);
const InitProductDetailsPage = React.lazy(() =>
    import("views/content").then((module) => ({
        default: module.InitProductDetailsPage,
    }))
);
const ProductDetailsPage = React.lazy(() =>
    import("views/content").then((module) => ({
        default: module.ProductDetailsPage,
    }))
);

const CategoryList = React.lazy(() =>
    import("views-ts/content").then((module) => ({
        default: module.CategoryList,
    }))
);
const CategoryForm = React.lazy(() =>
    import("views-ts/content").then((module) => ({
        default: module.CategoryForm,
    }))
);
const CategoryTreeList = React.lazy(() =>
    import("views-ts/content").then((module) => ({
        default: module.CategoryTreeList,
    }))
);
const CategoryTreeForm = React.lazy(() =>
    import("views-ts/content").then((module) => ({
        default: module.CategoryTreeForm,
    }))
);
const InitProductList = React.lazy(() =>
    import("views-ts/content").then((module) => ({
        default: module.InitProductList,
    }))
);
const ProductList = React.lazy(() =>
    import("views-ts/content").then((module) => ({
        default: module.ProductList,
    }))
);

export const Content: PrivateRoute[] = [
    new PrivateRoute("/content", initProductWithVendorRoles, undefined, "content", undefined, [
        new PrivateRoute(Paths.Content.InitProductsList, initProductWithVendorRoles, InitProductList, "initProducts", "create"),
        new PrivateRoute(Paths.Content.InitProductForm, initProductWithVendorRoles, InitProductDetailsPage),
        new PrivateRoute(Paths.Content.InitProduct, initProductRoles, InitProductPage),

        new PrivateRoute(Paths.Content.ProductsList, contentProductReadRoles, ProductList, "products", "shopping_basket"),
        new PrivateRoute(Paths.Content.ProductForm, contentProductReadRoles, ProductDetailsPage),

        new PrivateRoute(Paths.Content.CategoryTreesList, contentWriteRoles, CategoryTreeList, "categoryTrees", "device_hub"),
        new PrivateRoute(Paths.Content.CategoryTreeForm, contentWriteRoles, CategoryTreeForm),

        new PrivateRoute(Paths.Content.CategoriesList, contentWriteRoles, CategoryList, "categories", "category"),
        new PrivateRoute(Paths.Content.CategoryForm, contentWriteRoles, CategoryForm),

        new PrivateRoute(Paths.Content.BrandsList, contentWriteRoles, BrandsList, "brands", "photo"),
        new PrivateRoute(Paths.Content.BrandForm, contentWriteRoles, BrandsForm),
    ]),
    new PrivateRoute(Paths.Content.Alerts, AlertsRoles, AlertsPage, "alerts", "notifications_none"),
];