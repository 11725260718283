import { FormField } from "components/Form";
import { toSelectList } from "utils/extensions/arrayExtensions";
import { withFormName } from "utils/hoc";
import { useChange, useCommonTranslation } from "utils/hooks";
import { isEmpty, notEmptyAndNumber } from "utils/validators/basic";
import { Fragment } from "react";
import Modifier from "./Modifier";
import { PoolModifierEffect } from "./model";

const PoolModifier = ({ form, sectionPrefix, effect }) => {
    const { t, common } = useCommonTranslation();
    const change = useChange(form);
    const changeType = async (isModifier) => {
        await change(sectionPrefix, {});
        change(
            form,
            sectionPrefix,
            isModifier
                ? new PoolModifierEffect()
                : new PoolModifierEffect({
                      newValue: 1,
                  })
        );
    };
    const types = toSelectList(["modifier", "newValue"], t);

    const { poolModifier = {} } = effect;
    const { type = {} } = poolModifier;

    return (
        <Fragment>
            <FormField
                name={"type"}
                label={t(common.modificationType)}
                type="select"
                items={types}
                onChange={(_, value) => {
                    changeType(value === "modifier");
                }}
                hideDefaultItem
                validate={isEmpty}
            />
            {!type ? (
                <Fragment />
            ) : type === "modifier" ? (
                <Modifier
                    type={poolModifier?.modifier?.type}
                    formName={form}
                    field={`${sectionPrefix}.poolModifier`}
                />
            ) : (
                <FormField
                    name={"newValue"}
                    label={t(common.value)}
                    type="number"
                    validate={notEmptyAndNumber}
                />
            )}
        </Fragment>
    );
};

export default withFormName(PoolModifier);
