import { makeStyles } from "tss-react/mui";
import React from "react";

const useControlLabelStyles = makeStyles()((theme, { isRequired }) => ({
    label: {
        color: "#383838",
        fontWeight: isRequired ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular,
    },
    asterisk: {
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.error.main,
        userSelect: "none",
        fontSize: theme.typography.fontSize * 1.3,
    },
}));

const ControlLabel = ({ label, isRequired }) => {
    const { classes } = useControlLabelStyles({ isRequired });

    return (
        <>
            <label className={classes.label}>{label}</label>
            {isRequired ? <span className={classes.asterisk}>{" *"}</span> : undefined}
        </>
    );
};

export default React.memo(ControlLabel);
