import SimpleTable, { createColumn } from "components/Table/SimpleTable/SimpleTable";
import tableActionTypes from "components/Table/SimpleTable/TableActions/tableActionTypes";
import { findVendorOrders, listSelector } from "store/vendors/vendorOrders";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useCommonTranslation, useParams } from "utils/hooks";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePush } from "utils-ts/hooks";

const OrdersList = () => {
    const { t, common } = useCommonTranslation();
    const { id } = useParams();
    const { pageIndex, totalCount, pageSize, isLoading, items } = useSelector(listSelector);
    const dispatch = useDispatch();
    const { push } = usePush();
    const pushToVendorOrder = (vendorId, vendorOrderId) => push(`/vendors/${vendorId}/orders/${vendorOrderId}`);

    useEffect(() => {
        dispatch(findVendorOrders(id, pageIndex, pageSize));
    }, [dispatch, id, pageIndex, pageSize]);

    // prettier-ignore
    const columns = [
        createColumn("orderId", t(common.orderId), formatTypes.text),
        createColumn("orderCreatedAt", t(common.createdAt), formatTypes.time, {
            timeFormat: timePrecisonFormats.minutes,
        }),
        createColumn("deliveryAt", t(common.deliveryAt), formatTypes.time, {
            timeFormat: timePrecisonFormats.minutes,
        }),
        createColumn("deliveryAddress", t(common.address), formatTypes.shippingAddress),
        createColumn("invoiceNumber", t(common.invoice), formatTypes.text),
        createColumn("correctionNumbers", t(common.invoiceCorrect), formatTypes.text),
        createColumn(
            "preview",
            "",
            formatTypes.action,
            {
                actionType: tableActionTypes.preview,
            },
            async (vendorOrder) => {
                pushToVendorOrder(id, vendorOrder.orderId);
            }
        ),
    ];

    return (
        <SimpleTable
            isLoading={isLoading}
            columns={columns}
            data={items}
            onRowDoubleClick={(_, vendorOrder) => {
                pushToVendorOrder(id, vendorOrder.vendorOrderId);
            }}
            pagination
            hover
            stripped
            paginationProps={{
                pageIndex,
                totalCount,
                pageSize,
                onPageChange: (pageIndex) => {
                    dispatch(findVendorOrders(id, pageIndex, pageSize));
                },
                onRowsPerPageChange: (pageSize) => {
                    dispatch(findVendorOrders(id, pageIndex, pageSize));
                },
            }}
        />
    );
};

export default OrdersList;
