import Layout from "components/Grid/Layout";
import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import { makeStyles } from "tss-react/mui";
import { link, text } from "utils/formating/formatTypes";
import { useAppLocation, useCommonTranslation } from "utils/hooks";

const useStyles = makeStyles()((theme) => ({
    tableSpacing: {
        marginTop: theme.spacing(2),
    },
}));

const PromotionList = ({ promotions = [] }) => {
    const appLocation = useAppLocation();
    const { t, common } = useCommonTranslation();
    const { classes } = useStyles();
    const columns = [
        {
            key: "campaignName",
            value: t(common.campaignName),
            type: text,
        },
        {
            key: "promotionId",
            value: t(common.promotions),
            type: link,
            options: {
                target: "_blank",
                url: (id) => `${appLocation}/offers/promotions/form/${id}`,
            },
        },
    ];

    const data = promotions.map((x) => {
        const { definition = {} } = x;
        return {
            ...x,
            ...definition,
        };
    });

    return (
        <Layout
            headerText={t(common.promotions)}
            className={classes.tableSpacing}
        >
            <SimpleTable
                columns={columns}
                stripped
                data={data}
            />
        </Layout>
    );
};

export default PromotionList;
