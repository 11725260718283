import React from "react";
import { VendorForm, VendorList, VendorOperation, VendorOperationList } from "views/vendors";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import { AccountantDepartment, ECommerceDepartment, LogisticianDepartment, Role, SalesDepartment, TradeMarketingDepartment } from "../roles";

const Contract = React.lazy(() =>
    import("views/vendors/components").then((module) => ({
        default: module.Contract,
    }))
);
const CreditLimitForm = React.lazy(() =>
    import("views/vendors/components").then((module) => ({
        default: module.CreditLimitForm,
    }))
);
const DecisionList = React.lazy(() =>
    import("views/vendors/components").then((module) => ({
        default: module.DecisionList,
    }))
);
const Document = React.lazy(() =>
    import("views/vendors/components").then((module) => ({
        default: module.Document,
    }))
);
const SubsidiaryGain = React.lazy(() =>
    import("views/vendors/components").then((module) => ({
        default: module.SubsidiaryGain,
    }))
);
const VendorOrder = React.lazy(() =>
    import("views/vendors/components").then((module) => ({
        default: module.VendorOrder,
    }))
);
const TurnoverForm = React.lazy(() =>
    import("views/vendors/components").then((module) => ({
        default: module.TurnoverForm,
    }))
);
const Refundation = React.lazy(() =>
    import("views/vendors/components").then((module) => ({
        default: module.Refundation,
    }))
);
const CorrectionRequest = React.lazy(() =>
    import("views/vendors/components").then((module) => ({
        default: module.CorrectionRequest,
    }))
);
const Certificate = React.lazy(() =>
    import("views/vendors/components").then((module) => ({
        default: module.Certificate,
    }))
);
const MarketingBudget = React.lazy(() =>
    import("views/vendors/components").then((module) => ({
        default: module.MarketingBudget,
    }))
);

const strictRoles: Role[] = ["Management", "Analyst", "Auditor", "MasterData", ...AccountantDepartment.GetRoles(), ...SalesDepartment.GetRoles()];

const roles: Role[] = [...strictRoles, "AXUser", ...TradeMarketingDepartment.GetRoles(), ...LogisticianDepartment.GetRoles()];

const certificateRoles: Role[] = [...strictRoles, ...LogisticianDepartment.GetRoles()];

export const Vendors: PrivateRoute[] = [
    new PrivateRoute(Paths.Vendor.List, [...roles, ...ECommerceDepartment.GetRoles()], VendorList, "vendors", "local_shipping"),
    new PrivateRoute(Paths.Vendor.Form, ["Vendor", ...roles, ...ECommerceDepartment.GetRoles()], VendorForm),
    new PrivateRoute(Paths.Vendor.ContractForm, roles, Contract),
    new PrivateRoute(Paths.Vendor.CorrectionRequestForm, ["Auditor", ...SalesDepartment.GetRoles()], CorrectionRequest),
    new PrivateRoute(Paths.Vendor.CreditLimitForm, roles, CreditLimitForm),
    new PrivateRoute(Paths.Vendor.MarketingBudgetForm, [...SalesDepartment.GetRoles(), ...TradeMarketingDepartment.GetRoles()], MarketingBudget),
    new PrivateRoute(Paths.Vendor.Document, strictRoles, Document),
    new PrivateRoute(Paths.Vendor.OrderForm, SalesDepartment.GetRoles(), VendorOrder),
    new PrivateRoute(Paths.Vendor.RefundationForm, roles, Refundation),
    new PrivateRoute(Paths.Vendor.SubsidiaryGainForm, roles, SubsidiaryGain),
    new PrivateRoute(Paths.Vendor.Turnover, strictRoles, TurnoverForm),
    new PrivateRoute(Paths.Vendor.CertificateForm, certificateRoles, Certificate),

    new PrivateRoute(Paths.VendorOperationsList, ["Auditor"], VendorOperationList, "vendorOperations", "local_shipping"),
    new PrivateRoute(Paths.VendorOperationForm, ["Auditor"], VendorOperation),

    new PrivateRoute(Paths.VendorDecisions, ["SalesManager"], DecisionList, "decisions", "merge_type"),
];
