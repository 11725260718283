import { types } from "./actions";

const initialState = {};

export default (state = initialState, { type, payload, meta = {} }) => {
    switch (type) {
        case types.INIT_FORM_CONTENT_RULES:
            return { ...initialState };
        case types.GET_CONTENT_RULES:
            return { ...initialState, isLoading: true };
        case types.GET_CONTENT_RULES_SUCCESS:
            return {
                ...state,
                ...payload,
                id: meta.isCopy ? "" : payload.id,
                isLoading: false,
            };
        case types.GET_CONTENT_RULES_FAILURE:
            return { ...payload, isLoading: false };
        default:
            return state;
    }
};
