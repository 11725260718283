import moment from "moment";
import localforage from "localforage";

export const cacheNames = {
    productNameStore: "productNameStore",
    userProfileStore: "userProfileStore",
    productsStore: "productsStore",
    identityUsersStore: "identityUsersStore",
    categoriesStore: "categoriesStore",
    commerceUserStore: "commerceUserStore",
    userInfosStore: "userInfosStore",
    categoryTreesStore: "categoryTreesStore",
};

const productNameStoreInit = () => {
    localforage.config({
        driver: [localforage.WEBSQL, localforage.INDEXEDDB, localforage.LOCALSTORAGE],
        name: cacheNames.productNameStore,
        version: 1.0,
        size: 4980736,
        storeName: "productNameStore",
        description: "productNameStore",
    });
};

const userProfileStoreInit = () => {
    localforage.config({
        driver: [localforage.WEBSQL, localforage.INDEXEDDB, localforage.LOCALSTORAGE],
        name: cacheNames.productNameStore,
        version: 1.0,
        size: 4980736,
        storeName: "userProfileStoreInit",
        description: "userProfileStoreInit",
    });
};

const prodcutsStoreInit = () => {
    localforage.config({
        driver: [localforage.WEBSQL, localforage.INDEXEDDB, localforage.LOCALSTORAGE],
        name: cacheNames.productsStore,
        version: 1.0,
        size: 4980736,
        storeName: "prodcutsInit",
        description: "prodcutsInit",
    });
};

const identityUsersStoreInit = () => {
    localforage.config({
        driver: [localforage.WEBSQL, localforage.INDEXEDDB, localforage.LOCALSTORAGE],
        name: cacheNames.identityUsersStore,
        version: 1.0,
        size: 4980736,
        storeName: "identityUsersInit",
        description: "identityUsersInit",
    });
};

const categoriesStoreInit = () => {
    localforage.config({
        driver: [localforage.WEBSQL, localforage.INDEXEDDB, localforage.LOCALSTORAGE],
        name: cacheNames.categoriesStore,
        version: 1.0,
        size: 4980736,
        storeName: "categoriesInit",
    });
};

const commerceUserStoreInit = () => {
    localforage.config({
        driver: [localforage.WEBSQL, localforage.INDEXEDDB, localforage.LOCALSTORAGE],
        name: cacheNames.categoriesStore,
        version: 1.0,
        size: 4980736,
        storeName: "comerceUsers",
    });
};

const userInfosStoreInit = () => {
    localforage.config({
        driver: [localforage.WEBSQL, localforage.INDEXEDDB, localforage.LOCALSTORAGE],
        name: cacheNames.userInfosStore,
        version: 1.0,
        size: 4980736,
        storeName: cacheNames.userInfosStore,
    });
};

const categoryTreesStoreInit = () => {
    localforage.config({
        driver: [localforage.WEBSQL, localforage.INDEXEDDB, localforage.LOCALSTORAGE],
        name: cacheNames.categoryTreesStore,
        version: 1.0,
        size: 4980736,
        storeName: cacheNames.categoryTreesStore,
    });
};

export const isExpired = (expiresAt) => {
    return moment(expiresAt).isBefore(moment());
};

export const init = () => {
    productNameStoreInit();
    userProfileStoreInit();
    prodcutsStoreInit();
    categoriesStoreInit();
    commerceUserStoreInit();
    userInfosStoreInit();
    identityUsersStoreInit();
    categoryTreesStoreInit();
};

export default (cacheName) => localforage.createInstance({ name: cacheName });
