import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import tableActionTypes from "components/Table/SimpleTable/TableActions/tableActionTypes";
import { actions } from "store/customers/customer/customerReviews/action";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { customers } from "translations";
import { useTranslation } from "utils-ts/hooks";
import CustomerReviewsRow from "./CustomerReviewsRow";
import ReviewDialog from "./ReviewDialog";

const CustomerReviews = ({ customerId, customerReviews, getCustomerReviews }) => {
    const [review, setReview] = React.useState(null);
    const handleOpenReviewDialog = (review) => {
        setReview(review);
    };
    const handleCloseReviewDialog = () => {
        setReview(null);
    };
    const { items, pageIndex, pageSize, pageCount, totalCount, isLoading } = customerReviews;
    React.useEffect(() => {
        getCustomerReviews({ customerId, pageIndex, pageSize });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId, getCustomerReviews]);
    const { t } = useTranslation("customers");
    // prettier-ignore
    const columns = [
        { key: "reviewedAt",          value: customers.reviewedAt,          type: formatTypes.time,   options: { timeFormat: timePrecisonFormats.seconds }           },
        { key: "recommendation",      value: customers.recommendation,      type: formatTypes.rating, options: { max: 10, numeric: true }                            },
        { key: "hasQualityIssue",     value: customers.hasQualityIssue,     type: formatTypes.boolean                                                                },
        { key: "hasComplienceIssue",  value: customers.hasComplienceIssue,  type: formatTypes.boolean                                                                },
        { key: "hasPunctualityIssue", value: customers.hasPunctualityIssue, type: formatTypes.boolean                                                                },
        { key: "hasPaymentIssue",     value: customers.hasPaymentIssue,     type: formatTypes.boolean                                                                },
        { key: "hasPageIssue",        value: customers.hasPageIssue,        type: formatTypes.boolean                                                                },
        { key: "action",              value: "",                            type: formatTypes.action,  action: handleOpenReviewDialog, actionType: tableActionTypes.preview},
    ].map(col => ({...col, value: t(col.value)}));
    return (
        <Fragment>
            <SimpleTable
                isLoading={isLoading}
                columns={columns}
                hover
                pagination
                fillEmptyRows={false}
                paginationProps={{
                    pageIndex,
                    totalCount,
                    pageSize,
                    pageCount,
                    onPageChange: (pageIndex) => getCustomerReviews({ customerId, pageIndex, pageSize }),
                    onRowsPerPageChange: (pageSize) => getCustomerReviews({ customerId, pageIndex, pageSize }),
                }}
            >
                {items.map((x, index) => (
                    <CustomerReviewsRow
                        key={index}
                        columns={columns}
                        item={x}
                        t={t}
                        handleDoubleClick={handleOpenReviewDialog}
                    />
                ))}
            </SimpleTable>
            <ReviewDialog
                onClose={handleCloseReviewDialog}
                open={Boolean(review)}
                reviewItem={review}
            />
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    const {
        customer: { customerReviews },
    } = state;
    return { customerReviews };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CustomerReviews);
