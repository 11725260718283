import { generatePath, useNavigate } from "react-router";
import { Params, useLocation } from "react-router-dom";
import moment from "moment";
import { Fetcher } from "api-types";
import { PathParameters } from "routing-ts/PathParameters";

const isDate = (value: Fetcher.BaseTypes): value is Date => {
    return moment.isDate(value) && typeof value === "object" && value?.getFullYear() !== undefined;
};

const toQueryParameter = (key: string, value: Fetcher.BaseTypes) => {
    if (value === null || value === undefined) {
        return "";
    }

    if (moment.isMoment(value)) {
        return `${key}=${value.format("YYYY-MM-DDTHH:mm:ss")}`;
    }

    if (isDate(value)) {
        const month = value.getMonth() + 1;
        return `${key}=${value.getFullYear()}-${month.toString().length === 1 ? `0${month}` : month}-${value.getDate()}`;
    }

    return `${key}=${encodeURIComponent(value)}`;
};

const combineQueryParameters = (queryParameters?: Fetcher.QueryParams) => {
    if (!queryParameters) {
        return "";
    }

    const queryString = Object.keys(queryParameters)
        .filter((key) => {
            const value = queryParameters[key];
            return Boolean(value) || value === false;
        })
        .map((key) =>
            Array.isArray(queryParameters[key])
                ? (queryParameters[key] as Fetcher.BaseTypes[]).map((i: Fetcher.BaseTypes) => toQueryParameter(key, i)).join("&")
                : toQueryParameter(key, queryParameters[key] as Fetcher.BaseTypes)
        )
        .join("&");

    return `?${queryString}`;
};

const usePush = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const push = async <T extends PathParameters<T>>(route: string, routeParameters?: Params<string>, state?: unknown) => {
        const fullPath = generatePath(route, { ...routeParameters });
        await navigate(fullPath, { state });
    };

    const pushToForm = async <T extends PathParameters<T>>(path: string, id: string, isCopy: boolean = false) => {
        const routePath = path && path.endsWith("/") ? path.slice(0, -1) : path;
        push(id && !isCopy ? `${routePath}/${id}` : routePath, undefined, {
            id,
            isCopy,
        });
    };

    const replace = async <T extends PathParameters<T>>(route: string, routeParameters?: T) => {
        const path = generatePath(route, { ...routeParameters });
        await navigate(path, { replace: true });
    };

    const replaceQuery = async (query: Fetcher.QueryParams) => {
        await navigate({
            pathname: location.pathname,
            search: combineQueryParameters(query),
        });
    };

    const goBack = async () => {
        await navigate(-1);
    };

    const refresh = async () => {
        await navigate(0);
    };

    return { navigate, replace, push, pushToForm, goBack, replaceQuery, refresh };
};

export default usePush;
