import types from "./types";

const initialState = {};
const { createOrUpdate, formInit, getCnStakes } = types;

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case formInit:
            return { ...initialState };
        case getCnStakes.request:
            return { ...initialState, isLoading: true };
        case getCnStakes.success:
            return { ...state, ...payload, isLoading: false };
        case getCnStakes.failure:
            return { ...payload, isLoading: false };
        case createOrUpdate.request:
            return { ...state, isSubbmitRequested: true };
        case createOrUpdate.success:
        case createOrUpdate.failure:
            return { ...state, isSubbmitRequested: false };
        default:
            return state;
    }
};
