import { combineReducers } from "redux";
import { default as cnStakes } from "./cnStakes/reducer.js";
import { default as contactType } from "./contactType/reducer.js";
import { default as costReason } from "./costReason/reducer.js";
import { default as documentDefinitions } from "./documentDefinitions/reducer.js";
import { default as procedureCategory } from "./procedureCategory/reducer.js";

export const dictionaries = combineReducers({
    cnStakes,
    costReason,
    contactType,
    documentDefinitions,
    procedureCategory,
});
