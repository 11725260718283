import { combineReducers } from "redux";
import types from "./types";
import defaultListState from "../defaultListState";

const listInitialState = {
    ...defaultListState,
};

const unacceptedList = (state = listInitialState, { type, payload }) => {
    switch (type) {
        case types.findUnacceptedEmployeeProcedures.request:
            return {
                ...listInitialState,
                isLoading: true,
            };
        case types.findUnacceptedEmployeeProcedures.success:
            return {
                ...state,
                items: payload,
                isLoading: false,
            };
        case types.findUnacceptedEmployeeProcedures.failure:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return { ...state };
    }
};

const list = (state = listInitialState, { type, payload }) => {
    switch (type) {
        case types.findEmployeeProcedures.request:
            return {
                ...listInitialState,
                isLoading: true,
            };
        case types.findEmployeeProcedures.success:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case types.findEmployeeProcedures.failure:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return { ...state };
    }
};

const formInitialState = {
    isLoading: false,
};

const form = (state = formInitialState, { type, payload }) => {
    switch (type) {
        case types.formInit:
            return { ...formInitialState };

        case types.locationChanged:
            return { nextProcedure: undefined, isLoading: true, isDecisionMade: false };

        case types.makeDecision.request:
        case types.getEmployeeProcedure.request:
            return { ...state, nextProcedure: undefined, isLoading: true };

        case types.getEmployeeProcedure.success:
            return { isLoading: false, ...payload };

        case types.makeDecision.failure:
        case types.getEmployeeProcedure.failure:
            return { ...state, isLoading: false };

        case types.makeDecision.success:
            return { ...state, ...payload, isDecisionMade: true };

        default:
            return { ...state };
    }
};

export const employeeProcedures = combineReducers({ form, unacceptedList, list });
