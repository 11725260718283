import { FormField } from "components/Form";
import { Grid } from "@mui/material";
import { compose } from "redux";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";

const Details = ({ detail, canEdit }) => {
    const { t } = useTranslation("vendor");
    const { transactionSummary, invoiceSummary, vendorSummary, dateTo } = detail;

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-end"
        >
            <FormField
                name="transactionSummary"
                label={t(vendor.transactionSummary)}
                type="text"
                adornment={
                    transactionSummary !== "Brak danych" && {
                        position: "end",
                        value: "zł",
                    }
                }
                readOnly
            />
            <FormField
                name="invoiceSummary"
                label={t(vendor.invoiceSummary)}
                type="text"
                adornment={
                    invoiceSummary !== "Brak danych" && {
                        position: "end",
                        value: "zł",
                    }
                }
                readOnly
            />
            <FormField
                name="vendorSummary"
                label={t(vendor.vendorSummary)}
                type="text"
                adornment={{
                    position: "end",
                    value: "zł",
                }}
                readOnly={!canEdit(vendorSummary)}
                mask
                thousandSeparator=" "
                decimalSeparator="."
                decimalScale={2}
                fixedDecimalScale
            />
            {dateTo && (
                <FormField
                    name="dateTo"
                    label={t(vendor.dateTo)}
                    type="date"
                    readOnly
                />
            )}
        </Grid>
    );
};

export default compose((props) => {
    return <Details {...props} />;
});
