import defaultListState from "store/defaultListState";
import { combineReducers } from "redux";
import types from "./types";

const initialState = {
    form: {
        isLoading: false,
    },
    list: {
        filters: {},
        ...defaultListState,
    },
};

const form = (state = initialState.form, { type, payload }) => {
    switch (type) {
        case types.getTurnover.request:
            return { ...state, isLoading: true };
        case types.getTurnover.success:
            return { ...state, vendorSummaryTotalFile: undefined, ...payload, isLoading: false };
        case types.getTurnover.failure:
            return { ...state, isLoading: false };
        case types.updateTurnovers.request:
            return { ...state, isLoading: true, isSubmitRequested: true };
        case types.updateTurnovers.success:
            return { ...state, ...payload, isLoading: false, isSubmitRequested: false };
        case types.updateTurnovers.failure:
            return { ...state, isLoading: false, isSubmitRequested: false };
        default:
            return state;
    }
};

const list = (state = initialState.list, { type, payload }) => {
    switch (type) {
        case types.findTurnovers.request:
            return { ...state, isLoading: true };
        case types.findTurnovers.success:
            return { ...state, ...payload, isLoading: false };
        case types.findTurnovers.failure:
            return { ...state, isLoading: false };
        case types.changePagination:
            return { ...state, ...payload };
        case types.setFilters: {
            return { ...state, filters: { ...state.filters, ...payload } };
        }
        default:
            return state;
    }
};

export const turnovers = combineReducers({ form, list });
