import { PagedRequest, getPaged } from "utils/extensions";
import types from "./types";

const getRewards =
    ({ isActive, pageIndex, pageSize, ...rest }) =>
    async (dispatch) => {
        let query = { ...rest };
        if (isActive !== "") {
            query.isActive = isActive;
        }

        return dispatch(
            getPaged(new PagedRequest(pageIndex, pageSize, "api/v1/offer/products/rewards", types.getRewards.all, { queryParams: query }))
        );
    };

export const actions = {
    getRewards,
};
