export const userId = new RegExp(/^[0-9]{6,7}$/);
export const orderId = new RegExp(/^[0-9]{6,7}\/[0-9]{6}$/);
export const voucherId = new RegExp(/^[A-Za-z0-9]{5,24}$/);
export const productId = new RegExp(/^[0-9]{1,6}$/);
export const phoneNumber = new RegExp(/^(.*[0-9]){9,}.*/);
export const emailAddress = new RegExp(/(\w(=?@)\w+\.{1}[a-zA-Z]{2,})/);
export const currency = new RegExp(/^[0-9]+(\.[0-9]{1,2})?$/);
export const digits = new RegExp(/^\d*/);
export const slug = new RegExp(/^[A-Za-z0-9][A-Za-z0-9_-]*(:[A-Za-z0-9][A-Za-z0-9_-]*){0,1}$/);
export const postCode = new RegExp(/^[0-9]{2}-[0-9]{3}$/);
export const ean = new RegExp(/^[A-Za-z0-9]{4,13}$/);
