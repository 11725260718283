import React from "react";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import { ECommerceDepartment, Role, SalesDepartment } from "routing-ts/roles";

const ProductRations = React.lazy(() =>
    import("views/pricing").then((module) => ({
        default: module.ProductRations,
    }))
);
const ProductRationsForm = React.lazy(() =>
    import("views/pricing").then((module) => ({
        default: module.ProductRationsForm,
    }))
);
const ProductLimits = React.lazy(() =>
    import("views/pricing").then((module) => ({
        default: module.ProductLimits,
    }))
);
const ProductLimitsForm = React.lazy(() =>
    import("views/pricing").then((module) => ({
        default: module.ProductLimitsForm,
    }))
);
const ProductRestrictions = React.lazy(() =>
    import("views/pricing").then((module) => ({
        default: module.ProductRestrictions,
    }))
);
const ProductRestrictionsForm = React.lazy(() =>
    import("views/pricing").then((module) => ({
        default: module.ProductRestrictionsForm,
    }))
);
const WaterLimits = React.lazy(() =>
    import("views/pricing").then((module) => ({
        default: module.WaterLimits,
    }))
);
const WaterLimitsForm = React.lazy(() =>
    import("views/pricing").then((module) => ({
        default: module.WaterLimitsForm,
    }))
);

const pricingRoles: Role[] = ["Analyst", "Management", ...SalesDepartment.GetRoles(), ...ECommerceDepartment.GetRoles()];

export const Pricing: PrivateRoute[] = [
    new PrivateRoute("/pricing", pricingRoles, undefined, "limitsRestrictions", "block", [
        new PrivateRoute(Paths.Pricing.WaterLimitsList, pricingRoles, WaterLimits, "waterLimits", "local_drink"),
        new PrivateRoute(Paths.Pricing.WaterLimitForm, pricingRoles, WaterLimitsForm),
        new PrivateRoute(Paths.Pricing.ProductLimitsList, pricingRoles, ProductLimits, "productLimits", "payment"),
        new PrivateRoute(Paths.Pricing.ProductLimitForm, pricingRoles, ProductLimitsForm),
        new PrivateRoute(Paths.Pricing.ProductRestrictionsList, pricingRoles, ProductRestrictions, "productRestrictions", "block"),
        new PrivateRoute(Paths.Pricing.ProductRestrictionForm, pricingRoles, ProductRestrictionsForm),
        new PrivateRoute(Paths.Pricing.ProductRationsList, pricingRoles, ProductRations, "productRations", "speed"),
        new PrivateRoute(Paths.Pricing.ProductRationForm, pricingRoles, ProductRationsForm),
    ]),
];
