import { types as rootTypes } from "../action";
import { types } from "./action";

const initialState = {
    isLoading: false,
    balanceAmount: {
        _total: 0,
        FRS: 0,
        F24: 0,
    },
    creditLimit: {},
};

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case rootTypes.CUSTOMER_CHANGED:
            return initialState;
        case types.CUSTOMER_BALANCE_GET:
            return { ...state, isLoading: true };
        case types.CUSTOMER_BALANCE_GET_SUCCESS:
            return { ...state, ...payload, isLoading: false };
        case types.CUSTOMER_BALANCE_GET_FAILURE:
            return { ...state, isLoading: false };
        default:
            return { ...state };
    }
};
