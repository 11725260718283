import Layout from "components/Grid/Layout";
import TableWithToggleFilter from "components/Table/TableWithToggleFilter/TableWithToggleFilter";
import { actions } from "store/deliveryCosts/list/actions";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useCommonTranslation, useMappedDispatch } from "utils/hooks";
import { usePush } from "utils-ts/hooks";
import { deliveryCostsListSelector } from "./selectors";

const DeliveryCosts = () => {
    const { t, common } = useCommonTranslation();
    const { remove } = useMappedDispatch(actions);
    const { push } = usePush();
    const pushToForm = (id, isCopy) =>
        push(id && !isCopy ? `delivery-costs/form/${id}` : "delivery-costs/form", {
            id,
            isCopy,
        });
    //prettier-ignore
    const columns = [
            { key: "name",       value: common.name,       type: formatTypes.text,                                                     },
            { key: "validFrom",  value: common.validFrom,  type: formatTypes.time, options: {timeFormat: timePrecisonFormats.days},    },
            { key: "validTo",    value: common.validTo,    type: formatTypes.time, options: {timeFormat: timePrecisonFormats.days},    },
            { key: "isActive",   value: common.active,     type: formatTypes.boolean,                                                  },
            { key: "createdAt",  value: common.createdAt,  type: formatTypes.time, options: {timeFormat: timePrecisonFormats.seconds}, },
            { key: "updatedAt",  value: common.updatedAt,  type: formatTypes.time, options: {timeFormat: timePrecisonFormats.seconds}, },
            { key: "action",     value: "",                type: formatTypes.action, actionType: "preview", action: item => pushToForm(item.id) },
            { key: "action",     value: "",                type: formatTypes.action, actionType: "copy", action: item => pushToForm(item.id, true)},
            { key: "action",     value: "",                type: formatTypes.action, actionType: "delete", action: item => remove(item.id), reloadOnAction: true},
        ].map(col => ({...col, value: t(col.value)}));

    return (
        <Layout
            main
            headerText={t(common.deliveryCosts)}
        >
            <TableWithToggleFilter
                columns={columns}
                addItemAction={() => pushToForm()}
                dataSelector={deliveryCostsListSelector}
                dataSourceAction={actions.getList}
            />
        </Layout>
    );
};

export default DeliveryCosts;
