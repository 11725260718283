import { FormField } from "components/Form";
import divisions from "resource/divisions.json";
import { useCommonTranslation } from "utils/hooks";

const DivisionSelect = (props) => {
    const { t, common } = useCommonTranslation();
    return (
        <FormField
            {...props}
            name={"divisions"}
            label={t(common.divisions)}
            type="select"
            multiple
            items={
                props.selectedDivisions == undefined || !Array.isArray(props.selectedDivisions)
                    ? divisions
                    : divisions.map((o) => {
                          return { ...o, disabled: o.disabled && !props.selectedDivisions.includes(o.value) };
                      })
            }
        />
    );
};

export default DivisionSelect;
