import NavigationList from "components/Layout/Sidebar/NavigationList";
import { makeStyles } from "tss-react/mui";
import Drawer from "@mui/material/Drawer";
import useMediaQuery from "@mui/material/useMediaQuery";
import Brand from "./Brand";
import Image from "./Image";

const useStyles = makeStyles()((theme) => ({
    drawerPaper: {
        border: "none",
        position: "fixed",
        top: "0",
        bottom: "0",
        left: "0",
        zIndex: "1",
        width: 240,
        [theme.breakpoints.up("lg")]: {
            width: 240,
            position: "fixed",
            height: "100%",
        },
        [theme.breakpoints.down("lg")]: {
            width: 240,
            position: "fixed",
            display: "block",
            top: "0",
            height: "100vh",
            right: "0",
            left: "auto",
            zIndex: "1032",
            visibility: "visible",
            overflowY: "visible",
            borderTop: "none",
            textAlign: "left",
            paddingRight: "0px",
            paddingLeft: "0",
            transform: `translate3d(${240}px, 0, 0)`,
        },
    },
}));

const Sidebar = ({ routes, open, handleDrawerToggle }) => {
    const { classes } = useStyles();
    const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("lg"));

    return isLargeScreen ? (
        <Drawer
            anchor="left"
            variant="permanent"
            open={open}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <Brand />
            <NavigationList routes={routes} />
            <Image />
        </Drawer>
    ) : (
        <Drawer
            variant="temporary"
            anchor="right"
            open={open}
            classes={{
                paper: classes.drawerPaper,
            }}
            onClose={handleDrawerToggle}
            keepMounted
        >
            <Brand />
            <NavigationList routes={routes} />
            <Image />
        </Drawer>
    );
};

export default Sidebar;
