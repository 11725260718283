import { common } from "translations";

const activityReasons = [
    common.BusinessSupportInvolved,
    common.InVerification,
    common.PostponeContact,
    common.EmailContact,
    common.PhoneContact,
    common.PaymentRequestDocumentSend,
    common.IncludeInReportForLawyers,
    common.ReportedToLawyers,
    common.DebtCollectionCompanyInvolved,
    common.CourtCaseOpened,
    common.InstallmentPlanCreated,
    common.SmsContact,
];

export default activityReasons;
