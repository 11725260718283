import { GetRequest, get } from "utils/extensions";
import types from "./types";

export const getCustomerReservationAudit =
    ({ customerId, pageIndex, pageSize }) =>
    (dispatch) => {
        dispatch(
            get(
                new GetRequest(`api/v1/users/${customerId}/cart/reservation/audit`, types.CUSTOMER_RESERVATION_AUDIT.all, {
                    queryParams: {
                        pageIndex,
                        pageSize,
                    },
                })
            )
        );
    };
