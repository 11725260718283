import React from "react";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import { AccountantDepartment } from "routing-ts/roles";

const VatCorrections = React.lazy(() => import("views/vatCorrections/VatCorrections"));

export const Corrections: PrivateRoute[] = [
    new PrivateRoute(Paths.VatCorrections, AccountantDepartment.GetRoles(), VatCorrections, "vatCorrections", "speaker"),
];
