import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import tableActionTypes from "components/Table/SimpleTable/TableActions/tableActionTypes";
import ordersStatuses from "resource/ordersStatuses.json";
import paymentMethods from "resource/paymentMethods.json";
import { actions } from "store/order/orderList/action";
import { documentTypes } from "utils/dictionaryTypes/index";
import { stringStyle, timePrecisonFormats } from "utils/formating";
import formatTypes from "utils/formating/formatTypes";
import { withPush } from "utils/hoc";
import { splitOrderId } from "utils/splitIds";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { orders } from "translations";

class OrderList extends Component {
    constructor(props) {
        super(props);
        const { t } = props;
        this.columns = [
            {
                key: "id",
                value: t(orders.orderId),
                type: formatTypes.text,
                options: { style: stringStyle.imporant },
            },
            {
                key: "invoices",
                value: t(orders.invoice),
                type: formatTypes.text,
            },
            {
                key: "billingAddress.vatin",
                value: t(orders.vatin),
                type: formatTypes.vatin,
            },
            {
                key: "status",
                value: t(orders.status),
                type: formatTypes.resource,
                options: { items: ordersStatuses },
            },
            {
                key: "placedAt",
                value: t(orders.placedAt),
                type: formatTypes.time,
                options: { timeFormat: timePrecisonFormats.seconds },
            },
            {
                key: "paymentMethod",
                value: t(orders.paymentMethod),
                type: formatTypes.resource,
                options: { items: paymentMethods },
            },
            {
                key: "reservation.deliveryWindow.closesAt",
                value: t(orders.closesAt),
                type: formatTypes.time,
                options: { timeFormat: timePrecisonFormats.seconds },
            },
            {
                key: "invoiceTotalWithDeliveryCost",
                value: t(orders.invoiceTotal),
                type: formatTypes.currencyAmount,
            },
            {
                key: "deliveryWindow",
                value: t(orders.deliveryAt),
                type: formatTypes.deliveryWindow,
            },
            {
                key: "warehouse",
                value: t(orders.warehouse),
                type: formatTypes.text,
            },
            {
                key: "shippingAddress",
                value: t(orders.deliveryAddress),
                type: formatTypes.shippingAddress,
            },
            {
                key: "isEarlyEntranceAllowed",
                value: t(orders.isEarlyEntranceAllowedShort),
                type: formatTypes.boolean,
            },
            {
                key: "edit",
                value: "",
                type: formatTypes.action,
                action: this.handleGoToOrder,
                actionType: tableActionTypes.preview,
            },
        ];
    }

    componentDidMount = () => {
        const { customerId, orderList, tabIndex } = this.props;
        const { pageIndex, pageSize } = orderList;
        if (orderList && orderList.items && orderList.items.length && tabIndex) return;
        this.props.getOrders(pageIndex, pageSize, customerId);
    };

    handleGoToOrder = ({ id }) => {
        const { push } = this.props;
        const { customerId, orderNumber, complaintNumber } = splitOrderId(id);
        if (complaintNumber) {
            push(`/customers/${customerId}/orders/${orderNumber}/${complaintNumber}`);
        } else {
            push(`/customers/${customerId}/orders/${orderNumber}`);
        }
    };

    handlePageChange = (pageIndex) => {
        const { customerId, orderList } = this.props;
        const { pageSize } = orderList;
        this.props.getOrders(pageIndex, pageSize, customerId);
    };

    handlePageSizeChange = (pageSize) => {
        const { customerId, orderList } = this.props;
        const { pageIndex } = orderList;
        this.props.getOrders(pageIndex, pageSize, customerId);
    };

    render = () => {
        const { items, totalCount, pageIndex, pageSize, isLoading } = this.props.orderList;

        return (
            <SimpleTable
                columns={this.columns}
                isLoading={isLoading}
                onRowDoubleClick={(_, order) => {
                    this.handleGoToOrder(order);
                }}
                data={items.map((item) => {
                    const { documents, reservation } = item;
                    const invoices = documents
                        .map((x) => ({ ...x.documentReference }))
                        .filter((x) => x.documentType === documentTypes.invoice)
                        .map((x) => x.documentNumber);
                    const { deliveryWindow, isDeliveryAdjusted, extendedRange } = reservation;

                    return {
                        ...item,
                        ...item.delivery,
                        ...item.reservation,
                        ...item.totals,
                        deliveryWindow:
                            isDeliveryAdjusted !== true && extendedRange != null
                                ? {
                                      ...deliveryWindow,
                                      startsAt: extendedRange.startsAt,
                                      endsAt: extendedRange.endsAt,
                                      isFlexibleSlots: true,
                                  }
                                : deliveryWindow,
                        warehouse: item?.reservation?.deliveryWindow?.warehouse,
                        invoices,
                    };
                })}
                pagination
                stripped
                hover
                paginationProps={{
                    pageIndex,
                    totalCount,
                    pageSize,
                    onPageChange: this.handlePageChange,
                    onRowsPerPageChange: this.handlePageSizeChange,
                }}
            />
        );
    };
}

const mapStateToProps = (state) => {
    const { orderList } = state;

    return {
        orderList,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ ...actions }, dispatch);

export default withPush(withTranslation("orders")(connect(mapStateToProps, mapDispatchToProps)(OrderList)));
