import { FormField } from "components/Form";
import { activePromotionPeriodsSelector } from "store/autocomplete";
import { timePrecisonFormats } from "utils/formating";
import { withFormName } from "utils/hoc";
import { useChange, useFormValueSelector } from "utils/hooks";
import { isAccountingPeriodOpen, isQuaterlyAccountingPeriodOpen, shouldDisableDate } from "utils/utilsFunctions";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { common, vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { validators } from "./validation";

moment.locale("pl");

const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

const months = range(0, 11, 1).map((m) => ({
    value: m,
    name: moment().month(m).format("MMMM"),
}));

const years = range(moment().year() - 1, moment().year() + 1, 1).map((x) => ({
    value: x,
    name: x,
}));

const quaters = [
    { value: 1, name: "Kwartał I" },
    { value: 2, name: "Kwartał II" },
    { value: 3, name: "Kwartał III" },
    { value: 4, name: "Kwartał IV" },
];

const quaterMap = range(1, 4, 1)
    .map((m, i) => ({
        key: m,
        func: (year) => ({
            dateFrom: moment()
                .year(year)
                .month(3 * i)
                .startOf("month"),
            dateTo: moment()
                .year(year)
                .month(3 * i + 2)
                .endOf("month"),
        }),
    }))
    .reduce((c, n) => ({ ...c, [n.key]: n.func }), {});

const minDate = moment().subtract(1, "years");
const maxDate = moment().add(6, "months");

const CurrentDates = ({ isDecisionRequired, readOnly, disabled, accountingPeriods, form }) => {
    const { t } = useTranslation(["vendor", "common"]);
    const change = useChange(form);
    const allPromotionPeriods = useSelector(activePromotionPeriodsSelector);
    const promotionPeriods = allPromotionPeriods.filter(
        (p) => isDecisionRequired || (!shouldDisableDate(accountingPeriods, p.dateFrom) && !shouldDisableDate(accountingPeriods, p.dateTo))
    );
    const [periodInput, setPeriodInput] = useState("");
    const { isDate, dateFrom, dateTo, promotionPeriodId } = useFormValueSelector(form, ["isDate", "dateFrom", "dateTo", "promotionPeriodId"], {});

    const currentPromotionPeriod =
        allPromotionPeriods.find((p) => moment(p.dateFrom).isSameOrBefore(moment(), "days") && moment().isSameOrBefore(moment(p.dateTo), "days")) ||
        promotionPeriods.slice(-1)[0];
    const changeIsDate = (_, newValue) => {
        if (newValue === true) {
            change("isDate", true);
            change("promotionPeriodId", "");
            change("dateFrom", dateFrom);
            change("dateTo", dateTo);
        } else {
            const ppId = promotionPeriods.find(
                (pp) => moment(dateFrom).isSameOrAfter(moment(pp.dateFrom), "days") && moment(dateFrom).isSameOrBefore(moment(pp.dateTo), "days")
            )?.id;
            changePromotionPeriod(ppId || currentPromotionPeriod.id);
        }
    };

    const inputFromPeriod = (promotionPeriod) => {
        if (!promotionPeriod) {
            return {
                name: "",
                value: "",
                onChange: changePromotionPeriod,
            };
        }
        return {
            name: `${promotionPeriod.name} (${moment(promotionPeriod.dateFrom).format("YYYY-MM-DD")}/${moment(promotionPeriod.dateTo).format(
                "yyyy-MM-DD"
            )})`,
            value: promotionPeriod.id,
            onChange: changePromotionPeriod,
        };
    };

    const changePromotionPeriod = (value) => {
        const selectedPromotionPeriod = allPromotionPeriods.find((p) => p.id == value);
        setPeriodInput(inputFromPeriod(selectedPromotionPeriod));
        change("promotionPeriodId", selectedPromotionPeriod.id);
        change("dateFrom", selectedPromotionPeriod.dateFrom);
        change("dateTo", selectedPromotionPeriod.dateTo);
    };

    useEffect(() => {
        if (isDecisionRequired) {
            const current = allPromotionPeriods.find((p) => p.id == promotionPeriodId?.current);
            const proposal = allPromotionPeriods.find((p) => p.id == promotionPeriodId?.proposal);
            setPeriodInput({
                value: {
                    current: current ? current.id : undefined,
                    proposal: proposal ? proposal.id : undefined,
                    isSame: promotionPeriodId?.isSame,
                },
            });
        } else {
            setPeriodInput(inputFromPeriod(null));

            if (!isDate && !!promotionPeriodId && dateFrom && dateTo) {
                const promotionPeriod = (readOnly ? allPromotionPeriods : promotionPeriods).find(
                    (p) => moment(p.dateFrom).isSame(dateFrom, "days") && moment(p.dateTo).isSame(dateTo, "days")
                );
                if (!!promotionPeriod) {
                    setPeriodInput(inputFromPeriod(promotionPeriod));
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDecisionRequired ? isDecisionRequired : isDate, promotionPeriods.length]);

    return (
        <>
            <FormField
                name="isDate"
                type="boolean"
                label={t(`common:${common.isDate}`)}
                onChange={changeIsDate}
                readOnly={readOnly}
                useDiff={isDecisionRequired}
            />
            {(isDate === false || (isDecisionRequired && (!isDate.current || !isDate.proposal))) && (
                <FormField
                    name="promotionPeriodId"
                    label={t(`common:${common.promotionPeriod}`)}
                    type="select"
                    items={(readOnly ? allPromotionPeriods : promotionPeriods).map((p) => {
                        return inputFromPeriod(p);
                    })}
                    readOnly={readOnly}
                    hideDefaultItem={true}
                    input={periodInput}
                    onValueChange={changePromotionPeriod}
                    useDiff={isDecisionRequired}
                />
            )}
            {(isDate === true || (isDecisionRequired && (!!isDate.current || !!isDate.proposal))) && (
                <>
                    <FormField
                        disabled={disabled}
                        useDiff={isDecisionRequired}
                        type="date"
                        name="dateFrom"
                        label={t(vendor.dateFrom)}
                        readOnly={readOnly}
                        validate={validators.dateFrom}
                        minDate={minDate}
                        maxDate={maxDate}
                        shouldDisableDate={(date) => shouldDisableDate(accountingPeriods, date)}
                        disableCurrent={isDecisionRequired && !isDate.current && isDate.proposal}
                        disableProposal={isDecisionRequired && !isDate.proposal && isDate.current}
                    />
                    <FormField
                        disabled={disabled}
                        useDiff={isDecisionRequired}
                        type="date"
                        name="dateTo"
                        label={t(vendor.dateTo)}
                        readOnly={readOnly}
                        validate={validators.dateTo}
                        minDate={minDate}
                        maxDate={maxDate}
                        shouldDisableDate={(date) => shouldDisableDate(accountingPeriods, date)}
                        disableCurrent={isDecisionRequired && !isDate.current && isDate.proposal}
                        disableProposal={isDecisionRequired && !isDate.proposal && isDate.current}
                    />
                </>
            )}
        </>
    );
};

const MonthlyDates = ({ form, isDecisionRequired, readOnly, disabled, innerSettlementPeriod, accountingPeriods }) => {
    const change = useChange(form);
    const { t } = useTranslation(["vendor", "common"]);

    const { year, month } = useFormValueSelector(form, ["year", "month"]);

    const onYearChange = (_, newYear) => {
        if (newYear !== year) {
            change("dateFrom", null);
            change("dateTo", null);
            change("month", null);
            change("year", newYear);
        }
    };

    const onMonthChange = (_, newMonth) => {
        if (!Number.isInteger(newMonth)) {
            change("dateFrom", null);
            change("dateTo", null);
        } else {
            if (newMonth !== month) {
                const dateFrom = moment().year(year).month(newMonth);
                if (isAccountingPeriodOpen(accountingPeriods, dateFrom)) {
                    change("dateFrom", dateFrom.startOf("month"));
                    change("dateTo", dateFrom.clone().endOf("month"));
                    change("month", newMonth);
                }
            }
        }
    };

    return (
        <>
            <FormField
                disabled={!Boolean(innerSettlementPeriod)}
                useDiff={isDecisionRequired}
                dateFormat={timePrecisonFormats.years}
                validate={validators.required}
                name="year"
                type="select"
                onChange={onYearChange}
                label={t(vendor.year)}
                readOnly={readOnly}
                items={years}
            />
            <FormField
                disabled={disabled}
                useDiff={isDecisionRequired}
                type="select"
                name="month"
                onChange={onMonthChange}
                label={t(vendor.month)}
                readOnly={readOnly || !year}
                validate={validators.month}
                items={months.filter((x) => !year || isAccountingPeriodOpen(accountingPeriods, moment().year(year).month(x.value)))}
            />
        </>
    );
};

const QuarterlyDates = ({ form, isDecisionRequired, readOnly, disabled, accountingPeriods }) => {
    const { t } = useTranslation(["vendor", "common"]);
    const change = useChange(form);
    const { year, quater } = useFormValueSelector(form, ["year", "quater"], {});

    const onYearChange = (_, newYear) => {
        if (newYear !== year) {
            change("dateFrom", null);
            change("dateTo", null);
            change("quater", null);
            change("year", newYear);
        }
    };

    const onQuaterChange = (_, newQuater) => {
        if (newQuater !== quater) {
            const { dateFrom, dateTo } = quaterMap[newQuater](year);
            if (isQuaterlyAccountingPeriodOpen(accountingPeriods, dateFrom)) {
                change("dateFrom", dateFrom);
                change("dateTo", dateTo);
                change("quater", quater);
            }
        }
    };

    return (
        <>
            <FormField
                disabled={disabled}
                useDiff={isDecisionRequired}
                validate={validators.required}
                name="year"
                type="select"
                onChange={onYearChange}
                label={t(vendor.year)}
                readOnly={readOnly}
                items={years}
            />
            <FormField
                disabled={disabled}
                useDiff={isDecisionRequired}
                name="quater"
                type="select"
                onChange={onQuaterChange}
                label={t(vendor.quater)}
                readOnly={readOnly || !year}
                validate={validators.required}
                items={
                    readOnly || !year
                        ? quaters
                        : quaters.filter((x) => {
                              const dateFromQuater = quaterMap[x.value](year).dateFrom;

                              return isQuaterlyAccountingPeriodOpen(accountingPeriods, dateFromQuater);
                          })
                }
            />
        </>
    );
};

const SubsidiaryGainDates = ({ isDecisionRequired, readOnly, form, accountingPeriods }) => {
    const settlementPeriod = useFormValueSelector(form, "settlementPeriod");
    const innerSettlementPeriod = settlementPeriod?.proposal || settlementPeriod;
    const disabled = !innerSettlementPeriod;

    switch (innerSettlementPeriod) {
        case "Current":
            return (
                <CurrentDates
                    form={form}
                    isDecisionRequired={isDecisionRequired}
                    readOnly={readOnly}
                    disabled={disabled}
                    accountingPeriods={accountingPeriods}
                />
            );
        case "Monthly":
            return (
                <MonthlyDates
                    form={form}
                    isDecisionRequired={isDecisionRequired}
                    readOnly={readOnly}
                    disabled={disabled}
                    innerSettlementPeriod={innerSettlementPeriod}
                    accountingPeriods={accountingPeriods}
                />
            );
        case "Quarterly":
            return (
                <QuarterlyDates
                    form={form}
                    isDecisionRequired={isDecisionRequired}
                    readOnly={readOnly}
                    disabled={disabled}
                    accountingPeriods={accountingPeriods}
                />
            );
        default:
            return <></>;
    }
};

export default withFormName(SubsidiaryGainDates);
