import Button from "components/Button/Button";
import { renderFieldOrText } from "components/FormHelpers/Controls";
import { Field, reduxForm } from "redux-form";
import warehouses from "resource/warehouses.json";
import { withStyles } from "tss-react/mui";
import { useCommonTranslation } from "utils/hooks";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import moment from "moment";

const DaySelect = withStyles(
    ({ classes, handleSubmit, onSubmit, isLoading }) => {
        const { t, common } = useCommonTranslation();
        const deliveryWarehouses = [
            ...warehouses,
            { value: "WA2", name: "WA2", isActive: true },
            { value: "WA3", name: "WA3", isActive: true },
            { value: "WAW TOTAL", name: "WAW TOTAL", isActive: true },
        ].sort((a, b) => a.value.localeCompare(b.value));

        return (
            <form
                onSubmit={handleSubmit((values) => {
                    onSubmit(values);
                })}
            >
                <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Grid
                        item
                        xs={7}
                        className={classes.gridField}
                    >
                        <Field
                            name="day"
                            type="date"
                            label={t(common.data)}
                            component={renderFieldOrText}
                            fullWidth
                        />
                    </Grid>

                    <Grid
                        item
                        xs={2}
                    >
                        <Field
                            name="warehouse"
                            type="select"
                            label={t(common.warehouse)}
                            component={renderFieldOrText}
                            items={deliveryWarehouses.filter((w) => w.isActive)}
                            hideDefaultItem
                        />
                    </Grid>

                    <Grid
                        item
                        xs={2}
                    >
                        <Button
                            fullWidth
                            loading={isLoading}
                            actionType={"primary"}
                            type="submit"
                        >
                            {t(common.search)}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        );
    },
    (theme) => ({
        gridField: {
            margin: theme.spacing(2),
        },
    })
);

const mapStateToProps = (_) => {
    return {
        initialValues: {
            day: moment().startOf("day"),
            warehouse: "WAW",
        },
    };
};

export default connect(mapStateToProps)(
    reduxForm({
        form: "CalendarForm",
        enableReinitialize: true,
    })(DaySelect)
);
