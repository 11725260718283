import ArrayValidationError from "components/Controls/ArrayValidationError";
import ValueWithType from "components/Controls/ValueWithType";
import { FormField } from "components/Form";
import { FormSection } from "redux-form";
import { withFormName } from "utils/hoc";
import { Fragment } from "react";
import { Grid } from "@mui/material";
import { Adable, Erasable } from "components";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { validators } from "../../validators";
import ContractsAutocompletes from "./ContractsAutocompletes";

const discountSettlementMethod = [
    { value: "Front", name: "Front" },
    { value: "Retro", name: "Retro" },
];

const discountCountingMethod = [
    { value: "Total", name: "Sumarycznie" },
    { value: "Cascade", name: "Kaskadowo" },
];

const ProductDiscount = ({ fields, form, meta, readOnly, useDiff }) => {
    const { t } = useTranslation("vendor");

    return (
        <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
        >
            <ArrayValidationError {...meta} />
            <Adable
                handleAdd={() => {
                    fields.push({});
                }}
                renderCondition={!readOnly}
            >
                {fields.map((field, index) => {
                    return (
                        <Erasable
                            key={index}
                            handleRemove={() => {
                                fields.remove(index);
                            }}
                            renderCondition={!readOnly}
                        >
                            <FormSection
                                name={field}
                                component={Fragment}
                            >
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >
                                    <FormField
                                        useDiff={useDiff}
                                        name={"dateFrom"}
                                        label={t(vendor.dateFrom)}
                                        type="date"
                                        readOnly={readOnly}
                                        validate={validators.validateFrom}
                                    />

                                    <FormField
                                        useDiff={useDiff}
                                        name={"dateTo"}
                                        label={t(vendor.dateTo)}
                                        type="date"
                                        readOnly={readOnly}
                                        validate={validators.validateNullableDateTo}
                                    />
                                </Grid>

                                <Grid item>
                                    <FormField
                                        useDiff={useDiff}
                                        name={"description"}
                                        label={t(vendor.description)}
                                        type={"text"}
                                        readOnly={readOnly}
                                        multiline
                                        stretch
                                    />
                                </Grid>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >
                                    <ValueWithType
                                        useDiff={useDiff}
                                        readOnly={readOnly}
                                        names={{
                                            value: "discountValue",
                                            typeOfValue: "typeOfValue",
                                        }}
                                        labels={{
                                            typeOfValue: t(vendor.typeOfDiscount),
                                            value: t(vendor.discountValue),
                                        }}
                                        validate={{
                                            typeOfValue: validators.required,
                                            value: validators.discountValueWithType,
                                        }}
                                    />

                                    <FormField
                                        useDiff={useDiff}
                                        name={"discountSettlementMethod"}
                                        label={t(vendor.discountSettlementMethod)}
                                        type="select"
                                        hideDefaultItem
                                        items={discountSettlementMethod}
                                        readOnly={readOnly}
                                        validate={validators.required}
                                    />

                                    <FormField
                                        useDiff={useDiff}
                                        name={"discountCountingMethod"}
                                        label={t(vendor.discountCountingMethod)}
                                        type="select"
                                        hideDefaultItem
                                        items={discountCountingMethod}
                                        readOnly={readOnly}
                                        validate={validators.required}
                                    />
                                </Grid>
                                <ContractsAutocompletes
                                    readOnly={readOnly}
                                    useDiff={useDiff}
                                />
                            </FormSection>
                        </Erasable>
                    );
                })}
            </Adable>
        </Grid>
    );
};

export default withFormName(ProductDiscount);
