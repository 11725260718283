import { createSelector } from "reselect";

const root = (state) => ({
    deferredPayments: state.deferredPayments,
});

export const deferredPaymentsListSelector = createSelector(root, (root) => {
    return {
        list: {
            ...root.deferredPayments.list,
            items: root.deferredPayments.list.items,
        },
    };
});

export const deferredPaymentsFormSelector = createSelector(root, (root) => {
    return { form: root.deferredPayments.form };
});
