import Button from "components/Button/Button";
import { AcceptRejectDialog } from "components/Dialog";
import GridContainer from "components/Grid/GridContainer";
import TextWithLabel from "components/Text/TextWithLabel";
import { B2BDepartmentRoles, DebtCoordinatorRoles } from "consts/roles";
import { b2bUserNames, getUserInfoWithRoles } from "store/autocomplete";
import { actions } from "store/customers/customer/action";
import { actions as profileActions } from "store/customers/customer/customerProfileActions/action";
import { formatTypes } from "utils/formating";
import { withAuthorizedDisplay } from "utils/hoc";
import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { For } from "components";
import { useUser } from "context";
import { common, customers } from "translations";
import { usePush, useTranslation } from "utils-ts/hooks";
import DefferredPaymentDialog from "./DefferredPaymentDialog";

const CustomerType = ({
    customerId,
    profileType,
    accountManager = "none",
    retentionManager = "none",
    creditLimit,
    haveBillingAddress,
    deferredPayment,
    customerInfo,
}) => {
    const { isInAnyRoleOrAdmin } = useUser();
    const dispatch = useDispatch();
    const { push } = usePush();
    const [selectedAccountType, setSelectedAccountType] = useState(undefined);
    const [selectedManager, setSelectedManager] = useState(undefined);
    const [deferredPaymentSettingsOpen, setDeferredPaymentSettingsOpen] = useState(false);
    const [deferredPaymentRemoveOpen, setDeferredPaymentRemoveOpen] = useState(false);
    const { t } = useTranslation(["customers", "common"]);
    const isOffice = profileType === "Office";

    useEffect(() => {
        if (isOffice) {
            dispatch(getUserInfoWithRoles(["BusinessSupport", "BusinessSupportManager"]));
        }
    }, [dispatch, isOffice]);

    const b2bUsers = useSelector(b2bUserNames);
    const accountTypes = useMemo(
        () => [
            {
                value: "Home",
                disabled: Boolean(!deferredPayment && accountManager !== "none"),
            },
            { value: "Office", disabled: !haveBillingAddress },
        ],
        [accountManager, deferredPayment, haveBillingAddress]
    );

    const creditLimitFields = [
        {
            label: t(common.creditAmount),
            value: creditLimit?.creditAmount,
            format: formatTypes.text,
        },
        {
            label: t(common.paymentTerm),
            value: creditLimit?.paymentTerm,
            format: formatTypes.text,
        },
    ];

    return (
        <>
            <GridContainer
                iconName="merge_type"
                title={`customers:${customers.accountType}`}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <FormControl style={{ width: 200, marginLeft: 10 }}>
                    <InputLabel>{t(customers.accountType)}</InputLabel>
                    <Select
                        variant="standard"
                        readOnly={!isInAnyRoleOrAdmin(B2BDepartmentRoles)}
                        value={profileType}
                        onChange={(event) => {
                            const value = event?.target?.value;
                            if (value !== profileType) {
                                setSelectedAccountType(value);
                            }
                        }}
                    >
                        {accountTypes.map((type) => {
                            return (
                                <MenuItem
                                    key={type.value}
                                    {...type}
                                >
                                    {type.value}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                {isOffice && (
                    <>
                        <FormControl style={{ width: 200, marginLeft: 10 }}>
                            <InputLabel>{t(customers.accountManager)}</InputLabel>
                            <Select
                                variant="standard"
                                readOnly={!isInAnyRoleOrAdmin(B2BDepartmentRoles)}
                                value={accountManager}
                                onChange={(event) => {
                                    const value = event?.target?.value;
                                    if (value !== accountManager) {
                                        setSelectedManager({
                                            newValue: value,
                                            managerType: "konta",
                                        });
                                    }
                                }}
                            >
                                {[
                                    ...b2bUsers.filter((o) => o.isActive || o.value == accountManager),
                                    { name: "Brak", value: "none", isActive: true },
                                ].map((user) => {
                                    return (
                                        <MenuItem
                                            key={user.value}
                                            value={user.value}
                                        >
                                            {user.name + (user.isActive ? "" : " (nieaktywny)")}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <FormControl style={{ width: 200, marginLeft: 10 }}>
                            <InputLabel>{t(customers.retentionManager)}</InputLabel>
                            <Select
                                variant="standard"
                                readOnly={!isInAnyRoleOrAdmin(B2BDepartmentRoles)}
                                value={retentionManager}
                                onChange={(event) => {
                                    const value = event?.target?.value;
                                    if (value !== retentionManager) {
                                        setSelectedManager({
                                            newValue: value,
                                            managerType: "retencji",
                                        });
                                    }
                                }}
                            >
                                {[
                                    ...b2bUsers.filter((o) => o.isActive || o.value == retentionManager),
                                    { name: "Brak", value: "none", isActive: true },
                                ].map((user) => {
                                    return (
                                        <MenuItem
                                            key={user.value}
                                            value={user.value}
                                        >
                                            {user.name + (user.isActive ? "" : " (nieaktywny)")}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </>
                )}
                {isOffice && (
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <Grid
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            container
                        >
                            <For
                                of={creditLimitFields}
                                render={(item) => (
                                    <Grid
                                        item
                                        key={item.label}
                                    >
                                        <TextWithLabel
                                            unselectableLabel
                                            {...item}
                                        />
                                    </Grid>
                                )}
                            />
                            <Button
                                disabled={!isInAnyRoleOrAdmin(B2BDepartmentRoles)}
                                variant="contained"
                                onClick={() => {
                                    setDeferredPaymentSettingsOpen(true);
                                }}
                                color="primary"
                            >
                                {t(`common:${common.deferredPaymentSettings}`)}
                            </Button>
                            <Button
                                disabled={!isInAnyRoleOrAdmin(DebtCoordinatorRoles) || !creditLimit?.creditAmount}
                                variant="contained"
                                onClick={() => {
                                    setDeferredPaymentRemoveOpen(true);
                                }}
                                actionType="decline"
                            >
                                {t(`common:${common.deferredPaymentRemove}`)}
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </GridContainer>
            <AcceptRejectDialog
                maxWidth="sm"
                title={t(customers.changeAccountType)}
                open={Boolean(selectedAccountType)}
                handleAccept={() => {
                    dispatch(
                        profileActions.editCustomer(customerId, {
                            ...customerInfo,
                            initialEmail: customerInfo.email,
                            profileType: selectedAccountType,
                        })
                    )
                        .then(() => {
                            push(`/customers/${customerId}`);
                            dispatch(actions.getCustomer(customerId));
                        })
                        .catch((o) => {
                            console.log(o);
                        });
                    setSelectedAccountType(undefined);
                }}
                handleReject={() => {
                    setSelectedAccountType(undefined);
                }}
            >
                {t(`common:${common.areYouSure}`, {
                    what: t(customers.changeAccountTypeQuestion),
                })}
            </AcceptRejectDialog>
            <AcceptRejectDialog
                maxWidth="sm"
                title={t(customers.changeManager, {
                    managerType: selectedManager?.managerType,
                })}
                open={selectedManager !== undefined}
                handleAccept={async () => {
                    if (selectedManager?.managerType === "retencji") {
                        await dispatch(
                            profileActions.setRetentionManager(customerId, selectedManager.newValue === "none" ? null : selectedManager.newValue)
                        );
                    } else {
                        await dispatch(
                            profileActions.setAccountManager(customerId, selectedManager.newValue === "none" ? null : selectedManager.newValue)
                        );
                    }

                    setSelectedManager(undefined);
                    await dispatch(actions.getCustomer(customerId));
                }}
                handleReject={() => {
                    setSelectedManager(undefined);
                }}
            >
                {t(`common:${common.areYouSure}`, {
                    what:
                        !selectedManager?.newValue || selectedManager.newValue === "none"
                            ? t(customers.changeManagerQuestionToNone, {
                                  managerType: selectedManager?.managerType,
                              })
                            : t(customers.changeManagerQuestion, {
                                  manager: selectedManager.newValue,
                                  managerType: selectedManager?.managerType,
                              }),
                })}
            </AcceptRejectDialog>
            <DefferredPaymentDialog
                creditLimit={creditLimit}
                open={deferredPaymentSettingsOpen}
                handleAccept={async (val) => {
                    await dispatch(profileActions.changeCreditLimit(customerId, val));
                    setDeferredPaymentSettingsOpen(false);
                    dispatch(actions.getCustomer(customerId));
                }}
                handleReject={() => {
                    setDeferredPaymentSettingsOpen(false);
                }}
            />
            <AcceptRejectDialog
                maxWidth="sm"
                title={t(customers.removeDeferredPayment)}
                open={deferredPaymentRemoveOpen}
                handleAccept={async () => {
                    await dispatch(profileActions.changeCreditLimit(customerId, {}));
                    setDeferredPaymentRemoveOpen(false);
                    dispatch(actions.getCustomer(customerId));
                }}
                handleReject={() => {
                    setDeferredPaymentRemoveOpen(false);
                }}
            >
                {t(`common:${common.areYouSure}`, {
                    what: t(customers.removeDeferredPaymentQuestion),
                })}
            </AcceptRejectDialog>
        </>
    );
};

export default connect((state, ownProps) => {
    const { customerInfo } = state?.customer;
    const { creditLimit } = ownProps;
    const deferredPayment = creditLimit?.creditAmount === undefined ? false : creditLimit?.creditAmount > 0;

    return {
        profileType: customerInfo.profileType,
        deferredPayment,
        customerInfo,
    };
})(withAuthorizedDisplay(CustomerType));
