import { Button } from "components/Button";
import { useCommonTranslation } from "utils/hooks";
import React, { Fragment } from "react";

const Form = ({ children, onSubmit, isReadonly, isSubbmitRequested = false, pristine = false, submitButtonText, preSubmit }) => {
    const { t, common } = useCommonTranslation();
    return (
        <form
            onSubmit={async (event) => {
                event.persist();
                event.preventDefault();
                if (preSubmit) {
                    await preSubmit();
                }

                onSubmit(event);
            }}
        >
            {children}
            {!isReadonly ? (
                <Button
                    actionType="accept"
                    type="submit"
                    fullWidth
                    loading={isSubbmitRequested}
                    disabled={pristine}
                >
                    {submitButtonText || t(common.save)}
                </Button>
            ) : (
                <Fragment />
            )}
        </form>
    );
};

export default Form;
