import { IconButton } from "components/Button";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
    parent: {
        position: "relative",
    },
    iconContainer: {
        position: "absolute",
        top: 0,
        right: 0,
    },
});

const Erasable = ({ renderCondition = true, handleRemove, children, readOnly = false }) => {
    const { classes } = useStyles();

    if (!renderCondition) {
        return children;
    }

    return (
        <div className={classes.parent}>
            <div className={classes.iconContainer}>
                {!readOnly && (
                    <IconButton
                        icon="delete"
                        onClick={handleRemove}
                    />
                )}
            </div>
            {children}
        </div>
    );
};

export default Erasable;
