import { FormField } from "components/Form";
import { contentCategoriesSelector, getContentCategories as getContentCategoriesAction } from "store/autocomplete";
import { useCommonTranslation } from "utils/hooks";
import { useSelector } from "react-redux";
import { createFilterOptions } from "@mui/material/Autocomplete";

const filter = createFilterOptions();

const ContentCategoriesAutocomplete = ({ name = "categoryName", customInput = true, freeSolo = true, ...props }) => {
    const { t, common } = useCommonTranslation();
    const contentCategories = useSelector(contentCategoriesSelector);

    return (
        <FormField
            freeSolo={freeSolo}
            selectOnFocus
            clearOnBlur
            {...props}
            fullWidth
            name={name}
            type={"autocomplete"}
            label={t(common.categoryName)}
            items={contentCategories}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (customInput && params.inputValue !== "") {
                    filtered.push(params.inputValue);
                }

                return filtered;
            }}
        />
    );
};

export default ContentCategoriesAutocomplete;
