import { RSAA } from "redux-api-middleware";
import { commerce, operations } from "store/actionTypePrefixes";
import { displayMessage } from "store/messaging/actions";
import { invoice } from "utils/dictionaryTypes/documentType";
import { asAsyncFormTypes } from "utils/extensions/arrayExtensions";
import { downloadPdfFile, rsaaActionType } from "utils/rsaa/downloadFileAction";
import _ from "lodash";

export const types = {
    ORDERS_GET_ORDER: `${operations}/ORDERS_GET_ORDER`,
    ORDERS_GET_ORDER_SUCCESS: `${operations}/ORDERS_GET_ORDER_SUCCESS`,
    ORDERS_GET_ORDER_FAILURE: `${operations}/ORDERS_GET_ORDER_FAILURE`,
    ORDER_GET_COMPLETE_ORDER: `${operations}/ORDER_GET_COMPLETE_ORDER`,
    ORDER_GET_DOCUMENT: `${operations}/ORDER_GET_DOCUMENT`,
    ORDER_GET_DOCUMENT_SUCCESS: `${operations}/ORDER_GET_DOCUMENT_SUCCESS`,
    ORDER_GET_DOCUMENT_FAILURE: `${operations}/ORDER_GET_DOCUMENT_FAILURE`,
    ORDER_GET_ORDER_VOUCHER_INFO: `${commerce}/ORDER_GET_ORDER_VOUCHER_INFO`,
    ORDER_GET_ORDER_VOUCHER_INFO_SUCCESS: `${commerce}/ORDER_GET_ORDER_VOUCHER_INFO_SUCCESS`,
    ORDER_GET_ORDER_VOUCHER_INFO_FAILURE: `${commerce}/ORDER_GET_ORDER_VOUCHER_INFO_FAILURE`,
    ORDER_CANCEL: `${commerce}/ORDER_CANCEL`,
    ORDER_CANCEL_SUCCESS: `${commerce}/ORDER_CANCEL_SUCCESS`,
    ORDER_CANCEL_FAILURE: `${commerce}/ORDER_CANCEL_FAILURE`,
    ORDER_ABORT: `${commerce}/ORDER_ABORT`,
    ORDER_ABORT_SUCCESS: `${commerce}/ORDER_ABORT_SUCCESS`,
    ORDER_ABORT_FAILURE: `${commerce}/ORDER_ABORT_FAILURE`,
    ORDER_CREATE_ABORT_COMPLAINT_REQUEST: `${operations}/ORDER_CREATE_ABORT_COMPLAINT_REQUEST`,
    ORDER_CREATE_ABORT_COMPLAINT_SUCCESS: `${operations}/ORDER_CREATE_ABORT_COMPLAINT_SUCCESS`,
    ORDER_CREATE_ABORT_COMPLAINT_FAILURE: `${operations}/ORDER_CREATE_ABORT_COMPLAINT_FAILURE`,
};

const getOrder = (customerId, orderNumber, complaintNumber) => async (dispatch) => {
    const order = await dispatch({
        [RSAA]: {
            endpoint: `api/users/${customerId}/orders/${orderNumber}`,
            method: "POST",
            headers: { "Content-Type": "application/json" },
            types: [
                `${operations}/ORDERS_GET_ORDER`,
                `${operations}/ORDERS_GET_ORDER_SUCCESS`,
                {
                    type: `${operations}/ORDERS_GET_ORDER_FAILURE`,
                    meta: { hideError: true },
                },
            ],
            body: JSON.stringify(complaintNumber || ""),
        },
    });

    return order;
};

const getCompleteOrder = (customerId, orderNumber) => async (dispatch) => {
    let { payload: order } = await dispatch({
        [RSAA]: {
            endpoint: `api/v1/users/${customerId}/orders/${orderNumber}`,
            method: "GET",
            types: [types.ORDER_GET_ORDER_VOUCHER_INFO, types.ORDER_GET_ORDER_VOUCHER_INFO_SUCCESS, types.ORDER_GET_ORDER_VOUCHER_INFO_FAILURE],
        },
    });

    const { products: lines = [], totals = {} } = order;
    const { promotions } = totals;

    let operationsOrder = {};
    if (order.reservation?.deliveryWindow?.warehouse !== "OMU") {
        const { payload } = await dispatch(getOrder(customerId, orderNumber));
        operationsOrder = payload;
    }

    const { documents = [] } = operationsOrder || {};
    const invoiceLines = documents
        .filter((x) => x.documentType === invoice)
        .map((x) => x.lines)
        .reduce((c, n) => [...c, ...n], []);

    const products = lines.map((line) => {
        return {
            ...line,
            productName: `${line?.product?.brand || ""} ${line?.product?.name?.pl || ""}`,
            quantityInvoice: line.invoiceQuantity,
            validityPeriod: line.validityPeriod,
            productId: line.productId,
            priceBeforeVoucher: line.priceBeforeVoucher,
            diffrence: line.priceBeforeVoucher - line.price || "",
            grossPriceInvoice: line.invoicePrice,
            totalInvoice: line.invoiceTotal,
            grammage: invoiceLines.find((x) => String(x.productId).padStart(6, "0") === String(line.productId).padStart(6, "0"))?.grammage,
            grossPrice: line.price,
            grossTotal: line.price * line.quantity || "",
            campaigns: _.uniq(
                [
                    line.campaignName,
                    ...line.effects.map((l) => l.campaignName),
                    ...line.product.promotions.map((l) => l.campaignName),
                    ...line.vouchers.map((l) => l.voucherId),
                ].filter(Boolean)
            ),
        };
    });
    const pennyLines = products.filter((x) => x.grossPriceInvoice <= 0.01 || !x.grossPriceInvoice);
    const otherLines = products.filter((x) => x.grossPriceInvoice && x.grossPriceInvoice > 0.01);

    const productLines = [...otherLines, ...pennyLines];

    const { deliveryWindow, isDeliveryAdjusted, extendedRange } = order?.reservation;

    dispatch({
        type: types.ORDER_GET_COMPLETE_ORDER,
        payload: {
            ...order,
            user: {
                userId: order.userId,
                fullName: order.fullName,
                phoneNumber: order.phoneNumber,
                email: order.email,
            },
            ...order.payments,
            ...order.totals,
            membershipPoints: order.totals.membershipPoints,
            placedAt: order.placedAt,
            delivery:
                isDeliveryAdjusted !== true && extendedRange != null
                    ? {
                          ...deliveryWindow,
                          startsAt: extendedRange.startsAt,
                          endsAt: extendedRange.endsAt,
                          isFlexibleSlots: true,
                      }
                    : deliveryWindow,
            isEarlyEntranceAllowed: order.isEarlyEntranceAllowed,
            deliveryMethod: deliveryWindow?.deliveryMethod,
            packagingMethod: order.packagingMethod,
            paymentMethod: order.paymentMethod,
            orderDocuments: (order.documents || [])
                .filter((x) => x.documentReference)
                .map((x) => ({
                    ...x.documentReference,
                    blobName: x.blobReference?.blobName,
                })),
            orderInvoices: order.documents
                .filter((x) => x.documentReference?.documentType === invoice)
                .map((x) => ({
                    documentNumber: x.documentReference.documentNumber,
                    merchant: x.documentReference.merchant,
                })),
            vouchers: order.vouchers,
            productLines: productLines,
            warehouse: deliveryWindow.warehouse,
            promotions: [
                ...promotions,
                ..._(lines)
                    .flatMap((line) =>
                        line?.product?.promotions.map((p) => ({
                            ...p,
                            promotionId: p.id,
                        }))
                    )
                    .uniqBy((x) => x.promotionId)
                    .value(),
            ],
            operationsOrderId: operationsOrder.id,
            operationsDocuments: documents,
        },
    });

    return Promise.resolve();
};

const getOrderDocument = (customerId, orderId, documentId, documentNumber) => async (dispatch) => {
    await dispatch(
        downloadPdfFile(
            `api/users/${customerId}/orders/${orderId}/get-order-document/${documentId}`,
            rsaaActionType(types.ORDER_GET_DOCUMENT, types.ORDER_GET_DOCUMENT_SUCCESS, types.ORDER_GET_DOCUMENT_FAILURE),
            documentNumber
        )
    );

    return Promise.resolve();
};

const cancelOrAbortOrder = (customerId, orderNumber, cancelMethod, operationsOrderId, createdBy, reason) => async (dispatch) => {
    if (cancelMethod === "abort") {
        return await dispatch({
            [RSAA]: {
                headers: { "Content-Type": "application/json" },
                endpoint: `api/v1/users/${customerId}/orders/${orderNumber}/abort`,
                method: "POST",
                types: [
                    types.ORDER_ABORT,
                    {
                        type: types.ORDER_ABORT_SUCCESS,
                        payload: (_, __, res) => {
                            dispatch(
                                displayMessage({
                                    message: `Wycofano zamówienie: ${customerId}/${orderNumber}.`,
                                    variant: "success",
                                })
                            );
                            dispatch(createOrderAbortComplaint(res, customerId, operationsOrderId, createdBy));
                        },
                    },
                    types.ORDER_ABORT_FAILURE,
                ],
                body: JSON.stringify({
                    reason,
                }),
            },
        });
    } else {
        return await dispatch({
            [RSAA]: {
                endpoint: `api/v1/users/${customerId}/orders/${orderNumber}?isSilent=${cancelMethod === "cancelSilently"}`,
                method: "DELETE",
                types: asAsyncFormTypes([types.ORDER_CANCEL, types.ORDER_CANCEL_SUCCESS, types.ORDER_CANCEL_FAILURE]),
            },
        });
    }
};

const createOrderAbortComplaint = (res, customerId, orderId, createdBy) => (dispatch) => {
    if (res.ok === true && orderId) {
        dispatch({
            [RSAA]: {
                endpoint: `api/users/${customerId}/complaints/${orderId}`,
                method: "POST",
                headers: { "Content-Type": "application/json" },
                types: [
                    types.ORDER_CREATE_ABORT_COMPLAINT_REQUEST,
                    {
                        type: types.ORDER_CREATE_ABORT_COMPLAINT_SUCCESS,
                        payload: () => {
                            dispatch(
                                displayMessage({
                                    message: "Utworzono korektę do wycofanego zamówienie.",
                                    variant: "success",
                                })
                            );
                        },
                    },
                    {
                        type: types.ORDER_CREATE_ABORT_COMPLAINT_FAILURE,
                        meta: {
                            hideError: true,
                        },
                        payload: () => {
                            dispatch(
                                displayMessage({
                                    message: "Nie udało utworzyć się korekty anulującej zamówienie! Potrzebne jest utworznie korekty ręcznie.",
                                    variant: "error",
                                })
                            );
                        },
                    },
                ],
                body: JSON.stringify({ createdBy }),
            },
        });
    }
};

export const actions = {
    getCompleteOrder,
    getOrderDocument,
    cancelOrAbortOrder,
};
