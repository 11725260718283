import roles from "consts/roles";
import { RSAA } from "redux-api-middleware";
import downloadFile, { rsaaActionType } from "utils/rsaa/downloadFileAction";

export const PRODUCT_INIT_GET_DETAILS = "@@operations/PRODUCT_INIT_GET_DETAILS";
export const PRODUCT_INIT_GET_DETAILS_SUCCESS = "@@operations/PRODUCT_INIT_GET_DETAILS_SUCCESS";
export const PRODUCT_INIT_GET_DETAILS_FAILURE = "@@operations/PRODUCT_INIT_GET_DETAILS_FAILURE";

export const PRODUCT_INIT_GET_CONTENT = "@@content/PRODUCT_INIT_GET_CONTENT";
export const PRODUCT_INIT_GET_CONTENT_SUCCESS = "@@content/PRODUCT_INIT_GET_CONTENT_SUCCESS";
export const PRODUCT_INIT_GET_CONTENT_FAILURE = "@@content/PRODUCT_INIT_GET_CONTENT_FAILURE";

export const PRODUCT_INIT_FILL_CONTENT = "@@content/PRODUCT_INIT_FILL_CONTENT";
export const PRODUCT_INIT_FILL_CONTENT_SUCCESS = "@@content/PRODUCT_INIT_FILL_CONTENT_SUCCESS";
export const PRODUCT_INIT_FILL_CONTENT_FAILURE = "@@content/PRODUCT_INIT_FILL_CONTENT_FAILURE";

export const PRODUCT_INIT_FILL_OPERATIONS = "@@operations/PRODUCT_INIT_FILL_OPERATIONS";
export const PRODUCT_INIT_FILL_OPERATIONS_SUCCESS = "@@operations/PRODUCT_INIT_FILL_OPERATIONS_SUCCESS";
export const PRODUCT_INIT_FILL_OPERATIONS_FAILURE = "@@operations/PRODUCT_INIT_FILL_OPERATIONS_FAILURE";

export const PRODUCT_INIT_REVIEW = "@@operations/PRODUCT_INIT_REVIEW";
export const PRODUCT_INIT_REVIEW_SUCCESS = "@@operations/PRODUCT_INIT_REVIEW_SUCCESS";
export const PRODUCT_INIT_REVIEW_FAILURE = "@@operations/PRODUCT_INIT_REVIEW_FAILURE";

export const PRODUCT_INIT_ADD_FILE = "@@operations/PRODUCT_INIT_UPLOAD_FILE";
export const PRODUCT_INIT_REMOVE_FILE = "@@operations/PRODUCT_INIT_REMOVE_FILE";

export const PRODUCT_INIT_DOWNLOAD_FILE = "@@operations/PRODUCT_INIT_DOWNLOAD_FILE";
export const PRODUCT_INIT_DOWNLOAD_FILE_SUCCESS = "@@operations/PRODUCT_INIT_DOWNLOAD_FILE_SUCCESS";
export const PRODUCT_INIT_DOWNLOAD_FILE_FAILURE = "@@operations/PRODUCT_INIT_DOWNLOAD_FILE_FAILURE";

export const PRODUCT_INIT_AUDIT = "@@operations/PRODUCT_INIT_AUDIT";
export const PRODUCT_INIT_AUDIT_SUCCESS = "@@operations/PRODUCT_INIT_AUDIT_SUCCESS";
export const PRODUCT_INIT_AUDIT_FAILURE = "@@operations/PRODUCT_INIT_AUDIT_FAILURE";

export const INIT_PRODUCT_UPDATE_IMAGES = "@@content/INIT_PRODUCT_UPDATE_IMAGES";
export const INIT_PRODUCT_UPDATE_IMAGES_SUCCESS = "@@content/INIT_PRODUCT_UPDATE_IMAGES_SUCCESS";
export const INIT_PRODUCT_UPDATE_IMAGES_FAILURE = "@@content/INIT_PRODUCT_UPDATE_IMAGES_FAILURE";

export const INIT_PRODUCT_UPLOAD_FILE = "@@operations/INIT_PRODUCT_UPLOAD_FILE";
export const INIT_PRODUCT_UPLOAD_FILE_SUCCESS = "@@operations/INIT_PRODUCT_UPLOAD_FILE_SUCCESS";
export const INIT_PRODUCT_UPLOAD_FILE_FAILURE = "@@operations/INIT_PRODUCT_UPLOAD_FILE_FAILURE";

export const INIT_PRODUCT_DELETE_FILE = "@@operations/INIT_PRODUCT_DELETE_FILE";
export const INIT_PRODUCT_DELETE_FILE_SUCCESS = "@@operations/INIT_PRODUCT_DELETE_FILE_SUCCESS";
export const INIT_PRODUCT_DELETE_FILE_FAILURE = "@@operations/INIT_PRODUCT_DELETE_FILE_FAILURE";

export const GET_MANUFACTURED_PRODUCT_NAME = "@@operations/GET_MANUFACTURED_PRODUCT_NAME";
export const GET_MANUFACTURED_PRODUCT_NAME_SUCCESS = "@@operations/GET_MANUFACTURED_PRODUCT_NAME_SUCCESS";
export const GET_MANUFACTURED_PRODUCT_NAME_FAILURE = "@@operations/GET_MANUFACTURED_PRODUCT_NAME_FAILURE";

export const CHANGE_VARIANT = "INIT_PRODUCT_CHANGE_VARIANT";

export const SAVE_STARTED = "SAVE_STARTED";
export const SAVE_COMPLETED = "SAVE_COMPLETED";

export const changeVariant = (variantId) => ({
    type: CHANGE_VARIANT,
    payload: variantId,
});

export const downloadInitProductFile = (initProductId, fileId, fileType, fileName, role, vendorId) => {
    let endpoint = "";
    const fileIdEncoded = encodeURI(fileId);
    switch (role) {
        case roles.Auditor:
        case roles.MasterData: {
            endpoint = `api/auditors/init-products/${initProductId}/${fileIdEncoded}`;
            break;
        }
        case roles.Vendor: {
            endpoint = `api/vendors/${vendorId}/init-products/${initProductId}/${fileIdEncoded}`;
            break;
        }
        default: {
            endpoint = `api/products/init/${initProductId}/${fileIdEncoded}`;
            break;
        }
    }
    return (dispatch) => {
        dispatch(
            downloadFile(
                endpoint,
                { header: fileType, extension: undefined },
                rsaaActionType(
                    {
                        type: PRODUCT_INIT_DOWNLOAD_FILE,
                        meta: {
                            ignoreInProgress: true,
                        },
                    },
                    {
                        type: PRODUCT_INIT_DOWNLOAD_FILE_SUCCESS,
                        meta: {
                            ignoreInProgress: true,
                        },
                    },
                    {
                        type: PRODUCT_INIT_DOWNLOAD_FILE_FAILURE,
                        meta: {
                            ignoreInProgress: true,
                        },
                    }
                ),
                fileName
            )
        );
    };
};

export const uploadFile = (blobs) => ({
    type: PRODUCT_INIT_ADD_FILE,
    payload: blobs,
});

export const removeFile = (fileId) => ({
    type: PRODUCT_INIT_REMOVE_FILE,
    payload: fileId,
});

export const review = (vendorId, productId, role) => {
    let endpoint = "";
    switch (role) {
        case roles.Vendor: {
            endpoint = `api/vendors/${vendorId}/init-products/${productId}`;
            break;
        }
        default: {
            endpoint = `api/products/init/${productId}`;
            break;
        }
    }
    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: endpoint,
                method: "POST",
                body: JSON.stringify({}),
                types: [
                    PRODUCT_INIT_REVIEW,
                    {
                        type: PRODUCT_INIT_REVIEW_SUCCESS,
                        payload: (action, state, res) => {
                            return res;
                        },
                    },
                    {
                        type: PRODUCT_INIT_REVIEW_FAILURE,
                        payload: (action, state, res) => {
                            return res;
                        },
                    },
                ],
            },
        });
    };
};

export const fillContent = (vendorId, productId, attributes, variants, role) => (dispatch) => {
    let endpoint = "";
    switch (role) {
        case roles.Vendor: {
            endpoint = `api/v1/vendors/ext/${vendorId}/init-products/${productId}`;
            break;
        }
        default: {
            endpoint = `api/v1/products/init/${productId}`;
            break;
        }
    }

    const remapedAttributes = attributes
        .map((x) => {
            if (x.name !== "Obliczona wartość odżywcza") {
                return x;
            }

            const { subAttributes, ...rest } = x;

            const anyDecimalHaveValue = subAttributes.filter((x) => x.type === "decimal").some((x) => x.value);

            return anyDecimalHaveValue ? { ...rest, subAttributes } : undefined;
        })
        .filter(Boolean);

    return dispatch({
        [RSAA]: {
            endpoint: endpoint,
            headers: { "Content-Type": "application/json" },
            method: "PUT",
            body: JSON.stringify({
                attributes: remapedAttributes,
                variants,
            }),
            types: [
                PRODUCT_INIT_FILL_CONTENT,
                {
                    type: PRODUCT_INIT_FILL_CONTENT_SUCCESS,
                    payload: (action, state, res) => {
                        return res;
                    },
                },
                {
                    type: PRODUCT_INIT_FILL_CONTENT_FAILURE,
                    payload: (action, state, res) => {
                        return res;
                    },
                },
            ],
        },
    });
};

export const fillOperations = (vendorId, productId, productModel, readOnlyAttributes, variants, role, vendors) => {
    let endpoint = "";
    switch (role) {
        case roles.Vendor: {
            endpoint = `api/vendors/${vendorId}/init-products/${productId}`;
            break;
        }
        case roles.Auditor:
        case roles.MasterData: {
            endpoint = `api/auditors/init-products/${productId}/correct-vat-stake`;
            break;
        }
        default: {
            endpoint = `api/products/init/${productId}`;
            break;
        }
    }
    const selectedWarehouses = Object.entries(productModel)
        .filter(([key, value]) => key.startsWith("warehouse|") && key.endsWith("|isSold") && value)
        .map(([key, _]) => key.split("|")[1]);

    const suppliersPerWarehouse = Object.fromEntries(
        selectedWarehouses.map((x) => [
            x,
            {
                supplierId: productModel[`warehouse|${x}|supplier`],
                supplierName: vendors.find((y) => y.vendorId === productModel[`warehouse|${x}|supplier`])?.name,
            },
        ])
    );
    const warehouseStores = Object.fromEntries(selectedWarehouses.map((x) => [x, productModel[`warehouse|${x}|store`]]));
    const manufacturedWarehouses = selectedWarehouses.filter((x) => productModel[`warehouse|${x}|isManufacturedProduct`]);

    return (dispatch) => {
        return dispatch({
            [RSAA]: {
                endpoint: endpoint,
                headers: { "Content-Type": "application/json" },
                method: "PUT",
                body: JSON.stringify({
                    ...productModel,
                    defaultSupplier: vendors?.find((x) => x.vendorId === productModel["defaultSupplier"])?.name,
                    defaultSupplierId: productModel["defaultSupplier"],
                    producer: vendors?.find((x) => x.vendorId === productModel["producerId"])?.name,
                    suppliersPerWarehouse,
                    warehouses: selectedWarehouses,
                    warehouseStores,
                    readOnlyAttributes,
                    variants,
                    cnCode: productModel.cnCode && productModel.cnCode.includes("-") ? productModel.cnCode.split(" - ")[0] : productModel.cnCode,
                    analiticGroup:
                        productModel.analiticGroup && productModel.analiticGroup.includes("-")
                            ? productModel.analiticGroup.split(" - ")[0]
                            : productModel.analiticGroup,
                    manufacturedWarehouses,
                }),
                types: [
                    PRODUCT_INIT_FILL_OPERATIONS,
                    {
                        type: PRODUCT_INIT_FILL_OPERATIONS_SUCCESS,
                        payload: (action, state, res) => {
                            return res;
                        },
                    },
                    {
                        type: PRODUCT_INIT_FILL_OPERATIONS_FAILURE,
                        payload: (action, state, res) => {
                            return res;
                        },
                    },
                ],
            },
        });
    };
};

export const auditInitProduct = (initProductId, comment, isAccepted, role) => {
    let endpoint = "";
    switch (role) {
        case roles.Auditor:
        case roles.MasterData: {
            endpoint = `api/auditors/init-products/${initProductId}`;
            break;
        }
        default: {
            endpoint = `api/products/init/${initProductId}/review`;
            break;
        }
    }

    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: endpoint,
                headers: { "Content-Type": "application/json" },
                method: "POST",
                body: JSON.stringify({
                    comment,
                    isRejected: !isAccepted,
                }),
                types: [
                    PRODUCT_INIT_AUDIT,
                    {
                        type: PRODUCT_INIT_AUDIT_SUCCESS,
                        payload: (action, state, res) => {
                            return res;
                        },
                    },
                    {
                        type: PRODUCT_INIT_AUDIT_FAILURE,
                        payload: (action, state, res) => {
                            return res;
                        },
                    },
                ],
            },
        });
    };
};

export const getProductContent = (initProductId, role) => {
    let endpoint = "";
    switch (role) {
        case roles.Auditor:
        case roles.MasterData: {
            endpoint = `api/v1/auditors/init-products/${initProductId}`;
            break;
        }
        case roles.Vendor: {
            endpoint = `api/v1/vendors/ext/init-products/${initProductId}`;
            break;
        }
        default: {
            endpoint = `api/v1/products/init/${initProductId}`;
            break;
        }
    }

    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: endpoint,
                headers: { "Content-Type": "application/json" },
                method: "GET",
                types: [
                    {
                        type: PRODUCT_INIT_GET_CONTENT,
                        meta: {
                            hideError: true,
                        },
                    },
                    {
                        type: PRODUCT_INIT_GET_CONTENT_SUCCESS,
                        meta: {
                            hideError: true,
                        },
                    },
                    {
                        type: PRODUCT_INIT_GET_CONTENT_FAILURE,
                        meta: {
                            hideError: true,
                        },
                    },
                ],
            },
        });
    };
};

export const getProduct = (initProductId, role) => async (dispatch) => {
    let endpoint = "";
    switch (role) {
        case roles.Auditor:
        case roles.MasterData: {
            endpoint = `api/auditors/init-products/${initProductId}`;
            break;
        }
        case roles.Vendor: {
            endpoint = `api/vendors/initProducts/${initProductId}`;
            break;
        }
        default: {
            endpoint = `api/products/init/${initProductId}`;
            break;
        }
    }

    return await dispatch({
        [RSAA]: {
            endpoint: endpoint,
            headers: { "Content-Type": "application/json" },
            method: "GET",
            types: [PRODUCT_INIT_GET_DETAILS, PRODUCT_INIT_GET_DETAILS_SUCCESS, PRODUCT_INIT_GET_DETAILS_FAILURE],
        },
    });
};

export const updateProductImages = (vendorId, productInitId, variant, role, images) => {
    let endpoint = "";
    switch (role) {
        case roles.Vendor: {
            endpoint = `api/v1/vendors/ext/${vendorId}/init-products/${productInitId}/update-images`;
            break;
        }
        default: {
            endpoint = `api/v1/products/init/${productInitId}/update-images`;
            break;
        }
    }

    let formData = new FormData();
    formData.append("variant", variant !== undefined);
    variant && formData.append("variantEan", variant);

    var i;
    for (i = 0; i < images.length; i++) {
        var image = images[i];
        formData.append("images[" + i + "].id", image.id);
        formData.append("images[" + i + "].index", image.index);
        formData.append("images[" + i + "].isFront", image.isFront);
        formData.append("images[" + i + "].file", image.file);
        formData.append("images[" + i + "].integration", image.integration ?? "None");
    }

    return (dispatch) => {
        return dispatch({
            [RSAA]: {
                endpoint: endpoint,
                method: "PUT",
                body: formData,
                types: [
                    INIT_PRODUCT_UPDATE_IMAGES,
                    {
                        type: INIT_PRODUCT_UPDATE_IMAGES_SUCCESS,
                        payload: async (action, state, res) => {
                            return {
                                images: await res.json(),
                            };
                        },
                    },
                    {
                        type: INIT_PRODUCT_UPDATE_IMAGES_FAILURE,
                        payload: (action, state, res) => {
                            return res;
                        },
                    },
                ],
            },
        });
    };
};

export const uploadProductFile = (vendorId, productInitId, blob, role, description, isVatStatement) => {
    let endpoint = "";
    switch (role) {
        case roles.Vendor: {
            endpoint = `api/vendors/${vendorId}/init-products/${productInitId}/upload-file`;
            break;
        }
        case roles.Auditor:
        case roles.MasterData: {
            endpoint = `api/auditors/init-products/${productInitId}/upload-file`;
            break;
        }
        default: {
            endpoint = `api/products/init/${productInitId}/upload-file`;
            break;
        }
    }
    let formData = new FormData();
    formData.append("file", blob);
    formData.append("description", description);
    formData.append("isVatStatement", isVatStatement);
    return async (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: endpoint,
                method: "PUT",
                body: formData,
                types: [
                    INIT_PRODUCT_UPLOAD_FILE,
                    {
                        type: INIT_PRODUCT_UPLOAD_FILE_SUCCESS,
                        payload: async (action, state, res) => {
                            return await res.json();
                        },
                    },
                    {
                        type: INIT_PRODUCT_UPLOAD_FILE_FAILURE,
                        payload: (action, state, res) => {
                            return res;
                        },
                    },
                ],
            },
        });
    };
};

export const deleteProductFile = (vendorId, productInitId, blobId, role) => {
    let endpoint = "";
    switch (role) {
        case roles.Vendor: {
            endpoint = `api/vendors/${vendorId}/init-products/${productInitId}/remove-file`;
            break;
        }
        case roles.Auditor:
        case roles.MasterData: {
            endpoint = `api/auditors/init-products/${productInitId}/remove-file`;
            break;
        }
        default: {
            endpoint = `api/products/init/${productInitId}/remove-file`;
            break;
        }
    }
    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: endpoint,
                headers: { "Content-Type": "application/json" },
                method: "PUT",
                body: JSON.stringify({
                    blobId,
                }),
                types: [
                    INIT_PRODUCT_DELETE_FILE,
                    {
                        type: INIT_PRODUCT_DELETE_FILE_SUCCESS,
                        payload: async () => {
                            return blobId;
                        },
                    },
                    {
                        type: INIT_PRODUCT_DELETE_FILE_FAILURE,
                        payload: (action, state, res) => {
                            return res;
                        },
                    },
                ],
            },
        });
    };
};

export const saveStarted = () => (disaptch) => {
    disaptch({ type: SAVE_STARTED });
};

export const saveCompleted = () => (disaptch) => {
    disaptch({ type: SAVE_COMPLETED });
};

export const getManufacturedProductName = (manufacturedProductId) => async (dispatch) => {
    return dispatch({
        [RSAA]: {
            endpoint: `api/products/name?productId=${manufacturedProductId}`,
            headers: { "Content-Type": "application/json", "Accept": "application/json" },
            method: "GET",
            types: [
                {
                    type: GET_MANUFACTURED_PRODUCT_NAME,
                    meta: {
                        hideError: true,
                        ignoreInProgress: true,
                    },
                },
                {
                    type: GET_MANUFACTURED_PRODUCT_NAME_SUCCESS,
                    meta: {
                        hideError: true,
                        ignoreInProgress: true,
                    },
                },
                {
                    type: GET_MANUFACTURED_PRODUCT_NAME_FAILURE,
                    meta: {
                        hideError: true,
                        ignoreInProgress: true,
                    },
                },
            ],
        },
    });
};
