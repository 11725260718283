import GridContainer from "components/Grid/GridContainer";
import { Fragment } from "react";
import { orders } from "translations";
import { useTranslation } from "utils-ts/hooks";
import SimpleProductList from "./SimpleProductList";

const SuspiciousProducts = ({ suspiciousProducts }) => {
    const { t } = useTranslation("orders");
    if (!suspiciousProducts || !suspiciousProducts.length) return <Fragment />;

    return (
        <GridContainer
            iconName="shopping_cart"
            title={`orders:${orders.suspiciousProducts}`}
        >
            <SimpleProductList products={suspiciousProducts} />
        </GridContainer>
    );
};

export default SuspiciousProducts;
