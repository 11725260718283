import { commerce } from "store/actionTypePrefixes";
import { AsyncTypes } from "utils/extensions";
import { asAsyncFormTypes } from "utils/extensions/arrayExtensions";

const types = {
    getCustomer: new AsyncTypes("CUSTOMER_GET", commerce),
    putSegments: new AsyncTypes("CUSTOMER_PUT_SEGMENTS", commerce, [asAsyncFormTypes]),
    putProfile: new AsyncTypes("CUSTOMER_PROFILE_PUT", commerce, [asAsyncFormTypes]),
};

export default types;
