import React from "react";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import { Role } from "../roles";

const AnalyticGroupForm = React.lazy(() =>
    import("views/analyticGroups").then((module) => ({
        default: module.AnalyticGroupForm,
    }))
);
const AnalyticGroupsList = React.lazy(() =>
    import("views/analyticGroups").then((module) => ({
        default: module.AnalyticGroupsList,
    }))
);

const roles: Role[] = ["Management", "SalesManager"];

export const AnalyticGroups: PrivateRoute[] = [
    new PrivateRoute(Paths.AnalyticGroupsList, roles, AnalyticGroupsList, "analyticGroupsList", "group_work"),
    new PrivateRoute(Paths.AnalyticGroupForm, roles, AnalyticGroupForm),
];
