import { RSAA } from "redux-api-middleware";
import { operations } from "store/actionTypePrefixes";
import { asAsyncFormTypes } from "utils/extensions/arrayExtensions";
import { combineIntoQueryParams } from "utils/objectExtensions";
import { splitOrderId } from "utils/splitIds";
import _ from "lodash";

const COMPLAINT_POST = `${operations}/COMPLAINT_POST`;
const COMPLAINT_POST_SUCCESS = `${operations}COMPLAINT_POST_SUCCESS`;
const COMPLAINT_POST_FAILURE = `${operations}COMPLAINT_POST_FAILURE`;

const COMPLAINTS_GET = `${operations}/COMPLAINTS_GET`;
const COMPLAINTS_GET_SUCCESS = `${operations}/COMPLAINTS_GET_SUCCESS`;
const COMPLAINTS_GET_FAILURE = `${operations}/COMPLAINTS_GET_FAILURE`;

const COMPLAINT_CANCEL = `${operations}/COMPLAINT_CANCEL`;
const COMPLAINT_CANCEL_SUCCESS = `${operations}/COMPLAINT_CANCEL_SUCCESS`;
const COMPLAINT_CANCEL_FAILURE = `${operations}/COMPLAINT_CANCEL_FAILURE`;

const POST_IMPORT_COMPLAINTS = `${operations}/POST_IMPORT_COMPLAINTS`;
const POST_IMPORT_COMPLAINTS_SUCCESS = `${operations}/POST_IMPORT_COMPLAINTS_SUCCESS`;
const POST_IMPORT_COMPLAINTS_FAILURE = `${operations}/POST_IMPORT_COMPLAINTS_FAILURE`;

export const types = {
    COMPLAINT_POST,
    COMPLAINT_POST_SUCCESS,
    COMPLAINT_POST_FAILURE,
    COMPLAINTS_GET,
    COMPLAINTS_GET_SUCCESS,
    COMPLAINTS_GET_FAILURE,
    COMPLAINT_CANCEL,
    COMPLAINT_CANCEL_SUCCESS,
    COMPLAINT_CANCEL_FAILURE,
    POST_IMPORT_COMPLAINTS,
    POST_IMPORT_COMPLAINTS_SUCCESS,
    POST_IMPORT_COMPLAINTS_FAILURE,
};

const soldAndDelivered = (line) => {
    if (line.upsellType && line.upsellType === "withoutDelivery")
        return {
            isSold: true,
            isDelivered: false,
        };
    if (line.upsellType && line.upsellType === "withDelivery")
        return {
            isSold: true,
            isDelivered: true,
        };

    throw Error();
};

const calculateComplaintValue = (correctedItems, redelivery) => {
    const correctionValue = _.chain(correctedItems)
        .groupBy("lineNumber")
        .map((g) => {
            let quantity = _.chain(g)
                .map((g) => {
                    if (g.correctRealQuantity) {
                        return parseFloat(g.correctRealQuantity);
                    } else if (g.correctQuantity) {
                        return parseInt(g.correctQuantity, 10);
                    } else {
                        return 0;
                    }
                })
                .sum()
                .value();
            if (!quantity) {
                quantity = g.find((x) => x.realQuantity)?.realQuantity;

                if (!quantity) {
                    quantity = g.find((x) => x.quantity)?.quantity;
                }

                if (quantity === null || quantity === undefined) {
                    quantity = 0;
                }
            }
            const correctPrice = (g.find((x) => x.correctGrossPrice) || { correctGrossPrice: 0 }).correctGrossPrice;
            const initial = (g.find((x) => x.grossPrice) || { grossPrice: 0 }).grossPrice;
            return Math.round(quantity * (initial - correctPrice) * 100) / 100;
        })
        .sum()
        .value();

    const redeliveryValue = _.chain(redelivery)
        .map((p) => parseInt(p.quantity, 10) * parseFloat(p.grossPrice))
        .sum()
        .value();

    const upsellValue = _.chain(redelivery)
        .map((p) => parseInt(p.quantity, 10) * parseFloat(p.grossPrice))
        .sum()
        .value();

    return Math.round((correctionValue - redeliveryValue - upsellValue) * 100) / 100;
};

const mapCorrectionLines = (lines) => {
    return _.chain(lines)
        .flatMap(({ correctedItems, ...line }) => {
            return correctedItems
                ? correctedItems.map((corected) => {
                      return {
                          ...line,
                          type: corected.type,
                          lineNumber: corected.lineNumber,
                          reasonCode: corected.reasonCode,
                          correctNetPrice: corected.CorrectNetPrice,
                          correctGrossPrice: corected.correctGrossPrice,
                          correctQuantity: corected.correctQuantity,
                          correctRealQuantity: corected.correctRealQuantity,
                      };
                  })
                : [];
        })
        .value();
};

const mapUpsellLines = (upsellLines = []) => {
    return upsellLines
        .map(({ grossPrice, productId, ...line }) => {
            return {
                ...line,
                ...soldAndDelivered(line),
                reasonCode: line.reasonCode,
                grossPrice,
                productId: productId,
            };
        })
        .reduce(
            (c, n) => {
                if (n.upsellType === "withDelivery") return { ...c, withDelivery: [...c.withDelivery, n] };
                else
                    return {
                        ...c,
                        withoutDelivery: [...c.withoutDelivery, n],
                    };
            },
            { withDelivery: [], withoutDelivery: [] }
        );
};

const mapRedeliveryLines = (correctionLines = [], merchant) => {
    return correctionLines
        .filter((line) => line.type === "missingProducts")
        .map(({ correctQuantity: quantity, correctRealQuantity: realQuantity, lineNumber: referenceLineNumber, grossPrice, ...line }) => {
            return {
                ...line,
                referenceLineNumber,
                quantity,
                realQuantity,
                grossPrice,
                isSold: false,
                isDelivered: true,
                merchant,
            };
        });
};

const postComplaint =
    ({ merchant, documentType, loggedUser, formData }) =>
    async (dispatch) => {
        const {
            complaintId,
            orderIdentity,
            user,
            documentId,
            documentNumber,
            lines,
            upsellLines,
            complaintInfo,
            createdBy,
            complaintOrderInfo,
            driverName,
            deliveryComplaintId,
            orderId,
        } = formData;

        const { deliveryWindow } = complaintOrderInfo;
        let deliveryStartsAt,
            deliveryEndsAt,
            deliveryClosesAt = null;
        if (deliveryWindow) {
            [deliveryStartsAt, deliveryEndsAt, deliveryClosesAt] = deliveryWindow.split("|");
        }
        const correctedLines = mapCorrectionLines(lines);
        const correctedItems = correctedLines
            .filter((line) => line.type !== "missingProducts")
            .map((x) => ({
                ...x,
                correctQuantity: x.correctQuantity ? x.correctQuantity * -1 : null,
                correctRealQuantity: x.correctRealQuantity ? x.correctRealQuantity * -1 : null,
            }));
        const upsellItems = mapUpsellLines(upsellLines);
        const redeliveryItems = mapRedeliveryLines(correctedLines, merchant);
        const complaintValue = calculateComplaintValue(correctedLines, redeliveryItems, upsellLines);
        const { customerId, orderNumber } = splitOrderId(orderId);
        const body = JSON.stringify({
            warehouse: complaintOrderInfo.warehouse,
            merchant,
            orderIdentity,
            userId: customerId,
            ...user,
            orderNumber,
            orderId,
            documentId,
            documentNumber,
            documentType,
            operationReason: `Informujemy, iż zgłoszona reklamacja do zamówienia ${orderId} została rozpatrzona pozytywnie, tym samym wartość salda została zaktualizowana.`,
            complaintInfo,
            correctedItems,
            upsellItems,
            complaintId,
            complaintValue,
            createdBy,
            editedBy: loggedUser,
            driverName,
            deliveryComplaintId,
            orderInfo: {
                ...complaintOrderInfo,
                billingAddress: complaintOrderInfo?.billingAddress?.companyName ? complaintOrderInfo.billingAddress : null,
                deliveryStartsAt,
                deliveryEndsAt,
                deliveryClosesAt,
            },
            redelivery: {
                lines: [...redeliveryItems, ...upsellItems.withDelivery, ...upsellItems.withoutDelivery],
            },
        });

        return await dispatch({
            [RSAA]: {
                endpoint: `api/users/${customerId}/complaints`,
                headers: { "Content-Type": "application/json" },
                method: "POST",
                types: asAsyncFormTypes([COMPLAINT_POST, COMPLAINT_POST_SUCCESS, COMPLAINT_POST_FAILURE]),
                body: body,
            },
        });
    };

const getComplaints = (customerId, orderNumber, orderDocuments, pageIndex, pageSize) => (dispatch) => {
    const params = combineIntoQueryParams({
        pageIndex,
        pageSize,
        orderNumber,
        documentIds: orderDocuments,
    });
    return dispatch({
        [RSAA]: {
            endpoint: `api/users/${customerId}/complaints?${params}`,
            headers: { "Content-Type": "application/json" },
            method: "GET",
            types: [COMPLAINTS_GET, COMPLAINTS_GET_SUCCESS, COMPLAINTS_GET_FAILURE],
        },
    });
};

const cancelComplaint =
    ({ orderIdentity, customerId, orderNumber, complaintId, redeliveryOrderId, upsellOrderId }) =>
    async (dispatch) => {
        const body = JSON.stringify({
            orderId: orderIdentity,
            complaintId,
            redeliveryOrderId,
            upsellOrderId,
        });

        return await dispatch({
            [RSAA]: {
                endpoint: `api/users/${customerId}/complaints`,
                headers: { "Content-Type": "application/json" },
                method: "PUT",
                body: body,
                types: [COMPLAINT_CANCEL, COMPLAINT_CANCEL_SUCCESS, COMPLAINT_CANCEL_FAILURE],
            },
        });
    };

const refundOrder =
    ({ customerId, orderNumber, loggedUser, invoice: { lines }, ...formData }) =>
    async (dispatch) => {
        const correctionLines = lines
            .filter((x) => (x.realQuantity ?? x.quantity) > 0)
            .map((x) => ({
                ...x,
                correctedItems: [
                    {
                        productId: x.productId,
                        lineNumber: x.lineNumber,
                        correctQuantity: x.quantity,
                        correctRealQuantity: x.realQuantity,
                        reasonCode: "Q_Other_OrderCancelation",
                    },
                ],
            }));
        formData.lines = correctionLines;
        const complaint = { customerId, orderNumber, loggedUser, formData };
        return await dispatch(
            postComplaint({
                ...complaint,
                merchant: formData.merchant,
                documentType: formData.documentType,
            })
        );
    };

const importComplaint = (fileContent, complaintType) => async (dispatch) => {
    const body = { corrections: fileContent };
    return dispatch({
        [RSAA]: {
            endpoint: complaintType === "complaint" ? "api/import" : "api/import/order-return-complaint",
            headers: { "Content-Type": "application/json" },
            method: "POST",
            body: JSON.stringify(body),
            types: [types.POST_IMPORT_COMPLAINTS, types.POST_IMPORT_COMPLAINTS_SUCCESS, types.POST_IMPORT_COMPLAINTS_FAILURE],
        },
    });
};

const importOrderAbortComplaint = (fileContent) => async (dispatch) => {
    return dispatch({
        [RSAA]: {
            endpoint: "api/import/order-abort-complaint",
            headers: { "Content-Type": "application/json" },
            method: "POST",
            body: JSON.stringify(fileContent),
            types: [types.POST_IMPORT_COMPLAINTS, types.POST_IMPORT_COMPLAINTS_SUCCESS, types.POST_IMPORT_COMPLAINTS_FAILURE],
        },
    });
};

export const actions = {
    postComplaint,
    getComplaints,
    cancelComplaint,
    refundOrder,
    importComplaint,
    importOrderAbortComplaint,
};
