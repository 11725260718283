import { createSelector } from "reselect";

const root = (state) => ({
    correctionRequest: state.correctionRequest,
});

export const correctionRequestsListSelector = createSelector(root, (root) => {
    return root.correctionRequest.list;
});
export const correctionRequestFormSelector = createSelector(root, ({ correctionRequest = {} }) => {
    return correctionRequest.form;
});
