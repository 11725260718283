import { DeliveryMethodsSelectField, DictionaryInput, FeedFormatAutocomplete, PromotionPoolPredicateFields } from "components/Controls";
import { CommerceForm, FormField } from "components/Form";
import { renderFieldOrText } from "components/FormHelpers/Controls";
import Layout from "components/Grid/Layout";
import { FieldArray, reduxForm } from "redux-form";
import { usePromotionFormAutocompleteEffect } from "store/autocomplete";
import { actions } from "store/offers/feed/form/actions";
import { mappedFeedFormSelector } from "store/offers/selectors";
import { toObject } from "utils/extensions/arrayExtensions";
import { useCommonTranslation, useCopyRouterState, useFormValueSelector } from "utils/hooks";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Divider, Grid, Typography } from "@mui/material";
import { usePush } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { validators } from "../validators";

const toggleValues = [
    { value: true, name: "Tak" },
    { value: false, name: "Nie" },
    { value: "", name: "Nie dotyczy" },
];

//prettier-ignore
const marginValues = [
    { value: 0,   name: "0%"  },
    { value: 0.1, name: "5%"  },
    { value: 0.2, name: "10%" },
    { value: 0.3, name: "15%" },
    { value: 0.4, name: "20%" },
    { value: 0.5, name: "25%" },
    { value: 0.6, name: "30%" },
    { value: 0.7, name: "35%" },
    { value: 0.8, name: "40%" },
    { value: 0.9, name: "45%" },
    { value: 1,   name: "50%" }
];

const languages = [
    { value: "pl", name: "Polski" },
    { value: "en", name: "Angielski" },
];

const FeedForm = ({ handleSubmit, isSubbmitRequested, isSystem, name, initialValues }) => {
    usePromotionFormAutocompleteEffect();
    const { id, isCopy } = useCopyRouterState();
    const { replace } = usePush();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.initForm(id, isCopy));
    }, [dispatch, id, isCopy]);
    const { t, common } = useCommonTranslation();

    const submit = async (values) => {
        const { queryParameters, ...rest } = values;
        const mapped = {
            ...rest,
            queryParameters: toObject(queryParameters),
        };
        const { payload } = await dispatch(actions.createOrUpdate(mapped, !isCopy && id));
        if ((!isCopy && !id) || isCopy) {
            replace(Paths.Offers.FeedForm, { id: payload.id });
        }
    };

    const readOnly = isSystem && !isCopy;
    return (
        <CommerceForm
            onSubmit={handleSubmit((values) => submit(values))}
            isReadonly={readOnly}
            isSubbmitRequested={isSubbmitRequested}
        >
            <Layout
                main
                headerText={t(common.feed, {
                    id: !isCopy && id ? id : "",
                })}
                navigationProps={{
                    backProps: { path: Paths.Offers.FeedsList },
                }}
                downloadLink={!isCopy && id ? window.config.applications.commerce + "api/v1/integration/feeds/" + id : null}
                customTitle={id && name && `${t(common.feed)} - ${name}`}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <FormField
                        name={"name"}
                        component={renderFieldOrText}
                        type={"text"}
                        label={t(common.name)}
                        validate={validators.name}
                        readOnly={readOnly}
                    />
                    <FormField
                        name={"isActive"}
                        component={renderFieldOrText}
                        type={"boolean"}
                        label={t(common.isActive)}
                        readOnly={readOnly}
                    />
                    <FormField
                        name={"isPerWarehouse"}
                        component={renderFieldOrText}
                        type={"boolean"}
                        label={t(common.isPerWarehouse)}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <FormField
                        name={"validFrom"}
                        component={renderFieldOrText}
                        type={"dateTime"}
                        label={t(common.validFrom)}
                        readOnly={readOnly}
                    />
                    <FormField
                        name={"validTo"}
                        component={renderFieldOrText}
                        type={"dateTime"}
                        label={t(common.validTo)}
                        readOnly={readOnly}
                    />
                    <FormField
                        name={"marginOver"}
                        component={renderFieldOrText}
                        type={"select"}
                        label={t(common.marginOver)}
                        items={marginValues}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <FormField
                        name={"isPrimaryVariant"}
                        component={renderFieldOrText}
                        type={"toggle"}
                        label={t(common.isPrimaryVariant)}
                        items={toggleValues}
                        readOnly={readOnly}
                    />
                    <FormField
                        name={"isAvailable"}
                        component={renderFieldOrText}
                        type={"toggle"}
                        label={t(common.isAvailable)}
                        items={toggleValues}
                        readOnly={readOnly}
                    />
                    <FormField
                        name={"isPromotionOnly"}
                        component={renderFieldOrText}
                        type={"toggle"}
                        label={t(common.isPromotionOnly)}
                        items={toggleValues}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <FormField
                        name={"isAlcohol"}
                        component={renderFieldOrText}
                        type={"toggle"}
                        label={t(common.isAlcohol)}
                        items={toggleValues}
                        readOnly={readOnly}
                    />
                    <FormField
                        name={"isTobacco"}
                        component={renderFieldOrText}
                        type={"toggle"}
                        label={t(common.isTobacco)}
                        items={toggleValues}
                        readOnly={readOnly}
                    />
                    <FormField
                        name={"isWater"}
                        component={renderFieldOrText}
                        type={"toggle"}
                        label={t(common.isWater)}
                        items={toggleValues}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <FormField
                        name={"hasImage"}
                        component={renderFieldOrText}
                        type={"toggle"}
                        label={t(common.hasImage)}
                        items={toggleValues}
                        readOnly={readOnly}
                    />
                    <FormField
                        name={"imageSize"}
                        component={renderFieldOrText}
                        type={"number"}
                        label={t(common.imageSize)}
                        validate={validators.imageSize}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <FormField
                        name={"suppressCategoryRendering"}
                        component={renderFieldOrText}
                        type={"boolean"}
                        label={t(common.suppressCategoryRendering)}
                        readOnly={readOnly}
                    />
                    <FormField
                        multiple
                        name={"languages"}
                        component={renderFieldOrText}
                        type={"select"}
                        label={t(common.languages)}
                        items={languages}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <FeedFormatAutocomplete validate={validators.feedFormat} />
                    <DeliveryMethodsSelectField />
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <FormField
                        name={"productUrlPrefix"}
                        component={renderFieldOrText}
                        type={"text"}
                        label={t(common.productUrlPrefix)}
                        readOnly={readOnly}
                    />
                    <FormField
                        name={"useHttps"}
                        component={renderFieldOrText}
                        type={"boolean"}
                        label={t(common.useHttps)}
                        readOnly={readOnly}
                    />
                </Grid>
                <Divider />
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Typography>{t(common.urlParams)}</Typography>
                    <FieldArray
                        name={"queryParameters"}
                        component={DictionaryInput}
                        type={"dictionary"}
                        readOnly={readOnly}
                    />
                </Grid>
            </Layout>
            <PromotionPoolPredicateFields
                formName={formName}
                renderRequiremtnts={false}
                required={false}
                readOnly={readOnly}
            />
        </CommerceForm>
    );
};

const formName = "feed-form";

const stateToProps = (state) => {
    const { isSubbmitRequested, isSystem, ...form } = mappedFeedFormSelector(state);
    return {
        isSystem,
        isSubbmitRequested,
        name: form.name,
        initialValues: {
            ...form,
        },
    };
};

export default connect(stateToProps)(
    reduxForm({
        form: formName,
        enableReinitialize: true,
    })(FeedForm)
);
