import { useDebouncedCallback } from "use-debounce";
import React, { useState } from "react";
import { List, ListItem, Typography } from "@mui/material";
import _ from "lodash";
import { ListInputProps } from "control-types";
import TextField from "./TextField";

const ListInput: React.FC<ListInputProps> = ({
    value,
    label,
    error,
    readOnly = false,
    onChange,
    renderItem,
    onAdd,
    additionalValueCheck,
    useList = true,
    splitValues = true,
    splitByWhitespace = true,
}) => {
    const [inputValue, setInputValue] = useState("");
    const setFieldValue = useDebouncedCallback((event) => {
        const {
            target: { value: eventValue },
        } = event;
        setInputValue("");
        const newValues = _(String(eventValue))
            .split(splitValues ? (splitByWhitespace ? /[,;\s]/ : /[,;]/) : /$/)
            .filter((x) => Boolean(x) && (!additionalValueCheck || additionalValueCheck(x)))
            .map((x) => String(x))
            .uniq()
            .filter((x) => (value || []).every((y) => y !== x))
            .value();

        if (eventValue && newValues && newValues.length) {
            if (onAdd) {
                onAdd(newValues);
            }

            onChange([...value, ...newValues]);
        }
    }, 750);
    const handleRemove = (v: string) => {
        const newValues = value.filter((x) => x !== v);
        onChange(newValues);
    };

    return (
        <>
            <Typography
                component="div"
                variant="caption"
                style={{ width: "100%" }}
            >
                {label}
            </Typography>

            {useList ? (
                <List dense>
                    {(value || []).map((v, i) => (
                        <ListItem key={i}>{renderItem({ item: v, handleRemove })}</ListItem>
                    ))}
                </List>
            ) : (
                (value || []).map((v) => renderItem({ item: v, handleRemove }))
            )}

            {readOnly ? (
                <></>
            ) : (
                <TextField
                    label=""
                    fullWidth
                    placeholder={label}
                    onChange={(newValue, event) => {
                        if (!event) {
                            return;
                        }
                        event.persist();
                        setInputValue(newValue);
                        setFieldValue(event);
                    }}
                    onBlur={(event) => {
                        event.persist();
                        setInputValue(event.target.value);
                        setFieldValue(event);
                    }}
                    value={inputValue}
                    error={error}
                />
            )}
        </>
    );
};

export default ListInput;
