import { operations } from "store/actionTypePrefixes";
import { AsyncTypes, asAsyncFormTypes, createAction } from "utils/extensions";

const types = {
    createVendorOperation: new AsyncTypes("CREATE_VENDOR_OPERATION", operations, [asAsyncFormTypes]),
    findVendorOperations: new AsyncTypes("FIND_VENDOR_OPERATIONS", operations),
    getVendorOperation: new AsyncTypes("GET_VENDOR_OPERATION", operations),
    makeVendorOperationDecision: new AsyncTypes("MAKE_VENDOR_OPERATION_DECISION", operations),
    downloadFile: createAction("DOWNLOAD_FILE", operations),
    clear: createAction("CLEAR_VENDOR_OPERATION_FORM", operations),
};

export default types;
