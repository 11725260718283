import { manager, operations } from "store/actionTypePrefixes";
import { AsyncTypes, asAsyncFormTypes, createAction, importAsyncMessages } from "utils/extensions";

const types = {
    findEmails: new AsyncTypes("FIND_EMAILS", operations),
    getEmail: new AsyncTypes("GET_EMAIL", operations),
    createEmail: new AsyncTypes("CREATE_EMAIL", operations, [(t) => asAsyncFormTypes(t)]),
    getRecipientsFileTemplate: new AsyncTypes("GET_RECIPIENTS_FILE_TEMPLATE", operations),
    getRecipientsFromFile: new AsyncTypes("GET_RECIPIENTS_FROM_FILE", operations, [(t) => asAsyncFormTypes(t, importAsyncMessages)]),
    formInit: createAction("FORM_INIT_EMAIL", manager),
    downloadAttachment: new AsyncTypes("DOWNLOAD_EMAIL_ATTACHMENT", operations),
};

export default types;
