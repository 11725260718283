import _ from "lodash";
import { createSelector } from "reselect";

const root = (state) => {
    return {
        customerMemos: state.customer.customerMemos,
        userInfos: state.cache.userInfos,
    };
};

export const memoListSelector = createSelector(root, ({ customerMemos, userInfos }) => {
    const { memo, list } = customerMemos;

    return {
        memo,
        list: {
            ...list,
            items: _(list.items)
                .orderBy(["isImportant", "createdAt"], ["desc", "desc"])
                .map((item) => {
                    const userInfo = userInfos.find((u) => u.userId === item.createdBy);

                    return {
                        ...item,
                        createdBy: userInfo ? userInfo.email : item.createdBy,
                    };
                })
                .value(),
        },
    };
});

export const memoSelector = createSelector(root, ({ customerMemos }) => customerMemos.memo);

export const memoAuditSelector = createSelector(root, ({ customerMemos: { memo }, userInfos }) => {
    return {
        ...memo,
        auditList: {
            ...memo.auditList,
            items: memo.auditList.items.map((item) => {
                const userInfo = userInfos.find((u) => u.userId === item.createdBy);
                return {
                    ...item,
                    ...item.document,
                    createdBy: userInfo ? userInfo.email : item.createdBy,
                    createdAt: item.createdAt,
                };
            }),
        },
    };
});
