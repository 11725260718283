import { DeleteRequest, GetRequest, PagedRequest, get, getPaged, remove } from "utils/extensions";
import { splitPaymentId } from "utils/splitIds";
import types from "./types";

const { paginatedList, list, withhold } = types;

export const getPayments = (payload) => (dispatch) => {
    const { withPagination, ...rest } = payload;
    return withPagination ? dispatch(getPaymentsPagination(rest)) : dispatch(getPaymentsNoPagination(rest));
};

const getPaymentsPagination = (payload) => async (dispatch) => {
    const { customerId, pageIndex, pageSize } = payload;
    const endpoint = `api/v1/users/${customerId}/payments`;

    return await dispatch(getPaged(new PagedRequest(pageIndex, pageSize, endpoint, paginatedList.all)));
};

const getPaymentsNoPagination = (payload) => async (dispatch) => {
    const { orderNumber, customerId } = payload;
    const endpoint = `api/v1/users/${customerId}/orders/${orderNumber}/payments`;

    return await dispatch(get(new GetRequest(endpoint, list.all)));
};

const withholdPayment = (paymentId, isSilent) => async (dispatch) => {
    const { customerId, paymentNumber } = splitPaymentId(paymentId);

    return await dispatch(
        remove(
            new DeleteRequest(`api/v1/users/${customerId}/payments/${paymentNumber}`, withhold.all, {
                body: {
                    isSilent,
                },
            })
        )
    );
};

export const actions = {
    getPayments,
    withholdPayment,
};
