import _ from "lodash";

//New roles
const Administrator = "Administrator";
const HelpDesk = "HelpDesk";
const Analyst = "Analyst";
const AXUser = "AXUser";
const B2B = "BusinessSupport";
const B2BImporter = "BusinessSupportImporter";
const B2BManager = "BusinessSupportManager";
const Support = "Support";
const SupportManager = "SupportManager";
const Catman = "Catman";
const SalesAssistant = "SalesAssistant";
const SalesShopping = "SalesShopping";
const SalesManager = "SalesManager";
const Ecommerce = "Ecommerce";
const EcommerceManager = "EcommerceManager";
const Auditor = "Auditor";
const Accountant = "Accountant";
const AccountantManager = "AccountantManager";
const Logistician = "Logistician";
const LogisticianManager = "LogisticianManager";
const Marketing = "Marketing";
const MarketingManager = "MarketingManager";
const TradeMarketing = "TradeMarketing";
const TradeMarketingManager = "TradeMarketingManager";
const Management = "Management";
const Vendor = "Vendor";
const ProjectManager = "ProjectManager";
const OfficeManager = "OfficeManager";
const HumanResources = "HumanResources";
const HumanResourcesManager = "HumanResourcesManager";
const Archiver = "Archiver";
const ExternalUser = "ExternalUser";
const MasterData = "MasterData";
const DebtCoordinator = "DebtCoordinator";
const AccountBalanceCoordinator = "AccountBalanceCoordinator";

let roles = {
    Administrator,
    HelpDesk,
    Analyst,
    AXUser,
    B2B,
    B2BImporter,
    B2BManager,
    Support,
    SupportManager,
    Catman,
    SalesAssistant,
    SalesShopping,
    SalesManager,
    Ecommerce,
    EcommerceManager,
    Auditor,
    Accountant,
    AccountantManager,
    Logistician,
    LogisticianManager,
    Marketing,
    MarketingManager,
    TradeMarketing,
    TradeMarketingManager,
    Management,
    Vendor,
    ProjectManager,
    OfficeManager,
    HumanResources,
    HumanResourcesManager,
    Archiver,
    ExternalUser,
    MasterData,
    DebtCoordinator,
    AccountBalanceCoordinator,
};

roles.all = _(roles)
    .map((x) => x)
    .value();

roles.getUserDepartmentSubordinate = (userRoles) => {
    if (!userRoles) {
        return [];
    }

    if (userRoles.includes(Administrator)) {
        return roles.all.filter((r) => r !== Vendor && r !== HelpDesk);
    } else if (userRoles.includes(Management) || userRoles.includes(Auditor)) {
        return roles.all.filter((r) => r !== Vendor && r !== HelpDesk && r !== Administrator);
    }

    var managerUserRoles = ManagersRoles.filter((mr) => userRoles.includes(mr));
    if (managerUserRoles.length === 0) {
        return [];
    } else {
        var departmentRoles = [];

        if (B2BDepartmentRoles.some((r) => managerUserRoles.includes(r))) {
            departmentRoles = departmentRoles.concat(B2BDepartmentRoles);
        }
        if (SupportDepartmentRoles.some((r) => managerUserRoles.includes(r))) {
            departmentRoles = departmentRoles.concat(SupportDepartmentRoles);
        }
        if (SalesDepartmentRoles.some((r) => managerUserRoles.includes(r))) {
            departmentRoles = departmentRoles.concat(SalesDepartmentRoles);
        }
        if (ECommerceDepartmentRoles.some((r) => managerUserRoles.includes(r))) {
            departmentRoles = departmentRoles.concat(ECommerceDepartmentRoles);
        }
        if (AccountantDepartmentRoles.some((r) => managerUserRoles.includes(r))) {
            departmentRoles = departmentRoles.concat(AccountantDepartmentRoles);
        }
        if (LogisticianDepartmentRoles.some((r) => managerUserRoles.includes(r))) {
            departmentRoles = departmentRoles.concat(LogisticianDepartmentRoles);
        }
        if (MarketingDepartmentRoles.some((r) => managerUserRoles.includes(r))) {
            departmentRoles = departmentRoles.concat(MarketingDepartmentRoles);
        }
        if (TradeMarketingDepartmentRoles.some((r) => managerUserRoles.includes(r))) {
            departmentRoles = departmentRoles.concat(TradeMarketingDepartmentRoles);
        }
        if (HumanResourcesDepartmentRoles.some((r) => managerUserRoles.includes(r))) {
            departmentRoles = departmentRoles.concat(HumanResourcesDepartmentRoles);
        }

        return departmentRoles;
    }
};

export default roles;

export const B2BDepartmentRoles = [B2B, B2BImporter, B2BManager];
export const SupportDepartmentRoles = [Support, SupportManager];
export const B2BAndSupportDepartmentRoles = [B2B, Support, B2BManager, SupportManager];
export const SalesDepartmentRoles = [Catman, SalesAssistant, SalesShopping, SalesManager];
export const ECommerceDepartmentRoles = [Ecommerce, EcommerceManager];
export const AccountantDepartmentRoles = [Accountant, AccountantManager];
export const LogisticianDepartmentRoles = [Logistician, LogisticianManager];
export const MarketingDepartmentRoles = [Marketing, MarketingManager];
export const TradeMarketingDepartmentRoles = [TradeMarketing, TradeMarketingManager];
export const HumanResourcesDepartmentRoles = [HumanResources, HumanResourcesManager];
export const AuditorAndMasterDataRoles = [Auditor, MasterData];
export const DebtCoordinatorRoles = [DebtCoordinator];
export const AccountBalanceCoordinatorRoles = [AccountBalanceCoordinator];
export const ManagersRoles = [
    Management,
    B2BManager,
    SupportManager,
    SalesManager,
    EcommerceManager,
    AccountantManager,
    LogisticianManager,
    MarketingManager,
    TradeMarketingManager,
    HumanResourcesManager,
];

export const MANAGER = "Manager";

export const managerRoles = [
    Analyst,
    AXUser,
    B2B,
    B2BManager,
    Support,
    SupportManager,
    Catman,
    SalesAssistant,
    SalesShopping,
    SalesManager,
    Ecommerce,
    EcommerceManager,
    Auditor,
    Accountant,
    AccountantManager,
    Logistician,
    LogisticianManager,
    Marketing,
    MarketingManager,
    TradeMarketing,
    TradeMarketingManager,
    Management,
    HumanResources,
    HumanResourcesManager,
    MasterData,
    DebtCoordinator,
    AccountBalanceCoordinator,
];
