const procedures = {
    New: "New",
    Accepted: "Accepted",
    Postponed: "Postponed",
    Updated: "Updated",
    AcceptedUpdate: "AcceptedUpdate",
    PostponedUpdate: "PostponedUpdate",
    Postpone: "Postpone",
    Accept: "Accept",
    AllProcedures: "AllProcedures",
    UnacceptedProcedures: "UnacceptedProcedures",
} as const;

export default procedures;
