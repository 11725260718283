import { makeStyles } from "tss-react/mui";
import { useParams } from "utils/hooks";
import { Card, CardContent } from "@mui/material";
import DescriptionAndLinks from "./DescriptionAndLinks";
import ProductImage from "./ProductImage";
import ProductInfo from "./ProductInfo";
import ProductMeasurements from "./ProductMeasurements";
import ProductOperationalData from "./ProductOperationalData";
import ProductPrice from "./ProductPrice";
import ProductStock from "./ProductStock";

const useStyles = makeStyles()({
    root: {
        display: "inline-flex",
    },
    details: {
        display: "inline-flex",
        flexDirection: "column",
    },
    content: {
        flex: "1 0 auto",
    },
});

const ProductBase = ({ imageUrl, hideManagerLinks, ...product }) => {
    const { classes } = useStyles();
    const { id } = useParams();

    return (
        <>
            <div>
                <Card className={classes.container}>
                    <ProductImage imageUrl={imageUrl} />
                    <div className={classes.details}>
                        <CardContent className={classes.content}>
                            <ProductInfo {...product} />
                        </CardContent>
                    </div>
                    <div className={classes.details}>
                        <CardContent className={classes.content}>
                            <ProductStock {...product} />
                        </CardContent>
                    </div>
                    <div className={classes.details}>
                        <CardContent className={classes.content}>
                            <ProductPrice
                                {...product}
                                hidePromotionLink={hideManagerLinks}
                            />
                        </CardContent>
                    </div>
                    <div className={classes.details}>
                        <CardContent className={classes.content}>
                            <ProductOperationalData {...product} />
                        </CardContent>
                    </div>
                    <div className={classes.details}>
                        <CardContent className={classes.content}>
                            <ProductMeasurements {...product} />
                        </CardContent>
                    </div>
                </Card>
                <Card>
                    <div className={classes.details}>
                        <CardContent className={classes.content}>
                            <DescriptionAndLinks
                                id={id}
                                {...product}
                                imageUrl={imageUrl}
                                hideContentLink={hideManagerLinks}
                            />
                        </CardContent>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default ProductBase;
