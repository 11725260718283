import { DeliveryComplaintList } from "views/deliveryComplaints";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import { B2BDepartment, SupportDepartment } from "routing-ts/roles";

export const DeliveryComplaints: PrivateRoute[] = [
    new PrivateRoute(
        Paths.DeliveryComplaints,
        ["Management", "Analyst", ...B2BDepartment.GetRoles(), ...SupportDepartment.GetRoles()],
        DeliveryComplaintList,
        "deliverycomplaints",
        "local_shipping"
    ),
];
