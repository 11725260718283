import { isInteger } from "utils/numberExtensions";
import Validator, { isEmpty } from "utils/validators/basic";
import { tValidation, validation } from "utils-ts/validations/translation";

export const validateDefinitions = (definition = {}) => {
    const definitionErrors = {};
    const {
        effects = [],
        isCumulative,
        cartActivationLimit,
        userActivationLimit,
        promotionActivationLimit,
        requiresConsent,
        promotionType,
        requirements = {},
    } = definition;
    const isMembershipPromotion = promotionType == "Membership" || effects.some((x) => x.effectType === "membershipPoints");
    const { user = {} } = requirements;
    const { segments = [] } = user;
    const anyDiscount = effects.some((p) => Boolean(p.discountModifier));

    definitionErrors["isCumulative"] = new Validator(isCumulative)
        .must((x) => x === true, tValidation(validation.required))
        .when(anyDiscount || Boolean(cartActivationLimit) || Boolean(userActivationLimit))
        .validate();

    definitionErrors["cartActivationLimit"] = new Validator(cartActivationLimit)
        .must(isInteger, tValidation(validation.beInteger))
        .when(Boolean(cartActivationLimit))
        .must((x) => x > 0, tValidation(validation.greaterOrEqualsThan, { number: 1 }))
        .when(Boolean(cartActivationLimit))
        .must(
            isEmpty,
            tValidation(validation.mustBeEmpty, {
                when: "jeśli jako efekt została wybrana zniżka.",
            })
        )
        .when(anyDiscount)
        .validate();

    definitionErrors["userActivationLimit"] = new Validator(userActivationLimit)
        .must(isInteger, tValidation(validation.beInteger))
        .when(Boolean(userActivationLimit))
        .must((x) => x > 0, tValidation(validation.greaterOrEqualsThan, { number: 1 }))
        .when(Boolean(userActivationLimit))
        .must(isEmpty, tValidation(validation.empty))
        .when(anyDiscount)
        .validate();

    definitionErrors["promotionActivationLimit"] = new Validator(promotionActivationLimit)
        .must(isInteger, tValidation(validation.beInteger))
        .when(Boolean(promotionActivationLimit))
        .must((x) => x > 0, tValidation(validation.greaterOrEqualsThan, { number: 1 }))
        .when(Boolean(promotionActivationLimit))
        .must(isEmpty, tValidation(validation.empty))
        .when(anyDiscount)
        .validate();

    definitionErrors["requiresConsent"] = new Validator(requiresConsent)
        .must((x) => x === false || x === undefined, tValidation(validation.empty))
        .when(anyDiscount)
        .validate();

    if (isMembershipPromotion) {
        definitionErrors.requirements = { ...definitionErrors.requirements };
        definitionErrors.requirements.user = { ...definitionErrors.requirements.user };
        definitionErrors.requirements.user.segments = [
            {
                included: new Validator((segments[0] || { included: [] }).included)
                    .must(
                        (value) => value.includes("commerce:MembershipRewards"),
                        tValidation(validation.mustContains, {
                            what: `segment '${tValidation("MembershipRewards", {}, "segments")}'`,
                        })
                    )
                    .validate(),
            },
        ];
    }

    return definitionErrors;
};

export const validate = (values) => {
    const errors = {};
    const { definition = {} } = values;

    return {
        ...errors,
        definition: validateDefinitions(definition),
    };
};
