import { iconTypes } from "components/Icons/InfoIcon";
import { createSelector } from "reselect";

const root = (state) => ({
    balanceDebts: state.balanceDebts,
    userInfos: state.cache.userInfos,
    commerceUsers: state.cache.commerceUsers,
});

export const balanceDebtListSelector = createSelector(root, ({ balanceDebts, userInfos, commerceUsers }) => ({
    ...balanceDebts.list,
    items: balanceDebts.list.items.map((o) => {
        const {
            activity: { changeReason, activityReason, nextAt },
            createdBy,
        } = (o.recentActivities && o.recentActivities[0]) || {
            activity: {},
        };

        const user = userInfos.find((x) => x.userId === createdBy);
        const { user: commerceUser } = commerceUsers.find((p) => Number(o.id) === Number(p.userId)) || {
            user: {},
        };
        const { fullName, billingAddress = {}, segments = [] } = commerceUser || {};
        const isOfficeAccount = segments.some((x) => x === "commerce:OfficeAccount");

        let customerName = `${fullName?.firstName} ${fullName?.lastName}`;

        if (isOfficeAccount && billingAddress.companyName) {
            customerName += ` \n${billingAddress.companyName}`;
        }

        return {
            ...o,
            nextAt,
            activityReason,
            changeReason: changeReason ? changeReason : !activityReason ? "Brak danych" : undefined,
            createdBy: user?.email || createdBy || "System",
            customerName,
            accountType: segments.length ? (isOfficeAccount ? "business" : "home") : undefined,
            paymentTerm: o.creditLimit?.paymentTerm,
            creditLimit:
                o.creditLimit === undefined && o.previousCreditLimit !== undefined
                    ? {
                          ...o.previousCreditLimit,
                          isLost: true,
                      }
                    : o.creditLimit,
        };
    }),
}));

export const balanceDebtFormSelector = createSelector(root, ({ balanceDebts, userInfos, commerceUsers }) => {
    let commerceUser = {};

    if (balanceDebts.form?.balanceDebt?.id) {
        const { user } = commerceUsers.find((p) => Number(balanceDebts.form.balanceDebt.id) === Number(p.userId)) || {
            user: {},
        };

        if (user) {
            commerceUser = user;
        }
    }

    const { activities, balanceDebt, ...rest } = balanceDebts.form;

    return {
        ...rest,
        activities: {
            ...activities,
            items: activities.items.map((o) => {
                const user = userInfos.find((x) => x.userId === o.createdBy);
                return {
                    ...o,
                    ...o.activity,
                    isBalanceSettled: o.activity.balanceSettled ? iconTypes.success : "",
                    isOrderVariableWeight: o.activity.isOrderVariableWeight ? "𓍝" : "",
                    createdBy: user?.email || o.createdBy || "System",
                };
            }),
        },
        balanceDebt: {
            ...balanceDebt,
            user: commerceUser,
            creditAmount: balanceDebt?.creditLimit?.creditAmount,
            recentActivities: balanceDebt?.recentActivities.map((o) => {
                const user = userInfos.find((x) => x.userId === o.createdBy);

                return {
                    ...o,
                    ...o.activity,
                    isBalanceSettled: o.activity.balanceSettled ? iconTypes.success : "",
                    isOrderVariableWeight: o.activity.isOrderVariableWeight ? "𓍝" : "",
                    createdBy: user?.email || o.createdBy || "System",
                };
            }),
        },
    };
});
