import { Button } from "components/Button";
import { AcceptRejectDialog, useModalState } from "components/Dialog";
import { renderFieldOrText } from "components/FormHelpers/Controls";
import { Field } from "redux-form";
import { actions } from "store/cache/products/actions";
import { withFormName } from "utils/hoc";
import { useChange, useCommonTranslation, useMappedDispatch } from "utils/hooks";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import ProductItem from "./PromotionPredicateFieldsComponents/ProductItem";
import { productsSelector } from "./PromotionPredicateFieldsComponents/selectors";

const ProductDragAndDropListField = ({ form, sectionPrefix, ...props }) => {
    const { handleClose, handleOpen, modalState } = useModalState();
    const { t, common } = useCommonTranslation();
    const { getProducts } = useMappedDispatch(actions);
    const products = useSelector(productsSelector) || [];
    const change = useChange(form);

    return (
        <Grid item>
            <AcceptRejectDialog
                maxWidth={"sm"}
                title={t(common.removeItem)}
                open={modalState}
                handleAccept={async () => {
                    change(sectionPrefix ? `${sectionPrefix}.products` : "products", null);
                    handleClose();
                }}
                handleReject={handleClose}
            >
                {t(common.doYouWantToClearProductList)}
            </AcceptRejectDialog>
            <Grid
                item
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-end"
            >
                <Grid item>
                    <Button
                        actionType="decline"
                        onClick={handleOpen}
                    >
                        {t(common.clearProductList)}
                    </Button>
                </Grid>
            </Grid>
            <Field
                {...props}
                name="products"
                label={t(common.products)}
                type="dragAndDrop"
                useList={false}
                itemRenderer={(v, handleRemove) => (
                    <ProductItem
                        key={v}
                        v={v}
                        handleRemove={handleRemove}
                        products={products}
                        withToolTip
                    />
                )}
                onAdd={(productIds) => {
                    getProducts(productIds);
                }}
                additionalValueCheck={(v) => Boolean(Number(v))}
                component={renderFieldOrText}
            />
        </Grid>
    );
};

export default withFormName(ProductDragAndDropListField);
