import TextWithLabel from "components/Text/TextWithLabel";
import { refundationFormSelector } from "store/vendors/refundations";
import { useParams } from "utils/hooks";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { common, vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";

const RefundationStatus = () => {
    const { t } = useTranslation(["vendor", "common"]);
    const { refundationId } = useParams();
    const { changeStatus, user } = useSelector(refundationFormSelector);

    if (!refundationId) {
        return <></>;
    }

    return (
        <Grid
            container
            direction="row"
        >
            <Grid item>
                <TextWithLabel
                    value={t(changeStatus)}
                    translatedLabel={t(vendor.changeStatus)}
                />
            </Grid>
            <Grid item>
                <TextWithLabel
                    value={user?.email}
                    translatedLabel={t(`common:${common.createdBy}`)}
                />
            </Grid>
        </Grid>
    );
};

export default RefundationStatus;
