import { FileExport } from "components/Files";
import Cell from "components/Table/SimpleTable/Cell";
import Row from "components/Table/SimpleTable/Row";
import { useUser } from "context/UserContext/UserContext";
import { downloadInvoiceFile } from "store/vendors/subsidiaryGains";
import { formatTypes, formatValue, timePrecisonFormats } from "utils/formating";
import { useParams } from "utils/hooks";
import { useDispatch } from "react-redux";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";

export const getDocumentStatus = (status) => {
    return status === "Error" ? vendor.correctionError : status === "Ok" ? vendor.correctionOk : vendor.correctionPending;
};

const InvoiceRow = ({ item: { invoice = {}, operation, id, blobReference } }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation("vendor");
    const { vendorId } = useParams();
    const { isAdmin } = useUser();

    return (
        <Row>
            <Cell>{t(vendor.invoice)}</Cell>
            <Cell>{t(getDocumentStatus(operation.status))}</Cell>
            <Cell>{operation.reason}</Cell>
            <Cell>{invoice.invoiceNumber}</Cell>
            <Cell>
                {formatValue(invoice.documentDate, formatTypes.time, {
                    timeFormat: timePrecisonFormats.days,
                })}
            </Cell>
            <Cell>
                {formatValue(invoice.saleDate, formatTypes.time, {
                    timeFormat: timePrecisonFormats.days,
                })}
            </Cell>
            {/* <Cell>
                <Button
                    size="small"
                    color="primary"
                    disabled={
                        operation.status !== "Ok" ||
                        invoice?.corrections?.some(
                            (x) =>
                                x.operation.status === "Error" || x.operation.status === "Pending"
                        )
                    }
                    onClick={() => {
                        setSelectedInvoiceId(id);
                    }}
                >
                    {t(vendor.addInvoiceCorrection)}
                </Button>
            </Cell>
            <Cell>
                {operation.status === "Error" ? (
                    <Button
                        size="small"
                        color="primary"
                        onClick={() => {
                            dispatch(retryInvoice(vendorId, subsidiaryGainId, id));
                        }}
                    >
                        {t(vendor.retry)}
                    </Button>
                ) : (
                    <></>
                )}
            </Cell> */}
            {isAdmin && (
                <Cell>
                    <FileExport
                        handleDownload={() => {
                            dispatch(downloadInvoiceFile(vendorId, id, null, invoice.invoiceNumber));
                        }}
                        downloadDisabled={!blobReference}
                        downloadText={t(vendor.getInvoice)}
                    />
                </Cell>
            )}
        </Row>
    );
};

export default InvoiceRow;
