import types from "./types";

const initialState = {};
const { getUserRoles } = types;

export const userRoles = (state = initialState, { type, payload }) => {
    switch (type) {
        case getUserRoles.request:
            return { ...initialState, isLoading: true };
        case getUserRoles.success:
            return { ...state, roles: payload, isLoading: false };
        case getUserRoles.failure:
            return { roles: [], isLoading: false };
        default:
            return state;
    }
};
