import Button from "components/Button/Button";
import { FormField } from "components/Form";
import { decimalNormalize } from "components/FormHelpers/ControlFormaters";
import roles from "consts/roles";
import { formValueSelector, reduxForm } from "redux-form";
import { withStyles } from "tss-react/mui";
import { useCommonTranslation } from "utils/hooks";
import { decimalPlaces, inRange, mustBeNumber, notEmptyValidator } from "utils/validators/basic";
import { Fragment } from "react";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { useUser } from "context";
import { tValidation, validation } from "utils-ts/validations/translation";

const balanceCorrectionCodes = [
    "kierowca pobrał za dużo",
    "kierowca pobrał za mało",
    "brak wpłaty za zamówienie",
    "podwójnie zapłacone zamówienie",
    "inne",
];

const AddBalanceCorrectionForm = withStyles(
    ({ classes, handleSubmit, isNotifying, submitting, valid, handleAdd }) => {
        const { t, common } = useCommonTranslation();
        const { isInRoleOrAdmin } = useUser();
        const disabled = !isInRoleOrAdmin(roles.AccountBalanceCoordinator);
        //prettier-ignore
        const fields = [
        { type: "text", name: "complaint.balanceDiscrepancy.balanceAmount.FRS", label:t(common.frsRefund), format: decimalNormalize},
        { type: "text", name: "complaint.balanceDiscrepancy.balanceAmount.F24", label:t(common.f24Refund), format: decimalNormalize},
        { type: "select", name: "complaint.balanceDiscrepancy.complaintCode", label:t(common.reason), items: balanceCorrectionCodes.map(x => ({ value:x, name:x }))},
        { type: "text", name: "complaint.balanceDiscrepancy.note",  multiline: true, label:t(common.note)},
        { type: "boolean", name: "notification.isNotifying", label:t(common.isNotifying) },
        { type: "text", name: "notification.customMessage", label:t(common.customMessage), hidden: !isNotifying }
    ];

        const handleFormSubmit = (values) => {
            if (!valid) return;
            handleAdd(values);
        };

        return (
            <div className={classes.container}>
                <form onSubmit={handleSubmit((val) => handleFormSubmit(val))}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="stretch"
                    >
                        {fields.map((field, i) => {
                            if (field.hidden) return <Fragment key={i} />;
                            return (
                                <Grid
                                    key={i}
                                    className={classes.field}
                                    item
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="stretch"
                                >
                                    <FormField
                                        {...field}
                                        readOnly={disabled}
                                    />
                                </Grid>
                            );
                        })}
                        <Grid item>
                            {!disabled && (
                                <Button
                                    fullWidth
                                    type="submit"
                                    disabled={submitting}
                                    className={classes.button}
                                    variant="contained"
                                    color="primary"
                                >
                                    {t(common.add)}
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </form>
            </div>
        );
    },
    (theme) => ({
        root: {
            marginTop: theme.spacing(6),
        },
        button: {
            marginTop: theme.spacing(3),
        },
        field: {
            marginTop: theme.spacing(2),
        },
    })
);

const mapStateToProps = (state, props) => {
    const { editItem } = props;
    const isNotifying = formValueSelector("balanceCorrectionForm")(state, "notification.isNotifying");

    return {
        isNotifying,
        notification: null,
        initialValues: {
            ...(editItem
                ? {
                      ...editItem,
                      complaint: {
                          ...editItem.complaint,
                          balanceDiscrepancy: {
                              ...editItem.complaint.balanceDiscrepancy,
                              balanceAmount: {
                                  FRS: editItem.complaint.balanceDiscrepancy.balanceAmount.FRS || 0,
                                  F24: editItem.complaint.balanceDiscrepancy.balanceAmount.F24 || 0,
                              },
                          },
                      },
                  }
                : {
                      complaint: {
                          balanceDiscrepancy: {
                              balanceAmount: {
                                  FRS: 0,
                                  F24: 0,
                              },
                          },
                      },
                  }),
        },
    };
};

const validate = (values) => {
    const errors = {
        complaint: { balanceDiscrepancy: { balanceAmount: {} } },
        notification: {},
    };
    const { complaint = {}, notification } = values;
    const { balanceDiscrepancy = {} } = complaint;
    const { complaintCode, balanceAmount = {} } = balanceDiscrepancy;
    const { FRS, F24 } = balanceAmount;
    errors.complaint.balanceDiscrepancy.balanceAmount.FRS = notEmptyValidator(FRS)
        .must(mustBeNumber, tValidation(validation.mustBeNumber))
        .must(decimalPlaces([0, 2]))
        .allWhen(!F24)
        .validate();

    errors.complaint.balanceDiscrepancy.balanceAmount.F24 = notEmptyValidator(F24)
        .must(mustBeNumber, tValidation(validation.mustBeNumber))
        .must(decimalPlaces([0, 2]))
        .allWhen(!FRS)
        .validate();

    errors.complaint.balanceDiscrepancy.complaintCode = notEmptyValidator(complaintCode).validate();

    errors.notification.customMessage = notEmptyValidator(notification && notification.customMessage)
        .must(inRange(3, 1000), tValidation(validation.inRange, { min: 3, max: 1000 }))
        .allWhen(notification && notification.isNotifying)
        .validate();

    return errors;
};

export default connect(mapStateToProps)(
    reduxForm({
        form: "balanceCorrectionForm",
        validate,
        enableReinitialize: true,
    })(AddBalanceCorrectionForm)
);
