import React from "react";
import { Grid } from "@mui/material";

const FormRow: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    return (
        <Grid
            container
            item
            direction="row"
            justify-content="flex-start"
            alignItems="flex-start"
            spacing={2}
        >
            {children}
        </Grid>
    );
};

export default FormRow;
