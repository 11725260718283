import buttons from "assets/jss/custom/buttons";
import { makeStyles } from "tss-react/mui";
import React from "react";
import { CircularProgress, Icon, IconButton as MUIButton } from "@mui/material";

const useStyles = makeStyles()((theme, { useWrapper, showInline }) => ({
    ...buttons(theme),
    wrapper: {
        margin: useWrapper ? theme.spacing(1) : 0,
        position: "relative",
        display: showInline ? "inline-flex" : "flex",
    },
    grid: {
        marginBottom: theme.spacing(1),
    },
    item: {
        paddingLeft: theme.spacing(1),
    },
    buttonProgress: {
        color: theme.palette.success.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

const IconButton = ({ useWrapper = true, color, icon, iconClasses, loading, showInline = false, input, ...props }) => {
    const { classes } = useStyles({ useWrapper, showInline });

    return (
        <div className={classes.wrapper}>
            <MUIButton
                style={{ marginLeft: "auto", color }}
                {...props}
                disabled={props.disabled || loading}
            >
                <Icon className={iconClasses}>
                    {icon}
                    {input}
                </Icon>
            </MUIButton>
            {loading && (
                <CircularProgress
                    size={28}
                    className={classes.buttonProgress}
                />
            )}
        </div>
    );
};

export default React.memo(IconButton);
