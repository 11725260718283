import { api } from "api";
import FormAutocomplete from "../FormAutocomplete";

type ContentCategoriesAutocompleteProps = {
    label: string;
    /**
     * default 'categoryName'
     */
    name?: string;
    readOnly?: boolean;
    multiple?: true;
    disableEmpty?: boolean;
};

const ContentCategoriesAutocomplete: React.FC<ContentCategoriesAutocompleteProps> = ({ label, name = "categoryName" }) => {
    const { data: containerCategories, status } = api.commerce.autocomplete.useFindContainerCategories();

    return (
        <FormAutocomplete
            name={name}
            label={label}
            items={containerCategories || []}
            selectOnFocus
            freeSolo
            isLoadingItems={status === "pending"}
        />
    );
};

export default ContentCategoriesAutocomplete;
