import cardFooterStyle from "assets/jss/material-dashboard-react/components/cardFooterStyle";
import { withStyles } from "tss-react/mui";
import classNames from "classnames";
import PropTypes from "prop-types";

function CardFooter({ ...props }) {
    const { className, children, plain, profile, stats, chart, ...rest } = props;
    const classes = withStyles.getClasses(props);
    const cardFooterClasses = classNames({
        [classes.cardFooter]: true,
        [classes.cardFooterPlain]: plain,
        [classes.cardFooterProfile]: profile,
        [classes.cardFooterStats]: stats,
        [classes.cardFooterChart]: chart,
        [className]: className !== undefined,
    });

    return (
        <div
            className={cardFooterClasses}
            {...rest}
        >
            {children}
        </div>
    );
}

CardFooter.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    plain: PropTypes.bool,
    profile: PropTypes.bool,
    stats: PropTypes.bool,
    chart: PropTypes.bool,
};

export default withStyles(CardFooter, cardFooterStyle);
