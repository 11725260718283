import Layout from "components/Grid/Layout";
import TabLabel from "components/Tabs/TabLabel";
import Tabs from "components/Tabs/Tabs";
import roles, {
    AccountBalanceCoordinatorRoles,
    AccountantDepartmentRoles,
    B2BDepartmentRoles,
    DebtCoordinatorRoles,
    LogisticianDepartmentRoles,
    SupportDepartmentRoles,
} from "consts/roles";
import { actions } from "store/customers/customer/action";
import { useParams } from "utils/hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { createSelector } from "reselect";
import { customers } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { SupportContactList } from "views-ts/customer/supportContact";
import OperationsOrderList from "views/order/OperationsOrderList";
import OrderList from "views/order/OrderList";
import ComplaintsContainer from "views/order/order/ComplaintsContainer";
import PaymentList from "views/payments/PaymentList";
import { allCustomerRoles, allCustomerRolesWithAuditorAndHRManager, baseCustomerRoles, baseWithEcommerce } from "routing-ts/Routes/CustomerRoles";
import Consents from "./customer/consents/Consents";
import CustomerBalanceAudit from "./customer/customerBalanceAudit/CustomerBalanceAudit";
import CustomerBalnaceCorrectionList from "./customer/customerBalnaceCorrection/CustomerBalnaceCorrectionList";
import CustomerInfo from "./customer/customerInfo/CustomerInfo";
import CustomerMembership from "./customer/customerMembership/CustomerMembership";
import CustomerReviews from "./customer/customerReviews/CustomerReviews";
import CustomerVouchers from "./customer/customerVouchers/CustomerVouchers";
import DeferredPaymentList from "./customer/deferredPayment/DeferredPaymentList";
import ReservationAudit from "./customer/reservationAudit/ReservationAudit";

const baseSelector = (state) => state.customer;
const selector = createSelector(baseSelector, (customer) => customer.customerRoot);

const Customer = () => {
    const { t } = useTranslation("customers");
    const dispatch = useDispatch();
    const { customerId: storeCustomerId } = useSelector(selector);
    const { state } = useLocation();
    const { tabIndex = 0 } = state || {};
    const { customerId } = useParams();

    useEffect(() => {
        if (storeCustomerId !== customerId) {
            dispatch(actions.changeCustomer(customerId));
        }
    }, [customerId, dispatch, storeCustomerId]);

    const tabLabels = [
        new TabLabel(t(customers.customerInfo), allCustomerRolesWithAuditorAndHRManager),
        new TabLabel(t(customers.ordersList), allCustomerRoles),
        new TabLabel(t(customers.reservationAudit), allCustomerRoles),
        new TabLabel(t(customers.customerBalanceAuditDataTab), [...baseCustomerRoles, ...AccountantDepartmentRoles, roles.Auditor]),
        new TabLabel(t(customers.paymentDataTab), [
            ...baseCustomerRoles,
            ...AccountantDepartmentRoles,
            ...AccountBalanceCoordinatorRoles,
            roles.EcommerceManager,
        ]),
        new TabLabel(t(customers.complaintsDataTab), [
            ...baseCustomerRoles,
            ...AccountantDepartmentRoles,
            ...LogisticianDepartmentRoles,
            roles.EcommerceManager,
        ]),
        new TabLabel(t(customers.balanceCorrectionTab), [...baseCustomerRoles, ...AccountantDepartmentRoles, roles.EcommerceManager]),
        new TabLabel(t(customers.customerVouchersDataTab), [...baseCustomerRoles, roles.EcommerceManager]),
        new TabLabel(t(customers.customerReviewsDataTab), allCustomerRoles),
        new TabLabel(t(customers.customerMembershipTab), allCustomerRoles),
        new TabLabel(t(customers.gdprTab), baseWithEcommerce),
        new TabLabel(t(customers.operationsOrderList), [roles.Administrator]),
        new TabLabel(t(customers.deferredPaymentTab), [...B2BDepartmentRoles, roles.Auditor]),
        new TabLabel(t(customers.contactHistory), [...DebtCoordinatorRoles, ...SupportDepartmentRoles]),
    ];

    return (
        <Layout
            main
            headerText={t(customers.customerWithId, { customerId })}
            navigationProps={{
                backProps: { path: `/customers` },
            }}
        >
            <Tabs
                tableLabels={tabLabels}
                tabIndex={tabIndex}
            >
                <CustomerInfo
                    customerId={customerId}
                    isCustomerSynced={storeCustomerId === customerId}
                />
                <OrderList
                    customerId={customerId}
                    tabIndex={tabIndex}
                />
                <ReservationAudit customerId={customerId} />
                <CustomerBalanceAudit customerId={customerId} />
                <PaymentList
                    customerId={customerId}
                    withPagination
                />
                <ComplaintsContainer customerId={customerId} />
                <CustomerBalnaceCorrectionList customerId={customerId} />
                <CustomerVouchers customerId={customerId} />
                <CustomerReviews customerId={customerId} />
                <CustomerMembership />
                <Consents customerId={customerId} />
                <OperationsOrderList customerId={customerId} />
                <DeferredPaymentList customerId={customerId} />
                <SupportContactList />
            </Tabs>
        </Layout>
    );
};

export default Customer;
