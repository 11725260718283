import InfoDialog from "components/Dialog/InfoDialog";
import GridContainer from "components/Grid/GridContainer";
import TextWithLabel from "components/Text/TextWithLabel";
import { formatTypes } from "utils/formating";
import { Fragment } from "react";
import { Grid } from "@mui/material";
import { customers } from "translations";
import { useTranslation } from "utils-ts/hooks";

const ReviewDialog = ({ reviewItem, ...rest }) => {
    const { t } = useTranslation("customers");
    const { review = {} } = reviewItem || {};
    //prettier-ignore
    const fields = [
        { label: customers.recommendation,           value: review.recommendation,               format: formatTypes.rating, options: {max: 10} }, 
        { label: customers.qualityIssue,             value: review.qualityIssue,                 format: formatTypes.text }, 
        { label: customers.complienceIssue,          value: review.complienceIssue,              format: formatTypes.text }, 
        { label: customers.punctualityIssue,         value: review.punctualityIssue,             format: formatTypes.text },     
        { label: customers.paymentIssue,             value: review.paymentIssue,                 format: formatTypes.text }, 
        { label: customers.pageIssue,                value: review.pageIssue,                    format: formatTypes.text },     
        { label: customers.generalIssue,             value: review.generalIssue,                 format: formatTypes.text }, 
        { label: customers.whatDoYouLike,            value: review.whatDoYouLike,                format: formatTypes.text }, 
        { label: customers.generalSuggestions,       value: review.generalSuggestions,           format: formatTypes.text },     
        { label: customers.offerSuggestions,         value: review.offerSuggestions,             format: formatTypes.text },     
    ];

    return (
        <InfoDialog
            {...rest}
            maxWidth="md"
            title={t(customers.recommendation)}
        >
            <GridContainer
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                {fields.map((item) => {
                    if (!item.value) return <Fragment key={item.label} />;

                    return (
                        <Grid
                            item
                            key={item.label}
                        >
                            <TextWithLabel
                                unselectableLabel
                                label={item.label}
                                format={item.format}
                                value={item.value}
                                options={item.options}
                            />
                        </Grid>
                    );
                })}
            </GridContainer>
        </InfoDialog>
    );
};

export default ReviewDialog;
