import { Addable, FormField } from "components/Form";
import { Erasable } from "components/FormHelpers";
import { FormSection } from "redux-form";
import { useFormValueSelector } from "utils/hooks";
import { Grid } from "@mui/material";
import { Layout } from "components";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { default as validation } from "./validation";
import { default as dictionaries } from "./vendorAXDictionaries.json";

const OperationBankAccounts = ({ fields, readonly, isArchiveOnly, formName }) => {
    const { t } = useTranslation("vendor");
    const bankAccountInformations = useFormValueSelector(formName, "bankAccountInformations", []);

    return (
        <Layout headerText={t(vendor.bankAccounts)}>
            <Addable
                renderCondition={!readonly}
                handleAdd={() => {
                    fields.push({});
                }}
            >
                {fields.map((field, index) => {
                    const { language } = bankAccountInformations[index];

                    return (
                        <Erasable
                            key={field}
                            handleRemove={() => {
                                fields.remove(index);
                            }}
                        >
                            <FormSection name={field}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-end"
                                >
                                    <FormField
                                        name="name"
                                        type="text"
                                        label={t(vendor.name)}
                                        validate={isArchiveOnly ? undefined : validation.required}
                                        readonly={readonly}
                                    />
                                    <FormField
                                        name="number"
                                        type="text"
                                        label={t(vendor.accountNumber)}
                                        validate={isArchiveOnly ? undefined : validation.required}
                                        readonly={readonly}
                                    />
                                    <FormField
                                        name="language"
                                        type="select"
                                        label={t(vendor.language)}
                                        items={dictionaries.languages}
                                        validate={isArchiveOnly ? undefined : validation.required}
                                        readonly={readonly}
                                    />
                                    <FormField
                                        name="swiftCode"
                                        type="text"
                                        label={t(vendor.swiftCode)}
                                        validate={isArchiveOnly || language === "pl" ? undefined : validation.required}
                                        readonly={readonly}
                                    />
                                </Grid>
                            </FormSection>
                        </Erasable>
                    );
                })}
            </Addable>
        </Layout>
    );
};

export default OperationBankAccounts;
