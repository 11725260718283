import GridContainer from "components/Grid/GridContainer";
import TextWithLabel from "components/Text/TextWithLabel";
import formatTypes from "utils/formating/formatTypes";
import { Fragment } from "react";
import { orders } from "translations";

const BillingAdress = ({ billingAddress }) => {
    if (!billingAddress) {
        return <Fragment />;
    }

    const fields = [
        {
            label: `orders:${orders.companyName}`,
            value: billingAddress.companyName,
            format: formatTypes.text,
        },
        {
            label: `orders:${orders.vatin}`,
            value: billingAddress.vatin,
            format: formatTypes.text,
        },
        {
            label: `orders:${orders.address}`,
            value: billingAddress,
            format: formatTypes.shippingAddress,
        },
        {
            label: `orders:${orders.phoneNumber}`,
            value: billingAddress.phoneNumber,
            format: formatTypes.text,
        },
    ];

    return (
        <GridContainer
            iconName="receipt"
            title={`orders:${orders.billingAddress}`}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >
            {fields.map((item) => (
                <TextWithLabel
                    key={item.label}
                    label={item.label}
                    format={item.format}
                    value={item.value}
                />
            ))}
        </GridContainer>
    );
};

export default BillingAdress;
