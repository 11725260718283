export class Requirements {
    constructor(requirements = {}) {
        this.total = requirements.total;
        this.user = requirements.user && new User(requirements.user);
        this.payment = requirements.payment ? requirements.payment.map((p) => p.paymentMethod) : requirements.payment;
        this.delivery = requirements.delivery ? requirements.delivery.map((d) => new Delivery(d)) : requirements.delivery;
        this.packaging = requirements.packaging ? requirements.packaging.map((p) => p.packagingMethod) : requirements.packaging;
        this.channel = requirements.channel;
    }
}

export class CartPredicate extends Requirements {
    constructor(cartPredicate = {}) {
        super(cartPredicate);
        this.purchasedProducts = cartPredicate.purchasedProducts && new PurchasedProductsPredicate(cartPredicate.purchasedProducts);
        this.cartProducts = cartPredicate.cartProducts && new CartProductsPredicate(cartPredicate.cartProducts);
    }
}

class CartProductsPredicate {
    constructor(purchasedProducts = {}) {
        this.purchased = new ProductsPredicate(purchasedProducts.purchased);
        this.unpurchased = new ProductsPredicate(purchasedProducts.unpurchased);
    }
}

class PurchasedProductsPredicate {
    constructor(purchasedProducts = {}) {
        this.purchasedOrdersAgoFrom = purchasedProducts.purchasedOrdersAgoFrom;
        this.purchasedOrdersAgoTo = purchasedProducts.purchasedOrdersAgoTo;
        this.purchased = new ProductsPredicate(purchasedProducts.purchased);
        this.unpurchased = new ProductsPredicate(purchasedProducts.unpurchased);
    }
}

class ProductsPredicate {
    constructor(products = {}) {
        this.includeProducts = products.includeProducts;
        this.excludeProducts = products.excludeProducts;
    }
}

export class User {
    constructor(user = {}) {
        this.orders = user.orders && new Orders(user.orders);
        this.personalTarget = user.personalTarget && new PersonalTargetRequirement(user.personalTarget);
        this.segments = user.segments && user.segments.map((s) => new Segment(s));
        this.haveSegmentsConstraint = Boolean(user.segments);
        this.havePersonalTargetContraint = Boolean(user.personalTarget);
        this.orderRequirements = Boolean(user.orders);
    }
}

export class PersonalTargetRequirement {
    constructor(personalTarget = {}) {
        this.targetAmountFrom = personalTarget.targetAmountFrom;
        this.targetAmountTo = personalTarget.targetAmountTo;
    }
}

export class Delivery {
    constructor(delivery = {}) {
        this.deliveryMethods = delivery.deliveryMethods;
        this.currentDates = delivery.currentDates ? delivery.currentDates.map((d) => new DatePredicate(d)) : null;
        this.deliveryDates = delivery.deliveryDates ? delivery.deliveryDates.map((d) => new DatePredicate(d)) : null;
        this.postcodes = delivery.postcodes ? delivery.postcodes.map((d) => new PostCodes(d)) : null;
        this.closingDates = delivery.closingDates ? delivery.closingDates.map((d) => new DateLeadPredicate(d)) : null;
        this.havePostcodeConstraint = Boolean(delivery.postcodes);
        this.isSameDayDelivery =
            delivery.isSameDayDelivery != null && delivery.isSameDayDelivery !== undefined ? Boolean(delivery.isSameDayDelivery) : null;
        this.isNonTradeSunday =
            delivery.isNonTradeSunday != null && delivery.isNonTradeSunday !== undefined ? Boolean(delivery.isNonTradeSunday) : null;
        this.isMondayAfterNonTradeSunday =
            delivery.isMondayAfterNonTradeSunday != null && delivery.isMondayAfterNonTradeSunday !== undefined
                ? Boolean(delivery.isMondayAfterNonTradeSunday)
                : null;
        this.isMorningAfterHoliday =
            delivery.isMorningAfterHoliday != null && delivery.isMorningAfterHoliday !== undefined ? Boolean(delivery.isMorningAfterHoliday) : null;
    }
}

export class DateLeadPredicate {
    constructor(date = {}) {
        this.date = new DatePredicate(date.date);
        this.leadPeriod = date.leadPeriod;
    }
}

export class DatePredicate {
    constructor(date = {}) {
        const isDaySelection = Boolean(date.dayFrom);
        this.type = isDaySelection ? "select" : "date";
        if (isDaySelection) {
            this.dayFrom = date.dayFrom;
            this.dayTo = date.dayTo;
        } else {
            this.dateFrom = date.dateFrom;
            this.dateTo = date.dateTo;
        }

        this.hourFrom = date.hourFrom;
        this.hourTo = date.hourTo;
    }
}

export class PostCodes {
    constructor(postcode = {}) {
        const { range, mask } = postcode;
        this.range = null;
        this.mask = null;
        if (range) {
            this.type = "range";
            this.range = range;
        } else {
            this.type = "mask";
            this.mask = mask;
        }
    }
}

export class Payment {
    constructor(payment = {}) {
        this.paymentMethod = payment.paymentMethod;
    }
}

class Orders {
    constructor(orders = {}) {
        this.placedOrderCountTo = orders.placedOrderCountTo;
        this.placedOrderCountFrom = orders.placedOrderCountFrom;
        this.invoicedOrderCountFrom = orders.invoicedOrderCountFrom;
        this.invoicedOrderCountTo = orders.invoicedOrderCountTo;
        this.lastDeliveryDaysAgoFrom = orders.lastDeliveryDaysAgoFrom;
        this.lastDeliveryDaysAgoTo = orders.lastDeliveryDaysAgoTo;
        this.placedOrderCountModuloRemainder = orders.placedOrderCountModuloRemainder;
        this.placedOrderCountModulo = orders.placedOrderCountModulo;
        this.invoicedOrderCountModulo = orders.invoicedOrderCountModulo;
        this.invoicedOrderCountModuloRemainder = orders.invoicedOrderCountModuloRemainder;
        this.membershipOrderCountModulo = orders.membershipOrderCountModulo;
        this.membershipOrderCountModuloRemainder = orders.membershipOrderCountModuloRemainder;
        this.membershipOrderCountFrom = orders.membershipOrderCountFrom;
        this.membershipOrderCountTo = orders.membershipOrderCountTo;
    }
    i;
}

class Segment {
    constructor(segment = {}) {
        this.included = segment.included;
        this.excluded = segment.excluded;
    }
}
