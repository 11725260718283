import { manager, operations } from "store/actionTypePrefixes";
import { AsyncTypes, asAsyncFormTypes, createAction } from "utils/extensions";

const types = {
    findTurnovers: new AsyncTypes("FIND_TURNOVERS", operations),
    changePagination: createAction("CHANGE_REFUNDATION_PAGINATION", manager),
    setFilters: createAction("SET_REFUNDATION_FILTERS", manager),
    getTurnover: new AsyncTypes("GET_TURNOVER", operations),
    updateTurnovers: new AsyncTypes("UPDATE_TURNOVERS", operations, [asAsyncFormTypes]),
    updateVendorSummary: new AsyncTypes("UPDATE_VENDOR_SUMMARY", operations, [asAsyncFormTypes]),
    updateVendorSummaryTotal: new AsyncTypes("UPDATE_VENDOR_SUMMARY_TOTAL", operations, [asAsyncFormTypes]),
    createTurnoverCorrection: new AsyncTypes("CREATE_TURNOVER_CORRECTION", operations, [asAsyncFormTypes]),
    downloadFile: new AsyncTypes("DOWNLOAD_TURNOVER_FILE", operations),
    downloadSummaryFile: new AsyncTypes("DOWNLOAD_TURNOVER_SUMMARY_FILE", operations),
};

export default types;
