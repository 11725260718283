import TextWithLabel from "components/Text/TextWithLabel";
import { formatTypes } from "utils/formating";
import { common } from "translations";
import ProductField from "views/offers/productPreview/components/ProductField";

const ProductInfo = ({ brand, subbrand, supplier, producer, grammage, unitOfMeasure, packSize, name = {} }) => {
    const { pl = "", en = "" } = name;
    const info = [
        new ProductField(common.brand, brand, formatTypes.text),
        new ProductField(common.subbrand, subbrand, formatTypes.text),
        new ProductField(common.supplier, supplier, formatTypes.text),
        new ProductField(common.producer, producer, formatTypes.text),
        new ProductField(common.grammage, grammage, formatTypes.grammage, {
            unitOfMeasure,
        }),
        new ProductField(common.packSize, packSize, formatTypes.text),
        new ProductField(common.namePl, pl, formatTypes.text),
        new ProductField(common.nameEn, en, formatTypes.text),
    ];

    return info.map((f) => (
        <TextWithLabel
            unselectableLabel
            key={f.label}
            {...f}
        />
    ));
};

export default ProductInfo;
