import { manager, operations } from "store/actionTypePrefixes";
import { AsyncTypes, asAsyncFormTypes, createAction } from "utils/extensions";

const types = {
    findDocumentDefinitions: new AsyncTypes("FIND_DOCUMENT_DEFINITIONS", operations),
    formInit: createAction("FORM_INIT_DOCUMENT_DEFINITIONS", manager),
    getDocumentDefinitions: new AsyncTypes("GET_DOCUMENT_DEFINITIONS", operations),
    createOrUpdate: new AsyncTypes("CREATE_OR_UPDATE_DOCUMENT_DEFINITION", operations, [asAsyncFormTypes]),
    makeDecision: new AsyncTypes("MAKE_DECISION_DOCUMENT_DEFINITION", operations, [asAsyncFormTypes]),
    downloadFile: new AsyncTypes("DOWNLOAD_DOCUMENT_DEFINITION_FILE", operations),
};

export default types;
