import { commerce, identity } from "store/actionTypePrefixes";
import { actions as cachedActions } from "store/cache/actions";
import { cacheNames } from "utils/cache/cacheProvider";

const identityUserRegex = /^[A-z0-9]{8}-[A-z0-9]{4}-[A-z0-9]{4}-[A-z0-9]{4}-[A-z0-9]{12}$/;
const commerceUserRegex = /^[0-9]{6,7}$/;

const getUserInfos = (userIds) => async (dispatch) => {
    const { commerceUsers, identityUsers } = userIds.reduce(
        (c, n) => {
            if (identityUserRegex.test(n)) {
                return {
                    ...c,
                    identityUsers: [...c.identityUsers, n],
                };
            }
            if (commerceUserRegex.test(n)) {
                return {
                    ...c,
                    commerceUsers: [...c.commerceUsers, n],
                };
            }

            return c;
        },
        {
            commerceUsers: [],
            identityUsers: [],
        }
    );

    if (commerceUsers.length > 0) {
        dispatch(
            cachedActions.getCached({
                ids: commerceUsers,
                params: {},
                cacheName: cacheNames.userInfosStore,
                endpoint: `api/v1/identity/user-infos`,
                idPropertyName: "userId",
                queryPropetyName: "userIds",
                appName: commerce,
                method: "POST",
                useQuery: false,
            })
        );
    }
    if (identityUsers.length > 0) {
        dispatch(
            cachedActions.getCached({
                ids: identityUsers,
                params: {},
                cacheName: cacheNames.userInfosStore,
                endpoint: `api/identity/user-infos`,
                idPropertyName: "userId",
                queryPropetyName: "userIds",
                appName: identity,
                method: "POST",
                useQuery: false,
            })
        );
    }
    return Promise.resolve();
};

export const actions = {
    getUserInfos,
};
