import { renderFieldOrText } from "components/FormHelpers/Controls";
import Layout from "components/Grid/Layout";
import { Field, change, formValueSelector, getFormSyncErrors } from "redux-form";
import deliveryMethods from "resource/deliveryMethods.json";
import paymentMethods from "resource/paymentMethods.json";
import { actions } from "store/order/deliveryWindows/actions";
import { withStyles } from "tss-react/mui";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Divider, Grid, Typography } from "@mui/material";
import moment from "moment";
import classnames from "classnames";
import _ from "lodash";
import { orders } from "translations";

const styles = (theme) => ({
    layoutMargin: {
        margin: `${theme.spacing(2)}px 0px`,
    },
    gridWidth: {
        minWidth: "300px",
    },
    item: {
        padding: theme.spacing(1),
    },
    header: {
        padding: theme.spacing(1),
    },
});

const isDateDisabled = (d) => {
    return moment().add(1, "day").startOf("day").isAfter(d);
};

class Delivery extends Component {
    componentDidMount() {
        this.getWindows();
    }

    getWindows = async () => {
        const { complaintOrderInfo, productsIds, userId } = this.props;
        const { deliveryDate } = complaintOrderInfo;
        if (!userId) return;
        await this.props.getDeliveryWindows({
            ...complaintOrderInfo,
            userId,
            date: moment(deliveryDate).format("YYYY.MM.DD"),
            productsIds,
        });
    };

    render = () => {
        const { t, editionDisabled } = this.props;
        const classes = withStyles.getClasses(this.props);
        // prettier-ignore
        const fields = [
            { name: "complaintOrderInfo.shippingAddress.recipient",        label: orders.recipient,       size: 3 , type:"text"                                          },
            { name: "complaintOrderInfo.shippingAddress.phoneNumber",      label: orders.phoneNumber,     size: 3 , type:"text"                                          },
            { name: "complaintOrderInfo.shippingAddress.postcode",         label: orders.postcode,        size: 3 , type:"text"                                          },
            { name: "complaintOrderInfo.shippingAddress.city",             label: orders.city,            size: 3 , type:"text"                                          },
            { name: "complaintOrderInfo.shippingAddress.street",           label: orders.street,          size: 3 , type:"text"                                          },
            { name: "complaintOrderInfo.shippingAddress.buildingNumber",   label: orders.buildingNumber,  size: 3 , type:"text"                                          },
            { name: "complaintOrderInfo.shippingAddress.apartmentNumber",  label: orders.apartmentNumber, size: 3 , type:"text"                                          },
            { name: "complaintOrderInfo.shippingAddress.stairwayNumber",   label: orders.stairwayNumber,  size: 3 , type:"text"                                          },
            { name: "complaintOrderInfo.shippingAddress.floorNumber",      label: orders.floorNumber,     size: 3 , type:"text"                                          },
            { name: "complaintOrderInfo.deliveryMethod",                   label: orders.deliveryMethod,  size: 3 , type:"select", items: deliveryMethods,               },
            { name: "complaintOrderInfo.paymentMethod",                    label: orders.paymentMethod,   size: 3 , type:"select", items: paymentMethods,                },
            { name: "complaintOrderInfo.deliveryDate",                     label: orders.deliveryDate,    size: 3,  type:"date", shouldDisableDate: editionDisabled ? null :isDateDisabled                                     },
            { name: "complaintOrderInfo.deliveryWindow",                   label: orders.deliveryWindow,  size: 3,  type:"select", items: this.props.deliveryWindows, onOpen:() => { this.getWindows() }     },
        ];

        //prettier-ignore
        const billingFields = [
            { name: "complaintOrderInfo.billingAddress.companyName",    label: orders.companyName,     size: 3 , type:"text"                                          },
            { name: "complaintOrderInfo.billingAddress.vatin",          label: orders.vatin,           size: 3 , type:"text"                                          },
            { name: "complaintOrderInfo.billingAddress.postcode",       label: orders.postcode,        size: 3 , type:"text"                                          },
            { name: "complaintOrderInfo.billingAddress.city",           label: orders.city,            size: 3 , type:"text"                                          },
            { name: "complaintOrderInfo.billingAddress.street",         label: orders.street,          size: 3 , type:"text"                                          },
            { name: "complaintOrderInfo.billingAddress.buildingNumber", label: orders.buildingNumber,  size: 3 , type:"text"                                          },
            { name: "complaintOrderInfo.billingAddress.apartmentNumber",label: orders.apartmentNumber, size: 3 , type:"text"                                          },
            { name: "complaintOrderInfo.billingAddress.phoneNumber",    label: orders.phoneNumber,     size: 3 , type:"text"                                          },
            { name: "complaintOrderInfo.billingAddress.email",          label: orders.email,           size: 3 , type:"text"                                          },
        ];

        return (
            <Layout headerText={t(orders.delivery)}>
                <Typography variant="h5">{t(orders.shippingAddress)}</Typography>
                <Divider />
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="baseline"
                    className={classnames(classes.layoutMargin, classes.gridWidth)}
                >
                    {fields.map((field) => {
                        return (
                            <Grid
                                key={field.name}
                                item
                                xs={field.size}
                                className={classes.item}
                            >
                                <Field
                                    fullWidth
                                    component={renderFieldOrText}
                                    readOnly={editionDisabled}
                                    {...field}
                                    label={t(field.label)}
                                    onChange={() => {
                                        if (field !== "complaintOrderInfo.deliveryWindow") {
                                            this.props.resetDeliveryWindow();
                                        }
                                    }}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
                <div
                    className={classes.item}
                    style={{ marginBottom: 30 }}
                >
                    <Field
                        type={"text"}
                        readOnly={editionDisabled}
                        name={"complaintOrderInfo.shippingAddress.instructions"}
                        component={renderFieldOrText}
                        multiline
                        fullWidth
                        label={t(orders.instructions)}
                    />
                </div>
                <Typography variant="h5">{t(orders.billingAddress)}</Typography>
                <Divider />
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="baseline"
                    className={classnames(classes.layoutMargin, classes.gridWidth)}
                >
                    {billingFields.map((field) => {
                        return (
                            <Grid
                                key={field.name}
                                item
                                xs={field.size}
                                className={classes.item}
                            >
                                <Field
                                    fullWidth
                                    component={renderFieldOrText}
                                    readOnly={editionDisabled}
                                    {...field}
                                    label={t(field.label)}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </Layout>
        );
    };
}

const mapStateToProps = (state) => {
    const { deliveryWindows, orderComplaint } = state;
    const { userId } = orderComplaint;
    const selector = formValueSelector("complaintForm");
    const complaintOrderInfo = selector(state, "complaintOrderInfo");
    const errors = getFormSyncErrors("complaintForm")(state);
    const correctedItems = _.chain(selector(state, "lines"))
        .flatMap((x) => x.correctedItems || [])
        .filter((x) => x.type === "missingProducts")
        .map((x) => x.productId)
        .value();

    const upsellLinesSelector = (selector(state, "upsellLines") || []).filter((x) => x.upsellType === "withDelivery").map((x) => x.productId);

    const { windows = [] } = deliveryWindows || {};
    const deliveryWindowsss = windows
        .filter((window) => window.canReserve)
        .map(({ deliveryWindow: { startsAt, endsAt, closesAt } }) => ({
            value: `${startsAt}|${endsAt}|${closesAt}`,
            name: `${moment(startsAt).format("HH-mm")} -
                           ${moment(endsAt).format("HH-mm")}`,
        }));

    return {
        complaintOrderInfo,
        productsIds: [...upsellLinesSelector, ...correctedItems],
        deliveryWindows: deliveryWindowsss,
        errors,
        userId,
    };
};

const mapDispatchToProps = (dispatch) => {
    const { getWindows } = actions;

    return {
        getDeliveryWindows: (payload) => dispatch(getWindows(payload)),
        resetDeliveryWindow: () => dispatch(change("complaintForm", "shippingAddress.deliveryWindow", null)),
    };
};

export default withTranslation("orders")(withStyles(connect(mapStateToProps, mapDispatchToProps)(Delivery), styles));
