import { IconButton } from "components/Button";
import Dropzone from "components/Files/Dropzone";
import { Form, FormField } from "components/Form";
import Layout from "components/Grid/Layout";
import TextWithLabel from "components/Text/TextWithLabel";
import roles from "consts/roles";
import { reduxForm } from "redux-form";
import { createOrUpdate, documentDefinitionFormSelector, getDefinitionDocumentFile, initForm } from "store/dictonaries/documentDefinitions";
import { useChange, useFormValueSelector, useParams } from "utils/hooks";
import { notEmptyValidator } from "utils/validators/basic";
import { useCallback, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Delete as DeleteIcon, GetApp as DownloadIcon } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useUser } from "context";
import { common } from "translations";
import { usePush, useTranslation } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";

const validators = {
    name: (value) => notEmptyValidator(value).validate(),
    required: (value) => notEmptyValidator(value).validate(),
};

const DocumentDefinitionsForm = ({ handleSubmit, isSubbmitRequested, pristine, name }) => {
    const { id } = useParams();
    const { t } = useTranslation("common");
    const { replace } = usePush();
    const dispatch = useDispatch();
    const change = useChange(formName);
    const { isInRoleOrAdmin } = useUser();
    const isAuditor = isInRoleOrAdmin(roles.Auditor);
    useEffect(() => {
        dispatch(initForm(id));
    }, [dispatch, id]);
    const { blobName, fileName } = useFormValueSelector(formName, ["blobName", "fileName"], {});

    const handleDrop = useCallback(
        (acceptedFiles) => {
            acceptedFiles.forEach((file) => {
                const reader = new FileReader();

                reader.onload = function (e) {
                    const fileItem = {
                        extension: `.${file.name.split(".").slice(-1)[0].toLowerCase()}`,
                        fileName: file.name,
                        fileContent: e.target.result.split(",")[1],
                    };

                    change("file", fileItem);
                    change("fileName", file.name);
                    change("blobName", null);
                };

                reader.readAsDataURL(file);
            });
        },
        [change]
    );

    const handleDownloadFile = async ({ fileName }) => {
        dispatch(getDefinitionDocumentFile(id, fileName));
    };

    const readonly = !isAuditor;

    return (
        <Layout
            main
            headerText={t(common.documentDefinition, {
                id: id || t(common.newItem),
            })}
            navigationProps={{
                backProps: { path: Paths.Dictionaries.DocumentDefinitionsList },
            }}
            customTitle={id && name && `${t(common.documentDefinition)} - ${name}`}
        >
            <Form
                onSubmit={handleSubmit(async (values) => {
                    const { payload } = await dispatch(createOrUpdate(values, id));
                    if (Boolean(id)) {
                        await dispatch(initForm(id));
                    } else {
                        replace(Paths.Dictionaries.DocumentDefinitionForm, {
                            id: payload.id,
                        });
                    }
                })}
                isSubbmitRequested={isSubbmitRequested}
                isReadonly={readonly}
                pristine={pristine}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <FormField
                        name="name"
                        type="text"
                        label={t(common.name)}
                        validate={validators.name}
                        readonly={readonly}
                    />

                    <FormField
                        name="description"
                        type="text"
                        label={t(common.description)}
                        validate={validators.name}
                        readonly={readonly}
                    />
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <FormField
                        name="isActive"
                        type="boolean"
                        label={t(common.isActive)}
                        readOnly={readonly}
                    />

                    <FormField
                        name="archiveDocuments"
                        type="boolean"
                        label={t(common.archiveDocuments)}
                        readOnly={readonly}
                    />

                    <FormField
                        name="isVatStatement"
                        type="boolean"
                        label={t(common.isVatStatement)}
                        readOnly={readonly}
                    />
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item>
                        <Dropzone
                            disabled={readonly}
                            onDrop={handleDrop}
                            maxFiles={1}
                            hasFile={fileName}
                            required
                            accept=".pdf"
                        />
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <TextWithLabel
                        value={fileName}
                        label={common.fileName}
                    />

                    <Grid item>
                        <IconButton
                            edge="end"
                            onClick={() => handleDownloadFile({ fileName })}
                            disabled={!Boolean(blobName)}
                            icon={<DownloadIcon />}
                        />
                    </Grid>

                    <Grid item>
                        <IconButton
                            edge="end"
                            onClick={() => {
                                change("file", null);
                                change("fileName", null);
                                change("blobName", null);
                            }}
                            disabled={readonly || !fileName}
                            icon={<DeleteIcon />}
                        />
                    </Grid>
                </Grid>
            </Form>
        </Layout>
    );
};

const formName = "document-definitions-form";

const stateToProps = (state) => {
    const { isSubbmitRequested, ...form } = documentDefinitionFormSelector(state);

    return {
        isSubbmitRequested,
        name: form.name,
        initialValues: {
            ...form,
        },
    };
};

export default connect(stateToProps)(
    reduxForm({
        form: formName,
        enableReinitialize: true,
    })(DocumentDefinitionsForm)
);
