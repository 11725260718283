import { Validator } from "fluentvalidation-ts";
import { DeviceTypeDocument } from "Operations";
import { notEmptyString } from "utils-ts/validations";

class DeviceTypeValidator extends Validator<DeviceTypeDocument> {
    constructor() {
        super();
        this.ruleFor("name").must(notEmptyString).maxLength(100);
    }
}

export default DeviceTypeValidator;