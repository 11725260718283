import _ from "lodash";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mergeDeep = (target: any, source: any) => {
    Object.keys(source).forEach((key) => {
        if (source[key] && typeof source[key] === "object") {
            if (!target[key]) target[key] = Array.isArray(source[key]) ? [] : {};
            mergeDeep(target[key], source[key]);
        } else {
            target[key] = source[key];
        }
    });
    return target;
};

const prefixPath = (prefix?: string) => (prefix ? `${prefix}.` : "");

const deepPathObject = <T extends Record<string, unknown> | unknown>(source: T, prefix?: string): { [p: string]: string } => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let result: any = {};

    for (const i in source) {
        if (
            typeof source[i] === "bigint" ||
            typeof source[i] === "number" ||
            typeof source[i] === "string" ||
            typeof source[i] === "symbol" ||
            typeof source[i] === "boolean"
        ) {
            _.set(result, `${prefixPath(prefix)}${i}`, { message: source[i] });
        } else if (Array.isArray(source[i])) {
            for (const j in source[i]) {
                const subResult = deepPathObject(source[i][j], `${prefixPath(prefix)}${i}.[${j}]`);
                result = mergeDeep(result, subResult);
            }
        } else {
            result = {
                ...result,
                ...deepPathObject(source[i], `${prefixPath(prefix)}${i}`),
            };
        }
    }
    return result;
};

export default deepPathObject;
