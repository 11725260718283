import { manager, operations } from "store/actionTypePrefixes";
import { AsyncTypes, PagedAsyncTypes } from "utils/extensions";

const types = {
    list: new PagedAsyncTypes("GET_DELIVERY_COMPLAINTS", operations),
    select: `${manager}/SELECT_DELIVERY_COMPLAINT`,
    deleteTypes: new AsyncTypes("DELETE_DELIVERY_COMPLAINT", operations),
};

export default types;
