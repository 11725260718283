import { api } from "api";
import FormAutocomplete, { FormAutocompleteProps } from "../FormAutocomplete";

type FormVendorAutocomplete = Pick<FormAutocompleteProps, "name" | "label" | "readOnly" | "multiple" | "width"> & {
    filter?: Array<"Active" | "Producer" | "GoodsVendor" | "TechnicalGoodsVendor">;
    valueWithoutMerchant?: boolean;
};

const FormVendorAutocomplete: React.FC<FormVendorAutocomplete> = ({ name, label, readOnly, width, multiple, filter, valueWithoutMerchant }) => {
    const { data: vendors = [] } = api.operations.autocomplete.useFindVendors();

    return (
        <FormAutocomplete
            name={name}
            label={label}
            items={vendors
                .filter((v) => {
                    let result = true;
                    if (readOnly) {
                        return result;
                    }

                    if (filter) {
                        if (filter.includes("Active")) {
                            result = v.isActive;
                        }

                        if (filter.includes("GoodsVendor")) {
                            result = result && v.isGoodsVendor;
                        }

                        if (filter.includes("Producer")) {
                            result = result && v.isProducer;
                        }

                        if (filter.includes("TechnicalGoodsVendor")) {
                            result = result && (v.isTechnicalGoodsVendor || (v.isGoodsVendor && v.isActive));
                        }
                    }

                    return result;
                })
                .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
                .map((v) => {
                    const axId = v.vendorId.split("_")[0];

                    return {
                        name: `${v.name} (${axId})${v.isActive ? "" : " - nieaktywny"}`,
                        value: valueWithoutMerchant ? axId : v.vendorId,
                    };
                })}
            readOnly={readOnly}
            multiple={multiple}
            width={width}
        />
    );
};

export default FormVendorAutocomplete;
