import { withStyles } from "tss-react/mui";
import Chip from "@mui/material/Chip";

// const options = {
//     shouldForwardProp: (prop) => prop !== "bgcolor" && prop !== "color",
// };

// const getSchemeColors = (colorscheme) => {
//     switch (colorscheme) {
//         case "error":
//             return { color: "#581410", bgcolor: "#fbeae9" };
//         case "default":
//         default: {
//         }
//     }
// };

// const StyledChip = styled(
//     Chip,
//     options
// )(({ colorscheme }) => {
//     var colors = getSchemeColors(colorscheme);
//     return {
//         color: colors?.color,
//         backgroundColor: colors?.bgcolor,
//     };
// });

export default withStyles(Chip, (theme, { colorScheme }) => ({
    root: {
        color: colorScheme === "error" ? "#581410" : undefined,
        backgroundColor: colorScheme === "error" ? "#fbeae9" : undefined,
    },
}));
