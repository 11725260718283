import TextWithLabel from "components/Text/TextWithLabel";
import { formatTypes } from "utils/formating";
import { common } from "translations";
import ProductField from "views/offers/productPreview/components/ProductField";

const ProductMeasurements = ({ measurements = {} }) => {
    const { width, weight, depth, height } = measurements;

    const info = [
        new ProductField(common.width, width, formatTypes.numeric),
        new ProductField(common.height, height, formatTypes.numeric),
        new ProductField(common.productDepth, depth, formatTypes.numeric),
        new ProductField(common.weight, weight, formatTypes.numeric),
    ];

    return info.map((f) => (
        <TextWithLabel
            unselectableLabel
            key={f.label}
            {...f}
        />
    ));
};

export default ProductMeasurements;
