import { FieldPath, FieldValues } from "react-hook-form";
import { FormControlProps } from "./types";
import { Switch } from "components-ts/controls";
import { default as Controller } from "./Controller";

type Props<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = FormControlProps<
    string[],
    TFieldValues,
    TName
> & {
    onCustomChange?: (value: boolean | undefined, name: string) => void;
};

const FormSwitch = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
    name,
    label,
    defaultValue,
    required,
    width,
    readOnly,
    onCustomChange,
}: Props<TFieldValues, TName>): JSX.Element => {
    return (
        <Controller
            name={name}
            render={({ field: { ref, value, onChange, disabled: _, ...fieldProps }, fieldState: { error } }) => {
                return (
                    <Switch
                        ref={ref}
                        {...fieldProps}
                        onChange={(newValue) => {
                            onChange(newValue);
                            if (onCustomChange) {
                                onCustomChange(newValue, name);
                            }
                        }}
                        required={required}
                        label={label}
                        value={Boolean(value)}
                        error={{
                            hasError: Boolean(error),
                            message: error?.message,
                        }}
                        readOnly={readOnly}
                    />
                );
            }}
            defaultValue={defaultValue || false}
            width={width}
        />
    );
};

export default FormSwitch;
