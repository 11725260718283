import { CartPredicate, UserActivationLimit } from "components/Controls";
import Layout from "components/Grid/Layout";
import { FieldArray, FormSection } from "redux-form";
import { useCommonTranslation } from "utils/hooks";
import GiftEffects from "./GiftEffects";
import { validators } from "./validators";

const GiftChoiceDefinition = ({ readOnly = false }) => {
    const { t, common } = useCommonTranslation();

    return (
        <FormSection name="definition">
            <UserActivationLimit readOnly={readOnly} />

            <CartPredicate
                name="requirements"
                required={true}
                readOnly={readOnly}
            />

            <Layout headerText={t(common.effect)}>
                <FieldArray
                    name="effects"
                    component={GiftEffects}
                    validate={validators.effects}
                    readOnly={readOnly}
                />
            </Layout>
        </FormSection>
    );
};

export default GiftChoiceDefinition;
