import { ArrayValidationError, CartPredicate, DivisionSelect, PromotionPoolPredicateFields } from "components/Controls";
import { Addable, Erasable, FormField } from "components/Form";
import { decimalNormalize } from "components/FormHelpers/ControlFormaters";
import Layout from "components/Grid/Layout";
import { FieldArray, FormSection, change } from "redux-form";
import { toSelectList } from "utils/extensions/arrayExtensions";
import { withFormName } from "utils/hoc";
import { useFormValueSelector } from "utils/hooks";
import { emailValidator } from "utils/validators";
import { useDispatch } from "react-redux";
import { Chip, Grid } from "@mui/material";
import { common, voucherKinds } from "translations";
import { useTranslation } from "utils-ts/hooks";
import PromotionDefinition from "views/offers/promotions/components/PromotionDefinition";
import { validators } from "../validators";

const VoucherPromotions = ({ fields, meta }) => {
    return (
        <Layout
            headerText="Definicja promocji"
            subtitle
        >
            <ArrayValidationError {...meta} />
            <Addable
                handleAdd={() => {
                    fields.push();
                }}
            >
                {fields.map((field, index) => {
                    return (
                        <Erasable
                            key={field}
                            handleRemove={() => {
                                fields.remove(index);
                            }}
                        >
                            <FormSection name={field}>
                                <PromotionDefinition isVoucherEmbedded={true} />
                            </FormSection>
                        </Erasable>
                    );
                })}
            </Addable>
        </Layout>
    );
};

const VoucherDefinition = ({ form, sectionPrefix, name = "definition" }) => {
    const { t } = useTranslation(["common", "voucherKinds"]);
    const dispatch = useDispatch();
    const section = `${sectionPrefix ? `${sectionPrefix}.${name}` : name}`;
    const definition = useFormValueSelector(form, section);
    const { voucherType } = definition;
    let { merchant } = definition;
    const dispatchChange = (field) => dispatch(change(form, `${section}.${field}`, null));

    if (definition.paymentAmount && definition.merchant == null) {
        let valueF24, valueFrs;
        if (definition.paymentAmount.F24) {
            valueF24 = definition.paymentAmount.F24;
            definition.paymentAmount.f24 = valueF24;
            definition.merchant = "f24";
            merchant = "f24";
        } else if (definition.paymentAmount.FRS) {
            valueFrs = definition.paymentAmount.FRS;
            definition.paymentAmount.frs = valueFrs;
            definition.merchant = "frs";
            merchant = "frs";
        }
    }

    const handleVoucherTypeChange = (value) => {
        if (value !== voucherType) {
            switch (value) {
                case voucherKinds.promotion_voucher:
                    dispatchChange("voucherValue");
                    dispatchChange("voucherPercent");
                    dispatchChange("voucherAmount");
                    dispatchChange("includeProducts");
                    dispatchChange("excludeProducts");
                    break;
                case voucherKinds.value_voucher:
                    dispatchChange("voucherPercent");
                    dispatchChange("voucherAmount");
                    break;
                case voucherKinds.percent_voucher:
                    dispatchChange("voucherValue");
                    dispatchChange("voucherAmount");
                    break;
                case voucherKinds.amount_voucher:
                    dispatchChange("voucherValue");
                    dispatchChange("voucherPercent");
                    dispatchChange("includeProducts");
                    dispatchChange("excludeProducts");
                    dispatch(change(form, `${section}.merchant`, common.frs));
                    break;
                case voucherKinds.cashback_voucher:
                    dispatchChange("cashbackPercent");
                    break;
                default:
                    break;
            }
        }
    };

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <FormSection
                    name="definition"
                    style={{ width: "100%" }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <DivisionSelect selectedDivisions={definition.divisions} />
                        <FormField
                            name="useLimit"
                            label={t(common.useLimit)}
                            type="number"
                            validate={validators.useLimit}
                        />
                        <FormField
                            name="voucherType"
                            label={t(common.voucherType)}
                            type="select"
                            onChange={(_, value) => {
                                handleVoucherTypeChange(value);
                            }}
                            items={toSelectList(Object.keys(voucherKinds), t, "voucherKinds")}
                            validate={validators.voucherType}
                        />
                        <FormField
                            label={t(common.voucherValue)}
                            name="voucherValue"
                            type="text"
                            hidden={voucherType !== voucherKinds.value_voucher}
                            normalize={decimalNormalize}
                            validate={validators.voucherValue}
                        />
                        <FormField
                            label={t(common.voucherPercent)}
                            name="voucherPercent"
                            type="text"
                            hidden={voucherType !== voucherKinds.percent_voucher}
                            normalize={decimalNormalize}
                            validate={validators.voucherPercent}
                        />
                        <FormField
                            label={t(common.cashbackPercent)}
                            name="cashbackPercent"
                            type="text"
                            hidden={voucherType !== voucherKinds.cashback_voucher}
                            normalize={decimalNormalize}
                            validate={validators.voucherPercent}
                        />
                        <FormField
                            label={t(common.cashbackLimit)}
                            name="cashbackLimit"
                            type="text"
                            hidden={voucherType !== voucherKinds.cashback_voucher}
                            normalize={decimalNormalize}
                            validate={validators.voucherCashbackLimit}
                        />
                        <FormField
                            label={t(common.merchant)}
                            name={"merchant"}
                            type={"toggle"}
                            hidden={voucherType !== voucherKinds.amount_voucher}
                            items={toSelectList(["FRS", "F24"], t, "common").map((x) => {
                                return {
                                    value: x.value,
                                    name: x.name,
                                    disabled: x.value.toLowerCase() === common.f24,
                                };
                            })}
                            validate={validators.voucherAmountType}
                        />
                        <FormField
                            label={t(common.amountFrisco)}
                            name={"paymentAmount.FRS"}
                            type={"text"}
                            hidden={voucherType !== voucherKinds.amount_voucher || merchant !== "frs"}
                            normalize={decimalNormalize}
                            validate={validators.voucherAmount}
                        />
                        <FormField
                            label={t(common.amountF24)}
                            name={"paymentAmount.F24"}
                            type={"text"}
                            hidden={voucherType !== voucherKinds.amount_voucher || merchant !== "f24"}
                            normalize={decimalNormalize}
                            validate={validators.voucherAmount}
                        />
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <FormField
                            name="onlyIdentityVerifiedFirstOrder"
                            label={t(common.onlyIdentityVerifiedFirstOrder)}
                            type="boolean"
                        />
                        <FormField
                            name="includeAlcohol"
                            label={t(common.includeAlcohol)}
                            type="boolean"
                        />
                        <FormField
                            name="includeTobacco"
                            label={t(common.includeTobacco)}
                            type="boolean"
                        />
                        <FormField
                            name="isCumulative"
                            label={t(common.isCumulative)}
                            type="boolean"
                        />
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <FormField
                            name="userActivationLimit"
                            label={t(common.userActivationLimit)}
                            type="text"
                            validate={[validators.userActivationLimit]}
                        />
                        <FormField
                            name="userActivationLimitExpirationPeriod"
                            label={t(common.userActivationLimitExpirationPeriod)}
                            type="timeSpan"
                            validate={validators.userActivationLimitExpirationPeriod}
                        />
                        <FormField
                            name="minimumTotalWithDeliveryCostOverride"
                            label={t(common.minimumTotalWithDeliveryCostOverride)}
                            type="text"
                            normalize={decimalNormalize}
                            validate={validators.mustBeNumberValidator}
                        />
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="stretch"
                    >
                        <FormField
                            width="700px"
                            name="allowedEmailHosts"
                            label={t(common.allowedEmailHosts)}
                            type="list"
                            useList={false}
                            additionalValueCheck={(value) => {
                                const result = emailValidator(`mail@${value}`).validate();
                                return !Boolean(result);
                            }}
                            itemRenderer={(v, handleRemove) => (
                                <Chip
                                    key={v}
                                    style={{ marginRight: 10, marginTop: 10 }}
                                    size="medium"
                                    label={v}
                                    color="default"
                                    onDelete={() => handleRemove(v)}
                                />
                            )}
                        />
                    </Grid>
                    <CartPredicate name="requirements" />
                </FormSection>
            </Grid>
            {(voucherType === voucherKinds.percent_voucher ||
                voucherType === voucherKinds.value_voucher ||
                voucherType === voucherKinds.cashback_voucher) && (
                <PromotionPoolPredicateFields
                    formName={form}
                    prefix="definition"
                    renderRequiremtnts={false}
                    required={false}
                />
            )}

            <FormSection name="definition">
                <FieldArray
                    name="voucherPromotions"
                    component={VoucherPromotions}
                    validate={voucherType === voucherKinds.promotion_voucher ? validators.promotions : undefined}
                />
            </FormSection>
        </>
    );
};

export default withFormName(VoucherDefinition);
