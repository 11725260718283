import { manager } from "store/actionTypePrefixes";
import { actions as balanceActions } from "./customerBalance//action";
import { actions as infoActions } from "./customerInfo/action";
import { getCustomerMembership } from "./customerMembership";

export const types = {
    CUSTOMER_CHANGED: `${manager}/CUSTOMER_CHANGED`,
};

const getCustomer = (customerId) => (dispatch) => {
    dispatch(infoActions.getCustomerInfo(customerId));
    dispatch(getCustomerMembership(customerId));
    dispatch(balanceActions.getCustomerBalance(customerId));
};

const changeCustomer = (customerId) => (dispatch) => {
    return dispatch({ type: types.CUSTOMER_CHANGED, payload: customerId });
};

export const actions = {
    getCustomer,
    changeCustomer,
};
