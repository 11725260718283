import { withStyles } from "tss-react/mui";
import Grid from "@mui/material/Grid";

const style = {
    grid: {
        padding: "0 15px !important",
    },
};

function GridItem({ ...props }) {
    const { children, ...rest } = props;
    const classes = withStyles.getClasses(props);

    return (
        <Grid
            item
            {...rest}
            className={classes.grid}
        >
            {children}
        </Grid>
    );
}

export default withStyles(GridItem, style);
