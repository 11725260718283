import GridContainer from "components/Grid/GridContainer";
import roles, { LogisticianDepartmentRoles, TradeMarketingDepartmentRoles } from "consts/roles";
import { reduxForm } from "redux-form";
import { actions } from "store/customers/customer/customerInfo/action";
import { useDebouncedCallback } from "use-debounce";
import React from "react";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { useUser } from "context";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { common, customers } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { allCustomerRolesWithAuditor, allCustomerRolesWithAuditorAndHRManager, baseWithEcommerce } from "routing-ts/Routes/CustomerRoles";
import Segment from "./Segment";

const Segments = ({ customerId, putSegments }) => {
    const { isInAnyRoleOrAdmin } = useUser();
    const isNotInBaseRoles = !isInAnyRoleOrAdmin(baseWithEcommerce);

    //prettier-ignore
    const pernamentSettings = [
        { label: customers.specialClient,             name: "commerce:SpecialClient",             roles: allCustomerRolesWithAuditorAndHRManager, readOnly: isNotInBaseRoles },
        { label: customers.vipAccount,                name: "commerce:VipAccount",                roles: allCustomerRolesWithAuditorAndHRManager, readOnly: isNotInBaseRoles },
        { label: customers.testAccount,               name: "commerce:TestAccount",               roles: allCustomerRolesWithAuditorAndHRManager, readOnly: isNotInBaseRoles },
        { label: customers.fullMembership,            name: "commerce:FullMembership",            roles: allCustomerRolesWithAuditorAndHRManager, readOnly: !isInAnyRoleOrAdmin([...TradeMarketingDepartmentRoles, ...baseWithEcommerce]) },
        { label: customers.disablePersonalization,    name: "commerce:DisablePersonalization",    roles: allCustomerRolesWithAuditorAndHRManager, readOnly: isNotInBaseRoles },
        { label: customers.disableReview,             name: "commerce:DisableReview",             roles: allCustomerRolesWithAuditorAndHRManager, readOnly: isNotInBaseRoles },
        { label: customers.disableOrderLimits,        name: "commerce:DisableOrderLimits",        roles: allCustomerRolesWithAuditorAndHRManager, readOnly: isNotInBaseRoles },
        { label: customers.disableRationing,          name: "commerce:DisableRationing",          roles: allCustomerRolesWithAuditorAndHRManager, readOnly: isNotInBaseRoles },
        { label: customers.disableTotalLimit,         name: "commerce:DisableTotalLimit",         roles: allCustomerRolesWithAuditorAndHRManager, readOnly: isNotInBaseRoles },
        { label: customers.noWaterLimits,             name: "commerce:NoWaterLimits",             roles: allCustomerRolesWithAuditorAndHRManager, readOnly: isNotInBaseRoles },
        { label: customers.noProductLimits,           name: "commerce:NoProductLimits",           roles: allCustomerRolesWithAuditorAndHRManager, readOnly: isNotInBaseRoles },
        { label: customers.friscoWorkers,             name: "commerce:FriscoWorkers",             roles: allCustomerRolesWithAuditorAndHRManager, readOnly: !isInAnyRoleOrAdmin([roles.HumanResourcesManager]) },
        { label: customers.collectiveInvoice,         name: "commerce:CollectiveInvoice",         roles: allCustomerRolesWithAuditorAndHRManager, readOnly: isNotInBaseRoles },
        { label: customers.electronicInvoice,         name: "commerce:ElectronicInvoice",         roles: allCustomerRolesWithAuditorAndHRManager, readOnly: isNotInBaseRoles },
        { label: customers.savedReservations ,        name: "commerce:SavedReservations",         roles: allCustomerRolesWithAuditorAndHRManager, readOnly: isNotInBaseRoles },
        { label: customers.pushbackWindows,           name: "commerce:PushbackWindows",           roles: allCustomerRolesWithAuditorAndHRManager, readOnly: isNotInBaseRoles },
        { label: customers.disableTexts,              name: "commerce:DisableTexts",              roles: allCustomerRolesWithAuditorAndHRManager, readOnly: isNotInBaseRoles },
        { label: customers.disableTrustMate,          name: "commerce:DisableTrustMate",          roles: allCustomerRolesWithAuditorAndHRManager, readOnly: isNotInBaseRoles },
        { label: customers.allowMissingPayment,       name: "commerce:AllowMissingPayment",       roles: allCustomerRolesWithAuditorAndHRManager, readOnly: isNotInBaseRoles },
        { label: customers.disableReservationTexts,   name: "commerce:DisableReservationTexts",   roles: allCustomerRolesWithAuditorAndHRManager, readOnly: isNotInBaseRoles },
        { label: customers.disableReservationMails,   name: "commerce:DisableReservationMails",   roles: allCustomerRolesWithAuditorAndHRManager, readOnly: isNotInBaseRoles },
        { label: customers.disableLetters,            name: "commerce:DisableLetters",            roles: allCustomerRolesWithAuditorAndHRManager, readOnly: isNotInBaseRoles },
        { label: customers.disableGmailMarkup,        name: "commerce:DisableGmailMarkup",        roles: allCustomerRolesWithAuditorAndHRManager, readOnly: isNotInBaseRoles },
        { label: customers.DisallowUserBatches,       name: "commerce:DisallowUserBatches",       roles: allCustomerRolesWithAuditorAndHRManager, readOnly: isNotInBaseRoles },
        { label: customers.DisallowAdult,             name: "commerce:DisallowAdult",             roles: allCustomerRolesWithAuditorAndHRManager, readOnly: isNotInBaseRoles },
        { label: customers.DisableOnDeliveryPayments, name: "commerce:DisableOnDeliveryPayments", roles: allCustomerRolesWithAuditorAndHRManager, readOnly: isNotInBaseRoles },
        { label: customers.BindWarehouseWAW,          name: "commerce:BindWarehouseWAW",          roles: allCustomerRolesWithAuditorAndHRManager, readOnly: isNotInBaseRoles },
    ];

    const temponarySettings = [
        {
            label: customers.unlimitedWindows,
            name: "commerce:UnlimitedWindows",
            roles: [...baseWithEcommerce, ...LogisticianDepartmentRoles],
            readOnly: !isInAnyRoleOrAdmin([...baseWithEcommerce, ...LogisticianDepartmentRoles]),
        },
        {
            label: customers.forceWarehousePOZ,
            name: "commerce:ForceWarehousePOZ",
            roles: [...baseWithEcommerce, ...LogisticianDepartmentRoles],
            readOnly: !isInAnyRoleOrAdmin([...baseWithEcommerce, ...LogisticianDepartmentRoles]),
        },
        {
            label: customers.forceWarehouseWAW,
            name: "commerce:ForceWarehouseWAW",
            roles: [...baseWithEcommerce, ...LogisticianDepartmentRoles],
            readOnly: !isInAnyRoleOrAdmin([...baseWithEcommerce, ...LogisticianDepartmentRoles]),
        },
        {
            label: customers.forceWarehouseWA3,
            name: "commerce:ForceWarehouseWA3",
            roles: [...baseWithEcommerce, ...LogisticianDepartmentRoles],
            readOnly: !isInAnyRoleOrAdmin([...baseWithEcommerce, ...LogisticianDepartmentRoles]),
        },
        {
            label: customers.forceWarehouseWRO,
            name: "commerce:ForceWarehouseWRO",
            roles: [...baseWithEcommerce, ...LogisticianDepartmentRoles],
            readOnly: !isInAnyRoleOrAdmin([...baseWithEcommerce, ...LogisticianDepartmentRoles]),
        },
        {
            label: customers.disableVan,
            name: "commerce:DisableVan",
            roles: [...baseWithEcommerce, ...LogisticianDepartmentRoles],
            readOnly: !isInAnyRoleOrAdmin([...baseWithEcommerce, ...LogisticianDepartmentRoles]),
        },
        {
            label: customers.disableMails,
            name: "commerce:DisableMails",
            roles: allCustomerRolesWithAuditor,
            readOnly: isNotInBaseRoles,
        },
        {
            label: customers.disablePromotions,
            name: "commerce:DisablePromotions",
            roles: [...baseWithEcommerce],
            readOnly: !isInAnyRoleOrAdmin([...baseWithEcommerce]),
        },
        {
            label: customers.disableCosts,
            name: "commerce:DisableCosts",
            roles: allCustomerRolesWithAuditor,
            readOnly: isNotInBaseRoles,
        },
        {
            label: customers.unlimitedStock,
            name: "commerce:UnlimitedStock",
            roles: [roles.Administrator],
        },
    ];

    const [segments, changeSegments] = React.useState({});
    const debouncedPutSegements = useDebouncedCallback((segments) => {
        putSegments(customerId, segments);
    }, 1000);
    const handleChange = (name, checked) => {
        const newSegments = { ...segments };
        newSegments[name] = checked;
        changeSegments(newSegments);
        debouncedPutSegements(newSegments);
    };
    const { t } = useTranslation(["customers", "common"]);

    const fragments = _.chunk(
        pernamentSettings.map((x) => ({ ...x, label: t(x.label) })),
        Math.ceil(pernamentSettings.length / 3)
    );

    const temponaryFragments = _.chunk(
        temponarySettings.map((x) => ({ ...x, label: t(x.label), name: x.name })),
        Math.ceil(pernamentSettings.length / 3)
    );

    return (
        <form>
            <GridContainer
                iconName="settings_applications"
                title={`common:${common.settings}`}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
            >
                {fragments.map((fragment, i) => {
                    return (
                        <Grid
                            key={i}
                            item
                            style={{ marginLeft: "20px" }}
                        >
                            <Grid
                                container
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                {fragment.map((field, i) => (
                                    <Segment
                                        key={i}
                                        handleChange={handleChange}
                                        {...field}
                                    />
                                ))}
                            </Grid>
                        </Grid>
                    );
                })}
            </GridContainer>
            <GridContainer
                iconName="settings_applications"
                title={`common:${common.temponarySettings}`}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
            >
                {temponaryFragments.map((fragment, i) => {
                    return (
                        <Grid
                            key={i}
                            item
                            style={{ marginLeft: "20px" }}
                        >
                            <Grid
                                container
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                {fragment.map((field, i) => (
                                    <Segment
                                        key={i}
                                        handleChange={handleChange}
                                        {...field}
                                    />
                                ))}
                            </Grid>
                        </Grid>
                    );
                })}
            </GridContainer>
        </form>
    );
};

const mapStateToProps = (__, ownProps) => {
    const { segments = [] } = ownProps;
    const initialSegments = Object.assign({}, ...segments.map((segment) => ({ [segment]: true })));
    return {
        initialSegments: initialSegments,
        initialValues: initialSegments,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: "segments-form",
        enableReinitialize: true,
    })(Segments)
);
