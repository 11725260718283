import SimpleTable, { createColumn } from "components/Table/SimpleTable/SimpleTable";
import tableActionTypes from "components/Table/SimpleTable/TableActions/tableActionTypes";
import { reduxForm } from "redux-form";
import { userInfoWithRoleSelector } from "store/autocomplete";
import { certyficateFormSelector, getCertificateFile } from "store/vendors/certificates";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useParams } from "utils/hooks";
import { connect, useDispatch, useSelector } from "react-redux";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";

const CertificateHistory = () => {
    const { vendorId, certificateId } = useParams();
    const { t } = useTranslation("vendor");
    const dispatch = useDispatch();
    const { fileHistory = [], isLoading } = useSelector(certyficateFormSelector);
    const users = useSelector(userInfoWithRoleSelector);
    const usersWithRoles = [...users?.Administrator, ...users?.Catman, ...users?.SalesAssistant, ...users?.SalesManager];

    const handleDownload = (item) => {
        dispatch(getCertificateFile(vendorId, certificateId, item.file.blobName));
    };

    const columns = [
        createColumn("uploadDate", t(vendor.uploadDate), formatTypes.time, { timeFormat: timePrecisonFormats.seconds }),
        createColumn("fileName", t(vendor.name), formatTypes.text),
        createColumn("user", t(vendor.user), formatTypes.text),
        createColumn("action", "", formatTypes.action, { actionType: tableActionTypes.download }, handleDownload),
    ];

    return (
        <SimpleTable
            isLoading={isLoading}
            columns={columns}
            data={fileHistory
                .sort((a, b) => Date.parse(b.uploadDate) - Date.parse(a.uploadDate))
                .map((f) => ({
                    ...f,
                    user: usersWithRoles.find((u) => u.userId == f.userId)?.email || f.userId,
                }))}
            hover
            stripped
        />
    );
};

const formName = "certificate-history";

export default connect((state) => {
    const form = certyficateFormSelector(state);
    const history = form?.fileHistory || [];

    return {
        initialValues: { history },
    };
})(
    reduxForm({
        form: formName,
        enableReinitialize: true,
    })(CertificateHistory)
);
