import types from "./types";

const initialState = {
    user: null,
    init: false,
    inProgress: false,
};

export const userDetails = (state = initialState, action) => {
    state = state || initialState;
    switch (action.type) {
        case types.getUserDetails.request:
            return { ...state, init: true };
        case types.getUserDetails.success:
            return {
                ...state,
                user: action.payload,
                init: false,
            };

        case types.updateUserFullname.success:
            return {
                ...state,
                user: {
                    ...state.user,
                    fullName: {
                        firstName: action.payload.firstName,
                        lastName: action.payload.lastName,
                    },
                },
            };
        case types.updateUserEmail.success:
            return { ...state, user: { ...state.user, email: action.payload.email } };
        case types.updateUserRoles.success:
            return { ...state, ...action.payload };
        case types.updateUserVendors.success:
            return { ...state, user: { ...state.user, vendorIds: action.payload.vendorIds } };
        case types.updateUserActivision.success:
            return { ...state, user: { ...state.user, isDeactivated: !state.user.isDeactivated } };
        default:
            return state;
    }
};
