import { RSAA } from "redux-api-middleware";
import { commerce } from "store/actionTypePrefixes";
import { asAsyncFormTypes } from "utils/extensions/arrayExtensions";
import { combineIntoQueryParams } from "utils/objectExtensions";

export const types = {
    GET_PRODUCT_RESTRICTIONS: `${commerce}/GET_PRODUCT_RESTRICTIONS`,
    GET_PRODUCT_RESTRICTIONS_SUCCESS: `${commerce}/GET_PRODUCT_RESTRICTIONS_SUCCESS`,
    GET_PRODUCT_RESTRICTIONS_FAILURE: `${commerce}/GET_PRODUCT_RESTRICTIONS_FAILURE`,
    REMOVE_PRODUCT_RESTRICTION: `${commerce}/REMOVE_PRODUCT_RESTRICTION`,
    REMOVE_PRODUCT_RESTRICTION_SUCCESS: `${commerce}/REMOVE_PRODUCT_RESTRICTION_SUCCESS`,
    REMOVE_PRODUCT_RESTRICTION_FAILURE: `${commerce}/REMOVE_PRODUCT_RESTRICTION_FAILURE`,
};

const getProductRestrictions =
    ({ isActive, ...rest }) =>
    async (dispatch) => {
        let query = { ...rest };
        if (isActive !== "") query.isActive = isActive;
        const queryParams = combineIntoQueryParams({ ...query });
        return dispatch({
            [RSAA]: {
                endpoint: `api/v1/offer/products/restrictions?${queryParams}`,
                headers: { "Content-Type": "application/json" },
                method: "GET",
                types: [types.GET_PRODUCT_RESTRICTIONS, types.GET_PRODUCT_RESTRICTIONS_SUCCESS, types.GET_PRODUCT_RESTRICTIONS_FAILURE],
            },
        });
    };

const remove = (id) => async (dispatch) => {
    return dispatch({
        [RSAA]: {
            endpoint: `api/v1/offer/products/restrictions/${id}`,
            headers: { "Content-Type": "application/json" },
            method: "DELETE",
            types: asAsyncFormTypes([
                types.REMOVE_PRODUCT_RESTRICTION,
                types.REMOVE_PRODUCT_RESTRICTION_SUCCESS,
                types.REMOVE_PRODUCT_RESTRICTION_FAILURE,
            ]),
        },
    });
};

export const actions = {
    getProductRestrictions,
    remove,
};
