import { default as appRoles } from "consts/roles";
import React from "react";
import { useAuth } from "react-oidc-context";

export const UserContext = React.createContext();
export const UserProvider = ({ children }) => {
    const auth = useAuth();
    const { user } = auth;
    const { profile } = user || {};
    const { role: roles = [] } = profile || {};
    const isInRole = (role) => (role === "Logged" && !!user) || (Array.isArray(roles) ? roles.some((r) => r === role) : roles === role);
    const isAdmin = isInRole(appRoles.Administrator);
    const isInRoleOrAdmin = (role) => isInRole(role) || isAdmin;
    const isInAnyRole = (rolesToCheck) => {
        for (const roleToCheck of rolesToCheck) {
            if (isInRole(roleToCheck)) {
                return true;
            }
        }

        return false;
    };

    const isInAnyRoleOrAdmin = (rolesToCheck) => {
        if (isAdmin) {
            return true;
        }

        for (const roleToCheck of rolesToCheck) {
            if (isInRole(roleToCheck)) {
                return true;
            }
        }

        return false;
    };

    return (
        <UserContext.Provider
            value={{
                ...user,
                profile,
                isInRole,
                isAdmin,
                isInRoleOrAdmin,
                isInAnyRole,
                isInAnyRoleOrAdmin,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    return React.useContext(UserContext);
};
export const UserConsumer = ({ children }) => {
    return <UserContext.Consumer>{children}</UserContext.Consumer>;
};
