import { RSAA } from "redux-api-middleware";
import { commerce } from "store/actionTypePrefixes";
import { combineIntoQueryParams } from "utils/objectExtensions";

export const types = {
    CUSTOMER_REVIEWS_GET: `${commerce}/CUSTOMER_REVIEWS_GET`,
    CUSTOMER_REVIEWS_GET_SUCCESS: `${commerce}/CUSTOMER_REVIEWS_GET_SUCCESS`,
    CUSTOMER_REVIEWS_GET_FAILURE: `${commerce}/CUSTOMER_REVIEWS_GET_FAILURE`,
};

const getCustomerReviews =
    ({ customerId, pageIndex, pageSize }) =>
    async (dispatch) => {
        const params = combineIntoQueryParams({ pageIndex, pageSize });
        await dispatch({
            [RSAA]: {
                endpoint: `api/v1/users/${customerId}/orders/reviews?${params}`,
                method: "GET",
                types: [types.CUSTOMER_REVIEWS_GET, types.CUSTOMER_REVIEWS_GET_SUCCESS, types.CUSTOMER_REVIEWS_GET_FAILURE],
            },
        });
    };

export const actions = {
    getCustomerReviews,
};
