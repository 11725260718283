import { Button } from "components/Button";
import { withStyles } from "tss-react/mui";
import { useClickPreventionOnDoubleClick, useCommonTranslation } from "utils/hooks";
import { DialogActions, DialogContent } from "@mui/material";
import PropTypes from "prop-types";
import Dialog from "./Dialog";

const styles = (theme) => ({
    accept: {
        "backgroundColor": theme.palette.success.main,
        "color": theme.palette.success.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.success.dark,
            color: theme.palette.success.contrastText,
        },
    },
    reject: {
        "backgroundColor": theme.palette.error.main,
        "color": theme.palette.success.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.error.dark,
            color: theme.palette.success.contrastText,
        },
    },
});

const AcceptRejectDialog = ({
    classes,
    children,
    handleAccept,
    handleReject,
    buttonAcceptText,
    buttonRejectText,
    isSubmit,
    disableAccept,
    ...rest
}) => {
    const { t, common } = useCommonTranslation();
    const [handleClick] = useClickPreventionOnDoubleClick(handleAccept, handleAccept);

    return (
        <Dialog
            {...rest}
            onClose={handleReject}
        >
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button
                    disabled={disableAccept}
                    actionType="accept"
                    type={isSubmit ? "submit" : undefined}
                    onClick={handleClick}
                    className={classes.accept}
                >
                    {buttonAcceptText || t(common.yes)}
                </Button>
                <Button
                    onClick={handleReject}
                    actionType="danger"
                >
                    {buttonRejectText || t(common.no)}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

Dialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleAccept: PropTypes.func,
    handleReject: PropTypes.func,
    maxWidth: PropTypes.string,
    title: PropTypes.string,
    buttonAcceptText: PropTypes.string,
    buttonRejectText: PropTypes.string,
};

export default withStyles(AcceptRejectDialog, styles);
