import Cache, { cacheNames } from "utils/cache/cacheProvider";
import moment from "moment";
import types from "./types";

const initialState = {
    product: undefined,
    productAttributes: [],
    images: [],
    productImagesUpdating: false,
    productAttributesModificationDate: undefined,
    categoryTreesModificationDate: undefined,
    categoryTrees: undefined,
    changedArrayAttributes: {},
    initialAttributes: [],
    productAttributeUpdating: false,
    attributesFrom: "None",
    forceIntegrationImages: false,
    variant: undefined,
    productSections: [],
    productInternalData: {},
};

const getImages = (product, variantId) => {
    if (!variantId) {
        return [...product.images, ...product.integrationImages];
    }

    const productVariant = getVariant(product, variantId);

    return [...productVariant.images, ...productVariant.integrationImages];
};

const getVariant = (product, variantId) => product.variantsData.find((x) => Number(x.variantId) === Number(variantId));

export const productDetails = (state = initialState, action) => {
    state = state || initialState;
    switch (action.type) {
        case types.changeVariant:
            if (state.product.productId === action.payload) {
                return {
                    ...state,
                    variant: undefined,
                    productImagesUpdating: false,
                    images: getImages(state.product),
                    forceIntegrationImages: state.product.forceIntegrationImages,
                };
            } else {
                const variantId = action.payload;
                const variant = getVariant(state.product, variantId);
                return {
                    ...state,
                    variant: variantId,
                    productImagesUpdating: false,
                    images: getImages(state.product, variantId),
                    forceIntegrationImages: variant.forceIntegrationImages,
                };
            }
        case types.productGetDetails.request:
            return {
                ...state,
                images: [],
                product: undefined,
                changedArrayAttributes: {},
                initialAttributes: [],
                variant: undefined,
            };
        case types.productGetDetails.success:
            return {
                ...state,
                product: action.payload,
                initialAttributes: [...action.payload.attributes],
                images: getImages(action.payload),
                attributesFrom: action.payload.attributesFrom || "None",
                forceIntegrationImages: action.payload.forceIntegrationImages,
            };
        case types.productGetDetails.failure:
            return initialState;
        case types.productGetSections.success:
            return {
                ...state,
                productSections: action.payload,
                productAttributesModificationDate: new Date(),
            };
        case types.productGetAttributes.success:
            return {
                ...state,
                productAttributes: action.payload,
                productAttributesModificationDate: new Date(),
            };
        case types.productGetActiveCategories.success:
        case types.productGetActiveCategoriesCache:
            const cache = Cache(cacheNames.categoryTreesStore);
            cache.setItem("active", {
                ...action.payload,
                cacheExpiresAt: moment().add(1, "days").format(),
            });
            return {
                ...state,
                categoryTrees: action.payload,
                categoryTreesModificationDate: new Date(),
            };
        case types.productUpdateAttribute.request:
            return { ...state, productAttributeUpdating: true };
        case types.productUpdateAttribute.failure:
            return { ...state, productAttributeUpdating: false };
        case types.productUpdateAttribute.success:
            let productAttributesCopy = state.productAttributes;
            const index = productAttributesCopy.findIndex((x) => x.productAttributeId === action.payload.productAttributeId);
            productAttributesCopy[index] = action.payload.productAttribute;
            return {
                ...state,
                productAttributeUpdating: false,
                productAttributes: productAttributesCopy,
            };
        case types.changeAttributesFrom.success:
            return {
                ...state,
                attributesFrom: action.payload.set,
                product: {
                    ...state.product,
                    attributesFrom: action.payload.attributesFrom,
                },
            };
        case types.changeSetIntegrationImageIfPossible.success:
            if (action.payload.productId !== state.product.productId) {
                const variant = state.product.variantsData.find((x) => x.variantId === action.payload.productId);
                variant.forceIntegrationImages = action.payload.forceIntegrationImages;
                const newVariants = state.product.variantsData.filter((x) => x.variantId !== action.payload.productId);
                return {
                    ...state,
                    product: {
                        ...state.product,
                        variantData: [...newVariants, variant],
                    },
                    forceIntegrationImages: action.payload.forceIntegrationImages,
                };
            } else {
                return {
                    ...state,
                    product: {
                        ...state.product,
                        forceIntegrationImages: action.payload.forceIntegrationImages,
                    },
                    forceIntegrationImages: action.payload.forceIntegrationImages,
                };
            }
        case types.productUpdateImages.request: {
            return {
                ...state,
                productImagesUpdating: true,
            };
        }
        case types.productUpdateImages.success: {
            if (action.payload.isVariant) {
                const variantId = Number(action.payload.productId);

                const variant = state.product.variantsData.find((o) => Number(o.variantId) === variantId);

                variant.images = [...action.payload.images, ...action.payload.integrationImages];

                const remaningVaraints = state.product.variantsData.filter((o) => Number(o.variantId) !== variantId);

                const newState = {
                    ...state,
                    product: {
                        ...state.product,
                        variantData: [...remaningVaraints, variant],
                    },
                    images: [...action.payload.images, ...action.payload.integrationImages],
                    productImagesUpdating: false,
                };

                return newState;
            }

            const newState = {
                ...state,
                images: [...action.payload.images, ...action.payload.integrationImages],
                product: {
                    ...state.product,
                    images: [...action.payload.images, ...action.payload.integrationImages],
                },
                productImagesUpdating: false,
            };

            return newState;
        }
        case types.productUpdateImages.failure: {
            return {
                ...state,
                productImagesUpdating: false,
            };
        }
        case types.productUpdateAttributes.success:
            return {
                ...state,
                product: { ...state.product, attributes: action.payload },
                initialAttributes: [...action.payload],
                changedArrayAttributes: [],
            };
        case types.productUpdateCategories.success:
            return {
                ...state,
                product: { ...state.product, categoryPaths: action.payload },
            };
        case types.updateCustomLabelArray:
            let productAttributesClone = JSON.parse(JSON.stringify(state.product.attributes));
            let modified = productAttributesClone.find((x) => x.id === action.payload.productAttributeId);
            if (modified) {
                let found = modified.customLabels ? modified.customLabels.find((x) => x.name === action.payload.name) : undefined;
                if (found) {
                    if (action.payload.name === action.payload.label) {
                        const index = modified.customLabels.indexOf(found);
                        modified.customLabels.splice(index, 1);
                    } else {
                        found.label = action.payload.label;
                    }
                } else if (action.payload.name !== action.payload.label) {
                    if (modified.customLabels) {
                        modified.customLabels = [...modified.customLabels, { name: action.payload.name, label: action.payload.label }];
                    } else {
                        modified.customLabels = [{ name: action.payload.name, label: action.payload.label }];
                    }
                }
                if (modified.onlyLabels && (!modified.customLabels || modified.customLabels.length === 0)) {
                    const index = productAttributesClone.indexOf(modified);
                    productAttributesClone.splice(index, 1);
                } else if (modified.customLabels.length === 0 && modified.onlyLabels) {
                    delete modified.customLabels;
                }

                return {
                    ...state,
                    product: {
                        ...state.product,
                        attributes: [...productAttributesClone],
                    },
                };
            } else if (action.payload.name !== action.payload.label) {
                modified = {
                    id: action.payload.productAttributeId,
                    customLabels: [
                        {
                            name: action.payload.name,
                            label: action.payload.label,
                        },
                    ],
                    onlyLabels: true,
                };
                return {
                    ...state,
                    product: {
                        ...state.product,
                        attributes: [...productAttributesClone, modified],
                    },
                };
            } else return state;
        case types.getProductInternalData.success: {
            return {
                ...state,
                productInternalData: action.payload,
            };
        }
        case types.changeProductPricePoint.success: {
            return {
                ...state,
                productInternalData: {
                    ...state.productInternalData,
                    pricePoint: action.payload.pricePoint,
                },
            };
        }
        case types.changeProductClassification.success: {
            return {
                ...state,
                productInternalData: {
                    ...state.productInternalData,
                    productClassification: action.payload.productClassification,
                },
            };
        }
        default:
            return state;
    }
};
