import React from "react";
import { generatePath } from "react-router-dom";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import { Role } from "../roles";

const ShopConfigurationForm = React.lazy(() =>
    import("views-ts/crawler/shopConfiguration").then((module) => ({
        default: module.ShopConfigurationForm,
    }))
);
const ShopConfigurationList = React.lazy(() =>
    import("views-ts/crawler/shopConfiguration").then((module) => ({
        default: module.ShopConfigurationList,
    }))
);

const ExternalProduct = React.lazy(() =>
    import("views/crawler").then((module) => ({
        default: module.ExternalProduct,
    }))
);
const ExternalProductList = React.lazy(() =>
    import("views/crawler").then((module) => ({
        default: module.ExternalProductList,
    }))
);

const ProductScan = React.lazy(() =>
    import("views/crawler").then((module) => ({
        default: module.ProductScan,
    }))
);
const ProductScanningStatusList = React.lazy(() =>
    import("views/crawler").then((module) => ({
        default: module.ProductScanningStatusList,
    }))
);

const ShopAuthorizationList = React.lazy(() =>
    import("views/crawler").then((module) => ({
        default: module.ShopAuthorizationList,
    }))
);
const ShopAuthorizationForm = React.lazy(() =>
    import("views/crawler").then((module) => ({
        default: module.ShopAuthorizationForm,
    }))
);

const ProductSubstituteList = React.lazy(() =>
    import("views-ts/crawler").then((module) => ({
        default: module.ProductSubstituteList,
    }))
);
const ProductSubstituteForm = React.lazy(() =>
    import("views-ts/crawler").then((module) => ({
        default: module.ProductSubstituteForm,
    }))
);

const crawlerRoles: Role[] = ["Analyst", "Management", "Catman", "SalesManager", "SalesAssistant"];
const crawlerAuthorizationRoles: Role[] = ["Catman"];

export const Crawler: PrivateRoute[] = [
    new PrivateRoute("/crawler", crawlerRoles, undefined, "crawler", "bug_report", [
        new PrivateRoute(Paths.Crawler.ShopConfigurationsList, [], ShopConfigurationList, "shopConfiguration", "settings"),
        new PrivateRoute(Paths.Crawler.ShopConfigurationForm, [], ShopConfigurationForm),

        new PrivateRoute(Paths.Crawler.ScanStatus, crawlerRoles, ProductScanningStatusList, "productScanningStatus", "listAlt"),

        new PrivateRoute(
            generatePath(Paths.Crawler.ExternalProductsList, {
                id: undefined,
            }),
            crawlerRoles,
            undefined,
            "externalProduct",
            "sync"
        ),
        new PrivateRoute(Paths.Crawler.ExternalProductsList, crawlerRoles, ExternalProductList),
        new PrivateRoute(Paths.Crawler.ExternalProductScanForm, crawlerRoles, ProductScan),
        new PrivateRoute(Paths.Crawler.ExternalProductForm, crawlerRoles, ExternalProduct),

        new PrivateRoute(Paths.Crawler.ShopAuthorizationsList, crawlerAuthorizationRoles, ShopAuthorizationList, "shopAuthorization", "fingerprint"),
        new PrivateRoute(Paths.Crawler.ShopAuthorizationForm, crawlerAuthorizationRoles, ShopAuthorizationForm),

        new PrivateRoute(Paths.Crawler.ProductSubstitutesList, crawlerRoles, ProductSubstituteList, "productSubstitute", "compare_arrows"),
        new PrivateRoute(Paths.Crawler.ProductSubstituteForm, crawlerRoles, ProductSubstituteForm),
    ]),
];
