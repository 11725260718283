import tableStyle from "assets/jss/material-dashboard-react/components/tableStyle";
import tasksStyle from "assets/jss/material-dashboard-react/components/tasksStyle";
import { withStyles } from "tss-react/mui";
import React from "react";
import Edit from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import { blue, green } from "@mui/material/colors";
import classnames from "classnames";

const styles = {
    ...tableStyle,
    ...tasksStyle,
    icon: {
        "&:hover": {
            color: blue,
        },
    },
    iconActive: {
        color: green,
    },
};

const edit = ({ handleClick, classes, disabled }) => {
    return (
        <>
            <IconButton
                className={classes.icon}
                onClick={handleClick}
                disabled={disabled}
            >
                <Edit className={classnames(classes.tableActionButtonIcon, !disabled ? classes.edit : classes.disabled)} />
            </IconButton>
        </>
    );
};

export default withStyles(edit, styles);
