import { IconButton } from "components/Button";
import { makeStyles } from "tss-react/mui";
import { useCommonTranslation } from "utils/hooks";
import { TableFooter, TablePagination, TableRow } from "@mui/material";

const useStyles = makeStyles()((theme) => ({
    root: {
        display: "flex",
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    container: {
        display: "flex",
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;
    const { classes } = useStyles();

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.container}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                icon="first_page"
            />
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                icon="keyboard_arrow_left"
            />
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page === Math.ceil(count / rowsPerPage) - 1 || count === 0}
                icon="keyboard_arrow_right"
            />
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page === Math.ceil(count / rowsPerPage) - 1 || count === 0}
                icon="last_page"
            />
        </div>
    );
}

const Footer = ({ paginationProps }) => {
    const { t, common } = useCommonTranslation();
    const { classes } = useStyles();
    const { pageIndex, totalCount, pageSize, pageSizeOptions, onPageChange, onRowsPerPageChange, disableSavingPagination = false } = paginationProps;

    return (
        <TableFooter className={classes.footer}>
            <TableRow>
                <TablePagination
                    count={totalCount}
                    page={pageIndex - 1}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={pageSizeOptions || [10, 25, 50, 100, 250]}
                    labelDisplayedRows={({ from, to, count }) =>
                        t(common.paginationInfo, {
                            from,
                            to,
                            count,
                        })
                    }
                    labelRowsPerPage={t(common.paginationRowsPerPage)}
                    onPageChange={(_, page) => {
                        onPageChange(page + 1);
                    }}
                    onRowsPerPageChange={async ({ target }) => {
                        if (!disableSavingPagination) {
                            localStorage.setItem("tablePagination", JSON.stringify(target.value));
                        }

                        onRowsPerPageChange(target.value);
                    }}
                    ActionsComponent={TablePaginationActions}
                />
            </TableRow>
        </TableFooter>
    );
};

export default Footer;
