import Layout from "components/Grid/Layout";
import { createColumn } from "components/Table/SimpleTable/SimpleTable";
import tableActionTypes from "components/Table/SimpleTable/TableActions/tableActionTypes";
import TableWithToggleFilter from "components/Table/TableWithToggleFilter/TableWithToggleFilter";
import { actions } from "store/offers/feed/list/actions";
import { feedListSelector } from "store/offers/selectors";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useCommonTranslation, useMappedDispatch } from "utils/hooks";
import { usePush } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";

const FeedList = () => {
    const { t, common } = useCommonTranslation();
    const { remove } = useMappedDispatch(actions);
    const { pushToForm: push } = usePush();
    const pushToForm = (id, isCopy) => push(Paths.Offers.FeedForm, id, isCopy);

    const columns = [
        createColumn("name", t(common.name), formatTypes.text),
        createColumn("validFrom", t(common.validFrom), formatTypes.time, {
            timeFormat: timePrecisonFormats.seconds,
        }),
        createColumn("validTo", t(common.validTo), formatTypes.time, {
            timeFormat: timePrecisonFormats.seconds,
        }),
        createColumn("isActive", t(common.active), formatTypes.boolean),
        createColumn("isAvailable", t(common.isAvailable), formatTypes.boolean),
        createColumn("createdAt", t(common.createdAt), formatTypes.time, {
            timeFormat: timePrecisonFormats.seconds,
        }),
        createColumn("updatedAt", t(common.updatedAt), formatTypes.time, {
            timeFormat: timePrecisonFormats.seconds,
        }),
        createColumn("action", "", formatTypes.action, { actionType: tableActionTypes.preview }, (item) => pushToForm(item.id)),
        createColumn("action", "", formatTypes.action, { actionType: tableActionTypes.copy }, (item) => pushToForm(item.id, true)),
        createColumn("action", "", formatTypes.action, { actionType: tableActionTypes.delete }, (item) => remove(item.id, "batches")),
    ];

    return (
        <Layout
            main
            headerText={t(common.feed)}
        >
            <TableWithToggleFilter
                columns={columns}
                addItemAction={() => pushToForm()}
                dataSelector={feedListSelector}
                dataSourceAction={actions.getFeedList}
                showToggle={false}
            />
        </Layout>
    );
};

export default FeedList;
