import { DecisionButtons } from "components/Button";
import { FormField } from "components/Form";
import roles from "consts/roles";
import { formValueSelector } from "redux-form";
import { contractSelector, makeContractDecision } from "store/vendors/contracts";
import { withFormName } from "utils/hoc";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { useUser } from "context";
import { isEmpty } from "lodash";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";

const ContractDecision = ({ isDecisionRequired, handleDecision, form }) => {
    const { vendorId, id: contractId } = useSelector(contractSelector);
    const { isInRoleOrAdmin } = useUser();
    const dispatch = useDispatch();
    const [submitted, setSubmitted] = useState(false);
    const { t } = useTranslation("vendor");

    const comment = useSelector((state) => formValueSelector(form)(state, "comment"));

    return isDecisionRequired && isInRoleOrAdmin(roles.SalesManager) ? (
        <>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
            >
                <FormField
                    stretch
                    name={"comment"}
                    label={t(vendor.comment)}
                    type={"text"}
                />
            </Grid>
            <DecisionButtons
                submitted={submitted}
                onAccept={async () => {
                    setSubmitted(true);
                    await dispatch(makeContractDecision(vendorId, contractId, true, comment));
                    handleDecision();
                }}
                onReject={async () => {
                    setSubmitted(true);
                    await dispatch(makeContractDecision(vendorId, contractId, false, comment));
                    handleDecision();
                }}
                canReject={!isEmpty(comment)}
                acceptText={t(vendor.acceptChanges)}
                rejectText={t(vendor.rejectChanges)}
            />
        </>
    ) : (
        <></>
    );
};

export default withFormName(ContractDecision);
