import throwIf from "./throwIf";

const bomChars = "\uFEFF";

export default (csvValues, fileName) => {
    throwIf(!fileName, "File name must be defined");
    throwIf(!csvValues?.length, "CSVvalues most be defined");

    const clickEvent = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
    });

    const data = `data:text/csv;charset=utf-8,${encodeURIComponent(bomChars + csvValues)}`;

    const element = document.createElement("a");
    element.setAttribute("href", `data: ${data}`);
    element.setAttribute("download", `${fileName}.csv`);
    element.dispatchEvent(clickEvent);
    element.remove();
};
