import { PurchaseScheduleDocument, PurchaseScheduleListResponse, PurchaseScheduleSearchParameters } from "Commerce-Offer";
import { useCreateOrUpdateMutation, useGetPagedQuery, useGetQuery } from "api/hooks";

const url = (id?: string) => `api/v1/offer/products/purchase-schedules${id ? `/${id}` : ""}`;

const useFindPurchaseSchedule = (
    params: PurchaseScheduleSearchParameters = {
        pageIndex: 1,
        pageSize: 25,
        isActive: true,
    }
) => {
    return useGetPagedQuery<PurchaseScheduleListResponse, PurchaseScheduleSearchParameters>({
        app: "commerce",
        url: url(),
        queryParams: params,
    });
};

const useGetPurchaseSchedule = (id?: string) => {
    return useGetQuery<PurchaseScheduleDocument>({
        app: "commerce",
        url: url(id),
        shouldMakeRequest: Boolean(id),
    });
};

const useCreateOrUpdatePurchaseSchedule = (id?: string) => {
    return useCreateOrUpdateMutation<PurchaseScheduleDocument, PurchaseScheduleDocument>({
        app: "commerce",
        url: url(id),
        id: id,
    });
};

export default {
    useFindPurchaseSchedule,
    useGetPurchaseSchedule,
    useCreateOrUpdatePurchaseSchedule,
};
