import { createId } from "@paralleldrive/cuid2";
import { VariantType, useSnackbar } from "notistack";

const useMessages = () => {
    const { enqueueSnackbar } = useSnackbar();

    const showMessage = (message: string, variant: VariantType) => {
        enqueueSnackbar(message, {
            key: createId(),
            variant,
            autoHideDuration: 3500,
            anchorOrigin: {
                vertical: "top",
                horizontal: "right",
            },
        });
    };

    return {
        showInfoMessage: (message: string) => {
            showMessage(message, "info");
        },
        showErrorMessage: (message: string) => {
            showMessage(message, "error");
        },
        showSuccessMessage: (message: string) => {
            showMessage(message, "success");
        },
        showWarningMessage: (message: string) => {
            showMessage(message, "warning");
        },
    };
};

export default useMessages;
