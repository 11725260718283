import { IconButton } from "components/Button";
import { Dropzone } from "components/Files";
import { FormField } from "components/Form";
import { FormSection } from "redux-form";
import { initForm } from "store/vendors/refundations";
import { deleteRefundationSettlementFile, getRefundationSettlementFile } from "store/vendors/refundations/action";
import { withFormName } from "utils/hoc";
import { useCallback } from "react";
import { Delete as DeleteIcon, GetApp as DownloadIcon, FileCopy as FileIcon } from "@mui/icons-material";
import { Avatar, Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from "@mui/material";
import moment from "moment";
import { Layout } from "components";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";

const RefundationSettlements = ({ changedDateTo, isDecisionRequired, changedSettlementFile, dispatch, change, isAuditor, vendorId, id }) => {
    const { t } = useTranslation("vendor");
    const canAddSettlement = moment().isAfter(moment(changedDateTo));

    const handleDownloadSettlementFile = (file) => {
        dispatch(getRefundationSettlementFile(vendorId, id, file));
    };

    const handleDeleteSettlementFile = async (fileId) => {
        await dispatch(deleteRefundationSettlementFile(vendorId, id, fileId));
        await dispatch(initForm(vendorId, id));
    };

    const handleDrop = useCallback(
        (acceptedFiles) => {
            acceptedFiles.forEach((file) => {
                const reader = new FileReader();

                reader.onload = function (e) {
                    const fileItem = {
                        extension: `.${file.name.split(".").slice(-1)[0].toLowerCase()}`,
                        fileName: file.name,
                        fileContent: e.target.result.split(",")[1],
                    };

                    change("settlementFile", fileItem);
                };

                reader.readAsDataURL(file);
            });
        },
        [change]
    );

    return (
        <>
            {(canAddSettlement || isDecisionRequired) && (
                <Layout headerText={t(vendor.refundationSettlement)}>
                    {changedSettlementFile || isDecisionRequired ? (
                        <List>
                            <FormSection name="settlementFile">
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <FileIcon />
                                        </Avatar>
                                    </ListItemAvatar>

                                    <ListItemText>
                                        <Grid
                                            container
                                            direction="row"
                                        >
                                            <FormField
                                                label={t(vendor.fileName)}
                                                useDiff={isDecisionRequired}
                                                readOnly={true}
                                                name="fileName"
                                            />
                                        </Grid>
                                    </ListItemText>

                                    <ListItemSecondaryAction>
                                        <Grid
                                            container
                                            direction="row"
                                        >
                                            <Grid item>
                                                <IconButton
                                                    edge="end"
                                                    onClick={() =>
                                                        handleDownloadSettlementFile({
                                                            fileId: changedSettlementFile.fileId,
                                                            fileName: changedSettlementFile?.fileName,
                                                        })
                                                    }
                                                    disabled={!Boolean(changedSettlementFile?.fileId)}
                                                    icon={<DownloadIcon />}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <IconButton
                                                    edge="end"
                                                    onClick={() => {
                                                        if (Boolean(changedSettlementFile.fileId)) {
                                                            handleDeleteSettlementFile(changedSettlementFile.fileId);
                                                        } else {
                                                            change("settlementFile", null);
                                                        }
                                                    }}
                                                    disabled={isDecisionRequired || (Boolean(changedSettlementFile?.fileId) && !isAuditor)}
                                                    icon={<DeleteIcon />}
                                                />
                                            </Grid>
                                        </Grid>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </FormSection>
                        </List>
                    ) : (
                        <Dropzone
                            accept="application/pdf"
                            disabled={changedSettlementFile != null}
                            maxFiles={1}
                            onDrop={handleDrop}
                        />
                    )}
                </Layout>
            )}
        </>
    );
};

export default withFormName(RefundationSettlements);
