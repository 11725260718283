import { commerce, manager } from "store/actionTypePrefixes";
import { AsyncTypes, createAction } from "utils/extensions";

export const types = {
    initializePromotionForm: createAction("FORM_INIT_PROMOTION", manager),
    getPromotion: new AsyncTypes("GET_PROMOTION", commerce),
    getPromotionActivation: new AsyncTypes("GET_PROMOTION_ACTIVATION", commerce),
    getPromotionValidation: new AsyncTypes("GET_PROMOTION_VALIDATION", commerce),
    createOrUpdatePromotion: new AsyncTypes("CREATE_OR_UPDATE_PROMOTION_SUCCESS", commerce),
};
