import { IconButton } from "components/Button";
import { ControlLabel } from "components/Controls";
import { FileImport } from "components/Files";
import Dropzone from "components/Files/Dropzone";
import { Form, FormField } from "components/Form";
import Layout from "components/Grid/Layout";
import { HTMLRenderer } from "components/HTMLRenderer/HTMLRenderer";
import Preloader from "components/Preloader/Preloader";
import StyledChip from "components/StyledChip/StyledChip";
import TextWithLabel from "components/Text/TextWithLabel";
import { useUser } from "context/UserContext/UserContext";
import { SubmissionError, reduxForm } from "redux-form";
import { actions } from "store/cache/commerceUsers/actions";
import {
    commerceUsersSelector,
    createEmail,
    downloadRecipientsFileTemplate,
    emailFormSelector,
    getEmail,
    getEmailAttachment,
    getRecipientsFromFile,
} from "store/emails";
import { useDebounce } from "use-debounce";
import { firstOrEmpty } from "utils/extensions/arrayExtensions";
import { useChange, useCommonTranslation, useFormValueSelector, useParams } from "utils/hooks";
import { useCallback, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Delete as DeleteIcon, GetApp as DownloadIcon } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { uniq } from "lodash";
import { usePush } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { validators } from "./validation";

const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

const EmailForm = ({ handleSubmit, isLoading, isSubbmitRequested, recipientsReadResult }) => {
    const { t, common } = useCommonTranslation();
    const { id } = useParams();
    const { replace } = usePush();
    const cachedUsers = useSelector(commerceUsersSelector);
    const [usersToGet, setUsersToGet] = useState([]);
    const dispatch = useDispatch();
    const change = useChange(formName);
    const { blobName, fileName, toAddresses, body, subject } = useFormValueSelector(
        formName,
        ["blobName", "fileName", "toAddresses", "body", "subject"],
        {}
    );
    const { profile } = useUser();
    const readonly = Boolean(id);

    useEffect(() => {
        dispatch(getEmail(id));
    }, [dispatch, id]);

    const handleDrop = useCallback(
        (acceptedFiles) => {
            acceptedFiles.forEach((file) => {
                const reader = new FileReader();

                reader.onload = function (e) {
                    const fileItem = {
                        extension: `.${file.name.split(".").slice(-1)[0].toLowerCase()}`,
                        fileName: file.name,
                        fileContent: e.target.result.split(",")[1],
                    };

                    change("file", fileItem);
                    change("fileName", file.name);
                };

                reader.readAsDataURL(file);
            });
        },
        [change]
    );

    const handleDownloadTemplateFile = async ({ fileName }) => {
        dispatch(getEmailAttachment(id, fileName));
    };

    const mapRecipient = (emailOrUserId) => {
        if (!isValidUserId(emailOrUserId)) return { email: emailOrUserId };

        const user = firstOrEmpty(cachedUsers, (user) => user.userId === emailOrUserId);
        const { user: userProfile = {} } = user;
        const { email = null } = userProfile;
        return { userId: emailOrUserId, email: email };
    };

    const handleDownloadUsersFileTemplate = () => {
        dispatch(downloadRecipientsFileTemplate());
    };

    const handleImportRecipientsFromFile = (file) => {
        dispatch(getRecipientsFromFile(file));
    };

    useEffect(() => {
        if (!recipientsReadResult) return;

        if (recipientsReadResult.success) {
            dispatch(actions.getCommerceUsers(recipientsReadResult.recipients.map((r) => r.userId).filter(Boolean)));
            change("toAddresses", uniq([...(toAddresses ?? []), ...recipientsReadResult.recipients.map((r) => r.userId ?? r.email)]));
            change("recipientReadResult", undefined);
        }
    }, [change, dispatch, recipientsReadResult, toAddresses]);

    // eslint-disable-next-line no-unused-vars
    const [_, getUsersDebounced] = useDebounce(() => {
        dispatch(actions.getCommerceUsers(usersToGet));
        setUsersToGet([]);
    }, 500);

    const getUser = (v) => {
        if (cachedUsers.map((x) => x.userId).includes(v)) return;
        setUsersToGet(uniq([...usersToGet, v]));
        getUsersDebounced();
    };

    const getRecipientLabel = (recipient) => {
        if (isValidRecipient(recipient)) {
            return recipient.userId ? `${recipient.email ?? ""} (${recipient.userId})` : recipient.email;
        }

        return `(${recipient.userId}) - Nie odnaleziono klienta`;
    };

    if (isLoading) {
        return <Preloader />;
    }

    return (
        <>
            <Form
                isSubbmitRequested={isSubbmitRequested}
                onSubmit={handleSubmit(async (values) => {
                    const { file, toAddresses, ...rest } = values;
                    var addresses = toAddresses.map((x) => mapRecipient(x).email);
                    if (addresses.some((x) => !x))
                        throw new SubmissionError({
                            toAddresses: t(common.invalidRecipients),
                        });
                    const { payload } = await dispatch(
                        createEmail({
                            email: {
                                createdBy: profile.email,
                                toAddresses: addresses,
                                ...rest,
                            },
                            fileName: file?.fileName,
                            fileContent: file?.fileContent,
                        })
                    );

                    replace(Paths.EmailForm, { id: payload.id });
                })}
                isReadonly={readonly}
            >
                <Layout
                    main
                    headerText={t(common.emailForm, { id })}
                    navigationProps={{
                        backProps: { path: "/emails" },
                    }}
                    customTitle={subject && `${t(common.emailForm)} - ${subject}`}
                >
                    {readonly && (
                        <>
                            <Grid
                                container
                                direction="row"
                            >
                                <FormField
                                    name={"createdBy"}
                                    label={t(common.createdBy)}
                                    type={"text"}
                                    readOnly={true}
                                />
                                <FormField
                                    name={"isSended"}
                                    label={t(common.isSended)}
                                    type={"boolean"}
                                    readOnly={true}
                                />
                                <FormField
                                    name={"sendDate"}
                                    label={t(common.sendDate)}
                                    type={"date"}
                                    readOnly={true}
                                />
                            </Grid>
                        </>
                    )}
                    <FormField
                        name={"subject"}
                        label={t(common.subject)}
                        type={"text"}
                        readOnly={id}
                        validate={validators.required}
                    />
                    {readonly ? (
                        <FormField
                            name={"body"}
                            stretch
                            component={() => (
                                <>
                                    <ControlLabel label={t(common.body)} />
                                    <Grid item>
                                        <HTMLRenderer html={body} />
                                    </Grid>
                                </>
                            )}
                        />
                    ) : (
                        <FormField
                            name={"body"}
                            label={t(common.body)}
                            type={"richText"}
                            stretch
                        />
                    )}
                    <FormField
                        name={"toAddresses"}
                        validate={readonly ? [] : validators.atLeastOne}
                        label={t(common.to)}
                        type={"list"}
                        useList={false}
                        onAdd={(v) => isValidUserId(v) && getUser(v)}
                        additionalValueCheck={(v) => {
                            return emailRegex.test(v) || isValidUserId(v);
                        }}
                        itemRenderer={(value, handleRemove) => {
                            const recipient = mapRecipient(value);
                            return (
                                <StyledChip
                                    colorscheme={!readonly && !isValidRecipient(recipient) ? "error" : "default"}
                                    key={recipient.email ?? recipient.userId}
                                    style={{ marginRight: 10, marginTop: 10 }}
                                    size="medium"
                                    label={getRecipientLabel(recipient)}
                                    onDelete={Boolean(id) ? undefined : () => handleRemove(value)}
                                />
                            );
                        }}
                        readOnly={readonly}
                        stretch
                    />
                    {!readonly && (
                        <FileImport
                            clearOnImport
                            isImporting={false}
                            handleImport={handleImportRecipientsFromFile}
                            downloadTemplate={handleDownloadUsersFileTemplate}
                            importText={t(common.importRecipientsFromFile)}
                        />
                    )}
                    {!fileName && !id && (
                        <>
                            <ControlLabel label={t(common.attachment)} />
                            <Dropzone
                                onDrop={handleDrop}
                                maxFiles={1}
                                hasFile={fileName}
                                accept=".pdf"
                            />
                        </>
                    )}
                    {fileName && (
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            <TextWithLabel
                                value={fileName}
                                translatedLabel={t(common.attachment)}
                                readOnly={id}
                            />
                            {blobName && (
                                <Grid item>
                                    <IconButton
                                        edge="end"
                                        onClick={() =>
                                            handleDownloadTemplateFile({
                                                fileName,
                                            })
                                        }
                                        icon={<DownloadIcon />}
                                    />
                                </Grid>
                            )}
                            {!id && (
                                <Grid item>
                                    <IconButton
                                        edge="end"
                                        onClick={() => {
                                            change("file", null);
                                            change("fileName", null);
                                        }}
                                        icon={<DeleteIcon />}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    )}
                </Layout>
            </Form>
        </>
    );
};

const isValidUserId = (value) => {
    return Boolean(Number(value)) && (String(value).length === 6 || String(value).length === 7);
};

const isValidRecipient = ({ email }) => {
    return !!email;
};

const stateToProps = (state) => {
    const { isLoading, isSubbmitRequested, recipientsReadResult, ...form } = emailFormSelector(state);

    return {
        isSubbmitRequested,
        isLoading,
        recipientsReadResult,
        initialValues: {
            ...form,
        },
    };
};

const formName = "email-form";

export default connect(stateToProps)(
    reduxForm({
        form: formName,
        enableReinitialize: true,
    })(EmailForm)
);
