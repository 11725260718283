import { createSelector } from "reselect";

const root = (state) => ({
    procedures: state.procedures,
});

export const procedureListSelector = createSelector(root, (state) => state.procedures.list);

export const procedureFormSelector = createSelector(root, (state) => {
    const form = state.procedures.form;
    const { id, procedureVersion, file = {}, wordFile = {} } = form;
    const { blobName = "" } = file;
    const fileName = blobName.replace(`${id}_${procedureVersion}_`, "");

    const { blobName: wordBlobName = "" } = wordFile;
    const wordFileName = wordBlobName.replace(`${id}_`, "");

    return { ...form, fileName, blobName, wordFileName, wordBlobName };
});
