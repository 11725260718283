import Action from "components/Table/SimpleTable//TableActions/Action";
import Cell from "components/Table/SimpleTable/Cell";
import Row from "components/Table/SimpleTable/Row";
import { withStyles } from "tss-react/mui";
import { formatValue } from "utils/formating";
import { Typography } from "@mui/material";
import { customers } from "translations";

const CustomerReviewsRow = ({ columns, item, classes, t, handleDoubleClick }) => {
    return (
        <>
            <Row
                className={classes.idRow}
                hover
            >
                <Cell colSpan={columns.length}>
                    <Typography className={classes.idRowContent}>{t(`customers:${customers.orderWithId}`, { id: item.id })}</Typography>
                </Cell>
            </Row>
            {item.reviews
                .map((x) => ({ ...x, ...x.review }))
                .map((r, i) => {
                    return (
                        <Row
                            key={i}
                            hover
                            onDobuleClick={() => handleDoubleClick(r)}
                        >
                            {columns.map(({ key, type, options, action, actionType }, index) => {
                                if (key === "action") {
                                    return (
                                        <Action
                                            key={index}
                                            handleClick={() => action(r)}
                                            actionType={actionType}
                                        />
                                    );
                                }
                                return (
                                    <Cell key={index}>
                                        <Typography align="center">{formatValue(r[key] || false, type, options)}</Typography>
                                    </Cell>
                                );
                            })}
                        </Row>
                    );
                })}
        </>
    );
};

export default withStyles(CustomerReviewsRow, (theme) => ({
    idRow: { backgroundColor: theme.palette.primary.light },
    idRowContent: { color: theme.palette.primary.contrastText, marginLeft: theme.spacing(1), fontWeight: "bold" },
}));
