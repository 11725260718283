import { manager, operations } from "store/actionTypePrefixes";
import { AsyncTypes, asAsyncFormTypes, createAction } from "utils/extensions";

const types = {
    getCorrectionRequestReport: new AsyncTypes("GET_CORRECTION_REQUEST_REPORT", operations),
    getCorrectionRequestReportFile: new AsyncTypes("GET_CORRECTION_REQUEST_REPORT_FILE", operations, [asAsyncFormTypes]),
    acceptCorrectionRequests: new AsyncTypes("ACCEPT_CORRECTION_REQUESTS", operations, [asAsyncFormTypes]),
    changeSettlementType: createAction("CHANGE_CORRECTION_REQUEST_SETTLEMENT_TYPE", manager),
};

export default types;
