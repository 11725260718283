import { AlertTitle, Alert as MuiAlert } from "@mui/material";

const Alert = ({ severity, title, text, open, handleClose }) => {
    return (
        open && (
            <MuiAlert
                severity={severity}
                onClose={handleClose}
            >
                <AlertTitle>{title}</AlertTitle>
                {text}
            </MuiAlert>
        )
    );
};

export default Alert;
