import { iconTypes } from "components/Icons/InfoIcon";
import { diffCustom } from "utils/objectCompare/diffCustom";
import { createSelector } from "reselect";

const root = (state) => ({
    marketingBudgets: state.marketingBudgets,
});

export const marketingBudgetsFormSelector = createSelector(root, (root) => {
    let { current, proposal, status, ...document } = root.marketingBudgets.form;
    document.dateFrom = undefined;
    document.dateTo = undefined;

    if (status === "WaitingForDecision") {
        return {
            status,
            ...document,
            ...diffCustom(current, proposal, {
                catmansBudgets: {
                    findFunc: (a, b) => a?.catman === b?.catman,
                },
            }),
            catmansBudgetAudit: proposal.catmansBudgetAudit,
        };
    }

    if (status === "Rejected") {
        return { status, ...document, ...proposal };
    }

    return { status, ...document, ...current };
});

export const marketingBudgetsListSelector = createSelector(root, (root) => {
    return {
        ...root.marketingBudgets.list,
        items: root.marketingBudgets.list.items.map((i) => {
            var catmansBudgetUsageHelper = {};
            const catmansBudgetUsage = i.budgetUsage.reduce(function (r, o) {
                if (!catmansBudgetUsageHelper[o.catman]) {
                    catmansBudgetUsageHelper[o.catman] = { catman: o.catman, budget: o.budget };
                    r.push(catmansBudgetUsageHelper[o.catman]);
                } else {
                    catmansBudgetUsageHelper[o.catman].budget += o.budget;
                }

                return r;
            }, []);

            const isBudgetOverLimit =
                i.proposal != null
                    ? false
                    : i.current.catmansBudgets.some((cb) => cb.budget < (catmansBudgetUsage.find((cbu) => cbu.catman == cb.catman)?.budget ?? 0)) ||
                      i.current.catmansBudgets.reduce((a, b) => {
                          return a + b.budget;
                      }, 0) <
                          catmansBudgetUsage.reduce((a, b) => {
                              return a + b.budget;
                          }, 0);

            return {
                ...i,
                overLimit: isBudgetOverLimit ? iconTypes.warrning : undefined,
            };
        }),
    };
});
