import { RSAA } from "redux-api-middleware";
import { commerce } from "store/actionTypePrefixes";
import { actions as orderAlertActions } from "store/orderAlerts/orderAlert/actions";
import { combineIntoQueryParams } from "utils/objectExtensions";
import { splitOrderId } from "utils/splitIds";

export const types = {
    GET_CUSTOMER_PROFILES: `${commerce}/GET_CUSTOMER_PROFILES`,
    GET_CUSTOMER_PROFILES_SUCCESS: `${commerce}/GET_CUSTOMER_PROFILES_SUCCESS`,
    GET_CUSTOMER_PROFILES_FAILURE: `${commerce}/GET_CUSTOMER_PROFILES_FAILURE`,
    GET_ORDER_ALERTS_LIST: `${commerce}/GET_ORDER_ALERTS_LIST`,
    GET_ORDER_ALERTS_LIST_SUCCESS: `${commerce}/GET_ORDER_ALERTS_LIST_SUCCESS`,
    GET_ORDER_ALERTS_LIST_FAILURE: `${commerce}/GET_ORDER_ALERTS_LIST_FAILURE`,
};

const getOrderAlerts = (params) => async (dispatch) => {
    const { payload: orders } = await dispatch({
        [RSAA]: {
            endpoint: `api/v1/users/orders/alerts?${combineIntoQueryParams({
                ...params,
                isActive: true,
                isDismissed: false,
            })}`,
            headers: { "Content-Type": "application/json" },
            method: "GET",
            types: [types.GET_ORDER_ALERTS_LIST, "ignore", types.GET_ORDER_ALERTS_LIST_FAILURE],
        },
    });
    const { items: profiles } = await dispatch(getCustomers(orders.items.map((x) => splitOrderId(x.id).customerId)));
    await dispatch({
        type: types.GET_ORDER_ALERTS_LIST_SUCCESS,
        payload: {
            ...orders,
            items: orders.items.map((x) => ({
                ...x,
                customer: profiles.find((y) => x.id.includes(y.id)),
            })),
        },
    });

    return Promise.resolve();
};

const getCustomers = (payload) => async (dispatch) => {
    const params = combineIntoQueryParams({
        userIds: payload,
    });

    const { payload: profiles } = await dispatch({
        [RSAA]: {
            endpoint: `api/v1/users/query?${params}`,
            method: "GET",
            types: [types.GET_CUSTOMER_PROFILES, types.GET_CUSTOMER_PROFILES_SUCCESS, types.GET_CUSTOMER_PROFILES_FAILURE],
        },
    });

    return Promise.resolve(profiles);
};

const selectOrderAlert = (orderAlert) => async (dispatch) => {
    dispatch(orderAlertActions.selectOrderAlert(orderAlert));
};

export const actions = {
    getOrderAlerts,
    selectOrderAlert,
};
