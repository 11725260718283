import AcceptRejectDialog from "components/Dialog/AcceptRejectDialog";
import roles from "consts/roles";
import { useUser } from "context/UserContext/UserContext";
import { getMemos, selectMemo, updateMemo } from "store/customers/customer/customerMemos";
import { withAuthorizedDisplay } from "utils/hoc";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import { common, customers } from "translations";
import { useTranslation } from "utils-ts/hooks";
import MemoDialog from "./MemoDialog";
import { MemoCreateForm } from "./MemoForm";
import MemoTable from "./MemoTable";

const Memos = ({ customerId, customerMemos }) => {
    const { isInRole } = useUser();
    const readOnly = isInRole(roles.Auditor);
    const dispatch = useDispatch();
    const { t } = useTranslation(["customers", "common"]);
    const [open, setOpen] = useState(false);
    const [selectedMemo, setSelectedMemo] = useState(null);
    const openDialog = async (memo) => {
        await dispatch(selectMemo(memo));
        setOpen(true);
    };
    const { pageIndex, pageSize } = customerMemos.list;

    const updateMemosList = useCallback(() => {
        dispatch(getMemos({ customerId, pageIndex, pageSize }));
    }, [customerId, dispatch, pageIndex, pageSize]);

    const changeImportant = async (memo) => {
        await dispatch(
            updateMemo(memo.userId, memo.id, {
                ...memo,
                isImportant: !memo.isImportant,
            })
        );
        updateMemosList();
    };

    const deleteMemo = async () => {
        await dispatch(
            updateMemo(selectedMemo.userId, selectedMemo.id, {
                ...selectedMemo,
                isActive: !selectedMemo.isActive,
            })
        );
        setSelectedMemo(null);
        updateMemosList();
    };

    useEffect(() => {
        updateMemosList();
    }, [updateMemosList]);

    return (
        <>
            {!readOnly && (
                <MemoCreateForm
                    customerId={customerId}
                    updateMemosList={updateMemosList}
                />
            )}
            <MemoTable
                customerId={customerId}
                openDialog={openDialog}
                handleMemoUpdate={changeImportant}
                handleDelete={(memo) => setSelectedMemo(memo)}
                readOnly={readOnly}
            />
            <MemoDialog
                open={open}
                handleClose={() => {
                    setOpen(false);
                }}
                customerId={customerId}
                updateMemosList={updateMemosList}
                handleMemoUpdate={changeImportant}
            />
            <AcceptRejectDialog
                maxWidth={"xs"}
                open={Boolean(selectedMemo)}
                title={t(customers.deleteMemo)}
                handleAccept={deleteMemo}
                handleReject={() => setSelectedMemo(null)}
            >
                <Typography variant="body1">
                    {t(`common:${common.areYouSure}`, {
                        what: t(customers.toRemoveMemo),
                    })}
                </Typography>
            </AcceptRejectDialog>
        </>
    );
};

export default withAuthorizedDisplay(Memos);
