import tableStyle from "assets/jss/material-dashboard-react/components/tableStyle";
import tasksStyle from "assets/jss/material-dashboard-react/components/tasksStyle";
import { withStyles } from "tss-react/mui";
import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton, MenuItem, Menu as MenuPopup, Tooltip } from "@mui/material";
import { blue, green } from "@mui/material/colors";
import classnames from "classnames";

const styles = {
    ...tableStyle,
    ...tasksStyle,
    icon: {
        "&:hover": {
            color: blue,
        },
    },
    iconActive: {
        color: green,
    },
};

const Menu = ({ actions, classes, disabled }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                className={classes.icon}
                onClick={handleClick}
            >
                <MenuIcon className={classnames(classes.tableActionButtonIcon, !disabled ? classes.edit : classes.disabled)} />
            </IconButton>
            <MenuPopup
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {actions.map((x) => {
                    const menuItem = (
                        <MenuItem
                            key={x.label}
                            onClick={() => {
                                x.action();
                                handleClose();
                            }}
                            disabled={x.disabled}
                        >
                            {x.label}
                        </MenuItem>
                    );

                    return x.tooltip ? (
                        <Tooltip
                            title={x.tooltip}
                            placement="right-start"
                            arrow
                        >
                            <span>{menuItem}</span>
                        </Tooltip>
                    ) : (
                        menuItem
                    );
                })}
            </MenuPopup>
        </>
    );
};

export default withStyles(Menu, styles);
