import { PostRequest, post } from "utils/extensions";
import types from "./types";

const endpoint = "api/archive-documents";

export const addArchivedDocument = (body) => async (dispatch) =>
    dispatch(
        post(
            new PostRequest(endpoint, types.addArchivedDocument.all, {
                body: {
                    ...body,
                    vendorId: body?.vendorId.split("_")[0],
                    documentFileInput: undefined,
                    documentFileExtension: undefined,
                },
            })
        )
    );
