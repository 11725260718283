import { DeleteRequest, GetRequest, PostRequest, PutRequest, get, post, put, remove } from "utils/extensions";
import types from "./types";

const endpoint = (id) => `api/v1/users/dynamic-segments${id ? `/${id}` : ""}`;

export const getDynamicSegments = (params) => async (dispatch) => {
    await dispatch({ type: types.formInit });
    return dispatch(get(new GetRequest(endpoint(), types.getDynamicSegments.all, { queryParams: params })));
};

export const getDynamicSegment = (dynamicSegmentId) => async (dispatch) => {
    await dispatch({ type: types.formInit });

    if (dynamicSegmentId) {
        return dispatch(get(new GetRequest(endpoint(dynamicSegmentId), types.getDynamicSegment.all)));
    }
};

export const createOrUpdateDynamicSegment = (dynamicSegmentId, values) => async (dispatch) => {
    if (!dynamicSegmentId) {
        return await dispatch(
            post(
                new PostRequest(endpoint(dynamicSegmentId), types.createDynamicSegment.all, {
                    body: {
                        ...values,
                    },
                })
            )
        );
    } else {
        return await dispatch(
            put(
                new PutRequest(endpoint(dynamicSegmentId), types.updateDynamicSegment.all, {
                    body: {
                        ...values,
                    },
                })
            )
        );
    }
};

export const deleteDynamicSegment = (dynamicSegmentId) => async (dispatch) => {
    return dispatch(remove(new DeleteRequest(endpoint(dynamicSegmentId), types.deleteDynamicSegment.all)));
};

export const updateDynamicSegmentUsers = (dynamicSegmentId, users) => async (dispatch) => {
    return dispatch(
        put(
            new PutRequest(endpoint(dynamicSegmentId) + "/users", types.updateDynamicSegmentUsers.all, {
                body: {
                    users,
                },
            })
        )
    );
};

export const getDynamicSegmentUsers = (dynamicSegmentId) => async (dispatch) => {
    return dispatch(get(new GetRequest(endpoint(dynamicSegmentId) + "/users", types.getDynamicSegmentUsers.all)));
};
