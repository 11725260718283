import { SyntheticEvent, useMemo } from "react";
import { forwardRef } from "react";
import { AutocompleteRenderInputParams, TextField, Typography } from "@mui/material";
import { Autocomplete as MUIAutoComplete } from "@mui/material";
import { common } from "translations";
import { AutocompleteProps, Item } from "control-types";
import { useTranslation } from "utils-ts/hooks";
import { TextWithLabel } from "components-ts/text";
import { VirtualizedListbox } from "../virtualizations";
import FormHelperText from "./FormHelperText";

const Autocomplete: React.FC<AutocompleteProps> = forwardRef(
    (
        {
            label,
            items,
            disableCloseOnSelect,
            multiple,
            freeSolo,
            onChange,
            filterOptions,
            selectOnFocus,
            clearOnBlur,
            value,
            error,
            readOnly,
            useVirtualization = false,
            groupBy,
            onBlur,
            isLoadingItems = false,
            classes,
        },
        ref
    ) => {
        const { t } = useTranslation();
        const options = useMemo(
            () =>
                items.map((i) => {
                    if (typeof i === "object" && "value" in i) {
                        return i.value?.toString() || "";
                    } else {
                        return i.toString();
                    }
                }),
            [items]
        );
        const optionsMap = useMemo(() => {
            const arrayMap: Record<string | number, Item> = {};
            items.forEach((i) => {
                if (typeof i === "object" && "value" in i) {
                    if (i.value !== undefined) {
                        arrayMap[i.value] = i;
                    }
                } else if (i !== undefined) {
                    arrayMap[i] = i;
                }
            });

            return arrayMap;
        }, [items]);

        if (readOnly) {
            return (
                <TextWithLabel
                    label={label}
                    value={value === null ? undefined : Array.isArray(value) ? value.map((v) => v?.toString()) : value}
                    items={items}
                    multiple={multiple}
                    error={error}
                />
            );
        }

        const props = {
            clearOnBlur,
            selectOnFocus,
            filterOptions,
            ListboxComponent: useVirtualization ? VirtualizedListbox : undefined,
            options,
            noOptionsText: t(common.noOptions),
            clearText: t(common.clearText),
            disableCloseOnSelect,
            loading: isLoadingItems,
            loadingText: t(common.loading),
            classes,
            renderInput: (params: AutocompleteRenderInputParams) => {
                return (
                    <>
                        <TextField
                            label={label}
                            variant="standard"
                            fullWidth
                            placeholder={label}
                            error={error?.hasError}
                            inputProps={params.inputProps}
                            InputProps={params.InputProps}
                            inputRef={ref}
                        />
                        <FormHelperText error={error} />
                    </>
                );
            },
            isOptionEqualToValue: (option: string | number, value: string | number) => {
                const item = optionsMap[option];
                if (item && typeof item === "object" && "value" in item) {
                    return item.value === value;
                } else {
                    return false;
                }
            },
            getOptionDisabled: (option: string | number) => {
                const item = optionsMap[option];
                if (item && typeof item === "object" && "disabled" in item) {
                    return item.disabled ?? false;
                } else {
                    return false;
                }
            },
            getOptionLabel: (option: string | number) => {
                const item = optionsMap[option];
                if (item && typeof item === "object" && "name" in item) {
                    return typeof item.name === "string" ? item.name : t(item.name);
                } else {
                    return option.toString();
                }
            },
            onBlur,
            onChange: (_event: SyntheticEvent<Element, Event>, value: (string | number)[] | string | number | null) => {
                if (value === null) {
                    onChange(undefined);
                } else if (multiple === true) {
                    if (!Array.isArray(value) || value.length === 0) {
                        onChange(undefined);
                    } else if (value.every((v: string | number) => typeof v === "number")) {
                        onChange(value.map((v) => v as number));
                    } else {
                        onChange(value.map((v) => v.toString()));
                    }
                } else {
                    if (Array.isArray(value)) {
                        if (value.length === 0) {
                            onChange(undefined);
                        } else {
                            onChange(value.join(","));
                        }
                    } else {
                        onChange(value);
                    }
                }
            },
            groupBy: groupBy
                ? (option: string | number) => {
                      const item = optionsMap[option];

                      return groupBy(item);
                  }
                : undefined,
            renderOption: !multiple
                ? (props: React.HTMLAttributes<HTMLLIElement>, option: string | number) => {
                      const item = optionsMap[option];
                      let optionLabel = "";
                      if (item && typeof item === "object" && "name" in item) {
                          optionLabel = typeof item.name === "string" ? item.name : t(item.name);
                      } else {
                          optionLabel = option.toString();
                      }

                      return (
                          <li
                              key={option}
                              {...props}
                          >
                              <Typography
                                  variant="body1"
                                  style={{ whiteSpace: "pre-line" }}
                              >
                                  {optionLabel}
                              </Typography>
                          </li>
                      );
                  }
                : undefined,
        };

        if (multiple === true && freeSolo === true) {
            return (
                <MUIAutoComplete<string | number, typeof multiple, false, true>
                    {...props}
                    multiple
                    freeSolo
                    autoSelect
                    value={items && items.length >= 0 ? (!value ? [] : Array.isArray(value) ? value : [value]) : []}
                />
            );
        } else if (multiple === true && freeSolo === undefined) {
            return (
                <MUIAutoComplete<string | number, true, false, false>
                    {...props}
                    multiple
                    value={items && items.length >= 0 ? (!value ? [] : Array.isArray(value) ? value : [value]) : []}
                />
            );
        } else if (multiple === undefined && freeSolo === true) {
            return (
                <MUIAutoComplete<string | number, false, false, true>
                    {...props}
                    freeSolo
                    autoSelect
                    value={items && items.length >= 0 ? (!value || Array.isArray(value) ? "" : value) : ""}
                />
            );
        } else {
            return (
                <MUIAutoComplete<string | number, false, false, false>
                    {...props}
                    value={items && items.length >= 0 ? (!value || Array.isArray(value) ? "" : value) : ""}
                />
            );
        }
    }
);

export default Autocomplete;
