import { RSAA } from "redux-api-middleware";
import { commerce } from "store/actionTypePrefixes";
import { AsyncTypes } from "utils/extensions";
import { asAsyncFormTypes } from "utils/extensions/arrayExtensions";
import { chain } from "lodash";

export const types = {
    CUSTOMER_DELETE: `${commerce}/CUSTOMER_DELETE`,
    CUSTOMER_DELETE_SUCCESS: `${commerce}/CUSTOMER_DELETE_SUCCESS`,
    CUSTOMER_DELETE_FAILURE: `${commerce}/CUSTOMER_DELETE_FAILURE`,
    CUSTOMER_PUT: `${commerce}/CUSTOMER_PUT`,
    CUSTOMER_PUT_SUCCESS: `${commerce}/CUSTOMER_PUT_SUCCESS`,
    CUSTOMER_PUT_FAILURE: `${commerce}/CUSTOMER_PUT_FAILURE`,
    CUSTOMER_LOGIN_AS_CUSTOMER: `${commerce}/CUSTOMER_LOGIN_AS_CUSTOMER`,
    CUSTOMER_LOGIN_AS_CUSTOMER_SUCCESS: `${commerce}/CUSTOMER_LOGIN_AS_CUSTOMER_SUCCESS`,
    CUSTOMER_LOGIN_AS_CUSTOMER_FAILURE: `${commerce}/CUSTOMER_LOGIN_AS_CUSTOMER_FAILURE`,
    CUSTOMER_CHANGE_CREDIT_LIMIT: `${commerce}/CUSTOMER_CHANGE_CREDIT_LIMIT`,
    CUSTOMER_CHANGE_CREDIT_LIMIT_SUCCESS: `${commerce}/CUSTOMER_CHANGE_CREDIT_LIMIT_SUCCESS`,
    CUSTOMER_CHANGE_CREDIT_LIMIT_FAILURE: `${commerce}/CUSTOMER_CHANGE_CREDIT_LIMIT_FAILURE`,
    CUSTOMER_GET_SHIPPING_ADRESSES: `${commerce}/CUSTOMER_GET_SHIPPING_ADRESSES`,
    CUSTOMER_GET_SHIPPING_ADRESSES_SUCCESS: `${commerce}/CUSTOMER_GET_SHIPPING_ADRESSES_SUCCESS`,
    CUSTOMER_GET_SHIPPING_ADRESSES_FAILURE: `${commerce}/CUSTOMER_GET_SHIPPING_ADRESSES_FAILURE`,
    CUSTOMER_EXPORT_TO_AX: `${commerce}/CUSTOMER_EXPORT_TO_AX`,
    CUSTOMER_EXPORT_TO_AX_SUCCESS: `${commerce}/CUSTOMER_EXPORT_TO_AX_SUCCESS`,
    CUSTOMER_EXPORT_TO_AX_FAILURE: `${commerce}/CUSTOMER_EXPORT_TO_AX_FAILURE`,
    CUSTOMER_REMOVE_EMAIL_VERIFICATION_REQUIRMENT_REQUEST: `${commerce}/CUSTOMER_REMOVE_EMAIL_VERIFICATION_REQUIRMENT_REQUEST`,
    CUSTOMER_REMOVE_EMAIL_VERIFICATION_REQUIRMENT_SUCCESS: `${commerce}/CUSTOMER_REMOVE_EMAIL_VERIFICATION_REQUIRMENT_SUCCESS`,
    CUSTOMER_REMOVE_EMAIL_VERIFICATION_REQUIRMENT_FAILURE: `${commerce}/CUSTOMER_REMOVE_EMAIL_VERIFICATION_REQUIRMENT_FAILURE`,
    setAccountManager: new AsyncTypes("SET_ACCOUNT_MANAGER", commerce),
    setRetentionManager: new AsyncTypes("SET_RETENTION_MANAGER", commerce),
};

const deleteCustomer = (customerId) => async (dispatch) => {
    await dispatch({
        [RSAA]: {
            endpoint: `api/v1/users/${customerId}`,
            method: "DELETE",
            types: asAsyncFormTypes([types.CUSTOMER_DELETE, types.CUSTOMER_DELETE_SUCCESS, types.CUSTOMER_DELETE_FAILURE]),
        },
    });
};

const loginAsCustomer = (customerId, email) => async (dispatch) => {
    const { payload } = await dispatch({
        [RSAA]: {
            endpoint: `api/v1/users/${customerId}/signin-token`,
            method: "POST",
            types: [types.CUSTOMER_LOGIN_AS_CUSTOMER, types.CUSTOMER_LOGIN_AS_CUSTOMER_SUCCESS, types.CUSTOMER_LOGIN_AS_CUSTOMER_FAILURE],
            body: JSON.stringify({
                email,
            }),
        },
    });
    window.open(payload.frontendBaseUri + "?login_token=" + payload.passwordToken);
};

const editCustomer = (customerId, formData) => async (dispatch) => {
    const { password, billingAddress = {}, ...customer } = formData;
    const { country, ...billingAddressData } = billingAddress;
    const anyFilled = (billingAddressData) => {
        return chain(billingAddressData)
            .reduce((c, n) => c || n)
            .value();
    };

    let body = {
        ...customer,
        mobileNumber: {
            value: customer.mobileNumber ? customer.mobileNumber : null,
        },
    };

    let putProfile;
    if (anyFilled(billingAddressData)) {
        body = {
            ...body,
            billingAddress: {
                value: {
                    ...billingAddress,
                    phoneNumber: billingAddress.phoneNumber ? billingAddress.phoneNumber : null,
                    country,
                },
            },
        };
    } else {
        body = {
            ...body,
            billingAddress: { value: null },
        };
    }

    putProfile = dispatch({
        [RSAA]: {
            endpoint: `api/v1/users/${customerId}`,
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            types: asAsyncFormTypes([types.CUSTOMER_PUT, types.CUSTOMER_PUT_SUCCESS, types.CUSTOMER_PUT_FAILURE]),
            body: JSON.stringify({ ...body }),
        },
    });

    if (customer.initialEmail !== customer.email) {
        dispatch({
            [RSAA]: {
                endpoint: `api/v1/users/${customerId}/email`,
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                types: asAsyncFormTypes([types.CUSTOMER_PUT, types.CUSTOMER_PUT_SUCCESS, types.CUSTOMER_PUT_FAILURE]),
                body: JSON.stringify({ email: customer.email }),
            },
        });
    }
    let putPassword = Promise.resolve();

    if (password) {
        putPassword = dispatch({
            [RSAA]: {
                endpoint: `api/v1/users/${customerId}/password`,
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                types: asAsyncFormTypes([types.CUSTOMER_PUT, types.CUSTOMER_PUT_SUCCESS, types.CUSTOMER_PUT_FAILURE]),
                body: JSON.stringify({ password }),
            },
        });
    }

    return await Promise.all([putProfile, putPassword]);
};

const changeCreditLimit =
    (customerId, { paymentTerm, creditAmount, isEnabled }) =>
    async (dispatch) => {
        const body = JSON.stringify(creditAmount && isEnabled ? { creditLimit: { creditAmount, paymentTerm } } : {});

        await dispatch({
            [RSAA]: {
                endpoint: `api/v1/users/${customerId}/balance/credit-limit`,
                headers: { "Content-Type": "application/json" },
                method: "PUT",
                types: asAsyncFormTypes([
                    types.CUSTOMER_CHANGE_CREDIT_LIMIT,
                    types.CUSTOMER_CHANGE_CREDIT_LIMIT_SUCCESS,
                    types.CUSTOMER_CHANGE_CREDIT_LIMIT_FAILURE,
                ]),
                body,
            },
        });

        return Promise.resolve();
    };

const getShippingAddresses = (customerId) => async (dispatch) => {
    await dispatch({
        [RSAA]: {
            endpoint: `api/v1/users/${customerId}/addresses/shipping-addresses`,
            method: "GET",
            types: [types.CUSTOMER_GET_SHIPPING_ADRESSES, types.CUSTOMER_GET_SHIPPING_ADRESSES_SUCCESS, types.CUSTOMER_GET_SHIPPING_ADRESSES_FAILURE],
        },
    });
};

const exportToAx = (customerId, body) => async (dispatch) => {
    await dispatch({
        [RSAA]: {
            endpoint: `api/v1/users/${customerId}/export-request`,
            method: "POST",
            headers: { "Content-Type": "application/json" },
            types: asAsyncFormTypes([types.CUSTOMER_EXPORT_TO_AX, types.CUSTOMER_EXPORT_TO_AX_SUCCESS, types.CUSTOMER_EXPORT_TO_AX_FAILURE]),
            body: JSON.stringify(body),
        },
    });
};

const removeEmailVerificationRequirement = (customerId) => (dispatch) => {
    dispatch({
        [RSAA]: {
            endpoint: `api/v1/users/${customerId}/email/verification`,
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
            types: asAsyncFormTypes([
                types.CUSTOMER_REMOVE_EMAIL_VERIFICATION_REQUIRMENT_REQUEST,
                types.CUSTOMER_REMOVE_EMAIL_VERIFICATION_REQUIRMENT_SUCCESS,
                types.CUSTOMER_REMOVE_EMAIL_VERIFICATION_REQUIRMENT_FAILURE,
            ]),
        },
    });
};

const setAccountManager = (customerId, accountManager) => (dispatch) => {
    return dispatch({
        [RSAA]: {
            endpoint: `api/v1/users/${customerId}/account-manager`,
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            types: asAsyncFormTypes(types.setAccountManager.all),
            body: JSON.stringify({ accountManager }),
        },
    });
};

const setRetentionManager = (customerId, retentionManager) => (dispatch) => {
    return dispatch({
        [RSAA]: {
            endpoint: `api/v1/users/${customerId}/retention-manager`,
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            types: asAsyncFormTypes(types.setRetentionManager.all),
            body: JSON.stringify({ retentionManager }),
        },
    });
};

export const actions = {
    deleteCustomer,
    loginAsCustomer,
    editCustomer,
    changeCreditLimit,
    getShippingAddresses,
    exportToAx,
    removeEmailVerificationRequirement,
    setAccountManager,
    setRetentionManager,
};
