import defaultListState from "store/defaultListState";
import { combineReducers } from "redux";
import types from "./types";

const vendorDecisionInitialState = {
    isLoading: false,
    submitted: false,
    subsidiaryGain: {},
};

const formatServiceConfirmations = (payload) => ({
    ...payload,
    serviceConfirmations: payload.subsidiaryGain.serviceConfirmations.map((s) => ({ blobName: s.screenshot.blobName })),
});

const sgDecision = (state = vendorDecisionInitialState, { type, payload, meta }) => {
    switch (type) {
        case types.getSubsidiaryGain.request:
            return { ...state, isLoading: true };
        case types.getSubsidiaryGain.success:
            return { ...state, isLoading: false, ...formatServiceConfirmations(payload) };
        case types.getSubsidiaryGain.failure:
            return { ...state, isLoading: false };

        case types.makeDecision.request:
            return { ...state, submitted: true };
        case types.makeDecision.success:
            return { ...state, submitted: false };
        case types.makeDecision.failure:
            return { ...state, submitted: false };

        case types.getServiceConfirmation.success:
            return {
                ...state,
                serviceConfirmations: (state.serviceConfirmations ?? []).map((confirmation) =>
                    confirmation.blobName === meta.blobName ? { ...confirmation, imageUrl: payload } : confirmation
                ),
            };

        default:
            return state;
    }
};

const sgListInitialState = {
    subsidiaryGainUsers: [],
    subsidiaryGainStatuses: [],
    vendorRecipients: [],
    accountingPeriods: [],
    isDownloadingPwu: {},
    ...defaultListState,
};
const sgList = (state = sgListInitialState, { type, payload }) => {
    switch (type) {
        case types.findSubsidiaryGains.success:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case types.findSubsidiaryGains.failure:
            return {
                ...state,
                isLoading: false,
            };

        case types.getSubsidiaryGainsUsers.success:
            return { ...state, subsidiaryGainUsers: payload };

        case types.getSubsidiaryGainsStatuses.success:
            return { ...state, subsidiaryGainStatuses: payload };

        case types.getVendorRecipients.success:
            return { ...state, vendorRecipients: payload };

        case types.getAccountingPeriods.success:
            return { ...state, accountingPeriods: payload };

        default:
            return state;
    }
};

export const vendorUsers = combineReducers({ sgDecision, sgList });
