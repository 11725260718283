import { api } from "api";
import FormAutocomplete from "../FormAutocomplete";

type CampaignAutocompleteProps = {
    label: string;
    /**
     * default 'warehouse'
     */
    name?: string;
    readOnly?: boolean;
};

const CampaignAutocomplete: React.FC<CampaignAutocompleteProps> = ({ label, name = "campaign", readOnly }) => {
    const { data, status } = api.commerce.autocomplete.useFindCampaigns();

    return (
        <FormAutocomplete
            name={name}
            label={label}
            items={data || []}
            freeSolo
            isLoadingItems={status === "pending"}
            readOnly={readOnly}
            disableEmpty
        />
    );
};

export default CampaignAutocomplete;
