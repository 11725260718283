import { RSAA } from "redux-api-middleware";
import { GetRequest, get } from "utils/extensions";
import moment from "moment";
import types from "./types";

export const getProductPreview =
    (productId, dateTimeOverride = moment().format(), warehouse) =>
    async (dispatch) => {
        return dispatch({
            [RSAA]: {
                endpoint: `api/v1/offer/products/${productId}/internal`,
                headers: {
                    "Content-Type": "application/json",
                    "X-Frisco-DateTimeOverride": dateTimeOverride,
                    "X-Frisco-Warehouse": warehouse,
                },
                method: "GET",
                types: types.getProductPreview.all,
            },
        });
    };

export const cleanUp = () => (dispatch) => {
    dispatch({ type: types.productPreviewCleanup });
};

export const getProductInternalData = (productId) => async (dispatch) => {
    await dispatch(get(new GetRequest(`api/products/${productId}/internal-data`, types.getProductInternalData.all)));

    return Promise.resolve();
};

export const getProductPriceHistory = (productId, warehouse) => async (dispatch) => {
    return dispatch({
        [RSAA]: {
            endpoint: `api/v1/offer/products/${productId}/price-history`,
            headers: {
                "Content-Type": "application/json",
                "X-Frisco-Warehouse": warehouse,
            },
            method: "GET",
            types: types.getProductPriceHistory.all,
        },
    });
};
