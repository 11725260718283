import { documentArchiveCompanies } from "resource/documentArchiveCompanies";
import { createSelector } from "reselect";

const root = (state) => {
    return {
        autocomplete: state.autocomplete,
        documentArchive: state.documentArchive,
    };
};

export const documentSelector = createSelector(root, (state) => state.documentArchive);

const mapDocuments = (documents, autocomplete, categoryAlwaysVisible) => {
    const { vendors, documentCategories, documentTypes } = autocomplete;
    return documents.map((item) => ({
        ...item,
        category: item.canAccess ? documentCategories.find((x) => x.id === item.categoryId)?.name : "Brak dostępu",
        documentDate: item.canAccess ? item.documentDate : undefined,
        documentType: documentTypes.find((x) => x.id === item.documentTypeId)?.name,
        contractor: item.contractorId
            ? `[${item.contractorId}] - ${vendors.find((x) => x.vendorId === (item.vendorId ?? `${item.contractorId}_FRS`))?.name}`
            : "",
        company: documentArchiveCompanies.find((d) => d.value === item.companyId)?.name,
    }));
};
export const linkedDocumentsSelector = createSelector(root, (state) => {
    const { autocomplete } = state;
    const { linkedDocuments } = state.documentArchive.document;
    const { search, documents = [] } = linkedDocuments || {};
    const { items = [] } = search || {};

    return {
        ...linkedDocuments,
        documents: mapDocuments(documents, autocomplete, true),
        search: {
            ...search,
            items: mapDocuments(items, autocomplete, false),
        },
    };
});
