import defaultListState from "store/defaultListState";
import { combineReducers } from "redux";
import { types } from "./types";

const initialState = {
    list: {
        ...defaultListState,
        friscoId: "",
        productName: "",
    },
    matching: {
        exportingUnmatched: false,
        exportingNew: false,
        importing: false,
        importErrors: undefined,
    },
};

const list = (state = initialState.list, { type, payload }) => {
    const { friscoId, productName, ...rest } = state;
    switch (type) {
        case types.clearList:
            return initialState.list;
        case types.updateListFilters:
            return { ...rest, ...payload };
        case types.list.request:
            return {
                ...initialState.list,
                friscoId,
                productName,
                isLoading: true,
            };
        case types.list.success:
            return { ...payload, friscoId, productName, isLoading: false };
        case types.list.failure:
            return { ...payload, friscoId, productName, isLoading: false };
        default:
            return state;
    }
};

const form = (state = initialState.list, { type, payload }) => {
    switch (type) {
        case types.form.request:
            return { ...state, isLoading: true };
        case types.form.success:
            return { ...payload, isLoading: false };
        case types.form.failure:
            return { ...payload, isLoading: false };
        default:
            return state;
    }
};

const matching = (state = initialState.matching, { type, payload }) => {
    switch (type) {
        case types.exportUnmatched.request:
            return { ...state, exportingUnmatched: true };
        case types.exportUnmatched.success:
        case types.exportUnmatched.failure:
            return { ...state, exportingUnmatched: false };

        case types.exportNew.request:
            return { ...state, exportingNew: true };
        case types.exportNew.success:
        case types.exportNew.failure:
            return { ...state, exportingNew: false };

        case types.import.request:
            return {
                ...state,
                importing: true,
                importErrors: undefined,
            };
        case types.import.success:
            return {
                ...state,
                importing: false,
                importErrors: payload.errors ?? [],
            };
        case types.import.failure:
            return {
                ...state,
                importing: false,
            };
        case types.clearImportErrors:
            return {
                ...state,
                importErrors: undefined,
            };
        default:
            return state;
    }
};

export default combineReducers({
    list,
    form,
    matching,
});
