import Button from "components/Button/Button";
import { ToggleActiveGroup } from "components/Controls";
import AcceptRejectDialog from "components/Dialog/AcceptRejectDialog";
import SearchBar from "components/SearchBar/SearchBar";
import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import { useCommonTranslation } from "utils/hooks";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Icon, Typography } from "@mui/material";

const Table = ({
    dataSelector,
    columns,
    addItemAction,
    dataSourceAction,
    stripped = true,
    addSearch = false,
    canAddNewItem = true,
    showToggle = true,
    persistentFilter,
    filterFunction,
    initialFilterValue,
    filterValue,
    acceptRejectMessge = false,
    acceptRejectMessgeTranslations = [],
    shoudlHandleDoubleClick = false,
}) => {
    const [isActive, setIsActive] = useState(true);
    const [search, setSearch] = useState("");
    const { t, common } = useCommonTranslation();
    const { items, isLoading, pageIndex, totalCount, pageSize, pageCount } = useSelector(dataSelector);
    const dispatch = useDispatch();
    const [currentItem, setCurrentItem] = useState();
    const getData = (params) => {
        if (showToggle) {
            dispatch(dataSourceAction(params));
        } else {
            const { isActive, ...rest } = params;
            dispatch(dataSourceAction(rest));
        }
    };
    useEffect(() => {
        getData({ isActive, pageIndex, pageSize, search, ...filterValue });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSourceAction, isActive, filterValue]);

    const previewAction = columns.find((x) => x.key === "action" && x.actionType === "preview").action;

    const removeAction = columns.find((x) => x.key === "action" && x.actionType === "delete")?.action;

    const mappedColumns = columns
        .map((c) => {
            if (c.actionType !== "delete") return c;

            return {
                ...c,
                action(item) {
                    setCurrentItem(item);
                },
            };
        })
        .map((c) => {
            if (c.key !== "action" || !c.action || !c.reloadOnAction || c.actionType === "delete") return c;

            return {
                ...c,
                action: async (item) => {
                    await c.action(item);
                    getData({ isActive, pageIndex, pageSize, search });
                },
            };
        });

    return (
        <>
            {addSearch && (
                <Grid item>
                    <SearchBar
                        value={search}
                        onSearchChanged={(event) => {
                            event.persist();
                            setSearch(event.target.value);
                        }}
                        onSearchCommit={() =>
                            getData({
                                isActive,
                                pageIndex,
                                pageSize,
                                search,
                            })
                        }
                        placeholder={t(common.search)}
                    />
                </Grid>
            )}
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
            >
                <Grid item>
                    {showToggle && (
                        <ToggleActiveGroup
                            onChange={setIsActive}
                            value={isActive}
                        />
                    )}
                </Grid>
                <Grid item>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-end"
                    >
                        <Grid item>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() =>
                                    getData({
                                        isActive,
                                        pageIndex: 1,
                                        pageSize,
                                        search,
                                    })
                                }
                            >
                                <Icon>refresh</Icon>
                            </Button>
                        </Grid>
                        {canAddNewItem && (
                            <Grid item>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={addItemAction}
                                >
                                    <Icon>add</Icon>
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <SimpleTable
                stripped={stripped}
                isLoading={isLoading}
                columns={mappedColumns}
                onRowDoubleClick={(_, item) => previewAction(item)}
                hover
                pagination
                data={items}
                persistentFilter={persistentFilter}
                paginationProps={{
                    pageIndex,
                    totalCount,
                    pageSize,
                    pageCount,
                    onPageChange: (pageIndex) => getData({ isActive, pageIndex, pageSize, search }),
                    onRowsPerPageChange: (pageSize) => getData({ isActive, pageIndex, pageSize, search }),
                }}
                filterFunction={filterFunction}
                initialFilterValue={initialFilterValue}
                shoudlHandleDoubleClick={shoudlHandleDoubleClick}
            />
            <AcceptRejectDialog
                maxWidth={"sm"}
                title={t(common.removeItem)}
                open={Boolean(currentItem)}
                handleAccept={async () => {
                    await removeAction(currentItem);
                    await getData({ isActive, pageIndex, pageSize, search });
                    setCurrentItem(null);
                }}
                handleReject={() => {
                    setCurrentItem(null);
                }}
            >
                <Typography variant="body1">
                    {t(common.areYouSure, {
                        what: `${t(common.toRemoveItem)} ${currentItem?.name || currentItem?.containerName || currentItem?.id || ""}`,
                    }) +
                        (acceptRejectMessge && currentItem?.affected?.length > 0
                            ? acceptRejectMessge +
                              currentItem?.affected.map((a) => acceptRejectMessgeTranslations.find((t) => t.id == a)?.name || a).join(", ")
                            : "")}
                </Typography>
            </AcceptRejectDialog>
        </>
    );
};

export default Table;
