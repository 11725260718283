import { useState } from "react";
import { common } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { ToggleButtonGroup } from "components-ts/controls";
import { FormRow } from "components-ts/forms/layout";
import FormTextField from "../FormTextField";

const PostcodePredicate: React.FC<{
    readOnly?: boolean;
}> = ({ readOnly }) => {
    const [type, setType] = useState<"mask" | "range">("range");
    const { t } = useTranslation();

    return (
        <>
            <ToggleButtonGroup
                value={type}
                items={[
                    {
                        value: "mask",
                        name: "Maska",
                    },
                    {
                        value: "range",
                        name: "Zakres",
                    },
                ]}
                onChange={(newValue) => {
                    if (newValue === "mask" || newValue === "range") {
                        setType(newValue);
                    }
                }}
                readOnly={readOnly}
                label={t(common.type)}
            />

            {type === "range" ? (
                <FormRow>
                    <FormTextField
                        name="postcodeFrom"
                        label={t(common.rangeFrom)}
                        readOnly={readOnly}
                    />

                    <FormTextField
                        name="postcodeTo"
                        label={t(common.rangeTo)}
                        readOnly={readOnly}
                    />
                </FormRow>
            ) : (
                <FormRow>
                    <FormTextField
                        name="mask"
                        label={t(common.mask)}
                        readOnly={readOnly}
                    />
                </FormRow>
            )}
        </>
    );
};

export default PostcodePredicate;
