import { GetRequest, PutRequest, get, put } from "utils/extensions";
import { combineIntoQueryParams } from "utils/objectExtensions";
import { downloadXlsxFile } from "utils/rsaa/downloadFileAction";
import moment from "moment";
import types from "./types";

export const getCorrectionRequestReport =
    ({ settlementType, dateFrom, dateTo, vendorIds }) =>
    async (dispatch) => {
        return dispatch(
            get(
                new GetRequest(`api/correction-request/${settlementType}`, types.getCorrectionRequestReport.all, {
                    queryParams: {
                        dateFrom,
                        dateTo,
                        vendorIds,
                    },
                })
            )
        );
    };

export const getCorrectionRequestReportFile =
    ({ settlementType, dateFrom, dateTo, vendorIds }) =>
    async (dispatch) => {
        await dispatch(
            downloadXlsxFile(
                `api/correction-request/${settlementType}/report?${combineIntoQueryParams({
                    dateFrom,
                    dateTo,
                    vendorIds,
                })}`,
                types.getCorrectionRequestReportFile.all,
                `Raport_ŻFK_${settlementType}_${moment().format("YYYY_MM_DD")}`
            )
        );

        return Promise.resolve();
    };

export const makeDecision = (correctionRequestIds, isPositive) => async (dispatch) => {
    return dispatch(
        put(
            new PutRequest("api/correction-request/make-decision", types.acceptCorrectionRequests.all, {
                body: {
                    correctionRequestIds,
                    isPositive,
                },
            })
        )
    );
};

export const changeSettlementType = () => async (dispatch) => {
    dispatch({ type: types.changeSettlementType, payload: { items: [] } });
};
