import GridContainer from "components/Grid/GridContainer";
import TextWithLabel from "components/Text/TextWithLabel";
import packagingMethods from "resource/packagingMethods.json";
import paymentMethods from "resource/paymentMethods.json";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { Fragment } from "react";
import { orders } from "translations";

const VariousData = (props) => {
    const {
        placedAt,
        delivery = {},
        membershipPoints,
        orderDocuments,
        isEarlyEntranceAllowed = false,
        deliveryMethod,
        packagingMethod,
        paymentMethod,
        onlineAmount,
        cashAmount,
        totalWithDeliveryCostAfterVoucherPayment: amountToCollect,
        total,
        balanceSettlement,
        deliveryCost,
        packagingCost,
        invoiceTotalWithDeliveryCost,
        warehouse,
    } = props;

    //prettier-ignore
    const fields = [
        { label: `orders:${orders.warehouse}`,              value: warehouse,               format: formatTypes.text,                                                                  column: 1  },
        { label: `orders:${orders.orderMembershipPoints}`,  value: membershipPoints,        format: formatTypes.text,                                                                  column: 1  },
        { label: `orders:${orders.placedAt}`,               value: placedAt,                format: formatTypes.time,          options: { timeFormat: timePrecisonFormats.seconds },   column: 1  },
        { label: `orders:${orders.invoice}`,                value: (orderDocuments || []).map(x => x.documentNumber),              format: formatTypes.text,                           column: 1  },
        { label: `orders:${orders.deliveryWindow}`,         value: delivery,                format: formatTypes.deliveryWindow,                                                        column: 1  },
        { label: `orders:${orders.isEarlyEntranceAllowed}`, value: isEarlyEntranceAllowed,  format: formatTypes.boolean,                                                               column: 1  },
        { label: `orders:${orders.deliveryMethod}`,         value: deliveryMethod,          format: formatTypes.text,                                                                  column: 1  },
        { label: `orders:${orders.packagingMethod}`,        value: packagingMethod,         format: formatTypes.resource,  options:{ items: packagingMethods }, column: 1  },
        { label: `orders:${orders.paymentMethodDelivery}`,  value: paymentMethod,           format: formatTypes.resource, options: { items: paymentMethods },   column: 1 },
        { label: `orders:${orders.onlineAmount}`,           value: onlineAmount,            format: formatTypes.currencyAmount,                                                              column: 2  },
        { label: `orders:${orders.cashAmount}`,             value: cashAmount,              format: formatTypes.currencyAmount,                                                              column: 2  },
        { label: `orders:${orders.amountToCollect}`,        value: amountToCollect,         format: formatTypes.currencyAmount,                                                              column: 2  },
        { label: `orders:${orders.orderTotal}`,             value: total,                   format: formatTypes.currencyAmount,                                                              column: 2  },
        { label: `orders:${orders.balanceSettlement}`,      value: balanceSettlement,       format: formatTypes.currencyAmount,                                                              column: 3  },
        { label: `orders:${orders.deliveryCost}`,           value: deliveryCost,            format: formatTypes.currencyAmount,                                                              column: 3  },
        { label: `orders:${orders.packagingCost}`,          value: packagingCost,           format: formatTypes.currencyAmount,                                                              column: 3  },
        { label: `orders:${orders.invoiceTotal}`,           value: invoiceTotalWithDeliveryCost,              format: formatTypes.currencyAmount,                                            column: 3  },
    ];

    const leftColumn = fields.filter((x) => x.column === 1);
    const centerColumn = fields.filter((x) => x.column === 2);
    const rightColumn = fields.filter((x) => x.column === 3);
    return (
        <Fragment>
            <GridContainer
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                iconName="device_hub"
                title={`orders:${orders.orderDetails}`}
            >
                <GridContainer
                    item
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    {leftColumn.map((item) => (
                        <TextWithLabel
                            key={item.label}
                            {...item}
                        />
                    ))}
                </GridContainer>
                <GridContainer
                    item
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    {centerColumn.map((item) => (
                        <TextWithLabel
                            key={item.label}
                            {...item}
                        />
                    ))}
                </GridContainer>
                <GridContainer
                    item
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    {rightColumn.map((item) => (
                        <TextWithLabel
                            key={item.label}
                            {...item}
                        />
                    ))}
                </GridContainer>
            </GridContainer>
        </Fragment>
    );
};

export default VariousData;
