import defaultListState from "store/defaultListState";
import { combineReducers } from "redux";
import types from "./types";

const listInitialState = {
    idFilter: undefined,
    levelFilter: undefined,
    nameFilter: undefined,
    ...defaultListState,
};

const formInitialState = {
    isLoading: false,
    isSubbmitRequested: false,
};

const list = (state = listInitialState, { type, payload }) => {
    switch (type) {
        case types.findAnalyticGroups.request:
            return { ...state, isLoading: true };
        case types.findAnalyticGroups.success:
            return { ...state, isLoading: false, ...payload };
        case types.findAnalyticGroups.failure:
            return { ...state, isLoading: false };
        case types.changeQueryParams:
            return { ...state, ...payload };
        default:
            return state;
    }
};

const form = (state = formInitialState, { type, payload }) => {
    switch (type) {
        case types.getAnalyticGroup.request:
            return { ...state, isLoading: true };
        case types.getAnalyticGroup.success:
            return { ...state, isLoading: false, ...payload };
        case types.getAnalyticGroup.failure:
            return { ...state, isLoading: false };
        default:
            return state;
    }
};

export const analyticGroups = combineReducers({
    list,
    form,
});
