import TextWithLabel from "components/Text/TextWithLabel";
import { formatTypes } from "utils/formating";
import { useAppLocation } from "utils/hooks";
import { common } from "translations";
import ProductField from "views/offers/productPreview/components/ProductField";

const DescriptionAndLinks = ({ id, description, productUrl, imageUrl, hideContentLink }) => {
    const appLocation = useAppLocation();
    let info = [
        new ProductField(common.description, description, formatTypes.text),
        new ProductField(common.productUrl, productUrl, formatTypes.link, {
            url: productUrl,
            target: "_blank",
            addCopyIcon: true,
        }),
        new ProductField(common.imageUrl, imageUrl, formatTypes.link, {
            url: imageUrl,
            target: "_blank",
            addCopyIcon: true,
        }),
    ];

    if (!hideContentLink) {
        info.push(
            new ProductField(common.contentUrl, `${appLocation}/content/products/${id}`, formatTypes.link, {
                url: `${appLocation}/content/products/${id}`,
                target: "_blank",
                addCopyIcon: true,
            })
        );
    }

    return info.map((f) => (
        <TextWithLabel
            unselectableLabel
            key={f.label}
            {...f}
        />
    ));
};

export default DescriptionAndLinks;
