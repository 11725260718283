import { types } from "./actions";

const initialState = {
    definition: {
        replacements: [],
        equivalents: [],
    },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case types.GET_SYNONYMS:
            return { ...initialState, isLoading: true };
        case types.GET_SYNONYMS_SUCCESS:
            return { ...payload, isLoading: false };
        case types.GET_SYNONYMS_FAILURE:
            return { ...payload, isLoading: false };
        case types.PUT_SYNONYMS:
            return { ...state, isSubbmitRequested: true };
        case types.PUT_SYNONYMS_SUCCESS:
        case types.PUT_SYNONYMS_FAILURE:
            return { ...state, isSubbmitRequested: false };
        default:
            return state;
    }
};
