import defaultListState from "store/defaultListState";
import { types } from "./action";

const initialState = defaultListState;

export const dotPayPayouts = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case types.DOTPAY_PAYOUTS_GET_FAILURE:
        case types.DOTPAY_PAYOUTS_REPEAT_PUT_FAILURE:
        case types.DOTPAY_PAYOUTS_REPEAT_PUT_SUCCESS:
            return { ...state, isLoading: false };
        case types.DOTPAY_PAYOUTS_GET:
        case types.DOTPAY_PAYOUTS_REPEAT_PUT:
            return { ...state, isLoading: true };
        case types.DOTPAY_PAYOUTS_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                ...payload,
            };
        default:
            return { ...state };
    }
};
