import { types } from "./types";

const initialState = {};

export default (state = initialState, { type, payload, meta }) => {
    switch (type) {
        case types.initializePromotionForm:
            return { ...initialState };
        case types.getPromotion.request:
            return { ...state, isLoading: true };
        case types.getPromotion.success:
            return {
                ...state,
                ...payload,
                id: meta.isCopy ? "" : payload.id,
                isLoading: false,
            };
        case types.getPromotion.failure:
            return { ...payload, isLoading: false };
        case types.createOrUpdatePromotion.request:
            return { ...state, isSubbmitRequested: true };
        case types.createOrUpdatePromotion.success:
        case types.createOrUpdatePromotion.failure:
            return { ...state, isSubbmitRequested: false };
        case types.getPromotionActivation.request:
            return { ...state };
        case types.getPromotionActivation.success:
            return { ...state, activationInfo: payload };
        case types.getPromotionActivation.failure:
            return { ...state, activationInfo: {} };
        case types.getPromotionValidation.request:
            return { ...state };
        case types.getPromotionValidation.success:
            return { ...state, promotionValidation: payload };
        case types.getPromotionValidation.failure:
            return { ...state, promotionValidation: {} };
        default:
            return state;
    }
};
