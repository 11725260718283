export const promotionTypes = {
    Delivery: "Delivery",
    DoublePrice: "DoublePrice",
    Gratis: "Gratis",
    GratisPicker: "GratisPicker",
    Membership: "Membership",
    Pack: "Pack",
    PackGratis: "PackGratis",
    Pair: "Pair",
    Pool: "Pool",
    PoolGratis: "PoolGratis",
    Progressive: "Progressive",
    Sampling: "Sampling",
    Segment: "Segment",
    ChosenProduct: "ChosenProduct",
    Discount: "Discount",
};
