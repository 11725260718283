import moment from "moment";

export const dateToString = (date: Date | undefined) => {
    if (date === undefined) {
        return "";
    } else {
        const month = date.getMonth() + 1;
        const day = date.getDate();

        return `${date.getFullYear()}-${month.toString().length === 1 ? `0${month}` : month}-${day.toString().length === 1 ? `0${day}` : day}`;
    }
};

export const isDate = (value: unknown): value is Date => {
    return moment.isDate(value) && typeof value === "object" && value?.getFullYear() > 2000;
};
