import defaultListState from "store/defaultListState";
import { combineReducers } from "redux";
import types from "./types";

const initialState = {
    list: {
        ...defaultListState,
        filterValues: {
            activityReasons: [],
            balanceChangeReasons: [],
            dateFrom: null,
            dateTo: null,
            manualActivityCounter: null,
            hasCreditLimit: false,
            profileType: "none",
            search: "",
            searchingUsers: false,
        },
    },
    form: {
        isLoading: true,
        hasError: false,
        balanceDebt: undefined,
        activities: defaultListState,
    },
};

const list = (state = initialState.list, { type, payload, meta }) => {
    switch (type) {
        case types.findBalanceDebts.request:
            return { ...state, items: [], isLoading: true };
        case types.findBalanceDebts.success:
            if (state.userIdsOverride) {
                const { items } = payload;
                return { ...state, items, isLoading: false };
            } else {
                return { ...state, ...payload, isLoading: false };
            }
        case types.findBalanceDebts.failure:
            return { ...state, isLoading: false };
        case types.changePagination:
            return { ...state, ...payload };
        case types.muteBalanceDebt.success:
            return { ...state, items: state.items.filter((x) => x.id !== meta.customerId) };
        case types.clearBalanceDebts:
            return { ...state, ...defaultListState };
        case types.updateFilterValues:
            return { ...state, filterValues: payload };
        default:
            return state;
    }
};

const form = (state = initialState.form, { type, payload }) => {
    switch (type) {
        case types.getBalanceDebt.request:
            return { ...state, isLoading: true };
        case types.getBalanceDebt.success:
            return { balanceDebt: payload, isLoading: false, activities: state.activities };
        case types.getBalanceDebt.failure:
            return { balanceDebt: undefined, isLoading: false, hasError: true };
        case types.clearBalanceDebt:
            return { ...initialState.form };
        case types.findUserBalanceDebtActivities.request:
        case types.findUserBalanceDebtActivities.failure:
            return { activities: defaultListState, ...state };
        case types.findUserBalanceDebtActivities.success:
            return { ...state, activities: payload };
        default:
            return state;
    }
};

export const balanceDebts = combineReducers({
    list,
    form,
});
