import { FormField } from "components/Form";
import { useCommonTranslation } from "utils/hooks";
import { notEmptyValidator, stringLength } from "utils/validators/basic";
import { tValidation, validation } from "utils-ts/validations/translation";


const validators = {
    generatorId: (value) => {
        return notEmptyValidator(value)
            .must(stringLength(0, 100), tValidation(validation.stringLength, { min: 1, max: 100 }))
            .validate();
    },
};

const GeneratorActivation = () => {
    const { t, common } = useCommonTranslation();

    return (
        <>
            <FormField
                name="generatorId"
                label={t(common.generatorId)}
                type="text"
                validate={validators.generatorId}
            />
        </>
    );
};

export default GeneratorActivation;