import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { signinCallbackKey, signinRedirectKey, userManager } from "utils-ts/auth/userManager";
import { usePush } from "utils-ts/hooks";

export const CallbackPage = () => {
    const { error } = useAuth();
    const { replace } = usePush();

    useEffect(() => {
        if (localStorage.getItem(signinCallbackKey)) {
            return;
        }

        localStorage.setItem(signinCallbackKey, "true");

        userManager
            .signinRedirectCallback(window.location.href)
            .then(
                (user) => {
                    localStorage.removeItem(signinCallbackKey);
                    const prev = localStorage.getItem(signinRedirectKey);
                    localStorage.removeItem(signinRedirectKey);
                    if (prev) {
                        replace(prev);
                    } else {
                        replace("/");
                    }
                },
                (error) => {
                    localStorage.removeItem(signinCallbackKey);
                    console.error({ res: "reject", error });
                }
            )
            .catch((err) => {
                console.error(err);
            });
    }, []);

    if (error) {
        console.error(error);
    }

    return (
        <>
            <div>Ładowanie danych konta...</div>
            <div style={{ display: "none" }}>Callback</div>
        </>
    );
};
