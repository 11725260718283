import { combineReducers } from "redux";
import { types } from "./types";

const initialState = {
    list: {
        isLoading: false,
    },
    form: {},
};

const list = (state = initialState.list, { type, payload }) => {
    switch (type) {
        case types.list.request:
            return { ...state, isLoading: true };
        case types.list.success:
            return { items: payload, isLoading: false };
        case types.list.failure:
            return { ...payload, isLoading: false };
        default:
            return state;
    }
};

const form = (state = initialState.form, { type, payload }) => {
    switch (type) {
        case types.form.request:
            return { ...state, isLoading: true };
        case types.form.success:
            return { ...payload, isLoading: false };
        case types.form.failure:
            return { ...payload, isLoading: false };
        default:
            return state;
    }
};

export default combineReducers({
    list,
    form,
});
