import React from "react";
import { Grid } from "@mui/material";
import { ListItemText } from "@mui/material";
import AnalyticGroupsItem from "./AnalyticGroupsItem";
import ProductItem from "./ProductItem";

const DetailName = ({ d, products, analyticGroups }) => {
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-end"
        >
            {d.group === "p" && (
                <ProductItem
                    key={d.id}
                    v={d.id}
                    products={products}
                    readOnly={true}
                />
            )}
            {d.group === "ag" && (
                <AnalyticGroupsItem
                    key={d.id}
                    v={d.id}
                    analyticGroups={analyticGroups}
                    readOnly={true}
                />
            )}
            {d.group === "b" && <ListItemText primary={`${d.id}`} />}
        </Grid>
    );
};

export default DetailName;
