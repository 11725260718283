import { forwardRef } from "react";
import { TimePicker as MuiTimePicker } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";
import { TimePickerProps } from "control-types";
import { TextWithLabel } from "components-ts/text";
import FormHelperText from "./FormHelperText";

const TimePicker: React.FC<TimePickerProps> = forwardRef(({ value, label, onChange, error, includeSeconds, readOnly }, ref) => {
    const format = includeSeconds ? "HH:mm:ss" : "HH:mm:00";
    if (readOnly) {
        return (
            <TextWithLabel
                label={label}
                value={value}
                error={error}
            />
        );
    }

    return (
        <>
            <MuiTimePicker<Moment>
                label={label}
                value={value ? moment(value, format) : null}
                onChange={(newValue) => {
                    onChange(newValue ? newValue.format(format) : undefined);
                }}
                slotProps={{
                    textField: {
                        error: error?.hasError,
                        variant: "standard",
                        fullWidth: true,
                        InputLabelProps: { shrink: true },
                        inputRef: ref,
                    },
                }}
            />
            <FormHelperText error={error} />
        </>
    );
});

export default TimePicker;
