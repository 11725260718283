import { RSAA } from "redux-api-middleware";
import { commerce } from "store/actionTypePrefixes";
import { combineIntoQueryParams } from "utils/objectExtensions";

export const types = {
    GET_WATER_LIMITS: `${commerce}/GET_WATER_LIMITS`,
    GET_WATER_LIMITS_SUCCESS: `${commerce}/GET_WATER_LIMITS_SUCCESS`,
    GET_WATER_LIMITS_FAILURE: `${commerce}/GET_WATER_LIMITS_FAILURE`,
    REMOVE_WATER_LIMIT: `${commerce}/REMOVE_WATER_LIMIT`,
    REMOVE_WATER_LIMIT_SUCCESS: `${commerce}/REMOVE_WATER_LIMIT_SUCCESS`,
    REMOVE_WATER_LIMIT_FAILURE: `${commerce}/REMOVE_WATER_LIMIT_FAILURE`,
};

const getWaterLimits =
    ({ isActive, ...rest }) =>
    async (dispatch) => {
        let query = { ...rest };
        if (isActive !== "") query.isActive = isActive;
        const queryParams = combineIntoQueryParams({ ...query });
        return dispatch({
            [RSAA]: {
                endpoint: `api/v1/pricing/water-limits?${queryParams}`,
                headers: { "Content-Type": "application/json" },
                method: "GET",
                types: [types.GET_WATER_LIMITS, types.GET_WATER_LIMITS_SUCCESS, types.GET_WATER_LIMITS_FAILURE],
            },
        });
    };

const remove = (id) => async (dispatch) => {
    return dispatch({
        [RSAA]: {
            endpoint: `api/v1/pricing/water-limits/${id}`,
            headers: { "Content-Type": "application/json" },
            method: "DELETE",
            types: [types.REMOVE_WATER_LIMIT, types.REMOVE_WATER_LIMIT_SUCCESS, types.REMOVE_WATER_LIMIT_FAILURE],
        },
    });
};

export const actions = {
    getWaterLimits,
    remove,
};
