import { ArrayValidationError } from "components/Controls";
import { FormField } from "components/Form";
import { Adable, Erasable } from "components/FormHelpers";
import { FormSection } from "redux-form";
import { toSelectList } from "utils/extensions";
import { withFormName } from "utils/hoc";
import { useChange, useCommonTranslation, useFormValueSelector } from "utils/hooks";
import ExpiringSegment from "./ExpiringSegment";
import NewProduct from "./NewProduct";
import SampleProduct from "./SampleProduct";

const EffectSwitch = ({ effect, readOnly = false }) => {
    switch (effect.effectType) {
        case "newProduct":
            return (
                <NewProduct
                    newProduct={effect.newProduct}
                    readOnly={readOnly}
                />
            );
        case "sampleProduct":
            return (
                <SampleProduct
                    sampleProduct={effect.sampleProduct}
                    readOnly={readOnly}
                />
            );
        case "expiringSegment":
            return (
                <ExpiringSegment
                    expiringSegmentx={effect.expiringSegment}
                    readOnly={readOnly}
                />
            );
        default:
            throw Error("Missing gift effect type");
    }
};

const GiftEffects = ({ fields, form, sectionPrefix, meta, readOnly = false }) => {
    const { t, common } = useCommonTranslation();
    const effects = useFormValueSelector(form, `${sectionPrefix}.effects`, []);
    const change = useChange(form);

    return (
        <Adable
            handleAdd={() => fields.push({ effectType: "newProduct" })}
            readOnly={readOnly}
        >
            <ArrayValidationError {...meta} />
            {fields.map((field, index) => {
                const effect = effects[index];
                return (
                    <Erasable
                        key={field}
                        handleRemove={() => fields.remove(index)}
                        readOnly={readOnly}
                    >
                        <FormSection name={field}>
                            <FormField
                                hideDefaultItem
                                name="effectType"
                                type={"select"}
                                label={t(common.userActivationLimitExpirationInterval)}
                                onChange={async () => {
                                    await change(`${sectionPrefix}.${field}`, null);
                                }}
                                items={toSelectList(["newProduct", "sampleProduct", "expiringSegment"], t)}
                                readOnly={readOnly}
                            />
                            <EffectSwitch
                                effect={effect}
                                readOnly={readOnly}
                            />
                        </FormSection>
                    </Erasable>
                );
            })}
        </Adable>
    );
};

export default withFormName(GiftEffects);
