import { RSAA } from "redux-api-middleware";
import { asAsyncFormTypes, asCleanFormDataTypes } from "utils/extensions/arrayExtensions";
import types from "./types";

const { saveGiftCoice: saveGiftCoiceTypes, getGiftChoice, getGiftChoiceActivation } = types;

const endpoint = (giftId, id) => `api/v1/offer/products/gifts/${giftId}/choices${id ? `/${id}` : ""}`;

export const initGiftForm = (giftId, id, isCopy) => async (dispatch) => {
    await dispatch({ type: types.initForm });
    if (id) {
        await dispatch({
            [RSAA]: {
                endpoint: endpoint(giftId, id),
                headers: { "Content-Type": "application/json" },
                method: "GET",
                types: getGiftChoice.all.map((x) => ({
                    type: x,
                    meta: { isCopy },
                })),
            },
        });
    }

    if (id && !isCopy) {
        dispatch({
            [RSAA]: {
                endpoint: endpoint(giftId, id) + "/reservation-count",
                headers: { "Content-Type": "application/json" },
                method: "GET",
                types: [
                    getGiftChoiceActivation.request,
                    getGiftChoiceActivation.success,
                    {
                        type: getGiftChoiceActivation.failure,
                        meta: { hideError: true },
                    },
                ],
            },
        });
    }
};

export const getGiftChoiceValidation = (giftId, id) => (dispatch) => {
    dispatch({
        [RSAA]: {
            endpoint: `${endpoint(giftId, id)}/validation`,
            headers: { "Content-Type": "application/json" },
            method: "GET",
            types: [
                types.getGiftChoiceValidation.request,
                types.getGiftChoiceValidation.success,
                {
                    type: types.getGiftChoiceValidation.failure,
                    meta: { hideError: true },
                },
            ],
        },
    });
};

export const saveGiftChoice = (values, giftId, id) => async (dispatch) => {
    return await dispatch({
        [RSAA]: {
            endpoint: endpoint(giftId, id),
            headers: { "Content-Type": "application/json" },
            method: id ? "PUT" : "POST",
            types: asCleanFormDataTypes(asAsyncFormTypes(saveGiftCoiceTypes.all)),
            body: JSON.stringify(values),
        },
    });
};
