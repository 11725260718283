import { useModalState } from "components/Dialog";
import Actions, { createAction } from "components/Tabs/Actions";
import roles, { AccountantDepartmentRoles } from "consts/roles";
import { actions } from "store/customers/customer/customerProfileActions/action";
import { withAuthorizedDisplay } from "utils/hoc";
import { usePush } from "utils/hooks";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { common, customers } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { baseWithEcommerce } from "routing-ts/Routes/CustomerRoles";
import DeleteCustomerDialog from "./DeleteCustomerModal";
import ExportToAXModal from "./ExportToAXModal";

const CustomerActions = ({ customerId, email, isVerificationRequired }) => {
    const { isExecuting } = useSelector((state) => state?.customer?.customerProfileActions) || {};
    const push = usePush();
    const dispatch = useDispatch();
    const { t } = useTranslation(["customers", "common"]);
    const [open, setOpen] = useState(false);
    const { modalState: AXExportOpen, handleOpen: handleOpenAXExport, handleClose: handleCloseAXExport } = useModalState(false);

    const actionsComponents = useMemo(() => {
        return [
            createAction(
                t(customers.AXExport),
                () => {
                    handleOpenAXExport(true);
                },
                "primary",
                isExecuting,
                [...AccountantDepartmentRoles, roles.Management]
            ),
            createAction(
                t(customers.deleteCustomer),
                () => {
                    setOpen(true);
                },
                "danger",
                isExecuting,
                baseWithEcommerce
            ),
            createAction(
                t(`common:${common.edit}`),
                () => {
                    push(`/customers/${customerId}/edit`);
                },
                "primary",
                isExecuting,
                baseWithEcommerce
            ),
            createAction(
                t(customers.loginAsCustomer),
                () => {
                    dispatch(actions.loginAsCustomer(customerId, email));
                },
                "primary",
                isExecuting,
                baseWithEcommerce
            ),
            isVerificationRequired
                ? createAction(
                      t(customers.removeEmailVerificationRequirment),
                      () => {
                          dispatch(actions.removeEmailVerificationRequirement(customerId));
                      },
                      "warning",
                      isExecuting,
                      baseWithEcommerce
                  )
                : undefined,
        ].filter(Boolean);
    }, [customerId, dispatch, email, handleOpenAXExport, isExecuting, isVerificationRequired, push, t]);

    return (
        <>
            <Actions actions={actionsComponents} />
            <DeleteCustomerDialog
                open={open}
                handleAccept={async () => {
                    await dispatch(actions.deleteCustomer(customerId));
                    setOpen(false);
                    push("/customers");
                }}
                handleReject={() => setOpen(false)}
            />
            <ExportToAXModal
                customerId={customerId}
                open={AXExportOpen}
                handleAccept={handleCloseAXExport}
                handleReject={handleCloseAXExport}
            />
        </>
    );
};

export default withAuthorizedDisplay(CustomerActions);
