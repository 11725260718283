import types from "./types";

const initialState = {
    isLoading: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case types.getProductPreview.request:
            return { ...state, isLoading: true };
        case types.getProductPreview.success:
        case types.getProductPriceHistory.success:
            return { ...state, ...payload, isLoading: false };
        case types.getProductPreview.failure:
            return { ...state, isLoading: false };

        case types.getProductInternalData.success:
            return { ...state, ...payload };

        case types.productPreviewCleanup:
            return initialState;

        default:
            return state;
    }
};
