import { combineReducers } from "redux";
import externalProduct from "./externalProduct/reducer.js";
import productScan from "./productScan/reducer.js";
import productScanningStatus from "./productScanningStatus/reducer.js";
import shopAuthorization from "./shopAuthorization/reducer.js";

export const crawler = combineReducers({
    productScan,
    externalProduct,
    productScanningStatus,
    shopAuthorization,
});
