import { renderSwitch } from "components/FormHelpers/Controls";
import { Field } from "redux-form";
import React from "react";
import { Grid } from "@mui/material";
import { useUser } from "../../../../../context";
import { withAuthorizedDisplay } from "../../../../../utils/hoc";

const Segment = ({ handleChange, name, label, ...props }) => {
    const { isInRoleOrAdmin } = useUser();
    const canChange = props.roles.some((r) => isInRoleOrAdmin(r));

    return (
        <Grid
            item
            key={name}
        >
            <Field
                name={name}
                label={label}
                component={renderSwitch}
                readOnly={!canChange || props.readOnly}
                onChange={(_, checked) => {
                    if (canChange) {
                        handleChange(name, checked);
                    }
                }}
            />
        </Grid>
    );
};

export default withAuthorizedDisplay(Segment);
