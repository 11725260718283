import _ from "lodash";

const toQueryParam = (key, value) => `${key}=${encodeURIComponent(value)}`;

const toArrayQueryParam = (key, value, index) => `${key}[${index}]=${encodeURIComponent(value)}`;

export const combineIntoQueryParams = (params, useIndexInArray = false) => {
    const queryParmas = Object.keys(params)
        .filter((key) => {
            const value = params[key];
            return !_.isEmpty(value) || _.isNumber(value) || _.isDate(value) || _.isBoolean(value);
        })
        .map((key) =>
            _.isArray(params[key])
                ? params[key].map((value, index) => (useIndexInArray ? toArrayQueryParam(key, value, index) : toQueryParam(key, value))).join("&")
                : toQueryParam(key, params[key])
        );

    return queryParmas.map((x) => x).join("&");
};
