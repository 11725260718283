import defaultListState from "store/defaultListState";
import moment from "moment";
import { combineReducers } from "redux";
import types from "./types";

const initialState = {};
const { createOrUpdate, formInit, getRefundation, importRefundation, findRefundationForApproval } = types;

const listInitialState = defaultListState;

const list = (state = listInitialState, { type, payload }) => {
    switch (type) {
        case types.getRefundations.request:
            return {
                ...listInitialState,
                isloading: true,
            };
        case types.getRefundations.success:
            return {
                ...state,
                ...payload,
                isloading: true,
            };
        case types.getRefundations.failure:
            return {
                ...state,
                isloading: false,
            };
        default:
            return { ...state };
    }
};

const form = (state = initialState, { type, payload }) => {
    switch (type) {
        case formInit:
            return { ...initialState };
        case getRefundation.request:
        case findRefundationForApproval.request:
            return { ...initialState, isLoading: true };
        case findRefundationForApproval.success:
        case getRefundation.success:
            return { ...state, ...payload, isLoading: false };
        case findRefundationForApproval.failure:
        case getRefundation.failure:
            return { ...payload, isLoading: false };
        case createOrUpdate.request:
            return { ...state, isSubbmitRequested: true };
        case createOrUpdate.success:
        case createOrUpdate.failure:
            return { ...state, isSubbmitRequested: false };
        case importRefundation.request:
            return { ...state, isImporting: true };
        case importRefundation.success:
            let from = undefined,
                to = undefined;
            const { dateFrom, dateTo, ...rest } = payload.refundation;
            if (moment(dateFrom).isValid() && moment(dateFrom).year() >= 2020) {
                from = moment(dateFrom).format("YYYY-MM-DD");
            }

            if (moment(dateTo).isValid() && moment(dateTo).year() >= 2020) {
                to = moment(dateTo).format("YYYY-MM-DD");
            }
            return { ...state, dateFrom: from, dateTo: to, ...rest, isImporting: false };
        case importRefundation.failure:
            return { ...state, isImporting: false };
        default:
            return state;
    }
};

export const refundation = combineReducers({
    form,
    list,
});
