import Button from "components/Button/Button";
import { renderFieldOrText } from "components/FormHelpers/Controls";
import { Field, FormSection } from "redux-form";
import { useCommonTranslation } from "utils/hooks";
import { notEmptyValidator } from "utils/validators/basic";
import React, { Fragment } from "react";
import { Delete } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import ArrayValidationError from "./ArrayValidationError";

const required = (value) => {
    return notEmptyValidator(value).validate();
};

const Row = React.memo(
    ({ field, fields, index, readOnly = false }) => {
        const { t, common } = useCommonTranslation();
        return (
            <FormSection name={field}>
                <Grid
                    style={{ marginTop: 10 }}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-end"
                >
                    <Grid
                        item
                        xs={1}
                        style={{ marginRight: 10 }}
                    >
                        <Field
                            name={"key"}
                            fullWidth
                            component={renderFieldOrText}
                            type={"text"}
                            label={t(common.key)}
                            validate={required}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={9}
                    >
                        <Field
                            multiline
                            fullWidth
                            name={"value"}
                            component={renderFieldOrText}
                            type={"textWithResroucePreview"}
                            label={t(common.value)}
                            readOnly={readOnly}
                        />
                    </Grid>
                    {!readOnly && (
                        <Grid
                            item
                            style={{ marginRight: "auto" }}
                        >
                            <IconButton onClick={() => fields.remove(index)}>
                                <Delete />
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
            </FormSection>
        );
    },
    (prev, next) => {
        return prev.index === next.index && prev.field === next.field;
    }
);

const DictionaryInput = ({ fields, meta, readOnly = false }) => {
    const { t, common } = useCommonTranslation();
    return (
        <Fragment>
            <ArrayValidationError {...meta} />
            {fields.map((field, index) => {
                return (
                    <Row
                        key={field}
                        field={field}
                        fields={fields}
                        index={index}
                        readOnly={readOnly}
                    />
                );
            })}
            <Grid
                style={{ marginTop: 10 }}
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
            >
                {!readOnly && (
                    <Button
                        style={{ marginLeft: "auto" }}
                        color="primary"
                        variant="contained"
                        onClick={() => fields.push({ name: "", key: "" })}
                    >
                        {t(common.add)}
                    </Button>
                )}
            </Grid>
        </Fragment>
    );
};

export default React.memo(DictionaryInput);
