import cardBodyStyle from "assets/jss/material-dashboard-react/components/cardBodyStyle";
import { useStyles, withStyles } from "tss-react/mui";
import PropTypes from "prop-types";

function CardBody({ ...props }) {
    const { className, children, plain, profile, classes: propsClasses, ...rest } = props;
    const classes = withStyles.getClasses(props);
    const { cx } = useStyles();

    const cardBodyClasses = cx({
        [classes.cardBody]: true,
        [classes.cardBodyPlain]: plain,
        [classes.cardBodyProfile]: profile,
        [className]: className !== undefined,
    });

    return (
        <div
            className={cardBodyClasses}
            {...rest}
        >
            {children}
        </div>
    );
}

CardBody.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    plain: PropTypes.bool,
    profile: PropTypes.bool,
};

export default withStyles(CardBody, cardBodyStyle);
