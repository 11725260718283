import tableStyle from "assets/jss/material-dashboard-react/components/tableStyle";
import tasksStyle from "assets/jss/material-dashboard-react/components/tasksStyle";
import { Button } from "components/Button/index";
import { withStyles } from "tss-react/mui";
import { blue, green } from "@mui/material/colors";

const styles = {
    ...tableStyle,
    ...tasksStyle,
    icon: {
        "&:hover": {
            color: blue,
        },
    },
    iconActive: {
        color: green,
    },
};

const actionButton = ({ handleClick, classes, buttonText, disabled, isLoading }) => {
    return (
        <Button
            size="small"
            loading={isLoading}
            color="primary"
            className={classes.icon}
            onClick={handleClick}
            disabled={disabled}
        >
            {buttonText}
        </Button>
    );
};

export default withStyles(actionButton, styles);
