import { types } from "store/cache/actions";
import { cacheNames } from "utils/cache/cacheProvider";

const initialState = [];

export const commerceUsers = (state = initialState, { type, payload }) => {
    switch (type) {
        case types(cacheNames.commerceUserStore).UPDATE_CACHE_STORE:
            const updated = state.filter((x) => payload.every((y) => y.id !== x.id));
            return [...updated, ...payload.map((x) => ({ userId: x.id, user: x }))];
        default:
            return state;
    }
};
