import { makeStyles } from "tss-react/mui";
import formatValue from "utils/formating/formatValue";
import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import classnames from "classnames";

const useStyles = makeStyles()((theme) => ({
    marginBottom: {
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2),
    },
    label: {
        color: theme.palette.primary.dark,
    },
    unselectableLabel: {
        WebkitTouchCallout: "none",
        WebkitUserSelect: "none",
        KhtmlUserSelect: "none",
        MozUserSelect: "none",
        msUserSelect: "none",
        userSelect: "none",
    },
}));

const TextWithLabel = (props) => {
    const { className, label, translatedLabel, value, format = "text", options = {}, unselectableLabel, useFormat = true } = props;
    const { infoIfNoData } = options;
    const { t } = useTranslation();
    const { classes } = useStyles();

    let emptyValue = value;
    if (infoIfNoData) {
        emptyValue = t("noData");
    }

    return (
        <Grid
            item
            key={label}
            className={classnames(classes.marginBottom, className)}
        >
            <Typography
                component="div"
                className={classnames(classes.label, className?.label, unselectableLabel ? classes.unselectableLabel : "")}
                variant="caption"
            >
                {translatedLabel ? translatedLabel : t(label)}
            </Typography>
            <Typography
                component="div"
                display="block"
            >
                {value ? (useFormat ? formatValue(value, format, options) : value) : emptyValue}
            </Typography>
        </Grid>
    );
};

export default React.memo(TextWithLabel);
