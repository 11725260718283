import { MenuButton } from "components/Button";
import { invoice } from "utils/dictionaryTypes/documentType";
import { merchants } from "utils/dictionaryTypes/index";
import { withAuthorizedDisplay } from "utils/hoc";
import { useCommonTranslation } from "utils/hooks";
import React from "react";
import { FileCopy } from "@mui/icons-material";
import { Grid, ListItemIcon, MenuItem } from "@mui/material";

const getInvoice = (customerId, orderNumber, merchant, document, getOrderDocument) => {
    const documentNumber = document && document.documentNumber;
    const documentId = document && document.documentId;

    return () => {
        getOrderDocument(customerId, orderNumber, documentId, documentNumber, merchant);
    };
};

const InvoiceMenu = ({ customerId, orderNumber, getOrderDocument, orderDocuments = [] }) => {
    const { t, common } = useCommonTranslation();
    const invoiceFrisco = orderDocuments.find((d) => d.documentType === invoice && d.merchant === merchants.Frisco && d.blobName);
    const invoiceF24 = orderDocuments.find((d) => d.documentType === invoice && d.merchant === merchants.F24 && d.blobName);
    return (
        <MenuButton
            title={t(common.invoices)}
            disabled={!(Boolean(invoiceFrisco) && !Boolean(invoiceF24))}
        >
            <MenuItem
                onClick={getInvoice(customerId, orderNumber, merchants.Frisco, invoiceFrisco, getOrderDocument)}
                disabled={!Boolean(invoiceFrisco)}
            >
                <ListItemIcon>
                    <FileCopy />
                </ListItemIcon>
                {t(common.invoice, { merchant: "Frisco" })}
            </MenuItem>
            <MenuItem
                onClick={getInvoice(customerId, orderNumber, merchants.F24, invoiceF24, getOrderDocument)}
                disabled={!Boolean(invoiceF24)}
            >
                <ListItemIcon>
                    <FileCopy />
                </ListItemIcon>
                {t(common.invoice, { merchant: "F24" })}
            </MenuItem>
        </MenuButton>
    );
};

const OperationsOrderTabActions = ({ orderDocuments = {}, ...props }) => {
    return (
        <Grid
            container
            direction="row"
            justifyContent={"flex-end"}
            alignItems="center"
        >
            <Grid item>
                <InvoiceMenu
                    orderDocuments={orderDocuments}
                    {...props}
                />
            </Grid>
        </Grid>
    );
};

export default withAuthorizedDisplay(OperationsOrderTabActions);
