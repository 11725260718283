import { generatePath } from "react-router-dom";
import { ProceduresListResponse, ProceduresPageRequest } from "Operations";
import { useDeleteMutation, useGetQuery } from "api/hooks";

const url = (id?: string) => generatePath(`api/procedure/:id?`, { id: id ? id : null });

export const useFindProcedures = (initParams?: ProceduresPageRequest) => {
    return useGetQuery<ProceduresListResponse, ProceduresPageRequest>({ app: "operations", url: url(), queryParams: initParams });
};

export const useDeleteProcedure = (id?: string) => {
    return useDeleteMutation({
        app: "operations",
        url: url(id),
    });
};
