import { RSAA } from "redux-api-middleware";
import { commerce } from "store/actionTypePrefixes";
import { asAsyncFormTypes } from "utils/extensions/arrayExtensions";
import { combineIntoQueryParams } from "utils/objectExtensions";

export const types = {
    GET_VOUCHERS_LIST: `${commerce}/GET_VOUCHERS_LIST`,
    GET_VOUCHERS_LIST_SUCCESS: `${commerce}/GET_VOUCHERS_LIST_SUCCESS`,
    GET_VOUCHERS_LIST_FAILURE: `${commerce}/GET_VOUCHERS_LIST_FAILURE`,
    FIND_VOUCHER: `${commerce}/FIND_VOUCHER`,
    FIND_VOUCHER_SUCCESS: `${commerce}/FIND_VOUCHER_SUCCESS`,
    FIND_VOUCHER_FAILURE: `${commerce}/FIND_VOUCHER_FAILURE`,
    REMOVE_VOUCHER: `${commerce}/REMOVE_VOUCHER`,
    REMOVE_VOUCHER_SUCCESS: `${commerce}/REMOVE_VOUCHER_SUCCESS`,
    REMOVE_VOUCHER_FAILURE: `${commerce}/REMOVE_VOUCHER_FAILURE`,
};

const getList =
    ({ type, isActive, ...rest }) =>
    async (dispatch) => {
        let query = { ...rest };
        if (isActive !== "") query.isActive = isActive;
        const queryParams = combineIntoQueryParams({ ...query });
        return dispatch({
            [RSAA]: {
                endpoint: `api/v1/vouchers/${type}?${queryParams}`,
                headers: { "Content-Type": "application/json" },
                method: "GET",
                types: [types.GET_VOUCHERS_LIST, types.GET_VOUCHERS_LIST_SUCCESS, types.GET_VOUCHERS_LIST_FAILURE],
            },
        });
    };

const searchForVoucher = (value) => async (dispatch) => {
    return await dispatch({
        [RSAA]: {
            endpoint: `api/v1/vouchers/vouchers/${value}`,
            headers: { "Content-Type": "application/json" },
            method: "GET",
            types: [types.FIND_VOUCHER, types.FIND_VOUCHER_SUCCESS, { type: types.FIND_VOUCHER_FAILURE, meta: { hideError: true } }],
        },
    });
};

const remove = (id, type) => async (dispatch) => {
    return dispatch({
        [RSAA]: {
            endpoint: `api/v1/vouchers/${type}/${id}`,
            headers: { "Content-Type": "application/json" },
            method: "DELETE",
            types: asAsyncFormTypes([types.REMOVE_VOUCHER, types.REMOVE_VOUCHER_SUCCESS, types.REMOVE_VOUCHER_FAILURE]),
        },
    });
};

export const actions = {
    getList,
    searchForVoucher,
    remove,
};
