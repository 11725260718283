import { ProductInit, ProductInitSearchParameters } from "Operations-ProductInit";
import { generatePath } from "react-router-dom";
import { PagedResult } from "Shared";
import { useUser } from "utils-ts/hooks";
import { useDeleteMutation, useFileQuery, useGetCacheQuery, useGetPagedQuery } from "api/hooks";

const url = (role: "Sales" | "Auditor" | "Vendor", id?: string) =>
    generatePath(`api/${role === "Auditor" ? "auditors/init-products" : "products/init"}/:id?`, { id: id ? id : null });

export const useGetEans = () => {
    const { isInAnyRole } = useUser();
    const isAuditor = isInAnyRole(["Auditor", "MasterData"]);

    return useGetCacheQuery<Array<string>>({
        app: "operations",
        url: url(isAuditor ? "Auditor" : "Sales", "eans"),
    });
};

export const useGetProductsIds = () => {
    const { isInAnyRole } = useUser();
    const isAuditor = isInAnyRole(["Auditor", "MasterData"]);

    return useGetCacheQuery<Array<string>>({
        app: "operations",
        url: url(isAuditor ? "Auditor" : "Sales", "product-ids"),
    });
};

export const useGetInitProducts = (params: ProductInitSearchParameters) => {
    const { isInAnyRole } = useUser();
    const isAuditor = isInAnyRole(["Auditor", "MasterData"]);

    return useGetPagedQuery<PagedResult<ProductInit>, ProductInitSearchParameters>({
        app: "operations",
        url: url(isAuditor ? "Auditor" : "Sales"),
        queryParams: params,
    });
};

export const useDeleteInitProduct = (id?: string) => {
    return useDeleteMutation({
        app: "operations",
        url: url("Sales", id) + "/delete",
    });
};

export const useGetInitBrandsFile = (shouldMakeRequest: boolean, params?: ProductInitSearchParameters & { ids: string[]; allSelected: boolean }) => {
    return useFileQuery({
        app: "operations",
        url: url("Sales", "brands-file"),
        fileName: "1. Importuj kategorie",
        shouldMakeRequest,
        queryParams: params,
    });
};

export const useGetInitProductsFile = (
    shouldMakeRequest: boolean,
    params?: ProductInitSearchParameters & { ids: string[]; allSelected: boolean },
    onlyManufacturedProducts?: boolean
) => {
    let fileName = "2.1 Importuj kartoteki towarowe";
    if (onlyManufacturedProducts) {
        fileName = "2.2 Importuj kartoteki towarowe produkowane";
    }

    return useFileQuery({
        app: "operations",
        url: url("Sales", "products-file"),
        fileName: fileName,
        shouldMakeRequest,
        queryParams: params,
    });
};

export const useGetInitMeasureFile = (shouldMakeRequest: boolean, params?: ProductInitSearchParameters & { ids: string[]; allSelected: boolean }) => {
    return useFileQuery({
        app: "operations",
        url: url("Sales", "measure-file"),
        fileName: "3. Importuj wymiary FriscoID",
        shouldMakeRequest,
        queryParams: params,
    });
};
