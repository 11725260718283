import { FormField } from "components/Form";
import { useCommonTranslation } from "utils/hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const AutocompleteField = ({
    dataAction,
    dataSelector,
    compareOptionId,
    selectorKey,
    getOptionSelected,
    isOptionEqualToValue,
    compareLabels,
    getOptionLabel,
    filterItems,
    noOptionsText,
    ...props
}) => {
    const { t, common } = useCommonTranslation();
    const dispatch = useDispatch();
    let items = useSelector(dataSelector);
    if (selectorKey) {
        if (Array.isArray(selectorKey)) {
            let temp = [];
            selectorKey.forEach((key) => {
                temp = temp.concat(items[key]);
            });

            items = temp;
        } else {
            items = items[selectorKey];
        }
    }
    useEffect(() => {
        if (!!dataAction) {
            dispatch(dataAction());
        }
    }, [dispatch, dataAction]);

    const comparer = getOptionSelected
        ? getOptionSelected
        : compareOptionId
        ? (option, value) => {
              return option.id === value.id;
          }
        : undefined;

    const optionLabel = getOptionLabel
        ? getOptionLabel
        : comparer
        ? (option) => {
              const campared = items?.filter((i) => comparer(option, i));
              return campared && Boolean(campared[0]) ? campared[0].name || "" : "";
          }
        : (option) => (typeof option == "string" ? option : option.name || "");

    const fieldItems = filterItems ? filterItems(items || []) : items || [];
    return (
        <FormField
            {...props}
            fullWidth
            type={"autocomplete"}
            items={fieldItems.map((item, index) => {
                if (typeof item === "string") {
                    return item;
                } else {
                    return {
                        key: index,
                        ...item,
                    };
                }
            })}
            getOptionLabel={optionLabel}
            noOptionsText={noOptionsText || t(common.noOptions)}
            getOptionTooltip={false}
            isOptionEqualToValue={isOptionEqualToValue || getOptionSelected}
        />
    );
};

export default AutocompleteField;
