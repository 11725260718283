import { RSAA } from "redux-api-middleware";
import { operations } from "store/actionTypePrefixes";
import { combineIntoQueryParams } from "utils/objectExtensions";

export const ORDERS_GET_LIST = `${operations}/ORDERS_GET_LIST`;
export const ORDERS_GET_LIST_SUCCESS = `${operations}/ORDERS_GET_LIST_SUCCESS`;
export const ORDERS_GET_LIST_FAILURE = `${operations}/ORDERS_GET_LIST_FAILURE`;

const getOrders = (pageIndex, pageSize, userId) => {
    const params = combineIntoQueryParams({ pageIndex, pageSize });
    return {
        [RSAA]: {
            endpoint: `api/users/${userId}/orders?${params}`,
            headers: { "Content-Type": "application/json" },
            method: "GET",
            types: [ORDERS_GET_LIST, ORDERS_GET_LIST_SUCCESS, ORDERS_GET_LIST_FAILURE],
        },
    };
};

export const actions = {
    getOrders,
};
