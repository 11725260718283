import { types } from "./actions";

const initialState = {};

export default (state = initialState, { type, payload, meta }) => {
    switch (type) {
        case types.INIT_FORM_CONTEST:
            return { ...initialState };
        case types.GET_CONTEST:
            return { ...state, isLoading: true };
        case types.GET_CONTEST_SUCCESS:
            return {
                ...state,
                ...payload,
                id: meta.isCopy ? "" : payload.id,
                isLoading: false,
            };
        case types.GET_CONTEST_FAILURE:
            return { ...payload, isLoading: false };
        default:
            return state;
    }
};
