const RSAA = "@@redux-api-middleware/RSAA";

export const replaceEmptyArrayWithNull = (obj) => {
    for (var x in obj) {
        if (obj[x] === null || obj[x] === undefined) {
            continue;
        }

        if (x === "pools") {
            continue;
        }

        if (Array.isArray(obj[x]) && obj[x].length === 0) {
            obj[x] = null;
        } else if ((Array.isArray(obj[x]) && obj[x].length > 0) || obj[x] instanceof Object) {
            obj[x] = replaceEmptyArrayWithNull(obj[x]);
        }

        if (obj[x] instanceof Object && Object.keys(obj[x]).length === 0) {
            obj[x] = null;
        }
    }

    return obj;
};

export const removeEmptyObjects = (obj) => {
    let isEmpty = true;
    for (var x in obj) {
        if (
            obj[x] === null ||
            obj[x] === undefined ||
            obj[x] === "" ||
            (obj[x] instanceof Object && Object.keys(obj[x]).length === 0) ||
            (Array.isArray(obj[x]) && obj[x].length === 0)
        ) {
            if (x === "pools") {
                isEmpty = false;
            } else if (obj[x] === "") {
                delete obj[x];
                isEmpty = isEmpty && true;
            } else {
                isEmpty = isEmpty && true;
            }
        } else if ((Array.isArray(obj[x]) && obj[x].length > 0) || obj[x] instanceof Object) {
            obj[x] = removeEmptyObjects(obj[x]);
            if (obj[x] == null) {
                isEmpty = isEmpty && true;
            } else {
                isEmpty = false;
            }
        } else {
            isEmpty = false;
        }
    }

    if (isEmpty) {
        return null;
    } else {
        return obj;
    }
};

export const cleaniseFormDataMiddleware = () => (next) => (action) => {
    if (action[RSAA] && action[RSAA].types[0] && (action[RSAA].method === "PUT" || action[RSAA].method === "POST") && action[RSAA].types[0].meta) {
        const clearEmptyFields = action[RSAA].types[0].meta.clearEmptyFields;
        if (clearEmptyFields && action[RSAA].body && action[RSAA].body.length > 0) {
            var body = JSON.parse(action[RSAA].body);
            body = replaceEmptyArrayWithNull(body);
            body = removeEmptyObjects(body);
            action[RSAA].body = JSON.stringify(body);
        }
    }

    return next(action);
};
