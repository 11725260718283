import { renderFieldOrText } from "components/FormHelpers/Controls";
import GridContainer from "components/Grid/GridContainer";
import { Field } from "redux-form";
import { withStyles } from "tss-react/mui";
import { useCommonTranslation } from "utils/hooks";
import { Grid } from "@mui/material";
import { styles as form } from "./styles";

const styles = (theme) => form(theme);

const PasswordForm = ({ classes }) => {
    const { t, common } = useCommonTranslation();
    const fields = [
        { label: t(common.password), name: "password", type: "text" },
        {
            label: t(common.passwordRepetition),
            name: "passwordRepetition",
            type: "text",
        },
    ];

    return (
        <GridContainer
            iconName="lock"
            title={common.password}
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
        >
            {fields.map(({ type, label, name }) => {
                return (
                    <Grid
                        key={name}
                        item
                        className={classes.gridField}
                    >
                        <Field
                            fullWidth
                            type={type}
                            label={label}
                            name={name}
                            component={renderFieldOrText}
                        />
                    </Grid>
                );
            })}
        </GridContainer>
    );
};

export default withStyles(PasswordForm, styles);
