import { RSAA } from "redux-api-middleware";
import { manager, operations } from "store/actionTypePrefixes";
import { combineIntoQueryParams } from "utils/objectExtensions";

export const types = {
    GET_ORDER_FOR_COMPLAINT: `${operations}/GET_ORDER_FOR_COMPLAINT`,
    GET_ORDER_FOR_COMPLAINT_SUCCESS: `${operations}/GET_ORDER_FOR_COMPLAINT_SUCCESS`,
    GET_ORDER_FOR_COMPLAINT_FAILURE: `${operations}/GET_ORDER_FOR_COMPLAINT_FAILURE`,
    CLEAN_UP: `${manager}/CLEAN_UP_COMPLAINT_VIEW`,
};

const getOrderForComplaint =
    ({ customerId, orderNumber, complaintId, documentId }) =>
    (dispatch) => {
        const params = combineIntoQueryParams({ complaintId });
        return dispatch({
            [RSAA]: {
                endpoint: `api/users/${customerId}/orders/${orderNumber}/get-order-for-complaint/${documentId}?${params}`,
                headers: { "Content-Type": "application/json" },
                method: "GET",
                types: [types.GET_ORDER_FOR_COMPLAINT, types.GET_ORDER_FOR_COMPLAINT_SUCCESS, types.GET_ORDER_FOR_COMPLAINT_FAILURE],
            },
        });
    };

const cleanUp = () => (dispatch) => {
    dispatch({ type: types.CLEAN_UP });
};

export const actions = {
    getOrderForComplaint,
    cleanUp,
};
