import { default as PaymentTransfersPage } from "views/paymentTransfers/PaymentTansfers";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import { AccountantDepartment, B2BAndSupportDepartment } from "routing-ts/roles";

export const PaymentTransfers: PrivateRoute[] = [
    new PrivateRoute(
        Paths.PaymentTransfers,
        ["Management", "Analyst", ...AccountantDepartment.GetRoles(), ...B2BAndSupportDepartment],
        PaymentTransfersPage,
        "paymentTransfers",
        "payment"
    ),
];
