import { GetRequest, get } from "utils/extensions";
import { downloadPdfFile, downloadXlsxFile } from "utils/rsaa/downloadFileAction";
import types from "./types";

const endpoint = (vendorId, correctionRequestId) =>
    `api/vendors/${vendorId}/correction-request${correctionRequestId ? `/${correctionRequestId}` : ""}`;

export const getCorrectionRequests = (vendorId, params) => async (dispatch) => {
    return dispatch(
        get(
            new GetRequest(endpoint(vendorId), types.getCorrectionRequests.all, {
                queryParams: params,
            })
        )
    );
};

export const getCorrectionRequest = (vendorId, correctionRequestId, params) => async (dispatch) => {
    return dispatch(get(new GetRequest(endpoint(vendorId, correctionRequestId), types.getCorrectionRequest.all, { queryParams: params })));
};

export const getCorrectionRequestFile = (vendorId, correctionRequestId) => async (dispatch) => {
    await dispatch(downloadPdfFile(endpoint(vendorId, correctionRequestId) + `/file`, types.downloadFile.all, `${vendorId}_żądanie`));

    return Promise.resolve();
};

export const getCorrectionRequestReportFile = (vendorId, correctionRequestId, filename) => async (dispatch) => {
    await dispatch(
        downloadXlsxFile(endpoint(vendorId, correctionRequestId) + `/report-file`, types.downloadFile.all, `${filename}_${vendorId}_raport`)
    );

    return Promise.resolve();
};
