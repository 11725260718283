import { orders } from "translations";
import { types } from "./action";

const initialState = {
    productLines: [],
    user: {},
    shippingAddress: {},
    payment: {},
    billingAddress: {},
    isLoading: false,
    documents: [],
    fileRequestInProgress: false,
};

export const order = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case types.ORDERS_GET_ORDER:
            return { ...state, isLoading: true };
        case types.ORDER_GET_COMPLETE_ORDER:
            return {
                ...state,
                ...payload,
                billingAddress: payload.billingAddress || {},
                isLoading: false,
            };
        case types.ORDERS_GET_ORDER_FAILURE:
            return { ...state, isLoading: false };
        case types.ORDER_GET_DOCUMENT:
            return { ...state, fileRequestInProgress: true };
        case types.ORDER_GET_DOCUMENT_SUCCESS:
        case types.ORDER_GET_DOCUMENT_FAILURE:
            return { ...state, fileRequestInProgress: false };
        case types.ORDER_CANCEL_SUCCESS:
            return { ...state, status: orders.PendingCancellation };
        case types.ORDER_ABORT_SUCCESS:
            return { ...state, status: orders.PendingAbort };
        default:
            return state;
    }
};
