import { reducer as formReducer } from "redux-form";
import { accountingPeriods } from "./accountingPeriods";
import { alerts } from "./alerts/alerts/reducer.js";
import { analyticGroups } from "./analyticGroups/reducer.js";
import { autocomplete } from "./autocomplete/";
import { balanceDebts } from "./balanceDebts";
import { brands } from "./brands/reducer.js";
import { bridge } from "./bridge/reducer";
import { cache } from "./cache/reducer.js";
import { calendar } from "./calendar/reducer.js";
import { cms } from "./cms/reducer.js";
import { messageBox as messageBoxReducer } from "./common/messageBox/reducer.js";
import { crawler } from "./crawler/reducer.js";
import { customerDepositsImport } from "./customerDeposits/import/reducer.js";
import { customerMembership } from "./customers/customer/customerMembership/reducer.js";
import { deferredPayments } from "./customers/customer/deferredPayments/reducer.js";
import { customer } from "./customers/customer/reducer.js";
import { customerList } from "./customers/customerList/reducer.js";
import { dashboard } from "./dashboard/reducer.js";
import { deliveryComplaints } from "./deliveryComplaints/reducer.js";
import { deliveryCosts } from "./deliveryCosts/reducer.js";
import { dictionaries } from "./dictonaries/reducer.js";
import { documentArchive } from "./documentArchive/reducer.js";
import { dotPayPayouts } from "./dotPayPayouts/reducer.js";
import { dynamicSegments } from "./dynamicSegments/reducer.js";
import { emails } from "./emails";
import { employeeProcedures } from "./employeeProcedures/reducer.js";
import { createNewUser } from "./identity/createUser/reducer.js";
import { userRoles } from "./identity/roleInfo/reducer.js";
import { userDetails } from "./identity/userDetails/reducer.js";
import { usersList } from "./identity/users/reducer.js";
import { initProduct } from "./initProducts/initProduct/reducer.js";
import { initProductDetails } from "./initProducts/initProductDetails/reducer.js";
import { messaging } from "./messaging/reducer.js";
import { offers } from "./offers/reducer.js";
import { complaints } from "./order/complaint/reducer.js";
import { deliveryWindows } from "./order/deliveryWindows/reducer.js";
import { operationsOrder } from "./order/operationsOrder/reducer.js";
import { operationsOrderList } from "./order/operationsOrderList/reducer.js";
import { order } from "./order/order/reducer.js";
import { orderComplaint } from "./order/orderComplaint/reducer.js";
import { orderList } from "./order/orderList/reducer.js";
import { upsell } from "./order/upsell/reducer.js";
import { orderAlerts } from "./orderAlerts/reducer.js";
import { paymentList } from "./payments/paymentList/reducer.js";
import { paymentTransfers } from "./payments/paymentTransfers/reducer.js";
import { postCodes } from "./postCodes/reducer.js";
import { priceLists } from "./priceLists/reducer.js";
import { pricing } from "./pricing/reducer.js";
import { procedures } from "./procedures/reducer.js";
import { productDetails } from "./products/productDetails/reducer.js";
import { progress } from "./progress/reducer.js";
import { reports } from "./reports/reducer.js";
import { reviews } from "./reviews/reducer.js";
import { search } from "./search/reducer.js";
import { vatCorrections } from "./vatCorrections/reducer.js";
import { vendorUsers } from "./vendorUsers/reducer.js";
import { certificates } from "./vendors/certificates/reducer";
import { containers } from "./vendors/containers/reducer.js";
import { contract } from "./vendors/contracts/reducer.js";
import { correctionRequest } from "./vendors/correctionRequests/reducer.js";
import { creditLimit } from "./vendors/creditLimits/reducer.js";
import { decisionList } from "./vendors/decisionList/reducer.js";
import { vendorDocuments } from "./vendors/documents/reducer.js";
import { marketingBudgets } from "./vendors/marketingBudget/reducer";
import { vendorPms } from "./vendors/reducer.js";
import { refundation } from "./vendors/refundations/reducer.js";
import { replacements } from "./vendors/replacements";
import { subsidiaryGains } from "./vendors/subsidiaryGains/reducer.js";
import { turnovers } from "./vendors/turnovers/reducer.js";
import { vendorOperation } from "./vendors/vendorOperation";
import { vendorOrders } from "./vendors/vendorOrders/reducer.js";
import { vouchers } from "./vouchers/reducer.js";

const errorToWarning = (state, action) => {
    switch (action.type) {
        case "containerNameValidation":
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { asyncErrors: _, ...noErrors } = state;
            const syncWarnings = action.payload || undefined;
            return { ...noErrors, syncWarnings };
        default:
            return state;
    }
};

export const reducers = {
    usersList: usersList,
    createNewUser: createNewUser,
    userDetails: userDetails,
    form: formReducer.plugin({
        "cms-form": errorToWarning,
    }),
    messageBox: messageBoxReducer,
    productDetails: productDetails,
    vatCorrections,
    orderList: orderList,
    order: order,
    customerList: customerList,
    customer: customer,
    deferredPayments: deferredPayments,
    complaints: complaints,
    progress: progress,
    upsell: upsell,
    deliveryWindows: deliveryWindows,
    orderComplaint: orderComplaint,
    brands: brands,
    alerts: alerts,
    paymentList: paymentList,
    paymentTransfers,
    orderAlerts,
    reviews,
    calendar,
    initProductDetails,
    vendorPms,
    vouchers,
    cache,
    postCodes,
    deliveryCosts,
    pricing,
    offers,
    autocomplete,
    cms,
    search,
    messaging,
    customerDepositsImport,
    dashboard,
    operationsOrderList,
    operationsOrder,
    deliveryComplaints,
    dotPayPayouts,
    dictionaries,
    documentArchive,
    subsidiaryGains,
    contract,
    vendorOrders,
    initProduct,
    crawler,
    refundation,
    correctionRequest,
    creditLimit,
    priceLists,
    userRoles,
    replacements,
    reports,
    turnovers,
    accountingPeriods,
    decisionList,
    analyticGroups,
    vendorOperation,
    balanceDebts,
    vendorDocuments,
    emails,
    vendorUsers,
    containers,
    bridge,
    dynamicSegments,
    customerMembership,
    employeeProcedures,
    procedures,
    certificates,
    marketingBudgets,
};
