import Adable from "components/Adable/Adable";
import ArrayValidationError from "components/Controls/ArrayValidationError";
import Erasable from "components/Erasable/Erasable";
import { FormField } from "components/Form";
import { FormSection } from "redux-form";
import { withFormName } from "utils/hoc";
import { Grid } from "@mui/material";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";
import CalculationBaseSelect from "./CalculationBaseSelect";
import ContractsAutocompletes from "./ContractsAutocompletes";
import RetroDiscountBillingPeriod from "./RetroDiscountBillingPeriod";

const RetroConditionalDiscount = ({ fields, meta, readOnly, useDiff }) => {
    const { t } = useTranslation("vendor");

    return (
        <>
            <ArrayValidationError {...meta} />
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
            >
                <Adable
                    handleAdd={() => {
                        fields.push({});
                    }}
                    renderCondition={!readOnly}
                >
                    {fields.map((field, index) => {
                        return (
                            <Erasable
                                handleRemove={() => {
                                    fields.remove(index);
                                }}
                                renderCondition={!readOnly}
                                key={index}
                            >
                                <FormSection name={field}>
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="stretch"
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >
                                            <RetroDiscountBillingPeriod
                                                readOnly={readOnly}
                                                useDiff={useDiff}
                                            />
                                        </Grid>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >
                                            <CalculationBaseSelect
                                                readOnly={readOnly}
                                                useDiff={useDiff}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <FormField
                                            useDiff={useDiff}
                                            name={"description"}
                                            label={t(vendor.description)}
                                            type={"text"}
                                            readOnly={readOnly}
                                            multiline
                                            stretch
                                        />
                                    </Grid>
                                    <ContractsAutocompletes
                                        readOnly={readOnly}
                                        useDiff={useDiff}
                                    />
                                </FormSection>
                            </Erasable>
                        );
                    })}
                </Adable>
            </Grid>
        </>
    );
};

export default withFormName(RetroConditionalDiscount);
