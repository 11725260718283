import { FormField } from "components/Form";
import { productsCategoriesSelector } from "store/autocomplete";
import { orderBy } from "utils/extensions/arrayExtensions";
import { withFormName } from "utils/hoc";
import { useChange, useCommonTranslation } from "utils/hooks";
import React from "react";
import { useSelector } from "react-redux";

const ProductsCategoriesAutocomplete = ({ form, sectionPrefix, validate, name = "categories", showCategoryId = false, ...props }) => {
    const { t, common } = useCommonTranslation();
    const change = useChange(form);
    const productsCategories = useSelector(productsCategoriesSelector);
    const getCategory = (value) => {
        return typeof value === "string" ? productsCategories.find((c) => c.id === value) : value;
    };

    return (
        <FormField
            stretch
            name={name}
            label={t(common.categories)}
            type={"autocomplete"}
            items={orderBy(productsCategories, (x) => x.name)}
            multiple
            disableCloseOnSelect
            validate={validate}
            getOptionSelected={(option, value) => option?.id === value}
            getOptionLabel={(value) => {
                let cat = getCategory(value);
                return `${cat?.name} ${showCategoryId ? `(${cat?.id})` : ""}`;
            }}
            onCustomChange={(_, newValue) => {
                change(
                    (sectionPrefix ? sectionPrefix + "." : "") + name,
                    newValue.map((x) => (typeof x === "string" ? x : x.id))
                );
            }}
            filterOptions={(options, params) => {
                var { inputValue } = params;
                if (!inputValue || inputValue.trim() === "") {
                    return options;
                }

                return options.filter((o) => {
                    return (showCategoryId && o.id.includes(inputValue)) || o.name.toLowerCase().startsWith(inputValue.toLowerCase());
                });
            }}
            virtualized
            {...props}
        />
    );
};

export default withFormName(ProductsCategoriesAutocomplete);
