import { FormField } from "components/Form";
import { withFormName } from "utils/hoc";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { validators } from "../../validators";

export const calculationBases = [
    { value: "FromVendorPriceList", name: "Obrót w cenach z cennika dostawcy" },
    { value: "FromPurchasePrices", name: "Obrót w cenach zakupu" },
    { value: "ResaleVendorPriceList", name: "Obrót odsprzedażowy w cenach z cennika dostawcy" },
];

const CalculationBaseSelect = ({ readOnly, useDiff, validate = validators.required, hideDefaultItem = true, hidden = false }) => {
    const { t } = useTranslation("vendor");

    return (
        <FormField
            useDiff={useDiff}
            name={"calculationBase"}
            type={"select"}
            label={t(vendor.calculationBase)}
            items={calculationBases}
            hideDefaultItem={hideDefaultItem}
            readOnly={readOnly}
            validate={validate}
            hidden={hidden}
        />
    );
};

export default withFormName(CalculationBaseSelect);
