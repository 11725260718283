import isImageUrl from "is-image-url";
import React, { forwardRef } from "react";
import { Avatar, Grid, InputAdornment, TextField as MuiTextField } from "@mui/material";
import { TextFieldProps } from "control-types";
import FormHelperText from "components-ts/controls/inputs/FormHelperText";
import { TextWithLabel } from "components-ts/text";

const hexRegex = /^#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})$/;

const TextField: React.FC<TextFieldProps> = forwardRef(
    ({ label, error, value, adornment, onChange, numberType, classes, readOnly, placeholder, multiline = false, showPreview }, ref) => {
        if (readOnly) {
            return (
                <TextWithLabel
                    label={label}
                    value={value || value === 0 ? (numberType !== undefined ? parseFloat(String(value)) : value?.toString()) : ""}
                    adornment={adornment}
                    error={error}
                />
            );
        }
        const adormentProps =
            Boolean(adornment) && adornment !== undefined
                ? {
                      startAdornment:
                          adornment.position === "start" ? <InputAdornment position="start">{adornment.value}</InputAdornment> : undefined,
                      endAdornment: adornment.position === "end" ? <InputAdornment position="end">{adornment.value}</InputAdornment> : undefined,
                  }
                : undefined;

        const textField = (
            <MuiTextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                value={value || value === 0 ? (numberType == "numeric" ? parseInt(String(value)) : value) : ""}
                label={label}
                error={error?.hasError}
                onChange={(event) => {
                    if (onChange) {
                        onChange(event.target.value, event);
                    }
                }}
                InputProps={{ ...adormentProps, inputMode: numberType !== undefined ? numberType : "text" }}
                variant="standard"
                classes={classes}
                placeholder={placeholder}
                multiline={multiline}
                type={numberType ? "number" : "text"}
                inputRef={ref}
            />
        );

        if (showPreview) {
            const isResource = isImageUrl(value?.toString());
            const isHex = value && hexRegex.test(value?.toString());

            return (
                <>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-end"
                        spacing={1}
                    >
                        <Grid
                            item
                            xs={isResource || isHex ? 11 : 12}
                        >
                            {textField}
                            <FormHelperText error={error} />
                        </Grid>
                        {isResource || isHex ? (
                            <Grid
                                item
                                xs={1}
                            >
                                <Avatar
                                    variant="square"
                                    src={value?.toString()}
                                    alt=" "
                                    style={{
                                        backgroundColor: value?.toString(),
                                        width: "auto",
                                        height: 50,
                                    }}
                                    slotProps={{
                                        img: {
                                            style: { objectFit: "contain" },
                                        },
                                    }}
                                />
                            </Grid>
                        ) : (
                            <></>
                        )}
                    </Grid>
                </>
            );
        } else {
            return (
                <>
                    {textField}
                    <FormHelperText error={error} />
                </>
            );
        }
    }
);

export default TextField;
