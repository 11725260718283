import React from "react";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import { AccountantDepartment, Role } from "../roles";

const DotPayPayoutsList = React.lazy(() => import("views/dotPayPayouts/DotPayPayoutsList"));

const roles: Role[] = ["Management", "Analyst", "Auditor", ...AccountantDepartment.GetRoles()];

export const Dotpay: PrivateRoute[] = [new PrivateRoute(Paths.DotpayPayouts, roles, DotPayPayoutsList, "dotpayPayouts", "account_balance_wallet")];
