import Complaint from "views/complaint/Complaint";
import { default as CustomerComponent } from "views/customer/customer/Customer";
import CustomerEdit from "views/customer/customer/CustomerEdit";
import DeferredPayment from "views/customer/customer/customer/deferredPayment/DeferredPayment";
import CustomerVoucher from "views/customer/customer/customerVoucher/CustomerVoucher";
import CustomerList from "views/customer/customerList/CustomerList";
import OperationsOrder from "views/order/OperationsOrder";
import Order from "views/order/Order";
import { SupportContactList } from "views-ts/customer/supportContact";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import { AccountantDepartment, B2BAndSupportDepartment, LogisticianDepartment } from "routing-ts/roles";
import {
    allCustomerRoles,
    allCustomerRolesWithAuditorAndHRManager,
    baseWithEcommerce,
    baseWithEcommerceManager,
    deferredPaymentRoles,
} from "./CustomerRoles";
import { SupportDepartment, DebtCoordinator } from "routing-ts/roles";

export const Customer: PrivateRoute[] = [
    new PrivateRoute(Paths.Customer.List, allCustomerRolesWithAuditorAndHRManager, CustomerList, "customers", "people"),
    new PrivateRoute(Paths.Customer.OrderForm, allCustomerRoles, Order),
    new PrivateRoute(Paths.Customer.OperationOrderForm, [], OperationsOrder),
    new PrivateRoute(
        Paths.Customer.Complaint,
        [
            "Analyst",
            "Management",
            "EcommerceManager",
            ...LogisticianDepartment.GetRoles(),
            ...AccountantDepartment.GetRoles(),
            ...B2BAndSupportDepartment,
        ],
        Complaint
    ),
    new PrivateRoute(Paths.Customer.Form, allCustomerRolesWithAuditorAndHRManager, CustomerComponent),
    new PrivateRoute(Paths.Customer.Edit, baseWithEcommerce, CustomerEdit),
    new PrivateRoute(Paths.Customer.Voucher, baseWithEcommerceManager, CustomerVoucher),
    new PrivateRoute(Paths.Customer.DeferredPayment, deferredPaymentRoles, DeferredPayment),
    new PrivateRoute(Paths.Customer.SupportContact, [...DebtCoordinator, ...SupportDepartment.GetRoles()], SupportContactList)
];
