import { RSAA } from "redux-api-middleware";
import { commerce } from "store/actionTypePrefixes";
import { asAsyncFormTypes } from "utils/extensions/arrayExtensions";

export const types = {
    GET_DELIVERY_METHOD_POST_CODES: `${commerce}/GET_DELIVERY_METHOD_POST_CODES`,
    GET_DELIVERY_METHOD_POST_CODES_SUCCESS: `${commerce}/GET_DELIVERY_METHOD_POST_CODES_SUCCESS`,
    GET_DELIVERY_METHOD_POST_CODES_FAILURE: `${commerce}/GET_DELIVERY_METHOD_POST_CODES_FAILURE`,
    PUT_DELIVERY_METHOD_POST_CODES: `${commerce}/PUT_DELIVERY_METHOD_POST_CODES`,
    PUT_DELIVERY_METHOD_POST_CODES_SUCCESS: `${commerce}/PUT_DELIVERY_METHOD_POST_CODES_SUCCESS`,
    PUT_DELIVERY_METHOD_POST_CODES_FAILURE: `${commerce}/PUT_DELIVERY_METHOD_POST_CODES_FAILURE`,
};

const getDeliverymethodPostCodes = (deliveryMethod) => async (dispatch) => {
    return dispatch({
        [RSAA]: {
            endpoint: `alendar/postcodes/${deliveryMethod}`,
            headers: { "Content-Type": "application/json" },
            method: "GET",
            types: [types.GET_DELIVERY_METHOD_POST_CODES, types.GET_DELIVERY_METHOD_POST_CODES_SUCCESS, types.GET_DELIVERY_METHOD_POST_CODES_FAILURE],
        },
    });
};

const updatePostCodes = (values) => async (dispatch) => {
    await dispatch({
        [RSAA]: {
            endpoint: `alendar/postcodes/${values.deliveryMethod}`,
            headers: { "Content-Type": "application/json" },
            method: "PUT",
            types: asAsyncFormTypes([
                types.PUT_DELIVERY_METHOD_POST_CODES,
                types.PUT_DELIVERY_METHOD_POST_CODES_SUCCESS,
                types.PUT_DELIVERY_METHOD_POST_CODES_FAILURE,
            ]),
            body: JSON.stringify(values),
        },
    });

    dispatch(getDeliverymethodPostCodes(values.deliveryMethod));
};

export const actions = {
    getDeliverymethodPostCodes,
    updatePostCodes,
};
