import { useState } from "react";
import { Grid } from "@mui/material";
import { common } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { ToggleButtonGroup } from "components-ts/controls";
import { FormRow, FormSelect } from "components-ts/forms";
import FormDate from "../FormDate";
import FormDayOfWeekSelect from "../common/FormDayOfWeekSelect";

const DatePredicate: React.FC<{ readOnly?: boolean }> = ({ readOnly }) => {
    const [deliveryType, setDeliveryType] = useState<"date" | "day">("date");
    const { t } = useTranslation();

    return (
        <>
            <FormRow>
                <Grid item>
                    <ToggleButtonGroup
                        value={deliveryType}
                        items={[
                            {
                                value: "date",
                                name: "Data",
                            },
                            {
                                value: "day",
                                name: "Dzień",
                            },
                        ]}
                        onChange={(newValue) => {
                            if (newValue === "date" || newValue === "day") {
                                setDeliveryType(newValue);
                            }
                        }}
                        readOnly={readOnly}
                        label={t(common.deliveryMethods)}
                    />
                </Grid>
            </FormRow>

            {deliveryType === "date" ? (
                <FormRow>
                    <FormDate
                        name="dateFrom"
                        label={t(common.dateFrom)}
                        readOnly={readOnly}
                    />

                    <FormDate
                        name="dateTo"
                        label={t(common.dateTo)}
                        readOnly={readOnly}
                    />
                </FormRow>
            ) : (
                <FormRow>
                    <FormDayOfWeekSelect
                        name="dayFrom"
                        label={t(common.dateFrom)}
                        readOnly={readOnly}
                    />

                    <FormDayOfWeekSelect
                        name="dayTo"
                        label={t(common.dateTo)}
                        readOnly={readOnly}
                    />
                </FormRow>
            )}

            <FormRow>
                <FormSelect
                    name="hourFrom"
                    label={t(common.hourFrom)}
                    items={[...Array(25).keys()]}
                    readOnly={readOnly}
                />

                <FormSelect
                    name="hourTo"
                    label={t(common.hourTo)}
                    items={[...Array(25).keys()]}
                    readOnly={readOnly}
                />
            </FormRow>
        </>
    );
};

export default DatePredicate;
