import { tValidation, validation } from "utils-ts/validations/translation";
import Validator, { inRange, notEmptyValidator } from "./basic";
import phoneNumberValidator from "./phoneNumberValidator";
import postCodeValidator from "./postCodeValidator";

export default function validateShippingAddress(shippingAddress = {}) {
    const { recipient, apartmentNumber, buildingNumber, city, floorNumber, instructions, phoneNumber, postcode, stairwayNumber, street } =
        shippingAddress;
    const errors = {};

    errors["recipient"] = notEmptyValidator(recipient).must(inRange(0, 100)).validate();
    errors["phoneNumber"] = phoneNumberValidator(phoneNumber).validate();
    errors["postcode"] = postCodeValidator(postcode).validate();
    errors["city"] = notEmptyValidator(city)
        .must(inRange(0, 100), tValidation(validation.charCount, { min: 0, max: 100 }))
        .validate();
    errors["street"] = notEmptyValidator(street)
        .must(inRange(0, 100), tValidation(validation.charCount, { min: 0, max: 100 }))
        .validate();
    errors["buildingNumber"] = notEmptyValidator(buildingNumber)
        .must(inRange(0, 100), tValidation(validation.charCount, { min: 0, max: 100 }))
        .validate();
    errors["apartmentNumber"] = new Validator(apartmentNumber)
        .must(inRange(0, 10), tValidation(validation.charCount, { min: 0, max: 10 }))
        .validate();
    errors["stairwayNumber"] = new Validator(stairwayNumber).must(inRange(0, 10), tValidation(validation.charCount, { min: 0, max: 10 })).validate();
    errors["floorNumber"] = new Validator(floorNumber).must(inRange(0, 10), tValidation(validation.charCount, { min: 0, max: 10 })).validate();
    errors["instructions"] = new Validator(instructions).must(inRange(0, 1000), tValidation(validation.charCount, { min: 0, max: 1000 })).validate();

    return errors;
}
