import { IconButton } from "components/Button";
import { FormField } from "components/Form";
import { withStyles } from "tss-react/mui";
import { toSelectList } from "utils/extensions/arrayExtensions";
import { notEmptyValidator } from "utils/validators/basic";
import postCodeValidator from "utils/validators/postCodeValidator";
import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { common } from "translations";

const styles = (theme) => ({
    gridItem: {
        marginRight: theme.spacing(2),
        minWidth: 200,
    },
    select: {
        minWidth: 250,
    },
    container: {
        marginTop: 20,
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    removeButton: {
        marginLeft: "auto",
    },
});

const typeValidator = (value) => notEmptyValidator(value).validate();
const validatePostCode = (value) => postCodeValidator(value).validate();

class PostcodeField extends React.Component {
    shouldComponentUpdate(nextProps) {
        const { postcode = {} } = this.props;
        const { postcode: nextPostcode = {} } = nextProps;

        return postcode.type !== nextPostcode.type;
    }
    render() {
        const { t, postcode, handleRemove, handleChangeType, readOnly = false } = this.props;
        const classes = withStyles.getClasses(this.props);
        const { type } = postcode || {};
        return (
            <Fragment>
                <Grid
                    className={classes.container}
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="stretch"
                >
                    <FormField
                        name="type"
                        label={t(common.type)}
                        type="select"
                        items={toSelectList(["mask", "range"], t, "common")}
                        onChange={handleChangeType}
                        validate={typeValidator}
                        readOnly={readOnly}
                    />
                    <FormField
                        hidden={type !== "mask"}
                        name="mask.mask"
                        label={t(common.mask)}
                        type="text"
                        validate={validatePostCode}
                        readOnly={readOnly}
                    />
                    <FormField
                        hidden={type === "mask"}
                        name="range.postcodeFrom"
                        label={t(common.rangeFrom)}
                        type="text"
                        validate={validatePostCode}
                        readOnly={readOnly}
                    />
                    <FormField
                        hidden={type === "mask"}
                        name="range.postcodeTo"
                        label={t(common.rangeTo)}
                        type="text"
                        validate={validatePostCode}
                        readOnly={readOnly}
                    />
                    {/* <Grid item> */}
                    {!readOnly && (
                        <IconButton
                            icon="delete"
                            onClick={handleRemove}
                        />
                    )}
                    {/* </Grid> */}
                </Grid>
            </Fragment>
        );
    }
}

export default withStyles(withTranslation("common")(PostcodeField), styles);
