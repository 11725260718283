import { UserConsumer } from "context/UserContext/UserContext";
import React, { Fragment } from "react";

function withAuthorizedDisplay(Component) {
    return function WrapperComponent(props) {
        const { roles: requiredRoles = [] } = props;
        return (
            <UserConsumer>
                {(consumerProps) => {
                    const { isInAnyRoleOrAdmin } = consumerProps;

                    return isInAnyRoleOrAdmin(requiredRoles) ? (
                        <Component
                            {...props}
                            context={{ ...props.context, ...consumerProps }}
                        />
                    ) : (
                        <Fragment />
                    );
                }}
            </UserConsumer>
        );
    };
}

export default withAuthorizedDisplay;
