import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import { formatTypes, stringStyle } from "utils/formating";
import { orders } from "translations";
import { useTranslation } from "utils-ts/hooks";

const SimpleProductList = ({ products }) => {
    const { t } = useTranslation("orders");
    const columns = [
        { key: "productId", value: orders.productId, type: formatTypes.text },
        { key: "productName", value: orders.productName, type: formatTypes.text },
        { key: "ean", value: orders.ean, type: formatTypes.text },
        { key: "quantity", value: orders.quantity, type: formatTypes.text, options: { style: stringStyle.imporant } },
    ].map((col) => ({ ...col, value: t(col.value) }));

    return (
        <SimpleTable
            hover
            stripped
            columns={columns}
            data={products.map((x) => ({
                ...x,
                ...x.product,
                productName: x.productName || `${x.product.brand} ${x.product.name ? x.product.name.pl : ""}`,
            }))}
        />
    );
};

export default SimpleProductList;
