import AcceptRejectDialog from "components/Dialog/AcceptRejectDialog";
import Typography from "@mui/material/Typography";
import { common, orders } from "translations";
import { useTranslation } from "utils-ts/hooks";

const RefundOrderDialog = ({ open, handleAccept, handleReject }) => {
    const { t } = useTranslation(["orders", "common"]);

    return (
        <AcceptRejectDialog
            maxWidth={"sm"}
            open={open}
            title={t(orders.orderRefund)}
            handleAccept={handleAccept}
            handleReject={handleReject}
        >
            <Typography variant="body1">
                {t(`common:${common.areYouSure}`, {
                    what: t(orders.fullOrderRefundQuestion),
                })}
            </Typography>
        </AcceptRejectDialog>
    );
};

export default RefundOrderDialog;
