import { manager, operations } from "store/actionTypePrefixes";
import { AsyncTypes, asAsyncFormTypes, createAction } from "utils/extensions";

const types = {
    formInit: createAction("FORM_INIT_CAMPAIGNS", manager),
    getProcedureCategory: new AsyncTypes("GET_PROCEDURE_CATEGORY", operations),
    createOrUpdate: new AsyncTypes("CREATE_OR_UPDATE_PROCEDURE_CATEGORY", operations, [asAsyncFormTypes]),
};

export default types;
