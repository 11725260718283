import { apiMiddleware } from "redux-api-middleware";
import thunk from "redux-thunk";
import { composeWithDevToolsDevelopmentOnly, composeWithDevToolsLogOnlyInProduction } from "@redux-devtools/extension";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { RSAAEndResolverMiddleware } from "./middleware/RSAAEndResolverMiddleware";
import { RSSAStartResolverMiddleware } from "./middleware/RSSAStartResolverMiddleware";
import { apiErrorMiddleware } from "./middleware/apiErrorMiddleware";
import { cleaniseFormDataMiddleware } from "./middleware/cleaniseFormDataMiddleware";
import { endpointResolverMiddleware } from "./middleware/endpointResolverMiddleware";
import { formRequestMiddleware } from "./middleware/formRequestMiddleware";
import { tokenMiddleware } from "./middleware/tokenMiddleware";
import { reducers } from "./reducers";

const configureStore = () => {
    const middlewares = [
        thunk,
        RSSAStartResolverMiddleware,
        endpointResolverMiddleware,
        tokenMiddleware,
        cleaniseFormDataMiddleware,
        apiMiddleware,
        formRequestMiddleware,
        apiErrorMiddleware,
        RSAAEndResolverMiddleware,
    ];

    const { config } = window;
    const { env } = config;
    const isProduction = "Production" === env;
    const composeEnhancers = isProduction ? composeWithDevToolsLogOnlyInProduction : composeWithDevToolsDevelopmentOnly;

    return createStore(combineReducers({ ...reducers }), window.initialReduxState, composeEnhancers(applyMiddleware(...middlewares)));
};

const store = configureStore();

export default store;
