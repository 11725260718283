import { documentArchiveCompanies } from "resource/documentArchiveCompanies";
import { createSelector } from "reselect";

const root = (state) => {
    return {
        autocomplete: state.autocomplete,
        documentArchive: state.documentArchive,
    };
};

export const documentArchiveSearchSelector = createSelector(root, (state) => {
    const { autocomplete } = state;
    const { vendors, documentCategories, documentTypes } = autocomplete;
    const { search } = state.documentArchive;
    const { items = [] } = search || {};

    return {
        ...search,
        items: items.map((item) => ({
            ...item,
            documentDate: item.canAccess ? item.documentDate : undefined,
            category: item.canAccess ? documentCategories.find((x) => x.id === item.categoryId)?.name : "Brak dostępu",
            documentType: documentTypes.find((x) => x.id === item.documentTypeId)?.name,
            contractor: item.contractorId
                ? `[${item.contractorId}] - ${vendors.find((x) => x.vendorId === (item.vendorId ?? `${item.contractorId}_FRS`))?.name}`
                : "",
            company: documentArchiveCompanies.find((d) => d.value === item.companyId)?.name,
        })),
    };
});

export const documentArchiveSearchFiltersSelector = createSelector(documentArchiveSearchSelector, (search) => search.filters);
