import { FormField } from "components/Form";
import paymentMethods from "resource/paymentMethods";
import { useCommonTranslation } from "utils/hooks";

const PaymentMethodsSelectField = (props) => {
    const { t, common } = useCommonTranslation();
    return (
        <FormField
            {...props}
            name={props.name ? props.name : "paymentMethods"}
            label={t(common.availablePaymentMethods)}
            type="select"
            multiple
            items={paymentMethods}
        />
    );
};

export default PaymentMethodsSelectField;
