import { RSAA } from "redux-api-middleware";
import { operations } from "store/actionTypePrefixes";
import { asAsyncFormTypes } from "utils/extensions/arrayExtensions";
import { combineIntoQueryParams } from "utils/objectExtensions";

const DOTPAY_PAYOUTS_GET = `${operations}/DOTPAY_PAYOUTS_GET`;
const DOTPAY_PAYOUTS_GET_SUCCESS = `${operations}/DOTPAY_PAYOUTS_GET_SUCCESS`;
const DOTPAY_PAYOUTS_GET_FAILURE = `${operations}/DOTPAY_PAYOUTS_GET_FAILURE`;
const DOTPAY_PAYOUTS_REPEAT_PUT = `${operations}/DOTPAY_PAYOUTS_REPEAT_PUT`;
const DOTPAY_PAYOUTS_REPEAT_PUT_SUCCESS = `${operations}/DOTPAY_PAYOUTS_REPEAT_PUT_SUCCESS`;
const DOTPAY_PAYOUTS_REPEAT_PUT_FAILURE = `${operations}/DOTPAY_PAYOUTS_REPEAT_PUT_FAILURE`;

export const types = {
    DOTPAY_PAYOUTS_GET,
    DOTPAY_PAYOUTS_GET_SUCCESS,
    DOTPAY_PAYOUTS_GET_FAILURE,
    DOTPAY_PAYOUTS_REPEAT_PUT,
    DOTPAY_PAYOUTS_REPEAT_PUT_SUCCESS,
    DOTPAY_PAYOUTS_REPEAT_PUT_FAILURE,
};

const getDotPayPayouts = (payload) => async (dispatch) => {
    const { pageSize, pageIndex } = payload;

    const endpoint = `api/dotpay-payout?${combineIntoQueryParams({
        pageSize,
        pageIndex,
    })}`;
    const result = await dispatch({
        [RSAA]: {
            endpoint,
            method: "GET",
            types: [DOTPAY_PAYOUTS_GET, DOTPAY_PAYOUTS_GET_SUCCESS, DOTPAY_PAYOUTS_GET_FAILURE],
        },
    });

    return Promise.resolve(result);
};

const markDotPayPayoutToRepeat = (payload) => async (dispatch) => {
    const endpoint = `api/dotpay-payout/${payload}`;
    return await dispatch({
        [RSAA]: {
            endpoint,
            method: "PUT",
            types: asAsyncFormTypes([DOTPAY_PAYOUTS_REPEAT_PUT, DOTPAY_PAYOUTS_REPEAT_PUT_SUCCESS, DOTPAY_PAYOUTS_REPEAT_PUT_FAILURE]),
        },
    });
};

export const actions = {
    getDotPayPayouts,
    markDotPayPayoutToRepeat,
};
