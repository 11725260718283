import { commerce, crawler, identity, operations } from "store/actionTypePrefixes";

const RSAA = "@@redux-api-middleware/RSAA";

export const endpointResolverMiddleware = () => (next) => (action) => {
    if (action[RSAA] && action[RSAA].types[0]) {
        const actionType = action[RSAA].types[0].type ? action[RSAA].types[0].type : action[RSAA].types[0];
        if (actionType.indexOf("@@administration") !== -1) {
            action[RSAA].endpoint = window.config.applications.identity + action[RSAA].endpoint;
        } else if (actionType.indexOf("@@content") !== -1) {
            action[RSAA].endpoint = window.config.applications.content + action[RSAA].endpoint;
        }
        if (actionType.includes(operations)) {
            action[RSAA].endpoint = window.config.applications.operations + action[RSAA].endpoint;
        }
        if (actionType.includes(commerce)) {
            action[RSAA].endpoint = window.config.applications.commerce + action[RSAA].endpoint;
            action[RSAA].headers = {
                ...action[RSAA].headers,
                "X-Frisco-Features": "BalanceAmount=1",
            };
        }
        if (actionType.includes(identity)) {
            action[RSAA].endpoint = window.config.applications.identity + action[RSAA].endpoint;
        }
        if (actionType.includes(crawler)) {
            action[RSAA].endpoint = window.config.applications.crawler + action[RSAA].endpoint;
        }
    }
    return next(action);
};
