import { makeStyles } from "tss-react/mui";
import React from "react";
import { Theme } from "@mui/material";

const useStyles = makeStyles<{ spacing: number }>()((theme: Theme, { spacing }) => ({
    spacing: {
        padding: theme.spacing(spacing),
        position: "relative",
        boxSizing: "border-box",
        width: "100%",
    },
}));

const Spacing: React.FC<{ children: React.ReactNode; spacing: number, style?: React.CSSProperties }> = ({ spacing, children, style }) => {
    const { classes } = useStyles({ spacing });

    return <div className={classes.spacing} style={style}>{children}</div>;
};

export default Spacing;
