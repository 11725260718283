import {
    B2BDepartment,
    ECommerceDepartment,
    LogisticianDepartment,
    MarketingDepartment,
    Role,
    SalesDepartment,
    SupportDepartment,
    TradeMarketingDepartment,
} from "routing-ts/roles";

const base: Role[] = ["Management", "Analyst", ...ECommerceDepartment.GetRoles()];

export const contractsRoles: Role[] = [...base, ...B2BDepartment.GetRoles()];

export const giftsRoles: Role[] = [...base, ...MarketingDepartment.GetRoles(), ...TradeMarketingDepartment.GetRoles()];

export const offersEditRoles: Role[] = [...giftsRoles, ...SalesDepartment.GetRoles()];

export const feedRoles: Role[] = [...offersEditRoles, "BusinessSupportManager"];

export const readOfferRoles: Role[] = ["AXUser", ...B2BDepartment.GetRoles(), ...SupportDepartment.GetRoles(), ...offersEditRoles];

export const readOfferProductRoles: Role[] = [...readOfferRoles, ...LogisticianDepartment.GetRoles()];

export const purchaseScheduleRoles: Role[] = ["AXUser", ...SalesDepartment.GetRoles()];
