import { useMemo } from "react";
import { useTranslation as useT } from "react-i18next";
import { Namespace } from "../../translations/Translation";
import { Translation, TranslationFunction } from "translations/Translation";

const useTranslation = (namespace: Namespace[] | Namespace | undefined = undefined): { t: TranslationFunction } => {
    const { t: translate } = useT(namespace);
    const t = useMemo(() => {
        const t: TranslationFunction = (translation: Translation, params: {} | undefined) => {
            {
                if (translation === undefined) {
                    console.error("Empty translation");
                    return "____";
                } else if (typeof translation === "string") {
                    if (translation === "") {
                        return "";
                    } else if (translation.includes(".")) {
                        let splitter = ".";
                        if (translation.split(splitter).length === 1) {
                            splitter = ":";
                        }

                        const [ns, name] = translation.split(splitter);
                        return translate(`${ns}:${name}`, params);
                    } else if (namespace) {
                        if (Array.isArray(namespace)) {
                            if (namespace.some((ns) => translation.startsWith(ns))) {
                                return translate(translation, params);
                            } else {
                                return translate(`${namespace[0]}:${translation}`, params);
                            }
                        } else {
                            if (translation.startsWith(namespace)) {
                                return translate(translation, params);
                            } else {
                                return translate(`${namespace}:${translation}`, params);
                            }
                        }
                    } else {
                        return translate(`common:${translation}`, params);
                    }
                }

                const { resource, params: transParams } = translation;
                let splitter = ".";
                if (resource.split(splitter).length === 1) {
                    splitter = ":";
                }

                if (resource.split(splitter).length === 1) {
                    return translate(`common:${resource}`, transParams);
                } else {
                    const [ns, name] = resource.split(splitter);

                    return translate(`${ns}:${name}`, transParams);
                }
            }
        };

        return {
            t,
        };
    }, [translate]);

    return t;
};

export default useTranslation;
