import { content, operations } from "store/actionTypePrefixes";
import { AsyncTypes, createAction } from "utils/extensions";

const types = {
    productGetDetails: new AsyncTypes("PRODUCT_GET_DETAILS", content),
    productUpdateImages: new AsyncTypes("PRODUCT_UPDATE_IMAGE", content),
    productUpdateAttributes: new AsyncTypes("PRODUCT_UPDATE_ATTRIBUTES", content),
    productGetAttributes: new AsyncTypes("PRODUCT_GET_ATTRIBUTES", content),
    productGetActiveCategories: new AsyncTypes("PRODUCT_GET_ACTIVE_CATEGORIES", content),
    productGetActiveCategoriesCache: createAction("PRODUCT_GET_ACTIVE_CATEGORIES", content),
    productUpdateCategories: new AsyncTypes("PRODUCT_UPDATE_CATEGORIES", content),
    updateCustomLabelArray: createAction("UPDATE_CUSTOM_LABEL_ARRAY", content),
    productUpdateAttribute: new AsyncTypes("PRODUCT_ATTRIBUTE_UPDATE", content),
    productGetSections: new AsyncTypes("PRODUCT_GET_SECTIONS", content),
    changeAttributesFrom: new AsyncTypes("CHANGE_ATTRIBUTES_FROM", content),
    changeVariant: createAction("PRODUCT_CHANGE_VARIANT", content),
    changeSetIntegrationImageIfPossible: new AsyncTypes("CHANGE_SET_INTEGRATION_IMAGE_IF_POSSIBLE", content),
    getProductInternalData: new AsyncTypes("GET_PRODUCT_INTERNAL_DATA", operations),
    changeProductPricePoint: new AsyncTypes("CHANGE_PRODUCT_PRICE_POINT", operations),
    changeProductClassification: new AsyncTypes("CHANGE_PRODUCT_CLASSIFICATION", operations),
};

export default types;
