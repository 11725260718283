import { RSAA } from "redux-api-middleware";
import { asAsyncFormTypes, asCleanFormDataTypes } from "utils/extensions/arrayExtensions";
import types from "./types";

const { saveGift: saveGiftTypes, getGift } = types;

const endpoint = (id) => `api/v1/offer/products/gifts${id ? `/${id}` : ""}`;

export const initGiftForm = (id, isCopy) => async (dispatch) => {
    await dispatch({ type: types.initForm });
    if (id) {
        return await dispatch({
            [RSAA]: {
                endpoint: endpoint(id),
                headers: { "Content-Type": "application/json" },
                method: "GET",
                types: getGift.all.map((x) => ({ type: x, meta: { isCopy } })),
            },
        });
    }
};

export const saveGift = (values, id) => async (dispatch) => {
    return await dispatch({
        [RSAA]: {
            endpoint: endpoint(id),
            headers: { "Content-Type": "application/json" },
            method: id ? "PUT" : "POST",
            types: asCleanFormDataTypes(asAsyncFormTypes(saveGiftTypes.all)),
            body: JSON.stringify(values),
        },
    });
};
