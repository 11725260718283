import { FieldPath, FieldValues } from "react-hook-form";
import { FormControlProps } from "./types";
import { ProductsDragAndDrop } from "components-ts/controls";
import Controller from "./Controller";

type FormProductIdsProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = FormControlProps<string[], TFieldValues, TName> & {};

const FormProductsDragAndDrop = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
    name,
    label,
    defaultValue,
    emptyValue = [],
    width,
    readOnly,
}: FormProductIdsProps<TFieldValues, TName>): JSX.Element => {
    return (
        <Controller
            name={name}
            render={({ field: { ref, value, onChange, disabled, ...fieldProps }, fieldState: { error } }) => {
                return (
                    <ProductsDragAndDrop
                        ref={ref}
                        label={label}
                        value={value || []}
                        readOnly={readOnly}
                        onChange={(newValue) => {
                            onChange(newValue.map((v) => (typeof v === "string" ? { productId: v } : v)));
                        }}
                        error={{
                            hasError: Boolean(error),
                            message: error?.message,
                        }}
                    />
                );
            }}
            defaultValue={defaultValue}
            transform={(val) => (val ? val : emptyValue)}
            width={width}
        />
    );
};

export default FormProductsDragAndDrop;
