import { FormField } from "components/Form";
import { promotionPeriodsSelector } from "store/autocomplete";
import { withFormName } from "utils/hoc";
import { useChange } from "utils/hooks";
import { useFormValueSelector } from "utils/hooks";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { bridge, common } from "translations";
import { useTranslation } from "utils-ts/hooks";

const PromotionPeriodPicker = ({ onChange, form, readOnly, props }) => {
    const { t } = useTranslation(["bridge", "common"]);
    const promotionPeriods = useSelector(promotionPeriodsSelector);
    const [promotionPeriodsOptions, setPromotionPeriodsOptions] = useState([]);
    const [initYearChange, setInitYearChange] = useState(true);
    const change = useChange(form);
    const selectedPromotionPeriods = useFormValueSelector(form, "period", []);
    const year = useFormValueSelector(form, "year");
    const years = promotionPeriods.map((p) => p.dateFrom.split("-")[0]).filter((x, i, a) => a.indexOf(x) === i);

    useEffect(() => {
        handleYearChange(year);
        changePromotionPeriods(selectedPromotionPeriods);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [year, promotionPeriods]);

    const handleChange = (event, newValue) => {
        let value = newValue.filter((v) => !v.unassignedPeriod).map((v) => v.value || v);
        changePromotionPeriods(value);
    };

    const changePromotionPeriods = (value) => {
        if (value[value.length - 1] === "0") {
            onChange(promotionPeriodsOptions.map((p) => p.value));
            return;
        } else if (value.length == promotionPeriodsOptions.length - 1 && value.indexOf("0") < 0) {
            value = [];
        }
        onChange(value);
    };
    const findName = (values) => {
        const find = (val) =>
            (
                promotionPeriodsOptions.find((x) => x.value === val) || {
                    name: "",
                }
            ).name;

        if (!Array.isArray(values)) return find(values);
        if (values.find((v) => v === "0")) {
            return find("0");
        }
        return values.map((v) => find(v)).join(", ");
    };

    const handleYearChange = (newValue) => {
        const options =
            promotionPeriods.filter((p) => p.dateFrom.startsWith(newValue || "-")).length > 0
                ? [
                      { name: "(wszystkie)", value: "0" },
                      ...promotionPeriods
                          .filter((p) => p.dateFrom.startsWith(newValue || "-"))
                          .map((p) => {
                              const date = p.dateFrom.substring(5, p.dateFrom.indexOf("T")) + "/" + p.dateTo.substring(5, p.dateTo.indexOf("T"));

                              if (p.isUnassignedPeriod) {
                                  return {
                                      name: t(bridge.unassignedPeriod) + " (" + date + ")",
                                      value: p.dateFrom.substring(0, p.dateFrom.indexOf("T")) + "/" + p.dateTo.substring(0, p.dateTo.indexOf("T")),
                                  };
                              }
                              return {
                                  name: p.name + " (" + date + ")",
                                  value: p.id,
                              };
                          }),
                  ]
                : [];
        setPromotionPeriodsOptions(options);

        if (!!!initYearChange && newValue != year) {
            change("period", []);
        } else {
            setInitYearChange(false);
        }
    };

    return (
        <>
            <FormField
                {...props}
                name={"year"}
                label={t(`common:${common.year}`)}
                type="select"
                items={years.map((o) => {
                    return {
                        name: o,
                        value: o,
                    };
                })}
                readOnly={readOnly}
                onChange={(event, newValue) => {
                    handleYearChange(newValue);
                }}
                hideDefaultItem
            />
            <FormField
                label={t(`common:${common.promotionPeriods}`)}
                name={"period"}
                type={"select"}
                readOnly={readOnly}
                onChange={handleChange}
                renderValue={(value) => findName(value)}
                multiple
                items={promotionPeriodsOptions}
                selectAllEnabled={true}
            />
        </>
    );
};

export default withFormName(PromotionPeriodPicker);
