export const RSAA_STARTED = "RSAA_STARTED";
export const RSAA_ENDED = "RSAA_ENDED";

export const RSAAStart = (name) => ({
    type: RSAA_STARTED,
    payload: name,
});

export const RSAAEnd = (name) => ({
    type: RSAA_ENDED,
    payload: name,
});
