import types from "./types";

const initialState = {
    isSearchLoading: false,
    isExportToExcelInProgress: false,
    isExportCurrentPurchasePriceInProgress: false,
    isImportPromotionSellPriceInProgress: false,
    isTableLoading: false,
    warehouseMappings: null,
    priceListLines: [],
};

const { getWarehouseMappings, getPriceLists, exportPriceLists, exportCurrentPurchasePrice, importPromotionSellPrices } = types;

export const priceLists = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case getWarehouseMappings.request:
            return { ...state, isSearchLoading: true };
        case getWarehouseMappings.success:
            return { ...state, warehouseMappings: payload, isSearchLoading: false };
        case getWarehouseMappings.failure:
            return { ...payload, isSearchLoading: false };

        case getPriceLists.request:
            return { ...state, priceListLines: [], isTableLoading: true, isSearchLoading: true };
        case getPriceLists.success:
            return {
                ...state,
                priceListLines: payload,
                isTableLoading: false,
                isSearchLoading: false,
            };
        case getPriceLists.failure:
            return { ...state, priceListLines: [], isTableLoading: false, isSearchLoading: false };

        case exportPriceLists.request:
            return { ...state, isExportToExcelInProgress: true };
        case exportPriceLists.success:
        case exportPriceLists.failure:
            return { ...state, isExportToExcelInProgress: false };

        case exportCurrentPurchasePrice.request:
            return { ...state, isExportCurrentPurchasePriceInProgress: true };
        case exportCurrentPurchasePrice.success:
        case exportCurrentPurchasePrice.failure:
            return { ...state, isExportCurrentPurchasePriceInProgress: false };

        case importPromotionSellPrices.request:
            return { ...state, isImportPromotionSellPriceInProgress: true };
        case importPromotionSellPrices.success:
        case importPromotionSellPrices.failure:
            return { ...state, isImportPromotionSellPriceInProgress: false };

        default:
            return { ...state };
    }
};
