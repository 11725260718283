import { Button, IconButton } from "components/Button";
import { ArrayValidationError } from "components/Controls";
import Layout from "components/Grid/Layout";
import { FormSection } from "redux-form";
import { makeStyles } from "tss-react/mui";
import { useCommonTranslation } from "utils/hooks";
import { Grid, Paper } from "@mui/material";
import { Voucher } from "../models";
import VoucherFields from "./VoucherFields";

const useStyles = makeStyles()((theme) => ({
    paper: {
        margin: theme.spacing(1),
        padding: theme.spacing(2),
    },
    button: {
        marginLeft: "auto",
    },
}));

const GeneratorDefinitions = ({ fields, meta }) => {
    const { t, common } = useCommonTranslation();
    const { classes } = useStyles();
    return (
        <Layout
            isEnlarged
            headerText={t(common.generatorDefinition)}
        >
            <ArrayValidationError {...meta} />

            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
            >
                {fields.map((field, index) => (
                    <Paper
                        elevation={2}
                        key={field}
                        className={classes.paper}
                    >
                        <IconButton
                            icon="delete"
                            onClick={() => fields.remove(index)}
                        />

                        <FormSection name={field}>
                            <VoucherFields
                                datesRequired={false}
                                showGeneratorFields={true}
                            />
                        </FormSection>
                    </Paper>
                ))}

                <Grid
                    item
                    className={classes.button}
                >
                    <Button
                        actionType="primary"
                        onClick={() => fields.push(new Voucher())}
                    >
                        {t(common.add)}
                    </Button>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default GeneratorDefinitions;
