import TextWithLabel from "components/Text/TextWithLabel";
import { formatTypes } from "utils/formating";
import { common } from "translations";
import ProductField from "views/offers/productPreview/components/ProductField";

const ProductInternalInfo = ({ productClassification, pricePoint }) => {
    const info = [
        new ProductField(common.productClassification, productClassification, formatTypes.text),
        new ProductField(common.pricePoint, pricePoint, formatTypes.text),
    ];

    return info.map((f) => (
        <TextWithLabel
            unselectableLabel
            key={f.label}
            {...f}
        />
    ));
};

export default ProductInternalInfo;
