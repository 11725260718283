import { RSAA } from "redux-api-middleware";
import { commerce, manager } from "store/actionTypePrefixes";
import Cache, { isExpired } from "utils/cache/cacheProvider";
import { combineIntoQueryParams } from "utils/objectExtensions";
import moment from "moment";

export const types = (cacheName, appName = commerce) => ({
    GET_CACHED: `${appName}/GET_CACHED_${cacheName}`,
    GET_CACHED_SUCCESS: `${appName}/GET_CACHED_SUCCESS_${cacheName}`,
    GET_CACHED_FAILURE: `${appName}/GET_CACHED_FAILURE_${cacheName}`,
    UPDATE_CACHE_STORE: `${manager}/UPDATE_CACHE_STORE_${cacheName}`,
});

const getCachedItems = async (cache, ids, pad = true, itemGetPropertyName = undefined) => {
    const paddedIds = pad ? ids.map((id) => String(id).padStart(6, 0)) : ids;

    let cached = [];
    let notCached = [];
    for (let id of paddedIds) {
        const item = await cache.getItem(id);

        if (item && !isExpired(item.cacheExpiresAt)) {
            cached = undefined ? [...cached, ...item[itemGetPropertyName]] : [...cached, item];
        } else {
            notCached = [...notCached, id];
        }
    }

    return Promise.resolve([cached, notCached]);
};

const setCacheItems = (cache, items, idPropertyName, pad) => {
    const { items: pagedResult } = items;
    if (pagedResult) {
        pagedResult.forEach((item) => {
            const key = String(item[idPropertyName]);
            cache.setItem(pad ? key.padStart(6, "0") : key, {
                ...item,
                cacheExpiresAt: moment().add(1, "hour").format(),
            });
        });
    } else {
        items.forEach((item) => {
            const key = String(item[idPropertyName]);
            cache.setItem(pad ? key.padStart(6, "0") : key, {
                ...item,
                cacheExpiresAt: moment().add(1, "hour").format(),
            });
        });
    }
};

const getCached =
    ({
        ids,
        params,
        cacheName,
        endpoint,
        itemsPropertyName,
        queryPropetyName,
        idPropertyName,
        appName = commerce,
        method = "GET",
        useQuery = true,
        pad = true,
        query = undefined,
        queryPropetyNameForQuery = undefined,
        itemGetPropertyName = undefined,
    }) =>
    async (dispatch) => {
        const cache = Cache(cacheName);
        const [cached, notCached] = await getCachedItems(cache, ids, pad, itemGetPropertyName);
        const actionTypes = types(cacheName, appName);
        let items = [];
        if (notCached.length !== 0) {
            let queryParams;
            if (useQuery) {
                queryParams = combineIntoQueryParams({
                    [query ? queryPropetyNameForQuery : queryPropetyName]: query ? query : notCached,
                    ...params,
                });
            }
            let bodyParams;
            if (!useQuery) {
                bodyParams = {
                    [query ? queryPropetyNameForQuery : queryPropetyName]: query ? query : notCached,
                };
            }

            const { payload, error } = await dispatch({
                [RSAA]: {
                    endpoint: `${endpoint}?${queryParams}`,
                    headers: { "Content-Type": "application/json" },
                    method: method,
                    types: [
                        {
                            type: actionTypes.GET_CACHED,
                            meta: {
                                ignoreInProgress: true,
                            },
                        },
                        {
                            type: actionTypes.GET_CACHED_SUCCESS,
                            meta: {
                                ignoreInProgress: true,
                            },
                        },
                        {
                            type: actionTypes.GET_CACHED_FAILURE,
                            meta: {
                                ignoreInProgress: true,
                            },
                        },
                    ],
                    body: JSON.stringify(bodyParams),
                },
            });
            if (!error) items = itemsPropertyName ? payload[itemsPropertyName] : payload || [];
        }

        setCacheItems(cache, items, idPropertyName, pad);
        dispatch({
            type: actionTypes.UPDATE_CACHE_STORE,
            payload: [
                ...(itemGetPropertyName ? items.flatMap((x) => x[itemGetPropertyName].map((y) => ({ ...y, query: x.query }))) : items),
                ...(itemGetPropertyName ? cached.flatMap((x) => x[itemGetPropertyName].map((y) => ({ ...y, query: x.query }))) : cached),
            ],
        });
    };

export const actions = {
    getCached,
};
