import contractStatuses from "resource/contractStatuses";
import { diffCustom } from "utils/objectCompare/diffCustom";
import _ from "lodash";
import { createSelector } from "reselect";

const rootSelector = (state) => state.vendorPms;

const getDefaultArray = (val) => (val ? val : []);

export const contractSelector = (state) => {
    const { contract } = state;
    const { contractStatus, vendorProducts, proposal = {}, current = {}, contractId } = contract;
    const { previousDocumentId } = proposal;
    const hasPendingChanges =
        contractStatuses.isDecisionRequired(contractStatus) ||
        (contractStatuses.isStatusToShowDiff(contractStatus) && !_.isEmpty(previousDocumentId));
    const lastChangedBy = contract.proposal?.lastChangedBy ?? contract.current?.lastChangedBy;
    const decision = contract.proposal?.decision ?? contract.current?.decision;

    const mapped = {
        id: contractId,
        ...contract,
        proposal: {
            ...contract.proposal,
            contractFiles: contract.proposal?.contractFiles?.map((x) => ({
                ...x,
                documentType: { id: x.documentTypeId },
                documentCategory: { id: x.categoryId },
            })),
        },
        current: {
            ...contract.current,
            contractFiles: contract.current?.contractFiles?.map((x) => ({
                ...x,
                documentType: { id: x.documentTypeId },
                documentCategory: { id: x.categoryId },
            })),
        },
    };

    const allProducts = _([
        ...getDefaultArray(_.flatten(mapped.current?.frontDiscounts?.map((d) => d.products))),
        ...getDefaultArray(_.flatten(mapped.proposal?.frontDiscounts?.map((d) => d.products))),
        ...getDefaultArray(_.flatten(mapped.current?.retroUnconditionalDiscounts?.map((d) => d.products))),
        ...getDefaultArray(_.flatten(mapped.proposal?.retroUnconditionalDiscounts?.map((d) => d.products))),
        ...getDefaultArray(_.flatten(mapped.current?.retroConditionalDiscounts?.map((d) => d.products))),
        ...getDefaultArray(_.flatten(mapped.proposal?.retroConditionalDiscounts?.map((d) => d.products))),
        ...getDefaultArray(_.flatten(mapped.current?.marketingBudgets?.map((d) => d.products))),
        ...getDefaultArray(_.flatten(mapped.proposal?.marketingBudgets?.map((d) => d.products))),
        ...getDefaultArray(_.flatten(mapped.current?.productDiscounts?.map((d) => d.products))),
        ...getDefaultArray(_.flatten(mapped.proposal?.productDiscounts?.map((d) => d.products))),
    ])
        .uniq()
        .value();

    if (hasPendingChanges) {
        const result = {
            ...mapped,
            ...diffCustom(mapped.current, mapped.proposal, {
                frontDiscounts: {
                    findFunc: (a, b) => a?.frontDiscountId === b?.frontDiscountId,
                },
                productDiscounts: {
                    findFunc: (a, b) => a?.productDiscountId === b?.productDiscountId,
                },
                retroConditionalDiscounts: {
                    findFunc: (a, b) => a?.retroConditionalDiscountId === b?.retroConditionalDiscountId,
                },
                retroUnconditionalDiscounts: {
                    findFunc: (a, b) => a?.retroUnconditionalDiscountId === b?.retroUnconditionalDiscountId,
                },
                marketingBudgets: {
                    findFunc: (a, b) => a?.marketingBudgetId === b?.marketingBudgetId,
                },
                contractFiles: {
                    findFunc: (a, b) => a?.fileId === b?.fileId,
                    equalityProperties: ["fileName", "fileId"],
                },
                brands: {
                    findFunc: (a, b) => a === b,
                },
                billingPeriodThresholds: {
                    findFunc: (a, b) => a?.periodName === b?.periodName && a?.threshold === b?.threshold,
                },
            }),
            allProducts,
            lastChangedBy,
            decision,
            dateTo: proposal ? proposal.dateTo : current?.dateTo,
            dateFrom: proposal ? proposal.dateFrom : current?.dateFrom,
            isAnnex: proposal ? proposal.isAnnex : current?.isAnnex,
        };

        return result;
    }

    return {
        ...mapped,
        hasPendingChanges,
        vendorProducts,
        allProducts,
        lastChangedBy,
    };
};

export const contractsSelector = createSelector(rootSelector, (vendor) => {
    return {
        contracts: vendor?.contracts.map(({ current, proposal, ...rest }) => {
            if (proposal) {
                return { ...rest, ...proposal };
            } else {
                return { ...rest, ...current };
            }
        }),
    };
});

export const hasPendingDraftContractSelector = createSelector(rootSelector, (vendor) => {
    return {
        hasPendingDraftContract: vendor?.hasPendingDraftContract,
    };
});
