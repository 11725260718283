import { createSelector } from "reselect";

const root = (state) => ({
    customerMembership: state.customerMembership,
});

export const customerMembershipSelector = createSelector(root, (root) => {
    return root.customerMembership.form;
});

export const customerMembershipPointsSelector = createSelector(root, (root) => {
    return root.customerMembership.points;
});
