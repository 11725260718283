import React from "react";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import { B2BDepartment, ECommerceDepartment, MarketingDepartment, Role, SalesDepartment, TradeMarketingDepartment } from "../roles";

const CampaignsForm = React.lazy(() =>
    import("views-ts/campaings").then((module) => ({
        default: module.CampaignsForm,
    }))
);
const CampaingsList = React.lazy(() =>
    import("views-ts/campaings").then((module) => ({
        default: module.CampaignsList,
    }))
);

const roles: Role[] = [
    "Analyst",
    "Management",
    ...B2BDepartment.GetRoles(),
    ...SalesDepartment.GetRoles(),
    ...ECommerceDepartment.GetRoles(),
    ...MarketingDepartment.GetRoles(),
    ...TradeMarketingDepartment.GetRoles(),
];

export const Campaings: PrivateRoute[] = [
    new PrivateRoute(Paths.CampaingsList, roles, CampaingsList, "campaigns", "speaker"),
    new PrivateRoute(Paths.CampaignForm, roles, CampaignsForm),
];
