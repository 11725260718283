import { makeStyles } from "tss-react/mui";
import { useState } from "react";
import { Divider, List } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { useUser } from "context";
import { signinCallbackKey } from "utils-ts/auth/userManager";
import NestedNavigationItem from "./NestedNavigationItem";
import SingleNavigationItem from "./SingleNavigationItem";

const useStyles = makeStyles()({
    divider: {
        background: alpha("#eeeeee", 0.1),
    },
    sidebarWrapper: {
        position: "relative",
        height: "calc(100vh - 75px)",
        overflow: "auto",
        width: "240px",
        zIndex: "4",
        overflowScrolling: "touch",
    },
});

const NavigationList = ({ routes }) => {
    const { classes } = useStyles();
    const { isInAnyRoleOrAdmin } = useUser();
    const [collapse, setCollapse] = useState([]);
    const handleClick = (index) => {
        const newItemState = !collapse[index];
        const state = [...collapse];
        state[index] = newItemState;
        setCollapse(state);
    };

    const handleNavigate = () => {
        setCollapse([]);
    };

    return (
        <div className={classes.sidebarWrapper}>
            <List
                dense
                disablePadding
            >
                {routes
                    .filter((navigationItem) => {
                        return isInAnyRoleOrAdmin(navigationItem?.roles || []) && localStorage.getItem(signinCallbackKey) === null;
                    })
                    .map((navigationItem, key) => {
                        return (
                            <div key={navigationItem.path}>
                                {!navigationItem.items ? (
                                    <SingleNavigationItem
                                        navigationItem={navigationItem}
                                        onNavigate={handleNavigate}
                                        onInit={navigationItem.onInit}
                                    />
                                ) : (
                                    <NestedNavigationItem
                                        key={key}
                                        navigationItem={navigationItem}
                                        open={collapse[key]}
                                        handleClick={() => handleClick(key)}
                                        onNavigate={handleNavigate}
                                    />
                                )}
                                <Divider
                                    light
                                    className={classes.divider}
                                    variant="middle"
                                    component="li"
                                />
                            </div>
                        );
                    })}
            </List>
        </div>
    );
};

export default NavigationList;
