import defaultListState from "store/defaultListState";
import { combineReducers } from "redux";
import types from "./types";

const initialState = {
    importVatCorrections: {
        isProcessing: false,
        hasErrors: undefined,
    },
    vatCorrectionsBatches: {
        ...defaultListState,
        isDownloading: false,
    },
    vatCorrectionBatch: {
        isLoading: false,
        isRetrying: false,
    },
};

const importVatCorrections = (state = initialState.importVatCorrections, { type, payload }) => {
    switch (type) {
        case types.importVatCorrections.request:
            return {
                ...state,
                isProcessing: true,
            };
        case types.importVatCorrections.success:
            return {
                ...initialState.importVatCorrections,
                hasErrors: false,
                isProcessing: false,
            };
        case types.importVatCorrections.failure:
            return {
                ...payload.response,
                isProcessing: false,
            };
        case types.validateVatCorrections.request:
            return {
                ...state,
                isProcessing: true,
            };
        case types.validateVatCorrections.success:
            return {
                ...payload,
                isProcessing: false,
            };
        case types.validateVatCorrections.failure:
            return {
                ...state,
                isProcessing: false,
            };
        case types.resetImportVatCorrections:
            return {
                ...initialState.importVatCorrections,
            };
        default:
            return state;
    }
};

const reduceCorrectionCount = (corrections = []) =>
    corrections.reduce(
        (c, n) => ({
            correctionCount: c.correctionCount + 1,
            openCount: c.openCount + (n.status === "Pending" ? 1 : 0),
            errorCount: c.errorCount + (n.status === "Error" ? 1 : 0),
            correctedCount: c.correctedCount + (n.status === "Corrected" ? 1 : 0),
        }),
        {
            correctionCount: 0,
            openCount: 0,
            errorCount: 0,
            correctedCount: 0,
        }
    );

const vatCorrectionsBatches = (state = initialState.vatCorrectionsBatches, { type, payload }) => {
    switch (type) {
        case types.findVatCorrectionBatches.request:
            return { ...state, isLoading: true };
        case types.findVatCorrectionBatches.success:
            return {
                ...state,
                ...payload,
                items: payload.items.map((o) => ({
                    ...o,
                    ...reduceCorrectionCount(o.corrections),
                })),
                isLoading: false,
            };
        case types.findVatCorrectionBatches.failure:
            return { ...state, isLoading: false };
        case types.updatePagination:
            return { ...state, ...payload };
        case types.getBatchFile.request:
            return { ...state, isDownloading: true };
        case types.getBatchFile.success:
            return { ...state, isDownloading: false };
        case types.getBatchFile.failure:
            return { ...state, isDownloading: false };
        default:
            return state;
    }
};

const vatCorrectionsBatch = (state = initialState.vatCorrectionBatch, { type, payload }) => {
    switch (type) {
        case types.getVatCorrectionBatch.request:
            return { ...state, isLoading: true };
        case types.getVatCorrectionBatch.success:
            return { ...state, ...payload, isLoading: false };
        case types.getVatCorrectionBatch.failure:
            return { ...state, isLoading: false };
        case types.resetVatCorrectionBatch:
            return { ...initialState.vatCorrectionBatch };
        case types.retryComplaint.request:
            return { ...state, isRetrying: true };
        case types.retryComplaint.success:
            return { ...state, isRetrying: false };
        case types.retryComplaint.failure:
            return { ...state, isRetrying: false };
        default:
            return state;
    }
};

export const vatCorrections = combineReducers({
    importVatCorrections,
    vatCorrectionsBatches,
    vatCorrectionsBatch,
});
