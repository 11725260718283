const commerceProductProblems = {
    ExternalStockUnusedBecauseExternalProductNotMapped: "ExternalStockUnusedBecauseExternalProductNotMapped",
    ExternalStockUnusedBecauseExternalProductMappedToMultipleProducts: "ExternalStockUnusedBecauseExternalProductMappedToMultipleProducts",
    ExternalStockUnusedBecauseProductMappedFromMultipleExternalProducts: "ExternalStockUnusedBecauseProductMappedFromMultipleExternalProducts",
    IsAlcoholBecauseAlcoholByVolumeValue: "IsAlcoholBecauseAlcoholByVolumeValue",
    IsPromotionOnlyBecauseNoPriceSet: "IsPromotionOnlyBecauseNoPriceSet",
    IsWithdrawnBecauseStockNotFound: "IsWithdrawnBecauseStockNotFound",
    IsWithdrawnBecauseStockDeleted: "IsWithdrawnBecauseStockDeleted",
    IsWithdrawnBecauseStockSettings: "IsWithdrawnBecauseStockSettings",
    IsWithdrawnBecauseMerchantNotAllowingAlcohol: "IsWithdrawnBecauseMerchantNotAllowingAlcohol",
    IsWithdrawnBecauseMultipackWithoutPrimaryVariant: "IsWithdrawnBecauseMultipackWithoutPrimaryVariant",
    IsWithdrawnBecauseUnavailableAndNoRecentSales: "IsWithdrawnBecauseUnavailableAndNoRecentSales",
    IsWithdrawnBecauseRationingDisallowsMultipacksForNonMultipackOnlyProducts:
        "IsWithdrawnBecauseRationingDisallowsMultipacksForNonMultipackOnlyProducts",
    PrincipalWarehouseMissing: "PrincipalWarehouseMissing",
    IsWithdrawnBecauseSubstitutableCountryMissing: "IsWithdrawnBecauseSubstitutableCountryMissing",
    IsWithdrawnBecauseStorageTypeMissing: "IsWithdrawnBecauseStorageTypeMissing",
    IsWithdrawnBecauseMerchantNotAllowingTobacco: "IsWithdrawnBecauseMerchantNotAllowingTobacco",
    IsWithdrawnBecauseFrozenAndDependentWarehouse: "IsWithdrawnBecauseFrozenAndDependentWarehouse",
    IsWithdrawnBecauseNotStockedAndSupplierIdMissing: "IsWithdrawnBecauseNotStockedAndSupplierIdMissing",
    IsWithdrawnBecauseSubstitutedProductsHaveAvailability: "IsWithdrawnBecauseSubstitutedProductsHaveAvailability",
} as const;

export default commerceProductProblems;
