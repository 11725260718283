import Button from "components/Button/Button";
import { makeStyles } from "tss-react/mui";
import { useCommonTranslation } from "utils/hooks";
import { Grid } from "@mui/material";

const packaingProductPlastic = {
    quantity: 1,
    productId: "UP0001",
    fullName: "Opłata za pakowanie (w tym opłata recyclingowa)",
    description: "Opłata za pakowanie (w tym opłata recyclingowa)",
    ean: null,
    packSize: 1,
    grammage: null,
    producer: null,
    name: "Opłata za pakowanie (w tym opłata recyclingowa)",
    isAvailable: true,
    isWithdrawn: false,
    isStocked: true,
    vat: 23,
    grossPrice: 4.0,
    storageType: "Normal",
    deliveryMethods: ["Van", "DPD", "Coolomat"],
    merchant: "FRS",
    upsellType: null,
};

const packaingProductPaper = {
    quantity: 1,
    productId: "UP0002",
    fullName: "Koszt pakowania - ECO",
    description: "Koszt pakowania - ECO",
    ean: null,
    packSize: 1,
    grammage: null,
    producer: null,
    name: "Koszt pakowania - ECO",
    isAvailable: true,
    isWithdrawn: false,
    isStocked: true,
    vat: 23,
    grossPrice: 2.0,
    storageType: "Normal",
    deliveryMethods: ["Van", "DPD", "Coolomat"],
    merchant: "FRS",
    upsellType: null,
};

const deliveryProduct = {
    quantity: 1,
    productId: "UT0001",
    fullName: "Koszt transportu - VAN",
    description: "Koszt transportu - VAN",
    ean: null,
    packSize: 1,
    grammage: null,
    producer: null,
    name: "Koszt dostawy VAN",
    isAvailable: true,
    isWithdrawn: false,
    isStocked: true,
    vat: 23,
    grossPrice: 10.0,
    storageType: "Normal",
    deliveryMethods: ["Van", "DPD", "Coolomat"],
    merchant: "FRS",
    upsellType: null,
};

const useStyles = makeStyles()((theme) => ({
    button: {
        padding: theme.spacing(1),
    },
}));

const UpsellActions = ({ editionDisabled, handleDialogOpen, pushUpsellProduct }) => {
    const { t, common } = useCommonTranslation();
    const { classes } = useStyles();
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="baseline"
        >
            <Grid item>
                <Button
                    key="packaging"
                    style={{
                        display: editionDisabled ? "none" : "inherit",
                    }}
                    className={classes.button}
                    color="primary"
                    onClick={() => {
                        pushUpsellProduct("upsellLines", {
                            ...packaingProductPaper,
                        });
                    }}
                >
                    {t(common.addPaperPacking)}
                </Button>
            </Grid>

            <Grid item>
                <Button
                    key="packaging"
                    style={{
                        display: editionDisabled ? "none" : "inherit",
                    }}
                    className={classes.button}
                    color="primary"
                    onClick={() => {
                        pushUpsellProduct("upsellLines", {
                            ...packaingProductPlastic,
                        });
                    }}
                >
                    {t(common.addPlasicPacking)}
                </Button>
            </Grid>

            <Grid item>
                <Button
                    key="delivery"
                    style={{
                        display: editionDisabled ? "none" : "inherit",
                    }}
                    className={classes.button}
                    color="primary"
                    onClick={() => {
                        pushUpsellProduct("upsellLines", {
                            ...deliveryProduct,
                        });
                    }}
                >
                    {t(common.deliveryCost)}
                </Button>
            </Grid>

            <Grid item>
                <Button
                    key="add"
                    style={{
                        display: editionDisabled ? "none" : "inherit",
                    }}
                    className={classes.button}
                    color="primary"
                    onClick={handleDialogOpen}
                >
                    {t(common.addProduct)}
                </Button>
            </Grid>
        </Grid>
    );
};

export default UpsellActions;
