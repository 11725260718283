import { FormField } from "components/Form";
import { productsTagsSelector } from "store/autocomplete";
import { orderBy } from "utils/extensions/arrayExtensions";
import { useCommonTranslation } from "utils/hooks";
import React from "react";
import { useSelector } from "react-redux";

const ProductsTagsAutocomplete = ({ validate, name = "tags", readOnly = false }) => {
    const { t, common } = useCommonTranslation();
    const productsTags = useSelector(productsTagsSelector);

    return (
        <FormField
            name={name}
            label={t(common.tags)}
            type={"autocomplete"}
            items={orderBy(productsTags)}
            multiple
            stretch
            disableCloseOnSelect
            validate={validate}
            readOnly={readOnly}
        />
    );
};

export default ProductsTagsAutocomplete;
