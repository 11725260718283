import { manager, operations } from "store/actionTypePrefixes";
import { AsyncTypes, createAction } from "utils/extensions";

const types = {
    findContractsForApproval: new AsyncTypes("FIND_CONTRACTS_FOR_APPROVAL", operations),
    changeContractsPagination: createAction("CHANGE_CONTRACT_DECISIONS_PAGINATION", manager),
    findSubsidiaryGainForApproval: new AsyncTypes("FIND_SG_FOR_APPROVAL", operations),
    changeSubsidiaryGainPagination: createAction("CHANGE_SG_DECISIONS_PAGINATION", manager),
    findRefundationsForApproval: new AsyncTypes("FIND_REFUNDATIONS_FOR_APPROVAL", operations),
    changeRefundationsPagination: createAction("CHANGE_REFUNDATIONS_DECISIONS_PAGINATION", manager),
};

export default types;
