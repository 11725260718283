import React from "react";
import { DialogContent } from "@mui/material";
import PropTypes from "prop-types";
import Dialog from "./Dialog";

const InfoDialog = ({ children, onClose, ...rest }) => {
    return (
        <Dialog
            {...rest}
            onClose={onClose}
        >
            <DialogContent>{children}</DialogContent>
        </Dialog>
    );
};

Dialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    maxWidth: PropTypes.string,
    title: PropTypes.string,
};

export default InfoDialog;
