import { combineReducers } from "redux";
import { document } from "./document";
import { add } from "./documentArchiveAdd";
import { search } from "./documentArchiveSearch";
import { userPermissions } from "./userPermissions";

export const documentArchive = combineReducers({
    document,
    search,
    add,
    userPermissions,
});
