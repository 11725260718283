import Button from "components/Button/Button";
import { renderFieldOrText } from "components/FormHelpers/Controls";
import GridContainer from "components/Grid/GridContainer";
import { Field, reduxForm } from "redux-form";
import { createMemo, updateMemo } from "store/customers/customer/customerMemos";
import { useCommonTranslation } from "utils/hooks";
import { notEmptyValidator, stringLength } from "utils/validators/basic";
import { connect, useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { tValidation, validation } from "utils-ts/validations/translation";

const MemoForm = ({ isRequestInProgress, customerId, handleSubmit, reset, submitting, pristine, valid, id, handleClose, updateMemosList }) => {
    const dispatch = useDispatch();
    const { t, common } = useCommonTranslation();
    const fields = [
        { label: common.isImportant, name: "isImportant", type: "boolean", size: 2 },
        { label: common.comment, name: "text", type: "text", size: 8 },
    ].map((field) => ({ ...field, label: t(field.label) }));

    return (
        <form
            onSubmit={handleSubmit(async (val) => {
                if (id) {
                    await dispatch(updateMemo(customerId, id, val));
                    handleClose();
                    updateMemosList();
                } else {
                    await dispatch(createMemo(customerId, val));
                    reset();
                    updateMemosList();
                }
            })}
        >
            <GridContainer
                iconName="comment"
                title={common.comment}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                {fields.map(({ type, label, name, size }) => {
                    return (
                        <Grid
                            key={name}
                            item
                            xs={size}
                        >
                            <Field
                                shrink={false}
                                multiline
                                fullWidth
                                type={type}
                                label={label}
                                name={name}
                                component={renderFieldOrText}
                            />
                        </Grid>
                    );
                })}
                <Grid
                    item
                    xs={2}
                >
                    <Button
                        actionType="accept"
                        fullWidth
                        type={"submit"}
                        disabled={submitting || !valid || isRequestInProgress || pristine}
                        loading={isRequestInProgress}
                    >
                        {t(common.submit)}
                    </Button>
                </Grid>
            </GridContainer>
        </form>
    );
};

const validate = (values) => {
    const errors = {};
    errors.text = notEmptyValidator(values.text)
        .must(stringLength, tValidation(validation.stringLength, { min: 1, max: 1000 }))
        .validate();
    return errors;
};

const mapStateToProps = (state, ownProps) => {
    const {
        customer: {
            customerMemos: {
                memo: { isRequestInProgress },
            },
        },
    } = state;
    const { customerId, isImportant, text, id } = ownProps;

    return {
        id,
        isRequestInProgress,
        initialValues: {
            userId: customerId,
            isActive: true,
            isImportant,
            text: text,
        },
    };
};

export const MemoCreateForm = connect(mapStateToProps)(reduxForm({ form: "memoFormCreate", enableReinitialize: true, validate })(MemoForm));
export const MemoEditForm = connect(mapStateToProps)(reduxForm({ form: "memoFormEdit", enableReinitialize: true, validate })(MemoForm));
