import React from "react";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import { Role } from "../roles";

const AcceptSubsidiaryGainForm = React.lazy(() =>
    import("views/externalUsers/subsidiaryGains").then((module) => ({
        default: module.AcceptSubsidiaryGainForm,
    }))
);
const SubsidiaryGainsList = React.lazy(() =>
    import("views/externalUsers/subsidiaryGains").then((module) => ({
        default: module.SubsidiaryGainsList,
    }))
);

const roles: Role[] = ["ExternalUser"];

export const ExternalUsers: PrivateRoute[] = [
    new PrivateRoute(Paths.ExternalUsersSubsidiaryGainsList, roles, SubsidiaryGainsList, "subsidiaryGains", "find_in_page"),
    new PrivateRoute(Paths.ExternalUsersSubsidiaryGainForm, roles, AcceptSubsidiaryGainForm),
];
