import SimpleTable, { createColumn } from "components/Table/SimpleTable/SimpleTable";
import { userInfoWithRoleSelector } from "store/autocomplete";
import { marketingBudgetsFormSelector } from "store/vendors/marketingBudget";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useSelector } from "react-redux";
import { common } from "translations";
import { useTranslation } from "utils-ts/hooks";

const CatmanBudgetChanges = () => {
    const { t } = useTranslation("common");
    const { catmansBudgetAudit = [] } = useSelector(marketingBudgetsFormSelector);
    const users = useSelector(userInfoWithRoleSelector) || [];
    const allUsers = [
        ...(users?.Catman || []),
        ...(users?.TradeMarketing || []),
        ...(users?.TradeMarketingManager || []),
        ...(users?.Administrator || []),
    ];
    const catmansBudgetAuditItems = catmansBudgetAudit.map((c) => {
        return {
            ...c,
            catmanBudget: {
                ...c.catmanBudget,
                catman: {
                    userId: c.catmanBudget.catman,
                    userEmail: allUsers.find((u) => u.userId == c.catmanBudget.catman)?.email,
                },
            },
            userEmail: allUsers.find((u) => u.userId == c.userId)?.email,
        };
    });

    const columns = [
        createColumn("changeTime", t(common.changeTime), formatTypes.time, {
            timeFormat: timePrecisonFormats.minutes,
        }),
        createColumn("catmanBudget.catman.userEmail", t(common.catman), formatTypes.text),
        createColumn("catmanBudget.budget", t(common.amount), formatTypes.currency),
        createColumn("comment", t(common.reason), formatTypes.text),
        createColumn("userEmail", t(common.responsibleUser), formatTypes.text),
    ];

    return (
        <SimpleTable
            columns={columns}
            data={catmansBudgetAuditItems}
            stripped
            hover
        />
    );
};

export default CatmanBudgetChanges;
