import { Link as ReactLink } from "react-router-dom";
import { Button, Typography } from "@mui/material";

const Link = ({ handleClick, text, disabled, openInNewTab }) => {
    return (
        <>
            {openInNewTab ? (
                <ReactLink
                    to={handleClick()}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Typography
                        variant="body2"
                        style={{ fontWeight: 500 }}
                    >
                        {text}
                    </Typography>
                </ReactLink>
            ) : (
                <Button
                    variant="text"
                    color="secondary"
                    onClick={handleClick}
                    disabled={disabled}
                >
                    {text}
                </Button>
            )}
        </>
    );
};

export default Link;
