import { Button } from "components/Button";
import { CartPredicate, ContentData, DivisionSelect, ProductListField } from "components/Controls";
import { AcceptRejectDialog, useModalState } from "components/Dialog";
import { CommerceForm, FormField } from "components/Form";
import Layout from "components/Grid/Layout";
import { reduxForm } from "redux-form";
import { actions as productActions } from "store/cache/products/actions";
import { actions } from "store/cms/contests/form/actions";
import { toObject } from "utils/extensions/arrayExtensions";
import { mapRequirements } from "utils/formExtensions";
import { useChange, useCommonTranslation, useCopyRouterState, useFormValueSelector } from "utils/hooks";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { usePush } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { contestsFormSelector } from "../selectors";
import { validators } from "../validators";

const mapValues = (values) => {
    const { contentData, products, sections: _, generatorId, requirements = {}, ...rest } = values;
    const mapProducts = (ids) => (ids && ids.length > 0 ? ids.map((id) => ({ productId: id })) : null);

    return {
        ...rest,
        hasAnswers: Boolean(rest.hasAnswers),
        generatorId: generatorId === "" ? null : generatorId,
        contentData: contentData ? toObject(contentData) : null,
        requirements: mapRequirements(requirements),
        products: mapProducts(products),
    };
};

const formName = "contest-form";

const ContestForm = ({ handleSubmit, products, isSubbmitRequested, isSystem, contestName }) => {
    const { handleClose, handleOpen, modalState } = useModalState();
    const dispatch = useDispatch();
    const { t, common } = useCommonTranslation();
    const { id, isCopy } = useCopyRouterState();
    const { replace } = usePush();
    const change = useChange(formName);
    const divisions = useFormValueSelector(formName, "divisions", []);

    useEffect(() => {
        dispatch(actions.initForm(id, isCopy));
    }, [dispatch, id, isCopy]);
    useEffect(() => {
        if (products && products.length > 0) {
            dispatch(productActions.getProducts(products));
        }
    }, [dispatch, products]);

    return (
        <CommerceForm
            onSubmit={handleSubmit(async (values) => {
                const { payload, error } = await dispatch(actions.createOrUpdate(mapValues(values), !isCopy && id));

                if (!error && ((!isCopy && !id) || isCopy)) {
                    replace(Paths.Cms.ContestForm, { id: payload.id });
                }
            })}
            isReadonly={isSystem && !isCopy}
            isSubbmitRequested={isSubbmitRequested}
        >
            <Layout
                main
                headerText={t(common.contests, {
                    id: !isCopy && id ? id : "",
                })}
                navigationProps={{
                    backProps: { path: "/cms/contests" },
                }}
                customTitle={id && contestName && `${t(common.contests)} - ${contestName}`}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <FormField
                        name={"contestName"}
                        type={"text"}
                        label={t(common.name)}
                        validate={validators.contestName}
                    />
                    <DivisionSelect selectedDivisions={divisions} />
                    <FormField
                        name={"isActive"}
                        type={"boolean"}
                        label={t(common.isActive)}
                    />
                    <FormField
                        name={"hasAnswers"}
                        type={"boolean"}
                        label={t(common.hasAnswers)}
                        validate={validators.hasAnswers}
                    />
                    <FormField
                        name={"hasAttachments"}
                        type={"boolean"}
                        label={t(common.hasAttachments)}
                        validate={validators.hasAttachments}
                    />
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <FormField
                        name={"validFrom"}
                        type={"dateTime"}
                        label={t(common.validFrom)}
                        validate={validators.validFrom}
                    />

                    <FormField
                        name={"validTo"}
                        type={"dateTime"}
                        label={t(common.validTo)}
                        validate={validators.validTo}
                    />
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <FormField
                        name={"requiredQuantity"}
                        type={"number"}
                        label={t(common.requiredQuantity)}
                        validate={validators.nullOrGreaterThan0}
                    />
                    <FormField
                        name={"requiredValue"}
                        type={"decimal"}
                        label={t(common.requiredValue)}
                        validate={validators.nullOrGreaterThan0}
                    />
                    <FormField
                        name={"generatorId"}
                        type={"text"}
                        label={t(common.generatorId)}
                        validate={validators.generatorId}
                    />
                </Grid>
                <CartPredicate name="requirements" />
                <Grid
                    item
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-end"
                >
                    <Grid item>
                        <Button
                            actionType="decline"
                            onClick={handleOpen}
                        >
                            {t(common.clearProductList)}
                        </Button>
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item>
                        <ProductListField validate={validators.products} />
                    </Grid>
                </Grid>
            </Layout>

            <ContentData />

            <AcceptRejectDialog
                maxWidth={"sm"}
                title={t(common.removeItem)}
                open={modalState}
                handleAccept={async () => {
                    change("products", null);
                    handleClose();
                }}
                handleReject={handleClose}
            >
                {t(common.doYouWantToClearProductList)}
            </AcceptRejectDialog>
        </CommerceForm>
    );
};

const stateToProps = (state) => {
    const { isSubbmitRequested, isSystem, contestName, ...form } = contestsFormSelector(state);
    const products = form.products;

    return {
        isSystem,
        isSubbmitRequested,
        products,
        contestName: contestName,
        initialValues: {
            contestName: contestName,
            ...form,
        },
    };
};

export default connect(stateToProps)(
    reduxForm({
        form: formName,
        enableReinitialize: true,
    })(ContestForm)
);
