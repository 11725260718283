import { Button } from "components/Button";
import { AccountantDepartmentRoles, SalesDepartmentRoles } from "consts/roles";
import { initForm, requestInvoiceRetry } from "store/vendors/subsidiaryGains";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useUser } from "context";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";

const RequestInvoiceRetry = ({ canRequestInvoiceRetry }) => {
    const [isRequesting, setIsRequesting] = useState(false);
    const { vendorId, subsidiaryGainId } = useParams();
    const { isInAnyRoleOrAdmin } = useUser();
    const { t } = useTranslation("vendor");
    const dispatch = useDispatch();

    return canRequestInvoiceRetry && isInAnyRoleOrAdmin([...AccountantDepartmentRoles, ...SalesDepartmentRoles]) ? (
        <Button
            actionType="primary"
            type="button"
            fullWidth
            loading={isRequesting}
            onClick={async () => {
                setIsRequesting(true);
                await dispatch(requestInvoiceRetry(vendorId, subsidiaryGainId));

                dispatch(initForm(vendorId, subsidiaryGainId));
            }}
        >
            {t(vendor.requestInvoiceRetry)}
        </Button>
    ) : (
        <></>
    );
};

export default RequestInvoiceRetry;
