import { GetRequest, PostRequest, PutRequest, get, post, put } from "utils/extensions";
import types from "./types";

const endpoint = (id) => `api/procedure/category${id ? `/${id}` : ""}`;

const initForm = (id) => async (dispatch) => {
    await dispatch({ type: types.formInit });

    if (id) {
        return dispatch(get(new GetRequest(endpoint(id), types.getProcedureCategory.all)));
    }
};

const createOrUpdate = (values, id) => async (dispatch) => {
    if (!id) {
        return await dispatch(
            post(
                new PostRequest(endpoint(id), types.createOrUpdate.all, {
                    body: values,
                })
            )
        );
    } else {
        return await dispatch(
            put(
                new PutRequest(endpoint(id), types.createOrUpdate.all, {
                    body: values,
                })
            )
        );
    }
};

export const actions = {
    initForm,
    createOrUpdate,
};
