import { GetRequest, PutRequest, get, put } from "utils/extensions";
import types from "./types";

const endpoint = (employeeId) => `api/employees${employeeId ? `/${employeeId}` : ""}`;

export const getUserReplacements = (employeeId) => async (dispatch) => {
    dispatch(
        get(
            new GetRequest(endpoint(employeeId), [
                types.getUserReplacements.request,
                types.getUserReplacements.success,
                {
                    type: types.getUserReplacements.failure,
                    meta: { hideError: true },
                },
            ])
        )
    );
};

export const createOrUpdateUserReplacements = (employeeId, values) => (dispatch) => {
    dispatch(
        put(
            new PutRequest(endpoint(employeeId) + "/replacements", types.createOrUpdate.all, {
                body: values || {},
            })
        )
    );
};
