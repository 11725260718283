import { withStyles } from "tss-react/mui";
import { pipe } from "utils/functional";
import React, { Fragment, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Tab as MUITab, Tabs as TabsCore } from "@mui/material/";
import { useUser } from "context";
import { isNumber } from "lodash";
import PropTypes from "prop-types";
import TabLabel from "./TabLabel";

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        minHeight: "640px",
        maxWidth: "100%",
        backgroundColor: theme.palette.background.paper,
    },
    container: {
        marginTop: theme.spacing(1),
    },
    tabItems: {
        marginTop: theme.spacing(1),
        minHeight: "640px",
        overflow: "hidden",
        position: "relative",
    },
    tabs: {
        minWidth: "160px",
    },
});

const Tabs = ({ classes, tableLabels, actionComponent, children, t, labelsAsComponents, eager, tabIndex }) => {
    const [index, setIndex] = React.useState(0);
    const handleIndexChange = (_, index) => {
        setIndex(index);
    };

    let showChildrenIndexes = Array.from((Array.isArray(children) ? children : [children]).keys());
    const { isInAnyRoleOrAdmin } = useUser();
    const tabs = tableLabels.every((x) => !(x instanceof TabLabel))
        ? labelsAsComponents
            ? pipe((source) =>
                  React.Children.map(source, (label) => (
                      <MUITab
                          key={label}
                          label={label}
                          className={classes.tabs}
                      />
                  ))
              )(tableLabels)
            : tableLabels.map(t).map((l) => (
                  <MUITab
                      key={l}
                      label={l}
                      className={classes.tabs}
                  />
              ))
        : tableLabels
              .map((x, index) => {
                  const { roles = [] } = x;
                  if (isInAnyRoleOrAdmin(roles)) {
                      return (
                          <MUITab
                              key={index}
                              className={classes.tabs}
                              {...x}
                          />
                      );
                  } else {
                      showChildrenIndexes[index] = undefined;
                      return undefined;
                  }
              })
              .filter(Boolean);

    showChildrenIndexes = showChildrenIndexes.filter(isNumber);
    useEffect(() => {
        if (isNumber(tabIndex)) {
            setIndex(showChildrenIndexes.indexOf(tabIndex));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabIndex]);

    const tabItems = React.Children.map(showChildrenIndexes, (_, i) => {
        return i === index || eager ? (
            <div
                className={classes.container}
                style={{
                    display: i === index ? undefined : "none",
                }}
            >
                {children[showChildrenIndexes[i]]}
            </div>
        ) : (
            <Fragment />
        );
    });

    return (
        <div className={classes.container}>
            <div className={classes.container}>{actionComponent ? actionComponent : <Fragment />}</div>
            <div className={classes.container}>
                <TabsCore
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    value={index}
                    onChange={handleIndexChange}
                >
                    {tabs}
                </TabsCore>
            </div>

            {tabItems ? tabItems.map((t, i) => <div key={i}> {t}</div>) : <Fragment />}
        </div>
    );
};

Tabs.propTypes = {
    classes: PropTypes.object.isRequired,
    tableLabels: PropTypes.array.isRequired,
};

export default withTranslation()(withStyles(Tabs, styles));
