import { ContentData, DivisionSelect } from "components/Controls";
import { CommerceForm, FormField } from "components/Form";
import { integerNormalize } from "components/FormHelpers/ControlFormaters";
import Layout from "components/Grid/Layout";
import { FieldArray, reduxForm } from "redux-form";
import { usePromotionFormAutocompleteEffect } from "store/autocomplete";
import { createOrUpdateReward, getRewardReservationCount, initRewardForm } from "store/offers/rewards/form/actions";
import { rewardsFormSelector } from "store/offers/selectors";
import { toObject } from "utils/extensions";
import { mapRequirements, mapVoucherDefinition } from "utils/formExtensions";
import { useCommonTranslation, useCopyRouterState, useFormValueSelector } from "utils/hooks";
import Validator, { notEmptyArrayValidator, notEmptyValidator } from "utils/validators/basic";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { tValidation, validation } from "utils-ts/validations/translation";
import { Paths } from "routing-ts/ManagerPaths";
import { GiftChoiceActivation } from "../giftChoices/components";
import { validators } from "../validators";
import RewardDefinition from "./components/RewardDefinition";
import WarehouseReservationLimits from "./components/WarehouseReservationLimits";

const formName = "rewards-form";

const RewardsForm = ({ handleSubmit, isSubbmitRequested, isSystem, activationInfo, name }) => {
    usePromotionFormAutocompleteEffect();
    const { id, isCopy } = useCopyRouterState();
    const dispatch = useDispatch();
    const { t, common } = useCommonTranslation();
    const readOnly = isSystem && !isCopy;
    const divisions = useFormValueSelector(formName, "divisions", []);

    useEffect(() => {
        dispatch(initRewardForm(id, isCopy));
        if (id && !isCopy) {
            dispatch(getRewardReservationCount(id));
        }
    }, [dispatch, id, isCopy]);

    const submit = async (values) => {
        const { payload } = await dispatch(createOrUpdateReward(values, !isCopy && id));

        if ((!isCopy && !id) || isCopy) {
            replace(Paths.Offers.RewardForm, { id: payload.id });
        }
    };
    return (
        <CommerceForm
            onSubmit={handleSubmit((values) => {
                const { definition, contentData, ...rest } = values;
                const { requirements, effects, ...restDef } = definition;
                const mapped = {
                    ...rest,
                    definition: {
                        requirements: mapRequirements(requirements),
                        effects: effects.map((effect) => {
                            const { voucher } = effect;
                            const { definition: voucherDefinition, contentData: voucherContentData, ...rest } = voucher;
                            return {
                                voucher: {
                                    ...rest,
                                    contentData: voucherContentData ? toObject(voucherContentData.filter((c) => Boolean(c.value))) : undefined,
                                    definition: mapVoucherDefinition(voucherDefinition),
                                },
                            };
                        }),
                        ...restDef,
                    },

                    contentData: contentData ? toObject(contentData.filter((c) => Boolean(c.value))) : undefined,
                };
                submit(mapped);
            })}
            isReadonly={readOnly}
            isSubbmitRequested={isSubbmitRequested}
        >
            <Layout
                main
                headerText={t(common.reward, {
                    id: !isCopy && id ? id : "",
                })}
                navigationProps={{
                    backProps: { path: Paths.Offers.RewardsList },
                }}
                customTitle={id && name && `${t(common.reward)} - ${name}`}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-end"
                >
                    <FormField
                        name={"name"}
                        type={"text"}
                        label={t(common.name)}
                        validate={validators.name}
                        readOnly={readOnly}
                    />

                    <FormField
                        name={"isActive"}
                        type={"boolean"}
                        label={t(common.isActive)}
                        readOnly={readOnly}
                    />

                    <FormField
                        name={"validFrom"}
                        type={"dateTime"}
                        label={t(common.validFrom)}
                        validate={validators.validFrom}
                        readOnly={readOnly}
                    />

                    <FormField
                        name={"validTo"}
                        type={"dateTime"}
                        label={t(common.validTo)}
                        validate={validators.validTo}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-end"
                >
                    <FormField
                        name={"activeChoiceGroup"}
                        type={"text"}
                        label={t(common.activeChoiceGroup)}
                        validate={validators.activeChoiceGroup}
                        tooltipLabel={t(common.activeChoiceGroupTooltip)}
                        readOnly={readOnly}
                    />

                    <FormField
                        name={"membershipPoints"}
                        formatDisplay={integerNormalize}
                        type={"number"}
                        label={t(common.membershipPoints)}
                        readOnly={readOnly}
                    />

                    <DivisionSelect
                        readOnly={readOnly}
                        selectedDivisions={divisions}
                    />
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-end"
                >
                    <FormField
                        name={"priority"}
                        formatDisplay={integerNormalize}
                        type={"number"}
                        label={t(common.priority)}
                        validate={validators.priority}
                        readOnly={readOnly}
                    />

                    <FormField
                        name={"reservationLimit"}
                        formatDisplay={integerNormalize}
                        type={"number"}
                        label={t(common.reservationLimit)}
                        validate={validators.greaterThan0WhenNotEmpty}
                        readOnly={readOnly}
                    />

                    <FormField
                        name={"reservationMonthlyLimit"}
                        formatDisplay={integerNormalize}
                        type={"number"}
                        label={t(common.reservationMonthlyLimit)}
                        validate={validators.greaterThan0WhenNotEmpty}
                        readOnly={readOnly}
                    />
                </Grid>

                <Layout headerText={t(common.warehouseReservationLimits)}>
                    <FieldArray
                        name="warehouseReservationLimits"
                        component={WarehouseReservationLimits}
                        rewardDivisions={divisions}
                    />
                </Layout>
            </Layout>

            <Layout headerText={t(common.reservationCount)}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <GiftChoiceActivation activationInfo={activationInfo} />
                </Grid>
            </Layout>

            <Layout headerText={t(common.choiceRequirements)}>
                <RewardDefinition readOnly={readOnly} />
            </Layout>

            <ContentData readOnly={readOnly} />
        </CommerceForm>
    );
};

const stateToProps = (state) => {
    const { isSubbmitRequested, isLoading, isSystem, activationInfo, ...form } = rewardsFormSelector(state);
    return {
        isLoading,
        isSystem,
        isSubbmitRequested,
        activationInfo,
        name: form.name,
        initialValues: {
            ...form,
        },
    };
};

const validateReward = (values) => {
    const errors = {};
    const { definition = {} } = values;
    const { effects = [] } = definition;
    for (let i = 0; i < effects.length; i++) {
        const { voucher = {} } = effects[i];
        const { definition: voucherDefinition = {} } = voucher;
        const { requirements = {} } = voucherDefinition;
        const { user = {} } = requirements;
        const { segments = [], haveSegmentsConstraint } = user;
        errors.definition = {
            effects: effects.map((e) => {
                return {
                    voucher: {
                        definition: {
                            requirements: {
                                user: {
                                    haveSegmentsConstraint: notEmptyValidator(haveSegmentsConstraint)
                                        .must((value) => value === true, tValidation(validation.mustBeChecked))
                                        .validate(),
                                    segments: notEmptyArrayValidator(segments).validate(),
                                },
                            },
                        },
                    },
                };
            }),
        };

        if (segments.length == 1) {
            const { included } = segments[0];
            errors.definition.effects[i].voucher.definition.requirements.user.segments = [
                {
                    included: new Validator(included)
                        .must(
                            (value) => value.includes("commerce:MembershipRewards"),
                            tValidation(validation.mustContains, {
                                what: `segment '${tValidation("MembershipRewards", {}, "segments")}'`,
                            })
                        )
                        .validate(),
                },
            ];
        }
    }

    return errors;
};

export default connect(stateToProps)(
    reduxForm({
        form: formName,
        enableReinitialize: true,
        validate: validateReward,
    })(RewardsForm)
);
