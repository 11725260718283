import defaultListState from "store/defaultListState";
import types from "./types";

const initialState = defaultListState;

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case types.CUSTOMER_RESERVATION_AUDIT.request:
            return { ...initialState, isLoading: true };
        case types.CUSTOMER_RESERVATION_AUDIT.success:
            return { ...state, ...payload, isLoading: false };
        case types.CUSTOMER_RESERVATION_AUDIT.failure:
            return { ...state, isLoading: false };
        default:
            return { ...state };
    }
};
