import { ApiRequest, PagedRequest, getPaged, httpHeaders, httpMethods, remove } from "utils/extensions";
import types from "./types";

const endpoint = (id) => `api/cn-stakes${id ? `/${id}` : ""}`;

const getList =
    ({ isActive, cnStake, pageIndex, pageSize }) =>
    async (dispatch) => {
        const queryParams = {};
        if (isActive !== "") {
            queryParams.isActive = isActive;
        }

        if (cnStake !== "") {
            queryParams.name = cnStake;
        }

        return await dispatch(
            getPaged(
                new PagedRequest(pageIndex, pageSize, endpoint(), types.getList.all, {
                    queryParams,
                })
            )
        );
    };

const removeCnStake = (id) => async (dispatch) => {
    return await dispatch(
        remove(
            new ApiRequest(endpoint(id), types.removeCost.all, null, {
                method: httpMethods.DELETE,
                headers: httpHeaders.json,
            })
        )
    );
};

export const actions = {
    getList,
    removeCnStake,
};
