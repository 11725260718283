import { PrivateRoute } from "routing-ts/PrivateRoute";
import {
    AccountingPeriods,
    Administration,
    AnalyticGroups,
    BalanceDebts,
    Bridge,
    Calendar,
    Campaings,
    Cms,
    Content,
    Corrections,
    Crawler,
    Customer,
    CustomerDeposits,
    Dashboard,
    DeliveryComplaints,
    Dictionaries,
    DocumentArchive,
    Dotpay,
    DynamicSegments,
    Emails,
    EmployeeProcedures,
    ExternalUsers,
    FixedAssets,
    Imports,
    Offers,
    Orders,
    PaymentTransfers,
    PriceLists,
    Pricing,
    Replacements,
    Reports,
    Reviews,
    Search,
    Vendors,
    Voucher,
} from "./Routes/index";

// in Layout order
export const ManagerRoutes: PrivateRoute[] = [
    ...Dashboard,
    ...EmployeeProcedures,
    ...Content,
    ...Customer,
    ...DynamicSegments,
    ...BalanceDebts,
    ...Emails,
    ...Orders,
    ...DeliveryComplaints,
    ...Reviews,
    ...Calendar,
    ...PaymentTransfers,
    ...Pricing,
    ...Offers,
    ...Cms,
    ...Search,
    ...Voucher,
    ...Crawler,
    ...Bridge,
    ...Campaings,
    ...CustomerDeposits,
    ...Dotpay,
    ...PriceLists,
    ...Replacements,
    ...AccountingPeriods,
    ...Vendors,
    ...AnalyticGroups,
    ...Reports,
    ...DocumentArchive,
    ...Dictionaries,
    ...Corrections,
    ...Administration,
    ...FixedAssets,
    ...Imports,
    ...ExternalUsers,
];
