import { FormField } from "components/Form";
import { integerNormalize } from "components/FormHelpers/ControlFormaters";
import { FormSection } from "redux-form";
import { useCommonTranslation } from "utils/hooks";
import { greaterThan, lessOrEqualsThan, notEmptyValidator, stringLength } from "utils/validators/basic";
import { tValidation, validation } from "utils-ts/validations/translation";

const validators = {
    segmentSuffix: (value) => {
        return notEmptyValidator(value)
            .must(stringLength(0, 100), tValidation(validation.stringLength, { min: 1, max: 50 }))
            .mustRegex(/^[A-Za-z0-9][A-Za-z0-9_-]*$/, tValidation(validation.segmentSuffixPattern))
            .validate();
    },
    expirationPeriod: (value) => {
        return notEmptyValidator(value)
            .must(
                lessOrEqualsThan(365),
                tValidation(validation.lessOrEqualsThan, {
                    number: 365,
                })
            )
            .must(greaterThan(0), tValidation(validation.greaterThan, { number: 0 }))
            .validate();
    },
};

const ExpiringSegment = ({ readOnly = false }) => {
    const { t, common } = useCommonTranslation();

    return (
        <FormSection name="expiringSegment">
            <FormField
                name="segmentSuffix"
                label={t(common.segmentSuffix)}
                type="text"
                validate={validators.segmentSuffix}
                readOnly={readOnly}
            />
            <FormField
                name="expirationPeriod"
                label={t(common.expirationPeriod)}
                format={integerNormalize}
                type="number"
                validate={validators.expirationPeriod}
                readOnly={readOnly}
            />
        </FormSection>
    );
};

export default ExpiringSegment;
