import Layout from "components/Grid/Layout";
import { Cell, Row, SimpleTable } from "components/Table/SimpleTable";
import Action from "components/Table/SimpleTable/TableActions/Action";
import tableActionTypes from "components/Table/SimpleTable/TableActions/tableActionTypes";
import paymentMethods from "resource/paymentMethods.json";
import paymentStatuses from "resource/paymentStatuses.json";
import { actions } from "store/payments/paymentTransfers/actions";
import { formatTypes, formatValue, timePrecisonFormats } from "utils/formating/index";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useUser } from "context";
import { bindActionCreators } from "redux";
import { payments } from "translations";
import { usePush, useTranslation } from "utils-ts/hooks";
import { AccountBalanceCoordinator, AccountantDepartment } from "routing-ts/roles";
import FlagIcon from "../customer/customerList/FlagIcon";
import TransferDialog from "./TransferDialog";

const PaymentTransfers = ({ paymentTransfers, getPaymentTransfers, acceptPayment, transferPayment }) => {
    const { isInAnyRoleOrAdmin } = useUser();
    const paymentMethod = ["MBank"];
    const [transfer, setTransfer] = React.useState(undefined);
    const { t } = useTranslation("payments");
    const { pageIndex, totalCount, pageSize, pageCount, isLoading, items } = paymentTransfers;
    const { pushToForm } = usePush();
    const navigateToCustomer = (id) => pushToForm("/customers", id);

    const reload = async () => await getPaymentTransfers({ pageIndex, pageSize, paymentMethod });
    useEffect(() => {
        getPaymentTransfers({ pageIndex, pageSize, paymentMethod });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPaymentTransfers]);

    //prettier-ignore
    const columns = [
        { key: "id", value: t(`${payments.paymentId}`), type: formatTypes.text },
        { key: "icons", value: t(`${payments.accountType}`), type: formatTypes.icon },
        {
            key: "customer",
            value: t(`${payments.customer}`),
            type: formatTypes.action,
            action: (id) => navigateToCustomer(id),
            actionType: tableActionTypes.link,
        },
        {
            key: "paymentMethod",
            value: t(`${payments.paymentMethod}`),
            type: formatTypes.resource,
            options: { items: paymentMethods },
        },
        { key: "channelName", value: t(`${payments.channelName}`), type: formatTypes.text },
        {
            key: "paymentAmount",
            value: t(`${payments.paymentAmount}`),
            type: formatTypes.currencyAmount,
        },
        { key: "title", value: t(`${payments.title}`), type: formatTypes.text },
        {
            key: "status",
            value: t(`${payments.status}`),
            type: formatTypes.resource,
            options: { items: paymentStatuses },
        },
        {
            key: "createdAt",
            value: t(`${payments.createdAt}`),
            type: formatTypes.time,
            options: { timeFormat: timePrecisonFormats.seconds },
        },
        { key: "menu", value: "", type: formatTypes.action, actionType: tableActionTypes.menu },
    ];

    return (
        <Layout
            main
            headerText={t(payments.transferFor)}
        >
            <SimpleTable
                isLoading={isLoading}
                columns={columns}
                data={items}
                shoudlHandleDoubleClick
                hover
                stripped
                pagination
                paginationProps={{
                    pageCount,
                    pageIndex,
                    totalCount,
                    pageSize,
                    onPageChange: (pageIndex) =>
                        getPaymentTransfers({
                            pageIndex,
                            pageSize,
                            paymentMethod,
                        }),
                    onRowsPerPageChange: (pageSize) =>
                        getPaymentTransfers({
                            pageIndex,
                            pageSize,
                            paymentMethod,
                        }),
                }}
            >
                {items
                    .map((payment) => ({
                        ...payment,
                        title: payment.attributes && payment.attributes.title,
                        icons: payment.customer ? <FlagIcon customer={payment.customer} /> : undefined,
                        status: payment.withheldAt ? "Withhold" : payment.status,
                    }))
                    .map((payment) => {
                        return (
                            <Row
                                hover
                                key={payment.id}
                            >
                                {columns.map(({ key, type, options, actionType }, index) => {
                                    if (key === "menu") {
                                        const actions = [
                                            {
                                                label: t(payments.toCustomer),
                                                action: () => {
                                                    window.open(`/customers/${payment.userId}`);
                                                },
                                            },
                                            {
                                                label: t(payments.acceptPayment),
                                                action: async () => {
                                                    await acceptPayment(payment.id);
                                                    await reload();
                                                },
                                                disabled:
                                                    payment.withheldAt &&
                                                    !isInAnyRoleOrAdmin([...AccountantDepartment.GetRoles(), ...AccountBalanceCoordinator]),
                                                tooltip: payment.withheldAt
                                                    ? "Płatność wstrzymana w dniu " + moment(payment.withheldAt).format("YYYY-MM-DD")
                                                    : undefined,
                                            },
                                            {
                                                label: t(payments.transferPayment),
                                                action: () =>
                                                    setTransfer({
                                                        paymentAmount: payment.paymentAmount,
                                                        paymentId: payment.id,
                                                    }),
                                            },
                                        ];

                                        return (
                                            <Action
                                                key={index}
                                                actions={actions}
                                                actionType={actionType}
                                                text={payment[key]}
                                            />
                                        );
                                    }

                                    if (key === "customer") {
                                        const { id, fullName, billingAddress = {}, segments = [] } = payment[key] || {};
                                        const isOfficeAccount = segments.some((x) => x === "commerce:OfficeAccount");
                                        const name =
                                            isOfficeAccount && billingAddress.companyName
                                                ? `${billingAddress.companyName || ""}`
                                                : fullName && formatValue(fullName, formatTypes.fullName);

                                        if (!name) return <Cell key={index} />;
                                        return (
                                            <Action
                                                key={index}
                                                handleClick={() => navigateToCustomer(id)}
                                                actionType={actionType}
                                                text={name}
                                            />
                                        );
                                    }

                                    return (
                                        <Cell
                                            key={index}
                                            value={formatValue(payment[key], type, options)}
                                        />
                                    );
                                })}
                            </Row>
                        );
                    })}
            </SimpleTable>
            <TransferDialog
                t={t}
                open={Boolean(transfer)}
                transfer={transfer}
                transferPayment={transferPayment}
                onClose={() => setTransfer(undefined)}
                reload={reload}
            />
        </Layout>
    );
};

const mapStateToProps = (state) => {
    const { paymentTransfers } = state;
    return { paymentTransfers };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ ...actions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PaymentTransfers);
