import buttons from "assets/jss/custom/buttons";
import { makeStyles } from "tss-react/mui";
import React from "react";
import { CircularProgress, Button as MUIButton } from "@mui/material";
import classnames from "classnames";

const useStyles = makeStyles()((theme) => ({
    ...buttons(theme),
    fullWidth: {
        width: "100%",
    },
    wrapper: {
        margin: theme.spacing(1),
        position: "relative",
    },
    grid: {
        marginBottom: theme.spacing(1),
    },
    item: {
        paddingLeft: theme.spacing(1),
    },
    buttonProgress: {
        color: theme.palette.success.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

const Button = ({ loading = false, actionType, className, disabled, ...props }) => {
    const { classes } = useStyles();
    const classNameString = classnames(!disabled ? classes[actionType] : classes.disabled, { disabled: loading }, className);

    return (
        <div className={props.fullWidth ? classes.fullWidth : undefined}>
            <div className={classnames(classes.wrapper)}>
                <MUIButton
                    {...props}
                    variant={props.variant || "contained"}
                    disabled={disabled || loading}
                    className={classNameString}
                />
                {loading && (
                    <CircularProgress
                        size={28}
                        className={classes.buttonProgress}
                    />
                )}
            </div>
        </div>
    );
};

export default React.memo(Button);
