import { generatePath } from "react-router-dom";
import { DeviceDocument, DeviceListRequest, DeviceListResponse } from "Operations";
import { useCreateOrUpdateMutation, useGetPagedQuery, useGetQuery, useUpdateMutation } from "api/hooks";

const url = (id?: string) => generatePath(`api/devices/:id?`, { id: id ? id : null });

export const useFindDevices = (initialParams: DeviceListRequest) => {
    return useGetPagedQuery<DeviceListResponse, DeviceListRequest>({
        app: "operations",
        url: url(),
        queryParams: {
            ...initialParams,
        },
    });
};

export const useFindDevicesForAccountant = (initParams: { filterAsset?: "Editable" | "Unassigned" }) => {
    return useGetQuery<Array<DeviceDocument>, { filterAsset?: "Editable" | "Unassigned" }>({
        app: "operations",
        url: url("accountant"),
        queryParams: initParams,
    });
};

export const useGetDevice = (id?: string) => {
    return useGetQuery<DeviceDocument>({
        app: "operations",
        url: url(id),
        shouldMakeRequest: Boolean(id),
    });
};

export const useCreateOrUpdateDevice = (id?: string) => {
    return useCreateOrUpdateMutation<DeviceDocument, DeviceDocument>({ app: "operations", url: url(id), id: id });
};

export const useAssignAssetNumbers = () => {
    return useUpdateMutation<{ items: Array<DeviceDocument & { deviceId: string }> }, { items: Array<DeviceDocument & { deviceId: string }> }>({
        app: "operations",
        url: url("asset-number"),
    });
};
