const payments = {
    paymentId: "paymentId",
    paymentAmount: "paymentAmount",
    channelName: "channelName",
    paymentMethod: "paymentMethod",
    externalPaymentId: "externalPaymentId",
    amount: "amount",
    status: "status",
    createdAt: "createdAt",
    orderId: "orderId",
    refunded: "refunded",
    remaining: "remaining",
    payment: "payment",
    refundAmount: "refundAmount",
    isNotifying: "isNotifying",
    customMessage: "customMessage",
    refundQuestion: "refundQuestion",
    refundId: "refundId",
    paymentRefund: "paymentRefund",
    toCustomer: "toCustomer",
    acceptPayment: "acceptPayment",
    transferPayment: "transferPayment",
    save: "save",
    customerId: "customerId",
    transferFor: "transferFor",
    title: "title",
    accountType: "accountType",
    customer: "customer",
} as const;

export default payments;
