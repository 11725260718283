import { renderFieldOrText } from "components/FormHelpers/Controls";
import { Cell, Row } from "components/Table/SimpleTable";
import TextWithLabel from "components/Text/TextWithLabel";
import { Field } from "redux-form";
import correctionTypes from "resource/correctionTypes";
import { withStyles } from "tss-react/mui";
import { isInteger } from "utils/numberExtensions";
import React, { Fragment } from "react";
import Clear from "@mui/icons-material/Clear";
import { Fab } from "@mui/material";
import classnames from "classnames";
import { useConfig, useUser } from "context";
import { orders } from "translations";
import { useTranslation } from "utils-ts/hooks";
import complaintStyles from "../../complaintStyles";

const styles = (theme) => ({
    ...complaintStyles(theme),
    hidden: {
        display: "none",
    },
});

function setDefaults(type, change, line, productLine) {
    change(`${line}.correctQuantity`, "");
    change(`${line}.correctRealQuantity`, "");
    change(`${line}.correctRealItems`, "");
    change(`${line}.correctGrossPrice`, "");
    change(`${line}.reasonCode`, "");
    change(`${line}.type`, type);
    if (type === "Value") {
        change(`${line}.correctGrossPrice`, productLine.grossPrice);
    } else {
        change(`${line}.correctQuantity`, productLine.quantity);
        change(`${line}.correctRealQuantity`, productLine.realQuantity);
        change(`${line}.correctRealItems`, productLine.packSize > 1 ? Math.round(productLine.realQuantity * productLine.packSize) : "");
    }
}

function getReasonCodes(correctionType) {
    switch (correctionType) {
        case "Value":
            return correctionTypes.valueReasonCodes;
        case "Quantity":
            return correctionTypes.quantityReasonCodes;
        case "missingProducts":
            return correctionTypes.missingProductsReasonCodes;
        default:
            return [];
    }
}

function notUsedCorrections(correctionTypes, productLine) {
    const isServiceLine = productLine.productId.indexOf("U") > -1;
    const types = correctionTypes.map((t) => {
        return {
            ...t,
            disabled: isServiceLine && ["missingProducts"].some((tt) => tt === t.value),
        };
    });
    return types;
}

function RenderCorrectionLine(props) {
    const { t } = useTranslation("orders");
    const { isNotProduction } = useConfig();
    const { isAdmin } = useUser();
    const { fields, correctionTypes, change, productLine, editionDisabled, strippedRow } = props;
    const classes = withStyles.getClasses(props);
    const lineCorrections = fields.getAll();

    return fields.map((line, index) => {
        const currentCorrectionType = lineCorrections[index].type;
        return (
            <Row
                key={line}
                strippedRow={strippedRow}
            >
                <Cell />
                <Cell colSpan={2}>
                    <Field
                        type="select"
                        readOnly={editionDisabled}
                        component={renderFieldOrText}
                        name={`${line}.type`}
                        label={t(orders.correctionType)}
                        items={notUsedCorrections(correctionTypes, productLine)}
                        onValueChange={(newValue) => {
                            setDefaults(newValue, change, line, productLine);
                        }}
                    />
                </Cell>
                {currentCorrectionType ? (
                    <Fragment>
                        <Cell colSpan={2}>
                            <Field
                                type="select"
                                readOnly={editionDisabled}
                                component={renderFieldOrText}
                                name={`${line}.reasonCode`}
                                label={t(orders.reason)}
                                items={getReasonCodes(currentCorrectionType)}
                            />
                        </Cell>
                        {currentCorrectionType === "Quantity" || isNotProduction ? (
                            productLine.realQuantity ? (
                                productLine.packSize > 1 ? (
                                    <>
                                        <Cell colSpan={1}>
                                            <Field
                                                positive
                                                component={renderFieldOrText}
                                                readOnly={
                                                    (!isAdmin && !isFinite(productLine.realQuantity) && productLine.realQuantity == null) ||
                                                    editionDisabled
                                                }
                                                name={`${line}.correctRealItems`}
                                                type="number"
                                                label={t(orders.quantityOfSingleItems)}
                                                onChange={(newValue) => {
                                                    change(`${line}.correctQuantity`, Math.trunc(newValue / productLine.packSize));
                                                    change(`${line}.correctRealQuantity`, newValue / productLine.packSize);
                                                }}
                                            />
                                        </Cell>
                                        <Cell colSpan={1}>
                                            <TextWithLabel
                                                label={""}
                                                value={t(orders.singleItemsLeft, {
                                                    number: Math.round(productLine.realQuantity * productLine.packSize),
                                                })}
                                            />
                                        </Cell>
                                    </>
                                ) : (
                                    <>
                                        <Cell colSpan={1}>
                                            <Field
                                                positive
                                                component={renderFieldOrText}
                                                readOnly={
                                                    (!isAdmin && !isFinite(productLine.realQuantity) && productLine.realQuantity == null) ||
                                                    editionDisabled
                                                }
                                                name={`${line}.correctRealQuantity`}
                                                type="number"
                                                label={t(orders.quantity)}
                                                step={isInteger(productLine.realQuantity) ? 1 : productLine.realQuantity}
                                                onChange={(newValue) => {
                                                    change(`${line}.correctQuantity`, Math.trunc(newValue));
                                                }}
                                            />
                                        </Cell>
                                        <Cell colSpan={1} />
                                    </>
                                )
                            ) : (
                                <>
                                    <Cell colSpan={1}>
                                        <Field
                                            positive
                                            component={renderFieldOrText}
                                            readOnly={editionDisabled}
                                            name={`${line}.correctQuantity`}
                                            type="number"
                                            label={t(orders.quantity)}
                                        />
                                    </Cell>
                                    <Cell colSpan={1} />
                                </>
                            )
                        ) : undefined}
                        {currentCorrectionType === "Value" ? (
                            <>
                                <Cell colSpan={1}>
                                    <Field
                                        type="text"
                                        format={(val) => (val && typeof val === "string" ? val.replace(/[^0-9,.]/, "").replace(/[,]/, ".") : val)}
                                        component={renderFieldOrText}
                                        readOnly={editionDisabled}
                                        name={`${line}.correctGrossPrice`}
                                        label={t(orders.grossPrice)}
                                    />
                                </Cell>
                            </>
                        ) : undefined}
                        <Cell colSpan={2} />
                    </Fragment>
                ) : (
                    <Cell
                        className={classes.tableCell}
                        colSpan={6}
                    />
                )}
                <Cell
                    className={classes.tableCell}
                    colSpan={1}
                >
                    <Fab
                        disabled={editionDisabled}
                        size="small"
                        onClick={() => fields.remove(index)}
                        className={classnames(classes.button, "pull-right", editionDisabled ? classes.hidden : "")}
                    >
                        <Clear />
                    </Fab>
                </Cell>
            </Row>
        );
    });
}

export default withStyles(RenderCorrectionLine, styles);
