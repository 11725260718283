export const isEqual = (a, b) => {
    if (a && b) {
        for (const key in a) {
            if (a[key] !== b[key]) {
                return false;
            }
        }

        return true;
    }

    return false;
};

export const isEqualDeep = (objectsToCompare, skippedProperties = [], missingEqualToNullOrUndefined = false, valuesEqualToNull = []) => {
    let i, l, leftChain, rightChain;

    function compare2Objects(x, y) {
        let p;
        // remember that NaN === NaN returns false
        // and isNaN(undefined) returns true
        if (isNaN(x) && isNaN(y) && typeof x === "number" && typeof y === "number") {
            return true;
        }

        // Compare primitives and functions.
        // Check if both arguments link to the same object.
        // Especially useful on the step where we compare prototypes
        if (x === y) {
            return true;
        }

        // Works in case when functions are created in constructor.
        // Comparing dates is a common scenario. Another built-ins?
        // We can even handle functions passed across iframes
        if (
            (typeof x === "function" && typeof y === "function") ||
            (x instanceof Date && y instanceof Date) ||
            (x instanceof RegExp && y instanceof RegExp) ||
            (x instanceof String && y instanceof String) ||
            (x instanceof Number && y instanceof Number)
        ) {
            return x.toString() === y.toString();
        }
        // At last checking prototypes as good as we can
        if (!(x instanceof Object && y instanceof Object)) {
            return false;
        }

        if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
            return false;
        }

        if (x.constructor !== y.constructor) {
            return false;
        }

        if (x.prototype !== y.prototype) {
            return false;
        }

        // Check for infinitive linking loops
        if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
            return false;
        }

        // Quick checking of one object being a subset of another.
        // todo: cache the structure of arguments[0] for performance
        for (p in y) {
            if (skippedProperties.includes(p)) {
                continue;
            }
            if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                if (
                    missingEqualToNullOrUndefined !== true ||
                    (!x.hasOwnProperty(p) && y[p] !== undefined && y[p] !== null && !valuesEqualToNull.includes(y[p]))
                ) {
                    return false;
                }
            } else if (typeof y[p] !== typeof x[p]) {
                return false;
            }
        }

        for (p in x) {
            if (skippedProperties.includes(p)) {
                continue;
            }
            if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                return false;
            } else if (typeof y[p] !== typeof x[p]) {
                return false;
            }

            switch (typeof x[p]) {
                case "object":
                case "function":
                    leftChain.push(x);
                    rightChain.push(y);

                    if (!compare2Objects(x[p], y[p])) {
                        return false;
                    }

                    leftChain.pop();
                    rightChain.pop();
                    break;

                default:
                    if (x[p] !== y[p]) {
                        if ((!x[p] && valuesEqualToNull.includes(y[p])) || (!y[p] && valuesEqualToNull.includes(x[p]))) {
                            continue;
                        } else {
                            return false;
                        }
                    }
                    break;
            }
        }
        return true;
    }

    if (objectsToCompare.length < 1) {
        return true; //Die silently? Don't know how to handle such case, please help...
        // throw "Need two or more arguments to compare";
    }

    for (i = 1, l = objectsToCompare.length; i < l; i++) {
        leftChain = []; //Todo: this can be cached
        rightChain = [];

        if (!compare2Objects(objectsToCompare[0], objectsToCompare[i])) {
            return false;
        }
    }
    return true;
};
