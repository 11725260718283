import useCardIconStyle from "assets/jss/material-dashboard-react/components/cardIconStyle";
import classNames from "classnames";
import PropTypes from "prop-types";

function CardIcon({ ...props }) {
    const { className, children, color, ...rest } = props;
    const { classes } = useCardIconStyle();
    const cardIconClasses = classNames({
        [classes.cardIcon]: true,
        [classes[color + "CardHeader"]]: color,
        [className]: className !== undefined,
    });

    return (
        <div
            className={cardIconClasses}
            {...rest}
        >
            {children}
        </div>
    );
}

CardIcon.propTypes = {
    className: PropTypes.string,
    color: PropTypes.oneOf(["warning", "success", "danger", "info", "primary", "rose"]),
};

export default CardIcon;
