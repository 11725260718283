import { Button } from "components/Button";
import SimpleTable, { createColumn } from "components/Table/SimpleTable/SimpleTable";
import tableActionTypes from "components/Table/SimpleTable/TableActions/tableActionTypes";
import { getDocumentDefinitions } from "store/autocomplete";
import { documentsListSelector, getDocuments } from "store/vendors/documents";
import { makeStyles } from "tss-react/mui";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useCommonTranslation, useParams } from "utils/hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Icon } from "@mui/material";
import { vendor } from "translations";
import { usePush, useTranslation } from "utils-ts/hooks";
import { IsVendorReadOnly } from "views/vendors/IsVendorReadOnly";
import { DocumentStatus } from "./DocumentStatus";

const useStyles = makeStyles()(() => ({
    table: {
        "& tbody": {
            "& td:nth-of-type(4)": {
                wordBreak: "break-all",
            },
        },
    },
}));

const Documents = () => {
    const { classes } = useStyles();
    const { id: vendorId } = useParams();
    const { t } = useTranslation("vendor");
    const { t: tc, common } = useCommonTranslation();
    const dispatch = useDispatch();
    const { push } = usePush();
    const isAccountant = IsVendorReadOnly();
    const {
        autocomplete: { documentDefinitions },
        list,
    } = useSelector(documentsListSelector);
    const { items, pageIndex, pageSize, totalCount, isLoading } = list;

    useEffect(() => {
        dispatch(
            getDocuments(vendorId, {
                pageSize,
                pageIndex: 1,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vendorId, dispatch]);

    useEffect(() => {
        dispatch(getDocumentDefinitions());
    }, [dispatch]);

    const selectNameForDocumentDefinition = (documentDefinitionId) => {
        if (!documentDefinitionId) return "";

        const documentDefinition = documentDefinitions.find((x) => x.id === documentDefinitionId);
        return documentDefinition?.name || "";
    };

    const documentStatusTranslate = (status) => {
        if (!status) return "";

        const documentStatus = DocumentStatus.find((x) => x.value === status);
        return documentStatus?.name || "";
    };

    const columns = [
        {
            key: "documentDefinitionId",
            value: t(vendor.document),
            type: "text",
            transform: selectNameForDocumentDefinition,
        },
        {
            key: "status",
            value: t(vendor.status),
            type: "text",
            transform: documentStatusTranslate,
        },
        createColumn("archiveDocument", t(vendor.archiveDocument), formatTypes.boolean),
        createColumn("responsibleUser", t(vendor.email), formatTypes.text),
        createColumn("createdAt", tc(common.createdAt), formatTypes.time, {
            timeFormat: timePrecisonFormats.seconds,
        }),
        createColumn(
            "action",
            "",
            formatTypes.action,
            {
                actionType: () => tableActionTypes.preview,
                resetButton: true,
            },
            (item) => {
                const { id } = item;
                push(`/vendors/${vendorId}/documents/form/${id}`);
            }
        ),
    ];

    return (
        <>
            {!isAccountant && (
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                push(`/vendors/${vendorId}/documents/form`);
                            }}
                        >
                            <Icon>add</Icon>
                        </Button>
                    </Grid>
                </Grid>
            )}

            <SimpleTable
                className={classes.table}
                columns={columns}
                data={items}
                onRowDoubleClick={(_, item) => {
                    const { id } = item;
                    push(`/vendors/${vendorId}/documents/form/${id}`);
                }}
                isLoading={isLoading}
                stripped
                hover
                pagination
                paginationProps={{
                    pageIndex,
                    totalCount,
                    pageSize,
                    onPageChange: (pageIndex) =>
                        dispatch(
                            getDocuments(vendorId, {
                                pageSize,
                                pageIndex,
                            })
                        ),
                    onRowsPerPageChange: (pageSize) =>
                        dispatch(
                            getDocuments(vendorId, {
                                pageIndex: 1,
                                pageSize,
                            })
                        ),
                }}
            />
        </>
    );
};

export default Documents;
