import { allAccountingPeriodsSelector } from "store/accountingPeriods";
import { findAccountingPeriods } from "utils/utilsFunctions";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useUser } from "context";

const useUserAccountingPeriods = (vendorId) => {
    const { accountingPeriods } = useSelector(allAccountingPeriodsSelector);
    const { profile } = useUser();

    return useMemo(() => {
        return findAccountingPeriods(accountingPeriods, vendorId, profile?.sub);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountingPeriods?.length, profile.sub, vendorId]);
};

export default useUserAccountingPeriods;
