import { RSAA } from "redux-api-middleware";
import { commerce } from "store/actionTypePrefixes";
import { combineIntoQueryParams } from "utils/objectExtensions";

export const types = {
    GET_CONTENT_RULES_LIST: `${commerce}/GET_CONTENT_RULES_LIST`,
    GET_CONTENT_RULES_LIST_SUCCESS: `${commerce}/GET_CONTENT_RULES_LIST_SUCCESS`,
    GET_CONTENT_RULES_LIST_FAILURE: `${commerce}/GET_CONTENT_RULES_LIST_FAILURE`,
};

const getList =
    ({ isActive, ...rest }) =>
    async (dispatch) => {
        let query = { ...rest };
        if (isActive !== "") query.isActive = isActive;
        const queryParams = combineIntoQueryParams({ ...query });
        return dispatch({
            [RSAA]: {
                endpoint: `api/v1/content/rules?${queryParams}`,
                headers: { "Content-Type": "application/json" },
                method: "GET",
                types: [types.GET_CONTENT_RULES_LIST, types.GET_CONTENT_RULES_LIST_SUCCESS, types.GET_CONTENT_RULES_LIST_FAILURE],
            },
        });
    };

export const actions = {
    getList,
};
