import { IconButton } from "components/Button";
import DocumentArchiveFile from "components/Files/DocumentArchiveFile";
import { FormField } from "components/Form";
import { FormSection } from "redux-form";
import { withFormName } from "utils/hoc";
import { useChange, useCommonTranslation, useFormValueSelector } from "utils/hooks";
import React from "react";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { Grid } from "@mui/material";
import _ from "lodash";

const DocumentArchiveFileSection = ({
    form,
    sectionPrefix,
    hiddenFields = [],
    readOnly,
    useDialogPreview,
    hideFileDropZone,
    useDropzone,
    useDiff,
    readOnlyFields = [],
    validate,
}) => {
    const change = useChange(form);
    const { t, common } = useCommonTranslation();
    const { archiveFile = {} } = useFormValueSelector(form, sectionPrefix ? [sectionPrefix] : ["archiveFile"], {});
    const { fileContent } = archiveFile;
    const hasFile = !_.isEmpty(fileContent);

    const clearSelectedFile = () => {
        change(sectionPrefix + ".archiveFile", {});
        change(sectionPrefix + ".barcodePosition", {});
        change(sectionPrefix + ".documentFileInput", {});
    };

    return (
        <>
            <DocumentArchiveFile
                form={form}
                sectionPrefix={sectionPrefix}
                hiddenFields={hiddenFields}
                readOnly={readOnly}
                useDialogPreview={useDialogPreview}
                hideFileDropZone={hideFileDropZone}
                useDropzone={useDropzone}
                useDiff={useDiff}
                readOnlyFields={readOnlyFields}
                validate={validate}
            />
            {hasFile && (
                <FormSection name="archiveFile">
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        flex="1"
                    >
                        <Grid item>
                            <FormField
                                label={t(common.fileName)}
                                useDiff={useDiff}
                                readOnly={true}
                                name="fileName"
                            />
                        </Grid>
                        <Grid item>
                            <IconButton
                                edge="end"
                                onClick={clearSelectedFile}
                                icon={<DeleteIcon />}
                            />
                        </Grid>
                    </Grid>
                </FormSection>
            )}
        </>
    );
};

export default withFormName(DocumentArchiveFileSection);
