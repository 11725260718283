import InfoDialog from "components/Dialog/InfoDialog";
import { customers } from "translations";
import { useTranslation } from "utils-ts/hooks";
import AddBalanceCorrectionForm from "./AddBalanceCorrectionForm";

const AddBalanceCorrectiomDialog = ({ open, handleClose, handleAdd, editItem }) => {
    const { t } = useTranslation("customers");

    return (
        <InfoDialog
            open={open}
            onClose={handleClose}
            title={t(customers.addBalanceCorrection)}
            maxWidth="md"
        >
            <AddBalanceCorrectionForm
                handleAdd={handleAdd}
                editItem={editItem}
            />
        </InfoDialog>
    );
};

export default AddBalanceCorrectiomDialog;
