import { RSAA } from "redux-api-middleware";
import { commerce } from "store/actionTypePrefixes";
import { asAsyncFormTypes } from "utils/extensions/arrayExtensions";

export const types = {
    GET_SYNONYMS: `${commerce}/GET_SYNONYMS`,
    GET_SYNONYMS_SUCCESS: `${commerce}/GET_SYNONYMS_SUCCESS`,
    GET_SYNONYMS_FAILURE: `${commerce}/GET_SYNONYMS_FAILURE`,
    PUT_SYNONYMS: `${commerce}/PUT_SYNONYMS`,
    PUT_SYNONYMS_SUCCESS: `${commerce}/PUT_SYNONYMS_SUCCESS`,
    PUT_SYNONYMS_FAILURE: `${commerce}/PUT_SYNONYMS_FAILURE`,
};

const get = (language) => async (dispatch) => {
    return dispatch({
        [RSAA]: {
            endpoint: `api/v1/search/synonyms/${language}`,
            headers: { "Content-Type": "application/json" },
            method: "GET",
            types: [types.GET_SYNONYMS, types.GET_SYNONYMS_SUCCESS, types.GET_SYNONYMS_FAILURE],
        },
    });
};

const put = (language, values) => async (dispatch) => {
    return await dispatch({
        [RSAA]: {
            endpoint: `api/v1/search/synonyms/${language}`,
            headers: { "Content-Type": "application/json" },
            method: "PUT",
            types: asAsyncFormTypes([types.PUT_SYNONYMS, types.PUT_SYNONYMS_SUCCESS, types.PUT_SYNONYMS_FAILURE]),
            body: JSON.stringify(values),
        },
    });
};

export const actions = {
    get,
    put,
};
