import { operations } from "store/actionTypePrefixes";
import { AsyncTypes, asAsyncFormTypes, createAction } from "utils/extensions";

const types = {
    getDocument: new AsyncTypes("GET_ARCHIVE_DOCUMENT", operations),
    getOriginalFile: new AsyncTypes("GET_ARCHIVE_DOCUMENT_ORIGINAL_FILE", operations),
    downloadOriginalFile: new AsyncTypes("DOWNLOAD_ARCHIVE_DOCUMENT_ORIGINAL_FILE", operations),
    getDocumentPermissions: new AsyncTypes("GET_ARCHIVE_DOCUMENT_PERMISSIONS", operations),
    updatePermissions: new AsyncTypes("UPDATE_PERMISSIONS", operations, [asAsyncFormTypes]),
    getLinkedDocuments: new AsyncTypes("GET_ARCHIVED_DOCUMENT_LINKED_DOCUMENTS", operations),
    linkedDocumentsSetFilters: createAction("SET_FILTERS_LINKED_DOCUMENTS"),
    linkedDocumentsFindArchivedDocuments: new AsyncTypes("FIND_ARCHIVED_DOCUMENTS_LINKED_DOCUMENTS", operations),
    addLinkedDocument: new AsyncTypes("ADD_LINKED_DOCUMENT", operations),
    removeLinkedDocument: new AsyncTypes("REMOVE_LINKED_DOCUMENT", operations),
    updateDocument: new AsyncTypes("UPDATE_DOCUMENT", operations, [asAsyncFormTypes]),
};

export default types;
