import { RSAA } from "redux-api-middleware";
import { combineIntoQueryParams } from "utils/objectExtensions";

export const USERS_GET_PAGE = "@@administration/USERS_GET_PAGE";
export const USERS_GET_PAGE_SUCCESS = "@@administration/USERS_GET_PAGE_SUCCESS";
export const USERS_GET_PAGE_FAILURE = "@@administration/USERS_GET_PAGE_FAILURE";

export const getUsers = (pageIndex = 1, pageSize = 10, isDeactivated = null) => {
    return {
        [RSAA]: {
            endpoint: `api/users?${combineIntoQueryParams({ pageIndex, pageSize, isDeactivated })}`,
            headers: { "Content-Type": "application/json" },
            method: "GET",
            types: [
                {
                    type: USERS_GET_PAGE,
                    payload: (action) => ({ endpoint: action.endpoint }),
                },
                USERS_GET_PAGE_SUCCESS,
                USERS_GET_PAGE_FAILURE,
            ],
        },
    };
};
