import { createSelector } from "reselect";
import { dictionariesSelector } from "../selectors";

const rootSelector = createSelector(dictionariesSelector, (dictionaries) => {
    return dictionaries.cnStakes;
});

export const cnStakesListSelector = createSelector(rootSelector, (cnStakes) => cnStakes.list);

export const cnStakesFormSelector = createSelector(rootSelector, (cnStakes) => ({ ...cnStakes.form, name: cnStakes.form?.cnStake }));
