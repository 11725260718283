const customerDeposits = {
    importTitle: "importTitle",
    merchant: "merchant",
    provider: "provider",
    bankStatementNumber: "bankStatementNumber",
    sendFile: "sendFile",
    fileName: "fileName",
    paymentCount: "paymentCount",
    paymentsFrom: "paymentsFrom",
    paymentsTo: "paymentsTo",
    deleteCustomerDeposistsTitle: "deleteCustomerDeposistsTitle",
    deleteCustomerDeposists: "deleteCustomerDeposists",
} as const;

export default customerDeposits;
