import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import { getCustomerReservationAudit, reservationAuditSelector } from "store/customers/customer/reservationAudit";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useCommonTranslation } from "utils/hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { common } from "translations";

const columns = [
    {
        key: "infoIcon",
        value: "",
        type: formatTypes.infoIcon,
    },
    {
        key: "createdAt",
        value: common.createdAt,
        type: formatTypes.time,
        options: { timeFormat: timePrecisonFormats.seconds },
    },
    { key: "createdBy", value: common.createdBy, type: formatTypes.text },
    {
        key: "commandName",
        value: common.modificationType,
        type: formatTypes.translatable,
        options: { translationKey: "common" },
    },
    {
        key: "expiresAt",
        value: common.reservationExpiresAt,
        type: formatTypes.time,
        options: { timeFormat: timePrecisonFormats.seconds },
    },
    {
        key: "deliveryWindow",
        value: common.deliveryWindow,
        type: formatTypes.deliveryWindow,
    },
    {
        key: "shippingAddress",
        value: common.shippingAddress,
        type: formatTypes.shippingAddress,
    },
];

const ReservationAudit = ({ customerId }) => {
    const { t } = useCommonTranslation();
    const dispatch = useDispatch();
    const { pageIndex, pageSize, items, totalCount, isLoading } = useSelector(reservationAuditSelector);

    useEffect(() => {
        dispatch(
            getCustomerReservationAudit({
                customerId,
                pageIndex,
                pageSize,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, customerId]);

    return (
        <SimpleTable
            isLoading={isLoading}
            columns={columns.map((col) => ({ ...col, value: t(col.value) }))}
            hover
            stripped
            pagination
            data={items}
            paginationProps={{
                pageIndex,
                totalCount,
                pageSize,
                onPageChange: (pageIndex) =>
                    dispatch(
                        getCustomerReservationAudit({
                            customerId,
                            pageIndex,
                            pageSize,
                        })
                    ),
                onRowsPerPageChange: (pageSize) =>
                    dispatch(
                        getCustomerReservationAudit({
                            customerId,
                            pageIndex,
                            pageSize,
                        })
                    ),
            }}
        />
    );
};

export default ReservationAudit;
