import { GetRequest, PostRequest, PutRequest, get, post, put } from "utils/extensions";
import { downloadPdfFile } from "utils/rsaa/downloadFileAction";
import types from "./types";

const endpoint = (vendorId, documentId) => `api/vendors/${vendorId}/documents${documentId ? `/${documentId}` : ""}`;

export const getDocuments = (vendorId, params) => async (dispatch) => {
    return dispatch(
        get(
            new GetRequest(endpoint(vendorId), types.getDocuments.all, {
                queryParams: params,
            })
        )
    );
};

export const initForm = (vendorId, documentId) => async (dispatch) => {
    await dispatch({ type: types.formInit });

    if (documentId) {
        return dispatch(get(new GetRequest(endpoint(vendorId, documentId), types.getDocument.all)));
    }
};

export const createOrUpdate = (vendorId, documentId, values) => async (dispatch) => {
    if (!documentId) {
        return await dispatch(
            post(
                new PostRequest(endpoint(vendorId), types.createOrUpdate.all, {
                    body: values,
                })
            )
        );
    } else {
        return await dispatch(
            put(
                new PutRequest(endpoint(vendorId, documentId), types.createOrUpdate.all, {
                    body: values,
                })
            )
        );
    }
};

export const getDocumentFile = (vendorId, documentId, fileName) => async (dispatch) => {
    dispatch(downloadPdfFile(endpoint(vendorId, documentId) + `/file`, types.downloadFile.all, fileName));
};

export const getVendorsVatStatements = (vendorIds) => async (dispatch) => {
    return await dispatch(
        get(
            new GetRequest(endpoint("D000000", "vat-statements"), types.getVatStatements.all, {
                queryParams: { vendorIds },
            })
        )
    );
};
