import { PagedRequest, getPaged } from "utils/extensions";
import types from "./types";

const endpoint = `api/v1/offer/products/gifts`;

export const getGiftList =
    ({ isActive, pageIndex, pageSize, ...rest }) =>
    (dispatch) => {
        let query = { ...rest };
        if (isActive !== "") query.isActive = isActive;

        return dispatch(
            getPaged(
                new PagedRequest(pageIndex, pageSize, endpoint, types.getGifts.all, {
                    queryParams: { isActive },
                })
            )
        );
    };
