import defaultListState from "store/defaultListState";
import { combineReducers } from "redux";
import types from "./types";

const initialState = {};

const listInitialState = defaultListState;

const allAccountingPeriodsInitialState = {
    isLoading: false,
    accountingPeriods: [],
};

const allAccountingPeriods = (state = allAccountingPeriodsInitialState, { type, payload }) => {
    switch (type) {
        case types.findAllAccountingPeriods.request:
            return { ...state, isloading: true };
        case types.findAllAccountingPeriods.success:
            return { ...state, isloading: false, accountingPeriods: payload };
        case types.findAllAccountingPeriods.failure:
            return { ...state, isloading: false };
        default:
            return { ...state };
    }
};

const list = (state = listInitialState, { type, payload }) => {
    switch (type) {
        case types.findAccountingPeriods.request:
            return {
                ...state,
                isloading: true,
            };
        case types.findAccountingPeriods.success:
            return {
                ...state,
                ...payload,
                isloading: true,
            };
        case types.findAccountingPeriods.failure:
            return {
                ...state,
                isloading: false,
            };
        case types.updateQuery:
            return {
                ...state,
                ...payload,
            };
        default:
            return { ...state };
    }
};

const form = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.formInit:
            return { ...initialState };
        case types.getAccountingPeriod.request:
            return { ...state, isLoading: true };
        case types.getAccountingPeriod.success:
            return { ...state, ...payload, isLoading: false };
        case types.getAccountingPeriod.failure:
            return { ...payload, isLoading: false };
        default:
            return state;
    }
};

export const accountingPeriods = combineReducers({
    form,
    list,
    allAccountingPeriods,
});
