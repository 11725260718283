import { FileType } from "consts-ts/fileTypes";
import { makeStyles } from "tss-react/mui";
import { FileRejection } from "react-dropzone";
import { ArrayPath, FieldArray, FieldValues, Path, UseFieldArrayProps, useFieldArray, useFormContext } from "react-hook-form";
import { FileCopy } from "@mui/icons-material";
import { Avatar, Divider, Grid } from "@mui/material";
import _ from "lodash";
import { common } from "translations";
import { Fetcher, Query } from "api-types";
import { ValidationError } from "control-types";
import { Translation } from "translations/Translation";
import { useTranslation } from "utils-ts/hooks";
import { FileDropzone, FormHelperText, IconButton } from "components-ts/controls";
import { usePrefixContext } from "components-ts/forms/contexts";
import { ImageCard } from "components-ts/image";
import { SuspenseContainer } from "components-ts/suspense";
import { TextWithLabel } from "components-ts/text";
import { Spacing, View } from "components-ts/view";

const useStyles = makeStyles()((theme) => ({
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    parent: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
    },
    children: {
        width: "100%",
    },
}));

type FormFilesProps<T, TFieldValues extends FieldValues = FieldValues, TName extends ArrayPath<TFieldValues> = ArrayPath<TFieldValues>> = Pick<
    UseFieldArrayProps<TFieldValues, TName>,
    "name"
> & {
    acceptedFiles: FileType[];
    canRemoveFile: (file: T) => boolean;
    getFileQuery: (file: T) => Query.UseQueryResult<Fetcher.FileResponse, {}, {}>;
    defaultValue?: Partial<T>;
    maxFiles?: number;
    viewType: "Grid" | "List";
    label?: Translation;
    readOnly?: boolean;
};

type FileUpload = {
    fileId?: string;
    blobName?: string;
    fileName: string;
    fileContent: string;
    isNew: boolean;
};

const FormFiles = <
    T extends FileUpload,
    TFieldValues extends FieldValues = FieldValues,
    TName extends ArrayPath<TFieldValues> = ArrayPath<TFieldValues>,
>({
    name,
    defaultValue,
    maxFiles,
    viewType,
    label,
    readOnly,
    acceptedFiles,
    canRemoveFile,
    getFileQuery,
}: FormFilesProps<T, TFieldValues, TName>) => {
    const prefix = usePrefixContext();
    const key = prefix ? `${prefix}.${name}` : `${name}`;

    const { t } = useTranslation();
    const { classes } = useStyles();
    const { formState, clearErrors } = useFormContext<TFieldValues>();
    const { fields, append, remove } = useFieldArray<TFieldValues, TName>({
        name: key as TName,
    });
    const error: ValidationError | undefined = _.get(formState.errors, key)
        ? ({
              hasError: true,
              message: _.get(formState.errors, key)?.message,
          } as ValidationError)
        : undefined;

    const files = fields.map((item) => {
        return item as TFieldValues;
    });

    const filesComponent = (
        <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
        >
            {(maxFiles === undefined || files.length <= maxFiles) && !readOnly && (
                <FileDropzone
                    onDrop={(acceptedFiles: File[], fileRejections: FileRejection[]) => {
                        acceptedFiles.forEach((file) => {
                            const reader = new FileReader();

                            reader.onload = () => {
                                let fileContent: string;
                                if (reader.result === null) {
                                    return;
                                } else if (typeof reader.result === "string") {
                                    fileContent = reader.result;
                                } else {
                                    const buf = new ArrayBuffer(64);
                                    const decoder = new TextDecoder();
                                    fileContent = decoder.decode(reader.result);
                                }
                                const fileItem: FileUpload = {
                                    isNew: true,
                                    fileName: file.name,
                                    fileContent: fileContent.split(",")[1], //to get BASE64, ex: "data:image/png;base64,daksljdasdas"
                                };

                                append({
                                    ...defaultValue,
                                    ...fileItem,
                                } as FieldArray<TFieldValues, TName>);
                                clearErrors(key as TName as Path<TFieldValues>);
                            };

                            reader.readAsDataURL(file);
                        });
                    }}
                    acceptedFiles={acceptedFiles}
                />
            )}
            <FormHelperText error={error} />
            {viewType === "List" ? (
                fields.map((field, index) => {
                    const file = field as T;
                    return (
                        <>
                            <Grid
                                container
                                item
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                key={index}
                            >
                                <Grid item>
                                    <Grid
                                        container
                                        justifyContent="flex-start"
                                        alignItems="start"
                                    >
                                        <Avatar style={{ margin: "7px" }}>
                                            <FileCopy />
                                        </Avatar>

                                        <TextWithLabel
                                            label={t(common.fileName)}
                                            value={file.fileName}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="end"
                                    >
                                        <Grid item>
                                            <IconButton
                                                edge="start"
                                                onClick={() => console.log(file)}
                                                disabled={file.isNew}
                                                icon="download"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <IconButton
                                                edge="end"
                                                disabled={!canRemoveFile(file)}
                                                onClick={() => remove(index)}
                                                icon="delete"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {fields.length !== index + 1 && (
                                <Grid
                                    item
                                    key={`divider-${index}`}
                                >
                                    <Divider
                                        className={classes.divider}
                                        variant="fullWidth"
                                    />
                                </Grid>
                            )}
                        </>
                    );
                })
            ) : (
                <Grid
                    container
                    direction="row"
                >
                    {fields.map((field, index) => {
                        const file = field as T;

                        return (
                            <ImageCard
                                key={index}
                                image={{
                                    imageId: file.fileId ?? index.toString(),
                                    content: file.isNew ? file.fileContent : undefined,
                                    fileName: file.fileName,
                                }}
                                canRemove={(_image) => canRemoveFile(file)}
                                handleRemove={(_image) => remove(index)}
                                useFileQuery={() => getFileQuery(file)}
                            />
                        );
                    })}
                </Grid>
            )}
        </Grid>
    );

    if (label) {
        return (
            <Grid
                item
                xs={12}
                style={{ width: "100%" }}
            >
                <View headerText={label}>
                    <SuspenseContainer>
                        <Spacing spacing={2}>{filesComponent}</Spacing>
                    </SuspenseContainer>
                </View>
            </Grid>
        );
    } else {
        return (
            <Grid
                item
                xs={12}
                style={{ width: "100%" }}
            >
                {filesComponent}
            </Grid>
        );
    }
};

export default FormFiles;
