import { Button } from "components/Button";
import { Dialog } from "components/Dialog";
import { FormField } from "components/Form";
import { reduxForm } from "redux-form";
import { makeStyles } from "tss-react/mui";
import { useCommonTranslation } from "utils/hooks";
import { notEmptyValidator } from "utils/validators/basic";
import React, { useState } from "react";
import { connect } from "react-redux";
import { DialogActions, Typography } from "@mui/material";

const abortReasons = [
    {
        name: "Nieterminowa dostawa - wina transportu",
        value: "wina Frisco: Nieterminowa dostawa - wina transportu",
        group: "wina Frisco",
    },
    {
        name: "Nieterminowa dostawa - wina magazynu",
        value: "wina Frisco: Nieterminowa dostawa - wina magazynu",
        group: "wina Frisco",
    },
    {
        name: "Anulowanie dowózki - pracownik Frisco odnalazł towar",
        value: "wina Frisco: Anulowanie dowózki - pracownik Frisco odnalazł towar",
        group: "wina Frisco",
    },
    {
        name: "Pomylony adres - przez kierowcę",
        value: "wina Frisco: Pomylony adres - przez kierowcę",
        group: "wina Frisco",
    },
    {
        name: "Uszkodzona dostawa ",
        value: "wina Frisco: Uszkodzona dostawa ",
        group: "wina Frisco",
    },
    {
        name: "Braki w dostawie zafakturowane",
        value: "wina Frisco: Braki w dostawie zafakturowane",
        group: "wina Frisco",
    },
    {
        name: "Braki w dostawie niezafakturowane",
        value: "wina Frisco: Braki w dostawie niezafakturowane",
        group: "wina Frisco",
    },
    {
        name: "Zdublowane zamówienie - np. błąd strony",
        value: "wina Frisco: Zdublowane zamówienie - np. błąd strony",
        group: "wina Frisco",
    },
    {
        name: "Niedoręczone zamówienie - np. kierowca po prostu nie doręczył",
        value: "wina Frisco: Niedoręczone zamówienie - np. kierowca po prostu nie doręczył",
        group: "wina Frisco",
    },
    { name: "Konto testowe", value: "wina Frisco: Konto testowe", group: "wina Frisco" },
    {
        name: "Brak kontaktu z Klientem ",
        value: "wina Klienta: Brak kontaktu z Klientem ",
        group: "wina Klienta",
    },
    {
        name: "Pomylony adres - przez Klienta",
        value: "wina Klienta: Pomylony adres - przez Klienta",
        group: "wina Klienta",
    },
    {
        name: "Pomyłka w terminie dostawy - Klient",
        value: "wina Klienta: Pomyłka w terminie dostawy - Klient",
        group: "wina Klienta",
    },
    {
        name: "Rezygnacja Klienta/Na życzenie Klienta",
        value: "wina Klienta: Rezygnacja Klienta/Na życzenie Klienta",
        group: "wina Klienta",
    },
    { name: "Fikcyjne konto", value: "wina Klienta: Fikcyjne konto", group: "wina Klienta" },
    {
        name: "Rezygnacja z dowózki ",
        value: "wina Klienta: Rezygnacja z dowózki ",
        group: "wina Klienta",
    },
    {
        name: "Zdublowane zamówienie ",
        value: "wina Klienta: Zdublowane zamówienie ",
        group: "wina Klienta",
    },
];

const useStyles = makeStyles()((theme) => ({
    typography: {
        padding: theme.spacing(1),
    },
}));

const notEmptyReason = (val) => {
    return notEmptyValidator(val).validate();
};

const AbortOrderModal = ({ handleSubmit, cancelMethod, handleAccept, handleReject, open }) => {
    const { t, common } = useCommonTranslation();
    const [isExecuting, setIsExecuting] = useState(false);
    const { classes } = useStyles();

    return (
        <Dialog
            maxWidth="sm"
            open={open}
            onClose={handleReject}
            title={t(common.cancelOrder)}
        >
            <>
                <Typography className={classes.typography}>{t(common.areYouSure, { what: t(common.orderCancelation) })}</Typography>
                <form
                    onSubmit={handleSubmit(async ({ reason }) => {
                        setIsExecuting(true);
                        await handleAccept(reason);
                        setIsExecuting(false);
                    })}
                >
                    {cancelMethod === "abort" && (
                        <FormField
                            name="reason"
                            type="select"
                            grouped
                            items={abortReasons}
                            label={t(common.reason)}
                            validate={notEmptyReason}
                        />
                    )}
                    <DialogActions>
                        <Button
                            type="submit"
                            loading={isExecuting}
                            actionType="accept"
                        >
                            {t(common.yes)}
                        </Button>
                        <Button
                            loading={isExecuting}
                            onClick={handleReject}
                            actionType="danger"
                        >
                            {t(common.no)}
                        </Button>
                    </DialogActions>
                </form>
            </>
        </Dialog>
    );
};

export default connect()(
    reduxForm({
        form: "abort-order-modal",
        enableReinitialize: true,
    })(AbortOrderModal)
);
