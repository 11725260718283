import Validator, {
    beInteger,
    defaultLenghtValidator,
    greaterOrEqualsThan,
    greaterThan,
    lessOrEqualsThan,
    mustBeNumber,
    notEmpty,
    notEmptyArrayValidator,
    notEmptyValidator,
} from "utils/validators/basic";
import moment from "moment";
import { get } from "lodash";
import { common } from "translations";
import { tValidation, validation } from "utils-ts/validations/translation";

const validFrom = (value, form, _, path) => {
    let definition = get(form, path.substring(0, path.lastIndexOf("."))) || {};
    let { validTo } = definition;

    return new notEmptyValidator(value)
        .must(
            () => {
                return value <= validTo;
            },
            tValidation(validation.lessOrEqualsDateThan, { date: moment(form.validTo).format("DD.MM.YYYY HH:mm") })
        )
        .when(value != null && value !== "" && validTo != null && validTo !== "")
        .validate();
};
const validTo = (value, form, _, path) => {
    let definition = get(form, path.substring(0, path.lastIndexOf("."))) || {};
    let { validFrom } = definition;

    return new notEmptyValidator(value)
        .must(
            () => {
                return value >= validFrom;
            },
            tValidation(validation.greaterOrEqualsDateThan, { date: moment(form.validFrom).format("DD.MM.YYYY HH:mm") })
        )
        .when(value != null && value !== "" && validFrom != null && validFrom !== "")
        .validate();
};
const campaign = (value) => notEmptyValidator(value).validate();
const useLimit = (value) =>
    notEmptyValidator(value)
        .must(
            greaterThan(0),
            tValidation(validation.greaterThan, {
                number: 0,
            })
        )
        .validate();
const slug = (value) => notEmptyValidator(value).validate();
const name = (value) => defaultLenghtValidator(value).validate();
const promotions = (value) => notEmptyArrayValidator(value).validate();
const voucherDefinition = (value) => notEmptyArrayValidator(value).validate();
const codeLength = (value) =>
    notEmptyValidator(value)
        .must(
            greaterOrEqualsThan(4),
            tValidation(validation.greaterOrEqualsThan, {
                number: 4,
            })
        )
        .must(lessOrEqualsThan(24), tValidation(validation.lessOrEqualsThan, { number: 24 }))
        .validate();
const voucherCount = (value) =>
    notEmptyValidator(value)
        .must(
            greaterOrEqualsThan(1),
            tValidation(validation.greaterOrEqualsThan, {
                number: 1,
            })
        )
        .must(
            lessOrEqualsThan(50000),
            tValidation(validation.lessOrEqualsThan, {
                number: 50000,
            })
        )
        .validate();
const voucherValue = (value) =>
    notEmptyValidator(value)
        .must(
            greaterOrEqualsThan(0.01),
            tValidation(validation.greaterOrEqualsThan, {
                number: 0.01,
            })
        )
        .validate();

const voucherPercent = (value) =>
    notEmptyValidator(value)
        .must(
            greaterThan(0),
            tValidation(validation.greaterOrEqualsThan, {
                number: 0,
            })
        )
        .must(
            lessOrEqualsThan(100),
            tValidation(validation.lessOrEqualsThan, {
                number: 100,
            })
        )
        .validate();
const voucherAmountType = (value) => notEmptyValidator(value).validate();
const voucherAmount = (value) =>
    notEmptyValidator(value)
        .must(
            greaterOrEqualsThan(0),
            tValidation(validation.mustBeChecked, {
                number: 0,
            })
        )
        .validate();
const voucherType = (value) => {
    return notEmptyValidator(value).validate();
};

const isCumulative = (value, form, _, path) => {
    let voucherPromotion = get(form, path.substring(0, path.lastIndexOf("]") + 1)) || {};

    return new Validator(value)
        .must((value) => value === true, tValidation(validation.mustBeChecked))
        .when(
            voucherPromotion.definition &&
                (voucherPromotion.definition.cartActivationLimit > 0 ||
                    voucherPromotion.definition.userActivationLimit > 0 ||
                    voucherPromotion.definition.effects.some((e) => e.discountModifier))
        )
        .validate();
};

const promotionActivationLimit = (value) =>
    new Validator(value)
        .must(
            greaterOrEqualsThan(1),
            tValidation(validation.greaterOrEqualsThan, {
                number: 1,
            })
        )
        .when(Boolean(value))
        .validate();

const cartActivationLimit = (value) =>
    new Validator(value)
        .must(
            greaterOrEqualsThan(1),
            tValidation(validation.greaterOrEqualsThan, {
                number: 1,
            })
        )
        .when(Boolean(value))
        .validate();

const userActivationLimit = (value) =>
    new Validator(value)
        .must(
            () => beInteger(value) && Number(value) >= 1,
            tValidation(validation.greaterOrEqualsThan, {
                number: 1,
            })
        )
        .when(notEmpty(value))
        .validate();

const userActivationLimitExpirationPeriod = (value, form, _, path) => {
    let voucherPromotion = get(form, path.substring(0, path.lastIndexOf("]") + 1)) || form || {};

    return notEmptyValidator(value)
        .must(
            (value) => value && moment.duration(value, "days").asDays() > 0,
            tValidation(validation.greaterOrEqualsThan, {
                number: 1,
            })
        )
        .must(
            (value) => value && moment.duration(value, "days").asDays() <= 365,
            tValidation(validation.lessOrEqualsThan, {
                number: 365,
            })
        )
        .allWhen(voucherPromotion.definition && voucherPromotion.definition.userActivationLimit > 0)
        .must((value) => {
            return !value.endsWith(".");
        }, tValidation(validation.mustBeNumber))
        .when(notEmpty(value))
        .validate();
};

const validForDays = (value, form, _, path) => {
    let definition = get(form, path.substring(0, path.lastIndexOf("."))) || {};
    let { validTo, validFrom } = definition;

    return new Validator(value)
        .must(
            (value) => value == null || value === "",
            tValidation(validation.mustBeEmpty, {
                when: tValidation(common.whenValidToAndValidFromNotEmpty, {}, "common"),
            })
        )
        .when(validTo != null && validFrom != null)
        .must(
            (value) => value > 0,
            tValidation(validation.greaterOrEqualsThan, {
                number: 0,
            })
        )
        .when(validTo == null)
        .validate();
};

const onlyOneChosen = (value, form, __, path) => {
    const { isRepeatable, generateOnRegistration, generateOnTokenRefresh, generateOnAddedSegments } = form;
    const notEmptyGenerateOnAddedSegments = Boolean(!notEmptyArrayValidator(generateOnAddedSegments).validate());
    const fieldsTab = [
        Boolean(isRepeatable),
        Boolean(generateOnRegistration),
        Boolean(generateOnTokenRefresh),
        Boolean(notEmptyGenerateOnAddedSegments),
    ];
    if (Array.isArray(value) && !value.length) {
        value = false;
    }
    return new Validator(value)
        .must(() => Boolean(value) !== true || fieldsTab.filter((item) => item).length <= 1, tValidation(validation.onlyOneChosen))
        .validate();
};

const voucherId = (value) => {
    const voucherIdRegex = /^[A-Za-z0-9]*$/;
    return new Validator(value)
        .must(() => voucherIdRegex.test(value), tValidation(validation.onlyLettersAndNumbers))
        .must(() => value?.length >= 4 && value?.length <= 24, tValidation(validation.stringLength, { min: 4, max: 24 }))
        .when(notEmpty(value))
        .validate();
};

const voucherCashbackLimit = (value) =>
    new Validator(value)
        .must(
            greaterOrEqualsThan(0.01),
            tValidation(validation.greaterOrEqualsThan, {
                number: 0.01,
            })
        )
        .when(notEmpty(value))
        .validate();

const mustBeNumberValidator = (value) =>
    new Validator(value).must(mustBeNumber, tValidation(validation.mustBeNumber)).allWhen(notEmpty(value)).validate();

export const validators = {
    validFrom,
    validTo,
    campaign,
    useLimit,
    promotions,
    slug,
    voucherDefinition,
    name,
    codeLength,
    voucherCount,
    voucherValue,
    voucherPercent,
    voucherAmountType,
    voucherAmount,
    voucherType,
    isCumulative,
    userActivationLimit,
    userActivationLimitExpirationPeriod,
    promotionActivationLimit,
    cartActivationLimit,
    validForDays,
    onlyOneChosen,
    voucherId,
    voucherCashbackLimit,
    mustBeNumberValidator,
};
