import { RSAA } from "redux-api-middleware";
import { asAsyncFormTypes, asCleanFormDataTypes } from "utils/extensions/arrayExtensions";
import { types } from "./types";

export const initContractForm = (id, isCopy) => async (dispatch) => {
    await dispatch({ type: types.initContractForm });

    if (id) {
        dispatch({
            [RSAA]: {
                endpoint: `api/v1/offer/products/contracts/${id}`,
                headers: { "Content-Type": "application/json" },
                method: "GET",
                types: [types.getContract.request, { type: types.getContract.success, meta: { isCopy } }, types.getContract.failure],
            },
        });
    }
};

export const createOrUpdateContract = (values, id) => async (dispatch) => {
    return await dispatch({
        [RSAA]: {
            endpoint: id ? `api/v1/offer/products/contracts/${id}` : `api/v1/offer/products/contracts/`,
            headers: { "Content-Type": "application/json" },
            method: id ? "PUT" : "POST",
            types: asCleanFormDataTypes(asAsyncFormTypes(types.createOrUpdateContract.all)),
            body: JSON.stringify(values),
        },
    });
};
