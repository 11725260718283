import { makeStyles } from "tss-react/mui";
import { Grid } from "@mui/material";

const useStyles = makeStyles()((theme) => ({
    readonlyContainer: {
        border: `3px solid ${theme.palette.error.dark}`,
        borderRadius: theme.shape.borderRadius,
        background: "#f8d7da",
        padding: theme.spacing(4),
        color: theme.palette.error.dark,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const RejectMessage = ({ isRejected, message }) => {
    const { classes } = useStyles();

    return isRejected ? (
        <Grid
            item
            xs={12}
            className={classes.readonlyContainer}
        >
            {message}
        </Grid>
    ) : (
        <></>
    );
};

export default RejectMessage;
