import { Button } from "components/Button";
import { ArrayValidationError } from "components/Controls";
import { Adable } from "components/FormHelpers";
import Layout from "components/Grid/Layout";
import { FormSection } from "redux-form";
import { withFormName } from "utils/hoc";
import { useAppLocation, useFormValueSelector } from "utils/hooks";
import React, { useCallback } from "react";
import { GetApp, Pageview } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { Erasable } from "components";
import DocumentArchiveFile from "./DocumentArchiveFile";
import Dropzone from "./Dropzone";
import RowContainer from "./RowContainer";

const createFieldName = (sectionPrefix, name) => `${sectionPrefix ? `${sectionPrefix}.` : ""}${name}`;

const DocumentArchiveFileArray = ({
    fields,
    meta,
    form,
    sectionPrefix,
    canUpdate,
    canUpdateWithFileId,
    readOnly,
    header,
    itemTemplate,
    handleDownloadFile,
    hiddenFields,
    useDropzone,
    useDiff,
    hideHeader,
    onDropFile,
    onChangeFile,
    onBarcodePositionChanged,
    onRemoveFile,
    documentTypeFilter,
    children,
}) => {
    const files = useFormValueSelector(form, createFieldName(sectionPrefix, fields.name), []);
    const appLocation = useAppLocation();

    const handleDrop = useCallback(
        (acceptedFiles) => {
            acceptedFiles.forEach((file) => {
                var reader = new FileReader();

                reader.onload = function (e) {
                    const archiveFile = {
                        extension: `.${file.name.split(".").slice(-1)[0].toLowerCase()}`,
                        fileName: file.name,
                        fileContent: e.target.result.split(",")[1],
                    };

                    var newFile = {
                        ...itemTemplate,
                        documentFileInput: file,
                        archiveFile: {
                            ...archiveFile,
                        },
                        isNew: true,
                        barcodePosition: {
                            positionX: 66,
                            positionY: 4,
                        },
                    };

                    fields.push(newFile);

                    if (onDropFile) {
                        onDropFile(newFile, fields.length - 1);
                    }
                };

                reader.readAsDataURL(file);
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fields, itemTemplate]
    );

    const component = (
        <>
            <ArrayValidationError {...meta} />
            {useDropzone && (
                <Dropzone
                    disabled={readOnly || !canUpdate}
                    onDrop={handleDrop}
                    accept=".pdf"
                />
            )}
            <Adable
                handleAdd={() => {
                    fields.push(itemTemplate || {});
                }}
                renderCondition={false}
            >
                {fields.map((field, i) => {
                    const item = files[i];
                    // eslint-disable-next-line no-unused-vars
                    let { fileId, hasArchiveErrors, isArchived, archiveId, fileName, archiveFile } = item || {};
                    if (useDiff) {
                        fileId = fileId?.current || fileId?.proposal;
                        hasArchiveErrors = hasArchiveErrors?.current || fileId?.proposal;
                        isArchived = isArchived?.current || fileId?.proposal;
                        archiveId = archiveId?.current || fileId?.proposal;
                        fileName = fileName?.current || fileName?.proposal || archiveFile?.current?.fileName || archiveFile?.proposal?.fileName;
                    }

                    const isReadonly = item.archiveId || (!canUpdateWithFileId && item.fileId) || (readOnly && Boolean(canUpdate));

                    return (
                        <Erasable
                            key={i}
                            handleRemove={() => {
                                if (onRemoveFile) {
                                    onRemoveFile(item);
                                }

                                fields.remove(i);
                            }}
                            renderCondition={!isReadonly}
                        >
                            <RowContainer error={hasArchiveErrors !== undefined && hasArchiveErrors === true}>
                                <FormSection name={field}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Grid item>
                                            <DocumentArchiveFile
                                                useDiff={useDiff}
                                                hiddenFields={hiddenFields}
                                                readOnly={isReadonly || !Boolean(canUpdate)}
                                                useDialogPreview
                                                documentId={archiveId}
                                                documentTypeFilter={documentTypeFilter}
                                                onChangeFile={onChangeFile}
                                                onBarcodePositionChanged={onBarcodePositionChanged}
                                            />
                                        </Grid>
                                        <Grid item>
                                            {!(archiveId && !hasArchiveErrors) && fileId && (
                                                <Button
                                                    color="primary"
                                                    startIcon={<GetApp />}
                                                    onClick={() => {
                                                        handleDownloadFile(fileId, fileName || archiveFile?.fileName);
                                                    }}
                                                >
                                                    Pobierz
                                                </Button>
                                            )}
                                            {archiveId && !hasArchiveErrors && (
                                                <Button
                                                    color="primary"
                                                    startIcon={<Pageview />}
                                                    onClick={() => window.open(`${appLocation}/document-archive/document/${archiveId}`, "_blank")}
                                                >
                                                    Podgląd
                                                </Button>
                                            )}
                                        </Grid>
                                    </Grid>
                                </FormSection>
                            </RowContainer>
                        </Erasable>
                    );
                })}
            </Adable>
        </>
    );

    return !hideHeader ? (
        <Layout
            isEnlarged
            headerText={header}
        >
            {children}
            {component}
        </Layout>
    ) : (
        component
    );
};

export default withFormName(DocumentArchiveFileArray);
