import MappedProductIds from "components/Controls/MappedProductIds";
import { FormField } from "components/Form";
import { integerNormalize } from "components/FormHelpers/ControlFormaters";
import { FormSection } from "redux-form";
import { actions } from "store/cache/products/actions";
import { cachedProductsSelector } from "store/offers/selectors";
import { findProduct, formatProduct } from "utils/formating";
import { useCommonTranslation } from "utils/hooks";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { validators } from "./validators";

const SampleProduct = ({ sampleProduct = {}, readOnly = false }) => {
    const { t, common } = useCommonTranslation();
    const { productId } = sampleProduct;
    const cachedProducts = useSelector(cachedProductsSelector);
    const dispatch = useDispatch();
    useEffect(() => {
        if (sampleProduct) {
            if (sampleProduct.productId) {
                dispatch(actions.getProducts([sampleProduct.productId]));
            }

            if (sampleProduct.mappedProductIds) {
                dispatch(actions.getProducts([sampleProduct.mappedProductIds]));
            }
        }
    }, [dispatch, sampleProduct]);
    const product = formatProduct(productId, findProduct(cachedProducts, productId));

    return (
        <FormSection name="sampleProduct">
            <Grid
                container
                direction="row"
            >
                <FormField
                    style={{ width: 420 }}
                    name={"productId"}
                    label={t(common.productId)}
                    type={"textWithImage"}
                    item={product}
                    onAdd={(value) => dispatch(actions.getProducts([value]))}
                    validate={validators.productId}
                    readOnly={readOnly}
                />
                <FormField
                    name="quantity"
                    type={"text"}
                    format={integerNormalize}
                    label={t(common.quantity)}
                    validate={validators.quantity}
                    readOnly={readOnly}
                />
                <MappedProductIds name="mappedProductIds" />
            </Grid>
        </FormSection>
    );
};

export default SampleProduct;
