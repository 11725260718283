import Button from "components/Button/Button";
import UsersAutocomplete from "components/Controls/Autocompletes/UsersAutocomplete";
import { reduxForm } from "redux-form";
import { userInfoSelector } from "store/autocomplete";
import { getUserInfo } from "store/autocomplete/actions";
import { useCommonTranslation } from "utils/hooks";
import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";

const VendorUsers = ({ handleSubmit, isSubbmitRequested, onSubmitUsers }) => {
    const { t, common } = useCommonTranslation();
    const dispatch = useDispatch();
    const users = useSelector(userInfoSelector);

    useEffect(() => {
        dispatch(getUserInfo());
    }, [dispatch]);

    return (
        <form
            onSubmit={handleSubmit((values) => {
                var vendorUsers = values.userIds.map((id) => users.find((user) => user.userId === id));
                onSubmitUsers(vendorUsers);
            })}
        >
            <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
                direction="column"
            >
                <UsersAutocomplete
                    filterFunc={(x) => x.userType === "externalUser"}
                    users={users}
                />
            </Grid>

            <Button
                actionType="accept"
                type="submit"
                fullWidth
                loading={isSubbmitRequested}
            >
                {t(common.save)}
            </Button>
        </form>
    );
};

const formName = "vendors-users-form";

const stateToProps = (state) => {
    const { vendorPms } = state;
    const { isSubbmitRequested, vendorForm } = vendorPms;

    const { userIds = [] } = vendorForm;

    return {
        isSubbmitRequested,
        initialValues: {
            userIds,
        },
    };
};

export default connect(stateToProps)(
    reduxForm({
        form: formName,
        enableReinitialize: true,
    })(VendorUsers)
);
