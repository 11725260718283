import { Button } from "components/Button";
import { InfoDialog } from "components/Dialog";
import Layout from "components/Grid/Layout";
import Actions, { createAction } from "components/Tabs/Actions";
import { reduxForm } from "redux-form";
import { actions as cutomerActions } from "store/customers/customer/customerInfo/action";
import { actions as complaintActions } from "store/order/complaint/actions";
import { actions as orderComplaintActions } from "store/order/orderComplaint/action";
import { withConsumer, withMatchParams, withPush } from "utils/hoc";
import withRouter from "utils/hoc/withPush";
import { splitOrderId } from "utils/splitIds";
import { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { DialogActions } from "@mui/material";
import { ConfigConsumer, UserConsumer } from "context";
import { bindActionCreators } from "redux";
import { common, orders } from "translations";
import { baseCustomerComplaintRoles } from "routing-ts/Routes/CustomerRoles";
import mapStateToProps from "./ComplaintMapStateToProps";
import SaveButtons from "./SaveButton";
import DocumentLinesCorrection from "./complaint/DocumentLinesCorrection";
import OrderInfo from "./complaint/OrderInfo";
import { default as CancelComplaintDialog } from "./complaint/correction/CancelComplaintDialog";
import { default as RefundOrderDialog } from "./complaint/correction/RefundOrderDialog";
import Delivery from "./complaint/delivery/Delivery";
import Upsell from "./complaint/upsell/Upsell";
import validate from "./validate";

class Complaint extends Component {
    state = {
        cancelComplaintModalOpen: false,
        refundOrderModalOpen: false,
        isSubmitted: false,
    };

    componentDidMount = () => {
        const { customerId } = this.routeParams;
        const { getOrderForComplaint, getCustomerInfo } = this.props;
        getOrderForComplaint(this.routeParams);
        getCustomerInfo(customerId);
    };

    componentDidUpdate = () => {
        const { isAlertModalOpen, editionDisabled } = this.state;
        const { havePreviousComplaints } = this.props;
        if (isAlertModalOpen === undefined && havePreviousComplaints && !editionDisabled) {
            this.setState({
                isAlertModalOpen: havePreviousComplaints,
            });
        }
    };

    componentWillUnmount = () => {
        this.props.cleanUp();
    };

    get routeParams() {
        const { match } = this.props;
        const { params } = match;
        return params;
    }

    get requiresDelivery() {
        const { correctedItems = [], upsellLines = [] } = this.props;
        const anyMissingProducts = correctedItems.some((x) => x.some((y) => y.type === "missingProducts"));
        const anyUpsellLines = upsellLines.some((x) => x.upsellType === "withDelivery");
        return anyMissingProducts || anyUpsellLines;
    }

    shoouldRenderUpsell = (editionDisabled) => {
        return !editionDisabled || (this.props.upsellLines && this.props.upsellLines.length);
    };
    handleCancelComplaintModalOpen = () => {
        this.setState({ cancelComplaintModalOpen: true });
    };

    handleCancelComplaintModalClose = () => {
        this.setState({ cancelComplaintModalOpen: false });
    };

    handleRefundOrderModalOpen = () => {
        this.setState({ refundOrderModalOpen: true });
    };

    handleRefundOrderModalClose = () => {
        this.setState({ refundOrderModalOpen: false });
    };

    handleComplaintAlertClose = () => {
        this.setState({ isAlertModalOpen: false });
    };

    handleExport = () => {
        const el = document.createElement("textarea");
        const { exportData } = this.props;
        el.value = exportData;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
    };

    render = () => {
        const {
            context: { isNotProduction, isAdmin, isInAnyRoleOrAdmin },
        } = this.props;
        const { cancelComplaintModalOpen, refundOrderModalOpen, isAlertModalOpen } = this.state;
        const {
            t,
            pristine,
            submitting,
            handleSubmit,
            array: { push },
            editionDisabled,
            orderData,
            orderComplaint,
            upsellLines,
            cancelComplaint,
            refundOrder,
            isDeliveryComplaint,
            push: routerPush,
        } = this.props;

        const { customerId, orderNumber, complaintId } = this.routeParams;
        const tabActions = [];

        if (isInAnyRoleOrAdmin(baseCustomerComplaintRoles)) {
            if (!complaintId && !editionDisabled) tabActions.push(createAction(t(orders.fullRefund), this.handleRefundOrderModalOpen, "warning"));

            if (complaintId && !editionDisabled)
                tabActions.push(createAction(t(orders.cancelCorrection), this.handleCancelComplaintModalOpen, "danger"));

            if (complaintId) {
                tabActions.push(createAction("Export", this.handleExport, "primary"));
            }
        }

        return (
            <Fragment>
                <form onSubmit={handleSubmit}>
                    <Layout
                        main
                        navigationProps={{
                            backProps: {
                                path: `/customers/${customerId}/orders/${orderNumber}`,
                                state: { tabIndex: 1 },
                            },
                        }}
                        headerText={t(orders.complaintFor, {
                            orderId: `${customerId}/${orderNumber}`,
                        })}
                    >
                        <Actions
                            pull={"left"}
                            actions={tabActions}
                        />
                        <OrderInfo orderData={orderData} />
                    </Layout>
                    {isInAnyRoleOrAdmin(baseCustomerComplaintRoles) && (
                        <SaveButtons
                            editionDisabled={editionDisabled}
                            pristine={pristine}
                            isDeliveryComplaint={isDeliveryComplaint}
                            submitting={submitting}
                            isSubmitted={this.state.isSubmitted}
                            handleExport={this.handleExport}
                        />
                    )}
                    <DocumentLinesCorrection
                        {...this.props}
                        customerId={customerId}
                        orderNumber={orderNumber}
                    />
                    <Fragment>
                        {(isNotProduction || isAdmin) && this.shoouldRenderUpsell(editionDisabled) ? (
                            <Upsell
                                upsellLines={upsellLines}
                                editionDisabled={editionDisabled}
                                customerId={customerId}
                                pushUpsellProduct={push}
                            />
                        ) : (
                            <Fragment />
                        )}
                        {this.requiresDelivery ? <Delivery editionDisabled={editionDisabled} /> : <Fragment />}
                    </Fragment>
                    {isInAnyRoleOrAdmin(baseCustomerComplaintRoles) && (
                        <SaveButtons
                            editionDisabled={editionDisabled}
                            pristine={pristine}
                            isDeliveryComplaint={isDeliveryComplaint}
                            submitting={submitting}
                            isSubmitted={this.state.isSubmitted}
                            handleExport={this.handleExport}
                        />
                    )}
                </form>
                <CancelComplaintDialog
                    open={cancelComplaintModalOpen}
                    handleAccept={async () => {
                        const result = await cancelComplaint({
                            ...this.routeParams,
                            ...orderComplaint,
                        });

                        if (result.type.includes("SUCCESS")) {
                            routerPush(`/customers/${customerId}/orders/${orderNumber}`);
                        }
                    }}
                    handleReject={this.handleCancelComplaintModalClose}
                />
                <RefundOrderDialog
                    open={refundOrderModalOpen}
                    handleAccept={() => {
                        refundOrder({
                            ...this.routeParams,
                            ...orderComplaint,
                            loggedUser: this.props.loggedUser,
                        });
                        this.handleRefundOrderModalClose();
                    }}
                    handleReject={this.handleRefundOrderModalClose}
                />
                <InfoDialog
                    title={t(`common:${common.warning}`)}
                    open={isAlertModalOpen || false}
                    onClose={this.handleComplaintAlertClose}
                    maxWidth="sm"
                >
                    {t(`common:${common.previousComplaintWarning}`)}
                    <DialogActions>
                        <Button
                            style={{ marginLeft: "auto" }}
                            onClick={this.handleComplaintAlertClose}
                            actionType="primary"
                        >
                            {t(`common:${common.close}`)}
                        </Button>
                    </DialogActions>
                </InfoDialog>
            </Fragment>
        );
    };
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ ...complaintActions, ...orderComplaintActions, ...cutomerActions }, dispatch);

const form = reduxForm({
    form: "complaintForm",
    enableReinitialize: true,
    validate,
    onSubmit: async (form, dispatch, props) => {
        const data = {
            formData: form,
            loggedUser: props.loggedUser,
            merchant: props.match.params.merchant,
        };
        const result = await dispatch(complaintActions.postComplaint(data));

        const { customerId, orderNumber } = splitOrderId(form.orderId);
        if (result.meta.formSubmit === "SUCCESS") {
            if (form.voucherRedirect) {
                props.push(`/customers/${customerId}/voucher/${result.payload}`, {
                    tabIndex: 1,
                });
            } else {
                props.push(`/customers/${customerId}/orders/${orderNumber}`, {
                    tabIndex: 1,
                });
            }
        }
    },
})(withConsumer(withConsumer(withPush(Complaint), ConfigConsumer), UserConsumer));

const connected = connect(mapStateToProps, mapDispatchToProps)(form);

export default withRouter(withMatchParams(withTranslation(["orders", "common"])(connected)));
