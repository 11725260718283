import {
    AccountantDepartment,
    B2BAndSupportDepartment,
    B2BDepartment,
    ECommerceDepartment,
    LogisticianDepartment,
    MarketingDepartment,
    Role,
    SalesDepartment,
    TradeMarketingDepartment,
} from "routing-ts/roles";

export const baseCustomerRoles: Role[] = ["Analyst", "Management", "AXUser", ...B2BAndSupportDepartment];

export const deferredPaymentRoles: Role[] = [...B2BDepartment.GetRoles(), "Auditor"];

export const baseWithEcommerceManager: Role[] = [...baseCustomerRoles, "EcommerceManager"];

export const complaintsRoles: Role[] = [...baseWithEcommerceManager, ...LogisticianDepartment.GetRoles(), ...AccountantDepartment.GetRoles()];

export const baseCustomerComplaintRoles: Role[] = ["Management", ...B2BAndSupportDepartment];

export const baseWithEcommerce: Role[] = [...baseCustomerRoles, ...ECommerceDepartment.GetRoles()];

export const allCustomerRoles: Role[] = [
    ...LogisticianDepartment.GetRoles(),
    ...baseWithEcommerce,
    ...SalesDepartment.GetRoles(),
    ...MarketingDepartment.GetRoles(),
    ...TradeMarketingDepartment.GetRoles(),
    ...AccountantDepartment.GetRoles(),
];

export const allCustomerRolesWithAuditor: Role[] = [...allCustomerRoles, "Auditor", "DebtCoordinator", "AccountBalanceCoordinator"];

export const allCustomerRolesWithAuditorAndHRManager: Role[] = [...allCustomerRolesWithAuditor, "HumanResourcesManager"];
