import Button from "components/Button/Button";
import InfoDialog from "components/Dialog/InfoDialog";
import { renderFieldOrText } from "components/FormHelpers/Controls";
import { Field, reduxForm } from "redux-form";
import { withStyles } from "tss-react/mui";
import { notEmptyValidator, validCustomerId } from "utils/validators/basic";
import { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { payments } from "translations";

const TransferDialog = withStyles(
    ({ classes, t, open, onClose, transfer, pristine, transferInprogress, handleSubmit, transferPayment, reload }) => {
        if (!transfer) return <Fragment />;

        return (
            <InfoDialog
                maxWidth="sm"
                open={open}
                onClose={onClose}
                title={t(payments.transferPayment)}
            >
                <form
                    onSubmit={handleSubmit(async (values) => {
                        await transferPayment(values);
                        reload();
                    })}
                >
                    <div className={classes.field}>
                        <Field
                            fullWidth
                            name="transferTo"
                            type="text"
                            label={t(payments.customerId)}
                            component={renderFieldOrText}
                        />
                    </div>
                    <Button
                        actionType="accept"
                        variant="contained"
                        fullWidth
                        type={"submit"}
                        disabled={transferInprogress || pristine}
                        loading={transferInprogress}
                    >
                        {t(payments.save)}
                    </Button>
                </form>
            </InfoDialog>
        );
    },
    (theme) => ({
        field: { padding: theme.spacing(1) },
    })
);

const mapStateToProps = (state, props) => {
    const { paymentTransfers } = state;
    const { transfer } = props || {};
    return {
        transferInprogress: paymentTransfers.transferInprogress,
        initialValues: {
            paymentId: transfer && transfer.paymentId,
            transferAmount: transfer && transfer.paymentAmount,
        },
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const validate = (values) => {
    const errors = {};
    errors.userId = notEmptyValidator(values.transferTo).must(validCustomerId).validate();

    return errors;
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: "refundForm",
        enableReinitialize: true,
        validate,
    })(TransferDialog)
);
