import { makeStyles } from "tss-react/mui";
import React from "react";
import { Theme } from "@mui/material";
import moment from "moment";
import { DateTimeOffset } from "Shared";

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        fontWeight: theme.typography.fontWeightBold as number,
    },
}));

const DateCell: React.FC<{ value: DateTimeOffset | Date | unknown; showTime?: boolean }> = ({ value, showTime }) => {
    const { classes } = useStyles();
    const val = !!value ? (moment.isMoment(value) ? value : moment(value as Date)) : null;

    return (
        <>
            <div className={classes.root}>{`${val?.format("DD.MM.YYYY") ?? ""}`} </div>
            {showTime && <div>{`${val?.format("HH:mm:ss") ?? ""}`} </div>}
        </>
    );
};

export default DateCell;
