import ArrayValidationError from "components/Controls/ArrayValidationError";
import { FormField } from "components/Form";
import { Adable, Erasable } from "components/FormHelpers";
import { FormSection } from "redux-form";
import { useCommonTranslation } from "utils/hooks";
import { notEmptyAndPositiveInteger, notEmptyValidator } from "utils/validators/basic";
import { Grid } from "@mui/material";
import { GetWarehousesForDivision, ObsoleteWarehouses, Warehouses } from "types/sharedEnums";

const required = (value) => notEmptyValidator(value).validate();
const requiredNumber = (value) => notEmptyAndPositiveInteger(value);

const WarehouseReservationLimits = ({ fields, meta, rewardDivisions = [] }) => {
    const { t, common } = useCommonTranslation();

    return (
        <>
            <ArrayValidationError {...meta} />
            <Adable
                handleAdd={() => {
                    fields.push();
                }}
            >
                {fields.map((field, index) => {
                    const fieldsValues = fields.getAll();
                    const items = Warehouses.filter((w) => !ObsoleteWarehouses.includes(w)).map((w) => {
                        return {
                            value: w,
                            name: w,
                            disabled:
                                fieldsValues.find((f, i) => i !== index && f?.warehouse === w) !== undefined ||
                                (rewardDivisions.length > 0 && !rewardDivisions.flatMap((d) => GetWarehousesForDivision(d)).find((pd) => w == pd)),
                        };
                    });

                    return (
                        <Erasable
                            key={index}
                            handleRemove={() => {
                                fields.remove(index);
                            }}
                        >
                            <FormSection name={field}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >
                                    <FormField
                                        name="warehouse"
                                        type="select"
                                        label={t(common.warehouse)}
                                        items={items}
                                        validate={required}
                                        hideDefaultItem
                                    />
                                    <FormField
                                        name="reservationLimit"
                                        type="number"
                                        label={t(common.reservationLimit)}
                                        validate={requiredNumber}
                                    />
                                </Grid>
                            </FormSection>
                        </Erasable>
                    );
                })}
            </Adable>
        </>
    );
};

export default WarehouseReservationLimits;
