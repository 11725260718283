import defaultListState from "store/defaultListState";
import { combineReducers } from "redux";
import { types } from "./actions";

const initialState = {
    isLoading: false,
    deliveryWindows: defaultListState,
    statistics: [],
};

const deliveryWindows = (state = initialState.deliveryWindows, { type, payload }) => {
    switch (type) {
        case types.INIT_LOADING:
            return initialState.deliveryWindows;
        case types.GET_CALENDAR:
            return { ...state };
        case types.GET_CALENDAR_SUCCESS:
            if (state.items.length > 0) {
                payload.items = [...payload.items, ...state.items];
                payload.totalCount = payload.totalCount + state.totalCount;
            }
            return { ...payload };
        case types.GET_CALENDAR_FAILURE:
            return { ...state };
        default:
            return state;
    }
};

const isLoading = (state = initialState.isLoading, { type }) => {
    switch (type) {
        case types.INIT_LOADING:
            return true;
        case types.END_LOADING:
            return false;
        default:
            return state;
    }
};

const statistics = (state = initialState.statistics, { type, payload }) => {
    switch (type) {
        case types.INIT_LOADING:
            return initialState.statistics;
        case types.GET_CALENDAR_STATISTICS:
            return [...state];
        case types.GET_CALENDAR_STATISTICS_SUCCESS:
            return [...state, payload];
        case types.GET_CALENDAR_STATISTICS_FAILURE:
            return [...state];
        default:
            return state;
    }
};

export const calendar = combineReducers({
    deliveryWindows,
    statistics,
    isLoading,
});
