import Layout from "components/Grid/Layout";
import { FieldArray } from "redux-form";
import { notEmptyArrayValidator } from "utils/validators/basic";
import { common } from "translations";
import { useTranslation } from "utils-ts/hooks";
import DictionaryInput from "./Dictionary";

const validator = {
    contentData: (value) => {
        return notEmptyArrayValidator(value).validate();
    },
};

const ContentData = ({ withLayout = true, useCollapse = true, isCollapsed = false, required = false, readOnly = false }) => {
    const { t } = useTranslation("common");

    const component = (
        <FieldArray
            name={"contentData"}
            component={DictionaryInput}
            validate={required ? validator.contentData : undefined}
            readOnly={readOnly}
        />
    );

    if (withLayout)
        return (
            <Layout
                isEnlarged
                headerText={t(common.contentData)}
                useCollapse={useCollapse}
                isCollapsed={useCollapse && isCollapsed}
            >
                {component}
            </Layout>
        );
    else {
        return component;
    }
};

export default ContentData;
