import { UserManager, UserManagerSettings, WebStorageStateStore } from "oidc-client-ts";

const location = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}`;

export const signinRedirectKey = "signinRedirectKey";
export const signinCallbackKey = "signinCallback";
export const callbackLocation = "/callback";
export const silentRenewLocation = "/silentRenew";

export const userManagerConfig: UserManagerSettings = {
    client_id: "manager",
    redirect_uri: `${location}${callbackLocation}`,
    response_type: "code",
    scope: "openid",
    authority: window.config.applications.identity,
    silent_redirect_uri: `${location}${silentRenewLocation}`,
    post_logout_redirect_uri: `${location}/`,
    loadUserInfo: false,
    revokeTokenTypes: ["access_token"],
    userStore: new WebStorageStateStore({
        prefix: `oidc:${window.config.env.toLowerCase()}.`,
        store: window.localStorage,
    }),
    automaticSilentRenew:
        (window.config.env !== "Development" && window.config.env !== "Development (Static)") ||
        window.config.applications.identity.includes("localhost"),
    response_mode: "query",
};

export const userManager = new UserManager(userManagerConfig);
