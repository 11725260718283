import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import administration from "translations/resources/administration.json";
import bridge from "translations/resources/bridge.json";
import commerceErrorCode from "translations/resources/commerceErrorCode.json";
import commerceProductProblems from "translations/resources/commerceProductProblems.json";
import common from "translations/resources/common.json";
import content from "translations/resources/content.json";
import crawler from "translations/resources/crawler.json";
import customerBalanceCommandNames from "translations/resources/customerBalanceCommandNames.json";
import customerDeposits from "translations/resources/customerDeposits.json";
import customers from "translations/resources/customers.json";
import documentArchive from "translations/resources/documentArchive.json";
import orders from "translations/resources/orders.json";
import payments from "translations/resources/payments.json";
import procedures from "translations/resources/procedures.json";
import roles from "translations/resources/roles.json";
import routing from "translations/resources/routing.json";
import segments from "translations/resources/segments.json";
import validation from "translations/resources/validation.json";
import vendor from "translations/resources/vendor.json";
import voucher from "translations/resources/voucher.json";
import voucherKinds from "translations/resources/voucherKinds.json";

i18next.use(initReactI18next).init({
    lng: "pl", // if you're using a language detector, do not define the lng option
    keySeparator: ".", // we do not use keys in form messages.welcome
    debug: window.config.env !== "Production",
    interpolation: {
        escapeValue: false,
        skipOnVariables: false,
    },
    resources: {
        pl: {
            administration: administration,
            bridge: bridge,
            commerceErrorCode: commerceErrorCode,
            commerceProductProblems: commerceProductProblems,
            common: common,
            content: content,
            crawler: crawler,
            customerBalanceCommandNames: customerBalanceCommandNames,
            customerDeposits: customerDeposits,
            customers: customers,
            documentArchive: documentArchive,
            orders: orders,
            payments: payments,
            procedures: procedures,
            roles: roles,
            routing: routing,
            segments: segments,
            validation: validation,
            vendor: vendor,
            voucher: voucher,
            voucherKinds: voucherKinds,
        },
    },
    defaultNS: "common",
});

export default i18next;
