export const ExpirationIntervals = ["Daily", "Weekly", "Monthly", "Quarterly", "Yearly"] as const;

export type ExpirationInterval = (typeof ExpirationIntervals)[number];

export const PromotionTypes = [
    "Membership",
    "Delivery",
    "Gratis",
    "Discount",
    "Pack",
    "Pool",
    "Pair",
    "Progressive",
    "Sampling",
    "DoublePrice",
    "Packaging",
    "GratisPicker",
    "Segment",
    "ChosenProduct",
] as const;

export type PromotionType = (typeof PromotionTypes)[number];

const Home = "Home";
const Office = "Office";

export type ProfileType = typeof Home | typeof Office;
export const ProfileTypes: ProfileType[] = [Home, Office];
