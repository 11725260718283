import { tValidation, validation } from "utils-ts/validations/translation";
import Validator from "./basic";

export const isVatinValid = (value) => {
    const digitVatin = value.split("").map((x) => Number(x));
    const sum = [6, 5, 7, 2, 3, 4, 5, 6, 7].map((w, i) => w * digitVatin[i]).reduce((c, n) => c + n, 0);

    return sum % 11 === digitVatin[9];
};

export default (vatin = "") => {
    return new Validator(vatin).must(isVatinValid, tValidation(validation.vatin)).when(Boolean(vatin));
};
