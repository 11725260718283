import { ContainerDocument, ContainerListRequest, ContainerListResponse } from "Commerce-Content";
import { generatePath } from "react-router-dom";
import { useCreateOrUpdateMutation, useDeleteMutation, useGetPagedQuery, useGetQuery } from "api/hooks";

const queryKey = "api/v1/content/containers/";

const url = (id?: string) => generatePath(`${queryKey}:id?`, { id: id ? id : null });

export const useGetCMSs = (params: ContainerListRequest) => {
    return useGetPagedQuery<ContainerListResponse, ContainerListRequest>({
        app: "commerce",
        url: url(),
        queryParams: params,
    });
};

export const useGetCMS = (id?: string) => {
    return useGetQuery<ContainerDocument>({
        app: "commerce",
        url: url(id),
        shouldMakeRequest: Boolean(id),
    });
};

export const useCreateOrUpdateCMS = (id?: string) => {
    return useCreateOrUpdateMutation<ContainerDocument, ContainerDocument>({
        app: "commerce",
        url: url(id),
        id: id,
    });
};

export const useDeleteCMS = (id?: string) => {
    return useDeleteMutation({
        app: "commerce",
        url: url(id),
    });
};
