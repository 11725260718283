import defaultListState from "store/defaultListState";
import { combineReducers } from "redux";
import types from "./types";

const initialState = {
    list: {
        shouldNavigate: true,
        facets: [],
        ...defaultListState,
    },
    filters: {
        search: "",
    },
};

export const filters = (state = initialState.filters, { type, payload }) => {
    switch (type) {
        case types.filtersChanged:
            return { ...payload };
        default:
            return state;
    }
};

export const list = (state = initialState.list, { type, payload }) => {
    switch (type) {
        case types.initializeCustomerList:
            return { ...initialState };
        case types.getCustomers.request:
            return { ...state, isLoading: true };
        case types.getCustomers.success:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case types.getCustomers.failure:
            return { ...state, isLoading: false };
        case types.updatePagination:
            return {
                ...state,
                ...payload,
            };
        default:
            return { ...state };
    }
};

export const customerList = combineReducers({
    filters,
    list,
});
