import { Button } from "components/Button";
import { makeStyles } from "tss-react/mui";
import { useCommonTranslation } from "utils/hooks";
import React, { Fragment } from "react";
import { Divider, Grid } from "@mui/material";

const useStyles = makeStyles()((theme) => ({
    button: {
        marginLeft: "auto",
    },
    divider: {
        marginTop: theme.spacing(10),
    },
}));

const Adable = ({ renderCondition = true, useDivider = true, handleAdd, children, className, noMarginInDiver = false, addButtonText }) => {
    const { classes } = useStyles();
    const { t, common } = useCommonTranslation();

    return (
        <Grid
            className={className}
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
        >
            <Grid item>
                {children.map((c, i) => {
                    return (
                        <Fragment key={i}>
                            {c}
                            {useDivider && children.length !== i + 1 ? (
                                <Divider
                                    className={noMarginInDiver ? undefined : classes.divider}
                                    variant="fullWidth"
                                />
                            ) : undefined}
                        </Fragment>
                    );
                })}
            </Grid>
            {renderCondition && (
                <Grid
                    item
                    className={classes.button}
                >
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleAdd}
                    >
                        {addButtonText ?? t(common.add)}
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};

export default Adable;
