import Layout from "components/Grid/Layout";
import TableWithToggleFilter from "components/Table/TableWithToggleFilter/TableWithToggleFilter";
import { actions } from "store/offers/content/list/actions";
import { contentListSelector } from "store/offers/selectors";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useCommonTranslation, useMappedDispatch } from "utils/hooks";
import { usePush } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";

const ContentList = () => {
    const { t, common } = useCommonTranslation();
    const { remove } = useMappedDispatch(actions);
    const { pushToForm: push } = usePush();
    const pushToForm = (id, isCopy) => push(Paths.Offers.ContentForm, id, isCopy);

    //prettier-ignore
    const columns = [
        { key: "name",       value: common.name,       type: formatTypes.text,                                                     },
        { key: "validFrom",  value: common.validFrom,  type: formatTypes.time, options: {timeFormat: timePrecisonFormats.seconds}, },
        { key: "validTo",    value: common.validTo,    type: formatTypes.time, options: {timeFormat: timePrecisonFormats.seconds}, },
        { key: "isActive",   value: common.active,     type: formatTypes.boolean,                                                  },
        { key: "createdAt",  value: common.createdAt,  type: formatTypes.time, options: {timeFormat: timePrecisonFormats.seconds}, },
        { key: "updatedAt",  value: common.updatedAt,  type: formatTypes.time, options: {timeFormat: timePrecisonFormats.seconds}, },
        { key: "action",     value: "",                type: formatTypes.action, actionType: "preview", action: item => pushToForm(item.id) },
        { key: "action",     value: "",                type: formatTypes.action, actionType: "copy", action: item => pushToForm(item.id, true)},
        { key: "action",     value: "",                type: formatTypes.action, actionType: "delete", action: item => remove(item.id), reloadOnAction: true},
    ].map(col => ({...col, value: t(col.value)}));

    return (
        <Layout
            main
            headerText={t(common.productContent)}
        >
            <TableWithToggleFilter
                columns={columns}
                addItemAction={() => pushToForm()}
                dataSelector={contentListSelector}
                dataSourceAction={actions.getContentList}
            />
        </Layout>
    );
};

export default ContentList;
