const administration = {
    confirmationPassword: "confirmationPassword",
    update: "update",
    userType: "userType",
    newUser: "newUser",
    basicData: "basicData",
    roles: "roles",
    createUser: "createUser",
    users: "users",
    userAdministration: "userAdministration",
} as const;

export default administration;
