import { types } from "./types";

const initialState = {};

export default (state = initialState, { type, payload, meta }) => {
    switch (type) {
        case types.initContractForm:
            return initialState;
        case types.getContract.request:
            return { ...initialState, isLoading: true };
        case types.getContract.success:
            return {
                ...state,
                ...payload,
                id: meta.isCopy ? "" : payload.id,
                isLoading: false,
            };
        case types.getContract.failure:
            return { ...payload, isLoading: false };
        case types.createOrUpdateContract.request:
            return { ...state, isSubbmitRequested: true };
        case types.createOrUpdateContract.success:
        case types.createOrUpdateContract.failure:
            return { ...state, isSubbmitRequested: false };
        default:
            return state;
    }
};
