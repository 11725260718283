import { withStyles } from "tss-react/mui";
import React from "react";
import { Dialog as DialogBase } from "@mui/material/";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";

const styles = (theme) => ({
    dialogTitle: {
        backgroundColor: "#A20652",
        color: theme.palette.getContrastText("#A20652"),
        fontSize: "1.5rem",
    },
    children: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
    },
    root: {
        minwidth: 500,
    },
});

class Dialog extends React.Component {
    render() {
        const { children, onClose, title, maxWidth, ...other } = this.props;
        const classes = withStyles.getClasses(this.props);

        return (
            <DialogBase
                className={classes.container}
                {...other}
                fullWidth
                maxWidth={maxWidth || "md"}
                onClose={() => onClose()}
                aria-labelledby="simple-dialog-title"
            >
                {title ? (
                    <DialogTitle
                        className={classes.dialogTitle}
                        id="simple-dialog-title"
                    >
                        {title}
                    </DialogTitle>
                ) : undefined}
                <div className={classes.children}>{children}</div>
            </DialogBase>
        );
    }
}

Dialog.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    selectedValue: PropTypes.string,
};

export default withStyles(Dialog, styles);
