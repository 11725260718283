import { Button } from "components/Button";
import { AcceptRejectDialog, InfoDialog, useModalState } from "components/Dialog";
import { Form, FormField } from "components/Form";
import { WaitForResource } from "components/Preloader";
import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import { TabLabel, Tabs } from "components/Tabs";
import TextWithLabel from "components/Text/TextWithLabel";
import roles from "consts/roles";
import { reduxForm } from "redux-form";
import {
    balanceDebtFormSelector,
    clearBalanceDebt,
    deleteActivity,
    editAction,
    findUserBalanceDebtActivities,
    getBalanceDebt,
    mute,
    takeAction,
} from "store/balanceDebts";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useAppLocation, useCommonTranslation, useParams, useRouterState } from "utils/hooks";
import { Validator, greaterDateThan, notEmptyValidator } from "utils/validators";
import { useCallback, useEffect, useMemo, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import moment from "moment";
import { Layout } from "components";
import { useUser } from "context";
import { usePush } from "utils-ts/hooks";
import { tValidation, validation } from "utils-ts/validations/translation";
import { default as reasons } from "./activityReasons";
import balanceChangeReasons from "./balanceChangeReasons";

const required = (val) => notEmptyValidator(val).validate();

const nextAtValidation = (val) =>
    !val
        ? undefined
        : new Validator(val)
              .must(
                  greaterDateThan(moment()),
                  tValidation(validation.greaterDateThan, {
                      date: moment().format(timePrecisonFormats.seconds),
                  })
              )
              .validate();

const FormComponent = connect((state, props) => {
    const { activityId } = props;

    if (!activityId) return {};

    const { balanceDebt } = balanceDebtFormSelector(state);
    const activity = balanceDebt.recentActivities?.find((x) => x.activityId === activityId);

    if (!activity) return {};

    return {
        activity: activity,
        initialValues: {
            activityReason: activity.activityReason,
            changeReason: activity.changeReason,
            nextAt: activity.nextAt,
            message: activity.message,
        },
    };
})(
    reduxForm({
        form: "balance-debt-form",
    })(({ handleClose, handleSubmit, activity = {} }) => {
        const { id } = useParams();
        const dispatch = useDispatch();
        const { t, common } = useCommonTranslation();
        const { activityId } = activity;
        const isNew = !activityId;

        const activityReasons = useMemo(() => reasons.map((o) => ({ name: t(o), value: o })), [t]);

        const changeReasons = useMemo(() => balanceChangeReasons.map((o) => ({ name: t(o), value: o })), [t]);

        return (
            <Form
                onSubmit={handleSubmit(async (values) => {
                    const result = await dispatch(
                        isNew
                            ? takeAction(id, values)
                            : editAction(id, activityId, {
                                  message: values.message,
                              })
                    );

                    if (result.type.includes("SUCCESS")) {
                        dispatch(getBalanceDebt(id));
                        handleClose();
                    }
                })}
            >
                {isNew || !!activity.activityReason ? (
                    <FormField
                        name="activityReason"
                        type="select"
                        label={t(common.reason)}
                        items={activityReasons}
                        validate={isNew && required}
                        readOnly={!isNew}
                    />
                ) : (
                    <FormField
                        name="changeReason"
                        type="select"
                        label={t(common.balanceChangeReason)}
                        items={changeReasons}
                        readOnly
                    />
                )}
                <FormField
                    name="nextAt"
                    type="dateTime"
                    label={t(common.nextActionAt)}
                    validate={isNew && nextAtValidation}
                    disablePast
                    readOnly={!isNew}
                />
                <FormField
                    name="message"
                    type="text"
                    label={t(common.message)}
                    fullWidth
                    multiline
                />
            </Form>
        );
    })
);

const Actions = ({ balanceDebt, isReadonly, focusedUserIndex }) => {
    const appLocation = useAppLocation();
    const dispatch = useDispatch();
    const { push } = usePush();
    const { id } = useParams();
    const { t, common } = useCommonTranslation();
    const [open, setOpen] = useState(false);
    const { handleClose: handleCloseMute, handleOpen: handleOpenMute, modalState: muteModalState } = useModalState();

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const handleOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const navigateToCustomer = useCallback(() => {
        window.open(`${appLocation}/customers/${id}`, "_blank");
    }, [appLocation, id]);

    const muteDebt = useCallback(async () => {
        await dispatch(mute(id));
        handleCloseMute();
        push(`/balance-debts`, { focusedUserIndex });
    }, [dispatch, handleCloseMute, id, focusedUserIndex]);

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
            >
                {!isReadonly && (
                    <Grid item>
                        <Button
                            actionType="warning"
                            onClick={handleOpen}
                        >
                            {t(common.takeAction)}
                        </Button>
                    </Grid>
                )}
                <Grid item>
                    <Button
                        actionType="primary"
                        onClick={navigateToCustomer}
                    >
                        {t(common.customer)}
                    </Button>
                </Grid>
                {!isReadonly && (
                    <Grid item>
                        <Button
                            actionType="primary"
                            onClick={handleOpenMute}
                        >
                            {t(common.remove)}
                        </Button>
                    </Grid>
                )}
            </Grid>
            <AcceptRejectDialog
                maxWidth={"sm"}
                title={t(common.muteCustomerDebt)}
                open={muteModalState}
                handleAccept={muteDebt}
                handleReject={handleCloseMute}
                buttonAcceptText={t(common.remove)}
                buttonRejectText={t(common.cancel)}
            >
                {muteModalState &&
                    t(common.shouldMuteCustomerDebt, {
                        user: `${balanceDebt.user.fullName.firstName} ${balanceDebt.user.fullName.lastName} (${id})`,
                    })}
            </AcceptRejectDialog>
            <InfoDialog
                open={open}
                title={t(common.takeAction)}
                onClose={handleClose}
            >
                <FormComponent handleClose={handleClose} />
            </InfoDialog>
        </>
    );
};

const BalanceDebt = ({ balanceDebt }) => {
    const { t, common } = useCommonTranslation();
    const fields = useMemo(
        () => [
            {
                label: common.fullName,
                value: balanceDebt.user.fullName,
                format: formatTypes.fullName,
            },
            {
                label: common.balance,
                value: balanceDebt.balanceAmount,
                format: formatTypes.currencyAmount,
            },
            {
                label: common.creditLimit,
                value:
                    balanceDebt.creditLimit === undefined && balanceDebt.previousCreditLimit !== undefined
                        ? {
                              ...balanceDebt.previousCreditLimit,
                              isLost: true,
                          }
                        : balanceDebt.creditLimit,
                format: formatTypes.creditLimit,
                options: { useFullLabel: true },
            },
        ],
        [balanceDebt.balanceAmount, balanceDebt.user.fullName, balanceDebt.creditLimit, balanceDebt.previousCreditLimit, t]
    );

    return balanceDebt ? (
        <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >
            {fields.map((field, i) => (
                <Grid
                    item
                    key={i}
                >
                    <TextWithLabel
                        unselectableLabel
                        {...field}
                    />
                </Grid>
            ))}
        </Grid>
    ) : (
        <></>
    );
};

const BalanceDebtHistory = ({ activities, isReadonly, removeActivity, isLoading = false, pagination = false, paginationProps = undefined }) => {
    const { t, common } = useCommonTranslation();
    const [editedActivity, setEditedActivity] = useState(undefined);
    const [removeActivityId, setRemoveActivityId] = useState(undefined);

    const handleCloseMessageDialog = useCallback(() => {
        setEditedActivity(undefined);
    }, []);

    const handleCloseRemoveDialog = () => {
        setRemoveActivityId(undefined);
    };

    let col = [
        {
            key: "createdAt",
            value: t(common.takenAt),
            type: formatTypes.time,
            options: { timeFormat: timePrecisonFormats.seconds },
        },
        {
            key: "nextAt",
            value: t(common.nextActionAt),
            type: formatTypes.time,
            options: { timeFormat: timePrecisonFormats.seconds },
        },
        {
            key: "createdBy",
            value: t(common.user),
            type: formatTypes.text,
        },
        {
            key: "activityReason",
            value: t(common.reason),
            type: formatTypes.translatable,
            options: { translationKey: "common" },
        },
        {
            key: "isOrderVariableWeight",
            value: "",
            type: formatTypes.text,
        },
        {
            key: "isBalanceSettled",
            value: "",
            type: formatTypes.infoIcon,
            options: {
                tooltip: "Saldo wyrównane, wcześniej wyznaczone daty nie są brane pod uwagę",
            },
        },
        {
            key: "changeReason",
            value: t(common.balanceChangeReason),
            type: formatTypes.translatable,
            options: { translationKey: "common" },
        },
        {
            key: "message",
            value: t(common.message),
            type: formatTypes.text,
        },
    ];

    if (!isReadonly) {
        col.push({
            key: "edit",
            value: "",
            type: "action",
            actionType: "edit",
            action: (item) => setEditedActivity(item.activityId),
            disabled: () => isReadonly,
        });
        col.push({
            key: "remove",
            value: "",
            type: "action",
            actionType: "delete",
            action: (item) => setRemoveActivityId(item.activityId),
            disabled: (item) => {
                return isReadonly || item.createdBy === "System";
            },
        });
    }

    const columns = useMemo(() => col, [col]);
    const activityToRemove = activities.find((a) => a.activityId === removeActivityId) || {
        activity: {},
    };

    return activities ? (
        <>
            <InfoDialog
                open={!!editedActivity}
                title={t(common.editAction)}
                onClose={handleCloseMessageDialog}
            >
                <FormComponent
                    handleClose={handleCloseMessageDialog}
                    activityId={editedActivity}
                />
            </InfoDialog>
            <AcceptRejectDialog
                title={t(common.remove)}
                maxWidth={"sm"}
                open={Boolean(removeActivityId)}
                handleAccept={async () => {
                    removeActivity(removeActivityId);
                    handleCloseRemoveDialog();
                }}
                handleReject={() => {
                    handleCloseRemoveDialog();
                }}
            >
                {activityToRemove && (
                    <Typography variant="body1">
                        {t(common.areYouSure, {
                            what: `usunąć akcję z powodem "${t(activityToRemove.activity.activityReason)}" wprowadzoną przez "${
                                activityToRemove.createdBy
                            }" w dniu ${moment(activityToRemove.createdAt).format(timePrecisonFormats.seconds)}`,
                        })}
                    </Typography>
                )}
            </AcceptRejectDialog>

            <SimpleTable
                columns={columns}
                data={activities}
                isLoading={isLoading}
                pagination={pagination}
                paginationProps={paginationProps}
                hover
                stripped
            />
        </>
    ) : (
        <></>
    );
};

const BalanceDebtForm = () => {
    const { isInRoleOrAdmin } = useUser();
    const { t, common } = useCommonTranslation();
    const { id } = useParams();
    const dispatch = useDispatch();
    const { isLoading, balanceDebt, hasError, activities } = useSelector(balanceDebtFormSelector);
    const isReadonly = !isInRoleOrAdmin(roles.DebtCoordinator);
    const { focusedUserIndex } = useRouterState();

    const loadResource = useCallback(() => {
        dispatch(getBalanceDebt(id));
        dispatch(
            findUserBalanceDebtActivities({
                customerId: id,
                pageIndex: activities.pageIndex,
                pageSize: activities.pageSize,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, id]);

    useEffect(() => {
        loadResource();

        return () => {
            dispatch(clearBalanceDebt());
        };
    }, [dispatch, loadResource]);

    const removeActivity = async (activityId) => {
        await dispatch(deleteActivity(id, activityId));
        await dispatch(getBalanceDebt(id));
    };
    const tabLabels = [new TabLabel(t(common.summary), [roles.DebtCoordinator]), new TabLabel(t(common.details), [roles.DebtCoordinator])];

    const onPageChange = (pageIndex) => {
        dispatch(
            findUserBalanceDebtActivities({
                customerId: id,
                pageIndex,
                pageSize: activities.pageSize,
            })
        );
    };

    const onRowsPerPageChange = (pageSize) => {
        dispatch(
            findUserBalanceDebtActivities({
                customerId: id,
                pageIndex: 1,
                pageSize,
            })
        );
    };

    return (
        <Layout
            main
            headerText={t(common.balanceDebt, { id })}
            navigationProps={{
                backProps: {
                    path: "/balance-debts",
                    state: { focusedUserId: id },
                },
            }}
        >
            <WaitForResource
                isLoading={isLoading}
                hasError={hasError}
                retry={loadResource}
            >
                <Actions
                    balanceDebt={balanceDebt}
                    isReadonly={isReadonly}
                    focusedUserIndex={focusedUserIndex}
                />
                <BalanceDebt balanceDebt={balanceDebt} />
                <Tabs tableLabels={tabLabels}>
                    <BalanceDebtHistory
                        activities={balanceDebt.recentActivities}
                        isReadonly={isReadonly}
                        removeActivity={removeActivity}
                    />

                    <BalanceDebtHistory
                        activities={activities.items}
                        removeActivity={removeActivity}
                        isReadonly
                        pagination
                        paginationProps={{
                            pageIndex: activities.pageIndex,
                            totalCount: activities.totalCount,
                            pageSize: activities.pageSize,
                            onPageChange: onPageChange,
                            onRowsPerPageChange: onRowsPerPageChange,
                        }}
                    />
                </Tabs>
            </WaitForResource>
        </Layout>
    );
};

export default BalanceDebtForm;
