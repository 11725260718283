import { createSelector } from "reselect";

const root = (state) => ({
    certificates: state.certificates,
});

export const certyficateListSelector = createSelector(root, (state) => state.certificates.list);

export const certyficateFormSelector = createSelector(root, (state) => {
    const form = state.certificates.form;
    const { file = {} } = form;
    const { blobName = "" } = file;

    return {
        ...form,
        fileHistory: form.fileHistory?.map((f) => ({
            ...f,
            fileName: f.file.blobName.split("_")?.slice(2).join("_"),
        })),
        fileName: blobName.split("_")?.slice(2).join("_"),
        blobName,
    };
});
