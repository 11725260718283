import { makeStyles } from "tss-react/mui";
import { Divider, Grid } from "@mui/material";

const useStyles = makeStyles()({
    divider: {
        margin: 0,
        height: 2,
    },
    dividerItem: {
        margin: "20px 0px 10px 0px",
    },
});

const DividerInGrid = ({ hidden }) => {
    const { classes } = useStyles();

    return (
        <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >
            <Grid
                item
                xs={12}
                className={classes.dividerItem}
            >
                <Divider
                    variant="middle"
                    className={classes.divider}
                    hidden={hidden}
                />
            </Grid>
        </Grid>
    );
};

export default DividerInGrid;
