import { asFloat } from "utils/formating/formaters";
import moment from "moment";
import _ from "lodash";
import { createSelector } from "reselect";

const root = (state) => state.turnovers;

export const turnoverFormSelector = createSelector(root, ({ form }) => {
    return {
        ...form,
        invoiceSummaryTotal: form.invoiceSummaryTotal
            ? asFloat(form.invoiceSummaryTotal, {
                  useThousandsSeparator: true,
                  decimalSeparator: ".",
              }) + " zł"
            : "Brak danych",
        transactionSummaryTotal: form.transactionSummaryTotal
            ? asFloat(form.transactionSummaryTotal, {
                  useThousandsSeparator: true,
                  decimalSeparator: ".",
              }) + " zł"
            : "Brak danych",
        vendorSummaryTotal: form.vendorSummaryTotal
            ? asFloat(form.vendorSummaryTotal, {
                  useThousandsSeparator: true,
                  decimalSeparator: ".",
              })
            : form.vendorSummaryTotalFile
            ? 0
            : "Brak danych",
        vendorSummaryTotalFile: form.vendorSummaryTotalFile && {
            ...form.vendorSummaryTotalFile,
            name: form.vendorSummaryTotalFile?.fileName,
        },
        details: _(form.details)
            .map((value, key) => {
                return {
                    key,
                    ...value,
                    vendorSummary: value.vendorSummary || 0,
                    transactionSummary: value.transactionSummary
                        ? asFloat(value.transactionSummary, {
                              useThousandsSeparator: true,
                              decimalSeparator: ".",
                          })
                        : "Brak danych",
                    invoiceSummary: value.invoiceSummary
                        ? asFloat(value.invoiceSummary, {
                              useThousandsSeparator: true,
                              decimalSeparator: ".",
                          })
                        : "Brak danych",
                    file: value.file && {
                        ...value.file,
                        name: value.file.fileName,
                    },
                    corrections: value.corrections.map((c) => ({
                        ...c,
                        vendorSummaryDifference: c.newVendorSummary - c.oldVendorSummary,
                    })),
                };
            })
            .value(),
    };
});

export const turnoverMonthsSelector = createSelector(turnoverFormSelector, (form) => form.months);

export const turnoverListSelector = createSelector(root, (turnovers) => {
    return {
        ...turnovers.list,
        items: turnovers.list.items.map((i) => ({
            ...i,
            month: moment(i.month, "MM").format("MMMM"),
        })),
    };
});
