import { formValueSelector } from "redux-form";
import { useSelector } from "react-redux";

export default (form, formSection, defaultValue) => {
    const isFields = Array.isArray(formSection);
    const fields = isFields ? formSection : [formSection];
    const values = useSelector((state) => formValueSelector(form)(state, ...fields)) || defaultValue || {};

    return values;
};
