import moment from "moment";

export const identity = (x) => x;

export const chunks = (array, size) => Array.from({ length: Math.ceil(array.length / size) }, (_v, i) => array.slice(i * size, i * size + size));

export const combine = (...args) => args.filter(identity).join(" ");

export const isStartOfRange = ({ startDate }, day) => startDate && isSameDay(day, startDate);

export const isEndOfRange = ({ endDate }, day) => endDate && isSameDay(day, endDate);

export const inDateRange = ({ startDate, endDate }, day) =>
    (startDate && endDate && moment(day).isBetween(startDate, endDate, "day", "[]")) || isSameDay(day, startDate) || isSameDay(day, endDate);

export const isRangeSameDay = ({ startDate, endDate }) => {
    if (startDate && endDate) {
        return isSameDay(startDate, endDate);
    }
    return false;
};

export const parseOptionalDate = (date, defaultValue) => {
    if (date) {
        const parsed = moment(date);
        if (parsed.isValid()) return parsed;
    }
    return defaultValue;
};

export const getValidatedMonths = (range, minDate, maxDate) => {
    const { startDate, endDate } = range;
    if (startDate && endDate) {
        const newStart = moment.max(moment(startDate), moment(minDate));
        const newEnd = moment.min(moment(endDate), moment(maxDate));

        return [newStart, isSameMonth(newStart, newEnd) ? newStart.clone().add(1, "month") : newEnd];
    }
    return [startDate, endDate];
};

export const getDaysInMonth = (date, locale) => {
    const startWeek = moment(date).startOf("month").startOf("week");
    const endWeek = moment(date).endOf("month").endOf("week");
    const days = [];
    for (let curr = startWeek; curr.isBefore(endWeek); ) {
        days.push(curr.toDate());
        curr = curr.add(1, "day");
    }
    return days;
};

export const isSameDay = (day1, day2) => moment(day1).isSame(day2, "day");
export const isSameMonth = (day1, day2) => moment(day1).isSame(day2, "month");
export const isWithinInterval = (day, { start, end }) => moment(day).isBetween(start, end, "day", "[]");
