export default (object: unknown, fileName: string) => {
    const clickEvent = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
    });
    const data = `text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(object))}`;

    const element = document.createElement("a");

    element.setAttribute("href", `data: ${data}`);
    element.setAttribute("download", `${fileName}.json`);
    element.dispatchEvent(clickEvent);
    element.remove();
};
