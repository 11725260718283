import { combineReducers } from "redux";
import { default as keywords } from "./keywords/reducer.js";
import { default as phraseBoost } from "./phraseBoost/reducer.js";
import { default as synonyms } from "./synonyms/reducer.js";

export const search = combineReducers({
    phraseBoost,
    keywords,
    synonyms,
});
