import React from "react";
import { generatePath } from "react-router-dom";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import { ECommerceDepartment, MarketingDepartment, Role, SalesDepartment, TradeMarketingDepartment } from "routing-ts/roles";

const KeywordsForm = React.lazy(() =>
    import("views/search").then((module) => ({
        default: module.KeywordsForm,
    }))
);
const KeywordsList = React.lazy(() =>
    import("views/search").then((module) => ({
        default: module.KeywordsList,
    }))
);
const PhraseBoostForm = React.lazy(() =>
    import("views/search").then((module) => ({
        default: module.PhraseBoostForm,
    }))
);
const PhraseBoostList = React.lazy(() =>
    import("views/search").then((module) => ({
        default: module.PhraseBoostList,
    }))
);
const Synonyms = React.lazy(() =>
    import("views/search").then((module) => ({
        default: module.Synonyms,
    }))
);

const searchRoles: Role[] = [
    "Analyst",
    "Management",
    ...ECommerceDepartment.GetRoles(),
    ...SalesDepartment.GetRoles(),
    ...MarketingDepartment.GetRoles(),
    ...TradeMarketingDepartment.GetRoles(),
];

export const Search: PrivateRoute[] = [
    new PrivateRoute("/search", searchRoles, undefined, "search", "search", [
        new PrivateRoute(Paths.Search.PhraseBoostsList, searchRoles, PhraseBoostList, "phraseBoost", "spellcheck"),
        new PrivateRoute(Paths.Search.PhraseBoostForm, searchRoles, PhraseBoostForm),
        new PrivateRoute(Paths.Search.KeywordsList, searchRoles, KeywordsList, "keywords", "flare"),
        new PrivateRoute(Paths.Search.KeywordForm, searchRoles, KeywordsForm),
        new PrivateRoute(Paths.Search.Synonyms, searchRoles, Synonyms),
        new PrivateRoute(generatePath(Paths.Search.Synonyms, { language: "pl" }), searchRoles, undefined, "synonymsPl", "sync_alt"),
        new PrivateRoute(generatePath(Paths.Search.Synonyms, { language: "en" }), searchRoles, undefined, "synonymsEn", "sync_alt"),
    ]),
];
