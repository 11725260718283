import { RSAA } from "redux-api-middleware";
import { cache, commerce, manager } from "store/actionTypePrefixes";
import { actions as userInfosActions } from "store/cache/userInfos/actions";
import { asAsyncFormTypes } from "utils/extensions/arrayExtensions";
import { combineIntoQueryParams } from "utils/objectExtensions";
import moment from "moment";

export const types = {
    CUSTOMER_VOUCHER_GET_LIST: `${commerce}/CUSTOMER_VOUCHER_GET_LIST`,
    CUSTOMER_VOUCHER_GET_LIST_SUCCESS: `${commerce}/CUSTOMER_VOUCHER_GET_LIST_SUCCESS`,
    CUSTOMER_VOUCHER_GET_LIST_FAILURE: `${commerce}/CUSTOMER_VOUCHER_GET_LIST_FAILURE`,
    CUSTOMER_VOUCHERS_GET_LIST_WITH_PROFILE: `${cache}/CUSTOMER_VOUCHERS_GET_LIST_WITH_PROFILE`,
    CUSTOMER_MAKE_VOUCHER: `${commerce}/CUSTOMER_MAKE_VOUCHER`,
    CUSTOMER_MAKE_VOUCHER_SUCCESS: `${commerce}/CUSTOMER_MAKE_VOUCHER_SUCCESS`,
    CUSTOMER_MAKE_VOUCHER_FAILURE: `${commerce}/CUSTOMER_MAKE_VOUCHER_FAILURE`,
    CUSTOMER_VOUCHER_GET: `${commerce}/CUSTOMER_VOUCHER_GET`,
    CUSTOMER_VOUCHER_GET_SUCCESS: `${commerce}/CUSTOMER_VOUCHER_GET_SUCCESS`,
    CUSTOMER_VOUCHER_GET_FAILURE: `${commerce}/CUSTOMER_VOUCHER_GET_FAILURE`,
    CUSTOMER_VOUCHER_CLEAR_VOUCHER: `${manager}/CUSTOMER_VOUCHER_CLEAR_VOUCHER`,
};

const getVouchers = (args) => async (dispatch) => {
    const params = combineIntoQueryParams(args);
    const voucherResult = await dispatch({
        [RSAA]: {
            endpoint: `api/v1/vouchers/vouchers?${params}`,
            method: "GET",
            types: [types.CUSTOMER_VOUCHER_GET_LIST, types.CUSTOMER_VOUCHER_GET_LIST_SUCCESS, types.CUSTOMER_VOUCHER_GET_LIST_FAILURE],
        },
    });
    const { items, ...rest } = voucherResult.payload;
    const ids = items
        .map((x) => x.createdBy || undefined)
        .reduce((c, n) => {
            if (!n) return c;
            if (c.every((x) => x !== n)) return [...c, n];
            return c;
        }, []);
    await dispatch(userInfosActions.getUserInfos(ids));
    await dispatch({
        type: types.CUSTOMER_VOUCHERS_GET_LIST_WITH_PROFILE,
        payload: {
            ...rest,
            items,
        },
    });
};

const makeVoucher = (voucher, voucherId) => async (dispatch) => {
    const { isSending, validFrom, validTo, ...rest } = voucher;
    const params = combineIntoQueryParams({ isSending });
    const body = JSON.stringify({
        validFrom: moment(validFrom).toISOString(),
        validTo: moment(validTo).toISOString(),
        ...rest,
    });

    return await dispatch({
        [RSAA]: {
            endpoint: voucherId ? `api/v1/vouchers/vouchers/${voucherId}?${params}` : `api/v1/vouchers/vouchers?${params}`,
            headers: { "Content-Type": "application/json" },
            method: voucherId ? "PUT" : "POST",
            types: asAsyncFormTypes([types.CUSTOMER_MAKE_VOUCHER, types.CUSTOMER_MAKE_VOUCHER_SUCCESS, types.CUSTOMER_MAKE_VOUCHER_FAILURE]),
            body,
        },
    });
};

const getVoucher = (voucherId) => async (dispatch) => {
    await dispatch({
        [RSAA]: {
            endpoint: `api/v1/vouchers/vouchers/${voucherId}`,
            headers: { "Content-Type": "application/json" },
            method: "GET",
            types: [types.CUSTOMER_VOUCHER_GET, types.CUSTOMER_VOUCHER_GET_SUCCESS, types.CUSTOMER_VOUCHER_GET_FAILURE],
        },
    });

    return Promise.resolve();
};

const customerClearVoucher = (userId) => async (dispatch) => {
    await dispatch({ type: types.CUSTOMER_VOUCHER_CLEAR_VOUCHER });
    return Promise.resolve();
};

export const actions = {
    getVouchers,
    getVoucher,
    makeVoucher,
    customerClearVoucher,
};
