import { FormField } from "components/Form";
import deliveryMethods from "resource/deliveryMethods.json";
import { useTranslation } from "react-i18next";
import { voucher } from "translations";

const DeliveryMethodsSelectField = (props) => {
    const { t } = useTranslation("voucher");
    return (
        <FormField
            {...props}
            name={"deliveryMethods"}
            label={t(voucher.deliveryMethods)}
            type="select"
            multiple
            items={deliveryMethods}
        />
    );
};

export default DeliveryMethodsSelectField;
