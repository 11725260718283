import { commerce, manager } from "store/actionTypePrefixes";
import { AsyncTypes, asAsyncFormTypes, createAction } from "utils/extensions";

const types = {
    formInit: createAction("FORM_INIT_DYNAMIC_SEGMENT", manager),
    getDynamicSegments: new AsyncTypes("GET_DYNAMIC_SEGMENTS", commerce),
    getDynamicSegment: new AsyncTypes("GET_DYNAMIC_SEGMENT", commerce),
    createDynamicSegment: new AsyncTypes("CREATE_DYNAMIC_SEGMENT", commerce, [(types) => asAsyncFormTypes(types)]),
    updateDynamicSegment: new AsyncTypes("UPDATE_DYNAMIC_SEGMENT", commerce, [asAsyncFormTypes]),
    deleteDynamicSegment: new AsyncTypes("DELETE_DYNAMIC_SEGMENT", commerce),
    updateDynamicSegmentUsers: new AsyncTypes("UPDATE_DYNAMIC_SEGMENT_USERS", commerce),
    getDynamicSegmentUsers: new AsyncTypes("GET_DYNAMIC_SEGMENT_USERS", commerce),
};

export default types;
