import { usePush } from "utils-ts/hooks";

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        const { push, pushToForm } = usePush();
        return (
            <Component
                {...props}
                push={push}
                pushToForm={pushToForm}
            />
        );
    }

    return ComponentWithRouterProp;
}

export default withRouter;
