import Layout from "components/Grid/Layout";
import TableWithToggleFilter from "components/Table/TableWithToggleFilter/TableWithToggleFilter";
import { actions } from "store/offers/contracts/list/actions";
import { contractsListSelector } from "store/offers/selectors";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useCommonTranslation } from "utils/hooks";
import { usePush } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";

const ContractsList = () => {
    const { t, common } = useCommonTranslation();
    const { pushToForm: push } = usePush();
    const pushToForm = (id, isCopy) => push(Paths.Offers.ContractForm, id, isCopy);

    //prettier-ignore
    const columns = [
        { key: "name",        value: common.name,        type: formatTypes.text,                                                     },
        { key: "campaignName",value: common.campaignName,type: formatTypes.text,                                                     },
        { key: "validFrom",   value: common.validFrom,   type: formatTypes.time, options: {timeFormat: timePrecisonFormats.days},    },
        { key: "validTo",     value: common.validTo,     type: formatTypes.time, options: {timeFormat: timePrecisonFormats.days},    },
        { key: "isActive",    value: common.active,      type: formatTypes.boolean,                                                  },
        { key: "createdAt",   value: common.createdAt,   type: formatTypes.time, options: {timeFormat: timePrecisonFormats.seconds}, },
        { key: "updatedAt",   value: common.updatedAt,   type: formatTypes.time, options: {timeFormat: timePrecisonFormats.seconds}, },
        { key: "action",      value: "",                 type: formatTypes.action, actionType: "preview", action: item => pushToForm(item.id) },
        { key: "action",      value: "",                 type: formatTypes.action, actionType: "copy",  action: item => pushToForm(item.id, true)},
    ].map(col => ({...col, value: t(col.value)}));

    return (
        <Layout
            main
            headerText={t(common.contracts)}
        >
            <TableWithToggleFilter
                columns={columns}
                addItemAction={() => pushToForm()}
                dataSelector={contractsListSelector}
                dataSourceAction={actions.getContractsList}
            />
        </Layout>
    );
};

export default ContractsList;
