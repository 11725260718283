import Button from "components/Button/Button";
import Layout from "components/Grid/Layout";
import { reduxForm } from "redux-form";
import { actions } from "store/customers/customer/customerInfo/action";
import { actions as profileActions } from "store/customers/customer/customerProfileActions/action";
import { useParams } from "utils/hooks";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { bindActionCreators } from "redux";
import { common, customers } from "translations";
import { usePush, useTranslation } from "utils-ts/hooks";
import Form from "./customerEdit/Form";

const CustomerEdit = ({ pristine, handleSubmit, putCustomerInProgress, editCustomer }) => {
    const { t } = useTranslation(["customers", "common"]);
    const { customerId } = useParams();
    const { push } = usePush();

    const submit = (values) => {
        editCustomer(customerId, values);
        push(`/customers/${customerId}`);
    };

    return (
        <Layout
            main
            headerText={t(customers.editCustomerWithId, { customerId })}
            navigationProps={{
                backProps: {
                    path: `/customers/${customerId}`,
                    state: { tabIndex: 0 },
                },
            }}
        >
            <form onSubmit={handleSubmit((val) => submit(val))}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    <Grid
                        item
                        xs={4}
                    >
                        <Form.PersonalInfo />
                    </Grid>
                    <Grid
                        item
                        xs={4}
                    >
                        <Form.BillingAddress />
                    </Grid>
                    <Grid
                        item
                        xs={4}
                    >
                        <Form.Password />
                    </Grid>
                </Grid>
                <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type={"submit"}
                    disabled={putCustomerInProgress || pristine}
                    loading={putCustomerInProgress}
                >
                    {t(`common:${common.submit}`)}
                </Button>
            </form>
        </Layout>
    );
};

const mapStateToProps = (state) => {
    const {
        customer: {
            customerInfo: { fullName, email, phoneNumber, mobileNumber, billingAddress },
            customerProfileActions: { putCustomerInProgress },
        },
    } = state;
    return {
        initialValues: {
            fullName,
            phoneNumber,
            mobileNumber,
            email,
            initialEmail: email,
            billingAddress,
        },
        putCustomerInProgress,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ ...actions, ...profileActions }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: "customerForm",
        enableReinitialize: true,
        validate: Form.validate,
    })(CustomerEdit)
);
