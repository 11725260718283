import React from "react";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import { SalesDepartment } from "routing-ts/roles";

const UserReplacementForm = React.lazy(() =>
    import("views/replacements").then((module) => ({
        default: module.UserReplacementForm,
    }))
);
const UserReplacementsList = React.lazy(() =>
    import("views/replacements").then((module) => ({
        default: module.UserReplacementsList,
    }))
);

export const Replacements: PrivateRoute[] = [
    new PrivateRoute(Paths.ReplacementsList, SalesDepartment.GetRoles(), UserReplacementsList, "replacements", "swap_horiz"),
    new PrivateRoute(Paths.ReplacementForm, SalesDepartment.GetRoles(), UserReplacementForm),
];
