import { commerce } from "store/actionTypePrefixes";
import { AsyncTypes, asSilientError } from "utils/extensions";

const types = {
    getCMS: new AsyncTypes("GET_CMS_CONTAINER", commerce, [asSilientError]),
    getPromotion: new AsyncTypes("GET_PROMOTION_CONTAINER", commerce, [asSilientError]),
    getDiscount: new AsyncTypes("GET_DISCOUNT_CONTAINER", commerce, [asSilientError]),
};

export default types;
