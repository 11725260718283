import useButtonStyle from "assets/jss/material-dashboard-react/components/buttonStyle";
import Button from "@mui/material/Button";
import classNames from "classnames";
import PropTypes from "prop-types";

function RegularButton({ ...props }) {
    const { color, round, children, disabled, simple, size, block, link, justIcon, className, muiClasses, ...rest } = props;

    const { classes } = useButtonStyle();

    const btnClasses = classNames({
        [classes.button]: true,
        [classes[size]]: size,
        [classes[color]]: color,
        [classes.round]: round,
        [classes.disabled]: disabled,
        [classes.simple]: simple,
        [classes.block]: block,
        [classes.link]: link,
        [classes.justIcon]: justIcon,
        [className]: className,
    });

    return (
        <Button
            {...rest}
            className={btnClasses}
        >
            {children}
        </Button>
    );
}

RegularButton.propTypes = {
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger", "rose", "white", "transparent"]),
    size: PropTypes.oneOf(["sm", "lg"]),
    simple: PropTypes.bool,
    round: PropTypes.bool,
    disabled: PropTypes.bool,
    block: PropTypes.bool,
    link: PropTypes.bool,
    justIcon: PropTypes.bool,
    className: PropTypes.string,
    // use this to pass the classes props from Material-UI
    muiClasses: PropTypes.object,
};

export default RegularButton;
