import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { signinRedirectKey, userManager } from "utils-ts/auth/userManager";
import { usePush } from "utils-ts/hooks";

export const SilentRenewPage = () => {
    const { isLoading, isAuthenticated, user, error } = useAuth();
    const { replace } = usePush();

    console.log({
        isLoading,
        isAuthenticated,
        user,
        error,
        redirect: localStorage.getItem(signinRedirectKey),
        href: window.location.href,
    });

    useEffect(() => {
        userManager
            .signinSilentCallback(window.location.href)
            .then(
                (user) => {
                    console.debug("silent success");
                    const prev = localStorage.getItem(signinRedirectKey);
                    localStorage.removeItem(signinRedirectKey);
                    if (prev) {
                        replace(prev);
                    } else {
                        replace("/");
                    }
                },
                (error) => {
                    console.log({ res: "silent reject", error });
                }
            )
            .catch((err) => {
                console.log({ res: "silent error", err });
            });
    }, []);

    if (error) {
        console.error(error);
    }

    return <div>Loading... (silent)</div>;
};
