import GridContainer from "components/Grid/GridContainer";
import TextWithLabel from "components/Text/TextWithLabel";
import { formatTypes } from "utils/formating";
import { useCommonTranslation } from "utils/hooks";
import React, { Fragment } from "react";
import { Grid } from "@mui/material";
import _ from "lodash";

const BillingAdress = ({ billingAddress }) => {
    const { t, common } = useCommonTranslation();
    if (!billingAddress) return <Fragment />;

    const { companyName, vatin, phoneNumber } = billingAddress;
    if (!companyName) return <React.Fragment />;
    //prettier-ignore
    const fields = _([
        { label: common.companyName,  value: companyName,    format: formatTypes.text,            row: 0   },
        { label: common.vatin,        value: vatin,          format: formatTypes.text,            row: 1   },
        { label: common.address,      value: billingAddress, format: formatTypes.shippingAddress, row: 2   },
        { label: common.phoneNumber,  value: phoneNumber,    format: formatTypes.text,            row: 3   },
    ])
    .groupBy('row')
    .map(x => x)
    .value();

    return (
        <GridContainer
            iconName="business"
            title={common.invoiceInfo}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >
            {fields.map((row, i) => (
                <Grid
                    item
                    key={i}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    {row.map((item) =>
                        item.hidden ? (
                            <Fragment key={item.label} />
                        ) : (
                            <Grid
                                item
                                key={item.label}
                            >
                                <TextWithLabel
                                    unselectableLabel
                                    label={item.label}
                                    format={item.format}
                                    value={item.value}
                                />
                            </Grid>
                        )
                    )}
                </Grid>
            ))}
        </GridContainer>
    );
};

export default BillingAdress;
