import useCardAvatarStyle from "assets/jss/material-dashboard-react/components/cardAvatarStyle";
import classNames from "classnames";
import PropTypes from "prop-types";

function CardAvatar({ ...props }) {
    const { children, className, plain, profile, ...rest } = props;
    const { classes } = useCardAvatarStyle();
    const cardAvatarClasses = classNames({
        [classes.cardAvatar]: true,
        [classes.cardAvatarProfile]: profile,
        [classes.cardAvatarPlain]: plain,
        [className]: className !== undefined,
    });

    return (
        <div
            className={cardAvatarClasses}
            {...rest}
        >
            {children}
        </div>
    );
}

CardAvatar.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    profile: PropTypes.bool,
    plain: PropTypes.bool,
};

export default CardAvatar;
