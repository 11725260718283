import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import _ from "lodash";
import { customers } from "translations";
import { useTranslation } from "utils-ts/hooks";

const ConsentsHistory = ({ history }) => {
    const orderedHistory = _.orderBy(history, "createdAt", "desc");
    const { t } = useTranslation("customers");

    const columns = [
        {
            key: "consent.consent.consentType",
            value: customers.consentType,
            type: formatTypes.translatable,
            options: { translationKey: "customers" },
        },
        {
            key: "channelName",
            value: customers.channelName,
            type: formatTypes.translatable,
            options: { translationKey: "customers" },
        },
        {
            key: "consent.isConsented",
            value: customers.isConsented,
            type: formatTypes.boolean,
        },
        {
            key: "createdAt",
            value: customers.createdAt,
            type: formatTypes.time,
            options: { timeFormat: timePrecisonFormats.minutes },
        },
    ].map((col) => ({ ...col, value: t(col.value) }));

    return (
        <SimpleTable
            columns={columns}
            data={orderedHistory ?? []}
            stripped
            hover
        />
    );
};

export default ConsentsHistory;
