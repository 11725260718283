const bridge = {
    highSeason: "highSeason",
    useLimit: "useLimit",
    isB2B: "isB2B",
    isB2C: "isB2C",
    minDuration: "minDuration",
    maxDuration: "maxDuration",
    files: "files",
    prices: "prices",
    tools: "tools",
    isSystem: "isSystem",
    isSystemAlert: "isSystemAlert",
    pricesCalculationError: "pricesCalculationError",
    reservationToolStatus: "reservationToolStatus",
    vendorBudget: "vendorBudget",
    Draft: "Draft",
    Proposed: "Wysłano propozycje do kontrahenta",
    PropositionAccepted: "Propozycja zaakceptowana przez kontrahenta",
    SpecificationSent: "SpecificationSent",
    FirstNotification: "FirstNotification",
    SecondNotification: "SecondNotification",
    ThirdNotification: "ThirdNotification",
    FeedbackSent: "FeedbackSent",
    CreationReceived: "CreationReceived",
    LandingPageCreatedAndFeedbackSent: "LandingPageCreatedAndFeedbackSent",
    LandingPageCreatedAndSecondNotification: "LandingPageCreatedAndSecondNotification",
    LandingPageCreatedAndThirdNotification: "LandingPageCreatedAndThirdNotification",
    LandingPageCreated: "LandingPageCreated",
    Implemented: "Implemented",
    CmsToFix: "CmsToFix",
    VerifiedCorrectness: "VerifiedCorrectness",
    InProgress: "InProgress",
    Done: "Done",
    Expired: "Expired",
    sendedToVendor: "sendedToVendor",
    acceptedBy: "acceptedBy",
    addNewReservation: "addNewReservation",
    showReservations: "showReservations",
    toolName: "toolName",
    shouldGenerateToolkit: "shouldGenerateToolkit",
    reservations: "reservations",
    toolkits: "toolkits",
    durationInDays: "durationInDays",
    sum: "sum",
    vendorNotInSystem: "vendorNotInSystem",
    vendorTooltipText: "vendorTooltipText",
    periodsInCycle: "periodsInCycle",
    endDate: "endDate",
    isCyclic: "isCyclic",
    cyclicSystemGenerated: "cyclicSystemGenerated",
    editAllOccurances: "editAllOccurances",
    cycleEndDate: "cycleEndDate",
    Rejected: "Rejected",
    reservationsDifferentLength: "reservationsDifferentLength",
    goToSg: "goToSg",
    createSg: "createSg",
    sgStatus: "sgStatus",
    inactive: "inactive",
    activeToolkitNames: "activeToolkitNames",
    periodReservationLongerThenOnePeriod: "periodReservationLongerThenOnePeriod",
    targetReservationPartialHidden: "targetReservationPartialHidden",
    reservationTooLong: "reservationTooLong",
    targetReservationShorterThenDropped: "targetReservationShorterThenDropped",
    cannotSwitchPeriodWithDatesReservation: "cannotSwitchPeriodWithDatesReservation",
    cannotDropOnSameReservation: "cannotDropOnSameReservation",
    cannotDropReservationHere: "cannotDropReservationHere",
    containers: "containers",
    removeReservation: "removeReservation",
    cyclicOnlyForDates: "cyclicOnlyForDates",
    budgetToUse: "budgetToUse",
    overBudget: "overBudget",
    canNotCreateCycle: "canNotCreateCycle",
    cycleToolErrors: "cycleToolErrors",
    noVendor: "noVendor",
    toolsOverLimit: "toolsOverLimit",
    removeCycleOccurance: "removeCycleOccurance",
    currentFormCausesOverLimit: "currentFormCausesOverLimit",
    toolDurationTooLong: "toolDurationTooLong",
    toolDurationTooShort: "toolDurationTooShort",
    toolsOverLimitUsage: "toolsOverLimitUsage",
    addReservation: "addReservation",
    addToolkit: "addToolkit",
    promotionPeriodIsUsed: "promotionPeriodIsUsed",
    unassignedPeriod: "unassignedPeriod",
    cannotDropOnUnassignedPeriod: "cannotDropOnUnassignedPeriod",
    toolCount: "toolCount",
    reservationKits: "reservationKits",
    isShared: "isShared",
    reservationIsGrouped: "reservationIsGrouped",
    sharedTool: "sharedTool",
    showCategories: "showCategories",
    toolsOverCategoryLimit: "toolsOverCategoryLimit",
    problems: "problems",
    currentFormContiansDuplicateFor: "currentFormContiansDuplicateFor",
    toolDuplicate: "toolDuplicate",
    doYouWantToContinue: "doYouWantToContinue",
    splitReservations: "splitReservations",
    cancelSplitReservations: "cancelSplitReservations",
    splitSelectedReservations: "splitSelectedReservations",
    reservationToSplit: "reservationToSplit",
    cannotDropDueIsReadOnlyStatus: "cannotDropDueIsReadOnlyStatus",
    addToExistingSG: "addToExistingSG",
    sgToJoin: "sgToJoin",
    sgId: "sgId",
    assignToSg: "assignToSg",
    assignToSgMessage: "assignToSgMessage",
    reservationStatus: "reservationStatus",
    cannotDropWhileFiltering: "cannotDropWhileFiltering",
    overlapingReservationKitsExists: "overlapingReservationKitsExists",
    removeReservationKit: "removeReservationKit",
    isCreativeTool: "isCreativeTool",
    reservationType: "reservationType",
    priceListType: "priceListType",
    isNewUntil: "isNewUntil",
    mainCategory: "mainCategory",
    subCategory: "subCategory",
    orderNumber: "orderNumber",
    printToolkitPriceList: "printToolkitPriceList",
    priceListPositionNumber: "priceListPositionNumber",
    moreThanOneBudget: "moreThanOneBudget",
    moreThanOneBudgetError: "moreThanOneBudgetError",
    outOfBudget: "outOfBudget",
    noBudgetForVendor: "noBudgetForVendor",
    noBudgetForCatman: "noBudgetForCatman",
    noSelectedCatman: "noSelectedCatman",
    cyclicBudget: "cyclicBudget",
    cyclicInForm: "cyclicInForm",
    budgetSummary: "budgetSummary",
    noSelectedSettlementPeriod: "noSelectedSettlementPeriod",
    missingDeletionReason: "missingDeletionReason",
    cycleDeletionComment: "cycleDeletionComment",
    changeStatus: "changeStatus",
    hide: "hide",
    updateStatus: "updateStatus",
    isAlcohol: "isAlcohol",
    budgetTotalSummary: "budgetTotalSummary",
    filesCreativeTools: "filesCreativeTools",
    WrongCreationReceived: "WrongCreationReceived",
} as const;

export default bridge;
