import { useAuth } from "react-oidc-context";
import { Role } from "routing-ts/roles";

const useUser = () => {
    const auth = useAuth();
    const { user } = auth;
    const { profile } = user || {};
    const roles: string[] = profile ? (Array.isArray(profile["role"]) ? profile["role"] : [profile["role"]]) : [];
    const userId: string | undefined = profile ? profile["sub"] : undefined;
    const email: string | undefined = profile ? profile["email"] : undefined;

    const isInRole = (role: Role) => roles.some((r) => r === role);
    const isAdmin = isInRole("Administrator");
    const isInRoleOrAdmin = (role: Role) => isInRole(role) || isAdmin;
    const isInAnyRole = (rolesToCheck: Role[]) => {
        for (const roleToCheck of rolesToCheck) {
            if (isInRole(roleToCheck)) {
                return true;
            }
        }

        return false;
    };

    const isInAnyRoleOrAdmin = (rolesToCheck: Role[]) => {
        if (isAdmin) {
            return true;
        }

        for (const roleToCheck of rolesToCheck) {
            if (isInRole(roleToCheck)) {
                return true;
            }
        }

        return false;
    };

    return {
        userId,
        email,
        isInRole,
        isInRoleOrAdmin,
        isInAnyRole,
        isInAnyRoleOrAdmin,
    };
};

export default useUser;
