import { RSAA } from "redux-api-middleware";
import { commerce, manager } from "store/actionTypePrefixes";
import { AsyncTypes, createAction } from "utils/extensions";
import { combineIntoQueryParams } from "utils/objectExtensions";
import { actions as userInfosActions } from "../../../cache/userInfos/actions";

export const types = {
    getCustomerBalanceAudit: new AsyncTypes("CUSTOMER_BALANCE_AUDIT_GET", commerce),
    updateCustomerBalanceAuditPagination: createAction("UPDATE_CUSTOMER_BALANCE_PAGINATION", manager),
    getCustomerBalanceAuditProfile: createAction("CUSTOMER_BALANCE_AUDIT_GET_PROFILE", manager),
};

export const getCustomerBalanceAudit =
    ({ customerId, pageIndex, pageSize }) =>
    async (dispatch) => {
        const params = combineIntoQueryParams({ pageIndex, pageSize });
        const audits = await dispatch({
            [RSAA]: {
                endpoint: `api/v1/users/${customerId}/balance/audit?${params}`,
                method: "GET",
                types: types.getCustomerBalanceAudit.all,
            },
        });

        const { items, ...rest } = audits.payload;
        const ids = items
            .map((x) => x.createdBy)
            .filter((x) => Boolean(x))
            .reduce((c, n) => {
                if (c.every((x) => x !== n)) return [...c, n];
                return c;
            }, []);

        await dispatch(userInfosActions.getUserInfos(ids));

        await dispatch({
            type: types.getCustomerBalanceAuditProfile,
            payload: {
                ...rest,
                items,
            },
        });
    };

export const updateCustomerBalanceAuditPagination = (payload) => ({
    type: types.updateCustomerBalanceAuditPagination,
    payload,
});
