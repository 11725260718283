import Button from "components/Button/Button";
import { FileInput } from "components/Controls";
import { useCommonTranslation } from "utils/hooks";
import { useState } from "react";
import { Chip, Grid } from "@mui/material";

const FileImport = ({
    isImporting,
    downloadTemplate,
    handleValidateFile,
    handleImport,
    clearOnImport,
    importText,
    importDisabled,
    importMultiple,
    validateInput,
}) => {
    const { t, common } = useCommonTranslation();
    const [files, setFiles] = useState(null);

    return (
        <Grid
            container
            direction="row"
        >
            {!!downloadTemplate && (
                <Grid item>
                    <Button
                        type="button"
                        actionType="accept"
                        fullWidth
                        onClick={downloadTemplate}
                    >
                        {t(common.downloadTemplate)}
                    </Button>
                </Grid>
            )}
            <Grid item>
                <FileInput
                    id="fileInput"
                    label={files?.name}
                    name="importFile"
                    onChange={(files) => {
                        if (validateInput) {
                            validateInput([...files]);
                        }
                        if (files && !importMultiple) {
                            setFiles(files[0]);
                        } else if (files && !!importMultiple) {
                            setFiles([...files]);
                        }
                    }}
                    selectedFile={files}
                    disabled={isImporting}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    multiple={importMultiple}
                />
            </Grid>
            {!!handleValidateFile && (
                <Grid item>
                    <Button
                        type="button"
                        actionType="accept"
                        fullWidth
                        onClick={() => handleValidateFile(files)}
                        loading={isImporting}
                        disabled={!files}
                    >
                        {t(common.validateFile)}
                    </Button>
                </Grid>
            )}
            <Grid item>
                <Button
                    type="button"
                    actionType="accept"
                    fullWidth
                    onClick={() => {
                        handleImport(files);
                        clearOnImport && setFiles(null);
                    }}
                    loading={isImporting}
                    disabled={!files || importDisabled}
                >
                    {importText ?? t(common.import)}
                </Button>
            </Grid>
            <Grid
                item
                xs={10}
            >
                {!!files &&
                    !!importMultiple &&
                    files.map((file, index) => {
                        return (
                            <Chip
                                label={file.name}
                                variant="outlined"
                                key={index}
                            />
                        );
                    })}
            </Grid>
        </Grid>
    );
};

export default FileImport;
