import { combineReducers } from "redux";
import types from "./types";
import defaultListState from "../../defaultListState";

const listInitialState = {
    ...defaultListState,
};

const formInitialState = {
    isLoading: false,
    isSubbmitRequested: false,
};

const list = (state = listInitialState, { type, payload }) => {
    switch (type) {
        case types.getCertificates.request:
        case types.getCertificatesForAudit.request:
            return {
                ...listInitialState,
                isLoading: true,
            };
        case types.getCertificates.success:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case types.getCertificatesForAudit.success:
            return {
                ...state,
                items: payload,
                isLoading: false,
            };
        case types.getCertificates.failure:
        case types.getCertificatesForAudit.failure:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return { ...state };
    }
};

const form = (state = formInitialState, { type, payload }) => {
    switch (type) {
        case types.formInit:
            return { ...formInitialState };
        case types.getCertificate.request:
            return { isLoading: true };
        case types.createCertificate.request:
        case types.updateCertificate.request:
            return {
                ...formInitialState,
                isSubbmitRequested: true,
            };
        case types.getCertificate.success:
            return { isLoading: false, ...payload };
        case types.createCertificate.success:
        case types.updateCertificate.success:
            return {
                ...payload,
                isSubbmitRequested: false,
            };
        case types.createCertificate.failure:
        case types.getCertificate.failure:
        case types.updateCertificate.failure:
            return { isLoading: false };
        default:
            return { ...state };
    }
};

export const certificates = combineReducers({ form, list });
