import { makeStyles } from "tss-react/mui";
import { firstOrDefault } from "utils/extensions/arrayExtensions";
import { Avatar, Chip, Tooltip } from "@mui/material";

const useStyles = makeStyles()(() => ({
    buttonOnRight: {
        width: "95%",
    },
}));

const ProductItem = ({ v, handleRemove, products, withToolTip, readOnly }) => {
    const { product } = firstOrDefault(products, (x) => x.productId === v, {
        product: {},
    });
    const { classes } = useStyles();

    const { imageUrl = "", brand = "", name = "" } = product;

    return withToolTip ? (
        <Tooltip
            enterDelay={250}
            title={
                <span style={{ fontSize: "0.9rem", userSelect: "text" }}>
                    ({v}) {brand} - {name}
                </span>
            }
            leaveDelay={250}
            arrow
        >
            <Chip
                key={v}
                classes={{ label: classes.buttonOnRight }}
                style={{
                    marginRight: 10,
                    marginTop: 10,
                    maxWidth: "98%",
                    minWidth: "98%",
                    justifyContent: "stretch",
                }}
                size="medium"
                label={`(${v}) ${brand} - ${name}`}
                color="default"
                avatar={
                    <Avatar
                        variant="square"
                        src={imageUrl}
                    ></Avatar>
                }
                onDelete={readOnly ? undefined : () => handleRemove(v)}
            />
        </Tooltip>
    ) : (
        <Chip
            key={v}
            style={{ marginRight: 10, marginTop: 10 }}
            size="medium"
            label={`(${v}) ${brand} - ${name}`}
            color="default"
            avatar={
                <Avatar
                    variant="square"
                    src={imageUrl}
                ></Avatar>
            }
            onDelete={readOnly ? undefined : () => handleRemove(v)}
        />
    );
};

export default ProductItem;
