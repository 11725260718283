import defaultListState from "store/defaultListState";
import { combineReducers } from "redux";
import types from "./types";

const initialState = {};
const { createOrUpdate, formInit, getDocument } = types;

const listInitialState = defaultListState;

const list = (state = listInitialState, { type, payload }) => {
    switch (type) {
        case types.getDocuments.request:
            return {
                ...listInitialState,
                isloading: true,
            };
        case types.getDocuments.success:
            return {
                ...state,
                ...payload,
                isloading: true,
            };
        case types.getDocuments.failure:
            return {
                ...state,
                isloading: false,
            };
        case types.getVatStatements.success:
            return {
                ...state,
                vatStatements: payload,
            };
        default:
            return { ...state };
    }
};

const form = (state = initialState, { type, payload }) => {
    switch (type) {
        case formInit:
            return { ...initialState };
        case getDocument.request:
            return { ...initialState, isLoading: true };
        case getDocument.success:
            return { ...state, ...payload, isLoading: false };
        case getDocument.failure:
            return { ...payload, isLoading: false };
        case createOrUpdate.request:
            return { ...state, isSubbmitRequested: true };
        case createOrUpdate.success:
        case createOrUpdate.failure:
            return { ...state, isSubbmitRequested: false };
        default:
            return state;
    }
};

export const vendorDocuments = combineReducers({
    form,
    list,
});
