import { PagedRequest, getPaged } from "utils/extensions";
import types from "./types";

const endpoint = (id) => `api/procedure/category${id ? `/${id}` : ""}`;

const getList =
    ({ isActive, pageIndex, pageSize }) =>
    async (dispatch) => {
        const queryParams = {};
        if (isActive !== "") queryParams.isActive = isActive;

        return await dispatch(
            getPaged(
                new PagedRequest(pageIndex, pageSize, endpoint(), types.getList.all, {
                    queryParams,
                })
            )
        );
    };

export const actions = {
    getList,
};
