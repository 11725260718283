import { PromotionSearchParameters, PromotionSearchResult } from "Commerce-Offer";
import { useGetPagedQuery } from "api/hooks";

const useFindPromotions = (
    params: PromotionSearchParameters = {
        pageIndex: 1,
        pageSize: 25,
        includeFacets: true,
    }
) => {
    return useGetPagedQuery<PromotionSearchResult, PromotionSearchParameters>({
        app: "commerce",
        url: "api/v1/offer/products/promotions/query",
        queryParams: params,
    });
};

export default {
    useFindPromotions,
};
