import headerStyle from "assets/jss/material-dashboard-react/components/headerStyle";
import { withStyles } from "tss-react/mui";
import Menu from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import classNames from "classnames";
import PropTypes from "prop-types";
import HeaderLinks from "./HeaderLinks";

function Header({ ...props }) {
    const { color } = props;
    const classes = withStyles.getClasses(props);
    const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("lg"));

    const appBarClasses = classNames({
        [" " + classes[color]]: color,
    });

    return (
        <AppBar className={classes.appBar + appBarClasses}>
            <Toolbar className={classes.container}>
                {isLargeScreen ? (
                    <HeaderLinks />
                ) : (
                    <>
                        <HeaderLinks />
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={props.handleDrawerToggle}
                        >
                            <Menu />
                        </IconButton>
                    </>
                )}
            </Toolbar>
        </AppBar>
    );
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
};

export default withStyles(Header, headerStyle);
