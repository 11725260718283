import React from "react";
import { TablePagination as MuiTablePagination, TableRow as MuiTableRow, TableFooter } from "@mui/material";
import { useTranslation } from "utils-ts/hooks";
import TablePaginationActions from "./TablePaginationActions";
import { TablePaginationProps } from "./TableTypes";

const TablePagination: React.FC<TablePaginationProps> = ({
    pageIndex = 0,
    pageSize = 25,
    pageSizeOptions = [10, 25, 50, 100, 250],
    totalCount = 0,
    onPageChange,
    onPageSizeChange,
}) => {
    const { t } = useTranslation();

    return (
        <TableFooter>
            <MuiTableRow>
                <MuiTablePagination
                    page={pageIndex > 0 ? pageIndex - 1 : 0}
                    rowsPerPage={pageSize || 25}
                    rowsPerPageOptions={pageSizeOptions}
                    count={totalCount || 0}
                    onPageChange={(_, page) => {
                        onPageChange(page + 1);
                    }}
                    onRowsPerPageChange={(event) => {
                        const { value } = event.target;
                        onPageSizeChange(value as unknown as number);
                    }}
                    labelRowsPerPage={t("paginationRowsPerPage").toString()}
                    ActionsComponent={TablePaginationActions}
                />
            </MuiTableRow>
        </TableFooter>
    );
};

export default TablePagination;
