import { UserInternalDocument } from "Commerce-User";
import { generatePath } from "react-router-dom";
import { useGetQuery, useUpdateMutation } from "api/hooks";

const queryKey = "api/v1/users/";

const url = (id?: string) => generatePath(`${queryKey}:id?`, { id: id ? id : null });

export const useGetUserInternal = (id?: string) => {
    return useGetQuery<UserInternalDocument>({
        app: "commerce",
        url: url(id) + "/internal",
        shouldMakeRequest: Boolean(id),
    });
};

export const useUpdateUserAccountManager = (id?: string) => {
    return useUpdateMutation<{ accountManager?: string }, {}>({
        app: "commerce",
        url: url(id) + "/retention-manager",
    });
};

export const useUpdateUserRetentionManager = (id?: string) => {
    return useUpdateMutation<{ retentionManager?: string }, {}>({
        app: "commerce",
        url: url(id) + "/retention-manager",
    });
};

export const useUpdateUserBalanceDebt = (id?: string) => {
    return useUpdateMutation<{ creditLimit?: { creditLimit: number; paymentTerm: number } }, {}>({
        app: "commerce",
        url: url(id) + "/balance/credit-limit",
    });
};
