import { ContentData, DivisionSelect, OrderRequirementsFields, SegmentsSelectFields, SingleSegmentsSelectField } from "components/Controls";
import LabelItem from "components/Controls/PromotionPredicateFieldsComponents/LabelItem";
import { CommerceForm, FormField } from "components/Form";
import Layout from "components/Grid/Layout";
import { FieldArray, FormSection, reduxForm } from "redux-form";
import { useCommonTranslation } from "utils/hooks";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import GeneratorDefinitions from "views/vouchers/components/GeneratorDefinitions";
import { voucherFormGeneratorSelector } from "../selectors";
import { validators } from "../validators";
import { validateGenerator } from "./validate";

const VoucherGeneratorForm = ({ handleSubmit, isSubbmitRequested, onSubmit, id, name, isSystem }) => {
    const { t, common } = useCommonTranslation();
    return (
        <CommerceForm
            onSubmit={handleSubmit((values) => onSubmit(values))}
            isReadonly={isSystem}
            isSubbmitRequested={isSubbmitRequested}
        >
            <Layout
                main
                headerText={t(common.generators, { id })}
                navigateBack
                customTitle={id && name && `${t(common.generators)} - ${name}`}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <FormField
                        name={"name"}
                        type={"text"}
                        label={t(common.name)}
                        validate={validators.name}
                    />
                    <FormField
                        name={"slug"}
                        type={"text"}
                        label={t(common.slug)}
                        validate={validators.slug}
                    />
                    <FormField
                        name={"isActive"}
                        type={"boolean"}
                        label={t(common.isActive)}
                    />
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <FormField
                        name={"validFrom"}
                        type={"dateTime"}
                        label={t(common.validFrom)}
                    />
                    <FormField
                        name={"validTo"}
                        type={"dateTime"}
                        label={t(common.validTo)}
                    />

                    <DivisionSelect />
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <FormField
                        name={"isSending"}
                        type={"boolean"}
                        label={t(common.isSending)}
                    />
                    <FormField
                        name={"isReminding"}
                        type={"boolean"}
                        label={t(common.isReminding)}
                    />
                    <FormField
                        name={"isRepeatable"}
                        type={"boolean"}
                        label={t(common.isRepeatable)}
                        validate={validators.onlyOneChosen}
                    />
                    <FormField
                        name={"generateOnRegistration"}
                        type={"boolean"}
                        label={t(common.generateOnRegistration)}
                        validate={validators.onlyOneChosen}
                    />
                    <FormField
                        name={"generateOnTokenRefresh"}
                        type={"boolean"}
                        label={t(common.generateOnTokenRefresh)}
                        validate={validators.onlyOneChosen}
                    />
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <FormField
                        name={"addTags"}
                        type={"list"}
                        label={t(common.tags)}
                        useList={false}
                        itemRenderer={(v, handleRemove) => (
                            <LabelItem
                                key={v}
                                asChip
                                v={v}
                                handleRemove={handleRemove}
                                onlyLabel
                            />
                        )}
                    />
                    <SingleSegmentsSelectField
                        name="generateOnAddedSegments"
                        label={t(common.generateOnAddedSegments)}
                        validate={validators.onlyOneChosen}
                    />
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <FormSection name={"user"}>
                        <OrderRequirementsFields />

                        <SegmentsSelectFields />
                    </FormSection>
                </Grid>
            </Layout>
            <FieldArray
                name="definitions"
                component={GeneratorDefinitions}
                validate={validators.voucherDefinition}
            />
            <ContentData />
        </CommerceForm>
    );
};

const formName = "voucher-form";
const stateToProps = (state) => {
    const { isSubbmitRequested, isSystem, ...form } = voucherFormGeneratorSelector(state);
    return {
        isSystem,
        id: form.id,
        isSubbmitRequested,
        name: form.name,
        initialValues: {
            ...form,
        },
    };
};

export default connect(stateToProps)(
    reduxForm({
        form: formName,
        enableReinitialize: true,
        validate: validateGenerator,
    })(VoucherGeneratorForm)
);
