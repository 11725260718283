import defaultListState from "store/defaultListState";
import moment from "moment";
import { combineReducers } from "redux";
import types from "./types";

const listInitialState = {
    ...defaultListState,
    isDownloadingPwu: {},
};

const list = (state = listInitialState, { type, payload }) => {
    switch (type) {
        case types.getSubsidiaryGains.request:
            return {
                ...listInitialState,
                isLoading: true,
            };
        case types.getSubsidiaryGains.success:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case types.getSubsidiaryGains.failure:
            return {
                ...state,
                isLoading: false,
            };
        case types.downloadPwu.request:
            return {
                ...state,
                isDownloadingPwu: {
                    ...state.isDownloadingPwu,
                    [payload]: true,
                },
            };
        case types.downloadPwu.success:
        case types.downloadPwu.failure:
            return {
                ...state,
                isDownloadingPwu: {
                    ...state.isDownloadingPwu,
                    [payload]: false,
                },
            };
        case types.generateReport.request:
            return { ...state, isGeneratingReport: true };
        case types.generateReport.success:
        case types.generateReport.failure:
            return { ...state, isGeneratingReport: false };
        default:
            return { ...state };
    }
};

const formInitialState = {
    isLoading: false,
    isSubbmitRequested: false,
    isPurchaseOrderRequired: true,
    isDate: true,
};

const formatSG = (payload) => ({
    ...payload,
    confirmations: payload.proposal
        ? payload.proposal.serviceConfirmations
              ?.filter((s) => !s.containerId)
              .map((s) => ({
                  blobName: s.screenshot.blobName,
                  containerName: s.screenshot.containerName,
                  isPdf: s.isPdf,
              }))
        : payload.current?.serviceConfirmations
              ?.filter((s) => !s.containerId)
              .map((s) => ({
                  blobName: s.screenshot.blobName,
                  containerName: s.screenshot.containerName,
                  isPdf: s.isPdf,
              })),
    containers: payload.containers?.map((x) => ({
        ...x,
        confirmations: payload.proposal
            ? payload.proposal.serviceConfirmations
                  ?.filter((s) => s.containerId === x.containerId && (!x.sectionName || s.sectionName === x.sectionName))
                  .map((s) => ({
                      blobName: s.screenshot.blobName,
                      containerName: s.screenshot.containerName,
                      isPdf: s.isPdf,
                  }))
            : payload.current?.serviceConfirmations
                  ?.filter((s) => s.containerId === x.containerId && (!x.sectionName || s.sectionName === x.sectionName))
                  .map((s) => ({
                      blobName: s.screenshot.blobName,
                      containerName: s.screenshot.containerName,
                      isPdf: s.isPdf,
                  })),
    })),
    serviceConfirmations: undefined,
});

const form = (state = formInitialState, { type, payload, meta }) => {
    switch (type) {
        case types.formInit:
            return { ...formInitialState };

        case types.getSubsidiaryGain.request:
        case types.updateSubsidiaryGain.request:
        case types.updateResponsibleUser.request:
        case types.findSubsidiaryGainForApproval.request:
            return { isLoading: true };

        case types.getSubsidiaryGain.success:
            return { isLoading: false, ...formatSG(payload) };

        case types.findSubsidiaryGainForApproval.success:
            return { isLoading: false, ...formatSG(payload || {}) };

        case types.updateResponsibleUser.success:
        case types.updateResponsibleUser.failure:
        case types.findSubsidiaryGainForApproval.failure:
        case types.getSubsidiaryGain.failure:
            return { isLoading: false };

        case types.getServiceConfirmation.success:
            return {
                ...state,
                confirmations: (state.confirmations ?? []).map((confirmation) =>
                    confirmation.blobName === meta.blobName ? { ...confirmation, imageUrl: payload } : confirmation
                ),
                containers: (state.containers ?? []).map((container) => ({
                    ...container,
                    confirmations: (container.confirmations ?? []).map((confirmation) =>
                        confirmation.blobName === meta.blobName ? { ...confirmation, imageUrl: payload } : confirmation
                    ),
                })),
            };

        case types.getMarketingBudgetForSG.success:
            return {
                ...state,
                marketingBudgetDiscount: payload.current?.discount ?? payload.proposal?.discount ?? 0,
            };

        case types.fillSubsidiaryGainWithReservationKit.success:
            return {
                ...state,
                reservationKitId: state.reservationKitId ?? payload.id,
                settlementPeriod: state.settlementPeriod ?? payload.settlementPeriod,
                invoiceRecipientId: state.invoiceRecipientId ?? payload.invoiceRecipientId,
                responsibleUser: state.responsibleUser ?? payload.responsibleUser,
                subsidiaryGainType: state.subsidiaryGainType ?? payload.subsidiaryGainType,
            };

        case types.fillSubsidiaryGainWithReservation.success:
            const newContainers =
                payload.toolkits
                    .filter((o) => !o.isDeleted)
                    .flatMap((tk) => tk.reservationTools.flatMap((rt) => rt.containers))
                    .map((c) => {
                        return { ...c, reservationId: payload.id };
                    }) || [];

            const uniqueContainers = new Map(newContainers.map((o) => [o.containerId + o.containerType + o.sectionName, o]).reverse());

            const uniqueContainersList = [...uniqueContainers.values()];

            uniqueContainersList.forEach((uc) => {
                const sameContainers = (state.containers || []).filter(
                    (c) => c.containerId == uc.containerId && c.containerType == uc.containerType && c.sectionName == uc.sectionName
                );
                if (sameContainers.length > 0) {
                    sameContainers[0] = {
                        ...sameContainers[0],
                        reservationIds: [...(sameContainers[0].reservationIds || []), payload.id],
                    };
                } else {
                    state.containers = [
                        ...(state.containers || []),
                        {
                            containerId: uc.containerId,
                            containerType: uc.containerType,
                            sectionName: uc.sectionName,
                            reservationIds: [payload.id],
                        },
                    ];
                }
            });

            return {
                ...state,
                containers: state.containers,
                year: state.year ?? moment(payload.dateTo).year(),
                quater: state.quater ?? moment(payload.dateTo).quarter(),
                month: state.month ?? moment(payload.dateTo).month(),
                dateFrom: state.dateFrom
                    ? moment(state.dateFrom).isBefore(moment(payload.dateFrom), "day")
                        ? state.dateFrom
                        : payload.dateFrom
                    : payload.dateFrom,
                dateTo: state.dateTo ? (moment(state.dateTo).isAfter(moment(payload.dateTo), "day") ? state.dateTo : payload.dateTo) : payload.dateTo,
                isDate:
                    (state.dateFrom == undefined && state.isDate && payload.isDate) ||
                    (state.dateFrom != undefined && moment(state.dateFrom).isBefore(moment(payload.dateFrom), "day")) ||
                    (state.dateTo != undefined && moment(state.dateTo).isAfter(moment(payload.dateTo), "day")),
                promotionPeriodId:
                    (state.dateFrom == undefined && state.isDate && payload.isDate) ||
                    (state.dateFrom != undefined && moment(state.dateFrom).isBefore(moment(payload.dateFrom), "day")) ||
                    (state.dateTo != undefined && moment(state.dateTo).isAfter(moment(payload.dateTo), "day"))
                        ? null
                        : state.promotionPeriodId ?? payload.promotionPeriodId,
                current: {
                    ...state.current,
                    subsidiaryGainServices: [
                        ...(state.current?.subsidiaryGainServices || []),
                        ...payload.toolkits
                            .filter((o) => !o.isDeleted)
                            .map((t) => {
                                return {
                                    isDate: !payload.promotionPeriodId,
                                    dateFrom: payload.dateFrom,
                                    dateTo: payload.dateTo,
                                    promotionPeriodId: payload.promotionPeriodId,
                                    newCostReasons: [
                                        {
                                            name: t.toolkitId,
                                            toolkitId: t.toolkitId,
                                            reservationId: payload.id,
                                            price: t.price * (1 - (state.marketingBudgetDiscount ?? 0) / 100),
                                        },
                                    ],
                                };
                            }),
                    ],
                },
                value:
                    Number(state.current?.value ?? state.proposal?.value ?? 0) +
                    payload.toolkits
                        .filter((o) => !o.isDeleted)
                        .map((t) => Number(t.price))
                        .reduce((partialSum, a) => partialSum + a, 0) *
                        (1 - (state.marketingBudgetDiscount ?? 0) / 100),
                reservationAdded: true,
            };

        default:
            return { ...state };
    }
};

const invoicesInitialState = {
    items: [],
    isLoading: false,
};

const invoices = (state = invoicesInitialState, { type, payload }) => {
    switch (type) {
        case types.getSubsidiaryGainInvoices.request:
            return { ...state, isLoading: true };
        case types.getSubsidiaryGainInvoices.success:
            return { isLoading: false, items: payload };
        case types.getSubsidiaryGainInvoices.failure:
            return { ...state, isLoading: false };
        default:
            return state;
    }
};

const statusesInitialState = {
    items: [],
};

const statuses = (state = statusesInitialState, { type, payload }) => {
    switch (type) {
        case types.getSgStatuses.request:
        case types.getSgStatuses.failure:
            return { ...state };
        case types.getSgStatuses.success:
            return { ...state, items: payload };
        default:
            return state;
    }
};

export const subsidiaryGains = combineReducers({ form, list, invoices, statuses });
