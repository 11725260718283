import { diffCustom } from "utils/objectCompare/diffCustom";
import moment from "moment";
import * as _ from "lodash";
import { createSelector } from "reselect";

const root = (state) => ({
    subsidiaryGains: state.subsidiaryGains,
    autocomplete: state.autocomplete,
    vendor: state.vendorPms?.vendorForm,
});

export const sgListSelector = createSelector(root, (root) => {
    return {
        autocomplete: root.autocomplete,
        list: {
            ...root.subsidiaryGains.list,
            items: root.subsidiaryGains.list.items.map((x) => ({
                ...x,
                ...(x.proposal ? x.proposal : x.current),
                responsibleUser: x.responsibleUser.email,
                statusToShow:
                    x.decisionStatus === "AcceptedByVendor"
                        ? x.status === "Created"
                            ? x.decisionStatus
                            : x.status
                        : x.status === "InvoiceFailed" || x.status === "InvoiceRequested" || x.status === "Invoiced"
                        ? x.status
                        : x.decisionStatus,
            })),
        },
    };
});

export const sgFormSelector = createSelector(root, ({ subsidiaryGains = {}, vendor = {}, autocomplete = {} }) => {
    let { current, proposal, ...document } = subsidiaryGains.form;
    if (Array.isArray(current?.subsidiaryGainServices)) {
        current.subsidiaryGainServices = current.subsidiaryGainServices.map((o) => ({
            ...o,
            costReasons: o.newCostReasons?.map((c) => autocomplete.toolkitNames.find((t) => t.toolkitId == c.name)?.name || c.name),
            isReadOnlyService: moment(o.dateFrom).isSameOrBefore(moment(), "days"),
        }));
        current.subsidiaryGainServices.sort((a, b) => moment(a.dateFrom) - moment(b.dateFrom));
    }

    if (Array.isArray(proposal?.subsidiaryGainServices)) {
        proposal.subsidiaryGainServices = proposal.subsidiaryGainServices?.map((o) => ({
            ...o,
            costReasons: o.newCostReasons?.map((c) => autocomplete.toolkitNames.find((t) => t.toolkitId == c.name)?.name || c.name),
        }));
        proposal.subsidiaryGainServices.sort((a, b) => moment(a.dateFrom) - moment(b.dateFrom));
    }

    const isDecisionRequired = subsidiaryGains.form.decisionStatus === "WaitingForDecision";
    const isConfirmedByVendor = document.decisionStatus === "ConfirmationAcceptedByVendor";

    const canRequestVendorDecision =
        !isDecisionRequired &&
        !_.isEqual(document.sentToVendor, current) &&
        document.status !== "Completed" &&
        document.decisionStatus !== "ServiceRemoved" &&
        document.decisionStatus !== "ChangeRejected" &&
        document.decisionStatus !== "WaitingForVendorDecision" &&
        document.decisionStatus !== "WaitingForVendorConfirmation" &&
        current;

    const canRequestVendorConfimation =
        !isDecisionRequired &&
        document.status === "Completed" &&
        document.decisionStatus !== "ServiceRemoved" &&
        document.decisionStatus !== "ChangeRejected" &&
        document.decisionStatus !== "WaitingForVendorDecision" &&
        document.decisionStatus !== "WaitingForVendorConfirmation" &&
        document.decisionStatus !== "ConfirmationAcceptedByVendor" &&
        current;

    const hasInvoiceErrors = document.status === "InvoiceFailed";
    const invoiceError = document.invoiceError;

    let isAfterDateTo = false;
    let isAfterDateFrom = false;

    if (!isDecisionRequired) {
        isAfterDateTo = document?.dateTo && moment().isAfter(moment(document.dateTo), "days");
        isAfterDateFrom = document?.dateFrom && moment().isAfter(moment(document.dateFrom), "days");
    }

    let isNew = true;

    if (current) {
        isNew = false;
        const requiresMon = current.settlementPeriod === "Monthly";
        const requiresYer = current.settlementPeriod === "Quarterly" || requiresMon;
        current = {
            year: requiresYer ? moment(current.dateTo).year() : null,
            quater: requiresYer ? moment(current.dateTo).quarter() : null,
            month: requiresMon ? moment(current.dateTo).month() : null,
            ...current,
        };
    }

    if (proposal) {
        const requiresMon = proposal.settlementPeriod === "Monthly";
        const requiresYer = proposal.settlementPeriod === "Quarterly" || requiresMon;
        proposal = {
            year: requiresYer ? moment(proposal.dateTo).year() : null,
            quater: requiresYer ? moment(proposal.dateTo).quarter() : null,
            month: requiresMon ? moment(proposal.dateTo).month() : null,
            ...proposal,
        };
    }

    const vendorDecision = !!document?.uhStatusHistory && document.uhStatusHistory?.length > 0 ? document.uhStatusHistory.at(-1) : undefined;

    if (subsidiaryGains.form.decisionStatus === "WaitingForDecision") {
        return {
            ...document,
            ...diffCustom(
                current ? current : document.removalProposal ? proposal : current,
                document.removalProposal ? (current ? current : proposal) : proposal,
                {
                    subsidiaryGainServices: {
                        findFunc: (a, b) => a?.id === b?.id,
                        equalityProperties: ["dateFrom", "dateTo", "comment", "costReasons"],
                    },
                    files: {
                        findFunc: (a, b) => a?.fileId === b?.fileId,
                        equalityProperties: ["fileId", "fileName"],
                    },
                    costReasons: {
                        findFunc: (a, b) => a === b,
                    },
                }
            ),
            vendorUsers: vendor?.users,
            isDecisionRequired,
            vendorDecision,
            canRequestVendorDecision,
            canRequestVendorConfimation,
            isConfirmedByVendor,
        };
    }

    if (subsidiaryGains.form.decisionStatus === "ChangeRejected" || subsidiaryGains.form.decisionStatus === "ServiceRejected") {
        return {
            ...document,
            ...proposal,
            vendorUsers: vendor?.users,
            isDecisionRequired,
            vendorDecision,
            canRequestVendorDecision,
            canRequestVendorConfimation,
            isConfirmedByVendor,
            isNew,
        };
    }

    return {
        isEmpty: !document?.id,
        ...current,
        ...document,
        vendorUsers: vendor?.users,
        isDecisionRequired,
        vendorDecision,
        canRequestVendorDecision,
        canRequestVendorConfimation,
        isAfterDateTo,
        isAfterDateFrom,
        isConfirmedByVendor,
        isNew,
        hasInvoiceErrors,
        invoiceError,
    };
});

export const sgInvoicesSelector = createSelector(root, (state) => state.subsidiaryGains.invoices);

export const sgStatusesSelector = createSelector(root, (state) => state.subsidiaryGains.statuses);
