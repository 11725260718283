import { DeleteRequest, GetRequest, PagedRequest, PostRequest, PutRequest, get, post, put, remove } from "utils/extensions";
import downloadFile from "utils/rsaa/downloadFileAction";
import types from "./types";

const endpoint = (vendorId, certificateId) => `api/vendors/${vendorId}/certificates${certificateId ? `/${certificateId}` : ""}`;

const fileEndpoint = (vendorId, certificateId, fileName) => `api/vendors/${vendorId}/certificates/${certificateId}/${fileName}/file`;

export const findCertificates =
    ({ vendorId, pageSize, pageIndex, params }) =>
    async (dispatch) => {
        return dispatch(
            get(
                new PagedRequest(pageIndex, pageSize, endpoint(vendorId), types.getCertificates.all, {
                    queryParams: params,
                })
            )
        );
    };

export const getCertificate = (vendorId, certificateId) => async (dispatch) => {
    return dispatch(get(new GetRequest(endpoint(vendorId, certificateId), types.getCertificate.all)));
};

export const initForm = (vendorId, certificateId) => async (dispatch) => {
    await dispatch({ type: types.formInit });

    if (certificateId) {
        return dispatch(get(new GetRequest(endpoint(vendorId, certificateId), types.getCertificate.all)));
    }
};

export const createOrUpdateCertificate = (vendorId, certificateId, values) => async (dispatch) => {
    if (!certificateId) {
        return await dispatch(
            post(
                new PostRequest(endpoint(vendorId), types.createCertificate.all, {
                    body: values,
                })
            )
        );
    } else {
        return await dispatch(
            put(
                new PutRequest(endpoint(vendorId, certificateId), types.updateCertificate.all, {
                    body: values,
                })
            )
        );
    }
};

export const getCertificateFile = (vendorId, certificateId, fileName) => async (dispatch) => {
    await dispatch(
        downloadFile(
            fileEndpoint(vendorId, certificateId, fileName),
            undefined,
            types.downloadCertificate.all,
            fileName.split("_").slice(2).join("_")
        )
    );

    return Promise.resolve();
};

export const removeSubsidiaryGain = (vendorId, certificateId) => async (dispatch) => {
    return dispatch(remove(new DeleteRequest(endpoint(vendorId, certificateId), types.deleteCertificate.all)));
};

export const getCertificateForAudit = (vendorIds, analiticGroupId, brand, dateFor) => async (dispatch) => {
    return dispatch(
        get(
            new GetRequest("api/auditors/certificates", types.getCertificatesForAudit.all, {
                queryParams: { vendorIds, analiticGroupId, brand, dateFor },
            })
        )
    );
};
