import { manager, operations } from "store/actionTypePrefixes";
import { AsyncTypes, asAsyncFormTypes, createAction } from "utils/extensions";

const types = {
    formInit: createAction("FORM_INIT_DOCUMENT", manager),
    getDocument: new AsyncTypes("GET_DOCUMENT", operations),
    createOrUpdate: new AsyncTypes("CREATE_OR_UPDATE_DOCUMENT", operations, [asAsyncFormTypes]),
    getDocuments: new AsyncTypes("GET_DOCUMENTS", operations),
    downloadFile: new AsyncTypes("DOWNLOAD_DOCUMENT_FILE", operations),
    getVatStatements: new AsyncTypes("GET_VAT_STATEMENTS", operations),
};

export default types;
