import { Validator, notEmptyArrayValidator, notEmptyValidator, stringLength } from "utils/validators";
import moment from "moment";
import { get } from "lodash";
import { tValidation, validation } from "utils-ts/validations/translation";

const name = (value) =>
    notEmptyValidator(value)
        .must(
            stringLength(5, 100),
            tValidation(validation.stringLength, {
                min: 5,
                max: 100,
            })
        )
        .validate();

const required = (value) => notEmptyValidator(value).validate();

const arrayRequired = (value) => notEmptyArrayValidator(value).validate();

const validateFrom = (value, form, _, path) => {
    let valueTo = get(form, path.substring(0, path.lastIndexOf(".")) + "expirationDate");

    return notEmptyValidator(value)
        .must((value) => moment(value).isValid(), tValidation(validation.invalidDate))
        .must(
            (value) => moment(value).isBefore(moment(valueTo)),
            tValidation(validation.lessDateThan, {
                date: moment(valueTo).format("DD.MM.YYYY"),
            })
        )
        .when(valueTo != null && valueTo !== "" && moment(valueTo).isValid())
        .validate();
};

const validateTo = (value, form, _, path) => {
    let valueFrom = get(form, path.substring(0, path.lastIndexOf(".")) + "certificationDate");

    return notEmptyValidator(value)
        .must((value) => moment(value).isValid(), tValidation(validation.invalidDate))
        .must(
            (value) => value >= valueFrom,
            tValidation(validation.greaterOrEqualsDateThan, {
                date: moment(valueFrom).format("DD.MM.YYYY"),
            })
        )
        .when(value != null && value !== "" && valueFrom != null && valueFrom !== "" && moment(valueFrom).isValid())
        .validate();
};

const atLeastOneProductField = (_, form) => {
    return new Validator(form)
        .must((d) => {
            return (
                !notEmptyArrayValidator(d.brands).validate() ||
                !notEmptyArrayValidator(d.products).validate() ||
                !notEmptyArrayValidator(d.analyticGroups).validate()
            );
        }, tValidation(validation.atLeastOneField))
        .validate();
};

export const validators = {
    arrayRequired,
    name,
    validateTo,
    validateFrom,
    required,
    atLeastOneProductField,
};
