import Layout from "components/Grid/Layout";
import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import Actions, { createAction } from "components/Tabs/Actions";
import { useUser } from "context/UserContext/UserContext";
import { getUsers } from "store/identity/users/actions";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { administration, common } from "translations";
import { usePush } from "utils-ts/hooks";

const UsersPage = ({ hideHeader, pageIndex, pageSize, totalCount, items, isLoading }) => {
    const { isAdmin } = useUser();
    const { t } = useTranslation(["administration", "common"]);
    const dispatch = useDispatch();
    const { push } = usePush();

    useEffect(() => {
        dispatch(getUsers(pageIndex, pageSize, false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const onPageChange = (pageIndex) => {
        dispatch(getUsers(pageIndex, pageSize, false));
    };

    const onRowsPerPageChange = (pageSize) => {
        dispatch(getUsers(pageIndex, pageSize, false));
    };

    const addNewUser = () => {
        push("/administration/users/form");
    };

    const columns = [
        { key: "userId", value: t(`common:${common.userId}`), type: "text" },
        { key: "email", value: t(`common:${common.email}`), type: "text" },
        { key: "fullName", value: t(`common:${common.name}`), type: "text" },
        {
            key: "isActive",
            value: t(`common:${common.isActive}`),
            type: "boolean",
        },
        {
            key: "edit",
            value: "",
            type: "action",
            actionType: "edit",
            resetButton: true,
            action: (item) => {
                push(`/administration/users/form/${item.userId}`);
            },
        },
    ];

    const actions = [createAction(t(administration.createUser), addNewUser, "primary", false)];

    return (
        <Layout
            main
            headerText={t(administration.users)}
            hideHeader={hideHeader}
        >
            {isAdmin && <Actions actions={actions} />}
            <SimpleTable
                isLoading={isLoading}
                columns={columns}
                data={items}
                pagination
                hover
                stripped
                paginationProps={{
                    pageIndex,
                    totalCount,
                    pageSize,
                    onPageChange: onPageChange,
                    onRowsPerPageChange: onRowsPerPageChange,
                }}
            />
        </Layout>
    );
};

const mapStateToProps = (state) => {
    const { pageIndex, pageSize, items, isLoading, totalCount } = state.usersList;
    return {
        pageIndex,
        pageSize,
        items,
        isLoading,
        totalCount,
    };
};

export default connect(mapStateToProps)(UsersPage);
