const crawler = {
    product: "product",
    externalProduct: "externalProduct",
    externalProducts: "externalProducts",
    shopName: "shopName",
    externalId: "externalId",
    categories: "categories",
    url: "url",
    name: "name",
    grammage: "grammage",
    unitOfMeasure: "unitOfMeasure",
    description: "description",
    manufacturer: "manufacturer",
    brand: "brand",
    productionCountry: "productionCountry",
    mainEan: "mainEan",
    isNew: "isNew",
    ignoreDuringScan: "ignoreDuringScan",
    friscoId: "friscoId",
    searchProducts: "searchProducts",
    eans: "eans",
    scans: "scans",
    grammageRatio: "grammageRatio",
    friscoGrammage: "friscoGrammage",
    isLinkedAutomatically: "isLinkedAutomatically",
    linkedBy: "linkedBy",
    lastScanDate: "lastScanDate",
    scanWithSuccessCount: "scanWithSuccessCount",
    scanMappedCount: "scanMappedCount",
    scanWithErrorsCount: "scanWithErrorsCount",
    isScaned: "isScaned",
    grammageRatioTooltip: "grammageRatioTooltip",
    id: "id",
    successCount: "successCount",
    failureCount: "failureCount",
    mappedCount: "mappedCount",
    coverageRatio: "coverageRatio",
    productScanningStatus: "productScanningStatus",
    newScanCount: "newScanCount",
    newScanMappedCount: "newScanMappedCount",
    newScanMappedRatio: "newScanMappedRatio",
    exportUnmatchedProducts: "exportUnmatchedProducts",
    exportNewProducts: "exportNewProducts",
    importProductMatchings: "importProductMatchings",
    importProductMatchingsSucceeded: "importProductMatchingsSucceeded",
    linkingReport: "linkingReport",
    downloadLinkingReport: "downloadLinkingReport",
    dateFrom: "dateFrom",
    dateTo: "dateTo",
    biedronkaLoginInfo: "biedronkaLoginInfo",
    biedronkaPasswordInfo: "biedronkaPasswordInfo",
    shops: "shops",
} as const;

export default crawler;
