import { RSAA } from "redux-api-middleware";
import { startSubmit } from "redux-form";
import { operations } from "store/actionTypePrefixes";
import { asAsyncFormTypes } from "utils/extensions/arrayExtensions";
import { combineIntoQueryParams } from "utils/objectExtensions";

const CUSTOMER_DEPOSISTS_IMPORT_POST = `${operations}/CUSTOMER_DEPOSISTS_IMPORT_POST_SUPPLIERS`;
const CUSTOMER_DEPOSISTS_IMPORT_POST_SUCCESS = `${operations}/CUSTOMER_DEPOSISTS_IMPORT_POST_SUPPLIERS_SUCCESS`;
const CUSTOMER_DEPOSISTS_IMPORT_POST_FAILURE = `${operations}/CUSTOMER_DEPOSISTS_IMPORT_POST_SUPPLIERS_FAILURE`;
const CUSTOMER_DEPOSISTS_IMPORT_GET = `${operations}/CUSTOMER_DEPOSISTS_IMPORT_GET`;
const CUSTOMER_DEPOSISTS_IMPORT_GET_SUCCESS = `${operations}/CUSTOMER_DEPOSISTS_GET_SUCCESS`;
const CUSTOMER_DEPOSISTS_IMPORT_GET_FAILURE = `${operations}/CUSTOMER_DEPOSISTS_GET_FAILURE`;
const CUSTOMER_DEPOSISTS_IMPORT_DELETE = `${operations}/CUSTOMER_DEPOSISTS_DELETE`;
const CUSTOMER_DEPOSISTS_IMPORT_DELETE_SUCCESS = `${operations}/CUSTOMER_DEPOSISTS_DELETE_SUCCESS`;
const CUSTOMER_DEPOSISTS_IMPORT_DELETE_FAILURE = `${operations}/CUSTOMER_DEPOSISTS_DELETE_FAILURE`;

export const types = {
    CUSTOMER_DEPOSISTS_IMPORT_POST,
    CUSTOMER_DEPOSISTS_IMPORT_POST_SUCCESS,
    CUSTOMER_DEPOSISTS_IMPORT_POST_FAILURE,
    CUSTOMER_DEPOSISTS_IMPORT_GET,
    CUSTOMER_DEPOSISTS_IMPORT_GET_SUCCESS,
    CUSTOMER_DEPOSISTS_IMPORT_GET_FAILURE,
    CUSTOMER_DEPOSISTS_IMPORT_DELETE,
    CUSTOMER_DEPOSISTS_IMPORT_DELETE_SUCCESS,
    CUSTOMER_DEPOSISTS_IMPORT_DELETE_FAILURE,
};

const importCustomerDeposists = (payload) => async (dispatch) => {
    await dispatch(startSubmit("customerDepositsForm"));
    const { provider, merchant, files } = payload;
    const data = new FormData();
    for (let i = 0; i < files.length; i++) {
        data.append("file" + i, files[i]);
    }

    const endpoint = `api/customer-deposit/import?${combineIntoQueryParams({
        provider,
        merchant,
    })}`;
    const result = await dispatch({
        [RSAA]: {
            endpoint,
            method: "PUT",
            types: asAsyncFormTypes([CUSTOMER_DEPOSISTS_IMPORT_POST, CUSTOMER_DEPOSISTS_IMPORT_POST_SUCCESS, CUSTOMER_DEPOSISTS_IMPORT_POST_FAILURE]),
            body: data,
        },
    });

    return Promise.resolve(result);
};

const getCustomerDeposists = (payload) => async (dispatch) => {
    const { pageSize, pageIndex } = payload;

    const endpoint = `api/customer-deposit?${combineIntoQueryParams({ pageSize, pageIndex })}`;
    const result = await dispatch({
        [RSAA]: {
            endpoint,
            method: "GET",
            types: [CUSTOMER_DEPOSISTS_IMPORT_GET, CUSTOMER_DEPOSISTS_IMPORT_GET_SUCCESS, CUSTOMER_DEPOSISTS_IMPORT_GET_FAILURE],
        },
    });

    return Promise.resolve(result);
};

const deleteCustomerDeposists = (payload) => async (dispatch) => {
    const endpoint = `api/customer-deposit/${payload}`;
    const result = await dispatch({
        [RSAA]: {
            endpoint,
            method: "DELETE",
            types: asAsyncFormTypes([
                CUSTOMER_DEPOSISTS_IMPORT_DELETE,
                CUSTOMER_DEPOSISTS_IMPORT_DELETE_SUCCESS,
                CUSTOMER_DEPOSISTS_IMPORT_DELETE_FAILURE,
            ]),
        },
    });

    return Promise.resolve(result);
};

export const actions = {
    importCustomerDeposists,
    getCustomerDeposists,
    deleteCustomerDeposists,
};
