import { ApiRequest, PagedRequest, asAsyncFormTypes, getPaged, httpHeaders, httpMethods, remove } from "utils/extensions";
import types from "./types";

const endpoint = "api/delivery-complaints/";
const { list, deleteTypes } = types;

export const getDeliveryComplaints = (pageIndex, pageSize) => async (dispatch) => {
    return dispatch(getPaged(new PagedRequest(pageIndex, pageSize, endpoint, list.all)));
};

export const selectDeliveryComplaint = (complaint) => async (dispatch) => {
    return await dispatch({ type: types.select, payload: complaint });
};

export const deleteDeliveryComplaint = (id) => async (dispatch) => {
    return await dispatch(
        remove(
            new ApiRequest(endpoint + id, asAsyncFormTypes(deleteTypes.all), null, {
                method: httpMethods.DELETE,
                headers: httpHeaders.json,
            })
        )
    );
};
