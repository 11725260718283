import { IconButton } from "components/Button";
import Button from "components/Button/Button";
import { ArrayValidationError } from "components/Controls";
import { decimalNormalize } from "components/FormHelpers/ControlFormaters";
import { renderFieldOrText } from "components/FormHelpers/Controls";
import Layout from "components/Grid/Layout";
import { Field, FormSection } from "redux-form";
import { makeStyles } from "tss-react/mui";
import { useCommonTranslation } from "utils/hooks";
import { Grid } from "@mui/material";
import { validators } from "../validators";

const useStyles = makeStyles()((theme) => ({
    gridItem: {
        marginRight: theme.spacing(2),
    },
}));

const DeliveryCostRange = ({ fields, meta }) => {
    const { t, common } = useCommonTranslation();
    const { classes } = useStyles();

    return (
        <Layout
            main
            headerText={t(common.ranges)}
        >
            <ArrayValidationError {...meta} />
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                {fields.map((field, index) => {
                    return (
                        <FormSection
                            key={index}
                            name={field}
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                <Grid
                                    item
                                    className={classes.gridItem}
                                >
                                    <Field
                                        component={renderFieldOrText}
                                        name={"from"}
                                        label={t(common.from)}
                                        type="number"
                                        format={decimalNormalize}
                                        validate={validators.from}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    className={classes.gridItem}
                                >
                                    <Field
                                        component={renderFieldOrText}
                                        name={"deliveryCost"}
                                        label={t(common.deliveryCost)}
                                        type="number"
                                        format={decimalNormalize}
                                        validate={validators.deliveryCost}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    className={classes.gridItem}
                                    style={{ marginLeft: "auto" }}
                                >
                                    <IconButton
                                        icon="delete"
                                        onClick={() => {
                                            fields.remove(index);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </FormSection>
                    );
                })}
                <Grid
                    item
                    style={{ marginLeft: "auto" }}
                >
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            fields.push({});
                        }}
                    >
                        {t(common.add)}
                    </Button>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default DeliveryCostRange;
