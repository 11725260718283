import Button from "components/Button/Button";
import { AcceptRejectDialog, useModalState } from "components/Dialog";
import { changeUserActivation } from "store/identity/userDetails/actions";
import { useCommonTranslation } from "utils/hooks";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";

const ChangeAccountActivation = () => {
    const dispatch = useDispatch();
    const { userId, isDeactivated } = useSelector((state) => state.userDetails?.user) || {};
    const { t, common } = useCommonTranslation();
    const { modalState: open, handleOpen, handleClose } = useModalState(false);
    const whatResource = isDeactivated ? t(common.toActivateUser) : t(common.toDeactivateUser);

    if (!userId) {
        return <></>;
    }

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
            >
                <Grid item>
                    <Button
                        actionType={isDeactivated ? "accept" : "danger"}
                        onClick={() => handleOpen()}
                    >
                        {isDeactivated ? t(common.activateUser) : t(common.deactivateUser)}
                    </Button>
                </Grid>
            </Grid>
            <AcceptRejectDialog
                maxWidth={"sm"}
                open={open}
                handleAccept={async () => {
                    await dispatch(changeUserActivation(userId, !isDeactivated));
                    handleClose();
                }}
                handleReject={() => {
                    handleClose();
                }}
            >
                <Typography variant="body1">
                    {t(common.areYouSure, {
                        what: whatResource,
                    })}
                </Typography>
            </AcceptRejectDialog>
        </>
    );
};

export default ChangeAccountActivation;
