import { RSAA } from "redux-api-middleware";
import { commerce, manager } from "store/actionTypePrefixes";
import { combineIntoQueryParams } from "utils/objectExtensions";
import moment from "moment";
import _ from "lodash";

export const types = {
    GET_CALENDAR: `${commerce}/GET_CALENDAR`,
    GET_CALENDAR_SUCCESS: `${commerce}/GET_CALENDAR_SUCCESS`,
    GET_CALENDAR_FAILURE: `${commerce}/GET_CALENDAR_FAILURE`,
    GET_CALENDAR_STATISTICS: `${commerce}/GET_CALENDAR_STATISTICS`,
    GET_CALENDAR_STATISTICS_SUCCESS: `${commerce}/GET_CALENDAR_STATISTICS_SUCCESS`,
    GET_CALENDAR_STATISTICS_FAILURE: `${commerce}/GET_CALENDAR_STATISTICS_FAILURE`,
    INIT_LOADING: `${manager}/INIT_LOADING`,
    END_LOADING: `${manager}/END_LOADING`,
};

const getStatistics = (warehouse, closesAts) => async (dispatch) => {
    for (let closesAt of closesAts) {
        dispatch({
            [RSAA]: {
                endpoint: `api/v1/calendar/transfers/${warehouse}/${closesAt}/statistics`,
                headers: { "Content-Type": "application/json" },
                method: "GET",
                types: [
                    types.GET_CALENDAR_STATISTICS,
                    types.GET_CALENDAR_STATISTICS_SUCCESS,
                    {
                        type: types.GET_CALENDAR_STATISTICS_FAILURE,
                        meta: { hideError: true },
                    },
                ],
            },
        });
    }
};

const getCalendar = (params) => async (dispatch) => {
    await dispatch({ type: types.INIT_LOADING });

    const { day, warehouse } = params;
    const warehouses = warehouse === "WAW TOTAL" ? ["WAW", "WA2", "WA3"] : [warehouse];

    for (var warehouseElem in warehouses) {
        const queryParams = combineIntoQueryParams({
            dateFrom: moment(day).format("YYYY-MM-DD"),
            dateTo: moment(day).format("YYYY-MM-DD"),
            warehouse: warehouses[warehouseElem],
            pageSize: 250,
        });

        const partialResult = await dispatch({
            [RSAA]: {
                endpoint: `api/v1/calendar/windows?${queryParams}`,
                headers: { "Content-Type": "application/json" },
                method: "GET",
                types: [types.GET_CALENDAR, types.GET_CALENDAR_SUCCESS, types.GET_CALENDAR_FAILURE],
            },
        });

        const { payload = {} } = partialResult;
        const { items = [] } = payload;
        const closesAts = _(items)
            .map((x) => x.deliveryWindow.closesAt)
            .uniq()
            .value();

        await dispatch(getStatistics(warehouses[warehouseElem], closesAts));
    }

    return await dispatch({ type: types.END_LOADING });
};

export const actions = {
    getCalendar,
};
