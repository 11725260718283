import { makeStyles } from "tss-react/mui";
import { useCommonTranslation } from "utils/hooks";
import React, { Fragment } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import GetAppIcon from "@mui/icons-material/GetApp";
import PublishIcon from "@mui/icons-material/Publish";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { Divider, Grid } from "@mui/material";
import { Button } from "../Button";
import IconWithHint from "../Icons/IconWithHint";

const useStyles = makeStyles()((theme) => ({
    button: {
        marginLeft: "auto",
    },
    divider: {
        marginTop: theme.spacing(10),
    },
    parent: {
        position: "relative",
    },
    iconDeleteContainer: {
        position: "absolute",
        top: 0,
        right: 0,
    },
    iconExportContainer: {
        position: "absolute",
        top: 0,
        right: 65,
    },
}));

const ItemActions = ({ renderCondition = true, handleRemove, children, showExport, handleExport, t, common }) => {
    const { classes } = useStyles();

    if (!renderCondition) {
        return children;
    }

    return (
        <div className={classes.parent}>
            <div className={classes.iconDeleteContainer}>
                {showExport && (
                    <IconWithHint
                        icon={<GetAppIcon />}
                        onClick={handleExport}
                        title={t(common.export)}
                        custom
                    />
                )}
                <IconWithHint
                    icon={<DeleteIcon />}
                    onClick={handleRemove}
                    title={t(common.remove)}
                    custom
                />
            </div>
            {children}
        </div>
    );
};

const ListActions = ({
    renderCondition = true,
    useDivider = true,
    handleAdd,
    handleRemove,
    handleExport,
    handleExportAll,
    handleImport,
    children,
    className,
    noMarginInDiver = false,
    showExport = false,
}) => {
    const { classes } = useStyles();
    const { t, common } = useCommonTranslation();
    const hiddenFileInput = React.useRef(null);

    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };

    return (
        <Grid
            className={className}
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
        >
            {renderCondition && showExport && (
                <Grid
                    item
                    className={classes.button}
                >
                    <IconWithHint
                        icon={<PublishIcon />}
                        onClick={handleClick}
                        title={t(common.import)}
                        custom
                    />
                    <input
                        onChange={handleImport}
                        type="file"
                        style={{ display: "none" }}
                        ref={hiddenFileInput}
                    />
                    <IconWithHint
                        icon={<SaveAltIcon />}
                        onClick={handleExportAll}
                        title={t(common.exportAll)}
                        custom
                    />
                </Grid>
            )}
            <Grid item>
                {children.map((c, i) => {
                    return (
                        <Fragment key={i}>
                            <ItemActions
                                renderCondition={renderCondition}
                                handleRemove={() => handleRemove(i)}
                                showExport={showExport}
                                handleExport={() => handleExport(i)}
                                common={common}
                                t={t}
                            >
                                {c}
                            </ItemActions>

                            {useDivider && children.length !== i + 1 ? (
                                <Divider
                                    className={noMarginInDiver ? undefined : classes.divider}
                                    variant="fullWidth"
                                />
                            ) : undefined}
                        </Fragment>
                    );
                })}
            </Grid>
            {renderCondition && (
                <Grid
                    item
                    className={classes.button}
                >
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleAdd}
                    >
                        {t(common.add)}
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};

export default ListActions;
