import Layout from "components/Grid/Layout";
import { createColumn } from "components/Table/SimpleTable/SimpleTable";
import tableActionTypes from "components/Table/SimpleTable/TableActions/tableActionTypes";
import TableWithToggleFilter from "components/Table/TableWithToggleFilter/TableWithToggleFilter";
import { actions } from "store/vouchers/list/actions";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useCommonTranslation, useMappedDispatch, useParams } from "utils/hooks";
import { usePush } from "utils-ts/hooks";
import { voucherListSelector } from "./selectors";

const VoucherList = () => {
    const { type } = useParams();
    const { t, common } = useCommonTranslation();
    const { pushToForm: push } = usePush();
    const { remove } = useMappedDispatch(actions);
    const pushToForm = (id, type, isCopy) => {
        push(`/vouchers/${type}/form`, id, isCopy);
    };

    const columns = [
        createColumn("name", t(common.name), formatTypes.text),
        createColumn("validFrom", t(common.validFrom), formatTypes.time, {
            timeFormat: timePrecisonFormats.seconds,
        }),
        createColumn("validTo", t(common.validTo), formatTypes.time, {
            timeFormat: timePrecisonFormats.seconds,
        }),
        type === "generators" ? createColumn("isActive", t(common.active), formatTypes.boolean) : undefined,
        createColumn("createdAt", t(common.createdAt), formatTypes.time, {
            timeFormat: timePrecisonFormats.seconds,
        }),
        createColumn("updatedAt", t(common.updatedAt), formatTypes.time, {
            timeFormat: timePrecisonFormats.seconds,
        }),
        type === "batches"
            ? createColumn("batchStatus", t(common.batchStatus), formatTypes.translatable, {
                  translationKey: "common",
              })
            : undefined,
        createColumn("action", "", formatTypes.action, { actionType: tableActionTypes.preview }, (item) => pushToForm(item.id, type)),
        createColumn("action", "", formatTypes.action, { actionType: tableActionTypes.copy }, (item) => pushToForm(item.id, type, true)),
        createColumn("action", "", formatTypes.action, { actionType: tableActionTypes.delete }, (item) => remove(item.id, "batches")),
    ].filter(Boolean);

    return (
        <Layout
            main
            headerText={t(type)}
        >
            <TableWithToggleFilter
                columns={columns}
                addItemAction={() => pushToForm(undefined, type)}
                dataSelector={voucherListSelector}
                dataSourceAction={(params) => actions.getList({ type, ...params })}
                showToggle={type === "generators"}
            />
        </Layout>
    );
};

export default VoucherList;
