import InfoDialog from "components/Dialog/InfoDialog";
import { memoSelector } from "store/customers/customer/customerMemos";
import { useSelector } from "react-redux";
import { customers } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { MemoEditForm } from "./MemoForm";
import MemoTableAudit from "./MemoTableAudit";

const MemoDialog = ({ open, handleClose, customerId, updateMemosList }) => {
    const { t } = useTranslation("customers");
    const memo = useSelector(memoSelector);

    return (
        <InfoDialog
            title={t(customers.memo)}
            open={open}
            onClose={handleClose}
            maxWidth="md"
        >
            <MemoEditForm
                {...memo}
                handleClose={handleClose}
                customerId={customerId}
                updateMemosList={updateMemosList}
            />
            <MemoTableAudit />
        </InfoDialog>
    );
};

export default MemoDialog;
