import EnviromentInfo from "components/EviromentInfo/EnviromentInfo";
import { Footer, Header, MessageBox, Messages, Sidebar } from "components/Layout";
import roles from "consts/roles";
import { makeStyles } from "tss-react/mui";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import { useUser } from "context";
import { isEmpty } from "lodash";
import { ManagerRoutes as RoutesTS } from "routing-ts/ManagerRoutes";
import { CustomerContact } from "components-ts/customerContact";

const managerRoutes = RoutesTS.map((r) => r.GetLayoutItem())
    .filter((r) => !isEmpty(r.sidebarName))
    .map((r) => {
        if (r.items) {
            return {
                path: r.path,
                sidebarName: r.sidebarName,
                icon: r.icon,
                roles: r.roles,
                items: (r.items || [])
                    .filter((i) => !isEmpty(i.sidebarName))
                    .map((i) => {
                        return {
                            path: i.path,
                            sidebarName: i.sidebarName,
                            icon: i.icon,
                            roles: i.roles,
                        };
                    }),
            };
        } else {
            return {
                path: r.path,
                sidebarName: r.sidebarName,
                icon: r.icon,
                roles: r.roles,
            };
        }
    });

const useStyles = makeStyles()((theme) => ({
    wrapper: {
        position: "relative",
        top: "0",
        height: "100vh",
    },
    mainPanel: {
        [theme.breakpoints.up("lg")]: {
            width: `calc(100% - 240px)`,
        },
        overflow: "auto",
        position: "relative",
        float: "right",
        maxHeight: "100%",
        width: "100%",
        overflowScrolling: "touch",
    },
    content: {
        marginTop: "50px",
        padding: "10px",
        minHeight: "calc(100vh - 150px)",
    },
    container: {
        paddingRight: "5px",
        paddingLeft: "5px",
        marginRight: "auto",
        marginLeft: "auto",
    },
    map: {
        marginTop: "70px",
    },
}));

const Layout = () => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const { isInAnyRoleOrAdmin } = useUser();
    const isBokOrDebt = isInAnyRoleOrAdmin([roles.Support, roles.SupportManager, roles.DebtCoordinator]);
    const { classes } = useStyles();
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <div className={classes.wrapper}>
            <Sidebar
                routes={managerRoutes}
                handleDrawerToggle={handleDrawerToggle}
                open={mobileOpen}
            />
            <div className={classes.mainPanel}>
                <Header handleDrawerToggle={handleDrawerToggle} />
                <div className={classes.content}>
                    <div className={classes.container}>
                        <EnviromentInfo />
                        <Outlet />
                        <MessageBox />
                        <Messages />
                    </div>
                </div>

                <Footer />
            </div>
            {isBokOrDebt && <CustomerContact />}
        </div>
    );
};

export default Layout;
