import { notEmptyValidator } from "utils/validators/basic";
import { tValidation, validation } from "utils-ts/validations/translation";
import { inRange } from "./basic";

export default function postCodeValidator(postCode = "", country = "") {
    return new notEmptyValidator(postCode)
        .must(
            inRange(0, 6),
            tValidation(validation.postCodeLenght, {
                min: 0,
                max: 6,
            })
        )
        .when(country == "PL")
        .must((x) => x.toString().match(/^[0-9]{2}-[0-9]{3}$/), tValidation(validation.postCodeRegex))
        .when(country == "PL")
        .must(
            inRange(1, 10),
            tValidation(validation.postCodeLenght, {
                min: 1,
                max: 10,
            })
        )
        .when(country != "PL");
}
