import { RSAA } from "redux-api-middleware";
import { startSubmit, stopSubmit } from "redux-form";

export const ADD_NEW_USER = "@@administration/ADD_NEW_USER";
export const ADD_NEW_USER_SUCCESS = "@@administration/ADD_NEW_USER_SUCCESS";
export const ADD_NEW_USER_FAILURE = "@@administration/ADD_NEW_USER_FAILURE";

export const addNewUser = (email, firstName, lastName, password, userType) => {
    return (dispatch) => {
        dispatch(startSubmit("CreateUserForm"));
        return dispatch({
            [RSAA]: {
                endpoint: "api/users",
                headers: { "Content-Type": "application/json" },
                method: "POST",
                body: JSON.stringify({
                    userType,
                    email,
                    fullName: {
                        firstName,
                        lastName,
                    },
                    password,
                }),
                types: [
                    ADD_NEW_USER,
                    {
                        type: ADD_NEW_USER_SUCCESS,
                        payload: (action, state, res) => {
                            const contentType = res.headers.get("Content-Type");
                            if (contentType && ~contentType.indexOf("json")) {
                                return res.json().then((json) => {
                                    dispatch(stopSubmit("CreateUserForm"));
                                    return json.userId;
                                });
                            }
                        },
                    },
                    {
                        type: ADD_NEW_USER_FAILURE,
                        payload: (action, state, res) => {
                            dispatch(stopSubmit("CreateUserForm"));
                            return res;
                        },
                    },
                ],
            },
        });
    };
};
