import { combineReducers } from "redux";
import types from "./types";
import defaultListState from "../defaultListState";

const listInitialState = {
    ...defaultListState,
    isDownloadingPwu: {},
};

const list = (state = listInitialState, { type, payload }) => {
    switch (type) {
        case types.findProcedures.request:
            return {
                ...listInitialState,
                isLoading: true,
            };
        case types.findProcedures.success:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case types.findProcedures.failure:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return { ...state };
    }
};

const formInitialState = {
    isLoading: false,
    isSubbmitRequested: false,
    isDownloadingFile: false,
    isLoadingAcceptances: false,
};

const form = (state = formInitialState, { type, payload }) => {
    switch (type) {
        case types.formInit:
            return { ...formInitialState };

        case types.getProcedure.request:
        case types.createOrUpdateProcedure.request:
            return { isLoading: true };

        case types.downloadProcedureFile.request:
            return { ...state, isDownloadingFile: true };

        case types.downloadProcedureFile.success:
            return { ...state, isDownloadingFile: false };

        case types.getProcedureFile.request:
            return { ...state, isFetchingFile: true };

        case types.getProcedureAcceptances.request:
            return { ...state, isLoadingAcceptances: true, acceptances: [] };

        case types.getProcedureFile.success:
            return { ...state, fileContent: payload, isFetchingFile: false };

        case types.getProcedure.success:
            return { isLoading: false, ...payload };

        case types.getProcedureAcceptances.success:
            return { ...state, isLoadingAcceptances: false, acceptances: payload };

        case types.getProcedureFile.failure:
        case types.createOrUpdateProcedure.failure:
        case types.getProcedure.failure:
        case types.downloadProcedureFile.failure:
        case types.getProcedureAcceptances.failure:
            return {
                ...state,
                isLoading: false,
                isDownloadingFile: false,
                isLoadingAcceptances: false,
            };

        default:
            return { ...state };
    }
};

export const procedures = combineReducers({ form, list });
