import { FormField } from "components/Form";
import { toSelectList } from "utils/extensions/arrayExtensions";
import { useCommonTranslation } from "utils/hooks";
import { isEmpty, notEmptyAndNumber } from "utils/validators/basic";
import { Fragment } from "react";
import Modifier from "./Modifier";
import { useChangeType } from "./hooks";
import { DiscountModifierEffect } from "./model";

const DiscountModifier = ({ formName, field, effect }) => {
    const { t, common } = useCommonTranslation();
    const changeType = useChangeType(formName.form, field);
    const types = toSelectList(["modifier", "newValue"], t);

    const { discountModifier = {} } = effect;
    const { modifier = {} } = discountModifier;
    const { type } = modifier;
    return (
        <Fragment>
            <FormField
                name={"type"}
                label={t(common.modificationType)}
                type="select"
                items={types}
                onChange={(_, value) => {
                    changeType(
                        value === "modifier"
                            ? new DiscountModifierEffect()
                            : new DiscountModifierEffect({
                                  newValue: 1,
                              })
                    );
                }}
                hideDefaultItem
                validate={isEmpty}
            />
            {discountModifier.type === "modifier" ? (
                <Modifier
                    type={type}
                    formName={formName.form}
                    field={`${field}.discountModifier`}
                />
            ) : (
                <FormField
                    name={"newValue"}
                    label={t(common.value)}
                    type="number"
                    validate={notEmptyAndNumber}
                />
            )}
        </Fragment>
    );
};

export default DiscountModifier;
