import { FieldPath, FieldValues } from "react-hook-form";
import { FormControlProps } from "./types";
import { ToggleItem } from "control-types";
import { ToggleButtonGroup } from "components-ts/controls";
import { default as Controller } from "./Controller";

type FormToggleButtonsProps<T extends string | number | boolean, TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> = Omit<
    FormControlProps<T, TFieldValues, TName>,
    "label" | "emptyValue"
> & {
    items: ToggleItem<T>[];
    label?: string;
    hideSelectedIcon?: boolean;
    onAfterChange?: (newValue: T | undefined) => void;
};

const FormToggleButtons = <
    T extends string | number | boolean,
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    name,
    label,
    defaultValue,
    width,
    items,
    readOnly,
    hideSelectedIcon,
    onAfterChange,
}: FormToggleButtonsProps<T, FieldValues, TName>): JSX.Element => {
    return (
        <Controller<T | undefined>
            name={name}
            render={({ field: { ref, onChange, value, disabled: _, ...fieldProps }, fieldState: { error } }) => {
                return (
                    <ToggleButtonGroup<T>
                        ref={ref}
                        value={value}
                        label={label}
                        items={items}
                        readOnly={readOnly}
                        onChange={(value) => {
                            onChange(value);
                            if (onAfterChange) {
                                onAfterChange(value);
                            }
                        }}
                        error={{
                            hasError: Boolean(error),
                            message: error?.message,
                        }}
                        hideSelectedIcon={hideSelectedIcon}
                    />
                );
            }}
            defaultValue={defaultValue}
            transform={(val) =>
                typeof val === "number" || typeof val === "boolean" ? val : typeof val === "string" && val.length > 0 ? val : undefined
            }
            width={width}
        />
    );
};

export default FormToggleButtons;
