import { crawler, manager } from "store/actionTypePrefixes";
import { AsyncTypes, PagedAsyncTypes, asAsyncFormTypes } from "utils/extensions";

export const types = {
    clearList: `${manager}/CLEAR_EXTERNAL_PRODUCT_LIST`,
    updateListFilters: `${manager}/UPDATE_EXTERNAL_PRODUCT_LIST_FILTER`,
    list: new PagedAsyncTypes("GET_EXTERNAL_PRODUCTS", crawler),
    form: new AsyncTypes("GET_EXTERNAL_PRODUCT_FORM", crawler),
    put: new AsyncTypes("PUT_EXTERNAL_PRODUCT", crawler, [asAsyncFormTypes]),
    import: new AsyncTypes("IMPORT_EXTERNAL_PRODUCTS_MATCHINGS", crawler),
    exportUnmatched: new AsyncTypes("EXPORT_UNMATCHED_EXTERNAL_PRODUCTS", crawler),
    exportNew: new AsyncTypes("EXPORT_NEW_EXTERNAL_PRODUCTS", crawler),
    clearImportErrors: `${manager}/CLEAR_EXTERNAL_PRODUCTS_MATCHINGS_ERRORS`,
};
