import types from "./types";

const initialState = {};
const { createOrUpdate, formInit, getContract, downloadFile, removeFile, findContractForApproval, findDraft } = types;

export const contract = (state = initialState, { type, payload }) => {
    switch (type) {
        case formInit:
            return { ...initialState };
        case getContract.request:
        case findContractForApproval.request:
        case findDraft.request:
            return { ...initialState, isLoading: true };
        case getContract.success:
        case findContractForApproval.success:
            return { ...state, ...payload, isLoading: false };
        case findDraft.success:
            return { ...state, ...payload, isLoading: false };
        case downloadFile.success:
            return {
                ...state,
                isLoading: false,
                fileUploadInProgress: false,
            };
        case getContract.failure:
        case findContractForApproval.failure:
        case findDraft.failure:
            return { ...payload, isLoading: false };
        case downloadFile.failure:
        case removeFile.failure:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case createOrUpdate.request:
            return {
                ...state,
                isSubbmitRequested: true,
            };
        case createOrUpdate.success:
            return {
                ...state,
                contractId: payload || state.contractId,
                isSubbmitRequested: false,
            };
        case createOrUpdate.failure:
            return {
                ...state,
                isSubbmitRequested: false,
            };
        default:
            return state;
    }
};
