import Button from "components/Button/Button";
import { FormField } from "components/Form";
import GridContainer from "components/Grid/GridContainer";
import warehouses from "resource/warehouses.json";
import { common, orders } from "translations";
import { useTranslation } from "utils-ts/hooks";

const ReviewListSearchForm = ({ isLoading, submit, pageIndex, pageSize }) => {
    const { t } = useTranslation(["orders", "common"]);

    return (
        <form>
            <GridContainer
                title={`orders:${orders.delivery}`}
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
            >
                <FormField
                    fullWidth
                    type="date"
                    label={t(orders.deliveryFrom)}
                    name="deliveryFrom"
                />
                <FormField
                    fullWidth
                    type="date"
                    label={t(orders.deliveryTo)}
                    name="deliveryTo"
                />
                <FormField
                    name="warehouse"
                    type="select"
                    label={t(orders.warehouse)}
                    items={warehouses
                        .filter((w) => w.value !== "OMU")
                        .map((o) => {
                            return { ...o, disabled: false };
                        })}
                />
                <Button
                    color="primary"
                    variant="contained"
                    actionType="accept"
                    fullWidth
                    onClick={() => submit(pageIndex, pageSize)}
                    disabled={isLoading}
                    loading={isLoading}
                >
                    {t(`common:${common.search}`)}
                </Button>
            </GridContainer>
        </form>
    );
};

export default ReviewListSearchForm;
