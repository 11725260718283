import { FormField } from "components/Form";
import { feedFormatsSelector, getFeedFormats as getFeedFormatsAction } from "store/autocomplete";
import { useCommonTranslation } from "utils/hooks";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

const FeedFormatAutocomplete = (props) => {
    const { t, common } = useCommonTranslation();
    const feedFormats = useSelector(feedFormatsSelector);
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(getFeedFormatsAction());
    }, [dispatch]);

    return (
        <FormField
            name={"feedFormat"}
            type={"autocomplete"}
            label={t(common.feedFormat)}
            items={feedFormats}
            {...props}
        />
    );
};

export default FeedFormatAutocomplete;
