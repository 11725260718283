export const vatinMask = "### ## ## ###";

const mask = (value, pattern) => {
    const str = value.toString().split("").reverse();
    let result = [];
    for (let p of pattern) {
        if (p !== " ") {
            result = [...result, str.pop()];
        } else {
            result = [...result, " "];
        }
    }

    return result;
};

export default mask;
