import Button from "components/Button/Button";
import GridContainer from "components/Grid/GridContainer";
import Layout from "components/Grid/Layout";
import { FormName, formValueSelector, reduxForm } from "redux-form";
import { campaingsSelector, getCampaigns } from "store/autocomplete";
import { actions } from "store/customers/customer/customerVouchers/action";
import { formatTypes, formatValue } from "utils/formating";
import withMatchParams from "utils/hoc/withMatchParams";
import { useParams } from "utils/hooks";
import React from "react";
import { connect, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import moment from "moment";
import _, { chain } from "lodash";
import { bindActionCreators } from "redux";
import { common, voucherKinds } from "translations";
import { usePush, useTranslation } from "utils-ts/hooks";
import { fields, handleFormSubmit, validate } from "./Form";
import VoucherField from "./VoucherField";

const Voucher = (props) => {
    const { voucher, getCampaigns, selectedVoucherType, handleSubmit, makeVoucher, pristine, getVoucher, isEditMode } = props;
    const { isSubmiting } = voucher;
    const { state } = useLocation();
    const { voucherId = "" } = state || {};
    const campaigns = useSelector(campaingsSelector);
    const { customerId } = useParams();
    const { push } = usePush();
    React.useEffect(() => {
        if (voucherId) {
            getVoucher(voucherId);
        }
        getCampaigns();
    }, [getVoucher, getCampaigns, voucherId]);
    const { t } = useTranslation(["customers", "voucherKinds", "common"]);

    return (
        <Layout
            main
            headerText={t(`common:${common.voucher}`)}
            navigationProps={{
                backProps: {
                    path: `/customers/${customerId}`,
                    state: { tabIndex: 7 },
                },
            }}
        >
            <form
                onSubmit={handleSubmit(async (val) => {
                    const result = await handleFormSubmit(makeVoucher, val, voucherId);

                    if (result.meta?.formSubmit === "SUCCESS") {
                        push(`/customers/${customerId}`, undefined, {
                            tabIndex: 7,
                        });
                    }
                })}
            >
                <GridContainer
                    iconName="redeem"
                    title={common.voucher}
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="flex-start"
                >
                    <FormName>
                        {({ form }) => {
                            return chain(fields(t, isEditMode, selectedVoucherType, campaigns))
                                .filter((f) => !f.skip)
                                .groupBy((f) => f.column)
                                .map((x) => x)
                                .value()
                                .map((fragment, i) => {
                                    return (
                                        <VoucherField
                                            key={i}
                                            fragment={fragment}
                                            form={form}
                                        />
                                    );
                                });
                        }}
                    </FormName>
                </GridContainer>
                <Button
                    color="primary"
                    variant="contained"
                    actionType="accept"
                    fullWidth
                    type={"submit"}
                    disabled={isSubmiting || pristine}
                    loading={isSubmiting}
                >
                    {t(`common:${common.submit}`)}
                </Button>
            </form>
        </Layout>
    );
};

const mapStateToProps = (state, props) => {
    const {
        customer: {
            customerVouchers: { voucher, editVoucher },
        },
    } = state;
    const {
        match: {
            params: { customerId: userId },
        },
    } = props;
    const selectedVoucherType = formValueSelector("customerVoucher")(state, "type");
    const bonusPoints = formValueSelector("customerVoucher")(state, "bonusPoints");
    const isEditMode = !_.isEmpty(editVoucher);
    const editedVoucherType = isEditMode
        ? formatValue(editVoucher.definition, formatTypes.voucherKind, {
              translate: false,
          })
        : null;

    const promotions = _.get(editVoucher, "definition.voucherPromotions", []);
    const [first = {}] = promotions;
    const { definition = {} } = first;
    const { effects = [] } = definition;
    const isFreeDelivery = _.get(effects[0], "deliveryModifier.newDeliveryCost") === 0;
    const initialValues = {
        userId,
        ...editVoucher,
        validFrom: isEditMode ? moment(editVoucher.validFrom).startOf("day").format() : moment().startOf("day").format(),
        validTo: isEditMode ? moment(editVoucher.validTo).endOf("day").format() : null,
        type: editedVoucherType,
        definition: {
            useLimit: 1,
            ...editVoucher.definition,
            freeDelivery: isFreeDelivery,
        },
        isSending: true,
        complaintId: editVoucher.complaintId ? editVoucher.complaintId : props.match.params.complaintId,
    };

    if (editedVoucherType === voucherKinds.amount_voucher) {
        initialValues.frs = editVoucher.definition.paymentAmount.FRS;
        initialValues.f24 = editVoucher.definition.paymentAmount.F24;
    }

    return {
        isEditMode,
        voucher,
        selectedVoucherType,
        bonusPoints,
        initialValues,
    };
};
const mapDispatchToProps = (dispatch) => bindActionCreators({ ...actions, getCampaigns }, dispatch);

export default withMatchParams(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            form: "customerVoucher",
            enableReinitialize: true,
            validate,
        })(Voucher)
    )
);
