import Cell from "components/Table/SimpleTable/Cell";
import Row from "components/Table/SimpleTable/Row";
import Action from "components/Table/SimpleTable/TableActions/Action";
import { B2BAndSupportDepartmentRoles } from "consts/roles";
import { withStyles } from "tss-react/mui";
import { formatTypes, formatValue } from "utils/formating";
import moment from "moment";
import { useUser } from "context";

const CustomerVoucherRow = ({ columns, item, classes, onDobuleClick }) => {
    let className;
    const isExpired = moment(item.validTo).isBefore(moment());
    const isUsed = item.useCount === item.definition.useLimit;
    const { isInAnyRoleOrAdmin } = useUser();
    const disabled = !isInAnyRoleOrAdmin(B2BAndSupportDepartmentRoles);
    if (isUsed && !isExpired) {
        className = classes.active;
    } else if (!isUsed && isExpired) {
        className = classes.notUsedNotActive;
    }

    return (
        <Row
            className={className}
            onDobuleClick={() => onDobuleClick(item.id)}
        >
            {columns.map(({ key, type, options, action, actionType }, index) => {
                if (key === "kind") {
                    return (
                        <Cell
                            key={index}
                            value={formatValue(item.definition, formatTypes.voucherKind)}
                        />
                    );
                }

                if (key === "usage") {
                    return (
                        <Cell
                            key={index}
                            value={`${item.useCount}/${item.definition.useLimit}`}
                        />
                    );
                }

                if (key === "value") {
                    let value = "";
                    if (item.definition.voucherValue) value = formatValue(item.definition.voucherValue, formatTypes.currency);
                    else if (item.definition.paymentAmount) value = formatValue(item.definition.paymentAmount, formatTypes.currencyAmount);
                    else if (item.definition.voucherPercent) value = formatValue(item.definition.voucherPercent, formatTypes.percent);

                    return (
                        <Cell
                            key={index}
                            value={value}
                        />
                    );
                }

                if (key === "requirements") {
                    if (item.definition.requirements && item.definition.requirements.total && item.definition.requirements.total.minimumValue)
                        return (
                            <Cell
                                key={index}
                                value={formatValue(item.definition.requirements.total.minimumValue, formatTypes.currency)}
                            />
                        );
                    return (
                        <Cell
                            key={index}
                            value=""
                        />
                    );
                }

                if (key === "edit") {
                    return (
                        <Action
                            disabled={disabled}
                            key={index}
                            handleClick={() => action(item)}
                            actionType={actionType}
                        />
                    );
                }

                return (
                    <Cell
                        key={index}
                        value={formatValue(item[key], type, options)}
                    />
                );
            })}
        </Row>
    );
};

export default withStyles(CustomerVoucherRow, () => ({
    notUsedNotActive: { backgroundColor: "#FDFDE3" },
    active: { backgroundColor: "#E0FCE3" },
}));
