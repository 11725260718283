import { CartPredicate, UserActivationLimit } from "components/Controls";
import Layout from "components/Grid/Layout";
import { FieldArray, FormSection } from "redux-form";
import { useCommonTranslation } from "utils/hooks";
import React from "react";
import { validators } from "../../validators";
import RewardEffects from "./RewardEffects";

const RewardDefinition = ({ readOnly = false }) => {
    const { t, common } = useCommonTranslation();

    return (
        <FormSection name="definition">
            <UserActivationLimit readOnly={readOnly} />

            <CartPredicate
                name="requirements"
                required={false}
                readOnly={readOnly}
            />

            <Layout headerText={t(common.effect)}>
                <FieldArray
                    name="effects"
                    component={RewardEffects}
                    validate={validators.effectValidation}
                    readOnly={readOnly}
                />
            </Layout>
        </FormSection>
    );
};

export default RewardDefinition;
