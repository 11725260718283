import SimpleTable, { createColumn } from "components/Table/SimpleTable/SimpleTable";
import tableActionTypes from "components/Table/SimpleTable/TableActions/tableActionTypes";
import { correctionRequestsListSelector, getCorrectionRequestFile, getCorrectionRequests } from "store/vendors/correctionRequests";
import { makeStyles } from "tss-react/mui";
import { useDebouncedCallback } from "use-debounce";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useParams } from "utils/hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { vendor } from "translations";
import { usePush, useTranslation } from "utils-ts/hooks";

const useStyles = makeStyles()(() => ({
    table: {
        "& tbody": {
            "& td:nth-of-type(5)": {
                wordBreak: "break-all",
            },
        },
    },
}));

const CorrectionRequests = () => {
    const initFilter = {
        dateFrom: undefined,
        dateTo: undefined,
        settlementTypes: undefined,
    };
    const { classes } = useStyles();
    const { id: vendorId } = useParams();
    const [filter, setFilter] = useState(initFilter);
    const { t } = useTranslation("vendor");
    const dispatch = useDispatch();
    const { push } = usePush();
    const { items, pageIndex, pageSize, totalCount, isLoading } = useSelector(correctionRequestsListSelector);

    const settlementTypes = [
        {
            value: "Refundation",
            name: t(vendor.Refundation),
        },
        {
            value: "RetroConditional",
            name: t(vendor.RetroConditional),
        },
        {
            value: "RetroUnconditional",
            name: t(vendor.RetroUnconditional),
        },
    ];

    useEffect(() => {
        dispatch(
            getCorrectionRequests(vendorId, {
                ...filter,
                pageSize,
                pageIndex: 1,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vendorId, dispatch, filter]);

    const columns = [
        createColumn("requestNumber", t(vendor.correctionRequestNumber), formatTypes.text),
        createColumn(
            "settlementType",
            t(vendor.settlementType),
            formatTypes.translatable,
            {
                filterType: "select",
                filterItems: settlementTypes,
                translationKey: "vendor",
            },
            null,
            true
        ),
        createColumn("refundationsNumbers", t(vendor.refundationNumber), formatTypes.text),
        createColumn("dateFrom", t(vendor.dateFrom), formatTypes.time, { timeFormat: timePrecisonFormats.days }, null, true),
        createColumn("dateTo", t(vendor.dateTo), formatTypes.time, { timeFormat: timePrecisonFormats.days }, null, true),
        createColumn("description", t(vendor.description), formatTypes.text, {
            style: { "word-break": "break-all" },
        }),
        createColumn(
            "action",
            "",
            formatTypes.action,
            {
                actionType: () => tableActionTypes.download,
                resetButton: false,
            },
            (item) => {
                const { id } = item;
                dispatch(getCorrectionRequestFile(vendorId, id, t(vendor.CorrectionRequests)));
            }
        ),
        createColumn(
            "action",
            "",
            formatTypes.action,
            {
                actionType: () => tableActionTypes.preview,
                resetButton: true,
            },
            (item) => {
                const { id } = item;
                push(`/vendors/${vendorId}/correction-request/${id}`);
            }
        ),
    ];
    const handleFilter = useDebouncedCallback((column, value) => {
        if (column === undefined && value === undefined) {
            setFilter(initFilter);
            return;
        }
        setFilter({ ...filter, [column]: value });
    }, 1500);

    return (
        <>
            <SimpleTable
                className={classes.table}
                columns={columns}
                data={items}
                filterFunction={handleFilter}
                onRowDoubleClick={(_, item) => {
                    const { id } = item;
                    push(`/vendors/${vendorId}/correction-request/${id}`);
                }}
                isLoading={isLoading}
                stripped
                hover
                pagination
                persistentFilter
                paginationProps={{
                    pageIndex,
                    totalCount,
                    pageSize,
                    onChangePage: (pageIndex) =>
                        dispatch(
                            getCorrectionRequests(vendorId, {
                                ...filter,
                                pageSize,
                                pageIndex,
                            })
                        ),
                    onChangeRowsPerPage: (pageSize) =>
                        dispatch(
                            getCorrectionRequests(vendorId, {
                                ...filter,
                                pageIndex: 1,
                                pageSize,
                            })
                        ),
                }}
                t={t("vendor")}
            />
        </>
    );
};

export default CorrectionRequests;
