import { generatePath } from "react-router-dom";
import { DecisionResponseModel, ProcedureMakeDecision, ProcedureResponse, ProceduresListResponse, ProceduresPageRequest } from "Operations";
import { useCreateOrUpdateMutation, useGetQuery } from "api/hooks";

const url = (employeeId: string, id?: string) =>
    generatePath(`api/employees/:employeeId/procedures/:id?`, { employeeId: employeeId, id: id ? id : null });

export const useFindProcedures = (employeeId: string, initialParams: ProceduresPageRequest) => {
    return useGetQuery<ProceduresListResponse, ProceduresPageRequest>({
        app: "operations",
        url: url(employeeId),
        queryParams: initialParams,
    });
};

export const useFindProcedure = (employeeId: string, id?: string) => {
    return useGetQuery<ProcedureResponse>({
        app: "operations",
        url: url(employeeId, id),
        shouldMakeRequest: Boolean(id),
    });
};

export const useFindUnacceptedProcedures = (employeeId: string) => {
    return useGetQuery<ProcedureResponse[], ProceduresPageRequest>({
        app: "operations",
        url: url(employeeId) + "/unaccepted",
    });
};

export const useMakeDecision = (employeeId: string, id?: string) => {
    return useCreateOrUpdateMutation<ProcedureMakeDecision, DecisionResponseModel>({
        app: "operations",
        url: url(employeeId, id) + "/make-decision",
        id: id,
    });
};
