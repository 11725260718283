import { manager, operations } from "store/actionTypePrefixes";
import { AsyncTypes, asAsyncFormTypes, createAction } from "utils/extensions";

const types = {
    getToolkits: new AsyncTypes("GET_TOOLKITS", operations),
    getToolkit: new AsyncTypes("GET_TOOLKIT", operations),
    removeToolkit: new AsyncTypes("DELETE_TOOLKIT", operations),
    createToolkit: new AsyncTypes("CREATE_TOOLKIT", operations, [(types) => asAsyncFormTypes(types)]),
    updateToolkit: new AsyncTypes("UPDATE_TOOLKIT", operations, [asAsyncFormTypes]),
    formInit: createAction("FORM_INIT_TOOLKIT", manager),
    preCommit: new AsyncTypes("PRE_COMMIT_TOOLKIT", operations),
    downloadPriceListFile: new AsyncTypes("DOWNLOAD_PRICE_LIST_FILE", operations, [
        (types) =>
            asAsyncFormTypes(types, {
                requestMessage: "Pobieranie pliku...",
                successMessage: "Plik pobrany",
                failureMessage: "Błąd podczas pobierania pliku",
            }),
    ]),
};

export default types;
