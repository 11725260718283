import { Preloader } from "components/Preloader";
import { contractSelector, initForm } from "store/vendors/contracts";
import { useParams } from "utils/hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";
import VendorLayout from "views/vendors/components/VendorLayout";
import ContractForm from "./ContractForm";

const Contract = () => {
    const { vendorId, contractId, typeOfChange } = useParams();
    const { t } = useTranslation("vendor");
    const dispatch = useDispatch();
    const { isLoading, dateFrom, dateTo } = useSelector(contractSelector);

    useEffect(() => {
        dispatch(initForm(vendorId, contractId, !_.isEmpty(typeOfChange)));
    }, [contractId, dispatch, vendorId, typeOfChange]);

    return (
        <VendorLayout
            headerText={t(vendor.contractForm, { id: contractId })}
            vendorId={vendorId}
            isLoading={isLoading}
            tabIndex={2}
            customTitle={`${vendorId} ${t(vendor.contractForm)} ${
                dateFrom ? `${moment(dateFrom).format("DD.MM.YYYY")}` + (dateTo ? `-${moment(dateTo).format("DD.MM.YYYY")}` : "") : ""
            }`}
        >
            {isLoading ? (
                <Preloader />
            ) : (
                <ContractForm
                    handleDecision={() => {
                        dispatch(initForm(vendorId, contractId));
                    }}
                />
            )}
        </VendorLayout>
    );
};

export default Contract;
