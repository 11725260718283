import { IconButton } from "components/Button";
import { ContentBrandsAutocomplete, ProductAnalyticGroupsAutocomplete } from "components/Controls/Autocompletes";
import ProductListField from "components/Controls/ProductListField";
import Dropzone from "components/Files/Dropzone";
import { Form, FormField } from "components/Form";
import TextWithLabel from "components/Text/TextWithLabel";
import roles from "consts/roles";
import { useUser } from "context/UserContext/UserContext";
import { reduxForm } from "redux-form";
import { actions } from "store/cache/products/actions";
import { certyficateFormSelector, createOrUpdateCertificate, getCertificateFile, initForm } from "store/vendors/certificates";
import { useChange, useFormValueSelector, useParams } from "utils/hooks";
import { useCallback, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Delete as DeleteIcon, GetApp as DownloadIcon } from "@mui/icons-material";
import { Grid } from "@mui/material";
import _ from "lodash";
import { vendor } from "translations";
import { usePush, useTranslation } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { validators } from "./validation";

const editRoles = [roles.SalesAssistant, roles.SalesManager, roles.Management, roles.Catman];

const CertificateForm = ({ handleSubmit, pristine, isSubbmitRequested }) => {
    const { vendorId, certificateId } = useParams();
    const { replace } = usePush();
    const { t } = useTranslation("vendor");
    const { isInAnyRoleOrAdmin } = useUser();
    const readOnly = !isInAnyRoleOrAdmin(editRoles);
    const change = useChange(formName);
    const dispatch = useDispatch();
    const { blobName, fileName, products, appliesToAllProducts } = useFormValueSelector(
        formName,
        ["blobName", "fileName", "products", "appliesToAllProducts"],
        {}
    );

    useEffect(() => {
        dispatch(actions.getProducts(products));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, !_.isEmpty(products)]);

    const handleDrop = useCallback(
        (acceptedFiles) => {
            acceptedFiles.forEach((file) => {
                const reader = new FileReader();

                reader.onload = function (e) {
                    const fileItem = {
                        extension: `.${file.name.split(".").slice(-1)[0].toLowerCase()}`,
                        fileName: file.name,
                        fileContent: e.target.result.split(",")[1],
                    };

                    change("file", fileItem);
                    change("fileName", file.name);
                    change("fileContent", fileItem.fileContent);
                    change("blobName", null);
                };

                reader.readAsDataURL(file);
            });
        },
        [change]
    );

    const handleDownloadFile = async () => {
        if (blobName) {
            await dispatch(getCertificateFile(vendorId, certificateId, blobName));
        }
    };

    const changeAppliesToAllProducts = (_, newValue) => {
        if (newValue === true) {
            change("analyticGroups", []);
            change("brands", []);
            change("products", []);
        }
    };

    return (
        <Form
            onSubmit={handleSubmit(async (values) => {
                if (!certificateId && !!!fileName) {
                    return;
                }

                if (values.fileContent == undefined) {
                    values.fileName = undefined;
                }

                const { payload } = await dispatch(createOrUpdateCertificate(vendorId, certificateId, values));

                if (Boolean(payload?.id)) {
                    replace(Paths.Vendor.CertificateForm, {
                        vendorId,
                        certificateId: payload.id,
                    });
                } else if (certificateId) {
                    await dispatch(initForm(vendorId, certificateId));
                }
            })}
            isSubbmitRequested={isSubbmitRequested}
            pristine={pristine}
        >
            <Grid
                container
                direction="row"
                alignItems="stretch"
            >
                <FormField
                    name="certificateNumber"
                    label={t(vendor.certificateNumber)}
                    type="text"
                    validate={validators.required}
                    readOnly={readOnly}
                />
                <FormField
                    name="certificateProvider"
                    label={t(vendor.certificateProvider)}
                    type="text"
                    validate={validators.required}
                    readOnly={readOnly}
                />
                <FormField
                    name="isProducerCertificate"
                    label={t(vendor.isProducerCertificate)}
                    type="boolean"
                    readOnly={readOnly}
                />
            </Grid>
            <Grid
                container
                direction="row"
                alignItems="stretch"
            >
                <FormField
                    name="certificationDate"
                    label={t(vendor.certificationDate)}
                    type="date"
                    validate={validators.validateFrom}
                    readOnly={readOnly}
                />
                <FormField
                    name="expirationDate"
                    label={t(vendor.expirationDate)}
                    type="date"
                    validate={validators.validateTo}
                    readOnly={readOnly}
                />
                <FormField
                    name="isForChemistry"
                    label={t(vendor.isForChemistry)}
                    type="boolean"
                    readOnly={readOnly}
                />
                <FormField
                    name="isForGroceries"
                    label={t(vendor.isForGroceries)}
                    type="boolean"
                    readOnly={readOnly}
                />
            </Grid>

            <Grid
                container
                direction="row"
                alignItems="stretch"
            >
                <FormField
                    stretch
                    name="comment"
                    label={t(vendor.comment)}
                    type="text"
                    readOnly={readOnly}
                />
            </Grid>

            <Grid
                container
                direction="row"
                alignItems="stretch"
            >
                <FormField
                    name="appliesToAllProducts"
                    label={t(vendor.appliesToAllProducts)}
                    type="boolean"
                    onChange={changeAppliesToAllProducts}
                    readOnly={readOnly}
                />
                {!appliesToAllProducts && (
                    <>
                        <ProductAnalyticGroupsAutocomplete
                            validate={readOnly ? undefined : validators.atLeastOneProductField}
                            readOnly={readOnly}
                        />
                        <ContentBrandsAutocomplete
                            validate={readOnly ? undefined : validators.atLeastOneProductField}
                            readOnly={readOnly}
                        />
                        <ProductListField
                            validate={readOnly ? undefined : validators.atLeastOneProductField}
                            readOnly={readOnly}
                        />
                    </>
                )}
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                {!readOnly && (
                    <Grid item>
                        <Dropzone
                            onDrop={handleDrop}
                            maxFiles={1}
                            hasFile={fileName}
                            required={!certificateId}
                            accept=".pdf"
                        />
                    </Grid>
                )}
                {fileName && (
                    <>
                        <TextWithLabel
                            value={fileName}
                            translatedLabel={t(vendor.fileName)}
                        />
                        <Grid item>
                            <IconButton
                                edge="end"
                                onClick={() => handleDownloadFile()}
                                disabled={!Boolean(blobName)}
                                icon={<DownloadIcon />}
                            />
                        </Grid>
                        <Grid item>
                            <IconButton
                                edge="end"
                                onClick={() => {
                                    change("file", null);
                                    change("fileName", null);
                                    change("fileContent", null);
                                }}
                                disabled={readOnly || !fileName || Boolean(blobName)}
                                icon={<DeleteIcon />}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </Form>
    );
};

const formName = "certificate-form";

export default connect((state, props) => {
    const form = certyficateFormSelector(state);

    return {
        initialValues: { ...form, vendorId: props.vendorId },
    };
})(
    reduxForm({
        form: formName,
        enableReinitialize: true,
    })(CertificateForm)
);
