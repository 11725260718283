import TextWithLabel from "components/Text/TextWithLabel";
import { makeStyles } from "tss-react/mui";
import { formatTypes, formatValue, timePrecisonFormats } from "utils/formating";
import { useCommonTranslation } from "utils/hooks";
import _ from "lodash";

const formatDictinary = {
    date: (val, _, dateFormat) =>
        formatValue(val, formatTypes.time, {
            timeFormat: dateFormat || timePrecisonFormats.days,
        }),
    boolean: (val) => formatValue(val, formatTypes.boolean),
    select: (val, items) => {
        return items?.length ? items.find((x) => x.value === val)?.name : val;
    },
    autocomplete: (val, items) => {
        return items?.length ? items.find((x) => x.id === val?.id)?.name : val;
    },
};

const getLabelState = (current, proposal, isDateTime, format, type) => {
    if (_.isEqual(current, proposal)) {
        return "";
    }

    if (type === "boolean" && _.isBoolean(current) && _.isBoolean(proposal)) {
        return "changed";
    }

    if (current === undefined || current === null) {
        return "added";
    }

    if (proposal === undefined || proposal === null) {
        return "deleted";
    }

    if (isDateTime) {
        if (_.isEqual(format(current), format(proposal))) return "";
    }

    return "changed";
};

const useStyles = makeStyles()((theme) => ({
    added: {
        color: theme.palette.success.main,
    },
    deleted: {
        color: theme.palette.error.main,
    },
    changed: {
        color: theme.palette.warning.main,
    },
}));

const DefaultDiffControl = ({ input: { value }, label, type, items, dateFormat, adornment, disableCurrent, disableProposal }) => {
    const { t, common } = useCommonTranslation();
    const { classes } = useStyles();

    const format = (val, adornment) => {
        if (
            !val &&
            ((type === "boolean" && val !== false) ||
                (type === "text" && (val === undefined || val === null || val.toString().length === 0)) ||
                (type === "numeric" && val !== 0))
        ) {
            return t(common.noValue);
        }

        const formatFun =
            formatDictinary[type] ||
            function (val) {
                return val;
            };

        var formated = formatFun(val, items, dateFormat);
        if (Boolean(adornment)) {
            if (adornment.position == "start") {
                formated = adornment.value + " " + formated;
            } else {
                formated = formated + " " + adornment.value;
            }
        }

        return formated;
    };

    const current = disableCurrent ? undefined : value?.current;
    const proposal = disableProposal ? undefined : value?.proposal;

    const isDateTime = type === "date" || type === "dateTime";

    const changeType = getLabelState(current, proposal, isDateTime, format, type);
    let currentAdornment, proposalAdornment;
    if (Boolean(adornment)) {
        if (adornment.value.includes("➨")) {
            currentAdornment = {
                position: adornment.position,
                value: adornment.value.split("➨")[0],
            };
            proposalAdornment = {
                position: adornment.position,
                value: adornment.value.split("➨")[1],
            };
        } else {
            currentAdornment = adornment;
            proposalAdornment = adornment;
        }
    }

    let fieldValue = _.isEqual(current, proposal)
        ? format(current, currentAdornment)
        : current === undefined
        ? format(proposal, proposalAdornment)
        : proposal === undefined
        ? format(current, currentAdornment)
        : `${format(current, currentAdornment)} ➨ ${format(proposal, proposalAdornment)}`;

    if (type === "boolean" && _.isBoolean(current) && _.isBoolean(proposal) && current !== proposal) {
        fieldValue = `${format(current)} ➨ ${format(proposal)}`;
    }

    return (
        <TextWithLabel
            className={{ label: classes[changeType] }}
            translatedLabel={`${label}${changeType && t(`${changeType}Postfix`)}`}
            value={fieldValue}
        />
    );
};

export default DefaultDiffControl;
