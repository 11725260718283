import { manager, operations } from "store/actionTypePrefixes";
import { AsyncTypes, asAsyncFormTypes, createAction } from "utils/extensions";

const types = {
    getCertificates: new AsyncTypes("GET_CERTIFICATES", operations),
    getCertificate: new AsyncTypes("GET_CERTIFICATE", operations),
    formInit: createAction("FORM_INIT_CERTIFICATE", manager),
    createCertificate: new AsyncTypes("CREATE_CERTIFICATE", operations, [(types) => asAsyncFormTypes(types)]),
    updateCertificate: new AsyncTypes("UPDATE_CERTIFICATE", operations, [asAsyncFormTypes]),
    deleteCertificate: new AsyncTypes("DELETE_CERTIFICATE", operations),
    downloadCertificate: new AsyncTypes("DOWNLOAD_CERTIFICATE", operations),
    getCertificatesForAudit: new AsyncTypes("GET_CERTIFICATES_FOR_AUDIT", operations),
};

export default types;
