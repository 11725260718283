import Validator, {
    greaterDateThan,
    greaterThan,
    lessDateThan,
    lessThan,
    mustBeNumber,
    notEmpty,
    notEmptyArrayValidator,
    notEmptyValidator,
} from "utils/validators/basic";
import { tValidation, validation } from "utils-ts/validations/translation";

const notEmptyField = (val) => notEmptyValidator(val).validate();
const arrayRequired = (value) => notEmptyArrayValidator(value).validate();

const notEmptyNumberField = (val) =>
    notEmptyValidator(val)
        .must(greaterThan(0), tValidation(validation.greaterThan, { number: 0 }))
        .when(notEmpty(val))
        .validate();

const discount = (val) =>
    new Validator(val)
        .must(mustBeNumber, tValidation(validation.mustBeNumber))
        .must(greaterThan(0), tValidation(validation.greaterThan, { number: 0 }))
        .must(lessThan(100), tValidation(validation.lessThan, { value: 100 }))
        .allWhen(notEmpty(val))
        .validate();

const validateDateTo = (value, form) => {
    const { dateFrom } = form;

    return notEmptyValidator(value)
        .must(
            greaterDateThan(dateFrom),
            tValidation(validation.greaterDateThan, {
                date: dateFrom,
            })
        )
        .when(notEmpty(dateFrom))
        .validate();
};

const validateDateFrom = (value, form) => {
    const { dateTo } = form;

    return notEmptyValidator(value)
        .must(lessDateThan(dateTo), tValidation(validation.lessDateThan, { date: dateTo }))
        .when(notEmpty(dateTo))
        .validate();
};

const validateCatmans = (value, form) => {
    const { catmansBudgets = [] } = form;
    const occurances = Array.isArray(catmansBudgets) ? catmansBudgets.filter((c) => c.catman == value).length : 0;

    return notEmptyValidator(value)
        .must(() => occurances <= 1, tValidation(validation.unique))
        .when(notEmpty(value))
        .validate();
};

export const validators = {
    notEmptyField,
    arrayRequired,
    notEmptyNumberField,
    validateDateFrom,
    validateDateTo,
    validateCatmans,
    discount,
};
