import types from "./types";

const initialState = {
    isAdding: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case types.addArchivedDocument.request:
            return { ...state, isAdding: true };
        case types.addArchivedDocument.success:
            return { ...state, ...payload, isAdding: false };
        case types.addArchivedDocument.failure:
            return { ...state, isAdding: false };
        default:
            return state;
    }
};
