import { Addable, FormField } from "components/Form";
import { Erasable } from "components/FormHelpers";
import { FormSection } from "redux-form";
import { Grid } from "@mui/material";
import { Layout } from "components";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { default as validation } from "./validation";

const requiredEmails = ["Protocol", "MissingPurchaseOrder", "Accounting", "MarketingInvoices", "EInvoice"];

const OperationEmails = ({ fields, readonly, isArchiveOnly }) => {
    const { t } = useTranslation("vendor");

    return (
        <Layout headerText={t(vendor.emails)}>
            <Addable
                renderCondition={!readonly}
                handleAdd={() => {
                    fields.push({});
                }}
            >
                {fields.map((field, index) => {
                    const name = fields.get(index).name;
                    const isRequired = requiredEmails.some((n) => n === name);

                    return (
                        <Erasable
                            key={field}
                            renderCondition={!isRequired && !readonly}
                            handleRemove={() => {
                                fields.remove(index);
                            }}
                        >
                            <FormSection name={field}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-end"
                                >
                                    {isRequired ? (
                                        <span style={{ width: 350 }}>{t(name)}</span>
                                    ) : (
                                        <FormField
                                            name="name"
                                            type="text"
                                            label={t(vendor.name)}
                                            validate={isArchiveOnly ? undefined : validation.required}
                                            readonly={readonly}
                                        />
                                    )}
                                    <FormField
                                        name="email"
                                        type="text"
                                        label={t(vendor.email)}
                                        validate={isArchiveOnly ? validation.email : validation.requiredEmail}
                                        readonly={readonly}
                                    />
                                </Grid>
                            </FormSection>
                        </Erasable>
                    );
                })}
            </Addable>
        </Layout>
    );
};

export default OperationEmails;
