import { FormField } from "components/Form";
import { campaingsSelector, getCampaigns } from "store/autocomplete";
import { useCommonTranslation } from "utils/hooks";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

const CampaingsAutocomplete = (props) => {
    const { t, common } = useCommonTranslation();
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(getCampaigns());
    }, [dispatch]);

    const items = useSelector(campaingsSelector);

    return (
        <FormField
            {...props}
            fullWidth
            name="campaignName"
            type={props.readOnly ? "text" : "autocomplete"}
            label={t(common.campaignName)}
            items={items}
        />
    );
};

export default CampaingsAutocomplete;
