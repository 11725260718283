import ArrayValidationError from "components/Controls/ArrayValidationError";
import { FormField } from "components/Form";
import { Adable, Erasable } from "components/FormHelpers";
import { FormSection } from "redux-form";
import divisions from "resource/divisions.json";
import { useCommonTranslation } from "utils/hooks";
import { notEmptyAndPositiveInteger, notEmptyValidator } from "utils/validators/basic";
import { Grid } from "@mui/material";

const required = (value) => notEmptyValidator(value).validate();
const requiredNumber = (value) => notEmptyAndPositiveInteger(value);

const PromotionDivisionUseLimits = ({ fields, meta, promotionDivisions = [] }) => {
    const { t, common } = useCommonTranslation();
    return (
        <>
            <ArrayValidationError {...meta} />
            <Adable
                handleAdd={() => {
                    fields.push();
                }}
            >
                {fields.map((field, index) => {
                    const fieldsValues = fields.getAll();
                    return (
                        <Erasable
                            key={index}
                            handleRemove={() => {
                                fields.remove(index);
                            }}
                        >
                            <FormSection name={field}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >
                                    <FormField
                                        name="division"
                                        type="select"
                                        label={t(common.division)}
                                        items={divisions.map((o) => {
                                            return {
                                                ...o,
                                                disabled:
                                                    o.disabled ||
                                                    fieldsValues.find((f) => f?.division == o.value) ||
                                                    (promotionDivisions.length > 0 && !promotionDivisions.find((pd) => o.value == pd)),
                                            };
                                        })}
                                        validate={required}
                                        hideDefaultItem
                                    />
                                    <FormField
                                        name={"activationLimit"}
                                        type={"number"}
                                        label={t(common.activationLimit)}
                                        validate={requiredNumber}
                                    />
                                </Grid>
                            </FormSection>
                        </Erasable>
                    );
                })}
            </Adable>
        </>
    );
};

export default PromotionDivisionUseLimits;
