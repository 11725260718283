import InfoDialog from "components/Dialog/InfoDialog";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import TextWithLabel from "components/Text/TextWithLabel";
import paymentMethods from "resource/paymentMethods.json";
import paymentStatuses from "resource/paymentStatuses.json";
import formatTypes from "utils/formating/formatTypes";
import timePrecisonFormats from "utils/formating/timePrecisonFormats";
import { Fragment } from "react";
import { payments } from "translations";
import { useTranslation } from "utils-ts/hooks";
import RefundForm from "./RefundForm";

const Refund = (props) => {
    const { open, onClose: handleClose, reloadTable } = props;
    const { payment } = props;
    const { t } = useTranslation("payments");
    const paymentFields = [
        { label: `payments:${payments.paymentId}`, value: payment.id },
        { label: `payments:${payments.orderId}`, value: payment.orderId },
        {
            label: `payments:${payments.paymentMethod}`,
            value: payment.paymentMethod,
            format: formatTypes.resource,
            options: { items: paymentMethods },
        },
        {
            label: `payments:${payments.channelName}`,
            value: payment.channelName,
            format: formatTypes.text,
        },
        {
            label: `payments:${payments.paymentAmount}`,
            value: payment.paymentAmount,
            format: formatTypes.currencyAmount,
        },
        {
            label: `payments:${payments.status}`,
            value: payment.status,
            format: formatTypes.resource,
            options: { items: paymentStatuses },
        },
        {
            label: `payments:${payments.refunded}`,
            value: payment.refunded,
            format: formatTypes.currencyAmount,
        },
        {
            label: `payments:${payments.createdAt}`,
            value: payment.createdAt,
            format: formatTypes.time,
            options: { timeFormat: timePrecisonFormats.seconds },
        },
    ];

    const columns = [
        {
            key: "refundId",
            value: t(payments.refundId),
            type: formatTypes.text,
        },
        {
            key: "refundAmount",
            value: t(payments.refundAmount),
            type: formatTypes.currencyAmount,
        },
        {
            key: "status",
            value: t(payments.status),
            type: formatTypes.resource,
            options: { items: paymentStatuses },
        },
    ];

    return (
        <InfoDialog
            open={open}
            onClose={handleClose}
            title={t(payments.payment)}
            maxWidth="sm"
        >
            <GridContainer>
                {paymentFields.map((item) => (
                    <GridItem
                        key={item.label}
                        xs={6}
                    >
                        <TextWithLabel {...item} />
                    </GridItem>
                ))}
            </GridContainer>
            {payment.refunds && payment.refunds.length ? (
                <SimpleTable
                    hover
                    stripped
                    columns={columns}
                    data={payment.refunds}
                />
            ) : (
                <Fragment />
            )}
            <RefundForm
                payment={payment}
                onClose={handleClose}
                reloadTable={reloadTable}
            />
        </InfoDialog>
    );
};

export default Refund;
