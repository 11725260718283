import { OrderReservation } from "Commerce-Core";
import React from "react";
import { Icon, IconButton, TableCell as MuiTableCell, Tooltip } from "@mui/material";
import { TableCellProps, TableItem } from ".";
import { BalanceAmount } from "Shared";
import { get } from "lodash";
import { usePush, useTranslation } from "utils-ts/hooks";
import { Checkbox } from "components-ts/controls";
import { BalanceAmountCell, BooleanStatusCell, DateCell, DeliveryWindowCell, TranslateCell } from "components-ts/tables/customCells";
import { ActionColumn, ActionType, ValueColumn, ValueType, ValueTypeKeys } from "./TableTypes";
import useTableStyles from "./tableCommonStyles";

export const nameToIcon = new Map<ActionType, string>([
    ["preview", "search"],
    ["edit", "edit"],
    ["delete", "delete"],
    ["choose", "send"],
    ["copy", "file_copy"],
]);

const isAction = <T extends TableItem>(column: ActionColumn<T> | ValueColumn<T>): column is ActionColumn<T> => {
    return (column as ActionColumn<T>).actionType !== undefined;
};

const isValueKey = (valueType: ValueType): valueType is ValueTypeKeys => {
    return (typeof valueType as ValueTypeKeys) === "string";
};

const renderAs = (valueType: ValueType, value: unknown): React.ReactNode => {
    const valueTypeKey =
        valueType === "BalanceAmount"
            ? "balanceAmount"
            : valueType === "OrderReservation"
            ? "orderReservation"
            : isValueKey(valueType)
            ? valueType
            : valueType.type;

    switch (valueTypeKey) {
        case "orderReservation":
            const reservation = value as OrderReservation;
            return (
                <DeliveryWindowCell
                    deliveryWindow={reservation.deliveryWindow}
                    isFlexibleSlots={reservation.isDeliveryAdjusted}
                />
            );
        case "balanceAmount":
            return <BalanceAmountCell value={value as BalanceAmount} />;
        case "boolean":
            return <BooleanStatusCell value={value} />;
        case "dateTime":
            return (
                <DateCell
                    value={value}
                    showTime
                />
            );
        case "date":
            return <DateCell value={value} />;
        case "translation":
            return <TranslateCell value={value} />;
        case "integer":
        case "decimal":
        case "string":
            return value as string;
    }
};

const TableCell = <T extends TableItem>({ column, item, rowHeight, rowIndex }: TableCellProps<T>): JSX.Element => {
    const { classes, cx } = useTableStyles();
    const { t } = useTranslation();
    const { push } = usePush();

    if (isAction(column)) {
        const link = typeof column.link === "function" ? column.link(item) : column.link;
        return (
            <MuiTableCell
                height={rowHeight}
                className={cx(classes.cell, column.actionType === "select" ? undefined : classes.iconCell)}
                {...column.cellProps}
            >
                {column.hide && column.hide(item) ? (
                    <></>
                ) : column.actionType === "select" ? (
                    <Checkbox
                        label=""
                        value={column.isItemSelected(item, rowIndex)}
                        onChange={() => column.onClick(item, rowIndex)}
                        readOnly={!column.canBeSelected(item)}
                    />
                ) : (
                    <Tooltip
                        title={t(column.actionType)}
                        arrow
                    >
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={() => {
                                if (!link) {
                                    if (column.onClick) {
                                        column.onClick(item, rowIndex);
                                    }
                                } else if (column.openInNewWindow) {
                                    window.open(link);
                                } else if (column.actionType === "copy") {
                                    push<{ id: string; isCopy: string }>(link, undefined, {
                                        id: column.getItemId(item),
                                        isCopy: "true",
                                    });
                                } else {
                                    push(link);
                                }
                            }}
                            sx={column.link ? { fontSize: "0.875rem", fontWeight: 500 } : undefined}
                        >
                            {column.asText ? column.asText(item) : <Icon className={classes.icon}>{nameToIcon.get(column.actionType)}</Icon>}
                        </IconButton>
                    </Tooltip>
                )}
            </MuiTableCell>
        );
    } else {
        const propertyPath: string | number | symbol = column.property;

        const itemValue = get(item, propertyPath);
        const cellTypeStyle = column.as === "date" ? classes.dateCell : column.as === "boolean" ? classes.booleanCell : undefined;

        return (
            <MuiTableCell
                height={rowHeight}
                className={cx(classes.cell, cellTypeStyle)}
                {...column.cellProps}
            >
                {column.as
                    ? typeof column.as === "function"
                        ? column.as(itemValue, item)
                        : renderAs(column.as, itemValue)
                    : (itemValue as React.ReactNode)}
            </MuiTableCell>
        );
    }
};

export default TableCell;
