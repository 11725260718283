import { getAppLocation } from "utils/utilsFunctions";
import { useEffect, useState } from "react";

const useAppLocation = () => {
    const [appLocation, setAppLocation] = useState();

    useEffect(() => {
        setAppLocation(getAppLocation());
    }, []);

    return appLocation;
};

export default useAppLocation;
