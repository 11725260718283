import defaultListState from "store/defaultListState";
import { types } from "./actions";

const initialState = defaultListState;

export const complaints = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case types.COMPLAINTS_GET:
            return { ...initialState, isLoading: true };
        case types.COMPLAINTS_GET_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case types.COMPLAINTS_GET_FAILURE:
            return { ...state, isLoading: false };

        default:
            return { ...state };
    }
};
