import _ from "lodash";

export const diffCustom = (current = {}, proposal = {}, comparers = {}) => {
    if (typeof current !== "object") {
        return {
            current,
            proposal,
            isSame: _.isEqual(current, proposal),
        };
    }

    const properties = { ...current, ...proposal };

    const diff = _(properties)
        .map((_, property) => {
            return {
                [property]: diffSwitch(current[property], proposal[property], comparers[property], comparers),
            };
        })
        .reduce((curr, next) => ({ ...curr, ...next }), {});

    return diff;
};

const pairs = (item, items = [], comparer) => {
    const p = items.find((p) => comparer(item, p));
    return { c: item, p };
};

const diffSwitch = (current, proposal, comparer = { findFunc: _.isEqual }, comparers) => {
    if (Array.isArray(current) || Array.isArray(proposal)) {
        const state = !current && proposal ? "added" : current && !proposal ? "removed" : "changed";
        let removed;
        let added;
        let changed;
        let noDiff;

        if (state === "removed" || state === "changed") {
            removed = current
                .map((c) => pairs(c, proposal, comparer.findFunc))
                .filter((c) => !Boolean(c.p))
                .map((pair) => diffCustom(pair.c, pair.c, comparers));
        }

        if (state === "added" || state === "changed") {
            added = proposal
                .map((p) => pairs(p, current, comparer.findFunc))
                .filter((c) => !Boolean(c.p))
                .map((pair) => diffCustom(pair.c, pair.c, comparers));
        }

        if (state === "changed") {
            const remaining = current
                .filter((c) => !removed.find((p) => comparer.findFunc(c, p)) && !added.find((p) => comparer.findFunc(c, p)))
                .map((c) => pairs(c, proposal, comparer.findFunc))
                .filter((c) => Boolean(c?.p && c?.c))
                .map((pair) => diffCustom(pair.c, pair.p, comparers))
                .map((par) => {
                    return par;
                })
                .reduce(
                    (curr, next) => {
                        if (
                            next?.isSame ||
                            _(next)
                                .map((x, property) => {
                                    if (comparer.equalityProperties && comparer.equalityProperties.every((p) => p !== property)) {
                                        return true;
                                    }

                                    return (
                                        x.isSame === true ||
                                        (Boolean(x.changed || x.added || x.removed) && _.isEmpty([...x.changed, ...x.added, ...x.removed]))
                                    );
                                })
                                .every((x) => Boolean(x))
                        ) {
                            return { ...curr, noDiff: [...curr.noDiff, next] };
                        } else {
                            return { ...curr, changed: [...curr.changed, next] };
                        }
                    },
                    {
                        changed: [],
                        noDiff: [],
                    }
                );

            changed = remaining.changed;
            noDiff = remaining.noDiff;
        }

        return {
            state,
            removed,
            added,
            changed,
            noDiff,
        };
    }

    return {
        current: current,
        proposal: proposal,
        isSame: comparer.findFunc(current, proposal),
    };
};
