import { isInteger } from "utils/numberExtensions";
import Validator, {
    greaterOrEqualsThan,
    greaterThan,
    mustBeNumber,
    notEmpty,
    notEmptyArrayValidator,
    notEmptyValidator,
} from "utils/validators/basic";
import { get, isEmpty } from "lodash";
import { tValidation, validation } from "utils-ts/validations/translation";

const productId = (value) => notEmptyValidator(value).validate();
const mappedProductId = (_, form, __, path) => {
    const effect = get(form, path.substring(0, path.lastIndexOf("."))) || {};
    return notEmptyValidator(effect)
        .must((e) => {
            return isEmpty(e) || isEmpty(e.mappedProductId) || (!isEmpty(e.mappedProductId) && e.mappedProductId !== e.productId);
        }, tValidation(validation.mappedProductIdMustBeDiffrent))
        .validate();
};

const price = (value) =>
    notEmptyValidator(value)
        .must(greaterOrEqualsThan(0.01), tValidation(validation.stringLength, { number: 0.01 }))
        .validate();

const quantity = (value) =>
    notEmptyValidator(value)
        .must(mustBeNumber, tValidation(validation.mustBeNumber))
        .must(isInteger, tValidation(validation.mustBeInteger))
        .must(greaterThan(0), tValidation(validation.greaterThan, { number: 0 }))
        .validate();

const effects = (value) => notEmptyArrayValidator(value).validate();

const greaterThan0 = (value) => {
    return new Validator(value)
        .must(greaterThan(0), tValidation(validation.greaterThan, { number: 0 }))
        .when(notEmpty(value))
        .validate();
};

export const validators = {
    productId,
    mappedProductId,
    price,
    quantity,
    effects,
    greaterThan0,
};
