import AcceptRejectDialog from "components/Dialog/AcceptRejectDialog";
import { renderFieldOrText } from "components/FormHelpers/Controls";
import { Field, reduxForm } from "redux-form";
import consentChannels from "resource/consentChannels.json";
import { actions } from "store/customers/customer/consents/action";
import { withStyles } from "tss-react/mui";
import { notEmptyValidator } from "utils/validators/basic";
import React from "react";
import { connect } from "react-redux";
import { Button, Grid } from "@mui/material";
import classnames from "classnames";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { common, customers } from "translations";
import { useTranslation } from "utils-ts/hooks";

const styles = (theme) => ({
    root: {
        marginTop: theme.spacing(6),
    },
    button: {
        marginTop: theme.spacing(3),
    },
});

const ConsentForm = ({ classes, valid, submitting, handleSubmit, onClose, putCustomerConsent, buttonSettings }) => {
    const [confirmModalOpen, setOpen] = React.useState(false);
    const [form, setForm] = React.useState({});
    const { t } = useTranslation(["customers", "common"]);

    const handleAccept = async () => {
        await putCustomerConsent(form);
        onClose();
        setOpen(false);
    };

    const handleReject = () => {
        setOpen(false);
    };

    const handleFormSubmit = (values) => {
        if (!valid) return;

        setForm(values);
        setOpen(true);
    };

    return (
        <div className={classes.container}>
            <form onSubmit={handleSubmit((val) => handleFormSubmit(val))}>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="stretch"
                >
                    <Field
                        type={"select"}
                        name={"consentChannel"}
                        component={renderFieldOrText}
                        label={t(customers.consentChannel)}
                        items={consentChannels}
                    />
                    <Button
                        type="submit"
                        disabled={submitting}
                        className={classnames(classes.button, buttonSettings.classes)}
                        variant="contained"
                        color="primary"
                    >
                        {buttonSettings.res}
                    </Button>
                </Grid>
                <AcceptRejectDialog
                    title={t(customers.consentChange)}
                    open={confirmModalOpen}
                    handleAccept={handleAccept}
                    handleReject={handleReject}
                    maxWidth="sm"
                >
                    {t(`common:${common.areYouSure}`, {
                        what: t(customers.consentChangeQuestion),
                    })}
                </AcceptRejectDialog>
            </form>
        </div>
    );
};

const validate = (values) => {
    const errors = {};
    const { customerId, isConsented, consentId, consentChannel } = values;
    errors.customerId = notEmptyValidator(customerId).validate();
    errors.isConsented = notEmptyValidator(isConsented).validate();
    errors.consentId = notEmptyValidator(consentId).validate();
    errors.consentChannel = notEmptyValidator(consentChannel).validate();

    return errors;
};

const mapStateToProps = (state, ownProps) => {
    const { customerId, isConsented, consentId } = ownProps;
    return {
        initialValues: {
            customerId,
            isConsented,
            consentId,
        },
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: "putConsentForm",
        enableReinitialize: true,
        validate,
    })(withStyles(ConsentForm, styles))
);
