export const SHOW_MESSAGE_BOX = "SHOW_MESSAGE_BOX";
export const HIDE_MESSAGE_BOX = "HIDE_MESSAGE_BOX";

export const showMessageBox = ({ type, props }) => ({
    type: SHOW_MESSAGE_BOX,
    payload: {
        type,
        modalProps: props,
    },
});

export const hideMessageBox = () => ({
    type: HIDE_MESSAGE_BOX,
});
