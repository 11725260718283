import { administration } from "store/actionTypePrefixes";
import { AsyncTypes } from "utils/extensions";

const types = {
    getUserDetails: new AsyncTypes("USER_GET_DETAILS", administration),
    updateUserEmail: new AsyncTypes("UPDATE_EMAIL_SUCCESS", administration),
    updateUserFullname: new AsyncTypes("USER_UPDATE_FULLNAME", administration),
    updateUserRoles: new AsyncTypes("UPDATE_ROLES_SUCCESS", administration),
    updateUserVendors: new AsyncTypes("UPDATE_USER_VENDORS", administration),
    updateUserActivision: new AsyncTypes("CHANGE_USER_ACTIVATION", administration),
    updateUserPassword: new AsyncTypes("UPDATE_PASSWORD", administration),
};

export default types;
