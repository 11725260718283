import type { ContentData as ContentDataType } from "Commerce-Core";
import contentDataTemplates from "resource/contentDataTemplates.json";
import React from "react";
import { useFormContext } from "react-hook-form";
import { Grid } from "@mui/material";
import { common } from "translations";
import { Translation } from "translations/Translation";
import { useTranslation } from "utils-ts/hooks";
import { Select } from "components-ts/controls";
import { FormRow, usePrefixContext } from "components-ts/forms";
import { Spacing } from "components-ts/view";
import FormList from "../FormList";
import FormTextField from "../FormTextField";

type ContentDataProp = {
    name?: string;
    readOnly?: boolean;
    shouldUseTemplates?: boolean;
} & (
    | {
          hideLabel?: never;
          label?: Translation;
          useCollapse?: boolean;
          isCollapsed?: boolean;
      }
    | {
          hideLabel?: boolean;
          label?: never;
          useCollapse?: never;
          isCollapsed?: never;
      }
);

const ContentData: React.FC<ContentDataProp> = ({
    name = "contentData",
    label = "contentData",
    useCollapse = true,
    isCollapsed = false,
    readOnly = false,
    hideLabel,
    shouldUseTemplates = false,
}) => {
    const { t } = useTranslation();
    const [template, setTemplate] = React.useState<string | number | undefined>("");
    const prefix = usePrefixContext();
    const { setValue } = useFormContext();
    const key = prefix ? `${prefix}.${name}` : name;

    const children = ({ item, index, key }: { item: ContentDataType; index: number; key: string }) => (
        <Grid
            item
            container
            direction="row"
            justifyContent="space-around"
            alignItems="flex-start"
        >
            <Grid
                item
                xs={2}
            >
                <FormTextField
                    name="key"
                    label={t(common.key)}
                    readOnly={readOnly}
                />
            </Grid>

            <Grid
                item
                xs={9}
            >
                <FormTextField
                    name="value"
                    label={t(common.value)}
                    readOnly={readOnly}
                    multiline
                    width="fullWidth"
                />
            </Grid>
        </Grid>
    );

    const templateMenu = (
        <FormRow>
            <Spacing spacing={2}>
                <Select
                    items={contentDataTemplates.map((o) => ({ value: o.name, name: o.name }))}
                    label={t("useTemplate")}
                    value={template?.toString()}
                    onChange={(value) => {
                        setTemplate(value);
                        setValue(
                            key,
                            contentDataTemplates
                                .find((o) => o.name == value)
                                ?.values?.map((o, index) => ({ item: { index, ...o } as ContentDataType, index, ...o }))
                        );
                    }}
                />
            </Spacing>
        </FormRow>
    );

    if (!hideLabel) {
        return (
            <>
                {shouldUseTemplates && templateMenu}
                <FormList<ContentDataType>
                    name={name}
                    defaultValue={{ key: "", value: "" }}
                    label={label}
                    useCollapse={useCollapse}
                    isCollapsed={isCollapsed}
                hideDivider
                >
                    {children}
                </FormList>
            </>
        );
    } else {
        return (
            <>
                {shouldUseTemplates && templateMenu}
                <FormList<ContentDataType>
                    name={name}
                    defaultValue={{}}
                    hideDivider
                >
                    {children}
                </FormList>
            </>
        );
    }
};

export default ContentData;
