import { manager, operations } from "store/actionTypePrefixes";
import { AsyncTypes, asAsyncFormTypes, createAction } from "utils/extensions";

const types = {
    formInit: createAction("FORM_INIT_CREDIT_LIMIT", manager),
    getCreditLimit: new AsyncTypes("GET_CREDIT_LIMIT", operations),
    createOrUpdate: new AsyncTypes("CREATE_OR_UPDATE_CREDIT_LIMIT", operations, [asAsyncFormTypes]),
    deleteCreditLimit: new AsyncTypes("DELETE_CREDIT_LIMIT", operations, [asAsyncFormTypes]),
    retryCreditLimitOperations: new AsyncTypes("RETRY_CREDIT_LIMIT_OPERATION", operations, [asAsyncFormTypes]),
};

export default types;
