import Button from "components/Button/Button";
import { FormSection, change } from "redux-form";
import { withFormName } from "utils/hoc";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { common } from "translations";
import PostcodeField from "./PostcodeField";

class RangeItem {
    type = "range";
    mask = null;
    range = {
        postcodeFrom: "",
        postcodeTo: "",
    };
}

class MaskItem {
    type = "mask";
    range = null;
    mask = { mask: "" };
}

const PostcodeFields = ({ fields, meta, postcodes, sectionPrefix, readOnly = false }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation("common");
    return (
        <Fragment>
            {fields.map((field, index) => {
                const postcode = postcodes[index];
                return (
                    <Fragment key={`field-${index}`}>
                        <FormSection name={field}>
                            <PostcodeField
                                name={field}
                                postcode={postcode}
                                handleRemove={() => {
                                    fields.remove(index);
                                }}
                                handleChangeType={(_, value) => {
                                    if (value !== postcode.type) {
                                        const item = value === "mask" ? new MaskItem() : new RangeItem();
                                        dispatch(
                                            change(meta.form, `${sectionPrefix}.${field}`, {
                                                range: null,
                                                mask: null,
                                                ...item,
                                            })
                                        );
                                    }
                                }}
                                readOnly={readOnly}
                            />
                        </FormSection>
                    </Fragment>
                );
            })}
            {!readOnly && (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        fields.push(new RangeItem());
                    }}
                >
                    {t(`common:${common.add}`)}
                </Button>
            )}
        </Fragment>
    );
};

export default withFormName(PostcodeFields);
