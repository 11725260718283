import { DocumentArchiveCategoryDocument } from "Operations";
import { api } from "api";
import { useColumns } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { QueryTable } from "components-ts/tables";
import { View } from "components-ts/view";

const DocumentCategoryList: React.FC = () => {
    const columns = useColumns<DocumentArchiveCategoryDocument>(() => [
        {
            property: "name",
            label: "name",
        },
        {
            property: "description",
            label: "description",
        },
        {
            property: "levelOfConfidentiality",
            label: "levelOfConfidentiality",
            as: "translation",
        },
        {
            property: "isActive",
            label: "isActive",
            as: "boolean",
        },
        {
            actionType: "edit",
            link: (item) =>
                Paths.GeneratePath(Paths.Dictionaries.DocumentCategoryForm, {
                    id: item.id,
                }),
        },
    ]);

    return (
        <View
            headerText={"documentCategory"}
            isMainView
        >
            <QueryTable
                queryHook={api.operations.documentArchiveCategories.useGetDocumentArchiveCategories}
                columns={columns}
                formPath={Paths.Dictionaries.DocumentCategoryForm}
            />
        </View>
    );
};

export default DocumentCategoryList;
