import { INIT_PRODUCT, INIT_PRODUCT_FAILURE, INIT_PRODUCT_SUCCESS } from "./actions";

const initialState = {
    initProductId: undefined,
    productIniting: false,
    productInitMessage: undefined,
};

export const initProduct = (state = initialState, action) => {
    state = state || initialState;
    switch (action.type) {
        case INIT_PRODUCT:
            return { ...state, productIniting: true, productInitMessage: undefined };
        case INIT_PRODUCT_SUCCESS:
            return { ...state, productIniting: false, initProductId: action.payload };
        case INIT_PRODUCT_FAILURE:
            return { ...state, productIniting: false, productInitMessage: action.payload };
        default:
            return state;
    }
};
