import React from "react";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import { DocumentArchiveAccessRoles, DocumentArchiveRoles } from "./DocumentArchiveRoles";

const AddDocument = React.lazy(() =>
    import("views/documentArchive").then((module) => ({
        default: module.AddDocument,
    }))
);
const Document = React.lazy(() =>
    import("views/documentArchive").then((module) => ({
        default: module.Document,
    }))
);
const DocumentArchiveSearch = React.lazy(() =>
    import("views/documentArchive").then((module) => ({
        default: module.DocumentArchiveSearch,
    }))
);
const UserPermissions = React.lazy(() =>
    import("views/documentArchive").then((module) => ({
        default: module.UserPermissions,
    }))
);
const UsersPermissionsList = React.lazy(() =>
    import("views/documentArchive").then((module) => ({
        default: module.UsersPermissionsList,
    }))
);

export const DocumentArchive: PrivateRoute[] = [
    new PrivateRoute("/document-archive", DocumentArchiveRoles, undefined, "documentArchive", undefined, [
        new PrivateRoute(Paths.DocumentArchive.List, DocumentArchiveRoles, DocumentArchiveSearch, "search", "search"),
        new PrivateRoute(Paths.DocumentArchive.New, DocumentArchiveRoles, AddDocument),
        new PrivateRoute(Paths.DocumentArchive.Form, DocumentArchiveRoles, Document),

        new PrivateRoute(Paths.DocumentArchive.PermissionsList, DocumentArchiveAccessRoles, UsersPermissionsList, "documentArchiveAccess", "lock"),
        new PrivateRoute(Paths.DocumentArchive.PermissionForm, DocumentArchiveAccessRoles, UserPermissions),
    ]),
];
