import Tabs from "components/Tabs/Tabs";
import { LogisticianDepartmentRoles } from "consts/roles";
import { useUser } from "context/UserContext/UserContext";
import { getAnalyticGroups, getBrandNames, getUserInfoWithRoles } from "store/autocomplete";
import { certyficateFormSelector, initForm } from "store/vendors/certificates";
import { useParams } from "utils/hooks";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";
import VendorLayout from "../VendorLayout";
import CertificateForm from "./CertificateForm";
import CertificateHistory from "./CertificateHistory";

const Certificate = ({ isLoading, isSubbmitRequested, certificateNumber }) => {
    const { vendorId, certificateId } = useParams();
    const { t } = useTranslation("vendor");
    const dispatch = useDispatch();
    const { isInAnyRole } = useUser();
    const labels = ["Formularz"];

    if (!isInAnyRole(LogisticianDepartmentRoles)) {
        labels.push("Historia pliku");
    }

    useEffect(() => {
        dispatch(getBrandNames());
        dispatch(getAnalyticGroups());
        dispatch(initForm(vendorId, certificateId));
        dispatch(getUserInfoWithRoles(["Administrator", "Catman", "SalesAssistant", "SalesManager"]));
    }, [dispatch, certificateId, vendorId]);
    return (
        <VendorLayout
            headerText={t(vendor.certificateForm, { id: certificateId })}
            vendorId={vendorId}
            isLoading={isLoading}
            tabIndex={10}
            customTitle={`${vendorId} ${t(vendor.certificateForm)} ${certificateNumber ? certificateNumber : ""}`}
        >
            <Tabs tableLabels={labels}>
                <CertificateForm isSubbmitRequested={isSubbmitRequested} />
                <CertificateHistory />
            </Tabs>
        </VendorLayout>
    );
};

const stateToProps = (state) => {
    const { isSubbmitRequested, isLoading, certificateNumber } = certyficateFormSelector(state);

    return {
        isLoading,
        isSubbmitRequested,
        certificateNumber,
    };
};

export default connect(stateToProps)(Certificate);
