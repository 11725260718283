import { combineReducers } from "redux";
import types from "./types";

const formInitialState = {
    isLoading: false,
    isSubbmitRequested: false,
    toolsOverLimitUsage: [],
    toolkitsOverLimitUsage: [],
    overlapingReservationKits: [],
};

const listInitialState = {
    items: [],
};

const overlapingReservationKitsInitialState = [];

const form = (state = formInitialState, { type, payload }) => {
    switch (type) {
        case types.formInit:
            return { ...formInitialState };
        case types.getReservationKit.request:
            return {
                ...formInitialState,
                isLoading: true,
            };
        case types.getReservationKit.success:
            return {
                ...payload,
                isLoading: false,
            };
        case types.getReservationKit.failure:
            return {
                isLoading: false,
            };
        case types.createReservationKit.request:
        case types.updateReservationKit.request:
        case types.sentToVendorReservationKit.request:
        case types.makeDecisionReservationKit.request:
        case types.updateReservationStatus.request:
            return {
                ...formInitialState,
                isSubbmitRequested: true,
            };
        case types.createReservationKit.success:
        case types.createReservationKit.failure:
        case types.updateReservationKit.success:
        case types.updateReservationKit.failure:
        case types.sentToVendorReservationKit.success:
        case types.sentToVendorReservationKit.failure:
        case types.makeDecisionReservationKit.success:
        case types.makeDecisionReservationKit.failure:
            return {
                ...payload,
                isSubbmitRequested: false,
            };
        case types.updateReservationStatus.success:
            return {
                ...payload,
                status: "InProgress",
                isSubbmitRequested: false,
            };
        case types.canCreateReservationKit.request:
        case types.canCreateReservationKit.failure:
            return {
                toolsOverLimitUsage: [],
                toolsDuplicates: [],
                ...state,
            };
        case types.canCreateReservationKit.success:
            // eslint-disable-next-line no-unused-vars
            const { toolsOverLimitUsage, toolsDuplicates, ...rest } = state;
            return {
                ...payload,
                ...rest,
            };
        case types.canCreateCyclicReservationKit.request:
        case types.canCreateCyclicReservationKit.failure:
            return {
                toolkitsOverLimitUsage: [],
                ...state,
            };
        case types.canCreateCyclicReservationKit.success:
            // eslint-disable-next-line no-unused-vars
            const { toolkitsOverLimitUsage, ...leftOver } = state;
            return {
                ...payload,
                ...leftOver,
            };
        case types.fillReservationWithTool:
            return {
                ...state,
                ...payload,
            };
        default:
            return { ...state };
    }
};

const overlapingReservationKits = (state = overlapingReservationKitsInitialState, { type, payload }) => {
    switch (type) {
        case types.overlappingReservationKitInit:
            return overlapingReservationKitsInitialState;
        case types.checkForOverlappingForm.success:
            return payload;
        default:
            return state;
    }
};

const list = (state = listInitialState, { type, payload }) => {
    switch (type) {
        case types.getReservationKits.request:
            return {
                ...listInitialState,
                isLoading: true,
            };
        case types.getReservationKits.success:
            return {
                items: payload,
                isLoading: false,
            };
        case types.updateReservationPosition.request:
            return {
                ...state,
                isUpdating: true,
            };
        case types.getReservationKits.failure:
        case types.updateReservationPosition.success:
        case types.updateReservationPosition.failure:
            return {
                ...state,
                isUpdating: false,
            };
        case types.updateLocalReservationPosition:
            return {
                ...state,
                items: payload,
            };
        case types.removeReservation.request:
            return {
                ...state,
                isUpdating: true,
            };
        case types.removeReservation.success:
            return {
                ...state,
                isUpdating: false,
            };
        default:
            return { ...state };
    }
};

export const reservationKits = combineReducers({
    form,
    list,
    overlapingReservationKits,
});
