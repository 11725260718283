import Layout from "components/Grid/Layout";
import TableWithToggleFilter from "components/Table/TableWithToggleFilter/TableWithToggleFilter";
import { actions } from "store/offers/rewards/list/actions";
import { rewardsListSelector } from "store/offers/selectors";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useCommonTranslation } from "utils/hooks";
import { usePush } from "utils-ts/hooks";

const RewardsList = () => {
    const { t, common } = useCommonTranslation();
    const { pushToForm: push } = usePush();
    const pushToForm = (id, isCopy) => push("/offers/rewards/form", id, isCopy);

    //prettier-ignore
    const columns = [
        { key: "name",        value: common.name,        type: formatTypes.text,                                                     },
        { key: "priority",    value: common.priority,    type: formatTypes.numeric,                                                  },
        { key: "validFrom",   value: common.validFrom,   type: formatTypes.time, options: {timeFormat: timePrecisonFormats.seconds}, },
        { key: "validTo",     value: common.validTo,     type: formatTypes.time, options: {timeFormat: timePrecisonFormats.seconds}, },
        { key: "isActive",    value: common.active,      type: formatTypes.boolean,                                                  },
        { key: "createdAt",   value: common.createdAt,   type: formatTypes.time, options: {timeFormat: timePrecisonFormats.seconds}, },
        { key: "updatedAt",   value: common.updatedAt,   type: formatTypes.time, options: {timeFormat: timePrecisonFormats.seconds}, },
        { key: "action",      value: "",                 type: formatTypes.action, actionType: "preview", action: item => pushToForm(item.id) },
        { key: "action",      value: "",                 type: formatTypes.action, actionType: "copy", action: item => pushToForm(item.id, true)},
    ].map(col => ({ ...col, value: t(col.value) }));

    return (
        <Layout
            main
            headerText={t(common.rewards)}
        >
            <TableWithToggleFilter
                columns={columns}
                addItemAction={() => pushToForm()}
                dataSelector={rewardsListSelector}
                dataSourceAction={actions.getRewards}
            />
        </Layout>
    );
};

export default RewardsList;
