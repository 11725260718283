const customerBalanceCommandNames = {
    PlaceOrder: "PlaceOrder",
    PlaceOrder_description: "PlaceOrder_description",
    ChangeOrder: "ChangeOrder",
    ChangeOrder_description: "ChangeOrder_description",
    TransferOrder: "TransferOrder",
    TransferOrder_description: "TransferOrder_description",
    UpdatePayment: "UpdatePayment",
    UpdatePayment_description: "UpdatePayment_description",
    CompletePayment: "CompletePayment",
    CompletePayment_description: "CompletePayment_description",
    RefundPayment: "RefundPayment",
    RefundPayment_description: "RefundPayment_description",
    ApproveBalancePayment_description: "ApproveBalancePayment_description",
    ConvertToCash: "ConvertToCash",
    ConvertToCash_description: "ConvertToCash_description",
    InvoiceOrder: "InvoiceOrder",
    InvoiceOrder_description: "InvoiceOrder_description",
    SetCreditLimit: "SetCreditLimit",
    SetCreditLimit_description: "SetCreditLimit_description",
    MigrateBasicData: "MigrateBasicData",
    MigrateBasicData_description: "MigrateBasicData_description",
    AbortOrder: "AbortOrder",
    AbortOrder_description: "AbortOrder_description",
    UpdateComplaint: "UpdateComplaint",
    UpdateComplaint_description: "UpdateComplaint_description",
    CreateComplaint: "CreateComplaint",
    CreateComplaint_description: "CreateComplaint_description",
    CancelOrder: "CancelOrder",
    CancelOrder_description: "CancelOrder_description",
    CreateProfileWithPassword: "CreateProfileWithPassword",
    CreateProfileWithPassword_description: "CreateProfileWithPassword_description",
    CompleteOnlinePayment: "CompleteOnlinePayment",
    CompleteOnlinePayment_description: "CompleteOnlinePayment_description",
    CancelComplaint: "CancelComplaint",
    CancelComplaint_description: "CancelComplaint_description",
    CreateBalancePayment: "CreateBalancePayment",
    CreateBalancePayment_description: "CreateBalancePayment_description",
    ApproveBalancePayment: "ApproveBalancePayment",
    ApproveBalancePaymentt_description: "ApproveBalancePaymentt_description",
    CreateDocument: "CreateDocument",
    CreateDocument_description: "CreateDocument_description",
    CorrectOrder: "CorrectOrder",
    CorrectOrder_description: "CorrectOrder_description",
    Correct: "Correct",
    ImportOnlinePayment: "ImportOnlinePayment",
    ImportOnlinePayment_description: "ImportOnlinePayment_description",
    DeliverOrder: "DeliverOrder",
    DeliverOrder_description: "DeliverOrder_description",
    ChargeDeliveryStatement: "ChargeDeliveryStatement",
    ChargeDeliveryStatement_description: "ChargeDeliveryStatement_description",
} as const;

export default customerBalanceCommandNames;
