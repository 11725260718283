import defaultListState from "store/defaultListState";
import { types } from "./actions";

const initialState = defaultListState;

const onGet = (state) => ({
    ...state,
    isLoading: true,
});

const onGetSuccess = (state, payload) => {
    return {
        ...state,
        ...payload,
        products: [...payload.items],
        isLoading: false,
    };
};

const onGetFail = (state) => ({
    ...state,
    isLoading: false,
});

const mapProduct = (product) => {
    return {
        ...product,
        productId: product.id,
        ean: product.ean,
        fullName: `${product.brand}  ${product.name.pl}`,
        merchant: product.merchant,
        vat: product.vat,
        price: product.isPromotionOnly ? null : product?.price?.price,
    };
};

const defaultAction = (state) => ({ ...state });

export const upsell = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case types.UPSELL_GET_SEARCH_PRODUCT:
            return onGet(state);
        case types.UPSELL_GET_SEARCH_PRODUCT_SUCCESS:
            return onGetSuccess(state, payload);
        case types.UPSELL_GET_SEARCH_PRODUCT_FAILURE:
            return onGetFail(state);
        case types.UPSELL_GET_SEARCH_PRODUCT_OFFER:
            return { ...state, isLoading: true };
        case types.UPSELL_GET_SEARCH_PRODUCT_OFFER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                products: [mapProduct(payload)],
            };
        case types.UPSELL_GET_SEARCH_PRODUCT_OFFER_FAILURE:
            return { ...state, isLoading: false };
        case types.CLEAR_SEARCH:
            return initialState;
        default:
            return defaultAction(state);
    }
};
