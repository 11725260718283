import { types } from "./types";

const initialState = {
    isLoading: false,
    isSubbmitRequested: false,
};

export const form = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.clearForm:
            return {
                ...initialState,
            };
        case types.getBrand.request:
            return { ...state, isLoading: true };
        case types.getBrand.success:
            return { ...state, isLoading: false, ...payload };
        case types.getBrand.failure:
            return { ...initialState };
        case types.createOrUpdate.request:
            return { isSubbmitRequested: true, ...state };
        case types.createOrUpdate.success:
            return { isSubbmitRequested: false, ...state };
        case types.createOrUpdate.failure:
            return { isSubbmitRequested: false, ...state };
        default:
            return state;
    }
};
