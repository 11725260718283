import { makeStyles } from "tss-react/mui";
import { useEffect, useRef } from "react";
import { List, ListItem, ListItemText } from "@mui/material";
import Chip from "@mui/material/Chip";

const useStyles = makeStyles()({
    root: {
        width: "100%",
        overflow: "auto",
        maxHeight: "330px",
        maxWidth: "580px",
        display: "flex",
        flexDirection: "row",
        overflowX: "auto",
    },
    chip: {
        marginRight: 4,
        marginLeft: 4,
    },
});

const DefinedRanges = ({ ranges, setRange }) => {
    const scrollContainerRef = useRef(null);
    const { classes } = useStyles();

    const handleWheel = (event) => {
        const container = scrollContainerRef.current;
        const delta = event.deltaY;

        const maxScrollLeft = container.scrollWidth - container.clientWidth;

        if ((delta < 0 && container.scrollLeft > 0) || (delta > 0 && container.scrollLeft < maxScrollLeft)) {
            container.scrollLeft += delta;
            event.preventDefault();
        }
    };

    useEffect(() => {
        const container = scrollContainerRef.current;
        container.addEventListener("wheel", handleWheel, { passive: false });

        return () => {
            container.removeEventListener("wheel", handleWheel);
        };
    }, []);

    return (
        <List
            className={classes.root}
            ref={scrollContainerRef}
        >
            {ranges.map((range, idx) => (
                <ListItem
                    disableGutters
                    button
                    key={idx}
                    onClick={() => setRange(range)}
                >
                    <ListItemText>
                        <Chip
                            className={classes.chip}
                            label={range.label}
                        />
                    </ListItemText>
                </ListItem>
            ))}
        </List>
    );
};

export default DefinedRanges;
