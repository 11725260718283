import { FormField } from "components/Form";
import { useCommonTranslation } from "utils/hooks";
import Validator, { mustBeNumber, notEmpty } from "utils/validators/basic";
import { Grid } from "@mui/material";
import { get } from "lodash";
import { tValidation, validation } from "utils-ts/validations/translation";

const validators = {
    validateMin: (value, form, _, path) => {
        const { maximumValue } = get(form, path.substring(0, path.lastIndexOf("."))) || {};

        return new Validator(value)
            .must(mustBeNumber, tValidation(validation.mustBeNumber))
            .must((value) => Number(value) >= 0, tValidation(validation.mustBePossitive))
            .allWhen(notEmpty(value))
            .must(
                (value) => {
                    return Number(value) < Number(maximumValue);
                },
                tValidation(validation.lessThan, { value: maximumValue })
            )
            .when(notEmpty(value) && notEmpty(maximumValue))
            .validate();
    },
    validateMax: (value, form, _, path) => {
        const { minimumValue } = get(form, path.substring(0, path.lastIndexOf("."))) || {};

        return new Validator(value)
            .must(mustBeNumber, tValidation(validation.mustBeNumber))
            .must((value) => Number(value) >= 0, tValidation(validation.mustBePossitive))
            .allWhen(notEmpty(value))
            .must(
                (value) => {
                    return Number(value) > Number(minimumValue);
                },
                tValidation(validation.greaterThan, { number: minimumValue })
            )
            .when(notEmpty(value) && notEmpty(minimumValue))
            .validate();
    },
};

const OrderValueFields = (props) => {
    const { t, common } = useCommonTranslation();

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >
            <FormField
                {...props}
                name={"minimumValue"}
                label={t(common.orderMinimumValue)}
                type={"number"}
                validate={validators.validateMin}
            />

            <FormField
                {...props}
                name={"maximumValue"}
                label={t(common.orderMaximumValue)}
                type={"number"}
                validate={validators.validateMax}
            />
        </Grid>
    );
};

export default OrderValueFields;
