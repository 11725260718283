import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import { formatTypes } from "utils/formating";
import { useCommonTranslation } from "utils/hooks";

const ProductProblems = ({ problems = [] }) => {
    const { t, common } = useCommonTranslation();

    const columns = [
        {
            key: "problem",
            value: common.problem,
            type: formatTypes.translatable,
            options: { translationKey: "commerceProductProblems" },
        },
    ];

    return (
        <SimpleTable
            stripped
            hover
            columns={columns.map((c) => ({ ...c, value: t(c.value) }))}
            data={problems.map((x) => ({ problem: x }))}
        />
    );
};

export default ProductProblems;
