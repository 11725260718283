import { Cell, Row, SimpleTable } from "components/Table/SimpleTable";
import Action from "components/Table/SimpleTable/TableActions/Action";
import tableActionTypes from "components/Table/SimpleTable/TableActions/tableActionTypes";
import { getProductPriceHistory } from "store/offers/productPreview/product/action";
import { productPriceHistorySelector } from "store/offers/selectors";
import { formatTypes, formatValue, timePrecisonFormats } from "utils/formating";
import { useAppLocation, useCommonTranslation } from "utils/hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";

const ProductPriceHistory = ({ warehouse }) => {
    const { t, common } = useCommonTranslation();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useSelector(productPriceHistorySelector);
    const appLocation = useAppLocation();

    useEffect(() => {
        dispatch(getProductPriceHistory(id, warehouse));
    }, [id, warehouse, dispatch]);

    const columns = [
        {
            key: "changedAt",
            value: t(common.changeTime),
            type: formatTypes.time,
            options: { timeFormat: timePrecisonFormats.days },
        },
        {
            key: "priceBeforeDiscount",
            value: t(common.priceBeforeDiscount),
            type: formatTypes.currency,
        },
        {
            key: "price",
            value: t(common.price),
            type: formatTypes.currency,
        },
        {
            key: "priceAfterPromotion",
            value: t(common.priceAfterPromotion),
            type: formatTypes.currency,
        },
        {
            key: "priceAfterVoucher",
            value: t(common.priceAfterVoucher),
            type: formatTypes.currency,
        },
        {
            key: "lowPrice",
            value: t(common.lowPrice),
            type: formatTypes.currency,
        },
        {
            key: "priceBeforeFirstDecrease",
            value: t(common.priceBeforeFirstDecrease),
            type: formatTypes.currency,
        },
    ];

    return (
        <SimpleTable
            stripped
            hover
            columns={columns}
        >
            {(history || []).map((row) => {
                return (
                    <Row
                        hover
                        key={row.changedAt}
                    >
                        {columns.map((col, index) => {
                            if (col.key === "priceAfterPromotion") {
                                if (row.promotionId) {
                                    return (
                                        <Action
                                            key={col.key}
                                            handleClick={() => `${appLocation}/offers/promotions/form/${row.promotionId}`}
                                            actionType={tableActionTypes.link}
                                            text={
                                                <>
                                                    <LocalAtmIcon />
                                                    {formatValue(row[col.key], col.type, col.options)}
                                                </>
                                            }
                                            format={col.type}
                                            buttonText={common.promotions}
                                            openInNewTab={true}
                                        />
                                    );
                                }
                            } else if (col.key === "priceAfterVoucher" && row.voucherId) {
                                return (
                                    <Action
                                        key={col.key}
                                        handleClick={() => row.voucherId.map((voucherId) => `${appLocation}/vouchers/vouchers/form/${voucherId}`)}
                                        actionType={tableActionTypes.link}
                                        text={
                                            <>
                                                <CardGiftcardIcon />
                                                {formatValue(row[col.key], col.type, col.options)}
                                            </>
                                        }
                                        format={col.type}
                                        buttonText={common.voucher}
                                        openInNewTab={true}
                                    />
                                );
                            } else if (col.key === "price" && row.discountId) {
                                return (
                                    <Action
                                        key={col.key}
                                        handleClick={() => `${appLocation}/offers/discounts/form/${row.discountId}`}
                                        actionType={tableActionTypes.link}
                                        text={
                                            <>
                                                <MoneyOffIcon />
                                                {formatValue(row[col.key], col.type, col.options)}
                                            </>
                                        }
                                        format={col.type}
                                        buttonText={common.discounts}
                                        openInNewTab={true}
                                    />
                                );
                            }

                            return (
                                <Cell
                                    key={index}
                                    value={formatValue(row[col.key], col.type, col.options)}
                                    format={col.type}
                                />
                            );
                        })}
                    </Row>
                );
            })}
        </SimpleTable>
    );
};

export default ProductPriceHistory;
