import { ContainerDocument, ContainerListRequest } from "Commerce-Content";
import { api } from "api";
import { useColumns, useFacets } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { QueryTable } from "components-ts/tables";
import { View } from "components-ts/view";

const CmsList: React.FC = () => {
    const columns = useColumns<ContainerDocument>(() => [
        {
            property: "containerName",
            label: "containerName",
        },
        {
            property: "categoryName",
            label: "categoryName",
        },
        {
            property: "comment",
            label: "comment",
        },
        {
            property: "validFrom",
            label: "start",
            as: "date",
        },
        {
            property: "validTo",
            label: "end",
            as: "date",
        },
        {
            property: "isActive",
            label: "isActive",
            as: "boolean",
        },
        {
            property: "createdAt",
            label: "createdAt",
            as: "date",
        },
        {
            property: "updatedAt",
            label: "updatedAt",
            as: "date",
        },
        {
            actionType: "preview",
            link: (item) => Paths.GeneratePath(Paths.Cms.CmsForm, { id: item.id }),
        },
        {
            actionType: "copy",
            link: Paths.Cms.CmsForm,
            getItemId: (item) => item.id,
        },
        {
            actionType: "delete",
        },
    ]);

    const facets = useFacets<ContainerListRequest>(() => [
        {
            property: "search",
            label: "",
            as: "searchBar",
        },
    ]);

    return (
        <View
            headerText="cms"
            isMainView
        >
            <QueryTable
                queryHook={api.commerce.cms.useGetCMSs}
                columns={columns}
                formPath={Paths.Cms.CmsForm}
                facets={facets}
                facetPosition="top"
                removeHook={api.commerce.cms.useDeleteCMS}
                getItemRemoveName={(item) => item.containerName}
            />
        </View>
    );
};

export default CmsList;
