import defaultListState from "store/defaultListState";
import { types } from "./actions";

const initialState = defaultListState;

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case types.GET_CONTENT_RULES_LIST:
            return { ...initialState, isLoading: true };
        case types.GET_CONTENT_RULES_LIST_SUCCESS:
            return { ...payload, isLoading: false };
        case types.GET_CONTENT_RULES_LIST_FAILURE:
            return { ...payload, isLoading: false };
        default:
            return state;
    }
};
