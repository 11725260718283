import React from "react";
import { useTranslation } from "react-i18next";

const TranslateCell: React.FC<{ value: string | unknown | undefined }> = ({ value }): JSX.Element => {
    const { t } = useTranslation();

    if (!value) {
        return <></>;
    }

    return <>{t((value || "").toString())}</>;
};

export default TranslateCell;
