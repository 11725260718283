import { DeliveryWindow } from "Commerce-Core";
import { timePrecisonFormats } from "utils/formating";
import React from "react";
import { Icon, Tooltip } from "@mui/material";
import { green } from "@mui/material/colors";
import moment from "moment";

const DeliveryWindowCell: React.FC<{
    deliveryWindow: DeliveryWindow;
    isFlexibleSlots?: boolean;
    onlyHour?: boolean;
    includeDeliveryMethod?: boolean;
}> = ({ deliveryWindow, isFlexibleSlots = false, onlyHour = false, includeDeliveryMethod = false }) => {
    if (deliveryWindow.deliveryMethod === "DPD") {
        return <>Kurier</>;
    }

    const flexibleSlot = isFlexibleSlots ? (
        <Tooltip
            title="Rozszerzony termin dostawy"
            aria-label="Rozszerzony termin dostawy"
            placement="right-start"
            style={{ display: "inline-block" }}
        >
            <Icon
                fontSize="small"
                style={{ color: green[500] }}
            >
                energy_savings_leaf
            </Icon>
        </Tooltip>
    ) : (
        <></>
    );

    if (onlyHour) {
        return (
            <>
                {includeDeliveryMethod && `${deliveryWindow.deliveryMethod}<br/>`}
                {moment(deliveryWindow.startsAt).format("HH-mm")}-{moment(deliveryWindow.endsAt).format("HH-mm")}
            </>
        );
    } else {
        return (
            <>
                {includeDeliveryMethod && `${deliveryWindow.deliveryMethod}<br/>`}
                <strong>{moment(deliveryWindow.startsAt).format(timePrecisonFormats.days)}</strong>
                <br />
                {moment(deliveryWindow.startsAt).format("HH-mm")}-{moment(deliveryWindow.endsAt).format("HH-mm")}
                {flexibleSlot}
            </>
        );
    }
};

export default DeliveryWindowCell;
