import buttons from "assets/jss/custom/buttons";
import Button from "components/Button/Button";
import { withStyles } from "tss-react/mui";
import { CircularProgress, Grid } from "@mui/material";
import { useUser } from "context";

const styles = (theme) => ({
    ...buttons(theme),
    wrapper: {
        margin: theme.spacing(1),
        position: "relative",
    },
    grid: {
        marginBottom: theme.spacing(1),
    },
    item: {
        paddingLeft: theme.spacing(1),
    },
    buttonProgress: {
        color: theme.palette.success.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
});

export const createAction = (text, action, actionType, loading, roles) => ({
    text,
    action,
    actionType,
    loading,
    roles,
});

const Actions = ({ classes, actions, pull }) => {
    const { isInAnyRoleOrAdmin } = useUser();
    let side;
    switch (pull) {
        case "left":
            side = "flex-start";
            break;
        case "right":
        default:
            side = "flex-end";
            break;
    }

    return (
        <Grid
            container
            className={classes.grid}
            direction="row"
            justifyContent={side}
            alignItems="center"
        >
            {actions.map(({ text, action, actionType, loading, roles }) => {
                if (roles && !isInAnyRoleOrAdmin(roles)) {
                    return <Grid key={text} />;
                }

                return (
                    <Grid
                        item
                        className={classes.item}
                        key={text}
                    >
                        <div className={classes.wrapper}>
                            <Button
                                actionType={actionType}
                                color="primary"
                                variant="contained"
                                disabled={loading}
                                loading={loading}
                                onClick={action}
                            >
                                {text}
                            </Button>
                            {loading && (
                                <CircularProgress
                                    size={28}
                                    className={classes.buttonProgress}
                                />
                            )}
                        </div>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default withStyles(Actions, styles);
