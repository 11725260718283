import formatingTypes from "./formatTypes";
import * as formatters from "./formaters";

const formattingMap = {
    [formatingTypes.text]: formatters.asString,
    [formatingTypes.float]: formatters.asFloat,
    [formatingTypes.currency]: formatters.asCurrency,
    [formatingTypes.currencyAmount]: formatters.asCurrencyAmount,
    [formatingTypes.translatable]: formatters.asTranslated,
    [formatingTypes.time]: formatters.asTime,
    [formatingTypes.boolean]: formatters.asBoolean,
    [formatingTypes.correction]: formatters.asCorrection,
    [formatingTypes.grammage]: formatters.asGrammage,
    [formatingTypes.fullName]: formatters.asFullName,
    [formatingTypes.shippingAddress]: formatters.asShippingAddress,
    [formatingTypes.deliveryWindow]: formatters.asDeliveryWindow,
    [formatingTypes.vatin]: formatters.asVatin,
    [formatingTypes.link]: formatters.asLink,
    [formatingTypes.percent]: formatters.asPercent,
    [formatingTypes.voucherKind]: formatters.asVoucherKind,
    [formatingTypes.func]: formatters.withFunc,
    [formatingTypes.rating]: formatters.asRating,
    [formatingTypes.points]: formatters.asPoints,
    [formatingTypes.quantity]: formatters.asQuantity,
    [formatingTypes.numeric]: formatters.asNumeric,
    [formatingTypes.arrayCount]: formatters.asArrayCount,
    [formatingTypes.voucherValue]: formatters.asVoucherValue,
    [formatingTypes.infoIcon]: formatters.asInfoIcon,
    [formatingTypes.userInfo]: formatters.asUserInfo,
    [formatingTypes.creditLimit]: formatters.asCreditLimit,
    [formatingTypes.resource]: formatters.asResource,
};

const formatValue = (value, type, options) => {
    if ((!value || !type) && typeof value !== "boolean") if (typeof value !== "number") return value;

    if (value === undefined || value === null) return value;

    const formatter = formattingMap[type];
    if (!formatter) throw new Error("Unsupported mapping");

    return formatter(value, options);
};

export default formatValue;
