import { CampaingsAutocomplete, ContentData, DivisionSelect } from "components/Controls";
import { CommerceForm, FormField } from "components/Form";
import { integerNormalize } from "components/FormHelpers/ControlFormaters";
import Layout from "components/Grid/Layout";
import { reduxForm } from "redux-form";
import { usePromotionFormAutocompleteEffect } from "store/autocomplete";
import { getGiftChoiceValidation, initGiftForm, saveGiftChoice } from "store/offers/giftChoices/form/actions";
import { giftChoicesFormSelector } from "store/offers/selectors";
import { toObject } from "utils/extensions";
import { mapRequirements } from "utils/formExtensions";
import { useCommonTranslation, useCopyRouterState, useFormValueSelector, useParams } from "utils/hooks";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { usePush } from "utils-ts/hooks";
import { validators } from "../validators";
import { GiftChoiceActivation, GiftChoiceDefinition, GiftChoiceValidation } from "./components";

const formName = "gift-choice-form";

const GiftChoiceForm = ({ handleSubmit, isSubbmitRequested, activationInfo, validation, isSystem, name, initialValues }) => {
    usePromotionFormAutocompleteEffect();
    const { giftId } = useParams();
    const { push } = usePush();
    const { id, isCopy } = useCopyRouterState();
    const dispatch = useDispatch();
    const divisions = useFormValueSelector(formName, "divisions", []);

    useEffect(() => {
        dispatch(initGiftForm(giftId, id, isCopy));

        if (id && !isCopy) {
            dispatch(getGiftChoiceValidation(giftId, id));
        }
    }, [dispatch, giftId, id, isCopy]);
    const { t, common } = useCommonTranslation();

    const submit = async (values) => {
        const { payload, error } = await dispatch(saveGiftChoice(values, giftId, !isCopy && id));

        if (!id) {
            if (!error) {
                const [gift, choiceId] = payload.id.split("/");
                push(`/offers/gifts/${gift}/gift-choices/form/${choiceId}`);
            }
        }
    };

    const readOnly = isSystem && !isCopy;

    return (
        <CommerceForm
            onSubmit={handleSubmit((values) => {
                if (values.definition.requirements?.user?.segments && values.definition.requirements?.user?.segments?.length === 0) {
                    values.definition.requirements.user.segments = undefined;
                }
                const mapped = {
                    ...values,
                    definition: {
                        ...values.definition,
                        requirements: mapRequirements(values.definition.requirements, true),
                    },
                    contentData: values?.contentData ? toObject(values.contentData.filter((c) => Boolean(c.value))) : undefined,
                };
                submit(mapped);
            })}
            isReadonly={readOnly}
            isSubbmitRequested={isSubbmitRequested}
        >
            <Layout
                main
                headerText={t(common.giftChoices, {
                    id: !isCopy && id ? id : "",
                })}
                navigationProps={{
                    backProps: {
                        path: `/offers/gifts/${giftId}/gift-choices`,
                    },
                }}
                customTitle={id && name && `${t(common.giftChoices)} - ${name}`}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-end"
                >
                    <FormField
                        name={"name"}
                        type={"text"}
                        label={t(common.name)}
                        validate={validators.name}
                        readOnly={readOnly}
                    />
                    <FormField
                        name={"isActive"}
                        type={"boolean"}
                        label={t(common.isActive)}
                        readOnly={readOnly}
                    />
                    <FormField
                        name={"isFallback"}
                        type={"boolean"}
                        label={t(common.isFallback)}
                        readOnly={readOnly}
                    />
                    <FormField
                        name={"validFrom"}
                        type={"dateTime"}
                        label={t(common.validFrom)}
                        validate={validators.validFrom}
                        readOnly={readOnly}
                    />
                    <FormField
                        name={"validTo"}
                        type={"dateTime"}
                        label={t(common.validTo)}
                        validate={validators.validTo}
                        readOnly={readOnly}
                    />
                    <FormField
                        name={"priority"}
                        formatDisplay={integerNormalize}
                        type={"number"}
                        label={t(common.priority)}
                        validate={validators.priority}
                        readOnly={readOnly}
                    />
                    <FormField
                        name={"reservationLimit"}
                        formatDisplay={integerNormalize}
                        type={"number"}
                        label={t(common.reservationLimit)}
                        validate={validators.greaterThan0WhenNotEmpty}
                        readOnly={readOnly}
                    />
                    <FormField
                        name={"reservationMonthlyLimit"}
                        formatDisplay={integerNormalize}
                        type={"number"}
                        label={t(common.reservationMonthlyLimit)}
                        validate={validators.greaterThan0WhenNotEmpty}
                        readOnly={readOnly}
                    />
                    <CampaingsAutocomplete
                        validate={validators.campaign}
                        readOnly={readOnly}
                    />
                    <DivisionSelect
                        readOnly={readOnly}
                        selectedDivisions={divisions}
                    />
                    <FormField
                        name={"activeChoiceGroup"}
                        formatDisplay={integerNormalize}
                        type={"text"}
                        label={t(common.activeChoiceGroup)}
                        tooltipLabel={t(common.activeChoiceGroupTooltip)}
                        validate={validators.activeChoiceGroup}
                        readOnly={readOnly}
                    />
                </Grid>
            </Layout>
            <Layout headerText={t(common.reservationCount)}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <GiftChoiceActivation activationInfo={activationInfo} />
                </Grid>
            </Layout>
            <GiftChoiceValidation validation={validation} />
            <Layout headerText={t(common.awardRequirements)}>
                <GiftChoiceDefinition readOnly={readOnly} />
            </Layout>
            <ContentData readOnly={readOnly} />
        </CommerceForm>
    );
};

const stateToProps = (state) => {
    const { isSubbmitRequested, activationInfo, validation, isSystem, ...form } = giftChoicesFormSelector(state);

    return {
        activationInfo,
        validation,
        isSubbmitRequested,
        isSystem,
        name: form.name,
        initialValues: {
            ...form,
        },
    };
};

export default connect(stateToProps)(
    reduxForm({
        form: formName,
        enableReinitialize: true,
    })(GiftChoiceForm)
);
