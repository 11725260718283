import { FormField } from "components/Form";
import { getFormSubmitErrors } from "redux-form";
import { makeStyles } from "tss-react/mui";
import { withFormName } from "utils/hoc";
import { useFormValueSelector } from "utils/hooks";
import { notEmptyValidator } from "utils/validators";
import { useSelector } from "react-redux";
import { Alert, AlertTitle } from "@mui/lab";
import { vendor } from "translations";
import useTranslation from "utils-ts/hooks/useTranslation";

const useStyles = makeStyles()((theme) => ({
    alert: {
        padding: theme.spacing(1),
    },
}));

const notEmpty = (val) => notEmptyValidator(val).validate();

const ChangeReason = ({ isDecisionRequired, form, forceRender }) => {
    const changeReason = useFormValueSelector(form, "changeReason");
    const asyncErrors = useSelector(getFormSubmitErrors(form));
    const { t } = useTranslation("vendor");
    const { classes } = useStyles();

    return isDecisionRequired ? (
        <div className={classes.alert}>
            <Alert severity="warning">
                <AlertTitle>{t(vendor.changeReason)}</AlertTitle>
                {changeReason.proposal}
            </Alert>
        </div>
    ) : forceRender || asyncErrors?.newDecisionStatus === "WaitingForDecision" ? (
        <>
            <Alert severity="warning">
                <AlertTitle>{t(vendor.attention)}</AlertTitle>
                {t(vendor.changesWillRequireDecision)}
            </Alert>
            <FormField
                stretch
                name={"changeReason"}
                label={t(vendor.changeReason)}
                type={"text"}
                validate={notEmpty}
            />
        </>
    ) : (
        <></>
    );
};

export default withFormName(ChangeReason);
