import { PriceModifier } from "Commerce-Shared";
import { ExtendedValidator } from "../extendedValidator";

export class PriceModifierValidator extends ExtendedValidator<PriceModifier> {
    constructor() {
        super();

        this.ruleFor("discountPercent")
            .exclusiveBetween(0, 100)
            .whenNotEmpty();

        this.ruleFor("discountValue")
            .greaterThanOrEqualTo(0.01)
            .whenNotEmpty();

        this.ruleFor("newPrice")
            .greaterThanOrEqualTo(0.01)
            .whenNotEmpty();

        this.atLeastOneOfFieldNotEmpty([
            "discountPercent",
            "discountValue",
            "newPrice",
        ]);
    }
}
