export const polishFiles = [
    {
        text: "Zweryfikować i zarchiwizować wpis z Krajowego Rejestru Sądowego (KRS) (spółkiprawa handlowego)",
    },
    {
        text: "Zweryfikować i zarchiwizować wpis do Centralnej Ewidencji i Informacji o Działalności Gospodarczej",
    },
    {
        text: "Zweryfikować i zarchiwizować potwierdzenie, że dostawca jest zarejestrowany jako czynny podatnik VAT",
    },
    {
        text: " Zweryfikować i zarchiwizować potwierdzenie, że dostawca jest wpisany do prowadzonego przez Szefa Krajowej Administracji Skarbowej wykazu podmiotów zarejestrowanych jako podatnicy VAT",
    },
    {
        text: "Zweryfikować i zarchiwizować potwierdzenie, że dostawca posiada wymagane prawem koncesje lub zezwolenia. - w przypadku wytwarzania wyrobów tytoniowych - w przypadku shurtowej sprzedaży wyborów tytoniowych",
    },
    {
        text: "Zweryfikować i zarchiwizować potwierdzenie, że osoby zawierające umowę, dokonujące transakcji posiadają umocowania do działania w imieniu dostawcy (Umocowanie powinno wynikać z KRS, CEIGD lub pełnomocnictwa do działania w imieniu kontrahenta, podpisanego zgodnie z zasadami reprezentacji wynikającymi z tych rejestrów)",
    },
    {
        text: "Oswiadczenie o rachunku bankowym(plik)",
    },
];

export const foreginFiles = [
    {
        text: "Weryfikacja danych formalnych dostawcy w państwach członkowskich Unii Europejskiej i w Wielkiej Brytanii należy skorzystać z wyszukiwarki: https://e-justice.europa.eu/content_find_a_company-489-pl.do https://e-justice.europa.eu/content_business_registers_in_member_states-106-en. W przypadku braku spółki w rejestrze, o których nowa powyżej, należy przeprowadzić weryfikację spółki w Rejestrze spółek w upadłości https://e-justice.europa.eu/content_insolvency_registers-110-en.do",
    },
    {
        text: "Weryfikacja numerów VAT (VIES) https://ec.europa.eu/taxation_customs/vies/",
    },
    {
        text: "Weryfikacja poprzez system rejestracji i identyfikacji i podmiotów gospodarczych na potrzeby celne EORI https://ec.europa.eu/taxation_customs/dds2/eos/eori_validation.jsp?Lang=en",
    },
    {
        text: "Ustalenie, czy siedziba firmy nie znajduje się na liście krajów zaliczonych do tzw. Rajów podatkowych zgodnie z: http://isap.sejm.gov.pl/isap.nsf/download.xsp/WDU20190000599/O/D20190599.pdf https://isap.sejm.gov.pl/isap.nsf/download.xsp/WDU20190000600/O/D20190600.pdf",
    },
    {
        text: "Weryfikacja czy dostawca nie znajduje się na liście sankcyjnej Komisji Nadzoru Finansowego https://www.knf.gov.pl/dla_konsumenta/ostrzezenia_publiczne?warningname=&warningtypel",
    },
    {
        text: "Oswiadczenie o rachunku bankowym(plik)",
    },
];
