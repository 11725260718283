import { RSAA } from "redux-api-middleware";
import { commerce } from "store/actionTypePrefixes";
import { merchants } from "utils/dictionaryTypes/index";

const REFUND_POST = `${commerce}/REFUND_POST,`;
const REFUND_POST_SUCCESS = `${commerce}/REFUND_POST_SUCCESS,`;
const REFUND_POST_FAILURE = `${commerce}/REFUND_POST_FAILURE,`;

export const types = {
    REFUND_POST,
    REFUND_POST_SUCCESS,
    REFUND_POST_FAILURE,
};

const postRefund = (payload) => async (dispatch) => {
    const { id, frs, f24, notification } = payload;
    const body = {
        refundAmount: {
            [merchants.Frisco]: -frs || 0,
            [merchants.F24]: -f24 || 0,
        },
        notification: notification && notification.isNotifying ? notification : null,
    };
    const [userId, number] = id.split("/");
    const result = await dispatch({
        [RSAA]: {
            endpoint: `api/v1/users/${userId}/payments/${number}/refunds`,
            headers: { "Content-Type": "application/json" },
            method: "POST",
            types: [REFUND_POST, REFUND_POST_SUCCESS, REFUND_POST_FAILURE],
            body: JSON.stringify(body),
        },
    });

    return Promise.resolve(result);
};

export const actions = {
    postRefund,
};
