import GridContainer from "components/Grid/GridContainer";
import Actions, { createAction } from "components/Tabs/Actions";
import Tabs from "components/Tabs/Tabs";
import { B2BDepartmentRoles, ECommerceDepartmentRoles, SupportDepartmentRoles } from "consts/roles";
import { actions } from "store/customers/customer/consents/action";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { customers } from "translations";
import { useTranslation } from "utils-ts/hooks";
import Consent from "./Consent";
import ConsentsHistory from "./ConsentsHistory";

const exportRoles = [...B2BDepartmentRoles, ...ECommerceDepartmentRoles, ...SupportDepartmentRoles];

const Consents = ({
    customerId,
    consents: { isFileDownloading, consents: consentsData, history },
    profileType,
    getCustomerConsents,
    exportCustomerProfile,
}) => {
    const { t } = useTranslation("customers");
    React.useEffect(() => {
        getCustomerConsents(customerId);
    }, [customerId, getCustomerConsents]);
    const consents = consentsData.map((x) => {
        const { consentId } = x;
        const { createdAt } =
            _.chain(history)
                .orderBy("createdAt", "desc")
                .find((y) => y.consent.consentId === consentId)
                .value() || {};
        return { ...x, createdAt };
    });
    const isEmailConsented = consents.find((c) => c.consent.consentType === "Email")?.isConsented;
    const tabLabels = ["consents", "history"];

    const actions = [
        createAction(t(customers.export), () => exportCustomerProfile(customerId, true), "primary", isFileDownloading, exportRoles),
        createAction(t(customers.exportWithConsents), () => exportCustomerProfile(customerId, false), "primary", isFileDownloading, exportRoles),
    ];

    return (
        <Tabs tableLabels={tabLabels}>
            <Fragment>
                <Actions actions={actions} />
                <GridContainer
                    iconName="check"
                    title={customers.consents}
                >
                    {consents.map((x) => (
                        <Consent
                            key={x.consentId}
                            customerId={customerId}
                            profileType={profileType}
                            isEmailConsented={isEmailConsented}
                            {...x}
                        />
                    ))}
                </GridContainer>
            </Fragment>
            <ConsentsHistory history={history} />
        </Tabs>
    );
};

const mapStateToProps = (state) => {
    const { customer } = state;
    return { consents: customer.consents, profileType: customer.customerInfo.profileType };
};

const mapDispatchToActions = (dispatch) => ({
    getCustomerConsents: (customerId) => dispatch(actions.getCustomerConsents(customerId)),
    exportCustomerProfile: (customerId, includeConsents) => dispatch(actions.exportCustomerProfile(customerId, includeConsents)),
});

export default connect(mapStateToProps, mapDispatchToActions)(Consents);
