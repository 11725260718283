import defaultListState from "store/defaultListState";
import { combineReducers } from "redux";
import { types } from "./types";

const initialState = {
    list: defaultListState,
    scan: {},
};

const list = (state = initialState.list, { type, payload }) => {
    switch (type) {
        case types.list.request:
            return { ...state, isLoading: true };
        case types.list.success:
            return { ...payload, isLoading: false };
        case types.list.failure:
            return { ...payload, isLoading: false };
        default:
            return state;
    }
};

const scan = (state = initialState.scan, { type, payload }) => {
    switch (type) {
        case types.scan.request:
            return { ...state, isLoading: true };
        case types.scan.success:
            return { ...payload, isLoading: false };
        case types.scan.failure:
            return { ...payload, isLoading: false };
        default:
            return state;
    }
};

export default combineReducers({
    list,
    scan,
});
