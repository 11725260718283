import TextWithLabel from "components/Text/TextWithLabel";
import { formatTypes } from "utils/formating";
import { useAppLocation, useCommonTranslation } from "utils/hooks";
import { common } from "translations";
import ProductField from "views/offers/productPreview/components/ProductField";

const ProductPrice = ({ price = {}, vat, hidePromotionLink }) => {
    const appLocation = useAppLocation();
    const {
        priceBeforeDiscount,
        price: priceAfterDiscount,
        discountPercent,
        campaignName,
        discountId,
        lowPrice,
        priceBeforeReplacement,
        replacementDiscountId,
    } = price;

    let info = [
        new ProductField(common.price, priceAfterDiscount, formatTypes.currency),
        new ProductField(common.priceBeforeDiscount, priceBeforeDiscount || priceAfterDiscount, formatTypes.currency),
        new ProductField(common.priceBeforeReplacement, priceBeforeReplacement, formatTypes.currency),
        new ProductField(common.lowPrice, lowPrice, formatTypes.currency),
        new ProductField(common.discountPercent, discountPercent || 0, formatTypes.percent),
        new ProductField(common.discountCampaignName, campaignName, formatTypes.text),
        new ProductField(common.vat, vat, formatTypes.percent),
    ];

    if (!hidePromotionLink) {
        info.push(
            new ProductField(common.discountLink, discountId ? `${appLocation}/offers/discounts/form/${discountId}` : discountId, formatTypes.link, {
                url: `${appLocation}/offers/discounts/form/${discountId}`,
                target: "_blank",
                addCopyIcon: true,
            })
        );
        info.push(
            new ProductField(
                common.replacementDiscountLink,
                replacementDiscountId ? `${appLocation}/offers/discounts/form/${replacementDiscountId}` : replacementDiscountId,
                formatTypes.link,
                {
                    url: `${appLocation}/offers/discounts/form/${replacementDiscountId}`,
                    target: "_blank",
                    addCopyIcon: true,
                }
            )
        );
    }

    return info.map((f) => (
        <TextWithLabel
            unselectableLabel
            key={f.label}
            {...f}
        />
    ));
};

export default ProductPrice;
