import roles, {
    AccountantDepartmentRoles,
    AuditorAndMasterDataRoles,
    ECommerceDepartmentRoles,
    LogisticianDepartmentRoles,
    SalesDepartmentRoles,
    TradeMarketingDepartmentRoles,
} from "../../consts/roles";
import { useUser } from "../../context/UserContext/UserContext";

export const IsVendorReadOnly = (formName) => {
    const { isInRole, isInAnyRole, isAdmin } = useUser();

    if (formName === "vendor-contacts-form") {
        return (
            !isAdmin &&
            !isInRole(roles.Management) &&
            !isInAnyRole(SalesDepartmentRoles) &&
            !isInAnyRole(TradeMarketingDepartmentRoles) &&
            (isInAnyRole(AccountantDepartmentRoles) ||
                isInAnyRole(AuditorAndMasterDataRoles) ||
                isInRole(roles.Analyst) ||
                isInRole(roles.AXUser) ||
                isInAnyRole(LogisticianDepartmentRoles) ||
                isInAnyRole(ECommerceDepartmentRoles))
        );
    } else if (formName === "subsidiary-gain") {
        return (
            !isAdmin &&
            !isInRole(roles.Management) &&
            !isInAnyRole(SalesDepartmentRoles) &&
            !isInRole(roles.TradeMarketingManager) &&
            (isInAnyRole(AccountantDepartmentRoles) ||
                isInRole(roles.TradeMarketing) ||
                isInAnyRole(AuditorAndMasterDataRoles) ||
                isInRole(roles.Analyst) ||
                isInRole(roles.AXUser) ||
                isInAnyRole(LogisticianDepartmentRoles) ||
                isInAnyRole(ECommerceDepartmentRoles))
        );
    } else if (formName === "MarketingBudgetForm" || formName === "MarketingBudgetList") {
        return !isAdmin && !isInAnyRole(TradeMarketingDepartmentRoles);
    }

    return (
        !isAdmin &&
        !isInRole(roles.Management) &&
        !isInAnyRole(SalesDepartmentRoles) &&
        (isInAnyRole(AccountantDepartmentRoles) ||
            isInAnyRole(TradeMarketingDepartmentRoles) ||
            isInAnyRole(AuditorAndMasterDataRoles) ||
            isInRole(roles.Analyst) ||
            isInRole(roles.AXUser) ||
            isInAnyRole(LogisticianDepartmentRoles) ||
            isInAnyRole(ECommerceDepartmentRoles))
    );
};
