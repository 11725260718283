import TextWithLabel from "components/Text/TextWithLabel";
import { formatTypes } from "utils/formating";
import { common } from "translations";
import ProductField from "views/offers/productPreview/components/ProductField";

const ProductStock = ({
    isAvailable = false,
    isWithdrawn = false,
    isStocked = false,
    stock,
    reservedStock,
    actualStock,
    isMultipackOnly = false,
    isPromotionOnly = false,
}) => {
    const info = [
        new ProductField(common.isAvailable, isAvailable, formatTypes.boolean),
        new ProductField(common.isWithdrawn, isWithdrawn, formatTypes.boolean),
        new ProductField(common.isStocked, isStocked, formatTypes.boolean),
        new ProductField(common.stock, stock, formatTypes.text),
        new ProductField(common.reservedStock, reservedStock, formatTypes.text),
        new ProductField(common.actualStock, actualStock, formatTypes.text),
        new ProductField(common.isMultipackOnly, isMultipackOnly, formatTypes.boolean),
        new ProductField(common.isPromotionOnly, isPromotionOnly, formatTypes.boolean),
    ];

    return info.map((f) => (
        <TextWithLabel
            unselectableLabel
            key={f.label}
            {...f}
        />
    ));
};

export default ProductStock;
