import { makeStyles } from "tss-react/mui";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Theme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import { common } from "translations";
import { useTranslation } from "utils-ts/hooks";

const useStyles = makeStyles()((theme: Theme) => ({
    input: {
        marginLeft: 8,
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    container: {
        width: "95%",
        display: "flex",
        padding: "2px 8px",
        alignItems: "center",
    },
}));

type SearchBarProp = {
    initialValue: string;
    onSearchCommit: (newValue: string) => void;
};

const SearchBar = ({ initialValue, onSearchCommit }: SearchBarProp) => {
    const { classes } = useStyles();
    const { t } = useTranslation();
    const [search, setSearch] = useState(initialValue);

    return (
        <Paper className={classes.container}>
            <InputBase
                fullWidth
                autoFocus
                className={classes.input}
                placeholder={t(common.search)}
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                onKeyUp={(event) => {
                    if (event.key === "Enter" || event.keyCode === 13 || event.which === 13) {
                        onSearchCommit(search);
                    }
                }}
            />

            <IconButton
                className={classes.iconButton}
                onClick={() => onSearchCommit(search)}
            >
                <SearchIcon />
            </IconButton>
        </Paper>
    );
};

export default SearchBar;
