import types from "./types";

const initialState = {
    creditLimit: { limit: "" },
    creditLimitFile: { archiveFile: {} },
    security: { hasSecurity: false },
    securityFile: { archiveFile: {} },
};
const { createOrUpdate, formInit, getCreditLimit, retryCreditLimitOperations } = types;

export const creditLimit = (state = initialState, { type, payload }) => {
    switch (type) {
        case formInit:
            return { ...initialState };
        case getCreditLimit.request:
            return { ...initialState, isLoading: true };
        case getCreditLimit.success:
            const {
                creditLimitFile: { categoryId, documentTypeId, ...creditLimitFileRest } = {},
                hasSecurity = false,
                security: {
                    securityFile: { categoryId: securityCategoryId, documentTypeId: securityDocumentTypeId, ...securityFileRest },
                    ...securityRest
                } = { securityFile: {} },
                ...creditLimit
            } = payload;

            return {
                ...state,
                creditLimit,
                creditLimitFile: {
                    ...creditLimitFileRest,
                    documentType: { id: documentTypeId },
                    documentCategory: { id: categoryId },
                    archiveFile: {},
                },
                security: { ...securityRest, hasSecurity },
                securityFile: {
                    ...securityFileRest,
                    documentType: { id: securityDocumentTypeId },
                    documentCategory: { id: securityCategoryId },
                    archiveFile: {},
                },
                isLoading: false,
            };
        case getCreditLimit.failure:
            return { ...payload, isLoading: false };
        case createOrUpdate.request:
            return { ...state, isSubbmitRequested: true };
        case createOrUpdate.success:
        case createOrUpdate.failure:
            return { ...state, isSubbmitRequested: false };
        case retryCreditLimitOperations.request:
            return { ...state, isRetryPending: true };
        case retryCreditLimitOperations.success:
            return { ...state, isRetryPending: false, hasPendingCreditLimitOperation: true };
        case retryCreditLimitOperations.failed:
            return { ...state, isRetryPending: false };
        default:
            return state;
    }
};
