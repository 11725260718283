import Cell from "components/Table/SimpleTable/Cell";
import { default as TableRow } from "components/Table/SimpleTable/Row";
import { formatValue } from "utils/formating";
import React from "react";
import { Typography } from "@mui/material";

const Row = ({ columns, item }) => {
    return (
        <TableRow hover>
            {columns.map(({ key, type, options }, index) => {
                return (
                    <Cell key={index}>
                        <Typography>{formatValue(item[key], type, options)}</Typography>
                    </Cell>
                );
            })}
        </TableRow>
    );
};

export default Row;
