import _ from "lodash";
import {
    tValidation,
    validation,
} from "utils-ts/validations/translation";
import {
    notEmptyString,
    stringMustBeLength,
} from "../validators";

const Separator = ":";
const TagPrefix = "tag";
const CommercePrefix = "commerce";
const DynamicPrefix = "dynamic";
const ManualPrefix = "manual";
const ConsentPrefix = "consent";
const ContestPrefix = "contest";
const ProductPrefix = "product";
const PromotionPrefix = "promotion";
const GiftPrefix = "gift";

export const segmentValidator = [
    notEmptyString,
    stringMustBeLength(1, 100),
    {
        predicate: (value: string) => {
            const regex = new RegExp(
                `^(${TagPrefix}|${ProductPrefix}|${PromotionPrefix}|${GiftPrefix}|${DynamicPrefix}|${ManualPrefix}|${CommercePrefix}|${ConsentPrefix}|${ContestPrefix})${Separator}[A-Za-z0-9][A-Za-z0-9_-]*$`
            );

            return regex.test(value);
        },
        message: () =>
            tValidation(validation.formatRegex),
    },
];
