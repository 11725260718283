import { firstOrDefault } from "utils/extensions/arrayExtensions";
import React, { Fragment } from "react";
import Delete from "@mui/icons-material/Delete";
import { Chip, IconButton, ListItemSecondaryAction, ListItemText } from "@mui/material";

const LabelItem = ({ asChip, v, handleRemove, onlyLabel, items = [], idName, itemName }) => {
    let text = v;
    if (!onlyLabel) {
        const item = firstOrDefault(items, (x) => x[idName] === v, {
            [itemName]: {},
        });
        const { name = { pl: "" } } = item[itemName];
        text = `(${v}) - ${name.pl}`;
    }

    if (asChip) {
        return (
            <Chip
                key={v}
                style={{ marginRight: 10, marginTop: 10 }}
                size="medium"
                label={text}
                color="default"
                onDelete={() => handleRemove(v)}
            />
        );
    }

    return (
        <Fragment>
            <ListItemText primary={text} />
            <ListItemSecondaryAction>
                <IconButton
                    edge="end"
                    onClick={() => handleRemove(v)}
                >
                    <Delete />
                </IconButton>
            </ListItemSecondaryAction>
        </Fragment>
    );
};

export default LabelItem;
