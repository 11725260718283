import MappedProductIds from "components/Controls/MappedProductIds";
import { FormField } from "components/Form";
import { decimalNormalize } from "components/FormHelpers/ControlFormaters";
import { actions } from "store/cache/products/actions";
import { useCommonTranslation } from "utils/hooks";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { createSelector } from "reselect";
import { validators } from "./validators";

const cacheSelector = (state) => state.cache;

const cachedProductsSelector = createSelector(cacheSelector, (cache) => cache.products);

const NewProduct = ({ effect, isPriceLocked }) => {
    const { t, common } = useCommonTranslation();
    const cachedProducts = useSelector(cachedProductsSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        if (effect && effect.newProduct) {
            if (effect.newProduct.productId) {
                dispatch(actions.getProducts([effect.newProduct.productId]));
            }

            if (effect.newProduct.mappedProductIds) {
                dispatch(actions.getProducts(effect.newProduct.mappedProductIds));
            }
        }
    }, [effect, dispatch]);

    let productId = "";
    if (effect && effect.newProduct) {
        productId = effect.newProduct.productId;
    }

    let item = {};
    if (cachedProducts) {
        item = (cachedProducts.find((x) => x.productId === productId) || {}).product || {};
    }

    const product = {
        imageUrl: item.imageUrl,
        text: ` (${productId}) ${item.brand || ""} - ${item.name || ""}`,
    };

    return (
        <Fragment>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <FormField
                        style={{ width: 420 }}
                        name={"productId"}
                        label={t(common.productId)}
                        type={"textWithImage"}
                        item={product}
                        onAdd={(value) => dispatch(actions.getProducts([value]))}
                        validate={validators.productId}
                    />
                    <FormField
                        name={"quantity"}
                        label={t(common.quantity)}
                        type="number"
                        validate={validators.quantity}
                    />
                    <FormField
                        disabled={isPriceLocked}
                        name={"price"}
                        label={t(common.price)}
                        type={"number"}
                        format={decimalNormalize}
                        validate={validators.price}
                    />
                    <MappedProductIds name="mappedProductIds" />
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default NewProduct;
