import { FormField } from "components/Form";
import { toSelectList } from "utils/extensions/arrayExtensions";
import { withFormName } from "utils/hoc";
import { useChange, useCommonTranslation } from "utils/hooks";
import { isEmpty } from "utils/validators/basic";
import React, { Fragment } from "react";
import { DeliveryModifierEffect } from "./model";
import { validators } from "./validators";

const DeliveryModifier = ({ form, sectionPrefix, effect }) => {
    const { t, common } = useCommonTranslation();
    const change = useChange(form);
    const changeType = async (type) => {
        await change(sectionPrefix, null);
        change(form, sectionPrefix, new DeliveryModifierEffect({ [type]: 1 }));
    };

    const membershipPointsTypes = toSelectList(["newDeliveryCost", "deliveryDiscount"], t);
    const { effectType, ...value } = effect;
    return (
        <Fragment>
            <FormField
                name={"type"}
                label={t(common.modificationType)}
                type="select"
                items={membershipPointsTypes}
                onChange={(_, value) => {
                    if (effect.type !== value) changeType(value);
                }}
                hideDefaultItem
                validate={isEmpty}
            />

            <FormField
                name={value[effectType]?.type || ""}
                label={t(common.value)}
                type="number"
                validate={validators.deliveryModifierValue}
            />
        </Fragment>
    );
};

export default withFormName(DeliveryModifier);
