import { tValidation, validation } from "utils-ts/validations/translation";
import { notEmptyValidator, stringLength } from "./basic";

const lowerCaseCharRegex = /[a-z]/;
const upperCaseCharRegex = /[A-Z]/;
const digitCaseCharRegex = /[0-9]/;

export default function passwordValidator(password) {
    return notEmptyValidator(password)
        .must(stringLength(9, 100), tValidation(validation.stringLength, { min: 9, max: 100 }))
        .must((val) => val.split("").some((x) => lowerCaseCharRegex.test(x)), tValidation(validation.passwordLowerChar))
        .must((val) => val.split("").some((x) => upperCaseCharRegex.test(x)), tValidation(validation.passwordUpperChar))
        .must((val) => val.split("").some((x) => digitCaseCharRegex.test(x)), tValidation(validation.passwordDigit));
}
