import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
    cover: {
        width: 356,
        maxWidth: "100%",
        height: "auto",
    },
    container: {
        padding: theme.spacing(2),
        position: "relative",
        display: "inline-block",
        verticalAlign: "top",
        height: "auto",
        width: 400,
    },
}));

const ProductImage = ({ imageUrl }) => {
    const { classes } = useStyles();

    return (
        <div className={classes.container}>
            <img
                className={classes.cover}
                src={imageUrl}
                alt=""
            />
        </div>
    );
};

export default ProductImage;
