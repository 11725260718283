import { types } from "./actions";

const initialState = {
    isLoading: false,
    isPosting: false,
    postcodes: [],
};

export const postCodes = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.GET_DELIVERY_METHOD_POST_CODES:
            return { ...initialState, isLoading: true };
        case types.GET_DELIVERY_METHOD_POST_CODES_SUCCESS:
            return { ...payload, isLoading: false };
        case types.GET_DELIVERY_METHOD_POST_CODES_FAILURE:
            return { ...payload, isLoading: false };
        case types.PUT_DELIVERY_METHOD_POST_CODES:
            return { ...state, isPosting: true };
        case types.PUT_DELIVERY_METHOD_POST_CODES_SUCCESS:
            return { ...state, isPosting: false };
        default:
            return state;
    }
};
