const tableActionTypes = {
    add: "add",
    button: "button",
    edit: "edit",
    preview: "preview",
    link: "link",
    menu: "menu",
    delete: "delete",
    copy: "copy",
    retry: "retry",
    list: "list",
    select: "select",
    linkDocument: "linkDocument",
    unlinkDocument: "unlinkDocument",
    download: "download",
    accept: "accept",
    reject: "reject",
};

export default tableActionTypes;
