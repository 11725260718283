import Layout from "components/Grid/Layout";
import roles from "consts/roles";
import { useUser } from "context/UserContext/UserContext";
import { getVendors } from "store/autocomplete";
import { getUser } from "store/identity/userDetails/actions";
import { userSelector } from "store/identity/userDetails/selectors";
import userTypes from "utils/dictionaryTypes/userType";
import { useParams } from "utils/hooks";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { administration } from "translations";
import { useTranslation } from "utils-ts/hooks";
import ChangeAccountActivation from "./ChangeAccountActivation";
import ChangePasswordForm from "./ChangePasswordForm";
import ChangeUserEmailForm from "./ChangeUserEmailForm";
import ChangeUserFullNameForm from "./ChangeUserFullNameForm";
import CreateUserForm from "./CreateUserForm";
import UserRoles from "./UserRoles";

const UserDetailsPage = (props) => {
    const { isAdmin, isInRoleOrAdmin } = useUser();
    const { t } = useTranslation("administration");
    const { userId } = useParams();
    const { user } = props;
    const dispatch = useDispatch();
    const isNewUser = !userId;
    useEffect(() => {
        if (!isNewUser) {
            dispatch(getUser(userId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, userId, isNewUser]);

    return (
        <Layout
            main
            headerText={isNewUser === true ? t(administration.newUser) : t(administration.basicData)}
            navigationProps={{
                backProps: { path: `/administration/users` },
            }}
            customTitle={!isNewUser && user?.email && `${t(administration.userAdministration)} - ${user.email}`}
        >
            {isNewUser ? (
                isAdmin && <CreateUserForm />
            ) : (
                <>
                    {isInRoleOrAdmin(roles.HelpDesk) && <ChangeAccountActivation />}
                    <ChangeUserEmailForm isAdmin={isAdmin} />
                    <ChangeUserFullNameForm isAdmin={isAdmin} />
                    {isAdmin && <ChangePasswordForm userId={userId} />}
                    {user?.userType === userTypes.internalUser && (
                        <Layout
                            isEnlarged
                            headerText={t(administration.roles)}
                        >
                            <UserRoles />
                        </Layout>
                    )}
                </>
            )}
        </Layout>
    );
};

const mapStateToProps = (state) => {
    const user = userSelector(state);

    return {
        user,
    };
};

const mapDispatchToProps = (dispatch) => ({
    getVendors: () => dispatch(getVendors()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsPage);
