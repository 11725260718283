import { Button } from "components/Button";
import { CommerceForm } from "components/Form";
import Layout from "components/Grid/Layout";
import { reduxForm } from "redux-form";
import { actions } from "store/vouchers/form/actions";
import { useCommonTranslation, useMappedDispatch, useParams } from "utils/hooks";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { voucherFormSelector } from "../selectors";
import VoucherFields from "./VoucherFields";
import { validateVoucher } from "./validate";

const batchStatuses = {
    created: "Created",
    generated: "Generated",
};

const BatchActions = ({ id, batchStatus }) => {
    const { t, common } = useCommonTranslation();
    const { generateBatch, downloadBatch } = useMappedDispatch(actions);
    if (!id) return <Fragment />;

    if (batchStatus === batchStatuses.created) {
        return (
            <Button
                actionType="primary"
                onClick={() => generateBatch(id)}
            >
                {t(common.generate)}
            </Button>
        );
    }

    if (batchStatus === batchStatuses.generated) {
        return (
            <Button
                actionType="primary"
                onClick={() => downloadBatch(id)}
            >
                {t(common.downloadBatch)}
            </Button>
        );
    }

    return <Fragment />;
};

const Voucher = ({ handleSubmit, isSubbmitRequested, onSubmit, id, batchStatus, name, isSystem }) => {
    const { t, common } = useCommonTranslation();
    const { type } = useParams();
    const isBatchForm = type === "batches";

    return (
        <CommerceForm
            onSubmit={handleSubmit((values) => onSubmit(values))}
            isReadonly={isSystem}
            isSubbmitRequested={isSubbmitRequested}
        >
            <Layout
                main
                headerText={t(isBatchForm ? common.batches : common.voucher, {
                    id,
                })}
                navigateBack
                customTitle={id && name && `${t(common.batches)} - ${name}`}
            >
                {isBatchForm && id ? (
                    <BatchActions
                        id={id}
                        batchStatus={batchStatus}
                    />
                ) : undefined}
                <VoucherFields />
            </Layout>
        </CommerceForm>
    );
};

const formName = "voucher-form";
const stateToProps = (state, props) => {
    const { isSubbmitRequested, isSystem, ...form } = voucherFormSelector(state);
    return {
        id: form.id,
        isSystem,
        batchStatus: form.batchStatus,
        isSubbmitRequested,
        name: form.name,
        initialValues: {
            formType: props.type,
            ...form,
        },
    };
};

export default connect(stateToProps)(
    reduxForm({
        form: formName,
        enableReinitialize: true,
        validate: validateVoucher,
    })(Voucher)
);
