import { displayMessage } from "store/messaging/actions";

export const formRequestMiddleware = (store) => (next) => (action) => {
    if (action && action.meta) {
        const { formSubmit, message } = action.meta;
        if (formSubmit) {
            if (formSubmit === "REQUEST") {
                store.dispatch(
                    displayMessage({
                        message: message || "Zapisywanie...",
                        variant: "info",
                    })
                );
            }

            if (formSubmit === "SUCCESS") {
                store.dispatch(
                    displayMessage({
                        message: message || "Zapisano pomyślnie!",
                        variant: "success",
                    })
                );
            }

            if (formSubmit === "FAILURE") {
                store.dispatch(
                    displayMessage({
                        message: message || "Błąd przy zapisie.",
                        variant: "error",
                    })
                );
            }
        }
    }

    return next(action);
};
