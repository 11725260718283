import { GetRequest, PagedRequest, get, getPaged } from "utils/extensions";
import types from "./types";

const endpoint = (vendorId, vendorOrderId) => `api/vendors/${vendorId}/orders${vendorOrderId ? `/${vendorOrderId}` : ``}`;

export const findVendorOrders = (vendorId, pageIndex, pageSize) => async (dispatch) => {
    return dispatch(getPaged(new PagedRequest(pageIndex, pageSize, endpoint(vendorId), types.getList.all)));
};

export const getVendorOrder = (vendorId, orderId) => async (dispatch) => {
    return dispatch(get(new GetRequest(endpoint(vendorId, orderId), types.getVendorOrder.all)));
};
