import { businessRegistryNumberValidator, emailValidator, notEmptyValidator, requiredVatinValidator } from "utils/validators";

const validation = {
    required: (val) => notEmptyValidator(val).validate(),
    requiredEmail: (val) => notEmptyValidator(val).validate() && emailValidator(val).validate(),
    email: (val) => (val ? emailValidator(val).validate() : undefined),
    businessRegistryNumber: (val) => notEmptyValidator(val).validate() && businessRegistryNumberValidator(val).validate(),
    vatin: (val) => requiredVatinValidator(val).validate(),
};

export default validation;
