import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: (failureCount, error) => {
                if (error.status === 401) {
                    return failureCount > 6;
                }

                if (error.status == 503) {
                    return failureCount > 10;
                }

                return failureCount > 2;
            },
            staleTime: 1000 * 10,
            refetchOnWindowFocus: false,
            throwOnError: false,
        },
        mutations: {
            throwOnError: false,
        },
    },
});

const ApiProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

export default ApiProvider;
