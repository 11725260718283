import { RSAA } from "redux-api-middleware";
import { commerce, manager } from "store/actionTypePrefixes";
import { asAsyncFormTypes } from "utils/extensions/arrayExtensions";

export const types = {
    FORM_INIT_PHRASE_BOOST: `${manager}/FORM_INIT_PHRASE_BOOST`,
    GET_PHRASE_BOOST: `${commerce}/GET_PHRASE_BOOST`,
    GET_PHRASE_BOOST_SUCCESS: `${commerce}/GET_PHRASE_BOOST_SUCCESS`,
    GET_PHRASE_BOOST_FAILURE: `${commerce}/GET_PHRASE_BOOST_FAILURE`,
    CREATE_OR_UPDATE_PHRASE_BOOST: `${commerce}/CREATE_OR_UPDATE_PHRASE_BOOST`,
    CREATE_OR_UPDATE_PHRASE_BOOST_SUCCESS: `${commerce}/CREATE_OR_UPDATE_PHRASE_BOOST_SUCCESS`,
    CREATE_OR_UPDATE_PHRASE_BOOST_FAILURE: `${commerce}/CREATE_OR_UPDATE_PHRASE_BOOST_FAILURE`,
};

const initForm = (id, isCopy) => async (dispatch) => {
    await dispatch({ type: types.FORM_INIT_PHRASE_BOOST });

    if (id) {
        dispatch({
            [RSAA]: {
                endpoint: `api/v1/search/phrase-boost/${id}`,
                headers: { "Content-Type": "application/json" },
                method: "GET",
                types: [types.GET_PHRASE_BOOST, { type: types.GET_PHRASE_BOOST_SUCCESS, meta: { isCopy } }, types.GET_PHRASE_BOOST_FAILURE],
            },
        });
    }
};

const createOrUpdate = (values, id) => async (dispatch) => {
    return await dispatch({
        [RSAA]: {
            endpoint: id ? `api/v1/search/phrase-boost/${id}` : `api/v1/search/phrase-boost/`,
            headers: { "Content-Type": "application/json" },
            method: id ? "PUT" : "POST",
            types: asAsyncFormTypes([
                types.CREATE_OR_UPDATE_PHRASE_BOOST,
                types.CREATE_OR_UPDATE_PHRASE_BOOST_SUCCESS,
                types.CREATE_OR_UPDATE_PHRASE_BOOST_FAILURE,
            ]),
            body: JSON.stringify(values),
        },
    });
};

export const actions = {
    initForm,
    createOrUpdate,
};
