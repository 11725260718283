import ControlLabel from "components/Controls/ControlLabel";
import InputAdornment from "components/Controls/InoutAdorment/InputAdorment";
import React from "react";
import { TextField } from "@mui/material";

class StepInput extends React.Component {
    handleChange = (newValue) => {
        const { input, valueFormat, onChangeCustom, minValue, maxValue } = this.props;
        const { onChange } = input;
        let val = newValue;

        if (minValue !== undefined && val < minValue) {
            val = minValue;
        }

        if (maxValue !== undefined && val > maxValue) {
            val = maxValue;
        }
        if (valueFormat && newValue) {
            val = valueFormat(val);
        }
        onChange(val);
        if (onChangeCustom) onChangeCustom(val);
    };

    handleIncrement = () => {
        const {
            increment,
            input: { value },
            maxValue,
        } = this.props;
        const incremented = increment(value);
        if (maxValue === undefined || incremented <= maxValue) {
            this.handleChange(incremented);
        }
    };

    handleDecremenet = () => {
        const {
            decrement,
            input: { value },
            minValue,
        } = this.props;
        const decremented = decrement(value);
        if (minValue === undefined || decremented >= minValue) {
            this.handleChange(decremented);
        }
    };

    render() {
        const {
            label,
            input,
            meta: { touched, invalid, error, warning },
            formatDisplay,
            isRequired,
            autoComplete,
            ...custom
        } = this.props;
        const value = input?.value || input.value === 0 ? input.value : "";

        const disabled = Boolean(custom.disabled);
        return (
            <TextField
                {...input}
                error={touched && invalid}
                helperText={(touched && error) || warning}
                label={
                    <ControlLabel
                        label={label}
                        isRequired={isRequired}
                    />
                }
                variant="standard"
                type="text"
                fullWidth={custom.fullWidth || false}
                disabled={disabled}
                onChange={(event) => {
                    event.persist();
                    const value = event.target.value;
                    this.handleChange(value);
                }}
                onBlur={(event) => {
                    if (this.props.onBlur) {
                        this.props.onBlur(event);
                    }
                }}
                value={formatDisplay ? formatDisplay(value) : value}
                InputProps={{
                    startAdornment: (
                        <InputAdornment
                            position="start"
                            icon="remove"
                            onClick={this.handleDecremenet}
                            disabled={disabled}
                        />
                    ),
                    endAdornment: (
                        <InputAdornment
                            position="end"
                            icon="add"
                            onClick={this.handleIncrement}
                            disabled={disabled}
                        />
                    ),
                }}
                autoComplete={autoComplete || undefined}
                FormHelperTextProps={{
                    style: { color: warning ? "#fbc02d" : "" },
                }}
            />
        );
    }
}

export default React.memo(StepInput);
