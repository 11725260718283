import { AutocompleteDiffControl, DefaultDiffControl } from "components/FormDiff";
import { renderFieldOrText } from "components/FormHelpers/Controls";
import { Field } from "redux-form";
import { makeStyles } from "tss-react/mui";
import { Validator } from "utils/validators";
import React, { useMemo } from "react";
import { Grid, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import classnames from "classnames";

const DiffControl = (props) => {
    if (((props.type === "autocomplete" || props.type === "select") && props.multiple) || props.type === "list") {
        return <AutocompleteDiffControl {...props} />;
    }

    return <DefaultDiffControl {...props} />;
};

const useStyles = makeStyles()((theme, { stretch, width }) => ({
    field: {
        width: stretch ? "100%" : width,
        padding: theme.spacing(1),
    },
    noPadding: {
        padding: theme.spacing(0),
    },
    tooltipText: {
        color: "#fff",
        fontSize: theme.typography.pxToRem(16),
    },
    padding: {
        padding: 5,
    },
}));

const FieldToolTip = ({ tooltipLabel, classes, children }) => {
    return (
        <Tooltip
            title={
                <Typography
                    className={classes.tooltipText}
                    variant="body2"
                >
                    {tooltipLabel}
                </Typography>
            }
            placement="right-start"
            arrow
        >
            <div>{children}</div>
        </Tooltip>
    );
};

const isRequired = (validate) => {
    try {
        const result = validate(undefined, {}, {}, "");
        return validate && result !== false && result !== "";
    } catch (error) {
        return false;
    }
};

const DateValidator = (value) => {
    return new Validator(value)
        .must((v) => moment(v).isValid(), "Data nie jest poprawna")
        .when(!!value)
        .validate();
};

const FormField = ({ className, component, style, stretch = false, width = 350, tooltipLabel, ...props }) => {
    const mergedValidator = useMemo(() => {
        if (props.type === "dateTime" || props.type === "date") {
            return (value, ...args) => DateValidator(value) || (props?.validate && props.validate(value, ...args));
        }

        return props.validate;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.type, props.validate]);

    const { classes } = useStyles({ stretch, width });
    if (props.hidden) {
        return (
            <Grid
                item
                className={classnames(className, classes.noPadding)}
            ></Grid>
        );
    }

    let field;

    if (!props.useDiff) {
        field = (
            <Field
                {...props}
                fullWidth
                component={component ? component : renderFieldOrText}
                isRequired={isRequired(props?.validate)}
                validate={mergedValidator}
            />
        );
    } else {
        field = (
            <Field
                {...props}
                component={DiffControl}
            />
        );
    }

    return (
        <Grid
            item
            className={classnames(classes.field, className)}
            style={{ ...style, display: props.hidden ? "none" : undefined }}
        >
            {tooltipLabel ? (
                <FieldToolTip
                    tooltipLabel={tooltipLabel}
                    classes={classes}
                >
                    {field}
                </FieldToolTip>
            ) : (
                field
            )}
        </Grid>
    );
};

export default React.memo(FormField);
