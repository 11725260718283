import { useLocation } from "react-router-dom";

const withLocation = (WrappedComponent) => (props) => {
    const location = useLocation();

    return (
        <WrappedComponent
            {...props}
            location={{ ...location.state }}
        />
    );
};

export default withLocation;
