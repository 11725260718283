import { FormField } from "components/Form";
import { decimalNormalize } from "components/FormHelpers/ControlFormaters";
import { FormSection } from "redux-form";
import { withFormName } from "utils/hoc";
import { useChange, useCommonTranslation, useFormValueSelector } from "utils/hooks";
import Validator, { decimalPlaces, notEmpty } from "utils/validators/basic";
import { Collapse, Grid } from "@mui/material";
import { get } from "lodash";
import { tValidation, validation } from "utils-ts/validations/translation";

const validateAtLeastOne = (value, form, _, path) => {
    const list = get(form, path.substring(0, path.lastIndexOf("."))) || {};

    return new Validator(list)
        .must((o) => {
            return notEmpty(o.targetAmountFrom) || notEmpty(o.targetAmountTo);
        }, tValidation(validation.atLeastOneField))
        .validate();
};

const validateFrom = (value, form, _, path) => {
    let valueTo = get(form, path.substring(0, path.lastIndexOf(".")) + path.substring(path.lastIndexOf(".")).replace("From", "To"));

    return new Validator(value)
        .must((value) => Number(value) >= 0, tValidation(validation.mustBePossitive))
        .when(notEmpty(value))
        .must(decimalPlaces([0, 2]), tValidation(validation.decimalPlaces))
        .when(notEmpty(value))
        .must(
            (value) => Number(value) <= Number(valueTo),
            tValidation(validation.lessOrEqualsThan, {
                number: valueTo,
            })
        )
        .when(notEmpty(value) && notEmpty(valueTo))
        .validate();
};

const validateTo = (value, form, _, path) => {
    let valueFrom = get(form, path.substring(0, path.lastIndexOf(".")) + path.substring(path.lastIndexOf(".")).replace("To", "From"));

    return new Validator(value)
        .must((value) => Number(value) >= 0, tValidation(validation.mustBePossitive))
        .when(notEmpty(value))
        .must(decimalPlaces([0, 2]), tValidation(validation.decimalPlaces))
        .when(notEmpty(value))
        .must(
            (value) => Number(value) >= Number(valueFrom),
            tValidation(validation.greaterOrEqualsThan, {
                number: valueFrom,
            })
        )
        .when(notEmpty(value) && notEmpty(valueFrom))
        .validate();
};

const fieldsContainer = {
    item: true,
    container: true,
    direction: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
};

const PersonalTargetRequirement = ({ form, sectionPrefix, readOnly = false }) => {
    const change = useChange(form);
    const { t, common } = useCommonTranslation();
    const user = useFormValueSelector(form, sectionPrefix);
    const { havePersonalTargetContraint } = user;

    return (
        <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
        >
            <FormField
                name={"havePersonalTargetContraint"}
                label={t(common.personalTarget)}
                type={"boolean"}
                onChange={(event) => {
                    if (!event.target.checked) {
                        change(`${sectionPrefix}.personalTarget`, null);
                    }
                }}
                readOnly={readOnly}
            />
            <FormSection name="personalTarget">
                <Collapse in={Boolean(havePersonalTargetContraint)}>
                    <Grid {...fieldsContainer}>
                        <FormField
                            hidden={!havePersonalTargetContraint}
                            name={"targetAmountFrom"}
                            label={t(common.targetAmountFrom)}
                            type={"number"}
                            formatDisplay={decimalNormalize}
                            validate={[validateFrom, validateAtLeastOne]}
                            readOnly={readOnly}
                        />
                        <FormField
                            hidden={!havePersonalTargetContraint}
                            name={"targetAmountTo"}
                            label={t(common.targetAmountTo)}
                            type={"number"}
                            formatDisplay={decimalNormalize}
                            validate={[validateTo, validateAtLeastOne]}
                            readOnly={readOnly}
                        />
                    </Grid>
                </Collapse>
            </FormSection>
        </Grid>
    );
};

export default withFormName(PersonalTargetRequirement);
