import { makeStyles } from "tss-react/mui";
import { useCommonTranslation } from "utils/hooks";
import { ErrorCode, useDropzone } from "react-dropzone";
import { Grid } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import classnames from "classnames";

const useStyles = makeStyles()({
    base: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        borderWidth: 2,
        borderRadius: 2,
        borderColor: "#eeeeee",
        borderStyle: "dashed",
        backgroundColor: "#fafafa",
        color: "#bdbdbd",
        outline: "none",
        transition: "border .24s ease-in-out",
    },
    active: {
        borderColor: "#2196f3",
    },
    accept: {
        borderColor: "#00e676",
    },
    reject: {
        borderColor: "#ff1744",
    },
});

const Dropzone = ({
    onDrop,
    disabled,
    accept,
    maxFiles = 0,
    required = false,
    hasFile = false,
    maxSize = 52428800, //in bytes => 50MB
    minSize = 1024, //in bytes => 1KB
}) => {
    const { t, common } = useCommonTranslation();
    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, fileRejections } = useDropzone({
        accept,
        onDrop,
        maxFiles,
        multiple: maxFiles === 0 || maxFiles > 1,
        maxSize,
        minSize,
    });
    const { classes } = useStyles();
    const isFileTooLarge = fileRejections.length > 0 && fileRejections.some((f) => f.errors.some((e) => e.code === ErrorCode.FileTooLarge));
    const isFileTooSmall = fileRejections.length > 0 && fileRejections.some((f) => f.errors.some((e) => e.code === ErrorCode.FileTooSmall));

    return (
        !disabled && (
            <Grid
                item
                xs={12}
                sm={12}
                md={12}
            >
                <div className="clickable-container">
                    <div
                        {...getRootProps({
                            className: classnames(
                                classes.base,
                                isDragActive && classes.active,
                                isDragAccept && classes.accept,
                                isDragReject && classes.reject
                            ),
                        })}
                    >
                        <>
                            <input {...getInputProps()} />
                            <p>{t(common.fileDragAndDropLabel)}</p>
                        </>
                    </div>
                </div>

                {required && !hasFile && <FormHelperText style={{ color: "red" }}>Należy dodać conajmniej 1 plik</FormHelperText>}

                {isFileTooLarge && <FormHelperText style={{ color: "red" }}>Plik nie może być większy niż 50MB</FormHelperText>}

                {isFileTooSmall && <FormHelperText style={{ color: "red" }}>Plik nie może być mniejszy niż 1KB</FormHelperText>}
            </Grid>
        )
    );
};

export default Dropzone;
