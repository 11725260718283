import Button from "components/Button/Button";
import { FormField } from "components/Form";
import Layout from "components/Grid/Layout";
import { reduxForm } from "redux-form";
import { createOrUpdate, initForm, validateName } from "store/dictonaries/cnStakes/form";
import { useCommonTranslation, useParams } from "utils/hooks";
import { notEmptyValidator, stringLength } from "utils/validators/basic";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { isEmpty } from "lodash";
import { usePush } from "utils-ts/hooks";
import { tValidation, validation } from "utils-ts/validations/translation";
import { Paths } from "routing-ts/ManagerPaths";
import { cnStakesFormSelector } from "./selectors";

const validators = {
    name: (value) =>
        notEmptyValidator(value)
            .must(
                stringLength(1, 250),
                tValidation(validation.inRange, {
                    min: 1,
                    max: 250,
                })
            )
            .validate(),
    description: (value) =>
        notEmptyValidator(value)
            .must(
                stringLength(1, 250),
                tValidation(validation.inRange, {
                    min: 1,
                    max: 250,
                })
            )
            .validate(),
};

const CNStakesForm = ({ handleSubmit, isSubbmitRequested, name }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(initForm(id));
    }, [dispatch, id]);
    const { t, common } = useCommonTranslation();
    const { replace } = usePush();
    const submit = async (values) => {
        const { payload } = await dispatch(createOrUpdate(values, id));
        if (!id) {
            replace(Paths.Dictionaries.CnStakeForm, { id: payload.id });
        }
    };

    return (
        <form onSubmit={handleSubmit((values) => submit(values))}>
            <Layout
                main
                headerText={t(common.cnStake, {
                    id: id || t(common.newItem),
                })}
                navigationProps={{
                    backProps: { path: "/dictionaries/cn-stakes" },
                }}
                customTitle={id && name && `${t(common.cnStake)}- ${name}`}
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <FormField
                        name={"name"}
                        type={"text"}
                        label={t(common.cnStake)}
                        validate={validators.name}
                    />
                    <FormField
                        name={"description"}
                        type={"text"}
                        label={t(common.description)}
                        validate={validators.description}
                    />
                    <FormField
                        name={"isActive"}
                        type={"boolean"}
                        label={t(common.isActive)}
                    />
                </Grid>
            </Layout>
            <Button
                actionType="accept"
                type="submit"
                fullWidth
                loading={isSubbmitRequested}
            >
                {t(common.save)}
            </Button>
        </form>
    );
};

const formName = "cn-steaks-form";

const stateToProps = (state) => {
    const { isSubbmitRequested, ...form } = cnStakesFormSelector(state);
    return {
        isSubbmitRequested,
        name: form.name,
        initialValues: {
            ...form,
            isActive: form.isActive !== undefined ? form.isActive : true,
        },
    };
};

const asyncValidate = async (form, dispatch) => {
    let errors = {};
    let hasErrors = false;
    if (!isEmpty(form.name)) {
        const result = await dispatch(validateName(form.name, form.id));

        if (!isEmpty(result?.payload) && result.payload.length > 1) {
            hasErrors = true;
            errors = {
                name: `Wprowadzony kod CN jest już w bazie pod id: ${result.payload}`,
            };
        }
    }

    return new Promise((resolve, reject) => {
        if (hasErrors) {
            reject(errors);
        }

        resolve();
    });
};

export default connect(stateToProps)(
    reduxForm({
        form: formName,
        enableReinitialize: true,
        asyncValidate,
        shouldAsyncValidate: ({ trigger, syncValidationPasses }) => {
            if (trigger === "submit" || syncValidationPasses) {
                return true;
            }

            return false;
        },
    })(CNStakesForm)
);
