import footerStyle from "assets/jss/material-dashboard-react/components/footerStyle";
import { useVersion } from "context/VersionContext/VersionContext";
import { withStyles } from "tss-react/mui";
import PropTypes from "prop-types";

function Footer({ classes }) {
    const { currentVersion } = useVersion();

    return (
        <footer className={classes.footer}>
            <div className={classes.container}>
                <div className={classes.right}>
                    <span>
                        &copy; {1900 + new Date().getYear()}
                        <a
                            href="https://www.frisco.pl/"
                            className={classes.a}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Frisco
                        </a>
                    </span>
                    {currentVersion && <div>ver: {currentVersion}</div>}
                </div>
            </div>
        </footer>
    );
}

Footer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(Footer, footerStyle);
