import Validator, {
    greaterOrEqualsThan,
    greaterThan,
    lessOrEqualsThan,
    notEmpty,
    notEmptyArrayValidator,
    notEmptyValidator,
    stringLength,
} from "utils/validators/basic";
import moment from "moment";
import { get } from "lodash";
import { common } from "translations";
import { tValidation, validation } from "utils-ts/validations/translation";

const name = (value) =>
    notEmptyValidator(value)
        .must(
            stringLength(5, 250),
            tValidation(validation.stringLength, {
                min: 5,
                max: 250,
            })
        )
        .validate();
const feedFormat = (value) => notEmptyValidator(value).validate();
const campaign = (value, form) => {
    const { warehouses } = form;

    return notEmptyValidator(value)
        .must(
            (val) => val === "handel_krótkitermin" || val === "ecommerce_sampling",
            tValidation(validation.mustBeEqual, {
                value: "handel_krótkitermin' lub 'ecommerce_sampling",
                when: "kiedy wybrano conajmniej 1 magazyn",
            })
        )
        .when(Array.isArray(warehouses) && warehouses.length > 0)
        .validate();
};
const productId = (value, form) => {
    const { products } = form;
    return notEmptyValidator(value)
        .must((v) => products.filter((x) => x.productId === v).length <= 1, tValidation(validation.unique))
        .validate();
};
const products = (_, form, __, ___) => {
    return new Validator(form)
        .must((d) => {
            return !notEmptyArrayValidator(d.products).validate() || !notEmptyArrayValidator(d.productPredicates).validate();
        }, tValidation(validation.atLeastOneField))
        .validate();
};
const modifierType = (value) => notEmptyValidator(value).validate();
const productLists = (value) => notEmptyArrayValidator(value).validate();
const greaterThan0 = (value) => new Validator(value).must(greaterThan(0), tValidation(validation.greaterThan, { number: 0 }));
const greaterThan0WhenNotEmpty = (value) =>
    new Validator(value)
        .must(greaterThan(0), tValidation(validation.greaterThan, { number: 0 }))
        .when(notEmpty(value))
        .validate();
const priority = (value) => new Validator(value).must(greaterThan(0), tValidation(validation.greaterThan, { number: 0 })).validate();
const discountPercent = (value) => greaterThan0(value).validate();
const discountValue = (value) => greaterThan0(value).validate();
const newPrice = (value) => greaterThan0(value).validate();
const customers = (value) => notEmptyValidator(value).validate();
const effectValidation = (value) => notEmptyArrayValidator(value).validate();
const imageSize = (value) =>
    new Validator(value)
        .must(
            lessOrEqualsThan(500),
            tValidation(validation.lessOrEqualsThan, {
                number: 500,
            })
        )
        .must(greaterOrEqualsThan(40), tValidation(validation.greaterThan, { number: 40 }))
        .allWhen(Boolean(value))
        .validate();

const validFrom = (value, form) => {
    const { validTo } = form;

    return new Validator(value)
        .must(
            () => {
                return value < validTo;
            },
            tValidation(validation.lessDateThan, {
                date: moment(form.validTo).format("DD.MM.YYYY HH:mm"),
            })
        )
        .when(notEmpty(value) && notEmpty(validTo))
        .validate();
};

const validTo = (value, form) => {
    const { validFrom } = form;

    return new Validator(value)
        .must(
            () => {
                return value > validFrom;
            },
            tValidation(validation.greaterDateThan, {
                date: moment(form.validFrom).format("DD.MM.YYYY HH:mm"),
            })
        )
        .when(notEmpty(value) && notEmpty(validFrom))
        .validate();
};

const choiceCount = (value) => {
    return value ? greaterThan0(value).validate() : undefined;
};

const activeChoiceGroup = (value) =>
    new Validator(value)
        .must(
            stringLength(3, 25),
            tValidation(validation.stringLength, {
                min: 3,
                max: 25,
            })
        )
        .when(Boolean(value))
        .validate();

const cost = (value) => {
    return new Validator(value)
        .must(() => !isNaN(Number(value)), tValidation(validation.mustBeNumber))
        .must(greaterThan(0), tValidation(validation.greaterThan, { number: 0 }))
        .allWhen(Boolean(value))
        .validate();
};

const validateMinumumChoiceCount = (value, form) => {
    const { maximumChoiceCount } = form;

    return new Validator(value)
        .must(greaterThan(0), tValidation(validation.greaterThan, { number: 0 }))
        .must(
            (value) => Number(value) <= Number(maximumChoiceCount),
            tValidation(validation.lessOrEqualsThan, {
                number: maximumChoiceCount,
            })
        )
        .when(notEmpty(maximumChoiceCount))
        .allWhen(notEmpty(value))
        .validate();
};

const validateMaximumChoiceCount = (value, form) => {
    const { minumumChoiceCount } = form;

    return new Validator(value)
        .must(greaterThan(0), tValidation(validation.greaterThan, { number: 0 }))
        .must(
            (value) => Number(minumumChoiceCount) <= Number(value),
            tValidation(validation.greaterOrEqualsThan, {
                number: minumumChoiceCount,
            })
        )
        .when(notEmpty(minumumChoiceCount))
        .allWhen(notEmpty(value))
        .validate();
};

const validateFallbackChoiceCount = (value, form) => {
    const { minumumChoiceCount } = form;

    return new Validator(value)
        .must(greaterThan(0), tValidation(validation.greaterThan, { number: 0 }))
        .must(
            (value) => Number(minumumChoiceCount) >= Number(value),
            tValidation(validation.lessOrEqualsThan, {
                number: minumumChoiceCount,
            })
        )
        .when(notEmpty(minumumChoiceCount))
        .allWhen(notEmpty(value))
        .validate();
};

const validForDays = (value, form, _, path) => {
    let definition = get(form, path.substring(0, path.lastIndexOf("."))) || {};
    let { validTo, validFrom } = definition;

    return new Validator(value)
        .must(
            (value) => value == null || value === "",
            tValidation(validation.mustBeEmpty, {
                when: tValidation(common.whenValidToAndValidFromNotEmpty, {}, "common"),
            })
        )
        .when(validTo != null && validFrom != null)
        .must(
            (value) => value > 0,
            tValidation(validation.greaterOrEqualsThan, {
                number: 0,
            })
        )
        .when(validTo == null)
        .validate();
};
const warehouses = (value, form) => {
    const divisions = form["divisions"];

    return new Validator(value)
        .must(
            (val) => val === null || val === undefined || (Array.isArray(val) && val.length === 0),
            tValidation(validation.mustBeEmpty, {
                when: "kiedy wybrano co najmniej 1 oddział",
            })
        )
        .when(divisions !== undefined && divisions !== null && Array.isArray(divisions) && divisions.length > 0)
        .validate();
};

export const validators = {
    name,
    feedFormat,
    campaign,
    productId,
    modifierType,
    discountPercent,
    discountValue,
    newPrice,
    products,
    productLists,
    customers,
    effectValidation,
    imageSize,
    greaterThan0,
    priority,
    validFrom,
    validTo,
    choiceCount,
    validateMinumumChoiceCount,
    validateMaximumChoiceCount,
    validateFallbackChoiceCount,
    activeChoiceGroup,
    cost,
    greaterThan0WhenNotEmpty,
    validForDays,
    warehouses,
};
