import { makeStyles } from "tss-react/mui";
import React from "react";
import {
    FirstPage as FirstPageIcon,
    KeyboardArrowLeft as KeyboardArrowLeftIcon,
    KeyboardArrowRight as KeyboardArrowRightIcon,
    LastPage as LastPageIcon,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Theme } from "@mui/material";
import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        display: "flex",
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

const TablePaginationActions: React.FC<TablePaginationActionsProps> = ({ count, page, rowsPerPage, onPageChange }) => {
    const { classes } = useStyles();

    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    const isNextDisabled = page === Math.ceil(count / rowsPerPage) - 1 || count === 0;

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
            >
                <FirstPageIcon />
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
            >
                <KeyboardArrowLeftIcon />
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={isNextDisabled}
            >
                <KeyboardArrowRightIcon />
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={isNextDisabled}
            >
                <LastPageIcon />
            </IconButton>
        </div>
    );
};

export default TablePaginationActions;
