import { operations } from "store/actionTypePrefixes";
import { AsyncTypes, asAsyncFormTypes } from "utils/extensions";

const types = {
    findSubsidiaryGains: new AsyncTypes("VENDOR_USER_SGS_FIND", operations),
    getSubsidiaryGainsUsers: new AsyncTypes("VENDOR_USER_GET_SG_USERS", operations),
    getSubsidiaryGainsStatuses: new AsyncTypes("VENDOR_USER_GET_SG_STATUSES", operations),
    getServiceConfirmation: new AsyncTypes("VENDOR_USER_GET_SERVICE_CONFIRMATION", operations),
    downloadFile: new AsyncTypes("VENDOR_USER_DOWNLOAD_SG_FILE", operations),
    getVendorRecipients: new AsyncTypes("VENDOR_USER_GET_VENDOR_RECIPIENTS", operations),
    getAccountingPeriods: new AsyncTypes("VENDOR_USER_GET_ACCOUNTING_PERIODS", operations),

    getSubsidiaryGain: new AsyncTypes("VENDOR_USER_SG_GET", operations),
    makeDecision: new AsyncTypes("VENDOR_USER_SG_MAKE_DECISION", operations, [asAsyncFormTypes]),
};

export default types;
