import { FormName } from "redux-form";
import React from "react";

const withFormName = (Component) => {
    return (props) => (
        <FormName>
            {(formName) => (
                <Component
                    {...props}
                    {...formName}
                />
            )}
        </FormName>
    );
};

export default withFormName;
