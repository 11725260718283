import Button from "components/Button/Button";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { common } from "translations";

const DecisionButtons = ({ submitted, onAccept, onReject, acceptText, rejectText, canReject = true, canAccept = true }) => {
    const { t } = useTranslation("common");

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
        >
            <Grid
                item
                xs={6}
            >
                <Button
                    loading={submitted}
                    actionType="decline"
                    type="button"
                    fullWidth
                    onClick={onReject}
                    disabled={!canReject}
                >
                    {rejectText || t(common.reject)}
                </Button>
            </Grid>

            <Grid
                item
                xs={6}
            >
                <Button
                    actionType="accept"
                    type="button"
                    fullWidth
                    loading={submitted}
                    onClick={onAccept}
                    disabled={!canAccept}
                >
                    {acceptText || t(common.accept)}
                </Button>
            </Grid>
        </Grid>
    );
};

export default DecisionButtons;
