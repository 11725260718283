import { SubsidiaryGainServiceConfirmationsField } from "components/Controls/SubsidiaryGainServiceConfirmations";
import { Addable, Erasable, FormField } from "components/Form";
import TextWithLabel from "components/Text/TextWithLabel";
import { FormSection } from "redux-form";
import { getCMS, getDiscount, getPromotion } from "store/vendors/containers/actions";
import { containersSelector } from "store/vendors/containers/selectors";
import { useDebouncedCallback } from "use-debounce";
import { withFormName } from "utils/hoc";
import { useAppLocation, useChange } from "utils/hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import OpenInNew from "@mui/icons-material/OpenInNew";
import { Alert, AlertTitle } from "@mui/lab";
import { Grid, IconButton } from "@mui/material";
import { Layout } from "components";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { validators } from "views/vendors/components/validators";
import ArrayValidationError from "./ArrayValidationError";

const CMS_CONTAINER = "CMS";
const PROMOTION_CONTAINER = "Promotion";
const DISCOUNT_CONTAINER = "Discount";
const containerTypes = [CMS_CONTAINER, PROMOTION_CONTAINER, DISCOUNT_CONTAINER];
const guidRE = /([a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12})/i;

const parseId = (idOrLink) => {
    const match = guidRE.exec(idOrLink);
    return match ? match[0].toLowerCase() : null;
};

const getTypeFromLink = (link) => {
    return link.includes(PROMOTION_CONTAINER.toLowerCase())
        ? PROMOTION_CONTAINER
        : link.includes(DISCOUNT_CONTAINER.toLowerCase())
        ? DISCOUNT_CONTAINER
        : link.includes(CMS_CONTAINER.toLowerCase())
        ? CMS_CONTAINER
        : undefined;
};

const containerUri = (appLocation, type, id) => {
    switch (type) {
        case CMS_CONTAINER:
            return `${appLocation}/cms/cms/form/${id}`;
        case PROMOTION_CONTAINER:
            return `${appLocation}/offers/promotions/form/${id}`;
        case DISCOUNT_CONTAINER:
            return `${appLocation}/offers/discounts/form/${id}`;
        default:
            return null;
    }
};

const getContainer = (type, id) => {
    switch (type) {
        case CMS_CONTAINER:
            return getCMS(id);
        case PROMOTION_CONTAINER:
            return getPromotion(id);
        case DISCOUNT_CONTAINER:
            return getDiscount(id);
        default:
            return null;
    }
};

const SubsidiaryGainContainers = ({
    form,
    fields,
    sectionPrefix,
    containers,
    meta,
    readOnly,
    vendorId = "",
    sgId = "",
    showConfirmations = true,
    sgCreatedOutOfReservation = false,
    ...props
}) => {
    const { cmss, discounts, promotions } = useSelector(containersSelector);
    const { t } = useTranslation("vendor");

    return (
        <>
            <ArrayValidationError {...meta} />
            <Addable
                handleAdd={() => {
                    fields.push({ isNew: true });
                }}
                renderCondition={!readOnly}
                addButtonText={t(vendor.addContainer)}
            >
                {fields.map((field, index) => {
                    const container = containers[index];
                    const containerContent =
                        container.containerType === CMS_CONTAINER
                            ? cmss[container.containerId]
                            : container.containerType === PROMOTION_CONTAINER
                            ? promotions[container.containerId]
                            : container.containerType === DISCOUNT_CONTAINER
                            ? discounts[container.containerId]
                            : undefined;
                    container.valid = !!containerContent && !containerContent.isLoading;
                    return (
                        <Erasable
                            key={field}
                            handleRemove={() => {
                                fields.remove(index);
                            }}
                            renderCondition={!readOnly && !sgCreatedOutOfReservation}
                        >
                            <SubsidiaryGainContainer
                                form={form}
                                field={field}
                                container={container}
                                containerContent={containerContent}
                                readOnly={!container.isNew || readOnly}
                                vendorId={vendorId}
                                sgId={sgId}
                                showConfirmations={showConfirmations}
                                sectionPrefix={sectionPrefix}
                                {...props}
                            />
                        </Erasable>
                    );
                })}
            </Addable>
        </>
    );
};

const SubsidiaryGainContainer = ({
    form,
    field,
    container,
    containerContent,
    readOnly,
    readOnlyConfirmations,
    handleDownloadConfirmation,
    handleGetConfirmation,
    vendorId = "",
    sgId = "",
    showConfirmations = true,
    sectionPrefix,
}) => {
    const { t } = useTranslation("vendor");
    const dispatch = useDispatch();
    const appLocation = useAppLocation();
    const change = useChange(form);

    const filled = !!container.containerType && !!container.containerId;
    const isValidId = filled && parseId(container.containerId) === container.containerId;

    const sectionsNames = container.valid && container.containerType === CMS_CONTAINER && containerContent.sections?.map((s) => s.sectionName);

    const onIdOrLinkChanged = (idOrLink) => {
        if (!idOrLink) return;
        const prefix = sectionPrefix ? sectionPrefix + "." + field : field;

        const id = parseId(idOrLink);
        if (!!id && idOrLink !== id) change(`${prefix}.containerId`, id);

        const type = getTypeFromLink(idOrLink);
        !!type && change(`${prefix}.containerType`, type);
    };
    const debouncedOnIdOrLinkChanged = useDebouncedCallback(onIdOrLinkChanged, 500);
    useEffect(() => {
        debouncedOnIdOrLinkChanged(container.containerId);
    }, [container.containerId, debouncedOnIdOrLinkChanged]);
    useEffect(() => {
        if (isValidId) {
            dispatch(getContainer(container.containerType, container.containerId));
        }
    }, [container.containerId, container.containerType, dispatch, isValidId]);

    return (
        <FormSection name={field}>
            <Grid
                container
                direction="row"
            >
                <FormField
                    validate={validators.required}
                    name="containerId"
                    label={t(vendor.containerIdOrLink)}
                    type="text"
                    readOnly={readOnly}
                />
                <FormField
                    validate={validators.required}
                    name="containerType"
                    label={t(vendor.containerType)}
                    type="autocomplete"
                    items={containerTypes}
                    getOptionLabel={t}
                    readOnly={readOnly}
                />
                <FormField
                    validate={validators.required}
                    hidden={!sectionsNames || sectionsNames.length === 0}
                    name="sectionName"
                    label={t(vendor.containerSectionName)}
                    type="autocomplete"
                    items={sectionsNames}
                    readOnly={readOnly}
                    getOptionLabel={(x) => x}
                />
            </Grid>
            {container.valid && containerContent && (
                <Grid
                    container
                    direction="row"
                >
                    <Grid item>
                        <IconButton
                            readOnly={readOnly}
                            onClick={() => window.open(containerUri(appLocation, container.containerType, container.containerId))}
                        >
                            <OpenInNew />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        {containerContent.containerName && (
                            <Grid item>
                                <TextWithLabel
                                    label={t(vendor.containerName)}
                                    value={containerContent.containerName}
                                />
                            </Grid>
                        )}
                        {containerContent.categoryName && (
                            <Grid item>
                                <TextWithLabel
                                    label={t(vendor.containerCategoryName)}
                                    value={containerContent.categoryName}
                                />
                            </Grid>
                        )}
                        {containerContent.campaignName && (
                            <Grid item>
                                <TextWithLabel
                                    label={t(vendor.containerCampaignName)}
                                    value={containerContent.campaignName}
                                />
                            </Grid>
                        )}
                        {containerContent.name && (
                            <Grid item>
                                <TextWithLabel
                                    label={t(vendor.name)}
                                    value={containerContent.name}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )}

            {filled && !containerContent && (
                <Alert severity="error">
                    <AlertTitle>{t(vendor.attention)}</AlertTitle>
                    {t(vendor.containerNotFound, { type: t(container.containerType) })}
                </Alert>
            )}
            {showConfirmations && (
                <Layout headerText={t(vendor.confirmations)}>
                    <SubsidiaryGainServiceConfirmationsField
                        form={form}
                        formSection={field}
                        name="confirmations"
                        handleDownload={handleDownloadConfirmation}
                        handleGet={handleGetConfirmation}
                        readOnly={readOnlyConfirmations}
                        vendorId={vendorId}
                        sgId={sgId}
                        containerId={container.containerId}
                        sectionName={container.sectionName}
                        error={container.errorMessage}
                    />
                </Layout>
            )}
        </FormSection>
    );
};

export default withFormName(SubsidiaryGainContainers);
