import image from "assets/img/tapas.jpg";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
    background: {
        "position": "absolute",
        "zIndex": "1",
        "height": "100%",
        "width": "100%",
        "display": "block",
        "top": "0",
        "left": "0",
        "backgroundSize": "cover",
        "backgroundPosition": "center center",
        "&:after": {
            position: "absolute",
            zIndex: "3",
            width: "100%",
            height: "100%",
            content: '""',
            display: "block",
            background: "#000",
            opacity: ".8",
        },
    },
});

const Image = () => {
    const { classes } = useStyles();

    return image !== undefined ? (
        <div
            className={classes.background}
            style={{
                backgroundImage: "url(" + image + ")",
            }}
        />
    ) : null;
};

export default Image;
