import { FormField } from "components/Form";
import { renderFieldOrText } from "components/FormHelpers/Controls";
import { brandNamesSelector } from "store/autocomplete";
import { orderBy } from "utils/extensions/arrayExtensions";
import { useCommonTranslation } from "utils/hooks";
import React from "react";
import { useSelector } from "react-redux";

const ContentBrandsAutocomplete = ({ validate, name = "brands", ...props }) => {
    const { t, common } = useCommonTranslation();
    const productsBrands = useSelector(brandNamesSelector);

    return (
        <FormField
            stretch
            component={renderFieldOrText}
            name={name}
            label={t(common.brands)}
            type={"autocomplete"}
            items={orderBy(productsBrands)}
            multiple
            disableCloseOnSelect
            validate={validate}
            {...props}
        />
    );
};

export default ContentBrandsAutocomplete;
