import _, { orderBy as _orderBy } from "lodash";

export function skip(array, count) {
    return array.slice(count);
}

export function take(array, count) {
    return array.slice(0, count);
}

export function firstOrEmpty(array, predicate) {
    return array.find((y) => predicate(y)) || {};
}

export function firstOrDefault(array, predicate, defaultValue) {
    return array.find((y) => predicate(y)) || defaultValue;
}

export function toSelectList(array, translationFunction, namespace) {
    return array.map((x) => ({
        value: x,
        name: translationFunction ? translationFunction(namespace ? `${namespace}:${x}` : x) : x,
    }));
}

export function orderBy(array, orderFunc) {
    return _orderBy(array, orderFunc);
}

export function toDictionary(obj) {
    return _(obj)
        .keys()
        .map((key) => ({
            key: key,
            value: typeof obj[key] === "string" ? obj[key] : JSON.stringify(obj[key]),
        }))
        .value();
}

function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function toObject(array) {
    return _(array).reduce(
        (c, n) => ({
            ...c,
            [String(n.key).trim()]: isJson(n.value) ? JSON.parse(n.value) : n.value,
        }),
        {}
    );
}

export const importAsyncMessages = {
    requestMessage: "Importowanie...",
    successMessage: "Zaimportowano pomyślnie!",
    failureMessage: "Błąd przy imporcie.",
};

export const exportAsyncMessages = {
    requestMessage: "Eksportowanie...",
    successMessage: "Wyeksportowano pomyślnie!",
    failureMessage: "Błąd przy eksporcie.",
};

const mapTypes = (array) => {
    if (array.length !== 3) {
        throw Error("Async form types requires 3 elements");
    }

    return array.every((t) => typeof t === "string")
        ? array.map((val) => ({ type: val, meta: {} }))
        : array.map((type) => ({ ...type, meta: type.meta ?? {} }));
};

export function asAsyncFormTypes(array, options = {}) {
    const { requestMessage, successMessage, failureMessage } = options;
    const types = mapTypes(array);

    return [
        {
            ...types[0],
            meta: {
                ...types[0].meta,
                formSubmit: "REQUEST",
                message: requestMessage,
            },
        },
        {
            ...types[1],
            meta: {
                ...types[1].meta,
                formSubmit: "SUCCESS",
                message: successMessage,
            },
        },
        {
            ...types[2],
            meta: {
                ...types[2].meta,
                formSubmit: "FAILURE",
                message: failureMessage,
            },
        },
    ];
}

export function asSilientError(array) {
    const types = mapTypes(array);
    return [
        { ...types[0], meta: { ...types[0]?.meta } },
        { ...types[1], meta: { ...types[1]?.meta } },
        { ...types[2], meta: { ...types[2]?.meta, hideError: true } },
    ];
}

export function asCleanFormDataTypes(array) {
    return withMeta({ clearEmptyFields: true })(array);
}

export function asIgnoreInProgress(array) {
    return withMeta({ ignoreInProgress: true })(array);
}

export const withMeta = (meta) => (array) => {
    const types = mapTypes(array);
    return types.map((type) => ({ ...type, meta: { ...type.meta, ...meta } }));
};
