import { Layout } from "components/Grid";
import { FieldArray } from "redux-form";
import { makeStyles } from "tss-react/mui";
import { withFormName } from "utils/hoc";
import { useCommonTranslation, useFormValueSelector } from "utils/hooks";
import { Divider, Typography } from "@mui/material";

const useStyles = makeStyles()((theme) => ({
    added: {
        color: theme.palette.success.main,
    },
    addedDivider: {
        background: theme.palette.success.main,
    },
    removed: {
        color: theme.palette.error.main,
    },
    removedDivider: {
        background: theme.palette.error.main,
    },
    changed: {
        color: theme.palette.warning.main,
    },
    changedDivider: {
        background: theme.palette.warning.main,
    },
    noDiff: {
        color: theme.palette.common.black,
    },
    noDiffDivider: {
        background: theme.palette.common.black,
    },
}));

const DiffFieldArray = ({ form, sectionPrefix, name, hideDefaultHeader, header, ...props }) => {
    const { classes } = useStyles();
    const { t, common } = useCommonTranslation();
    const { removed = [], added = [], changed = [], noDiff = [] } = useFormValueSelector(form, sectionPrefix ? `${sectionPrefix}.${name}` : name);

    const component = (
        <>
            {removed.length > 0 && (
                <>
                    <Typography
                        className={classes.removed}
                        variant="button"
                        hidden={Boolean(sectionPrefix)}
                    >
                        {t(common.removed)}
                    </Typography>
                    <Divider
                        className={classes.removedDivider}
                        hidden={Boolean(sectionPrefix)}
                    />
                    <FieldArray
                        {...props}
                        name={`${name}.removed`}
                        hideHeader={hideDefaultHeader}
                    />
                </>
            )}
            {added.length > 0 && (
                <>
                    <Typography
                        className={classes.added}
                        variant="button"
                        hidden={Boolean(sectionPrefix)}
                    >
                        {t(common.added)}
                    </Typography>
                    <Divider
                        className={classes.addedDivider}
                        hidden={Boolean(sectionPrefix)}
                    />
                    <FieldArray
                        {...props}
                        name={`${name}.added`}
                        hideHeader={hideDefaultHeader}
                    />
                </>
            )}
            {changed.length > 0 && (
                <>
                    <Typography
                        className={classes.changed}
                        variant="button"
                        hidden={Boolean(sectionPrefix)}
                    >
                        {t(common.changed)}
                    </Typography>
                    <Divider
                        className={classes.changedDivider}
                        hidden={Boolean(sectionPrefix)}
                    />
                    <FieldArray
                        {...props}
                        name={`${name}.changed`}
                        hideHeader={hideDefaultHeader}
                    />
                </>
            )}
            {noDiff.length > 0 && (
                <>
                    <Typography
                        className={classes.noDiff}
                        variant="button"
                        hidden={Boolean(sectionPrefix)}
                    >
                        {t(common.noDiff)}
                    </Typography>
                    <Divider
                        className={classes.noDiffDivider}
                        hidden={Boolean(sectionPrefix)}
                    />
                    <FieldArray
                        {...props}
                        name={`${name}.noDiff`}
                        hideHeader={hideDefaultHeader}
                    />
                </>
            )}
        </>
    );

    return hideDefaultHeader ? (
        <Layout
            isEnlarged
            headerText={header}
        >
            {component}
        </Layout>
    ) : (
        component
    );
};

export default withFormName(DiffFieldArray);
