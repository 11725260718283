import types from "./types";

const initialState = {
    isGenerating: false,
};

export const subsidiaryGain = (state = initialState, { type }) => {
    switch (type) {
        case types.generateSubsidiaryGainReport.request:
            return { ...state, isGenerating: true };
        case types.generateSubsidiaryGainReport.success:
            return { ...state, isGenerating: false };
        case types.generateSubsidiaryGainReport.failure:
            return { ...state, isGenerating: false };
        default:
            return state;
    }
};
