import { types } from "./types";

const initialState = {};

export const form = (state = initialState, { type, payload, meta }) => {
    switch (type) {
        case types.initializeProductRestrictionForm:
            return initialState;
        case types.getProductRestriction.request:
            return { ...initialState, isLoading: true };
        case types.getProductRestriction.success:
            return {
                ...state,
                ...payload,
                id: meta.isCopy ? "" : payload.id,
                isLoading: false,
            };
        case types.getProductRestriction.failure:
            return { ...payload, isLoading: false };
        case types.createOrUpdateProductRestriction.request:
            return { ...state, isSubbmitRequested: true };
        case types.createOrUpdateProductRestriction.success:
        case types.createOrUpdateProductRestriction.failure:
            return { ...state, isSubbmitRequested: false };
        default:
            return state;
    }
};
