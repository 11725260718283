import Layout from "components/Grid/Layout";
import Preloader from "components/Preloader/Preloader";
import TabLabel from "components/Tabs/TabLabel";
import Tabs from "components/Tabs/Tabs";
import roles, { SupportDepartmentRoles } from "consts/roles";
import ordersStatuses from "resource/ordersStatuses.json";
import { actions as orderActions } from "store/order/operationsOrder/action";
import { useParams } from "utils/hooks";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { useUser } from "context";
import { isEmpty } from "lodash";
import { bindActionCreators } from "redux";
import { orders } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { allCustomerRoles } from "routing-ts/Routes/CustomerRoles";
import OperationsOrderTabActions from "./order/OperationsOrderTabActions";
import OrderContainer from "./order/OrderContainer";
import ProductList from "./order/ProductList";

const OperationsOrder = ({ getCompleteOrder, getComplaints, order, getOrderDocument }) => {
    const { customerId, orderNumber, complaintNumber } = useParams();
    const { t } = useTranslation("orders");
    React.useEffect(() => {
        getCompleteOrder(customerId, orderNumber, complaintNumber);
    }, [getCompleteOrder, getComplaints, customerId, orderNumber, complaintNumber]);
    const tabLabels = [new TabLabel(t(orders.orderDataTab), allCustomerRoles)];
    const { state } = useLocation();
    const { tabIndex = 0 } = state || {};
    const { productLines, orderDocuments } = order;
    const { invoiceFrisco, invoiceF24 } = orderDocuments || {};
    const containsInvoice = !isEmpty(invoiceFrisco) || !isEmpty(invoiceF24);
    const { isInRole, isAdmin } = useUser();
    const status = order.status ? ordersStatuses.find((o) => o.value === order.status)?.name : "";

    return (
        <Fragment>
            <Layout
                main
                navigationProps={{
                    backProps: {
                        path: `/customers/${customerId}`,
                        state: {
                            tabIndex: isInRole(...SupportDepartmentRoles) && !isAdmin ? 0 : 1,
                        },
                    },
                }}
                headerText={`${t(orders.orderWithId, {
                    orderId: order.orderId,
                })} ${status}`}
                actionComponent={
                    <OperationsOrderTabActions
                        roles={[roles.Administrator]}
                        isAdmin={isAdmin}
                        order={order}
                        orderDocuments={orderDocuments}
                        orderNumber={orderNumber}
                        getOrderDocument={getOrderDocument}
                        customerId={customerId}
                    />
                }
            >
                {order.isLoading ? (
                    <Preloader />
                ) : (
                    <Tabs
                        tabIndex={tabIndex}
                        tableLabels={tabLabels}
                    >
                        <OrderContainer order={order} />
                    </Tabs>
                )}
            </Layout>
            <ProductList
                isLoading={order.isLoading}
                lines={productLines}
                containsInvoice={containsInvoice}
            />
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    const { operationsOrder } = state;
    return { order: operationsOrder };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ ...orderActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OperationsOrder);
