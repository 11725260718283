import React from "react";
import DOMPurify from "dompurify";

export const HTMLRenderer = ({ html }) => {
    var cleanHtml = DOMPurify.sanitize(html, { USE_PROFILES: { html: true } });
    return (
        <div
            className="content"
            dangerouslySetInnerHTML={{ __html: cleanHtml }}
        ></div>
    );
};
