import React from "react";
import { AppBar, Dialog, Slide, Toolbar, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { FullScreenDialogProps } from "dialogs";
import { IconButton } from "components-ts/controls";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return (
        <Slide
            direction="up"
            ref={ref}
            {...props}
        />
    );
});

const FullScreenDialog = ({ children, isOpen, onClose, dialogTitle, appBarButtons }: FullScreenDialogProps): JSX.Element => {
    return (
        <Dialog
            fullScreen
            open={isOpen}
            onClose={onClose}
            TransitionComponent={Transition}
        >
            <AppBar component="nav">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        onClick={onClose}
                        aria-label="Close"
                        icon="close"
                    />

                    <Typography
                        variant="h6"
                        color="inherit"
                    >
                        {dialogTitle}
                    </Typography>

                    {appBarButtons && appBarButtons.length > 0 && appBarButtons.map((b) => b)}
                </Toolbar>
            </AppBar>

            <div style={{ marginTop: "70px" }}>{children}</div>
        </Dialog>
    );
};

export default FullScreenDialog;
