import Layout from "components/Grid/Layout";
import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import tableActionTypes from "components/Table/SimpleTable/TableActions/tableActionTypes";
import { actions as alertActions } from "store/orderAlerts/action";
import { actions } from "store/orderAlerts/orderAlertsList/action";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useAppLocation } from "utils/hooks";
import { splitOrderId } from "utils/splitIds";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { orders } from "translations";
import { usePush } from "utils-ts/hooks";
import { useTranslation } from "utils-ts/hooks";
import FlagIcon from "../../customer/customerList/FlagIcon";
import OrderAlertListRow from "./OrderAlertListRow";

const OrderAlertList = ({ list, getOrderAlerts, selectOrderAlert, dismissAlert }) => {
    const { isLoading, items, pageIndex, totalCount, pageSize } = list;
    const appLocation = useAppLocation();
    const { push } = usePush();

    const navigateToAlert = async (orderId, orderAlert) => {
        const { customerId, orderNumber } = splitOrderId(orderId);
        await selectOrderAlert(orderAlert);
        await push(`orderAlerts/${customerId}/${orderNumber}`);
    };

    React.useEffect(() => {
        getOrderAlerts({ pageIndex, pageSize });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getOrderAlerts]);
    const dissmiss = async (id) => {
        await dismissAlert(id);
        getOrderAlerts({ pageIndex, pageSize });
    };
    const { t } = useTranslation("orders");
    const columns = [
        {
            key: "id",
            value: orders.orderId,
            type: formatTypes.action,
            action: (id) => {
                const { customerId, orderNumber } = splitOrderId(id);
                return `${appLocation}/customers/${customerId}/orders/${orderNumber}`;
            },
            actionType: tableActionTypes.link,
            openInNewTab: true,
        },
        { key: "icons", value: orders.accountType, type: formatTypes.icon },
        {
            key: "customer",
            value: orders.customer,
            type: formatTypes.action,
            action: (id) => {
                const { customerId } = splitOrderId(id);
                return `${appLocation}/customers/${customerId}`;
            },
            actionType: tableActionTypes.link,
            openInNewTab: true,
        },
        {
            key: "isBusinessAccount",
            value: orders.isBusinessAccount,
            type: formatTypes.boolean,
        },
        {
            key: "deliveryWindow",
            value: orders.deliveryWindow,
            type: formatTypes.deliveryWindow,
            options: { includeDeliveryMethod: true },
        },
        { key: "reason", value: orders.reason, type: undefined },
        {
            key: "updatedAt",
            value: orders.notificationTime,
            type: formatTypes.time,
            options: { timeFormat: timePrecisonFormats.seconds },
        },
        {
            key: "menu",
            value: "",
            type: formatTypes.action,
            actionType: tableActionTypes.menu,
        },
    ].map((col) => ({ ...col, value: t(col.value) }));

    return (
        <Layout
            main
            headerText={t(orders.orderAlerts)}
        >
            <SimpleTable
                isLoading={isLoading}
                columns={columns}
                pagination
                stripped
                hover
                paginationProps={{
                    pageIndex,
                    totalCount,
                    pageSize,
                    onPageChange: (pageIndex) => getOrderAlerts({ pageIndex, pageSize }),
                    onRowsPerPageChange: (pageSize) => getOrderAlerts({ pageIndex, pageSize }),
                }}
            >
                {items
                    .map((order) => ({
                        ...order,
                        icons: order.customer ? <FlagIcon customer={order.customer} /> : undefined,
                    }))
                    .map((x) => (
                        <OrderAlertListRow
                            t={t}
                            key={x.id}
                            columns={columns}
                            item={x}
                            navigateToAlert={navigateToAlert}
                            dismissAlert={dissmiss}
                        />
                    ))}
            </SimpleTable>
        </Layout>
    );
};

const mapStateToProps = (state) => {
    const {
        orderAlerts: { list },
    } = state;
    return { list };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ ...actions, ...alertActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OrderAlertList);
