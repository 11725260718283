import { createSelector } from "reselect";

const root = (state) => ({
    dynamicSegments: state.dynamicSegments,
});

export const dynamicSegmentListSelector = createSelector(root, (root) => {
    return root.dynamicSegments.list;
});

export const dynamicSegmentFormSelector = createSelector(root, (root) => {
    return root.dynamicSegments.form;
});
