export function decimalCount(value) {
    const parsed = parseFloat(value);
    if (Math.floor(parsed.valueOf()) === parsed.valueOf()) return 0;
    return value.toString().split(".")[1].length || 0;
}

export function isInteger(value) {
    const number = +value;
    return parseInt(value, 10) === number;
}

export function roundDecimal(value, decimalPlaces) {
    const placesFactor = Math.pow(10, decimalPlaces);
    return Math.round((value + Number.EPSILON) * placesFactor) / placesFactor;
}
