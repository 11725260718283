export class AsyncTypes {
    constructor(actionName, actionPrefix, rsaaDecorators = []) {
        const actionBaseValue = createAction(actionName, actionPrefix);
        const actionTypes = ["REQUEST", "SUCCESS", "FAILURE"].map((t) => `${actionBaseValue}_${t}`);

        const [request, success, failure] = actionTypes;

        this.request = request;
        this.success = success;
        this.failure = failure;
        this.all = rsaaDecorators.reduce((value, fun) => fun(value), actionTypes);
    }
}

export class PagedAsyncTypes extends AsyncTypes {
    constructor(actionName, actionPrefix) {
        super(`${actionName}_PAGED`, actionPrefix);
    }
}

export const createAction = (actionName, actionPrefix) => `${actionPrefix}/${actionName}`;
