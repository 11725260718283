import { useState } from "react";
import { TextField, createFilterOptions } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { common } from "translations";
import { TreeItem, TreePickerProps } from "control-types";
import { useTranslation } from "utils-ts/hooks";
import { TextWithLabel } from "components-ts/text";
import FormHelperText from "./FormHelperText";

const TreePicker: React.FC<TreePickerProps> = ({ label, items, onChange: parentOnChange, value, error, readOnly }) => {
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState("");
    const [level, setLevel] = useState<number>(value.length);
    const [parents, setParents] = useState<string[]>(value);
    const [isLast, setIsLast] = useState<boolean>(false);
    const [open, isOpen] = useState<boolean>(false);

    const extendedLabel =
        parents.length > 0
            ? `${label}: ${inputValue === parents[parents.length - 1] ? parents.slice(0, -1).join(" -> ") : parents.join(" -> ")}`
            : label;

    const getLeaf = (items: TreeItem[], lvl: number): TreeItem[] => {
        if (items.some((i) => i.level == level)) {
            return items;
        }
        const parent = items.find((i) => i.name == parents[lvl]);
        if (parent?.childrens === undefined) {
            return items;
        } else {
            return getLeaf(
                parent?.childrens?.map((c) => {
                    c.parents?.push(parent.name);
                    return c;
                }) || [],
                lvl + 1
            );
        }
    };

    if (readOnly) {
        return (
            <TextWithLabel
                value={value}
                label={label}
                items={items.map((i) => ({
                    value: i.name,
                    name: i.name,
                    disabled: false,
                }))}
            />
        );
    }

    return (
        <Autocomplete
            options={[
                level > 0
                    ? { name: `← Cofnij do ${(isLast ? parents[parents.length - 3] : parents[parents.length - 2]) ?? "początku"}`, key: "back" }
                    : undefined,
                ...getLeaf(items, 0).map((value, index) => {
                    return { ...value, key: index };
                }),
            ].filter((o) => o != undefined)}
            fullWidth={true}
            noOptionsText={t(common.noOptions)}
            clearText={t(common.clearText)}
            disableCloseOnSelect={true}
            value={items && items.length >= 0 ? value : null}
            inputValue={inputValue}
            onInputChange={(event, newInputValue, reason) => {
                if (reason == "clear") {
                    setParents([]);
                    setLevel(0);
                    parentOnChange([]);
                    setInputValue("");
                    setIsLast(false);
                } else {
                    setInputValue(newInputValue);
                }
            }}
            onChange={(_event, value) => {
                if (value != undefined && value != null) {
                    if (value.key == "back") {
                        let itemsToBackCount = 1;
                        if (isLast) {
                            itemsToBackCount = 2;
                        }
                        const updatedParents = parents.slice();
                        updatedParents.splice(-itemsToBackCount);
                        setParents(updatedParents);
                        setLevel(parents.length - itemsToBackCount);
                        parentOnChange(updatedParents);
                        setIsLast(false);
                    } else {
                        if (isLast) {
                            const updatedParents = parents.slice();
                            updatedParents.splice(-1);
                            setParents([...updatedParents, value.name]);
                            setLevel(parents.length);
                            parentOnChange([...updatedParents, value.name]);
                        } else {
                            setParents([...parents, value.name]);
                            setLevel(parents.length + 1);
                            parentOnChange([...parents, value.name]);
                        }

                        if (!value.childrens || value.childrens.length < 1) {
                            setIsLast(true);
                            isOpen(false);
                        } else {
                            setIsLast(false);
                        }
                    }
                } else if (value == undefined && parents.length == 0) {
                    setParents([]);
                    setLevel(0);
                    parentOnChange(parents);
                    setIsLast(false);
                } else {
                    parentOnChange([]);
                    setIsLast(false);
                }
            }}
            open={open}
            onOpen={() => isOpen(true)}
            onClose={() => {
                setInputValue("");
                isOpen(false);
            }}
            filterOptions={(options, state) => {
                if (state.inputValue == "" || state.inputValue == parents[parents.length - 1]) {
                    return options;
                }
                return createFilterOptions()(options, state);
            }}
            renderInput={(params) => {
                return (
                    <>
                        <TextField
                            label={extendedLabel}
                            variant="standard"
                            fullWidth
                            placeholder={label}
                            error={error?.hasError}
                            inputProps={params.inputProps}
                            InputProps={params.InputProps}
                        />
                        <FormHelperText error={error} />
                    </>
                );
            }}
            getOptionLabel={(option) => {
                if (Array.isArray(option)) {
                    if (inputValue == "") {
                        return "";
                    }
                    return option[option.length - 1] ?? "";
                }
                return option.name ?? "";
            }}
        />
    );
};

export default TreePicker;
