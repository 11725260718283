import Cell from "components/Table/SimpleTable/Cell";
import Row from "components/Table/SimpleTable/Row";
import { withStyles } from "tss-react/mui";
import { formatValue } from "utils/formating";
import React from "react";
import classnames from "classnames";
import _ from "lodash";

const calculateSummary = (items) =>
    _(items).reduce(
        (c, n) => {
            return {
                reservationLimit: c.reservationLimit + n.reservationLimit,
                newLimit: c.newLimit + n.newLimit,
                vipLimit: c.vipLimit + n.vipLimit,
                limitTotal: c.limitTotal + n.limitTotal,
                reservationCount: c.reservationCount + n.reservationCount,
                newReservationCount: c.newReservationCount + n.newReservationCount,
                vipReservationCount: c.vipReservationCount + n.vipReservationCount,
                reservationTotal: c.reservationTotal + n.reservationTotal,
                orderCount: c.orderCount + n.orderCount,
            };
        },
        {
            reservationLimit: 0,
            newLimit: 0,
            vipLimit: 0,
            limitTotal: 0,
            reservationCount: 0,
            newReservationCount: 0,
            vipReservationCount: 0,
            reservationTotal: 0,
            orderCount: 0,
        }
    );

const SummaryRow = withStyles(
    ({ classes, columns, index, term }) => {
        const summary = calculateSummary(term);
        return (
            <Row key={`summary-${index}`}>
                {columns.map(({ key, type, options }, index) => {
                    return (
                        <Cell
                            key={index}
                            className={classnames(
                                classes.summary,
                                { [classes.borderLeft]: index === 0 },
                                {
                                    [classes.borderRight]: index + 1 === columns.length,
                                }
                            )}
                        >
                            <span className={classes.summaryCellText}>{formatValue(summary[key], type, options)}</span>
                        </Cell>
                    );
                })}
            </Row>
        );
    },
    (theme) => ({
        summaryCellText: { color: "#fff" },
        summary: { backgroundColor: theme.palette.secondary.main },
        borderLeft: { borderRadius: "0px 0px 0px 7px" },
        borderRight: { borderRadius: "0px 0px 7px 0px" },
    })
);

export default SummaryRow;
