import roles, { AccountantDepartmentRoles, DebtCoordinatorRoles } from "consts/roles";
import { actions } from "store/customers/customer/action";
import { customerMembershipSelector } from "store/customers/customer/customerMembership";
import { useParams } from "utils/hooks";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { createSelector } from "reselect";
import { baseCustomerRoles, baseWithEcommerce } from "routing-ts/Routes/CustomerRoles";
import Memos from "../memos/Memos";
import CustomerActions from "./CustomerActions";
import CustomerGridWrapper from "./CustomerGridWrapper";

const root = (state) => state.customer;

const customerSelector = createSelector(root, (o) => o);

const CustomerInfo = () => {
    const { customerId } = useParams();
    const dispatch = useDispatch();
    const {
        customerInfo: { billingAddress, segments, ...info },
        customerBalance,
        customerMemos,
    } = useSelector(customerSelector);

    const customerMembership = useSelector(customerMembershipSelector);

    useEffect(() => {
        dispatch(actions.getCustomer(customerId));
    }, [customerId, dispatch]);

    return (
        <>
            <CustomerActions
                customerId={customerId}
                email={info.email}
                isVerificationRequired={info.isVerificationRequired}
                roles={[...baseWithEcommerce, ...AccountantDepartmentRoles]}
            />
            <CustomerGridWrapper
                customerId={customerId}
                customerInfo={info}
                customerBalance={customerBalance}
                customerMembership={customerMembership}
                billingAddress={billingAddress}
                segments={segments}
            />
            <Grid
                item
                xs={12}
            >
                <Memos
                    customerId={customerId}
                    customerMemos={customerMemos}
                    roles={[...baseCustomerRoles, ...DebtCoordinatorRoles, roles.Auditor]}
                />
            </Grid>
        </>
    );
};

export default CustomerInfo;
