import { Button } from "components/Button";
import { Form, FormSection, reduxForm } from "redux-form";
import { getAnalyticGroups, productAnalyticGroupsSelector } from "store/autocomplete";
import { actions } from "store/cache/products/actions";
import { createVendorSummaryCorrection, updateVendorSummary } from "store/vendors/turnovers";
import { useCommonTranslation, useParams } from "utils/hooks";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { Layout } from "components";
import { useUser } from "context";
import { compose } from "redux";
import CorrectionsTable from "views/vendors/components/turnover/CorrectionsTable";
import { prodcutsSelector } from "../selectors";
import DetailName from "./DetailName";
import Details from "./Details";

const mapHeader = (key) => {
    switch (key) {
        case "ag":
            return `Grupy analityczne`;
        case "b":
            return `Marki`;
        case "p":
            return `Produkty`;
        case "c":
            return `Kategorie`;
        default:
            return key;
    }
};

const DetailsForm = ({ handleSubmit, pristine, initialValues, details, isPeriodOpen, canEdit }) => {
    const { t, common } = useCommonTranslation("vendor");
    const dispatch = useDispatch();
    const { profile } = useUser();
    const { vendorId, id } = useParams();
    const [turnoverDetails, setTurnoverDetails] = useState({});
    const products = useSelector(prodcutsSelector) || [];
    const analyticGroups = useSelector(productAnalyticGroupsSelector) || [];

    useEffect(() => {
        turnoverDetails.p && dispatch(actions.getProducts([...turnoverDetails.p.map((d) => d.id)]));
        turnoverDetails.ag && dispatch(getAnalyticGroups());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [turnoverDetails]);

    useEffect(() => {
        let detailsGrouped = details
            .map((d) => {
                return {
                    group: d.key.split(/_(.+)/)[0],
                    id: d.key.split(/_(.+)/)[1],
                    ...d,
                };
            })
            .sort((a, b) => (a.group > b.group ? 1 : -1))
            .reduce((turnoverDetail, detail) => {
                const { group } = detail;
                turnoverDetail[group] = turnoverDetail[group] ?? [];
                turnoverDetail[group].push(detail);
                return turnoverDetail;
            }, {});
        setTurnoverDetails(detailsGrouped);
    }, [details]);

    return Object.keys(turnoverDetails).length ? (
        <Paper style={{ padding: 20, marginBottom: 10 }}>
            <Form
                onSubmit={handleSubmit((values) => {
                    let dirty_fields_only = Object.values(values)
                        .filter((value) => !(value.vendorSummary === initialValues[value.key].vendorSummary))
                        .map((o) => {
                            return { ...o, vendorSummary: o.vendorSummary.split(" ").join("") };
                        });
                    if (isPeriodOpen) {
                        dispatch(
                            updateVendorSummary(vendorId, id, {
                                details: dirty_fields_only,
                            })
                        );
                    } else {
                        dispatch(
                            createVendorSummaryCorrection(vendorId, id, {
                                correctedByEmail: profile.email,
                                correctedByUserId: profile.sub,
                                details: dirty_fields_only,
                            })
                        );
                    }
                })}
            >
                {Object.entries(turnoverDetails).map((group) => (
                    <Layout
                        key={group[0]}
                        useCollapse
                        isCollapsed={false}
                        headerText={mapHeader(group[0])}
                    >
                        {group[1].map((d, index) => (
                            <Paper
                                key={index}
                                style={{ marginBottom: 15, marginTop: 5 }}
                            >
                                <Table aria-label="simple table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <DetailName
                                                    d={d}
                                                    products={products}
                                                    analyticGroups={analyticGroups}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <FormSection name={d.key}>
                                                    <Details
                                                        detail={d}
                                                        canEdit={canEdit}
                                                    />
                                                </FormSection>
                                                <CorrectionsTable
                                                    corrections={d.corrections}
                                                    detailKey={d.key}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Paper>
                        ))}
                    </Layout>
                ))}
                <Button
                    type="submit"
                    actionType="accept"
                    variant="contained"
                    fullWidth={true}
                    disabled={pristine}
                >
                    {t(common.save)}
                </Button>
            </Form>
        </Paper>
    ) : (
        <></>
    );
};

export default compose(
    reduxForm({
        enableReinitialize: true,
        form: `turnover-form`,
    })
)((props) => {
    return <DetailsForm {...props} />;
});
