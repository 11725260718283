import { RSAA } from "redux-api-middleware";
import { operations } from "store/actionTypePrefixes";

const DELIVERY_WINDOW_GET = `${operations}/DELIVERY_WINDOW_GET`;
const DELIVERY_WINDOW_GET_SUCCESS = `${operations}/DELIVERY_WINDOW_GET_SUCCESS`;
const DELIVERY_WINDOW_GET_FAILURE = `${operations}/DELIVERY_WINDOW_GET_FAILURE`;

export const types = {
    DELIVERY_WINDOW_GET,
    DELIVERY_WINDOW_GET_SUCCESS,
    DELIVERY_WINDOW_GET_FAILURE,
};

const getWindows =
    ({ userId, date, deliveryMethod, fullName, phoneNumber, shippingAddress, productsIds }) =>
    (dispatch) => {
        const body = JSON.stringify({
            fullName,
            phoneNumber,
            shippingAddress,
            poducts: productsIds,
        });
        const warehouse = "WAW";
        return dispatch({
            [RSAA]: {
                endpoint: `api/delivery-windows-proxy/windows/${warehouse}/${userId}/${date}/${deliveryMethod}`,
                headers: { "Content-Type": "application/json" },
                method: "POST",
                types: [DELIVERY_WINDOW_GET, DELIVERY_WINDOW_GET_SUCCESS, DELIVERY_WINDOW_GET_FAILURE],
                body: body,
            },
        });
    };

export const actions = {
    getWindows,
};
