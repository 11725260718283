import { DecisionButtons } from "components/Button";
import roles from "consts/roles";
import { getVendorOperation, makeVendorOperationDecision } from "store/vendors/vendorOperation";
import { useParams } from "utils/hooks";
import { useDispatch } from "react-redux";
import { useUser } from "context";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";

const OperationDecision = ({ state }) => {
    const { isInRoleOrAdmin } = useUser();
    const { t } = useTranslation("vendor");
    const dispatch = useDispatch();
    const { operationId } = useParams();

    return state === "AuditRequested" && isInRoleOrAdmin(roles.Auditor) ? (
        <DecisionButtons
            onAccept={async () => {
                await dispatch(makeVendorOperationDecision(operationId, { isPositive: true }));
                dispatch(getVendorOperation(operationId));
            }}
            onReject={async () => {
                await dispatch(makeVendorOperationDecision(operationId, { isPositive: false }));
                dispatch(getVendorOperation(operationId));
            }}
            acceptText={t(vendor.acceptChanges)}
            rejectText={t(vendor.rejectChanges)}
        />
    ) : (
        <></>
    );
};

export default OperationDecision;
