import {
    CartContextPredicate,
    OrdersRequirement,
    PersonalTargetRequirement,
    TotalRequirement,
    UserPredicate,
} from "Commerce-Pricing";
import {
    DeliveryPredicate,
    SegmentPredicate,
} from "Commerce-Shared";
import { ExtendedValidator } from "../extendedValidator";
import { DateLeadPredicateValidator } from "./dateLeadPredicateValidator";
import { DatePredicateValidator } from "./datePredicateValidator";
import { PostCodePredicateValidator } from "./postCodePredicateValidator";
import { segmentValidator } from "./segmentValidator";

const datePredicateValidator =
    new DatePredicateValidator();
const dateLeadPredicateValidator =
    new DateLeadPredicateValidator();

class OrdersRequirementValidator extends ExtendedValidator<OrdersRequirement> {
    constructor(required?: boolean) {
        super();

        if (required) {
            this.atLeastOneOfFieldNotEmpty([
                "placedOrderCountFrom",
                "placedOrderCountTo",
                "placedOrderCountModulo",
                "placedOrderCountModuloRemainder",
                "invoicedOrderCountFrom",
                "invoicedOrderCountTo",
                "invoicedOrderCountModulo",
                "invoicedOrderCountModuloRemainder",
                "membershipOrderCountFrom",
                "membershipOrderCountTo",
                "membershipOrderCountModulo",
                "membershipOrderCountModuloRemainder",
                "lastDeliveryDaysAgoFrom",
                "lastDeliveryDaysAgoTo",
            ]);
        }

        this.ruleFor("placedOrderCountFrom")
            .lessThanOrEqualTo(
                "placedOrderCountTo"
            )
            .whenNotEmpty("placedOrderCountTo")
            .greaterThanOrEqualTo(0)
            .whenNotEmpty(
                "placedOrderCountFrom",
                "AppliesToAllValidators"
            );

        this.ruleFor("placedOrderCountTo")
            .greaterThanOrEqualTo(
                "placedOrderCountFrom"
            )
            .whenNotEmpty("placedOrderCountFrom")
            .greaterThanOrEqualTo(0)
            .whenNotEmpty(
                "placedOrderCountTo",
                "AppliesToAllValidators"
            );

        this.ruleFor("placedOrderCountModulo")
            .notNull()
            .whenNotEmpty(
                "placedOrderCountModuloRemainder"
            )
            .greaterThan(1)
            .whenNotEmpty();

        this.ruleFor(
            "placedOrderCountModuloRemainder"
        )
            .notNull()
            .lessThan("placedOrderCountModulo")
            .whenNotEmpty(
                "placedOrderCountModulo",
                "AppliesToAllValidators"
            );

        this.ruleFor("invoicedOrderCountFrom")
            .lessThanOrEqualTo(
                "invoicedOrderCountTo"
            )
            .whenNotEmpty("invoicedOrderCountTo")
            .greaterThanOrEqualTo(0)
            .whenNotEmpty(
                "invoicedOrderCountFrom",
                "AppliesToAllValidators"
            );

        this.ruleFor("invoicedOrderCountTo")
            .greaterThanOrEqualTo(
                "invoicedOrderCountFrom"
            )
            .whenNotEmpty(
                "invoicedOrderCountFrom"
            )
            .greaterThanOrEqualTo(0)
            .whenNotEmpty(
                "invoicedOrderCountTo",
                "AppliesToAllValidators"
            );

        this.ruleFor("invoicedOrderCountModulo")
            .notNull()
            .whenNotEmpty(
                "invoicedOrderCountModuloRemainder"
            )
            .greaterThan(1)
            .whenNotEmpty();

        this.ruleFor(
            "invoicedOrderCountModuloRemainder"
        )
            .notNull()
            .lessThan("invoicedOrderCountModulo")
            .whenNotEmpty(
                "invoicedOrderCountModulo",
                "AppliesToAllValidators"
            );

        this.ruleFor("membershipOrderCountFrom")
            .greaterThanOrEqualTo(0)
            .whenNotEmpty(
                "membershipOrderCountFrom",
                "AppliesToAllValidators"
            )
            .lessThanOrEqualTo(
                "membershipOrderCountTo"
            )
            .whenNotEmpty(
                "membershipOrderCountTo"
            );

        this.ruleFor("membershipOrderCountTo")
            .greaterThanOrEqualTo(0)
            .whenNotEmpty(
                "membershipOrderCountTo",
                "AppliesToAllValidators"
            )
            .greaterThanOrEqualTo(
                "membershipOrderCountFrom"
            )
            .whenNotEmpty(
                "membershipOrderCountFrom"
            );

        this.ruleFor("membershipOrderCountModulo")
            .notNull()
            .whenNotEmpty(
                "membershipOrderCountModuloRemainder"
            )
            .greaterThan(1)
            .whenNotEmpty();

        this.ruleFor(
            "membershipOrderCountModuloRemainder"
        )
            .notNull()
            .whenNotEmpty()
            .lessThan(
                "membershipOrderCountModulo"
            )
            .whenNotEmpty(
                "membershipOrderCountModulo"
            );

        this.ruleFor("lastDeliveryDaysAgoFrom")
            .greaterThanOrEqualTo(0)
            .whenNotEmpty()
            .lessThanOrEqualTo(
                "lastDeliveryDaysAgoTo"
            )
            .whenNotEmpty(
                "lastDeliveryDaysAgoTo"
            );

        this.ruleFor("lastDeliveryDaysAgoTo")
            .greaterThan(0)
            .whenNotEmpty()
            .greaterThanOrEqualTo(
                "lastDeliveryDaysAgoFrom"
            )
            .whenNotEmpty(
                "lastDeliveryDaysAgoFrom"
            );
    }
}

class PersonalTargetRequirementValidator extends ExtendedValidator<PersonalTargetRequirement> {
    constructor(required?: boolean) {
        super();

        if (required) {
            this.atLeastOneOfFieldNotEmpty([
                "targetAmountFrom",
                "targetAmountTo",
            ]);
        }

        this.ruleFor("targetAmountFrom")
            .greaterThanOrEqualTo(0)
            .scalePrecision(2, 8)
            .whenNotEmpty(
                "targetAmountFrom",
                "AppliesToAllValidators"
            );

        this.ruleFor("targetAmountTo")
            .greaterThanOrEqualTo(0)
            .scalePrecision(2, 8)
            .whenNotEmpty(
                "targetAmountTo",
                "AppliesToAllValidators"
            );

        this.when(
            (o) =>
                o.targetAmountFrom !==
                    undefined &&
                o.targetAmountTo !== undefined,
            () => [
                this.ruleFor(
                    "targetAmountFrom"
                ).lessThanOrEqualTo(
                    "targetAmountTo"
                ),
            ]
        );
    }
}

class SegmentPredicateValidator extends ExtendedValidator<SegmentPredicate> {
    constructor(required?: boolean) {
        super();

        if (required) {
            this.atLeastOneOfFieldNotEmpty([
                "excluded",
                "included",
            ]);
        }

        this.ruleFor("included")
            .notNull()
            .must({
                predicate: (val) =>
                    val?.every((v) =>
                        segmentValidator.every(
                            (sv) =>
                                sv.predicate(v)
                        )
                    ) ?? false,
                message: (val) => {
                    const incorrectSegment =
                        val?.filter(
                            (v) =>
                                !segmentValidator.every(
                                    (sv) =>
                                        sv.predicate(
                                            v
                                        )
                                )
                        )[0] ?? "";

                    const validator =
                        segmentValidator.filter(
                            (sv) =>
                                !sv.predicate(
                                    incorrectSegment
                                )
                        )[0];

                    return `Segment ${incorrectSegment}: ${validator.message()}`;
                },
            })
            .whenNotEmpty(
                "included",
                "AppliesToAllValidators"
            );

        this.ruleFor("excluded")
            .notNull()
            .must({
                predicate: (val) =>
                    val?.every((v) =>
                        segmentValidator.every(
                            (sv) =>
                                sv.predicate(v)
                        )
                    ) ?? false,
                message: (val) => {
                    const incorrectSegment =
                        val?.filter(
                            (v) =>
                                !segmentValidator.every(
                                    (sv) =>
                                        sv.predicate(
                                            v
                                        )
                                )
                        )[0] ?? "";

                    const validator =
                        segmentValidator.filter(
                            (sv) =>
                                !sv.predicate(
                                    incorrectSegment
                                )
                        )[0];

                    return `Segment ${incorrectSegment}: ${validator.message()}`;
                },
            })
            .whenNotEmpty(
                "excluded",
                "AppliesToAllValidators"
            );
    }
}

class UserPredicateValidator extends ExtendedValidator<UserPredicate> {
    constructor(required?: boolean) {
        super();

        const segmentPredicateValidator =
            new SegmentPredicateValidator(
                required
            );
        const personalTargetRequirementValidator =
            new PersonalTargetRequirementValidator(
                required
            );

        const ordersRequirementValidator =
            new OrdersRequirementValidator(
                required
            );

        if (required) {
            this.atLeastOneOfFieldNotEmpty([
                "orders",
                "personalTarget",
                "segments",
            ]);
        }

        this.ruleFor("orders")
            .setValidator(
                ordersRequirementValidator
            )
            .whenNotEmpty();

        this.ruleFor("personalTarget")
            .setValidator(
                personalTargetRequirementValidator
            )
            .whenNotEmpty();

        this.ruleForEach("segments")
            .setValidator(
                segmentPredicateValidator
            )
            .whenNotEmpty();
    }
}

class TotalRequirementValidator extends ExtendedValidator<TotalRequirement> {
    constructor() {
        super();

        this.ruleFor("minimumValue")
            .greaterThan(0)
            .whenNotEmpty()
            .lessThan("maximumValue")
            .whenNotEmpty("maximumValue");

        this.ruleFor("maximumValue")
            .greaterThan(0)
            .whenNotEmpty()
            .greaterThan("minimumValue")
            .whenNotEmpty("minimumValue");

        this.when(
            (model) =>
                model.minimumValue !==
                    undefined &&
                model.maximumValue !== undefined,
            () => [
                this.ruleFor(
                    "minimumValue"
                ).lessThan("maximumValue"),
                this.ruleFor(
                    "maximumValue"
                ).greaterThan("minimumValue"),
            ]
        );
    }
}

const totalRequirementValidator =
    new TotalRequirementValidator();

const postCodePredicateValidator =
    new PostCodePredicateValidator();

class DeliveryPredicateValidator extends ExtendedValidator<DeliveryPredicate> {
    constructor(required?: boolean) {
        super();

        if (required) {
            this.atLeastOneOfFieldNotEmpty([
                "deliveryMethods",
                "postcodes",
                "currentDates",
                "deliveryDates",
                "closingDates",
                "isWholeDayDelivery",
                "isSameDayDelivery",
                "isExpressDelivery",
                "isBeforeHighHoliday",
                "isNonTradeSunday",
                "isMondayAfterNonTradeSunday",
                "isMorningAfterHoliday",
            ]);
        }

        this.ruleForEach("postcodes")
            .notNull()
            .setValidator(
                postCodePredicateValidator
            )
            .whenNotEmpty(
                "postcodes",
                "AppliesToAllValidators"
            );

        this.ruleFor("currentDates")
            .must(
                (o) =>
                    o === undefined ||
                    o === null ||
                    o.every(
                        (p) =>
                            p.isOnlyNextDay ===
                            undefined
                    )
            )
            .whenNotEmpty();

        this.ruleForEach("currentDates")
            .notNull()
            .setValidator(datePredicateValidator)
            .whenNotEmpty(
                "currentDates",
                "AppliesToAllValidators"
            );

        this.ruleForEach("deliveryDates")
            .notNull()
            .setValidator(datePredicateValidator);

        this.ruleFor("closingDates")
            .notNull()
            .whenNotEmpty();

        this.ruleForEach("closingDates")
            .notNull()
            .setValidator(
                dateLeadPredicateValidator
            );
    }
}

export class CartContextPredicateValidator extends ExtendedValidator<CartContextPredicate> {
    constructor() {
        super();

        const userPredicateValidator =
            new UserPredicateValidator(true);
        const deliveryPredicateValidator =
            new DeliveryPredicateValidator(true);

        if (true) {
            this.atLeastOneOfFieldNotEmpty([
                "user",
                "packaging",
                "delivery",
                "payment",
                "total",
            ]);
        }

        this.ruleFor("user")
            .setValidator(userPredicateValidator)
            .whenNotEmpty();

        this.ruleForEach("delivery")
            .setValidator(
                deliveryPredicateValidator
            )
            .whenNotEmpty();

        this.ruleFor("total")
            .setValidator(
                totalRequirementValidator
            )
            .whenNotEmpty();
    }
}
