const commerceErrorCode = {
    CartPredicateEmptyProducts: "CartPredicateEmptyProducts",
    ContractPriceReplacementNewPriceRequired: "ContractPriceReplacementNewPriceRequired",
    DeferredPaymentNotGenerated: "DeferredPaymentNotGenerated",
    DeferredPaymentNotSubmitted: "DeferredPaymentNotSubmitted",
    DiscountProductNotUnique: "DiscountProductNotUnique",
    DiscountInvalidValidity: "DiscountInvalidValidity",
    DiscountPriceReplacementNewPriceRequired: "DiscountPriceReplacementNewPriceRequired",
    GiftEffectTypeInvalid: "GiftEffectTypeInvalid",
    MinimumChoiceNotEmpty: "MinimumChoiceNotEmpty",
    MaximumChoiceEmpty: "MaximumChoiceEmpty",
    MissingMembershipRewardSegment: "MissingMembershipRewardSegment",
    PromotionInvalidTarget: "PromotionInvalidTarget",
    PromotionOneModifier: "PromotionOneModifier",
    PromotionEffectsInvalidLength: "PromotionEffectsInvalidLength",
    PromotionEffectInvalidType: "PromotionEffectInvalidType",
    PromotionEffectNewProductPrice: "PromotionEffectNewProductPrice",
    PromotionEffectModifierInvalid: "PromotionEffectModifierInvalid",
    PromotionEffectDiscountModifier: "PromotionEffectDiscountModifier",
    PromotionEffectChosenProduct: "PromotionEffectChosenProduct",
    PromotionPairQuantityInvalid: "PromotionPairQuantityInvalid",
    PromotionPoolsInvalidLength: "PromotionPoolsInvalidLength",
    PromotionPoolEitherQuantityOrValue: "PromotionPoolEitherQuantityOrValue",
    PromotionPoolSingleProduct: "PromotionPoolSingleProduct",
    PromotionPoolQuantityInvalid: "PromotionPoolQuantityInvalid",
    PromotionPoolIncludedProductsInvalidLength: "PromotionPoolIncludedProductsInvalidLength",
    PromotionPoolEmptyQuantity: "PromotionPoolEmptyQuantity",
    PromotionProgressiveModifiersDiffrent: "PromotionProgressiveModifiersDiffrent",
    PromotionProgressiveCardinalityDiffrent: "PromotionProgressiveCardinalityDiffrent",
    PromotionProgressiveQuantitiesEmpty: "PromotionProgressiveQuantitiesEmpty",
    PromotionSegmentEffectInvalid: "PromotionSegmentEffectInvalid",
    RewardEmptyEffect: "RewardEmptyEffect",
    RequirementMustBeSpecified: "RequirementMustBeSpecified",
    UserActivactionLimitInvalid: "UserActivactionLimitInvalid",
} as const;

export default commerceErrorCode;
