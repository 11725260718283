import { verifyEans, verifyProductId } from "store/initProducts/initProduct/actions";
import Validator, {
    decimalPlaces,
    greaterOrEqualsThan,
    greaterThan,
    lessOrEqualsThan,
    lessThan,
    notEmpty,
    notEmptyArray,
    notEmptyArrayValidator,
    notEmptyValidator,
} from "utils/validators/basic";
import _ from "lodash";
import { tValidation, validation } from "utils-ts/validations/translation";

function hasDuplicates(array) {
    var valuesSoFar = Object.create(null);
    for (var i = 0; i < array.length; ++i) {
        var value = array[i];
        if (value in valuesSoFar) {
            return true;
        }
        valuesSoFar[value] = true;
    }
    return false;
}

function hasDuplicatesWithReturn(array) {
    var valuesSoFar = Object.create(null);
    let result = [];
    for (var i = 0; i < array.length; ++i) {
        var value = array[i];
        if (value in valuesSoFar) {
            result = [...result, value];
        }
        valuesSoFar[value] = true;
    }
    return result.filter(onlyUnique);
}

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

const required = (value, form) => notEmptyValidator(value).when(form["canModify"]).validate();

const requiredSampling = (value, form) =>
    notEmptyValidator(value)
        .when(form["canModify"] && !form["isSampling"])
        .validate();

const requiredArray = (value, form) => notEmptyArrayValidator(value).when(form["canModify"]).validate();

const requiredNotEmpty = (value) => notEmptyValidator(value).validate();

const requiredNotEmptyDecimal = (value) =>
    notEmptyValidator(value)
        .must(decimalPlaces([0, 2]), tValidation(validation.decimalPlaces))
        .validate();

const requiredNotEmptyArray = (value) => notEmptyArrayValidator(value).validate();

const requiredNotNegative = (value, form) =>
    notEmptyValidator(value)
        .when(form["canModify"])
        .must(greaterOrEqualsThan(0), tValidation(validation.greaterOrEqualsThan, { number: 0 }))
        .validate();

const requiredNotNegativeDecimal = (value, form) =>
    notEmptyValidator(value)
        .when(form["canModify"])
        .must(greaterOrEqualsThan(0), tValidation(validation.greaterOrEqualsThan, { number: 0 }))
        .must(decimalPlaces([0, 2]), tValidation(validation.decimalPlaces))
        .validate();

const maxDimensions = [32, 40, 60];

const validateDimensions = (value, form) => {
    if (!value) {
        return "Pole wymagane";
    }

    if (parseFloat(value) <= 0) {
        return "Liczba musi być większa niż 0 cm";
    }

    const { width, depth, height } = form;

    if (!width || !depth || !height) {
        return undefined;
    }

    const areDimensionValid = _([width, depth, height])
        .map(parseFloat)
        .orderBy((x) => x)
        .zip(maxDimensions)
        .map((z) => z[0] <= z[1])
        .reduce((c, n) => c && n, true);

    if (!areDimensionValid) {
        return "Maksymalny rozmiar to 60x40x32";
    }

    return undefined;
};

const requiredPositive = (value, form) =>
    notEmptyValidator(value)
        .when(form["canModify"])
        .must(greaterThan(0), tValidation(validation.greaterThan, { number: 0 }))
        .validate();

const nonNegative = (value) =>
    new Validator(value)
        .must(greaterOrEqualsThan(0), tValidation(validation.greaterOrEqualsThan, { number: 0 }))
        .when(notEmpty(value))
        .validate();

const percentage = (value) =>
    new Validator(value)
        .must(greaterOrEqualsThan(0), tValidation(validation.greaterOrEqualsThan, { number: 0 }))
        .must(lessOrEqualsThan(100), tValidation(validation.lessOrEqualsThan, { number: 100 }))
        .allWhen(notEmpty(value))
        .validate();

const alcohol = (value, form) =>
    notEmptyValidator(value)
        .when(form["canModify"] && form["Produkt alkoholowy"])
        .must(greaterOrEqualsThan(0), tValidation(validation.greaterOrEqualsThan, { number: 0 }))
        .when(notEmpty(value))
        .must(lessOrEqualsThan(100), tValidation(validation.lessOrEqualsThan, { number: 100 }))
        .when(notEmpty(value))
        .validate();

const gtu = (value, form) =>
    notEmptyValidator(value)
        .when(form["canModify"] && form["Produkt alkoholowy"])
        .validate();

const alcoholMerchant = (value, form) =>
    notEmptyValidator(value)
        .when(form["canModify"])
        .must((value) => value === "F24", tValidation(validation.equalToF24))
        .when(notEmpty(value) && (form["Produkt alkoholowy"] || form["alcoholByVolume"] > 0.5))
        .must((value) => value === "FRS", tValidation(validation.equalToFRS))
        .when(notEmpty(value) && (!form["Produkt alkoholowy"] || form["alcoholByVolume"] <= 0.5))
        .validate();

const analiticGroup = (value, form) =>
    notEmptyValidator(value)
        .when(form["canModify"])
        .must(
            (_) => form["analyticGroupMerchant"] === "F24",
            tValidation(validation.analyticGroupMerchantConflict, {
                merchant: "Frisco",
            })
        )
        .when(notEmpty(value) && (form["Produkt alkoholowy"] || form["alcoholByVolume"] > 0.5))
        .must((_) => form["analyticGroupMerchant"] === "FRS", tValidation(validation.analyticGroupMerchantConflict, { merchant: "FR24" }))
        .when(notEmpty(value) && (!form["Produkt alkoholowy"] || form["alcoholByVolume"] <= 0.5))
        .validate();

const purchasePrice = (value, form) =>
    notEmptyValidator(value)
        .when(form["canModify"] && form["merchant"] === "F24")
        .must(greaterThan(0), tValidation(validation.greaterThan, { number: 0 }))
        .when(notEmpty(value))
        .validate();

const vatStakeSell = (value, form) => new notEmptyValidator(value).when(form["canModifyAudit"]).validate();

const onlyDigitRegex = /^\d+$/;

const eanValidator = (value) => {
    return notEmptyValidator(value)
        .mustRegex(/^[0-9]{6,13}$/, "Nieprawidłowy format kodu EAN")
        .when(Boolean(value))
        .validate();
};

export const validateInitProductDetails = (values) => {
    const errors = {};
    if (values["canModify"]) {
        if (values["ean"]) {
            errors["ean"] = eanValidator(values["ean"]);
        }

        if (!values["Skład produktu"] && !values["initedByTrade"]) {
            errors["Skład produktu"] = "Pole wymagane";
        }

        if (!values["Kraj pochodzenia"]) {
            errors["Kraj pochodzenia"] = "Pole wymagane";
        }

        if (values["Produkt alkoholowy"] && !values["Alkohol"]) {
            errors["Alkohol"] = "Pole wymagane";
        }

        if (values["Produkt tytoniowy"] && !values["Tytoń"]) {
            errors["Tytoń"] = "Pole wymagane";
        }

        const emptyValues = [undefined, null, "", "<p><br></p>"];
        if (values["analyticGroups"] && values["analiticGroup"]) {
            const analiticGroup = values["analyticGroups"].find((x) => x.id === values["analiticGroup"]);
            if (values["analyticGroups"].some((x) => x.parentId === analiticGroup.id)) {
                errors["analiticGroup"] = "Wybrana grupa analityczna musi być najniższego poziomu";
            }
        }

        if (values["minimalStockDays"] && values["maximumStockDays"] && parseInt(values["minimalStockDays"]) > parseInt(values["maximumStockDays"])) {
            errors["minimalStockDays"] = "Wartość musi być mniejsza lub równa maksymalnej ilości zapasu";
            errors["maximumStockDays"] = "Wartość musi być większa lub równa minimalnej ilości zapasu";
        }

        if (
            values["fullValidityPeriod"] &&
            values["contractedReceivePeriod"] &&
            parseInt(values["minimalStockDays"]) > parseInt(values["fullValidityPeriod"])
        ) {
            errors["minimalStockDays"] = "Wartość musi być mniejsza lub równa pełnemu okresowi przydatności";
            errors["contractedReceivePeriod"] = "Wartość musi być większa lub równa kontraktowemu okresowi do przyjęcia";
        }

        if (
            values["fullValidityPeriod"] &&
            values["contractedReceivePeriod"] &&
            parseInt(values["contractedReceivePeriod"]) > parseInt(values["fullValidityPeriod"])
        ) {
            errors["contractedReceivePeriod"] = "Wartość musi być mniejsza lub równa pełnemu okresowi przydatności do spożycia";
            errors["fullValidityPeriod"] = "Wartość musi być większa lub równa kontraktowego okresu do przyjęcia";
        }

        if (values?.minPieces && values?.maxPieces) {
            if (lessThan(values.minPieces)(values.maxPieces)) {
                errors["minPieces"] = "Wartość musi być mniejsza lub równa max(szt)";
                errors["maxPieces"] = "Wartość musi być większa lub równa min(szt)";
            }
        }

        if (values?.weightNet && values?.weightGross) {
            if (lessThan(values.weightNet)(values.weightGross)) {
                errors["weightNet"] = "Wartość musi być mniejsza lub równa masie brutto";
                errors["weightGross"] = "Wartość musi być większa lub równa masie netto ";
            }
        }

        const variants = values["variants"] || [];
        const allEans = variants.map((x) => x.ean).concat(values["ean"]);
        const duplicates = hasDuplicatesWithReturn(allEans);
        const variantErrors = [];
        variants.forEach((variant, index) => {
            const variantError = {};

            const ean = variant["ean"];
            if (ean && (!onlyDigitRegex.test(ean) || ean.length < 6 || ean.length > 13)) {
                variantError["ean"] = "Nieprawidłowy format kodu EAN";
            } else if (duplicates.includes(ean)) {
                variantError["ean"] = "Kody EAN są zduplikowane";
            }
            variantErrors[index] = variantError;
        });

        errors["variants"] = variantErrors;

        const stores = Object.entries(values || {}).filter(([key, _]) => key.startsWith("warehouse|") && key.endsWith("|store")) || [];
        const allStores = stores.flatMap(([_, value]) => value.map((x) => x.store));
        const duplicates2 = hasDuplicatesWithReturn(allStores);

        stores.forEach(([key, value]) => {
            const storeErrors = [];
            value.forEach((val, index) => {
                const storeError = {};
                const store = val["store"];
                const superiorStore = val["superiorStore"];
                if (duplicates2.includes(store)) {
                    storeError["store"] = "Magazyny są zduplikowane";
                } else if (store === superiorStore) {
                    storeError["store"] = "Magazyn nadrzędny musi być różny od podstawowego";
                    storeError["superiorStore"] = "Magazyn nadrzędny musi być różny od podstawowego";
                }
                storeErrors[index] = storeError;
            });
            errors[key] = storeErrors;
        });

        if (!values["initedByTrade"]) {
            const nutritionFields = ["Określenie", "w 100g", "w jednostce"];
            const nutritions = values["Obliczona wartość odżywcza"] || [];
            const nutritionTerms = nutritions.map((x) => x["Określenie"]);
            const nutritionErrors = [];
            nutritions.forEach((nutrition, index) => {
                const nutritionError = {};
                const nutritionTerm = nutrition["Określenie"];
                const in100g = nutrition["w 100g"];
                const inUnit = nutrition["w jednostce"];
                const missingFields = nutritionFields.filter((nutritionField) => emptyValues.includes(nutrition[nutritionField]));
                missingFields.forEach((missingField) => {
                    nutritionError[missingField] = "Pole wymagane";
                });

                if (nutritionTerm && nutritionTerms.filter((x) => x === nutritionTerm).length > 1) {
                    nutritionError["Określenie"] = "Zduplikowana wartość odżywcza";
                }

                if (in100g && parseInt(in100g) < 0) {
                    nutritionError["w 100g"] = "Wartość musi być nieujemna";
                }

                if (inUnit && parseInt(inUnit) < 0) {
                    nutritionError["w jednostce"] = "Wartość musi być nieujemna";
                }

                nutritionErrors[index] = nutritionError;
            });

            errors["Obliczona wartość odżywcza"] = nutritionErrors;
        }
    }

    return errors;
};

export const initProductValidate = (values) => {
    const errors = {};
    const positiveValues = ["plannedSale", "minimalStockDays", "maximumStockDays"];
    const requiredFields = ["ean", "vendorId", "brand", "defaultSupplier", "producer", "plannedSale", "minimalStockDays", "maximumStockDays"];
    const ean = "ean";
    const manufacturedProductId = "manufacturedProductId";
    const isManufacturedProduct = "isManufacturedProduct";
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = "Pole wymagane";
        }
    });

    positiveValues.forEach((field) => {
        if (values[field] && values[field] < 0) {
            errors[field] = "Wartość musi być nieujemena";
        }
    });

    if (values["minimalStockDays"] && values["maximumStockDays"] && parseInt(values["minimalStockDays"]) > parseInt(values["maximumStockDays"])) {
        errors["minimalStockDays"] = "Wartość musi być mniejsza lub równa maksymalnej ilości zapasu";
        errors["maximumStockDays"] = "Wartość musi być większa lub równa minimalnej ilości zapasu";
    }

    const stores = Object.entries(values || {}).filter(([key, _]) => key.startsWith("warehouse|") && key.endsWith("|store")) || [];
    const allStores = stores.flatMap(([_, value]) => value.map((x) => x.store));
    const duplicates2 = hasDuplicatesWithReturn(allStores);

    stores.forEach(([key, value]) => {
        const storeErrors = [];
        value.forEach((val, index) => {
            const storeError = {};
            const store = val["store"];
            const superiorStore = val["superiorStore"];
            if (duplicates2.includes(store)) {
                storeError["store"] = "Magazyny są zduplikowane";
            } else if (store === superiorStore) {
                storeError["store"] = "Magazyn nadrzędny musi być różny od podstawowego";
                storeError["superiorStore"] = "Magazyn nadrzędny musi być różny od podstawowego";
            }
            storeErrors[index] = storeError;
        });
        errors[key] = storeErrors;
    });

    if (values[ean]) {
        if (values[ean].includes(",")) {
            const eans = values[ean].split(",");
            if (hasDuplicates(eans)) {
                errors[ean] = "Kody EAN są zduplikowane";
            }

            eans.forEach((x) => {
                if (x && (!onlyDigitRegex.test(x) || x.length < 6 || x.length > 13)) {
                    errors[ean] = "Nieprawidłowy format jednego z kodów EAN";
                }
            });
        } else if (!onlyDigitRegex.test(values[ean]) || values[ean].length < 6 || values[ean].length > 13) {
            errors[ean] = "Nieprawidłowy format kodu EAN";
        }
    }

    if (values[isManufacturedProduct]) {
        if (!values[manufacturedProductId]) {
            errors[manufacturedProductId] = "Pole wymagane";
        }
    }

    return errors;
};

export const initProductAsyncValidate = async (values, dispatch) => {
    const ean = "ean";
    let isValid = true;
    if (values?.ean) {
        const eans = values.ean.split(",");
        if (values[ean].includes(",")) {
            if (hasDuplicates(eans)) {
                isValid = false;
            }

            eans.forEach((x) => {
                if (x && (!/^\d+$/.test(x) || x.length < 6 || x.length > 13)) {
                    isValid = false;
                }
            });
        } else if (!/^\d+$/.test(values[ean]) || values[ean].length < 6 || values[ean].length > 13) {
            isValid = false;
        }
        if (isValid) {
            const { payload } = await dispatch(verifyEans(eans.filter((x) => x)));
            if (payload === true) {
                return Promise.reject({
                    ean: "Produkt o podanym EAN'ie już istnieje",
                });
            }
        }
    }
    return Promise.resolve(undefined);
};

export const initProductVariantsAsyncValidate = async (values, dispatch, store) => {
    const variants = values["variants"] || [];
    const savedEans = values["savedEans"] || [];
    const variantErrors = [];
    const manufacturedProductId = "manufacturedProductId";
    const isManufacturedProduct = "isManufacturedProduct";
    const ean = "ean";
    let hasEanError = false;

    if (values[ean] && values[ean] !== store.initialValues.ean) {
        if (!eanValidator(values[ean])) {
            const { payload } = await dispatch(verifyEans([values[ean]]));
            if (payload === true) {
                hasEanError = true;
            }
        }
    }

    if (hasEanError) {
        return Promise.reject({
            ean: "Produkt o podanym EAN'ie już istnieje",
        });
    }

    for (const [index, variant] of variants.entries()) {
        variantErrors[index] = { ean: variant.ean };

        if (variant?.ean) {
            if (onlyDigitRegex.test(variant[ean]) && !(variant[ean].length < 6 || variant[ean].length > 13)) {
                const { payload } = await dispatch(verifyEans([variant.ean]));
                variantErrors[index].isValid = payload !== true || savedEans.includes(variant.ean);
            } else {
                variantErrors[index].isValid = true;
            }
        }
    }

    if (variantErrors.some((x) => x.isValid === false)) {
        return Promise.reject({
            variants: variantErrors.map((x) => ({
                ean: x.isValid === false ? "Produkt o podanym EAN'ie już istnieje" : undefined,
            })),
        });
    }

    if (values[isManufacturedProduct] && values[manufacturedProductId].length > 0) {
        const { payload } = await dispatch(verifyProductId(values[manufacturedProductId]));
        if (payload === false) {
            return Promise.reject({
                manufacturedProductId: "Nie istnieje produkt o takim id",
            });
        }
    }

    return Promise.resolve(undefined);
};

const gtuWarning = (val, form, props) => {
    const analyticGroup = props.analyticGroups.find((o) => form.analiticGroup && o.id === form.analiticGroup);
    if (!analyticGroup?.gtuNumber || analyticGroup.gtuNumber === val) {
        return;
    }

    return `Dla grupy analitycznej ${analyticGroup.name} oczekiwana wartość to: ${analyticGroup.gtuNumber}`;
};

const storageUnit = (value, form) =>
    new notEmptyValidator(value)
        .must(
            (_) => value === "g",
            tValidation(validation.storageUnitConflict, {
                type: "produkowalnego",
                unit: "g",
            })
        )
        .when(notEmpty(value) && form["isManufacturedProduct"])
        .must(
            (_) => value === "szt",
            tValidation(validation.storageUnitConflict, {
                type: "podstawowego",
                unit: "szt",
            })
        )
        .when(notEmpty(value) && !form["isManufacturedProduct"])
        .validate();

const manufacturedProductId = (value, form) =>
    notEmptyValidator(value)
        .when(form["canModify"] && form["isManufacturedProduct"] === true)
        .validate();

const positionGroup = (value, form) =>
    notEmptyValidator(value)
        .when(form["canModify"])
        .must(
            (_) => value === "PK",
            tValidation(validation.positionGroupConflict, {
                type: "produkowalnego",
                positionGroup: "PK",
            })
        )
        .when(notEmpty(value) && form["isManufacturedProduct"])
        .must(
            (_) => value === "TH",
            tValidation(validation.positionGroupConflict, {
                type: "podstawowego",
                positionGroup: "TH",
            })
        )
        .when(notEmpty(value) && !form["isManufacturedProduct"])
        .validate();

const atLeastOneManufacturedWarehouse = (value, form) =>
    new Validator(form["manufacturedWarehouses"])
        .must(notEmptyArray, tValidation(validation.atLeastOneManufacturedWarehouse))
        .when(form["canModify"] && form["isManufacturedProduct"])
        .validate();

const validateAvailableOnPlatform = (value, form) =>
    new Validator(value)
        .must((value) => value == false, tValidation(validation.emptyWhen, { when: "produkt jest do produkcji" }))
        .when(form["isManufacturedProduct"])
        .validate();

export const validators = {
    required,
    requiredNotNegative,
    requiredNotNegativeDecimal,
    nonNegative,
    percentage,
    alcohol,
    vatStakeSell,
    requiredNotEmpty,
    requiredNotEmptyArray,
    requiredNotEmptyDecimal,
    alcoholMerchant,
    requiredArray,
    purchasePrice,
    gtu,
    requiredPositive,
    requiredSampling,
    analiticGroup,
    eanValidator,
    validateDimensions,
    gtuWarning,
    storageUnit,
    manufacturedProductId,
    positionGroup,
    atLeastOneManufacturedWarehouse,
    validateAvailableOnPlatform,
};
