import { GetRequest, get } from "utils/extensions";
import types from "./types";

const endpoint = "api/vendors/decisions";

export const findContractsForApproval = (params) => async (dispatch) => {
    return dispatch(
        get(
            new GetRequest(`${endpoint}/contracts`, types.findContractsForApproval.all, {
                queryParams: params,
            })
        )
    );
};

export const changeContractsPagination = (payload) => {
    return { type: types.changeContractsPagination, payload };
};

export const findSubsidiaryGainForApproval = (params) => async (dispatch) => {
    return dispatch(
        get(
            new GetRequest(`${endpoint}/subsidiary-gains`, types.findSubsidiaryGainForApproval.all, {
                queryParams: params,
            })
        )
    );
};

export const changeSgPagination = (payload) => {
    return { type: types.changeSubsidiaryGainPagination, payload };
};

export const findRefundationsForApproval = (params) => async (dispatch) => {
    return dispatch(
        get(
            new GetRequest(`${endpoint}/refundations`, types.findRefundationsForApproval.all, {
                queryParams: params,
            })
        )
    );
};

export const changeRefundationsPagination = (payload) => {
    return { type: types.changeRefundationsPagination, payload };
};
