import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Division, Divisions, ObsoleteDivisions } from "types/sharedEnums";
import { usePrefixContext } from "components-ts/forms/contexts";
import FormSelect from "../FormSelect";

type FormDivisionSelectProps = {
    label: string;
    /**
     * default 'divisions'
     */
    name?: string;
    readOnly?: boolean;
    multiple?: true;
    disableEmpty?: boolean;
};

const FormDivisionSelect: React.FC<FormDivisionSelectProps> = ({ label, name = "divisions", multiple, disableEmpty, readOnly }) => {
    const form = useFormContext();
    const prefix = usePrefixContext();
    const value = form.getValues(prefix !== undefined ? `${prefix}.${name}` : name);
    const [selectedObsoleteDivisions, setSelectedObsoleteDivision] = useState<Division[]>([]);
    useEffect(() => {
        if (Array.isArray(value) && value.some((v) => ObsoleteDivisions.includes(v as Division))) {
            setSelectedObsoleteDivision(value.filter((v) => ObsoleteDivisions.includes(v as Division)));
        }
    }, [value]);

    return (
        <FormSelect
            name={name}
            label={label}
            items={Divisions.filter((d) => readOnly || !ObsoleteDivisions.includes(d) || selectedObsoleteDivisions.includes(d))}
            disableEmpty={disableEmpty}
            readOnly={readOnly}
            multiple={multiple}
        />
    );
};

export default FormDivisionSelect;
