import { FormField } from "components/Form";
import { integerNormalize } from "components/FormHelpers/ControlFormaters";
import { FormSection } from "redux-form";
import { withFormName } from "utils/hoc";
import { useChange, useCommonTranslation, useFormValueSelector } from "utils/hooks";
import Validator, { beInteger, greaterOrEqualsThan, lessThan, notEmpty } from "utils/validators/basic";
import { Collapse, Grid } from "@mui/material";
import { get } from "lodash";
import { tValidation, validation } from "utils-ts/validations/translation";

const validateAtLeastOne = (_, form, __, path) => {
    let orders = get(form, path.substring(0, path.lastIndexOf("."))) || {};

    return new Validator(orders)
        .must((o) => {
            return (
                notEmpty(o.invoicedOrderCountFrom) ||
                notEmpty(o.invoicedOrderCountModulo) ||
                notEmpty(o.invoicedOrderCountModuloRemainder) ||
                notEmpty(o.invoicedOrderCountTo) ||
                notEmpty(o.lastDeliveryDaysAgoFrom) ||
                notEmpty(o.lastDeliveryDaysAgoTo) ||
                notEmpty(o.membershipOrderCountFrom) ||
                notEmpty(o.membershipOrderCountModulo) ||
                notEmpty(o.membershipOrderCountModuloRemainder) ||
                notEmpty(o.membershipOrderCountTo) ||
                notEmpty(o.placedOrderCountFrom) ||
                notEmpty(o.placedOrderCountModulo) ||
                notEmpty(o.placedOrderCountModuloRemainder) ||
                notEmpty(o.placedOrderCountTo)
            );
        }, tValidation(validation.atLeastOneField))
        .validate();
};

const validateFrom = (value, form, _, path) => {
    let valueTo = get(form, path.substring(0, path.lastIndexOf(".")) + path.substring(path.lastIndexOf(".")).replace("From", "To"));

    return new Validator(value)
        .must(beInteger, tValidation(validation.mustBeInteger))
        .must((value) => Number(value) >= 0, tValidation(validation.mustBePossitive))
        .allWhen(notEmpty(value))
        .must(
            (value) => Number(value) <= Number(valueTo),
            tValidation(validation.lessOrEqualsThan, {
                number: valueTo,
            })
        )
        .when(notEmpty(value) && notEmpty(valueTo))
        .validate();
};

const validateTo = (value, form, _, path) => {
    let valueFrom = get(form, path.substring(0, path.lastIndexOf(".")) + path.substring(path.lastIndexOf(".")).replace("To", "From"));

    return new Validator(value)
        .must(beInteger, tValidation(validation.mustBeInteger))
        .must((value) => Number(value) >= 0, tValidation(validation.mustBePossitive))
        .allWhen(notEmpty(value))
        .must(
            (value) => Number(value) >= Number(valueFrom),
            tValidation(validation.greaterOrEqualsThan, {
                number: valueFrom,
            })
        )
        .when(notEmpty(value) && notEmpty(valueFrom))
        .validate();
};

const validateCountModulo = (value, form, _, path) => {
    const countModuloRemainder = get(
        form,
        path.substring(0, path.lastIndexOf(".")) + path.substring(path.lastIndexOf(".")).replace("CountModulo", "CountModuloRemainder")
    );

    return new Validator(value)
        .must(notEmpty, tValidation(validation.notEmpty))
        .when(notEmpty(countModuloRemainder))
        .must(beInteger, tValidation(validation.beInteger))
        .when(notEmpty(value))
        .must(
            greaterOrEqualsThan(2),
            tValidation(validation.greaterOrEqualsThan, {
                number: 2,
            })
        )
        .when(notEmpty(value))
        .validate();
};

const validateCountModulRemainder = (value, form, _, path) => {
    const countModulo = get(
        form,
        path.substring(0, path.lastIndexOf(".")) + path.substring(path.lastIndexOf(".")).replace("CountModuloRemainder", "CountModulo")
    );

    return new Validator(value)
        .must(notEmpty, tValidation(validation.notEmpty))
        .when(notEmpty(countModulo))
        .must(beInteger, tValidation(validation.beInteger))
        .when(notEmpty(value))
        .must(lessThan(countModulo), tValidation(validation.lessThan) + " " + countModulo)
        .when(notEmpty(countModulo && value))
        .must(
            greaterOrEqualsThan(0),
            tValidation(validation.greaterOrEqualsThan, {
                number: 0,
            })
        )
        .when(notEmpty(countModulo && value))
        .validate();
};

const fieldsContainer = {
    item: true,
    container: true,
    direction: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
};

const OrderRequirementsFields = ({ form, sectionPrefix, readOnly = false }) => {
    const change = useChange(form);
    const { t, common } = useCommonTranslation();
    const user = useFormValueSelector(form, sectionPrefix);
    const { orderRequirements = false } = user;

    return (
        <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
        >
            <FormField
                name={"orderRequirements"}
                label={t(common.orderRequirements)}
                type={"boolean"}
                onChange={(event) => {
                    if (!event.target.checked) {
                        change(`${sectionPrefix}.orders`, {});
                    }
                }}
                readOnly={readOnly}
            />
            <FormSection name="orders">
                <Collapse in={orderRequirements}>
                    <Grid {...fieldsContainer}>
                        <FormField
                            hidden={!orderRequirements}
                            name={"placedOrderCountFrom"}
                            label={t(common.placedOrderCountFrom)}
                            type={"number"}
                            formatDisplay={integerNormalize}
                            validate={[validateFrom, validateAtLeastOne]}
                            readOnly={readOnly}
                        />
                        <FormField
                            hidden={!orderRequirements}
                            name={"placedOrderCountTo"}
                            label={t(common.placedOrderCountTo)}
                            type={"number"}
                            validate={[validateTo, validateAtLeastOne]}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid {...fieldsContainer}>
                        <FormField
                            hidden={!orderRequirements}
                            name={"placedOrderCountModulo"}
                            label={t(common.placedOrderCountModulo)}
                            type={"number"}
                            validate={[validateCountModulo, validateAtLeastOne]}
                            readOnly={readOnly}
                        />
                        <FormField
                            hidden={!orderRequirements}
                            name={"placedOrderCountModuloRemainder"}
                            label={t(common.placedOrderCountModuloRemainder)}
                            type={"number"}
                            validate={[validateCountModulRemainder, validateAtLeastOne]}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid {...fieldsContainer}>
                        <FormField
                            hidden={!orderRequirements}
                            name={"invoicedOrderCountFrom"}
                            label={t(common.invoicedOrderCountFrom)}
                            type={"number"}
                            validate={[validateFrom, validateAtLeastOne]}
                            readOnly={readOnly}
                        />
                        <FormField
                            hidden={!orderRequirements}
                            name={"invoicedOrderCountTo"}
                            label={t(common.invoicedOrderCountTo)}
                            type={"number"}
                            validate={[validateTo, validateAtLeastOne]}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid {...fieldsContainer}>
                        <FormField
                            hidden={!orderRequirements}
                            name={"invoicedOrderCountModulo"}
                            label={t(common.invoicedOrderCountModulo)}
                            type={"number"}
                            validate={[validateCountModulo, validateAtLeastOne]}
                            readOnly={readOnly}
                        />
                        <FormField
                            hidden={!orderRequirements}
                            name={"invoicedOrderCountModuloRemainder"}
                            label={t(common.invoicedOrderCountModuloRemainder)}
                            type={"number"}
                            validate={[validateCountModulRemainder, validateAtLeastOne]}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid {...fieldsContainer}>
                        <FormField
                            hidden={!orderRequirements}
                            name={"membershipOrderCountFrom"}
                            label={t(common.membershipOrderCountFrom)}
                            type={"number"}
                            validate={[validateFrom, validateAtLeastOne]}
                            readOnly={readOnly}
                        />
                        <FormField
                            hidden={!orderRequirements}
                            name={"membershipOrderCountTo"}
                            label={t(common.membershipOrderCountTo)}
                            type={"number"}
                            validate={[validateTo, validateAtLeastOne]}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid {...fieldsContainer}>
                        <FormField
                            hidden={!orderRequirements}
                            name={"membershipOrderCountModulo"}
                            label={t(common.membershipOrderCountModulo)}
                            type={"number"}
                            validate={[validateCountModulo, validateAtLeastOne]}
                            readOnly={readOnly}
                        />
                        <FormField
                            hidden={!orderRequirements}
                            name={"membershipOrderCountModuloRemainder"}
                            label={t(common.membershipOrderCountModuloRemainder)}
                            type={"number"}
                            validate={[validateCountModulRemainder, validateAtLeastOne]}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid {...fieldsContainer}>
                        <FormField
                            hidden={!orderRequirements}
                            name={"lastDeliveryDaysAgoFrom"}
                            label={t(common.lastDeliveryDaysAgoFrom)}
                            type={"number"}
                            validate={[validateFrom, validateAtLeastOne]}
                            readOnly={readOnly}
                        />
                        <FormField
                            hidden={!orderRequirements}
                            name={"lastDeliveryDaysAgoTo"}
                            label={t(common.lastDeliveryDaysAgoTo)}
                            type={"number"}
                            validate={[validateTo, validateAtLeastOne]}
                            readOnly={readOnly}
                        />
                    </Grid>
                </Collapse>
            </FormSection>
        </Grid>
    );
};

export default withFormName(OrderRequirementsFields);
