import tableStyle from "assets/jss/material-dashboard-react/components/tableStyle";
import tasksStyle from "assets/jss/material-dashboard-react/components/tasksStyle";
import { withStyles } from "tss-react/mui";
import { Icon, IconButton, Tooltip, Typography } from "@mui/material";
import Zoom from "@mui/material/Zoom";
import classnames from "classnames";

const styles = (theme) => ({
    ...tasksStyle,
    ...tableStyle,
    disablePointer: {
        cursor: "default",
    },
    tooltipText: {
        color: "#fff",
        fontSize: theme.typography.pxToRem(16),
    },
    padding: {
        padding: 5,
    },
});

const IconWithHint = ({ classes, title, icon, custom, onClick }) => {
    return (
        <Tooltip
            title={
                <Typography
                    className={classes.tooltipText}
                    variant="body2"
                >
                    {title}
                </Typography>
            }
            TransitionComponent={Zoom}
            placement="bottom"
            arrow
        >
            <IconButton
                className={classnames(classes.padding)}
                onClick={onClick}
            >
                {custom ? (
                    icon
                ) : (
                    <Icon
                        color="secondary"
                        fontSize="small"
                    >
                        {icon}
                    </Icon>
                )}
            </IconButton>
        </Tooltip>
    );
};

export default withStyles(IconWithHint, styles);
