import getUser from "utils-ts/auth/getUser";

const RSAA = "@@redux-api-middleware/RSAA";

export const tokenMiddleware = () => (next) => (action) => {
    if (action[RSAA]) {
        const user = getUser();
        action[RSAA].headers = {
            ...action[RSAA].headers,
            Authorization: `${user.token_type} ${user.access_token}`,
        };
    }

    return next(action);
};
