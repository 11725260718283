import TextWithLabel from "components/Text/TextWithLabel";
import { productAnalyticGroupsSelector } from "store/autocomplete";
import { makeStyles } from "tss-react/mui";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useSelector } from "react-redux";
import { common } from "translations";
import ProductField from "views/offers/productPreview/components/ProductField";

const useStyles = makeStyles()({
    analitycGroupField: {
        whiteSpace: "pre",
    },
});

const ProductOperationalData = ({
    analyticGroup,
    storageType,
    fullValidityTerm,
    minimumValidityTerm,
    alcoholByVolume,
    popularity,
    margin,
    soldAt,
}) => {
    const { classes } = useStyles();
    const productsAnalyticGroups = useSelector(productAnalyticGroupsSelector);
    const analyticGroupName = productsAnalyticGroups.find((ag) => ag.id === analyticGroup);

    const info = [
        new ProductField(
            common.analyticGroup,
            analyticGroupName ? analyticGroupName.name.replaceAll("-> ", "->\n") : analyticGroup,
            formatTypes.text,
            {},
            classes.analitycGroupField
        ),
        new ProductField(common.storageType, storageType, formatTypes.translatable, {
            translationKey: "common",
        }),
        new ProductField(common.fullValidityTerm, fullValidityTerm, formatTypes.numeric),
        new ProductField(common.minimumValidityTerm, minimumValidityTerm, formatTypes.numeric),
        new ProductField(common.alcoholByVolume, alcoholByVolume, formatTypes.numeric),
        new ProductField(common.popularity, popularity ? Math.round(popularity * 1000) / 100 : null, formatTypes.percent),
        new ProductField(common.margin, margin ? Math.round(margin * 1000) / 100 : null, formatTypes.percent),
        new ProductField(common.soldAt, soldAt, formatTypes.time, {
            timeFormat: timePrecisonFormats.seconds,
        }),
    ];

    return info.map((f) => (
        <TextWithLabel
            unselectableLabel
            key={f.label}
            {...f}
        />
    ));
};

export default ProductOperationalData;
