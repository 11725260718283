import { DeleteRequest, GetRequest, PostRequest, PutRequest, get, post, put, remove } from "utils/extensions";
import { downloadPdfFile } from "utils/rsaa/downloadFileAction";
import types from "./types";

const endpoint = (vendorId, contractId, draft) =>
    `api/vendors/${vendorId}/contracts${contractId ? `/${contractId}` : ""}${contractId && draft ? `/draft` : ""}`;

const fileEndpoint = (vendorId, fileId) => `api/vendors/${vendorId}/contracts/file${fileId ? `/${fileId}` : ""}`;

export const initForm = (vendorId, contractId, isForChange) => async (dispatch) => {
    await dispatch({ type: types.formInit });

    if (contractId) {
        return dispatch(
            get(
                new GetRequest(endpoint(vendorId, contractId), types.getContract.all, {
                    queryParams: { isForChange },
                })
            )
        );
    } else {
        return dispatch(
            get(
                new GetRequest(endpoint(vendorId) + "/get-draft", types.findDraft.all, {
                    queryParams: { isForChange },
                })
            )
        );
    }
};

export const createOrUpdate = (values, vendorId, contractId, typeOfChange, isDraft) => async (dispatch) => {
    const body = {
        contract: {
            ...values,
            newFiles: undefined,
        },
        newFiles: (values.newFiles || []).map(({ archiveFile, ...x }) => ({
            ...x,
            fileName: archiveFile?.fileName,
            fileContent: null,
            documentTypeId: x.documentType?.id,
            categoryId: x.documentCategory?.id,
        })),
        updatedFiles: (values.contractFiles || [])
            .filter((x) => x.file)
            .map((x) => ({
                ...x,
                fileName: x.archiveFile?.fileName,
                fileContent: null,
                documentTypeId: x.documentType?.id,
                categoryId: x.documentCategory?.id,
            })),
        typeOfChange,
    };

    var type = types.createOrUpdate.all;
    if (isDraft) {
        type = [
            types.createOrUpdate.request,
            types.createOrUpdate.success,
            {
                type: types.createOrUpdate.failure,
                meta: { hideError: true },
            },
        ];
    }

    if (!contractId) {
        return await dispatch(
            post(
                new PostRequest(endpoint(vendorId), type, {
                    body: body,
                    queryParams: {
                        draft: isDraft,
                    },
                })
            )
        );
    } else {
        return await dispatch(
            put(
                new PutRequest(endpoint(vendorId, contractId, isDraft), type, {
                    body: body,
                    queryParams: {
                        draft: isDraft,
                    },
                })
            )
        );
    }
};

export const addFileToDraftContract = (file, vendorId, contractId) => async (dispatch) => {
    const { archiveFile, ...rest } = file;

    const body = {
        ...rest,
        fileName: archiveFile.fileName,
        fileContent: archiveFile.fileContent,
        documentTypeId: rest.documentType?.id,
        categoryId: rest.documentCategory?.id,
    };

    return await dispatch(
        post(
            new PostRequest(
                endpoint(vendorId, contractId, true) + "/file",
                [
                    types.addFileToDraftContract.request,
                    types.addFileToDraftContract.success,
                    {
                        type: types.addFileToDraftContract.failure,
                        meta: { hideError: true },
                    },
                ],
                {
                    body: body,
                }
            )
        )
    );
};

export const changeFileInDraftContract = (file, vendorId, contractId) => async (dispatch) => {
    const body = {
        ...file,
        fileName: file.archiveFile.fileName,
        fileContent: file.archiveFile.fileContent,
        documentTypeId: file.documentType?.id,
        categoryId: file.documentCategory?.id,
    };

    const { fileId } = file;

    return await dispatch(
        post(
            new PostRequest(
                endpoint(vendorId, contractId, true) + "/file/" + fileId,
                [
                    types.changeFileInDraftContract.request,
                    types.changeFileInDraftContract.success,
                    {
                        type: types.changeFileInDraftContract.failure,
                        meta: { hideError: true },
                    },
                ],
                {
                    body: body,
                }
            )
        )
    );
};

export const removeFileFromDraftContract = (vendorId, contractId, fileId) => async (dispatch) => {
    return dispatch(remove(new DeleteRequest(endpoint(vendorId, contractId, true) + `/file/${fileId}`, types.removeFileFromDraftContract.all)));
};

export const removeContract = (vendorId, contractId) => async (dispatch) => {
    return dispatch(remove(new DeleteRequest(endpoint(vendorId, contractId), types.deleteContractGain.all)));
};

export const getContractFile = (vendorId, contractId, fileId, fileName) => async (dispatch) => {
    await dispatch(
        downloadPdfFile(
            fileEndpoint(vendorId, fileId) + "/download" + (contractId ? `?contractId=${contractId}` : ""),
            types.downloadFile.all,
            fileName
        )
    );
};

export const findContractForApproval = (vendorId) => async (dispatch) => {
    return dispatch(get(new GetRequest(`${endpoint(vendorId)}/for-approval`, types.findContractForApproval.all)));
};

export const makeContractDecision = (vendorId, contractId, isPositive, comment) => async (dispatch) => {
    return dispatch(
        put(
            new PutRequest(endpoint(vendorId, contractId) + "/make-decision", types.makeContractDecison.all, {
                body: {
                    isPositive,
                    comment,
                },
            })
        )
    );
};
