import { RSAA } from "redux-api-middleware";
import { commerce } from "store/actionTypePrefixes";
import { combineIntoQueryParams } from "utils/objectExtensions";
import { splitOrderId, splitPaymentId } from "utils/splitIds";

export const types = {
    GET_PAYMENT_TRANSFERS: `${commerce}/GET_PAYMENT_TRANSFERS`,
    GET_PAYMENT_TRANSFERS_SUCCESS: `${commerce}/GET_PAYMENT_TRANSFERS_SUCCESS`,
    GET_PAYMENT_TRANSFERS_FAILURE: `${commerce}/GET_PAYMENT_TRANSFERS_FAILURE`,
    ACCEPT_PAYMENT: `${commerce}/ACCEPT_PAYMENT`,
    ACCEPT_PAYMENT_SUCCESS: `${commerce}/ACCEPT_PAYMENT_SUCCESS`,
    ACCEPT_PAYMENT_FAILURE: `${commerce}/ACCEPT_PAYMENT_FAILURE`,
    TRANSFER_PAYMENT: `${commerce}/TRANSFER_PAYMENT`,
    TRANSFER_PAYMENT_SUCCESS: `${commerce}/TRANSFER_PAYMENT_SUCCESS`,
    TRANSFER_PAYMENT_FAILURE: `${commerce}/TRANSFER_PAYMENT_FAILURE`,
    GET_CUSTOMER_PROFILES: `${commerce}/GET_CUSTOMER_PROFILE`,
    GET_CUSTOMER_PROFILES_SUCCESS: `${commerce}/GET_CUSTOMER_PROFILE_SUCCESS`,
    GET_CUSTOMER_PROFILES_FAILURE: `${commerce}/GET_CUSTOMER_PROFILE_FAILURE`,
};

const getPaymentTransfers = (params) => async (dispatch) => {
    const queryParams = combineIntoQueryParams(
        {
            ...params,
            status: "Pending",
        },
        true
    );
    const endpoint = `api/v1/users/payments?${queryParams}`;
    const {
        payload: { items = [], ...rest },
    } = await dispatch({
        [RSAA]: {
            endpoint,
            method: "GET",
            types: [types.GET_PAYMENT_TRANSFERS, types.GET_PAYMENT_TRANSFERS_SUCCESS, types.GET_PAYMENT_TRANSFERS_FAILURE],
        },
    });

    let result = { items: [] };

    if (items && items.length > 0) {
        result = await dispatch(getCustomers(items.map((x) => splitOrderId(x.id).customerId)));
    }

    await dispatch({
        type: types.GET_PAYMENT_TRANSFERS_SUCCESS,
        payload: {
            ...rest,
            items: items.map((x) => ({
                ...x,
                customer: result && result.items ? result.items.find((y) => x.id.includes(y.id)) : undefined,
            })),
        },
    });

    return Promise.resolve();
};

const getCustomers = (payload) => async (dispatch) => {
    const params = combineIntoQueryParams({
        userIds: payload,
    });

    const { payload: profiles } = await dispatch({
        [RSAA]: {
            endpoint: `api/v1/users/query?${params}`,
            method: "GET",
            types: [types.GET_CUSTOMER_PROFILES, types.GET_CUSTOMER_PROFILES_SUCCESS, types.GET_CUSTOMER_PROFILES_FAILURE],
        },
    });

    return Promise.resolve(profiles);
};

const acceptPayment = (paymentId) => async (dispatch) => {
    const { customerId, paymentNumber } = splitPaymentId(paymentId);
    return dispatch({
        [RSAA]: {
            endpoint: `api/v1/users/${customerId}/payments/${paymentNumber}`,
            headers: { "Content-Type": "application/json" },
            method: "PUT",
            body: JSON.stringify({}),
            types: [types.ACCEPT_PAYMENT, types.ACCEPT_PAYMENT_SUCCESS, types.ACCEPT_PAYMENT_FAILURE],
        },
    });
};

const transferPayment =
    ({ paymentId, transferTo, transferAmount }) =>
    async (dispatch) => {
        const { customerId, paymentNumber } = splitPaymentId(paymentId);
        return dispatch({
            [RSAA]: {
                endpoint: `api/v1/users/${customerId}/payments/${paymentNumber}/transfers`,
                headers: { "Content-Type": "application/json" },
                method: "POST",
                body: JSON.stringify({
                    transferTo,
                    transferAmount,
                }),
                types: [types.TRANSFER_PAYMENT, types.TRANSFER_PAYMENT_SUCCESS, types.TRANSFER_PAYMENT_FAILURE],
            },
        });
    };

export const actions = {
    getPaymentTransfers,
    acceptPayment,
    transferPayment,
};
