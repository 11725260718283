import { DecisionButtons } from "components/Button";
import { DateRangeField } from "components/DateRangeField";
import { Form, FormField, FormFieldArray } from "components/Form";
import { decimalNormalize } from "components/FormHelpers/ControlFormaters";
import { Layout } from "components/Grid";
import TextWithLabel from "components/Text/TextWithLabel";
import roles from "consts/roles";
import { useUser } from "context/UserContext/UserContext";
import { SubmissionError, reduxForm } from "redux-form";
import { formValueSelector } from "redux-form";
import { userInfoWithRoleSelector } from "store/autocomplete";
import {
    createOrUpdateMarketingBudget,
    getMarketingBudget,
    makeDecision,
    marketingBudgetsFormSelector,
    validateForm,
} from "store/vendors/marketingBudget";
import { useChange, useFormValueSelector, useParams } from "utils/hooks";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Alert } from "@mui/lab";
import { Grid } from "@mui/material";
import moment from "moment";
import { isEmpty } from "lodash";
import { common, vendor } from "translations";
import { usePush } from "utils-ts/hooks";
import { useTranslation } from "utils-ts/hooks";
import { IsVendorReadOnly } from "views/vendors/IsVendorReadOnly";
import { Paths } from "routing-ts/ManagerPaths";
import CatmanBudget from "./CatmanBudget";
import { validators } from "./validation";

const formName = "MarketingBudgetForm";

const MarketingBudgetForm = ({ document, pristine, handleSubmit, isSubbmitRequested, form }) => {
    const { vendorId, marketingBudgetId } = useParams();
    const dispatch = useDispatch();
    const { isInRoleOrAdmin } = useUser();
    const { replace } = usePush();
    const [submitted, setSubmitted] = useState(false);
    const { t } = useTranslation(["vendor", "common"]);
    const change = useChange(formName);
    const { dateFrom, catmansBudgets = [], budgetUsage = [] } = useFormValueSelector(formName, ["dateFrom", "catmansBudgets", "budgetUsage"], {});
    const readOnly =
        (marketingBudgetId && moment(document?.dateTo).isSameOrBefore(moment()) && document.status != "Rejected") || IsVendorReadOnly(formName);
    const users = useSelector(userInfoWithRoleSelector);
    const isDecisionRequired = document.status == "WaitingForDecision";
    const rejectReason = useSelector((state) => formValueSelector(form)(state, "rejectReason"));
    const isReasonRequired = moment(dateFrom).isSameOrBefore(moment()) && !pristine && document.status != "WaitingForDecision";

    useEffect(() => {
        if (catmansBudgets.length > 0) {
            const totalPrice = catmansBudgets.reduce((partialSum, a) => partialSum + (Number(a.budget) ?? 0), 0);
            change("total", totalPrice);
        } else if (catmansBudgets.state == "changed") {
            const totalPrice =
                catmansBudgets.added.reduce((partialSum, a) => partialSum + (Number(a.budget.proposal) ?? 0), 0) +
                catmansBudgets.changed.reduce((partialSum, a) => partialSum + (Number(a.budget.proposal) ?? 0), 0) +
                catmansBudgets.noDiff.reduce((partialSum, a) => partialSum + (Number(a.budget.proposal) ?? 0), 0);
            change("total", totalPrice);
        }
    }, [catmansBudgets]);

    var catmansBudgetUsageHelper = {};
    const catmansBudgetUsage = budgetUsage.reduce(function (r, o) {
        if (!catmansBudgetUsageHelper[o.catman]) {
            catmansBudgetUsageHelper[o.catman] = { catman: o.catman, budget: o.budget };
            r.push(catmansBudgetUsageHelper[o.catman]);
        } else {
            catmansBudgetUsageHelper[o.catman].budget += o.budget;
        }

        return r;
    }, []);

    const catmansBudgetOverLimit = Array.isArray(catmansBudgets)
        ? catmansBudgets
              .map((o) => {
                  const usage = catmansBudgetUsage.find((u) => u.catman == o.catman) || {};
                  if (Number(usage.budget || 0) > Number(o.budget)) {
                      const user = users?.Catman?.find((us) => us.userId == o.catman);
                      return t(vendor.catmanBudgetOverLimit, {
                          name: !!user ? `${user?.fullName?.firstName} ${user?.fullName?.lastName}` : "",
                          value: usage.budget,
                      });
                  }
                  return undefined;
              })
              .filter((o) => o != undefined)
        : [];

    const isBudgetOverLimit = Array.isArray(catmansBudgets)
        ? catmansBudgets.reduce((a, b) => {
              return a + b.budget;
          }, 0) <
          catmansBudgetUsage.reduce((a, b) => {
              return a + b.budget;
          }, 0)
        : false;

    return (
        <Form
            onSubmit={handleSubmit(async (values) => {
                const validationResult = await dispatch(validateForm(vendorId, marketingBudgetId, values.dateFrom, values.dateTo));

                if (validationResult?.payload == false) {
                    throw new SubmissionError({
                        _error: "Submit failed",
                        dateFrom: t(vendor.budgetDatesError),
                        dateTo: t(vendor.budgetDatesError),
                    });
                }

                values = {
                    ...values,
                    catmansBudgets: values.catmansBudgets.map((cb) => {
                        return {
                            ...cb,
                            reason: values.reason,
                        };
                    }),
                };
                const { payload } = await dispatch(createOrUpdateMarketingBudget(vendorId, marketingBudgetId, values));

                if (!marketingBudgetId) {
                    replace(Paths.Vendor.MarketingBudgetForm, {
                        vendorId: vendorId,
                        marketingBudgetId: payload.id,
                    });
                } else {
                    await dispatch(getMarketingBudget(vendorId, marketingBudgetId));
                }
            })}
            isReadonly={readOnly}
            pristine={pristine}
            isSubbmitRequested={isSubbmitRequested}
        >
            {!!document?.rejectReason && document.status == "Rejected" && (
                <Alert severity="error">
                    {t(vendor.rejectedMessageWithComment, {
                        comment: document?.rejectReason,
                    })}
                </Alert>
            )}
            {catmansBudgetOverLimit.length > 0 && (
                <Alert severity="error">{t(vendor.catmansBudgetOverLimit) + catmansBudgetOverLimit.join(", ")}</Alert>
            )}
            {catmansBudgetOverLimit.length == 0 && isBudgetOverLimit && <Alert severity="error">{t(vendor.budgetFormOverLimit)}</Alert>}
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                <DateRangeField
                    formName={formName}
                    startDateName="dateFrom"
                    endDateName="dateTo"
                    startDateValidator={validators.validateDateFrom}
                    endDateValidator={validators.validateDateTo}
                    startDateLabel={t(vendor.dateFrom)}
                    endDateLabel={t(vendor.dateTo)}
                    readOnly={readOnly}
                    blockSameDay={false}
                    useDiff={isDecisionRequired}
                />
                {!!marketingBudgetId && (
                    <TextWithLabel
                        value={t(`common:${document.status}`)}
                        translatedLabel={t(`common:${common.status}`)}
                    />
                )}
            </Grid>
            <Layout headerText={t(`common:${common.catmansBudget}`)}>
                <FormFieldArray
                    name="catmansBudgets"
                    component={CatmanBudget}
                    validate={validators.arrayRequired}
                    readOnly={readOnly}
                    useDiff={isDecisionRequired}
                />
            </Layout>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                <FormField
                    name="total"
                    label={t(`common:${common.Total}`)}
                    type="text"
                    readOnly
                />

                <FormField
                    name="discount"
                    label={t(`common:${common.discountPercent}`)}
                    type="text"
                    format={decimalNormalize}
                    validate={validators.discount}
                    readOnly={readOnly}
                    useDiff={isDecisionRequired}
                    adornment={{
                        position: "end",
                        value: "%",
                    }}
                />

                <FormField
                    stretch
                    name="comment"
                    label={t(`common:${common.comment}`)}
                    type="text"
                    readOnly={readOnly}
                />
            </Grid>
            {isReasonRequired && (
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                >
                    <FormField
                        stretch
                        name="reason"
                        label={t(`common:${common.reason}`)}
                        type="text"
                        validate={validators.notEmptyField}
                    />
                </Grid>
            )}
            {isDecisionRequired && (isInRoleOrAdmin(roles.SalesManager) || isInRoleOrAdmin(roles.TradeMarketingManager)) && (
                <>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                    >
                        <FormField
                            stretch
                            name="rejectReason"
                            label={t(vendor.comment)}
                            type="text"
                        />
                    </Grid>
                    <DecisionButtons
                        submitted={submitted}
                        onAccept={async () => {
                            setSubmitted(true);
                            await dispatch(makeDecision(vendorId, marketingBudgetId, true, rejectReason));
                            await dispatch(getMarketingBudget(vendorId, marketingBudgetId));
                            setSubmitted(false);
                        }}
                        onReject={async () => {
                            setSubmitted(true);
                            await dispatch(makeDecision(vendorId, marketingBudgetId, false, rejectReason));
                            await dispatch(getMarketingBudget(vendorId, marketingBudgetId));
                            setSubmitted(false);
                        }}
                        canReject={!isEmpty(rejectReason)}
                        acceptText={t(vendor.acceptChanges)}
                        rejectText={t(vendor.rejectChanges)}
                    />
                </>
            )}
        </Form>
    );
};

export default connect((state) => {
    const form = marketingBudgetsFormSelector(state);

    return {
        initialValues: form,
        document: form,
        isSubbmitRequested: form?.isSubbmitRequested,
    };
})(
    reduxForm({
        form: formName,
        enableReinitialize: true,
    })(MarketingBudgetForm)
);
