import Layout from "components/Grid/Layout";
import Preloader from "components/Preloader/Preloader";
import TabLabel from "components/Tabs/TabLabel";
import Tabs from "components/Tabs/Tabs";
import roles, { AccountantDepartmentRoles, LogisticianDepartmentRoles, SupportDepartmentRoles } from "consts/roles";
import ordersStatuses from "resource/ordersStatuses.json";
import { actions as complaintActions } from "store/order/complaint/actions";
import { actions as orderActions } from "store/order/order/action";
import { useParams } from "utils/hooks";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { useUser } from "context";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { orders } from "translations";
import { usePush, useTranslation } from "utils-ts/hooks";
import { allCustomerRoles, baseCustomerRoles } from "routing-ts/Routes/CustomerRoles";
import PaymentList from "../payments/PaymentList";
import ComplaintsContainer from "./order/ComplaintsContainer";
import OrderContainer from "./order/OrderContainer";
import OrderTabActions from "./order/OrderTabActions";
import ProductList from "./order/ProductList";
import PromotionDecisions from "./order/PromotionDecisions";
import PromotionList from "./order/PromotionList";
import VoucherList from "./order/VoucherList";

const Order = ({ complaints, getCompleteOrder, order, getOrderDocument, cancelOrAbortOrder }) => {
    const params = useParams();
    const { customerId, orderNumber, complaintNumber } = params;
    const { t } = useTranslation(["orders", "common"]);
    const { pushToForm } = usePush();
    const createComplaint = (orderNumber, documentId, complaintId) => {
        pushToForm(`/customers/${customerId}/orders/${orderNumber}/complaints/${documentId}`, complaintId);
    };

    React.useEffect(() => {
        getCompleteOrder(customerId, orderNumber, complaintNumber);
    }, [getCompleteOrder, customerId, orderNumber, complaintNumber]);

    const tabLabels = [
        new TabLabel(t(orders.orderDataTab), allCustomerRoles),
        new TabLabel(t(orders.complaintsDataTab), [
            ...baseCustomerRoles,
            ...AccountantDepartmentRoles,
            ...LogisticianDepartmentRoles,
            roles.ECommerceDepartment,
        ]),
        new TabLabel(t(orders.paymentsDataTab), [...baseCustomerRoles, ...AccountantDepartmentRoles]),
    ];
    const { state } = useLocation();
    const { tabIndex = 0 } = state || {};
    const {
        productLines,
        orderDocuments = [],
        orderInvoices = [],
        fileRequestInProgress,
        vouchers,
        warehouse,
        promotions,
        operationsDocuments = [],
        promotionDecisions = [],
    } = order;

    const containsInvoice = !_.isEmpty(orderInvoices);
    const { isInRole, isAdmin } = useUser();
    const status = order.status ? ordersStatuses.find((o) => o.value === order.status)?.name : "";

    return (
        <>
            <Layout
                main
                navigationProps={{
                    backProps: {
                        path: `/customers/${customerId}`,
                        state: {
                            tabIndex: isInRole(...SupportDepartmentRoles) && !isAdmin ? 0 : 1,
                        },
                    },
                }}
                headerText={`${t(orders.orderWithId, {
                    orderId: order.id,
                })} ${status}`}
            >
                {order.isLoading ? (
                    <Preloader />
                ) : (
                    <Tabs
                        tabIndex={tabIndex}
                        tableLabels={tabLabels}
                        actionComponent={
                            <OrderTabActions
                                roles={[...baseCustomerRoles, ...AccountantDepartmentRoles]}
                                isAdmin={isAdmin}
                                order={order}
                                orderDocuments={orderDocuments}
                                complaints={complaints}
                                createComplaint={createComplaint}
                                orderNumber={orderNumber}
                                getOrderDocument={getOrderDocument}
                                fileRequestInProgress={fileRequestInProgress}
                                customerId={customerId}
                                cancelOrAbortOrder={cancelOrAbortOrder}
                                operationsOrderId={order.operationsOrderId}
                                warehouse={warehouse}
                            />
                        }
                    >
                        <OrderContainer
                            order={order}
                            commerceOrder
                            warehouse={warehouse}
                        />
                        <ComplaintsContainer
                            {...params}
                            operationsDocumentIds={operationsDocuments.map((x) => x.documentId)}
                        />
                        <PaymentList {...params} />
                    </Tabs>
                )}
            </Layout>
            {promotionDecisions && promotionDecisions.length > 0 ? <PromotionDecisions promotionDecisions={promotionDecisions} /> : <Fragment />}
            {promotions && promotions.length > 0 ? <PromotionList promotions={promotions} /> : <Fragment />}
            {vouchers && vouchers.length > 0 ? <VoucherList vouchers={vouchers} /> : <Fragment />}
            <ProductList
                isLoading={order.isLoading}
                lines={productLines}
                containsInvoice={containsInvoice}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    const { order, complaints } = state;
    return { order, complaints };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ ...orderActions, ...complaintActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Order);
