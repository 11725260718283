export const CustomerContactTypes: string[] = [
    "Rozmowa przychodząca",
    "Rozmowa wychodząca",
    "E-mail od klienta",
    "NPS ankieta",
    "Formularz z WWW",
    "Formularz z aplikacji",
    "Chat FC",
    "Chat Livechat",
    "Trustmate",
    "Opinie google",
    "Kierowca",
    "Mail wychodzący",
];
