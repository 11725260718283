import { GetRequest, PostRequest, PutRequest, get, post, put } from "utils/extensions";
import types from "./types";

const endpoint = (id) => `api/cn-stakes${id ? `/${id}` : ""}`;

export const initForm = (id) => async (dispatch) => {
    await dispatch({ type: types.formInit });

    if (id) {
        return dispatch(get(new GetRequest(endpoint(id), types.getCnStakes.all)));
    }
};

export const createOrUpdate = (values, id) => async (dispatch) => {
    if (!id) {
        return dispatch(
            post(
                new PostRequest(endpoint(id), types.createOrUpdate.all, {
                    body: values,
                })
            )
        );
    } else {
        return dispatch(
            put(
                new PutRequest(endpoint(id), types.createOrUpdate.all, {
                    body: values,
                })
            )
        );
    }
};

export const validateName = (name, id) => async (dispatch) => {
    return dispatch(
        get(
            new GetRequest(endpoint("validate"), types.validateCnStakeName.all, {
                queryParams: {
                    name,
                    id,
                },
            })
        )
    );
};
