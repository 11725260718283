import { RSAA } from "redux-api-middleware";
import { commerce, manager } from "store/actionTypePrefixes";
import { asAsyncFormTypes, asCleanFormDataTypes } from "utils/extensions/arrayExtensions";

export const types = {
    FORM_INIT_CONTENT: `${manager}/FORM_INIT_CONTENT`,
    GET_CONTENT: `${commerce}/GET_CONTENT`,
    GET_CONTENT_SUCCESS: `${commerce}/GET_CONTENT_SUCCESS`,
    GET_CONTENT_FAILURE: `${commerce}/GET_CONTENT_FAILURE`,
    CREATE_OR_UPDATE_CONTENT: `${commerce}/CREATE_OR_UPDATE_CONTENT`,
    CREATE_OR_UPDATE_CONTENT_SUCCESS: `${commerce}/CREATE_OR_UPDATE_CONTENT_SUCCESS`,
    CREATE_OR_UPDATE_CONTENT_FAILURE: `${commerce}/CREATE_OR_UPDATE_CONTENT_FAILURE`,
};

const initForm = (id, isCopy) => async (dispatch) => {
    await dispatch({ type: types.FORM_INIT_CONTENT });

    if (id) {
        dispatch({
            [RSAA]: {
                endpoint: `api/v1/offer/products/content/${id}`,
                headers: { "Content-Type": "application/json" },
                method: "GET",
                types: [types.GET_CONTENT, { type: types.GET_CONTENT_SUCCESS, meta: { isCopy } }, types.GET_CONTENT_FAILURE],
            },
        });
    }
};

const createOrUpdate = (values, id) => async (dispatch) => {
    return await dispatch({
        [RSAA]: {
            endpoint: id ? `api/v1/offer/products/content/${id}` : `api/v1/offer/products/content/`,
            headers: { "Content-Type": "application/json" },
            method: id ? "PUT" : "POST",
            types: asCleanFormDataTypes(
                asAsyncFormTypes([types.CREATE_OR_UPDATE_CONTENT, types.CREATE_OR_UPDATE_CONTENT_SUCCESS, types.CREATE_OR_UPDATE_CONTENT_FAILURE])
            ),
            body: JSON.stringify(values),
        },
    });
};

export const actions = {
    initForm,
    createOrUpdate,
};
