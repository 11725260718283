import { BalanceDebtForm, BalanceDebtList } from "views/balanceDebts";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import { Role } from "routing-ts/roles";

const roles: Role[] = ["Analyst", "DebtCoordinator"];

export const BalanceDebts: PrivateRoute[] = [
    new PrivateRoute(Paths.BalanceDebtsList, roles, BalanceDebtList, "balanceDebts", "calculate"),
    new PrivateRoute(Paths.BalanceDebtForm, roles, BalanceDebtForm),
];
