import Layout from "components/Grid/Layout";
import SimpleTable, { createColumn } from "components/Table/SimpleTable/SimpleTable";
import TextWithLabel from "components/Text/TextWithLabel";
import { getVendorOrder, orderSelector } from "store/vendors/vendorOrders";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useCommonTranslation, useParams } from "utils/hooks";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";

const ProductList = ({ products, isLoading }) => {
    const { t, common } = useCommonTranslation();
    const columns = [
        createColumn("productId", t(common.productId), formatTypes.text),
        createColumn("ean", t(common.ean), formatTypes.text),
        createColumn("name", t(common.name), formatTypes.text),
        createColumn("quantity", t(common.quantity), formatTypes.numeric),
        createColumn("deliveredQuantity", t(common.deliveredQuantity), formatTypes.numeric),
        createColumn("packSize", t(common.packSize), formatTypes.numeric),
    ];

    return (
        <SimpleTable
            isLoading={isLoading}
            columns={columns}
            data={products}
            hover
            stripped
        />
    );
};

const OrdersList = () => {
    const { vendorId, orderId } = useParams();
    const { t, common } = useCommonTranslation();
    const { products, isLoading, ...order } = useSelector(orderSelector);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getVendorOrder(vendorId, orderId));
    }, [dispatch, orderId, vendorId]);

    const fields = [
        {
            label: common.orderId,
            value: order.orderId,
            format: formatTypes.text,
        },
        {
            label: common.vendorId,
            value: vendorId,
            format: formatTypes.text,
        },
        {
            label: common.orderCreatedAt,
            value: order.orderCreatedAt,
            format: formatTypes.time,
            options: {
                timeFormat: timePrecisonFormats.minutes,
            },
        },
        {
            label: common.deliveryAt,
            value: order.deliveryAt,
            format: formatTypes.time,
            options: {
                timeFormat: timePrecisonFormats.minutes,
            },
        },
        {
            label: common.deliveryAddress,
            value: order.deliveryAddress,
            format: formatTypes.shippingAddress,
        },
        {
            label: common.invoiceNumber,
            value: order.invoiceNumber,
            format: formatTypes.text,
        },
        {
            label: common.correctionNumbers,
            value: order.correctionNumbers?.join(", "),
            format: formatTypes.text,
        },
    ];

    const id = `${vendorId}/${order.orderId}`;
    return (
        <>
            <Layout
                main
                headerText={t(common.vendorOrderView, {
                    id: id,
                })}
                navigationProps={{
                    backProps: {
                        path: `/vendors/${vendorId}`,
                        state: {
                            tabIndex: 14,
                        },
                    },
                }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    {fields.map((item) => (
                        <TextWithLabel
                            key={item.label}
                            {...item}
                        />
                    ))}
                </Grid>
            </Layout>
            <Layout
                main
                headerText={t(common.products)}
            >
                <ProductList
                    products={products}
                    isLoading={isLoading}
                />
            </Layout>
        </>
    );
};

export default OrdersList;
