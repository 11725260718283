import { Layout } from "components/Grid";
import { Preloader } from "components/Preloader";
import { actions } from "store/vendors/action";
import { vendorFormSelector } from "store/vendors/selector";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";

const VendorLayout = ({ children, headerText, tabIndex, vendorId, isLoading, customTitle }) => {
    const { t } = useTranslation("vendor");
    const dispatch = useDispatch();
    const { name = "", isActive = false } = useSelector(vendorFormSelector);

    useEffect(() => {
        if (name.length < 1) {
            dispatch(actions.initVendorForm(vendorId));
        }
    }, [dispatch, vendorId, name]);

    return (
        <Layout
            main
            headerText={headerText}
            subtitle={t(vendor.vendor, {
                id: vendorId,
                name: name,
                isActive: isActive ? null : "- nieaktywny",
            })}
            navigationProps={{
                backProps: {
                    path: `/vendors/${vendorId}`,
                    state: { tabIndex },
                },
            }}
            customTitle={customTitle}
        >
            {isLoading ? <Preloader /> : children}
        </Layout>
    );
};

export default VendorLayout;
