import Layout from "components/Grid/Layout";
import { createColumn } from "components/Table/SimpleTable/SimpleTable";
import tableActionTypes from "components/Table/SimpleTable/TableActions/tableActionTypes";
import TableWithToggleFilter from "components/Table/TableWithToggleFilter/TableWithToggleFilter";
import { deleteDynamicSegment, dynamicSegmentListSelector, getDynamicSegments } from "store/dynamicSegments";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useCommonTranslation } from "utils/hooks";
import { useDispatch } from "react-redux";
import { usePush } from "utils-ts/hooks";

const DynamicSegments = () => {
    const { push } = usePush();
    const { t, common } = useCommonTranslation();
    const dispatch = useDispatch();

    const columns = [
        createColumn("name", t(common.name), formatTypes.text),
        createColumn("validFrom", t(common.dateFrom), formatTypes.time, { timeFormat: timePrecisonFormats.days }),
        createColumn("validTo", t(common.dateTo), formatTypes.time, { timeFormat: timePrecisonFormats.days }),
        createColumn("usersCount", t(common.usersCount), formatTypes.text),
        createColumn("isActive", t(common.isActive), formatTypes.boolean),
        createColumn(
            "action",
            "",
            formatTypes.action,
            {
                actionType: tableActionTypes.preview,
            },
            (item) => {
                const { id } = item;
                push(`/dynamic-segments/form/${id}`);
            }
        ),
        createColumn(
            "action",
            "",
            formatTypes.action,
            {
                actionType: tableActionTypes.delete,
            },
            async (item) => {
                dispatch(deleteDynamicSegment(item.id));
            }
        ),
    ];

    return (
        <Layout
            main
            headerText={t(common.dynamicSegments)}
        >
            <TableWithToggleFilter
                columns={columns}
                addItemAction={() => push(`/dynamic-segments/form`)}
                dataSelector={dynamicSegmentListSelector}
                dataSourceAction={getDynamicSegments}
            />
        </Layout>
    );
};

export default DynamicSegments;
