import { Button, IconButton } from "components/Button";
import { DocumentArchiveFileSection } from "components/Files";
import { Form, FormField } from "components/Form";
import TextWithLabel from "components/Text/TextWithLabel";
import { FormSection, reduxForm } from "redux-form";
import { friscoCompanyId } from "resource/documentArchiveCompanies";
import { documentDefinitionsSelector, getDocumentCategories, getDocumentDefinitions, getDocumentTypes } from "store/autocomplete";
import { getDefinitionDocumentFile } from "store/dictonaries/documentDefinitions";
import { documentFormSelector } from "store/vendors/documents";
import { createOrUpdate, getDocumentFile } from "store/vendors/documents/actions";
import { orderBy } from "utils/extensions/arrayExtensions";
import { useAppLocation, useChange, useFormValueSelector } from "utils/hooks";
import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { GetApp as DownloadIcon, Pageview } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useUser } from "context";
import { vendor } from "translations";
import { usePush, useTranslation } from "utils-ts/hooks";
import { IsVendorReadOnly } from "views/vendors/IsVendorReadOnly";
import { Paths } from "routing-ts/ManagerPaths";
import { validators } from "../validators";
import { DocumentStatus } from "./DocumentStatus";

const DocumentForm = ({ id, form, handleSubmit, handleSave, pristine, vendorId }) => {
    const appLocation = useAppLocation();
    const { replace } = usePush();
    const { profile } = useUser();
    const { t } = useTranslation("vendor");
    const documentDefinitions = useSelector(documentDefinitionsSelector);
    const change = useChange(form);
    const dispatch = useDispatch();

    const readOnly = IsVendorReadOnly() || id;
    const {
        documentDefinitionId,
        archiveDocument,
        responsibleUser = {},
        file = {},
    } = useFormValueSelector(form, ["documentDefinitionId", "archiveDocument", "responsibleUser", "file"]);
    const [canAddFile, setCanAddFile] = React.useState(readOnly);

    const { archiveFile, archiveId, hasArchiveErrors } = file;

    const hasFile = Boolean(archiveFile?.fileName) || Boolean(archiveId);
    const handleDownloadTemplateFile = (documentDefinitionId) => {
        let definition = documentDefinitions.find((d) => d.id === documentDefinitionId);
        dispatch(getDefinitionDocumentFile(documentDefinitionId, definition.fileName));
    };

    const handleDownloadDocumentFile = (fileName) => {
        dispatch(getDocumentFile(vendorId, id, fileName));
    };

    useEffect(() => {
        setCanAddFile(!readOnly || (id && !hasFile));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, readOnly]);

    useEffect(() => {
        dispatch(getDocumentDefinitions());
        dispatch(getDocumentCategories());
        dispatch(getDocumentTypes());
    }, [dispatch]);

    var hiddenFields = ["companyId", "vendor"];
    if (!Boolean(archiveDocument)) {
        hiddenFields = [...hiddenFields, "documentCategory", "documentType", "externalDocumentNumber", "barcodePosition", "documentDate"];
    }
    return (
        <>
            <Form
                onSubmit={handleSubmit(async (values) => {
                    const { file = {}, documentDefinitionId, status, archiveDocument } = values;
                    const { archiveFile, categoryId, documentTypeId, documentDate, barcodePosition, externalDocumentNumber, companyId } = file;
                    let fileUpload = null;
                    if (Boolean(archiveFile?.fileContent)) {
                        fileUpload = {
                            fileContent: archiveFile.fileContent,
                            fileName: archiveFile.fileName,
                        };

                        if (archiveDocument) {
                            fileUpload.documentTypeId = documentTypeId;
                            fileUpload.categoryId = categoryId;
                            fileUpload.companyId = companyId;
                            fileUpload.documentDate = documentDate;
                            fileUpload.barcodePosition = barcodePosition;
                            fileUpload.externalDocumentNumber = externalDocumentNumber;
                        }
                    }

                    if (id) {
                        setCanAddFile(file);
                        await dispatch(
                            createOrUpdate(vendorId, id, {
                                file: fileUpload,
                                archiveDocument,
                            })
                        );

                        if (handleSave) {
                            await handleSave();
                        }
                    } else {
                        const { payload } = await dispatch(
                            createOrUpdate(vendorId, id, {
                                file: fileUpload,
                                vendorDocumentDocument: {
                                    responsibleUser: {
                                        userId: profile.sub,
                                        email: profile.email,
                                    },
                                    documentDefinitionId,
                                    status,
                                    archiveDocument,
                                },
                            })
                        );

                        if (!id) {
                            replace(Paths.Vendor.Document, {
                                vendorId,
                                id: payload.id,
                            });
                        } else if (handleSave) {
                            await handleSave();
                        }
                    }
                })}
                isReadonly={!canAddFile}
                pristine={pristine}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <FormField
                        name={"documentDefinitionId"}
                        label={t(vendor.documentDefinition)}
                        type={"autocomplete"}
                        items={
                            readOnly
                                ? orderBy(documentDefinitions, (x) => x.name).map((x) => x.id)
                                : orderBy(documentDefinitions, (x) => x.name)
                                      .filter((x) => x.isActive)
                                      .map((x) => x.id)
                        }
                        validate={validators.required}
                        getOptionLabel={(documentDefinition) =>
                            (typeof documentDefinition === "string"
                                ? documentDefinitions?.find((c) => c.id === documentDefinition)
                                : documentDefinition
                            )?.name || ""
                        }
                        filterOptions={(options, params) => {
                            var { inputValue, getOptionLabel } = params;

                            return options.filter((o) => getOptionLabel(o).toLowerCase().includes(inputValue?.toLowerCase()));
                        }}
                        readOnly={readOnly}
                        onCustomChange={async (_, newValue) => {
                            const documentDefinition = documentDefinitions.find((dd) => dd.id === newValue);
                            change("documentDefinitionId", newValue);
                            change("archiveDocument", documentDefinition?.archiveDocuments);
                        }}
                    />

                    <IconButton
                        icon="get_app"
                        disabled={!Boolean(documentDefinitionId)}
                        onClick={() => {
                            handleDownloadTemplateFile(documentDefinitionId);
                        }}
                    />

                    <FormField
                        name="status"
                        label={t(vendor.status)}
                        type="select"
                        readOnly={readOnly}
                        items={DocumentStatus}
                        validate={hasFile ? null : validators.required}
                    />

                    {id && (
                        <TextWithLabel
                            value={responsibleUser.email}
                            translatedLabel={t(vendor.responsibleUser)}
                        />
                    )}
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <FormSection name="file">
                        <DocumentArchiveFileSection
                            hiddenFields={hiddenFields}
                            readOnly={!canAddFile}
                            useDropzone
                            useDialogPreview
                            validate={archiveDocument && hasFile ? validators.required : null}
                        />
                    </FormSection>
                    {id && !canAddFile && (
                        <>
                            <TextWithLabel
                                value={hasFile ? archiveFile?.fileName : t(vendor.noFile)}
                                label={t(vendor.fileName)}
                            />
                            {!archiveDocument && hasFile && (
                                <IconButton
                                    edge="end"
                                    onClick={() => handleDownloadDocumentFile(archiveFile.fileName)}
                                    icon={<DownloadIcon />}
                                />
                            )}

                            {archiveDocument && archiveId && !hasArchiveErrors && (
                                <Button
                                    color="primary"
                                    startIcon={<Pageview />}
                                    onClick={() => window.open(`${appLocation}/document-archive/document/${archiveId}`, "_blank")}
                                >
                                    Podgląd
                                </Button>
                            )}
                        </>
                    )}
                </Grid>
            </Form>
        </>
    );
};

const formName = "vendor-document-form";

const stateToProps = (state, props) => {
    const form = documentFormSelector(state);
    const { file = {}, ...rest } = form;
    const { documentTypeId, categoryId, fileName } = file;

    return {
        id: form.id,
        initialValues: {
            ...rest,
            vendorId: props.vendorId,
            file: {
                companyId: friscoCompanyId,
                documentType: { id: documentTypeId },
                documentCategory: { id: categoryId },
                archiveFile: { fileName },
                ...file,
            },
        },
    };
};

export default connect(stateToProps)(
    reduxForm({
        form: formName,
        enableReinitialize: true,
    })(DocumentForm)
);
