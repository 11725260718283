import {
    DiscountDocument,
    DiscountProduct,
} from "Commerce-Offer";
import { commerceErrorCode } from "translations";
import { notEmptyArray } from "utils-ts/validations";
import { PriceModifierValidator } from "utils-ts/validations/commerce";
import { ExtendedValidator } from "utils-ts/validations/extendedValidator";
import {
    tValidation,
    validation,
} from "utils-ts/validations/translation";

const priceModifierValidator =
    new PriceModifierValidator();

class DiscountProductValidator extends ExtendedValidator<DiscountProduct> {
    constructor() {
        super();

        this.ruleFor("productId").notEmpty();

        this.ruleFor("modifier")
            .notNull()
            .setValidator(priceModifierValidator);

        this.ruleFor("validFrom")
            .lessThan("validTo")
            .whenNotEmpty([
                "validFrom",
                "validTo",
            ]);

        this.ruleFor("validTo")
            .greaterThan("validFrom")
            .whenNotEmpty([
                "validFrom",
                "validTo",
            ]);
    }
}

const discountProductValidator =
    new DiscountProductValidator();

export class DiscountDocumentValidator extends ExtendedValidator<DiscountDocument> {
    constructor() {
        super();

        this.ruleFor("name")
            .notEmpty()
            .length(3, 250);

        this.ruleFor("campaignName").notEmpty();

        this.ruleFor("isPriceReplaced")
            .equal(true)
            .when(
                (model) =>
                    model.isPriceLocked === true
            );

        this.ruleFor("validFrom")
            .lessThan("validTo")
            .whenNotEmpty([
                "validFrom",
                "validTo",
            ]);

        this.ruleFor("validTo")
            .greaterThan("validFrom")
            .whenNotEmpty([
                "validFrom",
                "validTo",
            ]);

        this.ruleFor("products")
            .notNull()
            .must(notEmptyArray(1))
            .must({
                predicate: (value) =>
                    value.length ===
                    value.reduce<string[]>(
                        (prev, next) =>
                            prev.includes(
                                next.productId
                            )
                                ? prev
                                : [
                                      ...prev,
                                      next.productId,
                                  ],
                        []
                    ).length,
                message: tValidation(
                    validation.arrayMustContainsUniqueValues
                ),
            });

        this.ruleForEach("products")
            .notNull()
            .must({
                predicate: (product, model) =>
                    model.isPriceReplaced !==
                        true ||
                    (product.modifier
                        .discountPercent ===
                        undefined &&
                        product.modifier
                            .discountValue ===
                            undefined &&
                        product.modifier
                            .newPrice !==
                            undefined),
                message: () =>
                    tValidation(
                        `commerceErrorCode:${commerceErrorCode.DiscountPriceReplacementNewPriceRequired}`
                    ),
            })
            .setValidator(
                discountProductValidator
            );
    }
}
