import { GetRequest, PostRequest, PutRequest, get, post, put } from "utils/extensions";
import { downloadPdfFile } from "utils/rsaa/downloadFileAction";
import types from "./types";

const endpoint = (id) => `api/vendors/document-definitions${id ? `/${id}` : ""}`;

export const findDocumentDefinitions = (query) => (dispatch) => {
    return dispatch(
        get(
            new GetRequest(endpoint(), types.findDocumentDefinitions.all, {
                queryParams: query,
            })
        )
    );
};

export const initForm = (id) => async (dispatch) => {
    await dispatch({ type: types.formInit });

    if (id) {
        return dispatch(get(new GetRequest(endpoint(id), types.getDocumentDefinitions.all)));
    }
};

export const createOrUpdate = (values, id) => async (dispatch) => {
    const { file, ...rest } = values;
    if (!id) {
        return await dispatch(
            post(
                new PostRequest(endpoint(id), types.createOrUpdate.all, {
                    body: {
                        file,
                        documentDefinition: rest,
                    },
                })
            )
        );
    } else {
        return dispatch(
            put(
                new PutRequest(endpoint(id), types.createOrUpdate.all, {
                    body: {
                        file,
                        documentDefinition: rest,
                    },
                })
            )
        );
    }
};

export const getDefinitionDocumentFile = (documentDefinitionId, fileName) => async (dispatch) => {
    dispatch(downloadPdfFile(endpoint(documentDefinitionId) + `/file`, types.downloadFile.all, fileName));
};

export const makeDecision = (documentDefinitionId, isPositive, comment) => async (dispatch) => {
    return dispatch(
        put(
            new PutRequest(endpoint(documentDefinitionId) + "/make-decision", types.makeDecision.all, {
                body: {
                    isPositive,
                    comment,
                },
            })
        )
    );
};
