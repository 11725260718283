import { Button, DecisionButtons } from "components/Button";
import { FileInput } from "components/Controls";
import { AcceptRejectDialog } from "components/Dialog";
import { Form, FormField } from "components/Form";
import TextWithLabel from "components/Text/TextWithLabel";
import { B2BDepartmentRoles } from "consts/roles";
import { useUser } from "context/UserContext/UserContext";
import { reduxForm } from "redux-form";
import { actions } from "store/cache/userInfos/actions";
import {
    acceptDeferredPayment,
    createDeferredPayment,
    deferredPaymentsFormSelector,
    editDeferredPayment,
    getDeferredPayment,
    getDocumentFile,
    rejectDeferredPayment,
    submitDeferredPayment,
} from "store/customers/customer/deferredPayments";
import { useParams } from "utils/hooks";
import Validator, { beInteger, mustBeNumber, notEmpty, notEmptyValidator } from "utils/validators/basic";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Grid, TextField } from "@mui/material";
import { common, vendor } from "translations";
import { usePush } from "utils-ts/hooks";
import { useTranslation } from "utils-ts/hooks";
import { tValidation, validation } from "utils-ts/validations/translation";

const formName = "DeferredPaymentForm";
const required = (val) => notEmptyValidator(val).validate();
const positiveNumber = (val) => {
    return new Validator(val)
        .must(notEmpty, tValidation(validation.required))
        .must(mustBeNumber, tValidation(validation.mustBeNumber))
        .when(notEmpty(val))
        .must((val) => Number(val.toString()) > 0, tValidation(validation.greaterOrEqualsThan, { number: 0 }))
        .when(notEmpty(val))
        .validate();
};
const positiveInteger = (val) => {
    return new Validator(val)
        .must(notEmpty, tValidation(validation.required))
        .must(beInteger, tValidation(validation.beInteger))
        .when(notEmpty(val))
        .must((val) => Number(val.toString()) > 0, tValidation(validation.greaterOrEqualsThan, { number: 0 }))
        .when(notEmpty(val))
        .validate();
};
const regonValidator = (val) => {
    return new Validator(val)
        .must(notEmpty, tValidation(validation.required))
        .must(beInteger, tValidation(validation.mustBeNumber))
        .when(notEmpty(val))
        .must((val) => val.length === 9, tValidation(validation.length, { number: 9 }))
        .when(notEmpty(val))
        .validate();
};

const DeferredPaymentForm = ({ responsibleUserInfo, pristine, handleSubmit }) => {
    const { isInAnyRoleOrAdmin } = useUser();
    const isB2B = isInAnyRoleOrAdmin(B2BDepartmentRoles);
    const { customerId, deferredPaymentNumber: id } = useParams();
    const dispatch = useDispatch();
    const { push } = usePush();
    const { t } = useTranslation(["vendor", "common"]);
    const { form } = useSelector(deferredPaymentsFormSelector);
    const decisionToMake = form.document?.status === "Submitted";
    const decisionMade = form.document?.status === "Accepted" || form.document?.status === "Rejected";
    const [rejectDialogDisplayed, setRejectDialogDisplayed] = useState(false);
    const [rejectReason, setRejectReason] = useState(null);
    const [file, setFile] = useState();
    const { getUserInfos } = actions;
    const userName = responsibleUserInfo ? responsibleUserInfo.fullName.firstName + " " + responsibleUserInfo.fullName.lastName : "";
    pristine = file ? false : pristine;
    const canEdit = (!id || form.document?.status === "Generated") && isB2B;

    useEffect(() => {
        if (form.createdBy) {
            dispatch(getUserInfos([form.createdBy]));
        }
    }, [form.createdBy, dispatch, getUserInfos]);

    const handleDownloadFile = async (status) => {
        await dispatch(getDocumentFile(customerId, id, status));
    };

    const handleAccept = async () => {
        await dispatch(acceptDeferredPayment(customerId, id, file));
        await dispatch(getDeferredPayment(customerId, id));
    };

    const handleReject = () => {
        setRejectDialogDisplayed(true);
    };

    const cancelRejection = () => {
        setRejectDialogDisplayed(false);
    };

    const confirmRejection = async () => {
        setRejectDialogDisplayed(false);
        await dispatch(rejectDeferredPayment(customerId, id, file, rejectReason));
        await dispatch(getDeferredPayment(customerId, id));
    };

    const handleSubmitForm = async (values) => {
        if (!id) {
            await dispatch(createDeferredPayment(customerId, values));
        } else if (file) {
            await dispatch(submitDeferredPayment(customerId, id, file));
        } else {
            await dispatch(editDeferredPayment(customerId, id, values));
        }

        push(`/customers/${customerId}`, undefined, { tabIndex: 12 });
    };

    return (
        <>
            <Form
                onSubmit={handleSubmit((values) => {
                    let valuesToSend = {
                        additionalData: {
                            regon: values.form.document.additionalData.regon,
                            representatives: Array.isArray(values.form.document.additionalData.representatives)
                                ? values.form.document.additionalData.representatives.map((elem) => elem.trim())
                                : values.form.document.additionalData.representatives.split(",").map((elem) => elem.trim()),
                        },
                        creditLimit: values.form.document.creditLimit,
                    };
                    handleSubmitForm(valuesToSend);
                })}
                isReadonly={!canEdit}
                pristine={pristine}
            >
                {!!id && (
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <FormField
                            name={"form.document.createdAt"}
                            type={"date"}
                            label={t(vendor.correctionOk)}
                            readOnly={true}
                        />
                        <TextWithLabel
                            value={t(form.document?.status)}
                            translatedLabel={t(vendor.status)}
                        />
                        <Button
                            color="primary"
                            disabled={form.document?.status === "Created" || form.document?.status === "Editing"}
                            onClick={() => {
                                handleDownloadFile(form.document?.status === "Generated" ? "Generated" : "Submitted");
                            }}
                        >
                            {t(vendor.downloadForm)}
                        </Button>
                    </Grid>
                )}
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <FormField
                        name={"form.document.creditLimit.creditAmount"}
                        type={"number"}
                        label={t(vendor.creditAmount)}
                        readOnly={!canEdit || (!pristine && file)}
                        validate={positiveNumber}
                        autoComplete="off"
                    />
                    <FormField
                        name={"form.document.creditLimit.paymentTerm"}
                        type={"number"}
                        label={t(vendor.paymentTerm)}
                        readOnly={!canEdit || (!pristine && file)}
                        validate={positiveInteger}
                        autoComplete="off"
                    />
                    <FormField
                        name={"form.document.additionalData.regon"}
                        type={"text"}
                        label={t(vendor.businessRegistryNumber)}
                        readOnly={!canEdit || (!pristine && file)}
                        validate={regonValidator}
                        autoComplete="off"
                    />
                    {!(!canEdit || (!pristine && file)) ? (
                        <FormField
                            name={"form.document.additionalData.representatives"}
                            stretch
                            type={"text"}
                            label={t(vendor.representativesAfterComma)}
                            readOnly={!canEdit || (!pristine && file)}
                            validate={required}
                            autoComplete="off"
                        />
                    ) : (
                        <TextWithLabel
                            value={form.document?.additionalData?.representatives?.join(", ")}
                            translatedLabel={t(vendor.representativesAfterComma)}
                            autoComplete="off"
                        />
                    )}
                </Grid>
                {form.document?.status === "Generated" && isB2B && (
                    <Grid
                        container
                        direction="row"
                        alignItems="stretch"
                    >
                        <FileInput
                            id="fileInput"
                            label={file?.name ?? t(vendor.uploadSignedByCustmerForm)}
                            name="importFile"
                            onChange={(files) => {
                                setFile(files[0]);
                            }}
                            selectedFile={file}
                            accept=".pdf"
                            multiple={false}
                            disabled={!pristine && !file}
                        />
                    </Grid>
                )}
                {decisionToMake && !!id && isB2B && (
                    <Grid>
                        <FileInput
                            id="fileInput"
                            label={file?.name ?? t(vendor.uploadSignedForm)}
                            name="importFile"
                            onChange={(files) => {
                                setFile(files[0]);
                            }}
                            selectedFile={file}
                            accept=".pdf"
                            multiple={false}
                        />
                        <DecisionButtons
                            submitted={form.isLoading}
                            onAccept={handleAccept}
                            onReject={handleReject}
                            acceptText={t(vendor.acceptForm)}
                            rejectText={t(vendor.rejectForm)}
                            canAccept={file !== undefined}
                            canReject={file !== undefined}
                        />
                    </Grid>
                )}
                {decisionMade && !!id && (
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <FormField
                            name={"form.document.updatedAt"}
                            type={"date"}
                            label={t(vendor.documentDate)}
                            readOnly={true}
                        />
                        {form.document?.status === "Rejected" && (
                            <FormField
                                name={"form.document.decisionForm.comment"}
                                type={"text"}
                                label={t(vendor.comment)}
                                readOnly={true}
                            />
                        )}
                        <TextWithLabel
                            value={userName}
                            translatedLabel={t(vendor.responsibleUser)}
                        />
                        <Button
                            color="primary"
                            disabled={false}
                            onClick={() => {
                                handleDownloadFile("Decided");
                            }}
                        >
                            {t(vendor.dwonloadSignedForm)}
                        </Button>
                    </Grid>
                )}
            </Form>
            <AcceptRejectDialog
                title={t(vendor.comment)}
                open={Boolean(rejectDialogDisplayed)}
                buttonAcceptText={t(`common:${common.save}`)}
                buttonRejectText={t(`common:${common.cancel}`)}
                handleAccept={confirmRejection}
                handleReject={cancelRejection}
                disableAccept={!rejectReason}
            >
                <TextField
                    multiline
                    fullWidth
                    placeholder={t(vendor.comment)}
                    value={rejectReason}
                    onChange={(e) => setRejectReason(e.target.value)}
                    autoComplete="off"
                />
            </AcceptRejectDialog>
        </>
    );
};

export default connect((state) => {
    const form = deferredPaymentsFormSelector(state);
    const { createdBy = {} } = form.form;
    const responsibleUserInfo = state.cache.userInfos.find((x) => x.userId === createdBy);

    return { initialValues: form, responsibleUserInfo, document: form };
})(
    reduxForm({
        form: formName,
        enableReinitialize: true,
    })(DeferredPaymentForm)
);
