import { ArrayValidationError } from "components/Controls";
import { renderFieldOrText } from "components/FormHelpers/Controls";
import Cell from "components/Table/SimpleTable/Cell";
import Row from "components/Table/SimpleTable/Row";
import { Field } from "redux-form";
import { withStyles } from "tss-react/mui";
import { useFormValueSelector } from "utils/hooks";
import Clear from "@mui/icons-material/Clear";
import { Fab } from "@mui/material";
import classnames from "classnames";
import { orders } from "translations";
import { useTranslation } from "utils-ts/hooks";
import complaintStyles from "../../complaintStyles";

const styles = (theme) => ({
    ...complaintStyles(theme),
});

const upsellTypes = [
    { value: "withoutDelivery", name: "Dosprzedaż bez dostawy" },
    { value: "withDelivery", name: "Dosprzedaż z dostawą" },
];

//prettier-ignore
const formFields = [
    { name: orders.productId,   type: "label",   colSpan:1,                          },
    { name: orders.ean,         type: "label",   colSpan:1,                          },
    { name: orders.description, type: "label",   colSpan:2,                          },
    { name: orders.quantity,    type: "number",  colSpan:1,                          },
    { name: orders.grossPrice,  type: "text",    colSpan:1,                          },
    { name: orders.upsellType,  type: "select",  colSpan:2, items: upsellTypes       },
];

function UpsellLine(props) {
    const { t } = useTranslation("orders");
    const { fields, classes, editionDisabled, meta } = props;
    const currentLines = useFormValueSelector("complaintForm", "upsellLines");
    return (
        <>
            <ArrayValidationError {...meta} />
            {fields.map((line, index) => {
                const currentLine = currentLines[index];
                return (
                    <Row
                        key={line}
                        hover
                    >
                        {formFields.map(({ colSpan, name, ...rest }) => {
                            return (
                                <Cell
                                    colSpan={colSpan}
                                    key={name}
                                >
                                    <Field
                                        readOnly={
                                            editionDisabled ||
                                            (name === "quantity" && currentLine.productId && currentLine.productId.includes("U")) ||
                                            (name === "grossPrice" && currentLine.isPromotionOnly)
                                        }
                                        name={`${line}.${name}`}
                                        label={t(name)}
                                        {...rest}
                                        component={renderFieldOrText}
                                    />
                                </Cell>
                            );
                        })}
                        <Cell>
                            <Fab
                                disabled={editionDisabled}
                                style={{
                                    display: editionDisabled ? "none" : "inherit",
                                }}
                                size="small"
                                onClick={() => fields.remove(index)}
                                className={classnames(classes.button, "pull-right")}
                            >
                                <Clear />
                            </Fab>
                        </Cell>
                    </Row>
                );
            })}
        </>
    );
}

export default withStyles(UpsellLine, styles);
