import { AsyncValidator, Validator } from "fluentvalidation-ts";
import { useCallback } from "react";
import _ from "lodash";
import { deepPathObject } from "utils-ts/functions";
import { removeEmptyProps } from "utils-ts/functions/removeEmptyProps";
import { ExtendedValidator } from "utils-ts/validations/extendedValidator";

export type ValidationFunction<T extends {}> = (data: T) => Promise<{
    values: T;
    errors: {};
}>;

const useValidator = <T extends {}>(
    validator: Validator<T> | ExtendedValidator<T> | AsyncValidator<T>,
    cleanValues?: "cleanValuesBeforeValidation"
): ValidationFunction<T> => {
    return useCallback(
        async (data: T) => {
            if ("validate" in validator) {
                const result = validator.validate(cleanValues === "cleanValuesBeforeValidation" ? (removeEmptyProps(data) as T) : data);

                return {
                    values: _.isEmpty(deepPathObject(result)) ? data : ({} as T),
                    errors: deepPathObject(result),
                };
            } else {
                const asynResult = await validator.validateAsync(data);

                return {
                    values: _.isEmpty(deepPathObject(asynResult)) ? data : ({} as T),
                    errors: deepPathObject(asynResult),
                };
            }
        },
        [validator]
    );
};

export default useValidator;
