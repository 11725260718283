import TextWithLabel from "components/Text/TextWithLabel";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useCommonTranslation } from "utils/hooks";
import React, { Fragment } from "react";
import { Grid } from "@mui/material";

const field = (label, value, format, options) => {
    return {
        label,
        value,
        format,
        options,
    };
};

const GiftChoiceActivation = ({ activationInfo = {} }) => {
    const { reservationCount, lastReservedAt } = activationInfo;
    const { t, common } = useCommonTranslation();
    const info = [
        field(t(common.reservationCount), reservationCount, formatTypes.text),
        field(t(common.lastReservedAt), lastReservedAt, formatTypes.time, {
            timeFormat: timePrecisonFormats.seconds,
        }),
    ];

    if (!reservationCount && !lastReservedAt) return <Fragment />;

    return (
        <>
            <Grid container>
                {info.map(
                    (f) =>
                        f.value !== "" && (
                            <Grid
                                item
                                key={f.label}
                            >
                                <TextWithLabel
                                    unselectableLabel
                                    {...f}
                                />
                            </Grid>
                        )
                )}
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                {Object.keys(activationInfo.reservationMonthlyCount).map((month) => (
                    <Grid
                        item
                        key={month}
                    >
                        <TextWithLabel
                            label={t(common.month)}
                            unselectableLabel
                            value={month}
                        />
                        <TextWithLabel
                            label={t(common.reservationCount)}
                            unselectableLabel
                            value={activationInfo.reservationMonthlyCount[month]}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default GiftChoiceActivation;
