import { operations } from "store/actionTypePrefixes";
import { AsyncTypes, createAction } from "utils/extensions";

const types = {
    findArchivedDocuments: new AsyncTypes("FIND_ARCHIVED_DOCUMENTS", operations),
    setFilters: createAction("SET_FILTERS"),
    downloadReport: new AsyncTypes("DOWNLOAD_REPORT_DOCUMENTS_ARCHIVE", operations),
};

export default types;
