import commerce from "./commerce";
import content from "./content";
import crawler from "./crawler";
import identity from "./identity";
import operations from "./operations";

export * from "./hooks";

export const api = {
    identity,
    commerce,
    content,
    crawler,
    operations,
};
