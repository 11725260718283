import { RSAA } from "redux-api-middleware";
import { asAsyncFormTypes, asCleanFormDataTypes } from "utils/extensions/arrayExtensions";
import { types } from "./types";

export const initPromotionForm = (id, isCopy) => async (dispatch) => {
    await dispatch({ type: types.initializePromotionForm });

    if (id) {
        dispatch({
            [RSAA]: {
                endpoint: `api/v1/offer/products/promotions/${id}`,
                headers: { "Content-Type": "application/json" },
                method: "GET",
                types: [types.getPromotion.request, { type: types.getPromotion.success, meta: { isCopy } }, types.getPromotion.failure],
            },
        });
    }

    if (id && !isCopy) {
        dispatch({
            [RSAA]: {
                endpoint: `api/v1/offer/products/promotions/${id}/activation-count`,
                headers: { "Content-Type": "application/json" },
                method: "GET",
                types: [
                    types.getPromotionActivation.request,
                    types.getPromotionActivation.success,
                    {
                        type: types.getPromotionActivation.failure,
                        meta: { hideError: true },
                    },
                ],
            },
        });

        dispatch({
            [RSAA]: {
                endpoint: `api/v1/offer/products/promotions/${id}/validation`,
                headers: { "Content-Type": "application/json" },
                method: "GET",
                types: [
                    types.getPromotionValidation.request,
                    types.getPromotionValidation.success,
                    {
                        type: types.getPromotionValidation.failure,
                        meta: { hideError: true },
                    },
                ],
            },
        });
    }
};

export const createOrUpdatePromotion = (values, id) => async (dispatch) => {
    return await dispatch({
        [RSAA]: {
            endpoint: id ? `api/v1/offer/products/promotions/${id}` : `api/v1/offer/products/promotions/`,
            headers: { "Content-Type": "application/json" },
            method: id ? "PUT" : "POST",
            types: asCleanFormDataTypes(
                asAsyncFormTypes([
                    types.createOrUpdatePromotion.request,
                    types.createOrUpdatePromotion.success,
                    types.createOrUpdatePromotion.failure,
                ])
            ),
            body: JSON.stringify(values),
        },
    });
};
