import GridContainer from "components/Grid/GridContainer";
import TextWithLabel from "components/Text/TextWithLabel";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { Fragment } from "react";
import { Grid } from "@mui/material";
import { chain } from "lodash";
import { common, customers } from "translations";

const PersonalInfo = ({ customerInfo, customerMembership, customerBalance, segments }) => {
    const { fullName, email, phoneNumber, mobileNumber, accountNumbers = {} } = customerInfo;
    const { FRS: accFrs, F24: accF24 } = accountNumbers;
    const {
        balanceAmount: { FRS = 0, F24 = 0, _total },
    } = customerBalance;
    const { membershipStatus = {}, seasons = [] } = customerMembership;
    const { segment = "", expiresAt } = membershipStatus;
    const isOfficeAccount = segments?.some((x) => x === "commerce:OfficeAccount");
    const membershipPoints = seasons.map((season) => season.membershipPoints).reduce((prev, next) => prev + next, 0);

    const fields = chain([
        { label: common.fullName, value: fullName, format: formatTypes.fullName, row: 0 },
        { label: common.email, value: email, format: formatTypes.text, row: 0 },
        { label: common.phoneNumber, value: phoneNumber, format: formatTypes.text, row: 2 },
        { label: common.mobileNumber, value: mobileNumber, format: formatTypes.text, row: 3 },
        {
            label: common.membershipStatus,
            value: segment.replace("commerce:", ""),
            format: formatTypes.translatable,
            options: { translationKey: "common" },
            row: 4,
            hidden: isOfficeAccount,
        },
        {
            label: common.membershipPoints,
            value: membershipPoints,
            format: formatTypes.points,
            row: 4,
            hidden: isOfficeAccount,
        },
        {
            label: common.expiresAt,
            value: expiresAt,
            format: formatTypes.time,
            options: { timeFormat: timePrecisonFormats.days },
            row: 4,
            hidden: isOfficeAccount,
        },
        { label: common.balanceTotal, value: _total, format: formatTypes.currency, row: 5 },
        { label: common.balanceFRS, value: FRS, format: formatTypes.currency, row: 5 },
        { label: common.balanceF24, value: F24, format: formatTypes.currency, row: 5 },
        { label: common.accountNumberFrs, value: accFrs, format: formatTypes.text, row: 6 },
        { label: common.accountNumberF24, value: accF24, format: formatTypes.text, row: 7 },
    ])
        .groupBy("row")
        .map((x) => x)
        .value();

    return (
        <GridContainer
            iconName="person"
            title={`customers:${customers.personalInfo}`}
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
        >
            {fields.map((row, i) => (
                <Grid
                    item
                    key={i}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    {row.map((item) =>
                        item.hidden ? (
                            <Fragment key={item.label} />
                        ) : (
                            <Grid
                                item
                                key={item.label}
                            >
                                <TextWithLabel
                                    unselectableLabel
                                    label={item.label}
                                    format={item.format}
                                    value={item.value}
                                    options={item.options}
                                />
                            </Grid>
                        )
                    )}
                </Grid>
            ))}
        </GridContainer>
    );
};

export default PersonalInfo;
