import { RSAA } from "redux-api-middleware";
import { commerce } from "store/actionTypePrefixes";
import { asAsyncFormTypes } from "utils/extensions/arrayExtensions";
import { combineIntoQueryParams } from "utils/objectExtensions";

export const types = {
    GET_CONTESTS_LIST: `${commerce}/GET_CONTESTS_LIST`,
    GET_CONTESTS_LIST_SUCCESS: `${commerce}/GET_CONTESTS_LIST_SUCCESS`,
    GET_CONTESTS_LIST_FAILURE: `${commerce}/GET_CONTESTS_LIST_FAILURE`,
    REMOVE_CONTEST: `${commerce}/REMOVE_CONTEST`,
    REMOVE_CONTEST_SUCCESS: `${commerce}/REMOVE_CONTEST_SUCCESS`,
    REMOVE_CONTEST_FAILURE: `${commerce}/REMOVE_CONTEST_FAILURE`,
};

const getList =
    ({ isActive, ...rest }) =>
    async (dispatch) => {
        let query = { ...rest };
        if (isActive !== "") query.isActive = isActive;
        const queryParams = combineIntoQueryParams({ ...query });
        return dispatch({
            [RSAA]: {
                endpoint: `api/v1/content/contests?${queryParams}`,
                headers: { "Content-Type": "application/json" },
                method: "GET",
                types: [types.GET_CONTESTS_LIST, types.GET_CONTESTS_LIST_SUCCESS, types.GET_CONTESTS_LIST_FAILURE],
            },
        });
    };

const remove = (id) => async (dispatch) => {
    return dispatch({
        [RSAA]: {
            endpoint: `api/v1/content/contests/${id}`,
            headers: { "Content-Type": "application/json" },
            method: "DELETE",
            types: asAsyncFormTypes([types.REMOVE_CONTEST, types.REMOVE_CONTEST_SUCCESS, types.REMOVE_CONTEST_FAILURE]),
        },
    });
};

export const actions = {
    getList,
    remove,
};
