import { RSAA } from "redux-api-middleware";

export default (endpoint, fileType, actions, actionPayload = undefined) => {
    const [init, success, failure] = actions;
    return {
        [RSAA]: {
            endpoint,
            headers: fileType ? { "Content-Type": fileType.header } : {},
            method: "GET",
            types: [
                {
                    type: init.type || init,
                    meta: init.meta,
                    payload: () => actionPayload,
                },
                {
                    type: success.type || success,
                    meta: success.meta,
                    payload: async (_, __, response) => {
                        const blob = await response.blob();
                        return URL.createObjectURL(blob);
                    },
                },
                failure,
            ],
        },
    };
};

export const rsaaActionType = (init, success, failure) => [init, success, failure];
