import { combineIntoQueryParams } from "utils/objectExtensions";
import { downloadXlsxFile } from "utils/rsaa/downloadFileAction";
import moment from "moment";
import types from "./types";

export const getSubsidiaryGainReport = (params) => async (dispatch) => {
    await dispatch(
        downloadXlsxFile(
            `api/vendors/subsidiary-gains/generate-report?${combineIntoQueryParams(params)}`,
            types.generateSubsidiaryGainReport.all,
            `${moment().format("YYYY_MM_DD")}__SG_REPORT`
        )
    );

    return Promise.resolve();
};
