import { FormField } from "components/Form";
import { getVendors, vendorsSelector } from "store/autocomplete";
import { useCommonTranslation } from "utils/hooks";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

const VendorsAutocomplete = (props) => {
    const { t, common } = useCommonTranslation();
    const { showOnlyActiveVendors, showOnlyProducers, showOnlyGoodsVendors, showOnlyGoodsAndTechnicalGoodsVendors } = props;
    const dispatch = useDispatch();
    React.useEffect(() => {
        if (!props.skipInit) {
            dispatch(getVendors());
        }
    }, [dispatch, props.skipInit]);

    const items = useSelector(vendorsSelector);
    const filteredItems = useMemo(() => {
        return items
            .filter((x) => {
                let result = true;
                if (showOnlyActiveVendors) {
                    result = x.isActive;
                }

                if (showOnlyProducers) {
                    result = result && x.isProducer;
                }

                if (showOnlyGoodsVendors) {
                    result = result && x.isGoodsVendor;
                }

                if (showOnlyGoodsAndTechnicalGoodsVendors) {
                    result = result && (x.isTechnicalGoodsVendor || (x.isGoodsVendor && x.isActive));
                }

                return result;
            })
            .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
            .map((x) => {
                return { ...x, key: x.vendorId };
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items?.length, showOnlyActiveVendors, showOnlyGoodsVendors, showOnlyProducers]);

    return (
        <FormField
            {...props}
            fullWidth
            name={props.name}
            type={"autocomplete"}
            label={props.label || t(common.vendor)}
            getOptionLabel={(x) => {
                if (typeof x === "string") {
                    const vendor = filteredItems.find((i) => i.vendorId == x);
                    return vendor ? `${vendor.name}(${x})` : props.freeSolo ? x : "";
                } else {
                    return x.name ? `${x.name}(${x.vendorId})` : "";
                }
            }}
            items={filteredItems}
        />
    );
};

export default VendorsAutocomplete;
