import { tValidation, validation } from "utils-ts/validations/translation";

export default class ValidationError {
    constructor(error = {}) {
        this.propertyName = error.propertyName;
        this.errorMessage = error.errorMessage;
        this.attemptedValue = error.attemptedValue;
        this.severity = error.severity;
        this.errorCode = error.errorCode;
        this.formattedMessagePlaceholderValues = new FormattedMessage(error.formattedMessagePlaceholderValues);
        this.customState = error.customState;
    }

    GetErrorMessage() {
        const {
            comparisonValue,
            minLength,
            maxLength,
            expectedPrecision,
            from,
            to,
            effectType,
            price,
            fields = [],
            modifiers = [],
            usedModifiers = [],
        } = this.customState || this.formattedMessagePlaceholderValues;

        if (tValidation(this.errorCode, {}, "commerceErrorCodes") !== this.errorCode) {
            let length = "";
            if (minLength && maxLength) {
                if (minLength === maxLength) {
                    length = `dokładnie ${minLength}`;
                } else {
                    length = `od ${minLength} do ${maxLength}`;
                }
            } else if (minLength) {
                length = `co najmniej ${minLength}`;
            } else if (maxLength) {
                length = `maksymalnie ${maxLength}`;
            }

            return tValidation(
                this.errorCode,
                {
                    modifiers: modifiers.map((o) => tValidation(o, {}, "common")).join(", "),
                    usedModifiers: usedModifiers.map((o) => tValidation(o, {}, "common")).join(", "),
                    length: length,
                    effectType: tValidation(effectType, {}, "common"),
                    fields: fields.map((o) => tValidation(o, {}, "common")).join(", "),
                    price: price,
                },
                "commerceErrorCodes"
            );
        }

        let error = "";
        switch (this.errorCode) {
            case "EmailValidator":
                error = tValidation(validation.email);
                break;
            case "GreaterThanOrEqualValidator":
                error = tValidation(validation.greaterOrEqualsThan, {
                    number: comparisonValue,
                });
                break;
            case "GreaterThanValidator":
                error = tValidation(validation.greaterThan, {
                    number: comparisonValue,
                });
                break;
            case "LengthValidator":
            case "Length_Simple":
                error = tValidation(validation.inRange, {
                    min: minLength,
                    max: maxLength,
                });
                break;
            case "LessThanOrEqualValidator":
                error = tValidation(validation.lessOrEqualsThan, {
                    number: comparisonValue,
                });
                break;
            case "LessThanValidator":
                error = tValidation(validation.lessThan, {
                    number: comparisonValue,
                });
                break;
            case "NotEmptyValidator":
            case "NotNullValidator":
                error = tValidation(validation.notEmpty);
                break;
            case "EqualValidator":
                error = tValidation(validation.mustContains, {
                    what: comparisonValue,
                });
                break;
            case "ScalePrecisionValidator":
                error = tValidation(validation.mustHaveLessDecimalCount, {
                    expectedPrecision,
                });
                break;
            case "EmptyValidator":
            case "NullValidator":
                error = tValidation(validation.empty);
                break;
            case "ExactLengthValidator":
            case "ExactLength_Simple":
                error = tValidation(validation.length, {
                    number: maxLength,
                });
                break;
            case "MinimumLength_Simple":
            case "MinimumLengthValidator":
                error = tValidation(validation.minLength, {
                    min: minLength,
                });
                break;
            case "MaximumLength_Simple":
            case "MaximumLengthValidator":
                error = tValidation(validation.maxLength, {
                    max: maxLength,
                });
                break;
            case "NotEqualValidator":
                error = tValidation(validation.diffrentLength, {
                    number: comparisonValue,
                });
                break;
            case "InclusiveBetween_Simple":
            case "InclusiveBetweenValidator":
                error = tValidation(validation.between, {
                    min: from,
                    max: to,
                });
                break;
            case "ExclusiveBetweenValidator":
                error = tValidation(validation.between, {
                    min: from - 1,
                    max: to + 1,
                });
                break;
            case "RegularExpressionValidator":
                error = tValidation(validation.formatRegex);
                break;
            case "CreditCardValidator":
                error = tValidation(validation.creditCardRegex);
                break;
            case "EnumValidator":
            case "PredicateValidator":
            case "AsyncPredicateValidator":
            default:
                error = this.errorMessage;
                break;
        }

        return error;
    }

    GetPropertyPath() {
        let path = this.propertyName;
        if (path.includes(".")) {
            let props = path.split(".");
            path = props[1];
            path = path.charAt(0).toLowerCase() + path.slice(1);
            for (let j = 2; j < props.length; j++) {
                path += "." + props[j].charAt(0).toLowerCase() + props[j].slice(1);
            }
        }

        return path;
    }

    GetPropertyName() {
        return tValidation(this.propertyName.split(".").slice(-1), {}, "common");
    }
}

class FormattedMessage {
    constructor(message = {}) {
        this.comparisonValue = message.ComparisonValue;
        this.minLength = message.MinLength;
        this.maxLength = message.MaxLength;
        this.expectedPrecision = message.ExpectedPrecision;
        this.from = message.From;
        this.to = message.To;
    }
}
