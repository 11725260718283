import { RSAA_ENDED, RSAA_STARTED } from "./actions";

const initialState = {
    inProgress: false,
    started: [],
};

export const progress = (state = initialState, action) => {
    state = state || initialState;
    switch (action.type) {
        case RSAA_STARTED:
            return { ...state, inProgress: true, started: [...state.started, action.payload] };
        case RSAA_ENDED:
            const left = state.started.filter((x) => {
                const startedAction = x.endsWith("_REQUEST") ? x.slice(0, -"_REQUEST".length) : x;
                return !(action.payload === startedAction + "_SUCCESS" || action.payload === startedAction + "_FAILURE");
            });
            return { ...state, inProgress: left.length > 0, started: left };
        default:
            return state;
    }
};
