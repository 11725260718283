export const decimalNormalize = (value) => {
    return value && typeof value === "string"
        ? value
              .replace(/[\s]/g, "")
              .replace(/(?![-0-9.,]).*/g, "")
              .replace(",", ".")
              .replace(/^([^.]*\.)(.*)$/, function (_, b, c) {
                  return b + c.replace(/\./g, "");
              })
              .replace(/^([^-]*-)(.*)$/, function (_, b, c) {
                  return b + c.replace(/-/g, "");
              })
        : value;
};

export const integerNormalize = (value) => (value && typeof value === "string" ? value.replace(/[\s]/g, "").replace(/(?![-0-9]).*/g, "") : value);
