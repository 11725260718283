import React from "react";
import { Grid } from "@mui/material";
import { BalanceAmount } from "Shared";

const currencyAmountHelper = (text: string, value: number | undefined = 0, isLost: boolean | undefined = false): React.ReactNode => {
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
        >
            <Grid
                item
                xs="auto"
            >
                <strong>
                    {text}:{text?.length < 4 ? "\u00A0\u00A0\u00A0" : ""}
                </strong>
                &nbsp;
                {value !== 0 ? isLost ? <s>{value}&nbsp;zł</s> : <>{value}&nbsp;zł</> : ""}
            </Grid>
        </Grid>
    );
};

const BalanceAmountCell: React.FC<{ value: BalanceAmount }> = ({ value }) => {
    return (
        <>
            {currencyAmountHelper("Suma", value._total)}
            {currencyAmountHelper("FRS", value.FRS)}
            {currencyAmountHelper("F24", value.F24)}
        </>
    );
};

export default BalanceAmountCell;
