import { IconButton } from "components/Button";
import { removeMessage } from "store/messaging/actions";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

let displayed = [];

const Messages = () => {
    const messages = useSelector((state) => state.messaging);
    const dispatch = useDispatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const storeDisplayed = (id) => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = (id) => {
        displayed = [...displayed.filter((key) => id !== key)];
    };

    const action = useCallback(
        (key) => {
            return (
                <IconButton
                    color="#fff"
                    icon="delete"
                    onClick={() => {
                        closeSnackbar(key);
                    }}
                />
            );
        },
        [closeSnackbar]
    );

    useEffect(() => {
        messages.forEach(({ id, message, variant, options = {} }) => {
            if (displayed.includes(id)) return;

            enqueueSnackbar(message, {
                key: id,
                variant,
                ...options,
                autoHideDuration: 3500,
                TransitionProps: {
                    onClose: (event, reason, myKey) => {
                        if (options.onClose) {
                            options.onClose(event, reason, myKey);
                        }
                    },
                    onExited: (_, myKey) => {
                        dispatch(removeMessage(myKey));
                        removeDisplayed(myKey);
                    },
                },
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
                action,
            });
            storeDisplayed(id);
        });
    }, [messages, closeSnackbar, enqueueSnackbar, dispatch, action]);

    return null;
};

export default Messages;
