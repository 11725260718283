import { AccountingPeriodAlert } from "components/Alerts";
import roles from "consts/roles";
import { findAllAccountingPeriods } from "store/accountingPeriods";
import { getTurnover, turnoverFormSelector } from "store/vendors/turnovers";
import { timePrecisonFormats } from "utils/formating";
import { useParams, useUserAccountingPeriods } from "utils/hooks";
import { isAccountingPeriodOpen } from "utils/utilsFunctions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useUser } from "context";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";
import VendorLayout from "views/vendors/components/VendorLayout";
import { DetailsForm, Summary } from "views/vendors/components/turnover";

const turnoverEditRoles = [roles.Catman, roles.Auditor, roles.SalesAssistant, roles.SalesManager];

const Turnover = () => {
    const { t } = useTranslation("vendor");
    const dispatch = useDispatch();
    const { isInRoleOrAdmin, isInAnyRoleOrAdmin, isAdmin } = useUser();
    const isInEditRoles = isInAnyRoleOrAdmin(turnoverEditRoles);
    const isAuditor = isInRoleOrAdmin(roles.Auditor);
    const { vendorId, id } = useParams();
    const { details, year, month, isLoading, ...rest } = useSelector(turnoverFormSelector);
    const { accountingPeriods } = useUserAccountingPeriods(vendorId);
    const date = moment(`1.${month}.${year}`, timePrecisonFormats.days);
    const isPeriodOpen = isAccountingPeriodOpen(accountingPeriods, date);
    const canEdit = (vendorSummary) =>
        (isInEditRoles && isPeriodOpen) || (!isPeriodOpen && isAuditor && (!!!vendorSummary || vendorSummary === "Brak danych")) || isAdmin;

    useEffect(() => {
        dispatch(getTurnover(vendorId, id));
    }, [vendorId, id, dispatch]);

    useEffect(() => {
        dispatch(findAllAccountingPeriods());
    }, [dispatch]);

    return (
        <VendorLayout
            headerText={t(vendor.turnoverForm, {
                year,
                month,
            })}
            vendorId={vendorId}
            isLoading={isLoading}
            tabIndex={11}
            customTitle={`${vendorId} ${t(vendor.turnoverForm, {
                year,
                month,
            })}`}
        >
            <AccountingPeriodAlert
                accountingPeriods={accountingPeriods}
                date={date.endOf("month")}
            />
            <Summary
                summary={rest}
                isPeriodOpen={isPeriodOpen}
                canEdit={canEdit}
            />
            <DetailsForm
                details={details}
                isPeriodOpen={isPeriodOpen}
                initialValues={details.reduce((acc, current, _) => {
                    acc[current.key] = current;
                    return acc;
                }, {})}
                canEdit={canEdit}
            />
        </VendorLayout>
    );
};

export default Turnover;
