import { ValidationFailure } from "Shared";
import { tValidation, validation } from "./translation";

export const GetErrorText = (error: ValidationFailure) => {
    const errorState = error.customState || error.formattedMessagePlaceholderValues || {};
    if (tValidation(error.errorCode, {}, "commerceErrorCode") !== error.errorCode) {
        let length = "";
        if (errorState.inLength && errorState.maxLength) {
            if (errorState.minLength === errorState.maxLength) {
                length = `dokładnie ${errorState.minLength}`;
            } else {
                length = `od ${errorState.minLength} do ${errorState.maxLength}`;
            }
        } else if (errorState.minLength) {
            length = `co najmniej ${errorState.minLength}`;
        } else if (errorState.maxLength) {
            length = `maksymalnie ${errorState.maxLength}`;
        }

        return tValidation(
            error.errorCode,
            {
                modifiers: (errorState.modifiers as Array<string>).map((o: string) => tValidation(o, {}, "common")).join(", "),
                usedModifiers: (errorState.usedModifiers as Array<string>).map((o: string) => tValidation(o, {}, "common")).join(", "),
                length: length,
                effectType: tValidation(errorState.effectType as string, {}, "common"),
                fields: (errorState.fields as Array<string>).map((o: string) => tValidation(o, {}, "common")).join(", "),
                price: errorState.price,
            },
            "commerceErrorCode"
        );
    }

    switch (error.errorCode) {
        case "EmailValidator":
            return tValidation(validation.email);
        case "GreaterThanOrEqualValidator":
            return tValidation(validation.greaterOrEqualsThan, {
                number: errorState.ComparisonValue,
            });
        case "GreaterThanValidator":
            return tValidation(validation.greaterThan, {
                number: errorState.ComparisonValue,
            });
        case "LengthValidator":
        case "Length_Simple":
            return tValidation(validation.inRange, {
                min: errorState.MinLength,
                max: errorState.MaxLength,
            });
        case "LessThanOrEqualValidator":
            return tValidation(validation.lessOrEqualsThan, {
                number: errorState.ComparisonValue,
            });
        case "LessThanValidator":
            return tValidation(validation.lessThan, {
                number: errorState.ComparisonValue,
            });
        case "NotEmptyValidator":
        case "NotNullValidator":
            return tValidation(validation.notEmpty);
        case "EqualValidator":
            return tValidation(validation.mustContains, {
                what: errorState.ComparisonValue,
            });
        case "ScalePrecisionValidator":
            return tValidation(validation.mustHaveLessDecimalCount, {
                expectedPrecision: errorState.ExpectedPrecision,
            });
        case "EmptyValidator":
        case "NullValidator":
            return tValidation(validation.empty);
        case "ExactLengthValidator":
        case "ExactLength_Simple":
            return tValidation(validation.length, {
                number: errorState.MaxLength,
            });
        case "MinimumLength_Simple":
        case "MinimumLengthValidator":
            return tValidation(validation.minLength, {
                min: errorState.MinLength,
            });
        case "MaximumLength_Simple":
        case "MaximumLengthValidator":
            return tValidation(validation.maxLength, {
                max: errorState.MaxLength,
            });
        case "NotEqualValidator":
            return tValidation(validation.diffrentLength, {
                number: errorState.ComparisonValue,
            });
        case "InclusiveBetween_Simple":
        case "InclusiveBetweenValidator":
            return tValidation(validation.between, {
                min: errorState.From,
                max: errorState.To,
            });
        case "ExclusiveBetweenValidator":
            return tValidation(validation.between, {
                min: (errorState.From as number) - 1,
                max: (errorState.To as number) + 1,
            });
        case "RegularExpressionValidator":
            return tValidation(validation.formatRegex);
        case "CreditCardValidator":
            return tValidation(validation.creditCardRegex);
        case "EnumValidator":
        case "PredicateValidator":
        case "AsyncPredicateValidator":
        default:
            return error.errorMessage;
    }
};
