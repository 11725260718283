import { crawler, operations } from "store/actionTypePrefixes";
import { AsyncTypes, PagedAsyncTypes } from "utils/extensions";

export const types = {
    countComplaints: new PagedAsyncTypes("GET_COMPLAINTS_COUNT", operations),
    complaintsWithError: new AsyncTypes("GET_COMPLAINTS_WITH_ERRORS", operations),
    retryComplaintWithError: new AsyncTypes("POST_RETRY_COMPLAINT_WITH_ERROR", operations),
    priceListOperationCounter: new AsyncTypes("GET_PRICELIST_OPERATION_COUNT", operations),
    getShopScanSummary: new AsyncTypes("GET_SHOP_SCAN_SUMMARY", crawler),
    getArchiveErrors: new AsyncTypes("GET_ARCHIVE_ERRORS", operations),
    reprocessArchiveError: new AsyncTypes("REPROCESS_ARCHIVE_ERROR", operations),
};
