import defaultListState from "store/defaultListState";
import { types } from "./types";

const initialState = {
    ...defaultListState,
    filters: {
        brandId: null,
        name: null,
        description: null,
        isSubbrand: null,
    },
};

export const list = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.findBrands.request:
            return { ...state, isLoading: true };
        case types.findBrands.success:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case types.findBrands.failure:
            return { ...state, isLoading: false };
        case types.updateQuery: {
            return { ...state, ...payload };
        }
        default:
            return state;
    }
};
