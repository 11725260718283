import { Button } from "components/Button";
import { formatTypes, formatValue, timePrecisonFormats } from "utils/formating";
import { useState } from "react";
import { Collapse, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";

const CorrectionsTable = ({ corrections }) => {
    const { t } = useTranslation("vendor");
    const [open, setOpen] = useState(false);

    return (
        <>
            {corrections.length > 0 ? (
                <>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-start"
                    >
                        <Grid
                            item
                            style={{ marginBottom: 8, marginRight: 8 }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setOpen(!open);
                                }}
                            >
                                {open ? "Ukryj korekty" : "Pokaż korekty"}
                            </Button>
                        </Grid>
                    </Grid>
                    <Collapse
                        in={open}
                        timeout="auto"
                        unmountOnExit
                    >
                        <Paper component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t(vendor.dateOfCorrection)}</TableCell>
                                        <TableCell>{t(vendor.correctedBy)}</TableCell>
                                        <TableCell align="right">{t(vendor.oldVendorSummary)}</TableCell>
                                        <TableCell align="right">{t(vendor.newVendorSummary)}</TableCell>
                                        <TableCell align="right">{t(vendor.vendorSummaryDifference)}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {corrections.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                {formatValue(row.correctedAt, formatTypes.time, {
                                                    timeFormat: timePrecisonFormats.seconds,
                                                })}
                                            </TableCell>
                                            <TableCell>{formatValue(row.correctedByEmail, formatTypes.text)}</TableCell>
                                            <TableCell align="right">{formatValue(row.oldVendorSummary, formatTypes.currency)}</TableCell>
                                            <TableCell align="right">{formatValue(row.newVendorSummary, formatTypes.currency)}</TableCell>
                                            <TableCell align="right">
                                                {formatValue(row.vendorSummaryDifference, formatTypes.currency, { diff: true })}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Paper>
                    </Collapse>
                </>
            ) : (
                <></>
            )}
        </>
    );
};

export default CorrectionsTable;
