import defaultListState from "store/defaultListState";
import types from "./types";

const initialState = defaultListState;
const { getList } = types;

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case getList.request:
            return { ...initialState, isLoading: true };
        case getList.success:
            return { ...state, ...payload, isLoading: false };
        case getList.failure:
            return { ...payload, isLoading: false };
        default:
            return state;
    }
};
