import { FieldPath, FieldValues } from "react-hook-form";
import { FormControlProps } from "./types";
import { SignaturePad } from "components-ts/controls";
import { default as Controller } from "./Controller";

type FormSignaturePad<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> = FormControlProps<string, TFieldValues, TName> & {};

const FormSignaturePad = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
    name,
    label,
    defaultValue,
    emptyValue,
    width,
    readOnly,
}: FormSignaturePad<TFieldValues, TName>): JSX.Element => {
    return (
        <Controller
            name={name}
            render={({ field: { ref, onChange, onBlur, value, disabled: _, name }, fieldState: { error } }) => {
                return (
                    <SignaturePad
                        value={value}
                        onChange={(newValue) => onChange(newValue)}
                        error={{
                            hasError: Boolean(error),
                            message: error?.message,
                        }}
                        label={label}
                        readOnly={readOnly}
                        width={width !== "auto" && width !== "fullWidth" ? width : undefined}
                    />
                );
            }}
            defaultValue={defaultValue}
            transform={(val) => (val ? val : emptyValue)}
            width={width}
        />
    );
};

export default FormSignaturePad;
