import { commerce, manager } from "store/actionTypePrefixes";
import { AsyncTypes, asAsyncFormTypes, createAction } from "utils/extensions";

const types = {
    findBalanceDebts: new AsyncTypes("FIND_BALANACE_DEBTS", commerce),
    changePagination: createAction("CHANGE_BALANCE_DEBTS_PAGINATION", commerce),
    getBalanceDebt: new AsyncTypes("GET_BALANCE_DEBT", commerce),
    takeAction: new AsyncTypes("TAKE_BALANCE_DEBT_ACTION", commerce, [asAsyncFormTypes]),
    editAction: new AsyncTypes("EDIT_BALANCE_DEBT_ACTION", commerce, [asAsyncFormTypes]),
    clearBalanceDebt: createAction("CLEAR_BALANCE_DEBT_FORM", manager),
    clearBalanceDebts: createAction("CLEAR_BALANCE_DEBT_LIST", manager),
    updateFilterValues: createAction("UPDATE_FILTER_VALUES_DEBT_LIST", manager),
    muteBalanceDebt: new AsyncTypes("MUTE_BALANCE_DEBT", commerce),
    getBalanceDebtActivity: new AsyncTypes("GET_BALANCE_DEBT_ACTIVITY", commerce),
    deleteActivity: new AsyncTypes("DELETE_BALANCE_DEBT_ACTIVITY", commerce, [asAsyncFormTypes]),
    findUserBalanceDebtActivities: new AsyncTypes("FIND_USER_BALANCE_DEBT_ACTIVITIES", commerce),
};

export default types;
