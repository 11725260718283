import Layout from "components/Grid/Layout";
import TableWithToggleFilter from "components/Table/TableWithToggleFilter/TableWithToggleFilter";
import { actions } from "store/dictonaries/cnStakes/list/actions";
import { useDebouncedCallback } from "use-debounce";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useCommonTranslation } from "utils/hooks";
import { useState } from "react";
import { usePush } from "utils-ts/hooks";
import { cnStakesListSelector } from "./selectors";

const CostReasonList = () => {
    const resetFilter = {
        cnStake: undefined,
    };
    const [filter, setFilter] = useState(resetFilter);
    const { t, common } = useCommonTranslation();
    const { push } = usePush();
    const pushToForm = (id) => push(id ? `/dictionaries/cn-stakes/form/${id}` : "/dictionaries/cn-stakes/form");

    const columns = [
        {
            key: "cnStake",
            value: common.cnStake,
            type: formatTypes.text,
            filtrable: true,
        },
        {
            key: "description",
            value: common.description,
            type: formatTypes.text,
        },
        { key: "isActive", value: common.active, type: formatTypes.boolean },
        {
            key: "createdAt",
            value: common.createdAt,
            type: formatTypes.time,
            options: { timeFormat: timePrecisonFormats.seconds },
        },
        {
            key: "updatedAt",
            value: common.updatedAt,
            type: formatTypes.time,
            options: { timeFormat: timePrecisonFormats.seconds },
        },
        {
            key: "action",
            value: "",
            type: formatTypes.action,
            actionType: "preview",
            action(item) {
                pushToForm(item.id);
            },
        },
    ].map((col) => ({ ...col, value: t(col.value) }));

    const handleFilter = useDebouncedCallback((column, value) => {
        if (column === undefined && value === undefined) {
            setFilter(resetFilter);
            return;
        }
        setFilter({ ...filter, [column]: value });
    }, 1000);

    return (
        <Layout
            main
            headerText={t(common.cnStakes)}
        >
            <TableWithToggleFilter
                columns={columns}
                addItemAction={() => pushToForm()}
                dataSelector={cnStakesListSelector}
                dataSourceAction={actions.getList}
                initialFilterValue={resetFilter}
                filterValue={filter}
                filterFunction={handleFilter}
            />
        </Layout>
    );
};

export default CostReasonList;
