import { createSelector } from "reselect";

const root = ({ customer: { customerBalanceAudit }, cache: { userInfos } }) => ({
    customerBalanceAudit,
    userInfos,
});

export const customerBalanceAuditSelector = createSelector(root, ({ customerBalanceAudit, userInfos }) => {
    const balanceAudit = {
        ...customerBalanceAudit,
        items:
            customerBalanceAudit.items.map((b) => {
                const { email } = userInfos.find((x) => x.userId === b.createdBy) || {};
                return {
                    ...b,
                    ...b.document,
                    createdBy: email || b.createdBy,
                    createdAt: b.createdAt,
                };
            }) || [],
    };

    return balanceAudit;
});
