import StepInput from "components/Controls/StepInput/StepInput";
import React from "react";

class NumberField extends React.Component {
    constructor(props) {
        super(props);
        const { step } = props;
        this.step = Boolean(step) || step === 0 ? step : 1;
    }

    round = (val) => Math.round((val + Number.EPSILON) * 100) / 100;

    increment = (value) => {
        let val = typeof value === "string" ? value.replace(",", ".") : value;
        if (!val) {
            val = 0;
        }
        return this.round(Number(parseFloat(val)) + this.step);
    };

    decrement = (value) => {
        let val = typeof value === "string" ? value.replace(",", ".") : value;
        if (!val) {
            val = 0;
        }
        return this.round(Number(parseFloat(val)) - this.step);
    };

    handleOnBlur = (event) => {
        event.persist();
        const value = event.target.value;
        const val = typeof value === "string" ? value.replace(",", ".") : value;
        this.props.input.onBlur(val);
    };

    render() {
        return (
            <StepInput
                {...this.props}
                increment={this.increment}
                decrement={this.decrement}
                onBlur={this.handleOnBlur}
            />
        );
    }
}

export default NumberField;
