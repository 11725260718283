import { createSelector } from "reselect";
import { DeliveryCostForm } from "./model";

const deliveryCostsSelector = (state) => state.deliveryCosts;

export const deliveryCostsListSelector = createSelector(deliveryCostsSelector, (deliveryCosts) => deliveryCosts.list);

export const deliveryCostsFormSelector = createSelector(deliveryCostsSelector, (deliveryCosts) => new DeliveryCostForm(deliveryCosts.form));

// export const orderRequirementsSelector = state =>
//     formValueSelector(formName)(
//         state,
//         "requirements.user.orders.orderRequirements"
//     );

// export const segmentsSelector = state =>
//     formValueSelector(formName)(state, "requirements.user.segments[0]");

// export const deliveryPredicatesSelector = state =>
//     formValueSelector(formName)(state, "requirements.delivery");
