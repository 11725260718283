import { timePrecisonFormats } from "utils/formating";
import Validator, { notEmptyArrayValidator, notEmptyValidator, stringLength } from "utils/validators/basic";
import moment from "moment";
import { get, uniqBy } from "lodash";
import { tValidation, validation } from "utils-ts/validations/translation";

const validators = {
    required: (value) => notEmptyValidator(value).validate(),
    month: (value) => {
        return notEmptyValidator(value).validate();
    },
    dateFrom: (value, formValues) => {
        const { dateTo } = formValues;
        return notEmptyValidator(value)
            .must(
                (val) => moment(val).isSameOrBefore(dateTo),
                tValidation(validation.lessOrEqualsDateThan, {
                    date: moment(dateTo).subtract(1, "day").format(timePrecisonFormats.days),
                })
            )
            .when(Boolean(value) && Boolean(dateTo))
            .validate();
    },
    dateTo: (value, formValues) => {
        const { dateFrom } = formValues;
        return notEmptyValidator(value)
            .must(
                (val) => moment(val).isSameOrAfter(dateFrom),
                tValidation(validation.greaterOrEqualsDateThan, {
                    date: moment(dateFrom).add(1, "day").format(timePrecisonFormats.days),
                })
            )
            .when(Boolean(value) && Boolean(dateFrom))
            .validate();
    },
    value: (value, formValues) => {
        const { settlementPeriod } = formValues;
        return notEmptyValidator(value)
            .when(settlementPeriod === "Current")
            .validate();
    },
    purchaseOrderNumber: (value, formValues) => {
        const { isPurchaseOrderRequired } = formValues;
        return notEmptyValidator(value)
            .when(isPurchaseOrderRequired)
            .must(stringLength(1, 90), tValidation(validation.stringLength, { min: 1, max: 90 }))
            .when(value)
            .validate();
    },
    comment: (value) =>
        new Validator(value)
            .must(stringLength(0, 1000), tValidation(validation.stringLength, { min: 0, max: 1000 }))
            .when(value)
            .validate(),
    atLeastOne: (array) => {
        return new Validator(array).must((d) => d?.length > 0, tValidation(validation.notEmptyArray, { min: 1 })).validate();
    },
    serviceDateFrom: (value, formValues, _, fieldName) => {
        const section = get(formValues, fieldName.substring(0, fieldName.lastIndexOf("."))) || {};
        const { dateTo } = section;
        return notEmptyValidator(value)
            .must(
                (val) => moment(val).isSameOrBefore(dateTo),
                tValidation(validation.lessOrEqualsDateThan, {
                    date: moment(dateTo).subtract(1, "day").format(timePrecisonFormats.days),
                })
            )
            .when(Boolean(value) && Boolean(dateTo))
            .validate();
    },
    serviceDateTo: (value, formValues, _, fieldName) => {
        const { dateTo: rootDateTo, dateFrom: rootDateFrom } = formValues;
        const section = get(formValues, fieldName.substring(0, fieldName.lastIndexOf("."))) || {};
        const { dateFrom } = section;

        return notEmptyValidator(value)
            .must(
                (val) => moment(val).isSameOrAfter(dateFrom),
                tValidation(validation.greaterOrEqualsDateThan, {
                    date: moment(dateFrom).add(1, "day").format(timePrecisonFormats.days),
                })
            )
            .when(Boolean(value) && Boolean(dateFrom))
            .must(
                (val) => moment(val).isSameOrBefore(rootDateTo),
                tValidation(validation.lessOrEqualsDateThan, {
                    date: moment(rootDateTo).format(timePrecisonFormats.days),
                })
            )
            .when(Boolean(value) && Boolean(rootDateTo))
            .must(
                (val) => moment(val).isSameOrAfter(rootDateFrom),
                tValidation(validation.greaterOrEqualsDateThan, {
                    date: moment(rootDateFrom).format(timePrecisonFormats.days),
                })
            )
            .when(Boolean(value) && Boolean(rootDateTo))
            .validate();
    },
    uniqBy: (fieldNames) => (array) => {
        return new Validator(array)
            .must(
                (values) => fieldNames.some((fieldName) => uniqBy(values, fieldName).length === values.length),
                tValidation(validation.arrayMustContainsUniqueValues)
            )
            .when(!!array)
            .validate();
    },
    arrayRequired: (value) => notEmptyArrayValidator(value).validate(),
};

export { validators };
