import { types as rootTypes } from "store/customers/customer/action";
import { types } from "./action";

const initialState = {
    isExecuting: false,
    customerShippingAddresses: [],
};

const defaultAction = (state) => ({ ...state });

export default (state = initialState, action) => {
    const { type } = action;

    switch (type) {
        case rootTypes.CUSTOMER_CHANGED:
            return initialState;
        case types.CUSTOMER_DELETE:
            return { ...state, isExecuting: true };
        case types.CUSTOMER_DELETE_SUCCESS:
        case types.CUSTOMER_DELETE_FAILURE:
            return { ...state, isExecuting: false };
        case types.CUSTOMER_LOGIN_AS_CUSTOMER:
            return { ...state, isExecuting: true };
        case types.CUSTOMER_LOGIN_AS_CUSTOMER_SUCCESS:
        case types.CUSTOMER_LOGIN_AS_CUSTOMER_FAILURE:
            return { ...state, isExecuting: false };
        case types.CUSTOMER_PUT:
            return { ...state, putCustomerInProgress: true };
        case types.CUSTOMER_PUT_SUCCESS:
        case types.CUSTOMER_PUT_FAILURE:
            return { ...state, putCustomerInProgress: false };
        case types.CUSTOMER_GET_SHIPPING_ADRESSES_SUCCESS:
            return { ...state, customerShippingAddresses: action.payload };
        case types.CUSTOMER_EXPORT_TO_AX:
            return { ...state, isExecuting: true };
        case types.CUSTOMER_EXPORT_TO_AX_SUCCESS:
        case types.CUSTOMER_EXPORT_TO_AX_FAILURE:
            return { ...state, isExecuting: false };
        default:
            return defaultAction(state);
    }
};
