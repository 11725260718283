import { types } from "./actions";

const initialState = {
    windows: [],
    isLoading: false,
};

export const deliveryWindows = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case types.DELIVERY_WINDOW_GET:
            return {
                ...state,
                isLoading: true,
            };
        case types.DELIVERY_WINDOW_GET_SUCCESS:
            return {
                ...state,
                windows: payload,
                isLoading: false,
            };
        case types.DELIVERY_WINDOW_GET_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return { ...state };
    }
};
