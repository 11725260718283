import { tValidation, validation } from "utils-ts/validations/translation";
import { notEmptyValidator, stringLength } from "./basic";

export default function fullNameValidator(fullName = {}) {
    const errors = {};
    const { firstName, lastName } = fullName;
    errors["firstName"] = notEmptyValidator(firstName)
        .must(stringLength(0, 100), tValidation(validation.stringLength, { min: 0, max: 100 }))
        .validate();
    errors["lastName"] = notEmptyValidator(lastName)
        .must(stringLength(0, 100), tValidation(validation.stringLength, { min: 0, max: 100 }))
        .validate();

    return errors;
}
