import Cell from "components/Table/SimpleTable/Cell";
import Row from "components/Table/SimpleTable/Row";
import { withStyles } from "tss-react/mui";
import { formatValue } from "utils/formating";
import moment from "moment";

const CustomerMembershipRow = ({ columns, item, classes }) => {
    const isExpired = moment(item.expiresAt, "DD.MM.YYYY").isBefore(moment());
    const className = isExpired ? classes.expired : item.index % 2 === 0 ? classes.stripped : classes.defaultRow;

    return (
        <Row className={className}>
            {columns.map(({ key, type, options }, index) => {
                return (
                    <Cell
                        key={index}
                        value={formatValue(item[key], type, options)}
                    />
                );
            })}
        </Row>
    );
};

export default withStyles(CustomerMembershipRow, () => ({
    expired: {
        position: "relative",
        borderBottom: "1px solid #dddddd",
        backgroundColor: "#ffa0a0",
    },
    stripped: {
        position: "relative",
        borderBottom: "1px solid #dddddd",
        backgroundColor: "#f9f9f9",
    },
    defaultRow: {
        position: "relative",
        borderBottom: "1px solid #dddddd",
    },
}));
