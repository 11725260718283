import { FormField } from "components/Form";
import packagingMethods from "resource/packagingMethods.json";
import { useCommonTranslation } from "utils/hooks";

const PackagingMethodsSelectField = (props) => {
    const { t, common } = useCommonTranslation();

    return (
        <FormField
            {...props}
            name={"packaging"}
            label={t(common.packagingMethods)}
            type="select"
            multiple
            items={packagingMethods}
        />
    );
};

export default PackagingMethodsSelectField;
