import { manager, operations } from "store/actionTypePrefixes";
import { AsyncTypes, asAsyncFormTypes, createAction } from "utils/extensions";

const types = {
    formInit: createAction("FORM_INIT_EMPLOYEE_PROCEDURE", manager),
    findUnacceptedEmployeeProcedures: new AsyncTypes("FIND_UNACCEPTED_EMPLOYEE_PROCEDURES", operations),
    findEmployeeProcedures: new AsyncTypes("FIND_EMPLOYEE_PROCEDURES", operations),
    getEmployeeProcedure: new AsyncTypes("GET_EMPLOYEE_PROCEDURE", operations),
    makeDecision: new AsyncTypes("EMPLOYEE_PROCEDURE_MAKE_DECISION", operations, [asAsyncFormTypes]),
    locationChanged: createAction("EMPLOYEE_PROCEDURE_LOCATION_CHANGED", manager),
};

export default types;
