import defaultListState from "store/defaultListState";
import { combineReducers } from "redux";
import types from "./types";

const listInitialState = {
    ...defaultListState,
};

const formInitialState = {
    isLoading: false,
    isSubbmitRequested: false,
};

const list = (state = listInitialState, { type, payload }) => {
    switch (type) {
        case types.listInit:
            return { ...listInitialState };
        case types.getMarketingBudgets.request:
            return { ...state, isLoading: true };
        case types.getMarketingBudgets.success:
            return { isLoading: false, ...payload };
        case types.getMarketingBudgets.failure:
            return { ...state, isLoading: false };
        case types.getBudgetsForReservationKit.request:
        case types.getBudgetsForReservationKit.failure:
            return { items: [] };
        case types.getBudgetsForReservationKit.success:
            return { items: [...payload] };
        default:
            return state;
    }
};

const form = (state = formInitialState, { type, payload, meta }) => {
    switch (type) {
        case types.formInit:
            return { ...formInitialState };
        case types.getMarketingBudget.request:
        case types.createMarketingBudget.request:
        case types.updateMarketingBudget.request:
        case types.makeDecision.request:
            return { isLoading: true };
        case types.getMarketingBudget.success:
        case types.createMarketingBudget.success:
            return { ...payload, isLoading: false };
        case types.updateMarketingBudget.success:
        case types.makeDecision.success:
        case types.getMarketingBudget.failure:
        case types.createMarketingBudget.failure:
        case types.updateMarketingBudget.failure:
        case types.makeDecision.failure:
            return { isLoading: false };
        default:
            return state;
    }
};

export const marketingBudgets = combineReducers({ form, list });
