import { GetRequest, PutRequest, get, put } from "utils/extensions";
import { downloadPdfFile } from "utils/rsaa/downloadFileAction";
import types from "./types";

const endpoint = (vendorId, id) => `api/vendors/${vendorId}/turnovers${id ? `/${id}` : ""}`;

export const findTurnovers = (vendorId, params) => (dispatch) => {
    dispatch(get(new GetRequest(endpoint(vendorId), types.findTurnovers.all, { queryParams: params })));
};

export const changePagination = (pagedCriteria) => ({
    type: types.changePagination,
    payload: {
        ...pagedCriteria,
    },
});

export const setFilters = (filters) => ({
    type: types.setFilters,
    payload: {
        ...filters,
    },
});

export const getTurnover = (vendorId, id) => (dispatch) => {
    dispatch(get(new GetRequest(endpoint(vendorId, id), types.getTurnover.all)));
};

export const updateVendorSummaryTotal = (vendorId, id, values) => async (dispatch) => {
    await dispatch(
        put(
            new PutRequest(`${endpoint(vendorId, id)}/update-turnover-vendor-summary-total`, types.updateVendorSummaryTotal.all, {
                body: {
                    ...values,
                },
            })
        )
    );

    dispatch(getTurnover(vendorId, id));
};

export const updateVendorSummary = (vendorId, id, values) => async (dispatch) => {
    await dispatch(
        put(
            new PutRequest(`${endpoint(vendorId, id)}/update-turnover-detail`, types.updateVendorSummary.all, {
                body: {
                    ...values,
                },
            })
        )
    );

    dispatch(getTurnover(vendorId, id));
};

export const createVendorSummaryCorrection = (vendorId, id, values) => async (dispatch) => {
    await dispatch(
        put(
            new PutRequest(`${endpoint(vendorId, id)}/create-turnover-correction`, types.createTurnoverCorrection.all, {
                body: {
                    ...values,
                },
            })
        )
    );

    dispatch(getTurnover(vendorId, id));
};

export const getTurnoverVendorSummaryFile = (vendorId, id, fileId, fileName) => async (dispatch) => {
    await dispatch(downloadPdfFile(`${endpoint(vendorId, id)}/get-file/${fileId}`, types.downloadFile.all, fileName));

    return Promise.resolve();
};

export const getTurnoverFile = (vendorId, id, key, fileId, fileName) => async (dispatch) => {
    await dispatch(downloadPdfFile(`${endpoint(vendorId, id)}/get-file/${key}/${fileId}`, types.downloadFile.all, fileName));

    return Promise.resolve();
};
