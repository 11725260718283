const documentArchive = {
    addDocument: "addDocument",
    documentFile: "documentFile",
    documentNumber: "documentNumber",
    documentDate: "documentDate",
    scanDate: "scanDate",
    saveDocument: "saveDocument",
    documentType: "documentType",
    documentCategory: "documentCategory",
    company: "company",
    contractor: "contractor",
    documentAdded: "documentAdded",
    externalDocumentNumber: "externalDocumentNumber",
    P0: "P0",
    P1: "P1",
} as const;

export default documentArchive;
