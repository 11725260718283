import { PagedRequest, getPaged } from "utils/extensions";
import types from "./types";

const endpoint = (giftId) => `api/v1/offer/products/gifts/${giftId}/choices`;

export const getGiftChoicesList =
    ({ giftId, isActive, pageIndex, pageSize, ...rest }) =>
    (dispatch) => {
        let query = { ...rest };
        if (isActive !== "") query.isActive = isActive;

        return dispatch(getPaged(new PagedRequest(pageIndex, pageSize, endpoint(giftId), types.getGiftChoices.all, { queryParams: { isActive } })));
    };
