import { commerce, manager } from "store/actionTypePrefixes";
import { AsyncTypes, asAsyncFormTypes, asCleanFormDataTypes, asSilientError } from "utils/extensions";

const types = {
    initForm: `${manager}/FORM_INIT_REWARD`,
    getReward: new AsyncTypes("GET_REWARD", commerce),
    createOrUpdateReward: new AsyncTypes("CREATE_OR_UPDATE_REWARD", commerce, [asAsyncFormTypes, asCleanFormDataTypes]),
    getRewardReservationCount: new AsyncTypes("GET_REWARD_RESERVATION_COUNT", commerce, [asSilientError]),
    getRewardAudit: new AsyncTypes("GET_REWARD_AUDIT", commerce),
};

export default types;
