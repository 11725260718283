import { PdfViewer } from "components/PdfViewer";
import { makeStyles } from "tss-react/mui";
import React from "react";
import { Close } from "@mui/icons-material";
import { Backdrop, Card, CardContent, CardMedia, Fade, Grid, IconButton, Modal } from "@mui/material";

const useStyles = makeStyles()(() => ({
    imageCardOverlay: {
        zIndex: 10,
        backgroundColor: "inherit",
        position: "absolute",
    },
    imagePreview: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        boxShadow: 24,
    },
    imagePreviewContainer: {
        height: "100%",
        width: "100%",
        justifyContent: "flex-end",
        padding: "10px",
    },
    imagePreviewImage: {
        width: "auto",
        minWidth: "150px",
        maxHeight: "70vh",
        marginTop: "40px",
    },
}));

export const FilePreview = ({ file, open, onClose, onClosed, actions, isPdf }) => {
    const { classes } = useStyles();

    actions = [
        ...(actions ?? []),
        {
            icon: <Close />,
            onSelect: () => {},
        },
    ];

    return (
        <Modal
            open={open}
            onClose={onClose}
            onAnimationEnd={() => !open && !!onClosed && onClosed()}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Card className={classes.imagePreview}>
                    <CardContent>
                        {!!actions && (
                            <Backdrop
                                className={classes.imageCardOverlay}
                                open={true}
                            >
                                <Grid
                                    container
                                    className={classes.imagePreviewContainer}
                                >
                                    {actions
                                        .filter((x) => !!x)
                                        .map((action, i) => (
                                            <Grid
                                                item
                                                key={i}
                                            >
                                                <IconButton
                                                    onClick={() => {
                                                        action.onSelect();
                                                        !action.keepOpen && onClose();
                                                    }}
                                                >
                                                    {action.icon}
                                                </IconButton>
                                            </Grid>
                                        ))}
                                </Grid>
                            </Backdrop>
                        )}
                        {isPdf ? (
                            <PdfViewer
                                file={file}
                                autoSize
                            />
                        ) : (
                            <CardMedia
                                component="img"
                                className={classes.imagePreviewImage}
                                image={file}
                            />
                        )}
                    </CardContent>
                </Card>
            </Fade>
        </Modal>
    );
};
