import { ProductScan, ProductScanRequest } from "Crawler";
import { useGetCacheQuery } from "api/hooks";

export const useFindProductScansForFriscoId = (friscoId: string, shopConfigurationIds: string[]) => {
    return useGetCacheQuery<ProductScan[], ProductScanRequest>({
        app: "crawler",
        url: `api/scan/product/${friscoId}`,
        shouldMakeRequest: Boolean(friscoId) && shopConfigurationIds.length > 0,
        cacheTime: 10,
        queryParams: { shopConfigurationIds },
    });
};
