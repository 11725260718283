import { FormField } from "components/Form";
import { FieldArray, FormSection } from "redux-form";
import Validator, { isEmpty as isEmptyValue } from "utils/validators/basic";
import { Grid } from "@mui/material";
import { get, isEmpty } from "lodash";
import { tValidation, validation } from "utils-ts/validations/translation";
import DeliveryPredicateFields from "./DeliveryPredicateFields";
import OrderRequirementsFields from "./OrderRequirementsFields";
import OrderValueFields from "./OrderValueFields";
import PackagingMethodsSelectField from "./PackagingMethodsSelectField";
import PaymentMethodsSelectField from "./PaymentMethodsSelectField";
import PersonalTargetRequirement from "./PersonalTargetRequirement";
import SegmentsSelectFields from "./SegmentsSelectFields";

const validateSection = (value, form, _, path) => {
    const section = get(form, path.substring(0, path.lastIndexOf("."))) || {};
    const isEmptySection = (val = {}) => {
        const { user = {}, delivery, payment, packaging, channel = {}, total = {}, merchants } = val;
        const { orders, personalTarget, segments } = user;
        const { included, excluded } = channel;
        const { maximumValue, minimumValue } = total;
        return (
            isEmpty(orders) &&
            isEmpty(personalTarget) &&
            isEmpty(segments) &&
            isEmpty(payment) &&
            isEmpty(included) &&
            isEmpty(excluded) &&
            isEmpty(delivery) &&
            isEmpty(packaging) &&
            isEmpty(merchants) &&
            isEmptyValue(maximumValue) &&
            isEmptyValue(minimumValue)
        );
    };

    return new Validator(section).must((val) => !isEmptySection(val), tValidation(validation.atLestOneRequirement)).validate();
};

const CartContextPredicate = ({ name = "", required = false, readOnly = false, requiredSegment = false, isVoucherEmbedded = false }) => {
    return (
        <FormSection name={name}>
            <FormField
                name={`validation`}
                type="sectionValidation"
                validate={required ? validateSection : undefined}
                readOnly={readOnly}
            />

            {!isVoucherEmbedded && (
                <FormSection name="user">
                    <OrderRequirementsFields readOnly={readOnly} />
                    <PersonalTargetRequirement readOnly={readOnly} />
                    <SegmentsSelectFields
                        readOnly={readOnly}
                        required={requiredSegment}
                    />
                </FormSection>
            )}

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-end"
            >
                <PaymentMethodsSelectField
                    name="payment"
                    readOnly={readOnly}
                />
                <PackagingMethodsSelectField readOnly={readOnly} />
            </Grid>

            <FormSection name="total">
                <OrderValueFields readOnly={readOnly} />
            </FormSection>

            <FieldArray
                name="delivery"
                prefix="definition"
                component={DeliveryPredicateFields}
                readOnly={readOnly}
            />
        </FormSection>
    );
};

export default CartContextPredicate;
