import { useParams } from "react-router-dom";

const withMatchParams = (WrappedComponent) => (props) => {
    const params = useParams();

    return (
        <WrappedComponent
            {...props}
            match={{ params: params }}
        />
    );
};

export default withMatchParams;
