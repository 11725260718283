import { default as appRoles } from "consts/roles";
import { userInfoWithRoleSelector } from "store/autocomplete";
import { createSelector } from "reselect";

export const userReplacersSelector = (id) =>
    createSelector(userInfoWithRoleSelector, (roles) =>
        [
            ...new Set(
                [
                    ...(roles[appRoles.Catman] || []),
                    ...(roles[appRoles.SalesAssistant] || []),
                    ...(roles[appRoles.SalesShopping] || []),
                    ...(roles[appRoles.SalesManager] || []),
                ]
                    .map((x) => ({
                        ...x,
                        fullName: `${x.fullName?.firstName} ${x.fullName?.lastName}`,
                    }))
                    .filter((v, i, a) => a.findIndex((x) => x.userId === v.userId) === i)
            ),
        ].filter((x) => x.userId !== id)
    );

export const userReplacementsSelector = (state) => state.replacements;
