import { renderFieldOrText } from "components/FormHelpers/Controls";
import GridContainer from "components/Grid/GridContainer";
import { Field } from "redux-form";
import { countriesSelector, getCountries } from "store/autocomplete/index";
import { withStyles } from "tss-react/mui";
import { useCommonTranslation } from "utils/hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { styles as form } from "./styles";

const styles = (theme) => form(theme);

const BillingAdressForm = ({ classes }) => {
    const dispatch = useDispatch();
    const { t, common } = useCommonTranslation();
    const countries = useSelector(countriesSelector);
    const countryItems = Object.entries(countries)
        .map(([key, val]) => ({ value: key, name: val }))
        .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));

    useEffect(() => {
        dispatch(getCountries());
    }, [dispatch]);

    //prettier-ignore
    const fields = [
        { label: t(common.companyName), name: "billingAddress.companyName", type: "text" },
        { label: t(common.vatin), name: "billingAddress.vatin", type: "text" },
        { label: t(common.country), name: "billingAddress.country", type: "select", items: countryItems },
        { label: t(common.postcode), name: "billingAddress.postcode", type: "text" },
        { label: t(common.city), name: "billingAddress.city", type: "text" },
        { label: t(common.street), name: "billingAddress.street", type: "text" },
        { label: t(common.buildingNumber), name: "billingAddress.buildingNumber", type: "text" },
        { label: t(common.apartmentNumber), name: "billingAddress.apartmentNumber", type: "text" },
        { label: t(common.phoneNumber), name: "billingAddress.phoneNumber", type: "text" },
        { label: t(common.email), name: "billingAddress.email", type: "text" },
    ];

    return (
        <GridContainer
            iconName="business"
            title={common.invoiceInfo}
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
        >
            {fields.map(({ type, label, name, ...field }) => {
                return (
                    <Grid
                        key={name}
                        item
                        className={classes.gridField}
                    >
                        <Field
                            multiline
                            fullWidth
                            type={type}
                            label={label}
                            name={name}
                            component={renderFieldOrText}
                            {...field}
                        />
                    </Grid>
                );
            })}
        </GridContainer>
    );
};

export default withStyles(BillingAdressForm, styles);
