import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import { formatTypes } from "utils/formating";
import { useCommonTranslation } from "utils/hooks";
import { Fragment } from "react";
import Chart from "./Chart";
import Row from "./Row";
import SummaryRow from "./SummaryRow";

const Table = ({ items, isLoading }) => {
    const { t, common } = useCommonTranslation();
    //prettier-ignore
    const columns =[
        { key: "deliveryWindow",		value: common.hour,       		type: formatTypes.deliveryWindow, options: { onlyHour: true }  },
        { key: "reservationLimit",		value: common.limitTotal,		type: formatTypes.text },
        { key: "reservationCount",		value: common.reservationCount,	type: formatTypes.text },
        { key: "orderCount",			value: common.orderCount,		type: formatTypes.text },
    ].map(c => ({...c, value: t(c.value)}));

    return (
        <Fragment>
            {items.length > 0 && <Chart items={items} />}
            <SimpleTable
                stripped
                isLoading={isLoading}
                columns={columns}
                data={items}
            >
                {items.map((item, index) => (
                    <Row
                        key={index}
                        columns={columns}
                        item={item}
                    />
                ))}
                <SummaryRow
                    term={items}
                    columns={columns}
                />
            </SimpleTable>
        </Fragment>
    );
};

export default Table;
