import { styles } from "assets/jss/common";
import { renderTextField } from "components/FormHelpers/Controls";
import { Field, reduxForm } from "redux-form";
import { changeUserPassword } from "store/identity/userDetails/actions";
import { withStyles } from "tss-react/mui";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import classNames from "classnames";
import { administration, common } from "translations";

const internalStyles = (theme) => ({
    progress: {
        marginRight: theme.spacing(2),
    },
});

export const validate = (values) => {
    const errors = {};
    const requiredFields = ["password", "confirmationPassword"];
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = "Required";
        }
    });

    if (values.password) {
        if (values.password.length < 9) {
            errors["password"] = "Password must have at least 9 characters";
        } else if (!/\d/.test(values.password) || !/[a-z]/.test(values.password) || !/[A-Z]/.test(values.password)) {
            errors["password"] = "Password must contains at least 1 digital, 1 lower character and 1 upper character";
        }
    }

    if (values.password && values.confirmationPassword && values.password !== values.confirmationPassword) {
        errors.confirmationPassword = "Password and confirmation password do not match";
    }

    return errors;
};

const ChangePassword = (props) => {
    const dispatch = useDispatch();
    const { handleSubmit, invalid, pristine, submitting, isBusy, t, userId } = props;
    const classes = withStyles.getClasses(props);

    return (
        <form
            onSubmit={handleSubmit((values) => {
                dispatch(changeUserPassword(userId, values.password));
            })}
        >
            <Grid
                container
                spacing={4}
            >
                <Grid
                    item
                    xs={6}
                    md={3}
                >
                    <Field
                        name="password"
                        fullWidth={true}
                        component={renderTextField}
                        label={t(`common:${common.password}`)}
                        type="password"
                    />
                </Grid>
                <Grid
                    item
                    xs={6}
                    md={3}
                >
                    <Field
                        name="confirmationPassword"
                        fullWidth={true}
                        component={renderTextField}
                        label={t(administration.confirmationPassword)}
                        type="password"
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={2}
                >
                    {invalid || pristine ? undefined : (
                        <Button
                            type={"submit"}
                            variant="contained"
                            color="secondary"
                            disabled={submitting}
                        >
                            {isBusy === true ? (
                                <CircularProgress
                                    className={classes.progress}
                                    size={15}
                                />
                            ) : (
                                <SaveIcon className={classNames(styles.leftIcon, styles.iconSmall)} />
                            )}
                            {t(administration.update)}
                        </Button>
                    )}
                </Grid>
            </Grid>
        </form>
    );
};

export default reduxForm({
    form: "ChangePassword",
    enableReinitialize: true,
    validate: validate,
})(withTranslation(["administration", "common"])(withStyles(ChangePassword, internalStyles)));
