import SimpleTable, { createColumn } from "components/Table/SimpleTable/SimpleTable";
import tableActionTypes from "components/Table/SimpleTable/TableActions/tableActionTypes";
import { formatTypes } from "utils/formating";
import { useCommonTranslation } from "utils/hooks";
import _ from "lodash";

const ProcedureFileAudit = ({ procedureId, versionFileHistory, downloadFile }) => {
    const { t, common } = useCommonTranslation();

    const handleDownloadFile = async (item) => {
        await downloadFile(item.procedureVersion, item.fileName);
    };

    const columns = [
        createColumn("procedureVersion", t(common.version), formatTypes.text),
        createColumn("fileName", t(common.fileName), formatTypes.text),
        createColumn("action", "", formatTypes.action, { actionType: tableActionTypes.download }, handleDownloadFile),
    ];

    let data = _.keys(versionFileHistory).map((ver) => {
        return {
            procedureVersion: ver,
            fileName: versionFileHistory[ver].blobName.replace(`${procedureId}_${ver}_`, ""),
        };
    });

    data = _.orderBy(data, ["procedureVersion"], ["desc"]);

    return (
        <SimpleTable
            columns={columns}
            data={data}
            stripped
            hover
        />
    );
};

export default ProcedureFileAudit;
