import TextWithLabel from "components/Text/TextWithLabel";
import { makeStyles } from "tss-react/mui";
import { Chip } from "@mui/material";
import classnames from "classnames";

const useStyles = makeStyles()((theme) => ({
    "items": {
        display: "inline-block",
    },
    "added": {
        color: theme.palette.common.white,
        background: theme.palette.success.main,
    },
    "deleted": {
        color: theme.palette.common.white,
        background: theme.palette.error.main,
    },
    "marker-added": {
        "& > .MuiChip-root": {
            color: theme.palette.common.white,
            background: theme.palette.success.main,
        },
    },
    "marker-deleted": {
        "& > .MuiChip-root": {
            color: theme.palette.common.white,
            background: theme.palette.error.main,
        },
    },
}));

const AutocompleteDiffControl = (props) => {
    const {
        input: { value = { removed: [], added: [], noDiff: [] } },
        label,
        getOptionLabel,
        itemRenderer,
    } = props;
    const { classes } = useStyles();
    let diffValue = [];
    if (value) {
        diffValue = [
            ...(value.removed || []).map((x) => ({ value: x.current, change: "deleted" })),
            ...(value.added || []).map((x) => ({ value: x.current, change: "added" })),
            ...(value.noDiff || []).map((x) => ({ value: x.current, change: "noDiff" })),
        ];
    }
    return (
        <TextWithLabel
            useFormat={false}
            translatedLabel={label}
            value={
                diffValue.length > 0 && (
                    <div className={classes.items}>
                        {diffValue.map((i) =>
                            !itemRenderer ? (
                                <Chip
                                    key={i.value}
                                    style={{
                                        marginRight: 10,
                                        marginTop: 10,
                                    }}
                                    size="medium"
                                    label={getOptionLabel ? getOptionLabel(i.value) : i.value}
                                    className={classes[i.change]}
                                />
                            ) : (
                                <div
                                    key={i.value}
                                    className={classnames(classes[`marker-${i.change}`], classes.items)}
                                >
                                    {itemRenderer(i.value, null, { readOnly: true })}
                                </div>
                            )
                        )}
                    </div>
                )
            }
            fullWidth
        />
    );
};

export default AutocompleteDiffControl;
