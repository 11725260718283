import { CompanyStructure } from "views-ts/employeeProcedures";
import { ProcedureForm, ProceduresList } from "views-ts/procedures";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import { Role, Roles } from "routing-ts/roles";

const procedureRoles: Role[] = ["Logged"];
const companyStructureRoles: Role[] = [...Roles];


export const EmployeeProcedures: PrivateRoute[] = [
    new PrivateRoute(Paths.Procedures, procedureRoles, ProceduresList, "proceduresList", "library_books"),
    new PrivateRoute(Paths.Procedure, procedureRoles, ProcedureForm),
    new PrivateRoute(Paths.CompanyStructure, companyStructureRoles, CompanyStructure, "companyStructure", "account_tree"),
];
