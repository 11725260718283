import AcceptRejectDialog from "components/Dialog/AcceptRejectDialog";
import { common, customers } from "translations";
import { useTranslation } from "utils-ts/hooks";

const CancelBalanceCorrectionDialog = ({ ...props }) => {
    const { t } = useTranslation(["customers", "common"]);
    return (
        <AcceptRejectDialog
            {...props}
            title={t(customers.removeBalanceCorrection)}
        >
            {t(`common:${common.areYouSure}`, {
                what: t(customers.removeBalanceCorrectionQuestion),
            })}
        </AcceptRejectDialog>
    );
};

export default CancelBalanceCorrectionDialog;
