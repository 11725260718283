import Layout from "components/Grid/Layout";
import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import tableActionTypes from "components/Table/SimpleTable/TableActions/tableActionTypes";
import { customerListSelector, updatePagination } from "store/customers/customerList";
import { formatTypes, stringStyle, timePrecisonFormats } from "utils/formating";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { common, customers } from "translations";
import { usePush, useTranslation } from "utils-ts/hooks";
import CustomerSearch from "./CustomerSearch";
import FlagIcon from "./FlagIcon";

const CustomerList = () => {
    const { items, pageIndex, pageSize, totalCount, isLoading } = useSelector(customerListSelector);
    const dispatch = useDispatch();
    const { push } = usePush();
    const { t } = useTranslation(["customers", "common"]);

    const handleGoToCustomer = useCallback(
        ({ id }) => {
            push(`/customers/${id}`);
        },
        [push]
    );

    const columns = useMemo(
        () => [
            {
                key: "id",
                value: t(customers.id),
                type: formatTypes.text,
                options: { style: stringStyle.imporant },
            },
            {
                key: "fullName",
                value: t(`common:${common.fullName}`),
                type: formatTypes.fullName,
            },
            {
                key: "phoneNumber",
                value: t(`common:${common.phoneNumber}`),
                type: formatTypes.text,
            },
            {
                key: "email",
                value: t(`common:${common.email}`),
                type: formatTypes.text,
            },
            {
                key: "billingAddress",
                value: t(`common:${common.billingAddress}`),
                type: formatTypes.shippingAddress,
            },
            {
                key: "lastActiveAt",
                value: t(customers.lastActiveAt),
                type: formatTypes.time,
                options: { timeFormat: timePrecisonFormats.seconds },
            },
            {
                key: "icons",
                value: t(customers.accountType),
                type: formatTypes.icon,
            },
            {
                key: "goTO",
                value: t(`common:${common.goTo}`),
                type: formatTypes.action,
                action: handleGoToCustomer,
                actionType: tableActionTypes.preview,
            },
        ],
        [handleGoToCustomer, t]
    );

    return (
        <Layout
            main
            headerText={t(customers.customers)}
        >
            <CustomerSearch />
            <SimpleTable
                isLoading={isLoading}
                columns={columns}
                data={(items || []).map((customer) => ({
                    ...customer,
                    icons: <FlagIcon customer={customer} />,
                }))}
                onRowDoubleClick={(_, customer) => handleGoToCustomer(customer)}
                shoudlHandleDoubleClick
                pagination
                hover
                stripped
                paginationProps={{
                    pageIndex,
                    totalCount,
                    pageSize,
                    onPageChange: (pageIndex) => dispatch(updatePagination({ pageIndex, pageSize })),
                    onRowsPerPageChange: (pageSize) => dispatch(updatePagination({ pageIndex: 1, pageSize })),
                }}
            />
        </Layout>
    );
};

export default CustomerList;
