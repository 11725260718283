import { defaultLenghtValidator, mustBeNumber, notEmptyArrayValidator, notEmptyValidator } from "utils/validators/basic";
import { tValidation, validation } from "utils-ts/validations/translation";

const name = (value) => defaultLenghtValidator(value).validate();

const ranges = (value) => {
    return notEmptyArrayValidator(value).validate();
};

const deliveryCost = (value) => {
    return notEmptyValidator(value).must(mustBeNumber, tValidation(validation.mustBeNumber)).validate();
};

const from = (value) => {
    return notEmptyValidator(value).must(mustBeNumber, tValidation(validation.mustBeNumber)).validate();
};

const delivery = (value) => {
    return notEmptyArrayValidator(value).validate();
};

export const validators = {
    name,
    ranges,
    from,
    deliveryCost,
    delivery,
};
