import { commerce, manager } from "store/actionTypePrefixes";
import { AsyncTypes, createAction } from "utils/extensions";

const types = {
    initializeCustomerList: createAction("CUSTOMERS_LIST_INIT", manager),
    getCustomers: new AsyncTypes("CUSTOMERS_GET_LIST", commerce),
    updatePagination: createAction("UPDATE_PAGINATION", manager),
    filtersChanged: createAction("FILTERS_CHANGED", manager),
};

export default types;
