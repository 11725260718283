import { RSAAEnd } from "store/progress/actions";

export const RSAAEndResolverMiddleware = (store) => (next) => (action) => {
    if (
        store &&
        store.getState().progress &&
        store.getState().progress.started.some((x) => {
            const startedAction = x.endsWith("_REQUEST") ? x.slice(0, -"_REQUEST".length) : x;
            return startedAction + "_SUCCESS" === action.type || startedAction + "_FAILURE" === action.type;
        })
    ) {
        if (!action.meta || !action.meta.ignoreInProgress) {
            store.dispatch(RSAAEnd(action.type));
        }
    }

    return next(action);
};
