import { ContentData } from "components/Controls";
import { CommerceForm, FormField } from "components/Form";
import { renderFieldOrText } from "components/FormHelpers/Controls";
import Layout from "components/Grid/Layout";
import { reduxForm } from "redux-form";
import { actions as productActions } from "store/cache/products/actions";
import { actions } from "store/cms/rules/form/actions";
import { toObject, toSelectList } from "utils/extensions/arrayExtensions";
import { useCommonTranslation, useCopyRouterState, useMappedDispatch } from "utils/hooks";
import React from "react";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { common } from "translations";
import { usePush } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { rulesFormSelector } from "../selectors";
import { validators } from "../validators";

const mapValues = (values) => {
    const { contentData, products, sections: _, ...rest } = values;
    const mapProducts = (ids) => (ids && ids.length > 0 ? ids.map((id) => ({ productId: id })) : null);

    return {
        ...rest,
        contentData: contentData ? toObject(contentData) : null,
        products: mapProducts(products),
    };
};

const RulesForm = ({ handleSubmit, products, isSubbmitRequested, isSystem, name }) => {
    const { t, common } = useCommonTranslation();
    const { initForm, createOrUpdate, getProducts } = useMappedDispatch({
        ...actions,
        ...productActions,
    });
    const { id, isCopy } = useCopyRouterState();
    const { replace } = usePush();

    React.useEffect(() => {
        initForm(id, isCopy);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, isCopy]);
    React.useEffect(() => {
        getProducts(products);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products && products.length > 0]);

    return (
        <CommerceForm
            onSubmit={handleSubmit(async (values) => {
                const { payload } = await createOrUpdate(mapValues(values), !isCopy && id);

                if ((!isCopy && !id) || isCopy) {
                    replace(Paths.Cms.RuleForm, { id: payload.id });
                }
            })}
            isReadonly={isSystem && !isCopy}
            isSubbmitRequested={isSubbmitRequested}
        >
            <Layout
                main
                headerText={t(common.rules, {
                    id: !isCopy && id ? id : "",
                })}
                navigationProps={{
                    backProps: { path: Paths.Cms.RulesList },
                }}
                customTitle={id && name && `${t(common.rules)} - ${name}`}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <FormField
                        name={"name"}
                        component={renderFieldOrText}
                        type={"text"}
                        label={t(common.name)}
                        validate={validators.name}
                    />
                    <FormField
                        name={"isActive"}
                        component={renderFieldOrText}
                        type={"boolean"}
                        label={t(common.isActive)}
                    />
                    <FormField
                        name={"optionalFrom"}
                        component={renderFieldOrText}
                        type={"date"}
                        label={t(common.optionalFrom)}
                        validate={validators.optionalFrom}
                    />
                    <FormField
                        name={"obligatoryFrom"}
                        component={renderFieldOrText}
                        type={"date"}
                        label={t(common.obligatoryFrom)}
                        validate={validators.obligatoryFrom}
                    />
                    <FormField
                        name={"merchant"}
                        component={renderFieldOrText}
                        type={"select"}
                        label={t(common.merchant)}
                        items={toSelectList([common.FRS, common.F24], t)}
                        validate={validators.obligatoryFrom}
                    />
                </Grid>
            </Layout>
            <ContentData
                required={true}
                useCollapse={false}
            />
        </CommerceForm>
    );
};

const formName = "rules-form";

const stateToProps = (state) => {
    const { isSubbmitRequested, isSystem, ...form } = rulesFormSelector(state);
    const products = form.products;
    return {
        isSystem,
        isSubbmitRequested,
        products,
        name: form.name,
        initialValues: {
            merchant: common.FRS,
            ...form,
        },
    };
};

export default connect(stateToProps)(
    reduxForm({
        form: formName,
        enableReinitialize: true,
    })(RulesForm)
);
