import { findRefundationApproval, refundationFormSelector } from "store/vendors/refundations";
import { useParams } from "utils/hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RefundationForm } from "views/vendors/components/refundation";

const RefundationDecisions = () => {
    const { id: vendorId } = useParams();
    const { isEmpty } = useSelector(refundationFormSelector);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(findRefundationApproval(vendorId));
    }, [dispatch, vendorId]);

    return isEmpty ? (
        "Brak decyzji do akceptacji"
    ) : (
        <RefundationForm
            vendorId={vendorId}
            handleDecision={async () => {
                await dispatch(findRefundationApproval(vendorId));
            }}
        />
    );
};

export default RefundationDecisions;
