import { withStyles } from "tss-react/mui";
import SearchIcon from "@mui/icons-material/Search";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import classnames from "classnames";

const styles = (theme) => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: "100%",
    },
    disabled: {
        backgroundColor: "#EEEEEE",
        borderRadius: "5px",
    },
    input: {
        marginLeft: 8,
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4,
    },
    container: {
        width: "100%",
        display: "flex",
        padding: "2px 4px",
        marginTop: "16px",
        alignItems: "center",
        marginBottom: "16px",
    },
});

const searchBar = ({ value, onSearchChanged, onSearchCommit, input, classes, placeholder, disabled, readOnly }) => {
    return (
        <Paper className={classes.container}>
            <Divider className={classes.divider} />
            <InputBase
                disabled={disabled || readOnly}
                autoFocus
                className={classnames(classes.input, { [classes.disabled]: disabled || readOnly })}
                placeholder={placeholder}
                value={value || input?.value || ""}
                onChange={onSearchChanged || input.onChange}
                onKeyUp={(event) => {
                    if (event.key === "Enter" || event.keyCode === 13 || event.which === 13) onSearchCommit();
                }}
            />
            <Divider className={classes.divider} />
            <IconButton
                className={classes.iconButton}
                onClick={() => onSearchCommit()}
            >
                <SearchIcon />
            </IconButton>
            <Divider className={classes.divider} />
        </Paper>
    );
};

export default withStyles(searchBar, styles);
