import { makeStyles } from "tss-react/mui";
import { useCommonTranslation } from "utils/hooks";
import { Grid } from "@mui/material";

const useStyles = makeStyles()((theme) => ({
    readonlyContainer: {
        border: `3px solid ${theme.palette.error.dark}`,
        borderRadius: theme.shape.borderRadius,
        background: "#f8d7da",
        padding: theme.spacing(4),
        color: theme.palette.error.dark,
    },
}));

const ReadonlyContainer = ({ showContainer }) => {
    const { classes } = useStyles();
    const { t, common } = useCommonTranslation();

    return showContainer ? (
        <Grid container>
            <Grid
                item
                xs={12}
                className={classes.readonlyContainer}
            >
                {t(common.systemReadonlyForm)}
            </Grid>
        </Grid>
    ) : (
        <></>
    );
};

export default ReadonlyContainer;
