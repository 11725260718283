import { generatePath } from "react-router-dom";
import { DeviceTypeDocument, DeviceTypeRequest, DeviceTypeResponse } from "Operations";
import { useCreateOrUpdateMutation, useGetPagedQuery, useGetQuery } from "api/hooks";

const url = (id?: string) => generatePath(`api/device-types/:id?`, { id: id ? id : null });

export const useGetDeviceTypes = (initialParams: DeviceTypeRequest) => {
    return useGetPagedQuery<DeviceTypeResponse, DeviceTypeRequest>({
        app: "operations",
        url: url(),
        queryParams: initialParams,
    });
};

export const useGetDeviceType = (id?: string) => {
    return useGetQuery<DeviceTypeDocument>({
        app: "operations",
        url: url(id),
        shouldMakeRequest: Boolean(id),
    });
};

export const useCreateOrUpdateDeviceType = (id?: string) => {
    return useCreateOrUpdateMutation<DeviceTypeDocument, DeviceTypeDocument>({ app: "operations", url: url(id), id: id });
};
