import { PDFDocumentProxy } from "pdfjs-dist";
import { makeStyles } from "tss-react/mui";
import React from "react";
import { useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Paper } from "@mui/material";
import { PdfViewerProps } from "control-types";
import { Navigation } from "components-ts/PddfViewer";
import { SpinningPreloader } from "components-ts/preloaders";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles()((theme) => ({
    document: {
        padding: theme.spacing(4),
        background: "#DCDCDC",
    },
}));

const PdfViewer: React.FC<PdfViewerProps> = ({ isLoading, file, mode, autoSize }) => {
    const viewerRef = useRef(null);
    const { classes } = useStyles();
    const [documentState, setDocument] = useState({
        pageCount: 1,
        currentPage: 1,
        isLoaded: false,
    });
    const [pageWidth] = useState(800);
    const [pageHeight] = useState(800 * 1.4141);
    const [error, setError] = useState(false);
    const onError = () => {
        setError(true);
    };

    const onDocumentLoadSuccess = (document: PDFDocumentProxy) => {
        setError(false);
        setDocument({
            pageCount: document._pdfInfo.numPages,
            currentPage: 1,
            isLoaded: true,
        });
    };

    const setPage = (currentPage: number) => {
        setDocument({ ...documentState, currentPage });
    };

    return (
        <Paper
            className={classes.document}
            style={{
                width: autoSize ? undefined : pageWidth,
                height: autoSize ? undefined : pageHeight,
            }}
            ref={viewerRef}
        >
            {isLoading ? (
                <SpinningPreloader />
            ) : (
                <>
                    {error && <p>{error}</p>}
                    {!!file && (
                        <Document
                            file={mode === "base64" ? `data:application/pdf;base64,${file}` : file}
                            onLoadSuccess={onDocumentLoadSuccess}
                            onLoadError={onError}
                            onSourceError={onError}
                            loading=""
                        >
                            <>
                                <Paper
                                    elevation={24}
                                    style={{
                                        width: autoSize ? undefined : pageWidth,
                                        height: autoSize ? undefined : pageHeight,
                                        position: "relative",
                                    }}
                                >
                                    <>
                                        <Page
                                            width={autoSize ? undefined : pageWidth}
                                            height={autoSize ? undefined : pageHeight}
                                            pageNumber={documentState.currentPage}
                                            renderAnnotationLayer={false}
                                            renderTextLayer={false}
                                        />
                                    </>
                                    {documentState.pageCount && (
                                        <Navigation
                                            autoWidth={autoSize}
                                            width={pageWidth}
                                            currentPage={documentState.currentPage}
                                            pageCount={documentState.pageCount}
                                            setPage={setPage}
                                            mode={mode}
                                            file={file}
                                        />
                                    )}
                                </Paper>
                            </>
                        </Document>
                    )}
                </>
            )}
        </Paper>
    );
};

export default PdfViewer;
