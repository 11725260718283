import { combineReducers } from "redux";
import { categories } from "./categories/reducer.js";
import { commerceUsers } from "./commerceUsers/reducer.js";
import { identityUsers } from "./identityUsers/reducer.js";
import { products } from "./products/reducer.js";
import { userInfos } from "./userInfos/reducer.js";

export const cache = combineReducers({
    products,
    categories,
    commerceUsers,
    userInfos,
    identityUsers,
});
