import types from "./types";
import { types as rootTypes } from "../action";

const initialState = {
    isLoading: false,
    profileType: "",
    email: "",
    consentToken: "",
    isVerificationRequired: false,
    language: null,
    isAdult: false,
    fullName: {
        firstName: "",
        lastName: "",
    },
    phoneNumber: "",
    mobileNumber: "",
    companyName: "",
    vatin: "",
    billingAddress: {
        companyName: "",
        vatin: "",
        postcode: "",
        city: "",
        street: "",
        buildingNumber: "",
        apartmentNumber: "",
        phoneNumber: "",
    },
    accountNumber: "",
    creditLimit: {
        hasCollectiveInvoice: false,
    },
    salesManagoContactId: "",
    salesManagoPending: false,
    isTestAccount: false,
    isBusinessAccount: false,
    isOfficeAccount: false,
    isSpecialClient: false,
    isVipAccount: false,
    isPersonalizationDisabled: false,
    preferences: {},
    segments: [""],
    acceptedRules: [""],
    registeredAt: "",
    lastActiveAt: "",
    id: "",
    updatedAt: "",
    createdAt: "",
    accountManager: undefined,
};

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case rootTypes.CUSTOMER_CHANGED:
            return initialState;
        case types.getCustomer.request:
            return { isLoading: true };
        case types.getCustomer.success:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case types.getCustomer.failure:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return { ...state };
    }
};
