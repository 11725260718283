import Layout from "components/Grid/Layout";
import TableWithToggleFilter from "components/Table/TableWithToggleFilter/TableWithToggleFilter";
import { actions } from "store/cms/rules/list/actions";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useCommonTranslation } from "utils/hooks";
import { usePush } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { rulesListSelector } from "../selectors";

const RulesList = () => {
    const { t, common } = useCommonTranslation();
    const { pushToForm: push } = usePush();
    const pushToForm = (id, isCopy) => {
        push(Paths.Cms.RuleForm, id, isCopy);
    };

    //prettier-ignore
    const columns = [
            { key: "name",          value: common.name,          type: formatTypes.text,                                                     },
            { key: "optionalFrom",  value: common.optionalFrom,  type: formatTypes.time, options: {timeFormat: timePrecisonFormats.seconds}, },
            { key: "obligatoryFrom",value: common.obligatoryFrom,type: formatTypes.time, options: {timeFormat: timePrecisonFormats.seconds}, },
            { key: "merchant",      value: common.merchant,      type: formatTypes.text,                                                     },
            { key: "isActive",      value: common.active,        type: formatTypes.boolean,                                                  },
            { key: "createdAt",     value: common.createdAt,     type: formatTypes.time, options: {timeFormat: timePrecisonFormats.seconds}, },
            { key: "updatedAt",     value: common.updatedAt,     type: formatTypes.time, options: {timeFormat: timePrecisonFormats.seconds}, },
            { key: "action",        value: "",                   type: formatTypes.action, actionType: "preview", action: item => pushToForm(item.id) },
            { key: "action",        value: "",                   type: formatTypes.action, actionType: "copy", action: item => pushToForm(item.id, true)},
        ].map(col => ({...col, value: t(col.value)}));

    return (
        <Layout
            main
            headerText={t(common.rules)}
        >
            <TableWithToggleFilter
                columns={columns}
                addItemAction={() => pushToForm()}
                dataSelector={rulesListSelector}
                dataSourceAction={actions.getList}
            />
        </Layout>
    );
};

export default RulesList;
