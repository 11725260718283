import { operations } from "store/actionTypePrefixes";
import { AsyncTypes } from "utils/extensions";

const types = {
    getCorrectionRequests: new AsyncTypes("GET_CORRECTION_REQUESTS", operations),
    getCorrectionRequest: new AsyncTypes("GET_CORRECTION_REQUEST", operations),
    downloadFile: new AsyncTypes("DOWNLOAD_CORRECTION_REQUEST_FILE", operations),
};

export default types;
