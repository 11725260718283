import { Fragment } from "react";
import { FormHelperText } from "@mui/material";

const ArrayValidationError = ({ error, invalid }) => {
    if (!(invalid && error)) {
        return <Fragment />;
    } else {
        return <FormHelperText style={{ fontSize: 16, color: "#de3428" }}>{invalid && error}</FormHelperText>;
    }
};

export default ArrayValidationError;
