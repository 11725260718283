import { FileType } from "consts-ts/fileTypes";
import { makeStyles } from "tss-react/mui";
import React from "react";
import { Accept, DropEvent, ErrorCode, FileRejection, useDropzone } from "react-dropzone";
import { CircularProgress, FormHelperText } from "@mui/material";
import { Grid } from "@mui/material";
import _ from "lodash";
import { common, content } from "translations";
import { useTranslation } from "utils-ts/hooks";

const useStyles = makeStyles()((theme) => ({
    progress: {
        marginRight: theme.spacing(2),
    },
    base: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        borderWidth: 2,
        borderRadius: 2,
        borderColor: "#eeeeee",
        borderStyle: "dashed",
        backgroundColor: "#fafafa",
        color: "#bdbdbd",
        outline: "none",
        transition: "border .24s ease-in-out",
    },
    active: {
        borderColor: "#2196f3",
    },
    accept: {
        borderColor: "#00e676",
    },
    reject: {
        borderColor: "#ff1744",
    },
}));

type FileDropzoneProps = {
    onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => void;
    onDropRejected?: (fileRejections: FileRejection[]) => void;
    acceptedFiles: FileType[];
    fileUploadInProgress?: boolean;
    hasFile?: boolean;
    message?: string;
    readOnly?: boolean;
    required?: boolean;
    maxFiles?: number;
    /** Minimum file size (in bytes) - default 1KiB*/
    minFileSize?: number;
    /** Maximum file size (in bytes) - default 10MiB */
    maxFileSize?: number;
};

const FileDropzone = ({
    onDrop,
    onDropRejected,
    acceptedFiles,
    fileUploadInProgress = false,
    hasFile,
    message,
    readOnly = false,
    required = false,
    maxFiles = 0,
    minFileSize = 1024, //1Kib
    maxFileSize = 10_485_760, //10MiB
}: FileDropzoneProps) => {
    const { t } = useTranslation(["common", "content"]);
    const { classes, cx } = useStyles();

    const acceptedExtensions = _.uniq(acceptedFiles.map((x) => x.extension));
    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, fileRejections } = useDropzone({
        accept: _.uniqBy(acceptedFiles, "extension").reduce<Accept>((acc, key) => {
            if (acc[key.mimeType]) {
                acc[key.mimeType].push(key.extension);
            } else {
                acc[key.mimeType] = [key.extension];
            }

            return acc;
        }, {}),
        onDrop,
        onDropRejected,
        minSize: minFileSize,
        maxSize: maxFileSize,
        maxFiles: maxFiles !== 0 ? maxFiles : undefined,
        multiple: maxFiles === 0 || maxFiles > 1,
    });
    const isFileTooLarge = fileRejections.length > 0 && fileRejections.some((f) => f.errors.some((e) => e.code === ErrorCode.FileTooLarge));
    const isFileTooSmall = fileRejections.length > 0 && fileRejections.some((f) => f.errors.some((e) => e.code === ErrorCode.FileTooSmall));

    if (readOnly) {
        return <></>;
    }

    return (
        <Grid
            item
            xs={12}
            sm={12}
            md={12}
        >
            <div className="clickable-container">
                <div
                    {...getRootProps({
                        className: cx(classes.base, isDragActive && classes.active, isDragAccept && classes.accept, isDragReject && classes.reject),
                    })}
                >
                    {fileUploadInProgress ? (
                        <CircularProgress
                            className={classes.progress}
                            size={15}
                        />
                    ) : (
                        <React.Fragment>
                            <input
                                {...getInputProps()}
                                disabled={readOnly}
                            />
                            <p>{message ?? t(common.fileDragAndDropLabel)}</p>
                            <em>
                                {t(`content:${content.acceptedFiles}`, {
                                    acceptedFiles: acceptedExtensions.map((ext) => "." + ext).join(","),
                                })}
                            </em>
                        </React.Fragment>
                    )}
                </div>
            </div>

            {required && !hasFile && <FormHelperText style={{ color: "red" }}>Należy dodać conajmniej 1 plik</FormHelperText>}

            {isFileTooLarge && <FormHelperText style={{ color: "red" }}>Plik nie może być większy niż 50MB</FormHelperText>}

            {isFileTooSmall && <FormHelperText style={{ color: "red" }}>Plik nie może być mniejszy niż 1KB</FormHelperText>}
        </Grid>
    );
};

export default FileDropzone;
