import React from "react";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import { AccountantDepartment, ECommerceDepartment, Role, SalesDepartment } from "routing-ts/roles";

const CorrectionRequestReport = React.lazy(() =>
    import("views/reports").then((module) => ({
        default: module.CorrectionRequestReport,
    }))
);
const CrawlerLinkingReport = React.lazy(() =>
    import("views/reports").then((module) => ({
        default: module.CrawlerLinkingReport,
    }))
);
const SubsidiaryGainReport = React.lazy(() =>
    import("views/reports").then((module) => ({
        default: module.SubsidiaryGainReport,
    }))
);

const ProductSeoReport = React.lazy(() =>
    import("views-ts/reports").then((module) => ({
        default: module.ProductsSeoReport,
    }))
);

const sgRoles: Role[] = [...AccountantDepartment.GetRoles(), "Auditor", "Management", "SalesManager"];

export const Reports: PrivateRoute[] = [
    new PrivateRoute("reports", [...sgRoles, ...SalesDepartment.GetRoles(), ...ECommerceDepartment.GetRoles()], undefined, "reports", undefined, [
        new PrivateRoute(Paths.Reports.SubsidiaryGain, sgRoles, SubsidiaryGainReport, "subsidiaryGainReport", "assessment"),
        new PrivateRoute(Paths.Reports.Crawler, SalesDepartment.GetRoles(), CrawlerLinkingReport, "crawlerLinkingReport", "link"),
        new PrivateRoute(Paths.Reports.CorrectionRequestRetro, ["Auditor"], undefined, "correctionRequestRetro", "library_books"),
        new PrivateRoute(Paths.Reports.ProductsSeo, ECommerceDepartment.GetRoles(), ProductSeoReport, "productsSeo", "inventory"),
    ]),
    new PrivateRoute(Paths.Reports.CorrectionRequest, ["Auditor"], CorrectionRequestReport),
];
