import { contractSelector, findContractForApproval } from "store/vendors/contracts";
import { useParams } from "utils/hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContractForm from "views/vendors/components/Contracts/ContractForm";

const RefundationDecisions = () => {
    const { id: vendorId } = useParams();
    const dispatch = useDispatch();
    const { id: contractId } = useSelector(contractSelector);
    useEffect(() => {
        dispatch(findContractForApproval(vendorId));
    }, [dispatch, vendorId]);

    return !contractId ? (
        "Brak decyzji do akceptacji"
    ) : (
        <ContractForm
            handleDecision={async () => {
                await dispatch(findContractForApproval(vendorId));
            }}
        />
    );
};

export default RefundationDecisions;
