import React from "react";
import { useParams } from "react-router-dom";
import { DeviceTypeDocument } from "Operations";
import { api } from "api";
import { common } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { useForm, useValidator } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { Form, FormRow, FormSwitch, FormTextField } from "components-ts/forms";
import DeviceTypeValidator from "./validator";

const DeviceTypeForm: React.FC = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const { data, status } = api.operations.deviceTypes.useGetDeviceType(id);
    const validatorResolver = useValidator(new DeviceTypeValidator());

    const form = useForm<DeviceTypeDocument>({
        resolver: validatorResolver,
        values: data,
    });

    return (
        <Form
            form={form}
            headerText={{
                resource: "deviceType",
                params: { id: id },
            }}
            formPath={Paths.Dictionaries.DeviceTypeForm}
            listPath={Paths.Dictionaries.DeviceTypeList}
            initQueryStatus={status}
            submitMutation={api.operations.deviceTypes.useCreateOrUpdateDeviceType(id)}
            id={id}
        >
            <FormRow>
                <FormTextField
                    name="name"
                    label={t(common.name)}
                />
                
                <FormSwitch
                    name="isActive"
                    label={t(common.isActive)}
                />

                <FormSwitch
                    name="isFriscoNumberRequired"
                    label={t(common.isFriscoNumberRequired)}
                />
            </FormRow>
        </Form>
    );
};

export default DeviceTypeForm;
