import { generatePath } from "react-router-dom";
import { DocumentArchiveCategoryDocument, DocumentArchiveCategoryRequest, DocumentArchiveCategoryResponse } from "Operations";
import { useCreateOrUpdateMutation, useGetPagedQuery, useGetQuery } from "api/hooks";

const url = (id?: string) => generatePath(`api/archive-documents/categories/:id?`, { id: id ? id : null });

export const useGetDocumentArchiveCategories = (initialParams: DocumentArchiveCategoryRequest) => {
    return useGetPagedQuery<DocumentArchiveCategoryResponse, DocumentArchiveCategoryRequest>({
        app: "operations",
        url: url(),
        queryParams: initialParams,
    });
};

export const useGetDocumentArchiveCategory = (id?: string) => {
    return useGetQuery<DocumentArchiveCategoryDocument>({
        app: "operations",
        url: url(id),
        shouldMakeRequest: Boolean(id),
    });
};

export const useCreateOrUpdatDocumentArchiveCategory = (id?: string) => {
    return useCreateOrUpdateMutation<DocumentArchiveCategoryDocument, DocumentArchiveCategoryDocument>({ app: "operations", url: url(id), id: id });
};
