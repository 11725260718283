import { renderFieldOrText } from "components/FormHelpers/Controls";
import { Field } from "redux-form";
import { orderBy } from "utils/extensions/arrayExtensions";
import { withFormName } from "utils/hoc";
import { useCommonTranslation } from "utils/hooks";
import React from "react";

const UsersAutocomplete = ({ validate, name = "userIds", filterFunc, multiple, label, disableCloseOnSelect, users = [], ...props }) => {
    const { t, common } = useCommonTranslation();
    let userIds = orderBy(users, (x) => x.email);
    if (filterFunc) {
        userIds = userIds.filter((u) => filterFunc(u));
    }

    userIds = userIds.map((u) => u.userId);

    const getDisplayName = (user) => {
        if (typeof user === "string") {
            return user;
        } else {
            return `${user.fullName?.firstName} ${user.fullName?.lastName} (${user.email})`;
        }
    };

    const getOptionLabel = (value) => {
        if (typeof value === "string") {
            let user = users.find((c) => c.userId === value);
            return user ? getDisplayName(user) : value;
        } else {
            return getDisplayName(value);
        }
    };

    return (
        <Field
            component={renderFieldOrText}
            name={name}
            label={label || t(common.users)}
            type={"autocomplete"}
            items={userIds}
            multiple={multiple !== undefined ? multiple : true}
            disableCloseOnSelect={disableCloseOnSelect !== undefined ? disableCloseOnSelect : true}
            validate={validate}
            filterOptions={(options, params) => {
                var { inputValue } = params;

                return options.filter((o) => getOptionLabel(o).includes(inputValue));
            }}
            getOptionLabel={getOptionLabel}
            {...props}
        />
    );
};

export default withFormName(UsersAutocomplete);
