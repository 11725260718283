import { ProductInternalData } from "Operations";
import { useGetCacheQuery } from "api/hooks";

export const useGetProductInternalData = (productId: string) => {
    return useGetCacheQuery<ProductInternalData>({
        app: "operations",
        url: `api/products/${productId}/internal-data`,
        silentError: true,
        shouldMakeRequest: Boolean(productId),
        cacheTime: 60,
    });
};
