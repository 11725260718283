import { IconButton } from "components/Button";
import { AcceptRejectDialog, useModalState } from "components/Dialog";
import { FormField } from "components/Form";
import { integerNormalize } from "components/FormHelpers/ControlFormaters";
import SearchBar from "components/SearchBar/SearchBar";
import { Cell, Row, SimpleTable } from "components/Table/SimpleTable";
import Action from "components/Table/SimpleTable/TableActions/Action";
import tableActionTypes from "components/Table/SimpleTable/TableActions/tableActionTypes";
import roles from "consts/roles";
import { reduxForm } from "redux-form";
import { balanceDebtListSelector, changePagination, clearBalanceDebts, findBalanceDebts, mute, updateFilterValues } from "store/balanceDebts";
import { customerListSelector, getCustomers } from "store/customers/customerList";
import defaultListState from "store/defaultListState";
import { makeStyles } from "tss-react/mui";
import { orderBy } from "utils/extensions/arrayExtensions";
import { formatTypes, formatValue, timePrecisonFormats } from "utils/formating";
import { useAppLocation, useCommonTranslation, usePush, useRouterState } from "utils/hooks";
import { createRef, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import { Grid, Icon } from "@mui/material";
import moment from "moment";
import classnames from "classnames";
import { Layout } from "components";
import { useUser } from "context";
import _, { isEmpty } from "lodash";
import { common } from "translations";
import activityReasons from "./activityReasons";
import balanceChangeReasons from "./balanceChangeReasons";

const useStyles = makeStyles()(() => {
    return {
        okRow: {
            background: "rgb(200, 255, 200)",
        },
        exceededRow: {
            background: "rgb(255, 160, 160)",
        },
        closeToExceededRow: {
            background: "rgb(255, 244, 200)",
        },
        baseColumn: {
            padding: "6px",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: "300",
            lineHeight: "1rem",
        },
        customerName: {
            "@media (min-width: 1000px)": {
                width: "15%",
            },
            "@media (min-width: 1600px)": {
                wordBreak: "break-word",
                width: "19%",
            },
        },
        currencyAmount: {
            "@media (min-width: 1000px)": {
                width: "5%",
            },
            "@media (min-width: 1600px)": {
                width: "10%",
            },
        },
    };
});

const getRowColor = (date) => {
    if (!date) {
        return;
    }
    const dateMoment = moment(date).startOf("day");
    const now = moment().startOf("day");

    if (now.isSameOrAfter(dateMoment)) {
        return "exceededRow";
    } else if (now.add(2, "day").isAfter(dateMoment)) {
        return "closeToExceededRow";
    } else {
        return "okRow";
    }
};

const formName = "BalanceDebtList";

const BalanceDebtList = () => {
    const { isInRoleOrAdmin } = useUser();
    const { classes: styles } = useStyles();
    const dispatch = useDispatch();
    const push = usePush();
    const { t } = useCommonTranslation();
    const { isLoading, items, pageIndex, pageSize, totalCount, pageCount, filterValues } = useSelector(balanceDebtListSelector);
    const { isLoading: usersIsLoading } = useSelector(customerListSelector);
    const appLocation = useAppLocation();
    const { handleClose, handleOpen, modalState } = useModalState();
    const [customerToMute, setCustomerToMute] = useState();
    const [userIdsResponse, setUserIdsResponse] = useState(defaultListState);
    const [searchCommit, setSearchCommit] = useState(false);
    const [focused, setFocused] = useState(false);
    const focusRef = createRef();
    const { focusedUserId, focusedUserIndex } = useRouterState();
    const goToUserBalanceDebt = (id, index) => {
        push(`/balance-debts/form/${id}`, { focusedUserIndex: index });
    };

    useEffect(() => {
        const searchUsers = async () => {
            await clearBalanceDebts();
            const { payload } = await dispatch(
                getCustomers({
                    search,
                    pageIndex,
                    pageSize,
                })
            );

            if (payload && payload.items) {
                if (payload.totalCount === 1 && focusedUserId != payload.items[0].id) {
                    push(`/balance-debts/form/${payload.items[0].id}`);
                } else {
                    const items = payload.items.map((i) => i.id);
                    setUserIdsResponse({ ...payload, items });
                }
            } else if (!isEmpty(search) && search.match(/^[0-9]*$/s)) {
                push(`/balance-debts/form/${search}`);
            }

            setSearchCommit(false);
        };

        const search = filterValues.search;
        setUserIdsResponse(defaultListState);
        setFilter({ ...filterValues, searchingUsers: true });
        if (!searchCommit && isEmpty(search)) {
            setFilter({ ...filterValues, searchingUsers: false });
            return;
        }

        searchUsers();
        setFilter({ ...filterValues, searchingUsers: false });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchCommit]);

    useEffect(() => {
        if (filterValues.searchingUsers === true || (userIdsResponse.pageIndex > 1 && userIdsResponse.pageIndex === pageIndex)) {
            return;
        }

        if (!isEmpty(filterValues.search) && isEmpty(userIdsResponse.items)) {
            dispatch(clearBalanceDebts());
        } else if (userIdsResponse.items.length > 0 && (userIdsResponse.pageSize != pageSize || userIdsResponse.pageIndex != pageIndex)) {
            dispatch(
                getCustomers({
                    search: filterValues.search,
                    pageIndex,
                    pageSize,
                })
            ).then((resp) => {
                if (resp.payload) {
                    const items = resp.payload.items.map((i) => i.id);
                    setUserIdsResponse({ ...resp.payload, items });
                    dispatch(
                        findBalanceDebts({
                            pageIndex: pageIndex,
                            pageSize: pageSize,
                            userIds: items,
                            manualActivityCounter: filterValues.manualActivityCounter,
                            activityReasons: filterValues.activityReasons,
                            balanceChangeReasons: filterValues.balanceChangeReasons,
                            dateFrom: filterValues.dateFrom,
                            dateTo: filterValues.dateTo,
                            userIdsPageCount: resp.payload.pageCount,
                            userIdsTotalCount: resp.payload.totalCount,
                            userIdsPageIndex: resp.payload.pageIndex,
                            hasCreditLimit: filterValues.hasCreditLimit === "none" ? null : filterValues.hasCreditLimit,
                            profileType: filterValues.profileType === "none" ? null : filterValues.profileType,
                        })
                    );
                }
            });
        } else {
            dispatch(
                findBalanceDebts({
                    pageIndex: pageIndex,
                    pageSize: pageSize,
                    userIds: userIdsResponse.items,
                    manualActivityCounter: filterValues.manualActivityCounter,
                    activityReasons: filterValues.activityReasons,
                    balanceChangeReasons: filterValues.balanceChangeReasons,
                    dateFrom: filterValues.dateFrom,
                    dateTo: filterValues.dateTo,
                    userIdsPageCount: userIdsResponse.pageCount,
                    userIdsTotalCount: userIdsResponse.totalCount,
                    userIdsPageIndex: userIdsResponse.pageIndex,
                    hasCreditLimit: filterValues.hasCreditLimit === "none" ? null : filterValues.hasCreditLimit,
                    profileType: filterValues.profileType === "none" ? null : filterValues.profileType,
                })
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dispatch,
        pageIndex,
        pageSize,
        userIdsResponse.items.length,
        userIdsResponse.pageIndex,
        filterValues.manualActivityCounter,
        filterValues.activityReasons,
        filterValues.balanceChangeReasons,
        filterValues.searchingUsers,
        filterValues.dateFrom,
        filterValues.dateTo,
        filterValues.hasCreditLimit,
        filterValues.profileType,
    ]);

    let col = [
        {
            key: "id",
            value: t(common.customerId),
            type: formatTypes.action,
            action: ({ id }) => `${appLocation}/customers/${id}`,
            actionType: tableActionTypes.link,
            openInNewTab: true,
        },
        {
            key: "customerName",
            value: t(common.customer),
            type: formatTypes.text,
            options: {
                className: classnames(styles.baseColumn, styles.customerName),
            },
        },
        {
            key: "accountType",
            value: t(common.accountType),
            type: formatTypes.infoIcon,
        },
        {
            key: "isDone",
            value: "",
            type: formatTypes.text,
        },
        {
            key: "manualActivityCounter",
            value: t(common.manualActivityCounter),
            type: formatTypes.numeric,
        },
        {
            key: "activityReason",
            value: t(common.activityReason),
            type: formatTypes.translatable,
            options: { translationKey: "common" },
        },
        {
            key: "changeReason",
            value: t(common.balanceChangeReason),
            type: formatTypes.translatable,
            options: { translationKey: "common" },
        },
        {
            key: "createdBy",
            value: t(common.createdBy),
            type: formatTypes.text,
        },
        {
            key: "balanceAmount",
            value: t(common.balanceAmount),
            type: formatTypes.currencyAmount,
            options: {
                className: classnames(styles.baseColumn, styles.currencyAmount),
            },
        },
        {
            key: "creditLimit",
            value: t(common.creditLimit),
            type: formatTypes.creditLimit,
            options: {
                className: classnames(styles.baseColumn, styles.currencyAmount),
            },
        },
        {
            key: "updatedAt",
            value: t(common.updatedAt),
            type: formatTypes.time,
            options: { timeFormat: timePrecisonFormats.seconds },
        },
        {
            key: "nextAt",
            value: t(common.nextActionAt),
            type: formatTypes.time,
            options: { timeFormat: timePrecisonFormats.seconds },
        },
        {
            key: "goTo",
            value: "",
            type: formatTypes.action,
            actionType: "preview",
            action: (id, index) => {
                goToUserBalanceDebt(id, index);
            },
        },
    ];

    if (isInRoleOrAdmin(roles.DebtCoordinator)) {
        col.push({
            key: "mute",
            value: "",
            type: formatTypes.action,
            actionType: "delete",
            action: ({ id }) => {
                setCustomerToMute(items.find((x) => x.id === id));
                handleOpen();
            },
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const columns = useMemo(() => col, [appLocation, push, t]);

    const onPageChange = useCallback(
        (pageIndex) => {
            dispatch(changePagination({ pageIndex, pageSize }));
        },
        [dispatch, pageSize]
    );

    const onRowsPerPageChange = useCallback(
        (pageSize) => {
            dispatch(changePagination({ pageIndex: 1, pageSize }));
        },
        [dispatch]
    );

    useEffect(() => {
        if (!focused && focusRef.current && (focusedUserId || focusedUserIndex)) {
            focusRef.current.scrollIntoView();
            setFocused(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focusedUserId, focusedUserIndex, focusRef]);

    const setFilter = async (column, value) => {
        await dispatch(updateFilterValues({ ...filterValues, [column]: value }));
    };

    return (
        <Layout
            main
            headerText={t(common.balanceDebts)}
        >
            <AcceptRejectDialog
                maxWidth={"sm"}
                title={t(common.muteCustomerDebt)}
                open={modalState}
                handleAccept={() => {
                    dispatch(mute(customerToMute.id));
                    handleClose();
                    setCustomerToMute(undefined);
                }}
                handleReject={() => {
                    handleClose();
                    setCustomerToMute(undefined);
                }}
                buttonAcceptText={t(common.remove)}
                buttonRejectText={t(common.cancel)}
            >
                {modalState &&
                    customerToMute &&
                    t(common.shouldMuteCustomerDebt, {
                        user: `${customerToMute.customerName} (${customerToMute.id})`,
                    })}
            </AcceptRejectDialog>
            <SearchBar
                value={filterValues.search}
                onSearchChanged={(e) => {
                    setFilter("search", e.target.value || "");
                }}
                onSearchCommit={() => {
                    setSearchCommit(true);
                }}
            />

            <Grid
                container
                direction="row"
                spacing={1}
            >
                <FormField
                    name="manualActivityCounter"
                    type="number"
                    label={t(common.manualActivityCounter)}
                    formatDisplay={integerNormalize}
                    onChange={(val) => {
                        setFilter("manualActivityCounter", val);
                    }}
                />

                <FormField
                    name="dateFrom"
                    type="dateTime"
                    label={t(common.dateFrom)}
                    onChange={(val) => {
                        if (val) {
                            if (moment(val).isValid()) {
                                setFilter("dateFrom", val);
                            }
                        } else {
                            setFilter("dateFrom", null);
                        }
                    }}
                />

                <FormField
                    name="dateTo"
                    type="dateTime"
                    label={t(common.dateTo)}
                    onChange={(val) => {
                        if (val) {
                            if (moment(val).isValid()) {
                                setFilter("dateFrom", val);
                            }
                        } else {
                            setFilter("dateTo", null);
                        }
                    }}
                />

                <FormField
                    name="profileType"
                    type="toggle"
                    label={t(common.accountType)}
                    items={[
                        {
                            value: "Home",
                            name: "Home",
                        },
                        {
                            value: "Office",
                            name: "Biznes",
                        },
                        { value: "none", name: "Wszystko" },
                    ]}
                    onChange={(val) => {
                        setFilter("profileType", val);
                    }}
                />

                <Grid item>
                    <IconButton
                        icon="clear"
                        onClick={() => {
                            setSearchCommit(false);
                            dispatch(
                                updateFilterValues({
                                    activityReasons: [],
                                    balanceChangeReasons: [],
                                    dateFrom: null,
                                    dateTo: null,
                                    manualActivityCounter: null,
                                    hasCreditLimit: false,
                                    profileType: "none",
                                    search: "",
                                    searchingUsers: false,
                                })
                            );
                        }}
                    />
                </Grid>
            </Grid>

            <Grid
                container
                direction="row"
                spacing={1}
            >
                <FormField
                    name="activityReasons"
                    label={t(common.activityReason)}
                    type="facetSearch"
                    onFilterChange={(_) => {}}
                    items={orderBy(activityReasons, (o) => t(o)).map((o) => {
                        return { name: t(o), value: o };
                    })}
                    onChange={(val) => {
                        setFilter("activityReasons", val);
                    }}
                    facet={activityReasons.map((o) => {
                        let isSelected = filterValues.activityReasons.some((a) => a == o);
                        return {
                            value: o,
                            isSelected,
                            count: isSelected ? 1 : undefined,
                        };
                    })}
                />

                <FormField
                    name="balanceChangeReasons"
                    label={t(common.balanceChangeReason)}
                    type="facetSearch"
                    onFilterChange={(_) => {}}
                    items={orderBy(balanceChangeReasons, (o) => t(o)).map((o) => {
                        return { name: t(o), value: o };
                    })}
                    onChange={(val) => {
                        setFilter("balanceChangeReasons", val);
                    }}
                    facet={balanceChangeReasons.map((o) => {
                        let isSelected = filterValues.balanceChangeReasons.some((a) => a == o);
                        return {
                            value: o,
                            isSelected,
                            count: isSelected ? 1 : undefined,
                        };
                    })}
                />

                <FormField
                    name="hasCreditLimit"
                    label={t(common.hasCreditLimit)}
                    type="toggle"
                    items={[
                        {
                            value: true,
                            name: "Tak",
                        },
                        {
                            value: false,
                            name: "Nie",
                        },
                        { value: "none", name: "Wszystko" },
                    ]}
                    onChange={(val) => {
                        setFilter("hasCreditLimit", val);
                    }}
                />
            </Grid>

            <SimpleTable
                isLoading={isLoading || usersIsLoading}
                columns={columns}
                hover
                pagination
                paginationProps={{
                    pageIndex,
                    totalCount,
                    pageSize,
                    pageCount,
                    onPageChange,
                    onRowsPerPageChange,
                }}
            >
                {items.map((item, index) => {
                    return (
                        <Row
                            key={item.id}
                            refProp={focusedUserId === item.id || focusedUserIndex == index ? focusRef : undefined}
                            className={styles[getRowColor(item.nextAt)]}
                            onDobuleClick={() => goToUserBalanceDebt(item.id, index)}
                        >
                            {columns.map((column) => {
                                if (column.key === "id") {
                                    return (
                                        <Action
                                            key={column.key}
                                            handleClick={() => column.action(item)}
                                            actionType={column.actionType}
                                            text={item[column.key]}
                                            openInNewTab={column.openInNewTab}
                                        />
                                    );
                                }

                                if (column.key === "goTo") {
                                    return (
                                        <Action
                                            key={column.key}
                                            handleClick={() => column.action(item.id, index)}
                                            actionType={column.actionType}
                                        />
                                    );
                                }

                                if (column.key === "mute") {
                                    return (
                                        <Action
                                            key={column.key}
                                            handleClick={() => column.action(item)}
                                            actionType={column.actionType}
                                        />
                                    );
                                }

                                if (column.key === "isDone") {
                                    const value = item.activityReason;

                                    return (
                                        <Cell key={column.key}>
                                            {value === common.CourtCaseOpened || value === common.DebtCollectionCompanyInvolved ? (
                                                <Icon
                                                    style={{
                                                        alignContent: "end",
                                                    }}
                                                    color="primary"
                                                    size="small"
                                                >
                                                    done
                                                </Icon>
                                            ) : undefined}
                                        </Cell>
                                    );
                                }

                                return (
                                    <Cell
                                        key={column.key}
                                        value={formatValue(item[column.key], column.type, column.options)}
                                        format={column.type}
                                        className={column.options?.className}
                                    />
                                );
                            })}
                        </Row>
                    );
                })}
            </SimpleTable>
        </Layout>
    );
};

export default connect((state) => {
    var balanceDebts = state.balanceDebts.list;

    return {
        ...balanceDebts,
        formState: state.form.BalanceDebtList,
        initialValues: {
            ...balanceDebts.filterValues,
            hasCreditLimit:
                _.isBoolean(balanceDebts.filterValues.hasCreditLimit) || balanceDebts.filterValues.hasCreditLimit === "none"
                    ? balanceDebts.filterValues.hasCreditLimit
                    : false,
        },
    };
})(
    reduxForm({
        form: formName,
        enableReinitialize: true,
    })(BalanceDebtList)
);
