import { common } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { FormPrefixProvider } from "components-ts/forms/contexts";
import { FormRow } from "components-ts/forms/layout";
import FormTimePicker from "../FormTimePicker";
import DatePredicate from "./DatePredicate";

const DateLeadPredicate: React.FC<{ readOnly?: boolean }> = ({ readOnly }) => {
    const { t } = useTranslation();

    return (
        <>
            <FormRow>
                <FormTimePicker
                    name="leadPeriod"
                    label={t(common.leadPeriod)}
                    readOnly={readOnly}
                />
            </FormRow>

            <FormRow>
                <FormPrefixProvider name="date">
                    <DatePredicate readOnly={readOnly} />
                </FormPrefixProvider>
            </FormRow>
        </>
    );
};

export default DateLeadPredicate;
