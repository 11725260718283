import { types } from "./actions";

const initialState = [];

export const messaging = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.DISPLAY_MESSAGE: {
            return [...state, payload];
        }
        case types.REMOVE_MESSAGE:
            return state.filter((m) => m.id === payload);

        default:
            return state;
    }
};
