import defaultListState from "store/defaultListState";
import { combineReducers } from "redux";
import types from "./types";

const formInitialState = {
    isLoading: false,
};

const listInitialState = defaultListState;

const form = (state = formInitialState, { type, payload }) => {
    switch (type) {
        case types.formInit:
            return { ...formInitialState };
        case types.getToolkit.request:
            return {
                ...formInitialState,
                isLoading: true,
            };
        case types.getToolkit.success:
            return {
                ...payload,
                isLoading: false,
            };
        case types.getToolkit.failure:
            return {
                isLoading: false,
            };
        case types.createToolkit.request:
        case types.updateToolkit.request:
            return {
                ...formInitialState,
                isSubbmitRequested: true,
            };
        case types.createToolkit.success:
        case types.createToolkit.failure:
        case types.updateToolkit.success:
        case types.updateToolkit.failure:
            return {
                ...payload,
                isSubbmitRequested: false,
            };
        default:
            return { ...state };
    }
};

const list = (state = listInitialState, { type, payload }) => {
    switch (type) {
        case types.getToolkits.request:
            return {
                ...listInitialState,
                isLoading: true,
            };
        case types.getToolkits.success:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case types.getToolkits.failure:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return { ...state };
    }
};

export const toolkits = combineReducers({
    form,
    list,
});
