import defultListState from "store/defaultListState";
import { combineReducers } from "redux";
import types from "./types";
import { types as rootTypes } from "../action";

const initialState = {
    isLoading: false,
    id: "",
    membershipLevel: 0,
    membershipPoints: 0,
    points: [],
};

const initialStatePoints = defultListState;

const onGet = (state) => ({
    ...state,
    isLoading: true,
});

const onGetSuccess = (state, payload) => ({
    ...state,
    ...payload,
    isLoading: false,
});

const onGetFailure = (state) => ({
    ...state,
    isLoading: false,
});

const defaultAction = (state) => ({ ...state });

const form = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case rootTypes.CUSTOMER_CHANGED:
            return initialState;
        case types.getMembership.request:
            return onGet(state);
        case types.getMembership.success:
            return onGetSuccess(state, payload);
        case types.getMembership.failure:
            return onGetFailure(state, payload);
        default:
            return defaultAction(state);
    }
};

const points = (state = initialStatePoints, { type, payload }) => {
    switch (type) {
        case types.getMembershipPoints.request:
            return {
                ...initialStatePoints,
                isLoading: true,
            };
        case types.getMembershipPoints.success:
            return {
                ...payload,
                isLoading: false,
            };
        case types.getMembershipPoints.failure:
            return {
                isLoading: false,
            };
        default:
            return { ...state };
    }
};

export const customerMembership = combineReducers({
    form,
    points,
});
