import { CampaingsAutocomplete, DivisionSelect } from "components/Controls";
import CustomerListInput from "components/Controls/CustomerListInput";
import { CommerceForm, FormField } from "components/Form";
import { decimalNormalize } from "components/FormHelpers/ControlFormaters";
import { renderFieldOrText } from "components/FormHelpers/Controls";
import Layout from "components/Grid/Layout";
import { FieldArray, reduxForm } from "redux-form";
import { createOrUpdateContract, initContractForm } from "store/offers/contracts/form";
import { formProductPredicatesSelector, formProductsSelector, formUsersSelector, mappedContractFormSelector } from "store/offers/selectors";
import { toObject } from "utils/extensions/arrayExtensions";
import { useCommonTranslation, useCopyRouterState, useFormValueSelector } from "utils/hooks";
import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { usePush } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { validators } from "../validators";
import ProductPredicates from "./components/ProductPredicates";
import Products from "./components/Products";

const formName = "contract-form";

const mapContractProduct = (product) => {
    let modifier = product.modifier;
    let emptyModifier = {
        isWaterLimitsDisabled: product.isWaterLimitsDisabled,
        isProductLimitsDisabled: product.isProductLimitsDisabled,
        productId: product.productId,
        excludeProducts: product.excludeProducts,
        includeProducts: product.includeProducts,
        modifier: { type: product.type },
    };

    switch (product.type) {
        case "discountPercent":
            emptyModifier.modifier.discountPercent = modifier.discountPercent;
            break;
        case "discountValue":
            emptyModifier.modifier.discountValue = modifier.discountValue;
            break;
        case "newPrice":
            emptyModifier.modifier.newPrice = modifier.newPrice;
            break;
        default:
            throw new Error("Effect type cannot be mapped");
    }

    return emptyModifier;
};

const DiscountsForm = ({ handleSubmit, isSubbmitRequested, isSystem, name, initialValues }) => {
    const { id, isCopy } = useCopyRouterState();
    const dispatch = useDispatch();
    const { replace } = usePush();
    useEffect(() => {
        dispatch(initContractForm(id, isCopy));
    }, [dispatch, id, isCopy]);
    const { t, common } = useCommonTranslation();
    const users = useSelector((state) => formUsersSelector(formName, state));
    const products = useSelector((state) => formProductsSelector(formName, state));
    const productPredicates = useSelector((state) => formProductPredicatesSelector(formName, state));
    const divisions = useFormValueSelector(formName, "divisions", []);

    const submit = async (values) => {
        const { queryParameters, ...rest } = values;
        const mapped = {
            ...rest,
            queryParameters: queryParameters ? toObject(queryParameters) : undefined,
        };

        mapped.products = Array.isArray(mapped.products) ? mapped.products.map(mapContractProduct) : null;
        mapped.productPredicates = Array.isArray(mapped.productPredicates) ? mapped.productPredicates.map(mapContractProduct) : null;

        const { payload } = await dispatch(createOrUpdateContract(mapped, !isCopy && id));
        if ((!isCopy && !id) || isCopy) {
            replace(Paths.Offers.ContractForm, { id: payload.id });
        }
    };

    const readOnly = isSystem && !isCopy;

    return (
        <CommerceForm
            onSubmit={handleSubmit((values) => submit(values))}
            isReadonly={readOnly}
            isSubbmitRequested={isSubbmitRequested}
        >
            <Layout
                main
                headerText={t(common.contracts, {
                    id: !isCopy && id ? id : "",
                })}
                navigationProps={{
                    backProps: { path: Paths.Offers.ContractsList },
                }}
                customTitle={id && name && `${t(common.contracts)} - ${name}`}
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid
                        item
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <FormField
                            name={"name"}
                            component={renderFieldOrText}
                            type={"text"}
                            label={t(common.name)}
                            validate={validators.name}
                            readOnly={readOnly}
                        />
                        <CampaingsAutocomplete validate={validators.campaign} />
                        <DivisionSelect selectedDivisions={divisions} />
                    </Grid>
                    <Grid
                        item
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <FormField
                            name={"isActive"}
                            component={renderFieldOrText}
                            type={"boolean"}
                            label={t(common.isActive)}
                            readOnly={readOnly}
                        />
                        <FormField
                            name={"isPositioned"}
                            component={renderFieldOrText}
                            type={"boolean"}
                            label={t(common.isPositioned)}
                            readOnly={readOnly}
                        />
                        <FormField
                            name={"isPriceReplaced"}
                            component={renderFieldOrText}
                            type={"boolean"}
                            label={t(common.isPriceReplaced)}
                            readOnly={readOnly}
                        />
                        <FormField
                            name={"isPriceLocked"}
                            component={renderFieldOrText}
                            type={"boolean"}
                            label={t(common.isPriceLocked)}
                            readOnly={readOnly}
                        />
                    </Grid>

                    <Grid
                        item
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <FormField
                            name={"validFrom"}
                            component={renderFieldOrText}
                            type={"date"}
                            label={t(common.validFrom)}
                            readOnly={readOnly}
                        />
                        <FormField
                            name={"validTo"}
                            component={renderFieldOrText}
                            type={"date"}
                            label={t(common.validTo)}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid
                        item
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <FormField
                            name={"explicitDeliveryCost"}
                            component={renderFieldOrText}
                            type={"text"}
                            format={decimalNormalize}
                            label={t(common.explicitDeliveryCost)}
                            validate={validators.cost}
                            readOnly={readOnly}
                        />
                        <FormField
                            name={"explicitPackagingCost"}
                            component={renderFieldOrText}
                            type={"text"}
                            format={decimalNormalize}
                            label={t(common.explicitPackagingCost)}
                            validate={validators.cost}
                            readOnly={readOnly}
                        />
                    </Grid>

                    <CustomerListInput
                        users={users}
                        validate={validators.customers}
                    />
                </Grid>
            </Layout>
            <FieldArray
                name="products"
                component={Products}
                products={products}
            />
            <FieldArray
                name="productPredicates"
                component={ProductPredicates}
                productPredicates={productPredicates}
            />
        </CommerceForm>
    );
};

const stateToProps = (state) => {
    const { isSubbmitRequested, ...form } = mappedContractFormSelector(state);
    return {
        isSubbmitRequested,
        name: form.name,
        initialValues: {
            ...form,
        },
    };
};

export default connect(stateToProps)(
    reduxForm({
        form: formName,
        enableReinitialize: true,
    })(DiscountsForm)
);
