import { useDispatch } from "react-redux";

export default (actions) => {
    const dispatch = useDispatch();
    const funArray = Object.keys(actions).map((key) => ({
        key: key,
        fun: (...params) => dispatch(actions[key](...params)),
    }));
    const obj = {};
    funArray.forEach(({ key, fun }) => {
        obj[key] = fun;
    });

    return obj;
};
