import { DiscountDocument, DiscountSearchParameters, DiscountSearchTag } from "Commerce-Offer";
import moment from "moment";
import { api } from "api";
import { Division, Divisions } from "types/sharedEnums";
import { useColumns, useFacets, usePush } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { QueryTable } from "components-ts/tables";
import { View } from "components-ts/view";

const DiscountSearch: React.FC = () => {
    const { pushToForm } = usePush();
    const { roleUsers: catmans } = api.identity.roles.useGetUsersWithRoleForSelect(["Catman"], true);

    const columns = useColumns<DiscountDocument>(() => [
        {
            property: "name",
            label: "name",
        },
        {
            property: "divisions",
            label: "divisions",
            as: (value: unknown) => {
                if (value === undefined) {
                    return "";
                }

                return (value as Division[]).join(",");
            },
        },
        {
            property: "validFrom",
            label: "validFrom",
            as: "date",
        },
        {
            property: "validTo",
            label: "validTo",
            as: "date",
        },
        {
            property: "isActive",
            label: "active",
            as: "boolean",
            filtrable: true,
            transformFilterValue: (value) => (value ? true : undefined),
        },
        {
            property: "isPositioned",
            label: "isPositioned",
            as: "boolean",
            filtrable: true,
            transformFilterValue: (value) => (value ? true : undefined),
        },
        {
            property: "isPriceReplaced",
            label: "isPriceReplaced",
            as: "boolean",
            filtrable: true,
            transformFilterValue: (value) => (value ? true : undefined),
        },
        {
            property: "isPriceLocked",
            label: "isPriceLocked",
            as: "boolean",
            filtrable: true,
            transformFilterValue: (value) => (value ? true : undefined),
        },
        {
            property: "createdAt",
            label: "createdAt",
            as: "date",
        },
        {
            property: "updatedAt",
            label: "updatedAt",
            as: "date",
        },
        {
            actionType: "preview",
            onClick: (item) => pushToForm(Paths.Offers.DiscountForm, item.id, false),
        },
        {
            actionType: "copy",
            link: Paths.Offers.DiscountForm,
            getItemId: (item) => item.id,
        },
    ]);

    const facets = useFacets<DiscountSearchParameters>(() => [
        {
            property: "search",
            label: "",
            as: "searchBar",
        },
        {
            property: "validAt",
            label: { resource: "common:validAt" },
            as: "dateTime",
        },
        {
            property: "division",
            label: { resource: "common:division" },
            as: "checkboxList",
            items: Divisions,
            useAndOperator: true,
            addAllCount: true,
        },
        {
            property: "campaignName",
            label: { resource: "common:campaignName" },
            as: "autocomplete",
        },
        {
            property: "categoryManager",
            label: { resource: "common:catman" },
            as: "autocomplete",
            items: catmans,
        },
    ]);

    return (
        <View
            headerText={{ resource: "common:discounts" }}
            isMainView
        >
            <QueryTable
                queryHook={api.commerce.offers.discounts.useFindDiscounts}
                formPath={Paths.Offers.DiscountForm}
                columns={columns}
                defaultParams={{ validAt: moment(), isActive: true }}
                hideToggle
                facets={facets}
                facetPosition="top"
                columnFilterToParams={(property, value, params) => {
                    if (property === "isActive") {
                        return {
                            tag: value
                                ? ["IsActive" as DiscountSearchTag, ...(params.tag || [])]
                                : (params.tag || []).filter((t) => t !== "IsActive"),
                            validAt: value ? moment() : undefined,
                        };
                    } else if (property === "isPositioned") {
                        return {
                            tag: value
                                ? ["IsPositioned" as DiscountSearchTag, ...(params.tag || [])]
                                : (params.tag || []).filter((t) => t !== "IsPositioned"),
                        };
                    } else if (property === "isPriceReplaced") {
                        return {
                            tag: value
                                ? ["IsPriceReplaced" as DiscountSearchTag, ...(params.tag || [])]
                                : (params.tag || []).filter((t) => t !== "IsPriceReplaced"),
                        };
                    } else if (property === "isPriceLocked") {
                        return {
                            tag: value
                                ? ["IsPriceLocked" as DiscountSearchTag, ...(params.tag || [])]
                                : (params.tag || []).filter((t) => t !== "IsPriceLocked"),
                        };
                    } else {
                        return {};
                    }
                }}
            />
        </View>
    );
};

export default DiscountSearch;
