import React, { createContext, useContext, useMemo } from "react";
import { FieldPath, FieldValues } from "react-hook-form";

type PrefixProps<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = TName;

const FormPrefixContext = createContext<PrefixProps | undefined>(undefined);

export const usePrefixContext = () => useContext(FormPrefixContext);

export const FormPrefixProvider = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
    name,
    children,
}: {
    name: TName;
    children: React.ReactNode;
}) => {
    const prefix = usePrefixContext();
    const memoValue = useMemo(() => {
        //https://www.developerway.com/posts/react-re-renders-guide#part7.1
        return prefix ? (`${prefix}.${name}` as TName) : name;
    }, [prefix, name]);

    return <FormPrefixContext.Provider value={memoValue}>{children}</FormPrefixContext.Provider>;
};
