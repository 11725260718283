import { useParams } from "utils/hooks";
import React from "react";
import VoucherList from "./VoucherList";
import VoucherSearch from "./VoucherSearch";

const VoucherView = () => {
    const { type } = useParams();

    if (type === "vouchers") return <VoucherSearch />;
    else return <VoucherList />;
};

export default VoucherView;
