import Button from "components/Button/Button";
import Layout from "components/Grid/Layout";
import { FormSection } from "redux-form";
import { withFormName } from "utils/hoc";
import { useCommonTranslation, useFormValueSelector } from "utils/hooks";
import { Grid } from "@mui/material";
import ArrayValidationError from "./ArrayValidationError";
import DeliveryPredicateField from "./DeliveryPredicateField";

const DeliveryPredicateFields = ({ fields, form, sectionPrefix, meta, deliveries, readOnly = false }) => {
    const { delivery = [] } = useFormValueSelector(form, sectionPrefix, {});
    const { t, common } = useCommonTranslation();

    const current = deliveries ? deliveries : delivery;

    return (
        <Layout
            isEnlarged
            headerText={t(common.deliveryConditions)}
        >
            <ArrayValidationError {...meta} />
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
            >
                {fields.map((field, index) => {
                    const deliveryPredicate = current[index];
                    return (
                        <FormSection
                            key={index}
                            name={field}
                        >
                            <DeliveryPredicateField
                                index={index}
                                remove={(_) => {
                                    fields.remove(index);
                                }}
                                deliveryPredicate={deliveryPredicate}
                                readOnly={readOnly}
                            />
                        </FormSection>
                    );
                })}
                {!readOnly && (
                    <Grid
                        item
                        style={{ marginLeft: "auto" }}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                fields.push({});
                            }}
                        >
                            {t(common.add)}
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Layout>
    );
};

export default withFormName(DeliveryPredicateFields);
