import types from "./types";

const initialState = {
    permissions: {
        roles: [],
        userIds: [],
        categoryUsersIds: [],
        typeUsersIds: [],
    },
    linkedDocuments: {
        documents: [],
        isLoading: false,
        search: {
            items: [],
            filters: {},
            pageIndex: 1,
            pageSize: 25,
            pageCount: 0,
            totalCount: 0,
            isLoading: false,
        },
    },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case types.getDocument.request:
        case types.updateDocument.request:
            return { ...state, isLoading: true };
        case types.getDocumentPermissions.request:
            return { ...state, isLoadingPermissions: true };
        case types.getDocument.success:
        case types.updateDocument.success:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case types.getOriginalFile.success:
            return {
                ...state,
                documentFile: payload,
            };
        case types.getDocumentPermissions.success:
            return {
                ...state,
                permissions: payload === undefined ? state.permissions : { ...payload },
                isLoadingPermissions: false,
            };
        case types.getDocument.failure:
        case types.getDocumentPermissions.failure:
        case types.updateDocument.failure:
            return {
                ...state,
                isLoading: false,
                isLoadingPermissions: false,
            };

        case types.getLinkedDocuments.request:
            return { ...state, linkedDocuments: { ...state.linkedDocuments, isLoading: true } };
        case types.getLinkedDocuments.success:
            return {
                ...state,
                linkedDocuments: { ...state.linkedDocuments, documents: payload, isLoading: false },
            };
        case types.getLinkedDocuments.failure:
            return { ...state, linkedDocuments: { ...state.linkedDocuments, isLoading: false } };

        case types.addLinkedDocument.request:
        case types.removeLinkedDocument.request:
            return { ...state, linkedDocuments: { ...state.linkedDocuments, isLoading: true } };
        case types.addLinkedDocument.success:
        case types.removeLinkedDocument.success:
            return {
                ...state,
                linkedDocuments: { ...state.linkedDocuments, isLoading: false, documents: payload },
            };
        case types.addLinkedDocument.failure:
        case types.removeLinkedDocument.failure:
            return { ...state, linkedDocuments: { ...state.linkedDocuments, isLoading: false } };

        case types.linkedDocumentsSetFilters:
            return {
                ...state,
                linkedDocuments: {
                    ...state.linkedDocuments,
                    search: {
                        ...state.linkedDocuments.search,
                        filters: payload,
                    },
                },
            };

        case types.linkedDocumentsFindArchivedDocuments.request:
            return {
                ...state,
                linkedDocuments: {
                    ...state.linkedDocuments,
                    search: {
                        ...state.linkedDocuments.search,
                        isLoading: true,
                    },
                },
            };
        case types.linkedDocumentsFindArchivedDocuments.success:
            return {
                ...state,
                linkedDocuments: {
                    ...state.linkedDocuments,
                    search: {
                        ...state.linkedDocuments.search,
                        ...payload,
                        isLoading: false,
                    },
                },
            };
        case types.linkedDocumentsFindArchivedDocuments.failure:
            return {
                ...state,
                linkedDocuments: {
                    ...state.linkedDocuments,
                    search: {
                        ...state.linkedDocuments.search,
                        isLoading: false,
                    },
                },
                isLoading: false,
                isLoadingPermissions: false,
            };
        case types.updatePermissions.request:
            return { ...state, isLoading: true };
        case types.updatePermissions.failure:
            return { ...state, isLoading: false };
        case types.updatePermissions.success:
            return { ...state, isLoading: false };
        default:
            return state;
    }
};
