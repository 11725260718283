import { diffCustom } from "utils/objectCompare/diffCustom";
import { createSelector } from "reselect";

const root = (state) => ({
    refundation: state.refundation,
});

const getDefaultArray = (val) => (val ? val : []);

export const refundationsListSelector = createSelector(root, (root) => {
    return root.refundation.list;
});

export const refundationFormSelector = createSelector(root, ({ refundation = {} }) => {
    const { form = {} } = refundation;
    let { current, proposal, changeStatus, ...document } = form;
    const allProducts = [
        ...getDefaultArray(current?.values?.flatMap((x) => x.products)),
        ...getDefaultArray(proposal?.values?.flatMap((x) => x.products)),
    ].map((p) => Number(p));

    if (changeStatus === "RequiresDecision") {
        return {
            changeStatus,
            ...document,
            ...diffCustom(current, proposal, {
                files: {
                    findFunc: (a, b) => a?.fileId === b?.fileId,
                    equalityProperties: ["fileId", "fileName"],
                },
                values: {
                    findFunc: (a, b) => a?.id === b?.id,
                },
            }),
            allProducts,
        };
    }

    if (changeStatus === "Rejected") {
        return { changeStatus, ...document, ...proposal, allProducts };
    }

    return {
        changeStatus,
        isEmpty: !document?.id,
        ...current,
        ...document,
        allProducts,
    };
});
