import { withStyles } from "tss-react/mui";
import Star from "@mui/icons-material/Star";
import StarBorder from "@mui/icons-material/StarBorder";

const RatingIcon = ({ classes, outlined }) => {
    return !outlined ? <Star className={classes.star} /> : <StarBorder className={classes.star} />;
};

export default withStyles(RatingIcon, (theme) => ({
    star: { color: theme.palette.secondary.main },
}));
