import Layout from "components/Grid/Layout";
import { createColumn } from "components/Table/SimpleTable/SimpleTable";
import tableActionTypes from "components/Table/SimpleTable/TableActions/tableActionTypes";
import TableWithToggleFilter from "components/Table/TableWithToggleFilter/TableWithToggleFilter";
import { getGiftList } from "store/offers/gifts/list/actions";
import { giftsListSelector } from "store/offers/selectors";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useCommonTranslation } from "utils/hooks";
import { usePush } from "utils-ts/hooks";

const GiftList = () => {
    const { t, common } = useCommonTranslation();
    const { pushToForm: push } = usePush();
    const pushToForm = (id, isCopy) => push("/offers/gifts/form", id, isCopy);
    const pushToGiftChoices = (id) => push(`/offers/gifts/${id}/gift-choices`);

    const columns = [
        createColumn("name", common.name, formatTypes.text),
        createColumn("validFrom", common.validFrom, formatTypes.time, {
            timeFormat: timePrecisonFormats.seconds,
        }),
        createColumn("validTo", common.validTo, formatTypes.time, {
            timeFormat: timePrecisonFormats.seconds,
        }),
        createColumn("isActive", common.active, formatTypes.boolean),
        createColumn("createdAt", common.createdAt, formatTypes.time, {
            timeFormat: timePrecisonFormats.seconds,
        }),
        createColumn("updatedAt", common.updatedAt, formatTypes.time, {
            timeFormat: timePrecisonFormats.seconds,
        }),
        createColumn("action", "", formatTypes.action, { actionType: tableActionTypes.list }, (item) => pushToGiftChoices(item.id)),
        createColumn("action", "", formatTypes.action, { actionType: tableActionTypes.preview }, (item) => pushToForm(item.id)),
        createColumn("action", "", formatTypes.action, { actionType: tableActionTypes.copy }, (item) => pushToForm(item.id, true)),
    ].map((col) => ({ ...col, value: t(col.value) }));

    return (
        <Layout
            main
            headerText={t(common.gifts)}
        >
            <TableWithToggleFilter
                columns={columns}
                addItemAction={() => pushToForm()}
                dataSelector={giftsListSelector}
                dataSourceAction={getGiftList}
            />
        </Layout>
    );
};

export default GiftList;
