import Adable from "components/Adable/Adable";
import ArrayValidationError from "components/Controls/ArrayValidationError";
import ValueWithType from "components/Controls/ValueWithType";
import Erasable from "components/Erasable/Erasable";
import { FormField } from "components/Form";
import { FormSection } from "redux-form";
import { withFormName } from "utils/hoc";
import { useFormValueSelector } from "utils/hooks";
import { Grid } from "@mui/material";
import _ from "lodash";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { BillingPeriods } from "views/vendors/BillingPeriods";
import { validators } from "../../validators";
import CalculationBaseSelect from "./CalculationBaseSelect";
import ContractsAutocompletes from "./ContractsAutocompletes";

const MarketingBudget = ({ fields, form, meta, readOnly, useDiff }) => {
    const marketingBudgets = useFormValueSelector(form, "marketingBudgets", []);
    const { t } = useTranslation("vendor");

    return (
        <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
        >
            <ArrayValidationError {...meta} />
            <Adable
                handleAdd={() => {
                    fields.push({ typeOfValue: "Money" });
                }}
                renderCondition={!readOnly}
            >
                {fields.map((field, index) => {
                    const budget = marketingBudgets[index] || _.get(marketingBudgets, field.substring(field.indexOf(".") + 1));

                    return (
                        <Erasable
                            handleRemove={() => {
                                fields.remove(index);
                            }}
                            renderCondition={!readOnly}
                            key={index}
                        >
                            <FormSection name={field}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >
                                        <FormField
                                            useDiff={useDiff}
                                            name={"dateFrom"}
                                            label={t(vendor.dateFrom)}
                                            type="date"
                                            readOnly={readOnly}
                                            validate={validators.validateFrom}
                                        />
                                        <FormField
                                            useDiff={useDiff}
                                            name={"dateTo"}
                                            label={t(vendor.dateTo)}
                                            type="date"
                                            readOnly={readOnly}
                                            validate={validators.validateNullableDateTo}
                                        />

                                        <ValueWithType
                                            useDiff={useDiff}
                                            readOnly={readOnly}
                                            labels={{
                                                typeOfValue: t(vendor.typeOfDiscount),
                                                value: t(vendor.value),
                                            }}
                                            validate={{
                                                typeOfValue: validators.required,
                                                value: validators.discountValueWithType,
                                            }}
                                        />

                                        <FormField
                                            useDiff={useDiff}
                                            name={"billingPeriod"}
                                            label={t(vendor.billingPeriod)}
                                            type="select"
                                            readOnly={readOnly}
                                            validate={validators.required}
                                            items={BillingPeriods}
                                        />

                                        <CalculationBaseSelect
                                            readOnly={readOnly}
                                            useDiff={useDiff}
                                            hidden={budget.typeOfValue === "Money"}
                                            validate={budget.typeOfValue === "Money" ? null : validators.required}
                                        />
                                    </Grid>
                                </Grid>
                                <ContractsAutocompletes
                                    readOnly={readOnly}
                                    useDiff={useDiff}
                                />
                            </FormSection>
                        </Erasable>
                    );
                })}
            </Adable>
        </Grid>
    );
};

export default withFormName(MarketingBudget);
