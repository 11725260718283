import { FieldPath, FieldValues } from "react-hook-form";
import { FormControlProps } from "./types";
import { TreeItem } from "control-types";
import { TreePicker } from "components-ts/controls";
import { default as Controller } from "./Controller";

type Props<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = FormControlProps<
    string[],
    TFieldValues,
    TName
> & {
    items: TreeItem[];
    disableEmpty?: boolean;
};

const FormTreePicker = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
    name,
    label,
    defaultValue,
    items,
    disableEmpty,
    emptyValue,
    width,
    readOnly,
}: Props<TFieldValues, TName>): JSX.Element => {
    return (
        <Controller
            name={name}
            render={({ field: { ref, value, onChange, disabled: _, ...fieldProps }, fieldState: { error } }) => {
                return (
                    <TreePicker
                        {...fieldProps}
                        label={label}
                        error={{
                            hasError: Boolean(error),
                            message: error?.message,
                        }}
                        onChange={(value: string[] | string | undefined) => {
                            onChange(value);
                        }}
                        items={items}
                        value={value}
                        disableEmpty={disableEmpty}
                        readOnly={readOnly}
                    />
                );
            }}
            defaultValue={defaultValue}
            transform={(val) => (val ? val : emptyValue)}
            width={width}
        />
    );
};

export default FormTreePicker;
