import { Button } from "components/Button";
import Cell from "components/Table/SimpleTable/Cell";
import Row from "components/Table/SimpleTable/Row";
import SimpleTable, { createColumn } from "components/Table/SimpleTable/SimpleTable";
import Action from "components/Table/SimpleTable/TableActions/Action";
import tableActionTypes from "components/Table/SimpleTable/TableActions/tableActionTypes";
import contractStatuses from "resource/contractStatuses";
import { getContracts, hasPendingDraftContract } from "store/vendors/action";
import { contractsSelector, hasPendingDraftContractSelector, initForm, removeContract } from "store/vendors/contracts";
import { useDebouncedCallback } from "use-debounce";
import { formatTypes, formatValue, timePrecisonFormats } from "utils/formating";
import { useParams } from "utils/hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Icon } from "@mui/material";
import moment from "moment";
import { vendor } from "translations";
import { usePush, useTranslation } from "utils-ts/hooks";
import { IsVendorReadOnly } from "views/vendors/IsVendorReadOnly";

const Contracts = () => {
    const { id: vendorId } = useParams();
    const [filter, setFilter] = useState({
        dateFrom: undefined,
        dateTo: undefined,
    });
    const { t } = useTranslation("vendor");
    const { contracts } = useSelector(contractsSelector);
    const { hasPendingDraftContract: hasDraft } = useSelector(hasPendingDraftContractSelector);
    const dispatch = useDispatch();
    const { push } = usePush();
    const isVendorReadOnly = IsVendorReadOnly();
    useEffect(() => {
        dispatch(getContracts(vendorId));
        dispatch(initForm(vendorId));
        if (!isVendorReadOnly) {
            dispatch(hasPendingDraftContract(vendorId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vendorId, dispatch]);

    const pushToForm = (vendorId, contractId, typeOfChange) =>
        push(`/vendors/${vendorId}/contracts/form${contractId ? `/${contractId}` : ""}${contractId && typeOfChange ? `/${typeOfChange}` : ""}`);

    const columns = [
        createColumn(
            "dateFrom",
            t(vendor.dateFrom),
            formatTypes.time,
            {
                timeFormat: timePrecisonFormats.days,
            },
            null,
            true
        ),
        createColumn(
            "dateTo",
            t(vendor.dateTo),
            formatTypes.time,
            {
                timeFormat: timePrecisonFormats.days,
            },
            null,
            true
        ),
        createColumn("cooperationType", t(vendor.cooperationType), formatTypes.text),
        createColumn("contractStatus", t(vendor.contractStatus), formatTypes.translatable, {
            translationKey: "vendor",
        }),
        createColumn("lastChangedBy", t(vendor.responsibleUser), formatTypes.text),
    ];

    if (isVendorReadOnly) {
        columns.push(
            createColumn("action", "", formatTypes.action, { actionType: tableActionTypes.preview }, (item) => {
                const { contractId } = item;
                pushToForm(vendorId, contractId);
            })
        );
    } else {
        columns.push(
            createColumn(
                "menu",
                "",
                formatTypes.action,
                {
                    actionType: tableActionTypes.menu,
                },
                (item) => {
                    const { id } = item;
                    pushToForm(vendorId, id, "change");
                }
            )
        );
        columns.push(
            createColumn("preview", "", formatTypes.action, { actionType: tableActionTypes.preview }, (item) => () => {
                const { id } = item;
                pushToForm(vendorId, id);
            })
        );

        columns.push(
            createColumn(
                "delete",
                "",
                formatTypes.action,
                { actionType: tableActionTypes.delete },
                (item) => async () => {
                    const { id } = item;
                    const { type } = await dispatch(removeContract(vendorId, id));
                    if (type.endsWith("SUCCESS")) {
                        dispatch(getContracts(vendorId));
                        dispatch(hasPendingDraftContract(vendorId));
                    }
                },
                undefined,
                (item) => !item.contractStatus?.includes("Draft")
            )
        );
    }

    const handleFilter = useDebouncedCallback((column, value) => {
        setFilter({ ...filter, [column]: value });
    }, 200);

    const filterData = (items) => {
        return items
            .filter((x) => {
                const { dateFrom, dateTo } = filter;
                return (
                    (dateFrom ? moment(x.dateFrom).isSameOrAfter(dateFrom, "days") : true) &&
                    (dateTo ? moment(x.dateTo).isSameOrBefore(dateTo, "days") : true)
                );
            })
            .map((x) => ({
                ...x,
                dateFrom: x.dateFrom?.includes("01T00:00:00+") && x.dateFrom.startsWith("0001") ? undefined : x.dateFrom,
            }));
    };

    return (
        <>
            {!isVendorReadOnly && !hasDraft && (
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                pushToForm(vendorId);
                            }}
                        >
                            <Icon>add</Icon>
                        </Button>
                    </Grid>
                </Grid>
            )}

            <SimpleTable
                isLoading={contracts === undefined}
                columns={columns}
                filterFunction={handleFilter}
                onRowDoubleClick={(_, item) => {
                    const { contractId } = item;
                    pushToForm(vendorId, contractId);
                }}
                stripped
                hover
            >
                {filterData(contracts || []).map((contract) => {
                    return (
                        <Row
                            hover
                            key={contract.id}
                        >
                            {columns.map((column, index) => {
                                const { key, type, options, actionType, disabled, action } = column;
                                if (key === "menu") {
                                    if (
                                        contractStatuses.isDecisionRequired(contract.contractStatus) ||
                                        contractStatuses.isDraft(contract.contractStatus) ||
                                        contractStatuses.isReadOnly(contract.contractStatus) ||
                                        hasDraft
                                    ) {
                                        return (
                                            <Cell
                                                key={index}
                                                value={formatValue(contract[key], type, options)}
                                            />
                                        );
                                    }

                                    const actions = [
                                        {
                                            label: "Zmiana",
                                            action: () => {
                                                pushToForm(vendorId, contract.id, "change");
                                            },
                                        },
                                        {
                                            label: "Aneks",
                                            action: () => {
                                                pushToForm(vendorId, contract.id, "annex");
                                            },
                                        },
                                        {
                                            label: "Porozumienie mailowe",
                                            action: () => {
                                                pushToForm(vendorId, contract.id, "mail");
                                            },
                                        },
                                        {
                                            label: "Nowy kontrakt",
                                            action: () => {
                                                pushToForm(vendorId);
                                            },
                                        },
                                    ];

                                    return (
                                        <Action
                                            key={index}
                                            actions={actions}
                                            actionType={actionType}
                                            text={contract[key]}
                                        />
                                    );
                                } else if (key === "delete" || key === "preview") {
                                    return (
                                        <Action
                                            key={index}
                                            text={contract[key]}
                                            {...column}
                                            disabled={disabled ? disabled(contract) : undefined}
                                            handleClick={action(contract)}
                                        />
                                    );
                                }

                                if (key === "action") {
                                    return (
                                        <Action
                                            key={index}
                                            text={contract[key]}
                                            actionType={tableActionTypes.preview}
                                            handleClick={() => pushToForm(vendorId, contract.id)}
                                        />
                                    );
                                }

                                if (key === "cooperationType") {
                                    return (
                                        <Cell
                                            key={index}
                                            value={
                                                t(contract.cooperationType) + (contract.isChange ? " - " + t(`contract${contract.typeOfChange}`) : "")
                                            }
                                        />
                                    );
                                }

                                return (
                                    <Cell
                                        key={index}
                                        value={formatValue(contract[key], type, options)}
                                    />
                                );
                            })}
                        </Row>
                    );
                })}
            </SimpleTable>
        </>
    );
};

export default Contracts;
