import ArrayValidationError from "components/Controls/ArrayValidationError";
import { FormField } from "components/Form";
import { decimalNormalize } from "components/FormHelpers/ControlFormaters";
import { FormSection } from "redux-form";
import { withFormName } from "utils/hoc";
import { Fragment } from "react";
import { Grid } from "@mui/material";
import { Adable, Erasable } from "components";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";
import ContractsAutocompletes from "views/vendors/components/Contracts/components/ContractsAutocompletes";
import { validators } from "../../validators";

const typeOfDiscount = [
    { value: "Basic", name: "Rabat podstawowy" },
    { value: "Promotion", name: "Rabat promocyjny" },
];

const FrontDiscounts = ({ fields, meta, readOnly, useDiff }) => {
    const { t } = useTranslation("vendor");

    return (
        <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
        >
            <ArrayValidationError {...meta} />
            <Adable
                handleAdd={() => {
                    fields.push({});
                }}
                renderCondition={!readOnly}
            >
                {fields.map((field, index) => {
                    return (
                        <Erasable
                            key={index}
                            handleRemove={() => {
                                fields.remove(index);
                            }}
                            renderCondition={!readOnly}
                        >
                            <FormSection
                                name={field}
                                component={Fragment}
                            >
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >
                                        <FormField
                                            useDiff={useDiff}
                                            name={"dateFrom"}
                                            label={t(vendor.dateFrom)}
                                            type="date"
                                            readOnly={readOnly}
                                            validate={validators.validateFrom}
                                        />

                                        <FormField
                                            useDiff={useDiff}
                                            name={"dateTo"}
                                            label={t(vendor.dateTo)}
                                            type="date"
                                            readOnly={readOnly}
                                            validate={validators.validateNullableDateTo}
                                        />
                                    </Grid>

                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >
                                        <FormField
                                            useDiff={useDiff}
                                            name={"typeOfDiscount"}
                                            label={t(vendor.typeOfDiscount)}
                                            type="select"
                                            hideDefaultItem
                                            items={typeOfDiscount}
                                            readOnly={readOnly}
                                            validate={validators.required}
                                        />

                                        <FormField
                                            useDiff={useDiff}
                                            name={"discountValue"}
                                            label={t(vendor.discountValue)}
                                            type="text"
                                            format={decimalNormalize}
                                            readOnly={readOnly}
                                            validate={validators.percent}
                                            adornment={{
                                                position: "end",
                                                value: "%",
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <ContractsAutocompletes
                                    readOnly={readOnly}
                                    useDiff={useDiff}
                                />
                            </FormSection>
                        </Erasable>
                    );
                })}
            </Adable>
        </Grid>
    );
};

export default withFormName(FrontDiscounts);
