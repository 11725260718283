import { hideMessageBox } from "store/common/messageBox/actions";
import { withStyles } from "tss-react/mui";
import React from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MuiDialogActions from "@mui/material/DialogActions";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

const DialogTitle = withStyles(
    (props) => {
        const { children, classes, onClose } = props;
        return (
            <MuiDialogTitle className={classes.container}>
                <div>
                    <Typography variant="h6">{children}</Typography>
                </div>
                {onClose ? (
                    <IconButton
                        aria-label="Close"
                        className={classes.closeButton}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    },
    (theme) => ({
        root: {
            borderBottom: `1px solid ${theme.palette.divider}`,
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    })
);

const DialogContent = withStyles(MuiDialogContent, (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
}));

const DialogActions = withStyles(MuiDialogActions, (theme) => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing(1),
    },
}));

class MessageBox extends React.Component {
    handleClose = () => {
        this.props.onClose();
    };

    render() {
        const { modalProps, open } = this.props;
        const { confirmButtonText, title, rows, ...rest } = modalProps;

        return (
            <Dialog
                onClose={this.handleClose}
                fullWidth={true}
                open={open}
                {...rest}
            >
                <DialogTitle>
                    <Trans>{title}</Trans>
                </DialogTitle>
                <DialogContent>
                    <pre>{rows ? rows.map((error, index) => <Typography key={index}>{error}</Typography>) : undefined}</pre>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={this.handleClose}
                        color="primary"
                    >
                        <Trans>{confirmButtonText}</Trans>
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default connect(
    (state) => {
        return {
            open: state.messageBox.open,
            modalProps: state.messageBox.modalProps,
        };
    },
    (dispatch) => {
        return {
            onClose: () => dispatch(hideMessageBox()),
        };
    }
)(MessageBox);
