import { FormField } from "components/Form";
import { FormSection } from "redux-form";
import { toSelectList } from "utils/extensions/arrayExtensions";
import { withFormName } from "utils/hoc";
import { useCommonTranslation } from "utils/hooks";
import { notEmptyAndInteger, notEmptyAndNumber } from "utils/validators/basic";
import { useChangeType } from "./hooks";

const ProgressiveWrap = ({ progressive, children, handleQuantityChange }) => {
    const { t, common } = useCommonTranslation();
    if (progressive) {
        return (
            <>
                <FormField
                    name={"progressiveQuantity"}
                    label={t(common.quantity)}
                    type="number"
                    onChangeCustom={handleQuantityChange}
                    validate={notEmptyAndNumber}
                />
                {children}
            </>
        );
    }

    return <FormSection name={"modifier"}>{children}</FormSection>;
};

const Modifier = ({ type, form, sectionPrefix, progressive }) => {
    const { t, common } = useCommonTranslation();
    const changeType = useChangeType(form, progressive ? sectionPrefix : `${sectionPrefix}.modifier`);

    const modifierTypes = toSelectList(["discountPercent", "discountValue", "newPrice"], t);

    return (
        <ProgressiveWrap progressive={progressive}>
            <FormField
                name="type"
                label={t(common.modificationType)}
                type="select"
                items={modifierTypes}
                onChange={(_, value) => {
                    changeType({
                        type: value,
                        [value]: 1,
                    });
                }}
                hideDefaultItem
            />
            {type === "discountPercent" ? (
                <FormField
                    name={type}
                    label={t(common.value)}
                    type="number"
                    validate={notEmptyAndInteger}
                />
            ) : (
                <FormField
                    name={type}
                    label={t(common.value)}
                    type="number"
                    validate={notEmptyAndNumber}
                />
            )}
        </ProgressiveWrap>
    );
};

export default withFormName(Modifier);
