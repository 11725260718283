import { IconButton } from "components/Button";
import { FormField } from "components/Form";
import { FieldArray } from "redux-form";
import { makeStyles } from "tss-react/mui";
import Validator from "utils/validators/basic";
import { useTranslation } from "react-i18next";
import { Collapse, Divider, Grid, Typography } from "@mui/material";
import { get, isEmpty } from "lodash";
import { voucher } from "translations";
import { tValidation, validation } from "utils-ts/validations/translation";
import DateLeadPredicate from "./DateLeadPredicate";
import DatePredicate from "./DatePredicate";
import DeliveryMethodsSelectField from "./DeliveryMethodsSelectField";
import PostcodeFields from "./PostcodeFields";

const toggleValues = [
    { value: true, name: "Tak" },
    { value: false, name: "Nie" },
    { value: "", name: "Nie dotyczy" },
];

const useStyles = makeStyles()((theme) => ({
    item: { padding: theme.spacing(1) },
    header: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
}));

const filledDates = (values) => {
    const filled = values.filter(
        (d) =>
            (Boolean(d.dateFrom) && Boolean(d.dateTo)) ||
            (d.hourFrom !== undefined && d.hourTo !== undefined) ||
            (Boolean(d.dayFrom) && Boolean(d.dayTo))
    );

    if (filled?.length === values?.length) {
        return true;
    } else {
        return false;
    }
};

const filledClosingDates = (values) => {
    return !values.some((d) => !filledDates(Array.from([d.date])));
};

const dayOrDate = (values) => {
    const filled = values.filter((d) => (Boolean(d.dateFrom) || Boolean(d.dateTo)) && (Boolean(d.dayFrom) || Boolean(d.dayTo)));

    if (isEmpty(filled)) {
        return true;
    } else {
        return false;
    }
};

const closingDayorDate = (values) => {
    return !values.some((d) => !dayOrDate(Array.from([d.date])));
};

const validators = {
    atLeastOne: (_, form, __, path) => {
        const delivery = get(form, path.substring(0, path.lastIndexOf("]") + 1)) || {};
        return new Validator(delivery)
            .must((d) => {
                return (
                    !isEmpty(d.deliveryMethods) ||
                    !isEmpty(d.currentDates) ||
                    !isEmpty(d.deliveryDates) ||
                    d.isNonTradeSunday === false ||
                    d.isNonTradeSunday === true ||
                    d.isSameDayDelivery === false ||
                    d.isSameDayDelivery === true ||
                    d.isMondayAfterNonTradeSunday === false ||
                    d.isMondayAfterNonTradeSunday === true ||
                    d.isMorningAfterHoliday === false ||
                    d.isMorningAfterHoliday === true ||
                    !isEmpty(d.closingDates)
                );
            }, tValidation(validation.atLeastOneField))
            .validate();
    },
    deliveryDates: (_, form, __, path) => {
        const dates = get(form, path.substring(0, path.lastIndexOf("]") + 1)) || {};

        return new Validator(dates)
            .must((dates) => filledDates(dates?.deliveryDates || []), tValidation(validation.notEmptyFields))
            .must((dates) => dayOrDate(dates?.deliveryDates || []), tValidation(validation.dateOrDay))
            .validate();
    },
    currentDates: (_, form, __, path) => {
        const dates = get(form, path.substring(0, path.lastIndexOf("]") + 1)) || {};

        return new Validator(dates)
            .must((dates) => filledDates(dates?.currentDates || []), tValidation(validation.notEmptyFields))
            .must((dates) => dayOrDate(dates?.currentDates || []), tValidation(validation.dateOrDay))
            .validate();
    },
    closingDates: (_, form, __, path) => {
        const dates = get(form, path.substring(0, path.lastIndexOf("]") + 1)) || {};

        return new Validator(dates)
            .must((dates) => filledClosingDates(dates?.closingDates || []), tValidation(validation.notEmptyFields))
            .must((dates) => closingDayorDate(dates?.closingDates || []), tValidation(validation.dateOrDay))
            .validate();
    },
};

const DeliveryPredicateField = ({ deliveryPredicate = {}, remove, readOnly = false }) => {
    const { t } = useTranslation("voucher");
    const { currentDates = [], deliveryDates = [], postcodes = [], havePostcodeConstraint, closingDates = [] } = deliveryPredicate;
    const { classes } = useStyles();

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Grid
                        item
                        xs={11}
                        className={classes.item}
                    >
                        <DeliveryMethodsSelectField
                            validate={validators.atLeastOne}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={1}
                        className={classes.item}
                    >
                        <IconButton
                            icon="delete"
                            onClick={remove}
                            readOnly={readOnly}
                        />
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <FormField
                        name={"isSameDayDelivery"}
                        label={t(voucher.isSameDayDelivery)}
                        type="toggle"
                        items={toggleValues}
                        readOnly={readOnly}
                    />

                    <FormField
                        name={"isNonTradeSunday"}
                        label={t(voucher.isNonTradeSunday)}
                        type="toggle"
                        items={toggleValues}
                        readOnly={readOnly}
                    />

                    <FormField
                        name={"isMondayAfterNonTradeSunday"}
                        label={t(voucher.isMondayAfterNonTradeSunday)}
                        type="toggle"
                        items={toggleValues}
                        readOnly={readOnly}
                    />

                    <FormField
                        name={"isMorningAfterHoliday"}
                        label={t(voucher.isMorningAfterHoliday)}
                        type="toggle"
                        items={toggleValues}
                        readOnly={readOnly}
                    />
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        style={{ maxWidth: "50%" }}
                    >
                        <Typography
                            variant="h6"
                            className={classes.header}
                        >
                            {t(voucher.currentDates)}
                        </Typography>
                        <FieldArray
                            name="currentDates"
                            component={DatePredicate}
                            currentPredicates={currentDates}
                            validate={[validators.atLeastOne, validators.currentDates]}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        style={{ maxWidth: "50%" }}
                    >
                        <Typography
                            variant="h6"
                            className={classes.header}
                        >
                            {t(voucher.deliveryDates)}
                        </Typography>
                        <FieldArray
                            name="deliveryDates"
                            component={DatePredicate}
                            currentPredicates={deliveryDates}
                            validate={[validators.atLeastOne, validators.deliveryDates]}
                            readOnly={readOnly}
                        />
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{ maxWidth: "50%" }}
                >
                    <Typography
                        variant="h6"
                        className={classes.header}
                    >
                        {t(voucher.closingDates)}
                    </Typography>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    style={{ maxWidth: "50%" }}
                ></Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    style={{ maxWidth: "50%" }}
                >
                    <FieldArray
                        name="closingDates"
                        component={DateLeadPredicate}
                        currentPredicates={closingDates}
                        validate={[validators.atLeastOne, validators.closingDates]}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    style={{ maxWidth: "50%" }}
                ></Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Typography
                        variant="h6"
                        className={classes.header}
                    >
                        {t(voucher.postcodes)}
                    </Typography>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <FormField
                        name={"havePostcodeConstraint"}
                        label={t(voucher.postcodes)}
                        type="boolean"
                        readOnly={readOnly}
                    />
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Collapse in={havePostcodeConstraint}>
                        {havePostcodeConstraint ? (
                            <FieldArray
                                name="postcodes"
                                postcodes={postcodes}
                                component={PostcodeFields}
                                readOnly={readOnly}
                            />
                        ) : (
                            <div className={classes.item}></div>
                        )}
                    </Collapse>
                </Grid>
            </Grid>
            <Divider />
        </>
    );
};

export default DeliveryPredicateField;
