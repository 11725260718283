import { types } from "store/cache/actions";
import { cacheNames } from "utils/cache/cacheProvider";

const initialState = [];

export const products = (state = initialState, { type, payload }) => {
    switch (type) {
        case types(cacheNames.productsStore).UPDATE_CACHE_STORE:
            const updated = state.filter((x) => payload.every((y) => y.productId !== x.productId));
            return [...updated, ...payload.map((x) => ({ productId: x.productId, product: x }))];
        default:
            return state;
    }
};
