import { GetRequest, PostRequest, PutRequest, get, post, put } from "utils/extensions";
import types from "./types";

const endpoint = (vendorId, marketingBudgetId) => `api/vendors/${vendorId}/marketing-budgets${marketingBudgetId ? `/${marketingBudgetId}` : ""}`;

export const getMarketingBudget = (vendorId, marketingBudgetId) => async (dispatch) => {
    await dispatch({ type: types.formInit });

    if (marketingBudgetId) {
        return dispatch(get(new GetRequest(endpoint(vendorId, marketingBudgetId), types.getMarketingBudget.all)));
    }
};

export const getMarketingBudgets = (vendorId, params) => async (dispatch) => {
    return dispatch(get(new GetRequest(endpoint(vendorId), types.getMarketingBudgets.all, { queryParams: params })));
};

export const initMarketingBudgetList = () => async (dispatch) => {
    await dispatch({ type: types.listInit });
};

export const createOrUpdateMarketingBudget = (vendorId, marketingBudgetId, values) => async (dispatch) => {
    if (!marketingBudgetId) {
        return await dispatch(
            post(
                new PostRequest(endpoint(vendorId), types.createMarketingBudget.all, {
                    body: {
                        ...values,
                    },
                })
            )
        );
    } else {
        return await dispatch(
            put(
                new PutRequest(endpoint(vendorId, marketingBudgetId), types.updateMarketingBudget.all, {
                    body: {
                        ...values,
                    },
                })
            )
        );
    }
};

export const makeDecision = (vendorId, marketingBudgetId, isPositive, rejectReason) => async (dispatch) => {
    return dispatch(
        put(
            new PutRequest(endpoint(vendorId, marketingBudgetId) + "/make-decision", types.makeDecision.all, {
                body: {
                    isPositive,
                    rejectReason,
                },
            })
        )
    );
};

export const validateForm = (vendorId, marketingBudgetId, dateFrom, dateTo) => async (dispatch) => {
    return dispatch(
        post(
            new PostRequest(endpoint(vendorId) + "/validate", types.validateForm.all, {
                body: {
                    marketingBudgetId,
                    dateFrom,
                    dateTo,
                },
            })
        )
    );
};

export const getMarketingBudgetsForReservationKit = (vendorId, dateFrom, dateTo) => async (dispatch) => {
    return dispatch(
        get(
            new GetRequest(endpoint(vendorId) + "/for-reservation-kit", types.getBudgetsForReservationKit.all, {
                queryParams: {
                    dateFrom,
                    dateTo,
                },
            })
        )
    );
};
