import { Button, IconButton } from "components/Button";
import ArrayValidationError from "components/Controls/ArrayValidationError";
import PromotionProductsFields from "components/Controls/PromotionProductsFields";
import { FormField } from "components/Form";
import { FieldArray, FormSection } from "redux-form";
import { withFormName } from "utils/hoc";
import { useCommonTranslation, useFormValueSelector } from "utils/hooks";
import Validator from "utils/validators/basic";
import { emptyOrInteger } from "utils/validators/basic";
import { Fragment } from "react";
import { Divider, Grid, Typography } from "@mui/material";
import _ from "lodash";
import { tValidation, validation } from "utils-ts/validations/translation";
import Modifier from "./Modifier";
import { Modifier as ModifierEffect } from "./model";

const progressiveItems = (value) =>
    new Validator(value || [])
        .must(
            (value) => 2 <= value.length,
            tValidation(validation.arrayMustHaveAtLeastItems, {
                number: 2,
            })
        )
        .must((value) => {
            return _.isEqual(
                value,
                _.orderBy(value, (x) => x.progressiveQuantity)
            );
        }, "Ilość w promocji progresywnej musi być narastająca.")
        .validate();

const Modifiers = ({ fields, meta, modifiers = [] }) => {
    const { t, common } = useCommonTranslation();

    return (
        <>
            <ArrayValidationError {...meta} />
            {fields.map((field, index) => {
                return (
                    <FormSection
                        key={field}
                        name={field}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                        >
                            <Modifier
                                type={modifiers[index]?.type}
                                progressive
                            />
                            <IconButton
                                icon="delete"
                                onClick={() => fields.remove(index)}
                            />
                        </Grid>
                    </FormSection>
                );
            })}
            <Button
                color="primary"
                variant="contained"
                onClick={() => {
                    const { discountPercent = 0, progressiveQuantity = 0 } = modifiers[modifiers.length - 1] || {};

                    fields.push(
                        new ModifierEffect(
                            {
                                discountPercent: discountPercent + 1,
                            },
                            progressiveQuantity + 1
                        )
                    );
                }}
            >
                {t(common.add)}
            </Button>
        </>
    );
};

const Cart = ({ form, sectionPrefix }) => {
    const formState = useFormValueSelector(form, `${sectionPrefix}`);
    const { type } = formState;
    const { t, common } = useCommonTranslation();

    return (
        <>
            {type === "modifier" ? (
                <FormField
                    type="number"
                    label={t(common.quantity)}
                    name="quantity"
                    validate={emptyOrInteger}
                />
            ) : (
                <Fragment />
            )}

            <div style={{ width: "100%", marginBottom: 8 }}>
                <PromotionProductsFields
                    name="includeProducts"
                    required={false}
                    title={t(common.includeProducts)}
                />
                <PromotionProductsFields
                    name="excludeProducts"
                    title={t(common.excludeProducts)}
                />
            </div>

            {type === "modifier" ? (
                <Modifier type={formState?.modifier?.type} />
            ) : (
                <>
                    <Grid
                        container
                        direction="column"
                        alignItems="stretch"
                    >
                        <Grid item>
                            <Typography
                                gutterBottom
                                variant="h5"
                            >
                                {"Progi"}
                            </Typography>
                            <Divider
                                variant="middle"
                                style={{ height: 1 }}
                            />
                        </Grid>
                    </Grid>
                    <FieldArray
                        name="progressiveModifiers"
                        component={Modifiers}
                        modifiers={formState?.progressiveModifiers}
                        validate={progressiveItems}
                    />
                </>
            )}
        </>
    );
};

export default withFormName(Cart);
