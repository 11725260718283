import { Button } from "components/Button";
import Layout from "components/Grid/Layout";
import SimpleTable, { createColumn } from "components/Table/SimpleTable/SimpleTable";
import tableActionTypes from "components/Table/SimpleTable/TableActions/tableActionTypes";
import { findEmails, listSelector } from "store/emails";
import { formatTypes } from "utils/formating";
import { useCommonTranslation, usePush } from "utils/hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Icon } from "@mui/material";

const EmailsList = () => {
    const { t, common } = useCommonTranslation();
    const dispatch = useDispatch();
    const { items, pageIndex, pageSize, totalCount, isLoading } = useSelector(listSelector);
    const push = usePush();

    useEffect(() => {
        dispatch(findEmails(pageIndex, pageSize));
    }, [dispatch, pageIndex, pageSize]);

    const onRowClick = (item) => {
        push(`/emails/form/${item.id}`);
    };

    const columns = [
        createColumn("createdBy", t(common.createdBy), formatTypes.text),
        createColumn("subject", t(common.subject), formatTypes.text),
        createColumn("isSended", t(common.isSended), formatTypes.boolean),
        createColumn("action", "", formatTypes.action, { actionType: tableActionTypes.preview }, onRowClick),
    ];

    return (
        <Layout
            main
            headerText={t(common.emails)}
        >
            <SimpleTable
                tableActions={[
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                    push("/emails/form");
                                }}
                            >
                                <Icon>add</Icon>
                            </Button>
                        </Grid>
                    </Grid>,
                ]}
                columns={columns}
                data={items}
                onRowDoubleClick={(_, item) => {
                    onRowClick(item);
                }}
                isLoading={isLoading}
                stripped
                hover
                pagination
                paginationProps={{
                    pageIndex,
                    totalCount,
                    pageSize,
                    onPageChange: (pageIndex) => dispatch(findEmails(pageSize, pageIndex)),
                    onRowsPerPageChange: (pageSize) => dispatch(findEmails(1, pageSize)),
                }}
            />
        </Layout>
    );
};

export default EmailsList;
