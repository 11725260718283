import moment from "moment";

const getAccountingPeriod = (accountingPeriods = [], date) => {
    if (!date) {
        return undefined;
    }

    const dateAccountingPeriod = accountingPeriods.find((p) => {
        return moment(date).isBetween(moment(p.startDate), moment(p.endDate), "minutes", "[]");
    });

    return dateAccountingPeriod;
};

export default getAccountingPeriod;
