import { IconButton } from "components/Button";
import { ArrayValidationError } from "components/Controls";
import { FormField } from "components/Form";
import { FormSection } from "redux-form";
import { useCommonTranslation } from "utils/hooks";
import React, { useCallback } from "react";
import { Delete as DeleteIcon, GetApp as DownloadIcon, FileCopy as FileIcon } from "@mui/icons-material";
import { Avatar, Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from "@mui/material";
import Dropzone from "./Dropzone";

const FileArray = ({ fields, useDiff, readOnly, itemTemplate, accept, handleDownload, meta, canRemoveFromBlob = false }) => {
    const { t, common } = useCommonTranslation();
    const handleDrop = useCallback(
        (acceptedFiles) => {
            acceptedFiles.forEach((file) => {
                const reader = new FileReader();

                reader.onload = function (e) {
                    const fileItem = {
                        extension: `.${file.name.split(".").slice(-1)[0].toLowerCase()}`,
                        fileName: file.name,
                        fileContent: e.target.result.split(",")[1],
                    };

                    fields.push({
                        ...itemTemplate,
                        ...fileItem,
                    });
                };

                reader.readAsDataURL(file);
            });
        },
        [fields, itemTemplate]
    );

    return (
        <>
            <Dropzone
                accept={accept}
                disabled={readOnly || useDiff}
                onDrop={handleDrop}
            />
            <ArrayValidationError {...meta} />
            <List>
                {fields.map((field, index) => {
                    const file = fields.get(index);
                    let fileId = file.fileId;
                    let fileName = file.fileName;
                    if (useDiff) {
                        fileId = file.fileId.proposal;
                        fileName = file.fileName.proposal;
                    }

                    return (
                        <FormSection
                            name={field}
                            key={field}
                        >
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <FileIcon />
                                    </Avatar>
                                </ListItemAvatar>

                                <ListItemText>
                                    <Grid
                                        container
                                        direction="row"
                                    >
                                        <FormField
                                            label={t(common.fileName)}
                                            useDiff={useDiff}
                                            readOnly={true}
                                            name="fileName"
                                        />
                                    </Grid>
                                </ListItemText>

                                <ListItemSecondaryAction>
                                    <Grid
                                        container
                                        direction="row"
                                    >
                                        <Grid item>
                                            <IconButton
                                                edge="end"
                                                onClick={() => handleDownload({ fileId, fileName })}
                                                disabled={!fileId}
                                                icon={<DownloadIcon />}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <IconButton
                                                edge="end"
                                                onClick={() => fields.remove(index)}
                                                disabled={readOnly || useDiff || (!canRemoveFromBlob && !!fileId)}
                                                icon={<DeleteIcon />}
                                            />
                                        </Grid>
                                    </Grid>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </FormSection>
                    );
                })}
            </List>
        </>
    );
};

export default FileArray;
