import { Button } from "components/Button";
import { InfoDialog } from "components/Dialog";
import { renderFieldOrText } from "components/FormHelpers/Controls";
import { Field, reduxForm } from "redux-form";
import { actions as profileActins } from "store/customers/customer/customerProfileActions/action";
import { notEmptyValidator } from "utils/validators/basic";
import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { customers } from "translations";
import { useTranslation } from "utils-ts/hooks";

const validators = {
    shippingAddress: (val) => notEmptyValidator(val).validate(),
};

const customerShippingAddressesSelector = (state) => state.customer.customerProfileActions;

const ExportToAXModal = ({ handleSubmit, open, handleAccept, handleReject, customerId }) => {
    const { t } = useTranslation("customers");
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(profileActins.getShippingAddresses(customerId));
    }, [dispatch, customerId]);

    const { customerShippingAddresses, axExportInProgress } = useSelector(customerShippingAddressesSelector);
    return (
        <InfoDialog
            maxWidth={"sm"}
            open={open}
            title={t(customers.AXExport)}
            onClose={handleReject}
        >
            <form
                onSubmit={handleSubmit(async (values) => {
                    dispatch(profileActins.exportToAx(customerId, values)).then((_) => {
                        handleAccept();
                    });
                })}
            >
                <Field
                    label={t(customers.shippingAddress)}
                    component={renderFieldOrText}
                    type="select"
                    name="shippingAddressId"
                    items={customerShippingAddresses.map((a) => {
                        const { recipient, postcode, city, street, buildingNumber, apartmentNumber } = a.shippingAddress;
                        const name = (
                            <Typography>
                                {a.id} - {recipient}
                                <br /> {street} {buildingNumber + (apartmentNumber ? "/" + apartmentNumber : "")}
                                <br /> {postcode} {city}
                            </Typography>
                        );
                        return {
                            value: a.id,
                            name: name,
                        };
                    })}
                    validate={validators.shippingAddress}
                />
                <Button
                    fullWidth
                    type="submit"
                    loading={axExportInProgress}
                    variant="contained"
                    color="primary"
                >
                    {t(customers.export)}
                </Button>
            </form>
        </InfoDialog>
    );
};

export default connect()(reduxForm({ form: "ax-export" })(ExportToAXModal));
