import { FormField } from "components/Form";
import { productsSuppliersSelector } from "store/autocomplete";
import { orderBy } from "utils/extensions/arrayExtensions";
import { useCommonTranslation } from "utils/hooks";
import React from "react";
import { useSelector } from "react-redux";

const ProductsSuppliersAutocomplete = ({ validate, name = "suppliers", readOnly = false }) => {
    const { t, common } = useCommonTranslation();
    const productsSuppliers = useSelector(productsSuppliersSelector);

    return (
        <FormField
            name={name}
            label={t(common.suppliers)}
            type={"autocomplete"}
            items={orderBy(productsSuppliers)}
            multiple
            stretch
            disableCloseOnSelect
            validate={validate}
            readOnly={readOnly}
        />
    );
};

export default ProductsSuppliersAutocomplete;
