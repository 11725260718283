import { Button } from "components/Button";
import SimpleTable, { createColumn } from "components/Table/SimpleTable/SimpleTable";
import tableActionTypes from "components/Table/SimpleTable/TableActions/tableActionTypes";
import { getMarketingBudgets, marketingBudgetsListSelector } from "store/vendors/marketingBudget";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useParams } from "utils/hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@mui/material";
import { common } from "translations";
import { usePush, useTranslation } from "utils-ts/hooks";
import { IsVendorReadOnly } from "views/vendors/IsVendorReadOnly";
import { Paths } from "routing-ts/ManagerPaths";

const actionsStyle = {
    pb: 1,
    display: "flex",
    justifyContent: "flex-end",
};
const actionStyle = {
    ml: 1,
};

const formName = "MarketingBudgetList";

const MarketingBudgetList = () => {
    const { push } = usePush();
    const { id: vendorId } = useParams();
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    const readOnly = IsVendorReadOnly(formName);
    const { items, pageIndex, pageSize, totalCount, isLoading } = useSelector(marketingBudgetsListSelector);

    useEffect(() => {
        dispatch(
            getMarketingBudgets(vendorId, {
                pageSize,
                pageIndex: 1,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const columns = [
        createColumn("dateFrom", t(common.dateFrom), formatTypes.time, { timeFormat: timePrecisonFormats.days }, null, false),
        createColumn("dateTo", t(common.dateTo), formatTypes.time, { timeFormat: timePrecisonFormats.days }, null, false),
        createColumn("total", t(common.Total), formatTypes.currency, {}),
        createColumn("overLimit", "", formatTypes.infoIcon, {
            tooltip: "Budżet został przekroczony",
        }),
        createColumn("status", t(common.status), formatTypes.translatable, {
            translationKey: "common",
        }),
        createColumn(
            "action",
            "",
            formatTypes.action,
            {
                actionType: () => tableActionTypes.preview,
            },
            (item) => {
                const { id } = item;
                push(Paths.Vendor.MarketingBudgetForm, {
                    vendorId: vendorId,
                    marketingBudgetId: id,
                });
            }
        ),
    ];

    return (
        <>
            <SimpleTable
                tableActions={[
                    !readOnly && (
                        <Button
                            className={actionStyle}
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                push(Paths.Vendor.MarketingBudgetForm, {
                                    vendorId: vendorId,
                                });
                            }}
                        >
                            <Icon>add</Icon>
                        </Button>
                    ),
                ].filter(Boolean)}
                columns={columns}
                data={items}
                onRowDoubleClick={(_, item) => {
                    const { id } = item;
                    push(Paths.Vendor.MarketingBudgetForm, {
                        vendorId: vendorId,
                        marketingBudgetId: id,
                    });
                }}
                isLoading={isLoading}
                stripped
                hover
                pagination
                paginationProps={{
                    pageIndex,
                    totalCount,
                    pageSize,
                    onPageChange: (pageIndex) =>
                        dispatch(
                            getMarketingBudgets(vendorId, {
                                pageSize,
                                pageIndex,
                            })
                        ),
                    onRowsPerPageChange: (pageSize) =>
                        dispatch(
                            getMarketingBudgets(vendorId, {
                                pageSize,
                                pageIndex,
                            })
                        ),
                }}
            />
        </>
    );
};

export default MarketingBudgetList;
