/** @deprecated **/
const OMU = "OMU";
const WAW = "WAW";
/** @deprecated **/
const WA2 = "WA2";
const WA3 = "WA3";
const WRO = "WRO";
const POZ = "POZ";
const KRA = "KRA";
const GDA = "GDA";
/** @deprecated **/
const BYD = "BYD";

export type Division = typeof WAW | typeof WRO | typeof POZ | typeof KRA | typeof GDA | typeof BYD;

export const Divisions: Division[] = [WAW, WRO, POZ, KRA, GDA, BYD];

export const ObsoleteDivisions: Division[] = [BYD];

export type Warehouse = typeof OMU | typeof WAW | typeof WA2 | typeof WA3 | typeof WRO | typeof POZ | typeof KRA | typeof GDA | typeof BYD;

export const Warehouses: Warehouse[] = [OMU, WAW, WA2, WA3, WRO, POZ, KRA, GDA, BYD];

export const GetWarehousesForDivision: (division: Division) => Warehouse[] = (division) => {
    switch (division) {
        case "WAW":
            return [WAW, WA2, WA3];
        case "WRO":
            return [WRO];
        case "KRA":
            return [KRA];
        case "GDA":
            return [GDA];
        case "BYD":
            return [BYD];
        default:
            return [];
    }
};

export const GetDivisionForWarehouse: (warehouse: Warehouse) => Division | undefined = (warehouse) => {
    switch (warehouse) {
        case "OMU":
        case "WAW":
        case "WA2":
        case "WA3":
            return WAW;
        case "WRO":
            return WRO;
        case "POZ":
            return POZ;
        case "KRA":
            return KRA;
        case "GDA":
            return GDA;
        case "BYD":
            return BYD;
        default:
            return undefined;
    }
};

export const ObsoleteWarehouses: Warehouse[] = [OMU, WA2, BYD];

const Frisco = "FRS";
/** @deprecated **/
const Express = "XPR";

export type Channel = typeof Frisco | typeof Express;

export const Channels: Channel[] = [Frisco, Express];

const FR24 = "F24";

export type Merchant = typeof Frisco | typeof FR24;

export const Merchants: Merchant[] = [Frisco, FR24];

const Plastic = "Plastic";
const Paper = "Paper";

export type PackagingMethod = typeof Plastic | typeof Paper;
export const PackagingMethods: PackagingMethod[] = [Plastic, Paper];

const Van = "Van";
const DPD = "DPD";
/** @deprecated **/
const Coolomat = "Coolomat";
const InPost = "InPost";
const InPostFresh = "InPostFresh";

export type DeliveryMethod = typeof Frisco | typeof Van | typeof DPD | typeof Coolomat | typeof InPost | typeof InPostFresh;
export const DeliveryMethods: DeliveryMethod[] = [Frisco, Van, DPD, Coolomat, InPost, InPostFresh];
export const ObsoleteDeliveryMethods: DeliveryMethod[] = [Coolomat];

const CashOnDelivery = "CashOnDelivery";
const CreditCardOnDelivery = "CreditCardOnDelivery";
const Bzwbk = "Bzwbk";
const DotPay = "DotPay";
const Millennium = "Millennium";
const MBank = "MBank";

export type PaymentMethod = typeof CashOnDelivery | typeof CreditCardOnDelivery | typeof Bzwbk | typeof DotPay | typeof Millennium | typeof MBank;

export const PaymentMethods: PaymentMethod[] = [CashOnDelivery, CreditCardOnDelivery, Bzwbk, DotPay, Millennium, MBank];

export type Company = "FRS" | "DRV" | "F24";
