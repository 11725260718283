import { VoucherForm, VoucherView } from "views/vouchers";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import {
    AccountantDepartment,
    B2BAndSupportDepartment,
    ECommerceDepartment,
    MarketingDepartment,
    Role,
    SalesDepartment,
    TradeMarketingDepartment,
} from "../roles";

const roles: Role[] = [
    "Analyst",
    "Management",
    ...AccountantDepartment.GetRoles(),
    ...B2BAndSupportDepartment,
    ...SalesDepartment.GetRoles(),
    ...ECommerceDepartment.GetRoles(),
    ...MarketingDepartment.GetRoles(),
    ...TradeMarketingDepartment.GetRoles(),
];

export const Voucher: PrivateRoute[] = [
    new PrivateRoute("/vouchers", roles, undefined, "vouchers", "card_giftcard", [
        new PrivateRoute("/vouchers/vouchers", roles, undefined, "vouchers", "card_giftcard"),
        new PrivateRoute("/vouchers/generators", roles, undefined, "generators", "card_giftcard"),
        new PrivateRoute("/vouchers/batches", roles, undefined, "batches", "card_giftcard"),
    ]),

    new PrivateRoute(Paths.VouchersList, roles, VoucherView),
    new PrivateRoute(Paths.VoucherForm, roles, VoucherForm),
];
