import defaultListState from "store/defaultListState";
import { USERS_GET_PAGE, USERS_GET_PAGE_FAILURE, USERS_GET_PAGE_SUCCESS } from "./actions";

const initialState = defaultListState;

export const usersList = (state = initialState, action) => {
    state = state || initialState;
    switch (action.type) {
        case USERS_GET_PAGE:
            return { ...state, isLoading: true };
        case USERS_GET_PAGE_SUCCESS:
            return {
                ...state,
                items: action.payload.items.map(({ userId, email, fullName, isDeactivated }) => {
                    return {
                        userId,
                        email,
                        fullName: fullName.firstName + " " + fullName.lastName,
                        isActive: !isDeactivated,
                    };
                }),
                pageIndex: action.payload.pageIndex,
                pageSize: action.payload.pageSize,
                totalCount: action.payload.totalCount,
                isLoading: false,
            };
        case USERS_GET_PAGE_FAILURE:
            return { ...state };
        default:
            return state;
    }
};
