import { manager, operations } from "store/actionTypePrefixes";
import { AsyncTypes, asAsyncFormTypes, asCleanFormDataTypes, createAction } from "utils/extensions";

const types = {
    formInit: createAction("FORM_INIT_MARKETING_BUDGET", manager),
    getMarketingBudgets: new AsyncTypes("GET_MARKETING_BUDGETS", operations),
    getMarketingBudget: new AsyncTypes("GET_MARKETING_BUDGET", operations),
    createMarketingBudget: new AsyncTypes("CREATE_MARKETING_BUDGET", operations, [asAsyncFormTypes, asCleanFormDataTypes]),
    updateMarketingBudget: new AsyncTypes("UPDATE_MARKETING_BUDGET", operations, [asAsyncFormTypes, asCleanFormDataTypes]),
    makeDecision: new AsyncTypes("MAKE_DECISION", operations),
    validateForm: new AsyncTypes("VALIDATE_FORM", operations),
    getBudgetsForReservationKit: new AsyncTypes("GET_BUDGETS_FOR_RESERVATION_KIT", operations),
    listInit: createAction("LIST_INIT_MARKETING_BUDGET", manager),
};

export default types;
