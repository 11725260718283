import { IconButton } from "components/Button";
import React, { useState } from "react";
import { Trans } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { ClickAwayListener, Grid, Grow, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import { signinCallbackKey, signinRedirectKey } from "utils-ts/auth/userManager";

const HeaderLinks = React.forwardRef((props, ref) => {
    const auth = useAuth();
    const [state, setState] = useState({ open: false, openProfile: false });
    const [anchor, setAnchor] = useState(null);

    const handleToggleProfile = (event) => {
        setAnchor(anchor ? null : event.currentTarget);
        setState((state) => ({ openProfile: !state.openProfile }));
    };

    const handleCloseProfile = () => {
        setState({ openProfile: false });
    };

    const handleLogout = async () => {
        handleCloseProfile();
        await auth.removeUser();
        await auth.clearStaleState();

        let i = 0;
        let toRemove = [];
        while (true) {
            let temp = localStorage.key(i);
            if (temp === null) {
                break;
            } else if (temp.startsWith("oidc")) {
                toRemove.push(temp);
            }
            i++;
        }

        toRemove.forEach((key) => {
            localStorage.removeItem(key);
        });
        localStorage.removeItem(signinRedirectKey);
        localStorage.removeItem(signinCallbackKey);

        await auth.signoutRedirect();
    };

    const { openProfile } = state;
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
        >
            <Grid item>{auth.user?.profile?.email}</Grid>
            <Grid item>
                <IconButton
                    ref={ref}
                    onClick={handleToggleProfile}
                    icon="person"
                />
                <Popper
                    open={openProfile}
                    anchorEl={anchor}
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            id="menu-list-grow"
                            style={{
                                transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleCloseProfile}>
                                    <MenuList
                                        style={
                                            window.innerWidth < 959
                                                ? {
                                                      background: "linear-gradient(60deg, #ab47bc, #8e24aa)",
                                                      color: "inherit",
                                                  }
                                                : {}
                                        }
                                    >
                                        <MenuItem onClick={handleCloseProfile}>{auth.user?.profile?.email}</MenuItem>
                                        <MenuItem onClick={handleLogout}>
                                            <Trans>Logout</Trans>
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
        </Grid>
    );
});

export default HeaderLinks;
