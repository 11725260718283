import { types } from "./actions";

const initialState = {
    suspiciousOrdersDetail: [],
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SELECT_ORDER_ALERT:
            return { ...state, ...payload };
        case types.GET_ORDERS_BY_ID:
            return { ...state };
        case types.GET_ORDERS_BY_ID_SUCCESS:
            return { ...state, suspiciousOrdersDetail: [...payload] };
        case types.GET_ORDERS_BY_ID_FAILURE:
            return { ...state };
        default:
            return state;
    }
};
