import { commerce, identity } from "store/actionTypePrefixes";
import { types } from "store/cache/actions";
import { cacheNames } from "utils/cache/cacheProvider";

const initialState = [];

export const userInfos = (state = initialState, { type, payload }) => {
    switch (type) {
        case types(cacheNames.userInfosStore, identity).UPDATE_CACHE_STORE:
            return [...state.filter((x) => payload.every((y) => y.userId !== x.userId)), ...payload.map((x) => ({ ...x }))];
        case types(cacheNames.userInfosStore, commerce).UPDATE_CACHE_STORE:
            return [...state.filter((x) => payload.every((y) => y.id !== x.id)), ...payload.map((x) => ({ ...x }))];
        default:
            return state;
    }
};
