import { Button, IconButton } from "components/Button";
import { SingleSegmentsSelectField } from "components/Controls";
import { DateRangeField } from "components/DateRangeField";
import SingleFileField from "components/Files/SingleFileField";
import { Form, FormField } from "components/Form";
import { saveAs } from "file-saver";
import { reduxForm } from "redux-form";
import {
    createOrUpdateDynamicSegment,
    dynamicSegmentFormSelector,
    getDynamicSegment,
    getDynamicSegmentUsers,
    updateDynamicSegmentUsers,
} from "store/dynamicSegments";
import { useCommonTranslation, useFormValueSelector, useParams } from "utils/hooks";
import { isInteger } from "utils/numberExtensions";
import { greaterDateThan, lessDateThan, notEmpty, notEmptyValidator } from "utils/validators/basic";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid } from "@mui/material";
import * as XLSX from "xlsx";
import { usePush } from "utils-ts/hooks";
import { tValidation, validation } from "utils-ts/validations/translation";
import { Paths } from "routing-ts/ManagerPaths";

const formName = "DynamicSegmentForm";

const notEmptyField = (val) => notEmptyValidator(val).validate();

const validateSegments = (value) => {
    return new notEmptyValidator(value)
        .must(
            (value) => (value || []).every((x) => x.startsWith("dynamic:")),
            tValidation(validation.segmentMustStartsWithPrefix, {
                prefix: "dynamic:",
            })
        )
        .when(notEmpty(value))
        .validate();
};

const validateDateTo = (value, form) => {
    const { validFrom } = form;

    return new notEmptyValidator(value)
        .must(greaterDateThan(validFrom), tValidation(validation.greaterDateThan, { date: validFrom }))
        .when(notEmpty(validFrom))
        .validate();
};

const validateDayFrom = (value, form) => {
    const { validTo } = form;

    return new notEmptyValidator(value)
        .must(lessDateThan(validTo), tValidation(validation.lessDateThan, { date: validTo }))
        .when(notEmpty(validTo))
        .validate();
};

const DynamicSegmentForm = ({ document, pristine, form, handleSubmit, isSubbmitRequested }) => {
    const { dynamicSegmentId } = useParams();
    const { replace } = usePush();
    const dispatch = useDispatch();
    const [file, setFile] = useState(undefined);
    const [fileError, setFileError] = useState("");
    const [userIds, setUserIds] = useState(undefined);
    const { usersCount, userIds: formUserIds } = useFormValueSelector(form, ["usersCount", "userIds"], 0);
    const { t, common } = useCommonTranslation();

    useEffect(() => {
        if (formUserIds != 0 && formUserIds != undefined) {
            createExcelFile(formUserIds);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formUserIds]);

    const handleSubmitForm = async (values) => {
        const { payload } = await dispatch(createOrUpdateDynamicSegment(dynamicSegmentId, values));

        if (!dynamicSegmentId) {
            replace(Paths.DynamicSegmentForm, {
                dynamicSegmentId: payload.id,
            });
        } else {
            await dispatch(getDynamicSegment(dynamicSegmentId));
        }
    };

    const onFileChange = (newFile) => {
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
            try {
                const obj = event.target.result;
                const fileContent = XLSX.read(obj, { type: "binary" });
                const workSheetName = fileContent.SheetNames[0];
                const workSheet = fileContent.Sheets[workSheetName];
                const data = XLSX.utils.sheet_to_csv(workSheet);

                if (data.split("\n").every((x) => isInteger(x))) {
                    setUserIds(data);
                } else {
                    setUserIds([]);
                    setFileError("Plik powinien zawierać jedynie ID użytkowników.");
                }
            } catch (e) {}
        };
        fileReader.readAsBinaryString(newFile);

        setFile({
            ...newFile,
            fileName: newFile.name,
        });
    };

    const importFile = async () => {
        await dispatch(updateDynamicSegmentUsers(dynamicSegmentId, userIds.split("\n")));
        await dispatch(getDynamicSegment(dynamicSegmentId));
    };

    const exportFile = async () => {
        await dispatch(getDynamicSegmentUsers(dynamicSegmentId));
    };

    const createExcelFile = (values) => {
        const workSheet = XLSX.utils.book_new();
        const data = values.users.map((o) => {
            return { id: o };
        });

        XLSX.utils.sheet_add_json(workSheet, data, {
            origin: "A1",
            skipHeader: true,
        });
        const workBook = {
            Sheets: { użytkownicy: workSheet },
            SheetNames: ["użytkownicy"],
        };
        const excelBuffer = XLSX.write(workBook, {
            type: "array",
            cellStyles: true,
        });
        const finalData = new Blob([excelBuffer]);
        saveAs(finalData, "uzytkownicy_" + dynamicSegmentId + ".xlsx");
    };

    return (
        <Form
            onSubmit={handleSubmit(async (values) => {
                values.isActive = values.isActive || false;
                await handleSubmitForm(values);
            })}
            pristine={pristine}
            isSubbmitRequested={isSubbmitRequested}
        >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                <FormField
                    name={"name"}
                    type={"text"}
                    label={t(common.name)}
                    validate={notEmptyField}
                />
                <FormField
                    name={"isActive"}
                    type={"boolean"}
                    label={t(common.isActive)}
                />
                {dynamicSegmentId && (
                    <FormField
                        name={"usersCount"}
                        type={"text"}
                        label={t(common.usersCount)}
                        readOnly={true}
                    />
                )}
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                <DateRangeField
                    formName={formName}
                    startDateName={"validFrom"}
                    endDateName={"validTo"}
                    startDateValidator={validateDayFrom}
                    endDateValidator={validateDateTo}
                    startDateLabel={t(common.dateFrom)}
                    endDateLabel={t(common.dateTo)}
                    readOnly={document.form?.isUsed}
                    blockSameDay={true}
                />
                <SingleSegmentsSelectField
                    name="segments"
                    label={t(common.segments)}
                    validate={validateSegments}
                    tooltipText={t(common.dynamicSegmentTooltip)}
                    onlyDynamic
                    filterOptions={(_, params) => {
                        let filtered = [];
                        if (params.inputValue !== "") {
                            filtered = [params.inputValue];
                        }
                        return filtered;
                    }}
                />
            </Grid>
            {dynamicSegmentId && (
                <>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <FormField
                            name="file"
                            type="file"
                            width="auto"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            validate={notEmpty}
                            component={SingleFileField}
                            handleOnDrop={onFileChange}
                            file={file}
                        />
                        {!!file && (
                            <IconButton
                                size="small"
                                edge="end"
                                onClick={() => {
                                    setUserIds([]);
                                    setFileError("");
                                    setFile(undefined);
                                }}
                                icon={<DeleteIcon />}
                            />
                        )}
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={importFile}
                            disabled={!!!file || fileError != ""}
                        >
                            importuj
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={exportFile}
                            disabled={usersCount <= 0}
                        >
                            exportuj
                        </Button>
                    </Grid>
                    {fileError != "" && (
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <p style={{ color: "#de3428" }}>{fileError}</p>
                        </Grid>
                    )}
                </>
            )}
        </Form>
    );
};

export default connect((state) => {
    const form = dynamicSegmentFormSelector(state);
    return {
        initialValues: form,
        document: form,
        isSubbmitRequested: form?.form?.isSubbmitRequested,
    };
})(
    reduxForm({
        form: formName,
        enableReinitialize: true,
    })(DynamicSegmentForm)
);
