import { FormField } from "components/Form";
import TextWithLabel from "components/Text/TextWithLabel";
import { getPromotion } from "store/vendors/containers/actions";
import { containersSelector } from "store/vendors/containers/selectors";
import { useDebouncedCallback } from "use-debounce";
import { withFormName } from "utils/hoc";
import { useAppLocation, useChange } from "utils/hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import OpenInNew from "@mui/icons-material/OpenInNew";
import { Grid, IconButton } from "@mui/material";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { validators } from "../validators";

const guidRE = /([a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12})/i;

const parseId = (idOrLink) => {
    const match = guidRE.exec(idOrLink);
    return match ? match[0].toLowerCase() : null;
};

const RefundationPromotionRestriction = ({ form, useDiff, readOnly, promotionId, isRestrictedToPromotion = false, isAfterDateTo }) => {
    const { promotions } = useSelector(containersSelector);
    const dispatch = useDispatch();
    const change = useChange(form);
    const promotionContent = promotions[promotionId];
    const isValidId = promotionId && parseId(promotionId) === promotionId;
    const appLocation = useAppLocation();

    const { t } = useTranslation("vendor");

    const onIdOrLinkChanged = (idOrLink) => {
        if (!idOrLink) {
            return;
        }

        const id = parseId(idOrLink);
        if (!!id && idOrLink !== id) {
            change("promotionId", id);
        }
    };
    const debouncedOnIdOrLinkChanged = useDebouncedCallback(onIdOrLinkChanged, 500);
    useEffect(() => {
        debouncedOnIdOrLinkChanged(promotionId);
    }, [promotionId, debouncedOnIdOrLinkChanged]);
    useEffect(() => {
        if (isValidId) {
            dispatch(getPromotion(promotionId));
        }
    }, [promotionId, dispatch, isValidId]);

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
        >
            <FormField
                name="isRestrictedToPromotion"
                label={t(vendor.isRestrictedToPromotion)}
                type="boolean"
                useDiff={useDiff}
                readOnly={readOnly}
            />

            {isRestrictedToPromotion && (
                <FormField
                    name="promotionId"
                    label={t(vendor.containerIdOrLink)}
                    type="text"
                    useDiff={useDiff}
                    readOnly={readOnly}
                    validate={isAfterDateTo ? validators.required : undefined}
                />
            )}

            {!!promotionContent && !promotionContent.isLoading && (
                <>
                    <Grid item>
                        <IconButton
                            readOnly={readOnly}
                            onClick={() => window.open(`${appLocation}/offers/promotions/form/${promotionId}`)}
                        >
                            <OpenInNew />
                        </IconButton>
                    </Grid>
                    {promotionContent.campaignName && (
                        <Grid item>
                            <TextWithLabel
                                label={t(vendor.containerCampaignName)}
                                value={promotionContent.campaignName}
                            />
                        </Grid>
                    )}
                    {promotionContent.name && (
                        <Grid item>
                            <TextWithLabel
                                label={t(vendor.name)}
                                value={promotionContent.name}
                            />
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    );
};

export default withFormName(RefundationPromotionRestriction);
