import tasksStyle from "assets/jss/material-dashboard-react/components/tasksStyle";
import { MANAGER, managerRoles } from "consts/roles";
import { default as appRoles } from "consts/roles";
import { useUser } from "context/UserContext/UserContext";
import { updateUserRoles } from "store/identity/userDetails/actions";
import { withStyles } from "tss-react/mui";
import { connect, useDispatch } from "react-redux";
import { Switch, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { useTranslation } from "utils-ts/hooks";

const isInRole = (roles, role) => roles.some((x) => x === role);
const requiresManagerRole = (role) => managerRoles.some((x) => x === role);

const UserRoles = (props) => {
    const { user } = props;
    const classes = withStyles.getClasses(props);
    const { t } = useTranslation("roles");
    const { isAdmin } = useUser();
    const { roles = [], userId } = user;
    const dispatch = useDispatch();
    const changeUserIsInRole = async (checked, role) => {
        let addRoles = [];
        let removeRoles = [];
        if (checked) {
            if (requiresManagerRole(role) && roles.every((x) => x !== MANAGER)) {
                addRoles = [role, MANAGER];
            } else {
                addRoles = [role];
            }
        } else {
            const newRoles = roles.filter((x) => x !== role);
            if (!newRoles.some((x) => requiresManagerRole(x))) {
                removeRoles = [role, MANAGER];
            } else {
                removeRoles = [role];
            }
        }

        return await dispatch(updateUserRoles(userId, addRoles, removeRoles, isAdmin));
    };

    return (
        <div>
            <Table className={classes.table}>
                <TableBody>
                    {appRoles.all
                        .filter((x) => (!isAdmin ? x !== appRoles.HelpDesk && x !== appRoles.Administrator : true))
                        .map((role) => {
                            return (
                                <TableRow
                                    key={role}
                                    className={classes.tableRow}
                                >
                                    <TableCell>{t(role)}</TableCell>
                                    <TableCell className={classes.tableActions}>
                                        <Switch
                                            color="primary"
                                            checked={isInRole(roles, role)}
                                            onChange={(_, checked) => changeUserIsInRole(checked, role)}
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state?.userDetails?.user || {},
    };
};

export default connect(mapStateToProps)(withStyles(UserRoles, tasksStyle));
