import React, { useEffect, useState } from "react";
import { max, min } from "moment";
import moment from "moment";
import { MARKERS } from "./Makers";
import Menu from "./Menu";
import { getDefaultRanges } from "./defaults";
import { getValidatedMonths, isSameDay, isSameMonth, parseOptionalDate } from "./utils";

const DateRangePicker = ({ open, onChange, initialDateRange, minDate, maxDate, definedRanges = getDefaultRanges(moment()), blockSameDay }) => {
    const today = moment();

    const minDateValid = parseOptionalDate(minDate, moment(today).subtract(10, "year"));
    const maxDateValid = parseOptionalDate(maxDate, moment(today).add(10, "year"));

    const [intialFirstMonth, initialSecondMonth] = getValidatedMonths(initialDateRange || {}, minDateValid, maxDateValid);

    const [dateRange, setDateRange] = useState({
        ...initialDateRange,
    });
    const [hoverDay, setHoverDay] = useState(null);
    const [firstMonth, setFirstMonth] = useState(intialFirstMonth || today);
    const [secondMonth, setSecondMonth] = useState(initialSecondMonth || moment(firstMonth).add(1, "month"));

    const { startDate, endDate } = dateRange;

    useEffect(() => {
        setDateRange(initialDateRange);
    }, [initialDateRange]);

    // handlers
    const setFirstMonthValidated = (date) => {
        if (moment(date).isBefore(secondMonth)) {
            setFirstMonth(date);
        }
    };

    const setSecondMonthValidated = (date) => {
        if (moment(date).isAfter(firstMonth)) {
            setSecondMonth(date);
        }
    };

    const setDateRangeValidated = (range) => {
        let { startDate: newStart, endDate: newEnd } = range;

        if (newStart && newEnd) {
            range.startDate = newStart = max([newStart, minDateValid]);
            range.endDate = newEnd = min([newEnd, maxDateValid]);

            setDateRange(range);
            onChange(range);

            setFirstMonth(newStart);
            setSecondMonth(isSameMonth(newStart, newEnd) ? moment(newStart).add(1, "month") : moment(newEnd));
        } else {
            const emptyRange = {};

            setDateRange(emptyRange);
            onChange(emptyRange);

            setFirstMonth(today);
            setSecondMonth(moment(firstMonth).add(1, "month"));
        }
    };

    const onDayClick = (day) => {
        let validateEndDate = blockSameDay ? moment(day).isAfter(startDate) : !moment(day).isBefore(startDate);

        if (startDate && !endDate && validateEndDate) {
            const newRange = { startDate, endDate: day };
            onChange(newRange);
            setDateRange(newRange);
        } else {
            setDateRange({ startDate: day, endDate: undefined });
        }
        setHoverDay(day);
    };

    const onMonthNavigate = (marker, action) => {
        if (marker === MARKERS.FIRST_MONTH) {
            const firstNew = moment(firstMonth).add(action, "month");
            if (moment(firstNew).isBefore(secondMonth)) setFirstMonth(firstNew);
        } else {
            const secondNew = moment(secondMonth).add(action, "month");
            if (moment(firstMonth).isBefore(secondNew)) setSecondMonth(secondNew);
        }
    };

    const onDayHover = (date) => {
        if (startDate && !endDate) {
            if (!hoverDay || !isSameDay(date, hoverDay)) {
                setHoverDay(date);
            }
        }
    };

    // helpers
    const inHoverRange = (day) => {
        return startDate && !endDate && hoverDay && moment(hoverDay).isAfter(startDate) && moment(day).isBetween(startDate, hoverDay);
    };

    const helpers = {
        inHoverRange,
    };

    const handlers = {
        onDayClick,
        onDayHover,
        onMonthNavigate,
    };

    return open ? (
        <Menu
            dateRange={dateRange}
            minDate={minDateValid}
            maxDate={maxDateValid}
            ranges={definedRanges}
            firstMonth={firstMonth}
            secondMonth={secondMonth}
            setFirstMonth={setFirstMonthValidated}
            setSecondMonth={setSecondMonthValidated}
            setDateRange={setDateRangeValidated}
            helpers={helpers}
            handlers={handlers}
        />
    ) : null;
};

export default DateRangePicker;
