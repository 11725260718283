import { EmailForm, EmailsList } from "views/emails";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import { Role, SupportDepartment } from "../roles";

const roles: Role[] = ["DebtCoordinator", ...SupportDepartment.GetRoles()];

export const Emails: PrivateRoute[] = [
    new PrivateRoute(Paths.EmailsList, roles, EmailsList, "emailsList", "contact_mail"),
    new PrivateRoute(Paths.EmailForm, roles, EmailForm),
];
