import { types } from "store/cache/actions";
import { cacheNames } from "utils/cache/cacheProvider";

const initialState = [];

export const categories = (state = initialState, { type, payload }) => {
    switch (type) {
        case types(cacheNames.categoriesStore).UPDATE_CACHE_STORE:
            const updated = state.filter((x) => payload.every((y) => y.categoryId !== x.categoryId));
            return [...updated, ...payload.map((x) => ({ ...x, category: x.category || {} }))];
        default:
            return state;
    }
};
