import { createSelector } from "reselect";

const root = (state) => ({
    document: state.vendorDocuments,
    autocomplete: state.autocomplete,
});

export const documentsListSelector = createSelector(root, (root) => {
    return {
        autocomplete: root.autocomplete,
        list: {
            ...root.document.list,
            items: root.document.list.items.map((x) => ({
                ...x,
                responsibleUser: x.responsibleUser?.email,
            })),
        },
    };
});

export const documentFormSelector = createSelector(root, ({ document = {} }) => {
    return document.form;
});
