import { manager } from "store/actionTypePrefixes";
import { createId } from "@paralleldrive/cuid2";

export const types = {
    DISPLAY_MESSAGE: `${manager}/DISPLAY_MESSAGE`,
    REMOVE_MESSAGE: `${manager}/REMOVE_MESSAGE`,
};

export const displayMessage = (message) => (dispatch) => {
    dispatch({ type: types.DISPLAY_MESSAGE, payload: { id: createId(), ...message } });
};

export const removeMessage = (id) => (dispatch) => {
    dispatch({ type: types.REMOVE_MESSAGE, paylod: id });
};
