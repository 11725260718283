import { CustomerContactDocument, CustomerContactSubject } from "Commerce-Support";
import { Validator } from "fluentvalidation-ts";
import _ from "lodash";
import { validation } from "translations";
import { emailValidator, notEmptyArray, notEmptyString, phoneNumberValidator } from "utils-ts/validations";
import { tValidation } from "utils-ts/validations/translation";
import { WorkCodesTree } from "./WorkCodesTree";

class ContactSubjectValidator extends Validator<CustomerContactSubject> {
    constructor() {
        super();

        this.ruleFor("workCodes")
            .must(notEmptyArray(1))
            .must({
                predicate: (values: string[]) => this.isPathValid(values),
                message: () => tValidation(validation.lastLevel),
            });
    }

    private isPathValid = (workCodes: string[]): boolean => {
        let currentLevelItems = WorkCodesTree;

        for (let i = 0; i < workCodes.length; i++) {
            const foundItem = currentLevelItems.find((item) => item.name === workCodes[i]);

            if (!foundItem) return false;

            if (i === workCodes.length - 1) {
                return !foundItem.childrens || foundItem.childrens.length === 0;
            } else {
                if (!foundItem.childrens) return false;
                currentLevelItems = foundItem.childrens;
            }
        }

        return workCodes.length > 0;
    };
}

const contactSubjectValidator = new ContactSubjectValidator();

class CustomerContactValidator extends Validator<CustomerContactDocument> {
    constructor() {
        super();

        this.ruleFor("contactType").must(notEmptyString);

        this.ruleFor("subjects").must(notEmptyArray(1));

        this.ruleForEach("subjects").setValidator(() => contactSubjectValidator);

        this.ruleFor("phoneNumber")
            .must(phoneNumberValidator)
            .when((c) => !c.userId && !!c.phoneNumber);

        this.ruleFor("email")
            .must(emailValidator)
            .when((c) => !c.userId && !!c.email);
    }
}

export default CustomerContactValidator;
