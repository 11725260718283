import Cell from "components/Table/SimpleTable/Cell";
import Row from "components/Table/SimpleTable/Row";
import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import Action from "components/Table/SimpleTable/TableActions/Action";
import { withStyles } from "tss-react/mui";
import { formatValue } from "utils/formating";
import { Typography } from "@mui/material";
import classnames from "classnames";
import _ from "lodash";

const ReviewDetailsCell = withStyles(
    ({ issueTypes, issueTypesColumns, reviews, classes }) => {
        const data = reviews
            .map(({ review }) =>
                _.chain(issueTypes)
                    .map(
                        (translation, key) =>
                            review[key] && {
                                type: translation,
                                comment: review[key],
                            }
                    )
                    .filter((x) => Boolean(x))
                    .value()
            )
            .filter((x) => x.length > 0);

        return (
            <Cell colSpan={3}>
                {data && data.length ? (
                    <SimpleTable
                        noHeader
                        columns={issueTypesColumns}
                        data={_.flatten(data)}
                    >
                        {_.flatten(data).map((x, rowIndex) => {
                            return (
                                <Row
                                    key={rowIndex}
                                    className={classnames(classes.row, {
                                        [classes.everySecondRow]: rowIndex % 2 === 1,
                                    })}
                                >
                                    <Cell className={classes.type}>{`${rowIndex + 1}. ${x["type"]}`}</Cell>
                                    <Cell>
                                        <Typography
                                            className={classes.text}
                                            align="justify"
                                            variant="body2"
                                        >
                                            {x["comment"]}
                                        </Typography>
                                    </Cell>
                                </Row>
                            );
                        })}
                    </SimpleTable>
                ) : (
                    <Typography align="center">Brak</Typography>
                )}
            </Cell>
        );
    },
    (theme) => ({
        row: {
            borderBottom: "none",
        },
        everySecondRow: {
            borderTop: `1px solid ${theme.palette.divider}`,
        },
        type: {
            width: 200,
        },
        text: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    })
);

const ReviewListRow = withStyles(
    ({ columns, item, issueTypes, issueTypesColumns, classes, rowIndex }) => {
        return (
            <Row
                hover
                className={classnames({ [classes.odd]: rowIndex % 2 === 1 })}
            >
                {columns.map(({ key, type, options, action, actionType }, index) => {
                    if (key === "id") {
                        return (
                            <Action
                                key={index}
                                handleClick={() => action(item[key])}
                                actionType={actionType}
                                text={item[key]}
                            />
                        );
                    }

                    if (key === "recommendation") {
                        const { reviews } = item;
                        const recomendation = reviews.find((_) => true).review.recommendation;
                        return (
                            <Cell
                                key={index}
                                value={formatValue(recomendation, type, options)}
                            />
                        );
                    }

                    if (key === "details") {
                        const { reviews } = item;
                        return (
                            <ReviewDetailsCell
                                key={index}
                                issueTypesColumns={issueTypesColumns}
                                issueTypes={issueTypes}
                                reviews={reviews}
                            />
                        );
                    }

                    return (
                        <Cell
                            key={index}
                            value={formatValue(item[key], type, options)}
                        />
                    );
                })}
            </Row>
        );
    },
    () => ({
        odd: { backgroundColor: "#f9f9f9" },
    })
);

// const CelL

export default ReviewListRow;
