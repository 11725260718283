import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Action, Cell, Row, SimpleTable } from "components/Table/SimpleTable";
import { createColumn } from "components/Table/SimpleTable/SimpleTable";
import tableActionTypes from "components/Table/SimpleTable/TableActions/tableActionTypes";
import { AccountBalanceCoordinatorRoles, AccountantDepartmentRoles } from "consts/roles";
import paymentMethods from "resource/paymentMethods.json";
import paymentStatuses from "resource/paymentStatuses.json";
import { actions } from "store/payments/paymentList/action";
import AmountBalance from "utils/domainTypes/AmountBalance";
import { formatTypes, formatValue, linkTargets, stringStyle, timePrecisonFormats } from "utils/formating";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { UserConsumer } from "context";
import { bindActionCreators } from "redux";
import { createSelector } from "reselect";
import { common } from "translations";
import { baseCustomerRoles } from "routing-ts/Routes/CustomerRoles";
import Refund from "./paymentList/Refund";
import Withhold from "./paymentList/Withold";

const selector = (state) => state.paymentList;
const paymentsSelector = createSelector(selector, (paymentList) => {
    const { items, ...rest } = paymentList;

    const payments = items
        .map((x) => ({
            ...x,
            title: x?.attributes?.title || x?.attributes?.Title,
        }))
        .map((x) => {
            const { withheldAt, refunds = [] } = x;
            const refunded = refunds.map((x) => new AmountBalance(x.refundAmount)).reduce((c, n) => c.add(n), AmountBalance.empty());
            const remaining = new AmountBalance(x.paymentAmount).add(refunded);

            return {
                ...x,
                status: withheldAt ? "Withhold" : x.status,
                shortPaymentId: x.id.split("/")[1],
                refunded,
                remaining,
            };
        });

    return { items: payments, ...rest };
});

class OrderPaymentList extends Component {
    constructor(props) {
        super(props);
        const { t } = props;
        this.columns = [
            createColumn("orderId", common.orderId, formatTypes.text),
            createColumn("shortPaymentId", common.paymentId, formatTypes.text, {
                style: stringStyle.imporant,
            }),
            createColumn("paymentMethod", common.paymentMethod, formatTypes.resource, {
                items: paymentMethods,
            }),
            createColumn("channelName", common.channelName, formatTypes.text),
            createColumn("title", common.title, formatTypes.text),
            createColumn("paymentAmount", common.paymentAmount, formatTypes.currencyAmount),
            createColumn("refunded", common.refunded, formatTypes.currencyAmount),
            createColumn("remaining", common.remaining, formatTypes.currencyAmount),
            createColumn("externalPaymentId", common.externalPaymentId, formatTypes.text),
            createColumn("status", common.status, formatTypes.resource, {
                items: paymentStatuses,
            }),
            createColumn("createdAt", common.createdAt, formatTypes.time, {
                timeFormat: timePrecisonFormats.seconds,
            }),
            createColumn("action", common.preview, formatTypes.action, { actionType: tableActionTypes.preview }, this.handleCancelPaymentModalOpen),
            createColumn("action", common.withhold, formatTypes.action, { actionType: tableActionTypes.reject }, this.handleWitheldPaymentModalOpen),
        ].map(({ value, ...c }) => ({
            ...c,
            value: t(value),
        }));
    }

    state = {
        withholdPaymentModalOpen: false,
        cancelPaymentModalOpen: false,
        payment: { refunds: [] },
    };

    componentDidMount = () => {
        const { orderNumber, customerId, withPagination } = this.props;

        this.props.getPayments({
            withPagination,
            orderNumber,
            customerId,
            pageIndex: 1,
            pageSize: 25,
        });
    };

    handlePageChange = (pageIndex) => {
        const { orderNumber, customerId, withPagination, pageSize } = this.props;
        this.props.getPayments({
            withPagination,
            orderNumber,
            customerId,
            pageIndex,
            pageSize,
        });
    };

    handlePageSizeChange = (pageSize) => {
        const { orderNumber, customerId, withPagination } = this.props;
        this.props.getPayments({
            withPagination,
            orderNumber,
            customerId,
            pageIndex: 1,
            pageSize,
        });
    };

    handleCancelPaymentModalOpen = (payment) => {
        this.setState(() => ({
            cancelPaymentModalOpen: true,
            payment: payment,
        }));
    };

    handleWitheldPaymentModalOpen = (payment) => {
        this.setState(() => ({
            withholdPaymentModalOpen: true,
            payment: payment,
        }));
    };

    handleCancelPaymentModalClose = () => {
        this.setState({
            cancelPaymentModalOpen: false,
        });
    };

    handleWitheldPaymentModalClose = () => {
        this.setState({
            withholdPaymentModalOpen: false,
        });
    };

    handleTableReload = () => {
        const { orderNumber, customerId, withPagination, pageIndex, pageSize } = this.props;

        this.props.getPayments({
            withPagination,
            orderNumber,
            customerId,
            pageIndex,
            pageSize,
        });
    };

    render = () => {
        const { isLoading, items, withPagination } = this.props;
        const { payment } = this.state;

        let paginationProps = {};
        if (withPagination) {
            const { totalCount, pageIndex, pageSize } = this.props;
            paginationProps = {
                pageIndex,
                totalCount,
                pageSize,
                onPageChange: this.handlePageChange,
                onRowsPerPageChange: this.handlePageSizeChange,
            };
        }

        return (
            <UserConsumer>
                {({ isInAnyRoleOrAdmin }) => {
                    const isInBaseRoles = isInAnyRoleOrAdmin([...baseCustomerRoles, ...AccountantDepartmentRoles]);

                    const columns = this.columns.filter((x) => {
                        if (x.key !== "action") {
                            return true;
                        } else if (x.actionType === tableActionTypes.reject) {
                            return isInAnyRoleOrAdmin([...AccountantDepartmentRoles, ...AccountBalanceCoordinatorRoles]);
                        }

                        return isInBaseRoles;
                    });

                    return (
                        <>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <SimpleTable
                                        isLoading={isLoading}
                                        columns={columns}
                                        data={items}
                                        onRowDoubleClick={(_, payment) => {
                                            if (isInBaseRoles) {
                                                this.handleCancelPaymentModalOpen(payment);
                                            }
                                        }}
                                        shoudlHandleDoubleClick
                                        hover
                                        stripped
                                        pagination={withPagination}
                                        paginationProps={paginationProps}
                                    >
                                        {items.map((payment) => {
                                            return (
                                                <Row
                                                    hover
                                                    key={payment.id}
                                                >
                                                    {columns.map(({ key, value, type, options, action, actionType }, index) => {
                                                        if (key === "action") {
                                                            if (actionType === tableActionTypes.reject) {
                                                                if (payment.paymentMethod === "MBank" && payment.status === "Completed") {
                                                                    return (
                                                                        <Action
                                                                            key={index}
                                                                            actionType={actionType}
                                                                            text={value}
                                                                            handleClick={() => action(payment)}
                                                                        />
                                                                    );
                                                                } else {
                                                                    <Cell
                                                                        key={index}
                                                                        value=""
                                                                    />;
                                                                }
                                                            } else {
                                                                return (
                                                                    <Action
                                                                        key={index}
                                                                        actionType={actionType}
                                                                        text={value}
                                                                        handleClick={() => action(payment)}
                                                                    />
                                                                );
                                                            }
                                                        }

                                                        return (
                                                            <Cell
                                                                key={index}
                                                                value={formatValue(payment[key], type, options)}
                                                            />
                                                        );
                                                    })}
                                                </Row>
                                            );
                                        })}
                                    </SimpleTable>
                                </GridItem>
                            </GridContainer>

                            <Refund
                                open={this.state.cancelPaymentModalOpen}
                                onClose={this.handleCancelPaymentModalClose}
                                payment={payment}
                                reloadTable={this.handleTableReload}
                            />

                            <Withhold
                                open={this.state.withholdPaymentModalOpen}
                                onClose={this.handleWitheldPaymentModalClose}
                                payment={payment}
                                reloadTable={this.handleTableReload}
                                witholdPayment={this.props.withholdPayment}
                            />
                        </>
                    );
                }}
            </UserConsumer>
        );
    };
}

const mapStateToProps = (state) => {
    return paymentsSelector(state);
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ ...actions }, dispatch);

export default withTranslation("common")(connect(mapStateToProps, mapDispatchToProps)(OrderPaymentList));
