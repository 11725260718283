import React from "react";

export const ConfigContext = React.createContext();

const isProduction = (env) => env === "Production";
export const ConfigProvider = ({ children }) => {
    const { config } = window;
    const { env, ...rest } = config;
    const enrichedEnv = {
        env,
        isProduction: isProduction(env),
        isNotProduction: !isProduction(env),
    };

    return <ConfigContext.Provider value={{ ...rest, ...enrichedEnv }}>{children}</ConfigContext.Provider>;
};

export const useConfig = () => {
    return React.useContext(ConfigContext);
};

export const ConfigConsumer = ({ children }) => {
    return <ConfigContext.Consumer>{children}</ConfigContext.Consumer>;
};
