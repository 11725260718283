import { Button } from "components/Button";
import SimpleTable, { createColumn } from "components/Table/SimpleTable/SimpleTable";
import tableActionTypes from "components/Table/SimpleTable/TableActions/tableActionTypes";
import { B2BDepartmentRoles } from "consts/roles";
import { useUser } from "context/UserContext/UserContext";
import { actions } from "store/customers/customer/action";
import { deferredPaymentsListSelector, getDeferredPayments } from "store/customers/customer/deferredPayments/index";
import { useDebouncedCallback } from "use-debounce";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { splitDeferredPaymentId } from "utils/splitIds";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Icon } from "@mui/material";
import { createSelector } from "reselect";
import { vendor } from "translations";
import { usePush, useTranslation } from "utils-ts/hooks";

const root = (state) => state.customer;
const customerSelector = createSelector(root, (o) => o);

const DeferredPaymentList = ({ customerId }) => {
    const { isInAnyRoleOrAdmin } = useUser();
    const isB2B = isInAnyRoleOrAdmin(B2BDepartmentRoles);
    const dispatch = useDispatch();
    const { push } = usePush();
    const [filter, setFilter] = useState({
        status: undefined,
    });
    const { list } = useSelector(deferredPaymentsListSelector);
    const { t } = useTranslation("vendor");
    const {
        customerInfo: { ...info },
    } = useSelector(customerSelector);
    const isOfficeAccount = !!info.isOfficeAccount;

    useEffect(() => {
        dispatch(
            getDeferredPayments(customerId, {
                pageSize: list.pageSize,
                pageIndex: list.pageIndex,
            })
        );
    }, [customerId, dispatch, filter, list.pageSize, list.pageIndex]);

    useEffect(() => {
        dispatch(actions.getCustomer(customerId));
    }, [customerId, dispatch]);

    const { items, pageIndex, pageSize, totalCount, isLoading = true } = list;

    const columns = [
        createColumn(
            "createdAt",
            t(vendor.correctionOk),
            formatTypes.time,
            {
                timeFormat: timePrecisonFormats.days,
            },
            null,
            false
        ),
        createColumn("status", t(vendor.documentStatus), formatTypes.translatable, {
            translationKey: "vendor",
        }),
        createColumn(
            "action",
            "",
            formatTypes.action,
            {
                actionType: tableActionTypes.preview,
                resetButton: false,
            },
            (item) => {
                const { id } = item;
                const { customerId, deferredPaymentNumber } = splitDeferredPaymentId(id);
                push(`/customers/${customerId}/deferred-payments/form/${deferredPaymentNumber}`);
            }
        ),
    ];

    const handleFilter = useDebouncedCallback((column, value) => {
        if (column) {
            setFilter({ ...filter, [column]: value });
        } else {
            setFilter();
        }
    }, 1500);

    return (
        <>
            <SimpleTable
                tableActions={[
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item>
                            {isOfficeAccount && isB2B && (
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => {
                                        push(`/customers/${customerId}/deferred-payments/form`);
                                    }}
                                >
                                    <Icon>add</Icon>
                                </Button>
                            )}
                        </Grid>
                    </Grid>,
                ].filter(Boolean)}
                persistentFilter
                isLoading={isLoading ?? false}
                columns={columns}
                data={items || []}
                filterFunction={handleFilter}
                onRowDoubleClick={(_, item) => {
                    const { id } = item;
                    dispatch(goToDeferredPayment(id));
                }}
                stripped
                hover
                pagination
                paginationProps={{
                    pageIndex,
                    totalCount,
                    pageSize,
                    onChangePage: (pageIndex) =>
                        dispatch(
                            getDeferredPayments(customerId, {
                                ...filter,
                                pageSize: list.pageSize,
                                pageIndex,
                            })
                        ),
                    onChangeRowsPerPage: (pageSize) =>
                        dispatch(
                            getDeferredPayments(customerId, {
                                ...filter,
                                pageIndex: list.pageIndex,
                                pageSize,
                            })
                        ),
                }}
            />
        </>
    );
};

export default DeferredPaymentList;
