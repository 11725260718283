import { RSAAStart } from "store/progress/actions";

const RSAA = "@@redux-api-middleware/RSAA";

export const RSSAStartResolverMiddleware = (store) => (next) => (action) => {
    if (action[RSAA] && action[RSAA].types[0] && action[RSAA].method === "GET") {
        const actionType = action[RSAA].types[0].type ? action[RSAA].types[0].type : action[RSAA].types[0];
        if (!action[RSAA].types[0].meta || !action[RSAA].types[0].meta.ignoreInProgress) {
            store.dispatch(RSAAStart(actionType));
        }
    }

    return next(action);
};
