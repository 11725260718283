import React from "react";

export const useModalState = (initialModalState = false) => {
    const [modalState, setModalState] = React.useState(initialModalState);

    return {
        modalState,
        handleOpen: () => setModalState(true),
        handleClose: () => setModalState(false),
    };
};

export const useSecondModalState = (initialSecondModalState = false) => {
    const [secondModalState, setSecondModalState] = React.useState(initialSecondModalState);

    return {
        secondModalState,
        handleSecondOpen: () => setSecondModalState(true),
        handleSecondClose: () => setSecondModalState(false),
    };
};
