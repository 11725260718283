import { DiscountDocument, DiscountSearchParameters, DiscountSearchResult, DiscountValidationResult } from "Commerce-Offer";
import { useCreateOrUpdateMutation, useGetPagedQuery, useGetQuery } from "api/hooks";

const url = (id?: string) => `api/v1/offer/products/discounts${id ? `/${id}` : ""}`;

const useFindDiscounts = (
    params: DiscountSearchParameters = {
        pageIndex: 1,
        pageSize: 25,
        includeFacets: true,
    }
) => {
    return useGetPagedQuery<DiscountSearchResult, DiscountSearchParameters>({
        app: "commerce",
        url: url("query"),
        queryParams: params,
    });
};

const useGetDiscount = (id?: string) => {
    return useGetQuery<DiscountDocument>({
        app: "commerce",
        url: url(id),
        shouldMakeRequest: Boolean(id),
    });
};

const useGetDiscountValidation = (id?: string) => {
    return useGetQuery<DiscountValidationResult>({
        app: "commerce",
        url: url(id) + "/validation",
        shouldMakeRequest: Boolean(id),
        silentError: true,
    });
};

const useCreateOrUpdateDiscount = (id?: string) => {
    return useCreateOrUpdateMutation<DiscountDocument, DiscountDocument>({
        app: "commerce",
        url: url(id),
        id: id,
    });
};

export default {
    useFindDiscounts,
    useGetDiscount,
    useGetDiscountValidation,
    useCreateOrUpdateDiscount,
};
