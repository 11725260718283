export type FileType = {
    extension: string;
    mimeType: string;
};

const apng: FileType = { extension: "apng", mimeType: "image/apng" };
const avif: FileType = { extension: "avif", mimeType: "image/avif" };
const gif: FileType = { extension: "gif", mimeType: "image/gif" };
const jpg: FileType = { extension: "jpg", mimeType: "image/jpeg" };
const jpeg: FileType = { extension: "jpeg", mimeType: "image/jpeg" };
const jfif: FileType = { extension: "jfif", mimeType: "image/jpeg" };
const pjpeg: FileType = { extension: "pjpeg", mimeType: "image/jpeg" };
const pjp: FileType = { extension: "pjp", mimeType: "image/jpeg" };
const png: FileType = { extension: "png", mimeType: "image/png" };
const svg: FileType = { extension: "svg", mimeType: "image/svg+xml" };
const bmp: FileType = { extension: "bmp", mimeType: "image/bmp" };
const ico: FileType = { extension: "ico", mimeType: "image/x-icon" };
const cur: FileType = { extension: "cur", mimeType: "image/x-icon" };
const tif: FileType = { extension: "tif", mimeType: "image/tiff" };
const tiff: FileType = { extension: "tiff", mimeType: "image/tiff" };
const webp: FileType = { extension: "webp", mimeType: "image/webp" };

const pdf: FileType = { extension: "pdf", mimeType: "application/pdf" };
const zip: FileType = { extension: "zip", mimeType: "application/zip" };
const txt: FileType = { extension: "txt", mimeType: "text/plain" };
const csv: FileType = { extension: "csv", mimeType: "text/csv" };
const doc: FileType = {
    extension: "doc",
    mimeType: "application/msword",
};
const docx: FileType = {
    extension: "docx",
    mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
};
const xls: FileType = {
    extension: "xls",
    mimeType: "application/ms-excel",
};
const xlsx: FileType = {
    extension: "xlsx",
    mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

const fileTypes = {
    apng,
    avif,
    gif,
    jpg,
    jpeg,
    jfif,
    pjpeg,
    pjp,
    png,
    svg,
    bmp,
    ico,
    cur,
    tif,
    tiff,
    webp,
    pdf,
    zip,
    txt,
    csv,
    doc,
    docx,
    xls,
    xlsx,
};

export default fileTypes;

export const ImageFiles = [gif, jpg, jpeg, jfif, pjpeg, pjp, png, bmp, ico, cur];
export const PdfFile = pdf;
export const ExcelFile = xlsx;
export const CsvFile = csv;
