import { createSelector } from "reselect";

const root = (state) => ({
    emails: state.emails,
    commerceUsers: state.cache.commerceUsers,
});

export const listSelector = createSelector(root, ({ emails }) => {
    return emails.list;
});

export const emailFormSelector = createSelector(root, ({ emails }) => {
    return emails.form;
});

export const commerceUsersSelector = createSelector(root, ({ commerceUsers }) => {
    return commerceUsers;
});
