import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import NavListItem from "./NavigationListItem";

const SingleNavigationItem = ({ navigationItem, onNavigate, onInit }) => {
    const dispatch = useDispatch();

    return (
        <NavLink
            to={navigationItem.path}
            className={({ isActive, isPending }) => (isPending ? "pending" : isActive ? "active" : "")}
        >
            <NavListItem
                navigationItem={navigationItem}
                handleClick={() => {
                    onNavigate();
                    if (onInit) {
                        dispatch(onInit());
                    }
                }}
            />
        </NavLink>
    );
};

export default SingleNavigationItem;
