import types from "./types";

const initialState = { isLoading: true };

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case types.getUserReplacements.request:
            return { ...initialState, isLoading: true };
        case types.getUserReplacements.success:
            return { ...state, ...payload, isLoading: false };
        case types.getUserReplacements.failure:
            return { ...state, isLoading: false };
        case types.createOrUpdate.success:
            return { ...state, ...payload };
        default:
            return state;
    }
};
