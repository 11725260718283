import { FormField, FormFieldArray } from "components/Form";
import { decimalNormalize } from "components/FormHelpers/ControlFormaters";
import { FormSection } from "redux-form";
import { withFormName } from "utils/hoc";
import { useChange, useCommonTranslation, useFormValueSelector } from "utils/hooks";
import { Grid } from "@mui/material";
import moment from "moment";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { BillingPeriods } from "views/vendors/BillingPeriods";
import { validators } from "../../validators";

export const BillingPeriodThresholdLabels = new Map([
    ["Monthly", ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]],
    ["Quarterly", ["Q1", "Q2", "Q3", "Q4"]],
    ["HalfYearly", ["H1", "H2"]],
    ["Yearly", ["Year"]],
]);

const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

const billingPeriodToItemsCount = new Map([
    [undefined, null],
    ["Monthly", 12],
    ["Quarterly", 4],
    ["HalfYearly", 2],
    ["Yearly", 1],
]);

const BillingPerioidThresholds = ({ fields, billingPeriod, readOnly, useDiff }) => {
    const { t, common } = useCommonTranslation();
    const labels = BillingPeriodThresholdLabels.get(useDiff ? billingPeriod.proposal : billingPeriod);

    return (
        <>
            {fields.map((field, index) => {
                return (
                    <FormSection
                        key={field}
                        name={field}
                    >
                        <Grid
                            container
                            item
                            direction="row"
                        >
                            <FormField
                                useDiff={useDiff}
                                name="threshold"
                                label={`${t(common.threshold)} ${t(labels[index])}`}
                                format={decimalNormalize}
                                type="text"
                                validate={validators.required}
                                readOnly={readOnly}
                                adornment={{
                                    position: "end",
                                    value: "PLN",
                                }}
                            />
                            <FormField
                                useDiff={useDiff}
                                name="discountValue"
                                label={`${t(common.discount)} ${t(labels[index])}`}
                                format={decimalNormalize}
                                type="text"
                                validate={validators.percent}
                                readOnly={readOnly}
                                adornment={{
                                    position: "end",
                                    value: "%",
                                }}
                            />
                        </Grid>
                    </FormSection>
                );
            })}
        </>
    );
};

const RetroDiscountBillingPeriod = ({ form, sectionPrefix, readOnly, useDiff }) => {
    const { t } = useTranslation("vendor");
    const { billingPeriod } = useFormValueSelector(form, sectionPrefix, {});
    const change = useChange(form);
    const handleBillingPeriodChange = async (_, value) => {
        const arraySize = billingPeriodToItemsCount.get(value);
        const names = BillingPeriodThresholdLabels.get(value);

        await change(`${sectionPrefix}.billingPeriodThresholds`, null);

        change(
            `${sectionPrefix}.billingPeriodThresholds`,
            arraySize
                ? Array(arraySize)
                      .fill({})
                      .map((_, i) => ({ periodName: names[i] }))
                : null
        );
    };

    return (
        <>
            <FormField
                useDiff={useDiff}
                name={"billingPeriod"}
                label={t(vendor.billingPeriod)}
                type="select"
                items={BillingPeriods}
                validate={validators.required}
                readOnly={readOnly}
                onChange={handleBillingPeriodChange}
            />
            <FormField
                useDiff={useDiff}
                name={"year"}
                label={t(vendor.year)}
                type="select"
                readOnly={readOnly}
                items={range(2020, moment().year() + 1, 1).map((x) => ({ value: x, name: x }))}
            />
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                <FormFieldArray
                    useDiff={useDiff}
                    readOnly={readOnly}
                    billingPeriod={billingPeriod}
                    component={BillingPerioidThresholds}
                    name={"billingPeriodThresholds"}
                />
            </Grid>
        </>
    );
};

export default withFormName(RetroDiscountBillingPeriod);
