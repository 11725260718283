import { RSAA } from "redux-api-middleware";
import { commerce } from "store/actionTypePrefixes";
import { combineIntoQueryParams } from "utils/objectExtensions";

export const types = {
    GET_DELIVERY_COSTS: `${commerce}/GET_DELIVERY_COSTS`,
    GET_DELIVERY_COSTS_SUCCESS: `${commerce}/GET_DELIVERY_COSTS_SUCCESS`,
    GET_DELIVERY_COSTS_FAILURE: `${commerce}/GET_DELIVERY_COSTS_FAILURE`,
    REMOVE_DELIVERY_COST: `${commerce}/REMOVE_DELIVERY_COST`,
    REMOVE_DELIVERY_COST_SUCCESS: `${commerce}/REMOVE_DELIVERY_COST_SUCCESS`,
    REMOVE_DELIVERY_COST_FAILURE: `${commerce}/REMOVE_DELIVERY_COST_FAILURE`,
};

const getList =
    ({ isActive, ...rest }) =>
    async (dispatch) => {
        let query = { ...rest };
        if (isActive !== "") query.isActive = isActive;
        const queryParams = combineIntoQueryParams({ ...query });
        return dispatch({
            [RSAA]: {
                endpoint: `api/v1/pricing/delivery-costs?${queryParams}`,
                headers: { "Content-Type": "application/json" },
                method: "GET",
                types: [types.GET_DELIVERY_COSTS, types.GET_DELIVERY_COSTS_SUCCESS, types.GET_DELIVERY_COSTS_FAILURE],
            },
        });
    };

const remove = (id) => async (dispatch) => {
    return dispatch({
        [RSAA]: {
            endpoint: `api/v1/pricing/delivery-costs/${id}`,
            headers: { "Content-Type": "application/json" },
            method: "DELETE",
            types: [types.REMOVE_DELIVERY_COST, types.REMOVE_DELIVERY_COST_SUCCESS, types.REMOVE_DELIVERY_COST_FAILURE],
        },
    });
};

export const actions = {
    getList,
    remove,
};
