import { ProductInternal, ProductInternalParams, SimplifiedProduct, SimplifiedProductQueryParams } from "Commerce-Offer";
import { useGetCacheQuery } from "api/hooks";

const useGetSimplifiedProduct = (
    params: SimplifiedProductQueryParams = {
        productIds: [],
    }
) => {
    return useGetCacheQuery<SimplifiedProduct[], SimplifiedProductQueryParams>({
        app: "commerce",
        url: "api/v1/offer/products/simplified",
        queryParams: params,
        shouldMakeRequest: (params) =>
            params !== undefined && Boolean(params.productIds) && params.productIds.length > 0 && params.productIds.filter(Boolean).length > 0,
        friscoCustomHeaders: params.warehouse
            ? {
                  commerceWarehouse: params.warehouse,
              }
            : undefined,
    });
};

const useGetProductInternal = (productId: string, params: ProductInternalParams) => {
    return useGetCacheQuery<ProductInternal, ProductInternalParams>({
        app: "commerce",
        url: `api/v1/offer/products/${productId}/internal`,
        silentError: true,
        shouldMakeRequest: Boolean(productId),
        friscoCustomHeaders:
            params && (params.dateTimeOverride || params.warehouse)
                ? {
                      commerceWarehouse: params.warehouse,
                      commerceDateTimeOverride: params.dateTimeOverride,
                  }
                : undefined,
    });
};

export default {
    useGetSimplifiedProduct,
    useGetProductInternal,
};
