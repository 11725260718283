import { Button } from "components/Button";
import { submit } from "redux-form";
import { withFormName } from "utils/hoc";
import { useChange } from "utils/hooks";
import { connect, useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { orders } from "translations";
import { useTranslation } from "utils-ts/hooks";

const SaveButtons = ({
    form,
    editionDisabled,
    pristine,
    isDeliveryComplaint,
    submitting,
    handleSubmit,
    handleSubmitVocuher,
    isSubmitted,
    handleExport,
}) => {
    const change = useChange(form);
    const dispatch = useDispatch();
    const { t } = useTranslation("orders");

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
        >
            <Grid
                item
                xs={6}
            >
                <Button
                    style={{ display: editionDisabled ? "none" : "inline" }}
                    disabled={(pristine && !isDeliveryComplaint) || submitting || editionDisabled || isSubmitted}
                    loading={submitting || isSubmitted}
                    type="button"
                    size="large"
                    fullWidth
                    variant="contained"
                    actionType="accept"
                    onClick={async () => {
                        handleExport();
                        await change("voucherRedirect", true);
                        await dispatch(submit(form));
                    }}
                >
                    {t(orders.saveAndNavigateToVoucherForm)}
                </Button>
            </Grid>
            <Grid
                item
                xs={6}
            >
                <Button
                    style={{ display: editionDisabled ? "none" : "inline" }}
                    disabled={(pristine && !isDeliveryComplaint) || submitting || editionDisabled || isSubmitted}
                    loading={submitting || isSubmitted}
                    type="button"
                    size="large"
                    fullWidth
                    variant="contained"
                    actionType="primary"
                    onClick={async () => {
                        handleExport();
                        await change("voucherRedirect", false);
                        await dispatch(submit(form));
                    }}
                >
                    {t(orders.save)}
                </Button>
            </Grid>
        </Grid>
    );
};

export default connect()(withFormName(SaveButtons));
