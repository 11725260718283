import { ContentData, DeliveryPredicateFields, OrderRequirementsFields, PaymentMethodsSelectField, SegmentsSelectFields } from "components/Controls";
import { CommerceForm, FormField } from "components/Form";
import Layout from "components/Grid/Layout";
import { FieldArray, FormSection, reduxForm } from "redux-form";
import { actions } from "store/deliveryCosts/form/actions";
import { toObject } from "utils/extensions/arrayExtensions";
import { useCommonTranslation, useCopyRouterState, useMappedDispatch } from "utils/hooks";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { DeliveryCostRange } from "./components";
import { deliveryCostsFormSelector } from "./selectors";
import { validators } from "./validators";

const DeliveryCostsForm = ({ handleSubmit, isSystem }) => {
    const { t, common } = useCommonTranslation();
    const { id, isCopy } = useCopyRouterState();
    const { initForm, createOrUpdate } = useMappedDispatch(actions);
    useEffect(() => {
        initForm(id, isCopy);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, isCopy]);
    const submit = (values) => {
        const { contentData, ...rest } = values;
        const mapped = {
            ...rest,
            contentData: contentData ? toObject(contentData) : null,
        };

        createOrUpdate(mapped, !isCopy && id);
    };
    return (
        <CommerceForm
            onSubmit={handleSubmit((values) => submit(values))}
            isReadonly={isSystem && !isCopy}
        >
            <Layout
                main
                headerText={t(common.deliveryCosts, {
                    id: !isCopy && id ? id : "",
                })}
                navigationProps={{
                    backProps: { path: "/calendar/delivery-costs" },
                }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <FormField
                            name={"name"}
                            type={"text"}
                            label={t(common.name)}
                            validate={validators.name}
                        />
                        <FormField
                            name={"isActive"}
                            type={"boolean"}
                            label={t(common.isActive)}
                        />
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <FormField
                            name={"validFrom"}
                            type={"date"}
                            label={t(common.validFrom)}
                        />

                        <FormField
                            name={"validTo"}
                            type={"date"}
                            label={t(common.validTo)}
                        />

                        <FormField
                            name={"priority"}
                            type={"text"}
                            label={t(common.priority)}
                        />

                        <FormSection name="requirements">
                            <PaymentMethodsSelectField />
                        </FormSection>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <FormSection name="requirements.user">
                            <OrderRequirementsFields formName={formName} />

                            <SegmentsSelectFields />
                        </FormSection>
                    </Grid>
                </Grid>
            </Layout>

            <FormSection name="requirements">
                <FieldArray
                    name="delivery"
                    formName={formName}
                    component={DeliveryPredicateFields}
                    validate={validators.delivery}
                />
            </FormSection>

            <FieldArray
                name={"ranges"}
                component={DeliveryCostRange}
                validate={validators.ranges}
            />

            <ContentData />
        </CommerceForm>
    );
};

export const formName = "delivery-costs-form";

const stateToProps = (state) => {
    const { isSystem, ...form } = deliveryCostsFormSelector(state);
    return {
        isSystem,
        formData: form,
        initialValues: {
            ...form,
        },
    };
};

export default connect(stateToProps)(
    reduxForm({
        form: formName,
        enableReinitialize: true,
    })(DeliveryCostsForm)
);
