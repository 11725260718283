import { styles } from "assets/jss/common";
import { Button } from "components/Button";
import { renderTextField } from "components/FormHelpers/Controls";
import { Field, reduxForm } from "redux-form";
import { updateEmail } from "store/identity/userDetails/actions";
import { withStyles } from "tss-react/mui";
import { isEqual } from "utils/comparers";
import { useParams } from "utils/hooks";
import { emailValidator } from "utils/validators";
import { withTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import SaveIcon from "@mui/icons-material/Save";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import classNames from "classnames";
import { administration, common } from "translations";

const internalStyles = (theme) => ({
    progress: {
        marginRight: theme.spacing(2),
    },
});

const validate = (values) => {
    const errors = {};
    const requiredFields = ["email"];
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = "Required";
        }
    });
    if (values.email && emailValidator(values.email).validate()) {
        errors.email = "Invalid email address";
    }

    return errors;
};

const ChangeUserEmail = (props) => {
    const dispatch = useDispatch();
    const { userId } = useParams();
    const { handleSubmit, invalid, pristine, submitting, isBusy, t, modelChanged } = props;
    const classes = withStyles.getClasses(props);

    return (
        <form
            onSubmit={handleSubmit((values) => {
                dispatch(updateEmail(userId, values.email));
            })}
        >
            <Grid
                container
                spacing={4}
            >
                <Grid
                    item
                    xs={12}
                    md={6}
                >
                    <Field
                        readOnly={!props.isAdmin}
                        name="email"
                        fullWidth={true}
                        component={renderTextField}
                        label={t(`common:${common.email}`)}
                    />
                </Grid>
                {!modelChanged ? undefined : (
                    <Grid
                        item
                        xs={12}
                        md={6}
                    >
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={invalid || pristine || submitting}
                            type="submit"
                        >
                            {isBusy === true ? (
                                <CircularProgress
                                    className={classes.progress}
                                    size={15}
                                />
                            ) : (
                                <SaveIcon className={classNames(styles.leftIcon, styles.iconSmall)} />
                            )}
                            {t(administration.update)}
                        </Button>
                    </Grid>
                )}
            </Grid>
        </form>
    );
};

export default connect((state) => {
    const initialValues = {};
    if (state.userDetails && state.userDetails.user) {
        const { email } = state.userDetails.user;
        initialValues.email = email;
    }
    let modelChanged = false;

    if (state.form && state.form.ChangeUserEmail) {
        const { initial, values } = state.form.ChangeUserEmail;
        modelChanged = !isEqual(initial, values);
    }

    return {
        initialValues,
        modelChanged,
    };
})(
    reduxForm({
        form: "ChangeUserEmail",
        enableReinitialize: true,
        validate,
    })(withTranslation(["administration", "common"])(withStyles(ChangeUserEmail, internalStyles)))
);
