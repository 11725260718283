import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import { getSubsidiaryGainInvoices, sgInvoicesSelector } from "store/vendors/subsidiaryGains";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useParams } from "utils/hooks";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { CorrectionRow, InvoiceRow } from "./";

const SubsidiaryGainInvoices = () => {
    const dispatch = useDispatch();
    const { id: vendorId, subsidiaryGainId } = useParams();
    useEffect(() => {
        dispatch(getSubsidiaryGainInvoices(vendorId, subsidiaryGainId));
    }, [dispatch, subsidiaryGainId, vendorId]);

    const { t } = useTranslation("vendor");
    const sgInvoices = useSelector(sgInvoicesSelector);
    const columns = [
        { key: "documentType", value: vendor.documentType, type: formatTypes.text },
        { key: "status", value: vendor.documentStatus, type: formatTypes.text },
        { key: "reason", value: vendor.reason, type: formatTypes.text },
        { key: "invoiceNumber", value: vendor.invoiceNumber, type: formatTypes.text },
        {
            key: "documentDate",
            value: vendor.documentDate,
            type: formatTypes.time,
            options: {
                timeFormat: timePrecisonFormats.minutes,
            },
        },
        {
            key: "saleDate",
            value: vendor.saleDate,
            type: formatTypes.time,
            options: {
                timeFormat: timePrecisonFormats.minutes,
            },
        },
        // { key: "addCorrection", value: "", type: formatTypes.action },
        // { key: "retry", value: "", type: formatTypes.action },
        { key: "download", value: "", type: formatTypes.action },
    ].map((c) => ({ ...c, value: t(c.value) }));

    const invoices = sgInvoices.items;

    return invoices ? (
        <>
            <SimpleTable
                stripped
                isLoading={sgInvoices.isLoading}
                columns={columns}
            >
                {invoices.map((item, index) => {
                    return (
                        <React.Fragment key={index}>
                            <InvoiceRow item={item} />
                            {item?.invoice?.corrections?.map((c, i) => {
                                return (
                                    <CorrectionRow
                                        key={i}
                                        item={c}
                                        invoiceId={item.id}
                                    />
                                );
                            })}
                        </React.Fragment>
                    );
                })}
            </SimpleTable>
        </>
    ) : (
        <></>
    );
};

export default SubsidiaryGainInvoices;
