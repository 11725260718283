import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import { memoListSelector } from "store/customers/customer/customerMemos";
import { updateCustomerMemosParams } from "store/customers/customer/customerMemos/actions";
import { formatTypes, stringStyle, timePrecisonFormats } from "utils/formating";
import { useCommonTranslation } from "utils/hooks";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import MemoRow from "./MemoRow";

const MemoTable = ({ openDialog, customerId, handleMemoUpdate, handleDelete, readOnly }) => {
    const dispatch = useDispatch();
    const customerMemos = useSelector(memoListSelector) || {};
    const { isLoading, items, pageIndex, totalCount, pageSize } = customerMemos.list;
    const { t, common } = useCommonTranslation();

    const columns = useMemo(
        () =>
            [
                { key: "isImportant", value: "", type: formatTypes.text },
                { key: "text", value: common.comment, type: formatTypes.text },
                {
                    key: "createdBy",
                    value: common.createdBy,
                    type: formatTypes.text,
                    options: { style: stringStyle.imporant },
                },
                {
                    key: "createdAt",
                    value: common.createdAt,
                    type: formatTypes.time,
                    options: { timeFormat: timePrecisonFormats.seconds },
                },
                readOnly ? undefined : { key: "edit", value: "", type: formatTypes.action },
                readOnly ? undefined : { key: "delete", value: "", type: formatTypes.action },
            ]
                .filter(Boolean)
                .map((col) => ({ ...col, value: t(col.value) })),
        [t, readOnly]
    );

    return (
        <SimpleTable
            isLoading={isLoading}
            columns={columns}
            onRowDoubleClick={openDialog}
            shoudlHandleDoubleClick
            pagination
            hover
            stripped
            paginationProps={{
                disableSavingPagination: true,
                pageIndex,
                totalCount,
                pageSize,
                pageSizeOptions: [10, 25],
                onPageChange: (pageIndex) => dispatch(updateCustomerMemosParams({ customerId, pageIndex, pageSize })),
                onRowsPerPageChange: (pageSize) => dispatch(updateCustomerMemosParams({ customerId, pageIndex: 1, pageSize })),
            }}
        >
            {items.map((memo) => (
                <MemoRow
                    key={memo.id}
                    handleMemoUpdate={handleMemoUpdate}
                    handleDelete={handleDelete}
                    columns={columns}
                    handleEdit={openDialog}
                    memo={memo}
                    readOnly={readOnly}
                />
            ))}
        </SimpleTable>
    );
};

export default MemoTable;
