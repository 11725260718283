import { manager, operations } from "store/actionTypePrefixes";
import { AsyncTypes, asAsyncFormTypes, createAction } from "utils/extensions";

const types = {
    getReservationKits: new AsyncTypes("GET_RESERVATION_KITS", operations),
    getReservationKit: new AsyncTypes("GET_RESERVATION_KIT", operations),
    removeReservationKit: new AsyncTypes("DELETE_RESERVATION_KIT", operations),
    removeReservation: new AsyncTypes("DELETE_RESERVATION", operations),
    createReservationKit: new AsyncTypes("CREATE_RESERVATION_KIT", operations, [(types) => asAsyncFormTypes(types)]),
    updateReservationKit: new AsyncTypes("UPDATE_RESERVATION_KIT", operations, [asAsyncFormTypes]),
    sentToVendorReservationKit: new AsyncTypes("SENEDED_TO_VENDOR_RESERVATION_KIT", operations, [asAsyncFormTypes]),
    canCreateReservationKit: new AsyncTypes("CAN_ACCEPT_RESERVATION_KIT", operations),
    canCreateCyclicReservationKit: new AsyncTypes("CAN_ACCEPT_CYCLIC_RESERVATION_KIT", operations),
    makeDecisionReservationKit: new AsyncTypes("MAKE_DECISION_RESERVATION_KIT", operations, [asAsyncFormTypes]),
    formInit: createAction("FORM_INIT_RESERVATION_KIT", manager),
    fillReservationWithTool: createAction("FILL_RESERVATION_WITH_TOOL", manager),
    preCommit: new AsyncTypes("PRE_COMMIT_RESERVATION_KIT", operations),
    updateReservationPosition: new AsyncTypes("UPDATE_RESERVATION_POSITION", operations),
    updateLocalReservationPosition: createAction("UPDATE_LOCAL_RESERVATION_POSITION", operations),
    updateReservationStatus: new AsyncTypes("UPDATE_RESERVATION_STATUS", operations),
    overlappingReservationKitInit: createAction("OVERLAPPING_RESERVATION_KIT_INIT", manager),
    checkForOverlappingForm: new AsyncTypes("CHECK_FOR_OVERLAPPING_FORM", operations),
    splitReservationKit: new AsyncTypes("SPLIT_RESERVATION_KIT", operations),
    updateReservationsIndexes: new AsyncTypes("UPDATE_RESERVATIONS_INDEXES", operations),
};

export default types;
