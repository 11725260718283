import { FileImport } from "components/Files";
import { displayMessage } from "store/messaging/actions";
import { refundationFormSelector } from "store/vendors/refundations";
import { getRefundationFromFile } from "store/vendors/refundations/action";
import { useParams } from "utils/hooks";
import { useDispatch, useSelector } from "react-redux";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";

const ImportRefundation = ({ readOnly }) => {
    const { isImporting } = useSelector(refundationFormSelector);
    const { vendorId, refundationId } = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation("vendor");

    const downloadTemplate = () => {
        let link = document.createElement("a");
        link.setAttribute("type", "hidden");
        link.href = "/Refundacja.xlsx";
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    const handleImport = async (file) => {
        await dispatch(
            displayMessage({
                variant: "info",
                message: t(vendor.importingRefundation),
            })
        );

        const result = await dispatch(getRefundationFromFile(vendorId, file));

        if (result.error) {
            await dispatch(
                displayMessage({
                    variant: "error",
                    message: result.payload.response.message,
                })
            );
        } else if (result.payload.errors && result.payload.errors.length > 0) {
            await dispatch(
                displayMessage({
                    variant: "warning",
                    message: t(vendor.refundationImportedWithErrors),
                })
            );

            result.payload.errors.forEach((error) => {
                dispatch(
                    displayMessage({
                        variant: "error",
                        message: error,
                    })
                );
            });
        } else {
            await dispatch(
                displayMessage({
                    variant: "success",
                    message: t(vendor.refundationSuccesfullyImported),
                })
            );
        }
    };

    if (readOnly || refundationId) {
        return <></>;
    }

    return (
        <FileImport
            isImporting={isImporting}
            handleImport={handleImport}
            downloadTemplate={downloadTemplate}
        />
    );
};

export default ImportRefundation;
