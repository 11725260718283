import { operations } from "store/actionTypePrefixes";
import { AsyncTypes, asAsyncFormTypes } from "utils/extensions";

const types = {
    getVendor: new AsyncTypes("GET_VENDOR", operations),
    getVendorsList: new AsyncTypes("GET_VENDOR_LIST", operations),
    getVendorsExtList: new AsyncTypes("GET_VENDOR_EXT_LIST", operations),
    getVendorInvoices: new AsyncTypes("GET_VENDOR_INVOICES", operations),
    getVendorContracts: new AsyncTypes("GET_VENDOR_CONTRACTS", operations),
    getVendorContractsChanged: new AsyncTypes("GET_VENDOR_CONTRACTS_CHANGED", operations),
    getVendorCreditLimits: new AsyncTypes("GET_VENDOR_CREDIT_LIMITS", operations),
    getVendorCreditLimitsHasPendingOperation: new AsyncTypes("GET_VENDOR_CREDIT_LIMITS_HAS_PENDING_OPERATION", operations),
    getVendorContractHasPendingDraft: new AsyncTypes("GET_VENDOR_CONTRACTS_HAS_PENDING_DRAFT", operations),
    updateVendor: new AsyncTypes("UPDATE_VENDOR", operations, [asAsyncFormTypes]),
    assignVendorUsers: new AsyncTypes("ASSIGN_VENDOR_USERS", operations, [asAsyncFormTypes]),
};

export default types;
