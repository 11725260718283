import GridContainer from "components/Grid/GridContainer";
import { actions } from "store/orderAlerts/orderAlert/actions";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { orders } from "translations";
import { useTranslation } from "utils-ts/hooks";
import SuspiciousOrder from "./SuspiciousOrder";

const SuspiciousOrders = ({ customerId, suspiciousOrders, getOrders, suspiciousOrdersDetail }) => {
    const { t } = useTranslation("orders");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => {
        getOrders(customerId, suspiciousOrders);
    }, [getOrders]);

    return (
        <GridContainer
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            iconName="sync_alt"
            title={`orders:${orders.suspiciousOrdersTitle}`}
        >
            {suspiciousOrdersDetail.map((order) => (
                <SuspiciousOrder
                    key={order.id}
                    order={order}
                />
            ))}
        </GridContainer>
    );
};

const mapStateToProps = (state) => {
    const {
        orderAlerts: {
            orderAlert: { suspiciousOrdersDetail },
        },
    } = state;
    return { suspiciousOrdersDetail };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SuspiciousOrders);
