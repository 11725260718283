import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import { formatTypes } from "utils/formating";
import { useAppLocation, useCommonTranslation } from "utils/hooks";
import React from "react";

const ProductVariants = ({ variants = [] }) => {
    const { t, common } = useCommonTranslation();
    const appLocation = useAppLocation();

    const columns = [
        {
            key: "productId",
            value: common.productId,
            type: formatTypes.link,
            options: {
                target: "_blank",
                url: (id) => `${appLocation}/offers/product/${id}`,
            },
        },
        {
            key: "ean",
            value: common.ean,
            type: formatTypes.text,
        },
        {
            key: "packSize",
            value: common.packSize,
            type: formatTypes.text,
        },
    ];

    return (
        <SimpleTable
            stripped
            hover
            columns={columns.map((c) => ({ ...c, value: t(c.value) }))}
            data={variants}
        />
    );
};

export default ProductVariants;
