import { makeStyles } from "tss-react/mui";
import { useLocation } from "react-router-dom";
import { Collapse, List } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { useUser } from "context";
import NavListItem from "./NavigationListItem";
import SingleNavigationItem from "./SingleNavigationItem";

const useStyles = makeStyles()((theme) => ({
    nestedItems: {
        background: alpha(theme.palette.primary.dark, 0.3),
        paddingLeft: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
    },
}));

const NestedNavigationItem = ({ navigationItem = { items: [] }, handleClick, open, onNavigate }) => {
    const { pathname } = useLocation();
    const active = navigationItem?.items?.some((x) => {
        return pathname.includes(x.path);
    });
    const { isInAnyRoleOrAdmin } = useUser();
    const { classes } = useStyles();
    const collapsed = active || open;

    return (
        <>
            <NavListItem
                navigationItem={navigationItem}
                open={collapsed}
                handleClick={handleClick}
            />
            <Collapse
                in={collapsed}
                className={classes.nestedItems}
            >
                <List
                    dense
                    disablePadding
                >
                    {navigationItem?.items
                        .filter((item) => isInAnyRoleOrAdmin(item?.roles))
                        .map((item, key) => {
                            return (
                                <SingleNavigationItem
                                    key={key}
                                    navigationItem={item}
                                    onNavigate={onNavigate}
                                />
                            );
                        })}
                </List>
            </Collapse>
        </>
    );
};

export default NestedNavigationItem;
