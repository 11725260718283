import { createSelector } from "reselect";
import { foreginFiles, polishFiles, vendorAXDictionaries } from "views/vendors/vendorOperation/components";

export const vendorFormSelector = (state) => {
    const { vendorPms = {} } = state;
    const { vendorForm = {} } = vendorPms;

    return {
        ...vendorForm,
        billingAddress: {
            ...vendorForm.billingAddress,
            country: vendorAXDictionaries.countries.find((x) => x.name === vendorForm.billingAddress?.country)?.value,
        },
        paymentMethods: vendorAXDictionaries.paymentMethods.find((x) => x.name === vendorForm.paymentMethod)?.value,
        files: Boolean(vendorForm?.billingAddress?.country)
            ? vendorForm.billingAddress.country.toLowerCase() === "Polska"
                ? polishFiles
                : foreginFiles
            : undefined,
    };
};

export const newVendorOperationSelector = createSelector(vendorFormSelector, (vendorForm = {}) => {
    return vendorForm;
});

const root = (state) => state.vendorOperation;
export const vendorOperationSelector = createSelector(root, (state) => state.form.operation);
export const vendorOperationListSelector = createSelector(root, (state) => state.list);
