import { tValidation, validation } from "utils-ts/validations/translation";
import Validator from "./basic";

export default (businessRegistryNumber = "") => {
    const isBusinessRegistryNumberValid = (value) => {
        const digits = value.split("").map((x) => Number(x));
        const sum = [8, 9, 2, 3, 4, 5, 6, 7].map((w, i) => w * digits[i]).reduce((c, n) => c + n, 0);

        if (businessRegistryNumber.length > 9) {
            const sumLong = [2, 4, 8, 5, 0, 9, 7, 3, 6, 1, 2, 4, 8].map((w, i) => w * digits[i]).reduce((c, n) => c + n, 0);

            return sum % 11 === digits[8] && sumLong % 11 === digits[13];
        } else {
            return sum % 11 === digits[8];
        }
    };

    return new Validator(businessRegistryNumber)
        .must(isBusinessRegistryNumberValid, tValidation(validation.businessRegistryNumber))
        .when(Boolean(businessRegistryNumber));
};
