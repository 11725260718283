import { validateRefundation } from "store/vendors/refundations";
import {
    Validator,
    businessRegistryNumberValidator,
    emailValidator,
    greaterOrEqualsThan,
    greaterThan,
    isEmpty,
    lessOrEqualsThan,
    notEmpty,
    notEmptyArrayValidator,
    notEmptyValidator,
    phoneNumberValidator,
    postCodeValidator,
    stringLength,
    vatinValidator,
} from "utils/validators";
import { notEmptyArray } from "utils/validators/basic";
import moment from "moment";
import _, { get } from "lodash";
import { tValidation, validation } from "utils-ts/validations/translation";

const name = (value) =>
    notEmptyValidator(value)
        .must(
            stringLength(3, 300),
            tValidation(validation.stringLength, {
                min: 3,
                max: 300,
            })
        )
        .validate();

const description = (value) => {
    return notEmptyValidator(value)
        .must(
            stringLength(5, 1000),
            tValidation(validation.stringLength, {
                min: 5,
                max: 1000,
            })
        )
        .validate();
};

const postCode = (value, form) => {
    return validateBillingAddress(new postCodeValidator(value), form).validate();
};

const vatin = (value = "") => {
    return vatinValidator(value.replace(/\D/g, "")).must(notEmpty, tValidation(validation.notEmpty)).validate();
};

const businessRegistryNumber = (value) => businessRegistryNumberValidator(value).validate();

const required = (value) => notEmptyValidator(value).validate();

const arrayRequired = (value) => notEmptyArrayValidator(value).validate();

const whFilesArrayRequired = (value) => {
    return new Validator(value).must(notEmptyArray, tValidation(validation.whNotEmptyFileArray)).validate();
};

const requiredGreaterThan0 = (value) =>
    notEmptyValidator(value)
        .must(greaterThan(0), tValidation(validation.greaterThan, { number: 0 }))
        .validate();

const discountValue = (value) =>
    notEmptyValidator(value)
        .must(greaterOrEqualsThan(0), tValidation(validation.greaterOrEqualsThan, { number: 0 }))
        .must(lessOrEqualsThan(100), tValidation(validation.lessOrEqualsThan, { number: 100 }))
        .validate();

const creditLimit = (value) =>
    notEmptyValidator(value)
        .must(greaterThan(0), tValidation(validation.greaterThan, { number: 0 }))
        .validate();

const validateFrom = (value, form, _, path) => {
    let valueTo = get(form, path.substring(0, path.lastIndexOf(".")) + path.substring(path.lastIndexOf(".")).replace("From", "To"));

    return notEmptyValidator(value)
        .must((value) => moment(value).isValid(), tValidation(validation.invalidDate))
        .must(
            (value) => moment(value).isBefore(moment(valueTo)),
            tValidation(validation.lessDateThan, {
                date: moment(valueTo).format("DD.MM.YYYY"),
            })
        )
        .when(valueTo != null && valueTo !== "" && moment(valueTo).isValid())
        .validate();
};

const validateTo = (value, form, _, path) => {
    let valueFrom = get(form, path.substring(0, path.lastIndexOf(".")) + path.substring(path.lastIndexOf(".")).replace("To", "From"));

    return notEmptyValidator(value)
        .must((value) => moment(value).isValid(), tValidation(validation.invalidDate))
        .must(
            (value) => value >= valueFrom,
            tValidation(validation.greaterOrEqualsDateThan, {
                date: moment(valueFrom).format("DD.MM.YYYY"),
            })
        )
        .when(value != null && value !== "" && valueFrom != null && valueFrom !== "" && moment(valueFrom).isValid())
        .validate();
};

const validateNullableDateTo = (value, form, _, path) => {
    let valueFrom = get(form, path.substring(0, path.lastIndexOf(".")) + path.substring(path.lastIndexOf(".")).replace("To", "From"));

    return new Validator(value)
        .must((value) => moment(value).isValid(), tValidation(validation.invalidDate))
        .when(!isEmpty(value))
        .must(
            (value) => value >= valueFrom,
            tValidation(validation.greaterOrEqualsDateThan, {
                date: moment(valueFrom).format("DD.MM.YYYY"),
            })
        )
        .when(value != null && value !== "" && valueFrom != null && valueFrom !== "" && moment(valueFrom).isValid())
        .validate();
};

const sgValidateTo = (value, form, _, path) => {
    let valueFrom = get(form, path.substring(0, path.lastIndexOf(".")) + path.substring(path.lastIndexOf(".")).replace("To", "From"));

    return notEmptyValidator(value)
        .must((value) => moment(value).isValid(), tValidation(validation.invalidDate))
        .must(
            (value) => value >= valueFrom,
            tValidation(validation.greaterOrEqualsDateThan, {
                date: moment(valueFrom).format("DD.MM.YYYY"),
            })
        )
        .when(value != null && value !== "" && valueFrom != null && valueFrom !== "" && moment(valueFrom).isValid())
        .validate();
};

const atLeastOneProductField = (_, form, __, path) => {
    const contract = get(form, path.substring(0, path.lastIndexOf("."))) || {};
    return new Validator(contract)
        .must((d) => {
            return (
                !notEmptyArrayValidator(d.brands).validate() ||
                !notEmptyArrayValidator(d.products).validate() ||
                !notEmptyArrayValidator(d.analyticGroups).validate()
            );
        }, tValidation(validation.atLeastOneField))
        .validate();
};

const refundationValueProducts = (_, form, __, path) => {
    const refundation = get(form, path.substring(0, path.lastIndexOf("."))) || {};
    const index = parseInt(path.split("[")[1].split("]")[0]);
    const products = (form.values || []).filter((x, i) => i !== index).flatMap((x) => x.products || []);
    const duplicates = (refundation.products || []).filter((x) => products.includes(x));
    const ids = duplicates.join(", ");
    const currentValue = (form.values || [])[index];
    return new Validator(refundation)
        .must((d) => {
            return (
                !notEmptyArrayValidator(d.brands).validate() ||
                !notEmptyArrayValidator(d.products).validate() ||
                !notEmptyArrayValidator(d.analyticGroups).validate()
            );
        }, tValidation(validation.atLeastOneField))
        .must(
            () => (duplicates || []).length === 0,
            tValidation(validation.refundationValuesProductDuplication, {
                ids,
            })
        )
        .must(
            () => new Set(currentValue.products || []).size === (currentValue.products || []).length,
            tValidation(validation.arrayMustContainsUniqueValues)
        )
        .validate();
};

const email = (value, form, __, path) => {
    const { phoneNumber } = get(form, path.substring(0, path.lastIndexOf("."))) || {};
    return emailValidator(value).when(notEmpty(value)).must(notEmpty, tValidation(validation.atLeastOneField)).when(isEmpty(phoneNumber)).validate();
};

const phoneNumber = (value, form, __, path) => {
    const { email } = get(form, path.substring(0, path.lastIndexOf("."))) || {};
    return phoneNumberValidator(value).when(notEmpty(value)).must(notEmpty, tValidation(validation.atLeastOneField)).when(isEmpty(email)).validate();
};

const subsidiaryGainsValue = (value) =>
    notEmptyValidator(value)
        .must(
            greaterOrEqualsThan(0.01),
            tValidation(validation.greaterOrEqualsThan, {
                number: 0.01,
            })
        )
        .validate();

const subsidiaryGainsType = (value) => notEmptyValidator(value).validate();

const costReasons = (value) => notEmptyArrayValidator(value).validate();

const isProducerOrMiddleman = (value, form) => {
    const { isMiddleman, isProducer } = form || {};
    return new Validator(value)
        .must(
            isEmpty,
            tValidation(validation.mustBeNotChecked, {
                when: "kontrahent nie może być jednocześnie pośrednikiem i producentem",
            })
        )
        .when(isMiddleman && isProducer)
        .validate();
};

const billingAddress = (value, form) => {
    return validateBillingAddress(new Validator(value), form).validate();
};

const validateBillingAddress = (validator, form) => {
    const { billingAddress = {} } = form || {};
    const { postcode, city, street, buildingNumber, apartmentNumber, country } = billingAddress;
    return validator
        .must(
            notEmpty,
            tValidation(validation.notEmptyWhen, {
                when: "podawany jest adres",
            })
        )
        .when(postcode || city || street || buildingNumber || apartmentNumber || country);
};

const dateOfEndCooperation = (value, form) => {
    const { isActive, dateOfStartCooperation } = form || {};

    return new Validator(value)
        .must((value) => moment(value).isValid(), tValidation(validation.invalidDate))
        .when(value != null && value !== "")
        .must(
            notEmpty,
            tValidation(validation.notEmptyWhen, {
                when: "dostawca jest nie aktywny",
            })
        )
        .when(!isActive)
        .must(
            (value) => value >= dateOfStartCooperation,
            tValidation(validation.greaterOrEqualsDateThan, {
                date: moment(dateOfStartCooperation).format("DD.MM.YYYY"),
            })
        )
        .when(
            value != null &&
                value !== "" &&
                dateOfStartCooperation != null &&
                dateOfStartCooperation !== "" &&
                moment(dateOfStartCooperation).isValid()
        )
        .validate();
};

const billingPeriod = (value, form, __, path) => {
    const { typeOfValue } = get(form, path.substring(0, path.lastIndexOf("."))) || {};

    return notEmptyValidator(value)
        .when(typeOfValue === "Money")
        .validate();
};

const removeAtIndex = (array, index) => {
    return array.filter((_, i) => i !== index);
};

const findDuplicatedItems = (array = [], toSearch = []) => {
    const duplicates = array
        .map((x) => {
            if (toSearch.some((y) => y === x)) {
                return x;
            }

            return null;
        })
        .filter(Boolean);

    return duplicates;
};

const findDuplicates = (rootItem, compared) => {
    const { dateFrom, dateTo, products, analyticGroups, brands, appliesToAllProducts, typeOfDiscount } = compared;
    let duplicateAppliesToAllProducts = false;
    let resultAppliesToAllProducts = undefined;

    if (
        appliesToAllProducts &&
        rootItem.appliesToAllProducts &&
        appliesToAllProducts === rootItem.appliesToAllProducts &&
        rootItem.typeOfDiscount === typeOfDiscount
    ) {
        duplicateAppliesToAllProducts = true;
        resultAppliesToAllProducts = {
            typeOfDiscount,
            appliesToAllProducts: duplicateAppliesToAllProducts,
            index: compared.index,
        };
    }

    if (!dateFrom || !rootItem.dateFrom || rootItem.typeOfDiscount !== typeOfDiscount) {
        return resultAppliesToAllProducts;
    }

    const rootDateFrom = moment(rootItem.dateFrom);
    const rootDateTo = rootItem.dateTo ? moment(rootItem.dateTo) : moment().add(20, "years");
    const momentDateFrom = moment(dateFrom);
    const momentDateTo = dateTo ? moment(dateTo) : moment().add(20, "years");

    if (!rootDateFrom.isValid() || !rootDateTo.isValid() || !momentDateFrom.isValid() || !momentDateTo.isValid()) {
        return resultAppliesToAllProducts;
    }

    if (rootDateFrom.isAfter(momentDateTo) || rootDateTo.isSameOrBefore(momentDateFrom)) {
        return resultAppliesToAllProducts;
    }

    const duplicatedProducts = findDuplicatedItems(products, rootItem.products);
    const duplicatedAnalyticGroups = findDuplicatedItems(analyticGroups, rootItem.analyticGroups);
    const duplicatedBrands = findDuplicatedItems(brands, rootItem.brands);

    if (!duplicatedProducts.length && !duplicatedAnalyticGroups.length && !duplicatedBrands.length) {
        return resultAppliesToAllProducts;
    }

    return {
        appliesToAllProducts: duplicateAppliesToAllProducts,
        products: duplicatedProducts,
        analyticGroups: duplicatedAnalyticGroups,
        brands: duplicatedBrands,
        index: compared.index,
    };
};

const validateForm = (values) => {
    const { frontDiscounts = [] } = values;
    let errors = {};

    let termErrors = [];
    if (frontDiscounts && frontDiscounts.length > 0) {
        errors.frontDiscounts = undefined;

        const termsWithIndex = frontDiscounts.map((t, i) => ({ ...t, index: i }));
        for (const currentTerm of termsWithIndex) {
            let result = [];

            for (const innerTerm of removeAtIndex(termsWithIndex, currentTerm.index)) {
                const duplicates = findDuplicates(currentTerm, innerTerm);

                if (duplicates) {
                    result = [...result, duplicates];
                }
            }

            termErrors = [...termErrors, { index: currentTerm.index, errors: result }];
        }

        for (const termError of termErrors) {
            const products = termError.errors
                .map((error) =>
                    error.products?.length
                        ? tValidation(validation.termOfBusinessProductConflict, {
                              index: error.index + 1,
                              products: error.products?.join(", "),
                          })
                        : undefined
                )
                .filter(Boolean);

            const analyticGroups = termError.errors
                .map((error) =>
                    error.analyticGroups?.length
                        ? tValidation(validation.termOfBusinessAnalyticGroupConflict, {
                              index: error.index + 1,
                              analyticGroups: error.analyticGroups?.join(", "),
                          })
                        : undefined
                )
                .filter(Boolean);

            const brands = termError.errors
                .map((error) =>
                    error.brands?.length
                        ? tValidation(validation.termOfBusinessBrandConflict, {
                              index: error.index + 1,
                              brands: error.brands?.join(", "),
                          })
                        : undefined
                )
                .filter(Boolean);

            const appliesToAllProducts = termError.errors
                .map((error) =>
                    error.appliesToAllProducts
                        ? tValidation(validation.appliesToAllProductsConflict, {
                              index: error.index + 1,
                          })
                        : undefined
                )
                .filter(Boolean);

            if (
                (products?.length > 0 && products) ||
                (analyticGroups?.length > 0 && analyticGroups) ||
                (brands?.length > 0 && brands) ||
                (appliesToAllProducts?.length > 0 && appliesToAllProducts)
            ) {
                errors.frontDiscounts = Array(frontDiscounts.length);
                errors.frontDiscounts[termError.index] = {
                    products: products?.length > 0 && products,
                    analyticGroups: analyticGroups?.length > 0 && analyticGroups,
                    brands: brands?.length > 0 && brands,
                    appliesToAllProducts: appliesToAllProducts?.length > 0 && appliesToAllProducts,
                };
            }
        }
    }

    return errors;
};

const percent = (value) => {
    return notEmptyValidator(value)
        .must(greaterOrEqualsThan(0), tValidation(validation.greaterThan, { number: 0 }))
        .must(lessOrEqualsThan(100), tValidation(validation.lessOrEqualsThan, { number: 100 }))
        .validate();
};

const retroUnconditionalDiscountCountingMethod = (value, form) => {
    return notEmptyValidator(value)
        .when(form["retroUnconditionalDiscounts"] && form["retroUnconditionalDiscounts"].length > 0)
        .validate();
};

const validateRefundationAsync = async (form, dispatch) => {
    let hasErrors = false;
    const errors = {};
    const { dateFrom, dateTo, values = [], id: refundationId, vendorId, divisions } = form;
    const products = values.flatMap((x) => x.products || []);
    if (dateFrom && dateTo && !_.isEmpty(products)) {
        const result = await dispatch(validateRefundation(vendorId, { dateFrom, dateTo, products, refundationId, divisions }));

        if (!_.isEmpty(result.payload)) {
            hasErrors = true;
            errors["values"] = {};
            for (const index in values) {
                const matchingProducts = result.payload.filter((x) => (values[index]?.products || []).some((y) => x.products.includes(y)));
                errors["values"][index] = {};
                if (!_.isEmpty(matchingProducts)) {
                    matchingProducts.forEach((x) => (x.products = x.products.filter((y) => values[index].products.includes(y))));
                    errors["values"][index]["productErrors"] = matchingProducts;
                }
            }
        }
    }

    return new Promise((resolve, reject) => {
        if (hasErrors) {
            reject(errors);
        }

        resolve();
    });
};

const discountValueWithType = (value, form, _, path) => {
    const { typeOfValue } = get(form, path.substring(0, path.lastIndexOf("."))) || {};

    if (typeOfValue === "Money") {
        return requiredGreaterThan0(value);
    } else if (typeOfValue === "Percent") {
        return percent(value);
    } else {
        return notEmptyValidator(value).validate();
    }
};

export const validators = {
    arrayRequired,
    whFilesArrayRequired,
    requiredGreaterThan0,
    name,
    atLeastOneProductField,
    postCode,
    vatin,
    businessRegistryNumber,
    discountValue,
    validateFrom,
    validateTo,
    validateNullableDateTo,
    sgValidateTo,
    email,
    phoneNumber,
    creditLimit,
    subsidiaryGainsValue,
    subsidiaryGainsType,
    costReasons,
    isProducerOrMiddleman,
    dateOfEndCooperation,
    billingAddress,
    description,
    billingPeriod,
    validateForm,
    required,
    percent,
    retroUnconditionalDiscountCountingMethod,
    validateRefundationAsync,
    refundationValueProducts,
    discountValueWithType,
};
