import Button from "components/CustomButtons/Button";
import { FormField } from "components/Form";
import { FormSection } from "redux-form";
import { getOperationFile } from "store/vendors/vendorOperation";
import { useChange, useFormValueSelector, useParams } from "utils/hooks";
import { useDispatch } from "react-redux";
import { Layout } from "components";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { foreginFiles, polishFiles } from "./OperationFilesTexts";
import validation from "./validation";

const OperationFiles = ({ fields, readonly, formName, isArchiveOnly }) => {
    const { operationId } = useParams();
    const { t } = useTranslation("vendor");
    const dispatch = useDispatch();
    const { country } = useFormValueSelector(formName, "billingAddress", {});
    const change = useChange(formName);

    if (!country) {
        return <></>;
    }

    const onFileChange = (file, field) => {
        if (!file) {
            return;
        }

        const reader = new FileReader();

        reader.onload = function (e) {
            change(`${field}.fileContent`, e.target.result.split(",")[1]);
            change(`${field}.fileName`, file.name);
            change(`${field}.fileValue`, file);
        };

        reader.readAsDataURL(file);
    };

    return (
        <Layout headerText={t(vendor.files)}>
            {fields.map((field, index) => {
                const file = fields.get(index);

                return (
                    <FormSection
                        key={field}
                        name={field}
                    >
                        <div>{country === "POL" ? polishFiles[index].text : foreginFiles[index].text}</div>
                        {!readonly ? (
                            <FormField
                                name="fileValue"
                                type="file"
                                fullWidth
                                omitvalue="true"
                                multiple={false}
                                onChange={(file) => onFileChange(file, field)}
                                validate={isArchiveOnly ? undefined : validation.required}
                                readOnly={readonly}
                            />
                        ) : (
                            file.fileId && (
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        dispatch(getOperationFile(operationId, file.fileId, file.fileName));
                                    }}
                                >
                                    {t(vendor.download)}
                                </Button>
                            )
                        )}
                    </FormSection>
                );
            })}
        </Layout>
    );
};

export default OperationFiles;
