import { renderFieldOrText } from "components/FormHelpers/Controls";
import { Field, change } from "redux-form";
import { withStyles } from "tss-react/mui";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";

const VoucherField = ({ fragment, classes, form }) => {
    const dispatch = useDispatch();
    return (
        <Grid item>
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                {fragment.map((item, index) => {
                    if (item.name === "definition.freeDelivery") {
                        return (
                            <Grid
                                key={index}
                                item
                                className={classes.item}
                            >
                                <div className={classes.field}>
                                    <Field
                                        fullWidth
                                        component={renderFieldOrText}
                                        {...item}
                                        onChange={(event) => {
                                            if (event.target.checked === true) {
                                                dispatch(
                                                    change(
                                                        form,
                                                        "definition.voucherPromotions[0].definition.effects[0].deliveryModifier.newDeliveryCost",
                                                        "0"
                                                    )
                                                );
                                                dispatch(change(form, "definition.voucherPromotions[0].definition.promotionType", "Delivery"));
                                            } else {
                                                dispatch(change(form, "definition.voucherPromotions", null));
                                            }
                                        }}
                                    />
                                </div>
                            </Grid>
                        );
                    }

                    return (
                        <Grid
                            key={index}
                            item
                            className={classes.item}
                        >
                            <div className={classes.field}>
                                <Field
                                    fullWidth
                                    component={renderFieldOrText}
                                    {...item}
                                />
                            </div>
                        </Grid>
                    );
                })}
            </Grid>
        </Grid>
    );
};

export default withStyles(VoucherField, (theme) => ({
    item: { minWidth: 294 },
    field: { margin: theme.spacing(1) },
}));
