import { RSAA } from "redux-api-middleware";
import { startSubmit, stopSubmit } from "redux-form";
import { uniq } from "lodash";
import types from "./types";

export const getUser = (userId) => {
    return {
        [RSAA]: {
            endpoint: `api/users/${userId}`,
            headers: { "Content-Type": "application/json" },
            method: "GET",
            types: [
                {
                    type: types.getUserDetails.request,
                    payload: (action) => ({ endpoint: action.endpoint }),
                },
                types.getUserDetails.success,
                types.getUserDetails.failure,
            ],
        },
    };
};

export const updateEmail = (userId, email) => (dispatch) => {
    dispatch(startSubmit("ChangeUserEmail"));
    dispatch({
        [RSAA]: {
            endpoint: `api/users/${userId}/email`,
            headers: { "Content-Type": "application/json" },
            method: "PUT",
            body: JSON.stringify({
                email,
            }),
            types: [
                types.updateUserEmail.request,
                {
                    type: types.updateUserEmail.success,
                    payload: () => {
                        dispatch(stopSubmit("ChangeUserEmail"));
                        return {
                            email,
                        };
                    },
                },
                {
                    type: types.updateUserEmail.failure,
                    payload: (action, state, res) => {
                        dispatch(stopSubmit("ChangeUserEmail"));
                        return res;
                    },
                },
            ],
        },
    });
};

export const updateFullName = (userId, firstName, lastName) => {
    return (dispatch) => {
        dispatch(startSubmit("ChangeUserFullName"));
        dispatch({
            [RSAA]: {
                endpoint: `api/users/${userId}/full-name`,
                headers: { "Content-Type": "application/json" },
                method: "PUT",
                body: JSON.stringify({
                    fullName: {
                        firstName,
                        lastName,
                    },
                }),
                types: [
                    types.updateUserFullname.request,
                    {
                        type: types.updateUserFullname.success,
                        payload: () => {
                            dispatch(stopSubmit("ChangeUserFullName"));
                            return {
                                firstName,
                                lastName,
                            };
                        },
                    },
                    {
                        type: types.updateUserFullname.failure,
                        payload: (action, state, res) => {
                            dispatch(stopSubmit("ChangeUserFullName"));
                            return res;
                        },
                    },
                ],
            },
        });
    };
};

export const updateUserRoles = (userId, addRoles, removeRoles) => {
    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: `api/users/${userId}/roles`,
                headers: { "Content-Type": "application/json" },
                method: "PUT",
                body: JSON.stringify({
                    addRoles,
                    removeRoles,
                }),
                types: [
                    types.updateUserRoles.request,
                    {
                        type: types.updateUserRoles.success,
                        payload: (action, state) => {
                            const { userDetails } = state;
                            let roles = state?.userDetails?.user?.roles || [];

                            for (let role of addRoles) {
                                roles = [...roles, role];
                            }

                            for (let role of removeRoles) {
                                roles = roles.filter((x) => x !== role);
                            }

                            return {
                                ...userDetails,
                                user: {
                                    ...userDetails.user,
                                    roles: uniq(roles),
                                },
                            };
                        },
                    },
                    {
                        type: types.updateUserRoles.failure,
                        payload: (action, state, res) => {
                            return res;
                        },
                    },
                ],
            },
        });
    };
};

export const changeUserActivation = (userId, isDeactivated) => async (dispatch) => {
    return await dispatch({
        [RSAA]: {
            endpoint: `api/users/${userId}/is-deactivated`,
            headers: { "Content-Type": "application/json" },
            method: "PUT",
            body: JSON.stringify({
                isDeactivated,
            }),
            types: types.updateUserActivision.all,
        },
    });
};

export const changeUserPassword = (userId, password) => async (dispatch) => {
    return await dispatch({
        [RSAA]: {
            endpoint: `api/users/${userId}/password`,
            headers: { "Content-Type": "application/json" },
            method: "PUT",
            body: JSON.stringify({
                password,
            }),
            types: types.updateUserPassword.all,
        },
    });
};
