import StepInput from "components/Controls/StepInput/StepInput";
import React from "react";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";

momentDurationFormatSetup(moment);

const getAs = [
    { unit: "days", method: (duration) => duration.asDays() },
    { unit: "day", method: (duration) => duration.asDays() },
    { unit: "hour", method: (duration) => duration.asHours() },
    { unit: "hours", method: (duration) => duration.asHours() },
];

class TimeSpanField extends React.Component {
    constructor(props) {
        super(props);
        const { input, step, resolution } = props;
        this.onChange = input.onChange;
        this.step = Boolean(step) || step === 0 ? step : 1;
        this.resolution = resolution || "days";
        this.apiFormat = "DD.HH:mm:ss.SSS";
        this.getValueAs = getAs.find((x) => x.unit === this.resolution).method;
    }

    increment = (value) => {
        return moment.duration(value).add(this.step, this.resolution).format(this.apiFormat, { trim: false });
    };

    decrement = (value) => {
        if (this.getValueAs(moment.duration(value, this.resolution)) <= 0) return moment.duration(value).format(this.apiFormat, { trim: false });

        return moment.duration(value).subtract(this.step, this.resolution).format(this.apiFormat, { trim: false });
    };

    formatDisplay = (value) => {
        return !value || value.endsWith(".") ? value : this.getValueAs(moment.duration(value));
    };

    valueFormat = (value) => {
        if (value.includes(":")) {
            return moment.duration(value).format(this.apiFormat, { trim: false });
        } else {
            return value.endsWith(".") || value.endsWith(",")
                ? value.replace(",", ".")
                : moment.duration({ [this.resolution]: Number(value.replace(",", ".")) }).format(this.apiFormat, { trim: false });
        }
    };

    render() {
        return (
            <StepInput
                {...this.props}
                increment={this.increment}
                decrement={this.decrement}
                formatDisplay={this.formatDisplay}
                valueFormat={this.valueFormat}
            />
        );
    }
}

export default TimeSpanField;
