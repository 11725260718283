import { actions as cachedActions } from "store/cache/actions";
import { cacheNames } from "utils/cache/cacheProvider";
import { chunk } from "lodash";

const getProducts = (productIds) => async (dispatch) => {
    if (productIds && productIds.length > 0) {
        const chunks = chunk(productIds, 50);
        chunks.forEach((chunk) => {
            dispatch(
                cachedActions.getCached({
                    ids: chunk,
                    params: {},
                    cacheName: cacheNames.productsStore,
                    endpoint: `api/v1/offer/products/simplified`,
                    idPropertyName: "productId",
                    queryPropetyName: "productIds",
                })
            );
        });
    }
};

export const actions = {
    getProducts,
};
