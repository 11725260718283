import { RSAA } from "redux-api-middleware";
import _ from "lodash";
import types from "./types";

const getCustomerInfo = (customerId) => async (dispatch) => {
    return await dispatch({
        [RSAA]: {
            endpoint: `api/v1/users/${customerId}/internal`,
            method: "GET",
            types: types.getCustomer.all,
        },
    });
};

const putSegments = (customerId, segments) => async (dispatch) => {
    const body = _.chain(segments)
        .reduce((c, n, key) => (n ? { ...c, addSegments: [...c.addSegments, key] } : { ...c, removeSegments: [...c.removeSegments, key] }), {
            removeSegments: [],
            addSegments: [],
        })
        .value();

    return await dispatch({
        [RSAA]: {
            endpoint: `api/v1/users/${customerId}/segments`,
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            types: types.putSegments.all,
            body: JSON.stringify(body),
        },
    });
};

const putProfile = (customerId, values) => async (dispatch) => {
    return await dispatch({
        [RSAA]: {
            endpoint: `api/v1/users/${customerId}`,
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            types: types.putProfile.all,
            body: JSON.stringify({
                ...values,
                billingAddress: {
                    value: values.billingAddress,
                },
                mobileNumber: values.mobileNumber ? { value: values.mobileNumber } : null,
            }),
        },
    });
};

export const actions = {
    getCustomerInfo,
    putSegments,
    putProfile,
};
