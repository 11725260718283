import Tabs from "components/Tabs/Tabs";
import { vendor } from "translations";
import { ContractDecisions, RefundationDecisions, SubsidiaryGainDecisions } from "./components";

const Decisions = () => {
    const tabLabels = [`vendor:${vendor.contracts}`, `vendor:${vendor.sg}`, `vendor:${vendor.refundations}`];

    return (
        <Tabs tableLabels={tabLabels}>
            <ContractDecisions />
            <SubsidiaryGainDecisions />
            <RefundationDecisions />
        </Tabs>
    );
};

export default Decisions;
