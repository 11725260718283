import { DiffFieldArray } from "components/FormDiff";
import { FieldArray } from "redux-form";

const FormFieldArray = (props) => {
    if (props.useDiff) {
        return <DiffFieldArray {...props} />;
    }

    return <FieldArray {...props} />;
};

export default FormFieldArray;
