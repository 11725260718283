import { operations } from "store/actionTypePrefixes";
import { AsyncTypes, asAsyncFormTypes } from "utils/extensions";

const types = {
    getWarehouseMappings: new AsyncTypes("GET_WAREHOUSE_MAPPINGS", operations),
    getPriceLists: new AsyncTypes("GET_PRICE_LISTS", operations),
    updateSellPrice: new AsyncTypes("UPDATE_SELL_PRICE", operations, [asAsyncFormTypes]),
    updateMultipleSellPrices: new AsyncTypes("UPDATE_MULTIPLE_SELL_PRICE", operations, [asAsyncFormTypes]),
    exportPriceLists: new AsyncTypes("EXPORT_PRICE_LISTS", operations),
    exportCurrentPurchasePrice: new AsyncTypes("EXPORT_CURRENT_PURCHASE_PRICE", operations),
    importPromotionSellPrices: new AsyncTypes("IMPORT_PROMOTION_SELL_PRICES", operations),
};

export default types;
