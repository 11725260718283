import { Log } from "oidc-client-ts";
import { makeStyles } from "tss-react/mui";
import { useState } from "react";
import { Collapse, Paper, Portal, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useConfig, useUser, useVersion } from "context";
import _ from "lodash";

const useStyles = makeStyles()(() => ({
    container: {
        zIndex: 999999,
        position: "absolute",
        top: "1vh",
        right: "40vw",
    },
    inner: {
        padding: 10,
    },
}));

const EnviromentInfo = () => {
    const version = useVersion();
    const isSmallView = useMediaQuery((theme) => theme.breakpoints.down("md"));
    const [open, setOpen] = useState(false);
    const { applications, env, isProduction } = useConfig();
    const { profile = { exp: 0 } } = useUser();
    const { classes } = useStyles();
    if (isProduction) {
        return <></>;
    }

    Log.setLevel(Log.WARN);
    Log.setLogger(console);

    if (isSmallView) {
        return (
            <Portal>
                <Paper
                    elevation={10}
                    className={classes.container}
                >
                    <Typography
                        variant="h6"
                        className={classes.red}
                    >
                        {env}
                    </Typography>
                </Paper>
            </Portal>
        );
    }

    return (
        <Portal>
            <Paper
                elevation={10}
                className={classes.container}
            >
                <div
                    className={classes.inner}
                    onClick={() => setOpen(!open)}
                >
                    <Typography
                        variant="h4"
                        className={classes.red}
                    >
                        Środowisko: {env}
                    </Typography>
                    <Collapse in={open}>
                        <>
                            <Typography>APIs:</Typography>
                            {_(applications)
                                .map((x) => <Typography key={x}>{x}</Typography>)
                                .value()}
                            <pre>{JSON.stringify(profile, null, 4)}</pre>
                            <pre>{JSON.stringify(version, null, 4)}</pre>
                        </>
                    </Collapse>
                </div>
            </Paper>
        </Portal>
    );
};

export default EnviromentInfo;
