import Layout from "components/Grid/Layout";
import Preloader from "components/Preloader/Preloader";
import { Tabs } from "components/Tabs";
import { getProcedureCategories } from "store/autocomplete";
import { downloadProcedureFile, initForm, procedureFormSelector } from "store/procedures";
import { useCommonTranslation, useParams } from "utils/hooks";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import ProcedureAcceptances from "./ProcedureAcceptances";
import ProcedureFileAudit from "./ProcedureFileAudit";
import ProcedureForm from "./ProcedureForm";

const Procedure = ({ isLoading, versionFileHistory, isLoadingAcceptances, acceptances, name, needsAcceptance }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { t, common } = useCommonTranslation();
    useEffect(() => {
        dispatch(initForm(id));
        dispatch(getProcedureCategories({}));
    }, [dispatch, id]);

    const downloadFile = async (procedureVersion, fileName) => {
        await dispatch(downloadProcedureFile(id, procedureVersion, fileName));
    };

    let tabs = ["Formularz"];
    if (id) {
        tabs.push("Audyt pliku");
        if (needsAcceptance) {
            tabs.push("Akceptacje");
        }
    }

    return (
        <Layout
            main
            headerText={t(common.procedure, {
                id: id || t(common.newItem),
            })}
            navigationProps={{
                backProps: { path: "/administration/procedures" },
            }}
            customTitle={id && name && `${t(common.procedure)} - ${name}`}
        >
            {isLoading ? (
                <Preloader />
            ) : (
                <Tabs tableLabels={tabs}>
                    <ProcedureForm downloadFile={downloadFile} />
                    <ProcedureFileAudit
                        procedureId={id}
                        versionFileHistory={versionFileHistory}
                        downloadFile={downloadFile}
                    />
                    {needsAcceptance && (
                        <ProcedureAcceptances
                            procedureId={id}
                            isLoading={isLoadingAcceptances}
                            acceptances={acceptances}
                        />
                    )}
                </Tabs>
            )}
        </Layout>
    );
};

const stateToProps = (state) => {
    const { isLoading, isLoadingAcceptances, acceptances, versionFileHistory, name, needsAcceptance } = procedureFormSelector(state);

    return {
        isLoading,
        isLoadingAcceptances,
        acceptances,
        versionFileHistory,
        name,
        needsAcceptance,
    };
};

export default connect(stateToProps)(Procedure);
