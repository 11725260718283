import { RSAA } from "redux-api-middleware";
import { commerce, manager } from "store/actionTypePrefixes";
import { asAsyncFormTypes } from "utils/extensions/arrayExtensions";

export const types = {
    FORM_INIT_PRODUCT_RATION: `${manager}/FORM_INIT_PRODUCT_RATION`,
    GET_PRODUCT_RATION: `${commerce}/GET_PRODUCT_RATION`,
    GET_PRODUCT_RATION_SUCCESS: `${commerce}/GET_PRODUCT_RATION_SUCCESS`,
    GET_PRODUCT_RATION_FAILURE: `${commerce}/GET_PRODUCT_RATION_FAILURE`,
    CREATE_OR_UPDATE_PRODUCT_RATION: `${commerce}/CREATE_OR_UPDATE_PRODUCT_RATION`,
    CREATE_OR_UPDATE_PRODUCT_RATION_SUCCESS: `${commerce}/CREATE_OR_UPDATE_PRODUCT_RATION_SUCCESS`,
    CREATE_OR_UPDATE_PRODUCT_RATION_FAILURE: `${commerce}/CREATE_OR_UPDATE_PRODUCT_RATION_FAILURE`,
};

const initForm = (id, isCopy) => async (dispatch) => {
    await dispatch({ type: types.FORM_INIT_PRODUCT_RATION });

    if (id) {
        dispatch({
            [RSAA]: {
                endpoint: `api/v1/offer/products/rations/${id}`,
                headers: { "Content-Type": "application/json" },
                method: "GET",
                types: [
                    types.GET_PRODUCT_RATION,
                    {
                        type: types.GET_PRODUCT_RATION_SUCCESS,
                        meta: { isCopy },
                    },
                    types.GET_PRODUCT_RATION_FAILURE,
                ],
            },
        });
    }
};

const createOrUpdate = (values, id) => async (dispatch) => {
    return await dispatch({
        [RSAA]: {
            endpoint: id ? `api/v1/offer/products/rations/${id}` : `api/v1/offer/products/rations/`,
            headers: { "Content-Type": "application/json" },
            method: id ? "PUT" : "POST",
            types: asAsyncFormTypes([
                types.CREATE_OR_UPDATE_PRODUCT_RATION,
                types.CREATE_OR_UPDATE_PRODUCT_RATION_SUCCESS,
                types.CREATE_OR_UPDATE_PRODUCT_RATION_FAILURE,
            ]),
            body: JSON.stringify(values),
        },
    });
};

export const actions = {
    initForm,
    createOrUpdate,
};
