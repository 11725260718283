const bomChars = "\uFEFF";

export default (object: string, fileName: string) => {
    const clickEvent = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
    });
    const data = `data:text/csv;charset=utf-8,${encodeURIComponent(bomChars + object)}`;

    const element = document.createElement("a");

    element.setAttribute("href", `data: ${data}`);
    element.setAttribute("download", `${fileName}.csv`);
    element.dispatchEvent(clickEvent);
    element.remove();
};
