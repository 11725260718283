import { FormField } from "components/Form";
import { FormSection } from "redux-form";
import allSegments from "resource/segments.json";
import { orderBy } from "utils/extensions/arrayExtensions";
import { withFormName } from "utils/hoc";
import { useChange, useFormValueSelector } from "utils/hooks";
import Validator, { notEmpty, notEmptyArray } from "utils/validators/basic";
import { useTranslation } from "react-i18next";
import { Collapse, Grid } from "@mui/material";
import { get } from "lodash";
import { common } from "translations";
import { tValidation, validation } from "utils-ts/validations/translation";

const validateAtLeastOne = (value, form, _, path) => {
    let { included, excluded } = get(form, path.substring(0, path.lastIndexOf("."))) || {};
    return new Validator(value)
        .must(
            () =>
                (notEmptyArray(included) === true && notEmptyArray(excluded) !== true) ||
                (notEmptyArray(included) !== true && notEmptyArray(excluded) === true) ||
                (notEmptyArray(included) === true && notEmptyArray(excluded) === true),
            tValidation(validation.atLestOneRequirement)
        )
        .validate();
};

const validateRequired = (value) => {
    return new Validator(value).must(() => value === true, tValidation(validation.mustBeChecked, { when: "" })).validate();
};

const segmentRegex = /^(tag|product|promotion|gift|dynamic|manual|commerce|consent|contest):[A-Za-z0-9][A-Za-z0-9_-]*$/;

const validateSmallFirstLetterIncluded = (value, form, _, path) => {
    let { included } = get(form, path.substring(0, path.lastIndexOf("."))) || {};
    return new Validator(value)
        .must(
            (x) => (Array.isArray(x) ? x.every((v) => v.toString().match(segmentRegex)) : x.toString().match(segmentRegex)),
            tValidation(validation.segmentRegex)
        )
        .when(notEmpty(included))
        .validate();
};

const validateSmallFirstLetterExcluded = (value, form, _, path) => {
    let { excluded } = get(form, path.substring(0, path.lastIndexOf("."))) || {};
    return new Validator(value)
        .must(
            (x) => (Array.isArray(x) ? x.every((v) => v.toString().match(segmentRegex)) : x.toString().match(segmentRegex)),
            tValidation(validation.segmentRegex)
        )
        .when(notEmpty(excluded))
        .validate();
};

const SegmentsSelectFields = ({ form, sectionPrefix, readOnly = false, required }) => {
    const { t } = useTranslation(["common", "validation"]);
    const change = useChange(form);
    const user = useFormValueSelector(form, sectionPrefix);
    const { haveSegmentsConstraint = false, segments = [] } = user;
    const { included = [], excluded = [] } = segments[0] || {};
    const notSelectedSegments = orderBy(
        allSegments.filter((x) => included.concat(excluded).every((y) => y !== x.value)),
        (x) => x.name
    ).map((x) => x.value);

    const tooltipText =
        "Dodany ręcznie segment powinien składać się z: {prefix}:{dowolny ciąg liter i/lub cyfr bez polskich znaków}, " +
        "gdzie prefix to jedno z: tag, commerce, manual, consent, contest, product, promotion, gift np. 'manual:mojSegment'.";

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >
            <Grid
                item
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                <FormField
                    name={"haveSegmentsConstraint"}
                    label={t(common.haveSegmentsConstraint)}
                    type={"boolean"}
                    onChange={(event) => {
                        if (!event.target.checked) {
                            change(`${sectionPrefix}.segments`, []);
                        }
                    }}
                    readOnly={readOnly}
                    validate={required ? validateRequired : undefined}
                />
            </Grid>

            <FormSection name="segments[0]">
                <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Collapse in={haveSegmentsConstraint}>
                        <FormField
                            hidden={!haveSegmentsConstraint}
                            name={"included"}
                            label={t(common.includedSegments)}
                            type={"autocomplete"}
                            items={orderBy(included.concat(notSelectedSegments))}
                            getOptionLabel={(value) => {
                                let translation = allSegments.find((x) => x.value === value);
                                return translation != null ? translation.name : value;
                            }}
                            tooltipText={tooltipText}
                            multiple
                            freeSolo
                            disableCloseOnSelect
                            validate={[validateAtLeastOne, validateSmallFirstLetterIncluded]}
                            readOnly={readOnly}
                        />
                    </Collapse>
                    <Collapse in={haveSegmentsConstraint}>
                        <FormField
                            hidden={!haveSegmentsConstraint}
                            name={"excluded"}
                            label={t(common.excludedSegments)}
                            type={"autocomplete"}
                            items={orderBy(excluded.concat(notSelectedSegments))}
                            getOptionLabel={(value) => {
                                let translation = allSegments.find((x) => x.value === value);
                                return translation != null ? translation.name : value;
                            }}
                            tooltipText={tooltipText}
                            multiple
                            freeSolo
                            disableCloseOnSelect
                            validate={[validateAtLeastOne, validateSmallFirstLetterExcluded]}
                            readOnly={readOnly}
                        />
                    </Collapse>
                </Grid>
            </FormSection>
        </Grid>
    );
};

export default withFormName(SegmentsSelectFields);
