import Layout from "components/Grid/Layout";
import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import tableActionTypes from "components/Table/SimpleTable/TableActions/tableActionTypes";
import { reduxForm } from "redux-form";
import { actions } from "store/reviews/reviewList/action";
import { formatTypes } from "utils/formating";
import { splitOrderId } from "utils/splitIds";
import { connect } from "react-redux";
import moment from "moment";
import { bindActionCreators } from "redux";
import { common, customers, orders } from "translations";
import { usePush } from "utils-ts/hooks";
import { useTranslation } from "utils-ts/hooks";
import ReviewListRow from "./ReviewListRow";
import ReviewListSearchForm from "./ReviewListSearchForm";

const ReviewList = ({ reviewList, getReviews, handleSubmit }) => {
    const { pageIndex, totalCount, pageSize, pageCount, isLoading, items } = reviewList;
    const { t } = useTranslation(["orders", "customers", "common"]);
    const { push } = usePush();
    const navigateToCustomer = (orderId) => {
        const { customerId, orderNumber } = splitOrderId(orderId);
        push(`/customers/${customerId}/orders/${orderNumber}`);
    };

    const columns = [
        {
            key: "id",
            value: t(`${orders.orderId}`),
            type: formatTypes.action,
            action: (id) => navigateToCustomer(id),
            actionType: tableActionTypes.link,
        },
        {
            key: "warehouse",
            value: t(`${orders.warehouse}`),
            type: formatTypes.text,
        },
        {
            key: "deliveryWindow",
            value: t(`${orders.delivery}`),
            type: formatTypes.deliveryWindow,
            options: { includeDeliveryMethod: true },
        },
        {
            key: "recommendation",
            value: t(`customers:${customers.recommendation}`),
            type: formatTypes.rating,
            options: { max: 10, numeric: true },
        },
        {
            key: "details",
            value: t(`common:${common.details}`),
            type: formatTypes.text,
        },
    ];

    const issueTypes = {
        [customers.generalIssue]: t(`customers:${customers.generalIssue}`),
        [customers.whatDoYouLike]: t(`customers:${customers.whatDoYouLike}`),
        [customers.qualityIssue]: t(`customers:${customers.qualityIssue}`),
        [customers.complienceIssue]: t(`customers:${customers.complienceIssue}`),
        [customers.punctualityIssue]: t(`customers:${customers.punctualityIssue}`),
        [customers.paymentIssue]: t(`customers:${customers.paymentIssue}`),
        [customers.pageIssue]: t(`customers:${customers.pageIssue}`),
    };

    const issueTypesColumns = [
        { key: "type", value: t(`common:${common.type}`), type: formatTypes.text },
        {
            key: "comment",
            value: t(`common:${common.comment}`),
            type: formatTypes.text,
        },
    ];

    const submit = (pageIndex, pageSize) =>
        handleSubmit((values) => {
            const { deliveryFrom, deliveryTo, warehouse } = values;
            getReviews({
                pageIndex,
                pageSize,
                deliveryFrom: moment(deliveryFrom).format("YYYY-MM-DD"),
                deliveryTo: moment(deliveryTo).format("YYYY-MM-DD"),
                warehouse,
            });
        })();

    return (
        <Layout
            main
            headerText={t(`customers:${customers.recommendation}`)}
        >
            <ReviewListSearchForm
                isLoading={isLoading}
                submit={submit}
                pageIndex={pageIndex}
                pageSize={pageSize}
            />
            <SimpleTable
                isLoading={isLoading}
                columns={columns}
                hover
                pagination
                paginationProps={{
                    pageIndex,
                    totalCount,
                    pageSize,
                    pageCount,
                    pageSizeOptions: [10, 25, 50, 100, 200],
                    disableSavingPagination: true,
                    onPageChange: (pageIndex) => submit(pageIndex, pageSize),
                    onRowsPerPageChange: (pageSize) => submit(pageIndex, pageSize),
                }}
            >
                {items.map((x, index) => (
                    <ReviewListRow
                        rowIndex={index}
                        key={index}
                        columns={columns}
                        issueTypesColumns={issueTypesColumns}
                        issueTypes={issueTypes}
                        item={x}
                    />
                ))}
            </SimpleTable>
        </Layout>
    );
};

const mapStateToProps = (state) => {
    const {
        reviews: { reviewList },
    } = state;
    return {
        reviewList: {
            ...reviewList,
            items: reviewList?.items?.map((x) => ({ ...x.deliveryWindow, ...x })),
        },
        initialValues: {
            deliveryFrom: moment().startOf("day").format(),
            deliveryTo: moment().add(1, "days").startOf("day").format(),
        },
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: "reviewListSearch",
        enableReinitialize: true,
    })(ReviewList)
);
