import { AccountantDepartmentRoles } from "consts/roles";
import { formValueSelector } from "redux-form";
import moment from "moment";
import _ from "lodash";
import getUser from "utils-ts/auth/getUser";

const toDeliveryWinodwValue = (delivery) => {
    return `${delivery.deliveryStartsAt}|${delivery.deliveryEndsAt}|${delivery.deliveryClosesAt}`;
};

const normalizeProductId = (value) => String(value).padStart(6, "0");

const mapStateToProps = (state) => {
    const { orderComplaint, customer } = state;
    const { customerInfo } = customer;
    const { accountNumbers = {} } = customerInfo || {};
    const { complaintId, isComplaintOpen = true, isFirstComplaint = true, orderData, complaintOrderInfo, deliveryComplaint = {} } = orderComplaint;
    const { complaints: deliveryComplaintsItems = [] } = deliveryComplaint;
    const {
        invoice: { lines: orderLines = [] },
    } = orderComplaint;
    //move service lines to end of array
    const lines = _.chain(orderLines)
        .groupBy((x) => x.productId.includes("U"))
        .orderBy((x) => x)
        .flatMap((x) => x)
        .map((x, index) => ({ ...x, index: 1 + index }))
        .filter((x) => isComplaintOpen || (x.correctedItems && x.correctedItems.length > 0))
        .value();

    const linesWithDeliveryComplaint = lines.map((x) => {
        const deliveryComplaints = deliveryComplaintsItems
            .map((dc) => ({
                ...dc,
                productId: normalizeProductId(dc.productId),
            }))
            .filter((d) => d.productId === normalizeProductId(x.productId))
            .map((d) => {
                return {
                    ...d,
                    type: "Quantity",
                    reasonCode: d.reasonCode,
                    correctQuantity: d.quantity,
                    correctRealQuantity: d.realQuantity,
                    lineNumber: x.lineNumber,
                };
            });

        return {
            ...x,
            correctedItems: [...x.correctedItems, ...deliveryComplaints],
        };
    });

    const initialValues = {
        deliveryComplaintId: deliveryComplaint.id,
        driverName: deliveryComplaint.driverName,
        ...orderComplaint,
        complaintOrderInfo: {
            ...complaintOrderInfo,
            accountNumbers,
            paymentMethod: complaintOrderInfo && complaintOrderInfo.paymentMethod ? complaintOrderInfo.paymentMethod : "CreditCardOnDelivery",
            deliveryMethod: complaintOrderInfo && complaintOrderInfo.deliveryMethod ? complaintOrderInfo.deliveryMethod : "Van",
            deliveryWindow: complaintOrderInfo && complaintOrderInfo.deliveryClosesAt ? toDeliveryWinodwValue(complaintOrderInfo) : "",
            deliveryDate:
                complaintOrderInfo && complaintOrderInfo.deliveryStartsAt
                    ? moment(complaintOrderInfo.deliveryStartsAt).startOf("day")
                    : moment().add(1, "day").startOf("day"),
        },
        lines: linesWithDeliveryComplaint,
        complaintId,
    };

    const selector = formValueSelector("complaintForm");

    const correctedItems = _.chain(selector(state, "lines"))
        .map((x) => x.correctedItems || [])
        .value();

    const upsellLinesSelector = selector(state, "upsellLines");

    const exportData = _(selector(state, "lines"))
        .filter(filterEmpty)
        .map(mapWithDescription)
        .map((r) => {
            let quantityResult = "";
            let valueResult = "";
            if (r.correctRealItems) {
                const price = Math.round((r.grossPrice / r.packSize) * 100) / 100;
                quantityResult = `${r.description} ${r.correctRealItems} szt. x ${price} zł. `;
            } else if (r.correctRealQuantity) {
                quantityResult = `${r.description} ${r.correctRealQuantity} szt. x ${r.grossPrice} zł. `;
            } else if (r.correctQuantity) {
                quantityResult = `${r.description} ${r.correctQuantity} szt. x ${r.grossPrice} zł. `;
            }

            if (r.correctGrossPrice) {
                const vlaue = parseFloat(r.grossPrice) - parseFloat(r.correctGrossPrice);
                valueResult = `${r.description} ${r.quantity} szt. x ${Math.round(vlaue * 100) / 100} zł`;
            }

            return [quantityResult, valueResult].filter(Boolean).join("\n");
        })
        .value()
        .join("\n");

    const { profile } = getUser() || {};
    const { role: roles = [] } = profile || {};

    return {
        isDeliveryComplaint: Boolean(deliveryComplaint.id),
        havePreviousComplaints: isFirstComplaint === false,
        editionDisabled:
            !isComplaintOpen || (roles.some((x) => x === AccountantDepartmentRoles.includes(x)) && !roles.some((x) => x === roles.Administrator)),
        initialValues,
        orderComplaint,
        correctedItems,
        orderData,
        upsellLines: upsellLinesSelector,
        loggedUser: profile.email,
        exportData,
        complaintOrderInfo,
    };
};

export default mapStateToProps;

const filterEmpty = (p) => {
    return p.correctedItems && p.correctedItems.length && p.correctedItems.length > 0;
};

const mapWithDescription = (p) => {
    return p.correctedItems
        .map((c) => ({
            description: p.description,
            grossPrice: p.grossPrice,
            quantity: p.quantity,
            packSize: p.packSize,
            ...c,
        }))
        .reduce(
            (c, n) => {
                return {
                    ...c,
                    ...n,
                    correctQuantity: (Number(n.correctQuantity) || 0) + c.correctQuantity,
                    correctRealQuantity: n.correctRealQuantity ? Number(n.correctRealQuantity) + c.correctRealQuantity : undefined,
                    correctGrossPrice: n.correctGrossPrice ? n.correctGrossPrice : c.correctGrossPrice,
                };
            },
            {
                description: "",
                grossPrice: 0,
                correctQuantity: 0,
                correctRealQuantity: 0,
                correctGrossPrice: 0,
                quantity: 0,
                packSize: 0,
            }
        );
};
