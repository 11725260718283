import timePrecisonFormats from "utils/formating/timePrecisonFormats";
import Validator, { notEmptyValidator } from "utils/validators/basic";
import moment from "moment";
import _ from "lodash";
import { voucherKinds } from "translations";
import { tValidation, validation } from "utils-ts/validations/translation";
import { validateDefinitions } from "views/offers/promotions/validate";

function checkProperties(obj) {
    for (var key in obj) {
        if (obj[key] !== null && obj[key] !== "") return false;
    }
    return true;
}

const excludeProductsPropertyValidator = (includeProducts, excludeProducts) =>
    new Validator(excludeProducts)
        .must((x) => _.isEmpty(x) || checkProperties(x), tValidation(validation.emptyIfIncludeProductsEmpty))
        .when(_.isEmpty(includeProducts) || checkProperties(includeProducts));

export const validateGenerator = (values) => {
    const errors = {};
    const { definitions = [] } = values;
    return {
        ...errors,
        definitions: definitions.map((v) => {
            return {
                definition: {
                    voucherPromotions: v.definition?.voucherPromotions?.map((p = {}) => {
                        return {
                            definition: validateDefinitions(p.definition),
                        };
                    }),
                },
            };
        }),
    };
};

export const validateVoucher = (values) => {
    const errors = {};
    const { definition = {}, formType, validFrom, validTo } = values;
    const { voucherPromotions = [], voucherType } = definition;
    const validFromMoment = moment(validFrom);
    const validToMoment = moment(validTo);

    errors.validFrom = notEmptyValidator(validFrom)
        .when(formType === "vouchers")
        .must(
            () => validFromMoment.isBefore(validToMoment),
            tValidation(validation.lessDateThan, {
                date: validToMoment.format(timePrecisonFormats.seconds),
            })
        )
        .when(Boolean(validFrom && validTo))
        .validate();

    errors.validTo = notEmptyValidator(validTo)
        .when(formType === "vouchers")
        .must(
            () => validFromMoment.isBefore(validToMoment),
            tValidation(validation.greaterDateThan, {
                date: validFromMoment.format(timePrecisonFormats.seconds),
            })
        )
        .when(Boolean(validFrom && validTo))
        .validate();

    if (voucherType === voucherKinds.percent_voucher || voucherType === voucherKinds.value_voucher) {
        const { includeProducts = {}, excludeProducts = {} } = definition;
        const result = excludeProductsPropertyValidator(includeProducts, excludeProducts).validate();

        errors.definition = {
            excludeProducts: {
                products: result,
                categories: result,
                brands: result,
                suppliers: result,
                producers: result,
                tags: result,
            },
        };
    }

    return {
        ...errors,
        definition: {
            ...errors.definition,
            voucherPromotions: voucherPromotions.map((p = {}) => {
                return {
                    definition: validateDefinitions(p.definition),
                };
            }),
        },
    };
};
