import { AcceptRejectDialog } from "components/Dialog";
import Layout from "components/Grid/Layout";
import SimpleTable, { createColumn } from "components/Table/SimpleTable/SimpleTable";
import tableActionTypes from "components/Table/SimpleTable/TableActions/tableActionTypes";
import { deleteDeliveryComplaint, getDeliveryComplaints, selectDeliveryComplaint } from "store/deliveryComplaints/actions";
import { documentTypes } from "utils/dictionaryTypes/index";
import { formatTypes } from "utils/formating";
import { useCommonTranslation } from "utils/hooks";
import { splitOrderId } from "utils/splitIds";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePush } from "utils-ts/hooks";

const rootSelector = (state) => state.deliveryComplaints;

const DeliveryComplaintList = () => {
    const [idToRemove, setIdToRemove] = useState(null);
    const { t, common } = useCommonTranslation();
    const dispatch = useDispatch();
    const { push } = usePush();
    const { isLoading, items, pageIndex, pageSize, pageCount, totalCount } = useSelector(rootSelector);

    useEffect(() => {
        dispatch(getDeliveryComplaints(pageIndex, pageSize));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, getDeliveryComplaints]);

    const columns = [
        createColumn("orderId", common.orderId, formatTypes.text),
        createColumn("merchant", common.merchant, formatTypes.text),
        createColumn("status", common.status, formatTypes.text),
        createColumn("driverName", common.driverName, formatTypes.text),
        createColumn(
            "edit",
            "",
            formatTypes.action,
            {
                actionType: tableActionTypes.edit,
            },
            async (item) => {
                const { orderId, merchant } = item;
                const { customerId, orderNumber } = splitOrderId(orderId);
                await dispatch(selectDeliveryComplaint(item));
                push(`/customers/${customerId}/orders/${orderNumber}/complaints/${merchant}/${documentTypes.invoice}`);
            }
        ),
        createColumn(
            "delete",
            "",
            formatTypes.action,
            {
                actionType: tableActionTypes.delete,
            },
            async (item) => {
                await setIdToRemove(item.id);
            }
        ),
    ].map((c) => ({ ...c, value: t(c.value) }));

    return (
        <Layout
            main
            headerText={t(common.deliveryComplaint)}
        >
            <AcceptRejectDialog
                title={t(common.removeDeliveryComplaint)}
                open={Boolean(idToRemove)}
                handleAccept={async () => {
                    await dispatch(deleteDeliveryComplaint(idToRemove));
                    setIdToRemove(null);
                    dispatch(getDeliveryComplaints(pageIndex, pageSize));
                }}
                handleReject={() => setIdToRemove(null)}
                maxWidth="sm"
            >
                {t(common.removeDeliveryComplaintQuestion)}
            </AcceptRejectDialog>
            <SimpleTable
                isLoading={isLoading}
                columns={columns}
                hover
                pagination
                data={items}
                paginationProps={{
                    pageIndex,
                    totalCount,
                    pageSize,
                    pageCount,
                    onPageChange: (pageIndex) => dispatch(getDeliveryComplaints(pageIndex, pageSize)),
                    onRowsPerPageChange: (pageSize) => dispatch(getDeliveryComplaints(pageIndex, pageSize)),
                }}
            />
        </Layout>
    );
};

export default DeliveryComplaintList;
