import tableStyle from "assets/jss/material-dashboard-react/components/tableStyle";
import tasksStyle from "assets/jss/material-dashboard-react/components/tasksStyle";
import { withStyles } from "tss-react/mui";
import List from "@mui/icons-material/List";
import { Fab, IconButton } from "@mui/material";
import { blue, green } from "@mui/material/colors";
import classnames from "classnames";

const styles = {
    ...tableStyle,
    ...tasksStyle,
    icon: {
        "&:hover": {
            color: blue,
        },
    },
    iconActive: {
        color: green,
    },
};

const add = ({ handleClick, classes, fab, disabled }) => {
    return (
        <>
            {fab ? (
                <Fab
                    className="pull-right"
                    aria-label="Add"
                    size="small"
                    color="primary"
                    onClick={handleClick}
                    disabled={disabled}
                >
                    <List />
                </Fab>
            ) : (
                <IconButton
                    color="primary"
                    className={classes.icon}
                    onClick={handleClick}
                    disabled={disabled}
                >
                    <List className={classnames(classes.tableActionButtonIcon, !disabled ? classes.edit : classes.disabled)} />
                </IconButton>
            )}
        </>
    );
};

export default withStyles(add, styles);
