import {
    ContainerDocument,
    ContainerProduct,
    ContainerSection,
} from "Commerce-Content";
import { CartContextPredicateValidator } from "utils-ts/validations/commerce";
import { ExtendedValidator } from "utils-ts/validations/extendedValidator";

class ContainerProductValidator extends ExtendedValidator<ContainerProduct> {
    constructor() {
        super();

        this.ruleFor("productId").identity();

        this.ruleFor("validFrom")
            .lessThan("validTo")
            .whenNotEmpty([
                "validFrom",
                "validTo",
            ]);

        this.ruleFor("validTo")
            .greaterThan("validFrom")
            .whenNotEmpty([
                "validFrom",
                "validTo",
            ]);
    }
}

const containerProductValidator =
    new ContainerProductValidator();

class ContainerSectionValidator extends ExtendedValidator<ContainerSection> {
    constructor() {
        super();

        this.ruleFor("sectionName")
            .notNull()
            .length(1, 100);

        this.ruleForEach("products").notNull();
    }
}

const containerSectionValidator =
    new ContainerSectionValidator();

const cartContextPredicateValidator =
    new CartContextPredicateValidator();

export class ContainerValidator extends ExtendedValidator<ContainerDocument> {
    constructor() {
        super();

        this.ruleFor("containerName")
            .notEmpty()
            .length(1, 100);

        this.ruleFor("categoryName")
            .notEmpty()
            .length(1, 100);

        this.ruleFor("comment")
            .length(1, 100)
            .whenNotEmpty("comment");

        this.ruleFor("validFrom")
            .lessThan("validTo")
            .whenNotEmpty([
                "validFrom",
                "validTo",
            ]);

        this.ruleFor("validTo")
            .greaterThan("validFrom")
            .whenNotEmpty([
                "validFrom",
                "validTo",
            ]);

        this.ruleForEach("products").setValidator(
            containerProductValidator
        );

        this.ruleForEach("sections")
            .notNull()
            .setValidator(
                containerSectionValidator
            );

        this.ruleFor("requirements")
            .setValidator(
                cartContextPredicateValidator
            )
            .whenNotEmpty();
    }
}
