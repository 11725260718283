import { FormField } from "components/Form";
import { diff } from "json-diff";
import { makeStyles } from "tss-react/mui";
import { Alert, AlertTitle } from "@mui/lab";
import moment from "moment";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { validators } from "../validators";

const useStyles = makeStyles()((theme) => ({
    alert: {
        padding: theme.spacing(1),
    },
}));

const ChangeReason = ({ pristine, isDecisionRequired, changeReason, initialValues, currentValues }) => {
    const { t } = useTranslation("vendor");
    const { classes } = useStyles();
    const difference = diff(initialValues, currentValues);
    const { dateFrom } = initialValues;
    const { dateFrom: changedDateFrom } = currentValues;
    let changed = false;
    for (const property in difference) {
        if ((property.endsWith("_deleted") && !difference[property]) || property.startsWith("settlementFile")) {
            continue;
        } else {
            changed = true;
        }
    }

    return !isDecisionRequired ? (
        !pristine &&
        changed &&
        ((dateFrom && moment(dateFrom).isSameOrBefore(moment().subtract(1, "day"), "day")) ||
            (changedDateFrom && moment(changedDateFrom).isSameOrBefore(moment().subtract(1, "day"), "day"))) ? (
            <>
                <Alert severity="warning">
                    <AlertTitle>{t(vendor.attention)}</AlertTitle>
                    {t(vendor.changesWillRequireDecision)}
                </Alert>
                <FormField
                    stretch
                    name={"changeReason"}
                    label={t(vendor.changeReason)}
                    type={"text"}
                    validate={validators.required}
                />
            </>
        ) : (
            <></>
        )
    ) : (
        <div className={classes.alert}>
            <Alert severity="warning">
                <AlertTitle>{t(vendor.changeReason)}</AlertTitle>
                {changeReason}
            </Alert>
        </div>
    );
};

export default ChangeReason;
