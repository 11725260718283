import { types } from "./actions";

const initialState = {};

export default (state = initialState, { type, payload, meta }) => {
    switch (type) {
        case types.FORM_INIT_PHRASE_BOOST:
            return { ...initialState };
        case types.GET_PHRASE_BOOST:
            return { ...initialState, isLoading: true };
        case types.GET_PHRASE_BOOST_SUCCESS:
            return {
                ...state,
                ...payload,
                id: meta.isCopy ? "" : payload.id,
                isLoading: false,
            };
        case types.GET_PHRASE_BOOST_FAILURE:
            return { ...payload, isLoading: false };
        case types.CREATE_OR_UPDATE_PHRASE_BOOST:
            return { ...state, isSubbmitRequested: true };
        case types.CREATE_OR_UPDATE_PHRASE_BOOST_SUCCESS:
        case types.CREATE_OR_UPDATE_PHRASE_BOOST_FAILURE:
            return { ...state, isSubbmitRequested: false };
        default:
            return state;
    }
};
