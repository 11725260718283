import { generatePath } from "react-router-dom";
import { AssignedDevices, DevicesAssignmentDocument } from "Operations";
import { Fetcher } from "api-types";
import { useCreateOrUpdateMutation, useDeleteMutation, useFileQuery, useGetQuery } from "api/hooks";

const url = (id?: string) => generatePath(`api/device-assignments/:id?`, { id: id ? id : null });

export const useGetEmployeeAssignments = (id?: string) => {
    return useGetQuery<DevicesAssignmentDocument[]>({
        app: "operations",
        url: url() + `/employees/${id}`,
        shouldMakeRequest: Boolean(id),
    });
};

export const useGetWorkstationAssignments = (id?: string) => {
    return useGetQuery<DevicesAssignmentDocument[]>({
        app: "operations",
        url: url() + `/workstations/${id}`,
        shouldMakeRequest: Boolean(id),
    });
};

export const useGetEmployeeAssignedDevices = (id?: string) => {
    return useGetQuery<AssignedDevices[]>({
        app: "operations",
        url: url() + `/employees/${id}/devices`,
        shouldMakeRequest: Boolean(id),
    });
};

export const useGetWorkstationAssignedDevices = (id?: string) => {
    return useGetQuery<AssignedDevices[]>({
        app: "operations",
        url: url() + `/workstations/${id}/devices`,
        shouldMakeRequest: Boolean(id),
    });
};

export const useGetDeviceAssignment = (id?: string) => {
    return useGetQuery<DevicesAssignmentDocument>({
        app: "operations",
        url: url(id),
        shouldMakeRequest: Boolean(id),
    });
};

export const useCreateOrUpdateDevicesAssignment = (id?: string) => {
    return useCreateOrUpdateMutation<DevicesAssignmentDocument, DevicesAssignmentDocument>({
        app: "operations",
        url: url(id),
        id: id,
    });
};

export const useDevicesAssignmentReadyToSign = (id: string) => {
    return useCreateOrUpdateMutation<DevicesAssignmentDocument, DevicesAssignmentDocument>({
        app: "operations",
        url: url(id) + "/ready-to-sign",
        id: id,
    });
};

export const useSignDevicesAssignment = (id: string) => {
    return useCreateOrUpdateMutation<DevicesAssignmentDocument, DevicesAssignmentDocument>({
        app: "operations",
        url: url(id) + "/sign",
        id: id,
    });
};

export const useGetDeviceAssignmentFile = (id: string, blobName: string, fileName: string) => {
    return useFileQuery<Fetcher.FileResponse>({
        app: "operations",
        url: url(id) + `/file?blobName=${blobName}`,
        shouldMakeRequest: Boolean(id) && Boolean(blobName),
        downloadFile: false,
        fileName: fileName,
    });
};

export const useDeleteDevicesAssignment = (id?: string) => {
    return useDeleteMutation({
        app: "operations",
        url: url(id),
    });
};

export const useConfirmDevicesAssignment = (id: string) => {
    return useCreateOrUpdateMutation<DevicesAssignmentDocument, DevicesAssignmentDocument>({
        app: "operations",
        url: url(id) + "/confirm",
        id: id,
    });
};
