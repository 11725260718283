import { RSAA } from "redux-api-middleware";
import { operations } from "store/actionTypePrefixes";
import { actions as productNamesActions } from "store/order/productNames/action";
import { documentTypes, merchants } from "utils/dictionaryTypes/index";
import { firstOrDefault } from "utils/extensions/arrayExtensions";
import { downloadPdfFile, rsaaActionType } from "utils/rsaa/downloadFileAction";
import _ from "lodash";

export const types = {
    ORDERS_GET_ORDER: `${operations}/ORDERS_GET_ORDER`,
    ORDERS_GET_ORDER_SUCCESS: `${operations}/ORDERS_GET_ORDER_SUCCESS`,
    ORDERS_GET_ORDER_FAILURE: `${operations}/ORDERS_GET_ORDER_FAILURE`,
    ORDER_GET_COMPLETE_OPERATIONS_ORDER: `${operations}/ORDER_GET_COMPLETE_OPERATIONS_ORDER`,
};

const getOrder = (customerId, orderNumber, complaintNumber) => async (dispatch) => {
    const orderPromise = dispatch({
        [RSAA]: {
            endpoint: `api/users/${customerId}/orders/${orderNumber}`,
            method: "POST",
            headers: { "Content-Type": "application/json" },
            types: [types.ORDERS_GET_ORDER, types.ORDERS_GET_ORDER_SUCCESS, types.ORDERS_GET_ORDER_FAILURE],
            body: JSON.stringify(complaintNumber || ""),
        },
    });

    return orderPromise;
};

const getRootDocuments = (order) => {
    const { documents } = order;
    const invoices = _.chain(documents)
        .filter((d) => d.documentType === documentTypes.invoice)
        .value();
    const releaseNotices = _.chain(documents)
        .filter((d) => d.documentType === documentTypes.releaseNotice)
        .value();
    const releaseNoticeFrisco = releaseNotices.find((x) => x.merchant === merchants.Frisco);
    const releaseNoticeF24 = releaseNotices.find((x) => x.merchant === merchants.F24);
    const invoiceFrisco = invoices.find((x) => x.merchant === merchants.Frisco);
    const invoiceF24 = invoices.find((x) => x.merchant === merchants.F24);
    return {
        invoiceFrisco,
        invoiceF24,
        releaseNoticeFrisco,
        releaseNoticeF24,
        lines: [...(invoiceFrisco && invoiceFrisco.lines ? invoiceFrisco.lines : []), ...(invoiceF24 && invoiceF24.lines ? invoiceF24.lines : [])],
    };
};

const getCompleteOrder = (customerId, orderNumber, complaintNumber) => async (dispatch) => {
    const { payload: order } = await dispatch(getOrder(customerId, orderNumber, complaintNumber));

    const { productLines: lines = [], userId } = order;

    const productIds = lines.map((x) => x.productId);
    const $productNames = dispatch(productNamesActions.getProductNames("WAW", userId, productIds));
    const { lines: invoiceLines = [], ...orderDocuments } = getRootDocuments(order);
    const productNames = await $productNames;
    const products = lines.map((line) => {
        const { fullName = "" } = firstOrDefault(productNames, (x) => Number(x.productId) === Number(line.productId), {});
        const invoiceLine = firstOrDefault(invoiceLines, (x) => x.productId === line.productId, {});
        const { grossTotal, quantity } = invoiceLine;
        return {
            ...invoiceLine,
            ...line,
            productName: fullName,
            totalInvoice: grossTotal,
            quantityInvoice: quantity,
            validityPeriod: line.validityPeriod,
        };
    });

    const serviceProducts = invoiceLines
        .filter((x) => x.productId.includes("U"))
        .map((x) => ({
            ...x,
            productName: x.description,
            totalInvoice: x.grossTotal,
            quantityInvoice: x.quantity,
            merchant: merchants.Frisco,
        }));

    const productLines = [...products, ...serviceProducts];

    dispatch({
        type: types.ORDER_GET_COMPLETE_OPERATIONS_ORDER,
        payload: { ...order, productLines, orderDocuments },
    });

    return Promise.resolve();
};

const getOrderDocument = (customerId, orderId, documentId, documentNumber) => async (dispatch) => {
    await dispatch(
        downloadPdfFile(
            `api/users/${customerId}/orders/${orderId}/get-order-document/${documentId}`,
            rsaaActionType(types.ORDER_GET_DOCUMENT, types.ORDER_GET_DOCUMENT_SUCCESS, types.ORDER_GET_DOCUMENT_FAILURE),
            documentNumber
        )
    );

    return Promise.resolve();
};

export const actions = {
    getCompleteOrder,
    getOrderDocument,
};
