import Preloader from "components/Preloader/Preloader";
import SimpleTable, { createColumn } from "components/Table/SimpleTable/SimpleTable";
import { getUserInfo, userInfoSelector } from "store/autocomplete";
import { getProcedureAcceptances } from "store/procedures";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useCommonTranslation } from "utils/hooks";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { orderBy } from "lodash";

const ProcedureAcceptances = ({ procedureId, isLoading, acceptances }) => {
    const dispatch = useDispatch();
    const { t, common } = useCommonTranslation();

    useEffect(() => {
        dispatch(getProcedureAcceptances(procedureId));
    }, [dispatch, procedureId]);

    useEffect(() => {
        dispatch(getUserInfo({ isActive: true }));
    }, [dispatch]);

    const employees = useSelector(userInfoSelector);

    if (isLoading) {
        return <Preloader />;
    }

    const columns = [
        createColumn("fullName", t(common.fullName), formatTypes.fullName),
        createColumn("lastProcedureVersion", t(common.version), formatTypes.numeric),
        createColumn("acceptStatus", t(common.status), formatTypes.translatable, {
            translationKey: "procedures",
        }),
        createColumn("lastPostponedDate", t(common.postponedDate), formatTypes.time, {
            timeFormat: timePrecisonFormats.seconds,
        }),
        createColumn("isHidden", t(common.assigned), formatTypes.boolean, { negation: true }),
    ];

    let data = (acceptances || []).map((a) => {
        const employeeId = a.id.split("/")[1];
        const employee = employees.find((u) => u.userId === employeeId);
        const version = a.lastProcedureVersion < 0 ? undefined : a.lastProcedureVersion;

        return {
            ...a,
            fullName: employee?.fullName,
            email: employee?.email,
            lastProcedureVersion: version,
        };
    });

    data = orderBy(
        data,
        [
            (d) => d.isHidden,
            (d) => {
                if (d.acceptStatus.startsWith("Postponed")) {
                    return 3;
                } else if (d.acceptStatus.startsWith("Accepted")) {
                    return 1;
                } else {
                    return 2;
                }
            },
            (d) => d.fullName?.firstName,
            (d) => d.fullName?.lastName,
        ],
        ["asc", "desc", "asc", "asc"]
    );

    return (
        <SimpleTable
            columns={columns}
            data={data}
            stripped
            hover
        />
    );
};

export default ProcedureAcceptances;
