import moment from "moment";
import _ from "lodash";
import { createSelector } from "reselect";

const calendarSelector = (state) => state.calendar;

const statisticsSelctor = createSelector(calendarSelector, (calendar) => calendar.statistics);

export const totalStatisticSelector = createSelector(statisticsSelctor, (statistics) =>
    statistics
        .map((s) => s.deliveryStatistics)
        .reduce((current, next) => {
            return current.merge(next);
        }, new Statistic())
);

const deliveryWindowsSelector = createSelector(calendarSelector, (calendar) => calendar.deliveryWindows);

export const deliveryWindowsSummarySelector = createSelector(deliveryWindowsSelector, (deliveryWindows) => {
    const { items } = deliveryWindows;
    return calculateSummary(items);
});

const calculateSummary = (items) =>
    _(items).reduce(
        (c, n) => {
            return {
                reservationLimit: c.reservationLimit + n.reservationLimit,
                reservationCount: c.reservationCount + n.reservationCount,
                orderCount: c.orderCount + n.orderCount,
            };
        },
        {
            reservationLimit: 0,
            reservationCount: 0,
            orderCount: 0,
        }
    );

export const termsSelector = createSelector(deliveryWindowsSelector, (deliveryWindows) => {
    const { items } = deliveryWindows;
    const { DPD = [], Van = [] } = groupByDeliveryMethod(items);
    const groupedVanTerms = groupByTerms(Van);

    return { DPD, vanGroups: groupedVanTerms };
});

const groupByTerms = (items) =>
    _(items)
        .orderBy((x) => x.closesAt)
        .groupBy((n) => moment(n.closesAt).format("HH:mm"))
        .value();

const groupByDeliveryMethod = (items) =>
    _(items)
        .map((x) => ({
            ...x,
            limitTotal: x.reservationLimit + x.newLimit + x.vipLimit,
            reservationTotal: x.reservationCount + x.newReservationCount + x.vipReservationCount,
            carriersCount: x.carriers,
            ...x.deliveryWindow,
        }))
        .orderBy((x) => x.deliveryWindow.startsAt)
        .groupBy((x) => x.deliveryWindow.deliveryMethod)
        .value();

class PartialStatistic {
    orders = 0;
    lines = 0;
    quantity = 0;
    carriers = 0;

    merge = (next = {}) => {
        const { orders = 0, lines = 0, quantity = 0, carriers = 0 } = next;
        this.orders += orders;
        this.lines += lines;
        this.quantity += quantity;
        this.carriers += carriers;

        return this;
    };
}

class Statistic {
    totals = new PartialStatistic();
    deliveryTotals = {
        Van: new PartialStatistic(),
        DPD: new PartialStatistic(),
        Coolomat: new PartialStatistic(),
        Frisco: new PartialStatistic(),
    };
    storageTotals = {
        Normal: new PartialStatistic(),
        Cooler: new PartialStatistic(),
        Freezer: new PartialStatistic(),
    };

    merge = (next) => {
        const { totals = {}, deliveryTotals = {}, storageTotals = {} } = next;

        this.totals.merge(totals);
        this.deliveryTotals.Van.merge(deliveryTotals.Van);
        this.deliveryTotals.DPD.merge(deliveryTotals.DPD);
        this.deliveryTotals.Coolomat.merge(deliveryTotals.Coolomat);
        this.deliveryTotals.Frisco.merge(deliveryTotals.Frisco);
        this.storageTotals.Normal.merge(storageTotals.Normal);
        this.storageTotals.Cooler.merge(storageTotals.Cooler);
        this.storageTotals.Freezer.merge(storageTotals.Freezer);

        return this;
    };
}
