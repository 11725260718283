import { Validator } from "fluentvalidation-ts";
import { DocumentArchiveTypeDocument } from "Operations";
import { notEmptyString } from "utils-ts/validations";

class DocumentTypeValidator extends Validator<DocumentArchiveTypeDocument> {
    constructor() {
        super();

        this.ruleFor("name").must(notEmptyString).maxLength(100);

        this.ruleFor("description").must(notEmptyString).maxLength(1000);

        this.ruleFor("levelOfConfidentiality").must(notEmptyString);
    }
}

export default DocumentTypeValidator;
