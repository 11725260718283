import buttons from "assets/jss/custom/buttons";
import InfoDialog from "components/Dialog/InfoDialog";
import { B2BDepartmentRoles, ECommerceDepartmentRoles, SupportDepartmentRoles } from "consts/roles";
import consentTypes from "resource/consentTypes.json";
import { withStyles } from "tss-react/mui";
import { formatTypes, formatValue, timePrecisonFormats } from "utils/formating";
import React, { Fragment } from "react";
import { Button, Grid, Typography } from "@mui/material";
import classnames from "classnames";
import { useUser } from "context";
import { common, customers } from "translations";
import { useTranslation } from "utils-ts/hooks";
import ConsentForm from "./ConsentForm";

const styles = (theme) => ({
    ...buttons(theme),
    item: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    row: {
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        borderBottom: "1px solid lightgray",
    },
    charm: {
        border: `1px solid ${theme.palette.primary.light}`,
        color: theme.palette.primary.main,
        fontWeight: "bold",
        borderRadius: 5,
        textTransform: "uppercase",
        fontSize: 11,
        padding: theme.spacing(1),
    },
    button: {
        minWidth: 120,
        marginRight: theme.spacing(1),
    },
});

const Consent = ({ classes, isConsented, consent, createdAt, profileType, isEmailConsented, ...rest }) => {
    const { isInRoleOrAdmin } = useUser();
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation(["customers", "common"]);
    const { text, consentType } = consent;
    const { pl } = text;
    const canConsent = consent.consentType == "MembershipRewards" ? isEmailConsented && profileType == "Home" : true;

    const handleClose = () => {
        setOpen(false);
    };

    const buttonSettings = isConsented
        ? {
              res: t(`common:${common.decline}`),
              classes: classes.decline,
          }
        : {
              res: t(`common:${common.agree}`),
              classes: classes.accept,
          };

    return (
        <Fragment>
            <Grid
                container
                className={classes.row}
            >
                <Grid
                    item
                    xs={1}
                >
                    <Typography
                        className={classes.item}
                        variant="body1"
                        align="center"
                    >
                        {formatValue(createdAt, formatTypes.time, {
                            timeFormat: timePrecisonFormats.seconds,
                        })}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={1}
                >
                    <Typography
                        className={classnames(classes.item, classes.charm)}
                        variant="body1"
                        align="center"
                    >
                        {formatValue(consentType, formatTypes.resource, {
                            items: consentTypes,
                        })}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={10}
                >
                    <Typography
                        className={classes.item}
                        variant="body1"
                    >
                        {pl}
                    </Typography>
                    <Button
                        onClick={() => setOpen(true)}
                        className={classnames(classes.item, classes.button, buttonSettings.classes, "pull-right")}
                        variant="contained"
                        color="primary"
                        disabled={!isInRoleOrAdmin([...B2BDepartmentRoles, ...ECommerceDepartmentRoles, ...SupportDepartmentRoles]) || !canConsent}
                    >
                        {buttonSettings.res}
                    </Button>
                </Grid>
            </Grid>
            <InfoDialog
                title={t(`${customers.consentChange}`)}
                open={open}
                onClose={handleClose}
                maxWidth="sm"
            >
                <ConsentForm
                    {...rest}
                    onClose={handleClose}
                    isConsented={isConsented}
                    buttonSettings={buttonSettings}
                />
            </InfoDialog>
        </Fragment>
    );
};

export default withStyles(Consent, styles);
