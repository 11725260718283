import {
    container,
    dangerColor,
    defaultBoxShadow,
    defaultFont,
    infoColor,
    primaryColor,
    successColor,
    warningColor,
} from "assets/jss/material-dashboard-react";

const headerStyle = () => ({
    appBar: {
        backgroundColor: "transparent",
        boxShadow: "none",
        position: "absolute",
        width: "100%",
        paddingTop: "10px",
        zIndex: "1029",
        color: "#555555",
        border: "0",
        borderRadius: "3px",
        transition: "all 150ms ease 0s",
        minHeight: "0px",
        display: "block",
    },
    container: {
        ...container,
        alignItems: "flex-start",
        minHeight: "30px",
    },
    flex: {
        flex: 1,
    },
    title: {
        ...defaultFont,
        "lineHeight": "15px",
        "fontSize": "18px",
        "borderRadius": "3px",
        "textTransform": "none",
        "color": "inherit",
        "margin": "0",
        "&:hover,&:focus": {
            background: "transparent",
        },
    },
    appResponsive: {
        top: "8px",
    },
    primary: {
        backgroundColor: primaryColor,
        color: "#FFFFFF",
        ...defaultBoxShadow,
    },
    info: {
        backgroundColor: infoColor,
        color: "#FFFFFF",
        ...defaultBoxShadow,
    },
    success: {
        backgroundColor: successColor,
        color: "#FFFFFF",
        ...defaultBoxShadow,
    },
    warning: {
        backgroundColor: warningColor,
        color: "#FFFFFF",
        ...defaultBoxShadow,
    },
    danger: {
        backgroundColor: dangerColor,
        color: "#FFFFFF",
        ...defaultBoxShadow,
    },
});

export default headerStyle;
