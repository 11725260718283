const styles = (theme) => ({
    button: {
        "fontSize": 3,
        "textAlign": "center",
        "justifyContent": "center",
        "margin": "auto",
        "backgroundColor": theme.palette.error.main,
        "color": theme.palette.error.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.error.dark,
            color: theme.palette.error.contrastText,
        },
    },
});

export default styles;
