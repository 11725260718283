import { sgFormSelector } from "store/vendors/subsidiaryGains";
import { findSubsidiaryGainForApproval } from "store/vendors/subsidiaryGains/action";
import { useParams } from "utils/hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SubsidiaryGainsForm } from "views/vendors/components/subsidiaryGains";

const SubsidiaryGainDecisions = () => {
    const { id: vendorId } = useParams();
    const { isEmpty, vendorUsers = [] } = useSelector(sgFormSelector);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(findSubsidiaryGainForApproval(vendorId));
    }, [dispatch, vendorId]);

    return isEmpty ? (
        "Brak decyzji do akceptacji"
    ) : (
        <SubsidiaryGainsForm
            vendorUsers={vendorUsers}
            handleDecision={async () => {
                await dispatch(findSubsidiaryGainForApproval(vendorId));
            }}
        />
    );
};

export default SubsidiaryGainDecisions;
