import { HIDE_MESSAGE_BOX, SHOW_MESSAGE_BOX } from "./actions";

const initialState = {
    modalProps: {},
    open: false,
};

export const messageBox = (state = initialState, action) => {
    state = state || initialState;
    switch (action.type) {
        case SHOW_MESSAGE_BOX:
            const modalProps = action.payload.modalProps;
            switch (action.payload.type) {
                case "error":
                    modalProps.maxWidth = "sm";
                    modalProps.title = "error";
                    modalProps.confirmButtonText = "ok";
                    break;
                default:
                    break;
            }
            return { ...state, modalProps, open: true };
        case HIDE_MESSAGE_BOX:
            return { ...state, open: false };
        default:
            return state;
    }
};
