import { warehouses } from "views/content/initProducts/components/backoffice/VendorsPerWarehouse";
import {
    CHANGE_VARIANT,
    INIT_PRODUCT_DELETE_FILE,
    INIT_PRODUCT_DELETE_FILE_FAILURE,
    INIT_PRODUCT_DELETE_FILE_SUCCESS,
    INIT_PRODUCT_UPDATE_IMAGES,
    INIT_PRODUCT_UPDATE_IMAGES_FAILURE,
    INIT_PRODUCT_UPDATE_IMAGES_SUCCESS,
    INIT_PRODUCT_UPLOAD_FILE,
    INIT_PRODUCT_UPLOAD_FILE_FAILURE,
    INIT_PRODUCT_UPLOAD_FILE_SUCCESS,
    PRODUCT_INIT_ADD_FILE,
    PRODUCT_INIT_AUDIT,
    PRODUCT_INIT_AUDIT_FAILURE,
    PRODUCT_INIT_AUDIT_SUCCESS,
    PRODUCT_INIT_DOWNLOAD_FILE,
    PRODUCT_INIT_DOWNLOAD_FILE_FAILURE,
    PRODUCT_INIT_DOWNLOAD_FILE_SUCCESS,
    PRODUCT_INIT_FILL_CONTENT,
    PRODUCT_INIT_FILL_CONTENT_FAILURE,
    PRODUCT_INIT_FILL_CONTENT_SUCCESS,
    PRODUCT_INIT_FILL_OPERATIONS,
    PRODUCT_INIT_FILL_OPERATIONS_FAILURE,
    PRODUCT_INIT_FILL_OPERATIONS_SUCCESS,
    PRODUCT_INIT_GET_CONTENT_FAILURE,
    PRODUCT_INIT_GET_CONTENT_SUCCESS,
    PRODUCT_INIT_GET_DETAILS,
    PRODUCT_INIT_GET_DETAILS_SUCCESS,
    PRODUCT_INIT_REMOVE_FILE,
    PRODUCT_INIT_REVIEW,
    PRODUCT_INIT_REVIEW_FAILURE,
    PRODUCT_INIT_REVIEW_SUCCESS,
    SAVE_COMPLETED,
    SAVE_STARTED,
} from "./actions";

const initialState = {
    product: undefined,
    files: [],
    fileRequestInProgress: false,
    productAudited: false,
    productAuditedContent: false,
    productAuditedOperations: false,

    refresh: false,
    isSent: false,
    variant: undefined,
    fileUploadInProgress: false,
    filesBeingUploaded: 0,
    fileRemoveInProgress: false,

    reviewInProgress: false,

    images: [],
    productImagesUpdating: false,
    imageRemoveInProgress: false,
    setMainImageInProgress: false,
    initialAttributes: [],

    contentDownloaded: false,

    isSaving: false,
    isContentMissing: true,
};

export const initProductDetails = (state = initialState, action) => {
    state = state || initialState;
    switch (action.type) {
        case SAVE_STARTED:
            return { ...state, isSaving: true };
        case SAVE_COMPLETED:
            return { ...state, isSaving: false };
        case CHANGE_VARIANT:
            if (action.payload === state.product.ean) {
                return {
                    ...state,
                    variant: undefined,
                    productImagesUpdating: false,
                    fileUploadInProgress: false,
                    filesBeingUploaded: 0,
                    fileRemoveInProgress: false,
                    images: state.product.images,
                };
            } else {
                const variant = state.product.variantsData ? state.product.variantsData.find((x) => x.ean === action.payload) : undefined;
                return {
                    ...state,
                    variant: action.payload,
                    productImagesUpdating: false,
                    images: variant ? variant.images : [],
                };
            }

        case PRODUCT_INIT_REVIEW:
            return {
                ...state,
                reviewInProgress: true,
            };
        case PRODUCT_INIT_REVIEW_FAILURE:
            return {
                ...state,
                reviewInProgress: false,
            };
        case PRODUCT_INIT_REVIEW_SUCCESS:
            return {
                ...state,
                reviewInProgress: false,
                refresh: true,
                isSent: true,
            };

        case PRODUCT_INIT_FILL_CONTENT:
            return {
                ...state,
                productAudited: true,
                proudctAuditedContent: true,
            };
        case PRODUCT_INIT_FILL_CONTENT_FAILURE:
            return {
                ...state,
                productAudited: state.productAuditedOperations,
                proudctAuditedContent: false,
            };
        case PRODUCT_INIT_FILL_CONTENT_SUCCESS:
            return {
                ...state,
                proudctAuditedContent: false,
                productAudited: state.productAuditedOperations,
                refresh: !state.productAuditedOperations,
            };

        case PRODUCT_INIT_FILL_OPERATIONS:
            return {
                ...state,
                productAudited: true,
                productAuditedOperations: true,
            };
        case PRODUCT_INIT_FILL_OPERATIONS_FAILURE:
            return {
                ...state,
                productAudited: state.proudctAuditedContent,
                productAuditedOperations: false,
            };
        case PRODUCT_INIT_FILL_OPERATIONS_SUCCESS:
            return {
                ...state,
                productAuditedOperations: false,
                productAudited: state.proudctAuditedContent,
                refresh: !state.proudctAuditedContent,
            };

        case PRODUCT_INIT_AUDIT:
            return {
                ...state,
                productAudited: true,
            };
        case PRODUCT_INIT_AUDIT_FAILURE:
            return {
                ...state,
                productAudited: false,
            };
        case PRODUCT_INIT_AUDIT_SUCCESS:
            return {
                ...state,
                productAudited: false,
                refresh: true,
            };

        case PRODUCT_INIT_DOWNLOAD_FILE:
            return {
                ...state,
                fileRequestInProgress: true,
            };
        case PRODUCT_INIT_DOWNLOAD_FILE_FAILURE:
            return {
                ...state,
                fileRequestInProgress: false,
            };
        case PRODUCT_INIT_DOWNLOAD_FILE_SUCCESS:
            return {
                ...state,
                fileRequestInProgress: false,
            };

        case PRODUCT_INIT_GET_DETAILS:
            return {
                ...state,
                product: undefined,
                refresh: false,
                fileRequestInProgress: false,
                productAudited: false,
                productAuditedContent: false,
                productAuditedOperations: false,
                changedArrayAttributes: [],
                files: [],
                images: [],
                initialAttributes: [],
                contentDownloaded: false,
            };
        case PRODUCT_INIT_GET_DETAILS_SUCCESS:
            const operationsProduct = { ...state.product, ...action.payload };
            if (!operationsProduct.productDimensionGroup) {
                operationsProduct.productDimensionGroup = "W/S";
            }
            if (!operationsProduct.positionModelGroup) {
                operationsProduct.positionModelGroup = "FEFO";
            }
            if (!operationsProduct.positionGroup) {
                operationsProduct.positionGroup = "TH";
            }
            if (!operationsProduct.batchNumberGroup) {
                operationsProduct.batchNumberGroup = "STD";
            }
            if (!operationsProduct.storageDimensionGroup) {
                operationsProduct.storageDimensionGroup = "WMS";
            }
            if (!operationsProduct.trackingDimensionGroup) {
                operationsProduct.trackingDimensionGroup = "Partia";
            }
            if (!operationsProduct.rotationCode) {
                operationsProduct.rotationCode = "C";
            }
            if (!operationsProduct.acceptableOverWeight) {
                operationsProduct.acceptableOverWeight = 0;
            }
            if (operationsProduct.cut === undefined) {
                operationsProduct.cut = true;
            }
            if (operationsProduct.utilisation === undefined) {
                operationsProduct.utilisation = true;
            }
            if (operationsProduct.availableOnPlatform === undefined) {
                operationsProduct.availableOnPlatform = true;
            }
            if (operationsProduct.warehouses === undefined || operationsProduct.warehouses.length === 0) {
                if (operationsProduct.defaultSupplierId) {
                    operationsProduct.useDefaultSupplier = true;
                }
                operationsProduct.warehouses = warehouses.map((x) => x.value);
            }
            if (
                operationsProduct.defaultSupplierId &&
                !operationsProduct.useDefaultSupplier &&
                (!operationsProduct.suppliersPerWarehouse || Object.keys(operationsProduct.suppliersPerWarehouse).length === 0)
            ) {
                operationsProduct.useDefaultSupplier = true;
            }
            return {
                ...state,
                product: operationsProduct,
                files: [...action.payload.files],
                isSent: false,
            };

        case PRODUCT_INIT_GET_CONTENT_FAILURE:
            const contentProduct = { ...state.contentProduct };
            if (!contentProduct.attributes) {
                contentProduct.attributes = [];
            }
            return {
                ...state,
                product: contentProduct,
                changedArrayAttributes: contentProduct.attributes.some((x) => x.name === "Obliczona wartość odżywcza")
                    ? state.changedArrayAttributes
                    : { "Obliczona wartość odżywcza": 9 },
                contentDownloaded: true,
                isContentMissing: true,
            };

        case PRODUCT_INIT_GET_CONTENT_SUCCESS:
            const product = {
                ...state.product,
                ...action.payload,
                variants: state.product ? state.product.variants : [],
            };
            if (!product.attributes) {
                product.attributes = [];
            }
            const imagesFromIntegrations = action.payload.attributesFromIntegrations
                ? Object.entries(action.payload.attributesFromIntegrations).flatMap(([integration, attributes]) =>
                      Object.entries(attributes.images).map(([id, uri]) => ({
                          id,
                          uri,
                          integration,
                      }))
                  )
                : [];

            if (action.payload.imageFromIntegrationId) {
                var mainImage = imagesFromIntegrations.find((x) => x.id === action.payload.imageFromIntegrationId);
                mainImage.index = 0;
            }

            return {
                ...state,
                product,
                contentDownloaded: true,
                isContentMissing: false,
                initialAttributes: action.payload.attributes,
                images: [...action.payload.images, ...imagesFromIntegrations],
            };

        case PRODUCT_INIT_ADD_FILE:
            return {
                ...state,
                files: state.files.concat(action.payload),
            };
        case PRODUCT_INIT_REMOVE_FILE:
            return {
                ...state,
                files: state.files.filter((x) => x.blobId !== action.payload),
            };

        case INIT_PRODUCT_DELETE_FILE:
            return {
                ...state,
                fileRemoveInProgress: true,
            };

        case INIT_PRODUCT_DELETE_FILE_FAILURE:
            return {
                ...state,
                fileRemoveInProgress: false,
            };

        case INIT_PRODUCT_DELETE_FILE_SUCCESS:
            return {
                ...state,
                files: state.files.filter((x) => x.blobId !== action.payload),
                fileRemoveInProgress: false,
            };

        case INIT_PRODUCT_UPLOAD_FILE:
            return {
                ...state,
                fileUploadInProgress: true,
                fileBeingUploaded: state.fileBeingUploaded + 1,
            };

        case INIT_PRODUCT_UPLOAD_FILE_FAILURE:
            return {
                ...state,
                fileUploadInProgress: state.fileBeingUploaded === 1,
                fileBeingUploaded: state.fileBeingUploaded - 1,
            };

        case INIT_PRODUCT_UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                files: [...state.files, action.payload],
                fileUploadInProgress: state.fileBeingUploaded === 1,
                filesBeingUploaded: state.fileBeingUploaded - 1,
            };

        case INIT_PRODUCT_UPDATE_IMAGES: {
            return {
                ...state,
                productImagesUpdating: true,
            };
        }
        case INIT_PRODUCT_UPDATE_IMAGES_FAILURE: {
            return {
                ...state,
                productImagesUpdating: false,
            };
        }
        case INIT_PRODUCT_UPDATE_IMAGES_SUCCESS:
            if (state.variant) {
                const variant = state.product.variantsData ? state.product.variantsData.find((x) => x.ean === state.variant) : {};
                variant.images = action.payload.images;
                const newVariants = state.product.variantsData ? state.product.variantsData.filter((x) => x.ean !== state.variant) : [];
                return {
                    ...state,
                    images: action.payload.images,
                    productImagesUpdating: false,
                    product: {
                        ...state.product,
                        variantData: [...newVariants, variant],
                    },
                };
            }

            return {
                ...state,
                images: action.payload.images,
                productImagesUpdating: false,
                product: {
                    ...state.product,
                    images: action.payload.images,
                },
            };

        default:
            return state;
    }
};
