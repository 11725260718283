import { GetRequest, get, withMeta } from "utils/extensions";
import types from "./types";

const endpointCMS = (id) => `api/v1/content/containers/${id}`;
const endpointPromotions = (id) => `api/v1/offer/products/promotions/${id}`;
const endpointDiscounts = (id) => `api/v1/offer/products/discounts/${id}`;

export const getCMS = (id) => async (dispatch) => {
    return dispatch(get(new GetRequest(endpointCMS(id), withMeta({ id })(types.getCMS.all))));
};

export const getPromotion = (id) => async (dispatch) => {
    return dispatch(get(new GetRequest(endpointPromotions(id), withMeta({ id })(types.getPromotion.all))));
};

export const getDiscount = (id) => async (dispatch) => {
    return dispatch(get(new GetRequest(endpointDiscounts(id), withMeta({ id })(types.getDiscount.all))));
};
