import { combineReducers } from "redux";
import { marketingTools } from "./marketingTools/reducer";
import { promotionPeriods } from "./promotionPeriods/reducer";
import { reservationKits } from "./reservationKits/reducer";
import { toolkits } from "./toolkits/reducer";

export const bridge = combineReducers({
    promotionPeriods,
    marketingTools,
    toolkits,
    reservationKits,
});
