import { RSAA } from "redux-api-middleware";
import { actions as commerceCacheActions } from "store/cache/commerceUsers/actions";
import { actions as userInfosActions } from "store/cache/userInfos/actions";
import { DeleteRequest, PostRequest, post, remove } from "utils/extensions";
import { combineIntoQueryParams } from "utils/objectExtensions";
import types from "./types";

const endpoint = (customerId) => `api/v1/users${customerId ? "/" + customerId : ""}/balance-debt`;

export const findBalanceDebts =
    ({
        pageIndex,
        pageSize,
        userIds,
        manualActivityCounter,
        activityReasons,
        balanceChangeReasons,
        dateFrom,
        dateTo,
        userIdsPageCount,
        userIdsTotalCount,
        userIdsPageIndex,
        hasCreditLimit,
        profileType,
    }) =>
    async (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: `${endpoint()}?${combineIntoQueryParams({
                    pageIndex: userIdsPageIndex > 1 ? 1 : pageIndex,
                    pageSize,
                    userIds,
                    manualActivityCounter,
                    activityReasons,
                    balanceChangeReasons,
                    dateFrom,
                    dateTo,
                    hasCreditLimit,
                    profileType,
                })}`,
                method: "GET",
                types: types.findBalanceDebts.all,
            },
        }).then((resp) => {
            if (!resp.payload.items) {
                return;
            }

            const userIds = resp.payload.items.map((o) => o.recentActivities[0]?.createdBy);
            const commerceUsersIds = resp.payload.items.map((o) => o.id);
            dispatch(userInfosActions.getUserInfos(userIds));
            dispatch(commerceCacheActions.getCommerceUsers(commerceUsersIds));
            if (userIdsPageCount > 0 || userIdsTotalCount > 0 || userIdsPageIndex > 1) {
                dispatch(
                    changePagination({
                        pageCount: userIdsPageCount,
                        totalCount: userIdsTotalCount,
                        pageIndex: userIdsPageIndex,
                        userIdsOverride: true,
                    })
                );
            }
        });
    };

export const clearBalanceDebts = () => {
    return { type: types.clearBalanceDebts };
};

export const updateFilterValues = (filterValues) => {
    return { type: types.updateFilterValues, payload: filterValues };
};

export const changePagination = (pagination) => (dispatch) => {
    dispatch({ type: types.changePagination, payload: pagination });
};

export const getBalanceDebt = (customerId) => async (dispatch) => {
    dispatch({
        [RSAA]: {
            endpoint: endpoint(customerId),
            method: "GET",
            types: types.getBalanceDebt.all,
        },
    }).then((resp) => {
        if (resp?.payload?.recentActivities) {
            const userIds = resp.payload.recentActivities.map((o) => o.createdBy);
            dispatch(userInfosActions.getUserInfos(userIds));
            dispatch(commerceCacheActions.getCommerceUsers([resp.payload.id]));
        }
    });
};

export const findUserBalanceDebtActivities =
    ({ customerId, pageIndex, pageSize }) =>
    async (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: `${endpoint(customerId)}/activities?${combineIntoQueryParams({
                    pageIndex,
                    pageSize,
                })}`,
                method: "GET",
                types: types.findUserBalanceDebtActivities.all,
            },
        }).then((resp) => {
            if (resp?.payload?.items) {
                const userIds = resp.payload.items.map((o) => o.activity?.createdBy);
                dispatch(userInfosActions.getUserInfos(userIds));
            }
        });
    };

export const clearBalanceDebt = () => {
    return { type: types.clearBalanceDebt };
};

export const takeAction = (customerId, action) => (dispatch) => {
    return dispatch({
        [RSAA]: {
            headers: { "Content-Type": "application/json" },
            endpoint: `${endpoint(customerId)}/activity`,
            method: "POST",
            types: types.takeAction.all,
            body: JSON.stringify(action),
        },
    });
};

export const editAction = (customerId, activityId, action) => (dispatch) => {
    return dispatch({
        [RSAA]: {
            headers: { "Content-Type": "application/json" },
            endpoint: `${endpoint(customerId)}/activity/${activityId}`,
            method: "POST",
            types: types.editAction.all,
            body: JSON.stringify(action),
        },
    });
};

export const mute = (customerId) => (dispatch) => {
    return dispatch(
        post(
            new PostRequest(
                `${endpoint(customerId)}/mute`,
                [
                    types.muteBalanceDebt.request,
                    {
                        type: types.muteBalanceDebt.success,
                        meta: { customerId },
                    },
                    types.muteBalanceDebt.failure,
                ],
                {
                    body: {
                        customerId,
                    },
                }
            )
        )
    );
};

export const deleteActivity = (customerId, activityId) => (dispatch) => {
    return dispatch(remove(new DeleteRequest(`${endpoint(customerId)}/activity/${activityId}`, types.deleteActivity.all)));
};
