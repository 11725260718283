import { manager, operations } from "store/actionTypePrefixes";
import { AsyncTypes, asAsyncFormTypes, asSilientError, createAction } from "utils/extensions";

const types = {
    getSubsidiaryGains: new AsyncTypes("GET_SUBSIDIARY_GAINS", operations),
    formInit: createAction("FORM_INIT_SUBSIDIARY_GAIN", manager),
    getSubsidiaryGain: new AsyncTypes("GET_SUBSIDIARY_GAIN", operations),
    downloadPwu: new AsyncTypes("DOWNLOAD_PWU", operations),
    createSubsidiaryGain: new AsyncTypes("CREATE_SUBSIDIARY_GAIN", operations, [asAsyncFormTypes]),
    updateSubsidiaryGain: new AsyncTypes("UPDATE_SUBSIDIARY_GAIN", operations, [asAsyncFormTypes]),
    updateResponsibleUser: new AsyncTypes("UPDATE_RESPONSIBLE_USER_SUBSIDIARY_GAIN", operations),
    removeSubsidiaryGain: new AsyncTypes("REMOVE_SUBSIDIARY_GAIN", operations),
    findSubsidiaryGainForApproval: new AsyncTypes("FIND_SG_FOR_APPROVAL", operations),
    getServiceConfirmation: new AsyncTypes("GET_SERVICE_CONFIRMATION", operations),
    downloadServiceConfirmation: new AsyncTypes("DOWNLOAD_SERVICE_CONFIRMATION", operations),
    addServiceConfirmation: new AsyncTypes("ADD_SERVICE_CONFIRMATION", operations),
    downloadFile: new AsyncTypes("DOWNLOAD_SUBSIDIARY_GAIN_FILE", operations),
    makeDecision: new AsyncTypes("SUBSIDIARY_GAIN_MAKE_DECISION", operations, [asAsyncFormTypes]),
    generateReport: new AsyncTypes("GENERATE_SUBSIDIARY_GAIN_REPORT_FOR_VENDOR", operations),
    requestVendorDecision: new AsyncTypes("SUBSIDIARY_GAIN_REQUEST_VENDOR_DECISION", operations, [
        (types) =>
            asAsyncFormTypes(types, {
                requestMessage: "Wysyłanie maila",
                successMessage: "Mail wysłany",
                failureMessage: "Błąd podczas wysłania wiadomości",
            }),
    ]),
    getProposalDecisionStatus: new AsyncTypes("GET_PROPOSAL_CHANGE_STATUS", operations, [asSilientError]),
    createInvoiceCorrection: new AsyncTypes("CREATE_INVOICE_CORRECTION", operations, [asAsyncFormTypes]),
    getSubsidiaryGainInvoices: new AsyncTypes("GET_SUBSIDIARY_GAIN_INVOICES", operations),
    retryInvoice: new AsyncTypes("RETRY_INVOICE", operations),
    retryCorrectionInvoice: new AsyncTypes("RETRY_CORRECTION_INVOICE", operations),
    downloadArrangement: new AsyncTypes("DOWNLOAD_SUBSIDIARY_GAIN_ARRANGEMENT", operations),
    retryContainerScreenshotting: new AsyncTypes("RETRY_CONTAINER_SCREENSHOTTING", operations),
    fillSubsidiaryGainWithReservation: new AsyncTypes("FILL_SG_WITH_RESERVATION", operations),
    fillSubsidiaryGainWithReservationKit: new AsyncTypes("FILL_SG_WITH_RESERVATION_KIT", operations),
    getSgStatuses: new AsyncTypes("GET_SG_STATUSES", operations),
    getMarketingBudgetForSG: new AsyncTypes("GET_MARKETING_BUDGET_FOR_SG", operations),
};

export default types;
