import { FormField } from "components/Form";
import SearchBar from "components/SearchBar/SearchBar";
import { Field, reduxForm } from "redux-form";
import { b2bUserNames, getUserInfoWithRoles } from "store/autocomplete";
import { customerFiltersSelector, customerListSelector } from "store/customers/customerList";
import { getCustomers } from "store/customers/customerList/action";
import { useDebouncedCallback } from "use-debounce";
import { useCallback, useEffect, useMemo, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import _ from "lodash";
import { customers } from "translations";
import { usePush, useTranslation } from "utils-ts/hooks";

const commerceUsersSegments = [
    { name: customers.specialClient,             value: "commerce:SpecialClient" },
    { name: customers.vipAccount,                value: "commerce:VipAccount" },
    { name: customers.testAccount,               value: "commerce:TestAccount" },
    { name: customers.fullMembership,            value: "commerce:FullMembership" },
    { name: customers.disablePersonalization,    value: "commerce:DisablePersonalization" },
    { name: customers.disableReview,             value: "commerce:DisableReview" },
    { name: customers.disableOrderLimits,        value: "commerce:DisableOrderLimits" },
    { name: customers.disableRationing,          value: "commerce:DisableRationing" },
    { name: customers.disableTotalLimit,         value: "commerce:DisableTotalLimit" },
    { name: customers.noWaterLimits,             value: "commerce:NoWaterLimits" },
    { name: customers.noProductLimits,           value: "commerce:NoProductLimits" },
    { name: customers.friscoWorkers,             value: "commerce:FriscoWorkers" },
    { name: customers.collectiveInvoice,         value: "commerce:CollectiveInvoice" },
    { name: customers.electronicInvoice,         value: "commerce:ElectronicInvoice" },
    { name: customers.savedReservations,         value: "commerce:SavedReservations" },
    { name: customers.pushbackWindows,           value: "commerce:PushbackWindows" },
    { name: customers.disableTexts,              value: "commerce:DisableTexts" },
    { name: customers.disableTrustMate,          value: "commerce:DisableTrustMate" },
    { name: customers.allowMissingPayment,       value: "commerce:AllowMissingPayment" },
    { name: customers.disableReservationTexts,   value: "commerce:DisableReservationTexts" },
    { name: customers.disableReservationMails,   value: "commerce:DisableReservationMails" },
    { name: customers.disableGmailMarkup,        value: "commerce:DisableGmailMarkup" },
    { name: customers.DisallowUserBatches,       value: "commerce:DisallowUserBatches" },
    { name: customers.DisallowAdult,             value: "commerce:DisallowAdult" },
    { name: customers.DisableOnDeliveryPayments, value: "commerce:DisableOnDeliveryPayments" },
    { name: customers.BindWarehouseWAW,          value: "commerce:BindWarehouseWAW" },
];

const CustomerSearch = ({ handleSubmit, facets = [], pageSize, pageIndex, search }) => {
    const [isInitial, setIsInital] = useState(true);
    const dispatch = useDispatch();
    const { push } = usePush();
    const { t } = useTranslation("customers");
    useEffect(() => {
        dispatch(getUserInfoWithRoles(["BusinessSupport", "BusinessSupportManager"]));
    }, [dispatch]);
    const b2bUsers = useSelector(b2bUserNames);
    const b2bUsersWithIsActive = b2bUsers
        .sort((a, b) => b.isActive - a.isActive)
        .map((o) => {
            return { value: o.value, name: o.name + (o.isActive ? "" : " (nieaktywny)") };
        });

    const submitFun = useCallback(
        (pageIndex, pageSize) => {
            return handleSubmit(async (values) => {
                const { payload } = await dispatch(
                    getCustomers({
                        ...values,
                        pageIndex,
                        pageSize,
                        facetCount: 1000,
                        includeFacets: true,
                    })
                );

                if (_.isEmpty(values.accountManager) && _.isEmpty(values.segment) && payload.totalCount === 1) {
                    push(`/customers/${payload.items[0].id}`);
                }
            })();
        },
        [dispatch, handleSubmit]
    );

    const submit = useDebouncedCallback(() => {
        submitFun(1, pageSize);
    }, 1000);

    useEffect(() => {
        if (!isInitial) {
            submitFun(pageIndex, pageSize);
        } else {
            setIsInital(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitFun, pageIndex, pageSize]);

    const facetMap = useMemo(() => {
        const mappedFacects = facets.map((x) => [x.facet, x.values]);
        return new Map(mappedFacects);
    }, [facets]);

    return (
        <>
            <Grid
                container
                direction="column"
                alignContent="stretch"
            >
                <Field
                    autoFocus
                    stretch
                    name="search"
                    label="Wyszukaj..."
                    type="text"
                    onSearchCommit={() => submitFun(1, pageSize)}
                    value={search}
                    component={SearchBar}
                />
            </Grid>
            <Grid
                container
                direction="row"
            >
                <FormField
                    name="segment"
                    label={t(customers.segments)}
                    type="facetSearch"
                    onChange={submit}
                    onFilterChange={submit}
                    facet={facetMap.get("segment")}
                    items={commerceUsersSegments.map((i) => ({
                        ...i,
                        name: t(i.name),
                    }))}
                />
                <FormField
                    name="accountManager"
                    label={t(customers.accountManager)}
                    type="facetSearch"
                    onChange={submit}
                    onFilterChange={submit}
                    facet={facetMap.get("accountManager")}
                    items={b2bUsersWithIsActive}
                />
                <FormField
                    name="retentionManager"
                    label={t(customers.retentionManager)}
                    type="facetSearch"
                    onChange={submit}
                    onFilterChange={submit}
                    facet={facetMap.get("retentionManager")}
                    items={b2bUsersWithIsActive}
                />
            </Grid>
        </>
    );
};

export default connect((state) => {
    const filters = customerFiltersSelector(state);
    const list = customerListSelector(state);
    return {
        ...list,
        initialValues: {
            ...filters,
        },
    };
})(
    reduxForm({
        form: "customer-list",
    })(CustomerSearch)
);
