import { ArrayValidationError } from "components/Controls";
import AcceptRejectDialog from "components/Dialog/AcceptRejectDialog";
import { Addable, Erasable, FormField } from "components/Form";
import TextWithLabel from "components/Text/TextWithLabel";
import { FormSection } from "redux-form";
import { toolkitNamesSelector } from "store/autocomplete";
import { activePromotionPeriodsSelector } from "store/autocomplete";
import { useChange } from "utils/hooks";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Grid, TextField, Typography } from "@mui/material";
import moment from "moment";
import { common, vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { validators } from "./validation";

const SubsidiaryGainServices = ({
    fields,
    readOnly,
    meta,
    useDiff,
    isAfterDateTo,
    rootDateFrom,
    rootDateTo,
    invoicedAt,
    formName,
    sgCreatedOutOfReservation,
    sgValue,
}) => {
    const { t } = useTranslation(["vendor", "common"]);
    const change = useChange(formName);
    const allPromotionPeriods = useSelector(activePromotionPeriodsSelector);
    const toolkitNames = useSelector(toolkitNamesSelector);
    const [fieldsToDelete, setFieldsToDelete] = useState([]);
    const [removedReservationId, setRemovedReservationId] = useState("");
    const [rejectReason, setRejectReason] = useState("");
    const [rejectReasonsToSend, setRejectReasonsToSend] = useState([]);

    const shouldDisableDate = (date) => moment(date).isValid && !moment(date).isSameOrBefore(moment(rootDateTo));
    const shouldDisableDateTo = (date) =>
        moment(date).isValid && !(moment(date).isSameOrBefore(moment(rootDateTo)) && moment(date).isSameOrAfter(moment(rootDateFrom)));
    const promotionPeriods = allPromotionPeriods.filter((p) => !shouldDisableDate(p.dateFrom) && !shouldDisableDateTo(p.dateTo));

    const latestPromotionPeriod =
        promotionPeriods.find((p) => moment(p.dateFrom).isSameOrBefore(moment(), "days") && moment().isSameOrBefore(moment(p.dateTo), "days")) ||
        promotionPeriods.slice(-1)[0];

    const handleRemoveFields = (indexes) => {
        indexes.forEach((i) => fields.remove(i));
    };

    return (
        <>
            <ArrayValidationError {...meta} />
            <Addable
                renderCondition={!invoicedAt && !isAfterDateTo && !readOnly && !useDiff && !sgCreatedOutOfReservation}
                handleAdd={() => {
                    fields.push({
                        isDate: true,
                        dateFrom: null,
                        dateTo: null,
                    });
                }}
            >
                {fields.map((field, index) => {
                    let isAfterDateFromItem = false;
                    const { isReadOnlyService, id, costReasons, newCostReasons = [], isDate = true, dateFrom, dateTo } = fields.get(index);
                    const isReadonly = readOnly || isReadOnlyService;

                    const changeIsDate = (_, newValue) => {
                        change(`${field}.isDate`, newValue);
                        if (newValue === true) {
                            change(`${field}.promotionPeriodId`, "");
                            change(`${field}.dateFrom`, dateFrom);
                            change(`${field}.dateTo`, dateTo);
                        } else {
                            const ppId = promotionPeriods.find(
                                (pp) =>
                                    moment(dateFrom).isSameOrAfter(moment(pp.dateFrom), "days") &&
                                    moment(dateFrom).isSameOrBefore(moment(pp.dateTo), "days")
                            )?.id;
                            changePromotionPeriod(undefined, ppId || latestPromotionPeriod.id);
                        }
                    };
                    const changePromotionPeriod = (_, value) => {
                        const selectedPromotionPeriod = promotionPeriods.find((p) => p.id == value);
                        change(`${field}.promotionPeriodId`, selectedPromotionPeriod.id);
                        change(`${field}.dateFrom`, selectedPromotionPeriod.dateFrom);
                        change(`${field}.dateTo`, selectedPromotionPeriod.dateTo);
                    };

                    const inputFromPeriod = (promotionPeriod) => {
                        if (!promotionPeriod) {
                            return {
                                name: "",
                                value: "",
                                onChange: changePromotionPeriod,
                            };
                        }
                        return {
                            name: `${promotionPeriod.name} (${moment(promotionPeriod.dateFrom).format("YYYY-MM-DD")}/${moment(
                                promotionPeriod.dateTo
                            ).format("yyyy-MM-DD")})`,
                            value: promotionPeriod.id,
                            onChange: changePromotionPeriod,
                        };
                    };

                    return (
                        <Erasable
                            key={field}
                            renderCondition={!invoicedAt && !(isAfterDateFromItem && id) && !readOnly && !useDiff}
                            handleRemove={() => {
                                const allFields = fields.getAll();
                                const reservationId = allFields[index].newCostReasons.find((n) => n.reservationId != undefined)?.reservationId;
                                if (reservationId != undefined) {
                                    const indexesToDelete = allFields
                                        .map((f, index) => (f.newCostReasons.some((n) => n.reservationId == reservationId) ? index : -1))
                                        .filter((f) => f != -1);
                                    setRemovedReservationId(reservationId);
                                    setFieldsToDelete(indexesToDelete);
                                } else {
                                    fields.remove(index);
                                }
                            }}
                        >
                            <FormSection name={field}>
                                <Grid
                                    container
                                    direction="row"
                                >
                                    <TextWithLabel
                                        value={index + 1}
                                        translatedLabel={""}
                                    />

                                    <FormField
                                        name="isDate"
                                        type="boolean"
                                        label={t(`common:${common.isDate}`)}
                                        onChange={changeIsDate}
                                        readOnly={isReadonly || sgCreatedOutOfReservation}
                                        useDiff={useDiff}
                                    />

                                    {(isDate === false || (useDiff && (!isDate.current || !isDate.proposal))) && (
                                        <FormField
                                            name="promotionPeriodId"
                                            label={t(`common:${common.promotionPeriod}`)}
                                            type="select"
                                            items={(useDiff || isReadonly ? allPromotionPeriods : promotionPeriods).map((p) => {
                                                return inputFromPeriod(p);
                                            })}
                                            readOnly={isReadonly || sgCreatedOutOfReservation}
                                            hideDefaultItem={true}
                                            onChange={changePromotionPeriod}
                                            useDiff={useDiff}
                                        />
                                    )}

                                    {(isDate === true || (useDiff && (!!isDate.current || !!isDate.proposal))) && (
                                        <>
                                            <FormField
                                                useDiff={useDiff}
                                                name="dateFrom"
                                                label={t(vendor.dateFrom)}
                                                type="date"
                                                readOnly={isReadonly || sgCreatedOutOfReservation}
                                                validate={validators.serviceDateFrom}
                                                shouldDisableDate={shouldDisableDate}
                                                initialFocusedDate={rootDateFrom || moment()}
                                                maxDate={moment(rootDateTo)}
                                            />
                                            <FormField
                                                useDiff={useDiff}
                                                name="dateTo"
                                                label={t(vendor.dateTo)}
                                                type="date"
                                                readOnly={isReadonly || sgCreatedOutOfReservation}
                                                validate={validators.serviceDateTo}
                                                shouldDisableDate={shouldDisableDateTo}
                                                initialFocusedDate={rootDateFrom || moment()}
                                                minDate={moment(rootDateFrom)}
                                                maxDate={moment(rootDateTo)}
                                            />
                                        </>
                                    )}

                                    <FormField
                                        useDiff={useDiff}
                                        name="costReasons"
                                        label={t(vendor.costReason)}
                                        type="autocomplete"
                                        items={isReadonly ? costReasons : toolkitNames.filter((t) => t.isActive).map((t) => t.name)}
                                        readOnly={isReadonly || sgCreatedOutOfReservation}
                                        validate={validators.required}
                                        multiple
                                        disableCloseOnSelect
                                        onCustomChange={(_, val) => {
                                            change(`${field}.costReasons`, val);
                                            change(`${field}.newCostReasons`, [
                                                ...newCostReasons.filter((c) => val.some((v) => v == c.name)),
                                                ...val
                                                    .filter((v) => !newCostReasons.some((c) => c.name === v))
                                                    .map((v) => {
                                                        return {
                                                            name: v,
                                                        };
                                                    }),
                                            ]);
                                        }}
                                    />
                                </Grid>

                                <FormField
                                    useDiff={useDiff}
                                    stretch
                                    name="comment"
                                    label={t(vendor.comment)}
                                    type="text"
                                    readOnly={readOnly || isAfterDateTo}
                                />
                            </FormSection>
                        </Erasable>
                    );
                })}
            </Addable>
            <AcceptRejectDialog
                maxWidth={"sm"}
                title={t(`common:${common.removeItem}`)}
                open={!!fieldsToDelete.length}
                handleAccept={async () => {
                    const allFields = fields.getAll();
                    const price = allFields
                        .filter((o, i) => fieldsToDelete.find((f) => f == i) != undefined)
                        .map((o) => o.newCostReasons.reduce((a, b) => a + (b.price ?? 0), 0))
                        .reduce((a, b) => a + b, 0);
                    handleRemoveFields(fieldsToDelete.reverse());
                    change(`containerReservationIdToDelete`, removedReservationId);
                    setRejectReasonsToSend([...rejectReasonsToSend, { rejectMessage: rejectReason, reservationId: removedReservationId }]);
                    change(`rejectReasonsToSend`, [...rejectReasonsToSend, { rejectMessage: rejectReason, reservationId: removedReservationId }]);
                    change("value", sgValue - (price ?? 0));
                    setRemovedReservationId("");
                    setFieldsToDelete([]);
                    setRejectReason("");
                }}
                handleReject={() => {
                    setRemovedReservationId("");
                    setFieldsToDelete([]);
                    setRejectReason("");
                }}
                disableAccept={!rejectReason}
            >
                <Typography variant="body1">
                    {fieldsToDelete.length > 1
                        ? t(vendor.costReasonFromReservation) + fieldsToDelete.map((f) => f + 1).join(", ")
                        : t(`common:${common.areYouSure}`, { what: t(`common:${common.toRemoveItem}`) + " i ewentualne powiązane z nim kontenery" })}
                    <br />
                    {fieldsToDelete.length > 1 &&
                        t(`common:${common.areYouSure}`, { what: "usunąć wylistowane elementy i ewentualne powiązane z nimi kontenery" })}
                </Typography>
                <TextField
                    multiline
                    fullWidth
                    placeholder={t(`common:${common.reason}`)}
                    value={rejectReason}
                    onChange={(e) => setRejectReason(e.target.value)}
                    autoComplete="off"
                />
            </AcceptRejectDialog>
        </>
    );
};

export default SubsidiaryGainServices;
