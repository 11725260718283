import Button from "components/Button/Button";
import { FormField, ReadonlyContainer } from "components/Form";
import Layout from "components/Grid/Layout";
import { reduxForm } from "redux-form";
import { actions } from "store/dictonaries/contactType/form/actions";
import { useCommonTranslation, useMappedDispatch, useParams } from "utils/hooks";
import { notEmptyValidator } from "utils/validators/basic";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { usePush } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { contactTypesFormSelector } from "./selectors";

const validators = {
    contactTypeName: (value) => notEmptyValidator(value).validate(),
};

const ContactTypeForm = ({ handleSubmit, isSubbmitRequested, isSystem, contactTypeName }) => {
    const { id } = useParams();
    const { initForm, createOrUpdate } = useMappedDispatch(actions);
    const { replace } = usePush();
    useEffect(() => {
        initForm(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    const { t, common } = useCommonTranslation();
    const submit = async (values) => {
        const { payload } = await createOrUpdate(values, id);
        if (!id) {
            replace(Paths.Dictionaries.ContactTypeForm, { id: payload.id });
        }
    };

    return (
        <form onSubmit={handleSubmit((values) => submit(values))}>
            <Layout
                main
                headerText={t(common.contactType, {
                    id: id || t(common.newItem),
                })}
                navigationProps={{
                    backProps: { path: Paths.Dictionaries.ContactTypesList },
                }}
                customTitle={id && contactTypeName && `${t(common.contactType)} - ${contactTypeName}`}
            >
                <ReadonlyContainer showContainer={isSystem} />
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <FormField
                        name={"contactTypeName"}
                        type={"text"}
                        label={t(common.name)}
                        validate={validators.contactTypeName}
                        readonly={isSystem}
                    />
                    <FormField
                        name={"isActive"}
                        type={"boolean"}
                        label={t(common.isActive)}
                        readonly={isSystem}
                    />
                </Grid>
            </Layout>

            <Button
                actionType="accept"
                type="submit"
                fullWidth
                loading={isSubbmitRequested}
                disabled={isSystem}
            >
                {t(common.save)}
            </Button>
        </form>
    );
};

const formName = "contact-types-form";

const stateToProps = (state) => {
    const { isSubbmitRequested, isSystem, ...form } = contactTypesFormSelector(state);
    return {
        isSubbmitRequested,
        isSystem,
        contactTypeName: form.contactTypeName,
        initialValues: {
            ...form,
            isActive: form.isActive !== undefined ? form.isActive : true,
        },
    };
};

export default connect(stateToProps)(
    reduxForm({
        form: formName,
        enableReinitialize: true,
    })(ContactTypeForm)
);
