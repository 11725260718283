import { DeliveryCosts, DeliveryCostsForm, DeliveryWindows } from "views/calendar";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import { B2BAndSupportDepartment, ECommerceDepartment, LogisticianDepartment, MarketingDepartment, TradeMarketingDepartment } from "routing-ts/roles";

export const Calendar: PrivateRoute[] = [
    new PrivateRoute(
        Paths.Calendar.DeliveryWindows,
        [
            "Analyst",
            "AXUser",
            "Management",
            ...B2BAndSupportDepartment,
            ...ECommerceDepartment.GetRoles(),
            ...LogisticianDepartment.GetRoles(),
            ...MarketingDepartment.GetRoles(),
            ...TradeMarketingDepartment.GetRoles(),
        ],
        DeliveryWindows,
        "deliveryWindows",
        "schedule"
    ),
    new PrivateRoute(Paths.Calendar.DeliveryCostsList, [], DeliveryCosts),
    new PrivateRoute(Paths.Calendar.DeliveryCostForm, [], DeliveryCostsForm),
];
