import { Button } from "components/Button";
import { makeStyles } from "tss-react/mui";
import { useCommonTranslation } from "utils/hooks";
import { Fragment } from "react";
import { Divider, Grid } from "@mui/material";

const useStyles = makeStyles()((theme) => ({
    button: {
        marginLeft: "auto",
    },
    divider: {
        marginTop: theme.spacing(2),
    },
}));

const Adable = ({ renderCondition = true, useDivider = true, handleAdd, children, className, readOnly = false }) => {
    const { classes } = useStyles();
    const { t, common } = useCommonTranslation();

    if (!renderCondition) {
        return children;
    }

    return (
        <Grid
            className={className}
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
        >
            <Grid item>
                {children.map((c, i) => {
                    return (
                        <Fragment key={i}>
                            {c}
                            {useDivider && children.length !== i + 1 ? (
                                <Divider
                                    className={classes.divider}
                                    variant="fullWidth"
                                />
                            ) : undefined}
                        </Fragment>
                    );
                })}
            </Grid>
            <Grid
                item
                className={classes.button}
            >
                {!readOnly && (
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleAdd}
                    >
                        {t(common.add)}
                    </Button>
                )}
            </Grid>
        </Grid>
    );
};
export default Adable;
