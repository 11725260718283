import { Preloader } from "components/Preloader";
import { Tabs } from "components/Tabs";
import roles from "consts/roles";
import { getUserInfoWithRoles } from "store/autocomplete/actions";
import { getMarketingBudget, marketingBudgetsFormSelector } from "store/vendors/marketingBudget";
import { useCommonTranslation, useParams } from "utils/hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useConfig } from "context";
import VendorLayout from "views/vendors/components/VendorLayout";
import CatmanBudgetChanges from "./CatmanBudgetChanges";
import MarketingBudgetForm from "./MarketingBudgetForm";

const MarketingBudget = () => {
    const { vendorId, marketingBudgetId } = useParams();
    const { t, common } = useCommonTranslation();
    const { form } = useSelector(marketingBudgetsFormSelector);
    const dispatch = useDispatch();
    const { isNotProduction } = useConfig();
    const isLoading = form?.isLoading;
    const rolesToRequest = [roles.Catman, roles.TradeMarketing, roles.TradeMarketingManager];

    let tabs = ["form"];
    if (marketingBudgetId) {
        tabs.push("catmanBudgetChange");
    }

    if (isNotProduction) {
        rolesToRequest.push(roles.Administrator);
    }

    useEffect(() => {
        dispatch(getUserInfoWithRoles(rolesToRequest));
        dispatch(getMarketingBudget(vendorId, marketingBudgetId));
    }, [dispatch, marketingBudgetId]);

    return (
        <VendorLayout
            headerText={t(common.marketingBudgetForm, { id: marketingBudgetId })}
            vendorId={vendorId}
            isLoading={isLoading}
            tabIndex={7}
        >
            {isLoading ? (
                <Preloader />
            ) : (
                <Tabs tableLabels={tabs}>
                    <MarketingBudgetForm />
                    <CatmanBudgetChanges />
                </Tabs>
            )}
        </VendorLayout>
    );
};

export default MarketingBudget;
