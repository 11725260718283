import defaultListState from "store/defaultListState";
import { combineReducers } from "redux";
import types from "./types";

const listInitialState = defaultListState;

const formInitialState = {
    isLoading: false,
    isSubbmitRequested: false,
};

const list = (state = listInitialState, { type, payload }) => {
    switch (type) {
        case types.findEmails.request:
            return { ...state, isLoading: true };
        case types.findEmails.success:
            return { ...state, isLoading: false, ...payload };
        case types.findEmails.failure:
            return { ...state, isLoading: false };
        default:
            return state;
    }
};

const form = (state = formInitialState, { type, payload }) => {
    switch (type) {
        case types.formInit:
            return { ...formInitialState };
        case types.getEmail.request:
            return { ...state, isLoading: true };
        case types.getEmail.success:
            return { ...state, isLoading: false, ...payload };
        case types.getEmail.failure:
            return { ...state, isLoading: false };
        case types.createEmail.request:
            return { ...state, isSubbmitRequested: true };
        case types.createEmail.success:
            return { ...state, ...payload, isSubbmitRequested: false };
        case types.createEmail.failure:
            return { ...state, isSubbmitRequested: false };
        case types.getRecipientsFromFile.success:
            return { ...state, recipientsReadResult: payload };
        default:
            return state;
    }
};

export const emails = combineReducers({
    list,
    form,
});
