import { MenuButton } from "components/Button";
import { useModalState } from "components/Dialog";
import { invoice, releaseNotice } from "utils/dictionaryTypes/documentType";
import { merchants } from "utils/dictionaryTypes/index";
import { withAuthorizedDisplay } from "utils/hoc";
import { useCommonTranslation } from "utils/hooks";
import { splitOrderId } from "utils/splitIds";
import React, { Fragment } from "react";
import { Clear, FileCopy, HighlightOff } from "@mui/icons-material";
import { Grid, ListItemIcon, MenuItem } from "@mui/material";
import moment from "moment";
import { useUser } from "context";
import _ from "lodash";
import { baseCustomerComplaintRoles, baseCustomerRoles } from "routing-ts/Routes/CustomerRoles";
import AbortOrderModal from "./AbortOrderModal";

const getInvoice = (customerId, orderNumber, merchant, document, getOrderDocument) => {
    const documentNumber = document && document.documentNumber;
    const documentId = document && document.documentId;

    return () => {
        getOrderDocument(customerId, orderNumber, documentId, documentNumber, merchant);
    };
};

const ComplaintMenu = ({ items, createComplaint, orderDocuments, orderNumber, warehouse }) => {
    const { t, common } = useCommonTranslation();

    const getOpenComplaint = (merchant) => items.filter((x) => x.merchant === merchant).find((x) => !x.isClosed);

    const getComplaintText = (complaint, merchant, documentType) => {
        const resource = complaint ? common.complaintEdit : common.complaintCreate;

        return t(resource, { merchant, documentType });
    };
    const canCorrect = (document) => !_.isEmpty(document) && warehouse !== "OMU";

    const documents = orderDocuments || [];

    const invoiceFrisco = documents.find((d) => d.documentType === invoice && d.merchant === merchants.Frisco);
    const releaseNoticeFrisco = documents.find((d) => d.documentType === releaseNotice && d.merchant === merchants.Frisco);
    const invoiceF24 = documents.find((d) => d.documentType === invoice && d.merchant === merchants.F24);
    const releaseNoticeF24 = documents.find((d) => d.documentType === releaseNotice && d.merchant === merchants.F24);
    const f24Complaint = getOpenComplaint(merchants.F24);
    const friscoComplaint = getOpenComplaint(merchants.Frisco);
    const toComplaint = (complaint, documentId) => {
        return createComplaint(orderNumber, documentId, Boolean(complaint) ? complaint.complaintId : undefined);
    };

    return (
        <MenuButton title={t(common.complaintsDataTab)}>
            <MenuItem
                disabled={!canCorrect(invoiceFrisco)}
                onClick={() => toComplaint(friscoComplaint, invoiceFrisco.documentId)}
            >
                {getComplaintText(friscoComplaint, merchants.Frisco, "FV")}
            </MenuItem>
            <MenuItem
                disabled={!canCorrect(invoiceF24)}
                onClick={() => toComplaint(f24Complaint, invoiceF24.documentId)}
            >
                {getComplaintText(f24Complaint, merchants.F24, "FV")}
            </MenuItem>
            <MenuItem
                disabled={canCorrect(invoiceFrisco) || !canCorrect(releaseNoticeFrisco)}
                onClick={() => toComplaint(friscoComplaint, releaseNoticeFrisco.documentId)}
            >
                {getComplaintText(friscoComplaint, merchants.Frisco, "WZ")}
            </MenuItem>
            <MenuItem
                disabled={canCorrect(invoiceFrisco) || !canCorrect(releaseNoticeF24)}
                onClick={() => toComplaint(f24Complaint, releaseNoticeF24.documentId)}
            >
                {getComplaintText(friscoComplaint, merchants.F24, "WZ")}
            </MenuItem>
        </MenuButton>
    );
};

const InvoiceMenu = ({ customerId, orderNumber, getOrderDocument, orderDocuments = [] }) => {
    const { t, common } = useCommonTranslation();
    const invoiceFrisco = orderDocuments.find((d) => d.documentType === invoice && d.merchant === merchants.Frisco && d.blobName);
    const invoiceF24 = orderDocuments.find((d) => d.documentType === invoice && d.merchant === merchants.F24 && d.blobName);
    return (
        <MenuButton
            title={t(common.invoices)}
            disabled={!(Boolean(invoiceFrisco) || Boolean(invoiceF24))}
        >
            <MenuItem
                onClick={getInvoice(customerId, orderNumber, merchants.Frisco, invoiceFrisco, getOrderDocument)}
                disabled={!Boolean(invoiceFrisco)}
            >
                <ListItemIcon>
                    <FileCopy />
                </ListItemIcon>
                {t(common.invoice, { merchant: "Frisco" })}
            </MenuItem>
            <MenuItem
                onClick={getInvoice(customerId, orderNumber, merchants.F24, invoiceF24, getOrderDocument)}
                disabled={!Boolean(invoiceF24)}
            >
                <ListItemIcon>
                    <FileCopy />
                </ListItemIcon>
                {t(common.invoice, { merchant: "F24" })}
            </MenuItem>
        </MenuButton>
    );
};

const OrderActionsMenu = ({ order, cancelOrAbortOrder, operationsOrderId }) => {
    const { t, common } = useCommonTranslation();
    const [cancelMethod, setCancelMethod] = React.useState(null);
    const { modalState, handleOpen, handleClose } = useModalState();
    const { reservation = {}, id: orderId } = order || {};
    const { deliveryWindow = {} } = reservation;
    const { closesAt, startsAt } = deliveryWindow;
    const { profile } = useUser();

    const canCancel = moment(closesAt).isAfter(moment()) && order.status === "Placed";
    const canAbort =
        order.status === "Invoiced" ||
        order.status === "Archived" ||
        (order.status === "Transferred" && moment(startsAt).startOf("day") < moment().startOf("day"));

    const { customerId, orderNumber } = splitOrderId(orderId || "");

    return (
        <Fragment>
            <MenuButton title={t(common.orderActions)}>
                <MenuItem
                    onClick={() => {
                        handleOpen();
                        setCancelMethod("cancel");
                    }}
                    disabled={!canCancel}
                >
                    <ListItemIcon>
                        <Clear />
                    </ListItemIcon>
                    {t(common.cancelOrder)}
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleOpen();
                        setCancelMethod("cancelSilently");
                    }}
                    disabled={!canCancel}
                >
                    <ListItemIcon>
                        <Clear />
                    </ListItemIcon>
                    {t(common.cancelOrderSilently)}
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleOpen();
                        setCancelMethod("abort");
                    }}
                    disabled={!canAbort}
                >
                    <ListItemIcon>
                        <HighlightOff />
                    </ListItemIcon>
                    {t(common.abortOrder)}
                </MenuItem>
            </MenuButton>
            <AbortOrderModal
                open={modalState}
                cancelMethod={cancelMethod}
                handleAccept={async (reason) => {
                    await cancelOrAbortOrder(customerId, orderNumber, cancelMethod, operationsOrderId, profile?.email, reason);
                    handleClose();
                }}
                handleReject={() => {
                    setCancelMethod(null);
                    handleClose();
                }}
            />
        </Fragment>
    );
};

const OrderTabActions = ({ order, orderDocuments = {}, complaints: { items }, cancelOrAbortOrder, isAdmin, id, operationsOrderId, ...props }) => {
    const { isInAnyRoleOrAdmin } = useUser();
    const isInBaseRoles = isInAnyRoleOrAdmin(baseCustomerRoles);
    const isInBaseComplaintRoles = isInAnyRoleOrAdmin(baseCustomerComplaintRoles);

    return (
        <Grid
            container
            direction="row"
            justifyContent={"flex-end"}
            alignItems="center"
        >
            <Grid item>
                <InvoiceMenu
                    orderDocuments={orderDocuments}
                    {...props}
                />
            </Grid>
            {isInBaseComplaintRoles && (
                <Grid item>
                    <ComplaintMenu
                        {...props}
                        isAdmin={isAdmin}
                        items={items}
                        orderDocuments={orderDocuments}
                    />
                </Grid>
            )}
            {isInBaseRoles && (
                <Grid>
                    <OrderActionsMenu
                        id={id}
                        order={order}
                        cancelOrAbortOrder={cancelOrAbortOrder}
                        operationsOrderId={operationsOrderId}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default withAuthorizedDisplay(OrderTabActions);
