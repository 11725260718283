import { GetRequest, PutRequest, asAsyncFormTypes, get, put } from "utils/extensions";
import { downloadPdfFile } from "utils/rsaa/downloadFileAction";
import fetchFile from "utils/rsaa/fetchFileAction";
import types from "./types";

const rootEndpoint = "api/archive-documents";
const endpoint = (documentId) => `${rootEndpoint}/${documentId}`;

function wait(n) {
    return new Promise((resolve) => setTimeout(resolve, n));
}

function downloadItemWithRetryAndTimeout(documentId, dispatch, retry, rejectMessage) {
    if (retry < 0) {
        return;
    }

    return dispatch(tryGetDocument(documentId)).then((response) => {
        if (
            (response.payload.status >= 400 && response.payload.status !== 403) ||
            (!!response.payload.message && response.payload.message?.severity.toLowerCase() !== "error")
        ) {
            wait(1500).then(() => downloadItemWithRetryAndTimeout(documentId, dispatch, retry - 1, response));
        }

        return Promise.resolve(response);
    });
}

const tryGetDocument = (documentId) => async (dispatch) => {
    return dispatch(get(new GetRequest(endpoint(documentId), types.getDocument.all)));
};

export const getOriginalFile = (documentId) => async (dispatch) => {
    return await dispatch(fetchFile(endpoint(documentId) + "/original-file", null, types.getOriginalFile.all));
};

export const downloadOriginalFile = (documentId, fileName) => async (dispatch) => {
    await dispatch(downloadPdfFile(endpoint(documentId) + "/original-file", types.downloadOriginalFile.all, fileName || `Dokument_${documentId}`));

    return Promise.resolve();
};

export const getDocument = (documentId) => (dispatch) => {
    downloadItemWithRetryAndTimeout(documentId, dispatch, 10).catch((_) => {
        console.error("Promise error");
    });
};

export const updateDocumentPermissions = (documentId, values) => (dispatch) => {
    dispatch(
        put(
            new PutRequest(`${endpoint(documentId)}/update-permissions`, types.updatePermissions.all, {
                body: values,
            })
        )
    );
};

export const updateDocument = (documentId, values) => (dispatch) => {
    dispatch(
        put(
            new PutRequest(`${endpoint(documentId)}`, types.updateDocument.all, {
                body: values,
            })
        )
    );
};

export const getDocumentPermissions = (documentId) => (dispatch) => {
    dispatch(get(new GetRequest(endpoint(documentId) + "/permissions", types.getDocumentPermissions.all)));
};

export const getLinkedDocuments = (documentId) => async (dispatch) => {
    dispatch(get(new GetRequest(endpoint(documentId) + "/linked-documents", types.getLinkedDocuments.all)));
};

export const findArchivedDocuments = (query) => async (dispatch) => {
    dispatch(
        get(
            new GetRequest(rootEndpoint, types.linkedDocumentsFindArchivedDocuments.all, {
                queryParams: query,
            })
        )
    );
};

export const setFilters = (filters) => (dispatch) => {
    dispatch({ type: types.linkedDocumentsSetFilters, payload: filters });
};

export const addLinkedDocument = (documentId, linkedDocumentId) => async (dispatch) => {
    dispatch(
        put(
            new PutRequest(endpoint(documentId) + "/add-linked-document", asAsyncFormTypes(types.addLinkedDocument.all), {
                body: { documentId: documentId, linkedDocumentId: linkedDocumentId },
            })
        )
    );
};

export const removeLinkedDocument = (documentId, linkedDocumentId) => async (dispatch) => {
    dispatch(
        put(
            new PutRequest(endpoint(documentId) + "/remove-linked-document", asAsyncFormTypes(types.removeLinkedDocument.all), {
                body: { documentId: documentId, linkedDocumentId: linkedDocumentId },
            })
        )
    );
};
