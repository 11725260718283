import { makeStyles } from "tss-react/mui";
import React from "react";
import { Cancel as CancelIcon, CheckCircle as OkIcon } from "@mui/icons-material";
import { Theme } from "@mui/material";

const useStyles = makeStyles<{ value: boolean }>()((theme: Theme, { value }) => ({
    icon: {
        color: value ? theme.palette.success.main : theme.palette.error.main,
    },
}));

const BooleanStatusCell: React.FC<{ value: boolean | unknown }> = ({ value }) => {
    const { classes } = useStyles({ value: value as boolean });

    if (value) {
        return <OkIcon className={classes.icon} />;
    } else {
        return <CancelIcon className={classes.icon} />;
    }
};

export default BooleanStatusCell;
