import { ChangeReason } from "components/Controls/ChangeReason";
import { AcceptRejectDialog } from "components/Dialog";
import { reduxForm } from "redux-form";
import { removeSubsidiaryGain } from "store/vendors/subsidiaryGains";
import { useCommonTranslation } from "utils/hooks";
import { useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import moment from "moment";

const SubsidiaryGainRemoveDialog = ({ subsidiaryGainId, vendorId, getSubsidiaryGains, handleClose, dateFrom, handleSubmit, reservationKitId }) => {
    const { t, common } = useCommonTranslation();

    const dispatch = useDispatch();
    return (
        <AcceptRejectDialog
            title={t(common.remove)}
            maxWidth={"sm"}
            open={Boolean(subsidiaryGainId)}
            isSubmit
            handleAccept={handleSubmit(async (values) => {
                const { type } = await dispatch(removeSubsidiaryGain(vendorId, subsidiaryGainId, values.changeReason));
                if (type.endsWith("SUCCESS")) {
                    dispatch(getSubsidiaryGains());
                }
                handleClose();
            })}
            handleReject={() => {
                handleClose();
            }}
        >
            <Typography variant="body1">
                {t(common.areYouSure, {
                    what: t(common.toRemove),
                })}
            </Typography>
            <ChangeReason forceRender={dateFrom && (dateFrom.isBefore(moment(), "day") || !!reservationKitId)} />
        </AcceptRejectDialog>
    );
};

export default reduxForm({
    form: "SubsidiaryGainRemoveDialog",
    enableReinitialize: true,
})(SubsidiaryGainRemoveDialog);
