import { createSelector } from "reselect";

const root = (state) => ({ decisionList: state.decisionList, vendors: state.autocomplete.vendors });

export const contractsDecisionListSelector = createSelector(root, ({ decisionList, vendors }) => {
    return {
        ...decisionList.contracts,
        items: decisionList.contracts.items.map((x) => ({
            ...x,
            vendorId: x?.vendorId.split("_")[0],
            vendorName: vendors.find((v) => v.vendorId === x.vendorId)?.name,
        })),
    };
});

export const subsidiaryGainsDecisionListSelector = createSelector(root, ({ decisionList, vendors }) => {
    return {
        ...decisionList.subsidiaryGains,
        items: decisionList.subsidiaryGains.items.map((x) => ({
            ...x,
            vendorId: x?.vendorId.split("_")[0],
            vendorName: vendors.find((v) => v.vendorId === x.vendorId)?.name,
            email: x?.responsibleUser?.email,
        })),
    };
});

export const refundationsDecisionListSelector = createSelector(root, ({ decisionList, vendors }) => {
    return {
        ...decisionList.refundations,
        items: decisionList.refundations.items.map((x) => ({
            ...x,
            vendorId: x?.vendorId.split("_")[0],
            vendorName: vendors.find((v) => v.vendorId === x.vendorId)?.name,
            email: x?.user?.email,
        })),
    };
});
