import { actions } from "store/vouchers/form/actions";
import { toObject } from "utils/extensions/arrayExtensions";
import { mapVoucherDefinition } from "utils/formExtensions";
import { useCopyRouterState, useMappedDispatch, useParams } from "utils/hooks";
import React, { useEffect } from "react";
import { usePush } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { Voucher, VoucherGenerator } from "./components";

const VoucherForm = (props) => {
    const { type } = useParams();
    const { replace } = usePush();
    const { id, isCopy } = useCopyRouterState();
    const { initForm, createOrUpdate } = useMappedDispatch(actions);
    useEffect(() => {
        initForm(id, type, isCopy);
    }, [id, initForm, isCopy, type]);
    const handleSubmit = async (values) => {
        const { contentData, definition, definitions, user, ...form } = values;
        let voucher = {};
        if (type === "generators") {
            voucher = {
                ...form,
                definitions: (definitions || []).map((v) => {
                    return {
                        ...v,
                        definition: mapVoucherDefinition(v.definition),
                        validForDays: v.validForDays ? Number(v.validForDays) : null,
                        contentData: v.contentData ? toObject(v.contentData) : null,
                    };
                }),
                contentData: contentData ? toObject(contentData) : null,
            };

            if (user && (user.orderRequirements || user.haveSegmentsConstraint)) {
                const { orderRequirements, orders, haveSegmentsConstraint, segments } = user;
                voucher.user = {
                    orderRequirements: orderRequirements,
                    haveSegmentsConstraint: haveSegmentsConstraint,
                };

                if (orderRequirements) {
                    voucher.user.orders = orders;
                }
                if (haveSegmentsConstraint) {
                    voucher.user.segments = segments;
                }
            }
        } else {
            voucher = {
                ...form,
                definition: mapVoucherDefinition(definition),
                contentData: contentData ? toObject(contentData) : null,
            };

            if (voucher && voucher.definition && voucher.definition.voucherType === "amount_voucher") {
                if (voucher.definition.merchant === "frs") {
                    voucher.definition.paymentAmount = {
                        FRS: Number(voucher.definition.paymentAmount.FRS),
                    };
                } else if (voucher.definition.merchant === "f24") {
                    voucher.definition.paymentAmount = {
                        F24: Number(voucher.definition.paymentAmount.F24),
                    };
                }
            }
        }

        const { payload } = await createOrUpdate(voucher, !isCopy && id, type);

        if ((!isCopy && !id) || isCopy) {
            replace(Paths.VoucherForm, { type, id: payload.id });
        }
    };

    if (type === "generators")
        return (
            <VoucherGenerator
                {...props}
                onSubmit={handleSubmit}
                type={type}
            />
        );
    else
        return (
            <Voucher
                onSubmit={handleSubmit}
                type={type}
            />
        );
};

export default VoucherForm;
