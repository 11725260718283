import { tValidation, validation } from "utils-ts/validations/translation";
import Validator, { inRange, notEmptyValidator } from "./basic";
import phoneNumberValidator from "./phoneNumberValidator";
import postCodeValidator from "./postCodeValidator";
import vatinValidator from "./vatinValidator";

export default function validateBillingAddress(billingAdreess = {}) {
    const { companyName, vatin, postcode, city, street, buildingNumber, apartmentNumber, phoneNumber } = billingAdreess;
    const { country, ...billingAdressData } = billingAdreess;
    const errors = {};

    const isAny = !Object.values(billingAdressData).every((x) => x === null || x === "" || x === undefined);

    errors["companyName"] = notEmptyValidator(companyName).must(inRange(0, 100)).allWhen(isAny).validate();

    errors["vatin"] =
        country === "pl"
            ? vatinValidator(vatin).allWhen(isAny).validate()
            : notEmptyValidator(vatin)
                  .must(inRange(0, 20), tValidation(validation.charCount, { min: 0, max: 20 }))
                  .allWhen(isAny)
                  .validate();
    errors["country"] = notEmptyValidator(country).allWhen(isAny).validate();
    errors["postcode"] = postCodeValidator(postcode, country).allWhen(isAny).validate();
    errors["city"] = notEmptyValidator(city)
        .must(inRange(0, 100), tValidation(validation.charCount, { min: 0, max: 100 }))
        .allWhen(isAny)
        .validate();
    errors["street"] = notEmptyValidator(street)
        .must(inRange(0, 100), tValidation(validation.charCount, { min: 0, max: 100 }))
        .allWhen(isAny)
        .validate();
    errors["buildingNumber"] = notEmptyValidator(buildingNumber)
        .must(inRange(0, 100), tValidation(validation.charCount, { min: 0, max: 100 }))
        .allWhen(Boolean(buildingNumber))
        .validate();
    errors["apartmentNumber"] = new Validator(apartmentNumber)
        .must(inRange(0, 10), tValidation(validation.charCount, { min: 0, max: 10 }))
        .allWhen(isAny)
        .validate();
    errors["phoneNumber"] = phoneNumberValidator(phoneNumber).allWhen(isAny).validate();

    return errors;
}
