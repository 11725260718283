import { useDebouncedCallback } from "use-debounce";
import useWidth from "utils/hooks/useWidth";
import { useEffect, useState } from "react";
import { ReactSortable } from "react-sortablejs";
import { TextField } from "@mui/material";
import _ from "lodash";

const columnsWidthMap = new Map([
    ["xs", "100%"],
    ["sm", "50%"],
    ["md", "33%"],
    ["lg", "25%"],
    ["xl", "20%"],
]);

const DragAndDrop = ({
    label,
    input: { value, ...input },
    meta: { touched, invalid, error },
    itemRenderer,
    onAdd,
    additionalValueCheck,
    splitValues = true,
}) => {
    const [items, setItems] = useState(value);
    useEffect(() => {
        setItems(value);
    }, [value]);

    const [inputValue, setInputValue] = useState("");

    function onChange(items) {
        setItems(items);
        input.onChange(items);
    }

    const handleRemove = (v) => {
        let newValues = items.filter((x) => x !== v);
        if (!newValues?.length) {
            newValues = null;
        }
        setItems(newValues);
        input.onChange(newValues);
    };

    const setFieldValue = useDebouncedCallback((event) => {
        const {
            target: { value: eventValue },
        } = event;
        setInputValue("");
        const newValues = _(String(eventValue))
            .split(splitValues ? /[,;\s]/ : /$/)
            .filter((x) => Boolean(x) && (!additionalValueCheck || additionalValueCheck(x)))
            .map((x) => String(x))
            .uniq()
            .filter((x) => (value || []).every((y) => y !== x))
            .value();
        if (eventValue && newValues && newValues.length) {
            if (onAdd) onAdd(newValues);
            input.onChange([...value, ...newValues]);
            setItems([...value, ...newValues]);
        }
    }, 750);

    const setInput = (event) => {
        const {
            target: { value: val },
        } = event;
        setInputValue(val);
    };

    const width = useWidth();
    const columnWidth = columnsWidthMap.get(width);

    return (
        <>
            <ReactSortable
                list={(items || []).map((i) => {
                    return { id: i, name: i };
                })}
                setList={(items) => onChange(items.map((i) => i.id))}
            >
                {(items || []).map((item, index) => (
                    <div
                        style={{ width: columnWidth, display: "inline-flex" }}
                        key={index}
                    >
                        {itemRenderer(item, handleRemove)}
                    </div>
                ))}
            </ReactSortable>

            <TextField
                variant="standard"
                fullWidth
                label={label}
                placeholder={label}
                onChange={(event) => {
                    event.persist();
                    setInput(event);
                    setFieldValue(event);
                }}
                onBlur={(event) => {
                    event.persist();
                    setInput(event);
                    setFieldValue(event);
                }}
                value={inputValue}
                error={touched && invalid}
                helperText={touched && error}
            />
        </>
    );
};

export default DragAndDrop;
