import { manager, operations } from "store/actionTypePrefixes";
import { AsyncTypes, asAsyncFormTypes, createAction } from "utils/extensions";

const types = {
    findAccountingPeriods: new AsyncTypes("FIND_ACCOUTING_PERIODS", operations),
    updateQuery: createAction("ACCOUNTING_PERIODS_CHANGE_PAGE", manager),
    formInit: createAction("FORM_INIT_ACCOUNTING_PERIOD", manager),
    getAccountingPeriod: new AsyncTypes("GET_ACCOUNTING_PERIOD", operations),
    updateOverides: new AsyncTypes("UPDATE_ACCOUNTING_PERIOD_OVERRIDES", operations, [asAsyncFormTypes]),
    findAllAccountingPeriods: new AsyncTypes("FIND_ALL_ACCOUNTING_PERIODS", operations),
};

export default types;
