import { makeStyles } from "tss-react/mui";
import { useDebouncedCallback } from "use-debounce";
import { useState } from "react";
import Clear from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Chip, Grid, Paper, Tooltip } from "@mui/material";
import moment from "moment";
import { SearchFacet } from "Shared";
import { Item } from "control-types";
import { TranslationFunction } from "translations/Translation";
import { useTranslation } from "utils-ts/hooks";
import { Autocomplete, Button, Checkbox, DatePicker, DateTimePicker, IconButton, ListInput, Select, Switch, TextField } from "components-ts/controls";
import { Facet } from "components-ts/tables/table";
import { Spacing } from "components-ts/view";
import SearchBar from "./SearchBar";

type FacetsParams<T extends Record<string, unknown>> = {
    facets: Facet<T>[];
    initialParams?: T;
    updateQueryParams: (property: string, value: unknown) => void;
    searchFacets: SearchFacet[];
    orientation?: "horizontal" | "vertical";
    searchBar?: boolean;
    clearQueryParams?: () => void;
};

const useStyles = makeStyles()(() => ({
    item: {
        padding: "1px 5px 4px 5px",
        width: "280px",
    },
}));

function HorizontalFacets<T extends Record<string, unknown>>({
    facets,
    updateFacet,
    facetValues,
    children,
    t,
    searchBar,
    clearQueryParams,
}: {
    facets: Facet<T>[];
    facetValues: T;
    updateFacet: (property: keyof T, value: unknown) => void;
    children: React.ReactNode[];
    t: TranslationFunction;
    searchBar: boolean;
    clearQueryParams?: () => void;
}) {
    const { classes } = useStyles();
    const [expanded, setExpanded] = useState<boolean>(true);

    return (
        <Spacing spacing={0.5}>
            {expanded ? (
                <>
                    {searchBar && (
                        <Grid
                            container
                            justifyContent="flex-start"
                        >
                            <SearchBar
                                initialValue={facetValues["search"]?.toString() || ""}
                                onSearchCommit={(newValue) => {
                                    updateFacet("search", newValue);
                                }}
                            />
                        </Grid>
                    )}
                    <Grid
                        container
                        direction="row"
                        justify-content="flex-start"
                        alignItems="flex-start"
                    >
                        {children.map((c, index) => (
                            <Grid
                                key={index}
                                className={classes.item}
                                item
                            >
                                {c}
                            </Grid>
                        ))}
                        {clearQueryParams && (
                            <Grid
                                className={classes.item}
                                margin={"auto 0"}
                            >
                                <Button
                                    startIcon={<Clear />}
                                    variant="outlined"
                                    onClick={clearQueryParams}
                                    label={"clearFilters"}
                                />
                            </Grid>
                        )}
                    </Grid>
                </>
            ) : (
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{ minHeight: "45px" }}
                >
                    <Grid
                        item
                        xs={facets.length > 1 ? 11 : 12}
                    >
                        Aktywne filtry:&nbsp;
                        {facets
                            .filter((f) => {
                                const facetValue = facetValues[f.property];
                                if (Array.isArray(facetValue)) {
                                    return facetValue !== undefined && facetValue.length > 0;
                                } else {
                                    return facetValue !== undefined;
                                }
                            })
                            .map((f) => {
                                const facetValue = facetValues[f.property];
                                if (Array.isArray(facetValue)) {
                                    return `${t(f.label)}: ${facetValue.join(", ")}`;
                                } else if (moment.isDate(facetValue)) {
                                    return `${t(f.label)}: ${facetValue.getFullYear()}-${facetValue.getMonth() + 1}-${facetValue.getDay()}`;
                                } else if (moment.isMoment(facetValue)) {
                                    return `${t(f.label)}: ${facetValue.format("YYYY-MM-DD HH:mm:ss")}`;
                                } else {
                                    return `${t(f.label)}: ${facetValue}`;
                                }
                            })
                            .join("; ")}
                    </Grid>
                </Grid>
            )}
            {facets.length > 1 && (
                <div style={{ position: "absolute", right: "5px", top: "5px" }}>
                    <IconButton
                        onClick={() => setExpanded(!expanded)}
                        icon={expanded ? "expand_less" : "expand_more"}
                    />
                </div>
            )}
        </Spacing>
    );
}

function VerticalFacets({ children }: { children: React.ReactNode[] }) {
    return (
        <Spacing spacing={1}>
            {children.map((c, index) => (
                <div key={index}>{c}</div>
            ))}
        </Spacing>
    );
}

const Facets = <T extends Record<string, unknown>>({
    facets,
    initialParams,
    updateQueryParams,
    searchFacets,
    orientation,
    searchBar = true,
    clearQueryParams,
}: FacetsParams<T>) => {
    const [facetValues, setFacetValue] = useState<T>((initialParams || { includeFacets: true }) as T);
    const [facetsHidden, setFacetsHidden] = useState<boolean[]>(facets.map(() => false));
    const updateQueryParamsFacet = useDebouncedCallback(updateQueryParams, 500);
    const updateFacet = (property: keyof T, value: unknown, multiple: boolean = false) => {
        const newValue = value === "" ? undefined : value;
        setFacetValue({
            ...facetValues,
            [property.toString()]: newValue,
            includeFacets: true,
        });
        updateQueryParamsFacet(
            property.toString(),
            multiple ? (newValue as string)?.split(/(?:,| |\.|\n)+/).filter((o) => o != "") ?? newValue : newValue
        );
    };
    const { t } = useTranslation();

    const handleClearQueryParams = () => {
        setFacetValue((prevState) => {
            const resetState: T = { ...prevState, includeFacets: true };
            Object.keys(prevState).forEach((key) => {
                if (key !== "includeFacets") {
                    (resetState as Record<string, unknown>)[key] = undefined;
                }
            });
            return resetState;
        });
        clearQueryParams && clearQueryParams();
    };

    const facetsComponents = facets
        .filter((f) => (orientation === "horizontal" ? f.as !== "searchBar" : true))
        .map((facet, index) => {
            let component = <></>;
            const clearFilterValue = () => {
                setFacetValue({
                    ...facetValues,
                    [facet.property.toString()]: undefined,
                });
                updateQueryParams(facet.property.toString(), undefined);
            };
            const clearIconAdornment = (
                <IconButton
                    onClick={clearFilterValue}
                    icon="clear"
                />
            );
            const value = facetValues[facet.property.toString()];
            const label = facet.label ? t(facet.label).toString() : "";
            const searchFacet = searchFacets.find((s) => s.facet === facet.property);
            const allSearchFacetCount =
                facet.addAllCount && searchFacet !== undefined ? searchFacet.values.find((v) => v.value === "ALL")?.count : undefined;
            const searchTotal =
                searchFacet && searchFacet.values.some((v) => v.isSelected)
                    ? (facet.useAndOperator
                          ? Math.min(...(searchFacet?.values.filter((v) => v.isSelected).map((v) => v.count) ?? [0]))
                          : (searchFacet?.values.filter((v) => v.isSelected).map((v) => v.count) ?? [0]).reduce(
                                (partialSum, a) => partialSum + a,
                                0
                            )) + (allSearchFacetCount ?? 0)
                    : 0;
            const items =
                facet.items?.map((o) => {
                    if (typeof o === "object" && "name" in o) {
                        if (searchFacet && (searchFacet.values.find((v) => v.value === o.value) || allSearchFacetCount)) {
                            return {
                                name: `${o.name} (${(searchFacet.values.find((v) => v.value === o.value)?.count ?? 0) + (allSearchFacetCount ?? 0)})`,
                                value: o.value,
                            } as Item;
                        } else {
                            return o;
                        }
                    } else {
                        if (searchFacet && (searchFacet.values.find((v) => v.value === o) || allSearchFacetCount)) {
                            return {
                                name: `${o} (${(searchFacet.values.find((v) => v.value === o)?.count ?? 0) + (allSearchFacetCount ?? 0)})`,
                                value: o,
                            } as Item;
                        } else {
                            return {
                                name: `${o}`,
                                value: o,
                            } as Item;
                        }
                    }
                }) ||
                searchFacet?.values.map((s) => ({ name: `${s.value} (${s.count + (allSearchFacetCount ?? 0)})`, value: s.value }) as Item) ||
                ([] as Item[]);

            switch (facet.as) {
                case "select":
                    component = (
                        <Select
                            label={label}
                            key={facet.property}
                            value={value?.toString()}
                            items={items || []}
                            onChange={(newValue) => {
                                updateFacet(facet.property, newValue);
                            }}
                        />
                    );
                    break;
                case "listInput":
                    component = (
                        <ListInput
                            value={Array.isArray(value) ? value : []}
                            label={label}
                            onChange={(newValue) => {
                                updateFacet(facet.property, newValue);
                            }}
                            renderItem={({ item, handleRemove }) => {
                                return (
                                    <Chip
                                        key={item}
                                        color="primary"
                                        style={{ marginRight: 5, marginTop: 5 }}
                                        size="small"
                                        label={item}
                                        onDelete={() => handleRemove(item)}
                                    />
                                );
                            }}
                            additionalValueCheck={facet.listInputValueCheck}
                            useList={false}
                        />
                    );
                    break;
                case "date":
                    component = (
                        <DatePicker
                            label={label}
                            key={facet.property}
                            value={value?.toString()}
                            onChange={(newValue) => {
                                updateFacet(facet.property, newValue);
                            }}
                        />
                    );
                    break;
                case "dateTime":
                    const momentValue = moment.isMoment(value) ? value : value ? moment(value.toString()) : undefined;
                    component = (
                        <DateTimePicker
                            label={label}
                            key={facet.property}
                            value={momentValue && momentValue.isValid() ? momentValue : undefined}
                            onChange={(newValue) => {
                                updateFacet(facet.property, newValue);
                            }}
                        />
                    );
                    break;
                case "checkboxList":
                    if (orientation === "vertical") {
                        component = (
                            <Accordion
                                expanded={facetsHidden[index]}
                                onChange={(_event, expanded) => {
                                    setFacetsHidden([...facetsHidden.slice(0, index), expanded, ...facetsHidden.slice(index + 1)]);
                                }}
                            >
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    {(facet.label ? t(facet.label).toString() : "") + (searchTotal ? ` (${searchTotal})` : "")}
                                </AccordionSummary>
                                <AccordionDetails style={{ maxHeight: 350, overflowY: "auto" }}>
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="flex-start"
                                        alignItems="stretch"
                                    >
                                        {items.map((item, index) => {
                                            const facetValue = (facetValues[facet.property] as string[]) || [];
                                            const value = typeof item === "object" && "value" in item ? item.value || item : item;
                                            const label = typeof item === "object" && "name" in item ? item.name : item;

                                            return (
                                                <Grid
                                                    item
                                                    key={index}
                                                >
                                                    <Checkbox
                                                        label={label.toString()}
                                                        value={facetValue.includes(value?.toString() || "")}
                                                        onChange={(checked: boolean | undefined) => {
                                                            if (checked) {
                                                                updateFacet(facet.property, [...facetValue, value.toString()]);
                                                            } else {
                                                                updateFacet(
                                                                    facet.property,
                                                                    facetValue.filter((v) => v != value)
                                                                );
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        );
                    } else {
                        component = (
                            <Select
                                label={label}
                                key={facet.property}
                                value={value as string[]}
                                items={items}
                                onChange={(newValue) => {
                                    updateFacet(facet.property, newValue);
                                }}
                                disableEmpty
                                multiple
                            />
                        );
                    }

                    break;
                case "decimal":
                case "integer":
                    component = (
                        <TextField
                            numberType={facet.as === "decimal" ? "decimal" : "numeric"}
                            label={label}
                            key={facet.property}
                            onChange={(newValue) => {
                                const newNumber =
                                    facet.as === "decimal"
                                        ? Number.parseFloat(newValue?.replace(",", "."))
                                        : Number.parseInt(newValue?.replace(",", "."));
                                if (newNumber === 0 && newValue !== "0") {
                                    clearFilterValue();
                                    return;
                                }

                                if (isNaN(newNumber)) {
                                    return;
                                } else if (facet.as === "decimal" && !Number.isFinite(newNumber)) {
                                    return;
                                } else if (facet.as === "integer" && !Number.isInteger(newNumber)) {
                                    return;
                                }

                                updateFacet(facet.property, newNumber);
                            }}
                            value={value?.toString()}
                            adornment={{ position: "end", value: clearIconAdornment }}
                        />
                    );
                    break;
                case "switch":
                    component = (
                        <Switch
                            label={label}
                            key={facet.property}
                            onChange={(newValue) => {
                                updateFacet(facet.property, newValue);
                            }}
                            value={Boolean(value)}
                        />
                    );
                    break;
                case "autocomplete":
                    component = (
                        <Autocomplete
                            label={label}
                            key={facet.property}
                            items={items}
                            onChange={(value) => {
                                updateFacet(facet.property, value);
                            }}
                            disableCloseOnSelect
                            multiple
                            value={Array.isArray(value) ? value.map((v) => v?.toString()) : value?.toString()}
                        />
                    );
                    break;
                case "string":
                case undefined:
                default:
                    component = (
                        <TextField
                            label={label}
                            key={facet.property}
                            onChange={(newValue) => {
                                updateFacet(facet.property, newValue, facet.multiple);
                            }}
                            value={value?.toString()}
                            adornment={{ position: "end", value: clearIconAdornment }}
                        />
                    );
                    break;
            }
            return facet.tooltip ? (
                <Tooltip
                    title={facet.tooltip}
                    key={facet.property + "_tooltip"}
                >
                    <div children={component} />
                </Tooltip>
            ) : (
                component
            );
        });

    return (
        <Paper elevation={3}>
            {orientation === "horizontal" ? (
                <HorizontalFacets
                    facets={facets}
                    facetValues={facetValues}
                    updateFacet={updateFacet}
                    t={t}
                    searchBar={searchBar}
                    clearQueryParams={clearQueryParams && handleClearQueryParams}
                >
                    {facetsComponents}
                </HorizontalFacets>
            ) : (
                <VerticalFacets>{facetsComponents}</VerticalFacets>
            )}
        </Paper>
    );
};

export default Facets;
