import { manager, operations } from "store/actionTypePrefixes";
import { AsyncTypes, asAsyncFormTypes, createAction } from "utils/extensions";

const types = {
    formInit: createAction("FORM_INIT_PROCEDURE", manager),
    findProcedures: new AsyncTypes("FIND_PROCEDURES", operations),
    getProcedure: new AsyncTypes("GET_PROCEDURE", operations),
    getProcedureFile: new AsyncTypes("GET_PROCEDURE_FILE", operations),
    getProcedureAcceptances: new AsyncTypes("GET_PROCEDURE_ACCEPTANCES", operations),
    downloadProcedureFile: new AsyncTypes("DOWNLOAD_PROCEDURE_FILE", operations),
    createOrUpdateProcedure: (id) =>
        new AsyncTypes("CREATE_OR_UPDATE_PROCEDURE", operations, [(t) => asAsyncFormTypes(t, { redirectOnSuccess: !Boolean(id) })]),
    deleteProcedure: new AsyncTypes("DELETE_PROCEDURE", operations),
};

export default types;
