import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import { changePagination, findTurnovers, setFilters, turnoverListSelector } from "store/vendors/turnovers";
import { toSelectList } from "utils/extensions";
import { formatTypes } from "utils/formating";
import { useParams } from "utils/hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { vendor } from "translations";
import { usePush, useTranslation } from "utils-ts/hooks";

const Turnovers = () => {
    const { t } = useTranslation("vendor");
    const { push } = usePush();
    const dispatch = useDispatch();
    const { id: vendorId } = useParams();
    const { isLoading, items, filters, ...pagination } = useSelector(turnoverListSelector);

    useEffect(() => {
        dispatch(
            findTurnovers(vendorId, {
                pageSize: pagination.pageSize,
                pageIndex: pagination.pageIndex,
                ...filters,
            })
        );
    }, [dispatch, filters, pagination.pageIndex, pagination.pageSize, vendorId]);

    const columns = [
        {
            key: "year",
            value: t(vendor.year),
            type: "text",
            action: null,
            filtrable: true,
            filterType: "select",
            filterItems: toSelectList(
                Array(moment().year() + 2 - moment("2020", "YYYY").year())
                    .fill(2020)
                    .map((x, i) => x + i)
            ),
        },
        {
            key: "month",
            value: t(vendor.month),
            type: formatTypes.text,
        },
        {
            key: "invoiceSummaryTotal",
            value: t(vendor.InvoiceSummaryTotal),
            type: formatTypes.currency,
            options: { useThousandsSeparator: true },
        },
        {
            key: "transactionSummaryTotal",
            value: t(vendor.TransactionSummaryTotal),
            type: formatTypes.currency,
            options: { useThousandsSeparator: true },
        },
        {
            key: "vendorSummaryTotal",
            value: t(vendor.VendorSummaryTotal),
            type: formatTypes.currency,
            options: { useThousandsSeparator: true },
        },
        {
            key: "push",
            value: "",
            type: formatTypes.action,
            actionType: "preview",
            action: (item) => {
                push(`/vendors/${vendorId}/turnovers/form/${item.id}`);
            },
        },
    ];

    return (
        <SimpleTable
            isLoading={isLoading}
            columns={columns}
            data={items}
            onRowDoubleClick={(_, item) => {
                const { id } = item;
                push(`/vendors/${vendorId}/turnovers/form/${id}`);
            }}
            stripped
            hover
            persistentFilter
            pagination
            filterFunction={(name, value) => {
                dispatch(setFilters({ [name]: value }));
            }}
            paginationProps={{
                ...pagination,
                onPageChange: (pageIndex) => {
                    dispatch(changePagination({ pageIndex, pageSize: pagination.pageSize }));
                },
                onRowsPerPageChange: (pageSize) => {
                    dispatch(changePagination({ pageIndex: 1, pageSize }));
                },
            }}
        />
    );
};

export default Turnovers;
