import Tabs from "components/Tabs/Tabs";
import { getVendors } from "store/autocomplete";
import { makeStyles } from "tss-react/mui";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Layout } from "components";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { ContractDecisionList, RefundationDecisionsList, SubsidiaryGainDecisionsList } from "./components";

const useStyles = makeStyles()((theme) => ({
    container: {
        paddingTop: theme.spacing(1),
    },
}));

const DecisionList = () => {
    const { classes } = useStyles();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getVendors());
    });
    const { t } = useTranslation("vendor");
    const tabLabels = [`vendor:${vendor.contracts}`, `vendor:${vendor.sg}`, `vendor:${vendor.refundations}`];

    return (
        <Layout
            main
            headerText={t(vendor.decisions)}
        >
            <Tabs
                tableLabels={tabLabels}
                className={classes.container}
            >
                <div className={classes.container}>
                    <ContractDecisionList />
                </div>
                <div className={classes.container}>
                    <SubsidiaryGainDecisionsList />
                </div>
                <div className={classes.container}>
                    <RefundationDecisionsList />
                </div>
            </Tabs>
        </Layout>
    );
};

export default DecisionList;
