import AcceptRejectDialog from "components/Dialog/AcceptRejectDialog";
import { decimalNormalize } from "components/FormHelpers/ControlFormaters";
import { renderFieldOrText } from "components/FormHelpers/Controls";
import { B2BAndSupportDepartmentRoles } from "consts/roles";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { actions } from "store/payments/refund/action";
import { withStyles } from "tss-react/mui";
import AmountBalance from "utils/domainTypes/AmountBalance";
import { decimalPlaces, greaterOrEqualsThan, greaterThan, inRange, lessOrEqualsThan, mustBeNumber, notEmptyValidator } from "utils/validators/basic";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Button, Grid } from "@mui/material";
import { useUser } from "context";
import { bindActionCreators } from "redux";
import { common, payments } from "translations";
import { tValidation, validation } from "utils-ts/validations/translation";

const RefundForm = withStyles(
    ({ classes, isNotifying, maxAmount, submitting, valid, onClose, handleSubmit, postRefund, reloadTable }) => {
        const { isInAnyRoleOrAdmin } = useUser();
        const disabled = !isInAnyRoleOrAdmin(B2BAndSupportDepartmentRoles);
        const [confirmModalOpen, setOpen] = React.useState(false);
        const [form, setForm] = React.useState({});
        const { t } = useTranslation(["payments", "common"]);

        const handleAccept = async () => {
            await postRefund(form);
            onClose();
            reloadTable();
            setOpen(false);
        };

        const handleReject = () => {
            setOpen(false);
        };

        const handleFormSubmit = (values) => {
            if (!valid) return;

            setForm(values);
            setOpen(true);
        };

        const fields = [
            {
                type: "text",
                name: "frs",
                label: t(`common:${common.frsRefund}`),
                format: decimalNormalize,
            },
            {
                type: "text",
                name: "f24",
                label: t(`common:${common.f24Refund}`),
                format: decimalNormalize,
            },
            {
                type: "boolean",
                name: "notification.isNotifying",
                label: t(`common:${common.isNotifying}`),
            },
            {
                type: "text",
                name: "notification.customMessage",
                label: t(`common:${common.customMessage}`),
                hidden: !isNotifying,
            },
        ];

        if (maxAmount === 0) return <Fragment />;

        return (
            <div className={classes.container}>
                <form onSubmit={handleSubmit((val) => handleFormSubmit(val))}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="stretch"
                    >
                        {fields.map((field, i) => {
                            if (field.hidden) return <Fragment key={i} />;
                            return (
                                <Grid
                                    key={i}
                                    className={classes.field}
                                    item
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="stretch"
                                >
                                    <Field
                                        {...field}
                                        component={renderFieldOrText}
                                    />
                                </Grid>
                            );
                        })}
                        <Button
                            type="submit"
                            disabled={submitting || disabled}
                            className={classes.button}
                            variant="contained"
                            color="primary"
                        >
                            {t(`common:${common.add}`)}
                        </Button>
                    </Grid>
                    <AcceptRejectDialog
                        title={t(payments.paymentRefund)}
                        open={confirmModalOpen}
                        handleAccept={handleAccept}
                        handleReject={handleReject}
                        maxWidth="sm"
                    >
                        {t(`common:${common.areYouSure}`, {
                            what: t(payments.refundQuestion),
                        })}
                    </AcceptRejectDialog>
                </form>
            </div>
        );
    },
    (theme) => ({
        root: {
            marginTop: theme.spacing(6),
        },
        button: {
            marginTop: theme.spacing(3),
        },
        field: {
            marginTop: theme.spacing(2),
        },
    })
);

const validate = (values) => {
    const errors = { notification: {} };
    const { frs, f24, notification, maxAmountFRS, maxAmountF24 } = values;
    const castedfrs = Number(frs);
    const casted24 = Number(f24);
    errors.frs = notEmptyValidator(castedfrs)
        .must(mustBeNumber, tValidation(validation.mustBeNumber))
        .must(
            greaterOrEqualsThan(0),
            tValidation(validation.greaterOrEqualsThan, {
                number: 0,
            })
        )
        .must(greaterThan(0), tValidation(validation.greaterThan, { number: 0 }))
        .when(!casted24)
        .must(decimalPlaces([0, 2]))
        .must(
            lessOrEqualsThan(maxAmountFRS),
            tValidation(validation.lessOrEqualsThan, {
                number: maxAmountFRS,
            })
        )
        .validate();
    errors.f24 = notEmptyValidator(casted24)
        .must(mustBeNumber, tValidation(validation.mustBeNumber))
        .must(
            greaterOrEqualsThan(0),
            tValidation(validation.greaterOrEqualsThan, {
                number: 0,
            })
        )
        .must(greaterThan(0), tValidation(validation.greaterThan, { number: 0 }))
        .when(!castedfrs)
        .must(decimalPlaces([0, 2]))
        .must(
            lessOrEqualsThan(maxAmountF24),
            tValidation(validation.lessOrEqualsThan, {
                number: maxAmountF24,
            })
        )
        .validate();

    errors.notification.customMessage = notEmptyValidator(notification?.customMessage)
        .must(inRange(3, 1000), tValidation(validation.inRange, { min: 3, max: 1000 }))
        .allWhen(notification && notification.isNotifying)
        .validate();

    return errors;
};

const mapStateToProps = (state, ownProps) => {
    const { payment } = ownProps;
    const { id, paymentAmount, refunds } = payment;
    const isNotifying = formValueSelector("refundForm")(state, "notification.isNotifying");
    const maxAmount = new AmountBalance(paymentAmount).add(refunds.reduce((c, n) => c.add(n.refundAmount), AmountBalance.empty()));

    return {
        isNotifying,
        initialValues: {
            id,
            maxAmountFRS: maxAmount.FRS,
            maxAmountF24: maxAmount.F24,
            notification: null,
            frs: 0,
            f24: 0,
        },
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: "refundForm",
        enableReinitialize: true,
        validate,
    })(RefundForm)
);
