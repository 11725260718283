const timePrecisonFormats = {
    years: "YYYY",
    months: "MM.YYYY",
    days: "DD.MM.YYYY",
    hours: "DD.MM.YYYY HH",
    minutes: "DD.MM.YYYY HH:mm",
    seconds: "DD.MM.YYYY HH:mm:ss",
};

export default timePrecisonFormats;
