import Button from "components/Button/Button";
import UsersAutocomplete from "components/Controls/Autocompletes/UsersAutocomplete";
import { FormField } from "components/Form";
import roles from "consts/roles";
import { useUser } from "context/UserContext/UserContext";
import { FormSection, reduxForm } from "redux-form";
import { getUserInfoWithRole, userInfoWithRoleSelector } from "store/autocomplete";
import { makeStyles } from "tss-react/mui";
import useFormValueSelector from "utils/hooks/useFormValueSelector";
import { Fragment, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { Alert, AlertTitle } from "@mui/material";
import { common, vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { IsVendorReadOnly } from "views/vendors/IsVendorReadOnly";
import { formStyle } from "./styles";
import { validators } from "./validators";

const cooperationTypes = [
    { value: "Contract", name: "Kontrakt" },
    { value: "Mails", name: "Email" },
];

const useStyles = makeStyles()((theme) => ({
    assistant: {
        padding: theme.spacing(1),
        minWidth: "100%",
    },
}));

const VendorData = ({ isSubbmitRequested, handleSubmit, handleIsActiveDecision, updateVendor, operation }) => {
    const { classes } = useStyles();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getUserInfoWithRole(roles.Catman));
    }, [dispatch]);

    const { t } = useTranslation(["vendor", "common"]);
    const { hasPendingChangeIsActive = false, hasExpiredBioCertificate = false } = useFormValueSelector(
        formName,
        ["hasPendingChangeIsActive", "hasExpiredBioCertificate"],
        {}
    );

    const users = useSelector(userInfoWithRoleSelector);
    const { isInRoleOrAdmin, isInRole } = useUser();
    const isVendor = isInRole(roles.Vendor);
    const isAccountant = IsVendorReadOnly();

    return (
        <>
            {/* <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
                <Grid item>
                    <Button
                        color="primary"
                        onClick={() => {
                            push(
                                `/vendors/operation/${
                                    operation?.operationId ? operation.operationId : "new"
                                }/${id}`
                            );
                        }}
                    >
                        {!operation ? "Dodaj operację zmiany" : "Przejdz do operacji zmiany"}
                    </Button>
                </Grid>
            </Grid> */}
            <form
                onSubmit={handleSubmit((values) => updateVendor(values))}
                style={formStyle}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-start"
                    >
                        {hasPendingChangeIsActive && isInRoleOrAdmin(roles.SalesManager) ? (
                            <Fragment>
                                <Button
                                    actionType="accept"
                                    type="button"
                                    fullWidth
                                    loading={isSubbmitRequested}
                                    onClick={() => handleIsActiveDecision(true)}
                                >
                                    {t(vendor.acceptChanges)}
                                </Button>
                                <Button
                                    actionType="decline"
                                    type="button"
                                    fullWidth
                                    loading={isSubbmitRequested}
                                    onClick={() => handleIsActiveDecision(false)}
                                >
                                    {t(vendor.rejectChanges)}
                                </Button>
                            </Fragment>
                        ) : (
                            <Fragment />
                        )}

                        <Grid
                            item
                            className={classes.assistant}
                        >
                            <UsersAutocomplete
                                name={"assistant"}
                                filterFunc={(x) => x.email.toLowerCase().endsWith("frisco.pl")}
                                multiple={false}
                                label={t(vendor.Assistant)}
                                validate={validators.required}
                                readOnly={isVendor || isAccountant}
                                disableCloseOnSelect={false}
                                users={users.Catman}
                            />
                        </Grid>

                        <FormField
                            style={{ minWidth: "100%" }}
                            name={"name"}
                            type={"text"}
                            label={t(vendor.vendorName)}
                            readOnly={true}
                        />
                    </Grid>
                    {hasExpiredBioCertificate && (
                        <Alert severity="warning">
                            <AlertTitle>{t(vendor.expiredBioCertificateTitle)}</AlertTitle>
                            {t(vendor.expiredBioCertificateText)}
                        </Alert>
                    )}
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <FormField
                            name={"isActive"}
                            type={"boolean"}
                            label={t(`common:${common.isActive}`)}
                            readOnly={true}
                            width={225}
                        />
                        <FormField
                            name={"isSupplier"}
                            type={"boolean"}
                            label={t(vendor.isSupplier)}
                            readOnly={isVendor || isAccountant}
                            width={225}
                        />

                        <FormField
                            name={"isProducer"}
                            type={"boolean"}
                            label={t(vendor.isProducer)}
                            validate={validators.isProducerOrMiddleman}
                            readOnly={true}
                            width={225}
                        />

                        <FormField
                            name={"isMiddleman"}
                            type={"boolean"}
                            label={t(vendor.isMiddleman)}
                            validate={validators.isProducerOrMiddleman}
                            readOnly={isVendor || isAccountant}
                            width={225}
                        />

                        <FormField
                            name={"isDeliveringBio"}
                            type={"boolean"}
                            label={t(vendor.isDeliveringBio)}
                            readOnly={isVendor || isAccountant}
                            width={225}
                        />
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <FormField
                            name={"vatin"}
                            type={"text"}
                            label={t(`common:${common.vatin}`)}
                            mask={{
                                format: "###-###-##-##",
                                maskCharacter: "_",
                            }}
                            readOnly={true}
                        />

                        <FormField
                            name={"businessRegistryNumber"}
                            type={"text"}
                            label={t(`common:${common.businessRegistryNumber}`)}
                            readOnly={true}
                        />

                        <FormField
                            name={"bankAccountNumber"}
                            type={"text"}
                            label={t(`common:${common.accountNumber}`)}
                            mask={{
                                format: "## #### #### #### #### #### ####",
                                maskCharacter: "_",
                            }}
                            readOnly={true}
                        />
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <FormField
                            name={"currency"}
                            type={"text"}
                            label={t(`common:${common.currency}`)}
                            readOnly={true}
                        />

                        <FormField
                            name={"nationalCourtRegister"}
                            type={"text"}
                            label={t(`${vendor.nationalCourtRegister}`)}
                            readOnly={isVendor || isAccountant}
                        />
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <FormSection
                            name={"billingAddress"}
                            component={Fragment}
                        >
                            <FormField
                                name={"country"}
                                type={"text"}
                                label={t(`common:${common.country}`)}
                                readOnly={true}
                            />

                            <FormField
                                name={"postcode"}
                                type={"text"}
                                label={t(`common:${common.postcode}`)}
                                readOnly={true}
                            />

                            <FormField
                                name={"city"}
                                type={"text"}
                                label={t(`common:${common.city}`)}
                                readOnly={true}
                            />
                        </FormSection>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <FormSection
                            name={"billingAddress"}
                            component={Fragment}
                        >
                            <FormField
                                name={"street"}
                                type={"text"}
                                label={t(`common:${common.street}`)}
                                readOnly={true}
                            />

                            <FormField
                                name={"buildingNumber"}
                                type={"text"}
                                label={t(`common:${common.buildingNumber}`)}
                                readOnly={true}
                            />

                            <FormField
                                name={"apartmentNumber"}
                                type={"text"}
                                label={t(`common:${common.apartmentNumber}`)}
                                readOnly={true}
                            />
                        </FormSection>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <FormField
                            name={"cooperationType"}
                            type={"select"}
                            label={t(vendor.cooperationType)}
                            items={cooperationTypes}
                            hideDefaultItem={true}
                            readOnly
                        />

                        <FormField
                            name={"dateOfStartCooperation"}
                            type={"date"}
                            label={t(vendor.dateOfStartCooperation)}
                            readOnly={isVendor || isAccountant}
                        />

                        <FormField
                            name={"dateOfEndCooperation"}
                            type={"date"}
                            label={t(vendor.dateOfEndCooperation)}
                            validate={validators.dateOfEndCooperation}
                            readOnly={hasPendingChangeIsActive || isVendor || isAccountant}
                        />
                    </Grid>
                </Grid>
                {!isVendor && !isAccountant && (
                    <Button
                        actionType="accept"
                        type="submit"
                        fullWidth
                        loading={isSubbmitRequested}
                    >
                        {t(`common:${common.save}`)}
                    </Button>
                )}
            </form>
        </>
    );
};

const formName = "vendor-data-form";

const stateToProps = (state) => {
    const { vendorPms } = state;
    const { isSubbmitRequested, vendorForm } = vendorPms;

    return {
        operation: vendorForm.operation,
        isSubbmitRequested,
        initialValues: {
            ...vendorForm,
        },
    };
};

export default connect(stateToProps)(
    reduxForm({
        form: formName,
        enableReinitialize: true,
    })(VendorData)
);
