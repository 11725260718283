import { Layout } from "components/Grid";
import { Preloader } from "components/Preloader";
import { deferredPaymentsFormSelector, getDeferredPayment } from "store/customers/customer/deferredPayments/index";
import { useCommonTranslation, useParams } from "utils/hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeferredPaymentForm from "./DeferredPaymentForm";

const DeferredPayment = () => {
    const { customerId, deferredPaymentNumber } = useParams();
    const { t, common } = useCommonTranslation();
    const { form } = useSelector(deferredPaymentsFormSelector);
    const dispatch = useDispatch();
    const isLoading = form?.isLoading;

    useEffect(() => {
        dispatch(getDeferredPayment(customerId, deferredPaymentNumber));
    }, [dispatch, customerId, deferredPaymentNumber]);

    return (
        <Layout
            main
            headerText={t(common.deferredPayment, { id: deferredPaymentNumber })}
            navigationProps={{
                backProps: {
                    path: `/customers/${customerId}`,
                    state: { tabIndex: 12 },
                },
            }}
        >
            {isLoading ? <Preloader /> : <DeferredPaymentForm />}
        </Layout>
    );
};

export default DeferredPayment;
