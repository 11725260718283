import AcceptRejectDialog from "components/Dialog/AcceptRejectDialog";
import { Typography } from "@mui/material";
import { common, customers } from "translations";
import { useTranslation } from "utils-ts/hooks";

const DeleteCustomerDialog = ({ open, handleAccept, handleReject }) => {
    const { t } = useTranslation(["customers", "common"]);

    return (
        <AcceptRejectDialog
            maxWidth={"xs"}
            open={open}
            title={t(customers.deleteCustomer)}
            handleAccept={handleAccept}
            handleReject={handleReject}
        >
            <Typography variant="body1">
                {t(`common:${common.areYouSure}`, {
                    what: t(customers.removeCustomerQuestion),
                })}
            </Typography>
        </AcceptRejectDialog>
    );
};

export default DeleteCustomerDialog;
