import { Button, IconButton } from "components/Button";
import { FormField } from "components/Form";
import { renderFieldOrText } from "components/FormHelpers/Controls";
import { FormSection } from "redux-form";
import { makeStyles } from "tss-react/mui";
import { useDebouncedCallback } from "use-debounce";
import { toSelectList } from "utils/extensions/arrayExtensions";
import { useCommonTranslation } from "utils/hooks";
import Validator, { notEmpty, notEmptyValidator } from "utils/validators/basic";
import { Fragment, useEffect, useState } from "react";
import { Collapse, Grid, Paper } from "@mui/material";
import { get, range } from "lodash";
import { tValidation, validation } from "utils-ts/validations/translation";
import ArrayValidationError from "./ArrayValidationError";

const validateDateFrom = (value, form, _, path) => {
    const { dateTo } = get(form, path.substring(0, path.lastIndexOf("."))) || {};

    return new Validator(value).must(notEmpty, tValidation(validation.notEmpty)).when(notEmpty(dateTo)).validate();
};

const validateDateTo = (value, form, _, path) => {
    const { dateFrom } = get(form, path.substring(0, path.lastIndexOf("."))) || {};

    return new Validator(value).must(notEmpty, tValidation(validation.notEmpty)).when(notEmpty(dateFrom)).validate();
};

const validateDayFrom = (value, form, _, path) => {
    const { dayTo } = get(form, path.substring(0, path.lastIndexOf("."))) || {};

    return new Validator(value).must(notEmpty, tValidation(validation.notEmpty)).when(notEmpty(dayTo)).validate();
};

const validateDayTo = (value, form, _, path) => {
    const { dayFrom } = get(form, path.substring(0, path.lastIndexOf("."))) || {};

    return new Validator(value).must(notEmpty, tValidation(validation.notEmpty)).when(notEmpty(dayFrom)).validate();
};

const validateHourFrom = (value, form, _, path) => {
    const { hourTo } = get(form, path.substring(0, path.lastIndexOf("."))) || {};

    return new Validator(value).must(notEmpty, tValidation(validation.notEmpty)).when(notEmpty(hourTo)).validate();
};

const validateHourTo = (value, form, _, path) => {
    const { hourFrom } = get(form, path.substring(0, path.lastIndexOf("."))) || {};

    return new Validator(value).must(notEmpty, tValidation(validation.notEmpty)).when(notEmpty(hourFrom)).validate();
};

const mustBeNumberAndNotEmpty = (value) => {
    return new Validator(value)
        .must(notEmpty, tValidation(validation.notEmpty))
        .must((value) => {
            return !value.endsWith(".");
        }, tValidation(validation.mustBeNumber))
        .validate();
};

const validators = {
    notEmpty: (value) => notEmptyValidator(value).validate(),
    mustBeNumberAndNotEmpty,
    validateDateFrom,
    validateDateTo,
    validateDayFrom,
    validateDayTo,
    validateHourFrom,
    validateHourTo,
};

const DatesFields = ({ readOnly = false }) => {
    const { t, common } = useCommonTranslation();
    return (
        <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
        >
            <FormField
                component={renderFieldOrText}
                name={"dateFrom"}
                label={t(common.dateFrom)}
                type={"date"}
                validate={validators.validateDateFrom}
                readOnly={readOnly}
            />
            <FormField
                component={renderFieldOrText}
                name={"dateTo"}
                label={t(common.dateTo)}
                type={"date"}
                validate={validators.validateDateTo}
                readOnly={readOnly}
            />
        </Grid>
    );
};

const DaysFields = ({ readOnly = false }) => {
    const { t, common } = useCommonTranslation();
    const days = toSelectList(["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"], t);

    return (
        <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
        >
            <Grid item>
                <FormField
                    component={renderFieldOrText}
                    name={"dayFrom"}
                    label={t(common.dateFrom)}
                    type={"select"}
                    items={days}
                    validate={validators.notEmpty}
                    readOnly={readOnly}
                />
            </Grid>
            <Grid item>
                <FormField
                    component={renderFieldOrText}
                    name={"dayTo"}
                    label={t(common.dateTo)}
                    type={"select"}
                    items={days}
                    validate={validators.notEmpty}
                    readOnly={readOnly}
                />
            </Grid>
        </Grid>
    );
};

const HoursFields = ({ readOnly = false }) => {
    const { t, common } = useCommonTranslation();
    const hours = toSelectList(range(0, 25));

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
        >
            <FormField
                fullWidth
                component={renderFieldOrText}
                name={"hourFrom"}
                label={t(common.hourFrom)}
                type={"select"}
                items={hours}
                validate={validators.validateHourFrom}
                readOnly={readOnly}
            />
            <FormField
                fullWidth
                component={renderFieldOrText}
                name={"hourTo"}
                label={t(common.hourTo)}
                type={"select"}
                items={hours}
                validate={validators.validateHourTo}
                readOnly={readOnly}
            />
        </Grid>
    );
};

const useStyles = makeStyles()((theme) => {
    return {
        container: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
    };
});

const Item = ({ type, field, remove, readOnly = false }) => {
    const { t, common } = useCommonTranslation();
    const { classes } = useStyles();
    const [init, setInit] = useState(false);
    const debouncedCallback = useDebouncedCallback((_) => {
        setInit(true);
    }, 125);
    useEffect(() => {
        debouncedCallback();
    }, [debouncedCallback]);

    return (
        <Collapse in={init}>
            <div className={classes.container}>
                <FormSection name={field}>
                    <Paper elevation={2}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                        >
                            <FormField
                                component={renderFieldOrText}
                                name="leadPeriod"
                                label={t(common.leadPeriod)}
                                type="timeSpan"
                                step={0.1}
                                resolution="hours"
                                validate={validators.mustBeNumberAndNotEmpty}
                                readOnly={readOnly}
                            />
                            <Grid item>
                                {!readOnly && (
                                    <IconButton
                                        icon="delete"
                                        onClick={(index) => {
                                            setInit(false);
                                            remove(index);
                                        }}
                                    />
                                )}
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                        >
                            <FormSection name="date">
                                <FormField
                                    component={renderFieldOrText}
                                    name={"type"}
                                    label={t(common.deliveryMethods)}
                                    type={"toggle"}
                                    items={[
                                        {
                                            value: "date",
                                            name: "Data",
                                        },
                                        {
                                            value: "select",
                                            name: "Dzień",
                                        },
                                    ]}
                                    readOnly={readOnly}
                                />
                                {type === "date" ? <DatesFields readOnly={readOnly} /> : <DaysFields readOnly={readOnly} />}
                                <HoursFields readOnly={readOnly} />
                            </FormSection>
                        </Grid>
                    </Paper>
                </FormSection>
            </div>
        </Collapse>
    );
};

const DateLeadPredicate = ({ fields, currentPredicates = [], meta, readOnly = false }) => {
    const { t, common } = useCommonTranslation();
    const debouncedRemove = useDebouncedCallback((index) => {
        fields.remove(index);
    }, 350);
    return (
        <Fragment>
            <ArrayValidationError {...meta} />
            {fields.map((field, index) => {
                const { date } = currentPredicates[index] || {};
                return (
                    <Item
                        key={index}
                        type={date.type}
                        field={field}
                        remove={(_) => {
                            debouncedRemove(index);
                        }}
                        readOnly={readOnly}
                    />
                );
            })}
            {!readOnly && (
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        fields.push({ date: { type: "date" } });
                    }}
                    readOnly={readOnly}
                >
                    {t(common.add)}
                </Button>
            )}
        </Fragment>
    );
};

export default DateLeadPredicate;
