import { Table as MuiTable, useTheme } from "@mui/material";
import TableBody from "./TableBody";
import TableHead from "./TableHead";
import TablePagination from "./TablePagination";
import { TableItem, TableProps } from "./TableTypes";

const Table = <T extends TableItem>({
    items,
    columns,
    pagination,
    rowHeight = 50,
    loadable,
    handlers,
    initialFilters,
}: TableProps<T>): JSX.Element => {
    const theme = useTheme();

    return (
        <>
            <MuiTable
                stickyHeader
                size="small"
                padding="normal"
                cellPadding={theme.spacing(0)}
            >
                <TableHead
                    columns={columns}
                    handlers={handlers}
                    initialFilters={initialFilters}
                />
                <TableBody
                    rowHeight={rowHeight}
                    columns={columns}
                    items={items}
                    loadable={loadable}
                    handlers={handlers}
                />
                {pagination && <TablePagination {...pagination} />}
            </MuiTable>
        </>
    );
};

export default Table;
