import { renderFieldOrText } from "components/FormHelpers/Controls";
import Layout from "components/Grid/Layout";
import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import { Field, FieldArray } from "redux-form";
import { makeStyles } from "tss-react/mui";
import { stringStyle } from "utils/formating";
import { action, currency, grammage, text } from "utils/formating/formatTypes";
import { useConfig, useUser } from "context";
import { orders } from "translations";
import { useTranslation } from "utils-ts/hooks";
import renderProductLine from "./correction/renderers/renderProductLine";

const useStyles = makeStyles()((theme) => ({
    reason: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

const DocumentLineCorrection = (props) => {
    const { array, change, editionDisabled } = props;
    const { classes } = useStyles();
    const { t } = useTranslation("orders");
    const { isNotProduction } = useConfig();
    const { isAdmin } = useUser();
    // prettier-ignore
    const columns = [
        { key: "index",       value: "lp.",                  type: text,     colSpan: 1, options:{style: stringStyle.imporant} },
        { key: "productId",   value: t(orders.productId),    type: text,     colSpan: 1, },
        { key: "ean",         value: t(orders.ean),          type: text,     colSpan: 1  },
        { key: "description", value: t(orders.description),  type: text,     colSpan: 2, },
        { key: "quantity",    value: t(orders.quantity),     type: text,     colSpan: 1, },
        { key: "grossPrice",  value: t(orders.grossPrice),   type: currency, colSpan: 1, },
        { key: "grammage",    value: t(orders.grammage),     type: grammage, colSpan: 1, },
        { key: "grossTotal",  value: t(orders.grossTotal),   type: currency, colSpan: 1, },
        { key: "correction",  value: "",                     type: action,   colSpan: 1, },
    ];

    // prettier-ignore
    const getCorrectionTypes = () => {
        return [
            { value: "Quantity",        name: "Korekta ilości",   },
            isNotProduction || isAdmin  ? { value: "missingProducts", name: "Dowiezienie ",   } : undefined,
            { value: "Value",           name: "Korekta wartości", },
        ].filter(Boolean);
    };

    return (
        <Layout headerText={t(orders.productList)}>
            <SimpleTable columns={columns}>
                <FieldArray
                    name={"lines"}
                    props={{
                        editionDisabled,
                        columns,
                        array,
                        change,
                        correctionTypes: getCorrectionTypes(),
                    }}
                    component={renderProductLine}
                />
            </SimpleTable>
            <div className={classes.reason}>
                <Field
                    type="text"
                    field="complaintInfo"
                    label={t(orders.operationReason)}
                    name="complaintInfo"
                    readOnly={editionDisabled}
                    component={renderFieldOrText}
                    multiline
                    fullWidth
                />
            </div>
        </Layout>
    );
};

export default DocumentLineCorrection;
