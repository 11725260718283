import { identity } from "store/actionTypePrefixes";
import { types } from "store/cache/actions";
import { cacheNames } from "utils/cache/cacheProvider";

const initialState = [];

export const identityUsers = (state = initialState, { type, payload }) => {
    switch (type) {
        case types(cacheNames.identityUsersStore, identity).UPDATE_CACHE_STORE:
            const updated = state.filter((x) => !payload.some((y) => y.userId === x.userId));
            return [...updated, ...payload];
        default:
            return state;
    }
};
