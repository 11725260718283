import { makeStyles } from "tss-react/mui";
import React from "react";
import { FormHelperText as MuiFormHelperText, Theme } from "@mui/material";
import { ValidationError } from "control-types";

const useStyles = makeStyles()((theme: Theme) => ({
    error: {
        color: theme.palette.error.main,
        whiteSpace: "pre-wrap",
    },
}));

const FormHelperText: React.FC<{ error?: ValidationError }> = ({ error }) => {
    const { classes } = useStyles();

    if (!error || !error.hasError) {
        return <></>;
    } else {
        return <MuiFormHelperText className={classes.error}>{error.message}</MuiFormHelperText>;
    }
};

export default FormHelperText;
