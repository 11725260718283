import defaultListState from "store/defaultListState";
import { types } from "./action";

const initialState = defaultListState;

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case types.GET_REVIEWS:
            return { ...initialState, isLoading: true };
        case types.GET_REVIEWS_SUCCESS:
            return { ...state, isLoading: false, ...payload };
        case types.GET_REVIEWS_FAILURE:
            return { ...state, isLoading: false };
        default:
            return state;
    }
};
