import { SimplifiedProduct } from "Commerce-Offer";
import { makeStyles } from "tss-react/mui";
import { Avatar, Chip, Tooltip } from "@mui/material";

const useStyles = makeStyles()(() => ({
    buttonOnRight: {
        width: "95%",
    },
    chipBase: {
        marginRight: 10,
        marginTop: 10,
    },
    chipWithTooltTip: {
        marginRight: 10,
        marginTop: 10,
        maxWidth: "98%",
        minWidth: "98%",
        justifyContent: "stretch",
    },
}));

const ProductChip: React.FC<{
    value: string;
    handleRemove: (value: string) => void;
    product: SimplifiedProduct;
    withToolTip?: boolean;
    readOnly?: boolean;
}> = ({ value, handleRemove, product, withToolTip, readOnly }) => {
    const { classes } = useStyles();

    const { imageUrl, brand, name } = product || { imageUrl: "", brand: "", name: "" };

    return withToolTip ? (
        <Tooltip
            enterDelay={250}
            title={
                <span style={{ fontSize: "0.9rem", userSelect: "text" }}>
                    ({value}) {brand} - {name}
                </span>
            }
            leaveDelay={250}
            arrow
        >
            <Chip
                key={value}
                classes={{ label: classes.buttonOnRight, root: classes.chipWithTooltTip }}
                size="medium"
                label={`(${value}) ${brand} - ${name}`}
                color="default"
                avatar={
                    <Avatar
                        variant="square"
                        src={imageUrl}
                    ></Avatar>
                }
                onDelete={readOnly ? undefined : () => handleRemove(value)}
            />
        </Tooltip>
    ) : (
        <Chip
            key={value}
            classes={{ root: classes.chipBase }}
            size="medium"
            label={`(${value}) ${brand} - ${name}`}
            color="default"
            avatar={
                <Avatar
                    variant="square"
                    src={imageUrl}
                ></Avatar>
            }
            onDelete={readOnly ? undefined : () => handleRemove(value)}
        />
    );
};

export default ProductChip;
