import { commerce, manager } from "store/actionTypePrefixes";
import { AsyncTypes, createAction } from "utils/extensions";

const types = {
    getDeferredPayments: new AsyncTypes("GET_DEFERRED_PAYMENTS", commerce),
    getDeferredPayment: new AsyncTypes("GET_DEFERRED_PAYMENT", commerce),
    formInit: createAction("FORM_INIT_SUBSIDIARY_GAIN", manager),
    makeDecision: new AsyncTypes("DEFERRED_PAYMENT_MAKE_DECISION", commerce),
    downloadFile: new AsyncTypes("DOWNLOAD_FILE", commerce),
    createDeferredPayment: new AsyncTypes("CREATE_DEFERRED_PAYMENT", commerce),
    submitDeferredPayment: new AsyncTypes("SUBMIT_DEFERRED_PAYMENT", commerce),
    editDeferredPayment: new AsyncTypes("EDIT_DEFERRED_PAYMENT", commerce),
};

export default types;
