import moment from "moment";

const isAccountingPeriodOpen = (accountingPeriods = [], date) => {
    if (moment(date).isAfter(moment().startOf("M"))) {
        return true;
    }

    const dateAccountingPeriod = accountingPeriods.find((p) => {
        return moment(date).isBetween(moment(p.startDate), moment(p.endDate), "minutes", "[]");
    });

    if (dateAccountingPeriod) {
        if (!dateAccountingPeriod.isClosed) {
            return true;
        }

        return dateAccountingPeriod.overrides?.some(Boolean);
    }

    return false;
};

export default isAccountingPeriodOpen;
