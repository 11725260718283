import moment from "moment";

export const getDefaultRanges = (date) => [
    {
        label: "Dziś",
        startDate: moment(date),
        endDate: moment(date),
    },
    {
        label: "Wczoraj",
        startDate: moment(date).subtract(1, "day"),
        endDate: moment(date).subtract(1, "day"),
    },
    {
        label: "Aktualny tydzień",
        startDate: moment(date).startOf("week"),
        endDate: moment(date).endOf("week"),
    },
    {
        label: "Poprzedni tydzień",
        startDate: moment(date).subtract(1, "week").startOf("week"),
        endDate: moment(date).subtract(1, "week").endOf("week"),
    },
    {
        label: "Ostatnie 7 dni",
        startDate: moment(date).subtract(1, "week"),
        endDate: moment(date),
    },
    {
        label: "Aktualny miesiąc",
        startDate: moment(date).startOf("month"),
        endDate: moment(date).endOf("month"),
    },
    {
        label: "Poprzedni miesiąc",
        startDate: moment(date).subtract(1, "month").startOf("month"),
        endDate: moment(date).subtract(1, "month").endOf("month"),
    },
    {
        label: "Aktualny rok",
        startDate: moment(date).startOf("year"),
        endDate: moment(date).endOf("year"),
    },
    {
        label: "Poprzedni rok",
        startDate: moment(date).subtract(1, "year").startOf("year"),
        endDate: moment(date).subtract(1, "year").endOf("year"),
    },
];
