import { types as rootTypes } from "store/customers/customer/action";
import { combineReducers } from "redux";
import { types } from "./actions";

const initialState = {
    list: {
        isLoading: false,
        items: [],
        pageIndex: 1,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
    },
    memo: {
        isRequestInProgress: false,
        auditList: {
            isLoading: false,
            items: [],
            pageIndex: 1,
            pageSize: 10,
            pageCount: 0,
            totalCount: 0,
        },
    },
};

export default combineReducers({
    list: (state = initialState.list, { type, payload }) => {
        switch (type) {
            case rootTypes.CUSTOMER_CHANGED:
                return initialState.list;
            case types.updateCustomerMemosParams:
                return {
                    ...state,
                    ...payload,
                };
            case types.getCustomerMemos.request:
                return { ...state, isLoading: true };
            case types.getCustomerMemosWithProfileSuccess:
                return { ...state, ...payload, isLoading: false };
            default:
                return state;
        }
    },
    memo: (state = initialState.memo, { type, payload }) => {
        switch (type) {
            case rootTypes.CUSTOMER_CHANGED:
                return initialState.memo;
            case types.postCustomerMemos.request:
            case types.putCustomerMemos.request:
                return { ...state, isRequestInProgress: true };
            case types.postCustomerMemos.success:
            case types.putCustomerMemos.success:
                return { ...state, isRequestInProgress: false };
            case types.selectCustomerMemo:
                return { ...state, ...payload };
            case types.getCustomerMemoHistory.request:
                return {
                    ...state,
                    auditList: { ...state.auditList, isLoading: true },
                };
            case types.getCustomerMemoHistory.success:
                return {
                    ...state,
                    auditList: { isLoading: false, ...payload },
                };
            case types.getCustomerMemoHistory.failure:
                return { ...state, memo: { auditList: { isLoading: false } } };
            case types.getCustomerMemosAuditWithProfileSuccess:
                return {
                    ...state,
                    auditList: { isLoading: false, ...payload },
                };
            case types.updateCustomerMemoAuditParams:
                return {
                    ...state,
                    auditList: { ...state.auditList, ...payload },
                };
            default:
                return state;
        }
    },
});
