import { types } from "./action";

const initialState = {
    productLines: [],
    user: {},
    shippingAddress: {},
    payment: {},
    billingAddress: {},
    isLoading: false,
    documents: [],
    fileRequestInProgress: false,
};

export const operationsOrder = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case types.ORDERS_GET_ORDER:
            return { ...initialState, isLoading: true };
        case types.ORDER_GET_COMPLETE_OPERATIONS_ORDER:
            return { ...state, ...payload, isLoading: false };
        case types.ORDERS_GET_ORDER_FAILURE:
            return { ...state, isLoading: false };
        default:
            return state;
    }
};
