import { IconButton } from "components/Button";
import { makeStyles } from "tss-react/mui";
import { b64toBlob } from "utils/utilsFunctions";
import { Grid, Paper, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";

const useStyles = makeStyles()((_) => ({
    nav: {
        "position": "absolute",
        "width": "100%",
        "background": alpha("#DCDCDC", 0.4),
        "bottom": 0,
        "transition": "0.3s",
        "&:hover": {
            background: alpha("#DCDCDC", 1),
        },
        "z-index": 100,
    },
}));

const Navigation = ({ currentPage, pageCount, width, setPage, mode, file, autoWidth = false }) => {
    const { classes } = useStyles();

    return (
        <Paper
            className={classes.nav}
            style={{ width: autoWidth ? undefined : width }}
        >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item>
                    <IconButton
                        disabled={currentPage === 1}
                        icon="first_page"
                        onClick={() => {
                            setPage(1);
                        }}
                    />
                </Grid>
                <Grid item>
                    <IconButton
                        disabled={currentPage === 1}
                        icon="keyboard_arrow_left"
                        onClick={() => {
                            const newPage = currentPage - 1;
                            if (newPage >= 1) {
                                setPage(newPage);
                            }
                        }}
                    />
                </Grid>
                <Typography>
                    Strona: {currentPage}/ {pageCount}
                </Typography>
                {mode === "base64" && (
                    <Grid item>
                        <IconButton
                            icon="get_app"
                            onClick={() => {
                                if (file) {
                                    const blob = b64toBlob(file, "application/pdf");
                                    const fileURL = URL.createObjectURL(blob);
                                    window.open(fileURL);
                                }
                            }}
                        />
                    </Grid>
                )}
                <Grid item>
                    <IconButton
                        disabled={currentPage === pageCount}
                        icon="keyboard_arrow_right"
                        onClick={() => {
                            const newPage = currentPage + 1;
                            if (newPage <= pageCount) {
                                setPage(newPage);
                            }
                        }}
                    />
                </Grid>
                <Grid item>
                    <IconButton
                        disabled={currentPage === pageCount}
                        icon="last_page"
                        onClick={() => {
                            setPage(pageCount);
                        }}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export default Navigation;
