import { DateLeadPredicate } from "Commerce-Shared";
import { ExtendedValidator } from "../extendedValidator";
import { timeString } from "../validators";
import { DatePredicateValidator } from "./datePredicateValidator";

const datePredicateValidator =
    new DatePredicateValidator();

export class DateLeadPredicateValidator extends ExtendedValidator<DateLeadPredicate> {
    constructor() {
        super();

        this.ruleFor("date").setValidator(
            datePredicateValidator
        );

        this.ruleFor("leadPeriod")
            .notNull()
            .must(timeString);
    }
}
