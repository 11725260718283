import { manager, operations } from "store/actionTypePrefixes";
import { AsyncTypes, asAsyncFormTypes, createAction } from "utils/extensions";

const types = {
    formInit: createAction("FORM_INIT_CAMPAIGNS", manager),
    getCost: new AsyncTypes("GET_COST_REASON", operations),
    createOrUpdate: new AsyncTypes("CREATE_OR_UPDATE_COST_REASON", operations, [asAsyncFormTypes]),
};

export default types;
