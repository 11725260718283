import { OrderSearchParameters, OrderSearchResult } from "Commerce-Core";
import { useGetPagedQuery } from "api/hooks";

export const useFindSearchOrders = (
    params: OrderSearchParameters = {
        pageIndex: 1,
        pageSize: 25,
        includeFacets: true,
    }
) => {
    return useGetPagedQuery<OrderSearchResult, OrderSearchParameters>({
        app: "commerce",
        url: "api/v1/users/orders/query",
        queryParams: params,
    });
};
