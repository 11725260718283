import { FormField } from "components/Form";
import { useCommonTranslation } from "utils/hooks";
import React from "react";
import { validators } from "./validators";

const Segment = () => {
    const { t, common } = useCommonTranslation();

    return (
        <FormField
            name={"segment"}
            label={t(common.segment)}
            type={"text"}
            fullWidth
            validate={validators.segment}
        />
    );
};

export default Segment;
