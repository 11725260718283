import { Form, FormField, FormFieldArray } from "components/Form";
import { SalesDepartmentRoles } from "consts/roles";
import { FormSection, reduxForm } from "redux-form";
import companies from "resource/companies.json";
import { actions as vendorActions } from "store/vendors/action";
import { createVendorOperation, getVendorOperation, newVendorOperationSelector, vendorOperationSelector } from "store/vendors/vendorOperation";
import withMatchParams from "utils/hoc/withMatchParams";
import { useChange, useFormValueSelector, useParams, usePush } from "utils/hooks";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { Layout } from "components";
import { useUser } from "context";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { OperationBankAccounts, OperationEmails, vendorAXDictionaries as dictionaries, foreginFiles, polishFiles } from "./components";
import OperationDecision from "./components/OperationDecision";
import OperationFiles from "./components/OperationFiles";
import { default as validation } from "./components/validation";

const VendorOperation = ({ handleSubmit, state, vendorId: formVendorId }) => {
    const push = usePush();
    const { isInAnyRoleOrAdmin } = useUser();
    const dispatch = useDispatch();
    const { vendorId, operationId } = useParams();

    useEffect(() => {
        if (vendorId && operationId === "new") {
            dispatch(vendorActions.initVendorForm(vendorId));
        }

        if (operationId && operationId !== "new") {
            dispatch(getVendorOperation(operationId));
        }
    }, [dispatch, vendorId, operationId]);

    const change = useChange(formName);
    const { t } = useTranslation("vendor");

    const onSubmit = async (values) => {
        const result = await dispatch(createVendorOperation(vendorId, values));

        if (result.type.includes("SUCCESS")) {
            if (operationId === "new") {
                push(`/vendors/operation/${result.payload.id}`);
            } else {
                dispatch(getVendorOperation(operationId));
            }
        }
    };

    const isAxVendor = (vendorId || formVendorId || "").includes("D");

    const readonly =
        state === "Completed" || state === "AuditRequested" || state === "Accepted" || !isInAnyRoleOrAdmin([...SalesDepartmentRoles, "Auditor"]);

    const isArchiveOnly = useFormValueSelector(formName, "isArchiveOnly") === true;

    return (
        <Layout
            main
            headerText={
                vendorId || formVendorId
                    ? t(vendor.vendorOperation, {
                          vendorId: vendorId || formVendorId,
                      })
                    : t(vendor.vendorOperationNew)
            }
            navigationProps={{
                backProps: {
                    path: vendorId ? `/vendors/${vendorId}` : "/vendor-operations",
                },
            }}
        >
            <Form
                onSubmit={handleSubmit(onSubmit)}
                isReadonly={readonly}
            >
                {!isAxVendor && (
                    <Grid
                        container
                        direction="row"
                    >
                        <FormField
                            name="isArchiveOnly"
                            type="boolean"
                            label={t(vendor.isArchiveOnly)}
                            readOnly={true}
                        />
                    </Grid>
                )}
                <Grid
                    container
                    direction="row"
                >
                    <FormField
                        name="name"
                        type="text"
                        label={t(vendor.name)}
                        validate={validation.required}
                        readonly={readonly}
                    />

                    <FormField
                        name="shortName"
                        type="text"
                        label={t(vendor.shortName)}
                        validate={isArchiveOnly ? undefined : validation.required}
                        readonly={readonly}
                    />

                    <FormField
                        name="company"
                        type="select"
                        items={companies}
                        label={t(vendor.company)}
                        validate={validation.required}
                        readonly={readonly}
                    />
                </Grid>

                <Grid
                    container
                    direction="row"
                >
                    <FormField
                        name="businessRegistryNumber"
                        type="text"
                        label={t(vendor.businessRegistryNumber)}
                        validate={validation.businessRegistryNumber}
                        readonly={readonly}
                    />
                    <FormField
                        name="vatin"
                        type="text"
                        label={t(vendor.vatin)}
                        validate={validation.vatin}
                        readonly={readonly}
                    />
                    <FormField
                        name="nationalCourtRegister"
                        type="text"
                        label={t(vendor.nationalCourtRegister)}
                        validate={isArchiveOnly ? undefined : validation.required}
                        readonly={readonly}
                    />
                </Grid>
                <Layout headerText={t(vendor.billingAddress)}>
                    <FormSection name="billingAddress">
                        <Grid
                            container
                            direction="row"
                        >
                            <FormField
                                name="country"
                                type="autocomplete"
                                label={t(vendor.country)}
                                validate={isArchiveOnly ? undefined : validation.required}
                                getOptionLabel={(option) => dictionaries.countries.find((x) => x.value === option)?.name || ""}
                                items={dictionaries.countries.map((x) => x.value)}
                                onChange={(_, value) => {
                                    if (!value) {
                                        change("files", null);
                                    }

                                    if (value === "POL") {
                                        change("files", Array(polishFiles.length).fill({}));
                                    } else {
                                        change("files", Array(foreginFiles.length).fill({}));
                                    }
                                }}
                                readOnly={readonly}
                            />
                            <FormField
                                name="postcode"
                                type="text"
                                label={t(vendor.postcode)}
                                validate={isArchiveOnly ? undefined : validation.required}
                                readonly={readonly}
                            />
                            <FormField
                                name="city"
                                type="text"
                                label={t(vendor.city)}
                                validate={isArchiveOnly ? undefined : validation.required}
                                readonly={readonly}
                            />
                            <FormField
                                name="street"
                                type="text"
                                label={t(vendor.street)}
                                validate={isArchiveOnly ? undefined : validation.required}
                                readonly={readonly}
                            />
                            <FormField
                                name="buildingNumber"
                                type="text"
                                label={t(vendor.buildingNumber)}
                                validate={isArchiveOnly ? undefined : validation.required}
                                readonly={readonly}
                            />
                            <FormField
                                name="apartmentNumber"
                                type="text"
                                label={t(vendor.apartmentNumber)}
                                readonly={readonly}
                            />
                        </Grid>
                    </FormSection>
                </Layout>
                <Grid
                    container
                    direction="row"
                >
                    <FormField
                        name="isJustInTime"
                        type="boolean"
                        label={t(vendor.isJustInTime)}
                        readOnly={readonly}
                    />
                    <FormField
                        name="isProducer"
                        type="boolean"
                        label={t(vendor.isProducer)}
                        readOnly={readonly}
                    />
                    <FormField
                        name="pricesInPurchaseOrder"
                        type="boolean"
                        label={t(vendor.pricesInPurchaseOrder)}
                        readOnly={readonly}
                    />
                    <FormField
                        name="isTaxDateRequired"
                        type="boolean"
                        label={t(vendor.isTaxDateRequired)}
                        readOnly={readonly}
                    />
                    <FormField
                        name="pricesContainsTax"
                        type="boolean"
                        label={t(vendor.pricesContainsTax)}
                        readOnly={readonly}
                    />
                    <FormField
                        name="canReturn100"
                        type="boolean"
                        label={t(vendor.canReturn100)}
                        readOnly={readonly}
                    />
                    <FormField
                        name="orderInPurchaseUnit"
                        type="boolean"
                        label={t(vendor.orderInPurchaseUnit)}
                        readOnly={readonly}
                    />
                </Grid>
                <Grid
                    container
                    direction="row"
                >
                    <FormField
                        name="baseContact"
                        type="text"
                        label={t(vendor.baseContact)}
                        readonly={readonly}
                    />
                    <FormField
                        name="industryName"
                        type="text"
                        label={t(vendor.industryName)}
                        readonly={readonly}
                    />
                    <FormField
                        name="timeOfRealisation"
                        type="text"
                        label={t(vendor.timeOfRealisation)}
                        readonly={readonly}
                    />
                    <FormField
                        name="responsibleUser"
                        type="text"
                        label={t(vendor.responsibleUser)}
                        readonly={readonly}
                    />
                    <FormField
                        name="payerAccount"
                        type="text"
                        label={t(vendor.payerAccount)}
                        readonly={readonly}
                    />
                    <FormField
                        name="receiverAccount"
                        type="text"
                        label={t(vendor.receiverAccount)}
                        readonly={readonly}
                    />
                    <FormField
                        name="minimumValueOfLogistics"
                        type="text"
                        label={t(vendor.minimumValueOfLogistics)}
                        readonly={readonly}
                    />
                </Grid>
                <Grid
                    container
                    direction="row"
                >
                    <FormField
                        name="recordType"
                        type="text"
                        label={t(vendor.recordType)}
                        validate={isArchiveOnly ? undefined : validation.required}
                        readonly={readonly}
                    />
                    <FormField
                        name="language"
                        type="autocomplete"
                        label={t(vendor.language)}
                        getOptionLabel={(option) => dictionaries.languages.find((x) => x.value === option)?.name || ""}
                        items={dictionaries.languages.map((x) => x.value)}
                        validate={isArchiveOnly ? undefined : validation.required}
                        readOnly={readonly}
                    />
                    <FormField
                        name="vendorGroup"
                        type="select"
                        label={t(vendor.vendorGroup)}
                        items={dictionaries.vendorGroups}
                        validate={isArchiveOnly ? undefined : validation.required}
                        readonly={readonly}
                    />
                    <FormField
                        name="currency"
                        type="autocomplete"
                        label={t(vendor.currency)}
                        getOptionLabel={(option) => dictionaries.currencies.find((x) => x.value === option)?.name || ""}
                        items={dictionaries.currencies.map((x) => x.value)}
                        validate={isArchiveOnly ? undefined : validation.required}
                        readOnly={readonly}
                    />
                    <FormField
                        name="taxGroup"
                        type="select"
                        label={t(vendor.taxGroup)}
                        items={dictionaries.taxGroups}
                        validate={isArchiveOnly ? undefined : validation.required}
                        readonly={readonly}
                    />
                    <FormField
                        name="paymentTerms"
                        type="select"
                        label={t(vendor.paymentTerms)}
                        items={dictionaries.paymentTerms}
                        validate={isArchiveOnly ? undefined : validation.required}
                        readonly={readonly}
                    />
                    <FormField
                        name="paymentMethod"
                        type="select"
                        label={t(vendor.paymentMethod)}
                        items={dictionaries.paymentMethods}
                        validate={isArchiveOnly ? undefined : validation.required}
                        readonly={readonly}
                    />
                    <FormField
                        name="accountNumberCountry"
                        type="autocomplete"
                        label={t(vendor.accountNumberCountry)}
                        getOptionLabel={(option) => dictionaries.countries.find((x) => x.value === option)?.name || ""}
                        items={dictionaries.countries.map((x) => x.value)}
                        readOnly={readonly}
                    />
                    <FormField
                        name="vatinCountry"
                        type="autocomplete"
                        label={t(vendor.vatinCountry)}
                        getOptionLabel={(option) => dictionaries.countries.find((x) => x.value === option)?.name || ""}
                        items={dictionaries.countries.map((x) => x.value)}
                        readOnly={readonly}
                    />
                    <FormField
                        name="warehouse"
                        type="select"
                        label={t(vendor.warehouse)}
                        items={dictionaries.warehouses}
                        readonly={readonly}
                    />
                    <FormField
                        name="deliveryTerms"
                        type="text"
                        label={t(vendor.deliveryTerms)}
                        items={dictionaries.deliveryTerms}
                        readonly={readonly}
                    />
                    <FormField
                        name="deliveryMethod"
                        type="text"
                        label={t(vendor.deliveryMethod)}
                        items={dictionaries.deliveryMethods}
                        readonly={readonly}
                    />
                </Grid>
                <FormFieldArray
                    name="emails"
                    component={OperationEmails}
                    readonly={readonly}
                    isArchiveOnly={isArchiveOnly}
                />
                <FormFieldArray
                    name="bankAccountInformations"
                    component={OperationBankAccounts}
                    readonly={readonly}
                    isArchiveOnly={isArchiveOnly}
                    formName={formName}
                />
                <FormFieldArray
                    name="files"
                    component={OperationFiles}
                    readonly={readonly}
                    formName={formName}
                    isArchiveOnly={isArchiveOnly}
                />
            </Form>
            <OperationDecision state={state} />
        </Layout>
    );
};

const formName = "vendor-operation";

const initialEmails = [
    { name: "Protocol", email: "" },
    { name: "MissingPurchaseOrder", email: "" },
    { name: "Accounting", email: "" },
    { name: "MarketingInvoices", email: "" },
    { name: "EInvoice", email: "" },
];

const stateToProps = (state, props) => {
    let initialData;

    if (props.match.params.operationId === "new" && props.match.params.vendorId) {
        initialData = newVendorOperationSelector(state);
    } else if (props.match.params.operationId !== "new") {
        initialData = vendorOperationSelector(state);
    }

    return {
        vendorId: initialData?.vendorId,
        state: initialData?.state,
        initialValues: {
            ...initialData,
            emails: [
                ...(initialData?.emails
                    ? [...initialData?.emails, ...initialEmails.filter((x) => initialData?.emails.every((y) => y.name !== x.name))]
                    : initialEmails),
            ],
            isArchiveOnly: true,
        },
    };
};

export default withMatchParams(
    connect(stateToProps)(
        reduxForm({
            form: formName,
            enableReinitialize: true,
        })(VendorOperation)
    )
);
