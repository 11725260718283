import Cell from "components/Table/SimpleTable/Cell";
import Row from "components/Table/SimpleTable/Row";
import Action from "components/Table/SimpleTable/TableActions/Action";
import { formatTypes, formatValue } from "utils/formating";
import { Typography } from "@mui/material";
import { orders } from "translations";

const OrderAlertListRow = ({ t, columns, item, navigateToAlert, dismissAlert }) => {
    return (
        <Row hover>
            {columns.map(({ key, type, options, action, actionType }, index) => {
                if (key === "id") {
                    return (
                        <Action
                            key={index}
                            handleClick={() => action(item[key])}
                            actionType={actionType}
                            text={item[key]}
                            openInNewTab={true}
                        />
                    );
                }

                if (key === "customer") {
                    const { id, fullName, billingAddress = {}, segments = [] } = item[key] || {};
                    const isOfficeAccount = segments.some((x) => x === "commerce:OfficeAccount");
                    const name =
                        isOfficeAccount && billingAddress.companyName
                            ? `${billingAddress.companyName || ""}`
                            : fullName && formatValue(fullName, formatTypes.fullName);

                    if (!name) return <Cell key={index} />;
                    return (
                        <Action
                            key={index}
                            handleClick={() => action(id)}
                            actionType={actionType}
                            text={name}
                            openInNewTab={true}
                        />
                    );
                }

                if (key === "reason") {
                    let cell = [];
                    const { suspiciousTotal, suspiciousProducts, suspiciousOrders, suspiciousTerms } = item;
                    if (suspiciousTotal) {
                        cell = [
                            ...cell,
                            t(orders.suspiciousTotal, {
                                amount: suspiciousTotal,
                            }),
                        ];
                    }
                    if (suspiciousProducts) {
                        cell = [
                            ...cell,
                            t(orders.suspiciousProducts, {
                                count: suspiciousProducts.length,
                            }),
                        ];
                    }
                    if (suspiciousOrders) {
                        cell = [
                            ...cell,
                            t(orders.suspiciousOrders, {
                                count: suspiciousOrders.length,
                            }),
                        ];
                    }
                    if (suspiciousTerms) {
                        cell = [...cell, t(orders.suspiciousTerms)];
                    }

                    return (
                        <Cell
                            key={index}
                            value={cell.map((x) => (
                                <Typography key={x}>{x}</Typography>
                            ))}
                        />
                    );
                }

                if (key === "menu") {
                    const actions = [
                        {
                            label: t(orders.dismissAlert),
                            action: () => dismissAlert(item.id),
                        },
                        {
                            label: t(orders.more),
                            action: () => navigateToAlert(item.id, item),
                        },
                    ];
                    return (
                        <Action
                            key={index}
                            actions={actions}
                            actionType={actionType}
                            text={item[key]}
                        />
                    );
                }

                return (
                    <Cell
                        key={index}
                        value={formatValue(item[key], type, options)}
                    />
                );
            })}
        </Row>
    );
};

export default OrderAlertListRow;
