import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import {
    customerBalanceAuditSelector,
    getCustomerBalanceAudit,
    updateCustomerBalanceAuditPagination,
} from "store/customers/customer/customerBalanceAudit";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { common } from "translations";
import { useTranslation } from "utils-ts/hooks";
import CustomerBalanceAuditRow from "./CustomerBalanceAuditRow";

const CustomerBalanceAudit = ({ customerId }) => {
    const dispatch = useDispatch();
    const customerBalanceAudit = useSelector(customerBalanceAuditSelector);

    const { isLoading, items, pageIndex, pageSize, totalCount } = customerBalanceAudit;

    useEffect(() => {
        dispatch(getCustomerBalanceAudit({ customerId, pageIndex, pageSize }));
    }, [customerId, dispatch, pageIndex, pageSize]);

    const { t } = useTranslation(["common", "customerBalanceCommandNames"]);

    const columns = useMemo(
        () =>
            [
                {
                    key: "createdAt",
                    value: common.createdAt,
                    type: formatTypes.time,
                    options: { timeFormat: timePrecisonFormats.seconds },
                },
                {
                    key: "balanceAmount",
                    value: common.balanceAmount,
                    type: formatTypes.currencyAmount,
                },
                {
                    key: "createdBy",
                    value: common.createdBy,
                    type: formatTypes.text,
                },
                {
                    key: "commandName",
                    value: common.modificationType,
                    type: formatTypes.translatable,
                    options: { translationKey: "customerBalanceCommandNames" },
                },
                {
                    key: "commandNameDescription",
                    value: common.modificationTypeDescription,
                    type: formatTypes.text,
                },
            ].map((col) => ({ ...col, value: t(col.value) })),
        [t]
    );

    return (
        <SimpleTable
            isLoading={isLoading}
            columns={columns}
            hover
            stripped
            pagination
            paginationProps={{
                pageIndex,
                totalCount,
                pageSize,
                onPageChange: (pageIndex) =>
                    dispatch(
                        updateCustomerBalanceAuditPagination({
                            pageIndex: pageIndex,
                            pageSize: pageSize,
                        })
                    ),
                onRowsPerPageChange: (pageSize) =>
                    dispatch(
                        updateCustomerBalanceAuditPagination({
                            pageIndex: 1,
                            pageSize: pageSize,
                        })
                    ),
            }}
        >
            {items
                .map((x) => ({
                    ...x,
                    commandIdentifiers: x.commandIdentifiers && {
                        ...x.commandIdentifiers,
                        documentType: t(`customerBalanceCommandNames:${x.commandIdentifiers.documentType}`),
                    },
                }))
                .map((x, index) => (
                    <CustomerBalanceAuditRow
                        key={index}
                        columns={columns}
                        item={x}
                        customerId={customerId}
                    />
                ))}
        </SimpleTable>
    );
};

export default CustomerBalanceAudit;
