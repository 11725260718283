import { CreateCustomerContact, CustomerContactDocument, CustomerContactListQueryParams, CustomerContactListResponse } from "Commerce-Support";
import { generatePath } from "react-router-dom";
import { useCreateOrUpdateMutation, useGetQuery } from "api/hooks";

const queryKey = "api/v1/support/contacts/";

const url = (id?: string) => generatePath(`${queryKey}:id?`, { id: id ? id : null });

export const useGetCustomerContact = (id?: string) => {
    return useGetQuery<CustomerContactDocument>({
        app: "commerce",
        url: url(id),
        shouldMakeRequest: Boolean(id),
    });
};

export const useCreateCustomerContact = (id?: string) => {
    return useCreateOrUpdateMutation<CreateCustomerContact, CustomerContactDocument>({
        app: "commerce",
        url: url(id),
        id: id,
    });
};

export const useGetCustomerContacts = (userId: string, params: CustomerContactListQueryParams) => {
    return useGetQuery<CustomerContactListResponse, CustomerContactListQueryParams>({
        app: "commerce",
        url: url(),
        queryParams: { userId, ...params },
    });
};
