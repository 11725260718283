import React from "react";
import { useParams } from "react-router-dom";
import { DocumentArchiveTypeDocument } from "Operations";
import { api } from "api";
import { common } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { useForm, useValidator } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { Form, FormRow, FormSelect, FormSwitch, FormTextField } from "components-ts/forms";
import DocumentTypeValidator from "./validator";

const DocumentTypeForm: React.FC = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const { data, status } = api.operations.documentArchiveTypes.useGetDocumentArchiveType(id);
    const validatorResolver = useValidator(new DocumentTypeValidator());
    const form = useForm<DocumentArchiveTypeDocument>({
        resolver: validatorResolver,
        values: data,
    });

    return (
        <Form
            form={form}
            headerText={{
                resource: "documentType",
                params: { id: id },
            }}
            formPath={Paths.Dictionaries.DocumentTypeForm}
            listPath={Paths.Dictionaries.DocumentTypesList}
            initQueryStatus={status}
            submitMutation={api.operations.documentArchiveTypes.useCreateOrUpdatDocumentArchiveType(id)}
            id={id}
        >
            <FormRow>
                <FormTextField
                    name="name"
                    label={t(common.name)}
                />

                <FormTextField
                    name="description"
                    label={t(common.description)}
                />
            </FormRow>

            <FormRow>
                <FormSelect
                    name="levelOfConfidentiality"
                    label={t(common.levelOfConfidentiality)}
                    items={[
                        { value: "P0", name: "Poziom 0" },
                        { value: "P1", name: "Poziom 1" },
                    ]}
                    disableEmpty
                />

                <FormSwitch
                    name="isActive"
                    label={t(common.isActive)}
                />
            </FormRow>
        </Form>
    );
};

export default DocumentTypeForm;
