import Layout from "components/Grid/Layout";
import { createColumn } from "components/Table/SimpleTable/SimpleTable";
import tableActionTypes from "components/Table/SimpleTable/TableActions/tableActionTypes";
import TableWithToggleFilter from "components/Table/TableWithToggleFilter/TableWithToggleFilter";
import { getGiftChoicesList } from "store/offers/giftChoices/list/actions";
import { giftChoicesListSelector } from "store/offers/selectors";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useCommonTranslation, useParams } from "utils/hooks";
import { usePush } from "utils-ts/hooks";

const GiftList = () => {
    const { t, common } = useCommonTranslation();
    const { giftId } = useParams();
    const { pushToForm: push } = usePush();
    const pushToForm = (id, isCopy) => push(`/offers/gifts/${giftId}/gift-choices/form`, id ? id.split("/")[1] : undefined, isCopy);

    const columns = [
        createColumn("name", common.name, formatTypes.text),
        createColumn("activeChoiceGroup", common.activeChoiceGroup, formatTypes.text),
        createColumn("validFrom", common.validFrom, formatTypes.time, {
            timeFormat: timePrecisonFormats.seconds,
        }),
        createColumn("validTo", common.validTo, formatTypes.time, {
            timeFormat: timePrecisonFormats.seconds,
        }),
        createColumn("isActive", common.active, formatTypes.boolean),
        createColumn("createdAt", common.createdAt, formatTypes.time, {
            timeFormat: timePrecisonFormats.seconds,
        }),
        createColumn("updatedAt", common.updatedAt, formatTypes.time, {
            timeFormat: timePrecisonFormats.seconds,
        }),
        createColumn("action", "", formatTypes.action, { actionType: tableActionTypes.preview }, (item) => pushToForm(item.id)),
        createColumn("action", "", formatTypes.action, { actionType: tableActionTypes.copy }, (item) => pushToForm(item.id, true)),
    ].map((col) => ({ ...col, value: t(col.value) }));

    return (
        <Layout
            main
            headerText={t(common.giftChoices)}
            navigationProps={{
                backProps: { path: "/offers/gifts" },
            }}
        >
            <TableWithToggleFilter
                columns={columns}
                addItemAction={() => pushToForm()}
                dataSelector={giftChoicesListSelector}
                dataSourceAction={({ ...params }) => getGiftChoicesList({ ...params, giftId })}
            />
        </Layout>
    );
};

export default GiftList;
