import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { CircularProgress, Typography } from "@mui/material";

const useStyles = makeStyles()(() => ({
    root: {},
    progressStyle: {
        width: "100%",
    },
    center: {
        margin: 0,
        position: "absolute",
        top: "50%",
        left: "50%",
        msTransform: "translate(-50%, -50%)",
        transform: "translate(-50%, -50%)",
    },
}));

const Preloader = ({ height = 600 }) => {
    const { classes } = useStyles();
    const { t } = useTranslation();
    return (
        <div
            style={{ height }}
            className={classes.progressStyle}
        >
            <div className={classes.center}>
                <CircularProgress size={72} />
                <Typography>{t("Loading")}</Typography>
            </div>
        </div>
    );
};

export default Preloader;
