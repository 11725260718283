import TextWithLabel from "components/Text/TextWithLabel";
import { formatTypes } from "utils/formating";
import { useCommonTranslation } from "utils/hooks";
import { Grid } from "@mui/material";
import { Layout } from "components";

const field = (label, value, format, options) => {
    return {
        label,
        value,
        format,
        options,
    };
};

const GiftChoiceValidation = ({ validation = { warehouses: [] } }) => {
    const { warehouses } = validation;
    const { t, common } = useCommonTranslation();
    const warehouseProblems = warehouses
        .filter((x) => x.problems?.length > 0)
        .map((i) => ({
            warehouse: field(common.warehouse, i.warehouse, formatTypes.text),
            problems: field(common.problems, i.problems.map((p) => t(p)).join(", "), formatTypes.text),
        }));

    return (
        <Layout headerText={t(common.giftChoiceValidation)}>
            <div style={{ minWidth: "100%" }}>
                {warehouseProblems.map((w) => (
                    <Grid
                        item
                        container
                        direction="row"
                        key={w.warehouse.value}
                    >
                        <Grid item>
                            <TextWithLabel
                                unselectableLabel
                                {...w.warehouse}
                            />
                        </Grid>
                        <Grid item>
                            <TextWithLabel
                                unselectableLabel
                                {...w.problems}
                            />
                        </Grid>
                    </Grid>
                ))}
            </div>
        </Layout>
    );
};

export default GiftChoiceValidation;
