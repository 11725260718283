import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import tableActionTypes from "components/Table/SimpleTable/TableActions/tableActionTypes";
import Actions, { createAction } from "components/Tabs/Actions";
import roles from "consts/roles";
import { actions } from "store/customers/customer/customerBalanceCorrection/action";
import { firstOrEmpty } from "utils/extensions/arrayExtensions";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import React from "react";
import { connect } from "react-redux";
import { useUser } from "context";
import { bindActionCreators } from "redux";
import { customers } from "translations";
import { useTranslation } from "utils-ts/hooks";
import AddBalanceCorrectiomDialog from "./AddBalanceCorrectiomDialog";
import CancelBalanceCorrectionDialog from "./CancelBalanceCorrectionDialog";

const CustomerBalnaceCorrectionList = ({
    customerId,
    getCustomerBalanceCorrectionList,
    customerBalanceCorrection,
    removeBalanceCorrection,
    postCustomerBalanceCorrection,
}) => {
    const { isInRoleOrAdmin } = useUser();
    const [cancelBalanceCorrection, setCancelBalanceCorrectionItem] = React.useState(undefined);
    const [addBalanceCorrection, setAddBalanceCorrection] = React.useState(undefined);
    const [editBalanceCorrectionItem, setEditBalanceCorrectionItem] = React.useState(undefined);
    const canEdit = isInRoleOrAdmin(roles.AccountBalanceCoordinator);
    const { t } = useTranslation("customers");
    const { isLoading, items, pageIndex, pageSize, pageCount, totalCount } = customerBalanceCorrection;
    React.useEffect(() => {
        getCustomerBalanceCorrectionList({ customerId });
    }, [customerId, getCustomerBalanceCorrectionList]);

    const columns = [
        {
            key: "status",
            value: customers.status,
            type: formatTypes.translatable,
            options: { translationKey: "common" },
        },
        { key: "balanceAmount", value: customers.balance, type: formatTypes.currencyAmount },
        { key: "complaintCode", value: customers.reason, type: formatTypes.text },
        { key: "createdBy", value: customers.createdBy, type: formatTypes.text },
        {
            key: "createdAt",
            value: customers.createdAt,
            type: formatTypes.time,
            options: { timeFormat: timePrecisonFormats.seconds },
        },
        {
            key: "preview",
            value: "",
            type: formatTypes.action,
            action: (item) => {
                setEditBalanceCorrectionItem(item);
                setAddBalanceCorrection(customerId);
            },
            actionType: canEdit ? tableActionTypes.edit : tableActionTypes.preview,
            disabled: (item) => item.status === "Cancelled",
        },
        {
            key: "delete",
            value: "",
            type: formatTypes.action,
            action: (item) => {
                setCancelBalanceCorrectionItem(item);
            },
            actionType: tableActionTypes.delete,
            disabled: (item) => item.status === "Cancelled" || !canEdit,
        },
    ].map((col) => ({ ...col, value: t(col.value) }));

    return (
        <>
            {canEdit && (
                <Actions
                    actions={[
                        createAction(
                            t(customers.addBalanceCorrection),
                            () => {
                                setAddBalanceCorrection(customerId);
                            },
                            "primary"
                        ),
                    ]}
                />
            )}
            <SimpleTable
                isLoading={isLoading}
                columns={columns}
                hover
                pagination
                data={items.map((x) => ({
                    ...x,
                    ...x.complaint.balanceDiscrepancy,
                }))}
                paginationProps={{
                    pageIndex,
                    totalCount,
                    pageSize,
                    pageCount,
                    onPageChange: (pageIndex) =>
                        getCustomerBalanceCorrectionList({
                            customerId,
                            pageIndex,
                            pageSize,
                        }),
                    onRowsPerPageChange: (pageSize) =>
                        getCustomerBalanceCorrectionList({
                            customerId,
                            pageIndex,
                            pageSize,
                        }),
                }}
            />
            <CancelBalanceCorrectionDialog
                open={Boolean(cancelBalanceCorrection)}
                handleAccept={async () => {
                    const { id, userId } = cancelBalanceCorrection;
                    await removeBalanceCorrection(userId, id);
                    setCancelBalanceCorrectionItem(undefined);
                    getCustomerBalanceCorrectionList({
                        customerId,
                        pageIndex,
                        pageSize,
                    });
                }}
                handleReject={() => setCancelBalanceCorrectionItem(null)}
            />
            <AddBalanceCorrectiomDialog
                editItem={editBalanceCorrectionItem}
                handleAdd={async (values) => {
                    await postCustomerBalanceCorrection(customerId, values);
                    setAddBalanceCorrection(undefined);
                    setEditBalanceCorrectionItem(undefined);
                    getCustomerBalanceCorrectionList({
                        customerId,
                        pageIndex,
                        pageSize,
                    });
                }}
                open={Boolean(addBalanceCorrection)}
                handleClose={() => {
                    setAddBalanceCorrection(undefined);
                    setEditBalanceCorrectionItem(undefined);
                }}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    const {
        customer: { customerBalanceCorrection },
        cache: { userInfos },
    } = state;
    const { items, ...rest } = customerBalanceCorrection;
    const mapped = items.map((b) => {
        const { email } = firstOrEmpty(userInfos, (x) => x.userId === b.createdBy);
        return { ...b, createdBy: email || b.createdBy };
    });
    return {
        customerBalanceCorrection: {
            ...rest,
            items: mapped,
        },
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CustomerBalnaceCorrectionList);
