import React from "react";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import { AccountantDepartment } from "../roles";

const CustomerDepositsImport = React.lazy(() => import("views/customerDeposists/CustomerDepositsImport"));

export const CustomerDeposits: PrivateRoute[] = [
    new PrivateRoute(
        Paths.CustomerDepositsImport,
        ["Management", "Analyst", "Auditor", "AXUser", ...AccountantDepartment.GetRoles()],
        CustomerDepositsImport,
        "customerDepositsImport",
        "account_balance"
    ),
];
