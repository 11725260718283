import { useUser } from "context/UserContext/UserContext";
import procedureStatuses from "resource/procedureStatuses.json";
import { useDebouncedCallback } from "use-debounce";
import React from "react";
import { Grid } from "@mui/material";
import { ProcedureResponse } from "Operations";
import { api } from "api";
import { useColumns, usePush } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { GridContainer } from "components-ts/forms/grid";
import { BooleanStatusCell, QueryTable, Table } from "components-ts/tables";
import { View } from "components-ts/view";
import { Spacing } from "components-ts/view";

const ProceduresList: React.FC = () => {
    const { profile } = useUser();
    const employeeId = profile?.sub;
    const { push } = usePush();

    const unacceptedResult = api.operations.employeeProcedures.useFindUnacceptedProcedures(employeeId);
    const { data: categories } = api.operations.autocomplete.useFindProcedureCategories();
    const { data: departments } = api.operations.autocomplete.useFindEmployeeDepartments();

    const updateUnacceptedParams = (property: string, value: unknown) => {
        const newValue = value === "" ? undefined : value;
        unacceptedResult.setQueryParams({
            pageIndex: 1,
            [property]: newValue,
        });
    };

    const updateUnacceptedFilter = useDebouncedCallback(updateUnacceptedParams, 500);

    const unacceptedColumns = useColumns<ProcedureResponse>(() => [
        {
            property: "name",
            label: "name",
            filtrable: true,
        },
        {
            property: "procedureCategoryId",
            label: "categoryName",
            filtrable: true,
            filterAs: "select",
            filterItems: (categories || []).map((c) => ({
                name: c.procedureCategoryName,
                value: c.id,
            })),
            as: (value: unknown) => {
                const categoryId = value as string;
                if (categories) {
                    return categories.find((c) => c.id === categoryId)?.procedureCategoryName ?? categoryId;
                } else {
                    return categoryId;
                }
            },
        },
        {
            property: "acceptStatus",
            label: "status",
            as: (value: unknown) => {
                const status = procedureStatuses.find((o) => o.value === value);
                return status?.name;
            },
        },
        {
            actionType: "preview",
            link: (item) =>
                Paths.GeneratePath(Paths.Procedure, {
                    id: item.id.split("/")[0],
                }),
        },
    ]);

    const columns = useColumns<ProcedureResponse>(() => [
        {
            property: "name",
            label: "name",
            filtrable: true,
        },
        {
            property: "procedureCategoryId",
            label: "categoryName",
            filtrable: true,
            filterAs: "select",
            filterItems: (categories || []).map((c) => ({
                name: c.procedureCategoryName,
                value: c.id,
            })),
            as: (value: unknown) => {
                const categoryId = value as string;
                if (categories) {
                    return categories.find((c) => c.id === categoryId)?.procedureCategoryName ?? categoryId;
                } else {
                    return categoryId;
                }
            },
        },
        {
            property: "dedicatedForDepartment",
            label: "dedicatedForDepartment",
            filtrable: true,
            filterAs: "select",
            filterItems: (departments || []).map((d) => ({
                name: d,
                value: d,
            })),
        },
        {
            property: "acceptStatus",
            label: "status",
            as: (value: unknown) => {
                const status = procedureStatuses.find((o) => o.value === value);
                return status?.name;
            },
        },
        {
            property: "isHidden",
            label: "isActive",
            as: (value: unknown) => {
                const isHidden = value ?? false;

                return <BooleanStatusCell value={!isHidden} />;
            },
        },
        {
            actionType: "preview",
            link: (item) =>
                Paths.GeneratePath(Paths.Procedure, {
                    id: item.id.split("/")[0],
                }),
        },
    ]);

    return (
        <View
            headerText="documents"
            isMainView
        >
            {((unacceptedResult.data || []).length > 0 ||
                (unacceptedResult.queryParams &&
                    ((unacceptedResult.queryParams.name || "")?.length > 0 ||
                        (unacceptedResult.queryParams.procedureCategoryId || "")?.length > 0))) && (
                <Spacing spacing={1}>
                    <GridContainer
                        title="unaccepted"
                        subtitle="możliwość filtrowania po nazwie oraz kategorii"
                        translateSubtitle={false}
                        iconName="bookmark_border"
                    >
                        <Table
                            columns={unacceptedColumns}
                            items={unacceptedResult.data}
                            loadable={{
                                status: unacceptedResult.status,
                                isRefetching: unacceptedResult.isRefetching,
                                error: unacceptedResult.error,
                                refetch: unacceptedResult.refetch,
                            }}
                            handlers={{
                                onFilterChange: updateUnacceptedFilter,
                                onRowDoubleClick: (item) => {
                                    push(
                                        Paths.GeneratePath(Paths.Procedure, {
                                            id: item.id.split("/")[0],
                                        })
                                    );
                                },
                            }}
                        />
                    </GridContainer>
                </Spacing>
            )}

            <Spacing spacing={1}>
                <GridContainer
                    title="all"
                    subtitle="możliwość filtrowania po nazwie, kategorii oraz dziale"
                    translateSubtitle={false}
                    iconName="bookmarks"
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <QueryTable
                            queryHook={(params) => api.operations.employeeProcedures.useFindProcedures(employeeId, params)}
                            columns={columns}
                            hideToggle
                            hideReload
                            canAddItem={false}
                            formPath={Paths.Procedure}
                            getItemId={(item) => item.id.split("/")[0]}
                        />
                    </Grid>
                </GridContainer>
            </Spacing>
        </View>
    );
};

export default ProceduresList;
