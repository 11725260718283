import Dialog from "components/Dialog/Dialog";
import SearchBar from "components/SearchBar/SearchBar";
import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import { actions } from "store/order/upsell/actions";
import formatTypes from "utils/formating/formatTypes";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { orders } from "translations";
import { useTranslation } from "utils-ts/hooks";

const ProductDialog = ({ open, onClose, upsell, search, customerId, warehouse, pushUpsellProduct, clearSearch }) => {
    const [term, setTerm] = React.useState("");
    const { t } = useTranslation("orders");

    const handleSearchCommit = () => {
        const { pageIndex, pageSize } = upsell;
        search({ customerId, pageIndex, pageSize, term, warehouse });
    };

    const handleProductAdd = (product) => {
        const p = {
            ...product,
            description: product.fullName,
            grossPrice: product.price,
            productId: product.productId.toString().padStart(6, "0"),
            upsellType: null,
        };
        pushUpsellProduct("upsellLines", { ...p, quantity: 0 });
        onClose();
        clearSearch();
        setTerm("");
    };

    //prettier-ignore
    const columns = [
            { key: "productId", value: t(orders.productId)      , type: formatTypes.text                                                              },
            { key: "ean"      , value: t(orders.ean)            , type: formatTypes.text                                                              },
            { key: "description" , value: t(orders.productFullName), type: formatTypes.text                                                              },
            { key: "action"   , value: ""                       , type: formatTypes.action, actionType: "add", action: (p) => { handleProductAdd(p) } },
        ];

    const { products = [] } = upsell;

    return (
        <div>
            <Dialog
                open={open}
                onClose={() => {
                    onClose();
                    clearSearch();
                    setTerm("");
                }}
            >
                <Fragment>
                    <SearchBar
                        value={term}
                        placeholder={t(orders.searchBy)}
                        onSearchChanged={({ target: { value } }) => setTerm(value)}
                        onSearchCommit={handleSearchCommit}
                    />
                    <SimpleTable
                        hover
                        stripped
                        isLoading={upsell.isLoading}
                        columns={columns}
                        data={products}
                    />
                </Fragment>
            </Dialog>
        </div>
    );
};

const mapStateToProps = (state) => {
    const { upsell } = state;
    const { products = [] } = upsell;
    const filtered = products.map((x) => ({ ...x, description: x.fullName }));

    return { upsell: { ...upsell, products: filtered } };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ ...actions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductDialog);
