import { FormField } from "components/Form";
import { makeStyles } from "tss-react/mui";
import { useDebouncedCallback } from "use-debounce";
import { useChange, useFormValueSelector } from "utils/hooks";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ClickAwayListener, Grid } from "@mui/material";
import moment from "moment";
import { common } from "translations";
import DateRangePicker from "./DateRangePicker";

const useStyles = makeStyles()(() => ({
    dateRangePicker: {
        position: "absolute",
        top: "100%",
        height: "calc(100% + 16px)",
        zIndex: "10",
    },
}));

const DateRangeField = ({
    t,
    formName,
    formSectionName = "",
    startDateName = "dateFrom",
    endDateName = "dateTo",
    readOnly = false,
    blockSameDay,
    startDateValidator,
    endDateValidator,
    startDateLabel = t(common.dateTo),
    endDateLabel = t(common.dateFrom),
    minDate,
    maxDate,
    definedRanges,
    onChange,
    useDiff = false,
}) => {
    const createFieldName = (sectionPrefix, name) => `${!!sectionPrefix ? `${sectionPrefix}.` : ""}${name}`;
    const formValues = useFormValueSelector(
        formName,
        [createFieldName(formSectionName, startDateName), createFieldName(formSectionName, endDateName)],
        {}
    );
    const getFormValue = (name) =>
        createFieldName(formSectionName, name)
            // eslint-disable-next-line
            .split(/[\.\[\]]/)
            .filter((part) => part !== "")
            .reduce((obj, key) => (obj || {})[key], formValues);
    const { classes } = useStyles();
    const [dates, setDates] = useState({
        startDate: getFormValue(startDateName),
        endDate: getFormValue(endDateName),
    });
    const [open, setOpen] = useState(false);
    const [doubleClicked, setDoubleClicked] = useState(false);
    const change = useChange(formName);

    const openPicker = () => {
        setDates({
            startDate: getFormValue(startDateName),
            endDate: getFormValue(endDateName),
        });
        setOpen(true);
    };

    const close = (event) => {
        if (open) {
            if (event.target.closest("div.dateRangeField .MuiButtonBase-root.MuiIconButton-root")) {
                return;
            }
            if (doubleClicked) {
                setDoubleClicked(false);
            } else {
                setOpen(false);
            }
        }
    };

    const updateRange = useDebouncedCallback(async (range) => {
        if ((!moment(range.startDate).isValid() && !range.startDate) || (!moment(range.endDate).isValid() && !range.endDate)) {
            return;
        }

        setOpen(false);

        await change(createFieldName(formSectionName, startDateName), moment(range.startDate).format("YYYY-MM-DD"));
        await change(createFieldName(formSectionName, endDateName), moment(range.endDate).format("YYYY-MM-DD"));

        if (onChange) {
            onChange(range);
        }

        setDates({
            startDate: range.startDate,
            endDate: range.endDate,
        });
    }, 200);

    const onClickFormHandle = (event) => {
        if (event.detail === 2) {
            setDoubleClicked(true);
            openPicker();
        }
    };

    return (
        <div
            className="dateRangeField"
            style={{ position: "relative" }}
        >
            <Grid container>
                <FormField
                    name={startDateName}
                    type={"date"}
                    label={startDateLabel}
                    readOnly={readOnly}
                    validate={startDateValidator}
                    onPickerClick={openPicker}
                    onClick={onClickFormHandle}
                    onChange={(value) => {
                        updateRange({ startDate: value, endDate: dates.endDate });
                    }}
                    useDiff={useDiff}
                />
                <FormField
                    name={endDateName}
                    type={"date"}
                    label={endDateLabel}
                    readOnly={readOnly}
                    validate={endDateValidator}
                    onPickerClick={openPicker}
                    onClick={onClickFormHandle}
                    onChange={(value) => {
                        updateRange({ startDate: dates.startDate, endDate: value });
                    }}
                    useDiff={useDiff}
                />
            </Grid>
            <ClickAwayListener onClickAway={close}>
                <div className={classes.dateRangePicker}>
                    <DateRangePicker
                        open={open}
                        onChange={updateRange}
                        initialDateRange={dates}
                        minDate={minDate}
                        maxDate={maxDate}
                        definedRanges={definedRanges}
                        blockSameDay={blockSameDay}
                    />
                </div>
            </ClickAwayListener>
        </div>
    );
};

export default connect()(withTranslation("common")(DateRangeField));
