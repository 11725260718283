import { types } from "./types";

const initialState = {
    isLoadingCounter: false,
    isLoadingComplaintList: false,
    isLoadingShopScanSummary: false,
    isLoadingPriceListOperationCounter: false,
    complaintsErrorList: { items: [], pageIndex: 1, pageSize: 10 },
    shopScanSummary: [],
    documentArchiveErrors: {
        isLoading: false,
        items: [],
        pageIndex: 1,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
    },
    priceListOperationCounter: [],
};

export const dashboard = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case types.countComplaints.request:
            return { ...state, isLoadingCounter: true };
        case types.countComplaints.success:
            return {
                ...state,
                isLoadingCounter: false,
                complaintsCounter: { ...payload },
            };
        case types.countComplaints.failure:
            return { ...state, isLoadingCounter: false };

        case types.complaintsWithError.request:
            return { ...state, isLoadingComplaintList: true };
        case types.complaintsWithError.success:
            return {
                ...state,
                isLoadingComplaintList: false,
                complaintsErrorList: { ...payload },
            };
        case types.complaintsWithError.failure:
            return { ...state, isLoadingComplaintList: false };

        case types.getShopScanSummary.request:
            return {
                ...state,
                isLoadingShopScanSummary: true,
            };
        case types.getShopScanSummary.success:
            return {
                ...state,
                isLoadingShopScanSummary: false,
                shopScanSummary: [...payload],
            };
        case types.getShopScanSummary.failure:
            return {
                ...state,
                isLoadingShopScanSummary: false,
            };

        case types.priceListOperationCounter.request:
            return {
                ...state,
                isLoadingPriceListOperationCounter: true,
            };
        case types.priceListOperationCounter.success:
            return {
                ...state,
                priceListOperationCounter: [...payload],
                isLoadingPriceListOperationCounter: false,
            };
        case types.priceListOperationCounter.failure:
            return {
                ...state,
                isLoadingPriceListOperationCounter: false,
            };
        case types.getArchiveErrors.request: {
            return {
                ...state,
                documentArchiveErrors: {
                    ...state.documentArchiveErrors,
                    isLoading: true,
                },
            };
        }
        case types.getArchiveErrors.success: {
            return {
                ...state,
                documentArchiveErrors: {
                    ...payload,
                    isLoading: false,
                },
            };
        }
        case types.getArchiveErrors.failure: {
            return {
                ...state,
                documentArchiveErrors: {
                    ...state.documentArchiveErrors,
                    isLoading: false,
                },
            };
        }
        default:
            return { ...state };
    }
};
