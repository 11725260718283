import { commerce, manager } from "store/actionTypePrefixes";
import { AsyncTypes } from "utils/extensions";

const types = {
    initForm: `${manager}/FORM_INIT_FEED`,
    getGiftChoice: new AsyncTypes("GET_GIFT_CHOICE", commerce),
    saveGiftCoice: new AsyncTypes("SAVE_GIFT_CHOICE", commerce),
    getGiftChoiceActivation: new AsyncTypes("GET_GIFT_CHOICE_ACTIVATION", commerce),
    getGiftChoiceValidation: new AsyncTypes("GET_GIFT_CHOICE_VALIDATION", commerce),
};

export default types;
