import { createSelector } from "reselect";
import { ContestForm, RuleForm } from "./model";

const rootCmsSelector = (state) => state.cms;

const contestsSelector = createSelector(rootCmsSelector, (cms) => cms.contests);

export const contestsListSelector = createSelector(contestsSelector, (contests) => contests.list);

export const contestsFormSelector = createSelector(contestsSelector, (contests) => new ContestForm(contests.form));

const rulesSelector = createSelector(rootCmsSelector, (cms) => cms.rules);

export const rulesListSelector = createSelector(rulesSelector, (rules) => rules.list);

export const rulesFormSelector = createSelector(rulesSelector, (rules) => new RuleForm(rules.form));
