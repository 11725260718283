import defaultListState from "store/defaultListState";
import { types as rootTypes } from "../action";
import { types } from "./actions";

const initialState = defaultListState;

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case rootTypes.CUSTOMER_CHANGED:
            return initialState;
        case types.getCustomerBalanceAudit.request:
            return { ...state, isLoading: true };
        case types.getCustomerBalanceAudit.success:
            return { ...state, payload };
        case types.getCustomerBalanceAudit.failure:
            return { ...state, isLoading: false };
        case types.updateCustomerBalanceAuditPagination:
            return { ...state, ...payload };
        case types.getCustomerBalanceAuditProfile:
            return { ...state, ...payload, isLoading: false };
        default:
            return { ...state };
    }
};
