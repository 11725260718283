import { RSAA } from "redux-api-middleware";
import { cache, commerce, manager } from "store/actionTypePrefixes";
import { AsyncTypes, asAsyncFormTypes, createAction } from "utils/extensions";
import { combineIntoQueryParams } from "utils/objectExtensions";
import { create } from "lodash";
import { actions as userInfoActions } from "../../../cache/userInfos/actions";

export const types = {
    getCustomerMemos: new AsyncTypes("CUSTOMER_MEMOS_GET", commerce),
    postCustomerMemos: new AsyncTypes("CUSTOMER_MEMO_POST", commerce, [asAsyncFormTypes]),
    putCustomerMemos: new AsyncTypes("CUSTOMER_MEMOS_PUT", commerce, [asAsyncFormTypes]),
    getCustomerMemoHistory: new AsyncTypes("CUSTOMER_MEMO_AUDIT_GET", commerce),
    getCustomerMemosWithProfileSuccess: createAction("/CUSTOMER_MEMOS_GET_SUCCESS_WITH_PROFILE", cache),
    selectCustomerMemo: create("CUSTOMER_MEMOS_SELECT_MEMO", manager),
    getCustomerMemosAuditWithProfileSuccess: createAction("CUSTOMER_MEMOS_AUDIT_GET_SUCCESS_WITH_PROFILE", cache),
    updateCustomerMemosParams: createAction("UPDATE_CUSTOMER_MEMOS_PARAMS", manager),
    updateCustomerMemoAuditParams: createAction("UPDATE_CUSTOMER_MEMO_AUDIT_PARAMS", manager),
};

export const getMemos =
    ({ customerId, pageIndex, pageSize }) =>
    async (dispatch) => {
        const params = combineIntoQueryParams({
            pageIndex,
            pageSize,
            isActive: true,
        });
        const memosResult = await dispatch({
            [RSAA]: {
                endpoint: `api/v1/users/${customerId}/memo?${params}`,
                method: "GET",
                types: types.getCustomerMemos.all,
            },
        });
        const { items, ...rest } = memosResult.payload;
        const ids = items
            .map((x) => x.createdBy || undefined)
            .reduce((c, n) => {
                if (!n) return c;
                if (c.every((x) => x !== n)) return [...c, n];
                return c;
            }, []);
        await dispatch(userInfoActions.getUserInfos(ids));

        await dispatch({
            type: types.getCustomerMemosWithProfileSuccess,
            payload: {
                ...rest,
                items,
            },
        });
    };

export const createMemo = (customerId, form) => (dispatch) => {
    return dispatch({
        [RSAA]: {
            endpoint: `api/v1/users/${customerId}/memo`,
            method: "POST",
            headers: { "Content-Type": "application/json" },
            types: types.postCustomerMemos.all,
            body: JSON.stringify(form),
        },
    });
};

export const updateMemo = (customerId, memoId, body) => (dispatch) => {
    return dispatch({
        [RSAA]: {
            endpoint: `api/v1/users/${customerId}/memo/${memoId}`,
            method: "PUT",
            headers: { "Content-Type": "application/json-patch+json" },
            types: types.putCustomerMemos.all,
            body: JSON.stringify(body),
        },
    });
};

export const getMemoHistory =
    ({ id, customerId, pageIndex, pageSize }) =>
    async (dispatch) => {
        const params = combineIntoQueryParams({
            pageIndex,
            pageSize,
            isActive: true,
        });
        const memosResult = await dispatch({
            [RSAA]: {
                endpoint: `api/v1/users/${customerId}/memo/${id}/audit?${params}`,
                method: "GET",
                types: types.getCustomerMemoHistory.all,
            },
        });

        const { items, ...rest } = memosResult.payload;
        const ids = items
            .map((x) => x.createdBy || undefined)
            .reduce((c, n) => {
                if (!n) return c;
                if (c.every((x) => x !== n)) return [...c, n];
                return c;
            }, []);

        dispatch(userInfoActions.getUserInfos(ids));
        dispatch({
            type: types.getCustomerMemosAuditWithProfileSuccess,
            payload: {
                ...rest,
                items,
            },
        });
    };

export const selectMemo = (memo) => (dispatch) => {
    dispatch({ type: types.selectCustomerMemo, payload: memo });
};

export const updateCustomerMemosParams = (params) => {
    return { type: types.updateCustomerMemosParams, payload: params };
};

export const updateCustomerMemoAuditParams = (params) => {
    return { type: types.updateCustomerMemoAuditParams, payload: params };
};
