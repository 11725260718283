import { DynamicSegment, DynamicSegmentList } from "views/dynamicSegments";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import { ECommerceDepartment, MarketingDepartment, Role, SupportDepartment, TradeMarketingDepartment } from "routing-ts/roles";

const roles: Role[] = [
    "Management",
    "Analyst",
    ...ECommerceDepartment.GetRoles(),
    ...MarketingDepartment.GetRoles(),
    ...TradeMarketingDepartment.GetRoles(),
    ...SupportDepartment.GetRoles(),
];

export const DynamicSegments: PrivateRoute[] = [
    new PrivateRoute(Paths.DynamicSegments, roles, DynamicSegmentList, "dynamicSegmentList", "pie_chart"),
    new PrivateRoute(Paths.DynamicSegmentForm, roles, DynamicSegment),
];
