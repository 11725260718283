import defaultListState from "store/defaultListState";
import { combineReducers } from "redux";
import types from "./types";

const formInitialState = {
    isLoading: false,
};

const listInitialState = defaultListState;

const form = (state = formInitialState, { type, payload }) => {
    switch (type) {
        case types.formInit:
            return { ...formInitialState };
        case types.getPromotionPeriod.request:
            return {
                ...state,
                isLoading: true,
            };
        case types.getPromotionPeriod.success:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case types.getPromotionPeriod.failure:
            return {
                isLoading: false,
            };
        case types.createPromotionPeriod.request:
        case types.updatePromotionPeriod.request:
            return {
                ...formInitialState,
                isSubbmitRequested: true,
            };
        case types.createPromotionPeriod.success:
        case types.createPromotionPeriod.failure:
        case types.updatePromotionPeriod.success:
        case types.updatePromotionPeriod.failure:
            return {
                ...payload,
                isSubbmitRequested: false,
            };
        default:
            return { ...state };
    }
};

const list = (state = listInitialState, { type, payload }) => {
    switch (type) {
        case types.getPromotionPeriods.request:
            return {
                ...listInitialState,
                isLoading: true,
            };
        case types.getPromotionPeriods.success:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case types.getPromotionPeriods.failure:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return { ...state };
    }
};

export const promotionPeriods = combineReducers({
    form,
    list,
});
