import { CmsForm, CmsList } from "views-ts/cms";
import { ContestForm, ContestsList, RulesForm, RulesList } from "views/cms";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import {
    B2BDepartment,
    ECommerceDepartment,
    MarketingDepartment,
    Role,
    SalesDepartment,
    SupportDepartment,
    TradeMarketingDepartment,
} from "../roles";

const cmsRoles: Role[] = [
    "Analyst",
    "Management",
    "AXUser",
    "HumanResourcesManager",
    ...B2BDepartment.GetRoles(),
    ...SupportDepartment.GetRoles(),
    ...SalesDepartment.GetRoles(),
    ...ECommerceDepartment.GetRoles(),
    ...MarketingDepartment.GetRoles(),
    ...TradeMarketingDepartment.GetRoles(),
];

const contestsRoles: Role[] = [
    "Analyst",
    "Management",
    "AXUser",
    ...B2BDepartment.GetRoles(),
    ...SupportDepartment.GetRoles(),
    ...SalesDepartment.GetRoles(),
    ...ECommerceDepartment.GetRoles(),
    ...MarketingDepartment.GetRoles(),
    ...TradeMarketingDepartment.GetRoles(),
];

const rulesRoles: Role[] = [
    "Analyst",
    "Management",
    ...ECommerceDepartment.GetRoles(),
    ...MarketingDepartment.GetRoles(),
    ...TradeMarketingDepartment.GetRoles(),
];

export const Cms: PrivateRoute[] = [
    new PrivateRoute("/cms", cmsRoles, undefined, "cms", undefined, [
        new PrivateRoute(Paths.Cms.CmsList, cmsRoles, CmsList, "cms", "start"),
        new PrivateRoute(Paths.Cms.CmsForm, cmsRoles, CmsForm),

        new PrivateRoute(Paths.Cms.ContestsList, contestsRoles, ContestsList, "contests", "bookmarks"),
        new PrivateRoute(Paths.Cms.ContestForm, contestsRoles, ContestForm),

        new PrivateRoute(Paths.Cms.RulesList, rulesRoles, RulesList, "rules", "assignment_late"),
        new PrivateRoute(Paths.Cms.RuleForm, rulesRoles, RulesForm),
    ]),
];
