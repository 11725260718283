import {
    MaskRequirement,
    PostcodePredicate,
    RangeRequirement,
    SetRequirement,
} from "Commerce-Shared";
import { ExtendedValidator } from "../extendedValidator";

class SetRequirementValidator extends ExtendedValidator<SetRequirement> {
    constructor() {
        super();

        this.ruleFor("set").notNull();

        this.ruleForEach("set").postcode();
    }
}

const setRequirementValidator =
    new SetRequirementValidator();

class MaskRequirementValidator extends ExtendedValidator<MaskRequirement> {
    constructor() {
        super();

        this.ruleFor("mask").postcodeMask();
    }
}

const maskRequirementValidator =
    new MaskRequirementValidator();

class RangeRequirementValidator extends ExtendedValidator<RangeRequirement> {
    constructor() {
        super();

        this.ruleFor("postcodeFrom")
            .postcode()
            .must((val, model) => {
                if (
                    val === undefined ||
                    model.postcodeTo === undefined
                ) {
                    return true;
                }

                const from = Number(
                    val.replace("-", "")
                );
                const to = Number(
                    model.postcodeTo.replace(
                        "-",
                        ""
                    )
                );

                return from < to;
            })
            .withMessage(
                "Zakres od musi być mniejszy od zakresu do"
            );

        this.ruleFor("postcodeTo")
            .postcode()
            .must((val, model) => {
                if (
                    val === undefined ||
                    model.postcodeTo === undefined
                ) {
                    return true;
                }

                const to = Number(
                    val.replace("-", "")
                );
                const from = Number(
                    model.postcodeFrom.replace(
                        "-",
                        ""
                    )
                );

                return from < to;
            })
            .withMessage(
                "Zakres do musi być większy od zakresu od"
            );
    }
}

const rangeRequirementValidator =
    new RangeRequirementValidator();

export class PostCodePredicateValidator extends ExtendedValidator<PostcodePredicate> {
    constructor() {
        super();

        this.atLeastOneOfFieldNotEmpty([
            "mask",
            "range",
            "set",
        ]);

        this.ruleFor("mask")
            .setValidator(
                maskRequirementValidator
            )
            .whenNotEmpty();

        this.ruleFor("range")
            .setValidator(
                rangeRequirementValidator
            )
            .whenNotEmpty();

        this.ruleFor("set").setValidator(
            setRequirementValidator
        ).whenNotEmpty;
    }
}
