import { Preloader } from "components/Preloader";
import { getProductInternalData } from "store/offers/productPreview/product/action";
import { productProductPreviewSelector } from "store/offers/selectors";
import { makeStyles } from "tss-react/mui";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Card, CardContent } from "@mui/material";
import ProductImage from "./ProductImage";
import ProductInternalInfo from "./ProductInternalInfo";

const useStyles = makeStyles()(() => ({
    root: {
        display: "inline-flex",
    },
    details: {
        display: "inline-flex",
        flexDirection: "column",
    },
    content: {
        flex: "1 0 auto",
    },
}));

const ProductInternalData = ({ imageUrl }) => {
    const { classes } = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();

    const product = useSelector(productProductPreviewSelector);

    useEffect(() => {
        dispatch(getProductInternalData(id));
    }, [dispatch, id]);

    if (!product) {
        return <Preloader />;
    }

    return (
        <Fragment>
            <div>
                <Card className={classes.container}>
                    <ProductImage imageUrl={imageUrl} />
                    <div className={classes.details}>
                        <CardContent className={classes.content}>
                            <ProductInternalInfo {...product} />
                        </CardContent>
                    </div>
                </Card>
            </div>
        </Fragment>
    );
};

export default ProductInternalData;
