import { RSAA } from "redux-api-middleware";
import { commerce } from "store/actionTypePrefixes";

const CUSTOMER_BALANCE_GET = `${commerce}/GET_CUSTOMER_BALANCE_REQUEST`;
const CUSTOMER_BALANCE_GET_SUCCESS = `${commerce}/GET_CUSTOMER_BALANCE_SUCCESS`;
const CUSTOMER_BALANCE_GET_FAILURE = `${commerce}/GET_CUSTOMER_BALANCE_FAILURE`;

export const types = {
    CUSTOMER_BALANCE_GET,
    CUSTOMER_BALANCE_GET_SUCCESS,
    CUSTOMER_BALANCE_GET_FAILURE,
};

const getCustomerBalance = (customerId) => (dispatch) => {
    dispatch({
        [RSAA]: {
            endpoint: `api/v1/users/${customerId}/balance`,
            method: "GET",
            types: [CUSTOMER_BALANCE_GET, CUSTOMER_BALANCE_GET_SUCCESS, CUSTOMER_BALANCE_GET_FAILURE],
        },
    });
};

export const actions = {
    getCustomerBalance,
};
