import { makeStyles } from "tss-react/mui";
import React from "react";
import { Grid } from "@mui/material";

const useStyles = makeStyles<{ widthMulipier: number }>()((_, { widthMulipier }) => ({
    item: { width: widthMulipier * 280 },
    fullWidth: { width: "100%" },
    autoWidth: { width: "auto" },
}));

const ControlRoot: React.FC<{
    children: React.ReactNode;
    useGridItem?: boolean;
    width?: "fullWidth" | "standard" | "double" | "triple" | "auto" | number;
}> = ({ children, useGridItem = true, width = "standard" }) => {
    const isFullWidth = width === "fullWidth";
    const widthMulipier =
        typeof width === "number" ? (width ?? 0) / 280 : width === "standard" ? 1 : width === "double" ? 2 : width === "triple" ? 3 : 0;
    const isAutoWidth = width === "auto";
    const { classes } = useStyles({ widthMulipier });

    if (useGridItem) {
        return (
            <Grid
                item
                xs={isFullWidth ? 12 : isAutoWidth ? "auto" : undefined}
            >
                <div className={isFullWidth ? classes.fullWidth : isAutoWidth ? classes.autoWidth : classes.item}>{children}</div>
            </Grid>
        );
    }

    return <div className={isFullWidth ? classes.fullWidth : isAutoWidth ? classes.autoWidth : classes.item}>{children}</div>;
};

export default ControlRoot;
