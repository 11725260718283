import { types as rootTypes } from "../action";
import { types } from "./action";

const initialState = {
    isFileDownloading: false,
    isLoading: false,
    userId: "",
    isMailingAllowed: false,
    isTextingAllowed: false,
    consents: [],
    missingConsents: [],
    unacceptedConsents: [],
    history: [],
};

const onGet = (state) => ({
    ...state,
    isLoading: true,
});

const onGetSuccess = (state, payload) => ({
    ...state,
    ...payload,
    isLoading: false,
});

const onGetFailure = (state) => ({
    ...state,
    isLoading: false,
});

const exportProfileGet = (state) => ({
    ...state,
    isFileDownloading: true,
});

const exportProfileGetSuccess = (state) => ({
    ...state,
    isFileDownloading: false,
});

const exportProfileGetFailure = (state) => ({
    ...state,
    isFileDownloading: false,
});

const defaultAction = (state) => ({ ...state });

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case rootTypes.CUSTOMER_CHANGED:
            return initialState;
        case types.CUSTOMER_GET_CONSENTS:
            return onGet(state);
        case types.CUSTOMER_GET_CONSENTS_SUCCESS:
            return onGetSuccess(state, payload);
        case types.CUSTOMER_GET_CONSENTS_FAILURE:
            return onGetFailure(state, payload);
        case types.CUSTOMER_EXPORT_PROFILE:
            return exportProfileGet(state);
        case types.CUSTOMER_EXPORT_PROFILE_SUCCESS:
            return exportProfileGetSuccess(state, payload);
        case types.CUSTOMER_EXPORT_PROFILE_FAILURE:
            return exportProfileGetFailure(state, payload);
        default:
            return defaultAction(state);
    }
};
