import { firstOrDefault } from "utils/extensions/arrayExtensions";
import React, { Fragment } from "react";
import { Avatar, ListItemIcon, ListItemText } from "@mui/material";

const ProductItem = ({ v, products }) => {
    const { product } = firstOrDefault(products, (x) => x.productId === v, {
        product: {},
    });
    const { imageUrl = "", brand = "", name = "" } = product;
    return (
        <Fragment>
            <ListItemIcon>
                <Avatar
                    variant="square"
                    src={imageUrl}
                ></Avatar>
            </ListItemIcon>
            <ListItemText primary={`(${v}) ${brand} - ${name}`} />
        </Fragment>
    );
};

export default ProductItem;
