import tableStyle from "assets/jss/material-dashboard-react/components/tableStyle";
import tasksStyle from "assets/jss/material-dashboard-react/components/tasksStyle";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Cell from "components/Table/SimpleTable/Cell";
import Row from "components/Table/SimpleTable/Row";
import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import Action from "components/Table/SimpleTable/TableActions/Action";
import tableActionTypes from "components/Table/SimpleTable/TableActions/tableActionTypes";
import { AccountantDepartmentRoles, LogisticianDepartmentRoles } from "consts/roles.js";
import { UserConsumer } from "context/index.js";
import { actions } from "store/order/complaint/actions";
import { actions as orderActions } from "store/order/order/action";
import { withStyles } from "tss-react/mui";
import { formatTypes, formatValue, stringStyle, timePrecisonFormats } from "utils/formating";
import { withPush } from "utils/hoc";
import { splitOrderId } from "utils/splitIds";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { common, orders } from "translations";
import { baseWithEcommerce } from "routing-ts/Routes/CustomerRoles";

const styles = {
    ...tasksStyle,
    ...tableStyle,
};

class ComplaintContainer extends Component {
    constructor(props) {
        super(props);
        const { t } = props;
        this.columns = [
            {
                key: "complaintId",
                value: "Id",
                type: formatTypes.text,
            },
            {
                key: "orderId",
                value: t(orders.orderId),
                type: formatTypes.text,
            },
            {
                key: "complaintNumber",
                value: t(orders.complaintNumber),
                type: formatTypes.text,
                options: { style: stringStyle.imporant },
            },
            {
                key: "futureOrderDeliveryDate",
                value: t(orders.futureOrderDeliveryDate),
                type: formatTypes.text,
            },
            {
                key: "complaintValue",
                value: t(orders.complaintValue),
                type: formatTypes.currency,
            },
            {
                key: "correctionDocumentValue",
                value: t(orders.correctionDocumentValue),
                type: formatTypes.currency,
            },
            {
                key: "complaintStatus",
                value: t(orders.status),
                type: formatTypes.translatable,
                options: { translationKey: "common" },
            },
            {
                key: "correctedDocumentId",
                value: t(orders.correctionDocument),
                type: formatTypes.action,
                action: this.props.getOrderDocument,
                actionType: tableActionTypes.link,
            },
            {
                key: "editedBy",
                value: t(`common:${common.editedBy}`),
                type: formatTypes.text,
            },
            {
                key: "updatedAt",
                value: t(`common:${common.updatedAt}`),
                type: formatTypes.time,
                options: { timeFormat: timePrecisonFormats.seconds },
            },
            {
                key: "processedAt",
                value: t(`common:${common.processedAt}`),
                type: formatTypes.time,
                options: { timeFormat: timePrecisonFormats.seconds },
            },
            { key: "action", value: "", type: formatTypes.action },
        ];
    }

    componentDidMount = () => {
        const { orderNumber, customerId, operationsDocumentIds } = this.props;
        this.props.getComplaints(customerId, orderNumber, operationsDocumentIds, 1, 25);
    };

    handleGoToComplaint = ({ customerId, orderNumber, documentId, complaintId }) => {
        this.props.pushToForm(`/customers/${customerId}/orders/${orderNumber}/complaints/${documentId}`, complaintId);
    };

    handlePageChange = (pageIndex) => {
        const { orderNumber, customerId, operationsDocumentIds } = this.props;
        const { pageSize } = this.props.complaints;
        this.props.getComplaints(customerId, orderNumber, operationsDocumentIds, pageIndex, pageSize);
    };

    handlePageSizeChange = (pageSize) => {
        const { orderNumber, customerId, operationsDocumentIds } = this.props;
        const { pageIndex } = this.props.complaints;
        this.props.getComplaints(customerId, orderNumber, operationsDocumentIds, pageIndex, pageSize);
    };

    render = () => {
        const { complaints, customerId } = this.props;
        const { items, totalCount, pageIndex, pageSize, isLoading } = complaints;
        return (
            <UserConsumer>
                {({ isInAnyRoleOrAdmin }) => (
                    <GridContainer>
                        <GridItem xs={12}>
                            <SimpleTable
                                columns={this.columns}
                                isLoading={isLoading}
                                pagination
                                stripped
                                paginationProps={{
                                    pageIndex,
                                    totalCount,
                                    pageSize,
                                    onPageChange: this.handlePageChange,
                                    onRowsPerPageChange: this.handlePageSizeChange,
                                }}
                            >
                                {items.map((item) => {
                                    const { complaintId, isClosed, orderId, documentId } = item;
                                    const orderNumber = orderId.split("/")[1];
                                    const goToComplaint = () => {
                                        if (isInAnyRoleOrAdmin([...baseWithEcommerce, ...LogisticianDepartmentRoles, ...AccountantDepartmentRoles])) {
                                            this.handleGoToComplaint({
                                                customerId,
                                                orderNumber,
                                                documentId,
                                                complaintId,
                                            });
                                        }
                                    };

                                    return (
                                        <Row
                                            key={complaintId}
                                            hover
                                            onDobuleClick={goToComplaint}
                                        >
                                            {this.columns.map(({ key, type, options, action, actionType }) => {
                                                if (key === "action")
                                                    return (
                                                        <Action
                                                            key={key}
                                                            actionType={!isClosed ? "edit" : "preview"}
                                                            handleClick={goToComplaint}
                                                        />
                                                    );

                                                if (key === "correctedDocumentId") {
                                                    if (item.correctedDocumentId && item.complaintStatus === "CORRECTED") {
                                                        return (
                                                            <Action
                                                                key={key}
                                                                handleClick={() => {
                                                                    const { orderNumber, customerId } = splitOrderId(item.orderId);

                                                                    action(
                                                                        customerId,
                                                                        orderNumber,
                                                                        item.correctedDocumentId,
                                                                        item.correctedDocumentNumber
                                                                    );
                                                                }}
                                                                actionType={actionType}
                                                                text={item.correctedDocumentNumber}
                                                            />
                                                        );
                                                    } else {
                                                        return <Cell key={key}></Cell>;
                                                    }
                                                }

                                                return (
                                                    <Cell
                                                        key={key}
                                                        value={formatValue(item[key], type, options)}
                                                        format={type}
                                                    />
                                                );
                                            })}
                                        </Row>
                                    );
                                })}
                            </SimpleTable>
                        </GridItem>
                    </GridContainer>
                )}
            </UserConsumer>
        );
    };
}

const mapStateToProps = (state) => {
    const { complaints } = state;

    return { complaints };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ ...actions, ...orderActions }, dispatch);

export default withPush(withTranslation(["orders", "common"])(connect(mapStateToProps, mapDispatchToProps)(withStyles(ComplaintContainer, styles))));
