import { RSAA } from "redux-api-middleware";
import { GetRequest, PostRequest, get, post } from "utils/extensions";
import { downloadPdfFile } from "utils/rsaa/downloadFileAction";
import types from "./types";

const endpoint = (customerId, deferredPaymentId) =>
    `api/v1/users/${customerId}/deferred-payment/${deferredPaymentId ? deferredPaymentId + "/audit" : ""}`;

const submitEndpoint = (customerId, deferredPaymentId) => `api/v1/users/${customerId}/deferred-payment/${deferredPaymentId}`;

const decisionEndpoint = (customerId, deferredPaymentId, decision) => `api/v1/users/${customerId}/deferred-payment/${deferredPaymentId}/${decision}`;

const downloadFileEndpoint = (customerId, deferredPaymentId, status) =>
    `api/v1/users/${customerId}/deferred-payment/${deferredPaymentId}/file?formState=${status}`;

export const getDeferredPayments = (customerId, params) => async (dispatch) => {
    return dispatch(
        get(
            new GetRequest(endpoint(customerId), types.getDeferredPayments.all, {
                queryParams: params,
            })
        )
    );
};

export const getDeferredPayment = (customerId, deferredPaymentId) => async (dispatch) => {
    await dispatch({ type: types.formInit });

    if (deferredPaymentId) {
        return dispatch(get(new GetRequest(endpoint(customerId, deferredPaymentId), types.getDeferredPayment.all)));
    }
};

export const acceptDeferredPayment = (customerId, deferredPaymentId, file) => async (dispatch) => {
    let data = new FormData();
    data.append("file", file);

    return dispatch({
        [RSAA]: {
            endpoint: decisionEndpoint(customerId, deferredPaymentId, "accept"),
            method: "POST",
            types: types.makeDecision.all,
            body: data,
        },
    });
};

export const rejectDeferredPayment = (customerId, deferredPaymentId, file, comment) => async (dispatch) => {
    let data = new FormData();
    data.append("file", file);
    data.append("comment", comment);

    return dispatch({
        [RSAA]: {
            endpoint: decisionEndpoint(customerId, deferredPaymentId, "reject"),
            method: "POST",
            types: types.makeDecision.all,
            body: data,
        },
    });
};

export const getDocumentFile = (customerId, documentId, status) => async (dispatch) => {
    await dispatch(
        downloadPdfFile(
            downloadFileEndpoint(customerId, documentId, status),
            types.downloadFile.all,
            customerId + "_FormularzPlatnosciOdroczonej.pdf"
        )
    );

    return Promise.resolve();
};

export const createDeferredPayment = (customerId, values) => async (dispatch) => {
    await dispatch(
        post(
            new PostRequest(endpoint(customerId), types.createDeferredPayment.all, {
                body: {
                    ...values,
                },
            })
        )
    );
};

export const submitDeferredPayment = (customerId, deferredPaymentId, file) => async (dispatch) => {
    let data = new FormData();
    data.append("file", file);

    return dispatch({
        [RSAA]: {
            endpoint: submitEndpoint(customerId, deferredPaymentId) + "/file",
            method: "POST",
            types: types.submitDeferredPayment.all,
            body: data,
        },
    });
};

export const editDeferredPayment = (customerId, deferredPaymentId, values) => async (dispatch) => {
    await dispatch(
        post(
            new PostRequest(submitEndpoint(customerId, deferredPaymentId), types.editDeferredPayment.all, {
                body: {
                    ...values,
                },
            })
        )
    );
};
