import { FormField } from "components/Form";
import { change } from "redux-form";
import bonusPoints from "resource/bonusPoints.json";
import { useCommonTranslation } from "utils/hooks";
import { isEmpty, notEmptyAndInteger } from "utils/validators/basic";
import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { MembershipPointsEffect } from "./model";

const MembershipPoints = ({ formName, field, effect }) => {
    const { t, common } = useCommonTranslation();
    const dispatch = useDispatch();
    const changeType = async (type) => {
        dispatch(change(formName.form, formName.sectionPrefix ? formName.sectionPrefix : field, new MembershipPointsEffect({ [type]: 1 })));
    };
    const { effectType, ...value } = effect;
    const points = bonusPoints.find((b) => b.value == value[effectType]?.type);

    return (
        <Fragment>
            <FormField
                name="type"
                label={t(common.modificationType)}
                type="select"
                items={bonusPoints}
                onChange={(_, value) => {
                    if (effect.type !== value) {
                        changeType(value);
                    }
                }}
                hideDefaultItem
                validate={isEmpty}
            />
            <FormField
                name={value[effectType]?.type || ""}
                label={points?.name || "Punkty"}
                type="number"
                validate={notEmptyAndInteger}
            />
        </Fragment>
    );
};

export default MembershipPoints;
