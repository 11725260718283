import { RSAA } from "redux-api-middleware";
import { combineIntoQueryParams } from "utils/objectExtensions";
import throwIf from "utils/utilsFunctions/throwIf";

export const httpMethods = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
};

export const httpHeaders = {
    json: { "Content-Type": "application/json" },
};

export class ApiRequest {
    constructor(apiEndpoint, types, params, requestOptions) {
        const { queryParams, body } = params || {};
        const { method, headers } = requestOptions || {};

        throwIf(!apiEndpoint, "Endpoint must be defined");
        throwIf(typeof apiEndpoint !== "string", "Endpoint must be string");
        throwIf(!Array.isArray(types), "Types must be array");
        throwIf(!method, "Method must be defined");

        this.apiEndpoint = apiEndpoint;
        this.types = types;
        this.queryParams = queryParams;
        this.method = method;
        this.headers = headers;

        if (body) this.body = body;

        this.endpoint = !this.queryParams ? this.apiEndpoint : `${this.apiEndpoint}?${combineIntoQueryParams(this.queryParams)}`;
    }
}

export class GetRequest extends ApiRequest {
    constructor(apiEndpoint, types, params, requestOptions) {
        const { queryParams, bodyParams } = params || {};
        super(
            apiEndpoint,
            types,
            {
                queryParams: { ...queryParams },
                bodyParams,
            },
            requestOptions || {
                method: httpMethods.GET,
                headers: httpHeaders.json,
            }
        );
    }
}

export class PostRequest extends ApiRequest {
    constructor(apiEndpoint, types, params) {
        super(apiEndpoint, types, params, {
            method: httpMethods.POST,
            headers: params.body && params.body instanceof FormData ? {} : httpHeaders.json,
        });
    }
}

export class PutRequest extends ApiRequest {
    constructor(apiEndpoint, types, params) {
        super(apiEndpoint, types, params, {
            method: httpMethods.PUT,
            headers: params.body && params.body instanceof FormData ? {} : httpHeaders.json,
        });
    }
}

export class DeleteRequest extends ApiRequest {
    constructor(apiEndpoint, types, params) {
        super(apiEndpoint, types, params, {
            method: httpMethods.DELETE,
            headers: httpHeaders.json,
        });
    }
}

export class PagedRequest extends ApiRequest {
    constructor(pageIndex, pageSize, apiEndpoint, types, params, requestOptions) {
        const { queryParams, bodyParams } = params || {};
        super(
            apiEndpoint,
            types,
            {
                queryParams: { ...queryParams, pageIndex, pageSize },
                bodyParams,
            },
            requestOptions || {
                method: httpMethods.GET,
                headers: httpHeaders.json,
            }
        );
    }
}

export const get = (request) => async (dispatch) => {
    if (!(request instanceof ApiRequest)) throw Error(`Request must be of type ApiRequest`);

    return dispatch({
        [RSAA]: {
            endpoint: request.endpoint,
            method: httpMethods.GET,
            types: request.types,
            ...(request.body ? { body: request.body } : {}),
        },
    });
};

export const getPaged = (request) => async (dispatch) => {
    if (!(request instanceof PagedRequest)) throw Error(`Request must be of type PagedRequest`);

    return await dispatch({
        [RSAA]: {
            endpoint: request.endpoint,
            method: request.method,
            types: request.types,
            ...(request.body ? { body: request.body } : {}),
        },
    });
};

export const remove = (request) => async (dispatch) => {
    if (!(request instanceof ApiRequest)) throw Error(`Request must be of type ApiRequest`);

    return dispatch({
        [RSAA]: {
            endpoint: request.endpoint,
            method: request.method,
            types: request.types,
            headers: request.headers,
            ...(request.body ? { body: JSON.stringify(request.body) } : {}),
        },
    });
};

export const post = (request) => async (dispatch) => {
    if (!(request instanceof ApiRequest)) throw Error(`Request must be of type PostRequest`);

    return dispatch({
        [RSAA]: {
            endpoint: request.endpoint,
            method: httpMethods.POST,
            types: request.types,
            headers: request.headers,
            ...(request.body
                ? {
                      body: request.body instanceof FormData ? request.body : JSON.stringify(request.body),
                  }
                : {}),
        },
    });
};

export const put = (request) => async (dispatch) => {
    if (!(request instanceof ApiRequest)) throw Error(`Request must be of type PutRequest`);

    return dispatch({
        [RSAA]: {
            endpoint: request.endpoint,
            method: httpMethods.PUT,
            types: request.types,
            headers: request.headers,
            ...(request.body
                ? {
                      body: request.body instanceof FormData ? request.body : JSON.stringify(request.body),
                  }
                : {}),
        },
    });
};
