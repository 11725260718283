import { notEmpty } from "utils/validators/basic";
import emailValidator from "utils/validators/emailValidator";
import passwordValidator from "utils/validators/passwordValidator";
import phoneNumberValidator from "utils/validators/phoneNumberValidator";
import validateBillingAddress from "utils/validators/validateBillingAddress";
import validateFullName from "utils/validators/validateFullName";
import { chain } from "lodash";
import { tValidation, validation } from "utils-ts/validations/translation";
import BillingAdressForm from "./BillingAdressForm";
import PasswordForm from "./PasswordForm";
import PersonalInfoForm from "./PersonalInfoForm";
import { styles } from "./styles";

const anyFilled = (billingAddress) => {
    return chain(billingAddress)
        .reduce((c, n) => c || n)
        .value();
};

export const validate = (values) => {
    const errors = { firstName: {}, billingAddress: {} };
    errors.fullName = validateFullName(values.fullName);
    errors.email = emailValidator(values.email).validate();
    errors.phoneNumber = phoneNumberValidator(values.phoneNumber).must(notEmpty).validate();
    errors.mobileNumber = phoneNumberValidator(values.mobileNumber).validate();
    if (values.billingAddress && anyFilled(values.billingAddress)) errors.billingAddress = validateBillingAddress(values.billingAddress);
    errors.password = passwordValidator(values.password)
        .allWhen(values.password !== "" && values.password !== undefined)
        .validate();
    errors.passwordRepetition = passwordValidator(values.passwordRepetition)
        .must((pR) => pR === values.password, tValidation(validation.passwordMustMatch))
        .allWhen(values.password !== "" && values.password !== undefined)
        .validate();

    return errors;
};

export const BillingAddress = BillingAdressForm;
export const Password = PasswordForm;
export const PersonalInfo = PersonalInfoForm;

export default {
    BillingAddress,
    Password,
    PersonalInfo,
    validate,
    styles,
};
