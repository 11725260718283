import { AccountantDepartment, DepratmentManagersRoles, Role } from "routing-ts/roles";

export const DocumentArchiveRoles: Role[] = [
    "Auditor",
    "OfficeManager",
    "ProjectManager",
    "Archiver",
    "Catman",
    "SalesAssistant",
    ...AccountantDepartment.GetRoles(),
    ...DepratmentManagersRoles,
];

export const DocumentArchiveAccessRoles: Role[] = [...DepratmentManagersRoles, "Auditor"];
