import { IconButton } from "components/Button";
import Button from "components/Button/Button";
import { ContactTypeAutocomplete } from "components/Controls/Autocompletes";
import { FormField } from "components/Form";
import { FieldArray, FormSection, reduxForm } from "redux-form";
import { useContactTypeEffect } from "store/autocomplete";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { common, vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { IsVendorReadOnly } from "views/vendors/IsVendorReadOnly";
import DividerInGrid from "../DividerInGrid";
import { formStyle } from "../styles";
import { validators } from "../validators";

const Contacts = ({ handleSubmit, updateVendor, isSubbmitRequested }) => {
    const { t } = useTranslation(["vendor", "common"]);
    const isVendorReadOnly = IsVendorReadOnly(formName);

    useContactTypeEffect();

    return (
        <form
            onSubmit={handleSubmit((values) => updateVendor(values))}
            style={formStyle}
        >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
            >
                <FieldArray
                    name="contactPeople"
                    formName={formName}
                    component={ContactComponent}
                    t={t}
                    readOnly={isVendorReadOnly}
                />
            </Grid>
            <Button
                actionType="accept"
                type="submit"
                fullWidth
                loading={isSubbmitRequested}
                disabled={isVendorReadOnly}
            >
                {t(`common:${common.save}`)}
            </Button>
        </form>
    );
};

const ContactComponent = ({ fields, t, readOnly }) => {
    return (
        <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
        >
            {fields.map((field, index) => {
                const isLastItem = index + 1 === fields.length;
                return (
                    <FormSection
                        key={index}
                        name={field}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <Grid
                                    item
                                    style={{ width: 350, paddingLeft: 5 }}
                                >
                                    <ContactTypeAutocomplete
                                        label={t(vendor.typeOfContact)}
                                        type="select"
                                        hideDefaultItem={true}
                                        validate={validators.required}
                                        readOnly={readOnly}
                                    />
                                </Grid>
                                <FormField
                                    name={"jobPosition"}
                                    label={t(vendor.jobPosition)}
                                    type="text"
                                    validate={validators.required}
                                    readOnly={readOnly}
                                />
                                <FormField
                                    name={"personName"}
                                    label={t(vendor.personName)}
                                    type="text"
                                    validate={validators.required}
                                    readOnly={readOnly}
                                />
                                <Grid
                                    item
                                    xs={1}
                                    style={{ marginLeft: "auto" }}
                                >
                                    <IconButton
                                        icon="delete"
                                        onClick={() => {
                                            fields.remove(index);
                                        }}
                                        disabled={readOnly}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <FormField
                                    name={"email"}
                                    label={t(vendor.email)}
                                    type="text"
                                    validate={validators.email}
                                    readOnly={readOnly}
                                />

                                <FormField
                                    name={"phoneNumber"}
                                    label={t(vendor.phoneNumber)}
                                    type="text"
                                    validate={validators.phoneNumber}
                                    mask={{
                                        format: formatPhoneNumber,
                                    }}
                                    readOnly={readOnly}
                                />

                                <FormField
                                    name={"comment"}
                                    label={t(`common:${common.comment}`)}
                                    type="text"
                                    readOnly={readOnly}
                                />
                            </Grid>

                            <DividerInGrid hidden={isLastItem === true} />
                        </Grid>
                    </FormSection>
                );
            })}

            <Grid
                item
                style={{ marginLeft: "auto" }}
            >
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        fields.push({});
                    }}
                    disabled={readOnly}
                >
                    {t(`common:${common.add}`)}
                </Button>
            </Grid>
        </Grid>
    );
};

const formatPhoneNumber = (value) => {
    let cleaned = ("" + value).replace(/\D/g, "");
    if (cleaned.length === 0) {
        return cleaned;
    }

    let matchStation = cleaned.match(/^(\d{2})(\d{2})(\d{3})(\d{2})(\d{2})$/);

    if (matchStation) {
        return `(+${matchStation[1]}) ${matchStation[2]} ${matchStation[3]} ${matchStation[4]} ${matchStation[5]}`;
    }

    let match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,3})$/);
    if (match) {
        return `${match[1]} ${match[2]} ${match[3]}`;
    }

    return value;
};

const formName = "vendor-contacts-form";

const stateToProps = (state) => {
    const { vendorPms } = state;
    const { isSubbmitRequested, vendorForm } = vendorPms;
    const { contactPeople = [] } = vendorForm;

    return {
        isSubbmitRequested,
        initialValues: {
            contactPeople,
        },
    };
};

export default connect(stateToProps)(
    reduxForm({
        form: formName,
        enableReinitialize: true,
    })(Contacts)
);
