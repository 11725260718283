import { FormField } from "components/Form";
import { FormSection } from "redux-form";
import { withFormName } from "utils/hoc";
import { useCommonTranslation, useFormValueSelector } from "utils/hooks";
import { Grid } from "@mui/material";

const channelOptions = [
    { value: "FRS", name: "Frisco" },
    { value: "XPR", name: "Express" },
];

const ChannelSelectField = ({ name, disabledItems, ...props }) => {
    const { t, common } = useCommonTranslation();

    return (
        <FormField
            {...props}
            name={name}
            label={t(common[`${name}Channel`])}
            type="select"
            multiple
            items={channelOptions.map((p) => (disabledItems.some((x) => x === p.value) ? { ...p, disabled: true } : p))}
        />
    );
};

const ChannelPredicate = ({ form, sectionPrefix }) => {
    const { included = [], excluded = [] } = useFormValueSelector(form, sectionPrefix + ".channel", {});

    return (
        <FormSection name="channel">
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-end"
            >
                <ChannelSelectField
                    name="included"
                    disabledItems={excluded}
                />
                <ChannelSelectField
                    name="excluded"
                    disabledItems={included}
                />
            </Grid>
        </FormSection>
    );
};

export default withFormName(ChannelPredicate);
