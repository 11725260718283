import { timePrecisonFormats } from "utils/formating";
import { getAccountingPeriod } from "utils/utilsFunctions";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { common } from "translations";
import Alert from "./Alert";

const AccountingPeriodAlert = ({ accountingPeriods, date }) => {
    date = date?.current || date?.proposal || date;
    const { t } = useTranslation("common");
    const [isAlertOpen, setIsAlertOpen] = useState(true);
    const accountingPeriod = getAccountingPeriod(accountingPeriods, date);

    const accountingPeriodsEndsInDays = accountingPeriod
        ? moment(accountingPeriod.closingDate).endOf("day").diff(moment().startOf("day"), "day")
        : 100;

    let alertProps = {
        severity: "info",
        title: t(common.accountingPeriod),
        texst: "",
    };

    if (!accountingPeriod || (!accountingPeriod.isClosed && accountingPeriodsEndsInDays > 10)) {
        return <></>;
    } else if (!accountingPeriod.isClosed && accountingPeriodsEndsInDays <= 10) {
        alertProps = {
            ...alertProps,
            text: t(common.accountingPeriodClosingSoon, {
                when: moment().startOf("day").add(accountingPeriodsEndsInDays, "days").format(timePrecisonFormats.seconds),
            }),
        };
    } else if (accountingPeriod.overrides?.some((o) => !o.isCompleted)) {
        alertProps = {
            ...alertProps,
            text: t(common.accountingPeriodOverridePeriod, {
                until: moment(accountingPeriod.overrides?.filter((o) => !o.isCompleted)[0].dateTo).format(timePrecisonFormats.seconds),
            }),
        };
    } else {
        alertProps = {
            ...alertProps,
            severity: "warning",
            text: t(common.accountingPeriodClosed),
        };
    }

    return (
        <Alert
            {...alertProps}
            open={isAlertOpen}
            handleClose={() => setIsAlertOpen(false)}
        />
    );
};

export default AccountingPeriodAlert;
