import { tValidation, validation } from "utils-ts/validations/translation";
import Validator, { inRange } from "./basic";

export default function phoneValidator(phoneNumber = "") {
    if (!phoneNumber) return new Validator(phoneNumber);
    return new Validator(phoneNumber)
        .must(
            inRange(9, 20),
            tValidation(validation.phoneNumberMustHaveDigitsCount, {
                min: 9,
                max: 20,
            })
        )
        .must((x) => x.toString().match(/^(.*[0-9]){9,}.*$/), tValidation(validation.phoneNumberRegex));
}
