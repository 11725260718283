import Cell from "components/Table/SimpleTable/Cell";
import { makeStyles } from "tss-react/mui";
import { useCommonTranslation } from "utils/hooks";
import React from "react";
import { Tooltip as MuiTooltip, Typography } from "@mui/material";
import _ from "lodash";
import PropTypes from "prop-types";
import { Accept, Add, Copy, Delete, Download, Edit, Link, LinkIcon, LinkOffIcon, List, Menu, Preview, Reject, Retry, Select } from "./";
import ActionButton from "./ActionButton";
import tableActionTypes from "./tableActionTypes";

const useStyles = makeStyles()((theme) => ({
    tooltipText: {
        color: "#fff",
        fontSize: theme.typography.pxToRem(16),
    },
    padding: {
        padding: 5,
    },
}));

const map = new Map([
    [tableActionTypes.add, (props) => <Add {...props} />],
    [tableActionTypes.edit, (props) => <Edit {...props} />],
    [tableActionTypes.preview, (props) => <Preview {...props} />],
    [tableActionTypes.delete, (props) => <Delete {...props} />],
    [tableActionTypes.link, (props) => <Link {...props} />],
    [tableActionTypes.menu, (props) => <Menu {...props} />],
    [tableActionTypes.copy, (props) => <Copy {...props} />],
    [tableActionTypes.retry, (props) => <Retry {...props} />],
    [tableActionTypes.list, (props) => <List {...props} />],
    [tableActionTypes.select, (props) => <Select {...props} />],
    [tableActionTypes.button, (props) => <ActionButton {...props} />],
    [tableActionTypes.linkDocument, (props) => <LinkIcon {...props} />],
    [tableActionTypes.unlinkDocument, (props) => <LinkOffIcon {...props} />],
    [tableActionTypes.download, (props) => <Download {...props} />],
    [tableActionTypes.accept, (props) => <Accept {...props} />],
    [tableActionTypes.reject, (props) => <Reject {...props} />],
]);

const action = ({ actionType, isLoading, format, ...rest }) => {
    let iconFunc = map.get(actionType);
    if (iconFunc) {
        return (
            <Tooltip
                title={rest?.buttonText || actionType}
                format={format}
            >
                {iconFunc({ ...rest, isLoading: isLoading })}
            </Tooltip>
        );
    }

    return undefined;
};

const Tooltip = React.memo(({ title, children, format }) => {
    const { classes } = useStyles();
    const { t } = useCommonTranslation();
    return (
        <Cell format={format}>
            <MuiTooltip
                title={
                    <Typography
                        className={classes.tooltipText}
                        variant="body2"
                    >
                        {t(title)}
                    </Typography>
                }
                placement="right-start"
                arrow
            >
                <div>{children}</div>
            </MuiTooltip>
        </Cell>
    );
});

export default action;

action.propTypes = {
    actionType: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf(_.values(tableActionTypes))]).isRequired,
    handleClick: PropTypes.func,
    fab: PropTypes.bool,
};
