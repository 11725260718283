import { withStyles } from "tss-react/mui";
import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { withTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { Grid } from "@mui/material";
import _ from "lodash";
import { content } from "translations";

const internalStyles = (theme) => ({
    progress: {
        marginRight: theme.spacing(2),
    },
});

const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
};

const activeStyle = {
    borderColor: "#2196f3",
};

const acceptStyle = {
    borderColor: "#00e676",
};

const rejectStyle = {
    borderColor: "#ff1744",
};

const ImageDrop = (props) => {
    const { t, onDrop, onDropRejected, imageUploadInProgress, classes, disabled, acceptedFiles, message, maxFileSize } = props;

    const acceptedExtensions = _.uniq(acceptedFiles.map((x) => x.extension));

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        accept: _.uniq(acceptedFiles.map((x) => x.mimeType)).reduce((acc, key) => {
            acc[key] = [];
            return acc;
        }, {}),
        onDrop,
        onDropRejected,
        maxSize: maxFileSize ?? 10_485_760, //10MiB
    });
    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragAccept, isDragActive, isDragReject]
    );

    return (
        <Grid
            item
            xs={12}
            sm={12}
            md={12}
        >
            <div className="clickable-container">
                <div {...getRootProps({ style })}>
                    {imageUploadInProgress ? (
                        <CircularProgress
                            className={classes.progress}
                            size={15}
                        />
                    ) : (
                        <React.Fragment>
                            <input
                                {...getInputProps()}
                                disabled={disabled}
                            />
                            <p>{message ?? t(content.toAddNewImagesDragThemOrClickHere)}</p>
                            <em>
                                {t(content.acceptedFiles, {
                                    acceptedFiles:
                                        acceptedExtensions.length === 1
                                            ? acceptedExtensions[0]
                                            : `.${acceptedExtensions.slice(0, -1).reduce((a, b) => `${a}, .${b}`)} i .${
                                                  acceptedExtensions[acceptedExtensions.length - 1]
                                              }`,
                                })}
                            </em>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </Grid>
    );
};

export default withTranslation("content")(withStyles(ImageDrop, internalStyles));
