import Layout from "components/Grid/Layout";
import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import { makeStyles } from "tss-react/mui";
import { stringStyle } from "utils/formating";
import { text, voucherKind, voucherValue } from "utils/formating/formatTypes";
import { useCommonTranslation } from "utils/hooks";

const useStyles = makeStyles()((theme) => ({
    tableSpacing: {
        marginTop: theme.spacing(2),
    },
}));

const VoucherList = ({ vouchers = [] }) => {
    const { t, common } = useCommonTranslation();
    const { classes } = useStyles();
    const columns = [
        {
            key: "id",
            value: t(common.voucherId),
            type: text,
            options: { style: stringStyle.imporant },
        },
        {
            key: "promotionType",
            value: t(common.promotionType),
            type: voucherKind,
        },
        {
            key: "value",
            value: t(common.value),
            type: voucherValue,
            options: { style: stringStyle.imporant },
        },
        {
            key: "useLimit",
            value: t(common.useLimit),
            type: text,
            options: { style: stringStyle.imporant },
        },
        {
            key: "campaignName",
            value: t(common.campaignName),
            type: text,
        },
    ];
    const data = vouchers.map((x) => {
        const { definition = {} } = x;
        return {
            ...x,
            promotionType: definition,
            value: definition,
            useLimit: `1/${definition.useLimit}`,
        };
    });

    return (
        <Layout
            headerText={t(common.vouchers)}
            className={classes.tableSpacing}
        >
            <SimpleTable
                columns={columns}
                stripped
                data={data}
            />
        </Layout>
    );
};

export default VoucherList;
