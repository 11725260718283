import Layout from "components/Grid/Layout";
import SearchBar from "components/SearchBar/SearchBar";
import { useCommonTranslation, usePush } from "utils/hooks";
import { productId as productIdRegex } from "utils/validators/regexes";
import { useState } from "react";

const ProductPreviewSearch = () => {
    const { t, common } = useCommonTranslation();
    const [search, setSearch] = useState("");
    const push = usePush();

    return (
        <Layout
            main
            headerText={t(common.productPreviewSearch)}
        >
            <SearchBar
                value={search}
                onSearchChanged={({ target: { value } }) => {
                    if (productIdRegex.test(value)) {
                        setSearch(value);
                    }
                }}
                onSearchCommit={() => {
                    push(`/offers/product/${search}`);
                }}
            />
        </Layout>
    );
};

export default ProductPreviewSearch;
