import ProductItem from "components/Controls/PromotionPredicateFieldsComponents/ProductItem";
import { renderFieldOrText } from "components/FormHelpers/Controls";
import { Field } from "redux-form";
import { actions } from "store/cache/products/actions";
import { cachedProductsSelector } from "store/offers/selectors";
import { useCommonTranslation } from "utils/hooks";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";

const MappedProductIds = ({ name }) => {
    const dispatch = useDispatch();
    const { t, common } = useCommonTranslation();
    const products = useSelector(cachedProductsSelector);

    return (
        <Grid
            style={{ padding: 8 }}
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
        >
            <Grid item>
                <Field
                    component={renderFieldOrText}
                    name={name}
                    label={t(common.mappedProductId)}
                    type="dragAndDrop"
                    useList={false}
                    itemRenderer={(v, handleRemove) => {
                        return (
                            <ProductItem
                                key={v}
                                v={v}
                                handleRemove={handleRemove}
                                products={products}
                                withToolTip
                            />
                        );
                    }}
                    onAdd={(productIds) => {
                        dispatch(actions.getProducts(productIds));
                    }}
                    additionalValueCheck={(v) => Boolean(Number(v))}
                />
            </Grid>
        </Grid>
    );
};

export default MappedProductIds;
