import { DocumentArchiveTypeDocument } from "Operations";
import { api } from "api";
import { useColumns } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { QueryTable } from "components-ts/tables";
import { View } from "components-ts/view";

const DocumentTypeList: React.FC = () => {
    const columns = useColumns<DocumentArchiveTypeDocument>(() => [
        {
            property: "name",
            label: "name",
        },
        {
            property: "description",
            label: "description",
        },
        {
            property: "levelOfConfidentiality",
            label: "levelOfConfidentiality",
            as: "translation",
        },
        {
            property: "isActive",
            label: "isActive",
            as: "boolean",
        },
        {
            actionType: "edit",
            link: (item) =>
                Paths.GeneratePath(Paths.Dictionaries.DocumentTypeForm, {
                    id: item.id,
                }),
        },
    ]);

    return (
        <View
            headerText={"documentType"}
            isMainView
        >
            <QueryTable
                queryHook={api.operations.documentArchiveTypes.useGetDocumentArchiveTypes}
                columns={columns}
                formPath={Paths.Dictionaries.DocumentTypeForm}
            />
        </View>
    );
};

export default DocumentTypeList;
