import { commerce, manager } from "store/actionTypePrefixes";
import { AsyncTypes } from "utils/extensions";

const types = {
    initForm: `${manager}/FORM_INIT_FEED`,
    getGift: new AsyncTypes("GET_GIFT", commerce),
    saveGift: new AsyncTypes("SAVE_GIFT", commerce),
};

export default types;
