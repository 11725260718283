import Layout from "components/Grid/Layout";
import TableWithToggleFilter from "components/Table/TableWithToggleFilter/TableWithToggleFilter";
import { actions } from "store/dictonaries/procedureCategory/list/actions";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useCommonTranslation } from "utils/hooks";
import { usePush } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { procedureCategoriesListSelector } from "./selectors";

const ProcedureCategoriesList = () => {
    const { t, common } = useCommonTranslation();
    const { push } = usePush();

    const pushToForm = (id) => {
        push(
            Paths.GeneratePath(Paths.Dictionaries.ProcedureCategoryForm, {
                id: id,
            })
        );
    };

    const columns = [
        {
            key: "procedureCategoryName",
            value: common.name,
            type: formatTypes.text,
        },
        { key: "isActive", value: common.active, type: formatTypes.boolean },
        {
            key: "createdAt",
            value: common.createdAt,
            type: formatTypes.time,
            options: { timeFormat: timePrecisonFormats.seconds },
        },
        {
            key: "updatedAt",
            value: common.updatedAt,
            type: formatTypes.time,
            options: { timeFormat: timePrecisonFormats.seconds },
        },
        {
            key: "action",
            value: "",
            type: formatTypes.action,
            actionType: "preview",
            action(item) {
                pushToForm(item.id);
            },
        },
    ].map((col) => ({ ...col, value: t(col.value) }));

    return (
        <Layout
            main
            headerText={t(common.procedureCategory)}
        >
            <TableWithToggleFilter
                columns={columns}
                addItemAction={() => pushToForm()}
                dataSelector={procedureCategoriesListSelector}
                dataSourceAction={actions.getList}
            />
        </Layout>
    );
};

export default ProcedureCategoriesList;
