import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
    box: {
        "borderTopStyle": "solid",
        "borderTopWidth": "2px",
        "paddingBottom": "10px",
        "marginTop": "5px",
        "borderTopColor": theme.palette.error.main,
        "& div.title": {
            color: theme.palette.error.dark,
            marginTop: "0.2rem",
            background: "white none repeat scroll 0 0",
            display: "inline-block",
            padding: "0 2px",
        },
    },
}));

const RowContainer = ({ children, error }) => {
    const { classes } = useStyles();

    if (!error) {
        return children;
    }

    return (
        <div className={classes.box}>
            <div
                style={{
                    padding: "0 3px",
                    marginTop: "-1.1em",
                }}
            >
                <div className="title">{"Błąd archiwizacji"}</div>
                <div className="box-contents">{children}</div>
            </div>
        </div>
    );
};

export default RowContainer;
