import { createSelector } from "reselect";
import { dictionariesSelector } from "../selectors";

const rootSelector = createSelector(dictionariesSelector, (dictionaries) => {
    return dictionaries.costReason;
});

export const costReaonsListSelector = createSelector(rootSelector, (costReason) => costReason.list);

export const costReaonsFormSelector = createSelector(rootSelector, (costReason) => costReason.form);
