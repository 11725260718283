import { IconButton } from "components/Button";
import { Card, CardBody, CardHeader } from "components/Card";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import NavigateBack from "components/Navigation/navigateBack";
import { makeStyles } from "tss-react/mui";
import React, { Fragment, useEffect } from "react";
import { Collapse, FormHelperText, Grid, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles()((theme) => ({
    // ...commonStyles,
    spacing: {
        marginLeft: theme.spacing(1),
    },
    headerContainerStyle: {
        display: "flex",
        width: "100%",
        alignItems: "center",
    },
    header: {
        flex: 1,
    },
    collapse: {
        width: 100,
        padding: 0,
        margin: 0,
    },
    collapseIcon: {
        padding: 0,
        margin: 0,
        color: "#fff",
    },
    collapseIconOpen: {
        transform: "rotate(180deg)",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.standard,
        }),
    },
    collapseIconClose: {
        transform: "rotate(-360deg)",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.standard,
        }),
    },
}));

const MainHeader = ({ headerText, classes, path, state, action, navigateBack, downloadLink, subtitle, cx, customTitle }) => {
    useEffect(() => {
        document.title = headerText ? customTitle || headerText : "Manager";
    }, [headerText, customTitle]);
    const isSmallView = useMediaQuery((theme) => theme.breakpoints.down("md"));

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            display="block"
        >
            <Grid
                item
                display="inline-block"
            >
                {path || navigateBack ? (
                    <NavigateBack
                        path={path}
                        state={state}
                    />
                ) : (
                    <Fragment />
                )}
            </Grid>

            <Grid
                item
                display="inline-block"
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    display="inline-block"
                >
                    <Grid
                        container
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        display="inline-block"
                    >
                        <Typography
                            color="inherit"
                            variant="h6"
                            className={classes.cardTitleWhite}
                            display="inline-block"
                        >
                            <span className={isSmallView ? "text-uppercase" : cx("text-uppercase", classes.spacing)}>{headerText}</span>
                            {downloadLink && (
                                <IconButton
                                    color="#fff"
                                    icon="cloud_download"
                                    href={downloadLink}
                                    target="_blank"
                                    showInline
                                />
                            )}
                        </Typography>
                        {downloadLink && (
                            <IconButton
                                color="#fff"
                                icon="cloud_download"
                                href={downloadLink}
                                target="_blank"
                                showInline
                            />
                        )}
                        {action}
                    </Grid>
                    {subtitle && (
                        <Grid item>
                            <Typography
                                color="inherit"
                                variant="subtitle2"
                                className={classes.cardTitleWhite}
                            >
                                <span className={isSmallView ? "text-uppercase" : cx("text-uppercase", classes.spacing)}>{subtitle}</span>
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

const Header = ({ classes, headerText, action, isEnlarged }) => {
    const body = (
        <>
            <span className="text-uppercase margin-right10">{headerText}</span>
            {action}
        </>
    );

    return isEnlarged ? (
        <Typography
            color="inherit"
            variant="h6"
            className={classes.cardTitleWhite}
        >
            {body}
        </Typography>
    ) : (
        <span className={classes.cardTitleWhite}> {body} </span>
    );
};

const Layout = ({
    children,
    headerText,
    main,
    hideHeader,
    navigationProps,
    action,
    useCollapse = false,
    isCollapsed = true,
    navigateBack,
    downloadLink,
    subtitle,
    validationText,
    customTitle,
    isEnlarged,
    transparentBackground,
}) => {
    const [collapsed, toggleCollapsed] = React.useState(isCollapsed);
    const { classes, cx, css } = useStyles();
    useEffect(() => {
        toggleCollapsed(isCollapsed);
    }, [isCollapsed]);

    const { backProps: { path, state } = {} } = navigationProps || {};

    const header = (
        <CardHeader color="primary">
            <div className={classes.headerContainerStyle}>
                <div className={classes.header}>
                    {main ? (
                        <MainHeader
                            navigateBack={navigateBack}
                            headerText={headerText}
                            classes={classes}
                            path={path}
                            action={action}
                            state={state}
                            downloadLink={downloadLink}
                            subtitle={subtitle}
                            cx={cx}
                            customTitle={customTitle}
                        />
                    ) : (
                        <Header
                            action={action}
                            headerText={headerText}
                            classes={classes}
                            isEnlarged={isEnlarged}
                        />
                    )}
                </div>

                {useCollapse ? (
                    <div className={classes.collapse}>
                        <IconButton
                            useWrapper={false}
                            iconClasses={cx(classes.collapseIcon, collapsed ? classes.collapseIconOpen : classes.collapseIconClose)}
                            onClick={() => toggleCollapsed(!collapsed)}
                            icon="keyboard_arrow_down"
                        />
                    </div>
                ) : (
                    <Fragment />
                )}
            </div>
        </CardHeader>
    );

    return (
        <Grid container>
            <GridItem
                xs={12}
                sm={12}
                md={12}
            >
                {children ? (
                    <Card style={transparentBackground && { backgroundColor: "transparent" }}>
                        {!hideHeader ? header : undefined}
                        {validationText && (
                            <GridItem
                                xs={12}
                                sm={12}
                                md={12}
                            >
                                <FormHelperText style={{ color: "red" }}>{validationText}</FormHelperText>
                            </GridItem>
                        )}
                        {useCollapse ? (
                            <Collapse in={!collapsed}>
                                <CardBody>{children}</CardBody>
                            </Collapse>
                        ) : (
                            <CardBody>{children}</CardBody>
                        )}
                    </Card>
                ) : (
                    header
                )}
            </GridItem>
        </Grid>
    );
};

export default Layout;
