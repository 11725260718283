import { DatePredicate } from "Commerce-Shared";
import moment from "moment";
import { isEmpty } from "lodash";
import { dateToString } from "utils-ts/functions";
import { ExtendedValidator } from "../extendedValidator";
import {
    tValidation,
    validation,
} from "../translation";
import { notEmptyDate } from "../validators";

export class DatePredicateValidator extends ExtendedValidator<DatePredicate> {
    constructor() {
        super();

        this.atLeastOneOfFieldNotEmpty([
            "dateFrom",
            "dateTo",
            "dayFrom",
            "dayTo",
            "hourFrom",
            "hourTo",
            "timeFrom",
            "timeTo",
        ]);

        this.ruleFor("dayFrom")
            .notNull()
            .when(
                (o) =>
                    !isEmpty(o.isOnlyNextDay) ||
                    !isEmpty(o.dayTo)
            );

        this.ruleFor("dayTo")
            .notNull()
            .whenNotEmpty("dayFrom");

        this.ruleFor("dateFrom")
            .null()
            .whenNotEmpty(["dayFrom", "dayTo"])
            .must(notEmptyDate)
            .whenNotEmpty("dateTo")
            .must({
                predicate: (val, obj) =>
                    moment(val).isSameOrAfter(
                        moment(obj.dateTo)
                    ),
                message: (_, obj) =>
                    tValidation(
                        validation.lessOrEqualsDateThan,
                        {
                            date: dateToString(
                                obj.dateTo
                            ),
                        }
                    ),
            })
            .whenNotEmpty(["dateFrom", "dateTo"]);

        this.ruleFor("dateTo")
            .null()
            .whenNotEmpty(["dayFrom", "dayTo"])
            .must(notEmptyDate)
            .whenNotEmpty("dateFrom")
            .must({
                predicate: (val, obj) =>
                    moment(val).isSameOrBefore(
                        moment(obj.dateFrom)
                    ),
                message: (_, obj) =>
                    tValidation(
                        validation.greaterOrEqualsDateThan,
                        {
                            date: dateToString(
                                obj.dateFrom
                            ),
                        }
                    ),
            })
            .whenNotEmpty(["dateFrom", "dateTo"]);

        this.ruleFor("hourFrom")
            .inclusiveBetween(0, 24)
            .whenNotEmpty()
            .null()
            .when(
                (o) =>
                    !isEmpty(o.timeFrom) ||
                    !isEmpty(o.timeTo)
            );

        this.ruleFor("hourTo")
            .inclusiveBetween(0, 24)
            .whenNotEmpty()
            .null()
            .when(
                (o) =>
                    !isEmpty(o.timeFrom) ||
                    !isEmpty(o.timeTo)
            );

        this.ruleFor("timeFrom")
            .null()
            .when(
                (o) =>
                    !isEmpty(o.hourFrom) ||
                    !isEmpty(o.hourTo)
            )
            .must((o) => {
                const timeSpan =
                    moment.duration(o);
                return (
                    timeSpan.milliseconds() > 0 &&
                    timeSpan.hours() <= 24
                );
            })
            .whenNotEmpty();

        this.ruleFor("timeTo")
            .null()
            .when(
                (o) =>
                    !isEmpty(o.hourFrom) ||
                    !isEmpty(o.hourTo)
            )
            .must((o) => {
                const timeSpan =
                    moment.duration(o);
                return (
                    timeSpan.milliseconds() > 0 &&
                    timeSpan.hours() <= 24
                );
            })
            .whenNotEmpty();
    }
}
