import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import { getMemoHistory, updateCustomerMemoAuditParams } from "store/customers/customer/customerMemos";
import { memoAuditSelector } from "store/customers/customer/customerMemos";
import { formatTypes, stringStyle, timePrecisonFormats } from "utils/formating";
import { useCommonTranslation } from "utils/hooks";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import MemoRow from "./MemoRow";

const MemoTable = ({ openDialog }) => {
    const dispatch = useDispatch();
    const { id, userId: customerId, auditList } = useSelector(memoAuditSelector);
    const { isLoading, items, pageIndex, totalCount, pageSize } = auditList;
    const { t, common } = useCommonTranslation();

    useEffect(() => {
        dispatch(getMemoHistory({ customerId, id, pageSize, pageIndex }));
    }, [customerId, dispatch, id, pageIndex, pageSize]);

    const columns = useMemo(
        () =>
            [
                {
                    key: "isImportant",
                    value: common.isImportant,
                    type: formatTypes.text,
                },
                { key: "text", value: common.comment, type: formatTypes.text },
                {
                    key: "createdBy",
                    value: common.createdBy,
                    type: formatTypes.text,
                    options: { style: stringStyle.imporant },
                },
                {
                    key: "createdAt",
                    value: common.createdAt,
                    type: formatTypes.time,
                    options: { timeFormat: timePrecisonFormats.seconds },
                },
            ].map((col) => ({ ...col, value: t(col.value) })),
        [t]
    );

    return (
        <SimpleTable
            isLoading={isLoading}
            columns={columns}
            onRowDoubleClick={openDialog}
            shoudlHandleDoubleClick
            pagination
            hover
            stripped
            paginationProps={{
                pageIndex,
                totalCount,
                pageSize,
                pageSizeOptions: [5, 10],
                disableSavingPagination: true,
                onPageChange: (pageIndex) => dispatch(updateCustomerMemoAuditParams({ pageIndex, pageSize })),
                onRowsPerPageChange: (pageSize) => dispatch(updateCustomerMemoAuditParams({ pageIndex: 1, pageSize })),
            }}
        >
            {items.map((memo) => {
                return (
                    <MemoRow
                        audit
                        key={memo.version}
                        columns={columns}
                        memo={memo}
                    />
                );
            })}
        </SimpleTable>
    );
};

export default MemoTable;
