import { types as rootTypes } from "store/customers/customer/action";
import defaultListState from "store/defaultListState";
import { types } from "./action";

const initialState = defaultListState;

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case rootTypes.CUSTOMER_CHANGED:
            return initialState;
        case types.CUSTOMER_REVIEWS_GET:
            return { ...state, isLoading: true };
        case types.CUSTOMER_REVIEWS_GET_SUCCESS:
            return { ...state, ...payload, isLoading: false };
        case types.CUSTOMER_REVIEWS_GET_FAILURE:
            return { ...state, isLoading: false };
        default:
            return { ...state };
    }
};
