import { GetRequest, PostRequest, get, post } from "utils/extensions";
import types from "./types";

const endpoint = (customerId) => `api/v1/users/${customerId}/membership`;

export const getCustomerMembership = (customerId) => async (dispatch) => {
    await dispatch(get(new GetRequest(endpoint(customerId), types.getMembership.all)));
};

export const getCustomerMembershipPoints = (customerId, pageSize, pageIndex) => async (dispatch) => {
    await dispatch(
        get(
            new GetRequest(endpoint(customerId) + "/points", types.getMembershipPoints.all, {
                queryParams: {
                    pageSize,
                    pageIndex,
                },
            })
        )
    );
};

export const editCustomerPoints = (customerId, values) => async (dispatch) => {
    await dispatch(
        post(
            new PostRequest(endpoint(customerId) + "/points/adjustment", types.editMembershipPoints.all, {
                body: { ...values },
            })
        )
    );
};
