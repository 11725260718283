import GridContainer from "components/Grid/GridContainer";
import TextWithLabel from "components/Text/TextWithLabel";
import { formatTypes } from "utils/formating";
import { useCommonTranslation } from "utils/hooks";
import { Grid } from "@mui/material";
import { Layout } from "components";
import { isEmpty } from "lodash";

const field = (label, value, format, options) => {
    return {
        label,
        value,
        format,
        options,
    };
};

const PromotionValidation = ({ promotionValidation = { warehouses: [] } }) => {
    const { warehouses } = promotionValidation;
    const { t, common } = useCommonTranslation();
    const warehouseProblems = warehouses
        .filter((x) => x.problems?.length > 0 || !isEmpty(x.productProblems))
        .map((i) => {
            const poolError =
                i.poolProductCount != null
                    ? t("poolCountVsMaxPoolCount", { poolCount: i.poolProductCount, maxPoolCount: i.maximumPoolProductCount })
                    : "";

            return {
                warehouse: i.warehouse,
                hasProblems: (i.problems || []).length > 0,
                problems: field(common.problems, (i.problems || []).map((p) => t(p)).join(", ") + poolError, formatTypes.text),
                hasProductProblems: !isEmpty(i.productProblems),
                productProblems: field(
                    common.productProblems,
                    i.products.map((p) => {
                        const productPrices =
                            p.lowPrice != null && p.newPrice != null
                                ? " " + t("newPriceVsLowPrice", { lowPrice: p.lowPrice, newPrice: p.newPrice })
                                : "";
                        return `${p.productId}: ${p.problems.map((pp) => t(pp)).join(", ") + productPrices}`;
                    }),
                    formatTypes.text
                ),
            };
        });

    return (
        <Layout headerText={t(common.promotionValidation)}>
            <Grid
                container
                direction="row"
            >
                {warehouseProblems.map((w) => (
                    <Grid
                        item
                        xs={6}
                        key={w.warehouse}
                    >
                        <GridContainer
                            iconName="location_city"
                            title={w.warehouse}
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            translateTitle={false}
                        >
                            {w.hasProblems && (
                                <TextWithLabel
                                    unselectableLabel
                                    {...w.problems}
                                />
                            )}
                            {w.hasProductProblems && (
                                <TextWithLabel
                                    unselectableLabel
                                    {...w.productProblems}
                                />
                            )}
                        </GridContainer>
                    </Grid>
                ))}
            </Grid>
        </Layout>
    );
};

export default PromotionValidation;
