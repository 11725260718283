import Layout from "components/Layout/Layout";
import React, { Fragment } from "react";
import { Suspense } from "react";
import { Route as ReactRoute, Routes as ReactRoutes } from "react-router-dom";
import { callbackLocation, silentRenewLocation } from "utils-ts/auth/userManager";
import Loading from "views-ts/loading/Loading";
import { AuthenticationGuard, CallbackPage, SilentRenewPage } from "components-ts/auth";
import { ManagerRoutes } from "./ManagerRoutes";

const Routes: React.FC = () => {
    const subRoutes = ManagerRoutes.flatMap((r) => r.subpaths);
    const routes = ManagerRoutes.concat(subRoutes)
        .filter((r) => Boolean(r.component))
        .flatMap((r) => r.GetReactRoutes())
        .filter((r) => r !== null && r !== undefined);

    return (
        <ReactRoutes>
            <ReactRoute
                key="/"
                path="/"
                element={<Layout />}
            >
                <ReactRoute
                    index
                    element={<AuthenticationGuard component={Fragment} />}
                />

                <ReactRoute
                    key={callbackLocation}
                    path={callbackLocation}
                    element={<CallbackPage />}
                />
                <ReactRoute
                    key={silentRenewLocation}
                    path={silentRenewLocation}
                    element={<SilentRenewPage />}
                />

                {routes.map((r) => (
                    <ReactRoute
                        key={r?.path}
                        path={r?.path}
                        element={<Suspense fallback={<Loading />}>{r?.element}</Suspense>}
                    />
                ))}
            </ReactRoute>
        </ReactRoutes>
    );
};

export default Routes;
