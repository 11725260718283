import { ArrayValidationError, CampaingsAutocomplete, ContentData } from "components/Controls";
import { FormField } from "components/Form";
import { Adable, Erasable } from "components/FormHelpers";
import { integerNormalize } from "components/FormHelpers/ControlFormaters";
import { FormSection } from "redux-form";
import { withFormName } from "utils/hoc";
import { useCommonTranslation } from "utils/hooks";
import React from "react";
import { Grid } from "@mui/material";
import VoucherDefinition from "views/vouchers/components/VoucherDefinition";
import { RewardEffect } from "../../model";
import { validators } from "../../validators";

const RewardEffects = ({ fields, meta, readOnly = false }) => {
    const { t, common } = useCommonTranslation();

    return (
        <Adable
            handleAdd={() => fields.push(RewardEffect.default())}
            readOnly={readOnly}
        >
            <ArrayValidationError {...meta} />
            {fields.map((field, index) => {
                return (
                    <Erasable
                        key={field}
                        handleRemove={() => fields.remove(index)}
                        readOnly={readOnly}
                    >
                        <FormSection name={`${field}.voucher`}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                <FormField
                                    name={"validFrom"}
                                    type={"dateTime"}
                                    label={t(common.validFrom)}
                                    validate={validators.validFrom}
                                    readOnly={readOnly}
                                />
                                <FormField
                                    name={"validTo"}
                                    type={"dateTime"}
                                    label={t(common.validTo)}
                                    validate={validators.validTo}
                                    readOnly={readOnly}
                                />
                                <FormField
                                    name={"validForDays"}
                                    formatDisplay={integerNormalize}
                                    type={"number"}
                                    label={t(common.validForDays)}
                                    validate={validators.validForDays}
                                    readOnly={readOnly}
                                />

                                <CampaingsAutocomplete
                                    validate={validators.campaign}
                                    readOnly={readOnly}
                                />
                            </Grid>
                            <VoucherDefinition readOnly={readOnly} />

                            <ContentData
                                readOnly={readOnly}
                                isCollapsed
                            />
                        </FormSection>
                    </Erasable>
                );
            })}
        </Adable>
    );
};

export default withFormName(RewardEffects);
