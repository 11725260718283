import { IconButton } from "components/Button";
import { AutocompleteField } from "components/Controls/Autocompletes";
import Dropzone from "components/Files/Dropzone";
import { Form, FormField } from "components/Form";
import GridContainer from "components/Grid/GridContainer";
import { PdfViewer } from "components/PdfViewer";
import TextWithLabel from "components/Text/TextWithLabel";
import { reduxForm } from "redux-form";
import {
    employeeDepartmentsSelector,
    employeesSelector,
    getEmployeeDepartments,
    getEmployees,
    procedureCategoriesSelector,
} from "store/autocomplete";
import { createOrUpdateProcedure, getProcedureFile, procedureFormSelector } from "store/procedures";
import { useChange, useCommonTranslation, useFormValueSelector, useParams } from "utils/hooks";
import { notEmptyValidator, stringLength } from "utils/validators/basic";
import Validator from "utils/validators/basic";
import { useCallback, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Delete as DeleteIcon, GetApp as DownloadIcon } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useUser } from "context";
import { isEmpty } from "lodash";
import { usePush } from "utils-ts/hooks";
import { tValidation, validation } from "utils-ts/validations/translation";
import { Paths } from "routing-ts/ManagerPaths";

const validators = {
    name: (value) =>
        notEmptyValidator(value)
            .must(stringLength(5, 100), tValidation(validation.stringLength, { min: 5, max: 100 }))
            .validate(),
    required: (value) => notEmptyValidator(value).validate(),
    atLestOneRequirement: (value, form) => {
        return new Validator(form)
            .must((val) => {
                const { departments, employees } = val;
                return !(isEmpty(departments) && isEmpty(employees));
            }, tValidation(validation.atLestOneRequirement))
            .validate();
    },
    shouldNotBeEmpty: (value, form) => {
        return new Validator(form)
            .must(
                (val) => {
                    const { needsAcceptance, isForEveryone } = val;
                    return needsAcceptance || (!needsAcceptance && isForEveryone);
                },
                tValidation(validation.notEmptyWhen, { when: "procedura nie wymaga akceptacji" })
            )
            .validate();
    },
};

const ProcedureForm = ({ handleSubmit, isSubbmitRequested, isDownloadingFile, pristine, downloadFile, fileContent }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { replace } = usePush();
    const change = useChange(formName);
    const { t, common } = useCommonTranslation();
    const categories = useSelector(procedureCategoriesSelector);
    const {
        fileName = {},
        procedureVersion,
        blobName,
        isForEveryone,
    } = useFormValueSelector(formName, ["fileName", "blobName", "procedureVersion", "isForEveryone"], {});
    const { isInAnyRoleOrAdmin, profile } = useUser() || {};
    const { role: roles = [] } = profile || {};
    const fullPermissions = ["Auditor"];
    useEffect(() => {
        if (id && procedureVersion) {
            dispatch(getProcedureFile(id, procedureVersion, fileName, false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, id, procedureVersion]);

    useEffect(() => {
        if (isForEveryone) {
            change("departments", null);
            change("employees", null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isForEveryone]);

    const handleDrop = useCallback(
        (acceptedFiles) => {
            acceptedFiles.forEach((file) => {
                const reader = new FileReader();

                reader.onload = function (e) {
                    const fileItem = {
                        extension: `.${file.name.split(".").slice(-1)[0].toLowerCase()}`,
                        fileName: file.name,
                        fileContent: e.target.result.split(",")[1],
                    };

                    change("file", fileItem);
                    change("fileName", file.name);
                    change("fileContent", fileItem.fileContent);
                };

                reader.readAsDataURL(file);
            });
        },
        [change]
    );

    const handleDownloadFile = async ({ fileName }) => {
        await downloadFile(procedureVersion, fileName);
    };

    return (
        <Form
            onSubmit={handleSubmit(async (values) => {
                const { payload } = await dispatch(createOrUpdateProcedure(id, values));

                if (payload && payload.id && !Boolean(id)) {
                    replace(Paths.Administration.ProcedureForm, { id: payload.id });
                }
            })}
            isSubbmitRequested={isSubbmitRequested}
            pristine={pristine}
        >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <FormField
                    name="name"
                    type="text"
                    label={t(common.name)}
                    validate={validators.name}
                />

                <FormField
                    name="procedureVersion"
                    type="text"
                    label={t(common.version)}
                    readonly={true}
                    hidden={!Boolean(id)}
                    width={100}
                />

                <AutocompleteField
                    label={t(common.procedureCategory)}
                    name="procedureCategoryId"
                    dataSelector={() =>
                        categories
                            .filter((c) => c.isActive || c.id == id)
                            .filter((c) => isInAnyRoleOrAdmin(fullPermissions) || c.assignedRoles.some((r) => roles.includes(r)))
                            .map((c) => c.id)
                    }
                    getOptionLabel={(option) => {
                        let category = categories.find((c) => c.id == option);
                        let isActive = category?.isActive ? "" : ` (${t(common.inActive)})`;
                        return category?.procedureCategoryName ? category?.procedureCategoryName + isActive : "";
                    }}
                    validate={validators.required}
                />
                <AutocompleteField
                    label={t(common.dedicatedForDepartment)}
                    name="dedicatedForDepartment"
                    dataAction={getEmployeeDepartments}
                    dataSelector={employeeDepartmentsSelector}
                    getOptionLabel={(option) => option || ""}
                    disableCloseOnSelect
                />
            </Grid>

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <FormField
                    name="isActive"
                    type="boolean"
                    label={t(common.isActive)}
                />
                <FormField
                    name="isForEveryone"
                    type="boolean"
                    label={t(common.isForEveryone)}
                    validate={validators.shouldNotBeEmpty}
                />
                <FormField
                    name="needsAcceptance"
                    type="boolean"
                    label={t(common.needsAcceptance)}
                />
            </Grid>
            {!isForEveryone && (
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <AutocompleteField
                        multiple
                        label={t(common.groups)}
                        name="departments"
                        dataAction={getEmployeeDepartments}
                        dataSelector={employeeDepartmentsSelector}
                        getOptionLabel={(option) => option || ""}
                        disableCloseOnSelect
                        validate={validators.atLestOneRequirement}
                    />
                    <AutocompleteField
                        multiple
                        label={t(common.employees)}
                        name="employees"
                        dataAction={getEmployees}
                        dataSelector={employeesSelector}
                        getOptionLabel={(option) => option.name || ""}
                        compareOptionId
                        disableCloseOnSelect
                        validate={validators.atLestOneRequirement}
                        filterItems={(items) => items.filter((i) => !i.isDeactivated)}
                    />
                </Grid>
            )}
            <GridContainer
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                title={t(common.file, { fileType: "pdf" })}
                translateTitle={false}
            >
                <Grid item>
                    <Dropzone
                        onDrop={handleDrop}
                        maxFiles={1}
                        hasFile={fileName}
                        required={!procedureVersion}
                        accept=".pdf"
                    />
                </Grid>

                <TextWithLabel
                    value={fileName}
                    label={common.fileName}
                />

                <Grid item>
                    <IconButton
                        edge="end"
                        onClick={() => handleDownloadFile({ fileName })}
                        disabled={!Boolean(blobName)}
                        icon={<DownloadIcon />}
                        loading={isDownloadingFile}
                    />
                </Grid>

                <Grid item>
                    <IconButton
                        edge="end"
                        onClick={() => {
                            change("file", null);
                            change("fileName", null);
                        }}
                        disabled={!fileName || Boolean(blobName)}
                        icon={<DeleteIcon />}
                    />
                </Grid>
            </GridContainer>

            {id && (
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                >
                    <PdfViewer
                        file={fileContent}
                        isLoading={false}
                    />
                </Grid>
            )}
        </Form>
    );
};

const formName = "procedure-form";

const stateToProps = (state) => {
    const { isSubbmitRequested, isDownloadingFile, isLoading, isFetchingFile, fileContent, ...form } = procedureFormSelector(state);

    return {
        isSubbmitRequested,
        isDownloadingFile,
        isLoading,
        isFetchingFile,
        fileContent,
        initialValues: {
            ...form,
            isActive: form.isActive !== undefined ? form.isActive : true,
            isForEveryone: form.isForEveryone !== undefined ? form.isForEveryone : true,
            needsAcceptance: form.needsAcceptance !== undefined ? form.needsAcceptance : true,
        },
    };
};

export default connect(stateToProps)(
    reduxForm({
        form: formName,
        enableReinitialize: true,
    })(ProcedureForm)
);
