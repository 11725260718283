import { GetRequest, PostRequest, PutRequest, get, post, put } from "utils/extensions";
import types from "./types";

const endpoint = (id) => `api/v1/offer/products/rewards${id ? `/${id}` : ""}`;

export const initRewardForm = (id, isCopy) => async (dispatch) => {
    await dispatch({ type: types.initForm });

    if (id) {
        await dispatch(
            get(new GetRequest(endpoint(id), [types.getReward.request, { type: types.getReward.success, meta: { isCopy } }, types.getReward.failure]))
        );
    }
};

export const createOrUpdateReward = (values, id) => async (dispatch) => {
    if (!id) {
        return await dispatch(
            post(
                new PostRequest(endpoint(id), types.createOrUpdateReward.all, {
                    body: values,
                })
            )
        );
    } else {
        return await dispatch(
            put(
                new PutRequest(endpoint(id), types.createOrUpdateReward.all, {
                    body: values,
                })
            )
        );
    }
};

export const getRewardAudit = (id) => async (dispatch) => {
    dispatch(get(new GetRequest(endpoint(id) + "/audit", types.getRewardAudit.all)));
};

export const getRewardReservationCount = (id) => async (dispatch) => {
    dispatch(get(new GetRequest(endpoint(id) + "/reservation-count", types.getRewardReservationCount.all)));
};
