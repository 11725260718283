import React, { createContext, useContext, useMemo } from "react";

const VirtualizedItemResizeContext = createContext<(() => void) | undefined>(undefined);

export const useVirtualizedParentResize = () => useContext(VirtualizedItemResizeContext);

export const VirtualizedParentResizeProvider = ({ resize, children }: { resize: () => void; children: React.ReactNode }) => {
    const memoValue = useMemo(() => {
        //https://www.developerway.com/posts/react-re-renders-guide#part7.1
        return resize;
    }, []);

    return <VirtualizedItemResizeContext.Provider value={memoValue}>{children}</VirtualizedItemResizeContext.Provider>;
};
