import fileTypes from "consts/fileTypes";
import { RSAA } from "redux-api-middleware";
import { commerce, manager } from "store/actionTypePrefixes";
import { asAsyncFormTypes, asCleanFormDataTypes } from "utils/extensions/arrayExtensions";
import downloadFile, { rsaaActionType } from "utils/rsaa/downloadFileAction";

export const types = {
    FORM_INIT_VOUCHER: `${manager}/FORM_INIT_VOUCHER`,
    GET_VOUCHER: `${commerce}/GET_VOUCHER`,
    GET_VOUCHER_SUCCESS: `${commerce}/GET_VOUCHER_SUCCESS`,
    GET_VOUCHER_FAILURE: `${commerce}/GET_VOUCHER_FAILURE`,
    CREATE_OR_UPDATE_VOUCHER: `${commerce}/CREATE_OR_UPDATE_VOUCHER`,
    CREATE_OR_UPDATE_VOUCHER_SUCCESS: `${commerce}/CREATE_OR_UPDATE_VOUCHER_SUCCESS`,
    CREATE_OR_UPDATE_VOUCHER_FAILURE: `${commerce}/CREATE_OR_UPDATE_VOUCHER_FAILURE`,
    GENERATE_VOUCHERS: `${commerce}/GENERATE_VOUCHERS`,
    GENERATE_VOUCHERS_SUCCESS: `${commerce}/GENERATE_VOUCHERS_SUCCESS`,
    GENERATE_VOUCHERS_FAILURE: `${commerce}/GENERATE_VOUCHERS_FAILURE`,
    DOWNLOAD_VOUCHERS: `${commerce}/DOWNLOAD_VOUCHERS`,
    DOWNLOAD_VOUCHERS_SUCCESS: `${commerce}/DOWNLOAD_VOUCHERS_SUCCESS`,
    DOWNLOAD_VOUCHERS_FAILURE: `${commerce}/DOWNLOAD_VOUCHERS_FAILURE`,
};

const initForm = (id, type, isCopy) => async (dispatch) => {
    await dispatch({ type: types.FORM_INIT_VOUCHER });

    if (id) {
        return await dispatch({
            [RSAA]: {
                endpoint: `api/v1/vouchers/${type}/${id}`,
                headers: { "Content-Type": "application/json" },
                method: "GET",
                types: [types.GET_VOUCHER, { type: types.GET_VOUCHER_SUCCESS, meta: { isCopy } }, types.GET_VOUCHER_FAILURE],
            },
        });
    }
};

const createOrUpdate = (values, id, type) => async (dispatch) => {
    return await dispatch({
        [RSAA]: {
            endpoint: id ? `api/v1/vouchers/${type}/${id}` : `api/v1/vouchers/${type}`,
            headers: { "Content-Type": "application/json" },
            method: id ? "PUT" : "POST",
            types: asCleanFormDataTypes(
                asAsyncFormTypes([types.CREATE_OR_UPDATE_VOUCHER, types.CREATE_OR_UPDATE_VOUCHER_SUCCESS, types.CREATE_OR_UPDATE_VOUCHER_FAILURE])
            ),
            body: JSON.stringify(values),
        },
    });
};

const generateBatch = (id) => async (dispatch) => {
    await dispatch({
        [RSAA]: {
            endpoint: `api/v1/vouchers/batches/${id}/vouchers`,
            headers: { "Content-Type": "application/json" },
            method: "POST",
            types: asAsyncFormTypes([types.GENERATE_VOUCHERS, types.GENERATE_VOUCHERS_SUCCESS, types.GENERATE_VOUCHERS_FAILURE]),
            body: JSON.stringify({}),
        },
    });
};

const downloadBatch = (id) => async (dispatch) => {
    await dispatch(
        downloadFile(
            `api/v1/vouchers/batches/${id}/vouchers`,
            fileTypes.txt,
            rsaaActionType(types.DOWNLOAD_VOUCHERS, types.DOWNLOAD_VOUCHERS_SUCCESS, types.DOWNLOAD_VOUCHERS_FAILURE),
            id,
            true
        )
    );
};

export const actions = {
    initForm,
    createOrUpdate,
    generateBatch,
    downloadBatch,
};
