import ProductItem from "components/Controls/PromotionPredicateFieldsComponents/ProductItem";
import { productsSelector } from "components/Controls/PromotionPredicateFieldsComponents/selectors";
import { Field } from "redux-form";
import divisions from "resource/divisions.json";
import { makeStyles } from "tss-react/mui";
import { timePrecisonFormats } from "utils/formating";
import { useAppLocation, useParams } from "utils/hooks";
import { useSelector } from "react-redux";
import { Alert, AlertTitle } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import moment from "moment";

const useStyles = makeStyles()((theme) => ({
    alert: {
        padding: theme.spacing(1),
    },
}));

const Errors = ({ meta }) => {
    const products = useSelector(productsSelector);
    const { valid, error } = meta;
    const { vendorId } = useParams();
    const { classes } = useStyles();
    const appLocation = useAppLocation();
    if (valid || !error) {
        return <></>;
    }

    return (
        <div className={classes.alert}>
            <Alert severity="error">
                <AlertTitle>Błędy walidacji produktów</AlertTitle>
                {error &&
                    error.map &&
                    error.map((error) => {
                        return (
                            <Grid
                                key={error.refundationId}
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="flex-end"
                            >
                                <Grid item>
                                    <Typography>Produkty:</Typography>
                                </Grid>
                                <Grid item>
                                    <div style={{ display: "inline", marginLeft: 10 }}>
                                        {error.products.map((p) => {
                                            return (
                                                <ProductItem
                                                    key={p}
                                                    v={p}
                                                    products={products}
                                                    readOnly={true}
                                                />
                                            );
                                        })}
                                    </div>
                                </Grid>
                                <Grid item>
                                    <Typography>
                                        kolidują z refundacją:
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={`${appLocation}/vendors/${vendorId}/refundations/form/${error.refundationId}`}
                                        >
                                            {error.refundationId}
                                        </a>
                                        w okresie:{" "}
                                        {`${moment(error.dateFrom).format(timePrecisonFormats.days)} - ${moment(error.dateTo).format(
                                            timePrecisonFormats.days
                                        )}`}
                                        {error.divisions
                                            ? " w oddziałach: " + error.divisions.map((ed) => divisions.find((d) => d.value === ed).name).join(", ")
                                            : ""}
                                    </Typography>
                                </Grid>
                            </Grid>
                        );
                    })}
            </Alert>
        </div>
    );
};

const ProductErrors = () => {
    return (
        <Field
            name="productErrors"
            component={Errors}
        />
    );
};

export default ProductErrors;
