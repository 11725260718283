import { FormField } from "components/Form";
import { useCommonTranslation } from "utils/hooks";

const MerchantsSelect = ({ name = "merchants", validate, readOnly = false }) => {
    const { t, common } = useCommonTranslation();

    return (
        <FormField
            name={name}
            label={t(common.merchant)}
            type={"autocomplete"}
            items={[common.FRS, common.F24]}
            multiple
            stretch
            disableCloseOnSelect
            validate={validate}
            readOnly={readOnly}
        />
    );
};

export default MerchantsSelect;
