import { renderFieldOrText } from "components/FormHelpers/Controls";
import { Field } from "redux-form";
import { contactTypesSelector } from "store/autocomplete";
import { useCommonTranslation } from "utils/hooks";
import { useSelector } from "react-redux";

const ContactTypeAutocomplete = ({ validate, name = "contactType", readOnly }) => {
    const { t, common } = useCommonTranslation();
    const contactTypes = useSelector(contactTypesSelector) || [];

    return (
        <Field
            component={renderFieldOrText}
            name={name}
            label={t(common.contactType)}
            type={"autocomplete"}
            items={contactTypes}
            disableCloseOnSelect
            validate={validate}
            readOnly={readOnly}
        />
    );
};

export default ContactTypeAutocomplete;
