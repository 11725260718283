import React from "react";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import { Role } from "../roles";

const RetentionManagers = React.lazy(() =>
    import("views-ts/imports").then((module) => ({
        default: module.RetentionManagers,
    }))
);

const UsersBalanceDebts = React.lazy(() =>
    import("views-ts/imports").then((module) => ({
        default: module.UsersBalanceDebts,
    }))
);

const roles: Role[] = ["BusinessSupportImporter"];

export const Imports: PrivateRoute[] = [
    new PrivateRoute("/imports", roles, undefined, "imports", undefined, [
        new PrivateRoute(Paths.Imports.RetentionManagers, roles, RetentionManagers, "retentionManagers", "person"),
        new PrivateRoute(Paths.Imports.UsersBalanceDebts, roles, UsersBalanceDebts, "userBalanceDebts", "credit_score"),
    ]),
];
