import { Button } from "components/Button";
import AcceptRejectDialog from "components/Dialog/AcceptRejectDialog";
import SimpleTable, { createColumn } from "components/Table/SimpleTable/SimpleTable";
import tableActionTypes from "components/Table/SimpleTable/TableActions/tableActionTypes";
import { actions } from "store/vendors/action";
import { removeCreditLimit } from "store/vendors/creditLimits/action";
import { useDebouncedCallback } from "use-debounce";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useCommonTranslation, useParams } from "utils/hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Icon, Typography } from "@mui/material";
import moment from "moment";
import { vendor } from "translations";
import { usePush, useTranslation } from "utils-ts/hooks";
import { IsVendorReadOnly } from "views/vendors/IsVendorReadOnly";
import { creditLimitsSelector } from "../selectors";

const CreditLimits = () => {
    const { id: vendorId } = useParams();
    const [creditLimitId, setCreditLimitId] = useState();
    const [filter, setFilter] = useState({
        dateFrom: undefined,
        dateTo: undefined,
    });
    const { t } = useTranslation("vendor");
    const { t: tc, common } = useCommonTranslation();
    const { creditLimits, hasPendingCreditLimitOperation } = useSelector(creditLimitsSelector);
    const dispatch = useDispatch();
    const { push } = usePush();
    const isVendorReadOnly = IsVendorReadOnly();
    useEffect(() => {
        dispatch(actions.getCreditLimits(vendorId));
        if (!isVendorReadOnly) {
            dispatch(actions.hasPendingCreditLimitOperation(vendorId));
        }
    }, [vendorId, isVendorReadOnly, dispatch]);

    const handleOpen = (id) => setCreditLimitId(id);
    const handleClose = () => setCreditLimitId(null);

    const columns = [
        createColumn("validFrom", t(vendor.dateFrom), formatTypes.time, { timeFormat: timePrecisonFormats.days }, null, true),
        createColumn("validTo", t(vendor.dateTo), formatTypes.time, { timeFormat: timePrecisonFormats.days }, null, true),
        createColumn("limit", t(vendor.value), formatTypes.currency),
        createColumn("action", "", formatTypes.action, { actionType: tableActionTypes.edit }, (item) => {
            const { id } = item;
            push(`/vendors/${vendorId}/credit-limits/form/${id}`);
        }),
    ];

    if (!isVendorReadOnly) {
        columns.push(
            createColumn(
                "action",
                "",
                formatTypes.action,
                {
                    actionType: tableActionTypes.delete,
                },
                (item) => {
                    const { id, fileAttached } = item;
                    if (fileAttached) {
                        return;
                    }
                    handleOpen(id);
                },
                false,
                (item) => item.fileAttached
            )
        );
    }

    const handleFilter = useDebouncedCallback((column, value) => {
        setFilter({ ...filter, [column]: value });
    }, 200);

    const filterData = (items) => {
        return items.filter((x) => {
            const { validFrom, validTo, typeOfValue } = filter;
            return (
                (validFrom ? moment(x.dateFrom).isSameOrAfter(validFrom, "days") : true) &&
                (validTo ? moment(x.dateTo).isSameOrBefore(validTo, "days") : true) &&
                (typeOfValue ? t(x.typeOfValue)?.toLowerCase()?.includes(typeOfValue.toLowerCase()) : true)
            );
        });
    };

    return (
        <>
            {!isVendorReadOnly && !hasPendingCreditLimitOperation && (
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                push(`/vendors/${vendorId}/credit-limits/form`);
                            }}
                        >
                            <Icon>add</Icon>
                        </Button>
                    </Grid>
                </Grid>
            )}

            <SimpleTable
                isLoading={creditLimits === undefined}
                columns={columns}
                data={filterData(creditLimits || [])}
                filterFunction={handleFilter}
                onRowDoubleClick={(_, item) => {
                    const { id } = item;
                    push(`/vendors/${vendorId}/credit-limits/form/${id}`);
                }}
                stripped
                hover
            />

            <AcceptRejectDialog
                title={tc(common.remove)}
                maxWidth={"sm"}
                open={Boolean(creditLimitId)}
                handleAccept={async () => {
                    const { meta } = await dispatch(removeCreditLimit(vendorId, creditLimitId));
                    if (meta.formSubmit === "SUCCESS") {
                        dispatch(actions.initVendorForm(vendorId));
                    }
                    handleClose();
                }}
                handleReject={() => {
                    handleClose();
                }}
            >
                <Typography variant="body1">
                    {tc(common.areYouSure, {
                        what: tc(common.toRemove),
                    })}
                </Typography>
            </AcceptRejectDialog>
        </>
    );
};

export default CreditLimits;
