import useCardheaderStyles from "assets/jss/material-dashboard-react/components/cardHeaderStyle";
import classNames from "classnames";
import PropTypes from "prop-types";

function CardHeader({ ...props }) {
    const { className, children, color, plain, stats, icon, ...rest } = props;
    const { classes } = useCardheaderStyles();

    const cardHeaderClasses = classNames({
        [classes.cardHeader]: true,
        [classes[color + "CardHeader"]]: color,
        [classes.cardHeaderPlain]: plain,
        [classes.cardHeaderStats]: stats,
        [classes.cardHeaderIcon]: icon,
        [className]: className !== undefined,
    });

    return (
        <div
            className={cardHeaderClasses}
            {...rest}
        >
            {children}
        </div>
    );
}

CardHeader.propTypes = {
    className: PropTypes.string,
    color: PropTypes.oneOf(["warning", "success", "danger", "info", "primary", "rose"]),
    plain: PropTypes.bool,
    stats: PropTypes.bool,
    icon: PropTypes.bool,
};

export default CardHeader;
