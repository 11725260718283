import types from "./types";

const initialState = { isLoading: true };

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case types.getUserPermissions.request:
            return { ...initialState, isLoading: true };
        case types.getUserPermissions.success:
            return { ...state, isNew: !payload?.userAccess, ...payload, isLoading: false };
        case types.getUserPermissions.failure:
            return { ...state, isNew: true, isLoading: false };
        case types.createOrUpdate.success:
            return { ...state, isNew: false, ...payload };
        default:
            return state;
    }
};
