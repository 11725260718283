import { ADD_NEW_USER, ADD_NEW_USER_FAILURE, ADD_NEW_USER_SUCCESS } from "./actions";

const initialState = {
    inProgress: false,
};

export const createNewUser = (state = initialState, action) => {
    state = state || initialState;
    switch (action.type) {
        case ADD_NEW_USER:
            return {
                ...state,
                inProgress: true,
            };
        case ADD_NEW_USER_SUCCESS:
            return {
                ...state,
                inProgress: false,
            };
        case ADD_NEW_USER_FAILURE:
            return {
                ...state,
                inProgress: false,
            };
        default:
            return state;
    }
};
