import { iconTypes } from "components/Icons/InfoIcon";
import { createSelector } from "reselect";

const root = (state) => ({
    reports: state.reports,
    autocomplete: state.autocomplete,
});

export const correctionRequestReportSelector = createSelector(root, ({ reports = {}, autocomplete = {} }) => {
    var report = reports.correctionRequestReport;
    var vendors = autocomplete.vendors;

    report.items = (report.items || []).map((i) => {
        i.forNegotiation = i.generatedForNegotiation ? iconTypes.warrning : undefined;
        var vendor = vendors.find((v) => v.vendorId === i.vendorId);
        if (!vendor) {
            return i;
        } else {
            return {
                ...i,
                vendorId: vendor.vendorId.replace("_FRS", ""),
                vendorName: vendor.name,
            };
        }
    });

    return report;
});
