import React from "react";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import { AccountantDepartment, Role } from "../roles";

const DevicesList = React.lazy(() =>
    import("views-ts/fixedAssets").then((module) => ({
        default: module.DevicesList,
    }))
);

const DevicesForm = React.lazy(() =>
    import("views-ts/fixedAssets").then((module) => ({
        default: module.Device,
    }))
);

const DevicesAssignmentList = React.lazy(() =>
    import("views-ts/fixedAssets").then((module) => ({
        default: module.DevicesAssignmentList,
    }))
);

const DevicesAssignmentEmployeeList = React.lazy(() =>
    import("views-ts/fixedAssets").then((module) => ({
        default: module.DevicesAssignmentEmployeeList,
    }))
);

const DevicesAssignmentForm = React.lazy(() =>
    import("views-ts/fixedAssets").then((module) => ({
        default: module.DevicesAssignmentForm,
    }))
);

const WorkstationList = React.lazy(() =>
    import("views-ts/fixedAssets").then((module) => ({
        default: module.WorkstationList,
    }))
);

const WorkstationForm = React.lazy(() =>
    import("views-ts/fixedAssets").then((module) => ({
        default: module.Workstation,
    }))
);

const DevicesAssignmentWorkstationList = React.lazy(() =>
    import("views-ts/fixedAssets").then((module) => ({
        default: module.DevicesAssignmentWorkstationList,
    }))
);

const DevicesAccountant = React.lazy(() =>
    import("views-ts/fixedAssets").then((module) => ({
        default: module.DevicesAccountant,
    }))
);

const helpDesk: Role[] = ["HelpDesk"];
const fixedAssetsRoles: Role[] = ["HelpDesk", ...AccountantDepartment.GetRoles()];

export const FixedAssets: PrivateRoute[] = [
    new PrivateRoute("/fixed-assets", fixedAssetsRoles, undefined, "fixedAssets", "devices_other", [
        new PrivateRoute(Paths.FixedAssets.DevicesList, helpDesk, DevicesList, "devicesList", "important_devices"),
        new PrivateRoute(Paths.FixedAssets.DevicesForm, helpDesk, DevicesForm),
        new PrivateRoute(Paths.FixedAssets.DevicesAssignmentList, helpDesk, DevicesAssignmentList, "devicesAssignmentList", "manage_accounts"),
        new PrivateRoute(Paths.FixedAssets.DevicesAssignmentEmployeeList, helpDesk, DevicesAssignmentEmployeeList),
        new PrivateRoute(Paths.FixedAssets.DevicesAssignmentForm, helpDesk, DevicesAssignmentForm),
        new PrivateRoute(Paths.FixedAssets.WorkstationList, helpDesk, WorkstationList, "workstationList", "desk"),
        new PrivateRoute(Paths.FixedAssets.WorkstationForm, helpDesk, WorkstationForm),
        new PrivateRoute(Paths.FixedAssets.DevicesAssignmentWorkstationList, helpDesk, DevicesAssignmentWorkstationList),
        new PrivateRoute(Paths.FixedAssets.DeviceAssets, AccountantDepartment.GetRoles(), DevicesAccountant, "assignAssetNumbers", "screen_search_desktop"),
    ]),
];
