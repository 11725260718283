import { User } from "oidc-client-ts";
import { userManagerConfig } from "utils-ts/auth/userManager";

function getUser() {
    const oidcStorage = localStorage.getItem(
        `oidc:${window.config.env.toLowerCase()}.user:${userManagerConfig.authority}:${userManagerConfig.client_id}`
    );

    if (!oidcStorage) {
        return null;
    }

    return User.fromStorageString(oidcStorage);
}

export default getUser;
