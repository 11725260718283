import { combineIntoQueryParams } from "utils/objectExtensions";
import { downloadXlsxFile } from "utils/rsaa/downloadFileAction";
import moment from "moment";
import types from "./types";

export const getLinkingReport =
    ({ dateFrom, dateTo }) =>
    async (dispatch) => {
        await dispatch(
            downloadXlsxFile(
                `api/external-product/linking-report?${combineIntoQueryParams({
                    dateFrom,
                    dateTo,
                })}`,
                types.generateLinkingReport.all,
                `Raport matchowania ${moment(dateFrom).format("YYYY_MM_DD")}-${moment(dateTo).format("YYYY_MM_DD")}`
            )
        );

        return Promise.resolve();
    };
