import { RSAA } from "redux-api-middleware";
import { commerce } from "store/actionTypePrefixes";
import { splitOrderId } from "utils/splitIds";

export const types = {
    DISMISS_ALERT: `${commerce}/DISMISS_ALERT`,
    DISMISS_ALERT_SUCCESS: `${commerce}/DISMISS_ALERT_SUCCESS`,
    DISMISS_ALERT_FAILURE: `${commerce}/DISMISS_ALERT_FAILURE`,
};

const dismissAlert = (orderId) => async (dispatch) => {
    const { customerId, orderNumber } = splitOrderId(orderId);
    return dispatch({
        [RSAA]: {
            endpoint: `api/v1/users/${customerId}/orders/${orderNumber}/alert`,
            headers: { "Content-Type": "application/json" },
            method: "DELETE",
            types: [types.DISMISS_ALERT, types.DISMISS_ALERT_SUCCESS, types.DISMISS_ALERT_FAILURE],
        },
    });
};

export const actions = {
    dismissAlert,
};
