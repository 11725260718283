const customers = {
    id: "id",
    fullName: "fullName",
    email: "email",
    customers: "customers",
    billingAddress: "billingAddress",
    customerInfo: "customerInfo",
    ordersList: "ordersList",
    customer: "customer",
    customerWithId: "customerWithId",
    editCustomerWithId: "editCustomerWithId",
    accountType: "accountType",
    lastActiveAt: "lastActiveAt",
    phoneNumber: "phoneNumber",
    searchBy: "searchBy",
    complaintsDataTab: "complaintsDataTab",
    paymentDataTab: "paymentDataTab",
    gdprTab: "gdprTab",
    consents: "consents",
    consentChange: "consentChange",
    consentChannel: "consentChannel",
    consentChangeQuestion: "consentChangeQuestion",
    export: "export",
    exportWithConsents: "exportWithConsents",
    personalInfo: "personalInfo",
    homeAccount: "homeAccount",
    testAccount: "testAccount",
    specialClient: "specialClient",
    noProductLimits: "noProductLimits",
    noWaterLimits: "noWaterLimits",
    vipAccount: "vipAccount",
    disableAlerts: "disableAlerts",
    friscoWorkers: "friscoWorkers",
    fullMembership: "fullMembership",
    disablePersonalization: "disablePersonalization",
    disableReview: "disableReview",
    disableOrderLimits: "disableOrderLimits",
    disableMails: "disableMails",
    disableGmailMarkup: "disableGmailMarkup",
    collectiveInvoice: "collectiveInvoice",
    electronicInvoice: "electronicInvoice",
    unlimitedWindows: "unlimitedWindows",
    forceWarehousePOZ: "forceWarehousePOZ",
    forceWarehouseWAW: "forceWarehouseWAW",
    forceWarehouseWA3: "forceWarehouseWA3",
    forceWarehouseWRO: "forceWarehouseWRO",
    deleteCustomer: "deleteCustomer",
    removeCustomerQuestion: "removeCustomerQuestion",
    loginAsCustomer: "loginAsCustomer",
    customerBalanceAuditDataTab: "customerBalanceAuditDataTab",
    customerVouchersDataTab: "customerVouchersDataTab",
    voucherId: "voucherId",
    userId: "userId",
    campaignName: "campaignName",
    voucherType: "voucherType",
    voucherValue: "voucherValue",
    voucherPercent: "voucherPercent",
    paymentAmount: "paymentAmount",
    minimumValue: "minimumValue",
    maximumValue: "maximumValue",
    useLimit: "useLimit",
    isCumulative: "isCumulative",
    includeTobacco: "includeTobacco",
    includeAlcohol: "includeAlcohol",
    freeDelivery: "freeDelivery",
    isReminding: "isReminding",
    isSending: "isSending",
    customerReviewsDataTab: "customerReviewsDataTab",
    orderWithId: "orderWithId",
    reviewedAt: "reviewedAt",
    recommendation: "recommendation",
    hasQualityIssue: "hasQualityIssue",
    hasComplienceIssue: "hasComplienceIssue",
    hasPunctualityIssue: "hasPunctualityIssue",
    hasPaymentIssue: "hasPaymentIssue",
    hasPageIssue: "hasPageIssue",
    customerMembershipTab: "customerMembershipTab",
    orderId: "orderId",
    membershipPoints: "membershipPoints",
    awardedAt: "awardedAt",
    expiresAt: "expiresAt",
    qualityIssue: "qualityIssue",
    complienceIssue: "complienceIssue",
    punctualityIssue: "punctualityIssue",
    paymentIssue: "paymentIssue",
    pageIssue: "pageIssue",
    generalIssue: "generalIssue",
    whatDoYouLike: "whatDoYouLike",
    generalSuggestions: "generalSuggestions",
    offerSuggestions: "offerSuggestions",
    balanceCorrectionTab: "balanceCorrectionTab",
    removeBalanceCorrectionQuestion: "removeBalanceCorrectionQuestion",
    removeBalanceCorrection: "removeBalanceCorrection",
    addBalanceCorrection: "addBalanceCorrection",
    status: "status",
    balance: "balance",
    reason: "reason",
    createdBy: "createdBy",
    createdAt: "createdAt",
    changeAccountType: "changeAccountType",
    changeAccountTypeQuestion: "changeAccountTypeQuestion",
    creditAmount: "creditAmount",
    paymentTerm: "paymentTerm",
    defferedPaymentSettings: "defferedPaymentSettings",
    disableRationing: "disableRationing",
    disableTotalLimit: "disableTotalLimit",
    AXExport: "AXExport",
    shippingAddress: "shippingAddress",
    disableCosts: "disableCosts",
    savedReservations: "savedReservations",
    pushbackWindows: "pushbackWindows",
    unlimitedStock: "unlimitedStock",
    disableVan: "disableVan",
    operationsOrderList: "operationsOrderList",
    reservationAudit: "reservationAudit",
    disableTexts: "disableTexts",
    disableTrustMate: "disableTrustMate",
    allowMissingPayment: "allowMissingPayment",
    disableReservationMails: "disableReservationMails",
    disableReservationTexts: "disableReservationTexts",
    removeEmailVerificationRequirment: "removeEmailVerificationRequirment",
    KDR: "KDR",
    DisallowUserBatches: "DisallowUserBatches",
    DisallowAdult: "DisallowAdult",
    DisableOnDeliveryPayments: "DisableOnDeliveryPayments",
    BindWarehouseWAW: "BindWarehouseWAW",
    PersonalTarget: "PersonalTarget",
    memo: "memo",
    deleteMemo: "deleteMemo",
    toRemoveMemo: "toRemoveMemo",
    disablePromotions: "disablePromotions",
    accountManager: "accountManager",
    segments: "segments",
    changeAccountManager: "changeAccountManager",
    changeAccountManagerQuestion: "changeAccountManagerQuestion",
    changeAccountManagerQuestionToNone: "changeAccountManagerQuestionToNone",
    deferredPaymentTab: "deferredPaymentTab",
    changeManager: "changeManager",
    changeManagerQuestion: "changeManagerQuestion",
    changeManagerQuestionToNone: "changeManagerQuestionToNone",
    removeDeferredPayment: "removeDeferredPayment",
    removeDeferredPaymentQuestion: "removeDeferredPaymentQuestion",
    retentionManager: "retentionManager",
    disableLetters: "disableLetters",
    EditFFBalance: "EditFFBalance",
    SeasonNumber: "SeasonNumber",
    cashbackPercent: "cashbackPercent",
    cashbackLimit: "cashbackLimit",
    contactHistory: "contactHistory",
    isConsented: "isConsented",
    MembershipRewards: "MembershipRewards",
    Registration: "Registration",
    Phone: "Phone",
    Email: "Email",
    Profile: "Profile",
    Review: "Review",
    Telecommunication: "Telecommunication",
    ThirdParty: "ThirdParty",
    consentType: "consentType",
    channelName: "channelName",
} as const;

export default customers;
