import { makeStyles } from "tss-react/mui";
import { Grid, Paper, Typography } from "@mui/material";
import moment from "moment";
import { NavigationAction } from "./types";
import Day from "./Day";
import Header from "./Header";
import { chunks, getDaysInMonth, isSameMonth, isWithinInterval } from "./utils";
import { inDateRange, isEndOfRange, isRangeSameDay, isStartOfRange } from "./utils";

const WEEK_DAYS = ["Pon", "Wto", "Śro", "Czw", "Pią", "Sob", "Nie"];

const useStyles = makeStyles()(() => ({
    root: {
        width: 290,
    },
    weekDaysContainer: {
        marginTop: 10,
        paddingLeft: 25,
        paddingRight: 25,
    },
    daysContainer: {
        paddingLeft: 15,
        paddingRight: 15,
        marginTop: 15,
        marginBottom: 20,
    },
}));

const Month = (props) => {
    const { classes } = useStyles();
    const today = moment().startOf("day");
    const { helpers, handlers, value: date, dateRange, marker, setValue: setDate, minDate, maxDate } = props;

    // eslint-disable-next-line react/destructuring-assignment
    const [back, forward] = props.navState;

    return (
        <Paper
            square
            elevation={0}
            className={classes.root}
        >
            <Grid container>
                <Header
                    date={date}
                    setDate={setDate}
                    nextDisabled={!forward}
                    prevDisabled={!back}
                    onClickPrevious={() => handlers.onMonthNavigate(marker, NavigationAction.Previous)}
                    onClickNext={() => handlers.onMonthNavigate(marker, NavigationAction.Next)}
                />

                <Grid
                    item
                    container
                    direction="row"
                    justifyContent="space-between"
                    className={classes.weekDaysContainer}
                >
                    {WEEK_DAYS.map((day) => (
                        <Typography
                            color="textSecondary"
                            key={day}
                            variant="caption"
                        >
                            {day}
                        </Typography>
                    ))}
                </Grid>

                <Grid
                    item
                    container
                    direction="column"
                    justifyContent="space-between"
                    className={classes.daysContainer}
                >
                    {chunks(getDaysInMonth(date), 7).map((week, idx) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Grid
                            key={idx}
                            container
                            direction="row"
                            justifyContent="center"
                        >
                            {week.map((day) => {
                                const isStart = isStartOfRange(dateRange, day);
                                const isEnd = isEndOfRange(dateRange, day);
                                const isRangeOneDay = isRangeSameDay(dateRange);
                                const highlighted = inDateRange(dateRange, day) || helpers.inHoverRange(day);
                                const todayClass = moment(day).startOf("day").isSame(today) && "today";

                                return (
                                    <Day
                                        key={moment(day).format("MM-DD-YYYY")}
                                        filled={isStart || isEnd}
                                        outlined={todayClass}
                                        highlighted={highlighted && !isRangeOneDay}
                                        disabled={
                                            !isSameMonth(date, day) ||
                                            !isWithinInterval(day, {
                                                start: minDate,
                                                end: maxDate,
                                            })
                                        }
                                        startOfRange={isStart && !isRangeOneDay}
                                        endOfRange={isEnd && !isRangeOneDay}
                                        onClick={() => handlers.onDayClick(day)}
                                        onHover={() => handlers.onDayHover(day)}
                                        value={moment(day).date()}
                                    />
                                );
                            })}
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Paper>
    );
};

export default Month;
