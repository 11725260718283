import { RSAA } from "redux-api-middleware";
import { manager, operations } from "store/actionTypePrefixes";
import { actions as productNamesActions } from "store/order/productNames/action";
import { combineIntoQueryParams } from "utils/objectExtensions";

export const types = {
    SELECT_ORDER_ALERT: `${manager}/SELECT_ORDER_ALERT`,
    GET_ORDERS_BY_ID: `${operations}/GET_ORDERS_BY_ID`,
    GET_ORDERS_BY_ID_SUCCESS: `${operations}/GET_ORDERS_BY_ID_SUCCESS`,
    GET_ORDERS_BY_ID_FAILURE: `${operations}/GET_ORDERS_BY_ID_FAILURE`,
};

const selectOrderAlert = (orderAlert) => async (dispatch) => {
    await dispatch({ type: types.SELECT_ORDER_ALERT, payload: orderAlert });
    return Promise.resolve();
};

const getOrders = (customerId, orderIds) => async (dispatch) => {
    const params = combineIntoQueryParams({ orderIds });
    const getProductNames = async (lines) => {
        const productIds = lines.map((x) => x.productId);
        const productNames = await dispatch(productNamesActions.getProductNames("WAW", customerId, productIds));
        const products = lines.map((line) => {
            const { fullName = "" } = productNames.find((x) => Number(x.productId) === Number(line.productId)) || {};
            return {
                ...line,
                productName: fullName,
            };
        });

        return products;
    };

    const { payload: orders } = await dispatch({
        [RSAA]: {
            endpoint: `api/users/${customerId}/orders/get-by-ids?${params}`,
            headers: { "Content-Type": "application/json" },
            method: "GET",
            types: [types.GET_ORDERS_BY_ID, "ingore", types.GET_ORDERS_BY_ID_FAILURE],
        },
    });
    const ordersWithNames = await Promise.all(
        orders.map(async (x) => {
            const productLines = await getProductNames(x.productLines);
            return Promise.resolve({ ...x, productLines: productLines });
        })
    );
    dispatch({ type: types.GET_ORDERS_BY_ID_SUCCESS, payload: ordersWithNames });

    return Promise.resolve();
};

export const actions = {
    selectOrderAlert,
    getOrders,
};
