import Button from "components/Button/Button";
import InfoDialog from "components/Dialog/InfoDialog";
import { renderFieldOrText } from "components/FormHelpers/Controls";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { withStyles } from "tss-react/mui";
import Validator, { decimalPlaces, greaterThan, mustBeNumber } from "utils/validators/basic";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { common, customers } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { tValidation, validation } from "utils-ts/validations/translation";

const DefferredPaymentDialog = withStyles(
    ({ open, handleAccept, handleReject, handleSubmit, classes, submitting, pristine, isEnabled }) => {
        const { t } = useTranslation(["customers", "common"]);

        const fieldWrapper = (type, name, label, multiline, autoComplete) => {
            return (
                <Grid
                    className={classes.field}
                    item
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="stretch"
                >
                    <Field
                        type={type}
                        name={name}
                        component={renderFieldOrText}
                        label={label}
                        multiline={multiline}
                        autoComplete={"off"}
                    />
                </Grid>
            );
        };

        return (
            <InfoDialog
                title={t(customers.defferedPaymentSettings)}
                open={Boolean(open)}
                onClose={handleReject}
            >
                <div className={classes.container}>
                    <form
                        onSubmit={handleSubmit((val) => {
                            handleAccept(val.creditLimit);
                        })}
                    >
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="stretch"
                        >
                            {fieldWrapper("boolean", "creditLimit.isEnabled", t(customers.defferedPaymentSettings))}
                            {isEnabled && fieldWrapper("text", "creditLimit.creditAmount", t(customers.creditAmount))}
                            {isEnabled && fieldWrapper("text", "creditLimit.paymentTerm", t(customers.paymentTerm))}
                            <Button
                                fullWidth
                                type="submit"
                                disabled={submitting || pristine}
                                className={classes.button}
                                variant="contained"
                                color="primary"
                            >
                                {t(`common:${common.save}`)}
                            </Button>
                        </Grid>
                    </form>
                </div>
            </InfoDialog>
        );
    },
    (theme) => ({
        root: {
            marginTop: theme.spacing(6),
        },
        button: {
            marginTop: theme.spacing(3),
        },
        field: {
            marginTop: theme.spacing(2),
        },
    })
);

const validate = (values) => {
    const creditLimitErrors = {};
    const { creditAmount, paymentTerm } = values;

    const haveValue = Boolean(creditAmount) || Boolean(paymentTerm);

    creditLimitErrors.creditAmount = new Validator(creditAmount)
        .must(mustBeNumber, tValidation(validation.mustBeNumber))
        .must(greaterThan(0), tValidation(validation.greaterThan, { number: 0 }))
        .must(decimalPlaces([0, 2]))
        .allWhen(haveValue)
        .validate();

    creditLimitErrors.paymentTerm = new Validator(paymentTerm)
        .must(mustBeNumber, tValidation(validation.mustBeNumber))
        .must(greaterThan(0), tValidation(validation.greaterThan, { number: 0 }))
        .must(decimalPlaces([0, 0]))
        .allWhen(haveValue)
        .validate();

    return { creditLimit: creditLimitErrors };
};

const mapStateToProps = (state, ownProps) => {
    const { creditLimit } = ownProps;
    const isEnabled = formValueSelector("defferred-payment-settings-form")(state, "creditLimit.isEnabled");
    return {
        isEnabled,
        initialValues: {
            creditLimit: {
                ...creditLimit,
                isEnabled: creditLimit && creditLimit.creditAmount && creditLimit.creditAmount > 0,
            },
        },
    };
};

export default connect(mapStateToProps)(
    reduxForm({
        form: "defferred-payment-settings-form",
        enableReinitialize: true,
        validate,
    })(DefferredPaymentDialog)
);
