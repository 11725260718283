import { DeleteRequest, GetRequest, PagedRequest, PostRequest, PutRequest, get, getPaged, post, put, remove, withMeta } from "utils/extensions";
import { downloadPdfFile } from "utils/rsaa/downloadFileAction";
import fetchFile from "utils/rsaa/fetchFileAction";
import types from "./types";

const endpoint = (id) => `api/procedure${id ? "/" + id : ""}`;

export const findProcedures =
    ({ pageSize, pageIndex, ...params }) =>
    async (dispatch) => {
        return dispatch(
            getPaged(
                new PagedRequest(pageIndex, pageSize, endpoint(), types.findProcedures.all, {
                    queryParams: params,
                })
            )
        );
    };

export const initForm = (id) => async (dispatch) => {
    await dispatch({ type: types.formInit });

    if (id) {
        return dispatch(get(new GetRequest(endpoint(id), types.getProcedure.all)));
    }
};

export const getProcedureFile = (id, procedureVersion, fileName) => async (dispatch) => {
    await dispatch(fetchFile(`${endpoint(id)}/${procedureVersion}/file`, null, withMeta({ blobName: fileName })(types.getProcedureFile.all)));
};

export const getProcedureAcceptances = (id) => async (dispatch) => {
    return dispatch(get(new GetRequest(endpoint(id) + "/acceptances", types.getProcedureAcceptances.all)));
};

export const downloadProcedureFile = (id, procedureVersion, fileName) => async (dispatch) => {
    return await dispatch(downloadPdfFile(`${endpoint(id)}/${procedureVersion}/file`, types.downloadProcedureFile.all, fileName));
};

export const createOrUpdateProcedure = (id, values) => async (dispatch) => {
    if (!id) {
        return await dispatch(
            post(
                new PostRequest(endpoint(id), types.createOrUpdateProcedure(null).all, {
                    body: values,
                })
            )
        );
    } else {
        return await dispatch(
            put(
                new PutRequest(endpoint(id), types.createOrUpdateProcedure(id).all, {
                    body: values,
                })
            )
        );
    }
};

export const deleteProcedure = (id) => async (dispatch) => {
    return dispatch(remove(new DeleteRequest(endpoint(id), types.deleteProcedure.all)));
};
