import { Button } from "components/Button";
import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import { findVendorOperations, vendorOperationListSelector } from "store/vendors/vendorOperation";
import { formatTypes } from "utils/formating";
import { usePush } from "utils/hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { Layout } from "components";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";

const VendorOperationsList = () => {
    const push = usePush();
    const dispatch = useDispatch();
    const { t } = useTranslation("vendor");
    const { isLoading, pageIndex, pageSize, totalCount, items } = useSelector(vendorOperationListSelector);

    useEffect(() => {
        dispatch(findVendorOperations(pageIndex, pageSize));
    }, [dispatch]);

    const columns = [
        {
            key: "vendorId",
            value: t(vendor.vendorId),
            type: formatTypes.text,
            transform: (value) => value?.split("_")[0],
        },
        {
            key: "company",
            value: t(vendor.company),
            type: formatTypes.text,
            transform: (value) => {
                if (value === "FRS") {
                    return "Frisco";
                } else if (value === "F24") {
                    return "FR24";
                } else if (value === "DRV") {
                    return "Dorvest";
                } else {
                    return value;
                }
            },
        },
        {
            key: "name",
            value: t(vendor.vendorName),
            type: formatTypes.text,
        },
        {
            key: "action",
            value: "",
            type: formatTypes.action,
            actionType: "preview",
            action: (item) => push(`/vendors/operation/${item.id}`),
        },
    ];

    return (
        <Layout
            main
            headerText={t(vendor.vendorOperations)}
        >
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
            >
                <Grid item>
                    <Button
                        color="primary"
                        onClick={() => {
                            push(`/vendors/operation/new`);
                        }}
                    >
                        {t(vendor.add)}
                    </Button>
                </Grid>
            </Grid>
            <SimpleTable
                isLoading={isLoading}
                columns={columns}
                data={items}
                pagination
                hover
                stripped
                paginationProps={{
                    pageIndex,
                    totalCount,
                    pageSize,
                    onPageChange: (pageIndex) => dispatch(findVendorOperations(pageIndex, pageSize)),
                    onRowsPerPageChange: (pageSize) => dispatch(findVendorOperations(1, pageSize)),
                }}
            />
        </Layout>
    );
};

export default VendorOperationsList;
