import { Cell, Row } from "components/Table/SimpleTable";
import { Delete, Edit } from "components/Table/SimpleTable/TableActions";
import { withStyles } from "tss-react/mui";
import { formatValue } from "utils/formating";
import ImportantIcon from "@mui/icons-material/ErrorOutline";
import { IconButton } from "@mui/material";

const MemoRow = ({ classes, columns, memo, handleMemoUpdate, handleEdit, handleDelete, audit, readOnly }) => {
    const editMemo = () => {
        if (!audit && !readOnly) {
            handleEdit(memo);
        }
    };

    return (
        <Row
            hover
            onDobuleClick={editMemo}
        >
            {columns.map(({ key, type, options }, index) => {
                if (key === "isImportant") {
                    return (
                        <Cell key={index}>
                            <IconButton
                                onClick={() => {
                                    if (!audit && !readOnly) {
                                        handleMemoUpdate(memo);
                                    }
                                }}
                            >
                                <ImportantIcon className={memo[key] ? classes.isImportant : ""} />
                            </IconButton>
                        </Cell>
                    );
                }

                if (key === "edit") {
                    return (
                        <td key={index}>
                            <Edit handleClick={editMemo} />
                        </td>
                    );
                }

                if (key === "delete") {
                    return (
                        <td key={index}>
                            <Delete
                                key={index}
                                handleClick={() => {
                                    handleDelete(memo);
                                }}
                            />
                        </td>
                    );
                }

                return (
                    <Cell
                        key={index}
                        value={formatValue(memo[key], type, options)}
                    />
                );
            })}
        </Row>
    );
};

export default withStyles(MemoRow, (theme) => ({
    isImportant: { color: theme.palette.secondary.main },
}));
