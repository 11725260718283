import { FormField } from "components/Form";
import { productAnalyticGroupsSelector } from "store/autocomplete";
import { orderBy } from "utils/extensions/arrayExtensions";
import { withFormName } from "utils/hoc";
import { useCommonTranslation } from "utils/hooks";
import { useSelector } from "react-redux";

const ProductAnalyticGroupsAutocomplete = ({ form, sectionPrefix, validate, name = "analyticGroups", ...props }) => {
    const { t, common } = useCommonTranslation();
    const productsAnalyticGroups = useSelector(productAnalyticGroupsSelector);

    return (
        <FormField
            stretch
            name={name}
            label={t(common.analyticGroup)}
            type={"autocomplete"}
            items={orderBy(productsAnalyticGroups, (x) => x.name).map((x) => x.id)}
            validate={validate}
            multiple
            disableCloseOnSelect
            getOptionLabel={(analyticGroup) =>
                (typeof analyticGroup === "string" ? productsAnalyticGroups.find((c) => c.id === analyticGroup) : analyticGroup)?.name
            }
            filterOptions={(options, params) => {
                var { inputValue, getOptionLabel } = params;

                return options.filter((o) => getOptionLabel(o).toLowerCase().includes(inputValue?.toLowerCase()));
            }}
            {...props}
        />
    );
};

export default withFormName(ProductAnalyticGroupsAutocomplete);
