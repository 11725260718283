import fileTypes from "consts/fileTypes";
import { RSAA } from "redux-api-middleware";

const downloadFile = (endpoint, fileType, actions, fileName, readBody = false, actionPayload = undefined) => {
    const [init, success, failure] = actions;
    const name =
        !fileType?.omitExtension && fileType?.extension && fileName && !fileName?.toLowerCase().endsWith(fileType.extension)
            ? `${fileName}.${fileType.extension}`
            : fileName;

    return {
        [RSAA]: {
            endpoint,
            headers: fileType ? { "Content-Type": fileType.mimeType } : {},
            method: "GET",
            types: [
                {
                    type: init.type || init,
                    meta: init.meta,
                    payload: () => actionPayload,
                },
                {
                    type: success.type || success,
                    meta: success.meta,
                    payload: async (_, __, response) => {
                        let responseValue = undefined;
                        if (readBody) {
                            const blob = await response.blob();
                            responseValue = blob;
                            const reader = new FileReader();
                            reader.onload = function () {
                                const linkElement = document.createElement("a");
                                linkElement.setAttribute("href", URL.createObjectURL(new Blob([JSON.parse(reader.result).join("\n")])));
                                linkElement.setAttribute("download", name);
                                const clickEvent = new MouseEvent("click", {
                                    view: window,
                                    bubbles: true,
                                    cancelable: false,
                                });
                                linkElement.dispatchEvent(clickEvent);
                            };
                            reader.readAsText(blob);
                        } else {
                            const blob = await response.blob();
                            responseValue = blob;
                            if (blob.size > 0) {
                                const linkElement = document.createElement("a");
                                linkElement.setAttribute("href", URL.createObjectURL(responseValue));
                                linkElement.setAttribute("download", name);
                                const clickEvent = new MouseEvent("click", {
                                    view: window,
                                    bubbles: true,
                                    cancelable: false,
                                });
                                linkElement.dispatchEvent(clickEvent);
                            }
                        }
                        return actionPayload;
                    },
                },
                failure,
            ],
        },
    };
};

export default downloadFile;

export const downloadPdfFile = (endpoint, actions, fileName, readBody = false, actionPayload = undefined) =>
    downloadFile(endpoint, fileTypes.pdf, actions, fileName, readBody, actionPayload);

export const downloadXlsxFile = (endpoint, actions, fileName, readBody = false, actionPayload = undefined) =>
    downloadFile(endpoint, fileTypes.xlsx, actions, fileName, readBody, actionPayload);

export const downloadDocxFile = (endpoint, actions, fileName, readBody = false, actionPayload = undefined) =>
    downloadFile(endpoint, fileTypes.docx, actions, fileName, readBody, actionPayload);

export const rsaaActionType = (init, success, failure) => [init, success, failure];
