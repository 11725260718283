import { combineReducers } from "redux";
import { default as content } from "./content/reducer.js";
import { default as contracts } from "./contracts/reducer.js";
import { default as feed } from "./feed/reducer.js";
import { default as giftChoices } from "./giftChoices/reducer.js";
import { default as gifts } from "./gifts/reducer.js";
import { default as productPreview } from "./productPreview/reducer.js";
import { default as promotions } from "./promotions/reducer.js";
import { default as rewards } from "./rewards/reducer.js";

export const offers = combineReducers({
    content,
    contracts,
    feed,
    promotions,
    productPreview,
    gifts,
    giftChoices,
    rewards,
});
