import { makeStyles } from "tss-react/mui";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { Grid, IconButton, MenuItem, Select } from "@mui/material";
import moment from "moment";

const useStyles = makeStyles()(() => ({
    iconContainer: {
        padding: 5,
    },
    icon: {
        "padding": 10,
        "&:hover": {
            background: "none",
        },
    },
}));

const generateYears = (relativeTo, count) => {
    const half = Math.floor(count / 2);
    return Array(count)
        .fill(0)
        .map((_y, i) => moment(relativeTo).year() - half + i);
};

const Header = ({ date, setDate, nextDisabled, prevDisabled, onClickNext, onClickPrevious }) => {
    const { classes } = useStyles();

    const handleMonthChange = (event) => {
        setDate(moment(date).month(parseInt(event.target.value)));
    };

    const handleYearChange = (event) => {
        setDate(moment(date).year(parseInt(event.target.value)));
    };

    return (
        <Grid
            container
            justifyContent="space-between"
            alignItems="center"
        >
            <Grid
                item
                className={classes.iconContainer}
            >
                <IconButton
                    className={classes.icon}
                    disabled={prevDisabled}
                    onClick={onClickPrevious}
                >
                    <ChevronLeft color={prevDisabled ? "disabled" : "action"} />
                </IconButton>
            </Grid>
            <Grid item>
                <Select
                    variant="standard"
                    value={moment(date).month()}
                    onChange={handleMonthChange}
                    MenuProps={{ disablePortal: true }}
                >
                    {moment.monthsShort().map((month, idx) => (
                        <MenuItem
                            key={month}
                            value={idx}
                        >
                            {month}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>

            <Grid item>
                <Select
                    variant="standard"
                    value={moment(date).year()}
                    onChange={handleYearChange}
                    MenuProps={{ disablePortal: true }}
                >
                    {generateYears(moment(), 20).map((year) => (
                        <MenuItem
                            key={year}
                            value={year}
                        >
                            {year}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid
                item
                className={classes.iconContainer}
            >
                <IconButton
                    className={classes.icon}
                    disabled={nextDisabled}
                    onClick={onClickNext}
                >
                    <ChevronRight color={nextDisabled ? "disabled" : "action"} />
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default Header;
