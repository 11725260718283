import { types as rootTypes } from "store/customers/customer/action";
import defaultListState from "store/defaultListState";
import { combineReducers } from "redux";
import { types } from "./action";

const initialState = {
    list: defaultListState,
    voucher: {
        isLoading: false,
        isSubmiting: false,
    },
    editVoucher: {},
};

export default combineReducers({
    list: (state = initialState.list, { type, payload }) => {
        switch (type) {
            case rootTypes.CUSTOMER_CHANGED:
                return initialState.list;
            case types.CUSTOMER_VOUCHER_GET_LIST:
                return { ...state, isLoading: true };
            case types.CUSTOMER_VOUCHER_GET_LIST_FAILURE:
                return { ...state, isLoading: false };
            case types.CUSTOMER_VOUCHERS_GET_LIST_WITH_PROFILE:
                return { ...state, ...payload, isLoading: false };
            default:
                return { ...state };
        }
    },
    voucher: (state = initialState.voucher, { type, payload }) => {
        switch (type) {
            case rootTypes.CUSTOMER_CHANGED:
                return initialState.voucher;
            case types.CUSTOMER_CAMPAIGNS_GET:
                return { ...state, isLoading: true };
            case types.CUSTOMER_CAMPAIGNS_GET_SUCCESS:
                return { ...state, campaigns: payload, isLoading: true };
            case types.CUSTOMER_CAMPAIGNS_GET_FAILURE:
                return { ...state, isLoading: false };
            case types.CUSTOMER_MAKE_VOUCHER:
                return { ...state, isSubmiting: true };
            case types.CUSTOMER_MAKE_VOUCHER_SUCCESS:
            case types.CUSTOMER_MAKE_VOUCHER_FAILURE:
                return { ...state, isSubmiting: false };
            default:
                return { ...state };
        }
    },
    editVoucher: (state = initialState.editVoucher, { type, payload }) => {
        switch (type) {
            case types.CUSTOMER_VOUCHER_GET:
                return { ...state, isLoading: true };
            case types.CUSTOMER_VOUCHER_GET_SUCCESS:
                return { ...state, ...payload, isLoading: true };
            case types.CUSTOMER_VOUCHER_GET_FAILURE:
                return { ...state, isLoading: false };
            case types.CUSTOMER_VOUCHER_CLEAR_VOUCHER:
                return { ...initialState.editVoucher };
            default:
                return { ...state };
        }
    },
});
