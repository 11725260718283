import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import { formatTypes } from "utils/formating";
import { useAppLocation, useCommonTranslation } from "utils/hooks";
import React from "react";

const ProductPromotions = ({ promotions = [] }) => {
    const { t, common } = useCommonTranslation();
    const appLocation = useAppLocation();

    const columns = [
        {
            key: "id",
            value: common.id,
            type: formatTypes.link,
            options: {
                target: "_blank",
                url: (id) => `${appLocation}/offers/promotions/form/${id}`,
            },
        },
        {
            key: "name",
            value: common.name,
            type: formatTypes.text,
        },
        {
            key: "promotionType",
            value: common.promotionType,
            type: formatTypes.text,
        },
    ];

    return (
        <SimpleTable
            stripped
            hover
            columns={columns.map((c) => ({ ...c, value: t(c.value) }))}
            data={promotions.map((c) => ({ ...c, ...c.definition }))}
        />
    );
};

export default ProductPromotions;
