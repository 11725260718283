import Bar from "components/Charts/Bar";
import moment from "moment";

const Chart = ({ items }) => {
    const data = {
        labels: items.map((x) => `${moment(x.startsAt).format("HH:mm")} - ${moment(x.endsAt).format("HH:mm")}`),
        datasets: [
            {
                label: "Rezerwacje",
                backgroundColor: "rgba(33, 56, 163, 0.2)",
                borderColor: "rgba(33, 56, 163, 1)",
                hoverBackgroundColor: "rgba(33, 56, 163, 0.4)",
                hoverBorderColor: "rgba(33, 56, 163, 1)",
                borderWidth: 1,
                data: items.map((x) => x.reservationTotal),
            },
            {
                label: "Zamówienia",
                backgroundColor: "rgba(164, 0, 79, 0.2)",
                borderColor: "rgba(164, 0, 79, 1)",
                hoverBackgroundColor: "rgba(164, 0, 79, 0.4)",
                hoverBorderColor: "rgba(164, 0, 79, 1)",
                borderWidth: 1,
                data: items.map((x) => x.orderCount),
            },
        ],
    };

    return (
        <Bar
            data={data}
            width={200}
            height={70}
            type="bar"
        />
    );
};

export default Chart;
