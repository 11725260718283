import { RSAA } from "redux-api-middleware";
import { commerce } from "store/actionTypePrefixes";
import { asAsyncFormTypes } from "utils/extensions/arrayExtensions";
import { combineIntoQueryParams } from "utils/objectExtensions";

export const types = {
    GET_PRODUCT_LIMITS: `${commerce}/GET_PRODUCT_LIMITS`,
    GET_PRODUCT_LIMITS_SUCCESS: `${commerce}/GET_PRODUCT_LIMITS_SUCCESS`,
    GET_PRODUCT_LIMITS_FAILURE: `${commerce}/GET_PRODUCT_LIMITS_FAILURE`,
    REMOVE_PRODUCT_LIMIT: `${commerce}/REMOVE_PRODUCT_LIMIT`,
    REMOVE_PRODUCT_LIMIT_SUCCESS: `${commerce}/REMOVE_PRODUCT_LIMIT_SUCCESS`,
    REMOVE_PRODUCT_LIMIT_FAILURE: `${commerce}/REMOVE_PRODUCT_LIMIT_FAILURE`,
};

const getPoductLimits =
    ({ isActive, ...rest }) =>
    async (dispatch) => {
        let query = { ...rest };
        if (isActive !== "") query.isActive = isActive;
        const queryParams = combineIntoQueryParams({ ...query });
        return dispatch({
            [RSAA]: {
                endpoint: `api/v1/pricing/product-limits?${queryParams}`,
                headers: { "Content-Type": "application/json" },
                method: "GET",
                types: [types.GET_PRODUCT_LIMITS, types.GET_PRODUCT_LIMITS_SUCCESS, types.GET_PRODUCT_LIMITS_FAILURE],
            },
        });
    };

const remove = (id) => async (dispatch) => {
    return dispatch({
        [RSAA]: {
            endpoint: `api/v1/pricing/product-limits/${id}`,
            headers: { "Content-Type": "application/json" },
            method: "DELETE",
            types: asAsyncFormTypes([types.REMOVE_PRODUCT_LIMIT, types.REMOVE_PRODUCT_LIMIT_SUCCESS, types.REMOVE_PRODUCT_LIMIT_FAILURE]),
        },
    });
};

export const actions = {
    getPoductLimits,
    remove,
};
