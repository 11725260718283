import { decimalNormalize } from "components/FormHelpers/ControlFormaters";
import Validator, {
    greaterOrEqualsThan,
    greaterThan,
    lessOrEqualsThan,
    mustBeNumber,
    notEmpty,
    notEmptyValidator,
    regexes,
    stringLength,
} from "utils/validators/basic";
import moment from "moment";
import { chain } from "lodash";
import { common, customers, voucherKinds } from "translations";
import { tValidation, validation } from "utils-ts/validations/translation";

const fields = (t, isEditMode, selectedVoucherType, voucherCampaigns) => {
    const voucherTypes = chain(voucherKinds)
        .filter((x) => x !== voucherKinds.promotion_voucher)
        .map((x) => ({ value: x, name: t(`voucherKinds:${x}`) }))
        .value();
    const campaigns = chain(voucherCampaigns)
        .partition((x) => x.includes("bok"))
        .reduce((c, n) => [...c, ...n], [])
        .value();
    return [
        {
            label: "ID reklamacji",
            column: 1,
            name: "complaintId",
            type: "text",
            disabled: isEditMode,
        },
        {
            label: t(customers.voucherId),
            column: 1,
            name: "id",
            type: "text",
            disabled: isEditMode,
        },
        {
            label: t(customers.userId),
            column: 1,
            name: "userId",
            type: "text",
            disabled: isEditMode,
        },
        {
            label: t(`common:${common.validFrom}`),
            column: 1,
            name: "validFrom",
            type: "date",
            disablePast: true,
        },
        {
            label: t(`common:${common.validTo}`),
            column: 1,
            name: "validTo",
            type: "date",
            disablePast: true,
        },
        {
            label: t(`common:${common.campaignName}`),
            column: 1,
            name: "campaignName",
            type: "autocomplete",
            items: campaigns,
        },
        {
            label: t(customers.voucherType),
            column: 2,
            name: "type",
            type: "select",
            items: voucherTypes,
        },
        {
            label: t(customers.voucherValue),
            column: 2,
            name: "definition.voucherValue",
            type: "text",
            skip: selectedVoucherType !== voucherKinds.value_voucher,
            format: decimalNormalize,
        },
        {
            label: t(customers.voucherPercent),
            column: 2,
            name: "definition.voucherPercent",
            type: "text",
            skip: selectedVoucherType !== voucherKinds.percent_voucher,
            format: decimalNormalize,
        },
        {
            label: t(customers.cashbackPercent),
            column: 2,
            name: "definition.cashbackPercent",
            type: "text",
            skip: selectedVoucherType !== voucherKinds.cashback_voucher,
            format: decimalNormalize,
        },
        {
            label: t(customers.cashbackLimit),
            column: 2,
            name: "definition.cashbackLimit",
            type: "text",
            skip: selectedVoucherType !== voucherKinds.cashback_voucher,
            format: decimalNormalize,
        },
        {
            label: t(`common:${common.balanceFRS}`),
            column: 2,
            name: "frs",
            type: "text",
            skip: selectedVoucherType !== voucherKinds.amount_voucher,
            format: decimalNormalize,
        },
        {
            label: t(`common:${common.balanceF24}`),
            column: 2,
            name: "f24",
            type: "text",
            skip: selectedVoucherType !== voucherKinds.amount_voucher,
            format: decimalNormalize,
        },
        {
            label: t(customers.membershipPoints),
            column: 2,
            name: "definition.voucherPromotions[0].definition.effects[0].membershipPoints.bonusPoints",
            type: "text",
            skip: selectedVoucherType !== voucherKinds.promotion_voucher,
        },
        {
            label: t(customers.minimumValue),
            column: 2,
            name: "definition.requirements.total.minimumValue",
            type: "text",
            format: decimalNormalize,
        },
        {
            label: t(customers.maximumValue),
            column: 2,
            name: "definition.requirements.total.maximumValue",
            type: "text",
            format: decimalNormalize,
        },
        {
            label: t(customers.useLimit),
            column: 2,
            name: "definition.useLimit",
            type: "number",
            format: decimalNormalize,
        },
        {
            label: t(customers.isCumulative),
            column: 3,
            name: "definition.isCumulative",
            type: "boolean",
        },
        {
            label: t(customers.includeAlcohol),
            column: 3,
            name: "definition.includeAlcohol",
            type: "boolean",
        },
        {
            label: t(customers.freeDelivery),
            column: 3,
            name: "definition.freeDelivery",
            type: "boolean",
        },
        {
            label: t(customers.isReminding),
            column: 3,
            name: "isReminding",
            type: "boolean",
        },
        {
            label: t(customers.isSending),
            column: 3,
            name: "isSending",
            type: "boolean",
            readOnly: isEditMode,
        },
    ];
};

const guidValidate = (value) => {
    return new Validator(value)
        .mustRegex(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/, tValidation(validation.notValidGuidId))
        .when(notEmpty(value))
        .validate();
};

const validate = (values = {}) => {
    const errors = { definition: { requirements: { total: {} } } };
    const { complaintId, voucherId, userId, campaignName, type, definition = {}, frs, f24 } = values;
    const validFrom = values.validFrom;
    const validTo = values.validTo;
    const {
        requirements = {},
        voucherValue = 0,
        voucherPercent = 0,
        useLimit = 0,
        freeDelivery,
        cashbackPercent = 0,
        cashbackLimit = undefined,
    } = definition;
    const { total = {} } = requirements;
    const { minimumValue, maximumValue } = total;
    errors.complaintId = guidValidate(complaintId);
    errors.voucherId = notEmptyValidator(voucherId)
        .must(stringLength, tValidation(validation.stringLength, { min: 5, max: 250 }))
        .when(Boolean(voucherId))
        .validate();
    errors.userId = new Validator(userId).mustRegex(regexes.userId).when(Boolean(userId)).validate();
    errors.validFrom = notEmptyValidator(validFrom).validate();
    errors.validTo = notEmptyValidator(validTo)
        .must(
            (val) => moment(val).isSameOrAfter(validFrom),
            tValidation(validation.dateNotLessThan, {
                dateNotLessThan: moment(validFrom).format("DD.MM.YYYY"),
            })
        )
        .when(moment(validTo).isValid())
        .validate();
    errors.campaignName = notEmptyValidator(campaignName).validate();
    errors.type = notEmptyValidator(type).when(!freeDelivery).validate();
    errors.definition.voucherValue = notEmptyValidator(voucherValue)
        .must(mustBeNumber, tValidation(validation.mustBeNumber))
        .must(greaterThan(0), tValidation(validation.greaterThan, { number: 0 }))
        .allWhen(type === voucherKinds.value_voucher)
        .validate();
    errors.definition.voucherPercent = notEmptyValidator(voucherPercent)
        .must(mustBeNumber, tValidation(validation.mustBeNumber))
        .must(greaterThan(0), tValidation(validation.greaterThan, { number: 0 }))
        .must(lessOrEqualsThan, tValidation(validation.lessOrEqualsThan, { number: 100 }))
        .allWhen(type === voucherKinds.percent_voucher)
        .validate();
    errors.definition.paymentAmount = notEmptyValidator(frs)
        .must(mustBeNumber, tValidation(validation.mustBeNumber))
        .must(greaterThan(0), tValidation(validation.greaterThan, { number: 0 }))
        .allWhen(type === voucherKinds.amount_voucher)
        .validate();
    errors.definition.paymentAmount = notEmptyValidator(f24)
        .must(mustBeNumber, tValidation(validation.mustBeNumber))
        .must(greaterThan(0), tValidation(validation.greaterThan, { number: 0 }))
        .allWhen(type === voucherKinds.amount_voucher)
        .validate();
    errors.definition.cashbackPercent = notEmptyValidator(cashbackPercent)
        .must(mustBeNumber, tValidation(validation.mustBeNumber))
        .must(greaterThan(0), tValidation(validation.greaterThan, { number: 0 }))
        .must(lessOrEqualsThan(100), tValidation(validation.lessOrEqualsThan, { number: 100 }))
        .allWhen(type === voucherKinds.cashback_voucher)
        .validate();
    errors.definition.cashbackLimit = new Validator(cashbackLimit)
        .must(mustBeNumber, tValidation(validation.mustBeNumber))
        .must(greaterThan(0), tValidation(validation.greaterThan, { number: 0 }))
        .allWhen(!!cashbackLimit)
        .validate();
    errors.definition.requirements.total.minimumValue = new Validator(minimumValue)
        .must(mustBeNumber, tValidation(validation.mustBeNumber))
        .must(greaterThan(0), tValidation(validation.greaterThan, { number: 0 }))
        .allWhen(!!minimumValue)
        .validate();
    errors.definition.requirements.total.maximumValue = new Validator(maximumValue)
        .must(mustBeNumber, tValidation(validation.mustBeNumber))
        .must(greaterThan(0), tValidation(validation.greaterThan, { number: 0 }))
        .must(greaterOrEqualsThan(minimumValue), tValidation(validation.greaterOrEqualsThan, { number: minimumValue }))
        .allWhen(!!maximumValue)
        .validate();
    errors.definition.useLimit = notEmptyValidator(useLimit)
        .must(mustBeNumber, tValidation(validation.mustBeNumber))
        .must(greaterThan(0), tValidation(validation.greaterThan, { number: 0 }))
        .validate();
    return errors;
};

const handleFormSubmit = async (makeVoucher, val, voucherId) => {
    const { ...formData } = val;
    let { definition } = val;
    if (val.type === voucherKinds.amount_voucher) {
        val.definition.paymentAmount = {
            FRS: val.frs,
            F24: val.f24,
        };
    }

    return await makeVoucher({ ...formData, definition: { ...definition } }, voucherId);
};

export { fields, validate, handleFormSubmit };
