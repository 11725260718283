import { SubmissionError } from "redux-form/lib/SubmissionError";
import { showMessageBox } from "store/common/messageBox/actions";
import { displayMessage } from "store/messaging/actions";
import _ from "lodash";
import ValidationError from "./validationError";

export const apiErrorMiddleware = (store) => (next) => (action) => {
    if (["@@redux-form/STOP_ASYNC_VALIDATION", "@@redux-form/STOP_SUBMIT"].includes(action.type)) {
        return next(action);
    }

    if (action.error && action.payload) {
        if (
            action.meta &&
            (action.meta.hideError || (action.meta.hideErrorIf && action.meta.hideErrorIf(action.payload.response || action.payload)))
        ) {
            console.error(
                "errorHidden",
                action.payload.response && action.payload.response.errors
                    ? action.payload.response.length
                        ? action.payload.response.map((value) => value.errorMessage)
                        : [JSON.stringify(action.payload.response)]
                    : action.payload.stack
            );
        } else {
            if (action.payload.response && action.payload.response.errors) {
                store.dispatch(
                    displayMessage({
                        message: "Błąd walidacji",
                        variant: "error",
                    })
                );

                const { errors } = action.payload.response;
                let errorsText = ["Wykryto następujące błędy walidacji:"];
                let submissionError = new SubmissionError({
                    _error: "Submit failed",
                });
                for (let i = 0; i < errors.length; i++) {
                    const error = new ValidationError(errors[i]);
                    errorsText.push(`- ${error.GetPropertyName()}: ${error.GetErrorMessage()}`);
                    _.set(submissionError.errors, error.GetPropertyPath(), error.GetErrorMessage());
                }

                store.dispatch(
                    showMessageBox({
                        type: "error",
                        props: {
                            rows: errorsText,
                        },
                    })
                );

                return Promise.reject(submissionError);
            } else {
                if (action.payload.status === 410) {
                    store.dispatch(
                        showMessageBox({
                            type: "error",
                            props: {
                                rows: ["Zasób został usunięty"],
                            },
                        })
                    );
                } else if (action.payload.status === 403) {
                    store.dispatch(
                        showMessageBox({
                            type: "error",
                            props: {
                                rows: ["Brak uprawnień, aby zobaczyć dokument"],
                            },
                        })
                    );
                } else {
                    //TODO when reloading due to auth dont show messagebox or show with message about reauth
                    console.log({
                        action,
                        payload: action.payload,
                        status: action.payload?.status,
                        message: action.payload?.message,
                    });
                    store.dispatch(
                        showMessageBox({
                            type: "error",
                            props: {
                                rows: [
                                    `${action.payload?.status}: ${action.payload?.message}`,
                                    `correlationId: ${action.response?.correlationId}`,
                                    action.type,
                                ],
                            },
                        })
                    );
                }
            }
        }
    }

    return next(action);
};
