import { combineReducers } from "redux";
import { types } from "./action";
import consents from "./consents/reducer.js";
import customerBalance from "./customerBalance/reducer.js";
import customerBalanceAudit from "./customerBalanceAudit/reducer.js";
import customerBalanceCorrection from "./customerBalanceCorrection/reducer.js";
import customerInfo from "./customerInfo/reducer.js";
import customerMemos from "./customerMemos/reducer.js";
import customerProfileActions from "./customerProfileActions/reducer.js";
import customerReviews from "./customerReviews/reducer.js";
import customerVouchers from "./customerVouchers/reducer.js";
import reservationAudit from "./reservationAudit/reducer.js";

const initialState = {
    customerId: "",
};

export const customer = combineReducers({
    customerInfo,
    customerBalance,
    customerBalanceAudit,
    customerProfileActions,
    customerMemos,
    consents,
    customerVouchers,
    customerReviews,
    customerBalanceCorrection,
    reservationAudit,
    customerRoot: (state = initialState, action) => {
        const { type, payload } = action;

        switch (type) {
            case types.CUSTOMER_CHANGED:
                return {
                    customerId: payload,
                };
            default:
                return state;
        }
    },
});
