import { manager, operations } from "store/actionTypePrefixes";
import { AsyncTypes, createAction } from "utils/extensions";
import { asAsyncFormTypes, asSilientError } from "utils/extensions/arrayExtensions";

const types = {
    findVatCorrectionBatches: new AsyncTypes("FIND_VAT_CORRECTION_BATCHES", operations),
    updatePagination: createAction("UPDATE_VAT_CORRECTION_BATCHES_PAGINATION", manager),
    getBatchFile: new AsyncTypes("GET_BATCH_FILE", operations),
    getVatCorrectionBatch: new AsyncTypes("GET_VAT_CORRECTION_BATCH", operations),
    retryComplaint: new AsyncTypes("POST_RETRY_COMPLAINT", operations, [asAsyncFormTypes]),
    resetVatCorrectionBatch: createAction("RESET_VAT_CORRECTION_BATCH", manager),
    validateVatCorrections: new AsyncTypes("VALIDATE_VAT_CORRECTIONS", operations),
    importVatCorrections: new AsyncTypes("IMPORT_VAT_CORRECTIONS", operations, [asSilientError]),
    resetImportVatCorrections: createAction("RESET_IMPORT_VAT_CORRECTIONS", manager),
};

export default types;
