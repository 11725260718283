import { decimalCount } from "utils/numberExtensions";
import moment from "moment";
import { tValidation, validation } from "utils-ts/validations/translation";
import * as regexesObj from "./regexes";

export const regexes = regexesObj;

const replaceDecimalSignAndCovertToNumber = (value) => {
    const val = value || "0";
    return Number(val.toString().replace(",", "."));
};

export const must = (value, predicate, message) => {
    return !predicate(value) ? message : "";
};

export const isEmpty = (value) => {
    return value === undefined || value === null || value === "";
};

export const notEmpty = (value) => {
    return !isEmpty(value);
};

export const notEmptyArray = (value) => {
    return value != undefined && value.length > 0;
};

export const stringLength = (min, max) => {
    const inRange = (value = "") => min <= value.length && value.length <= max;
    return inRange;
};

export const mustBeNumber = (value) => {
    const toValidate = value.toString().replace(",", ".");
    return !isNaN(Number(toValidate));
};

export const beInteger = (value) => {
    const toValidate = Number(value.toString().replace(",", "."));
    return !isNaN(toValidate) && Number.isInteger(toValidate);
};

export const greaterThan = (a) => {
    const greaterThan = (value) => replaceDecimalSignAndCovertToNumber(value) > a;
    return greaterThan;
};

export const greaterOrEqualsThan = (a) => {
    const greaterOrEqualsThan = (value) => replaceDecimalSignAndCovertToNumber(value) >= a;
    return greaterOrEqualsThan;
};

export const lessThan = (a) => {
    const lessThan = (value) => replaceDecimalSignAndCovertToNumber(value) < a;
    return lessThan;
};

export function validCustomerId(value) {
    return value.toString().match(/^[0-9]{6,7}$/);
}

export const lessOrEqualsThan = (a) => {
    const lessOrEqualsThan = (value) => replaceDecimalSignAndCovertToNumber(value) <= a;
    return lessOrEqualsThan;
};

export const inRange = (min, max) => {
    const inRange = (value) => !value || !value.length || (min <= value.length && value.length <= max);
    return inRange;
};

export const decimalPlaces = (array) => {
    const decimalPlaces = (value) => {
        const val = decimalCount(replaceDecimalSignAndCovertToNumber(value));
        const [min, max] = array;
        return val >= min && val <= max;
    };
    return decimalPlaces;
};

export function notEmptyValidator(value) {
    return new Validator(value).must(notEmpty, tValidation(validation.required));
}

export function notEmptyArrayValidator(value) {
    return new Validator(value).must(notEmptyArray, tValidation("notEmptyArray", { min: 1 }));
}

export function greaterDateThan(value) {
    const greaterDateThan = (valueToValidate) => {
        return moment(value).isBefore(moment(valueToValidate));
    };

    return greaterDateThan;
}

export function lessDateThan(value) {
    const lessDateThan = (valueToValidate) => {
        return moment(value).isAfter(moment(valueToValidate));
    };

    return lessDateThan;
}

export function validFromValidator(validFrom, validTo) {
    return new Validator(validFrom)
        .must(
            lessDateThan(validTo),
            tValidation(validation.lessDateThan, {
                date: validTo,
            })
        )
        .when(Boolean(validTo && validFrom));
}

export function validToValidator(validTo, validFrom) {
    return new Validator(validTo)
        .must(
            greaterDateThan(validFrom),
            tValidation(validation.greaterDateThan, {
                date: validFrom,
            })
        )
        .when(Boolean(validTo && validFrom));
}

export function defaultLenghtValidator(value) {
    return notEmptyValidator(value).must(stringLength(3, 250), tValidation(validation.notEmpty), {
        min: 3,
        max: 250,
    });
}

export function notEmptyAndNumber(value) {
    return new Validator(value)
        .must(notEmpty, tValidation(validation.notEmpty))
        .must(mustBeNumber, tValidation(validation.mustBeNumber))
        .when(notEmpty(value))
        .validate();
}

export function notEmptyAndPositiveNumber(value) {
    return new Validator(value)
        .must(notEmpty, tValidation(validation.notEmpty))
        .must(mustBeNumber, tValidation(validation.mustBeNumber))
        .must(greaterThan(0), tValidation(validation.mustBePossitive))
        .when(notEmpty(value))
        .validate();
}

export function notEmptyAndInteger(value) {
    return new Validator(value)
        .must(notEmpty, tValidation(validation.notEmpty))
        .must(beInteger, tValidation(validation.mustBeInteger))
        .when(notEmpty(value))
        .validate();
}

export function emptyOrInteger(value) {
    return new Validator(value).must(beInteger, tValidation(validation.mustBeInteger)).when(notEmpty(value)).validate();
}

export function notEmptyAndPositiveInteger(value) {
    return new Validator(value)
        .must(notEmpty, tValidation(validation.notEmpty))
        .must(beInteger, tValidation(validation.mustBeInteger))
        .must(greaterThan(0), tValidation(validation.mustBePossitive))
        .when(notEmpty(value))
        .validate();
}

export default class Validator {
    constructor(value) {
        this.value = value;
        this.validators = [];
    }
    must(predicate, message, params) {
        let validationMessage = message;
        if (!validationMessage) {
            validationMessage = tValidation(predicate.name, params);
        }

        this.validators = [...this.validators, { predicate, message: validationMessage }];
        return this;
    }

    mustRegex(regex, message, params) {
        let validationMessage = message;
        if (!validationMessage) {
            validationMessage = tValidation(regex.name, params);
        }

        this.validators = [
            ...this.validators,
            {
                predicate: () => regex.test(this.value),
                message: validationMessage,
            },
        ];
        return this;
    }

    when(boolean) {
        if (!boolean) {
            this.validators = this.validators.slice(0, -1);
        }

        return this;
    }

    allWhen(boolean) {
        if (!boolean) {
            this.validators = [];
        }

        return this;
    }

    validate() {
        let error = "";
        for (let { predicate, message } of this.validators) {
            const result = predicate(this.value);
            if (!result) {
                error = message;
                break;
            }
        }

        return error === "" ? false : error;
    }
}
