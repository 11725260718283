import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import { changeRefundationsPagination, findRefundationsForApproval, refundationsDecisionListSelector } from "store/vendors/decisionList";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useAppLocation } from "utils/hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";

const RefundationDecisionsList = () => {
    const appLocation = useAppLocation();
    const { t } = useTranslation("vendor");
    const dispatch = useDispatch();

    const openInNewWindow = ({ vendorId, id }) => {
        window.open(`${appLocation}/vendors/${vendorId}/refundations/form/${id}`, "_blank");
    };

    const { isLoading, pageIndex, totalCount, pageSize, items } = useSelector(refundationsDecisionListSelector);

    useEffect(() => {
        dispatch(findRefundationsForApproval({ pageIndex, pageSize }));
    }, [dispatch, pageIndex, pageSize]);

    useEffect(() => {
        const fetch = () => {
            dispatch(findRefundationsForApproval({ pageIndex, pageSize }));
        };

        window.addEventListener("focus", fetch);

        return () => {
            window.removeEventListener("focus", fetch);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        {
            key: "vendorId",
            value: t(vendor.vendorId),
            type: formatTypes.text,
        },
        {
            key: "vendorName",
            value: t(vendor.vendorName),
            type: formatTypes.text,
        },
        {
            key: "dateFrom",
            value: t(vendor.dateFrom),
            type: formatTypes.time,
            options: { timeFormat: timePrecisonFormats.days },
        },
        {
            key: "dateTo",
            value: t(vendor.dateTo),
            type: formatTypes.time,
            options: { timeFormat: timePrecisonFormats.days },
        },
        {
            key: "email",
            value: t(vendor.responsibleUser),
            type: formatTypes.text,
        },
        {
            key: "updatedAt",
            value: t(vendor.updatedAt),
            type: formatTypes.time,
            options: { timeFormat: timePrecisonFormats.seconds },
        },
        {
            key: "action",
            value: "",
            type: formatTypes.action,
            actionType: "preview",
            action: (item) => openInNewWindow(item),
        },
    ];

    return (
        <SimpleTable
            isLoading={isLoading}
            columns={columns}
            data={items}
            onRowDoubleClick={(_, item) => {
                openInNewWindow(item);
            }}
            stripped
            hover
            pagination
            paginationProps={{
                pageIndex,
                totalCount,
                pageSize,
                onPageChange: (pageIndex) => {
                    dispatch(changeRefundationsPagination({ pageIndex, pageSize }));
                },
                onRowsPerPageChange: (pageSize) => {
                    dispatch(changeRefundationsPagination({ pageIndex: 1, pageSize }));
                },
            }}
        />
    );
};

export default RefundationDecisionsList;
