import defaultListState from "store/defaultListState";
import { types } from "./actions";

const initialState = {
    ...defaultListState,
    transferInprogress: false,
};

export const paymentTransfers = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.GET_PAYMENT_TRANSFERS:
            return { ...initialState, isLoading: true };
        case types.GET_PAYMENT_TRANSFERS_SUCCESS:
            return { ...state, isLoading: false, ...payload };
        case types.GET_PAYMENT_TRANSFERS_FAILURE:
            return { ...state, isLoading: false };
        case types.TRANSFER_PAYMENT:
            return { ...state, transferInprogress: true };
        case types.TRANSFER_PAYMENT_SUCCESS:
            return { ...state, transferInprogress: false };
        case types.TRANSFER_PAYMENT_FAILURE:
            return { ...state, transferInprogress: false };
        default:
            return state;
    }
};
