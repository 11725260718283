import { Button } from "components/Button";
import React from "react";
import { Grid } from "@mui/material";

const FileInput = ({ id, onChange, label, loading, accept, selectedFile, multiple, disabled = false }) => {
    return (
        <>
            <Button
                onClick={() => {
                    document.getElementById(id).click();
                }}
                variant="outlined"
                type="button"
                color="primary"
                fullWidth
                loading={loading}
                disabled={disabled}
            >
                {label || "Wybierz plik"}
            </Button>
            <Grid>
                <input
                    accept={accept}
                    id={id}
                    onChange={(event) => {
                        onChange(event.target.files, event);
                        event.target.value = null;
                    }}
                    value={selectedFile?.filename}
                    type="file"
                    style={{ display: "none" }}
                    multiple={multiple}
                />
            </Grid>
        </>
    );
};

export default FileInput;
