import { combineReducers } from "redux";
import { productLimits } from "./productLimis/reducer.js";
import { productRestrictions } from "./productRestrictions/reducer.js";
import { rations } from "./rations/reducer.js";
import { waterLimits } from "./waterLimis/reducer.js";

export const pricing = combineReducers({
    productLimits,
    productRestrictions,
    waterLimits,
    rations,
});
