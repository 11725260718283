import Layout from "components/Grid/Layout";
import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import { FieldArray } from "redux-form";
import React, { Fragment } from "react";
import _ from "lodash";
import { orders } from "translations";
import { useTranslation } from "utils-ts/hooks";
import ProductsDialog from "./ProductsDialog";
import UpsellActions from "./UpsellActions";
import UpsellLine from "./renderers/UpsellLine";

const validateUpsellLines = (values) => {
    if (!values || values.length === 0) return undefined;
    const rquiresNormalProduct = _(values)
        .groupBy((x) => x.upsellType)
        .map((x) => x.every((y) => y.isPromotionOnly))
        .reduce((c, n) => c || n, false);
    return rquiresNormalProduct ? "Dodaj produkt, który nie jest samplem" : undefined;
};

const Upsell = (props) => {
    const { customerId, pushUpsellProduct, warehouse, editionDisabled, upsellLines } = props;
    const [productDialogOpen, setModal] = React.useState(false);
    const { t } = useTranslation("orders");
    //prettier-ignore
    const columns = [
            { key: "productId",        value: t(orders.productId),   type: "text",     colSpan: 1,},
            { key: "ean",              value: t(orders.ean),         type: "text",     colSpan: 1,},
            { key: "description",      value: t(orders.productName), type: "text",     colSpan: 2,},
            { key: "quantity",         value: t(orders.quantity),    type: "text",     colSpan: 1,},
            { key: "grossPrice",       value: t(orders.grossPrice),  type: "currency", colSpan: 1,},
            { key: "upsellType",       value: t(orders.upsellType),  type: "text",     colSpan: 2,},
            { key: "upsellReasonType", value: t(orders.reason),      type: "text",     colSpan: 2,},
            { key: "delete",           value: "",                    type: "action",   colSpan: 1,},
        ];

    const handleDialogOpen = () => {
        setModal(true);
    };
    const handleDialogClose = () => {
        setModal(false);
    };

    return (
        <Layout headerText={t(orders.upsell)}>
            <UpsellActions
                handleDialogOpen={handleDialogOpen}
                pushUpsellProduct={pushUpsellProduct}
                editionDisabled={editionDisabled}
            />
            <ProductsDialog
                warehouse={warehouse}
                customerId={customerId}
                open={productDialogOpen}
                onClose={handleDialogClose}
                pushUpsellProduct={pushUpsellProduct}
            />
            {upsellLines && upsellLines.length ? (
                <SimpleTable columns={columns}>
                    <FieldArray
                        name={"upsellLines"}
                        props={{
                            columns: columns,
                            editionDisabled,
                        }}
                        component={UpsellLine}
                        validate={validateUpsellLines}
                    />
                </SimpleTable>
            ) : (
                <Fragment />
            )}
        </Layout>
    );
};

export default Upsell;
