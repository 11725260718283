import { Button } from "components/Button";
import Cell from "components/Table/SimpleTable/Cell";
import Row from "components/Table/SimpleTable/Row";
import { downloadInvoiceFile, retryInvoiceCorrection } from "store/vendors/subsidiaryGains";
import { formatTypes, formatValue, timePrecisonFormats } from "utils/formating";
import { useParams } from "utils/hooks";
import { useDispatch } from "react-redux";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { getDocumentStatus } from "./InvoiceRow";

const CorrectionRow = ({ item = {}, invoiceId }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation("vendor");
    const { vendorId, subsidiaryGainId } = useParams();

    return (
        <Row>
            <Cell>{t(vendor.correction)}</Cell>
            <Cell>{t(getDocumentStatus(item.operation.status))}</Cell>
            <Cell>{item.operation.reason}</Cell>
            <Cell>{item.invoiceCorrectionNumber}</Cell>
            <Cell colSpan={2}>
                {formatValue(item.accountingDate, formatTypes.time, {
                    timeFormat: timePrecisonFormats.days,
                })}
            </Cell>
            <Cell>
                {item.operation.status === "Error" ? (
                    <Button
                        size="small"
                        color="primary"
                        onClick={() => {
                            dispatch(retryInvoiceCorrection(vendorId, subsidiaryGainId, invoiceId, item.correctionId));
                        }}
                    >
                        {t(vendor.retry)}
                    </Button>
                ) : (
                    <></>
                )}
            </Cell>
            <Cell>
                <Button
                    size="small"
                    color="primary"
                    disabled={true}
                    onClick={() => {
                        dispatch(downloadInvoiceFile(vendorId, invoiceId, item.correctionId, item.invoiceCorrectionNumber));
                    }}
                >
                    {t(vendor.getInvoice)}
                </Button>
            </Cell>
        </Row>
    );
};

export default CorrectionRow;
