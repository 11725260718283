import { createSelector } from "reselect";

const cacheSelector = (state) => state.cache;

export const prodcutsSelector = createSelector(cacheSelector, (cache) => cache.products);

export const categoriesSelector = createSelector(cacheSelector, (cache) => cache.categories);

const rootSelector = (state) => state.vendorPms;

export const invoicesSelector = createSelector(rootSelector, (vendor) => {
    return {
        invoices: vendor?.invoices,
    };
});

export const creditLimitsSelector = createSelector(rootSelector, (vendor) => {
    return {
        creditLimits: vendor?.creditLimits,
        hasPendingCreditLimitOperation: vendor?.hasPendingCreditLimitOperation,
    };
});

export const creditLimitSelector = (state) => state.creditLimit;
