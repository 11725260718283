import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import { makeStyles } from "tss-react/mui";
import { link } from "utils/formating/formatTypes";
import { useAppLocation, useCommonTranslation } from "utils/hooks";
import { Layout } from "components";

const useStyles = makeStyles()((theme) => ({
    tableSpacing: {
        marginTop: theme.spacing(2),
    },
}));

const PromotionDecisions = ({ promotionDecisions }) => {
    const appLocation = useAppLocation();
    const { t, common } = useCommonTranslation();
    const { classes } = useStyles();
    const columns = [
        {
            key: "promotionId",
            value: t(common.promotions),
            type: link,
            options: {
                target: "_blank",
                url: (id) => `${appLocation}/offers/promotions/form/${id}`,
            },
        },
        {
            key: "blank",
            type: "text",
        },
    ];

    return (
        <>
            <Layout
                headerText={t(common.rejectedPromotions)}
                className={classes.tableSpacing}
            >
                <SimpleTable
                    columns={columns}
                    stripped
                    data={promotionDecisions.filter((p) => p.isRejected)}
                />
            </Layout>
        </>
    );
};

export default PromotionDecisions;
