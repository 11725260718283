import { useDebouncedCallback } from "use-debounce";
import React, { Fragment, useState } from "react";
import { List, ListItem, TextField, Typography } from "@mui/material";
import _ from "lodash";

const ListInput = (props) => {
    const {
        label,
        input: { value, ...input },
        meta: { touched, invalid, error },
        itemRenderer,
        onAdd,
        additionalValueCheck,
        splitValues = true,
        useList = true,
        readOnly,
        splitByWhitespace = true,
    } = props;
    const [inputValue, setInputValue] = useState("");
    const setFieldValue = useDebouncedCallback((event) => {
        const {
            target: { value: eventValue },
        } = event;
        setInputValue("");
        const newValues = _(String(eventValue))
            .split(splitValues ? (splitByWhitespace ? /[,;\s]/ : /[,;]/) : /$/)
            .filter((x) => Boolean(x) && (!additionalValueCheck || additionalValueCheck(x)))
            .map((x) => String(x))
            .uniq()
            .filter((x) => (value || []).every((y) => y !== x))
            .value();

        if (eventValue && newValues && newValues.length) {
            if (onAdd) onAdd(newValues);
            input.onChange([...value, ...newValues]);
        }
    }, 750);
    const setInput = (event) => {
        const {
            target: { value: val },
        } = event;
        setInputValue(val);
    };
    const handleRemove = (v) => {
        const newValues = value.filter((x) => x !== v);
        input.onChange(newValues);
    };

    return (
        <Fragment>
            {readOnly ? (
                <Fragment>
                    <Typography
                        component="div"
                        variant="caption"
                        style={{ width: "100%" }}
                    >
                        {label}
                    </Typography>
                </Fragment>
            ) : (
                <Fragment />
            )}

            {useList ? (
                <List dense>
                    {(value || []).map((v, i) => (
                        <ListItem key={i}>{itemRenderer(v, handleRemove, props)}</ListItem>
                    ))}
                </List>
            ) : (
                (value || []).map((v) => itemRenderer(v, handleRemove, props))
            )}

            {readOnly ? (
                <Fragment />
            ) : (
                <TextField
                    variant="standard"
                    fullWidth
                    label={label}
                    placeholder={label}
                    onChange={(event) => {
                        event.persist();
                        setInput(event);
                        setFieldValue(event);
                    }}
                    onBlur={(event) => {
                        event.persist();
                        setInput(event);
                        setFieldValue(event);
                    }}
                    value={inputValue}
                    error={touched && invalid}
                    helperText={touched && error}
                />
            )}
        </Fragment>
    );
};

export default ListInput;
