const segments = {
    HomeAccount: "HomeAccount",
    TestAccount: "TestAccount",
    BusinessAccount: "BusinessAccount",
    OfficeAccount: "OfficeAccount",
    VipAccount: "VipAccount",
    SpecialClient: "SpecialClient",
    NoProductLimits: "NoProductLimits",
    NoWaterLimits: "NoWaterLimits",
    FriscoWorkers: "FriscoWorkers",
    FullMembership: "FullMembership",
    DisableAlerts: "DisableAlerts",
    DisablePersonalization: "DisablePersonalization",
    DisableReview: "DisableReview",
    DisableOrderLimits: "DisableOrderLimits",
    DisableMails: "DisableMails",
    ConsentingEmail: "ConsentingEmail",
    CollectiveInvoice: "CollectiveInvoice",
    ElectronicInvoice: "ElectronicInvoice",
    UnlimitedWindows: "UnlimitedWindows",
    ForceWarehousePOZ: "ForceWarehousePOZ",
    ForceWarehouseWAW: "ForceWarehouseWAW",
    ForceWarehouseWA3: "ForceWarehouseWA3",
    ForceWarehouseWRO: "ForceWarehouseWRO",
    MobileApp: "MobileApp",
    CreditCard: "CreditCard",
    PersonalTarget: "PersonalTarget",
    MembershipRewards: "MembershipRewards",
} as const;

export default segments;
