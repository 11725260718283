import ReviewList from "views/reviews/reviewList/ReviewList";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import { B2BAndSupportDepartment, ECommerceDepartment, LogisticianDepartment, MarketingDepartment, TradeMarketingDepartment } from "routing-ts/roles";

export const Reviews: PrivateRoute[] = [
    new PrivateRoute(
        Paths.Reviews,
        [
            "Analyst",
            "AXUser",
            "Management",
            "SalesManager",
            ...B2BAndSupportDepartment,
            ...ECommerceDepartment.GetRoles(),
            ...LogisticianDepartment.GetRoles(),
            ...MarketingDepartment.GetRoles(),
            ...TradeMarketingDepartment.GetRoles(),
        ],
        ReviewList,
        "reviews",
        "rate_review"
    ),
];
