import { RSAA } from "redux-api-middleware";
import { operations } from "store/actionTypePrefixes";
import Cache, { cacheNames, isExpired } from "utils/cache/cacheProvider";
import { combineIntoQueryParams } from "utils/objectExtensions";
import moment from "moment";

const ORDERS_GET_PRODUCT_NAMES = `${operations}/ORDERS_GET_PRODUCT_NAMES`;
const ORDERS_GET_PRODUCT_NAMES_SUCCESS = `${operations}/ORDERS_GET_PRODUCT_NAMES_SUCCESS`;
const ORDERS_GET_PRODUCT_NAMES_FAILURE = `${operations}/ORDERS_GET_PRODUCT_NAMES_FAILURE`;

export const types = {
    ORDERS_GET_PRODUCT_NAMES,
    ORDERS_GET_PRODUCT_NAMES_SUCCESS,
    ORDERS_GET_PRODUCT_NAMES_FAILURE,
};

const getProductNames = (warehouse, userId, productIds) => async (dispatch) => {
    const cache = Cache(cacheNames.productNameStore);
    const $cachedPromises = productIds.map((id) => cache.getItem(id));
    const cached = (await Promise.all($cachedPromises)).filter((x) => x && isExpired(x.cacheExpiresAt));
    const cachedIds = cached.map((x) => x.productId);
    const notCached = productIds
        .filter((x) => !(x === "USL003" || x === "USL001" || x === "USL002"))
        .reduce((c, n) => {
            return cachedIds.some((x) => String(x).padStart(6, "0") === n) ? c : [...c, n];
        }, []);

    let products = [];
    if (notCached.length !== 0) {
        const queryParams = combineIntoQueryParams({ productIds: notCached });
        const { payload, error } = await dispatch({
            [RSAA]: {
                endpoint: `api/offer-proxy/products/${warehouse}/${userId}?${queryParams}`,
                method: "GET",
                types: [ORDERS_GET_PRODUCT_NAMES, ORDERS_GET_PRODUCT_NAMES_SUCCESS, ORDERS_GET_PRODUCT_NAMES_FAILURE],
            },
        });

        if (!error) {
            products = payload;
        }
    }

    products.forEach((product) => {
        cache.setItem(String(product.productId).padStart(6, "0"), {
            ...product,
            cacheExpiresAt: moment().add(1, "days").format(),
        });
    });

    return Promise.resolve([...products, ...cached]);
};

export const actions = {
    getProductNames,
};
