import React, { forwardRef } from "react";
import { DateTimePicker as MuiDatePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";
import { DateTimePickerProps } from "control-types";
import FormHelperText from "components-ts/controls/inputs/FormHelperText";
import { TextWithLabel } from "components-ts/text";

const DateTimePicker: React.FC<DateTimePickerProps> = forwardRef(
    ({ error, label, value, onChange, classes, format = "DD.MM.YYYY HH:mm", disableFuture, disablePast, slotProps, slots, readOnly }, ref) => {
        if (readOnly) {
            return (
                <TextWithLabel
                    value={value ? (moment.isMoment(value) ? value : moment(value)).format(format || "YYYY-MM-DD HH:mm:ss") : undefined}
                    label={label}
                    error={error}
                />
            );
        }

        return (
            <>
                <MuiDatePicker
                    onChange={(newValue, _) => onChange(newValue !== null && newValue.isValid() ? newValue : undefined)}
                    format={format}
                    value={value ? (moment.isMoment(value) ? value : moment(value)) : null}
                    label={label}
                    slotProps={{
                        textField: {
                            error: error?.hasError,
                            variant: "standard",
                            fullWidth: true,
                            classes: classes,
                        },
                        field: {
                            clearable: true,
                            onClear: () => onChange(undefined),
                        },
                        ...slotProps,
                    }}
                    disableFuture={disableFuture ?? false}
                    disablePast={disablePast ?? false}
                    slots={slots}
                    ampm={false}
                    ref={ref}
                />
                <FormHelperText error={error} />
            </>
        );
    }
);

export default DateTimePicker;
