import Layout from "components/Grid/Layout";
import TableWithToggleFilter from "components/Table/TableWithToggleFilter/TableWithToggleFilter";
import roles from "consts/roles";
import { documentDefinitionListSelector, findDocumentDefinitions } from "store/dictonaries/documentDefinitions";
import { formatTypes } from "utils/formating";
import { useCommonTranslation } from "utils/hooks";
import { useUser } from "context";
import { usePush } from "utils-ts/hooks";

const DocumentDefinitionsList = () => {
    const { t, common } = useCommonTranslation();
    const { push } = usePush();
    const { isInRoleOrAdmin } = useUser();
    const isAuditor = isInRoleOrAdmin(roles.Auditor);
    const pushToForm = (id) => push(id ? `/dictionaries/document-definitions/form/${id}` : "/dictionaries/document-definitions/form");

    const columns = [
        { key: "name", value: t(common.name), type: formatTypes.text },
        {
            key: "description",
            value: t(common.description),
            type: formatTypes.text,
        },
        {
            key: "archiveDocuments",
            value: t(common.archiveDocuments),
            type: formatTypes.boolean,
        },
        {
            key: "isActive",
            value: t(common.isActive),
            type: formatTypes.boolean,
        },
        {
            key: "action",
            value: "",
            type: formatTypes.action,
            actionType: "preview",
            action: (item) => pushToForm(item.id),
        },
    ];

    return (
        <Layout
            main
            headerText={t(common.documentDefinitions)}
        >
            <TableWithToggleFilter
                columns={columns}
                addItemAction={() => pushToForm()}
                dataSelector={documentDefinitionListSelector}
                dataSourceAction={findDocumentDefinitions}
                canAddNewItem={isAuditor}
            />
        </Layout>
    );
};

export default DocumentDefinitionsList;
