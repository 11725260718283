import { combineReducers } from "redux";
import types from "./types";

const initialState = {
    cmss: {},
    promotions: {},
    discounts: {},
};

const all = (state = initialState, { type, payload, meta }) => {
    switch (type) {
        case types.getCMS.request:
            return {
                ...state,
                cmss: { ...state.cmss, [meta.id]: { isLoading: true } },
            };
        case types.getCMS.success:
            return {
                ...state,
                cmss: { ...state.cmss, [payload.id]: payload },
            };
        case types.getCMS.failure:
            return {
                ...state,
                cmss: { ...state.cmss, [meta.id]: undefined },
            };

        case types.getPromotion.request:
            return {
                ...state,
                promotions: { ...state.promotions, [meta.id]: { isLoading: true } },
            };
        case types.getPromotion.success:
            return {
                ...state,
                promotions: { ...state.promotions, [payload.id]: payload },
            };
        case types.getPromotion.failure:
            return {
                ...state,
                promotions: { ...state.promotions, [meta.id]: undefined },
            };

        case types.getDiscount.request:
            return {
                ...state,
                discounts: { ...state.discounts, [meta.id]: { isLoading: true } },
            };
        case types.getDiscount.success:
            return {
                ...state,
                discounts: { ...state.discounts, [payload.id]: payload },
            };
        case types.getDiscount.failure:
            return {
                ...state,
                discounts: { ...state.discounts, [meta.id]: undefined },
            };
        default:
            return state;
    }
};

export const containers = combineReducers({
    all,
});
