import GridContainer from "components/Grid/GridContainer";
import TextWithLabel from "components/Text/TextWithLabel";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { common } from "translations";
import { deliveryWindowsSummarySelector, totalStatisticSelector } from "../selectors";

const PartialStatisticComponent = ({ header, partial }) => {
    return (
        <Grid
            xs={2}
            item
        >
            <GridContainer
                item
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                title={header}
            >
                <Grid item>
                    <TextWithLabel
                        label={common.orders}
                        value={partial.orders}
                    />
                </Grid>
                <Grid item>
                    <TextWithLabel
                        label={common.lines}
                        value={partial.lines}
                    />
                </Grid>
                <Grid item>
                    <TextWithLabel
                        label={common.quantityStatistic}
                        value={partial.quantity}
                    />
                </Grid>
                <Grid item>
                    <TextWithLabel
                        label={common.carriers}
                        value={partial.carriers}
                    />
                </Grid>
            </GridContainer>
        </Grid>
    );
};

const Statistics = () => {
    const statistic = useSelector(totalStatisticSelector);
    const { reservationCount, orderCount } = useSelector(deliveryWindowsSummarySelector);

    return (
        <GridContainer
            style={{ padding: 20 }}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >
            <PartialStatisticComponent
                partial={statistic.totals}
                header={common.totals}
            />
            <PartialStatisticComponent
                header={common.normal}
                partial={statistic.storageTotals.Normal}
            />
            <PartialStatisticComponent
                header={common.cooler}
                partial={statistic.storageTotals.Cooler}
            />
            <PartialStatisticComponent
                header={common.freezer}
                partial={statistic.storageTotals.Freezer}
            />
            <Grid
                item
                xs={2}
            >
                <GridContainer
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    title={common.deliveryWindowsSummary}
                >
                    <Grid item>
                        <TextWithLabel
                            label={common.reservationCount}
                            value={reservationCount}
                        />
                    </Grid>
                    <Grid item>
                        <TextWithLabel
                            label={common.orderCount}
                            value={orderCount}
                        />
                    </Grid>
                </GridContainer>
            </Grid>
        </GridContainer>
    );
};

export default Statistics;
