import OrderAlert from "views/orderAlerts/orderAlert/OrderAlert";
import OrderAlertList from "views/orderAlerts/orderAlertsList/OrderAlertList";
import OrderSearch from "views-ts/orderSearch/OrderSearch";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import {
    AccountantDepartment,
    B2BAndSupportDepartment,
    ECommerceDepartment,
    LogisticianDepartment,
    MarketingDepartment,
    TradeMarketingDepartment,
} from "routing-ts/roles";

export const Orders: PrivateRoute[] = [
    new PrivateRoute(
        Paths.OrderSearch,
        [
            "Management",
            "Analyst",
            "AXUser",
            "Auditor",
            ...B2BAndSupportDepartment,
            ...ECommerceDepartment.GetRoles(),
            ...AccountantDepartment.GetRoles(),
            ...LogisticianDepartment.GetRoles(),
            ...MarketingDepartment.GetRoles(),
            ...TradeMarketingDepartment.GetRoles(),
        ],
        OrderSearch,
        "orderSearch",
        "monetization_on"
    ),
    new PrivateRoute(
        Paths.OrderAlertsList,
        [
            "Management",
            "Analyst",
            "AXUser",
            ...B2BAndSupportDepartment,
            ...ECommerceDepartment.GetRoles(),
            ...AccountantDepartment.GetRoles(),
            ...LogisticianDepartment.GetRoles(),
            ...MarketingDepartment.GetRoles(),
            ...TradeMarketingDepartment.GetRoles(),
        ],
        OrderAlertList,
        "orderAlerts",
        "warning"
    ),
    new PrivateRoute(
        Paths.OrderAlertForm,
        [
            "Management",
            "Analyst",
            "AXUser",
            ...B2BAndSupportDepartment,
            ...ECommerceDepartment.GetRoles(),
            ...AccountantDepartment.GetRoles(),
            ...LogisticianDepartment.GetRoles(),
            ...MarketingDepartment.GetRoles(),
            ...TradeMarketingDepartment.GetRoles(),
        ],
        OrderAlert
    ),
];
