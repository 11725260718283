import { AccountingPeriodAlert } from "components/Alerts";
import { Button } from "components/Button";
import { SubsidiaryGainContainers } from "components/Controls";
import { ChangeReason } from "components/Controls/ChangeReason";
import { SubsidiaryGainServiceConfirmationsField } from "components/Controls/SubsidiaryGainServiceConfirmations";
import { FileArray } from "components/Files";
import { Form, FormField, FormFieldArray } from "components/Form";
import { decimalNormalize } from "components/FormHelpers/ControlFormaters";
import { Layout } from "components/Grid";
import roles from "consts/roles";
import { settlementPeriods } from "consts/settlementPeriods";
import { useUser } from "context/UserContext/UserContext";
import { SubmissionError, reduxForm } from "redux-form";
import sgTypes from "resource/subsidiaryGainTypes.json";
import { toolkitNamesSelector, vendorRecipientsSelector } from "store/autocomplete";
import { displayMessage } from "store/messaging/actions";
import { sgFormSelector } from "store/vendors/subsidiaryGains";
import {
    addServiceConfirmation,
    createOrUpdate,
    downloadServiceConfirmation,
    getProposalDecisionStatus,
    getServiceConfirmation,
    getSubsidiaryGainArrangement,
    getSubsidiaryGainFile,
    initForm,
} from "store/vendors/subsidiaryGains/action";
import { toSelectList } from "utils/extensions";
import { useChange, useFormValueSelector, useParams, useUserAccountingPeriods } from "utils/hooks";
import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Grid, Tooltip } from "@mui/material";
import moment from "moment";
import _ from "lodash";
import { vendor } from "translations";
import { usePush } from "utils-ts/hooks";
import { useTranslation } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import RequestInvoiceRetry from "./RequestInvoiceRetry";
import RequestVendorDecision from "./RequestVendorDecision";
import SubsidiaryGainDates from "./SubsidiaryGainDates";
import SubsidiaryGainDecision from "./SubsidiaryGainDecision";
import SubsidiaryGainServices from "./SubsidiaryGainServices";
import SubsidiaryGainStatus from "./SubsidiaryGainStatus";
import { validators } from "./validation";

const formName = "subsidiary-gain-form";

const editRoles = [roles.SalesShopping, roles.SalesManager, roles.Management, roles.Catman, roles.TradeMarketingManager];

const isDocumentReadonly = (document) =>
    document.decisionStatus === "ServiceRemoved" ||
    document.decisionStatus === "WaitingForDecision" ||
    document.decisionStatus === "WaitingForVendorDecision" ||
    document.decisionStatus === "WaitingForVendorConfirmation";

const isReadonlyDueToInvoice = (document) =>
    document.status === "InvoiceRequested" || document.status === "Invoiced" || document.invoiceDocumentNumber;

const getStartPeriodDate = (settlementPeriod, value, year) => {
    switch (settlementPeriod) {
        case "Monthly":
            return new Date(year, value, 1);
        case "Quarterly":
            return new Date(year, (value - 1) * 3, 1);
        default:
            return undefined;
    }
};

const SubsidiaryGainForm = ({ handleSubmit, pristine, handleDecision, document, vendorUsers, searchParams }) => {
    const {
        isDecisionRequired,
        isAfterDateFrom,
        isAfterDateTo,
        status,
        decisionStatus,
        canRequestVendorDecision,
        canRequestVendorConfimation,
        isConfirmedByVendor,
        hasInvoiceErrors,
        invoiceError,
    } = document;
    const { replace } = usePush();
    const { profile, isInAnyRoleOrAdmin } = useUser();
    const { vendorId, subsidiaryGainId } = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation("vendor");
    const change = useChange(formName);
    const vendorRecipients = useSelector(vendorRecipientsSelector);
    const toolkitNames = useSelector(toolkitNamesSelector);
    const {
        settlementPeriod,
        dateFrom,
        dateTo,
        invoicedAt,
        containers,
        vendorUserId,
        confirmations,
        month,
        quater,
        year,
        reservationId = null,
        reservationKitId = null,
        reservationAdded = false,
        containerReservationIdToDelete = "",
        rejectReasonsToSend = [],
        subsidiaryGainServices,
        arrangements = [],
        files = [],
        value = 0,
    } = useFormValueSelector(
        formName,
        [
            "settlementPeriod",
            "dateFrom",
            "dateTo",
            "invoicedAt",
            "containers",
            "vendorUserId",
            "confirmations",
            "month",
            "quater",
            "year",
            "reservationId",
            "reservationKitId",
            "reservationAdded",
            "containerReservationIdToDelete",
            "rejectReasonsToSend",
            "subsidiaryGainServices",
            "arrangements",
            "files",
            "value",
        ],
        {}
    );
    const noExternalUser = vendorUserId === undefined;
    const sgCreatedOutOfReservation = !!reservationKitId;

    useEffect(() => {
        if (containerReservationIdToDelete != "") {
            const newContainersValues = (containers || [])
                .filter((c) => !c.reservationIds.some((r) => r == containerReservationIdToDelete) || c.reservationIds.length > 1)
                .map((c) => {
                    return {
                        ...c,
                        reservationIds: c.reservationIds.filter((r) => r != containerReservationIdToDelete),
                    };
                });
            change(`containers`, newContainersValues);
        }
    }, [containerReservationIdToDelete]);

    const settlementPeriodValue = settlementPeriod?.current
        ? settlementPeriod?.current
        : settlementPeriod?.proposal
        ? settlementPeriod?.proposal
        : settlementPeriod;

    const startPeriodDate =
        month == null && quater == null
            ? new Date()
            : getStartPeriodDate(settlementPeriodValue, settlementPeriodValue === "Monthly" ? month : quater, year);
    const startPeriodDatePlusPeriod = new Date(
        new Date(startPeriodDate?.getTime()).setMonth(startPeriodDate?.getMonth() + (settlementPeriodValue === "Monthly" ? 1 : 3))
    );

    const { isAnyAccountingPeriodOpen, accountingPeriods } = useUserAccountingPeriods(vendorId);

    const isWaitingForVendorDecision = decisionStatus === "WaitingForVendorDecision" || decisionStatus === "WaitingForVendorConfirmation";

    const readOnlyDueToInvoice = isReadonlyDueToInvoice(document);

    const baseReadonly = isDocumentReadonly(document) || (subsidiaryGainId && !isAnyAccountingPeriodOpen) || isConfirmedByVendor;

    const readOnly = baseReadonly || !isInAnyRoleOrAdmin(editRoles);
    const readOnlyEditRolesAndAssistants = baseReadonly || !isInAnyRoleOrAdmin([...editRoles, roles.SalesAssistant]);

    const isNew = !document.id || document.isNew;
    const canTMAddNewSG =
        isInAnyRoleOrAdmin([roles.TradeMarketingManager]) && !!searchParams.get("reservationId") && !!searchParams.get("reservationKitId");

    const wasRejected = decisionStatus === "ServiceRejected" || decisionStatus === "ChangeRejected";

    const handleDownloadFile = async ({ fileId, fileName }) => {
        dispatch(getSubsidiaryGainFile(vendorId, subsidiaryGainId, fileId, fileName));
    };

    const handleGetConfirmation = async (blobName) => {
        dispatch(getServiceConfirmation(vendorId || document.vendorId, subsidiaryGainId || document.id, blobName));
    };

    const handleDownloadConfirmation = async (blobName) => {
        dispatch(downloadServiceConfirmation(vendorId || document.vendorId, subsidiaryGainId || document.id, blobName));
    };

    const handleRemoveConfirmation = ({ containerId, blobName }) => {
        var containerIndex = containers.findIndex((c) => c.containerId === containerId);

        var fieldName = containerIndex === -1 ? "confirmations" : `containers[${containerIndex}].confirmations`;

        var containerConfirmations = containerIndex === -1 ? confirmations : containers[containerIndex].confirmations;

        change(
            fieldName,
            containerConfirmations.filter((confirmation) => confirmation.blobName !== blobName)
        );
    };

    const handleDownloadArrangement = ({ fileId, fileName }) => {
        dispatch(getSubsidiaryGainArrangement(vendorId, subsidiaryGainId, fileId, fileName));
    };

    const renderGoToReservationButton = () => {
        const canAddReservationToSG = !["WaitingForDecision", "WaitingForVendorDecision", "ServiceRemoved"].includes(decisionStatus);

        const button = (
            <Button
                type="button"
                onClick={async () => {
                    var params = new URLSearchParams({
                        subsidiaryGainId: subsidiaryGainId,
                        reservationId: subsidiaryGainServices[0]?.newCostReasons[0]?.reservationId || undefined,
                        toolkitId: subsidiaryGainServices[0]?.newCostReasons[0]?.toolkitId || undefined,
                    }).toString();
                    if (canAddReservationToSG) {
                        window.open(`/bridge/reservation-kits/form/${reservationKitId}?${params}`);
                    } else {
                        window.open(`/bridge/reservation-kits/form/${reservationKitId}`);
                    }
                }}
            >
                {t(vendor.goToReservationKit)}
            </Button>
        );

        return canAddReservationToSG ? (
            button
        ) : (
            <Tooltip
                title="Do tego SG nie można dodać obecnie rezerwacji, ponieważ nie pozwala na to jego status."
                placement="right-start"
                arrow
            >
                <span>{button}</span>
            </Tooltip>
        );
    };

    return (
        <>
            <AccountingPeriodAlert
                accountingPeriods={accountingPeriods}
                date={dateTo}
            />
            <Form
                onSubmit={handleSubmit(async (values) => {
                    if (!values.changeReason) {
                        const result = await dispatch(
                            getProposalDecisionStatus(vendorId, {
                                ...values,
                                subsidiaryGainServices: values.subsidiaryGainServices.map((s) => {
                                    return { ...s, costReasons: [] };
                                }),
                                containers: [],
                                confirmations: [],
                            })
                        );

                        if (result?.payload !== "ServiceAccepted") {
                            await dispatch(
                                displayMessage({
                                    variant: "warning",
                                    message: "Wprowadzone dane wymagają uzupełnienia uzasadnienia",
                                })
                            );

                            throw new SubmissionError({
                                _error: "status-need-change-reason",
                                newDecisionStatus: result.payload,
                            });
                        }
                    }

                    const { containers = [], confirmations = [], arrangements = [], ...subsidiaryGain } = values;
                    if (containers?.some((x) => !x.valid)) {
                        return;
                    }

                    const responsibleUser = {
                        userId: values.responsibleUser ? values.responsibleUser.userId : profile.sub,
                        email: values.responsibleUser ? values.responsibleUser.email : profile.email,
                    };

                    const serviceConfirmations = [
                        ...confirmations.map((x) => ({
                            ...x,
                            screenshot: {
                                containerName: x.containerName,
                                blobName: x.blobName,
                            },
                            user: responsibleUser,
                        })),
                        ...containers.flatMap((c) =>
                            (c.confirmations ?? []).map((x) => ({
                                ...x,
                                screenshot: {
                                    containerName: x.containerName,
                                    blobName: x.blobName,
                                },
                                user: responsibleUser,
                                containerId: c.containerId,
                                sectionName: c.sectionName,
                            }))
                        ),
                    ];

                    subsidiaryGain.subsidiaryGainServices = subsidiaryGain.subsidiaryGainServices.map((s) => {
                        return {
                            ...s,
                            newCostReasons: s.newCostReasons.map((c) => ({
                                ...c,
                                name: toolkitNames.find((t) => t.toolkitId == c.name)?.name || c.name,
                            })),
                            costReasons: null,
                        };
                    });
                    subsidiaryGain.serviceConfirmations = serviceConfirmations.filter((x) => !x.isNew);

                    const { payload } = await dispatch(
                        createOrUpdate(vendorId, subsidiaryGainId, {
                            responsibleUser: !subsidiaryGainId ? responsibleUser : undefined,
                            subsidiaryGain: subsidiaryGain,
                            containers: containers.map((c) => ({
                                ...c,
                                confirmations: [],
                            })),
                            arrangements,
                            reservationId,
                            reservationKitId,
                            reservationRejectReasonsToSend: rejectReasonsToSend,
                        })
                    );

                    await Promise.all(
                        serviceConfirmations
                            .map((x) => ({ ...x, user: responsibleUser }))
                            .filter((x) => x.isNew)
                            .map(async (c) => {
                                await dispatch(addServiceConfirmation(vendorId, subsidiaryGainId, c));
                            })
                    );

                    if (!subsidiaryGainId) {
                        replace(Paths.Vendor.SubsidiaryGainForm, {
                            vendorId,
                            subsidiaryGainId: payload.id,
                        });
                    } else {
                        replace(Paths.Vendor.SubsidiaryGainForm, {
                            vendorId,
                            subsidiaryGainId: subsidiaryGainId,
                        });
                        await dispatch(initForm(vendorId, subsidiaryGainId));
                    }
                })}
                isReadonly={
                    (!isInAnyRoleOrAdmin([roles.SalesAssistant, ...editRoles]) && !canTMAddNewSG) ||
                    (pristine && !reservationAdded) ||
                    isDecisionRequired
                }
                pristine={pristine && !reservationAdded}
            >
                <SubsidiaryGainStatus
                    subsidiaryGainId={subsidiaryGainId}
                    decisionStatus={decisionStatus}
                    status={status}
                    invoiceError={invoiceError}
                    removalProposal={document.removalProposal}
                    decision={document.decision}
                    vendorDecision={document.vendorDecision}
                    readOnly={sgCreatedOutOfReservation}
                />
                <Grid
                    container
                    direction="row"
                    alignItems="flex-end"
                >
                    {sgCreatedOutOfReservation && !!subsidiaryGainId && renderGoToReservationButton()}
                </Grid>
                <Grid
                    container
                    direction="row"
                    alignItems="flex-end"
                >
                    <FormField
                        useDiff={isDecisionRequired}
                        name="vendorUserId"
                        label={t(vendor.vendorUser)}
                        type="select"
                        readOnly={readOnly || isAfterDateFrom}
                        items={vendorUsers.map((x) => ({
                            value: x.userId,
                            name: x.email,
                        }))}
                    />
                    <FormField
                        useDiff={isDecisionRequired}
                        name="settlementPeriod"
                        label={t(vendor.settlementPeriod)}
                        type="select"
                        items={toSelectList(settlementPeriods, t)}
                        readOnly={readOnly || isAfterDateFrom || readOnlyDueToInvoice || sgCreatedOutOfReservation}
                        validate={validators.required}
                        onChange={(_, value) => {
                            if (settlementPeriod !== value && value === "Current") {
                                change("subsidiaryGainServices", null);
                                change("year", null);
                                change("month", null);
                            }

                            if (settlementPeriod !== value && value !== "Current") {
                                change("subsidiaryGainServices", null);
                                change("costReasons", null);
                                change("year", moment().year());
                            }

                            change("dateFrom", null);
                            change("dateTo", null);
                            change("quater", null);
                            change("month", null);
                        }}
                    />
                    <FormField
                        useDiff={isDecisionRequired}
                        name="invoiceRecipientId"
                        label={t(vendor.invoiceRecipient)}
                        type="select"
                        readOnly={readOnly || isAfterDateTo || readOnlyDueToInvoice || sgCreatedOutOfReservation}
                        items={vendorRecipients.map((x) => ({
                            value: x.recipientId,
                            name: x.name,
                        }))}
                        validate={validators.required}
                    />
                    <FormField
                        useDiff={isDecisionRequired}
                        name="subsidiaryGainType"
                        label={t(vendor.subsidiaryGainType)}
                        type="select"
                        items={sgTypes}
                        readOnly={readOnly || isAfterDateTo || sgCreatedOutOfReservation}
                        validate={validators.required}
                    />
                </Grid>
                <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                >
                    <SubsidiaryGainDates
                        settlementPeriod={settlementPeriod}
                        isDecisionRequired={isDecisionRequired}
                        readOnly={readOnly || isAfterDateFrom || readOnlyDueToInvoice || sgCreatedOutOfReservation}
                        isAnyAccountingPeriodOpen={isAnyAccountingPeriodOpen}
                        accountingPeriods={accountingPeriods}
                    />
                    <FormField
                        useDiff={isDecisionRequired}
                        name="value"
                        label={t(vendor.value)}
                        format={decimalNormalize}
                        type="text"
                        readOnly={(readOnly && !canTMAddNewSG) || (isAfterDateTo && settlementPeriod === "Current") || readOnlyDueToInvoice}
                        validate={validators.value}
                    />
                </Grid>
                <Grid
                    container
                    direction="row"
                    alignItems="flex-end"
                >
                    <FormField
                        useDiff={isDecisionRequired}
                        name="invoiceIssueReason"
                        label={t(vendor.invoiceIssueReason)}
                        type="text"
                        readOnly={true}
                    />
                    <FormField
                        useDiff={isDecisionRequired}
                        name="isPurchaseOrderRequired"
                        label={t(vendor.isPurchaseOrderRequired)}
                        readOnly={(readOnly && !canTMAddNewSG) || isAfterDateTo}
                        type="boolean"
                        onChange={(_, value) => {
                            if (value == false) {
                                change("purchaseOrderNumber", "");
                            }
                        }}
                    />
                    <FormField
                        useDiff={isDecisionRequired}
                        name="purchaseOrderNumber"
                        label={t(vendor.purchaseOrderNumber)}
                        type="text"
                        readOnly={
                            hasInvoiceErrors
                                ? false
                                : isWaitingForVendorDecision ||
                                  isConfirmedByVendor ||
                                  readOnlyDueToInvoice ||
                                  (!isInAnyRoleOrAdmin([roles.SalesAssistant, ...editRoles]) && !canTMAddNewSG)
                        }
                        validate={validators.purchaseOrderNumber}
                    />
                </Grid>
                <Grid
                    container
                    direction="row"
                    alignItems="stretch"
                >
                    <FormField
                        useDiff={isDecisionRequired}
                        stretch
                        name="comment"
                        label={t(vendor.comment)}
                        type="text"
                        readOnly={readOnly && !canTMAddNewSG}
                        validate={validators.required}
                    />
                </Grid>
                <Layout headerText={t(vendor.arrangements)}>
                    <FormFieldArray
                        accept="application/pdf"
                        name="arrangements"
                        component={FileArray}
                        readOnly={readOnlyEditRolesAndAssistants && !canTMAddNewSG}
                        handleDownload={handleDownloadArrangement}
                        validate={validators.atLeastOne}
                        canRemoveFromBlob={arrangements.filter((o) => o.isNew == false).length >= 2}
                    />
                </Layout>
                <Layout headerText={t(vendor.containers)}>
                    <FormFieldArray
                        name="containers"
                        containers={containers}
                        component={SubsidiaryGainContainers}
                        readOnly={readOnlyEditRolesAndAssistants || isAfterDateTo}
                        readOnlyConfirmations={isNew || readOnly}
                        validate={validators.uniqBy(["containerId", "containerType", "sectionName"])}
                        vendorId={document.vendorId}
                        sgId={subsidiaryGainId}
                        handleDownloadConfirmation={handleDownloadConfirmation}
                        handleGetConfirmation={handleGetConfirmation}
                        handleRemoveConfirmation={handleRemoveConfirmation}
                        sgCreatedOutOfReservation={sgCreatedOutOfReservation}
                    />
                </Layout>
                {!isNew && (
                    <Layout headerText={t(vendor.otherConfirmations)}>
                        <SubsidiaryGainServiceConfirmationsField
                            form={formName}
                            name="confirmations"
                            handleDownload={handleDownloadConfirmation}
                            handleGet={handleGetConfirmation}
                            readOnly={readOnlyEditRolesAndAssistants}
                        />
                    </Layout>
                )}
                {noExternalUser && (
                    <Layout headerText={t(vendor.pwuFiles)}>
                        <FormFieldArray
                            accept="application/pdf"
                            name="files"
                            component={FileArray}
                            useDiff={isDecisionRequired}
                            readOnly={
                                hasInvoiceErrors ? false : isWaitingForVendorDecision || !isInAnyRoleOrAdmin([roles.SalesAssistant, ...editRoles])
                            }
                            handleDownload={handleDownloadFile}
                            canRemoveFromBlob={Array.isArray(files) && files.filter((o) => o.isNew == false).length >= 2}
                        />
                    </Layout>
                )}

                <Layout headerText={t(vendor.subsidiaryGainServices)}>
                    <FormFieldArray
                        name="subsidiaryGainServices"
                        isAfterDateTo={isAfterDateTo}
                        isAfterDateFrom={isAfterDateFrom}
                        component={SubsidiaryGainServices}
                        useDiff={isDecisionRequired}
                        readOnly={readOnly || readOnlyDueToInvoice}
                        validate={validators.atLeastOne}
                        rootDateFrom={dateFrom || startPeriodDate}
                        rootDateTo={dateTo || startPeriodDatePlusPeriod}
                        invoicedAt={invoicedAt}
                        formName={formName}
                        sgCreatedOutOfReservation={sgCreatedOutOfReservation}
                        sgValue={value}
                    />
                </Layout>

                <ChangeReason
                    isDecisionRequired={isDecisionRequired}
                    forceRender={wasRejected}
                />
            </Form>
            <RequestVendorDecision
                canRequestVendorDecision={pristine && vendorUserId && (canRequestVendorDecision || canRequestVendorConfimation)}
                status={status}
            />
            <RequestInvoiceRetry canRequestInvoiceRetry={hasInvoiceErrors} />
            <SubsidiaryGainDecision
                isDecisionRequired={isDecisionRequired}
                handleDecision={handleDecision}
                removalProposal={document.removalProposal}
            />
        </>
    );
};

export default connect((state, props) => {
    const form = sgFormSelector(state);
    form.invoiceIssueReason = form.invoiceIssueReason || "Usługa marketingowa";

    return {
        document: form,
        initialValues: { ...form, vendorId: props.vendorId },
    };
})(
    reduxForm({
        form: formName,
        enableReinitialize: true,
    })(SubsidiaryGainForm)
);
