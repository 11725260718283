import Layout from "components/Grid/Layout";
import Tabs from "components/Tabs/Tabs";
import { formValueSelector } from "redux-form";
import { actions } from "store/calendar/actions";
import { useCommonTranslation } from "utils/hooks";
import { Fragment, useState } from "react";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { DaySelect, Statistics, Table } from "./components";
import { termsSelector } from "./selectors";

const DeliveryWindows = ({ calendar, getCalendar }) => {
    const { t, common } = useCommonTranslation();
    const { isLoading } = calendar;
    const data = useSelector(termsSelector);
    const [filters, setFilters] = useState(undefined);
    const { DPD, vanGroups } = data;
    const tabLabels = _(vanGroups)
        .map((_, key) => key)
        .map((key) => `${t(common.van)} - ${key}`)
        .value()
        .concat(t(common.dpd));

    var tabs = _(vanGroups)
        .map((item, index) => (
            <Table
                key={index}
                items={item}
                isLoading={isLoading}
            />
        ))
        .value();
    if (tabs.length > 0) {
        tabs.push(
            <Table
                key={vanGroups.length + 1}
                items={DPD}
                isLoading={isLoading}
            />
        );
    }

    return (
        <Layout
            main
            headerText={t(common.deliveryWindows)}
            customTitle={filters && `${t(common.deliveryWindows)} - ${filters.warehouse} ${moment(filters.day).format("DD.MM.YYYY")}`}
        >
            <DaySelect
                t={t}
                onSubmit={(values) => {
                    getCalendar({ ...values });
                    setFilters({ day: values.day, warehouse: values.warehouse });
                }}
                isLoading={isLoading}
            />
            {!_.isEmpty(vanGroups) ? (
                <Fragment>
                    <Statistics />
                    <Tabs
                        labelsAsComponents
                        tableLabels={tabLabels}
                    >
                        {tabs}
                    </Tabs>
                </Fragment>
            ) : (
                <Fragment />
            )}
        </Layout>
    );
};

const mapStateToProps = (state) => {
    const { calendar } = state;
    const day = formValueSelector("CalendarForm")(state, "day");
    const warehouse = formValueSelector("CalendarForm")(state, "warehouse");
    return { calendar, day, warehouse };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryWindows);
