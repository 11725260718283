import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ObsoleteWarehouses, Warehouse, Warehouses } from "types/sharedEnums";
import { usePrefixContext } from "components-ts/forms/contexts";
import FormSelect from "../FormSelect";

type FormWarehouseProps = {
    label: string;
    /**
     * default 'warehouse'
     */
    name?: string;
    readOnly?: boolean;
    multiple?: true;
    disableEmpty?: boolean;
};

const FormWarehouseSelect: React.FC<FormWarehouseProps> = ({ label, name = "warehouse", multiple, disableEmpty, readOnly }) => {
    const form = useFormContext();
    const prefix = usePrefixContext();
    const value = form.getValues(prefix !== undefined ? `${prefix}.${name}` : name);
    const [selectedObsoleteWarehouses, setSelectedObsoleteWarehouse] = useState<Warehouse[]>([]);
    useEffect(() => {
        if (Array.isArray(value) && value.some((v) => ObsoleteWarehouses.includes(v as Warehouse))) {
            setSelectedObsoleteWarehouse(value.filter((v) => ObsoleteWarehouses.includes(v as Warehouse)));
        }
    }, [value]);

    return (
        <FormSelect
            name={name}
            label={label}
            items={Warehouses.filter((w) => readOnly || !ObsoleteWarehouses.includes(w) || selectedObsoleteWarehouses.includes(w))}
            disableEmpty={disableEmpty}
            readOnly={readOnly}
            multiple={multiple}
        />
    );
};

export default FormWarehouseSelect;
