import { PagedResult } from "utils/domainTypes/PagedResult";
import moment from "moment";
import { range } from "lodash";
import { combineReducers } from "redux";
import types from "./types";

const createOrder = (i) => {
    return {
        orderId: `OID${i * 123}`,
        orderCreatedAt: moment()
            .subtract(i, "months")
            .add(i * 15, "days")
            .format(),
        deliveryAt: moment()
            .subtract(i, "months")
            .add(i * 19, "days")
            .format(),
        deliveryAddress: {
            postcode: "99-999",
            city: "Warszawa",
            street: "Ulica jakaś",
            buildingNumber: 1,
            apartmentNumber: 2,
        },
        invoiceNumber: i % 2 === 1 ? `FV/${i}/2020` : "",
        correctionNumbers: i % 4 === 1 ? [`KOR/${i}/2020`] : [],
        deliveredQuantity: 99,
        products: range(1, 100).map((x) => ({
            productId: String(x).padStart(6, "0"),
            ean: String(x).padEnd(9, "0"),
            name: `Product name ${x}`,
            quantity: x,
            deliveredQuantity: x,
            packSize: x % 10,
        })),
    };
};

const tempData = {
    isLoading: false,
    pageIndex: 1,
    pageSize: 25,
    pageCount: 25,
    totalCount: 25,
    items: range(1, 25).map((i) => createOrder(i)),
};

const initialState = {
    list: new PagedResult(),
    vendorOrder: { products: [], isLoading: false },
};

const list = (state = initialState.list, { type }) => {
    switch (type) {
        case types.getList.request:
            return { ...state, isLoading: true };
        case types.getList.success:
            return { ...state, isLoading: false, ...tempData };
        case types.getList.failure:
            return { ...state, isLoading: false };
        default:
            return state;
    }
};

const order = (state = initialState.vendorOrder, { type }) => {
    switch (type) {
        case types.getVendorOrder.request:
            return { ...state, isLoading: true };
        case types.getVendorOrder.success:
            return { ...state, isLoading: false, ...tempData.items[0] };
        case types.getVendorOrder.failure:
            return { ...state, isLoading: false };
        default:
            return state;
    }
};

export const vendorOrders = combineReducers({ list, order });
