import SimpleTable, { createColumn } from "components/Table/SimpleTable/SimpleTable";
import tableActionTypes from "components/Table/SimpleTable/TableActions/tableActionTypes";
import TextWithLabel from "components/Text/TextWithLabel";
import { correctionRequestFormSelector, getCorrectionRequestFile, getCorrectionRequestReportFile } from "store/vendors/correctionRequests";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useAppLocation, useParams } from "utils/hooks";
import { useDispatch, useSelector } from "react-redux";
import { GetApp as DownloadIcon, Assessment as ReportIcon } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { Button } from "@mui/material";
import { Layout } from "components";
import { common, vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";

const CorrectionRequestForm = () => {
    const form = useSelector(correctionRequestFormSelector);
    const { vendorId, correctionRequestId } = useParams();
    const { t } = useTranslation(["vendor", "common"]);
    const dispatch = useDispatch();
    const appLocation = useAppLocation();

    const handleDownloadFile = async () => {
        dispatch(getCorrectionRequestFile(vendorId, correctionRequestId));
    };

    const handleDownloadReportFile = async () => {
        dispatch(getCorrectionRequestReportFile(vendorId, correctionRequestId, "report"));
    };

    const handleSearchClick = async (id) => {
        if (form.settlementType === "Refundation") {
            window.open(`${appLocation}/vendors/${vendorId}/refundations/form/${id}`, "_blank");
        } else {
            window.open(`${appLocation}/vendors/${vendorId}/contracts/form/${form.contractId}?focusedRetroId=${id}`, "_blank");
        }
    };
    const transformToFixed = (value) => {
        return parseFloat(value).toFixed(2);
    };
    const settlementTypes = {
        Refundation: {
            translation: t(vendor.refundations),
            objectName: "refundations",
        },
        RetroConditional: {
            translation: t(vendor.retroConditionalDiscounts),
            objectName: "retroConditionalDiscounts",
        },
        RetroUnconditional: {
            translation: t(vendor.retroUnconditionalDiscounts),
            objectName: "retroUnconditionalDiscounts",
        },
    };

    const requestStatus = {
        Draft: t(vendor.Draft),
        WaitingForVerification: t(vendor.WaitingForVerification),
        Accepted: t(vendor.Accepted),
        Rejected: t(vendor.Rejected),
        Mailed: t(vendor.Mailed),
        CorrectionReceived: t(vendor.CorrectionReceived),
    };

    const columns = [
        createColumn("baseId", t(vendor.id), formatTypes.text),
        createColumn("correctionValue", t(vendor.CorrectionValue), formatTypes.currency, {
            transform: transformToFixed,
            useThousandsSeparator: true,
        }),
        createColumn("turnoverValue", t(vendor.turnover), formatTypes.currency, {
            transform: transformToFixed,
            useThousandsSeparator: true,
        }),
        createColumn("calculationBase.description", t(vendor.description), formatTypes.text),
        createColumn(
            "action",
            "",
            formatTypes.action,
            {
                actionType: () => tableActionTypes.preview,
            },
            (item) => {
                const { baseId } = item;
                handleSearchClick(baseId);
            }
        ),
    ];

    if (form.settlementType === "Refundation") {
        columns.shift();
        columns.unshift(createColumn("baseNumber", t(vendor.id), formatTypes.text));
    }

    const settlementType = settlementTypes[form.settlementType] || {};
    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                spacing={3}
                alignItems="center"
                style={{ marginBottom: 5, marginTop: 5 }}
            >
                <TextWithLabel
                    value={requestStatus[form.requestStatus]}
                    translatedLabel={t(vendor.changeStatus)}
                    format={formatTypes.text}
                />
                <TextWithLabel
                    value={form.requestNumber}
                    translatedLabel={t(vendor.correctionRequestNumber)}
                    format={formatTypes.text}
                    options={{
                        infoIfNoData: true,
                    }}
                />
                <TextWithLabel
                    value={transformToFixed(form.correctionValue || 0)}
                    translatedLabel={t(vendor.CorrectionValue)}
                    format={formatTypes.currency}
                />
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => handleDownloadFile()}
                    startIcon={<DownloadIcon />}
                >
                    {t(`common:${common.download}`)}
                </Button>
                {form.settlementType === "Refundation" && !!form.reportFile && (
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => handleDownloadReportFile()}
                        startIcon={<ReportIcon />}
                        style={{ marginLeft: 5 }}
                    >
                        {t(`common:${common.downloadReportFile}`)}
                    </Button>
                )}
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                style={{ marginBottom: 5, marginTop: 5 }}
            >
                <TextWithLabel
                    value={form.dateFrom}
                    label={t(vendor.dateFrom)}
                    format={formatTypes.time}
                    options={{ timeFormat: timePrecisonFormats.days }}
                />
                <TextWithLabel
                    value={form.dateTo}
                    label={t(vendor.dateTo)}
                    format={formatTypes.time}
                    options={{ timeFormat: timePrecisonFormats.days }}
                />
            </Grid>
            <Layout headerText={settlementType.translation}>
                <SimpleTable
                    columns={columns}
                    data={form[settlementType.objectName]}
                    onRowDoubleClick={(_, item) => {
                        const { baseId } = item;
                        handleSearchClick(baseId);
                    }}
                    stripped
                    hover
                />
            </Layout>
        </>
    );
};

export default CorrectionRequestForm;
