import { Button } from "components/Button";
import Layout from "components/Grid/Layout";
import SearchBar from "components/SearchBar/SearchBar";
import { actions } from "store/vouchers/list/actions";
import { useCommonTranslation, useMappedDispatch, useParams } from "utils/hooks";
import { Fragment, useState } from "react";
import { usePush } from "utils-ts/hooks";

const VoucherSearch = () => {
    const { searchForVoucher } = useMappedDispatch(actions);
    const { push } = usePush();
    const { type } = useParams();
    const { t, common } = useCommonTranslation();
    const [search, setSearch] = useState("");

    document.title = t(common.vouchers);

    return (
        <Fragment>
            <SearchBar
                value={search}
                placeholder={t(common.searchByVoucherId)}
                onSearchChanged={({ target }) => setSearch(target.value)}
                onSearchCommit={async () => {
                    const { payload } = await searchForVoucher(search);
                    if (payload.id) {
                        push(`/vouchers/${type}/form/${payload.id}`);
                    }
                }}
            />
            <Button
                actionType="primary"
                onClick={() => push(`/vouchers/${type}/form`)}
            >
                {t(common.addNew)}
            </Button>
        </Fragment>
    );
};

export default VoucherSearch;
