import Cell from "components/Table/SimpleTable/Cell";
import Row from "components/Table/SimpleTable/Row";
import { formatValue } from "utils/formating";
import { common } from "translations";
import { useTranslation } from "utils-ts/hooks";

const CustomerBalanceAuditRow = ({ customerId, columns, item }) => {
    const { t } = useTranslation(["common", "customerBalanceCommandNames"]);
    return (
        <Row hover>
            {columns.map(({ key, type, options }, index) => {
                if (key === "createdBy") {
                    if (!item[key] || item[key] === "999999")
                        return (
                            <Cell
                                key={index}
                                value={t(common.shop)}
                            />
                        );
                    if (item[key] === customerId)
                        return (
                            <Cell
                                key={index}
                                value={t(common.customer)}
                            />
                        );
                    return (
                        <Cell
                            key={index}
                            value={item.createdBy}
                        />
                    );
                }

                if (key === "commandNameDescription") {
                    return (
                        <Cell
                            key={index}
                            value={t(`customerBalanceCommandNames:${item.commandName}_description`, { ...item })}
                        />
                    );
                }

                return (
                    <Cell
                        key={index}
                        value={formatValue(item[key], type, options)}
                    />
                );
            })}
        </Row>
    );
};

export default CustomerBalanceAuditRow;
