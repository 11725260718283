import Adable from "components/Adable/Adable";
import ArrayValidationError from "components/Controls/ArrayValidationError";
import { ProductAnalyticGroupsAutocomplete } from "components/Controls/Autocompletes";
import ProductsBrandsAutocomplete from "components/Controls/Autocompletes/ProductsBrandsAutocomplete";
import ProductListField from "components/Controls/ProductListField";
import ValueWithType from "components/Controls/ValueWithType";
import Erasable from "components/Erasable/Erasable";
import { FormField } from "components/Form";
import { decimalNormalize } from "components/FormHelpers/ControlFormaters";
import { FormSection } from "redux-form";
import { withFormName } from "utils/hoc";
import { Grid } from "@mui/material";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { validators } from "../validators";
import ProductErrors from "./ProductsErrors";

const RefundationValue = ({ fields, meta, readOnly, useDiff, isQuantityLimit = false }) => {
    const { t } = useTranslation("vendor");

    return (
        <>
            <ArrayValidationError {...meta} />
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
            >
                <Adable
                    handleAdd={() => {
                        fields.push({});
                    }}
                    renderCondition={!readOnly && !useDiff}
                    noMarginInDiver
                >
                    {fields.map((field, index) => {
                        return (
                            <Erasable
                                handleRemove={() => {
                                    fields.remove(index);
                                }}
                                renderCondition={!readOnly && !useDiff}
                                key={index}
                            >
                                <FormSection name={field}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >
                                        <ValueWithType
                                            useDiff={useDiff}
                                            readOnly={readOnly}
                                            labels={{
                                                typeOfValue: t(vendor.typeOfDiscount),
                                                value: t(vendor.value),
                                            }}
                                            validate={{
                                                typeOfValue: validators.required,
                                                value: validators.discountValueWithType,
                                            }}
                                        />

                                        <FormField
                                            useDiff={useDiff}
                                            name="quantity"
                                            label={isQuantityLimit ? t(vendor.maxQuantity) : t(vendor.quantity)}
                                            type="text"
                                            format={decimalNormalize}
                                            readOnly={readOnly}
                                        />
                                    </Grid>

                                    <ProductAnalyticGroupsAutocomplete
                                        useDiff={useDiff}
                                        validate={readOnly ? undefined : validators.atLeastOneProductField}
                                        readOnly={readOnly}
                                    />
                                    <ProductsBrandsAutocomplete
                                        useDiff={useDiff}
                                        validate={readOnly ? undefined : validators.atLeastOneProductField}
                                        readOnly={readOnly}
                                    />
                                    <ProductListField
                                        useDiff={useDiff}
                                        validate={readOnly ? undefined : validators.refundationValueProducts}
                                        readOnly={readOnly}
                                    />
                                    <ProductErrors />
                                </FormSection>
                            </Erasable>
                        );
                    })}
                </Adable>
            </Grid>
        </>
    );
};

export default withFormName(RefundationValue);
