import { DeleteRequest, GetRequest, PostRequest, PutRequest, get, post, put, remove, withMeta } from "utils/extensions";
import { combineIntoQueryParams } from "utils/objectExtensions";
import downloadFile, { downloadPdfFile, downloadXlsxFile } from "utils/rsaa/downloadFileAction";
import fetchFile from "utils/rsaa/fetchFileAction";
import moment from "moment";
import types from "./types";

const fileEndpoint = (vendorId, fileId) => `api/vendors/${vendorId}/subsidiary-gains/file${fileId ? `/${fileId}` : ""}`;

const pwuEndpoint = (vendorId, sgId) => `api/vendors/${vendorId}/subsidiary-gains/${sgId}/pwu`;

const endpoint = (vendorId, subsidiaryGainId) => `api/vendors/${vendorId}/subsidiary-gains${subsidiaryGainId ? `/${subsidiaryGainId}` : ""}`;

const invoiceEndpoint = (vendorId, subsidiaryGainId) => `api/vendors/${vendorId}/subsidiary-gains-invoices/${subsidiaryGainId}`;

const retryScreenshotingEndpoint = (subsidiaryGainId, containerId, vendorId, sectionName) =>
    `api/vendors/subsidiary-gains/${subsidiaryGainId}/screenshots/${containerId}/retry?vendorId=${vendorId}${
        sectionName ? `&sectionName=${sectionName}` : ""
    }`;

export const getSgs = (vendorId, params) => async (dispatch) => {
    return dispatch(
        get(
            new GetRequest(endpoint(vendorId), types.getSubsidiaryGains.all, {
                queryParams: params,
            })
        )
    );
};

export const getSG = (vendorId, subsidiaryGainId) => async (dispatch) => {
    return dispatch(get(new GetRequest(endpoint(vendorId, subsidiaryGainId), types.getSubsidiaryGain.all)));
};

export const initForm = (vendorId, subsidiaryGainId) => async (dispatch) => {
    await dispatch({ type: types.formInit });

    if (subsidiaryGainId) {
        return dispatch(get(new GetRequest(endpoint(vendorId, subsidiaryGainId), types.getSubsidiaryGain.all)));
    } else {
        return Promise.resolve();
    }
};

function objectToFormData(obj, rootName, ignoreList) {
    var formData = new FormData();

    function appendFormData(data, root) {
        if (!ignore(root)) {
            root = root || "";
            if (data instanceof File) {
                formData.append(root, data);
            } else if (Array.isArray(data)) {
                for (var i = 0; i < data.length; i++) {
                    appendFormData(data[i], root + "[" + i + "]");
                }
            } else if (typeof data === "object" && data) {
                if (moment.isMoment(data)) {
                    appendFormData(new Date(data).toISOString(), root);
                    return;
                }
                for (var key in data) {
                    if (data.hasOwnProperty(key)) {
                        if (root === "") {
                            appendFormData(data[key], key);
                        } else {
                            appendFormData(data[key], root + "." + key);
                        }
                    }
                }
            } else {
                if (data !== null && typeof data !== "undefined") {
                    formData.append(root, data);
                }
            }
        }
    }

    function ignore(root) {
        return (
            Array.isArray(ignoreList) &&
            ignoreList.some(function (x) {
                return x === root;
            })
        );
    }

    appendFormData(obj, rootName);

    return formData;
}

export const createOrUpdate = (vendorId, subsidiaryGainId, values) => async (dispatch) => {
    var formData = objectToFormData(values);

    if (!subsidiaryGainId) {
        return await dispatch(
            post(
                new PostRequest(endpoint(vendorId), types.createSubsidiaryGain.all, {
                    body: formData,
                })
            )
        );
    } else {
        return await dispatch(
            put(
                new PutRequest(endpoint(vendorId, subsidiaryGainId), types.updateSubsidiaryGain.all, {
                    body: formData,
                })
            )
        );
    }
};

export const removeSubsidiaryGain = (vendorId, subsidiaryGainId, changeReason) => async (dispatch) => {
    return dispatch(
        remove(
            new DeleteRequest(endpoint(vendorId, subsidiaryGainId), types.removeSubsidiaryGain.all, {
                body: {
                    changeReason,
                },
            })
        )
    );
};

export const getSGFile = (vendorId, subsidiaryGainId, fileId, fileName) => async (dispatch) => {
    await dispatch(
        downloadFile(
            fileEndpoint(vendorId, fileId) + "/download" + (subsidiaryGainId ? `?subsidiaryGainId=${subsidiaryGainId}` : ""),
            undefined,
            types.downloadFile.all,
            fileName
        )
    );

    return Promise.resolve();
};

export const getPwu = (vendorId, subsidiaryGainId) => async (dispatch) => {
    await dispatch(
        downloadFile(pwuEndpoint(vendorId, subsidiaryGainId), undefined, types.downloadPwu.all, `${vendorId}_PWU`, false, subsidiaryGainId)
    );

    return Promise.resolve();
};

export const updateResponsibleUserSG = (vendorId, subsidiaryGainId, responsibleUser) => async (dispatch) => {
    return await dispatch(
        put(
            new PutRequest(endpoint(vendorId, subsidiaryGainId) + "/responsible-user", types.makeDecision.all, {
                body: responsibleUser,
            })
        )
    );
};

export const findSubsidiaryGainForApproval = (vendorId) => async (dispatch) => {
    return dispatch(get(new GetRequest(`${endpoint(vendorId)}/for-approval`, types.findSubsidiaryGainForApproval.all)));
};

export const makeDecision = (vendorId, subsidiaryGainId, isPositive, comment) => async (dispatch) => {
    return dispatch(
        put(
            new PutRequest(endpoint(vendorId, subsidiaryGainId) + "/make-decision", types.makeDecision.all, {
                body: {
                    isPositive,
                    comment,
                },
            })
        )
    );
};

export const getSubsidiaryGainFile = (vendorId, subsidiaryGainId, fileId, fileName) => async (dispatch) => {
    await dispatch(downloadPdfFile(endpoint(vendorId, subsidiaryGainId) + `/file-download/${fileId}`, types.downloadFile.all, fileName));

    return Promise.resolve();
};

export const getSubsidiaryGainReportForVendor = (vendorId, subsidiaryGainIds) => async (dispatch) => {
    await dispatch(
        downloadXlsxFile(
            endpoint(vendorId) + `/generate-report-for-vendor?${combineIntoQueryParams({ subsidiaryGainIds })}`,
            types.generateReport.all,
            `${moment().format("YYYY_MM_DD")}_${vendorId}_SG_REPORT`
        )
    );

    return Promise.resolve();
};

export const getProposalDecisionStatus = (vendorId, form) => async (dispatch) => {
    return await dispatch(
        post(
            new PostRequest(endpoint(vendorId) + "/get-pre-commit-decision-status", types.getProposalDecisionStatus.all, {
                body: {
                    subsidiaryGainId: form.id,
                    subsidiaryGain: form,
                },
            })
        )
    );
};

export const requestVendorDecision = (vendorId, subsidiaryGainId) => async (dispatch) => {
    return await dispatch(
        post(new PostRequest(endpoint(vendorId, subsidiaryGainId) + "/request-vendor-decision", types.requestVendorDecision.all, { body: {} }))
    );
};

export const getSubsidiaryGainInvoices = (vendorId, subsidiaryGainId) => async (dispatch) => {
    if (subsidiaryGainId) {
        return dispatch(get(new GetRequest(invoiceEndpoint(vendorId, subsidiaryGainId), types.getSubsidiaryGainInvoices.all)));
    }
};

export const createCorrection = (vendorId, subsidiaryGainInvoiceId, values) => async (dispatch) => {
    return await dispatch(
        post(
            new PostRequest(
                `api/vendors/${vendorId}/subsidiary-gains-invoices/${subsidiaryGainInvoiceId}/create-correction`,
                types.createInvoiceCorrection.all,
                {
                    body: {
                        correctionLines: values.invoiceLines.map((l) => ({
                            lineNumber: l.lineNumber,
                            correctNetPrice: l.netPrice,
                            correctDescription: l.description,
                        })),
                    },
                }
            )
        )
    );
};

export const downloadInvoiceFile = (vendorId, subsidiaryGainInvoiceId, documentId, fileName) => async (dispatch) => {
    await dispatch(
        downloadFile(
            `api/vendors/${vendorId}/subsidiary-gains-invoices/${subsidiaryGainInvoiceId}/download-invoice${
                documentId ? `?documentId=${documentId}` : ""
            }`,
            null,
            types.downloadFile.all,
            fileName
        )
    );
};

export const requestInvoiceRetry = (vendorId, subsidiaryGainId) => async (dispatch) => {
    return await dispatch(post(new PostRequest(endpoint(vendorId, subsidiaryGainId) + "/retry", types.retryInvoice.all, { body: {} })));
};

export const retryInvoice = (vendorId, subsidiaryGainId, subsidiaryGainInvoiceId) => async (dispatch) => {
    await dispatch(
        post(
            new PostRequest(`api/vendors/${vendorId}/subsidiary-gains-invoices/${subsidiaryGainInvoiceId}/retry-invoice`, types.retryInvoice.all, {})
        )
    );

    await dispatch(getSubsidiaryGainInvoices(vendorId, subsidiaryGainId));
};

export const retryInvoiceCorrection = (vendorId, subsidiaryGainId, subsidiaryGainInvoiceId, correctionId) => async (dispatch) => {
    await dispatch(
        post(
            new PostRequest(
                `api/vendors/${vendorId}/subsidiary-gains-invoices/${subsidiaryGainInvoiceId}/retry-correction`,
                types.retryCorrectionInvoice.all,
                {
                    body: {
                        correctionId,
                    },
                }
            )
        )
    );

    await dispatch(getSubsidiaryGainInvoices(vendorId, subsidiaryGainId));
};

export const downloadServiceConfirmation = (vendorId, subsidiaryGainId, blobName) => async (dispatch) => {
    await dispatch(
        downloadFile(
            `api/vendors/${vendorId}/subsidiary-gains/${subsidiaryGainId}/confirmation?blobName=${blobName}`,
            null,
            types.downloadServiceConfirmation.all,
            blobName
        )
    );
};

export const getServiceConfirmation = (vendorId, subsidiaryGainId, blobName) => async (dispatch) => {
    await dispatch(
        fetchFile(
            `api/vendors/${vendorId}/subsidiary-gains/${subsidiaryGainId}/confirmation?blobName=${blobName}`,
            null,
            withMeta({ blobName })(types.getServiceConfirmation.all)
        )
    );
};

export const addServiceConfirmation =
    (vendorId, subsidiaryGainId, { containerId, sectionName, imageFile, user }) =>
    async (dispatch) => {
        const formData = new FormData();
        formData.append("imageFile", imageFile);
        formData.append("user.userId", user.userId);
        formData.append("user.email", user.email);
        containerId && formData.append("containerId", containerId);
        sectionName && formData.append("sectionName", sectionName);

        return dispatch(
            put(
                new PutRequest(
                    `api/vendors/${vendorId}/subsidiary-gains/${subsidiaryGainId}/confirmation`,
                    withMeta({ containerId })(types.addServiceConfirmation.all),
                    {
                        body: formData,
                    }
                )
            )
        );
    };

export const getSubsidiaryGainArrangement = (vendorId, subsidiaryGainId, fileId, fileName) => async (dispatch) => {
    await dispatch(
        downloadPdfFile(endpoint(vendorId, subsidiaryGainId) + `/arrangement/${fileId}/download`, types.downloadArrangement.all, fileName)
    );

    return Promise.resolve();
};

export const retryContainerScreenshotting = (vendorId, subsidiaryGainId, containerId, sectionName) => async (dispatch) => {
    return await dispatch(
        post(
            new PostRequest(
                retryScreenshotingEndpoint(subsidiaryGainId, containerId, vendorId, sectionName),
                types.retryContainerScreenshotting.all,
                {}
            )
        )
    );
};

export const getSgStatuses = (vendorId, subsidiaryGainIds) => async (dispatch) => {
    const params = { subsidiaryGainIds: subsidiaryGainIds };
    return await dispatch(
        get(
            new GetRequest(endpoint(vendorId) + "/statuses", types.getSgStatuses.all, {
                queryParams: params,
            })
        )
    );
};

export const getReservationForSG = (reservationId) => async (dispatch) => {
    return await dispatch(
        get(new GetRequest(`api/bridge/reservation-kits/reservation/${reservationId}`, types.fillSubsidiaryGainWithReservation.all, {}))
    );
};

export const getReservationKitForSG = (reservationKitId) => async (dispatch) => {
    return await dispatch(
        get(new GetRequest(`api/bridge/reservation-kits/${reservationKitId}/sg-info`, types.fillSubsidiaryGainWithReservationKit.all, {}))
    );
};

export const getMarketingBudgetForSG = (vendorId, marketingBudgetId) => async (dispatch) => {
    return dispatch(get(new GetRequest(`api/vendors/${vendorId}/marketing-budgets/${marketingBudgetId}`, types.getMarketingBudgetForSG.all)));
};

export const actions = {
    getPwu,
};
