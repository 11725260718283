import { RSAA } from "redux-api-middleware";
import { GetRequest, get } from "utils/extensions";

export const INIT_PRODUCT = "@@operations/INIT_PRODUCT";
export const INIT_PRODUCT_SUCCESS = "@@operations/INIT_PRODUCT_SUCCESS";
export const INIT_PRODUCT_FAILURE = "@@operations/INIT_PRODUCT_FAILURE";

export const VERIFY_PRODUCT_EANS = "@@operations/VERIFY_PRODUCT_EANS";
export const VERIFY_PRODUCT_EANS_SUCCESS = "@@operations/VERIFY_PRODUCT_EANS_SUCCESS";
export const VERIFY_PRODUCT_EANS_FAILURE = "@@operations/VERIFY_PRODUCT_EANS_FAILURE";

export const VERIFY_PRODUCT_ID = "@@operations/VERIFY_PRODUCT_ID";
export const VERIFY_PRODUCT_ID_SUCCESS = "@@operations/VERIFY_PRODUCT_ID_SUCCESS";
export const VERIFY_PRODUCT_ID_FAILURE = "@@operations/VERIFY_PRODUCT_ID_FAILURE";

export const verifyEans = (eans) => async (dispatch) => {
    return await dispatch(
        get(
            new GetRequest(
                `api/products/verify-eans`,
                [
                    {
                        type: VERIFY_PRODUCT_EANS,
                        meta: {
                            hideError: true,
                            ignoreInProgress: true,
                        },
                    },
                    {
                        type: VERIFY_PRODUCT_EANS_SUCCESS,
                        meta: {
                            hideError: true,
                            ignoreInProgress: true,
                        },
                    },
                    {
                        type: VERIFY_PRODUCT_EANS_FAILURE,
                        meta: {
                            hideError: true,
                            ignoreInProgress: true,
                        },
                    },
                ],
                { queryParams: { eans } }
            )
        )
    );
};

export const verifyProductId = (productId) => async (dispatch) => {
    return await dispatch(
        get(
            new GetRequest(
                `api/products/verify-product-id`,
                [
                    {
                        type: VERIFY_PRODUCT_ID,
                        meta: {
                            hideError: true,
                            ignoreInProgress: true,
                        },
                    },
                    {
                        type: VERIFY_PRODUCT_ID_SUCCESS,
                        meta: {
                            hideError: true,
                            ignoreInProgress: true,
                        },
                    },
                    {
                        type: VERIFY_PRODUCT_ID_FAILURE,
                        meta: {
                            hideError: true,
                            ignoreInProgress: true,
                        },
                    },
                ],
                { queryParams: { productId } }
            )
        )
    );
};

export const initProduct = (
    ean,
    vendorId,
    initedByTrade,
    brand,
    subbrand,
    defaultSupplierId,
    defaultSupplier,
    producerId,
    producer,
    plannedSale,
    minimalStockDays,
    maximumStockDays,
    productClassification,
    pricePoint,
    useDefaultSupplier,
    suppliersPerWarehouse,
    warehouses,
    warehouseStores,
    manufacturedWarehouses
) => {
    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: `api/products/init`,
                headers: { "Content-Type": "application/json" },
                method: "POST",
                body: JSON.stringify({
                    eans: ean.split(",").filter((x) => x),
                    vendorId,
                    initedByTrade,
                    brand,
                    subbrand,
                    defaultSupplierId,
                    defaultSupplier,
                    producer,
                    producerId,
                    plannedSale,
                    minimalStockDays,
                    maximumStockDays,
                    productClassification,
                    pricePoint,
                    useDefaultSupplier,
                    suppliersPerWarehouse,
                    warehouses,
                    warehouseStores,
                    manufacturedWarehouses,
                }),
                types: [
                    {
                        type: INIT_PRODUCT,
                        meta: {
                            hideErrorIf: (resp) => resp.status === 400,
                        },
                    },
                    {
                        type: INIT_PRODUCT_SUCCESS,
                        meta: {
                            hideErrorIf: (resp) => resp.status === 400,
                        },
                        payload: (action, state, res) => {
                            return res.json();
                        },
                    },
                    {
                        type: INIT_PRODUCT_FAILURE,
                        meta: {
                            hideErrorIf: (resp) => resp.status === 400,
                        },
                        payload: async (action, state, res) => {
                            return res.text();
                        },
                    },
                ],
            },
        });
    };
};
