import { RSAA } from "redux-api-middleware";
import { commerce } from "store/actionTypePrefixes";
import { combineIntoQueryParams } from "utils/objectExtensions";
import { actions as userInfosActions } from "../../../cache/userInfos/actions";

export const types = {
    GET_CUSTOMER_BALANCE_CORRECTIONS_LIST: `${commerce}/GET_CUSTOMER_BALANCE_CORRECTIONS_LIST`,
    GET_CUSTOMER_BALANCE_CORRECTIONS_LIST_SUCCESS: `${commerce}/GET_CUSTOMER_BALANCE_CORRECTIONS_LIST_SUCCESS`,
    GET_CUSTOMER_BALANCE_CORRECTIONS_LIST_FAILURE: `${commerce}/GET_CUSTOMER_BALANCE_CORRECTIONS_LIST_FAILURE`,
    REMOVE_CUSTOMER_BALANCE_CORRECTION: `${commerce}/REMOVE_CUSTOMER_BALANCE_CORRECTION`,
    REMOVE_CUSTOMER_BALANCE_CORRECTION_SUCCESS: `${commerce}/REMOVE_CUSTOMER_BALANCE_CORRECTION_SUCCESS`,
    REMOVE_CUSTOMER_BALANCE_CORRECTION_FAILURE: `${commerce}/REMOVE_CUSTOMER_BALANCE_CORRECTION_FAILURE`,
    POST_CUSTOMER_BALANCE_CORRECTION: `${commerce}/POST_CUSTOMER_BALANCE_CORRECTION`,
    POST_CUSTOMER_BALANCE_CORRECTION_SUCCESS: `${commerce}/POST_CUSTOMER_BALANCE_CORRECTION_SUCCESS`,
    POST_CUSTOMER_BALANCE_CORRECTION_FAILURE: `${commerce}/POST_CUSTOMER_BALANCE_CORRECTION_FAILURE`,
};

const getCustomerBalanceCorrectionList =
    ({ customerId, ...params }) =>
    async (dispatch) => {
        const { payload } = await dispatch({
            [RSAA]: {
                endpoint: `api/v1/users/${customerId}/complaints?${combineIntoQueryParams(params)}`,
                headers: { "Content-Type": "application/json" },
                method: "GET",
                types: [
                    types.GET_CUSTOMER_BALANCE_CORRECTIONS_LIST,
                    types.GET_CUSTOMER_BALANCE_CORRECTIONS_LIST_SUCCESS,
                    types.GET_CUSTOMER_BALANCE_CORRECTIONS_LIST_FAILURE,
                ],
            },
        });

        const ids = payload.items.map((c) => c.createdBy);
        await dispatch(userInfosActions.getUserInfos(ids));

        return Promise.resolve();
    };

const removeBalanceCorrection = (userId, complaintId) => async (dispatch) => {
    return dispatch({
        [RSAA]: {
            endpoint: `api/v1/users/${userId}/complaints/${complaintId}`,
            headers: { "Content-Type": "application/json" },
            method: "DELETE",
            types: [
                types.REMOVE_CUSTOMER_BALANCE_CORRECTION,
                types.REMOVE_CUSTOMER_BALANCE_CORRECTION_SUCCESS,
                types.REMOVE_CUSTOMER_BALANCE_CORRECTION_FAILURE,
            ],
            body: JSON.stringify({
                notification: {
                    isNotifying: false,
                },
            }),
        },
    });
};

const postCustomerBalanceCorrection = (customerId, body) => async (dispatch) => {
    const { id } = body;
    const isEdit = Boolean(id);

    return await dispatch({
        [RSAA]: {
            endpoint: isEdit ? `api/v1/users/${customerId}/complaints/${id}` : `api/v1/users/${customerId}/complaints`,
            headers: { "Content-Type": "application/json" },
            method: isEdit ? "PUT" : "POST",
            types: [
                types.POST_CUSTOMER_BALANCE_CORRECTION,
                types.POST_CUSTOMER_BALANCE_CORRECTION_SUCCESS,
                types.POST_CUSTOMER_BALANCE_CORRECTION_FAILURE,
            ],
            body: JSON.stringify({
                complaint: {
                    ...body.complaint,
                    balanceDiscrepancy: {
                        ...body.complaint.balanceDiscrepancy,
                        balanceAmount: {
                            FRS: Number(body.complaint.balanceDiscrepancy.balanceAmount.FRS),
                            F24: Number(body.complaint.balanceDiscrepancy.balanceAmount.F24),
                        },
                    },
                },
            }),
        },
    });
};

export const actions = {
    getCustomerBalanceCorrectionList,
    removeBalanceCorrection,
    postCustomerBalanceCorrection,
};
