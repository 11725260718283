import React from "react";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import { Role } from "../roles";

const AccountingPeriodsForm = React.lazy(() =>
    import("views/accountingPeriods").then((module) => ({
        default: module.AccountingPeriodsForm,
    }))
);
const AccountingPeriodsList = React.lazy(() =>
    import("views/accountingPeriods").then((module) => ({
        default: module.AccountingPeriodsList,
    }))
);

const roles: Role[] = ["Auditor"];

export const AccountingPeriods: PrivateRoute[] = [
    new PrivateRoute(Paths.AccountingPeriodList, roles, AccountingPeriodsList, "closedAccountingPeriods", "date_range"),
    new PrivateRoute(Paths.AccountingPeriodForm, roles, AccountingPeriodsForm),
];
