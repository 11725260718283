import { firstOrDefault } from "utils/extensions/arrayExtensions";
import React, { Fragment } from "react";
import { ListItemText } from "@mui/material";

const AnalyticGroupsItem = ({ v, analyticGroups }) => {
    const analyticGroup = firstOrDefault(analyticGroups, (x) => x.id === v, {
        name: v,
    });
    const { name = "" } = analyticGroup;
    return (
        <Fragment>
            <ListItemText primary={`${name}`} />
        </Fragment>
    );
};

export default AnalyticGroupsItem;
