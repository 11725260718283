export const text = "text";
export const float = "float";
export const currency = "currency";
export const currencyAmount = "currencyAmount";
export const translatable = "translatable";
export const time = "time";
export const boolean = "boolean";
export const correction = "correction";
export const grammage = "grammage";
export const fullName = "fullName";
export const shippingAddress = "shippingAddress";
export const deliveryWindow = "deliveryWindow";
export const action = "action";
export const vatin = "vatin";
export const link = "link";
export const percent = "percent";
export const voucherKind = "voucherKind";
export const func = "func";
export const rating = "rating";
export const points = "points";
export const customer = "customer";
export const quantity = "quantity";
export const numeric = "numeric";
export const infoIcon = "infoIcon";
export const voucherValue = "voucherValue";
export const arrayCount = "arrayCount";
export const userInfo = "userInfo";
export const creditLimit = "creditLimit";
export const resource = "resource";

const formatTypes = {
    text,
    float,
    currency,
    currencyAmount,
    translatable,
    time,
    boolean,
    correction,
    grammage,
    fullName,
    shippingAddress,
    deliveryWindow,
    action,
    vatin,
    link,
    percent,
    voucherKind,
    func,
    points,
    customer,
    quantity,
    numeric,
    arrayCount,
    voucherValue,
    infoIcon,
    userInfo,
    creditLimit,
    resource,
};

export default formatTypes;
