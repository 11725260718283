import { FormField } from "components/Form";
import { toSelectList } from "utils/extensions/arrayExtensions";
import { withFormName } from "utils/hoc";
import { useChange, useCommonTranslation, useFormValueSelector } from "utils/hooks";
import { useDispatch } from "react-redux";
import {
    CartModifierEffect,
    ChosenProductModifier,
    DeliveryModifierEffect,
    DiscountModifierEffect,
    GratisEffect,
    MembershipPointsEffect,
    NewProductEffect,
    PoolModifierEffect,
    ProgressiveCartModifierEffect,
    SampleProductEffect,
    SegmentEffect,
} from "./EffectForm/model";
import { promotionTypes } from "./promotionTypes";

const PromotionTypeSelect = ({ form, sectionPrefix }) => {
    const { t, common } = useCommonTranslation();
    const dispatch = useDispatch();
    const change = useChange(form);
    const contentData = useFormValueSelector(form, "contentData", {});
    const changeEffects = (effectValue) => {
        change(`${sectionPrefix ? `${sectionPrefix}.` : ""}effects`, effectValue);
        change(`${sectionPrefix ? `${sectionPrefix}.` : ""}pools`, []);
    };

    const promotions = toSelectList(Object.keys(promotionTypes), t);
    return (
        <FormField
            name={"promotionType"}
            label={t(common.promotionType)}
            type="select"
            items={promotions}
            onChange={(_, value) => {
                change(`definition.minimumChoices`, null);
                change(`definition.maximumChoices`, null);
                change("definition.possibleChoices", null);
                if (Array.isArray(contentData) && contentData.length > 0 && value !== "Membership") {
                    change(
                        `contentData`,
                        contentData.filter((c) => c.key != "tags")
                    );
                }

                switch (value) {
                    case "Membership":
                        changeEffects([new MembershipPointsEffect()]);
                        change(`${sectionPrefix ? `${sectionPrefix}.` : ""}requirements.user.haveSegmentsConstraint`, true);
                        change(`${sectionPrefix ? `${sectionPrefix}.` : ""}requirements.user.segments[0].included`, ["commerce:MembershipRewards"]);

                        if (Array.isArray(contentData) && contentData.length > 0 && !contentData.some((c) => c.key === "tags")) {
                            change(`contentData`, [...contentData, { key: "tags", name: "" }]);
                        }

                        break;
                    case "Delivery":
                        changeEffects([new DeliveryModifierEffect()]);
                        break;
                    case "GratisPicker":
                        const choiceKey = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                        change(`definition.minimumChoices`, 0);
                        change(`definition.maximumChoices`, 1);
                        change("definition.possibleChoices", [choiceKey]);
                        changeEffects([new NewProductEffect({ price: 0.01 }, choiceKey)]);
                        break;
                    case "Gratis":
                    case "PackGratis":
                    case "PoolGratis":
                        changeEffects([new GratisEffect()]);
                        break;
                    case "Discount":
                    case "Pack":
                    case "Pool":
                        changeEffects([new PoolModifierEffect()]);
                        break;
                    case "Pair":
                        changeEffects([new CartModifierEffect()]);
                        break;
                    case "Progressive":
                        changeEffects([
                            new ProgressiveCartModifierEffect({
                                progressiveModifiers: [],
                            }),
                        ]);
                        break;
                    case "Sampling":
                        changeEffects([new SampleProductEffect()]);
                        break;
                    case "DoublePrice":
                        changeEffects([new DiscountModifierEffect()]);
                        break;
                    case "Segment":
                        changeEffects([new SegmentEffect()]);
                        break;
                    case "ChosenProduct":
                        changeEffects([new ChosenProductModifier()]);
                        break;
                    default:
                        dispatch(change(form, `${sectionPrefix ? `${sectionPrefix}.` : ""}.effects`, []));
                        dispatch(change(form, `${sectionPrefix ? `${sectionPrefix}.` : ""}.pools`, []));
                }
            }}
        />
    );
};

export default withFormName(PromotionTypeSelect);
