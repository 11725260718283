export default class AmountBalance {
    FRS = 0;
    F24 = 0;

    constructor(values) {
        if (!values) throw Error("Initial object is null or undefined");
        let { FRS = 0, F24 = 0 } = values;
        if (Number.isNaN(FRS)) {
            FRS = 0;
        }
        if (Number.isNaN(F24)) {
            F24 = 0;
        }
        this.FRS = parseFloat(FRS);
        this.F24 = parseFloat(F24);
    }

    get _total() {
        return this.FRS + this.F24;
    }

    static empty = () => {
        return new AmountBalance({
            FRS: 0,
            F24: 0,
        });
    };

    subtract = (amountBalance) => {
        const values = {
            FRS: this.FRS - amountBalance.FRS,
            F24: this.F24 - amountBalance.F24,
        };

        return new AmountBalance(values);
    };

    add = (amountBalance) => {
        const values = {
            FRS: this.FRS + amountBalance.FRS,
            F24: this.F24 + amountBalance.F24,
        };

        return new AmountBalance(values);
    };
}
