import { FormField } from "components/Form";
import { productsBrandsSelector } from "store/autocomplete";
import { orderBy } from "utils/extensions/arrayExtensions";
import { useCommonTranslation } from "utils/hooks";
import React from "react";
import { useSelector } from "react-redux";

const ProductsBrandsAutocomplete = ({ validate, name = "brands", stretch = true, multiple = true, ...props }) => {
    const { t, common } = useCommonTranslation();
    const productsBrands = useSelector(productsBrandsSelector);

    return (
        <FormField
            stretch={stretch}
            name={name}
            label={multiple ? t(common.brands) : t(common.brand)}
            type={"autocomplete"}
            items={orderBy(productsBrands)}
            multiple={multiple}
            disableCloseOnSelect
            validate={validate}
            {...props}
        />
    );
};

export default ProductsBrandsAutocomplete;
