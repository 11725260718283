const findAccountingPeriods = (accountingPeriods = [], vendorId, userId) => {
    const isAnyOpen = accountingPeriods.some(
        (p) =>
            !p.isClosed || p?.overrides?.some((o) => o.userid === userId && !o.isCompleted && (!o.vendorId || o.vendorId.split("_")[0] === vendorId))
    );

    return {
        accountingPeriods: accountingPeriods.map((p) => {
            const overrides = p.overrides?.filter(
                (o) => o.userId === userId && !o.isCompleted && (!o.vendorId || o.vendorId.split("_")[0] === vendorId)
            );

            return {
                ...p,
                overrides: overrides?.length > 0 ? overrides : undefined,
            };
        }),
        isAnyAccountingPeriodOpen: isAnyOpen,
    };
};

export default findAccountingPeriods;
