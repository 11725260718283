import { Button } from "components/Button";
import AcceptRejectDialog from "components/Dialog/AcceptRejectDialog";
import SimpleTable, { createColumn } from "components/Table/SimpleTable/SimpleTable";
import tableActionTypes from "components/Table/SimpleTable/TableActions/tableActionTypes";
import { getRefundations, refundationsListSelector } from "store/vendors/refundations";
import { removeRefundation } from "store/vendors/refundations/action";
import { makeStyles } from "tss-react/mui";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useCommonTranslation, useParams, useUserAccountingPeriods } from "utils/hooks";
import { isAccountingPeriodOpen } from "utils/utilsFunctions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon, Typography } from "@mui/material";
import moment from "moment";
import { vendor } from "translations";
import { usePush } from "utils-ts/hooks";
import { useTranslation } from "utils-ts/hooks";
import { IsVendorReadOnly } from "views/vendors/IsVendorReadOnly";

const isBlocked = (item) => moment(item.dateFrom).isSameOrBefore(moment(), "day");

const useStyles = makeStyles()(() => ({
    table: {
        "& tbody": {
            "& td:nth-of-type(4)": {
                wordBreak: "break-all",
            },
        },
    },
}));

const Refundations = () => {
    const { classes } = useStyles();
    const { id: vendorId } = useParams();
    const [refundationId, setRefundationId] = useState();
    const [filter, setFilter] = useState({
        dateFrom: undefined,
        dateTo: undefined,
        typeOfValue: undefined,
        email: undefined,
    });
    const { t } = useTranslation("vendor");
    const { t: tc, common } = useCommonTranslation();
    const dispatch = useDispatch();
    const { push } = usePush();
    const isAccountant = IsVendorReadOnly();
    const { items, pageIndex, pageSize, totalCount, isLoading } = useSelector(refundationsListSelector);

    const { accountingPeriods } = useUserAccountingPeriods(vendorId);

    useEffect(() => {
        dispatch(
            getRefundations(vendorId, {
                ...filter,
                pageSize,
                pageIndex: 1,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vendorId, dispatch, filter]);

    const handleOpen = (id) => setRefundationId(id);
    const handleClose = () => setRefundationId(null);

    const columns = [
        createColumn("refundationNumber", t(vendor.refundationNumber), formatTypes.text),
        createColumn("dateFrom", t(vendor.dateFrom), formatTypes.time, { timeFormat: timePrecisonFormats.days }, null, true),
        createColumn("dateTo", t(vendor.dateTo), formatTypes.time, { timeFormat: timePrecisonFormats.days }, null, true),
        createColumn("description", t(vendor.description), formatTypes.text, {
            style: { "word-break": "break-all" },
        }),
        createColumn("user", t(vendor.email), formatTypes.userInfo, null, null, true),
        createColumn("changeStatus", t(vendor.correction), formatTypes.correction),
        createColumn(
            "action",
            "",
            formatTypes.action,
            {
                actionType: (item) =>
                    isAccountant || isAccountingPeriodOpen(accountingPeriods, item?.dateFrom) ? tableActionTypes.preview : tableActionTypes.edit,
                resetButton: true,
            },
            (item) => {
                const { id } = item;
                push(`/vendors/${vendorId}/refundations/form/${id}`);
            }
        ),
    ];

    if (!isAccountant) {
        columns.push(
            createColumn(
                "action",
                "",
                formatTypes.action,
                { actionType: tableActionTypes.delete },
                (item) => {
                    const { id } = item;
                    handleOpen(id);
                },
                undefined,
                isBlocked
            )
        );
    }

    const handleFilter = (column, value) => {
        setFilter({ ...filter, [column]: value });
    };

    return (
        <>
            <SimpleTable
                tableActions={[
                    !isAccountant && (
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                push(`/vendors/${vendorId}/refundations/form`);
                            }}
                        >
                            <Icon>add</Icon>
                        </Button>
                    ),
                ].filter(Boolean)}
                className={classes.table}
                columns={columns}
                data={
                    items?.map(({ current, ...rest }) => ({
                        ...rest,
                        ...current,
                        changeStatus: rest.isCorrected ? "Corrected" : rest.changeStatus,
                    })) || []
                }
                filterFunction={handleFilter}
                onRowDoubleClick={(_, item) => {
                    const { id } = item;
                    push(`/vendors/${vendorId}/refundations/form/${id}`);
                }}
                isLoading={isLoading}
                stripped
                hover
                pagination
                persistentFilter
                paginationProps={{
                    pageIndex,
                    totalCount,
                    pageSize,
                    onPageChange: (pageIndex) =>
                        dispatch(
                            getRefundations(vendorId, {
                                ...filter,
                                pageSize,
                                pageIndex,
                            })
                        ),
                    onRowsPerPageChange: (pageSize) =>
                        dispatch(
                            getRefundations(vendorId, {
                                ...filter,
                                pageIndex: 1,
                                pageSize,
                            })
                        ),
                }}
            />

            <AcceptRejectDialog
                title={tc(common.remove)}
                maxWidth={"sm"}
                open={Boolean(refundationId)}
                handleAccept={async () => {
                    const { type } = await dispatch(removeRefundation(vendorId, refundationId));
                    if (type.endsWith("SUCCESS")) {
                        dispatch(
                            getRefundations(vendorId, {
                                ...filter,
                                pageIndex,
                                pageSize,
                            })
                        );
                    }
                    handleClose();
                }}
                handleReject={() => {
                    handleClose();
                }}
            >
                <Typography variant="body1">
                    {tc(common.areYouSure, {
                        what: tc(common.toRemove),
                    })}
                </Typography>
            </AcceptRejectDialog>
        </>
    );
};

export default Refundations;
