import { makeStyles } from "tss-react/mui";
import { Grid, Paper } from "@mui/material";
import moment from "moment";
import DefinedRanges from "./DefinedRanges";
import { MARKERS } from "./Makers";
import Month from "./Month";

const useStyles = makeStyles()((theme) => ({
    header: {
        padding: "20px 70px",
    },
    headerItem: {
        flex: 1,
        textAlign: "center",
    },
    divider: {
        borderLeft: `1px solid ${theme.palette.action.hover}`,
        marginBottom: 5,
    },
    dividerHorizontal: {
        borderTop: `1px solid ${theme.palette.action.hover}`,
        marginBottom: 5,
    },
    definedRangesContainer: {
        width: "100%",
    },
}));

const Menu = (props) => {
    const { classes } = useStyles();

    const { ranges, dateRange, minDate, maxDate, firstMonth, setFirstMonth, secondMonth, setSecondMonth, setDateRange, helpers, handlers } = props;

    const canNavigateCloser = moment(secondMonth).diff(moment(firstMonth), "months") >= 2;
    const commonProps = {
        dateRange,
        minDate,
        maxDate,
        helpers,
        handlers,
    };
    return (
        <Paper
            elevation={5}
            square
        >
            <Grid
                container
                direction="row"
                wrap="nowrap"
            >
                <Month
                    {...commonProps}
                    value={firstMonth}
                    setValue={setFirstMonth}
                    navState={[true, canNavigateCloser]}
                    marker={MARKERS.FIRST_MONTH}
                />
                <div className={classes.divider} />
                <Month
                    {...commonProps}
                    value={secondMonth}
                    setValue={setSecondMonth}
                    navState={[canNavigateCloser, true]}
                    marker={MARKERS.SECOND_MONTH}
                />
            </Grid>
            <Grid className={classes.definedRangesContainer}>
                <div className={classes.dividerHorizontal} />
                <DefinedRanges
                    selectedRange={dateRange}
                    ranges={ranges}
                    setRange={setDateRange}
                />
            </Grid>
        </Paper>
    );
};

export default Menu;
