import { ContentData, PromotionPoolPredicateFields } from "components/Controls";
import { CommerceForm, FormField } from "components/Form";
import Layout from "components/Grid/Layout";
import { reduxForm } from "redux-form";
import { usePromotionFormAutocompleteEffect } from "store/autocomplete";
import { actions } from "store/offers/content/form/actions";
import { mappedContentFormSelector } from "store/offers/selectors";
import { toObject } from "utils/extensions/arrayExtensions";
import { useCommonTranslation, useCopyRouterState, useMappedDispatch } from "utils/hooks";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { usePush } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { validators } from "../validators";

const ContentForm = ({ handleSubmit, isSubbmitRequested, isSystem, name, initialValues }) => {
    usePromotionFormAutocompleteEffect();
    const { id, isCopy } = useCopyRouterState();
    const { initForm, createOrUpdate } = useMappedDispatch(actions);
    const { replace } = usePush();
    useEffect(() => {
        initForm(id, isCopy);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, isCopy]);
    const { t, common } = useCommonTranslation();

    const submit = async (values) => {
        const { contentData, ...rest } = values;
        const mapped = {
            ...rest,
            contentData: contentData ? toObject(contentData) : null,
        };
        const { payload } = await createOrUpdate(mapped, !isCopy && id);
        if ((!isCopy && !id) || isCopy) {
            replace(Paths.Offers.ContentForm, { id: payload.id });
        }
    };

    const readOnly = isSystem && !isCopy;

    return (
        <CommerceForm
            onSubmit={handleSubmit((values) => submit(values))}
            isReadonly={readOnly}
            isSubbmitRequested={isSubbmitRequested}
        >
            <Layout
                main
                headerText={t(common.productContent, {
                    id: !isCopy && id ? id : "",
                })}
                navigationProps={{
                    backProps: { path: Paths.Offers.ContentsList },
                }}
                customTitle={id && name && `${t(common.productContent)} - ${name}`}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <FormField
                        name={"name"}
                        type={"text"}
                        label={t(common.name)}
                        validate={validators.name}
                        readOnly={readOnly}
                    />
                    <FormField
                        name={"isActive"}
                        type={"boolean"}
                        label={t(common.isActive)}
                        readOnly={readOnly}
                    />
                    <FormField
                        name={"isPositioned"}
                        type={"boolean"}
                        label={t(common.isPositioned)}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <FormField
                        name={"validFrom"}
                        type={"dateTime"}
                        label={t(common.validFrom)}
                        readOnly={readOnly}
                    />
                    <FormField
                        name={"validTo"}
                        type={"dateTime"}
                        label={t(common.validTo)}
                        readOnly={readOnly}
                    />
                </Grid>
            </Layout>

            <PromotionPoolPredicateFields
                formName={formName}
                renderRequiremtnts={false}
                readOnly={readOnly}
            />

            <ContentData
                required
                useCollapse={false}
                readOnly={readOnly}
            />
        </CommerceForm>
    );
};

const formName = "content-form";

const stateToProps = (state) => {
    const { isSubbmitRequested, isSystem, ...form } = mappedContentFormSelector(state);
    return {
        isSubbmitRequested,
        isSystem,
        name: form.name,
        initialValues: {
            ...form,
        },
    };
};

export default connect(stateToProps)(
    reduxForm({
        form: formName,
        enableReinitialize: true,
    })(ContentForm)
);
