import { Button } from "components/Button";
import { useCommonTranslation } from "utils/hooks";
import React, { Fragment } from "react";
import ReadonlyContainer from "./ReadonlyContainer";

const CommerceForm = ({ children, onSubmit, isReadonly, isSubbmitRequested = false }) => {
    const { t, common } = useCommonTranslation();
    return (
        <form onSubmit={onSubmit}>
            <ReadonlyContainer showContainer={isReadonly} />
            {children}
            {!isReadonly ? (
                <Button
                    actionType="accept"
                    type="submit"
                    fullWidth
                    loading={isSubbmitRequested}
                >
                    {t(common.save)}
                </Button>
            ) : (
                <Fragment />
            )}
        </form>
    );
};

export default CommerceForm;
