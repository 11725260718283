import { correctionRequestFormSelector, getCorrectionRequest } from "store/vendors/correctionRequests";
import { useParams } from "utils/hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { common, vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";
import VendorLayout from "views/vendors/components/VendorLayout";
import CorrectionRequestForm from "./CorrectionRequestForm";

const CorrectionRequest = () => {
    const { vendorId, correctionRequestId } = useParams();
    const { t } = useTranslation(["common", "vendor"]);
    const dispatch = useDispatch();
    const { isLoading, settlementType, dateFrom, dateTo } = useSelector(correctionRequestFormSelector);

    useEffect(() => {
        dispatch(getCorrectionRequest(vendorId, correctionRequestId));
    }, [dispatch, correctionRequestId, vendorId]);

    const settlementTypes = {
        Refundation: t("vendor:" + vendor.refundations),
        RetroConditional: t("vendor:" + vendor.retroConditionalDiscounts),
        RetroUnconditional: t("vendor:" + vendor.retroUnconditionalDiscounts),
    };

    return (
        <VendorLayout
            headerText={t(common.correctionRequest, {
                id: correctionRequestId,
            })}
            vendorId={vendorId}
            isLoading={isLoading}
            tabIndex={5}
            customTitle={`${vendorId} ${t(common.correctionRequest)} ${settlementType && settlementTypes[settlementType]} ${
                dateFrom && dateTo ? `${moment(dateFrom).format("DD.MM.YYYY")}-${moment(dateTo).format("DD.MM.YYYY")}` : ""
            }`}
        >
            <CorrectionRequestForm />
        </VendorLayout>
    );
};

export default CorrectionRequest;
