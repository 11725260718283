import { commerce, manager, operations } from "store/actionTypePrefixes";
import { AsyncTypes } from "utils/extensions";

const types = {
    getProductPreview: new AsyncTypes("GET_PRODUCT_PREVIEW", commerce),
    getProductPriceHistory: new AsyncTypes("GET_PRODUCT_PRICE_HISTORY", commerce),
    getProductInternalData: new AsyncTypes("GET_PRODUCT_INTERNAL_DATA", operations),
    productPreviewCleanup: `${manager}/PRODUCT_PREVIEW_CLEANUP`,
};

export default types;
