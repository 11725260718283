import React from "react";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import { SalesDepartment } from "routing-ts/roles";

const PriceListsPage = React.lazy(() => import("views/priceLists/PriceLists"));

export const PriceLists: PrivateRoute[] = [
    new PrivateRoute(Paths.PriceLists, ["Management", ...SalesDepartment.GetRoles()], PriceListsPage, "priceList", "local_atm"),
];
