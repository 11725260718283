import { manager, operations } from "store/actionTypePrefixes";
import { AsyncTypes, asAsyncFormTypes, createAction } from "utils/extensions";

const types = {
    formInit: createAction("FORM_INIT_CONTRACT", manager),
    getContract: new AsyncTypes("GET_CONTRACT", operations),
    findDraft: new AsyncTypes("FIND_CONTRACT_DRAFT", operations),
    createOrUpdate: new AsyncTypes("CREATE_OR_UPDATE_CONTRACT", operations, [asAsyncFormTypes]),
    deleteContractGain: new AsyncTypes("DELETE_CONTRACT", operations, [asAsyncFormTypes]),
    downloadFile: new AsyncTypes("DOWNLOAD_FILE", operations),
    removeFile: new AsyncTypes("REMOVE_FILE", operations),
    findContractForApproval: new AsyncTypes("FIND_CONTRACT_FOR_APPROVAL", operations),
    makeContractDecison: new AsyncTypes("MAKE_CONTRACT_DECISION", operations, [asAsyncFormTypes]),
    addFileToDraftContract: new AsyncTypes("ADD_FILE_TO_DRAFT_CONTRACT", operations),
    changeFileInDraftContract: new AsyncTypes("CHANGE_FILE_IN_DRAFT_CONTRACT", operations),
    removeFileFromDraftContract: new AsyncTypes("REMOVE_FILE_FROM_DRAFT_CONTRACT", operations),
};

export default types;
