import React, { createContext, useContext, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Box, Grid } from "@mui/material";
import {
    GridActionsCellItem,
    GridActionsColDef,
    GridCallbackDetails,
    GridColDef,
    GridColumnResizeParams,
    GridColumnVisibilityModel,
    GridPaginationModel,
    GridRowSelectionModel,
    DataGrid as MuiDataGrid,
    gridClasses,
    useGridApiRef,
} from "@mui/x-data-grid";
import { GridApiCommunity, GridBaseColDef } from "@mui/x-data-grid/internals";
import { createId } from "@paralleldrive/cuid2";
import { DataGridColumn, DataGridProps } from "control-types";
import { useWindowSize } from "utils-ts/hooks";
import { Button } from "components-ts/controls";
import { Spacing } from "components-ts/view";

const columnWidthsStorageName = "dataGridWidths";
const dataGridPaginationStorageName = "dataGridPagination";
const initState = {
    pagination: {
        paginationModel: {
            pageSize:
                localStorage.getItem(dataGridPaginationStorageName) !== null
                    ? JSON.parse(localStorage.getItem(dataGridPaginationStorageName) as string)
                    : 50,
        },
    },
};

const pageSizes = [10, 20, 50, 100];
const serverPageSizes = [10, 25, 50, 100];
const sx = {
    "& .MuiDataGrid-columnHeaderTitle": {
        whiteSpace: "normal",
        lineHeight: "normal",
    },
    "& .MuiDataGrid-columnHeaders": {
        backgroundColor: "#283593",
        color: "#FFFFFF",
    },
    "& .MuiDataGrid-columnHeader.a": {
        backgroundColor: "#283593",
        color: "#FFFFFF",
    },
    "& .MuiDataGrid-columnHeader.b": {
        backgroundColor: "#0e8e00",
        color: "#FFFFFF",
    },
    "& .MuiDataGrid-sortIcon": {
        color: "#FFFFFF",
    },
    "& .MuiDataGrid-menuIconButton": {
        color: "#FFFFFF",
    },
    "& .MuiDataGrid-filterIcon": {
        color: "#FFFFFF",
    },
    "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
        color: "#FFFFFF",
        borderColor: "#FFFFFF",
    },
    "& .MuiDataGrid-columnHeaderCheckbox": {
        backgroundColor: "#283593",
        color: "#FFFFFF",
    },
    [`& .${gridClasses.row}.odd`]: {
        backgroundColor: "#ececec",
    },
    "& ::-webkit-scrollbar": {
        width: "20px",
        height: "20px",
    },
};

const getRowClassName = (params: { indexRelativeToCurrentPage: number }) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd");

const calculateGridHeight = (
    columnHeaderHeight: number,
    pageSize: number,
    pageIndex: number,
    rows: Array<{ id: string }>,
    apiRef: React.MutableRefObject<GridApiCommunity>
) => {
    let rowsHeight = columnHeaderHeight;
    rows.slice((pageIndex - 1) * pageSize, pageIndex * pageSize).forEach((row, rowIndex) => {
        console.log({ rowIndex: rowIndex + (pageIndex - 1) * pageSize, rowHeight: apiRef.current.unstable_getRowHeight(row.id), rowsHeight });
        rowsHeight += apiRef.current.unstable_getRowHeight(row.id) + 5; //5px extra to not showing vertical scroll when rows number is small
    });
    rowsHeight += 53; //pagination
    rowsHeight += 20; //horizontal scroll

    return rowsHeight;
};
type DataGridWidth = {
    name: string;
    width: number;
};
const DataGrid = <TValue extends { id: string }>({
    columns,
    rows,
    visibleColumnsKey,
    addItem,
    getRowActions,
    checkboxSelection,
    canCopyItemValues,
    copyButtonText,
    deleteButtonText,
    handleCopyValues,
    onRowEditStop,
    setSelectedRows,
    additionalButtons,
    autoHeight,
    handlePaginationChange,
    paginationModel,
    isRowSelectable,
    handleDeleteRows,
    columnHeaderHeight = 50,
}: DataGridProps<TValue>): JSX.Element => {
    const [_windowWidth, windowHeight] = useWindowSize();
    const apiRef = useGridApiRef();
    const onColumnVisibilityModelChange = React.useMemo(
        () => (_model: GridColumnVisibilityModel, _details: GridCallbackDetails) => {
            const state = apiRef.current.exportState();
            localStorage.setItem(visibleColumnsKey, JSON.stringify(state));
        },
        [visibleColumnsKey]
    );
    const [gridHeight, setGridHeight] = useState<number>(columnHeaderHeight);
    const [selection, setSelection] = useState<GridRowSelectionModel>([]);
    const pageSize = paginationModel?.pageSize ?? initState.pagination.paginationModel.pageSize;
    const pageIndex = paginationModel?.pageIndex ?? 1;
    const columnWidths: DataGridWidth[] =
        localStorage.getItem(columnWidthsStorageName) !== null ? JSON.parse(localStorage.getItem(columnWidthsStorageName) as string) : [];
    useEffect(() => {
        try {
            const stateJSON = localStorage.getItem(visibleColumnsKey);
            if (stateJSON) {
                apiRef.current.restoreState(JSON.parse(stateJSON));
            }
        } catch (e) {
            console.log(e);
        }
    }, []);

    useEffect(() => {
        if (apiRef.current && apiRef.current.getAllRowIds !== undefined) {
            setGridHeight(calculateGridHeight(columnHeaderHeight, pageSize, pageIndex, rows, apiRef));
        }
    }, [rows, windowHeight]);

    const handleAdd = () => {
        if (!addItem) {
            return;
        }

        addItem(createId());
    };

    const isGridColDef = (column: DataGridColumn<TValue> | GridColDef<TValue>): column is GridColDef<TValue> => {
        return (column as GridColDef<TValue>).field !== undefined;
    };

    const cols: GridColDef<TValue>[] = columns.map((c) => {
        const columnWidth = columnWidths.find((cn) => cn.name == c.name || cn.name == c.field);
        if (columnWidth) {
            c.width = columnWidth.width;
        }

        if (isGridColDef(c)) {
            return c;
        } else {
            return {
                field: c.name,
                headerName: c.label,
                width: c.width,
                minWidth: c.minWidth,
                editable: c.readOnly ? false : true,
                hideable: c.canHide ? true : false,
                headerClassName: c.alterStyle ? "b" : "a",
                renderCell: c.renderCell,
                renderEditCell: c.renderEditCell,
                display: "flex",
            } as GridBaseColDef<TValue>;
        }
    });

    if (getRowActions) {
        cols.push({
            field: "actions",
            type: "actions",
            headerName: "",
            width: 100,
            hideable: false,
            sortable: false,
            cellClassName: "actions",
            headerClassName: "a",
            resizable: false,
            getActions: ({ id, row }: { id: string; row: TValue }) => {
                return getRowActions().map((ca) => (
                    <GridActionsCellItem
                        icon={ca.actionType === "delete" ? <DeleteIcon /> : <></>}
                        label={ca.actionType === "delete" ? "Delete" : ""}
                        onClick={() => ca.onClick(id, row)}
                        color="secondary"
                        disabled={ca.disabled}
                    />
                ));
            },
        } as GridActionsColDef<TValue>);
    }

    const handleSetSelection = (rows: GridRowSelectionModel) => {
        setSelection(rows);
        if (setSelectedRows) {
            setSelectedRows(rows);
        }
    };

    return (
        <>
            <Box sx={!!autoHeight ? { width: "100%" } : { width: "100%", height: `min(100vh, ${gridHeight}px)` }}>
                <MuiDataGrid
                    autoHeight={autoHeight}
                    getRowHeight={() => "auto"}
                    editMode="row"
                    apiRef={apiRef}
                    rows={rows}
                    columns={cols}
                    initialState={initState}
                    pageSizeOptions={paginationModel ? serverPageSizes : pageSizes}
                    disableRowSelectionOnClick
                    showCellVerticalBorder
                    onColumnVisibilityModelChange={onColumnVisibilityModelChange}
                    getRowClassName={getRowClassName}
                    sx={sx}
                    checkboxSelection={checkboxSelection}
                    isRowSelectable={isRowSelectable}
                    onRowSelectionModelChange={handleSetSelection}
                    onRowEditStart={(params) => {
                        setGridHeight(gridHeight + 100);
                    }}
                    onRowEditStop={(params) => {
                        if (apiRef.current && apiRef.current.getAllRowIds !== undefined) {
                            setGridHeight(calculateGridHeight(columnHeaderHeight, pageSize, pageIndex, rows, apiRef));
                        }

                        if (onRowEditStop) {
                            onRowEditStop(params.row);
                        }
                    }}
                    onPaginationModelChange={(model: GridPaginationModel) => {
                        localStorage.setItem(dataGridPaginationStorageName, model.pageSize.toString());
                        if (handlePaginationChange) {
                            handlePaginationChange({ pageIndex: model.page + 1, pageSize: model.pageSize });
                        }

                        if (apiRef.current && apiRef.current.getAllRowIds !== undefined) {
                            setTimeout(
                                () => setGridHeight(calculateGridHeight(columnHeaderHeight, model.pageSize, model.page + 1, rows, apiRef)),
                                500
                            );
                        }
                    }}
                    paginationModel={paginationModel ? { pageSize: pageSize, page: pageIndex - 1 } : undefined}
                    rowCount={paginationModel?.totalCount}
                    paginationMode={paginationModel ? "server" : "client"}
                    columnHeaderHeight={columnHeaderHeight}
                    onColumnWidthChange={(params: GridColumnResizeParams) => {
                        const col: DataGridWidth[] =
                            localStorage.getItem(columnWidthsStorageName) !== null
                                ? JSON.parse(localStorage.getItem(columnWidthsStorageName) as string)
                                : [];
                        localStorage.setItem(
                            columnWidthsStorageName,
                            JSON.stringify([...col.filter((c) => c.name != params.colDef.field), { name: params.colDef.field, width: params.width }])
                        );

                        if (apiRef.current && apiRef.current.getAllRowIds !== undefined) {
                            setGridHeight(calculateGridHeight(columnHeaderHeight, pageSize, pageIndex, rows, apiRef));
                        }
                    }}
                />
            </Box>
            <Spacing spacing={2}>
                <Grid
                    container
                    direction="column"
                    justify-content="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                >
                    {addItem && (
                        <Grid
                            item
                            key="add"
                        >
                            <Button
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={handleAdd}
                                label="addRow"
                            />
                        </Grid>
                    )}
                    {canCopyItemValues && (
                        <Grid
                            item
                            key="copy"
                        >
                            <Button
                                color="primary"
                                onClick={() => {
                                    if (handleCopyValues) {
                                        handleCopyValues(selection, rows);
                                    }
                                }}
                                disabled={selection.length == 0}
                                label={copyButtonText || "copy"}
                                startIcon={<AutoAwesomeMotionIcon />}
                            />
                        </Grid>
                    )}
                    {handleDeleteRows && (
                        <Grid
                            item
                            key="delete"
                        >
                            <Button
                                color="primary"
                                onClick={() => {
                                    if (handleDeleteRows) {
                                        handleDeleteRows(selection, rows);
                                    }
                                }}
                                disabled={selection.length == 0}
                                label={deleteButtonText || "delete"}
                                startIcon={<DeleteForeverIcon />}
                            />
                        </Grid>
                    )}
                    {!!additionalButtons &&
                        additionalButtons.map((button, index) => (
                            <Grid
                                item
                                key={`button-${index}`}
                            >
                                {button}
                            </Grid>
                        ))}
                </Grid>
            </Spacing>
        </>
    );
};

export default DataGrid;

const DataGridContext = createContext<React.MutableRefObject<GridApiCommunity> | undefined>(undefined);

export const useDataGridApiContext = (): React.MutableRefObject<GridApiCommunity> => {
    const context = useContext(DataGridContext);
    if (!context) {
        throw new Error("useDataGridApiContext must be used within a DataGridApiProvider");
    }

    return context;
};

export const DataGridApiProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const apiRef = useGridApiRef();

    return <DataGridContext.Provider value={apiRef}>{children}</DataGridContext.Provider>;
};
