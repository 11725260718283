import { useConfig } from "context/ConfigContext/ConfigContext";
import React, { createContext, useContext, useEffect, useState } from "react";

const VersionContext = createContext();

const everyFourthMinute = 1000 * 60 * 4;

const versionStorageKey = "version";

const currentVersion = localStorage.getItem(versionStorageKey);

const refreshCacheAndReload = async () => {
    let cachesPromises = [];

    if (caches) {
        caches.keys().then((names) => {
            for (let name of names) {
                cachesPromises = [...cachesPromises, caches.delete(name)];
            }
        });
    }

    return Promise.all(cachesPromises).then(() => {
        window.location.reload();
    });
};

export const VersionProvider = ({ children }) => {
    const { env } = useConfig();
    const isDevelopment = env === "Development";
    const [initialCheckDone, setInitialCheckDone] = useState(isDevelopment);
    const [state, setState] = useState({
        isLatestVersion: true,
        currentVersion: currentVersion === "undefined" || !currentVersion ? undefined : currentVersion,
        isLoading: false,
    });

    useEffect(() => {
        const fetchVersion = () =>
            fetch("/meta.json", { cache: "no-store" })
                .then((resp) => resp.json())
                .catch((_) => {
                    if (!isDevelopment) {
                        console.error("meta.json file is missing");
                    }
                })
                .then((newMeta) => {
                    if (newMeta) {
                        const oldVersion = currentVersion;
                        const newVersion = newMeta?.version;

                        localStorage.setItem(versionStorageKey, newVersion);

                        if (oldVersion === newVersion) {
                            setState({
                                ...state,
                                isLatestVersion: true,
                            });
                        }

                        if (oldVersion !== newVersion) {
                            return refreshCacheAndReload();
                        }

                        return Promise.resolve();
                    }
                });

        let interval = null;

        if (!isDevelopment) {
            fetchVersion().then(() => {
                setInitialCheckDone(true);
            });

            interval = setInterval(() => {
                fetchVersion();
            }, everyFourthMinute);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <VersionContext.Provider
            value={{
                currentVersion: state.currentVersion,
                latestVersion: state.latestVersion,
                isLatestVersion: state.isLatestVersion,
                refreshCacheAndReload,
            }}
        >
            {children}
        </VersionContext.Provider>
    );
};

export const useVersion = () => useContext(VersionContext);
