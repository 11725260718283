import { RSAA } from "redux-api-middleware";
import { commerce } from "store/actionTypePrefixes";
import { asAsyncFormTypes } from "utils/extensions/arrayExtensions";
import { combineIntoQueryParams } from "utils/objectExtensions";

export const types = {
    PHRASE_BOOST: `${commerce}/PHRASE_BOOST`,
    PHRASE_BOOST_SUCCESS: `${commerce}/PHRASE_BOOST_SUCCESS`,
    PHRASE_BOOST_FAILURE: `${commerce}/PHRASE_BOOST_FAILURE`,
    REMOVE_PHRASE: `${commerce}/REMOVE_PHRASE`,
    REMOVE_PHRASE_SUCCESS: `${commerce}/REMOVE_PHRASE_SUCCESS`,
    REMOVE_PHRASE_FAILURE: `${commerce}/REMOVE_PHRASE_FAILURE`,
};

const getList =
    ({ isActive, ...rest }) =>
    async (dispatch) => {
        let query = { ...rest };
        if (isActive !== "") query.isActive = isActive;
        const queryParams = combineIntoQueryParams({ ...query });
        return dispatch({
            [RSAA]: {
                endpoint: `api/v1/search/phrase-boost?${queryParams}`,
                headers: { "Content-Type": "application/json" },
                method: "GET",
                types: [types.PHRASE_BOOST, types.PHRASE_BOOST_SUCCESS, types.PHRASE_BOOST_FAILURE],
            },
        });
    };

const remove = (id) => async (dispatch) => {
    return dispatch({
        [RSAA]: {
            endpoint: `api/v1/search/phrase-boost/${id}`,
            headers: { "Content-Type": "application/json" },
            method: "DELETE",
            types: asAsyncFormTypes([types.REMOVE_PHRASE, types.REMOVE_PHRASE_SUCCESS, types.REMOVE_PHRASE_FAILURE]),
        },
    });
};

export const actions = {
    getList,
    remove,
};
