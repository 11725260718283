import { Button } from "components/Button";
import { InfoDialog } from "components/Dialog";
import { Form, FormField } from "components/Form";
import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import { reduxForm } from "redux-form";
import { getMembershipSeasons, membershipSeasonsSelector } from "store/autocomplete";
import { customerMembershipPointsSelector, editCustomerPoints, getCustomerMembershipPoints } from "store/customers/customer/customerMembership";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useParams } from "utils/hooks";
import { notEmptyValidator } from "utils/validators";
import { useCallback, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { customers } from "translations";
import { useTranslation } from "utils-ts/hooks";
import CustomerMembershipRow from "./CustomerMembershipRow";

const required = (val) => notEmptyValidator(val).validate();

const CustomerMembership = ({ handleSubmit }) => {
    const { t } = useTranslation("customers");
    const { customerId } = useParams();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const { items, isLoading, ...pagination } = useSelector(customerMembershipPointsSelector);
    const seasons = useSelector(membershipSeasonsSelector);

    useEffect(() => {
        dispatch(getCustomerMembershipPoints(customerId, pagination.pageSize, pagination.pageIndex));
        dispatch(getMembershipSeasons());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId, dispatch]);

    const handleOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const getSeasonExpirationDate = (forDate) => {
        return seasons
            .find((s) => s.endsAt > forDate)
            ?.expiresAt.split("-")
            .reverse()
            .join(".");
    };

    const columns = [
        {
            key: "createdAt",
            value: customers.awardedAt,
            type: formatTypes.time,
            options: { timeFormat: timePrecisonFormats.seconds },
        },
        {
            key: "operation",
            value: customers.reason,
            type: formatTypes.translatable,
            options: { translationKey: "customers" },
        },
        {
            key: "membershipPoints",
            value: customers.membershipPoints,
            type: formatTypes.points,
        },
        {
            key: "orderId",
            value: customers.orderId,
            type: formatTypes.text,
        },
        {
            key: "createdAt",
            value: customers.expiresAt,
            type: formatTypes.func,
            options: { func: getSeasonExpirationDate },
        },
    ].map((col) => ({ ...col, value: t(col.value) }));

    return (
        <>
            <SimpleTable
                tableActions={[
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            handleOpen();
                        }}
                    >
                        {t(customers.EditFFBalance)}
                    </Button>,
                ]}
                isLoading={isLoading}
                columns={columns}
                hover
                fillEmptyRows={true}
                pagination
                paginationProps={{
                    ...pagination,
                    onPageChange: (pageIndex) => dispatch(getCustomerMembershipPoints(customerId, pagination.pageSize, pageIndex)),
                    onRowsPerPageChange: (pageSize) => dispatch(getCustomerMembershipPoints(customerId, pageSize, 1)),
                }}
            >
                {items.map((x, index) => (
                    <CustomerMembershipRow
                        key={index}
                        columns={columns}
                        item={{ ...x, index, expiresAt: getSeasonExpirationDate(x.createdAt) }}
                        t={t}
                    />
                ))}
            </SimpleTable>
            <InfoDialog
                open={open}
                title={t(customers.EditFFBalance)}
                onClose={handleClose}
            >
                <Form
                    onSubmit={handleSubmit(async (values) => {
                        await dispatch(editCustomerPoints(customerId, values));

                        dispatch(getCustomerMembershipPoints(customerId, pagination.pageSize, 1));
                        handleClose();
                    })}
                >
                    <FormField
                        name="seasonNumber"
                        type="number"
                        label={t(customers.SeasonNumber)}
                        validate={required}
                        minValue={1}
                    />
                    <FormField
                        name="membershipPoints"
                        type="number"
                        label={t(customers.membershipPoints)}
                        validate={required}
                    />
                </Form>
            </InfoDialog>
        </>
    );
};

const formName = "customer-membership-points";

const mapStateToProps = (state) => {
    const {
        customer: { customerMembership },
    } = state;

    return { customerMembership };
};

export default connect(mapStateToProps)(
    reduxForm({
        form: formName,
        enableReinitialize: true,
    })(CustomerMembership)
);
