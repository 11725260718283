import { CartContextPredicate, CartPredicate, ContentData } from "components/Controls";
import { CommerceForm, FormField } from "components/Form";
import { integerNormalize } from "components/FormHelpers/ControlFormaters";
import Layout from "components/Grid/Layout";
import { reduxForm } from "redux-form";
import { usePromotionFormAutocompleteEffect } from "store/autocomplete";
import { initGiftForm, saveGift } from "store/offers/gifts/form/actions";
import { giftsFormSelector } from "store/offers/selectors";
import { toObject } from "utils/extensions";
import { mapRequirements } from "utils/formExtensions";
import { useCommonTranslation, useCopyRouterState } from "utils/hooks";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { usePush } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { validators } from "../validators";

const GiftForm = ({ handleSubmit, isSubbmitRequested, isSystem, name, initialValues }) => {
    usePromotionFormAutocompleteEffect();
    const { id, isCopy } = useCopyRouterState();
    const dispatch = useDispatch();
    const { replace } = usePush();
    useEffect(() => {
        dispatch(initGiftForm(id, isCopy));
    }, [dispatch, id, isCopy]);
    const { t, common } = useCommonTranslation();

    const readOnly = isSystem && !isCopy;

    const submit = async (values) => {
        const { payload } = await dispatch(saveGift(values, !isCopy && id));

        if ((!isCopy && !id) || isCopy) {
            replace(Paths.Offers.GiftForm, { id: payload.id });
        }
    };

    return (
        <CommerceForm
            onSubmit={handleSubmit((values) => {
                const mapped = {
                    ...values,
                    choiceRequirements: mapRequirements(values.choiceRequirements, true),
                    retentionRequirements: mapRequirements(values.retentionRequirements),
                    awardRequirements: mapRequirements(values.awardRequirements),
                    contentData: values?.contentData ? toObject(values.contentData.filter((c) => Boolean(c.value))) : undefined,
                };
                submit(mapped);
            })}
            isReadonly={readOnly}
            isSubbmitRequested={isSubbmitRequested}
        >
            <Layout
                main
                headerText={t(common.giftForm, {
                    id: !isCopy && id ? id : "",
                })}
                navigationProps={{
                    backProps: { path: Paths.Offers.GiftsList },
                }}
                customTitle={id && name && `${t(common.giftForm)} - ${name}`}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-end"
                >
                    <FormField
                        name={"name"}
                        type={"text"}
                        label={t(common.name)}
                        validate={validators.name}
                        readOnly={readOnly}
                    />
                    <FormField
                        name={"isActive"}
                        type={"boolean"}
                        label={t(common.isActive)}
                        readOnly={readOnly}
                    />
                    <FormField
                        name={"validFrom"}
                        type={"dateTime"}
                        label={t(common.validFrom)}
                        validate={validators.validFrom}
                        readOnly={readOnly}
                    />
                    <FormField
                        name={"validTo"}
                        type={"dateTime"}
                        label={t(common.validTo)}
                        validate={validators.validTo}
                        readOnly={readOnly}
                    />
                    <FormField
                        name={"minumumChoiceCount"}
                        formatDisplay={integerNormalize}
                        type={"number"}
                        label={t(common.minumumChoiceCount)}
                        validate={validators.validateMinumumChoiceCount}
                        readOnly={readOnly}
                    />
                    <FormField
                        name={"maximumChoiceCount"}
                        formatDisplay={integerNormalize}
                        type={"number"}
                        label={t(common.maximumChoiceCount)}
                        validate={validators.validateMaximumChoiceCount}
                        readOnly={readOnly}
                    />
                    <FormField
                        name={"fallbackChoiceCount"}
                        formatDisplay={integerNormalize}
                        type={"number"}
                        label={t(common.fallbackChoiceCount)}
                        validate={validators.validateFallbackChoiceCount}
                        readOnly={readOnly}
                    />
                </Grid>
            </Layout>
            <Layout headerText={t(common.awardRequirements)}>
                <CartContextPredicate
                    name="awardRequirements"
                    required
                    readOnly={readOnly}
                />
            </Layout>
            <Layout headerText={t(common.retentionRequirements)}>
                <CartContextPredicate
                    name="retentionRequirements"
                    readOnly={readOnly}
                />
            </Layout>
            <Layout headerText={t(common.choiceRequirements)}>
                <CartPredicate
                    name="choiceRequirements"
                    readOnly={readOnly}
                />
            </Layout>
            <ContentData readOnly={readOnly} />
        </CommerceForm>
    );
};

const formName = "gift-form";

const stateToProps = (state) => {
    const { isSubbmitRequested, isSystem, ...form } = giftsFormSelector(state);
    return {
        isSystem,
        isSubbmitRequested,
        name: form.name,
        initialValues: {
            ...form,
        },
    };
};

export default connect(stateToProps)(
    reduxForm({
        form: formName,
        enableReinitialize: true,
    })(GiftForm)
);
