import { Button } from "components/Button";
import { useCommonTranslation } from "utils/hooks";
import { Alert, AlertTitle } from "@mui/lab";
import Preloader from "./Preloader";

const WaitForResource = ({ isLoading, hasError, retry, children }) => {
    const { t, common } = useCommonTranslation();

    return isLoading ? (
        <Preloader />
    ) : hasError ? (
        <Alert color="error">
            <AlertTitle>{t(common.errorOccuredWhileLoading)}</AlertTitle>
            {Boolean(retry) && (
                <Button
                    actionType="warning"
                    onClick={retry}
                >
                    {t(common.retryResourceLoading)}
                </Button>
            )}
        </Alert>
    ) : (
        children
    );
};

export default WaitForResource;
