import React from "react";
import { Grid } from "@mui/material";
import { useUser } from "context";
import { allCustomerRolesWithAuditorAndHRManager, baseCustomerRoles } from "routing-ts/Routes/CustomerRoles";
import BillingAdress from "./BillingAdress";
import CustomerType from "./CustomerType";
import PersonalInfo from "./PersonalInfo";
import Segments from "./Segments";

const CustomerGridWrapper = ({ customerId, customerInfo, customerBalance, customerMembership, billingAddress, segments }) => {
    const { isInAnyRoleOrAdmin } = useUser();

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
        >
            <Grid
                item
                xs={4}
            >
                <PersonalInfo
                    customerInfo={customerInfo}
                    customerBalance={customerBalance}
                    customerMembership={customerMembership}
                    segments={segments}
                />
                <BillingAdress billingAddress={billingAddress} />
            </Grid>
            <Grid
                item
                xs={7}
            >
                <CustomerType
                    disabled={!isInAnyRoleOrAdmin(baseCustomerRoles)}
                    customerId={customerId}
                    segments={segments}
                    creditLimit={customerInfo.creditLimit}
                    accountManager={customerInfo.accountManager}
                    retentionManager={customerInfo.retentionManager}
                    haveBillingAddress={Boolean(billingAddress?.vatin)}
                    roles={allCustomerRolesWithAuditorAndHRManager}
                />
                <Segments
                    customerId={customerId}
                    segments={segments}
                />
            </Grid>
        </Grid>
    );
};

export default CustomerGridWrapper;
