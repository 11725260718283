import { FormField } from "components/Form";
import { actions } from "store/cache/products/actions";
import { useMappedDispatch } from "utils/hooks";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { common } from "translations";
import ProductItem from "./PromotionPredicateFieldsComponents/ProductItem";
import { productsSelector } from "./PromotionPredicateFieldsComponents/selectors";

const isValidProductId = (v) => Boolean(Number(v)) && v?.length <= 6;

const ProductListField = (props) => {
    const { t } = useTranslation("common");
    const { getProducts } = useMappedDispatch(actions);
    const products = useSelector(productsSelector) || [];

    return (
        <FormField
            stretch
            {...props}
            name="products"
            label={t(common.products)}
            type="list"
            useList={false}
            itemRenderer={(v, handleRemove, innerProps) => {
                return (
                    <ProductItem
                        key={v}
                        v={v}
                        handleRemove={handleRemove}
                        products={products}
                        readOnly={innerProps.readOnly}
                    />
                );
            }}
            onAdd={(productIds) => {
                getProducts(productIds);
            }}
            additionalValueCheck={isValidProductId}
        />
    );
};

export default ProductListField;
