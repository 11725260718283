import { ArrayValidationError } from "components/Controls";
import {
    RenderAutoComplete,
    renderColor,
    renderRichTextField,
    renderSelect,
    renderSelectFlat,
    renderSwitch,
    renderTextField,
    renderTextFieldFlat,
} from "components/FormHelpers/Controls";
import { Field, FieldArray, FormSection } from "redux-form";
import React from "react";
import { Icon, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import { renderFieldOrText } from "./Controls";

export function createForm(fields, containerStyle, noGrid, noForm) {
    const result = noGrid ? (
        <React.Fragment>
            {fields.map((field) => {
                return createComponent(field);
            })}
        </React.Fragment>
    ) : (
        <Grid
            container
            spacing={2}
            style={containerStyle}
        >
            {fields.map((field, index) => {
                return <React.Fragment key={`${field}_${index}`}>{createComponent(field)}</React.Fragment>;
            })}
        </Grid>
    );
    return noForm ? result : <form>{result}</form>;
}

function createComponent(field) {
    const isRequired = field.validate && Boolean(field.validate(undefined, { canModify: true })) !== false;

    switch (field.component) {
        case "combined": {
            return (
                <Grid
                    container
                    key={field.name}
                >
                    {field.subComponents.map((x, i) => (
                        <React.Fragment key={i}>{createCombinedComponent(x, i)}</React.Fragment>
                    ))}
                </Grid>
            );
        }
        case "array": {
            return (
                <FieldArray
                    component={arrayComponent}
                    name={field.name}
                    field={field}
                    validate={field.validate}
                />
            );
        }
        case "textField": {
            return (
                <Grid
                    item
                    xs={(field.width ? (field.width > 2 ? 2 : field.width) : 1) * 6}
                    md={(field.width ? (field.width > 4 ? 4 : field.width) : 1) * 3}
                    key={field.name}
                >
                    <Field
                        {...field}
                        name={field.name}
                        fullWidth={true}
                        component={renderTextField}
                        label={field.label}
                        type={field.type}
                        isRequired={isRequired}
                    />
                </Grid>
            );
        }
        case "text": {
            return (
                <Grid
                    item
                    xs={(field.width ? (field.width > 2 ? 2 : field.width) : 1) * 6}
                    md={(field.width ? (field.width > 4 ? 4 : field.width) : 1) * 3}
                    key={field.name}
                >
                    <Field
                        {...field}
                        name={field.name}
                        fullWidth={true}
                        component={renderFieldOrText}
                        label={field.label}
                        type={field.type}
                        readOnly={true}
                        isRequired={isRequired}
                    />
                </Grid>
            );
        }
        case "textFieldFlat":
        default: {
            return (
                <Grid
                    container
                    key={field.name}
                >
                    <Field
                        {...field}
                        name={field.name}
                        fullWidth={true}
                        component={renderTextFieldFlat}
                        label={field.label}
                        type={field.type}
                        isRequired={isRequired}
                    />
                </Grid>
            );
        }
        case "richTextField": {
            return (
                <Grid
                    item
                    xs={(field.fieldWidth ? (field.fieldWidth > 1 ? 1 : field.fieldWidth) : 1) * 12}
                    sm={(field.fieldWidth ? (field.fieldWidth > 1 ? 1 : field.fieldWidth) : 1) * 12}
                    md={(field.fieldWidth ? (field.fieldWidth > 1 ? 1 : field.fieldWidth) : 1) * 12}
                    key={field.name}
                >
                    <Field
                        {...field}
                        name={field.name}
                        fullfieldWidth={true}
                        component={renderRichTextField}
                        label={field.label}
                    />
                </Grid>
            );
        }
        case "select": {
            return (
                <Grid
                    item
                    xs={(field.width ? (field.width > 2 ? 2 : field.width) : 1) * 6}
                    md={(field.width ? (field.width > 4 ? 4 : field.width) : 1) * 3}
                    key={field.name}
                >
                    <Field
                        {...field}
                        name={field.name}
                        fullWidth={!field.showHistoryButton}
                        component={renderSelect}
                        label={field.label}
                        isRequired={isRequired}
                    />
                    {field.showHistoryButton && field.showHistory()}
                </Grid>
            );
        }
        case "autoComplete": {
            return (
                <Grid
                    item
                    xs={(field.width ? (field.width > 2 ? 2 : field.width) : 1) * 6}
                    md={(field.width ? (field.width > 4 ? 4 : field.width) : 1) * 3}
                    key={field.name}
                >
                    <Field
                        {...field}
                        name={field.name}
                        fullWidth={true}
                        component={RenderAutoComplete}
                        label={field.label}
                        isRequired={isRequired}
                    />
                </Grid>
            );
        }
        case "selectFlat": {
            return (
                <Grid
                    container
                    key={field.name}
                >
                    <Field
                        {...field}
                        name={field.name}
                        fullWidth={true}
                        component={renderSelectFlat}
                        label={field.label}
                        isRequired={isRequired}
                    />
                    {!field.disableEditing &&
                        field.name !== "Kraj pochodzenia" &&
                        field.editSelectComponent({
                            attribute: field.attribute,
                        })}
                </Grid>
            );
        }
        case "switch": {
            return (
                <Grid
                    item
                    xs={(field.width ? (field.width > 2 ? 2 : field.width) : 1) * 6}
                    md={(field.width ? (field.width > 6 ? 6 : field.width) : 1) * 2}
                    key={field.name}
                >
                    <Field
                        {...field}
                        name={field.name}
                        fullWidth={!field.showHistoryButton}
                        component={renderSwitch}
                        label={field.label}
                    />
                    {field.showHistoryButton && field.showHistory()}
                </Grid>
            );
        }
        case "color": {
            return (
                <>
                    <Grid
                        item
                        xs={(field.width ? (field.width > 6 ? 6 : field.width) : 1) * 2}
                        key={field.name}
                    >
                        <Field
                            {...field}
                            name={field.name}
                            fullWidth={true}
                            component={renderColor}
                            label={field.label}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={(field.width ? (field.width > 6 ? 6 : field.width) : 1) * 1}
                        key={field.name + "space"}
                    />
                </>
            );
        }
    }
}

const arrayComponent = ({ field, fields, meta }) => {
    const result = fields.map((sub, row) => (
        <FormSection
            name={sub}
            key={row}
        >
            <TableRow>
                {field.subComponents.map((x, i) => (
                    <React.Fragment key={i}>
                        {createArrayComponent(x, i, row, field.customLabels, field.specialAction, field.readOnlyRows, field.readOnly, field.disabled)}
                    </React.Fragment>
                ))}
                {(!field.readOnlyRows || !field.readOnlyRows[row]) && !field.readonly && !field.readOnly && !field.disabled && (
                    <TableCell>
                        <Fab
                            aria-label="Remove"
                            size={"small"}
                            color="primary"
                            onClick={() => {
                                fields.remove(row);
                                if (fields.length === 1 && field.change) {
                                    field.change(field.name, undefined);
                                }
                            }}
                            className="pull-right"
                        >
                            <Icon>remove</Icon>
                        </Fab>
                    </TableCell>
                )}
            </TableRow>
        </FormSection>
    ));
    return (
        <Table>
            {!field.disableHeader && (
                <TableHead>
                    <TableRow style={{ height: "4rem" }}>
                        <TableCell>{field.label || field.name}</TableCell>
                    </TableRow>
                </TableHead>
            )}
            <TableBody>
                <TableRow
                    style={{
                        height: !field.disableColumnHeader || meta?.warning || meta?.error ? "4rem" : "",
                    }}
                >
                    {!field.disableColumnHeader &&
                        field.subComponents.map((x, index) => {
                            const customLabel = field.customLabels.find((y) => x.name === y.name);
                            return (
                                <TableCell key={field.id && x.name ? `${field.id}_${x.name}` : index}>
                                    {renderFieldOrText({
                                        type: "text",
                                        fullWidth: true,
                                        input: {
                                            value: customLabel ? customLabel.label : x.label,
                                        },
                                        readOnly: !field.onLabelChange,
                                        onChange: (event) =>
                                            field.onLabelChange ? field.onLabelChange(field.id, x.name, event.target.value) : undefined,
                                    })}
                                </TableCell>
                            );
                        })}
                    <TableCell>
                        <ArrayValidationError {...meta} />
                    </TableCell>
                </TableRow>
                {result}
                <TableRow style={{ height: "4rem" }}>
                    <TableCell>
                        {!field.disableEditing &&
                            field.subComponents
                                .filter((x) => x.component === "select" || x.component === "selectFlat")
                                .map((x) =>
                                    x.editSelectComponent({
                                        attribute: field.attribute,
                                        className: "pull-right",
                                        subAttribute: x.name,
                                    })
                                )}
                        {!field.readonly && !field.readOnly && !field.disabled && (
                            <Fab
                                aria-label="Add"
                                size={"small"}
                                color="primary"
                                onClick={() => fields.push({})}
                                className="pull-left"
                            >
                                <Icon>add</Icon>
                            </Fab>
                        )}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

function createCombinedComponent(field, i) {
    const isRequired = field.validate && field.validate(undefined, { canModify: true }) !== false;

    switch (field.component) {
        case "textField": {
            return (
                <Field
                    {...field}
                    name={field.name}
                    component={renderTextField}
                    label={i === 0 && field.label}
                    type={field.type}
                    isRequired={isRequired}
                />
            );
        }
        case "textFieldFlat":
        default: {
            return (
                <Field
                    {...field}
                    name={field.name}
                    component={renderTextFieldFlat}
                    label={i === 0 && field.label}
                    type={field.type}
                    isRequired={isRequired}
                />
            );
        }
        case "richTextField": {
            return (
                <Field
                    {...field}
                    name={field.name}
                    component={renderRichTextField}
                    label={i === 0 && field.label}
                    isRequired={isRequired}
                />
            );
        }
        case "select": {
            return (
                <Field
                    {...field}
                    name={field.name}
                    component={renderSelect}
                    label={i === 0 && field.label}
                    isRequired={isRequired}
                />
            );
        }
        case "selectFlat": {
            return (
                <Field
                    {...field}
                    name={field.name}
                    component={renderSelectFlat}
                    label={i === 0 && field.label}
                    isRequired={isRequired}
                />
            );
        }
        case "switch": {
            return (
                <Field
                    {...field}
                    name={field.name}
                    component={renderSwitch}
                    label={i === 0 && field.label}
                    isRequired={isRequired}
                />
            );
        }
        case "color": {
            return (
                <Field
                    {...field}
                    name={field.name}
                    component={renderColor}
                    label={i === 0 && field.label}
                />
            );
        }
    }
}

function createArrayComponent(field, i, row, customLabels, specialAction, readOnlyRows, readOnly, disabled) {
    const fieldLabel = customLabels.find((x) => x.name === field.name);
    const fieldCustomLabel = fieldLabel ? fieldLabel.label : undefined;
    const isReadOnlyRow = readOnlyRows && readOnlyRows[row];
    const isRequired = field.validate && field.validate(undefined, { canModify: true }) !== false;
    switch (field.component) {
        case "textField": {
            return (
                <TableCell>
                    <Field
                        {...field}
                        readOnly={field.readonly || field.readOnly || readOnly || (isReadOnlyRow && readOnlyRows[row][field.name])}
                        onChange={specialAction ? specialAction(i, row) : undefined}
                        name={`${field.name}`}
                        component={renderTextField}
                        type={field.type}
                        label={fieldCustomLabel || field.label}
                        isRequired={isRequired}
                        disabled={disabled}
                    />
                </TableCell>
            );
        }
        case "textFieldFlat":
        default: {
            return (
                <TableCell>
                    <Field
                        {...field}
                        readOnly={field.readonly || field.readOnly || readOnly || (isReadOnlyRow && readOnlyRows[row][field.name])}
                        onChange={specialAction ? specialAction(i, row) : undefined}
                        name={`${field.name}`}
                        component={renderTextFieldFlat}
                        type={field.type}
                        label={fieldCustomLabel || field.label}
                        isRequired={isRequired}
                        disabled={disabled}
                    />
                </TableCell>
            );
        }
        case "richTextField": {
            return (
                <TableCell>
                    <Field
                        {...field}
                        readOnly={field.readonly || field.readOnly || readOnly || (isReadOnlyRow && readOnlyRows[row][field.name])}
                        onChange={specialAction ? specialAction(i, row) : undefined}
                        name={`${field.name}`}
                        component={renderRichTextField}
                        label={fieldCustomLabel || field.label}
                        isRequired={isRequired}
                        disabled={disabled}
                    />
                </TableCell>
            );
        }
        case "select": {
            return (
                <TableCell>
                    <Field
                        {...field}
                        items={field.items || field.dynamicItems(row)}
                        hidden={field.dynamicHidden && field.dynamicHidden(row)}
                        readOnly={field.readonly || field.readOnly || readOnly || (isReadOnlyRow && readOnlyRows[row][field.name])}
                        onChange={specialAction ? specialAction(i, row) : undefined}
                        onValueChange={field.onValueChange ? (value) => field.onValueChange(row, value) : undefined}
                        name={`${field.name}`}
                        component={renderSelect}
                        label={fieldCustomLabel || field.label}
                        isRequired={isRequired}
                        disabled={disabled}
                    />
                </TableCell>
            );
        }
        case "selectFlat": {
            return (
                <TableCell>
                    <Field
                        {...field}
                        readOnly={field.readonly || field.readOnly || readOnly || (isReadOnlyRow && readOnlyRows[row][field.name])}
                        onChange={specialAction ? specialAction(i, row) : undefined}
                        name={`${field.name}`}
                        component={renderSelectFlat}
                        label={fieldCustomLabel || field.label}
                        isRequired={isRequired}
                        disabled={disabled}
                    />
                </TableCell>
            );
        }
        case "switch": {
            return (
                <TableCell>
                    <Field
                        {...field}
                        readOnly={field.readonly || field.readOnly || readOnly || (isReadOnlyRow && readOnlyRows[row][field.name])}
                        onChange={specialAction ? specialAction(i, row) : undefined}
                        name={`${field.name}`}
                        component={renderSwitch}
                        label={fieldCustomLabel || field.label}
                        isRequired={isRequired}
                        disabled={disabled}
                    />
                </TableCell>
            );
        }
        case "color": {
            return (
                <TableCell>
                    <Field
                        {...field}
                        readOnly={field.readonly || field.readOnly || readOnly || (isReadOnlyRow && readOnlyRows[row][field.name])}
                        onChange={specialAction ? specialAction(i, row) : undefined}
                        name={`${field.name}`}
                        component={renderColor}
                        label={fieldCustomLabel || field.label}
                        isRequired={isRequired}
                        disabled={disabled}
                    />
                </TableCell>
            );
        }
    }
}
