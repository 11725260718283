import Button from "components/Button/Button";
import { FormField, ReadonlyContainer } from "components/Form";
import Layout from "components/Grid/Layout";
import userRoles from "consts/roles";
import { reduxForm } from "redux-form";
import { actions } from "store/dictonaries/procedureCategory/form/actions";
import { useMappedDispatch, useParams } from "utils/hooks";
import { defaultLenghtValidator } from "utils/validators/basic";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { common, roles } from "translations";
import { usePush } from "utils-ts/hooks";
import { useTranslation } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { procedureCategoryFormSelector } from "./selectors";

const validators = {
    procedureCategoryName: (value) => defaultLenghtValidator(value).validate(),
};

const ProcedureCategoryForm = ({ handleSubmit, isSubbmitRequested, isSystem, procedureCategoryName }) => {
    const { id } = useParams();
    const { initForm, createOrUpdate } = useMappedDispatch(actions);
    const { replace } = usePush();
    useEffect(() => {
        initForm(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    const { t } = useTranslation(["common", "roles"]);
    const submit = async (values) => {
        const { payload } = await createOrUpdate(values, id);
        if (!id) {
            replace(Paths.Dictionaries.ProcedureCategoryForm, { id: payload.id });
        }
    };

    return (
        <form onSubmit={handleSubmit((values) => submit(values))}>
            <Layout
                main
                headerText={t(common.procedureCategory, {
                    id: id || t(common.newItem),
                })}
                navigationProps={{
                    backProps: { path: Paths.Dictionaries.ProcedureCategoriesList },
                }}
                customTitle={id && procedureCategoryName && `${t(common.procedureCategory)} - ${procedureCategoryName}`}
            >
                <ReadonlyContainer showContainer={isSystem} />
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <FormField
                        name={"procedureCategoryName"}
                        type={"text"}
                        label={t(common.name)}
                        validate={validators.procedureCategoryName}
                        readonly={isSystem}
                    />
                    <FormField
                        name={"isActive"}
                        type={"boolean"}
                        label={t(common.isActive)}
                        readonly={isSystem}
                    />
                    <FormField
                        name={"assignedRoles"}
                        label={t(common.roles)}
                        type={"autocomplete"}
                        items={userRoles.all}
                        multiple={true}
                        freeSolo
                        disableCloseOnSelect={true}
                        getOptionLabel={(item) => t(`roles:${item}`)}
                    />
                </Grid>
            </Layout>

            <Button
                actionType="accept"
                type="submit"
                fullWidth
                loading={isSubbmitRequested}
                disabled={isSystem}
            >
                {t(common.save)}
            </Button>
        </form>
    );
};

const formName = "procedure-category-form";

const stateToProps = (state) => {
    const { isSubbmitRequested, isSystem, ...form } = procedureCategoryFormSelector(state);
    return {
        isSubbmitRequested,
        isSystem,
        procedureCategoryName: form.procedureCategoryName,
        initialValues: {
            ...form,
            isActive: form.isActive !== undefined ? form.isActive : true,
        },
    };
};

export default connect(stateToProps)(
    reduxForm({
        form: formName,
        enableReinitialize: true,
    })(ProcedureCategoryForm)
);
