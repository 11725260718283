import { makeStyles } from "tss-react/mui";
import { Grid, Icon, Typography } from "@mui/material";

const useErrorStyles = makeStyles()((theme) => ({
    progressStyle: {
        width: "100%",
    },
    center: {
        margin: 0,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    icon: {
        fontSize: "3.75rem",
        color: theme.palette.error.dark,
        textAlign: "center",
        verticalAign: "middle",
    },
    erroText: {
        textTransform: "uppercase ",
        textAlign: "center",
        verticalAign: "middle",
    },
}));

const Error = ({ height }) => {
    const { classes } = useErrorStyles();

    return (
        <div
            style={{ height }}
            className={classes.progressStyle}
        >
            <div className={classes.center}>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item>
                        <Icon className={classes.icon}>error</Icon>
                    </Grid>
                    <Grid item>
                        <Typography
                            className={classes.erroText}
                            variant="h4"
                        >
                            Błąd podczas ładowania dokumentu
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default Error;
