import {
    PurchaseAction,
    PurchaseOrder,
    PurchasePredicate,
    PurchasePreorder,
    PurchaseRule,
    PurchaseScheduleDocument,
} from "Commerce-Offer";
import { timeString } from "utils-ts/validations";
import { ExtendedValidator } from "utils-ts/validations/extendedValidator";

class PurchasePredicateValidator extends ExtendedValidator<PurchasePredicate> {
    constructor() {
        super();

        this.atLeastOneOfFieldNotEmpty([
            "deliveryDateFrom",
            "deliveryDateTo",
            "weekdays",
            "shifts",
            "isNonTradeSunday",
            "isMondayAfterNonTradeSunday",
            "isMorningAfterHoliday",
        ]);

        this.ruleFor("deliveryDateTo")
            .greaterThanOrEqualTo(
                "deliveryDateFrom"
            )
            .whenNotEmpty([
                "deliveryDateFrom",
                "deliveryDateTo",
            ]);
    }
}

const purchasePredicateValidator =
    new PurchasePredicateValidator();

class PurchasePreorderValidator extends ExtendedValidator<PurchasePreorder> {
    constructor() {
        super();

        this.ruleFor("dayOffset").notNull();

        this.ruleFor("timeOffset").must(
            timeString
        );
    }
}

const purchasePreorderValidator =
    new PurchasePreorderValidator();

class PurchaseOrderValidator extends ExtendedValidator<PurchaseOrder> {
    constructor() {
        super();

        this.ruleFor(
            "deliveryDayOffset"
        ).notNull();
    }
}

const purchaseOrderValidator =
    new PurchaseOrderValidator();

class PurchaseActionValidator extends ExtendedValidator<PurchaseAction> {
    constructor() {
        super();

        this.ruleFor("actionType").notEmpty();

        this.ruleFor("order")
            .notNull()
            .when(
                (o) =>
                    o.actionType === "Order" ||
                    o.actionType === "Preorder",
                "AppliesToCurrentValidator"
            )
            .setValidator(
                () => purchaseOrderValidator
            )
            .when(
                (o) =>
                    o.actionType === "Order" ||
                    o.actionType === "Preorder",
                "AppliesToCurrentValidator"
            )
            .null()
            .when(
                (o) => o.actionType === "Skip",
                "AppliesToCurrentValidator"
            );

        this.ruleFor("preorder")
            .notNull()
            .when(
                (o) =>
                    o.actionType === "Preorder",
                "AppliesToCurrentValidator"
            )
            .setValidator(
                () => purchasePreorderValidator
            )
            .when(
                (o) =>
                    o.actionType === "Preorder",
                "AppliesToCurrentValidator"
            )
            .null()
            .when(
                (o) =>
                    o.actionType === "Skip" ||
                    o.actionType === "Order",
                "AppliesToCurrentValidator"
            );
    }
}

const purchaseActionValidator =
    new PurchaseActionValidator();

class PurchaseRuleValidator extends ExtendedValidator<PurchaseRule> {
    constructor() {
        super();

        this.ruleFor("name")
            .notEmpty()
            .length(3, 250);

        this.ruleFor("action")
            .notNull()
            .setValidator(
                () => purchaseActionValidator
            );

        this.ruleForEach(
            "predicates"
        ).setValidator(
            purchasePredicateValidator
        );
    }
}

const purchaseRuleValidator =
    new PurchaseRuleValidator();

class PurchaseScheduleValidator extends ExtendedValidator<PurchaseScheduleDocument> {
    constructor() {
        super();

        this.ruleFor("warehouse").notEmpty();

        this.ruleFor("supplierId").notEmpty();

        this.ruleForEach("rules").setValidator(
            () => purchaseRuleValidator
        );
    }
}

export default PurchaseScheduleValidator;
