import {
    dangerCardHeader,
    infoCardHeader,
    primaryCardHeader,
    roseCardHeader,
    successCardHeader,
    warningCardHeader,
} from "assets/jss/material-dashboard-react";
import { makeStyles } from "tss-react/mui";

const useCardIconStyle = makeStyles()((_theme, _params, classes) => ({
    cardIcon: {
        [`&.${classes.warningCardHeader},&.${classes.successCardHeader},&.${classes.dangerCardHeader},&.${classes.infoCardHeader},&.${classes.primaryCardHeader},&.${classes.roseCardHeader}`]:
            {
                borderRadius: "3px",
                backgroundColor: "#999",
                padding: "15px",
                marginTop: "-20px",
                marginRight: "15px",
                float: "left",
            },
    },
    warningCardHeader,
    successCardHeader,
    dangerCardHeader,
    infoCardHeader,
    primaryCardHeader,
    roseCardHeader,
}));

export default useCardIconStyle;
