import { IconButton } from "components/Button";
import { useDebouncedCallback } from "use-debounce";
import React, { useEffect, useState } from "react";
import { Avatar, Grid, InputLabel, TextField } from "@mui/material";

const TextInputWithImage = ({ label, input: { value, ...input }, meta: { touched, invalid, error }, onAdd, item, ...rest }) => {
    const [inputValue, setInputValue] = useState("");
    const setFieldValue = useDebouncedCallback((event) => {
        const {
            target: { value: val },
        } = event;
        if (val) {
            input.onChange(val);
            if (onAdd) onAdd(val);
        }
    }, 750);
    useEffect(() => {
        setInputValue(value);
    }, [invalid, value]);
    const setInput = (event) => {
        const {
            target: { value: val },
        } = event;
        setInputValue(val);
    };

    const { text, imageUrl } = item;
    return value && !invalid ? (
        <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
        >
            <Grid
                item
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                <Grid item>
                    <InputLabel>{label}</InputLabel>
                </Grid>
            </Grid>
            <Grid
                item
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                <Grid item>
                    <Avatar
                        variant="square"
                        src={imageUrl}
                    ></Avatar>
                </Grid>
                <Grid item>{text}</Grid>
                <Grid item>
                    <IconButton
                        size="small"
                        icon={"clear"}
                        onClick={() => {
                            input.onChange(null);
                            if (rest.onInputClear) {
                                rest.onInputClear();
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
    ) : (
        <TextField
            fullWidth
            variant="standard"
            label={label}
            placeholder={label}
            onChange={(event) => {
                event.persist();
                setInput(event);
                setFieldValue(event);
            }}
            onBlur={(event) => {
                event.persist();
                setInput(event);
                setFieldValue(event);
            }}
            value={inputValue}
            error={touched && invalid}
            helperText={touched && error}
        />
    );
};

export default TextInputWithImage;
