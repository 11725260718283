import Cell from "components/Table/SimpleTable/Cell";
import Row from "components/Table/SimpleTable/Row";
import { FieldArray } from "redux-form";
import formatValue from "utils/formating/formatValue";
import React, { Fragment } from "react";
import AddIcon from "@mui/icons-material/KeyboardReturn";
import Fab from "@mui/material/Fab";
import _ from "lodash";
import RenderCorrectionLine from "./renderCorrectionLine";

function calculatePendingCorrections({ correctedItems = [], quantity = 0, realQuantity, packSize }) {
    const pendingQuantity = correctedItems.reduce(
        (c, n) => {
            var qty = n.correctQuantity;
            if (Number(_.isNumber(n.realQuantity))) {
                qty = n.realQuantity;
            }

            if (n.correctRealItems) {
                qty = n.correctRealItems / packSize;
            }

            if (!qty) {
                qty = 0;
            }

            return c - qty;
        },
        Number(_.isNumber(realQuantity) ? realQuantity : quantity)
    );
    if (Math.round(pendingQuantity * 100) / 100 < 0) return "BŁĄD";

    return Math.round(pendingQuantity * 100) / 100;
}

function pushCorrection(push, line, productLine) {
    push(`${line}.correctedItems`, {
        productId: productLine.productId,
        lineNumber: productLine.lineNumber,
        vat: productLine.vat,
        reasonCode: undefined,
    });
}

function renderProductRow({ array, columns }, productLine, line, index, editionDisabled, strippedRow) {
    const { push } = array;
    const { realQuantity, quantity, correctedItems } = productLine;
    const correctedItemsLength = correctedItems && correctedItems.length ? correctedItems.length : 0;
    const qty = _.isNumber(realQuantity) ? Math.round(realQuantity * 100) / 100 : quantity;
    const isCorrectionCapped = qty <= correctedItemsLength;

    return (
        <Row strippedRow={strippedRow}>
            {columns
                .filter((x) => x.type !== "action")
                .map((x) => {
                    if (x.key === "quantity") {
                        return (
                            <Cell
                                colSpan={x.colSpan}
                                key={`${x.key}${index}`}
                            >
                                {`${qty} (Pozostało: ${calculatePendingCorrections(productLine)})`}
                            </Cell>
                        );
                    }
                    return (
                        <Cell
                            colSpan={x.colSpan}
                            key={`${x.key}${index}`}
                        >
                            {formatValue(productLine[x.key], x.type, x.options)}
                        </Cell>
                    );
                })}
            <Cell>
                <Fab
                    className="pull-right"
                    style={{
                        display: editionDisabled ? "none" : "inherit",
                    }}
                    size="small"
                    color="primary"
                    disabled={editionDisabled || isCorrectionCapped}
                    onClick={() => {
                        pushCorrection(push, line, productLine);
                    }}
                >
                    <AddIcon />
                </Fab>
            </Cell>
        </Row>
    );
}

function rederCorrectionRow({ change, correctionTypes }, productLine, line, editionDisabled, strippedRow) {
    return (
        <FieldArray
            name={`${line}.correctedItems`}
            props={{
                productLine,
                correctionTypes,
                change,
                editionDisabled,
                strippedRow,
            }}
            component={RenderCorrectionLine}
        />
    );
}

function renderProductLine(props) {
    const { fields, editionDisabled } = props;
    const productLines = fields.getAll();
    return fields.map((line, index) => {
        const productLine = productLines[index];
        const strippedRow = index % 2 === 1;
        return (
            <Fragment key={line}>
                {renderProductRow(props, productLine, line, index, editionDisabled, strippedRow)}
                {rederCorrectionRow(props, productLine, line, editionDisabled, strippedRow)}
            </Fragment>
        );
    });
}

export default renderProductLine;
