import { Button } from "components/Button";
import SimpleTable, { createColumn } from "components/Table/SimpleTable/SimpleTable";
import tableActionTypes from "components/Table/SimpleTable/TableActions/tableActionTypes";
import { certyficateListSelector, findCertificates } from "store/vendors/certificates";
import { useDebouncedCallback } from "use-debounce";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useParams } from "utils/hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Icon } from "@mui/material";
import { vendor } from "translations";
import { usePush, useTranslation } from "utils-ts/hooks";
import { IsVendorReadOnly } from "views/vendors/IsVendorReadOnly";
import { Paths } from "routing-ts/ManagerPaths";

const Certificates = () => {
    const dispatch = useDispatch();
    const { id: vendorId } = useParams();
    const { t } = useTranslation("vendor");
    const { push } = usePush();
    const readOnly = IsVendorReadOnly();
    const { isLoading, items, ...pagination } = useSelector(certyficateListSelector);
    const trueFalseFilterOptions = [
        {
            value: true,
            name: "Tak",
        },
        {
            value: false,
            name: "Nie",
        },
    ];

    const [filter, setFilter] = useState({
        expirationDate: undefined,
        isForChemistry: undefined,
        isForGroceries: undefined,
    });

    useEffect(() => {
        dispatch(
            findCertificates({
                vendorId,
                pageSize: pagination.pageSize,
                pageIndex: pagination.pageIndex,
                params: filter,
            })
        );
    }, [dispatch, vendorId, filter]);

    const onRowClick = (item) => {
        push(Paths.Vendor.CertificateForm, {
            vendorId,
            certificateId: item.id,
        });
    };

    const columns = [
        createColumn("expirationDate", t(vendor.expirationDate), formatTypes.time, { timeFormat: timePrecisonFormats.days }, null, true),
        createColumn("certificateProvider", t(vendor.certificateProvider), formatTypes.text),
        createColumn(
            "isForChemistry",
            t(vendor.isForChemistry),
            formatTypes.boolean,
            {
                filterType: "select",
                filterItems: trueFalseFilterOptions,
            },
            null,
            true
        ),
        createColumn(
            "isForGroceries",
            t(vendor.isForGroceries),
            formatTypes.boolean,
            {
                filterType: "select",
                filterItems: trueFalseFilterOptions,
            },
            null,
            true
        ),
        createColumn(
            "action",
            "",
            formatTypes.action,
            {
                actionType: tableActionTypes.preview,
                resetButton: true,
            },
            onRowClick
        ),
    ];

    const handleFilter = useDebouncedCallback((column, value) => {
        if (column) {
            setFilter({ ...filter, [column]: value });
        } else {
            setFilter();
        }
    }, 1500);

    return (
        <SimpleTable
            tableActions={[
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Grid item>
                        {!readOnly && (
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                    push(Paths.Vendor.CertificateForm, {
                                        vendorId,
                                        certificateId: undefined,
                                    });
                                }}
                            >
                                <Icon>add</Icon>
                            </Button>
                        )}
                    </Grid>
                </Grid>,
            ].filter(Boolean)}
            isLoading={isLoading}
            columns={columns}
            data={items}
            onRowDoubleClick={(_, item) =>
                push(Paths.Vendor.CertificateForm, {
                    vendorId,
                    certificateId: item.id,
                })
            }
            shoudlHandleDoubleClick
            filterFunction={handleFilter}
            filterUndefinedColumns
            persistentFilter
            hover
            stripped
            pagination
            paginationProps={{
                ...pagination,
                onPageChange: (pageIndex) =>
                    dispatch(
                        findCertificates({
                            vendorId,
                            ...pagination,
                            pageIndex,
                            params: filter,
                        })
                    ),
                onRowsPerPageChange: (pageSize) =>
                    dispatch(
                        findCertificates({
                            vendorId,
                            pageIndex: 1,
                            pageSize,
                            params: filter,
                        })
                    ),
            }}
        />
    );
};

export default Certificates;
