import { generatePath } from "react-router-dom";
import { WorkstationAssignment, WorkstationDocument, WorkstationRequest, WorkstationResponse } from "Operations";
import { useCreateOrUpdateMutation, useGetPagedQuery, useGetQuery } from "api/hooks";

const url = (id?: string) => generatePath(`api/workstations/:id?`, { id: id ? id : null });

export const useFindWorkstations = (initialParams: WorkstationRequest) => {
    return useGetPagedQuery<WorkstationResponse, WorkstationRequest>({
        app: "operations",
        url: url(),
        queryParams: {
            ...initialParams,
        },
    });
};

export const useGetWorkstation = (id?: string) => {
    return useGetQuery<WorkstationDocument>({
        app: "operations",
        url: url(id),
        shouldMakeRequest: Boolean(id),
    });
};

export const useGetWorkstationByNumber = (number?: string) => {
    return useGetQuery<WorkstationDocument>({
        app: "operations",
        url: url() + `/number/${number}`,
        shouldMakeRequest: Boolean(number),
    });
};

export const useCreateOrUpdateWorkstation = (id?: string) => {
    return useCreateOrUpdateMutation<WorkstationDocument, WorkstationDocument>({ app: "operations", url: url(id), id: id });
};

export const useGetWorkstationAssignmentsHistory = (id?: string) => {
    return useGetQuery<WorkstationAssignment[]>({
        app: "operations",
        url: url(id) + "/assignments",
        shouldMakeRequest: Boolean(id),
    });
};
