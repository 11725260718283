import { createSelector } from "reselect";

const root = (state) => ({
    vendor: state.vendorPms,
});

export const vendorFormSelector = createSelector(root, (root) => {
    return root.vendor.vendorForm;
});

export const vendorListSelector = createSelector(root, (root) => {
    return root.vendor;
});
