import { makeStyles } from "tss-react/mui";
import React from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { AddIcCall } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { CustomerContactForm } from "views-ts/customerContact";

const useStyles = makeStyles()((theme) => ({
    contactButton: {
        position: "fixed",
        bottom: 100,
        right: 10,
        overflowY: "auto",
    },
    button: {
        "backgroundColor": theme.palette.secondary.main,
        "&:hover, &.Mui-focusVisible": { backgroundColor: theme.palette.secondary.light },
    },
}));

const CustomerContact: React.FC = () => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const { classes } = useStyles();
    const widgetContainerRef = React.useRef<HTMLInputElement>(null);
    const [widgetState, setWidgetState] = React.useState({
        visible: false,
        disabled: true,
        bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    });
    const height = 350;

    const onStart = (event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = widgetContainerRef?.current?.getBoundingClientRect();
        if (targetRect) {
            setWidgetState((prevState) => ({
                ...prevState,
                bounds: {
                    left: -targetRect?.left + uiData?.x,
                    right: clientWidth - (targetRect?.right - uiData?.x),
                    top: -targetRect?.top + uiData?.y,
                    bottom: clientHeight - (targetRect?.bottom - uiData?.y),
                },
            }));
        }
    };

    const minimizeFC = React.useMemo(() => () => setIsOpen(false), []);

    return isOpen ? (
        <div
            style={{
                position: "fixed",
                bottom: 40,
                right: 20,
                zIndex: "10",
                width: 800,
                height: height,
            }}
        >
            <Draggable
                bounds={widgetState.bounds}
                onStart={(event, uiData) => onStart(event, uiData)}
                cancel={"input"}
                enableUserSelectHack={false}
            >
                <div ref={widgetContainerRef}>
                    <CustomerContactForm
                        minimizeFunction={minimizeFC}
                        height={height}
                    />
                </div>
            </Draggable>
        </div>
    ) : (
        <div className={classes.contactButton}>
            <IconButton
                onClick={() => {
                    setIsOpen(true);
                }}
                className={classes.button}
            >
                <AddIcCall
                    fontSize="large"
                    sx={{ color: "white" }}
                />
            </IconButton>
        </div>
    );
};

export default CustomerContact;
