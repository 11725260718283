import { combineReducers } from "redux";
import { correctionRequestReport } from "./correctionRequestReport";
import { linkingReport } from "./crawlerLinkingReport";
import { subsidiaryGain } from "./subsidiaryGainReport";

export const reports = combineReducers({
    subsidiaryGain,
    linkingReport,
    correctionRequestReport,
});
