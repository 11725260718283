import { TreeItem } from "control-types";

export const WorkCodesTree: TreeItem[] = [
    {
        name: "Informacje o sklepie",
        level: 0,
        childrens: [
            {
                name: "Ogólne informacje",
                level: 1,
            },
            {
                name: "Logowanie i rejestracja",
                level: 1,
                childrens: [
                    {
                        name: "Zasady",
                        level: 2,
                        childrens: [
                            {
                                name: "Czy trzeba zakładac konto",
                                level: 3,
                            },
                            {
                                name: "Dane do założenia konta",
                                level: 3,
                            },
                            {
                                name: "Inne",
                                level: 3,
                            },
                        ],
                    },
                    {
                        name: "Problemy",
                        level: 2,
                        childrens: [
                            {
                                name: "Z logowaniem",
                                level: 3,
                            },
                            {
                                name: "Z założnieniem konta",
                                level: 3,
                            },
                            {
                                name: "Z weryfikacją adresu mailowego",
                                level: 3,
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        name: "Zamówienie",
        level: 0,
        childrens: [
            {
                name: "Zasady dostawy",
                level: 1,
                childrens: [
                    {
                        name: "Koszty dostawy",
                        level: 2,
                        childrens: [
                            {
                                name: "Pytanie",
                                level: 3,
                            },
                            {
                                name: "Skarga",
                                level: 3,
                            },
                        ],
                    },
                    {
                        name: "Minimalna wartość zamówienia ",
                        level: 2,
                        childrens: [
                            {
                                name: "Pytanie",
                                level: 3,
                            },
                            {
                                name: "Skarga",
                                level: 3,
                            },
                        ],
                    },
                    {
                        name: "Obszar dostaw/Rodzaj dostawy (VAN, DPD)",
                        level: 2,
                        childrens: [
                            {
                                name: "Pytanie",
                                level: 3,
                            },
                            {
                                name: "Skarga",
                                level: 3,
                            },
                            {
                                name: "Pytanie o możliwość rozszeżenia",
                                level: 3,
                            },
                        ],
                    },
                    {
                        name: "Koszty pakowania",
                        level: 2,
                        childrens: [
                            {
                                name: "Pytanie",
                                level: 3,
                            },
                            {
                                name: "Skarga",
                                level: 3,
                            },
                        ],
                    },
                    {
                        name: "Terminy dostaw, okna",
                        level: 2,
                        childrens: [
                            {
                                name: "Zasady działania",
                                level: 3,
                            },
                            {
                                name: "Dostepność",
                                level: 3,
                            },
                            {
                                name: "Rezerwacja terminu",
                                level: 3,
                            },
                            {
                                name: "Uwagi",
                                level: 3,
                            },
                        ],
                    },
                ],
            },
            {
                name: "B2B",
                level: 1,
                childrens: [
                    {
                        name: "Zapytanie o ofertę dla B2B",
                        level: 2,
                    },
                    {
                        name: "Przekazanie do działu B2B",
                        level: 2,
                    },
                ],
            },
            {
                name: "Płatności",
                level: 1,
                childrens: [
                    {
                        name: "Dostępne formy płatności",
                        level: 2,
                    },
                    {
                        name: "Płatności i rozliczenia na koncie",
                        level: 2,
                        childrens: [
                            {
                                name: "Wyjaśnienie",
                                level: 3,
                            },
                            {
                                name: "Windykacja",
                                level: 3,
                            },
                            {
                                name: "Zwrot",
                                level: 3,
                            },
                        ],
                    },
                ],
            },
            {
                name: "Faktura",
                level: 1,
                childrens: [
                    {
                        name: "Korekta danych na fakturze",
                        level: 2,
                    },
                    {
                        name: "Faktura korygująca / nota",
                        level: 2,
                    },
                    {
                        name: "Wysłanie faktury",
                        level: 2,
                    },
                    {
                        name: "Zapytanie o wystawienie FV na część zakupów",
                        level: 2,
                    },
                    {
                        name: "Pytanie o FV/paragon",
                        level: 2,
                    },
                ],
            },
            {
                name: "Obługa zamówienia",
                level: 1,
                childrens: [
                    {
                        name: "Zmiana listy produktów",
                        level: 2,
                    },
                    {
                        name: "Zmiana terminu",
                        level: 2,
                    },
                    {
                        name: "Zmiana adresu",
                        level: 2,
                    },
                    {
                        name: "Anulowanie",
                        level: 2,
                    },
                    {
                        name: "Wskazówki dla kierowcy",
                        level: 2,
                    },
                    {
                        name: "Złożenie zamówienia",
                        level: 2,
                    },
                    {
                        name: "Zapytanie o godzinę przyjazdu kierowcy",
                        level: 2,
                    },
                    {
                        name: "Niedziałająca winda - ustalenie z logistyką",
                        level: 2,
                    },
                    {
                        name: "Zmiana danych na koncie",
                        level: 2,
                    },
                    {
                        name: "Brak Klienta",
                        level: 2,
                    },
                    {
                        name: "Ogólne informacje o swoim zamówieniu",
                        level: 2,
                    },
                    {
                        name: "Weryfikacja listy zakupów",
                        level: 2,
                    },
                ],
            },
            {
                name: "Ogólna opinia o sklepie",
                level: 1,
                childrens: [
                    {
                        name: "Pozytywna",
                        level: 2,
                    },
                    {
                        name: "Negatywna",
                        level: 2,
                    },
                ],
            },
        ],
    },
    {
        name: "Reklamacja",
        level: 0,
        childrens: [
            {
                name: "Jakość",
                level: 1,
                childrens: [
                    {
                        name: "Pleśń/gnicie",
                        level: 2,
                        childrens: [
                            {
                                name: "Zwrot",
                                level: 3,
                            },
                            {
                                name: "Dowózka",
                                level: 3,
                            },
                        ],
                    },
                    {
                        name: "Klasa",
                        level: 2,
                        childrens: [
                            {
                                name: "Zwrot",
                                level: 3,
                            },
                            {
                                name: "Dowózka",
                                level: 3,
                            },
                        ],
                    },
                    {
                        name: "Rozmrożenie",
                        level: 2,
                        childrens: [
                            {
                                name: "Zwrot",
                                level: 3,
                            },
                            {
                                name: "Dowózka",
                                level: 3,
                            },
                        ],
                    },
                    {
                        name: "Przeterminowanie",
                        level: 2,
                        childrens: [
                            {
                                name: "Zwrot",
                                level: 3,
                            },
                            {
                                name: "Dowózka",
                                level: 3,
                            },
                        ],
                    },
                    {
                        name: "Wycofana partia produktów",
                        level: 2,
                        childrens: [
                            {
                                name: "Zwrot",
                                level: 3,
                            },
                            {
                                name: "Dowózka",
                                level: 3,
                            },
                        ],
                    },
                ],
            },
            {
                name: "Brak",
                level: 1,
                childrens: [
                    {
                        name: "Brak cały produkt ",
                        level: 2,
                        childrens: [
                            {
                                name: "Zwrot",
                                level: 3,
                            },
                            {
                                name: "Dowózka",
                                level: 3,
                            },
                        ],
                    },
                    {
                        name: "Sztuka",
                        level: 2,
                        childrens: [
                            {
                                name: "Zwrot",
                                level: 3,
                            },
                            {
                                name: "Dowózka",
                                level: 3,
                            },
                        ],
                    },
                    {
                        name: "Brak gratisu/sampla",
                        level: 2,
                        childrens: [
                            {
                                name: "Odłożenie",
                                level: 3,
                            },
                            {
                                name: "Voucher",
                                level: 3,
                            },
                        ],
                    },
                ],
            },
            {
                name: "Uszkodzenie",
                level: 1,
                childrens: [
                    {
                        name: "Dowózka ",
                        level: 2,
                    },
                    {
                        name: "Zwrot",
                        level: 2,
                    },
                ],
            },
            {
                name: "Niezgodność z zamówieniem",
                level: 1,
                childrens: [
                    {
                        name: "Dowózka ",
                        level: 2,
                    },
                    {
                        name: "Zwrot",
                        level: 2,
                    },
                ],
            },
            {
                name: "Inne",
                level: 1,
                childrens: [
                    {
                        name: "Zbyt krótki termin",
                        level: 2,
                        childrens: [
                            {
                                name: "Zwrot",
                                level: 3,
                            },
                            {
                                name: "Dowózka",
                                level: 3,
                            },
                        ],
                    },
                    {
                        name: "Produkt niezgodny z opisem",
                        level: 2,
                        childrens: [
                            {
                                name: "Zwrot",
                                level: 3,
                            },
                            {
                                name: "Dowózka",
                                level: 3,
                            },
                        ],
                    },
                    {
                        name: "Zła gramatura",
                        level: 2,
                        childrens: [
                            {
                                name: "Zwrot",
                                level: 3,
                            },
                            {
                                name: "Dowózka",
                                level: 3,
                            },
                        ],
                    },
                    {
                        name: "Błąd na etykiecie",
                        level: 2,
                        childrens: [
                            {
                                name: "Zwrot",
                                level: 3,
                            },
                            {
                                name: "Dowózka",
                                level: 3,
                            },
                        ],
                    },
                ],
            },
            {
                name: "Rezygnacja klienta",
                level: 1,
                childrens: [
                    {
                        name: "Zwrot i odbiór/odesłanie przez Klienta",
                        level: 2,
                    },
                ],
            },
            {
                name: "Nadprogramowe produkty",
                level: 1,
                childrens: [
                    {
                        name: "Odbiór",
                        level: 2,
                    },
                    {
                        name: "Zakup",
                        level: 2,
                    },
                    {
                        name: "Gratis",
                        level: 2,
                    },
                    {
                        name: "Zwrot",
                        level: 2,
                    },
                ],
            },
            {
                name: "Koszt pakowania - inne torby",
                level: 1,
            },
            {
                name: "Koszt dostawy",
                level: 1,
            },
            {
                name: "Braki magazynowe",
                level: 1,
                childrens: [
                    {
                        name: "Kupon",
                        level: 2,
                    },
                    {
                        name: "Brak kuponu",
                        level: 2,
                    },
                    {
                        name: "Dowózka",
                        level: 2,
                    },
                ],
            },
            {
                name: "Ogólna uwaga do produktu",
                level: 1,
                childrens: [
                    {
                        name: "Uwaga do wielkości",
                        level: 2,
                    },
                    {
                        name: "Uwaga do wyglądu",
                        level: 2,
                    },
                    {
                        name: "Uwaga do terminu przydatności",
                        level: 2,
                    },
                ],
            },
        ],
    },
    {
        name: "Dostawa/kierowcy",
        level: 0,
        childrens: [
            {
                name: "Wcześniejsza dostawa",
                level: 1,
                childrens: [
                    {
                        name: "Pochwała",
                        level: 2,
                    },
                    {
                        name: "Próba wymuszenia WW",
                        level: 2,
                    },
                    {
                        name: "Zbyt wczesny kontakt w sprawie WW",
                        level: 2,
                    },
                    {
                        name: "WW bez zgody",
                        level: 2,
                    },
                    {
                        name: "WW bez kontaktu kierowcy",
                        level: 2,
                    },
                ],
            },
            {
                name: "Spóźnienie",
                level: 1,
                childrens: [
                    {
                        name: "Skarga",
                        level: 2,
                    },
                    {
                        name: "brak informacji",
                        level: 2,
                    },
                    {
                        name: "Błędnie oszacowany czas spóźnienia",
                        level: 2,
                    },
                    {
                        name: "Powtarzające się spóźnienia",
                        level: 2,
                    },
                ],
            },
            {
                name: "Skarga na kierowcę",
                level: 1,
                childrens: [
                    {
                        name: "Nieuprzejmie zachowanie",
                        level: 2,
                    },
                    {
                        name: "Kierowca zostawił zakupy bez wiedzy Klienta",
                        level: 2,
                    },
                    {
                        name: "Kierowca pomylił adres dostawy",
                        level: 2,
                    },
                    {
                        name: "Szkody wyrządzone przez kierowcę",
                        level: 2,
                    },
                    {
                        name: "Zbyt głośne zachowanie kierowcy",
                        level: 2,
                    },
                    {
                        name: "Brak stroju",
                        level: 2,
                    },
                    {
                        name: "Niestosowanie się do notatek dotyczących zamówienia",
                        level: 2,
                    },
                    {
                        name: "Nieestetyczny wygląd kierowcy",
                        level: 2,
                    },
                    {
                        name: "Nieprzepisowa jazda",
                        level: 2,
                    },
                    {
                        name: "Nieprzypisowe parkowanie",
                        level: 2,
                    },
                    {
                        name: "Problemy komunikacyjne (brak całkwowitej znajomości języka)",
                        level: 2,
                    },
                    {
                        name: "Odmowa wniesienia zakupów",
                        level: 2,
                    },
                    {
                        name: "Brak zwrotów grzecznościowych",
                        level: 2,
                    },
                ],
            },
            {
                name: "Brak spóźnienia - klient pomylił się",
                level: 1,
            },
            {
                name: "WW - klient pomylił się",
                level: 1,
            },
            {
                name: "Ogólna pochwała dla kierowców",
                level: 1,
            },
            {
                name: "Pochwała zachowania kierowcy ",
                level: 1,
            },
            {
                name: "Pochwała punktualności",
                level: 1,
            },
            {
                name: "Skarga na realizację dostawy przez DPD",
                level: 1,
                childrens: [
                    {
                        name: "Terminy",
                        level: 2,
                    },
                    {
                        name: "Reklamacje",
                        level: 2,
                    },
                ],
            },
        ],
    },
    {
        name: "Vouchery",
        level: 0,
        childrens: [
            {
                name: "Vouchery",
                level: 1,
                childrens: [
                    {
                        name: "BOK",
                        level: 2,
                        childrens: [
                            {
                                name: "Sposób użycia",
                                level: 3,
                            },
                            {
                                name: "Problem z dodaniem",
                                level: 3,
                            },
                            {
                                name: "Przedłużenie ważności wina Frisco",
                                level: 3,
                            },
                            {
                                name: "Przedłużenie wina Klienta",
                                level: 3,
                            },
                            {
                                name: "Przyznanie kuponu",
                                level: 3,
                            },
                            {
                                name: "Pytanie kiedy będą wysyłane kupony",
                                level: 3,
                            },
                        ],
                    },
                    {
                        name: "B2B",
                        level: 2,
                        childrens: [
                            {
                                name: "Sposób użycia",
                                level: 3,
                            },
                            {
                                name: "Problem z dodaniem",
                                level: 3,
                            },
                            {
                                name: "Przedłużenie ważności wina Frisco",
                                level: 3,
                            },
                            {
                                name: "Przedłużenie wina Klienta",
                                level: 3,
                            },
                            {
                                name: "Przyznanie kuponu",
                                level: 3,
                            },
                            {
                                name: "Pytanie kiedy będą wysyłane kupony",
                                level: 3,
                            },
                            {
                                name: "Uwagi do atrakcyjności benefitów - stali Klienci",
                                level: 3,
                            },
                        ],
                    },
                    {
                        name: "Marketing",
                        level: 2,
                        childrens: [
                            {
                                name: "Sposób użycia",
                                level: 3,
                            },
                            {
                                name: "Problem z dodaniem",
                                level: 3,
                            },
                            {
                                name: "Przedłużenie ważności wina Frisco",
                                level: 3,
                            },
                            {
                                name: "Przedłużenie wina Klienta",
                                level: 3,
                            },
                            {
                                name: "Przyznanie kuponu",
                                level: 3,
                            },
                            {
                                name: "Pytanie kiedy będą wysyłane kupony",
                                level: 3,
                            },
                            {
                                name: "Uwagi do atrakcyjności benefitów - stali Klienci",
                                level: 3,
                            },
                        ],
                    },
                    {
                        name: "Kaucja",
                        level: 2,
                        childrens: [
                            {
                                name: "Wygenerowanie kuponu",
                                level: 3,
                            },
                            {
                                name: "Brak realizacji odbioru opakowań szklanych - uwagi",
                                level: 3,
                            },
                            {
                                name: "Pytanie o możliwość odbioru butelek",
                                level: 3,
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        name: "Kompletacja",
        level: 0,
        childrens: [
            {
                name: "Torby",
                level: 1,
                childrens: [
                    {
                        name: "Uwagi do dużej ilości toreb",
                        level: 2,
                    },
                    {
                        name: "Uwaga do małej ilość toreb",
                        level: 2,
                    },
                    {
                        name: "Sugestie do pakowania",
                        level: 2,
                    },
                    {
                        name: "Dużo folii na produktach świeżych - uwagi",
                        level: 2,
                    },
                    {
                        name: "Pochwała na zminimalizowanie folii na świeżych",
                        level: 2,
                    },
                    {
                        name: "Brak realizacji odbioru toreb - uwagi",
                        level: 2,
                    },
                    {
                        name: "Pytanie o możliwość odbioru toreb",
                        level: 2,
                    },
                ],
            },
            {
                name: "Sposób pakowania",
                level: 1,
                childrens: [
                    {
                        name: "Brak segregacji kategoriami",
                        level: 2,
                    },
                    {
                        name: "Nieracjonalne pakowania (np. ciężkie na lekkie)",
                        level: 2,
                    },
                    {
                        name: "Pochwała",
                        level: 2,
                    },
                    {
                        name: "Pogniecione produkty",
                        level: 2,
                    },
                ],
            },
            {
                name: "Opakowania zbiorcze - produkt luzem",
                level: 1,
            },
        ],
    },
    {
        name: "Asortyment",
        level: 0,
        childrens: [
            {
                name: "Pytania o dostępność produktów",
                level: 1,
            },
            {
                name: "Sugestie do asortymentu",
                level: 1,
                childrens: [
                    {
                        name: "Inne - komentarz",
                        level: 2,
                    },
                ],
            },
            {
                name: "Pytania o skład/opis/szatę graficzną produktów",
                level: 1,
            },
            {
                name: "Pytania o wycofane produkty",
                level: 1,
            },
            {
                name: "Pytania o promocje ",
                level: 1,
            },
            {
                name: "Oferta/propozycja współpracy",
                level: 1,
            },
            {
                name: "Wysokie ceny",
                level: 1,
            },
            {
                name: "Pochwała - nieskie ceny",
                level: 1,
            },
            {
                name: "Pochwała do asortymentu",
                level: 1,
            },
            {
                name: "Pochwała do jakości",
                level: 1,
            },
        ],
    },
    {
        name: "Program lojalnościowy",
        level: 0,
        childrens: [
            {
                name: "Ogólne informacje",
                level: 1,
                childrens: [
                    {
                        name: "Pytanie o dołączenie",
                        level: 2,
                    },
                    {
                        name: "Benfity",
                        level: 2,
                    },
                ],
            },
            {
                name: "Poblem z działaniem programu",
                level: 1,
            },
            {
                name: "Sugestie do działania programu",
                level: 1,
            },
            {
                name: "Uwagi do działania programu",
                level: 1,
            },
            {
                name: "Brak nagrody/prezentu",
                level: 1,
                childrens: [
                    {
                        name: "Odłożenie",
                        level: 2,
                    },
                    {
                        name: "Zwrot punktów",
                        level: 2,
                    },
                ],
            },
        ],
    },
    {
        name: "Obsługa BOK",
        level: 0,
        childrens: [
            {
                name: "Pochwała",
                level: 1,
            },
            {
                name: "Uwagi , skargi ",
                level: 1,
            },
        ],
    },
    {
        name: "Działanie WWW",
        level: 0,
        childrens: [
            {
                name: "Strona nie działa",
                level: 1,
            },
            {
                name: "Problem z wyszukiwarką ",
                level: 1,
            },
            {
                name: "Problemy z płatnością na stronie",
                level: 1,
            },
            {
                name: "Problem z panelem rezerwacji",
                level: 1,
            },
            {
                name: "Sugestia",
                level: 1,
            },
            {
                name: "Pochwała",
                level: 1,
            },
            {
                name: "Problem z działaniem strony",
                level: 1,
            },
        ],
    },
    {
        name: "Działanie aplikacji ",
        level: 0,
        childrens: [
            {
                name: "Problemy z działaniem aplikacji",
                level: 1,
            },
            {
                name: "Problem z wyszukiwarką ",
                level: 1,
            },
            {
                name: "Problemy z płatnością w aplikacji",
                level: 1,
            },
            {
                name: "Nie działa aplikacja",
                level: 1,
            },
            {
                name: "Problem z panelem rezerwacji",
                level: 1,
            },
            {
                name: "Sugestia",
                level: 1,
            },
            {
                name: "Pochwała",
                level: 1,
            },
        ],
    },
    {
        name: "Zgody marketingowe, żądania",
        level: 0,
        childrens: [
            {
                name: "Pytanie o miejsce wyrażania i odznaczania zgód",
                level: 1,
            },
            {
                name: "Zapytanie o działanie zgód",
                level: 1,
            },
            {
                name: "Żądanie usunięcie konta i danych",
                level: 1,
            },
            {
                name: "Żądanie usunięcia zgód",
                level: 1,
            },
            {
                name: "Pytanie, gdzie samodzielnie można usunąć konto",
                level: 1,
            },
        ],
    },
    {
        name: "Konkurencja",
        level: 0,
        childrens: [
            {
                name: "Pochwały",
                level: 1,
                childrens: [
                    {
                        name: "Taniej niż w:",
                        level: 2,
                        childrens: [
                            {
                                name: "Stacjonarne",
                                level: 3,
                            },
                            {
                                name: "Barbora",
                                level: 3,
                            },
                            {
                                name: "Auchan Direct",
                                level: 3,
                            },
                            {
                                name: "Hipernet24",
                                level: 3,
                            },
                            {
                                name: "eCarrefour",
                                level: 3,
                            },
                        ],
                    },
                ],
            },
            {
                name: "Uwagi",
                level: 1,
                childrens: [
                    {
                        name: "Za drogo w porównaniu do:",
                        level: 2,
                        childrens: [
                            {
                                name: "Stacjonarne",
                                level: 3,
                            },
                            {
                                name: "Barbora",
                                level: 3,
                            },
                            {
                                name: "Auchan Direct",
                                level: 3,
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        name: "Retencja",
        level: 0,
        childrens: [
            {
                name: "Brak zakupów",
                level: 1,
                childrens: [
                    {
                        name: "Przeprowadzka",
                        level: 2,
                    },
                    {
                        name: "Wysokie ceny",
                        level: 2,
                    },
                    {
                        name: "Spóźnienia",
                        level: 2,
                    },
                    {
                        name: "Częste wyjazdy",
                        level: 2,
                    },
                    {
                        name: "Brak zniżek/voucherów",
                        level: 2,
                    },
                    {
                        name: "Braki w asortymencie",
                        level: 2,
                    },
                    {
                        name: "Zakupy stacjonarne",
                        level: 2,
                    },
                    {
                        name: "Catering",
                        level: 2,
                    },
                    {
                        name: "Inne konto",
                        level: 2,
                    },
                    {
                        name: "Brak powodu",
                        level: 2,
                    },
                    {
                        name: "Brak terminów",
                        level: 2,
                    },
                    {
                        name: "Brak dostaw na już",
                        level: 2,
                    },
                    {
                        name: "Zła jakośc towaru",
                        level: 2,
                    },
                    {
                        name: "Zakupy u konkurencji online",
                        level: 2,
                    },
                    {
                        name: "Zbyt duża ilość plastiku",
                        level: 2,
                    },
                    {
                        name: "Minimalna wartość zamówienia",
                        level: 2,
                    },
                    {
                        name: "Wysokie koszty dostaw",
                        level: 2,
                    },
                    {
                        name: "Brak czasu",
                        level: 2,
                    },
                    {
                        name: "Brak płatności gotówką",
                        level: 2,
                    },
                    {
                        name: "Wymaganie rejestracji",
                        level: 2,
                    },
                    {
                        name: "Twierdzi, że nie zakładał konta",
                        level: 2,
                    },
                    {
                        name: "Brak środków na koncie",
                        level: 2,
                    },
                    {
                        name: "Zakupy planowane w późniejszym terminie",
                        level: 2,
                    },
                    {
                        name: "Zapoznanie się z asortymentem",
                        level: 2,
                    },
                    {
                        name: "Limit na produkty (woda)",
                        level: 2,
                    },
                ],
            },
        ],
    },
];
