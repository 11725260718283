import { styles } from "assets/jss/common";
import { renderTextField } from "components/FormHelpers/Controls";
import { Field, reduxForm } from "redux-form";
import { updateFullName } from "store/identity/userDetails/actions";
import { withStyles } from "tss-react/mui";
import { isEqual } from "utils/comparers";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import classNames from "classnames";
import { administration, common } from "translations";

const internalStyles = (theme) => ({
    progress: {
        marginRight: theme.spacing(2),
    },
});

export const validate = (values) => {
    const errors = {};
    const requiredFields = ["firstName", "lastName"];
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = "Required";
        }
    });

    return errors;
};

const ChangeUserFullName = (props) => {
    const { update, invalid, pristine, submitting, t, modelChanged, fieldValues } = props;
    const classes = withStyles.getClasses(props);

    return (
        <form>
            <Grid
                container
                spacing={4}
            >
                <Grid
                    item
                    xs={6}
                    md={3}
                >
                    <Field
                        name="firstName"
                        fullWidth={true}
                        component={renderTextField}
                        label={t(`common:${common.firstName}`)}
                        readOnly={!props.isAdmin}
                    />
                </Grid>
                <Grid
                    item
                    xs={6}
                    md={3}
                >
                    <Field
                        name="lastName"
                        fullWidth={true}
                        component={renderTextField}
                        label={t(`common:${common.lastName}`)}
                        readOnly={!props.isAdmin}
                    />
                </Grid>
                {!modelChanged ? undefined : (
                    <Grid
                        item
                        xs={12}
                        md={6}
                    >
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={invalid || pristine}
                            onClick={
                                submitting
                                    ? undefined
                                    : () => {
                                          update(fieldValues);
                                      }
                            }
                        >
                            {submitting === true ? (
                                <CircularProgress
                                    className={classes.progress}
                                    size={15}
                                />
                            ) : (
                                <SaveIcon className={classNames(styles.leftIcon, styles.iconSmall)} />
                            )}
                            {t(administration.update)}
                        </Button>
                    </Grid>
                )}
            </Grid>
        </form>
    );
};

export default connect(
    (state) => {
        const initialValues = {};
        if (state.userDetails && state.userDetails.user && state.userDetails.user.fullName) {
            const { firstName, lastName } = state.userDetails.user.fullName;
            initialValues.userId = state.userDetails.user.userId;
            initialValues.firstName = firstName;
            initialValues.lastName = lastName;
        }

        let modelChanged = false;
        let fieldValues = null;
        if (state.form && state.form.ChangeUserFullName) {
            const { initial, values } = state.form.ChangeUserFullName;
            modelChanged = !isEqual(initial, values);
            fieldValues = values;
        }

        return {
            initialValues,
            modelChanged,
            fieldValues,
        };
    },
    (dispatch) => {
        return {
            update: ({ userId, firstName, lastName }) => {
                dispatch(updateFullName(userId, firstName, lastName));
            },
        };
    }
)(
    reduxForm({
        form: "ChangeUserFullName",
        enableReinitialize: true,
        validate,
    })(withTranslation(["administration", "common"])(withStyles(ChangeUserFullName, internalStyles)))
);
