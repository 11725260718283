import { manager, operations } from "store/actionTypePrefixes";
import { AsyncTypes, asAsyncFormTypes, createAction } from "utils/extensions";

const types = {
    findAnalyticGroups: new AsyncTypes("FIND_ANALYTIC_GROUPS", operations),
    changeQueryParams: createAction("CHANGE_ANALYTIC_GROUPS_QUERY_PARAMS", manager),
    getAnalyticGroup: new AsyncTypes("GET_ANALYTIC_GROUP", operations),
    updateAnalyticGroupCategoryManager: new AsyncTypes("UPDATE_ANALYTIC_GROUP_CATEGORY_MANAGER", operations, [asAsyncFormTypes]),
};

export default types;
