import { ControlLabel } from "components/Controls";
import Layout from "components/Grid/Layout";
import Preloader from "components/Preloader/Preloader";
import TabLabel from "components/Tabs/TabLabel";
import Tabs from "components/Tabs/Tabs";
import roles from "consts/roles";
import warehouses from "resource/warehouses.json";
import { getAnalyticGroups } from "store/autocomplete";
import { cleanUp, getProductPreview } from "store/offers/productPreview/product/action";
import { productProductPreviewSelector } from "store/offers/selectors";
import { makeStyles } from "tss-react/mui";
import { timePrecisonFormats } from "utils/formating";
import { useCommonTranslation, useParams } from "utils/hooks";
import { Fragment, useEffect, useState } from "react";
import { JsonView, allExpanded, darkStyles } from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, InputLabel, MenuItem, Select as MuiSelect } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";
import { useUser } from "context";
import { readOfferProductRoles, readOfferRoles } from "routing-ts/Routes/OffersRoles";
import {
    ProductBase,
    ProductCategories,
    ProductPriceHistory,
    ProductProblems,
    ProductPromotions,
    ProductRations,
    ProductRestrictions,
    ProductVariants,
} from "./components";
import ProductInternalData from "./components/ProductInternalData";

const useStyles = makeStyles()((theme) => ({
    dateField: {
        margin: theme.spacing(1),
    },
}));

const ProductPreview = () => {
    const { isInAnyRoleOrAdmin } = useUser();
    const { classes } = useStyles();
    const [overrideDateTime, setDate] = useState(moment().startOf("day"));
    const [warehouse, setWarehouse] = useState("WAW");
    const { id } = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getProductPreview(id, overrideDateTime.format(), warehouse));

        return () => {
            dispatch(cleanUp());
        };
    }, [dispatch, id, overrideDateTime, warehouse]);
    useEffect(() => {
        dispatch(getAnalyticGroups());
    }, [dispatch]);

    const { t, common } = useCommonTranslation();
    const product = useSelector(productProductPreviewSelector);
    const { imageUrl, promotions = [], categories = [], restrictions = [], variants = [], rations = [], problems = [], isLoading } = product;

    const tabLabels = [
        new TabLabel(t(common.productBase), readOfferProductRoles),
        new TabLabel(t(common.productInternal), readOfferRoles),
        new TabLabel(`${t(common.productVariants)} (${variants.length})`, readOfferRoles),
        new TabLabel(`${t(common.categories)} (${categories.length})`, readOfferRoles),
        new TabLabel(`${t(common.promotions)} (${promotions.length})`, readOfferRoles),
        new TabLabel(`${t(common.productRestrictions)} (${restrictions.length})`, readOfferRoles),
        new TabLabel(`${t(common.rations)} (${rations.length})`, readOfferRoles),
        new TabLabel(`${t(common.priceHistory)}`, readOfferRoles),
        new TabLabel(`${t(common.problems)} (${problems.length})`, readOfferRoles),
        new TabLabel("json", [roles.Administrator]),
    ];

    if (isLoading) return <Preloader />;

    return (
        <Layout
            main
            headerText={t(common.product, { id })}
        >
            {isLoading ? (
                <Preloader />
            ) : (
                <Fragment>
                    <DateTimePicker
                        slotProps={{ textField: { variant: "standard" } }}
                        className={classes.dateField}
                        label={t(common.fromDateTime)}
                        format={timePrecisonFormats.seconds}
                        value={overrideDateTime}
                        onChange={setDate}
                        ampm={false}
                        ampmInClock={false}
                    />
                    <FormControl className={classes.dateField}>
                        <InputLabel>
                            <ControlLabel label={t(common.warehouse)} />
                        </InputLabel>
                        <MuiSelect
                            variant="standard"
                            label={t(common.warehouse)}
                            value={warehouse}
                            onChange={(event) => {
                                setWarehouse(event.target.value);
                            }}
                        >
                            {warehouses
                                .filter((x) => x.value !== "OMU")
                                .map((x) => (
                                    <MenuItem
                                        key={x.value}
                                        value={x.value}
                                    >
                                        {x.name}
                                    </MenuItem>
                                ))}
                        </MuiSelect>
                    </FormControl>
                    <Tabs
                        tableLabels={tabLabels}
                        variant="scrollable"
                    >
                        <ProductBase
                            imageUrl={imageUrl}
                            {...product}
                            hideManagerLinks={!isInAnyRoleOrAdmin(readOfferRoles)}
                        />
                        <ProductInternalData
                            imageUrl={imageUrl}
                            {...product}
                        />
                        <ProductVariants variants={variants} />
                        <ProductCategories categories={categories} />
                        <ProductPromotions promotions={promotions} />
                        <ProductRestrictions restrictions={restrictions} />
                        <ProductRations rations={rations} />
                        <ProductPriceHistory warehouse={warehouse} />
                        <ProductProblems problems={problems} />
                        <JsonView
                            data={product}
                            shouldInitiallyExpand={allExpanded}
                            style={darkStyles}
                        />
                    </Tabs>
                </Fragment>
            )}
        </Layout>
    );
};

export default ProductPreview;
