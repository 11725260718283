export const splitOrderId = (orderId) => {
    const parts = orderId.split("/");
    const customerId = parts[0];
    const orderNumber = parts[1];
    const complaintNumber = parts[2];

    return { customerId, orderNumber, complaintNumber };
};

export const splitPaymentId = (paymentId) => {
    const parts = paymentId.split("/");
    const customerId = parts[0];
    const paymentNumber = parts[1];

    return { customerId, paymentNumber };
};

export const splitDeferredPaymentId = (deferredPaymentId) => {
    const parts = deferredPaymentId.split("/");
    const customerId = parts[0];
    const deferredPaymentNumber = parts[1];

    return { customerId, deferredPaymentNumber };
};
