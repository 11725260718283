import { DecisionButtons } from "components/Button";
import roles from "consts/roles";
import { useUser } from "context/UserContext/UserContext";
import { makeDecision, refundationFormSelector } from "store/vendors/refundations";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";

const RefundationDecision = ({ isDecisionRequired, handleDecision }) => {
    const { vendorId, id: refundationId } = useSelector(refundationFormSelector);
    const { isInRoleOrAdmin } = useUser();
    const { t } = useTranslation("vendor");
    const dispatch = useDispatch();
    const [submitted, setSubmitted] = useState(false);

    return isDecisionRequired && isInRoleOrAdmin(roles.SalesManager) ? (
        <DecisionButtons
            submitted={submitted}
            onAccept={async () => {
                setSubmitted(true);
                await dispatch(makeDecision(vendorId, refundationId, true));
                handleDecision();
            }}
            onReject={async () => {
                setSubmitted(true);
                await dispatch(makeDecision(vendorId, refundationId, false));
                handleDecision();
            }}
            acceptText={t(vendor.acceptChanges)}
            rejectText={t(vendor.rejectChanges)}
        />
    ) : (
        <></>
    );
};

export default RefundationDecision;
