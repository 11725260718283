import { makeStyles } from "tss-react/mui";
import React from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress, Typography } from "@mui/material";

const useStyles = makeStyles()(() => ({
    container: {
        width: "100%",
    },
    center: {
        margin: 0,
        position: "absolute",
        top: "50%",
        left: "50%",
        msTransform: "translate(-50%, -50%)",
        transform: "translate(-50%, -50%)",
    },
}));

const SpinningPreloader: React.FC<{
    height?: number | string;
    size?: number;
}> = ({ height = 600, size = 72 }) => {
    const { classes } = useStyles();
    const { t } = useTranslation();
    return (
        <div
            style={{ height }}
            className={classes.container}
        >
            <div className={classes.center}>
                <CircularProgress size={size} />
                <Typography>{t("Loading").toString()}</Typography>
            </div>
        </div>
    );
};

export default SpinningPreloader;
