import defaultListState from "store/defaultListState";
import { combineReducers } from "redux";
import types from "./types";

const initialState = {
    list: defaultListState,
    form: {},
};
const { findDocumentDefinitions, createOrUpdate, formInit, getDocumentDefinitions } = types;

const list = (state = initialState.list, { type, payload }) => {
    switch (type) {
        case findDocumentDefinitions.request:
            return { ...state, isLoading: true };
        case findDocumentDefinitions.success:
            return { ...payload, isLoading: false };
        case findDocumentDefinitions.failure:
            return { ...state, isLoading: false };
        default:
            return state;
    }
};

const form = (state = initialState.form, { type, payload }) => {
    switch (type) {
        case formInit:
            return { ...initialState };
        case getDocumentDefinitions.request:
            return { ...initialState, isLoading: true };
        case getDocumentDefinitions.success:
            return { ...state, ...payload, isLoading: false };
        case getDocumentDefinitions.failure:
            return { ...payload, isLoading: false };
        case createOrUpdate.request:
            return { ...state, isSubbmitRequested: true };
        case createOrUpdate.success:
        case createOrUpdate.failure:
            return { ...state, isSubbmitRequested: false };
        default:
            return state;
    }
};

export default combineReducers({
    list,
    form,
});
