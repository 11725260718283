import { Button } from "components/Button";
import { DocumentArchiveFileSection } from "components/Files";
import { FormField } from "components/Form";
import { decimalNormalize } from "components/FormHelpers/ControlFormaters";
import IconWithHint from "components/Icons/IconWithHint";
import { Form, FormSection, reduxForm } from "redux-form";
import { friscoCompanyId } from "resource/documentArchiveCompanies";
import { documentCategoriesSelector, documentTypesSelector, getDocumentCategories, getDocumentTypes } from "store/autocomplete";
import { actions } from "store/vendors/action";
import { createOrUpdate, initForm, retryCreditLimitOperation } from "store/vendors/creditLimits/action";
import { useAppLocation, useFormValueSelector, useParams } from "utils/hooks";
import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Pageview } from "@mui/icons-material";
import { Alert, AlertTitle } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import moment from "moment";
import { Layout } from "components";
import { common, vendor } from "translations";
import { usePush, useTranslation } from "utils-ts/hooks";
import { IsVendorReadOnly } from "views/vendors/IsVendorReadOnly";
import VendorLayout from "views/vendors/components/VendorLayout";
import { creditLimitSelector, creditLimitsSelector } from "../selectors";
import { validators } from "../validators";

const LIMITY_KREDYTOWE = {
    name: "LIMITY KREDYTOWE",
    category: "HANDLOWE",
};

const PORĘCZENIE_GWARANCJA = {
    name: "PORĘCZENIE/GWARANCJA",
    category: "HANDLOWE",
};

const CreditLimitForm = ({ form, handleSubmit, formData, hasPendingCreditLimitOperation, isRetryPending, isLoading }) => {
    const { vendorId, creditLimitId } = useParams();
    const { t } = useTranslation(["vendor", "common"]);
    const permittedDocumentTypes = useSelector(documentTypesSelector);
    const permittedDocumentCategories = useSelector(documentCategoriesSelector);
    const dispatch = useDispatch();
    const { push } = usePush();
    const isVendorReadOnly = IsVendorReadOnly();
    const appLocation = useAppLocation();

    const { fileAttached, vendorCreditLimitOperationId, hasVendorCreditLimitOperationResult, isSuccess, errorMessage, validFrom, validTo } =
        useFormValueSelector(form, "creditLimit", {});
    const { hasSecurity, fileAttached: securityFileAttached } = useFormValueSelector(form, "security", false);

    useEffect(() => {
        dispatch(initForm(vendorId, creditLimitId));
        if (!isVendorReadOnly) {
            dispatch(actions.hasPendingCreditLimitOperation(vendorId));
        }
    }, [vendorId, creditLimitId, isVendorReadOnly, dispatch]);

    useEffect(() => {
        dispatch(getDocumentTypes());
        dispatch(getDocumentCategories());
    }, [dispatch]);

    const retryOperation = async () => {
        const { meta } = await dispatch(retryCreditLimitOperation(vendorId, creditLimitId));
        if (meta.formSubmit === "SUCCESS") {
            await dispatch(initForm(vendorId, creditLimitId));
        }
    };

    const canAccessDocumentType = !!permittedDocumentTypes && permittedDocumentTypes.map((x) => x.name).includes(LIMITY_KREDYTOWE.name);

    const canAccessDocumentCategory =
        !!permittedDocumentCategories && permittedDocumentCategories.map((x) => x.name).includes(LIMITY_KREDYTOWE.category);

    const canSaveFiles = canAccessDocumentType && canAccessDocumentCategory;

    const canAccessSecurityDocumentType = !!permittedDocumentTypes && permittedDocumentTypes.map((x) => x.name).includes(PORĘCZENIE_GWARANCJA.name);

    const canAccessSecurityDocumentCategory =
        !!permittedDocumentCategories && permittedDocumentCategories.map((x) => x.name).includes(PORĘCZENIE_GWARANCJA.category);

    const canSaveSecurityFiles = !hasSecurity || (canAccessSecurityDocumentType && canAccessSecurityDocumentCategory);

    return (
        <VendorLayout
            headerText={t(`common:${common.creditLimitForm}`, {
                id: creditLimitId,
            })}
            vendorId={vendorId}
            isLoading={isLoading}
            tabIndex={6}
            customTitle={`${vendorId} ${t(`common:${common.creditLimitForm}`)} ${
                validFrom ? `${moment(validFrom).format("DD.MM.YYYY")}` + (validTo ? `-${moment(validTo).format("DD.MM.YYYY")}` : "") : ""
            }`}
        >
            <Form
                readOnly={isVendorReadOnly || hasPendingCreditLimitOperation}
                onSubmit={handleSubmit(async (values) => {
                    let { creditLimit, creditLimitFile, security, securityFile } = values;
                    const { validFrom, validTo, limit, fileAttached } = creditLimit;
                    const { fileContent, fileName, companyId, documentNumber, documentType, documentCategory, documentDate, barcodePosition } =
                        creditLimitFile;

                    const documentTypeId = documentType.id;
                    const categoryId = documentCategory.id;

                    const { validFrom: securityValidFrom, validTo: securityValidTo, value, guarantor, hasSecurity } = security;

                    let body = {
                        creditLimit: {
                            validFrom,
                            validTo,
                            limit,
                            fileAttached,
                            hasSecurity,
                        },
                        creditLimitFile: {
                            fileContent,
                            fileName,
                            companyId,
                            documentNumber,
                            documentTypeId,
                            categoryId,
                            documentDate,
                        },
                    };

                    if (hasSecurity) {
                        body.creditLimit.security = {
                            validFrom: securityValidFrom,
                            validTo: securityValidTo,
                            value,
                            guarantor,
                        };
                        body.securityFile = securityFile;

                        if (securityFile && securityFile.archiveFile) {
                            body.creditLimit.security.fileAttached = true;
                            body.securityFile.fileContent = securityFile.archiveFile.fileContent;
                            body.securityFile.fileName = securityFile.archiveFile.fileName;
                            body.securityFile.categoryId = securityFile.documentCategory.id;
                            body.securityFile.documentTypeId = securityFile.documentType.id;
                            body.securityFile.companyId = friscoCompanyId;
                            body.securityFile.barcodePosition = barcodePosition;
                        }
                    }

                    if (creditLimitFile && creditLimitFile.archiveFile) {
                        body.creditLimit.fileAttached = true;
                        body.creditLimitFile.fileContent = creditLimitFile.archiveFile.fileContent;
                        body.creditLimitFile.fileName = creditLimitFile.archiveFile.fileName;
                        body.creditLimitFile.companyId = friscoCompanyId;
                        body.creditLimitFile.barcodePosition = barcodePosition;
                    }

                    await dispatch(createOrUpdate(body, vendorId, creditLimitId));
                    push(`/vendors/${vendorId}`, undefined, { tabIndex: 6 });
                })}
            >
                <FormSection name="creditLimit">
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <FormField
                            name={"validFrom"}
                            label={t(vendor.creditLimitValidFrom)}
                            type="date"
                            validate={validators.required}
                            readOnly={
                                isVendorReadOnly ||
                                hasPendingCreditLimitOperation ||
                                vendorCreditLimitOperationId ||
                                hasVendorCreditLimitOperationResult
                            }
                        />

                        <FormField
                            name={"validTo"}
                            label={t(vendor.creditLimitValidTo)}
                            type="date"
                            readOnly={
                                isVendorReadOnly ||
                                hasPendingCreditLimitOperation ||
                                vendorCreditLimitOperationId ||
                                hasVendorCreditLimitOperationResult
                            }
                        />

                        <FormField
                            name={"limit"}
                            label={t(vendor.creditLimit)}
                            type="text"
                            format={decimalNormalize}
                            validate={validators.creditLimit}
                            readOnly={
                                isVendorReadOnly ||
                                hasPendingCreditLimitOperation ||
                                vendorCreditLimitOperationId ||
                                hasVendorCreditLimitOperationResult
                            }
                        />
                        <Grid item>
                            {formData?.creditLimitFile?.archiveId && (
                                <Button
                                    color="primary"
                                    startIcon={<Pageview />}
                                    onClick={() =>
                                        window.open(`${appLocation}/document-archive/document/${formData?.creditLimitFile?.archiveId}`, "_blank")
                                    }
                                >
                                    Podgląd
                                </Button>
                            )}
                        </Grid>

                        {creditLimitId && (
                            <Grid
                                item
                                style={{ padding: "8px" }}
                            >
                                <Typography
                                    component="div"
                                    variant="caption"
                                >
                                    Status przetwarzania AX
                                </Typography>
                                <Typography component="div">
                                    {vendorCreditLimitOperationId && !hasVendorCreditLimitOperationResult && t(vendor.creditLimitPending)}
                                    {hasVendorCreditLimitOperationResult && isSuccess && t(vendor.creditLimitSuccess)}
                                    {hasVendorCreditLimitOperationResult &&
                                        !isSuccess &&
                                        t(vendor.creditLimitError, {
                                            error: errorMessage,
                                        })}
                                    {hasVendorCreditLimitOperationResult && !isSuccess && (
                                        <IconWithHint
                                            icon="refresh"
                                            loading={isRetryPending}
                                            onClick={retryOperation}
                                            target="_blank"
                                            title={t(vendor.creditLimitRetryMessage)}
                                        />
                                    )}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </FormSection>

                <FormSection name="creditLimitFile">
                    <DocumentArchiveFileSection
                        hiddenFields={["companyId", "vendor"]}
                        readOnlyFields={["documentCategory", "documentType"]}
                        readOnly={
                            isVendorReadOnly ||
                            hasPendingCreditLimitOperation ||
                            vendorCreditLimitOperationId ||
                            hasVendorCreditLimitOperationResult ||
                            fileAttached
                        }
                        useDropzone
                        useDialogPreview
                        validate={isVendorReadOnly || hasPendingCreditLimitOperation || fileAttached || validators.required}
                    />
                </FormSection>

                <Layout headerText={t(vendor.creditLimitSecurity)}>
                    <FormSection name="security">
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <FormField
                                name={"hasSecurity"}
                                label={t(vendor.creditLimitHasSecurity)}
                                type="boolean"
                                readOnly={isVendorReadOnly || hasPendingCreditLimitOperation}
                                disabled={securityFileAttached}
                            />
                        </Grid>

                        {hasSecurity && (
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <FormField
                                    name={"validFrom"}
                                    label={t(vendor.creditLimitValidFrom)}
                                    type="date"
                                    validate={hasSecurity ? validators.validateFrom : undefined}
                                    readOnly={isVendorReadOnly || hasPendingCreditLimitOperation}
                                />

                                <FormField
                                    name={"validTo"}
                                    label={t(vendor.creditLimitValidTo)}
                                    type="date"
                                    validate={hasSecurity ? validators.validateTo : undefined}
                                    readOnly={isVendorReadOnly || hasPendingCreditLimitOperation}
                                />

                                <FormField
                                    name={"value"}
                                    label={t(vendor.creditLimitSecurityValue)}
                                    type="text"
                                    format={decimalNormalize}
                                    validate={hasSecurity ? validators.creditLimit : undefined}
                                    readOnly={isVendorReadOnly || hasPendingCreditLimitOperation}
                                />

                                <FormField
                                    name={"guarantor"}
                                    label={t(vendor.guarantor)}
                                    type="text"
                                    validate={hasSecurity ? validators.required : undefined}
                                    readOnly={isVendorReadOnly || hasPendingCreditLimitOperation}
                                />

                                <Grid item>
                                    {formData?.security?.securityFile?.archiveId && (
                                        <Button
                                            color="primary"
                                            startIcon={<Pageview />}
                                            onClick={() =>
                                                window.open(
                                                    `${appLocation}/document-archive/document/${formData?.security?.file?.archiveId}`,
                                                    "_blank"
                                                )
                                            }
                                        >
                                            Podgląd
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                    </FormSection>

                    {hasSecurity && (
                        <FormSection name="securityFile">
                            <DocumentArchiveFileSection
                                hiddenFields={["companyId", "vendor"]}
                                readOnlyFields={["documentCategory", "documentType"]}
                                readOnly={isVendorReadOnly || hasPendingCreditLimitOperation || securityFileAttached}
                                useDropzone
                                useDialogPreview
                                validate={
                                    isVendorReadOnly || hasPendingCreditLimitOperation || securityFileAttached || hasSecurity
                                        ? validators.required
                                        : undefined
                                }
                            />
                        </FormSection>
                    )}
                </Layout>
                {(!canSaveFiles || !canSaveSecurityFiles) && (
                    <NoAccessAlert
                        canAccessDocumentType={canAccessDocumentType}
                        canAccessDocumentCategory={canAccessDocumentCategory}
                        canAccessSecurityDocumentType={canAccessSecurityDocumentType}
                        canAccessSecurityDocumentCategory={canAccessSecurityDocumentCategory}
                    />
                )}
                <Button
                    actionType="accept"
                    type="submit"
                    fullWidth
                    disabled={isVendorReadOnly || hasPendingCreditLimitOperation || !canSaveFiles || !canSaveSecurityFiles}
                >
                    {t(`common:${common.save}`)}
                </Button>
            </Form>
        </VendorLayout>
    );
};

const NoAccessAlert = ({ canAccessDocumentType, canAccessDocumentCategory, canAccessSecurityDocumentType, canAccessSecurityDocumentCategory }) => {
    const { t } = useTranslation(["vendor", "common"]);

    return (
        <Alert severity="error">
            <AlertTitle>{t(vendor.attention)}</AlertTitle>
            {!canAccessDocumentType || !canAccessSecurityDocumentType
                ? `${t(vendor.noAccessToDocumentTypes)} ${[
                      ...(!canAccessDocumentType ? [LIMITY_KREDYTOWE.name] : []),
                      ...(!canAccessSecurityDocumentType ? [PORĘCZENIE_GWARANCJA.name] : []),
                  ].reduce((prev, cur) => `${prev}, ${cur}`)}`
                : `${t(vendor.noAccessToDocumentCategories)} ${[
                      ...(!canAccessDocumentCategory ? [LIMITY_KREDYTOWE.category] : []),
                      ...(!canAccessSecurityDocumentCategory ? [PORĘCZENIE_GWARANCJA.category] : []),
                  ].reduce((prev, cur) => `${prev}, ${cur}`)}`}
        </Alert>
    );
};

const formName = "credit-limit-form";

const stateToProps = (state) => {
    const { hasPendingCreditLimitOperation, isLoading } = creditLimitsSelector(state);
    const { isSubbmitRequested, isRetryPending, hasPendingCreditLimitOperation: retryResult, ...form } = creditLimitSelector(state);
    const documentCategories = documentCategoriesSelector(state);
    const documentTypes = documentTypesSelector(state);

    return {
        isLoading,
        isSubbmitRequested,
        isRetryPending,
        formData: form,
        hasPendingCreditLimitOperation: retryResult || hasPendingCreditLimitOperation,
        initialValues: {
            ...form,
            creditLimitFile: {
                ...(form?.creditLimitFile || {}),
                documentCategory: (documentCategories || []).find((x) => x.name === LIMITY_KREDYTOWE.category),
                documentType: (documentTypes || []).find((x) => x.name === LIMITY_KREDYTOWE.name),
            },
            securityFile: {
                ...(form?.securityFile || {}),
                documentCategory: (documentCategories || []).find((x) => x.name === PORĘCZENIE_GWARANCJA.category),
                documentType: (documentTypes || []).find((x) => x.name === PORĘCZENIE_GWARANCJA.name),
            },
        },
    };
};

export default connect(stateToProps)(
    reduxForm({
        form: formName,
        enableReinitialize: true,
    })(CreditLimitForm)
);
