import pdfImage from "assets/img/pdf.png";
import Preloader from "components/Preloader/Preloader";
import { makeStyles } from "tss-react/mui";
import React from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Backdrop, Card, CardContent, CardMedia, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";

//const imageWidth = 200;
const useStyles = makeStyles()({
    imageCard: {
        width: (props) => props.imageWidth + "px",
        height: (props) => props.imageWidth + "px",
        marginRight: "20px",
        marginBottom: "20px",
        borderRadius: 6,
    },
    imageCardContent: {
        "position": "relative",
        "padding": 0,
        "&:last-child": {
            paddingBottom: 0,
        },
    },
    imageCardOverlay: {
        zIndex: 10,
        backgroundColor: "inherit",
        position: "absolute",
    },
    imageCardOverlayMenuBtn: {
        position: "absolute",
        top: "0px",
        right: "0px",
    },
    imageCardImageContainer: {
        display: "flex",
        justifyContent: "center",
    },
    imageCardImage: {
        width: "auto",
        maxHeight: "200px",
        alignSelf: "center",
    },
});

export const ImageCard = ({ image, actions, onDoubleClick, disabled, backdrop, backdropOpen, imageWidth = 200, className = "", isPdf }) => {
    const { classes } = useStyles({ imageWidth });
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const menuOpen = Boolean(menuAnchorEl);

    const openMenu = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setMenuAnchorEl(null);
    };

    const preventSelectionOnDoubleClick = (e) => {
        e.detail === 2 && e.preventDefault();
    };

    return (
        <Card
            className={`${classes.imageCard} ${className}`}
            onMouseDown={!!onDoubleClick && preventSelectionOnDoubleClick}
            onDoubleClick={onDoubleClick}
        >
            <CardContent className={classes.imageCardContent}>
                {!!backdrop && (
                    <Backdrop
                        className={classes.imageCardOverlay}
                        open={backdropOpen}
                    >
                        {backdrop}
                    </Backdrop>
                )}
                {!disabled && !!actions && actions.length > 0 && (
                    <Backdrop
                        className={classes.imageCardOverlay}
                        open
                    >
                        <IconButton
                            onClick={openMenu}
                            className={classes.imageCardOverlayMenuBtn}
                        >
                            <MoreHorizIcon />
                        </IconButton>
                        <Menu
                            onClose={closeMenu}
                            open={menuOpen}
                            anchorEl={menuAnchorEl}
                        >
                            {actions.map((action, i) => (
                                <MenuItem
                                    key={i}
                                    onClick={() => {
                                        !action.keepOpen && closeMenu();
                                        action.onSelect();
                                    }}
                                >
                                    <ListItemIcon>{action.icon}</ListItemIcon>
                                    <ListItemText>{action.name}</ListItemText>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Backdrop>
                )}
                <div className={classes.imageCardImageContainer}>
                    {image ? (
                        <CardMedia
                            component="img"
                            className={classes.imageCardImage}
                            image={isPdf ? pdfImage : image}
                        />
                    ) : (
                        <Preloader height={imageWidth} />
                    )}
                </div>
            </CardContent>
        </Card>
    );
};
