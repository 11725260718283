import { common } from "translations";

const balanceChangeReasons = [
    common.BalancePaid,
    common.BalancePaymentApproved,
    common.BalancePaymentCreated,
    common.BalancePaymentTransferred,
    common.BalanceReseted,
    common.ComplaintCanceled,
    common.ComplaintCreated,
    common.ComplaintUpdated,
    common.CreditLimitChanged,
    common.DeliveryStatementCharged,
    common.DocumentCorrectionCreated,
    common.OnlinePaymentCompleted,
    common.OnlinePaymentImported,
    common.OrderAborted,
    common.OrderCanceled,
    common.OrderChanged,
    common.OrderDelivered,
    common.OrderInvoiced,
    common.OrderPlaced,
    common.OrderTransferred,
    common.OrderTransferredWithUnpaidBalance,
    common.OrderUnaborted,
    common.PaymentOverpayed,
    common.PaymentRefunded,
    common.RefundCanceled,
    common.BalancePaymentWithheld,
];

export default balanceChangeReasons;
