import { CampaingsAutocomplete, ContentData } from "components/Controls";
import { FormField } from "components/Form";
import { renderFieldOrText } from "components/FormHelpers/Controls";
import { usePromotionFormAutocompleteEffect } from "store/autocomplete";
import { useCommonTranslation, useParams } from "utils/hooks";
import React, { Fragment } from "react";
import { Grid } from "@mui/material";
import { validators } from "../validators";
import VoucherDefinition from "./VoucherDefinition";

const forms = {
    vouchers: "vouchers",
    batches: "batches",
    generators: "generators",
};

const VoucherFields = ({ showGeneratorFields, datesRequired = true }) => {
    usePromotionFormAutocompleteEffect();
    const { t, common } = useCommonTranslation();
    const { id, type } = useParams();

    const displayWhenType = (types) => types.every((t) => t !== type);
    return (
        <Fragment>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <FormField
                    hidden={displayWhenType([forms.batches])}
                    name={"name"}
                    type={"text"}
                    label={t(common.name)}
                    validate={validators.name}
                />
                <FormField
                    hidden={displayWhenType([forms.batches])}
                    name={"codeLength"}
                    type={"number"}
                    label={t(common.codeLength)}
                    validate={validators.codeLength}
                />
                <FormField
                    hidden={displayWhenType([forms.batches])}
                    name={"voucherCount"}
                    type={"number"}
                    label={t(common.voucherCount)}
                    validate={validators.voucherCount}
                />
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <FormField
                    hidden={displayWhenType([forms.batches])}
                    name={"isDisallowed"}
                    type={"boolean"}
                    label={t(common.isDisallowed)}
                />
                <FormField
                    hidden={displayWhenType([forms.batches])}
                    name={"isUserDisallowed"}
                    type={"boolean"}
                    label={t(common.isUserDisallowed)}
                />
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <FormField
                    diabled={Boolean(id)}
                    hidden={displayWhenType([forms.vouchers])}
                    name={"id"}
                    type={"text"}
                    label={t(common.voucherId)}
                    validate={validators.voucherId}
                />
                <FormField
                    hidden={displayWhenType([forms.vouchers])}
                    name={"userId"}
                    type={"text"}
                    label={t(common.userId)}
                />
                <FormField
                    hidden={displayWhenType([forms.vouchers])}
                    name={"complaintId"}
                    type={"text"}
                    label={t(common.complaintId)}
                />
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <FormField
                    name={"validFrom"}
                    type={"dateTime"}
                    label={t(common.validFrom)}
                    validate={datesRequired ? validators.validFrom : undefined}
                />
                <FormField
                    name={"validTo"}
                    type={"dateTime"}
                    label={t(common.validTo)}
                    validate={datesRequired ? validators.validFrom : undefined}
                />
                <FormField
                    hidden={!showGeneratorFields}
                    name="validForDays"
                    type="number"
                    label={t(common.validForDays)}
                    validate={validators.validForDays}
                />
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <CampaingsAutocomplete validate={validators.campaign} />
                <FormField
                    name={"isGlobal"}
                    component={renderFieldOrText}
                    type={"boolean"}
                    label={t(common.isGlobal)}
                />
            </Grid>
            <FormField
                disabled={true}
                hidden={!id && displayWhenType([forms.vouchers])}
                name={"useCount"}
                type={"number"}
                label={t(common.useCount)}
            />

            <VoucherDefinition />

            <ContentData />
        </Fragment>
    );
};

export default VoucherFields;
