import { styles } from "assets/jss/common";
import { RenderAutoComplete, renderTextField } from "components/FormHelpers/Controls";
import { Field, reduxForm } from "redux-form";
import { addNewUser } from "store/identity/createUser/actions";
import { withStyles } from "tss-react/mui";
import userTypes from "utils/dictionaryTypes/userType";
import { withPush } from "utils/hoc";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import classNames from "classnames";
import { administration, common } from "translations";

const internalStyles = (theme) => ({
    progress: {
        marginRight: theme.spacing(2),
    },
});

export const validate = (values) => {
    const errors = {};
    const requiredFields = ["firstName", "lastName", "email", "password", "confirmationPassword", "userType"];
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = "Required";
        }
    });
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = "Invalid email address";
    }

    if (values.password && values.confirmationPassword && values.password !== values.confirmationPassword) {
        errors.confirmationPassword = "Password and confirmation password do not match";
    }
    if (values.userType === userTypes.vendorUser && !values.vendor) {
        errors.vendor = "Required";
    }
    return errors;
};

const CreateUserForm = (props) => {
    const { handleSubmit, invalid, pristine, submitting, t, addNewUser, push } = props;
    const classes = withStyles.getClasses(props);

    const createUser = async (values) => {
        const { email, firstName, lastName, password, userType } = values;
        const { payload, type } = await addNewUser(email, firstName, lastName, password, userType);

        if (type.includes("SUCCESS")) {
            push(`/administration/users/form/${payload}`);
        }
    };

    const tcommon = (name) => t(`common:${name}`);

    return (
        <form
            onSubmit={handleSubmit((values) => {
                createUser(values);
            })}
        >
            <Grid
                container
                spacing={4}
            >
                <Grid
                    item
                    xs={12}
                    md={10}
                >
                    <Field
                        name="userType"
                        fullWidth={true}
                        disableEditing={true}
                        component={RenderAutoComplete}
                        label={t(administration.userType)}
                        options={Object.values(userTypes)}
                        getOptionLabel={(x) => tcommon(x) ?? ""}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={10}
                >
                    <Field
                        name="email"
                        fullWidth={true}
                        component={renderTextField}
                        label={t("Email")}
                    />
                </Grid>
                <Grid
                    item
                    xs={6}
                    md={3}
                >
                    <Field
                        name="firstName"
                        fullWidth={true}
                        component={renderTextField}
                        label={tcommon(common.firstName)}
                    />
                </Grid>
                <Grid
                    item
                    xs={6}
                    md={3}
                >
                    <Field
                        name="lastName"
                        fullWidth={true}
                        component={renderTextField}
                        label={tcommon(common.lastName)}
                    />
                </Grid>
                <Grid
                    item
                    xs={6}
                    md={3}
                >
                    <Field
                        name="phoneNumber"
                        fullWidth={true}
                        component={renderTextField}
                        label={tcommon(common.phoneNumber)}
                    />
                </Grid>
                <Grid
                    item
                    xs={6}
                    md={3}
                >
                    <Field
                        name="password"
                        fullWidth={true}
                        component={renderTextField}
                        label={tcommon(common.password)}
                        type="password"
                    />
                </Grid>
                <Grid
                    item
                    xs={6}
                    md={3}
                >
                    <Field
                        name="confirmationPassword"
                        fullWidth={true}
                        component={renderTextField}
                        label={t(administration.confirmationPassword)}
                        type="password"
                    />
                </Grid>
            </Grid>
            <Grid
                container
                spacing={4}
            >
                <Grid
                    item
                    xs={12}
                    md={12}
                >
                    <Button
                        variant="contained"
                        color="secondary"
                        className="pull-right"
                        disabled={invalid || pristine}
                        type="submit"
                    >
                        {submitting === true ? (
                            <CircularProgress
                                className={classes.progress}
                                size={15}
                            />
                        ) : (
                            <SaveIcon className={classNames(styles.leftIcon, styles.iconSmall)} />
                        )}
                        {t("Save")}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

const mapStateToProps = () => {
    return {};
};
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        addNewUser: (email, firstName, lastName, password, userType, vendorIds) =>
            dispatch(addNewUser(email, firstName, lastName, password, userType, vendorIds)),
    };
};

const formName = "CreateUserForm";
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: formName,
        validate,
    })(withPush(withTranslation(["administration", "common"])(withStyles(CreateUserForm, internalStyles))))
);
