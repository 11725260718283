const styles = (theme) => ({
    accept: {
        "backgroundColor": theme.palette.success.main,
        "color": theme.palette.success.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.success.dark,
            color: theme.palette.success.contrastText,
        },
    },
    decline: {
        "backgroundColor": theme.palette.error.main,
        "color": theme.palette.success.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.error.dark,
            color: theme.palette.success.contrastText,
        },
    },
    danger: {
        "backgroundColor": theme.palette.error.main,
        "color": theme.palette.error.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.error.dark,
            color: theme.palette.error.contrastText,
        },
    },
    warning: {
        "backgroundColor": theme.palette.warning.main,
        "color": theme.palette.warning.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.warning.dark,
            color: theme.palette.warning.contrastText,
        },
    },
    primary: {
        "backgroundColor": theme.palette.primary.main,
        "color": theme.palette.primary.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText,
        },
    },
    secondary: {
        "backgroundColor": theme.palette.secondary.main,
        "color": theme.palette.secondary.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.secondary.dark,
            color: theme.palette.secondary.contrastText,
        },
    },
    disabled: {
        backgroundColor: theme.palette.action.disabledBackground,
        color: theme.palette.action.disabled,
    },
});

export default styles;
