import Button from "components/Button/Button";
import { useCommonTranslation } from "utils/hooks";
import { Grid } from "@mui/material";

const FileExport = ({ handleDownload, downloadDisabled, downloadText, loading, fullWidth, size, actionType }) => {
    const { t, common } = useCommonTranslation();

    return (
        <Grid
            container
            direction="row"
        >
            <Grid item>
                <Button
                    type="button"
                    actionType={actionType ?? "secondary"}
                    fullWidth={fullWidth}
                    size={size}
                    onClick={handleDownload}
                    loading={loading}
                    disabled={downloadDisabled}
                >
                    {downloadText ?? t(common.export)}
                </Button>
            </Grid>
        </Grid>
    );
};

export default FileExport;
