import { ContentBrandsAutocomplete, ProductAnalyticGroupsAutocomplete } from "components/Controls/Autocompletes";
import ProductListField from "components/Controls/ProductListField";
import { FormField } from "components/Form";
import { brandNamesSelector } from "store/autocomplete";
import { makeStyles } from "tss-react/mui";
import { withFormName } from "utils/hoc";
import { useChange, useCommonTranslation, useFormValueSelector } from "utils/hooks";
import { validators } from "../../validators";

const useStyles = makeStyles()((theme) => ({
    autocompletes: {
        width: "100%",
        padding: theme.spacing(1),
    },
}));

const ContractsAutocompletes = ({ readOnly, notRequired, form, sectionPrefix, useDiff }) => {
    const { classes } = useStyles();
    const { t, common } = useCommonTranslation();
    const { appliesToAllProducts, ...rest } = useFormValueSelector(form, sectionPrefix, {});
    const change = useChange(form);
    const changeAppliesToAllProducts = (_, newValue) => {
        if (newValue === true)
            change(sectionPrefix, {
                ...rest,
                categories: undefined,
                brands: undefined,
                products: undefined,
            });
    };

    return (
        <div className={classes.autocompletes}>
            <FormField
                useDiff={useDiff}
                name="appliesToAllProducts"
                label={t(common.appliesToAllProducts)}
                type="boolean"
                onChange={changeAppliesToAllProducts}
                readOnly={readOnly}
            />
            {!(appliesToAllProducts === true) && (
                <>
                    <ProductAnalyticGroupsAutocomplete
                        useDiff={useDiff}
                        readOnly={readOnly}
                        validate={notRequired ? undefined : validators.atLeastOneProductField}
                        asyncValidate
                    />
                    <ContentBrandsAutocomplete
                        useDiff={useDiff}
                        readOnly={readOnly}
                        selector={brandNamesSelector}
                        validate={notRequired ? undefined : validators.atLeastOneProductField}
                        asyncValidate
                    />
                    <ProductListField
                        useDiff={useDiff}
                        readOnly={readOnly}
                        validate={notRequired ? undefined : validators.atLeastOneProductField}
                        asyncValidate
                    />
                </>
            )}
        </div>
    );
};

export default withFormName(ContractsAutocompletes);
