import { documentDefinitionsSelector } from "store/autocomplete";
import { documentFormSelector, initForm } from "store/vendors/documents";
import { useCommonTranslation, useParams } from "utils/hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import VendorLayout from "views/vendors/components/VendorLayout";
import DocumentForm from "./DocumentForm";

const Document = () => {
    const { vendorId, id } = useParams();
    const { t, common } = useCommonTranslation();
    const dispatch = useDispatch();
    const { isLoading, documentDefinitionId } = useSelector(documentFormSelector);
    const documentDefinitions = useSelector(documentDefinitionsSelector);

    useEffect(() => {
        dispatch(initForm(vendorId, id));
    }, [dispatch, id, vendorId]);
    const name = documentDefinitions?.find((c) => c.id === documentDefinitionId)?.name || "";
    return (
        <VendorLayout
            headerText={t(common.document, { id: id })}
            vendorId={vendorId}
            isLoading={isLoading}
            tabIndex={9}
            customTitle={`${vendorId} ${t(common.document)} ${name}`}
        >
            <DocumentForm
                vendorId={vendorId}
                handleSave={() => {
                    dispatch(initForm(vendorId, id));
                }}
            />
        </VendorLayout>
    );
};

export default Document;
