import { PriceListResponse, ProductPriceListRequest } from "Operations";
import { useGetQuery } from "api/hooks";

export const useFindProductPriceList = (initParams: ProductPriceListRequest) => {
    return useGetQuery<PriceListResponse, ProductPriceListRequest>({
        app: "operations",
        url: "api/price-list/product",
        queryParams: initParams,
        shouldMakeRequest: (params) => Boolean(params?.productId) && Boolean(params?.supplierId),
    });
};
