import { iconTypes } from "components/Icons/InfoIcon";
import { createSelector } from "reselect";
import { common } from "translations";

const rootSelector = (state) => state.customer;

const rowStatusMap = new Map([
    [common.MakeReservationInternal, iconTypes.success],
    [common.PlaceOrder, iconTypes.success],
    [common.RemindSavedReservation, iconTypes.info],
    [common.RemindReservation, iconTypes.info],
    [common.ExchangeSavedReservation, iconTypes.info],
    [common.ExchangeReservation, iconTypes.info],
    [common.EditOrder, iconTypes.info],
    [common.RequestReservationConfirmation, iconTypes.warrning],
    [common.ExpireReservation, iconTypes.warrning],
    [common.CancelReservationByToken, iconTypes.warrning],
    [common.ExpireSavedReservation, iconTypes.warrning],
    [common.CancelReservation, iconTypes.danger],
    [common.CancelSavedReservation, iconTypes.danger],
    [common.ClearCart, iconTypes.info],
]);

export const reservationAuditSelector = createSelector(rootSelector, (customer) => {
    const { reservationAudit } = customer;

    return {
        ...reservationAudit,
        items: reservationAudit?.items?.map((x) => {
            return {
                ...x,
                ...x.document,
                ...(x.document?.lastReservation ? x.document?.lastReservation : x.document?.reservation),
                createdBy: x.createdBy ? "Klient" : "Sklep",
                infoIcon: rowStatusMap.get(x.commandName),
                createdAt: x.createdAt,
            };
        }),
    };
});
