import defaultListState from "store/defaultListState";
import { combineReducers } from "redux";
import types from "./types";

const formInitialState = {
    isLoading: false,
    activeToolkitNames: [],
};

const listInitialState = defaultListState;

const form = (state = formInitialState, { type, payload }) => {
    switch (type) {
        case types.formInit:
            return { ...formInitialState };
        case types.getMarketingTool.request:
            return {
                ...formInitialState,
                isLoading: true,
            };
        case types.getMarketingTool.success:
            return {
                ...payload,
                isLoading: false,
            };
        case types.getMarketingTool.failure:
            return {
                isLoading: false,
            };
        case types.createMarketingTool.request:
        case types.updateMarketingTool.request:
            return {
                ...formInitialState,
                isSubbmitRequested: true,
            };
        case types.createMarketingTool.success:
        case types.createMarketingTool.failure:
        case types.updateMarketingTool.success:
        case types.updateMarketingTool.failure:
            return {
                ...payload,
                isSubbmitRequested: false,
            };
        default:
            return { ...state };
    }
};

const list = (state = listInitialState, { type, payload }) => {
    switch (type) {
        case types.getMarketingTools.request:
            return {
                ...listInitialState,
                isLoading: true,
            };
        case types.getMarketingTools.success:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case types.getMarketingTools.failure:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return { ...state };
    }
};

const validation = (state = formInitialState, { type, payload }) => {
    switch (type) {
        case types.setActiveToolkitNames:
            return {
                ...state,
                activeToolkitNames: payload,
            };
        default:
            return { ...state };
    }
};

export const marketingTools = combineReducers({
    form,
    list,
    validation,
});
