import { formValueSelector } from "redux-form";
import { createSelector } from "reselect";
import { Voucher, VoucherGenerator } from "./models";

const voucherSelector = (state) => state.vouchers;

export const voucherListSelector = createSelector(voucherSelector, (vouchers) => vouchers.list);

export const voucherFormSelector = createSelector(voucherSelector, (vouchers) => new Voucher(vouchers.form));

export const voucherFormGeneratorSelector = createSelector(voucherSelector, (vouchers) => new VoucherGenerator(vouchers.form));

export const voucherFormValue = (formName, state) => formValueSelector(formName)(state, "definition");
