import { withStyles } from "tss-react/mui";
import { firstOrEmpty } from "utils/extensions/arrayExtensions";
import { ExpandMore } from "@mui/icons-material";
import {
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Accordion as MuiExpansionPanel,
    Typography,
} from "@mui/material";

const ExpansionPanel = withStyles(MuiExpansionPanel, (theme) => ({
    root: {
        "border": "1px solid rgba(0, 0, 0, .125)",
        "boxShadow": "none",
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    },
    expanded: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const FacetSearchControl = ({ label, items, facet = [], input, onFilterChange }) => {
    const handleChange = async (event, checked) => {
        event.persist();
        const {
            target: { name },
        } = event;
        if (checked) {
            await input.onChange([...input.value, name]);
        } else {
            await input.onChange([...input.value].filter((x) => x !== name));
        }

        onFilterChange();
    };

    const findFacetValue = (name) => {
        return firstOrEmpty(facet, (x) => x.value === name);
    };
    const facetSum = facet
        .filter((x) => x.isSelected)
        .map((x) => x.count)
        .reduce((c, n) => c + Number(n), 0);

    return (
        <ExpansionPanel>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>{`${label} ${facetSum ? `(${facetSum})` : ""}`}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ maxHeight: 350, overflowY: "auto" }}>
                <FormControl
                    size="small"
                    component="fieldset"
                    fullWidth
                >
                    <FormGroup>
                        {(
                            items ||
                            facet.map((x) => ({
                                value: x.value,
                                name: x.value,
                            })) ||
                            []
                        ).map(({ name, value }, index) => {
                            const { count, isSelected } = findFacetValue(value);
                            const label = `${name} ${count !== undefined ? `(${count})` : ""}`;
                            return (
                                <FormControlLabel
                                    key={`${input.name}_${index}`}
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={isSelected}
                                            onChange={handleChange}
                                            name={value}
                                        />
                                    }
                                    label={label}
                                />
                            );
                        })}
                    </FormGroup>
                </FormControl>
            </AccordionDetails>
        </ExpansionPanel>
    );
};

export default FacetSearchControl;
