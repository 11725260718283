import ControlLabel from "components/Controls/ControlLabel";
import VirtualizedListbox from "components/VirtualizedListbox/VirtualizedListbox";
import { Fragment } from "react";
import { FormControl, FormHelperText, TextField, Tooltip } from "@mui/material";
import { Autocomplete as MUIAutoComplete } from "@mui/material";

const RenderFromHelper = ({ touched, error }) => {
    if (!(touched && error)) {
        return <Fragment />;
    } else {
        return <FormHelperText style={{ color: "red" }}>{error}</FormHelperText>;
    }
};

const Autocomplete = ({
    label,
    input,
    meta,
    items,
    disableCloseOnSelect,
    multiple,
    freeSolo,
    getOptionLabel,
    onCustomChange,
    filterOptions,
    selectOnFocus,
    clearOnBlur,
    isRequired,
    virtualized,
    getOptionTooltip,
    noOptionsText,
    clearText,
    asyncValidate,
    liveValidate,
    tooltipText,
    isOptionEqualToValue,
    getOptionSelected,
}) => {
    const { touched, error, invalid } = meta || {};
    return (
        <FormControl
            fullWidth={true}
            error={(touched || liveValidate) && invalid}
        >
            <Tooltip
                title={tooltipText ?? ""}
                placement="right-start"
                arrow
            >
                <MUIAutoComplete
                    clearOnBlur={clearOnBlur}
                    selectOnFocus={selectOnFocus}
                    filterOptions={filterOptions}
                    freeSolo={freeSolo}
                    multiple={multiple}
                    options={items}
                    ListboxComponent={virtualized ? VirtualizedListbox : undefined}
                    noOptionsText={noOptionsText}
                    clearText={clearText}
                    value={items && items.length >= 0 ? (!input.value ? (multiple ? [] : "") : input.value) : null}
                    renderOption={
                        getOptionTooltip
                            ? (option) => (
                                  <Tooltip
                                      title={getOptionTooltip(option)}
                                      placement="right-start"
                                      arrow
                                  >
                                      <div>{getOptionLabel ? getOptionLabel(option) : option}</div>
                                  </Tooltip>
                              )
                            : undefined
                    }
                    getOptionLabel={
                        getOptionLabel ||
                        ((option) => {
                            return option;
                        })
                    }
                    disableCloseOnSelect={disableCloseOnSelect}
                    onChange={
                        onCustomChange
                            ? (event, newValue) => {
                                  onCustomChange(event, newValue, input.onChange);
                                  if (asyncValidate) {
                                      input.onChange(newValue);
                                  }
                              }
                            : (_, a) => {
                                  input.onChange(a);
                              }
                    }
                    renderInput={(params) => (
                        <TextField
                            error={(touched || liveValidate) && invalid}
                            {...params}
                            variant="standard"
                            fullWidth
                            label={
                                <ControlLabel
                                    label={label}
                                    isRequired={isRequired}
                                />
                            }
                        />
                    )}
                    isOptionEqualToValue={isOptionEqualToValue || getOptionSelected}
                />
            </Tooltip>
            <RenderFromHelper
                touched={touched || liveValidate}
                error={error}
            />
        </FormControl>
    );
};

export default Autocomplete;
