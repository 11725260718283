import { renderToggleButtons as RenderToggleButtons } from "components/FormHelpers/Controls";
import React from "react";

const ToggleActiveGroup = ({ onChange, value }) => {
    return (
        <RenderToggleButtons
            input={{ onChange, value }}
            items={[
                {
                    value: true,
                    name: "Aktywne",
                },
                {
                    value: false,
                    name: "Nieaktywne",
                },
                { value: null, name: "Wszystko" },
            ]}
        />
    );
};

export default ToggleActiveGroup;
