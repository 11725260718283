import tableStyle from "assets/jss/material-dashboard-react/components/tableStyle";
import tasksStyle from "assets/jss/material-dashboard-react/components/tasksStyle";
import { withStyles } from "tss-react/mui";
import React from "react";
import { TableRow } from "@mui/material";
import classnames from "classnames";

const styles = (theme) => ({
    ...tableStyle,
    ...tasksStyle,
    root: {
        width: "100%",
        marginTop: theme.spacing(3),
        overflowX: "auto",
    },
    stripped: {
        background: "#f0f0f0",
    },
});

const row = ({ children, classes, onDobuleClick, hover, strippedRow, className = "", refProp }) => {
    const { tableRow } = classes;
    return (
        <TableRow
            ref={refProp}
            hover={hover}
            onDoubleClick={onDobuleClick}
            className={classnames(tableRow, strippedRow ? classes.stripped : "", className)}
        >
            {children}
        </TableRow>
    );
};

export default withStyles(row, styles);
