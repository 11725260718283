import { GetRequest, get } from "utils/extensions";
import { combineIntoQueryParams } from "utils/objectExtensions";
import { downloadXlsxFile } from "utils/rsaa/downloadFileAction";
import moment from "moment";
import types from "./types";

const endpoint = "api/archive-documents";

export const findArchivedDocuments = (query) => async (dispatch) => {
    dispatch(
        get(
            new GetRequest(endpoint, types.findArchivedDocuments.all, {
                queryParams: query,
            })
        )
    );
};

export const setFilters = (filters) => (dispatch) => {
    dispatch({ type: types.setFilters, payload: filters });
};

export const downloadReport = (filters) => async (dispatch) => {
    return dispatch(
        downloadXlsxFile(
            `${endpoint}/report?${combineIntoQueryParams(filters)}`,
            types.downloadReport.all,
            `raport_archiwum_${moment().format("YYYY-MM-DD")}`
        )
    );
};
