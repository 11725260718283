import React from "react";
import { useParams } from "react-router-dom";
import { DocumentArchiveCategoryDocument } from "Operations";
import { api } from "api";
import { common } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { useForm, useValidator } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { Form, FormRow, FormSelect, FormSwitch, FormTextField } from "components-ts/forms";
import DocumentCategoryValidator from "./validator";

const DocumentCategoryForm: React.FC = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const { data, status } = api.operations.documentArchiveCategories.useGetDocumentArchiveCategory(id);
    const validatorResolver = useValidator(new DocumentCategoryValidator());
    const form = useForm<DocumentArchiveCategoryDocument>({
        resolver: validatorResolver,
        values: data,
    });

    return (
        <Form
            form={form}
            headerText={{
                resource: "documentCategory",
                params: { id: id },
            }}
            formPath={Paths.Dictionaries.DocumentCategoryForm}
            listPath={Paths.Dictionaries.DocumentCategoriesList}
            initQueryStatus={status}
            submitMutation={api.operations.documentArchiveCategories.useCreateOrUpdatDocumentArchiveCategory(id)}
            id={id}
        >
            <FormRow>
                <FormTextField
                    name="name"
                    label={t(common.name)}
                />

                <FormTextField
                    name="description"
                    label={t(common.description)}
                />
            </FormRow>

            <FormRow>
                <FormSelect
                    name="levelOfConfidentiality"
                    label={t(common.levelOfConfidentiality)}
                    items={[
                        { value: "P0", name: "Poziom 0" },
                        { value: "P1", name: "Poziom 1" },
                    ]}
                    disableEmpty
                />

                <FormSwitch
                    name="isActive"
                    label={t(common.isActive)}
                />
            </FormRow>
        </Form>
    );
};

export default DocumentCategoryForm;
