import { combineReducers } from "redux";
import types from "./types";

const initialState = {
    campaigns: [],
    feedFormats: [],
    contentCategories: [],
    productsProducers: [],
    productsBrands: [],
    productsSuppliers: [],
    productsTags: [],
    productsCategories: [],
    vendors: [],
    documentCategories: [],
    documentTypes: [],
    brands: [],
    brandNames: [],
    userInfos: [],
    contactTypes: [],
    cnCodes: [],
    userInfosWithRole: {},
    analyticGroups: [],
    shopConfigurations: [],
    documentStatuses: [],
    vendorRecipients: [],
    vendorUsers: [],
    vendorSubsidiaryGainUsers: [],
    vendorSubsidiaryGainStatuses: [],
    documentDefinitions: [],
    countries: [],
    activePromotionPeriods: [],
    promotionPeriods: [],
    marketingTools: [],
    toolkitNames: [],
    toolkits: [],
    membershipSeasons: [],
    employeeDepartments: [],
    employees: [],
    procedureCategories: [],
    reservationGroups: [],
};

export default combineReducers({
    vendors: (state = initialState.vendors, { type, payload }) => {
        switch (type) {
            case types.vendors.success:
                return payload;
            default:
                return state;
        }
    },
    brands: (state = initialState.brands, { type, payload }) => {
        switch (type) {
            case types.brands.success:
                payload.sort((a, b) => (a.name.trim().toLowerCase() > b.name.trim().toLowerCase() ? 1 : -1));
                return payload;
            default:
                return state;
        }
    },
    brandNames: (state = initialState.brandNames, { type, payload }) => {
        switch (type) {
            case types.brandNames.success:
                return payload;
            default:
                return state;
        }
    },
    campaigns: (state = initialState.campaigns, { type, payload }) => {
        switch (type) {
            case types.campaigns.success:
                return payload;
            default:
                return state;
        }
    },
    feedFormats: (state = initialState.feedFormats, { type, payload }) => {
        switch (type) {
            case types.feedFormats.success:
                return payload;
            default:
                return state;
        }
    },
    contentCategories: (state = initialState.contentCategories, { type, payload }) => {
        switch (type) {
            case types.contentCategories.success:
                return payload;
            default:
                return state;
        }
    },
    productsProducers: (state = initialState.productsProducers, { type, payload }) => {
        switch (type) {
            case types.productsProducers.success:
                return payload;
            default:
                return state;
        }
    },
    productsSuppliers: (state = initialState.productsSuppliers, { type, payload }) => {
        switch (type) {
            case types.productsSuppliers.success:
                return payload;
            default:
                return state;
        }
    },
    productsBrands: (state = initialState.productsBrands, { type, payload }) => {
        switch (type) {
            case types.productsBrands.success:
                return payload;
            default:
                return state;
        }
    },
    productsTags: (state = initialState.productsTags, { type, payload }) => {
        switch (type) {
            case types.productsTags.success:
                return payload;
            default:
                return state;
        }
    },
    productsCategories: (state = initialState.productsCategories, { type, payload }) => {
        switch (type) {
            case types.productsCategories.success:
                return payload;
            default:
                return state;
        }
    },
    costReasons: (state = initialState.productsCategories, { type, payload }) => {
        switch (type) {
            case types.costReasons.success:
                return payload;
            default:
                return state;
        }
    },
    userInfos: (state = initialState.userInfos, { type, payload }) => {
        switch (type) {
            case types.userInfos.success:
                return payload;
            default:
                return state;
        }
    },
    userInfosWithRole: (state = initialState.userInfosWithRole, { type, payload }) => {
        switch (type) {
            case types.userInfosWithRole.success:
                return {
                    ...state,
                    [payload.role]: payload.users.map((u) => {
                        return { ...u, lastFetched: Date.now() };
                    }),
                };
            default:
                return state;
        }
    },
    contactTypes: (state = initialState.contactTypes, { type, payload }) => {
        switch (type) {
            case types.contactTypes.success:
                return payload;
            default:
                return state;
        }
    },
    cnCodes: (state = initialState.cnCodes, { type, payload }) => {
        switch (type) {
            case types.cnCodes.success:
                return payload;
            default:
                return state;
        }
    },
    analyticGroups: (state = initialState.analyticGroups, { type, payload }) => {
        switch (type) {
            case types.analyticGroups.success:
                return payload;
            default:
                return state;
        }
    },
    shopConfigurations: (state = initialState.shopConfigurations, { type, payload }) => {
        switch (type) {
            case types.shopConfigurations.success:
                return payload;
            default:
                return state;
        }
    },
    documentCategories: (state = initialState.documentCategories, { type, payload }) => {
        switch (type) {
            case types.documentCategories.success:
                return payload;
            default:
                return state;
        }
    },
    documentTypes: (state = initialState.documentTypes, { type, payload }) => {
        switch (type) {
            case types.documentTypes.success:
                return payload;
            default:
                return state;
        }
    },
    documentStatuses: (state = initialState.documentStatuses, { type, payload }) => {
        switch (type) {
            case types.documentStatuses.success:
                return payload;
            default:
                return state;
        }
    },
    vendorRecipients: (state = initialState.vendorRecipients, { type, payload }) => {
        switch (type) {
            case types.vendorRecipients.success:
                return payload;
            default:
                return state;
        }
    },
    vendorUsers: (state = initialState.vendorUsers, { type, payload }) => {
        switch (type) {
            case types.vendorUsers.success:
                return payload;
            default:
                return state;
        }
    },
    vendorSubsidiaryGainUsers: (state = initialState.vendorSubsidiaryGainUsers, { type, payload }) => {
        switch (type) {
            case types.vendorSubsidiaryGainUsers.success:
                return payload;
            default:
                return state;
        }
    },
    vendorSubsidiaryGainStatuses: (state = initialState.vendorSubsidiaryGainStatuses, { type, payload }) => {
        switch (type) {
            case types.vendorSubsidiaryGainStatuses.success:
                return payload;
            default:
                return state;
        }
    },
    documentDefinitions: (state = initialState.documentDefinitions, { type, payload }) => {
        switch (type) {
            case types.documentDefinitions.success:
                return payload;
            default:
                return state;
        }
    },
    countries: (state = initialState.countries, { type, payload }) => {
        switch (type) {
            case types.countries.success:
                return payload;
            default:
                return state;
        }
    },
    activePromotionPeriods: (state = initialState.activePromotionPeriods, { type, payload }) => {
        switch (type) {
            case types.activePromotionPeriods.success:
                return payload;
            default:
                return state;
        }
    },
    promotionPeriods: (state = initialState.promotionPeriods, { type, payload }) => {
        switch (type) {
            case types.promotionPeriods.success:
                return payload;
            default:
                return state;
        }
    },
    marketingTools: (state = initialState.marketingTools, { type, payload }) => {
        switch (type) {
            case types.marketingTools.success:
                return payload;
            default:
                return state;
        }
    },
    toolkitNames: (state = initialState.toolkitNames, { type, payload }) => {
        switch (type) {
            case types.toolkitNames.success:
                return payload;
            default:
                return state;
        }
    },
    toolkits: (state = initialState.toolkits, { type, payload }) => {
        switch (type) {
            case types.toolkits.success:
                return payload;
            default:
                return state;
        }
    },
    membershipSeasons: (state = initialState.membershipSeasons, { type, payload }) => {
        switch (type) {
            case types.membershipSeasons.success:
                return payload;
            default:
                return state;
        }
    },
    employeeDepartments: (state = initialState.employeeDepartments, { type, payload }) => {
        switch (type) {
            case types.employeeDepartments.success:
                return payload;
            default:
                return state;
        }
    },
    employees: (state = initialState.employees, { type, payload }) => {
        switch (type) {
            case types.employees.success:
                return payload;
            default:
                return state;
        }
    },
    procedureCategories: (state = initialState.procedureCategories, { type, payload }) => {
        switch (type) {
            case types.procedureCategories.success:
                return payload;
            default:
                return state;
        }
    },
    reservationGroups: (state = initialState.reservationGroups, { type, payload }) => {
        switch (type) {
            case types.reservationGroups.success:
            case types.setReservationGroups:
                return payload;
            default:
                return state;
        }
    },
});
