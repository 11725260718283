import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material";

export type Color = "primary" | "secondary" | "error" | "warning" | "info" | "success";

const useButtonStyles = makeStyles<{
    color: Color;
}>()((theme: Theme, { color }) => ({
    button: {
        "background": theme.palette[color].main,
        "color": theme.palette[color].contrastText,
        "&:hover": {
            background: theme.palette[color].dark,
        },
        "&.Mui-selected": {
            "background": theme.palette[color].dark,
            "color": theme.palette[color].contrastText,
            "&:hover": {
                background: theme.palette[color].dark,
                color: theme.palette[color].contrastText,
            },
        },
    },
}));

export default useButtonStyles;
