import Adable from "components/Adable/Adable";
import ArrayValidationError from "components/Controls/ArrayValidationError";
import Erasable from "components/Erasable/Erasable";
import { FormField } from "components/Form";
import { decimalNormalize } from "components/FormHelpers/ControlFormaters";
import { FormSection } from "redux-form";
import { withFormName } from "utils/hoc";
import { Grid } from "@mui/material";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { BillingPeriods } from "views/vendors/BillingPeriods";
import { validators } from "../../validators";
import CalculationBaseSelect from "./CalculationBaseSelect";
import ContractsAutocompletes from "./ContractsAutocompletes";

export const retroUnconditionalDiscountCountingMethods = [
    { value: "Total", name: "Sumarycznie" },
    { value: "Cascade", name: "Kaskadowo" },
];

const RetroUnconditionalDiscount = ({ fields, meta, readOnly, useDiff, focusRetroField, focusRef }) => {
    const { t } = useTranslation("vendor");
    return (
        <>
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
            >
                <Grid item>
                    <FormField
                        useDiff={useDiff}
                        name={"retroUnconditionalDiscountCountingMethod"}
                        type={"select"}
                        label={t(vendor.retroUnconditionalDiscountCountingMethod)}
                        items={retroUnconditionalDiscountCountingMethods}
                        hideDefaultItem={true}
                        readOnly={readOnly}
                        validate={validators.retroUnconditionalDiscountCountingMethod}
                    />
                </Grid>
            </Grid>
            <ArrayValidationError {...meta} />
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
            >
                <Adable
                    handleAdd={() => {
                        fields.push({});
                    }}
                    renderCondition={!readOnly}
                >
                    {fields.map((field, index) => {
                        return (
                            <Erasable
                                handleRemove={() => {
                                    fields.remove(index);
                                }}
                                renderCondition={!readOnly}
                                key={index}
                            >
                                <FormSection name={field}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        ref={field === focusRetroField ? focusRef : undefined}
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >
                                            <FormField
                                                useDiff={useDiff}
                                                name={"dateFrom"}
                                                label={t(vendor.dateFrom)}
                                                type="date"
                                                readOnly={readOnly}
                                                validate={validators.validateFrom}
                                            />
                                            <FormField
                                                useDiff={useDiff}
                                                name={"dateTo"}
                                                label={t(vendor.dateTo)}
                                                type="date"
                                                readOnly={readOnly}
                                                validate={validators.validateNullableDateTo}
                                            />
                                        </Grid>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >
                                            <FormField
                                                useDiff={useDiff}
                                                name={"billingPeriod"}
                                                label={t(vendor.billingPeriod)}
                                                type="select"
                                                items={BillingPeriods}
                                                validate={validators.required}
                                                readOnly={readOnly}
                                            />
                                            <FormField
                                                useDiff={useDiff}
                                                name={"discountValue"}
                                                label={t(vendor.discountValue)}
                                                type={"text"}
                                                validate={validators.percent}
                                                format={decimalNormalize}
                                                readOnly={readOnly}
                                                adornment={{
                                                    position: "end",
                                                    value: "%",
                                                }}
                                            />
                                            <CalculationBaseSelect
                                                readOnly={readOnly}
                                                useDiff={useDiff}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <FormField
                                            useDiff={useDiff}
                                            name={"description"}
                                            label={t(vendor.description)}
                                            type={"text"}
                                            readOnly={readOnly}
                                            multiline
                                            stretch
                                            validate={validators.description}
                                        />
                                    </Grid>

                                    <ContractsAutocompletes
                                        readOnly={readOnly}
                                        useDiff={useDiff}
                                    />
                                </FormSection>
                            </Erasable>
                        );
                    })}
                </Adable>
            </Grid>
        </>
    );
};

export default withFormName(RetroUnconditionalDiscount);
