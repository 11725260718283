import { useUser } from "context/UserContext/UserContext";
import procedureStatuses from "resource/procedureStatuses.json";
import React from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material/";
import { ProcedureResponse } from "Operations";
import { api } from "api";
import { common } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { useForm } from "utils-ts/hooks";
import { useMessages, usePush } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { PdfViewer } from "components-ts/PddfViewer";
import { Button } from "components-ts/controls";
import { Form, FormRow } from "components-ts/forms";
import { TextWithLabel } from "components-ts/text";
import { Spacing } from "components-ts/view";

const isAccepted = (status: string) => {
    switch (status) {
        case "New":
        case "Postponed":
        case "Updated":
        case "PostponedUpdate":
            return false;

        default:
            return true;
    }
};

const ProcedureForm: React.FC = () => {
    const { id } = useParams();
    const { profile } = useUser();
    const employeeId = profile?.sub;
    const { push } = usePush();
    const { t } = useTranslation(["common", "procedures"]);
    const { data, status, ...query } = api.operations.employeeProcedures.useFindProcedure(employeeId, id);
    const { showSuccessMessage, showErrorMessage } = useMessages();
    const form = useForm<ProcedureResponse>({
        values: data,
    });

    const makeDecisionMutation = api.operations.employeeProcedures.useMakeDecision(employeeId, id);

    return (
        <Form
            form={form}
            headerText={{ resource: "common.document", params: { id: data?.name } }}
            formPath={Paths.Procedure}
            listPath={Paths.Procedures}
            initQueryStatus={status}
            id={id}
            hideSubmit={true}
        >
            <FormRow>
                <TextWithLabel
                    label={t("status")}
                    value={procedureStatuses.find((o) => o.value === data?.acceptStatus)?.name || ""}
                />
                <TextWithLabel
                    label={t("version")}
                    value={data?.procedureVersion.toString()}
                />
            </FormRow>
            <Grid
                container
                direction="row"
                justifyContent="center"
            >
                <PdfViewer
                    isLoading={false}
                    file={data?.file || ""}
                    mode={"base64"}
                    autoSize={false}
                />
            </Grid>
            {!isAccepted(data?.acceptStatus) && data?.needsAcceptance && (
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                >
                    <Grid
                        item
                        xs={6}
                    >
                        <Spacing spacing={1}>
                            <Button
                                type="button"
                                fullWidth
                                color="success"
                                onClick={async () => {
                                    const result = await makeDecisionMutation.mutateAsync(
                                        { isAccepted: true, procedureVersion: data?.procedureVersion },
                                        {
                                            onSuccess: () => showSuccessMessage(t(common.saveSuccess)),
                                            onError: () => showErrorMessage(t(common.saveFail)),
                                        }
                                    );

                                    if (!!result.nextProcedure) {
                                        push(Paths.GeneratePath(Paths.Procedure, { id: result.nextProcedure }));
                                    } else {
                                        query.refetch();
                                    }
                                }}
                                label="accept"
                            />
                        </Spacing>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                    >
                        <Spacing spacing={1}>
                            <Button
                                type="button"
                                fullWidth
                                color="error"
                                onClick={async () => {
                                    const result = await makeDecisionMutation.mutateAsync(
                                        { isAccepted: false, procedureVersion: data?.procedureVersion },
                                        {
                                            onSuccess: () => showSuccessMessage(t(common.saveSuccess)),
                                            onError: () => showErrorMessage(t(common.saveFail)),
                                        }
                                    );

                                    if (!!result.nextProcedure) {
                                        push(Paths.GeneratePath(Paths.Procedure, { id: result.nextProcedure }));
                                    } else {
                                        query.refetch();
                                    }
                                }}
                                label="postpone"
                            />
                        </Spacing>
                    </Grid>
                </Grid>
            )}
        </Form>
    );
};

export default ProcedureForm;
