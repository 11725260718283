import { PurchaseScheduleDocument } from "Commerce-Offer";
import React from "react";
import { api } from "api";
import { useColumns } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { QueryTable } from "components-ts/tables";
import { View } from "components-ts/view";

const PurchaseSchedulesList: React.FC = () => {
    const { data: vendors = [] } = api.operations.autocomplete.useFindVendors();

    const columns = useColumns<PurchaseScheduleDocument>(() => [
        {
            property: "warehouse",
            label: "warehouse",
        },
        {
            property: "supplierId",
            label: "supplier",
            as: (value: unknown) => {
                const vendor = vendors.find((v) => v.vendorId.startsWith(value as string));

                return vendor ? `${vendor.name} (${value})` : value?.toString();
            },
        },
        {
            property: "isActive",
            label: "isActive",
            as: "boolean",
        },
        {
            property: "createdAt",
            label: "createdAt",
            as: "dateTime",
        },
        {
            property: "updatedAt",
            label: "updatedAt",
            as: "dateTime",
        },
        {
            actionType: "preview",
            link: (item) =>
                Paths.GeneratePath(Paths.Offers.PurchaseScheduleForm, {
                    id: item.id,
                }),
        },
    ]);

    return (
        <View
            headerText="purchaseSchedule"
            isMainView
        >
            <QueryTable
                queryHook={api.commerce.offers.purchaseSchedules.useFindPurchaseSchedule}
                columns={columns}
                formPath={Paths.Offers.PurchaseScheduleForm}
                defaultParams={{ isActive: true }}
            />
        </View>
    );
};

export default PurchaseSchedulesList;
