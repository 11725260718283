const orders = {
    accountType: "accountType",
    accountNumber: "accountNumber",
    address: "address",
    email: "email",
    floorNumber: "floorNumber",
    fullName: "fullName",
    hasElevator: "hasElevator",
    isAdult: "isAdult",
    isBusinessAccount: "isBusinessAccount",
    isNewWindow: "isNewWindow",
    isOfficeAccount: "isOfficeAccount",
    isSpecialClient: "isSpecialClient",
    isTestAccount: "isTestAccount",
    isVipAccount: "isVipAccount",
    isVipReservation: "isVipReservation",
    isVipWindow: "isVipWindow",
    language: "language",
    membershipLevel: "membershipLevel",
    membershipPoints: "membershipPoints",
    mobileNumber: "mobileNumber",
    order: "order",
    orderData: "orderData",
    orders: "orders",
    orderDataTab: "orderDataTab",
    orderId: "orderId",
    paymentDataTab: "paymentDataTab",
    phoneNumber: "phoneNumber",
    price: "price",
    productId: "productId",
    productList: "productList",
    productName: "productName",
    quantity: "quantity",
    quantityOfSingleItems: "quantityOfSingleItems",
    singleItemsLeft: "singleItemsLeft",
    recipient: "recipient",
    stairwayNumber: "stairwayNumber",
    status: "status",
    total: "total",
    totalInvoice: "totalInvoice",
    warehouse: "warehouse",
    createComplaint: "createComplaint",
    validityPeriod: "validityPeriod",
    grammage: "grammage",
    send: "send",
    vatin: "vatin",
    paymentMethod: "paymentMethod",
    cashAmount: "cashAmount",
    onlineAmount: "onlineAmount",
    onlineCompletedAt: "onlineCompletedAt",
    totalBeforeDiscount: "totalBeforeDiscount",
    totalBeforePromotion: "totalBeforePromotion",
    totalBeforeVoucher: "totalBeforeVoucher",
    deliveryCost: "deliveryCost",
    waterDeliveryCost: "waterDeliveryCost",
    packagingCost: "packagingCost",
    totalWithDeliveryCost: "totalWithDeliveryCost",
    totalPayment: "totalPayment",
    isCollectiveInvoice: "isCollectiveInvoice",
    invoiceInPDF: "invoiceInPDF",
    isPersonalizationDisabled: "isPersonalizationDisabled",
    companyName: "companyName",
    transaction: "transaction",
    externalId: "externalId",
    createdAt: "createdAt",
    placedAt: "placedAt",
    grossTotal: "grossTotal",
    deliveryAt: "deliveryAt",
    deliveryAddress: "deliveryAddress",
    goTo: "goTo",
    isEarlyEntranceAllowed: "isEarlyEntranceAllowed",
    isEarlyEntranceAllowedShort: "isEarlyEntranceAllowedShort",
    ean: "ean",
    grossPrice: "grossPrice",
    grossPriceInvoice: "grossPriceInvoice",
    addProduct: "addProduct",
    complaintsDataTab: "complaintsDataTab",
    complaintNumber: "complaintNumber",
    documentNumber: "documentNumber",
    valueCorrection: "valueCorrection",
    quantitytCorrection: "quantitytCorrection",
    upsell: "upsell",
    productFullName: "productFullName",
    add: "add",
    complaintFor: "complaintFor",
    reason: "reason",
    reasonCode: "reasonCode",
    correctionType: "correctionType",
    delivery: "delivery",
    deliveryMethod: "deliveryMethod",
    deliveryStartsAt: "deliveryStartsAt",
    deliveryEndsAt: "deliveryEndsAt",
    upsellType: "upsellType",
    paymentMethodDelivery: "paymentMethodDelivery",
    packagingMethod: "packagingMethod",
    deliveryDate: "deliveryDate",
    deliveryWindow: "deliveryWindow",
    shippingAddressInstructions: "shippingAddressInstructions",
    products: "products",
    quantityInvoice: "quantityInvoice",
    description: "description",
    save: "save",
    saveAndNavigateToVoucherForm: "saveAndNavigateToVoucherForm",
    apartmentNumber: "apartmentNumber",
    buildingNumber: "buildingNumber",
    postcode: "postcode",
    instructions: "instructions",
    street: "street",
    city: "city",
    futureOrderDeliveryDate: "futureOrderDeliveryDate",
    complaintValue: "complaintValue",
    invoice: "invoice",
    documentId: "documentId",
    value: "value",
    orderWithId: "orderWithId",
    cancelCorrection: "cancelCorrection",
    editComplaint: "editComplaint",
    operationReason: "operationReason",
    billingAddress: "billingAddress",
    userInfo: "userInfo",
    orderMembershipPoints: "orderMembershipPoints",
    orderDetails: "orderDetails",
    cancelComplaintQuestion: "cancelComplaintQuestion",
    complaintCancelation: "complaintCancelation",
    paymentsDataTab: "paymentsDataTab",
    fullRefund: "fullRefund",
    balanceSettlement: "balanceSettlement",
    orderTotal: "orderTotal",
    fullOrderRefundQuestion: "fullOrderRefundQuestion",
    searchBy: "searchBy",
    downloadInvoice: "downloadInvoice",
    invoiceTotal: "invoiceTotal",
    notificationTime: "notificationTime",
    customer: "customer",
    orderAlerts: "orderAlerts",
    suspiciousTotal: "suspiciousTotal",
    suspiciousProductsCount: "suspiciousProductsCount",
    suspiciousOrders: "suspiciousOrders",
    suspiciousTerms: "suspiciousTerms",
    dismissAlert: "dismissAlert",
    more: "more",
    orderAlert: "orderAlert",
    suspiciousProducts: "suspiciousProducts",
    suspiciousOrdersTitle: "suspiciousOrdersTitle",
    deliveryFrom: "deliveryFrom",
    campaignName: "campaignName",
    deliveryTo: "deliveryTo",
    search: "search",
    orderSearch: "orderSearch",
    searchType: "searchType",
    releaseNotice: "releaseNotice",
    fv: "fv",
    correctionEdit: "correctionEdit",
    correctionCreate: "correctionCreate",
    campaigns: "campaigns",
    diffrence: "diffrence",
    orderRefund: "orderRefund",
    correctionDocument: "correctionDocument",
    invoiceNumberFrisco: "invoiceNumberFrisco",
    invoiceGrossFrisco: "invoiceGrossFrisco",
    invoiceNumberF24: "invoiceNumberF24",
    invoiceGrossF24: "invoiceGrossF24",
    waybillNumber: "waybillNumber",
    amountToCollect: "amountToCollect",
    shippingAddress: "shippingAddress",
    eans: "eans",
    vouchers: "vouchers",
    routeNumber: "routeNumber",
    segments: "segments",
    priceBeforeVoucher: "priceBeforeVoucher",
    stock: "stock",
    actualStock: "actualStock",
    externalStock: "externalStock",
    reservedStock: "reservedStock",
    Basic: "Basic",
    VoucherId: "VoucherId",
    ProductId: "ProductId",
    Ean: "Ean",
    correctionDocumentValue: "correctionDocumentValue",
    closesAt: "closesAt",
} as const;

export default orders;
