import { Location, useLocation } from "react-router";
import { useParams } from "react-router-dom";

const useCopyParams = () => {
    const { id } = useParams<{ id?: string }>();
    const location = useLocation() as Location<{ id?: string; isCopy?: string }>;
    const { isCopy, id: copyId } = location.state || {};

    return {
        isCopy: isCopy === "true",
        id: isCopy ? copyId : id,
    };
};

export default useCopyParams;
