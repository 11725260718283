import { generatePath } from "react-router-dom";
import { ProductSubstitute, ProductSubstituteListResponse, ProductSubstitutePageRequest } from "Crawler";
import { useCreateOrUpdateMutation, useGetPagedQuery, useGetQuery } from "api/hooks";

const queryKey = "api/product-substitute/";

const url = (id?: string) => generatePath(`${queryKey}:id?`, { id: id ? id : null });

export const useFindProductSubstitutes = (initailParams: ProductSubstitutePageRequest) => {
    return useGetPagedQuery<ProductSubstituteListResponse, ProductSubstitutePageRequest>({
        app: "crawler",
        url: url(),
        queryParams: initailParams,
    });
};

export const useGetProductSubstitute = (id?: string) => {
    return useGetQuery<ProductSubstitute>({
        app: "crawler",
        url: url(id),
        shouldMakeRequest: Boolean(id),
    });
};

export const useUpdateProductSubstitute = (id?: string) => {
    return useCreateOrUpdateMutation<ProductSubstitute, ProductSubstitute>({
        app: "crawler",
        url: url(id),
        id: id,
    });
};
