import { withStyles } from "tss-react/mui";
import Grid from "@mui/material/Grid";
import GridHeader from "./GridHeader";

const style = (theme) => ({
    grid: {
        margin: "0 -15px !important",
        width: "unset",
    },
    gridWithHeader: {
        marginBottom: theme.spacing(4),
    },
});

function GridContainer(props) {
    const { children, title, iconName, translateTitle, ...rest } = props;
    const classes = withStyles.getClasses(props);

    return (
        <>
            <GridHeader
                title={title}
                iconName={iconName}
                translateTitle={translateTitle}
            />
            <Grid
                container
                {...rest}
                className={title ? classes.gridWithHeader : classes.grid}
            >
                {children}
            </Grid>
        </>
    );
}

export default withStyles(GridContainer, style);
