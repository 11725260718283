import { IconButton } from "components/Button";
import React from "react";
import { InputAdornment as MUIInputAdorment } from "@mui/material";

const InputAdornment = ({ position, icon, onClick, disabled }) => {
    return (
        <MUIInputAdorment position={position}>
            <IconButton
                icon={icon}
                onClick={onClick}
                disabled={disabled}
            />
        </MUIInputAdorment>
    );
};

export default React.memo(InputAdornment);
