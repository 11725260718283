import defaultListState from "store/defaultListState";
import { types } from "./action";

const initialState = {
    ...defaultListState,
    selectedFiles: null,
    importCompleted: false,
};

export const customerDepositsImport = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case types.CUSTOMER_DEPOSISTS_IMPORT_POST:
            return {
                ...state,
                isLoading: true,
                importCompleted: false,
            };
        case types.CUSTOMER_DEPOSISTS_IMPORT_POST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                importCompleted: true,
                selectedFiles: null,
            };
        case types.CUSTOMER_DEPOSISTS_IMPORT_GET_FAILURE:
        case types.CUSTOMER_DEPOSISTS_IMPORT_POST_FAILURE:
        case types.CUSTOMER_DEPOSISTS_IMPORT_DELETE_FAILURE:
            return { ...state, isLoading: false };
        case types.CUSTOMER_DEPOSISTS_IMPORT_GET:
        case types.CUSTOMER_DEPOSISTS_IMPORT_DELETE:
            return { ...state, isLoading: true };
        case types.CUSTOMER_DEPOSISTS_IMPORT_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                ...payload,
            };
        case types.CUSTOMER_DEPOSISTS_IMPORT_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return { ...state };
    }
};
