import React from "react";

const SunEditor = React.lazy(() => import("./SunEditor"));

export default class PimRichTextField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
            readonly: props.readonly || props.readOnly,
            rerender: false,
        };
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.readonly || prevProps.readOnly) !== (this.props.readonly || this.props.readOnly)) {
            this.setState({
                readonly: this.props.readonly || this.props.readOnly,
                value: this.props.value,
                rerender: true,
            });
        } else if (this.state.rerender) {
            this.setState({ rerender: false, value: this.props.value });
        }
    }

    onChange = (value) => {
        if (this.props.onChange) {
            // Send the changes up to the parent component as an HTML string.
            // This is here to demonstrate using `.toString()` but in a real app it
            // would be better to avoid generating a string on each change.
            this.props.onChange(value);
        }
    };

    render() {
        if (this.state.rerender) {
            return <></>;
        }

        return (
            <SunEditor
                {...this.props}
                setContents={this.state.value}
                onChange={this.onChange}
                readonly={this.state.readonly}
            />
        );
    }
}
