import types from "./types";

const initialState = {
    isLoading: false,
    isSubbmitRequested: false,
};

export default (state = initialState, { type, payload, meta }) => {
    switch (type) {
        case types.initForm:
            return initialState;

        case types.getReward.request:
            return { ...initialState, isLoading: true };
        case types.getReward.success:
            return {
                ...state,
                ...payload,
                id: meta.isCopy ? "" : payload.id,
                isLoading: false,
            };
        case types.getReward.failure:
            return { ...payload, isLoading: false };

        case types.createOrUpdateReward.request:
            return { ...state, isSubbmitRequested: true };
        case types.createOrUpdateReward.success:
        case types.createOrUpdateReward.failure:
            return { ...state, isSubbmitRequested: false };

        case types.getRewardReservationCount.request:
            return { ...state };
        case types.getRewardReservationCount.success:
            return { ...state, reservation: payload };
        case types.getRewardReservationCount.failure:
            return {
                ...state,
                reservation: { reservationCount: 0, lastReservedAt: undefined },
            };

        default:
            return state;
    }
};
