import { commerce } from "store/actionTypePrefixes";
import { AsyncTypes, PagedAsyncTypes, asAsyncFormTypes } from "utils/extensions";

const types = {
    paginatedList: new PagedAsyncTypes("PAYMENTS_GET_LIST", commerce),
    list: new AsyncTypes("PAYMENTS_GET_LIST", commerce),
    withhold: new AsyncTypes("WITHHOLD_PAYMENT", commerce, [asAsyncFormTypes]),
};

export default types;
