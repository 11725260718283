import { toDictionary } from "utils/extensions/arrayExtensions";
import { CartPredicate } from "utils/models/CommerceForms";

export class ContestForm {
    constructor(contest) {
        this.divisions = contest.divisions;
        this.validFrom = contest.validFrom;
        this.validTo = contest.validTo;
        this.contestName = contest.contestName;
        this.comment = contest.comment;
        this.contentData = contest.contentData ? toDictionary(contest.contentData) : contest.contentData;
        this.email = contest.email;
        this.products = contest.products ? contest.products.map((p) => p.productId) : contest.products;
        this.isActive = contest.isActive;
        this.id = contest.id;
        this.updatedAt = contest.updatedAt;
        this.createdAt = contest.createdAt;
        this.version = contest.version;
        this.hasAnswers = contest.hasAnswers;
        this.hasAttachments = contest.hasAttachments;
        this.generatorId = contest.generatorId;
        this.requirements = new CartPredicate(contest.requirements);
        this.requiredQuantity = contest.requiredQuantity;
        this.requiredValue = contest.requiredValue;
        this.isSystem = contest.isSystem;
    }
}

export class RuleForm {
    constructor(rule) {
        this.id = rule.id;
        this.createdAt = rule.createdAt;
        this.updatedAt = rule.updatedAt;
        this.name = rule.name;
        this.optionalFrom = rule.optionalFrom;
        this.obligatoryFrom = rule.obligatoryFrom;
        this.isActive = rule.isActive;
        this.merchant = rule.merchant;
        this.contentData = rule.contentData && toDictionary(rule.contentData);
        this.isSystem = rule.isSystem;
    }
}
