import { FormField } from "components/Form";
import allSegments from "resource/segments.json";
import { orderBy } from "utils/extensions/arrayExtensions";
import { withFormName } from "utils/hoc";
import { useFormValueSelector } from "utils/hooks";

const SingleSegmentsSelectField = ({ form, sectionPrefix, name, label, onlyDynamic = false, ...props }) => {
    let formValue = useFormValueSelector(form, `${sectionPrefix ? `${sectionPrefix}.` : ""}${name}`, []);
    const notSelectedSegments = orderBy(
        allSegments.filter((x) => formValue.every((y) => y !== x.value)),
        (x) => x
    ).map((x) => x.value);

    return (
        <FormField
            {...props}
            name={name}
            label={label}
            type={"autocomplete"}
            items={onlyDynamic ? [] : orderBy(formValue.concat(notSelectedSegments))}
            getOptionLabel={(value) => {
                let translation = allSegments.find((x) => x.value === value);
                return translation != null ? translation.name : value;
            }}
            multiple
            freeSolo
            disableCloseOnSelect
            liveValidate
        />
    );
};

export default withFormName(SingleSegmentsSelectField);
