import React from "react";
import Menu from "@mui/material/Menu";
import Button from "./Button";

export default function SimpleMenu({ title, children, disabled }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                disabled={disabled}
                actionType="primary"
                onClick={handleClick}
            >
                {title}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {children}
            </Menu>
        </div>
    );
}
