import { makeStyles } from "tss-react/mui";
import React from "react";
import Grid from "@mui/material/Grid";
import GridHeader from "./GridHeader";

const useStyles = makeStyles()((theme) => ({
    grid: {
        margin: "0 -15px !important",
        width: "unset",
    },
    gridWithHeader: {
        marginBottom: theme.spacing(4),
    },
}));

const GridContainer: React.FC<{
    children: React.ReactNode;
    title: string;
    subtitle?: string;
    iconName: string;
    translateTitle?: boolean;
    translateSubtitle?: boolean;
    hideBottomLine?: boolean;
}> = ({ children, title, subtitle, iconName, translateTitle = true, translateSubtitle = true, hideBottomLine = false }) => {
    const { classes } = useStyles();

    return (
        <>
            <GridHeader
                title={title}
                subtitle={subtitle}
                iconName={iconName}
                translateTitle={translateTitle}
                translateSubtitle={translateSubtitle}
                hideBottomLine={hideBottomLine}
            />
            <Grid
                container
                className={title ? classes.gridWithHeader : classes.grid}
            >
                {children}
            </Grid>
        </>
    );
};

export default GridContainer;
