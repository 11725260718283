import { PostRequest, PutRequest, post, put } from "utils/extensions";
import types from "./types";

const endpoint = (userId) => `api/user-access${userId ? `/${userId}` : ""}`;

export const getUserPermissions = (userId, roles) => async (dispatch) => {
    dispatch(
        post(
            new PostRequest(
                endpoint(userId) + "/access",
                [
                    types.getUserPermissions.request,
                    types.getUserPermissions.success,
                    {
                        type: types.getUserPermissions.failure,
                        meta: { hideError: true },
                    },
                ],
                {
                    body: roles,
                }
            )
        )
    );
};

export const createOrUpdateUserPermissions = (userId, values, isNew) => (dispatch) => {
    if (isNew) {
        dispatch(post(new PostRequest(endpoint(userId), types.createOrUpdate.all, { body: values })));
    } else {
        dispatch(put(new PutRequest(endpoint(userId), types.createOrUpdate.all, { body: values })));
    }
};
