import React from "react";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import { AccountantDepartment, SupportDepartment } from "routing-ts/roles";

const dashboardpage = React.lazy(() => import("views/dashboard/Dashboard"));

export const Dashboard: PrivateRoute[] = [
    new PrivateRoute(
        Paths.Dashboard,
        [
            "SalesManager",
            "EcommerceManager",
            "Management",
            "LogisticianManager",
            "Auditor",
            "AXUser",
            ...AccountantDepartment.GetRoles(),
            ...SupportDepartment.GetRoles(),
        ],
        dashboardpage,
        "dashboard",
        "dashboard"
    ),
];
