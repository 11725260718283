import { generatePath } from "react-router-dom";
import { Category, FindCategoriesModel, FindCategoriesResponse } from "Content";
import { useCreateOrUpdateMutation, useGetPagedQuery, useGetQuery } from "api/hooks";

const queryKey = "api/v1/categories/";

const url = (id?: string) => generatePath(`${queryKey}:id?`, { id: id ? id : null });

export const useFindCategories = (initailParams: FindCategoriesModel) => {
    return useGetPagedQuery<FindCategoriesResponse, FindCategoriesModel>({
        app: "content",
        url: queryKey + "find-categories",
        method: "POST",
        body: initailParams,
    });
};

export const useGetCategory = (id?: string) => {
    return useGetQuery<Category>({ app: "content", url: url(id), shouldMakeRequest: Boolean(id) });
};

export const useCreateOrUpdateCategory = (id?: string) => {
    return useCreateOrUpdateMutation<Category, Category>({ app: "content", url: url(id), id: id });
};
