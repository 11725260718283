import { manager, operations } from "store/actionTypePrefixes";
import { AsyncTypes, asAsyncFormTypes, createAction } from "utils/extensions";

const types = {
    formInit: createAction("FORM_INIT_CN_STAKES", manager),
    getCnStakes: new AsyncTypes("GET_CN_STAKES", operations),
    createOrUpdate: new AsyncTypes("CREATE_OR_UPDATE_CN_STAKES", operations, [(types) => asAsyncFormTypes(types)]),
    validateCnStakeName: new AsyncTypes("VALIDATE_CN_STAKE_NAME", operations),
};

export default types;
