import { FieldValues, KeepStateOptions, UseFormProps, useForm as useFormBase } from "react-hook-form";

export const resetOptions: KeepStateOptions = {
    keepErrors: true,
    keepDirty: false, //when true, form stays isDirty after submit
    keepDirtyValues: false, //when true, form stays isDirty after submit
    keepValues: false, //when true, data not showing
    keepDefaultValues: false,
    keepIsSubmitted: false,
    keepIsSubmitSuccessful: false,
    keepTouched: false,
    keepIsValidating: false,
    keepIsValid: true,
    keepSubmitCount: true,
};

const useForm = <TFieldValues extends FieldValues = FieldValues, TContext extends object = object>(
    formProps: Pick<UseFormProps<TFieldValues, TContext>, "resolver" | "values">
) => {
    const props: UseFormProps<TFieldValues, TContext> = {
        mode: "onSubmit",
        reValidateMode: "onChange",
        shouldFocusError: true,
        shouldUnregister: false,
        shouldUseNativeValidation: false,
        criteriaMode: "all",
        resetOptions: resetOptions,
        defaultValues: undefined,
        resolver: formProps.resolver,
        values: formProps.values,
    };

    const form = useFormBase(props);

    return form;
};

export default useForm;
