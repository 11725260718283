import { manager, operations } from "store/actionTypePrefixes";
import { AsyncTypes, asAsyncFormTypes, createAction } from "utils/extensions";

const types = {
    getPromotionPeriods: new AsyncTypes("GET_PROMOTION_PERIODS", operations),
    getPromotionPeriod: new AsyncTypes("GET_PROMOTION_PERIOD", operations),
    removePromotionPeriod: new AsyncTypes("DELETE_PROMOTION_PERIOD", operations),
    createPromotionPeriod: new AsyncTypes("CREATE_PROMOTION_PERIOD", operations, [(types) => asAsyncFormTypes(types)]),
    updatePromotionPeriod: new AsyncTypes("UPDATE_PROMOTION_PERIOD", operations, [asAsyncFormTypes]),
    formInit: createAction("FORM_INIT_PROMOTION_PERIOD", manager),
    preCommit: new AsyncTypes("PRE_COMMIT_PROMOTION_PERIOD", operations),
};

export default types;
