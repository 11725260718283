import { RSAA } from "redux-api-middleware";
import { combineIntoQueryParams } from "utils/objectExtensions";
import types from "./types";

export const getCustomers = (payload) => async (dispatch) => {
    const params = combineIntoQueryParams({
        ...payload,
    });

    const result = await dispatch({
        [RSAA]: {
            endpoint: `api/v2/users/query?${params}`,
            method: "GET",
            types: types.getCustomers.all,
        },
    });

    await dispatch({ type: types.filtersChanged, payload });

    return Promise.resolve(result);
};

export const updatePagination = (pagination) => (dispatch) => {
    return dispatch({ type: types.updatePagination, payload: pagination });
};

export const initView = () => (dispatch) => {
    return dispatch({ type: types.initializeCustomerList });
};
