import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ExpandMore } from "@mui/icons-material";
import { Icon, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import classnames from "classnames";
import { createSelector } from "reselect";

const useStyles = makeStyles()((theme) => ({
    itemLink: {
        "marginBottom": theme.spacing(0.2),
        "transition": "all 200ms linear",
        "backgroundColor": "transparent",
        "borderRadius": theme.shape.borderRadius,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            boxShadow: theme.palette.primary.main,
        },
    },
    activeItem: {
        backgroundColor: theme.palette.primary.main,
        boxShadow: theme.palette.primary.dark,
    },
    text: {
        color: "#eeeeee",
    },
    icon: {
        color: "#dddddd",
        fontSize: 4,
    },
    alertIcon: {
        color: theme.palette.error.light,
    },
    collapseIconOpen: {
        transform: "rotate(180deg)",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.standard,
        }),
    },
    collapseIconClose: {
        transform: "rotate(-360deg)",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.standard,
        }),
    },
}));

const rootSelector = (state) => state.alert;
const newAlertSelector = createSelector(rootSelector, (alert) => alert?.newAlert);

const NavListItem = ({ navigationItem, open, handleClick }) => {
    const isLeaf = navigationItem?.items?.length > 0;
    const { pathname } = useLocation();
    const active = pathname.startsWith(navigationItem.path);
    const newAlert = useSelector(newAlertSelector);
    const isAlertPathWithError = navigationItem.path?.includes("content/alerts") && newAlert;
    const { classes } = useStyles({ active });
    const { t } = useTranslation("routing");

    return (
        <ListItem
            className={classnames({
                [classes.itemLink]: true,
                [classes.activeItem]: active && !isLeaf,
            })}
            onClick={handleClick}
        >
            {isLeaf ? (
                <ListItemIcon className={classes.icon}>
                    {<ExpandMore className={open ? classes.collapseIconOpen : classes.collapseIconClose} />}
                </ListItemIcon>
            ) : (
                <ListItemIcon className={isAlertPathWithError ? classes.alertIcon : classes.icon}>
                    <Icon>{isAlertPathWithError ? "notification_important" : navigationItem.icon}</Icon>
                </ListItemIcon>
            )}
            <ListItemText
                className={classes.text}
                primary={t(navigationItem.sidebarName)}
            />
        </ListItem>
    );
};

export default NavListItem;
