import { createSelector } from "reselect";

const root = (state) => {
    return {
        autocomplete: state.autocomplete,
        documentArchive: state.documentArchive,
        users: state.cache?.userInfos,
        roles: state.userRoles?.roles,
    };
};

export const userPermissionsSelector = createSelector(root, (state) => {
    const { documentArchive, autocomplete, users, roles } = state;
    const { userPermissions } = documentArchive;
    const { userAccess, roleAccesses, documentCategoriesAccesses, documentTypeAccesses, documentArchives } = userPermissions;

    return {
        documentType: userAccess?.archiveDocumentTypeIds?.map((x) => ({
            id: x,
            ...autocomplete?.documentTypes?.find((y) => y.id === x),
        })),
        documentCategory: userAccess?.archiveDocumentCategoryIds?.map((x) => ({
            id: x,
            ...autocomplete?.documentCategories?.find((y) => y.id === x),
        })),
        users,
        roles: roles || [],
        isNew: userPermissions.isNew,
        userDocumentsIdsAccess: userAccess?.archiveDocumentsIds,
        roleAccesses,
        documentCategoriesAccesses,
        documentTypeAccesses,
        documentArchives,
    };
});
