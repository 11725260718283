import defaultListState from "store/defaultListState";
import types from "./types";

const { paginatedList, list } = types;

const initialState = defaultListState;

export const paymentList = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case list.request:
        case paginatedList.request:
            return { ...initialState, isLoading: true };
        case list.success:
            return {
                ...state,
                items: [...payload],
                isLoading: false,
            };
        case paginatedList.success:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case list.failure:
        case paginatedList.failure:
            return { ...state, isLoading: false };
        default:
            return { ...state };
    }
};
