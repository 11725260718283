import { FileType } from "consts-ts/fileTypes";
import { useCommonTranslation } from "utils/hooks";
import { useState } from "react";
import { Chip, Grid } from "@mui/material";
import { Button } from "../buttons";

type FileImportProps = {
    isImporting: boolean;
    downloadTemplate?: () => void;
    accept: FileType[] | FileType;
} & (
    | {
          multiple: true;
          validateInput: (files: File[]) => void;
          handleValidateFile: (files: File[]) => void;
      }
    | {
          multiple?: never;
          validateInput: (file: File) => void;
          handleValidateFile: (file: File) => void;
      }
    | {
          validateInput?: never;
          handleValidateFile?: never;
      }
) &
    (
        | {
              multiple: true;
              handleImport: (files: File[]) => Promise<boolean> | boolean;
          }
        | {
              multiple?: never;
              handleImport: (file: File) => Promise<boolean> | boolean;
          }
    );

const FileImport = ({ accept, isImporting, downloadTemplate, handleImport, validateInput, handleValidateFile, multiple }: FileImportProps) => {
    const { t, common } = useCommonTranslation();
    const [files, setFiles] = useState<File[] | undefined>(undefined);

    return (
        <Grid
            container
            direction="row"
            spacing={1}
        >
            {!!downloadTemplate && (
                <Grid item>
                    <Button
                        type="button"
                        actionType="secondary"
                        color="info"
                        fullWidth
                        onClick={downloadTemplate}
                        label={common.downloadTemplate}
                    />
                </Grid>
            )}

            <Grid item>
                <Button
                    onClick={() => {
                        setFiles(undefined);
                        document.getElementById("fileInput")?.click();
                    }}
                    variant="outlined"
                    type="button"
                    color="primary"
                    fullWidth
                    isLoading={isImporting}
                    label={files?.map((f) => f.name)?.join(",") || "Wybierz plik"}
                    literalLabel
                />

                <Grid>
                    <input
                        accept={Array.isArray(accept) ? accept.map((a) => a.mimeType).join(",") : accept.mimeType}
                        id="fileInput"
                        onChange={(event) => {
                            const files = event.target.files ? [...event.target.files] : [];
                            if (validateInput) {
                                if (multiple) {
                                    validateInput(files);
                                } else {
                                    validateInput(files[0]);
                                }
                            }

                            if (files && !multiple) {
                                setFiles([files[0]]);
                            } else if (files && !!multiple) {
                                setFiles([...files]);
                            }
                        }}
                        type="file"
                        style={{ display: "none" }}
                        multiple={multiple}
                    />
                </Grid>
            </Grid>

            {!!handleValidateFile && (
                <Grid item>
                    <Button
                        type="button"
                        actionType="accept"
                        fullWidth
                        onClick={() => {
                            if (files) {
                                if (multiple) {
                                    handleValidateFile(files);
                                } else {
                                    handleValidateFile(files[0]);
                                }
                            }
                        }}
                        isLoading={isImporting}
                        disabled={!files}
                        label={t(common.validateFile)}
                    />
                </Grid>
            )}

            <Grid item>
                <Button
                    type="button"
                    actionType="accept"
                    color="primary"
                    fullWidth
                    onClick={async () => {
                        if (files) {
                            const result = multiple ? handleImport(files) : handleImport(files[0]);
                            let isSuccess: boolean = false;
                            if (typeof result === "boolean") {
                                isSuccess = result;
                            } else {
                                isSuccess = await result;
                            }

                            if (isSuccess) {
                                setFiles(undefined);
                            }
                        }
                    }}
                    isLoading={isImporting}
                    disabled={!files}
                    label={common.import}
                />
            </Grid>

            <Grid
                item
                xs={10}
            >
                {!!files &&
                    !!multiple &&
                    files.map((file, index) => {
                        return (
                            <Chip
                                label={file.name}
                                variant="outlined"
                                key={index}
                            />
                        );
                    })}
            </Grid>
        </Grid>
    );
};

export default FileImport;
