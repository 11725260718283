import moment, { Moment } from "moment";
import _ from "lodash";
import {
    dateToString,
    isDate,
} from "utils-ts/functions";
import {
    tValidation,
    validation,
} from "utils-ts/validations/translation";

export const notEmptyString = {
    predicate: (value: string | undefined) =>
        !_.isEmpty(value),
    message: () =>
        tValidation(validation.notEmpty),
};

export const timeString = {
    predicate: (o: string | undefined) => {
        const duration = moment.duration(o);

        return duration.asMilliseconds() > 0;
    },
    message: tValidation(validation.greaterThan, {
        number: "00:00",
    }),
};

export const stringMustBeLength = (
    minLength: number,
    maxLength: number
) => {
    return {
        predicate: (value: string) =>
            !_.isEmpty(value) &&
            value.length >= minLength &&
            value.length <= maxLength,
        message: () =>
            tValidation(validation.stringLength, {
                min: minLength,
                max: maxLength,
            }),
    };
};

export const notEmptyInteger = {
    predicate: (
        value: string | number | undefined
    ) => {
        if (
            value === undefined ||
            value === "" ||
            /^[-]{0,1}\d{1,}[,.]{0,1}\d{0,}$/.test(
                value.toString()
            ) === false
        ) {
            return false;
        }

        const toValidate = Number(
            value?.toString().replace(",", ".")
        );
        return (
            !isNaN(toValidate) &&
            Number.isInteger(toValidate)
        );
    },
    message: () =>
        tValidation(validation.mustBeNumber),
};

export const notEmptyNumber = {
    predicate: (value: number | null) =>
        value !== null && value !== undefined,
    message: () =>
        tValidation(validation.notEmpty),
};

export const mustBeGreaterThan = (
    number: number
) => {
    return {
        predicate: (value: number) =>
            value !== null &&
            value !== undefined &&
            value > number,
        message: () =>
            tValidation(validation.greaterThan, {
                number,
            }),
    };
};

export const notEmptyArray = (
    minLength: number
) => {
    return {
        predicate: (value: Array<unknown>) =>
            !_.isEmpty(value) &&
            value.length >= minLength,
        message: () =>
            tValidation(
                validation.notEmptyArray,
                { min: minLength }
            ),
    };
};

export const notEmptyDate = {
    predicate: (
        value: string | Date | Moment | undefined
    ) => {
        if (value === undefined) {
            return false;
        }

        return (
            isDate(value) ||
            (moment.isMoment(value) &&
                value.year() > 2000)
        );
    },
    message: () =>
        tValidation(validation.notEmpty),
};

export const mustDateSameOrBefore = <T>(
    getDate: (obj: T) => Date | undefined
) => {
    return {
        predicate: (
            value: Date | undefined,
            obj: T
        ) => {
            const date = getDate(obj);
            return (
                isDate(value) &&
                isDate(date) &&
                moment(value).isSameOrBefore(date)
            );
        },
        message: (
            _value: Date | undefined,
            obj: T
        ) =>
            tValidation(
                validation.lessOrEqualsDateThan,
                {
                    date: dateToString(
                        getDate(obj)
                    ),
                }
            ),
    };
};

export const mustDateSameOrAfter = <T>(
    getDate: (obj: T) => Date | undefined
) => {
    return {
        predicate: (
            value: Date | undefined,
            obj: T
        ) => {
            const date = getDate(obj);
            return (
                isDate(value) &&
                isDate(date) &&
                moment(value).isSameOrAfter(date)
            );
        },
        message: (
            _value: Date | undefined,
            obj: T
        ) =>
            tValidation(
                validation.greaterOrEqualsDateThan,
                {
                    date: dateToString(
                        getDate(obj)
                    ),
                }
            ),
    };
};

export const eanValidator = [
    notEmptyString,
    stringMustBeLength(4, 13),
    {
        predicate: (value: string) =>
            /^[A-Za-z0-9]*$/.test(value),
        message: () =>
            tValidation(
                validation.onlyLettersAndNumbers
            ),
    },
];

export const friscoIdValidator = [
    notEmptyString,
    stringMustBeLength(1, 6),
    {
        predicate: (value: string) =>
            /^[1-9][0-9]{0,5}$/.test(value),
        message: () =>
            tValidation(
                validation.friscoIdIncorrect
            ),
    },
];

export const polishPostcodeValidator = [
    notEmptyString,
    {
        predicate: (value: string) =>
            value.length === 6,
        message: () =>
            tValidation(validation.length, {
                number: 6,
            }),
    },
    {
        predicate: (value: string) =>
            /^[0-9]{2}-[0-9]{3}$/.test(value),
        message: () =>
            tValidation(validation.postCodeRegex),
    },
];

export const internationalPostcodeValidator = [
    notEmptyString,
    {
        predicate: (value: string) =>
            value.length <= 10,
        message: () =>
            tValidation(validation.length, {
                number: 10,
            }),
    },
];

export const phoneNumberValidator = [
    notEmptyString,
    stringMustBeLength(9, 20),
    {
        predicate: (value: string) => {
            return /^(.*[0-9]){9,}.*$/.test(
                value
            );
        },
        message: () =>
            tValidation(
                validation.phoneNumberRegex
            ),
    },
];

export const emailValidator = [
    notEmptyString,
    {
        predicate: (value: string) =>
            /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(
                value
            ),
        message: () =>
            tValidation(validation.email),
    },
];
