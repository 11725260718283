import GridContainer from "components/Grid/GridContainer";
import TextWithLabel from "components/Text/TextWithLabel";
import formatTypes from "utils/formating/formatTypes";
import { orders } from "translations";

const ShippingAddress = ({ shippingAddress }) => {
    const fields = [
        {
            label: `orders:${orders.recipient}`,
            value: shippingAddress.recipient,
            format: formatTypes.text,
        },
        {
            label: `orders:${orders.phoneNumber}`,
            value: shippingAddress.phoneNumber,
            format: formatTypes.text,
        },
        {
            label: `orders:${orders.address}`,
            value: shippingAddress,
            format: formatTypes.shippingAddress,
        },
        {
            label: `orders:${orders.instructions}`,
            value: shippingAddress.instructions,
            format: formatTypes.text,
        },
        {
            label: `orders:${orders.waybillNumber}`,
            value: shippingAddress.waybillNumber,
            format: formatTypes.text,
            hidden: shippingAddress.waybillNumber === undefined || shippingAddress.waybillNumber === "",
        },
    ];

    return (
        <GridContainer
            iconName="location_on"
            title={`orders:${orders.deliveryAddress}`}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >
            {fields
                .filter((x) => !x.hidden)
                .map((item) => (
                    <TextWithLabel
                        key={item.label}
                        label={item.label}
                        format={item.format}
                        value={item.value}
                    />
                ))}
        </GridContainer>
    );
};

export default ShippingAddress;
