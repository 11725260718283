import { RSAA } from "redux-api-middleware";
import { commerce } from "store/actionTypePrefixes";
import { combineIntoQueryParams } from "utils/objectExtensions";

export const ORDERS_GET_LIST = `${commerce}/ORDERS_GET_LIST`;
export const ORDERS_GET_LIST_SUCCESS = `${commerce}/ORDERS_GET_LIST_SUCCESS`;
export const ORDERS_GET_LIST_FAILURE = `${commerce}/ORDERS_GET_LIST_FAILURE`;

const getOrders = (pageIndex, pageSize, userId, disableProductEmbedding = true) => {
    const params = combineIntoQueryParams({ pageIndex, pageSize, disableProductEmbedding });
    return {
        [RSAA]: {
            endpoint: `api/v1/users/${userId}/orders?${params}`,
            headers: { "Content-Type": "application/json" },
            method: "GET",
            types: [ORDERS_GET_LIST, ORDERS_GET_LIST_SUCCESS, ORDERS_GET_LIST_FAILURE],
        },
    };
};

export const actions = {
    getOrders,
};
