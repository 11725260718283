import fileTypes from "consts/fileTypes";
import { RSAA } from "redux-api-middleware";
import { commerce } from "store/actionTypePrefixes";
import downloadFile, { rsaaActionType } from "utils/rsaa/downloadFileAction";

const CUSTOMER_GET_CONSENTS = `${commerce}/CUSTOMER_GET_CONSENTS`;
const CUSTOMER_GET_CONSENTS_SUCCESS = `${commerce}/CUSTOMER_GET_CONSENTS_SUCCESS`;
const CUSTOMER_GET_CONSENTS_FAILURE = `${commerce}/CUSTOMER_GET_CONSENTS_FAILURE`;
const CUSTOMER_PUT_CONSENTS = `${commerce}/CCUSTOMER_PUT_CONSENTS`;
const CUSTOMER_PUT_CONSENTS_SUCCESS = `${commerce}/CCUSTOMER_PUT_CONSENTS_SUCCESS`;
const CUSTOMER_PUT_CONSENTS_FAILURE = `${commerce}/CCUSTOMER_PUT_CONSENTS_FAILURE`;
const CUSTOMER_EXPORT_PROFILE = `${commerce}/CUSTOMER_EXPORT_PROFILE`;
const CUSTOMER_EXPORT_PROFILE_SUCCESS = `${commerce}/CUSTOMER_EXPORT_PROFILE_SUCCESS`;
const CUSTOMER_EXPORT_PROFILE_FAILURE = `${commerce}/CUSTOMER_EXPORT_PROFILE_FAILURE`;
export const types = {
    CUSTOMER_GET_CONSENTS,
    CUSTOMER_GET_CONSENTS_SUCCESS,
    CUSTOMER_GET_CONSENTS_FAILURE,
    CUSTOMER_PUT_CONSENTS,
    CUSTOMER_PUT_CONSENTS_SUCCESS,
    CUSTOMER_PUT_CONSENTS_FAILURE,
    CUSTOMER_EXPORT_PROFILE,
    CUSTOMER_EXPORT_PROFILE_SUCCESS,
    CUSTOMER_EXPORT_PROFILE_FAILURE,
};

const getCustomerConsents = (customerId) => (dispatch) => {
    return dispatch({
        [RSAA]: {
            endpoint: `api/v1/users/${customerId}/consents`,
            method: "GET",
            types: [CUSTOMER_GET_CONSENTS, CUSTOMER_GET_CONSENTS_SUCCESS, CUSTOMER_GET_CONSENTS_FAILURE],
        },
    });
};

const putCustomerConsent =
    ({ customerId, ...consent }) =>
    async (dispatch) => {
        const body = {
            consentDecisions: [
                {
                    consentId: consent.consentId,
                    isConsented: !consent.isConsented,
                },
            ],
            consentChannel: consent.consentChannel,
        };

        await dispatch({
            [RSAA]: {
                endpoint: `api/v1/users/${customerId}/consents`,
                headers: { "Content-Type": "application/json" },
                method: "PUT",
                types: [CUSTOMER_PUT_CONSENTS, CUSTOMER_PUT_CONSENTS_SUCCESS, CUSTOMER_PUT_CONSENTS_FAILURE],
                body: JSON.stringify(body),
            },
        });

        return await dispatch(getCustomerConsents(customerId));
    };

const exportCustomerProfile = (customerId, includeConsents) => async (dispatch) => {
    await dispatch(
        downloadFile(
            `api/v1/users/${customerId}/export/archive?includeConsents=${includeConsents}`,
            fileTypes.zip,
            rsaaActionType(CUSTOMER_EXPORT_PROFILE, CUSTOMER_EXPORT_PROFILE_SUCCESS, CUSTOMER_EXPORT_PROFILE_FAILURE),
            customerId
        )
    );
};

export const actions = {
    getCustomerConsents,
    putCustomerConsent,
    exportCustomerProfile,
};
