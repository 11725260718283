import { RSAA } from "redux-api-middleware";
import { GetRequest, PostRequest, get, post } from "utils/extensions";
import { downloadPdfFile, downloadXlsxFile } from "utils/rsaa/downloadFileAction";
import types from "./types";

const endpoint = (id) => `api/emails${id ? `/${id}` : ""}`;

export const findEmails = (pageIndex, pageSize) => async (dispatch) => {
    return dispatch(
        get(
            new GetRequest(endpoint(), types.findEmails.all, {
                queryParams: {
                    pageSize,
                    pageIndex,
                },
            })
        )
    );
};

export const getEmail = (emailId) => async (dispatch) => {
    await dispatch({ type: types.formInit });

    if (emailId) {
        return dispatch(get(new GetRequest(endpoint(emailId), types.getEmail.all)));
    }
};

export const getEmailAttachment = (emailId, fileName) => async (dispatch) => {
    dispatch(downloadPdfFile(`${endpoint(emailId)}/attachment`, types.downloadAttachment.all, fileName));
};

export const createEmail = (values) => async (dispatch) => {
    return dispatch(
        post(
            new PostRequest(endpoint(), types.createEmail.all, {
                body: values,
            })
        )
    );
};

export const downloadRecipientsFileTemplate = () => (dispatch) =>
    dispatch(downloadXlsxFile(`${endpoint()}/recipients-template`, types.getRecipientsFileTemplate.all, "Lista_odbiorców - Wzór"));

export const getRecipientsFromFile = (file) => async (dispatch) => {
    let data = new FormData();
    data.append("file0", file);

    return dispatch({
        [RSAA]: {
            endpoint: `${endpoint()}/read-recipients-file`,
            method: "POST",
            types: types.getRecipientsFromFile.all,
            body: data,
        },
    });
};
