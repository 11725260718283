import React from "react";

function withConsumer(Component, Consumer) {
    return function WrapperComponent(props) {
        return (
            <Consumer>
                {(consumerProps) => {
                    return (
                        <Component
                            {...props}
                            context={{ ...props.context, ...consumerProps }}
                        />
                    );
                }}
            </Consumer>
        );
    };
}

export default withConsumer;
