import IconWithHint from "components/Icons/IconWithHint";
import accountTypes from "resource/accountTypes.json";

const FlagIcon = ({ customer }) => {
    const { isOfficeAccount, isTestAccount } = customer;
    const iconsArray = [];
    if (isOfficeAccount) {
        iconsArray.push(accountTypes.find((i) => i.value === "businessAccount"));
    } else {
        iconsArray.push(accountTypes.find((i) => i.value === "homeAccount"));
    }

    if (isTestAccount) {
        iconsArray.push(accountTypes.find((i) => i.value === "testAccount"));
    }

    return (
        <div style={{ display: "flex" }}>
            {iconsArray.map(({ icon, name }, i) => (
                <IconWithHint
                    color="primary"
                    key={i}
                    icon={icon}
                    title={name}
                />
            ))}
        </div>
    );
};

export default FlagIcon;
