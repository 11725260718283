import { toDictionary } from "utils/extensions/arrayExtensions";
import { Requirements } from "utils/models/CommerceForms";

export class DeliveryCostForm {
    constructor(data = {}) {
        this.validFrom = data.validFrom;
        this.validTo = data.validTo;
        this.name = data.name;
        this.requirements = data.requirements && new Requirements(data.requirements);
        this.ranges = data.ranges;
        this.priority = data.priority;
        this.contentData = data.contentData && toDictionary(data.contentData);
        this.isActive = data.isActive;
        this.isSystem = data.isSystem;
        this.id = data.id;
        this.updatedAt = data.updatedAt;
        this.createdAt = data.createdAt;
        this.version = data.version;
    }
}
