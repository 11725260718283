import Autocomplete from "components/Controls/Autocomplete";
import ControlLabel from "components/Controls/ControlLabel";
import DragAndDrop from "components/Controls/DragAndDrop";
import IconStatus from "components/Controls/IconStatus";
import ListInput from "components/Controls/ListInput";
import NumberField from "components/Controls/NumberField/NumberField";
import TextInputWithImage from "components/Controls/TextInputWithImage";
import TimeSpanField from "components/Controls/TimeSpanField/TimeSpanField.";
import FacetSearchControl from "components/FacetSearchControl/FacetSearchControl";
import PimRichTextField from "components/RichTextField/PimRichTextField";
import RichTextField from "components/RichTextField/RichTextField";
import VirtualizedListbox from "components/VirtualizedListbox/VirtualizedListbox";
import isImageUrl from "is-image-url";
import timePrecisonFormats from "utils/formating/timePrecisonFormats";
import { isEmpty } from "utils/validators/basic";
import React, { Fragment } from "react";
import { NumberFormatBase, NumericFormat, useNumericFormat } from "react-number-format";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Autocomplete as MUIAutoComplete } from "@mui/lab";
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import { Avatar, Button, Checkbox, Grid, ListItemText, Typography } from "@mui/material";
import Fab from "@mui/material/Fab";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";
import _ from "lodash";

export const renderTree = ({ label, input, meta, ...custom }) => {
    const { touched = true, error, dirty } = meta || {};
    const mapChildren = (item) => {
        return (
            <TreeItem
                nodeId={item.id}
                label={item.name}
            >
                {item.children.map((x) => mapChildren(x))}
            </TreeItem>
        );
    };
    return (
        <FormControl
            fullWidth={true}
            className="text-field-flat-label"
            error={touched && !!error}
        >
            <FormLabel className="text-field-flat-label">{label}</FormLabel>
            <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                selected={input.value}
                onNodeSelect={(event, value) => {
                    input.onChange(value);
                    if (custom.onSelected) custom.onSelected(event.target);
                }}
                defaultExpanded={custom.defaultExpanded}
                disableSelection={custom.readOnly || custom.readonly}
            >
                {(custom.data || []).map((x) => mapChildren(x))}
            </TreeView>
            {renderFromHelper({ touched, error, dirty })}
        </FormControl>
    );
};

export const renderTextField = (props) => {
    const { label, input, meta, adornment, mask, autoComplete, isRequired, ...custom } = props;
    const { touched = true, invalid, error, warning } = meta || {};

    if ((custom.readonly || custom.readOnly || custom.disabled) && !custom.hidden) {
        return prepareToRenderAsText(
            {
                label,
                value: input.value,
                items: custom.items,
                adornment,
                type: "text",
                timePrecisonFormat: custom.timePrecisonFormat,
            },
            props
        );
    }

    if (mask) {
        const sharedProps = {
            variant: "standard",
            customInput: TextField,
            label: (
                <ControlLabel
                    label={label}
                    isRequired={isRequired}
                />
            ),
            placeholder: label,
            error: touched && invalid,
            helperText: (touched && error) || warning,
            title: input.value,
            type: custom.type,
            fullWidth: custom.fullWidth || false,
            disabled: custom.disabled || false,
            inputProps: {
                readOnly: custom.readOnly || custom.readonly || false,
            },
            onKeyUp: custom.onKeyUp,
            FormHelperTextProps: {
                style: { color: warning ? "#fbc02d" : "" },
            },
        };

        if (mask === true) {
            const _format = (numStr, props) => {
                const format = useNumericFormat(custom);
                const formattedValue = format(numStr, props);
                return formattedValue === "" ? prefix + suffix : formattedValue;
            };

            return (
                <NumericFormat
                    {...sharedProps}
                    {...input}
                    format={_format}
                    fixedDecimalScale={custom.fixedDecimalScale}
                    decimalScale={custom.decimalScale}
                    decimalSeparator={custom.decimalSeparator}
                    thousandSeparator={custom.thousandSeparator}
                    allowedDecimalSeparators={custom.allowedDecimalSeparators}
                />
            );
        } else {
            return (
                <NumberFormatBase
                    {...sharedProps}
                    {...input}
                    format={mask.format}
                    mask={mask.maskCharacter}
                />
            );
        }
    } else {
        const adormentProps = Boolean(adornment)
            ? {
                  startAdornment: adornment.position === "start" ? <InputAdornment position="start">{adornment.value}</InputAdornment> : undefined,
                  endAdornment: adornment.position === "end" ? <InputAdornment position="end">{adornment.value}</InputAdornment> : undefined,
              }
            : undefined;

        return (
            <TextField
                variant="standard"
                autoFocus={props.autoFocus}
                label={
                    <ControlLabel
                        label={label}
                        isRequired={isRequired}
                    />
                }
                placeholder={label}
                error={touched && invalid}
                helperText={(touched && error ? (error.value ? error.value : error) : "") || warning}
                multiline={custom.multiline || false}
                title={input.value}
                type={custom.type}
                fullWidth={custom.fullWidth || false}
                disabled={custom.disabled || false}
                onKeyUp={custom.onKeyUp}
                spellCheck={custom.spellCheck || false}
                {...input}
                value={isEmpty(input.value) ? "" : input.value}
                InputLabelProps={custom.type === "date" ? { shrink: true } : { shrink: !isEmpty(input.value) }}
                FormHelperTextProps={{
                    style: { color: warning ? "#fbc02d" : "" },
                }}
                InputProps={{
                    readOnly: custom.readOnly || custom.readonly || false,
                    ...adormentProps,
                }}
                rows={custom.rows}
                autoComplete={autoComplete || undefined}
            />
        );
    }
};

export const RenderAutoComplete = (props) => {
    const {
        label,
        input,
        meta: { touched, invalid, error },
        onSelect,
        readOnly,
        readonly,
        isRequired,
        getOptionTooltip,
        ...custom
    } = props;
    return readonly || readOnly ? (
        renderSelect({
            ...props,
            items: custom.options.map((x) => ({
                name: custom.getOptionLabel ? custom.getOptionLabel(x) : x,
                value: x,
            })),
        })
    ) : (
        <MUIAutoComplete
            {...custom}
            readOnly={readonly || readOnly}
            autoHighlight={true}
            autoComplete={true}
            noOptionsText={custom.noOptionsText ?? ""}
            multiple={custom.multiple || false}
            disabled={custom.disabled || false}
            disableCloseOnSelect={custom.disableCloseOnSelect}
            value={custom.options && custom.options.length >= 0 ? (!input.value ? (custom.multiple ? [] : "") : input.value) : null}
            onChange={(event, newValue) => {
                input.onChange(newValue);
                if (onSelect) {
                    onSelect(newValue);
                }
            }}
            groupBy={custom.groupBy}
            options={custom.options}
            renderOption={
                getOptionTooltip
                    ? (props, option) => (
                          <li {...props}>
                              <Tooltip
                                  title={getOptionTooltip(option)}
                                  placement="right-start"
                                  arrow
                              >
                                  <div>{custom.getOptionLabel ? custom.getOptionLabel(option) : option}</div>
                              </Tooltip>
                          </li>
                      )
                    : undefined
            }
            ListboxComponent={custom.virtualized ? VirtualizedListbox : undefined}
            getOptionLabel={
                custom.getOptionLabel ||
                ((option) => {
                    return option;
                })
            }
            renderInput={(params) => (
                <TextField
                    variant="standard"
                    placeholder={label}
                    error={touched && invalid}
                    helperText={touched && error}
                    title={input.value}
                    fullWidth={custom.fullWidth || false}
                    {...params}
                    label={
                        <ControlLabel
                            label={label}
                            isRequired={isRequired}
                        />
                    }
                />
            )}
        />
    );
};

export const renderTextFieldFlat = ({ label, input, meta: { touched, invalid, error }, ...custom }) => {
    return (
        <FormControlLabel
            control={
                custom.type === "number" && custom.numberControls ? (
                    <NumberTextField
                        input={input}
                        meta={{ touched, invalid, error }}
                        {...custom}
                    />
                ) : (
                    <TextField
                        variant="standard"
                        error={touched && invalid}
                        helperText={touched && error}
                        title={input.value}
                        multiline={custom.multiline || false}
                        type={custom.type}
                        fullWidth={custom.fullWidth || false}
                        disabled={custom.disabled || false}
                        inputProps={{
                            readOnly: custom.readOnly || custom.readonly || false,
                        }}
                        {...input}
                        InputLabelProps={custom.type === "date" ? { shrink: true } : {}}
                        rows={custom.rows}
                    />
                )
            }
            label={
                <ControlLabel
                    label={label}
                    isRequired={custom.isRequired}
                />
            }
            labelPlacement={custom.multiline ? "top" : "start"}
            className={
                custom.type === "number"
                    ? "text-field-flat-label-number"
                    : custom.multiline
                    ? "text-field-flat-label-multiline"
                    : "text-field-flat-label"
            }
        />
    );
};

export const renderRichTextField = ({ pim, label, input, meta: { touched, error, dirty }, ...custom }) => {
    return (
        <FormControl
            fullWidth={true}
            className="text-field-flat-label"
            style={{ padding: "0px" }}
            error={touched && !!error}
        >
            <FormLabel
                className="text-field-flat-label"
                style={{ padding: "0px" }}
            >
                <ControlLabel
                    label={label}
                    isRequired={custom.isRequired}
                />
            </FormLabel>
            {pim ? (
                <PimRichTextField
                    title={input.value}
                    {...input}
                    {...custom}
                />
            ) : (
                <RichTextField
                    title={input.value}
                    {...input}
                    {...custom}
                />
            )}
            {renderFromHelper({ touched, error, dirty })}
        </FormControl>
    );
};

const renderFromHelper = ({ touched = true, dirty = true, error, warning }) => {
    let warningComponent = Boolean(warning) ? <FormHelperText style={{ color: "#ff9800" }}>{warning}</FormHelperText> : null;

    if (!((touched || dirty) && error)) {
        return warningComponent;
    } else {
        return (
            <>
                {warningComponent}
                <FormHelperText style={{ color: "red" }}>{(touched || dirty) && error}</FormHelperText>
            </>
        );
    }
};

export const renderSelect = (props) => {
    const {
        label,
        input,
        meta: { touched, error, dirty, warning },
        fullWidth = true,
        ...custom
    } = props;
    if ((custom.readonly || custom.readOnly || custom.disabled) && !custom.hidden) {
        return prepareToRenderAsText(
            {
                label,
                value: input.value,
                items: custom.items,
                adornment: custom.adornment,
                type: "select",
                timePrecisonFormat: custom.timePrecisonFormat,
                warning: warning,
            },
            props
        );
    }
    const findName = (value) => {
        const find = (val) => {
            const value = custom.items.find((x) => x.value === val) || {
                name: "",
            };

            return value.polishName || value.englishName || value.name;
        };

        if (!_.isArray(value)) return find(value);

        return value.map((v) => find(v)).join(", ");
    };

    const processValues = (value) => {
        if (custom.selectAllEnabled === true) {
            if (value[value.length - 1] === "0") {
                value = custom.items.map((i) => i.value);
            } else if (value.length > 1 && value.length != custom.items.length && value[0] === "0") {
                value = value.filter((v) => v != "0");
            } else if (value.length == custom.items.length - 1 && value.indexOf("0") < 0) {
                value = [];
            }
            input.value = value;
            return value;
        } else {
            return value;
        }
    };

    const value = input.value || input.value === 0 ? processValues(input.value) : custom.multiple ? [] : "";

    let items = custom.items.map((x, index) => {
        const name = x.polishName || x.englishName || x.name;
        return {
            item: (
                <MenuItem
                    disabled={x.disabled}
                    key={index}
                    value={x.value}
                >
                    {custom.multiple ? <Checkbox checked={Boolean((input.value || []).find((y) => y === x.value))} /> : undefined}
                    <ListItemText>{name}</ListItemText>
                </MenuItem>
            ),
            group: x.group,
        };
    });

    if (custom.grouped) {
        items = _(items)
            .groupBy((x) => x.group)
            .map((x, y) => {
                return [
                    <ListSubheader style={{ background: "#ccc" }}>
                        <Typography variant="button">{y}</Typography>
                    </ListSubheader>,
                    ...x.map((x) => x.item),
                ];
            })
            .value();
    } else {
        items = items.map((x) => x.item);
    }

    const select = (
        <Select
            variant="standard"
            MenuProps={{
                style: {
                    maxHeight: 400,
                },
            }}
            multiple={custom.multiple}
            value={value}
            onOpen={custom.onOpen}
            onClick={custom.onClick}
            onChange={(event) => {
                if (custom.onValueChange) {
                    custom.onValueChange(event.target.value);
                } else if (custom.onChange) {
                    custom.onChange(event);
                } else if (input.onChange) {
                    input.onChange(event);
                }
            }}
            onClose={custom.onClose}
            open={custom.open}
            disabled={custom.disabled || false}
            renderValue={(value) => (!!custom.renderValue ? custom.renderValue(value) : findName(value) || value)}
            inputProps={{
                readOnly: custom.readOnly || custom.readonly || false,
            }}
        >
            {!custom.multiple && !custom.hideDefaultItem ? (
                <MenuItem value="">
                    <em>(brak)</em>
                </MenuItem>
            ) : undefined}
            {items}
        </Select>
    );
    return (
        <FormControl
            fullWidth={fullWidth}
            error={(touched || dirty) && !!error}
            style={{
                display: custom.hidden ? "none" : undefined,
                width: fullWidth ? undefined : "80%",
            }}
        >
            <InputLabel>
                <ControlLabel
                    label={label}
                    isRequired={custom.isRequired}
                />
            </InputLabel>
            {custom.tooltip ? (
                <Tooltip
                    title={custom.tooltip}
                    aria-label={custom.tooltip}
                    placement="right-start"
                    arrow
                >
                    {select}
                </Tooltip>
            ) : (
                select
            )}
            {renderFromHelper({ touched, error, dirty, warning })}
        </FormControl>
    );
};
export const renderSelectFlat = ({ label, input, meta: { touched, error, dirty }, ...custom }) => (
    <FormControlLabel
        control={
            <>
                <Select
                    variant="standard"
                    value={input.value}
                    onChange={(value) => {
                        input.onChange(value);
                        if (custom.onValueChange) {
                            custom.onValueChange(value.target.value);
                        } else if (custom.onChange) {
                            custom.onChange(value);
                        }
                    }}
                    autoWidth={true}
                    disabled={custom.disabled || false}
                    inputProps={{
                        readOnly: custom.readOnly || custom.readonly || false,
                    }}
                >
                    {!custom.hideDefaultItem && (
                        <MenuItem value="">
                            <em>(brak)</em>
                        </MenuItem>
                    )}
                    {custom.items.map((x) => (
                        <MenuItem
                            disabled={x.disabled}
                            key={x.polishName || x.englishName || x.name}
                            value={x.value}
                        >
                            {x.polishName || x.englishName || x.name}
                        </MenuItem>
                    ))}
                </Select>
                {renderFromHelper({ touched, error, dirty })}
            </>
        }
        label={
            <ControlLabel
                label={label}
                isRequired={custom.isRequired}
            />
        }
        labelPlacement="start"
        className="select-flat-label"
    />
);

export const renderColor = ({ input, label, ...custom }) => {
    const fab = (
        <Fab
            size="small"
            readOnly
            disableRipple
            style={{
                backgroundColor: input.value ? "green" : "red",
                marginLeft: "10px",
                opacity: "0.7",
                boxShadow: "2px 2px 1px grey",
                width: "36px",
                height: "36px",
            }}
            variant="extended"
        >
            <></>
        </Fab>
    );

    return (
        <FormControlLabel
            control={
                custom.tooltip ? (
                    <Tooltip
                        title={input.value ? custom.tooltip.true : custom.tooltip.false}
                        aria-label={input.value ? custom.tooltip.true : custom.tooltip.false}
                        placement="right-start"
                        arrow
                    >
                        {fab}
                    </Tooltip>
                ) : (
                    { fab }
                )
            }
            labelPlacement="start"
            style={{ display: "flex", marginTop: "5px" }}
            label={label}
        />
    );
};

export const renderSwitch = ({ input, label, fullWidth = true, meta: { touched, error }, ...custom }) => {
    return (
        <React.Fragment>
            <FormControlLabel
                control={
                    <Switch
                        readOnly={Boolean(custom.readOnly) || false}
                        disabled={custom.disabled || false}
                        color={custom.color || "primary"}
                        checked={input.value ? true : false}
                        onChange={
                            !custom.readOnly
                                ? (event, val) => {
                                      input.onChange(event);
                                      if (custom.onSwitch) {
                                          custom.onSwitch(val);
                                      }
                                  }
                                : // eslint-disable-next-line @typescript-eslint/no-empty-function
                                  () => {}
                        }
                    />
                }
                label={label}
                style={{
                    width: fullWidth ? undefined : "80%",
                }}
            />
            {renderFromHelper({ touched, error })}
        </React.Fragment>
    );
};

export class NumberTextField extends React.Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }
    render() {
        const {
            label,
            input,
            meta: { touched, invalid, error },
            ...custom
        } = this.props;
        return (
            <div className="number-input">
                <TextField
                    variant="standard"
                    error={touched && invalid}
                    helperText={touched && error}
                    title={input.value}
                    inputRef={(ref) => (this.inputRef = ref)}
                    type={custom.type}
                    label={
                        !isEmpty(label) && (
                            <ControlLabel
                                label={label}
                                isRequired={custom.isRequired}
                            />
                        )
                    }
                    fullWidth={custom.fullWidth || false}
                    disabled={custom.disabled || false}
                    {...input}
                    InputProps={{
                        readOnly: custom.readOnly || custom.readonly || false,
                        endAdornment: (
                            <InputAdornment position="end">
                                <button
                                    disabled={custom.readOnly || custom.readonly || custom.disabled || false}
                                    type="button"
                                    onClick={() => {
                                        this.inputRef.stepUp();
                                        input.onChange(this.inputRef.value);
                                    }}
                                    className="plus"
                                />
                            </InputAdornment>
                        ),
                        startAdornment: (
                            <InputAdornment position="start">
                                <button
                                    disabled={custom.readOnly || custom.readonly || custom.disabled || false}
                                    type="button"
                                    onClick={() => {
                                        if (Number(input.value) <= 0 && custom.positive) {
                                            return;
                                        }
                                        this.inputRef.stepDown();
                                        input.onChange(this.inputRef.value);
                                    }}
                                />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
        );
    }
}

export function renderDatePicker({
    label,
    input,
    readOnly,
    meta: { error, touched, dirty, submitFailed, warning },
    initialFocusedDate,
    fullWidth,
    ...custom
}) {
    return (
        <>
            <DatePicker
                slotProps={{
                    textField: {
                        variant: "standard",
                        fullWidth,
                        onDoubleClick: custom.onPickerClick,
                    },
                    openPickerButton: custom.onPickerClick ? { onClick: custom.onPickerClick } : undefined,
                }}
                minDate={custom.minDate}
                maxDate={custom.maxDate}
                shouldDisableDate={custom.shouldDisableDate}
                disablePast={custom.disablePast || false}
                readOnly={custom.disablePicker}
                disabled={readOnly}
                format={custom.dateFormat || "DD.MM.YYYY"}
                label={
                    <ControlLabel
                        label={label}
                        isRequired={custom.isRequired}
                    />
                }
                initialFocusedDate={initialFocusedDate ?? moment().startOf("day")}
                ampm="false"
                error={(submitFailed || dirty) && Boolean(error)}
                fullWidth
                value={input.value ? (moment.isMoment(input.value) ? input.value : moment(input.value)) : null}
                onChange={(value) => {
                    if (custom.onCustomChange) {
                        custom.onCustomChange(value ? value.format() : null);
                    } else {
                        input.onChange(value ? value.format() : null);
                    }
                }}
                onClick={custom.onClick}
                views={custom.views}
                size="medium"
            />
            {(submitFailed || dirty) && renderFromHelper({ touched: true, error, warning })}
        </>
    );
}

export function renderDateTimePicker({ label, input, readOnly, meta: { error, touched, dirty, submitFailed }, fullWidth, ...custom }) {
    return (
        <React.Fragment>
            <DateTimePicker
                slotProps={{ textField: { variant: "standard", fullWidth } }}
                disablePast={custom.disablePast || false}
                minDate={custom.minDate}
                disabled={readOnly}
                format={"DD.MM.YYYY HH:mm"}
                helperText={""}
                label={label}
                initialFocusedDate={moment().startOf("day")}
                ampm={false}
                ampmInClock={false}
                error={(submitFailed || (touched && dirty)) && Boolean(error)}
                fullWidth
                value={input.value ? (moment.isMoment(input.value) ? input.value : moment(input.value)) : null}
                onChange={(value) => {
                    input.onChange(value ? value.format() : null);
                }}
            />
            {(submitFailed || (touched && dirty)) && renderFromHelper({ touched: true, error })}
        </React.Fragment>
    );
}

export function renderToggleButtons({ label, input, items, meta = {} }) {
    const { error, touched, dirty, submitFailed } = meta;
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            {Boolean(label) ? (
                <Grid
                    item
                    style={{ width: "40%" }}
                >
                    <Typography style={{ width: "100%", overflowWrap: "break-word" }}>{label}</Typography>
                </Grid>
            ) : (
                <Fragment />
            )}
            <Grid
                item
                style={{ width: Boolean(label) ? "60%" : "100%" }}
            >
                <ToggleButtonGroup
                    style={{ marginLeft: "auto" }}
                    value={input.value}
                    size="small"
                    exclusive
                >
                    {items.map((item) => {
                        return (
                            <ToggleButton
                                {...input}
                                disabled={item.disabled || false}
                                selected={input.value === item.value}
                                key={String(item.value)}
                                onClick={() => {
                                    if (item.value === "") {
                                        input.onChange(null);
                                    } else {
                                        input.onChange(item.value);
                                    }
                                }}
                                // eslint-disable-next-line @typescript-eslint/no-empty-function
                                onBlur={() => {}}
                                onChange={() => {}}
                            >
                                {item.name}
                            </ToggleButton>
                        );
                    })}
                </ToggleButtonGroup>
                {(submitFailed || (touched && dirty)) && renderFromHelper({ touched: true, error })}
            </Grid>
        </Grid>
    );
}

const prepareToRenderAsText = ({ label, value, items = [], adornment, format, type, getOptionLabel, warning, getOptionSelected }, props) => {
    let val = value;
    if (type === "select" || type === "autocomplete" || type === "toggle") {
        if (Array.isArray(value)) {
            const values = items
                .filter((x) =>
                    value.some(
                        (y) => (x.id && (x.id === y || x.id === y.id)) || x.value === y || x === y || (getOptionSelected && getOptionSelected(x))
                    )
                )
                .map((x) => (getOptionLabel ? getOptionLabel(x) : x.name || x))
                .join(",");

            val = values;
        } else if (getOptionLabel) {
            val = getOptionLabel(value);
        } else {
            const { name } = items.find((x) => x.value === value) || (value?.id ? items.find((x) => x.id === value.id) : undefined) || {};
            val = name;
        }
    }

    if (type === "date" && val) {
        val = moment(value).format(format ? format : timePrecisonFormats.days);
    }

    if (type === "dateTime" && val) {
        val = moment(value).format(format ? format : timePrecisonFormats.minutes);
    }

    if (type === "boolean") {
        return renderSwitch(props);
    }

    if (type === "list") {
        return <ListInput {...props} />;
    }

    if (type === "file") {
        return <FileInput {...props} />;
    }

    return renderAsText(val, label, adornment, warning);
};

export const renderFieldOrText = (props) => {
    const {
        type,
        readOnly,
        disabled,
        label,
        input: { value },
        items,
        adornment,
        timePrecisonFormat,
        dateFormat,
        getOptionLabel,
        getOptionSelected,
    } = props;
    if (type === "label") {
        return renderLabel(props);
    }

    if (readOnly || disabled) {
        const format = timePrecisonFormat || dateFormat;

        return prepareToRenderAsText(
            {
                label,
                value,
                items,
                adornment,
                format,
                type,
                getOptionLabel,
                getOptionSelected,
            },
            props
        );
    }

    switch (type) {
        case "select":
            return renderSelect(props);
        case "text":
            return renderTextField(props);
        case "richText":
            return renderRichTextField(props);
        case "textWithResroucePreview":
            return renderPreviewTextField(props);
        case "decimal":
            return renderTextField(props);
        case "date":
            return renderDatePicker(props);
        case "dateTime":
            return renderDateTimePicker(props);
        case "number":
            return <NumberField {...props} />;
        case "timeSpan":
            return <TimeSpanField {...props} />;
        case "boolean":
            return renderSwitch(props);
        case "toggle":
            return renderToggleButtons(props);
        case "list":
            return <ListInput {...props} />;
        case "textWithImage":
            return <TextInputWithImage {...props} />;
        case "autocomplete":
            return <Autocomplete {...props} />;
        case "dragAndDrop":
            return <DragAndDrop {...props} />;
        case "facetSearch":
            return <FacetSearchControl {...props} />;
        case "file":
            return <FileInput {...props} />;
        case "sectionValidation":
            return <SectionValidation {...props} />;
        case "iconStatus":
            return <IconStatus {...props} />;
        default:
            throw Error("Input not found");
    }
};

export const SectionValidation = ({ meta: { error } }) => {
    return renderFromHelper({ touched: true, error }) || "";
};

export function renderLabel({ meta: { touched, error }, input }) {
    const { value } = input;

    return (
        <React.Fragment>
            <Typography>{value}</Typography>
            {renderFromHelper({ touched, error })}
        </React.Fragment>
    );
}

const renderAsText = (value, label, adornment, warning) => {
    const endAdornment = Boolean(adornment) && adornment.position === "end" ? adornment.value : undefined;
    const startAdornment = Boolean(adornment) && adornment.position === "start" ? adornment.value : undefined;

    return (
        <Fragment>
            {!isEmpty(label) ? (
                <Typography
                    component="div"
                    variant="caption"
                >
                    {label}
                </Typography>
            ) : (
                <Fragment />
            )}

            <Typography component="div">
                {startAdornment} {value?.current ? value.current : value?.proposal ? value.proposal : value}
                {endAdornment}
            </Typography>
            {warning && renderFromHelper({ warning })}
        </Fragment>
    );
};

const hexRegex = /^#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})$/;

const renderPreviewTextField = (props) => {
    const isResource = isImageUrl(props.input.value);
    const isHex = hexRegex.test(props.input.value);
    return (
        <Fragment>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-end"
                spacing={2}
            >
                <Grid
                    item
                    style={{ width: isResource || isHex ? "90%" : "100%" }}
                >
                    {renderTextField(props)}
                </Grid>
                {isResource || isHex ? (
                    <Grid item>
                        <Avatar
                            variant="square"
                            src={props.input.value}
                            style={{
                                backgroundColor: props.input.value,
                                width: 50,
                                height: 50,
                            }}
                        ></Avatar>
                    </Grid>
                ) : (
                    <Fragment />
                )}
            </Grid>
        </Fragment>
    );
};

const adaptFileEventToValue = (delegate) => (e) => delegate(e.target.files[0]);

const FileInput = ({ input: { value: omitvalue, onChange, onBlur }, meta, isRequired: _, fullWidth: __, useDiff: ___, redIfMissing, ...props }) => {
    return (
        <>
            <Button
                variant="outlined"
                color={redIfMissing && !omitvalue ? "secondary" : "primary"}
                style={{ padding: 0, marginTop: 12 }}
                readOnly={props.readOnly}
                disabled={props.disabled}
            >
                <label
                    style={{
                        color: props.disabled ? "#00000042" : redIfMissing && !omitvalue ? "red" : "#2138a3",
                        padding: 8,
                        cursor: "pointer",
                    }}
                >
                    {omitvalue ? omitvalue.name : "Wybierz plik"}
                    <input
                        onChange={adaptFileEventToValue(onChange)}
                        onBlur={adaptFileEventToValue(onBlur)}
                        type="file"
                        {...props.input}
                        {...props}
                        style={{ display: "none" }}
                    />
                </label>
            </Button>
            {renderFromHelper(meta)}
        </>
    );
};
