import { getContentContainers } from "store/autocomplete";
import Validator, {
    defaultLenghtValidator,
    greaterThan,
    isEmpty,
    lessDateThan,
    notEmpty,
    notEmptyArrayValidator,
    notEmptyValidator,
    stringLength,
} from "utils/validators/basic";
import moment from "moment";
import { tValidation, validation } from "utils-ts/validations/translation";

const validFrom = (value) => notEmptyValidator(value).validate();
const validTo = (value) => notEmptyValidator(value).validate();
const contestName = (value) =>
    notEmptyValidator(value)
        .must(stringLength(1, 50), tValidation(validation.notEmpty), {
            min: 1,
            max: 50,
        })
        .mustRegex(
            /^[a-zA-Z0-9][a-zA-Z0-9_]{0,49}$/,
            tValidation(validation.incorrectChars, {
                allowedChars: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_",
            })
        )
        .validate();
const containerName = (value) => defaultLenghtValidator(value).validate();
const categoryName = (value) => notEmptyValidator(value).validate();
const sectionName = (value) => notEmptyValidator(value).validate();
const obligatoryFrom = (value) => notEmptyValidator(value).validate();
const name = (value) => notEmptyValidator(value).validate();
const optionalFrom = (value, p1) => {
    if (!value) return undefined;

    const { obligatoryFrom } = p1;
    if (!obligatoryFrom) return undefined;

    return new Validator(value)
        .must(
            lessDateThan(obligatoryFrom),
            tValidation(validation.lessDateThan, {
                date: moment(obligatoryFrom).format("YYYY-MM-DD"),
            })
        )
        .validate();
};
const greaterThan0 = (value) => new Validator(value).must(greaterThan(0), tValidation(validation.greaterThan, { number: 0 })).validate();

const nullOrGreaterThan0 = (value) =>
    new Validator(value)
        .must(greaterThan(0), tValidation(validation.greaterThan, { number: 0 }))
        .when(notEmpty(value))
        .validate();

const products = (value, formValue) => {
    const { requiredQuantity, requiredValue } = formValue;
    return notEmptyArrayValidator(value)
        .when(requiredQuantity > 0 || requiredValue > 0)
        .validate();
};

const generatorId = (value) => {
    return new Validator(value)
        .mustRegex(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/, tValidation(validation.notValidGuidId))
        .when(notEmpty(value))
        .validate();
};

const hasAnswers = (value, formValue) => {
    const { hasAttachments, generatorId } = formValue;
    return new Validator(value)
        .must((value) => value !== true, tValidation(validation.mustBeNotChecked))
        .when(notEmpty(generatorId))
        .must((value) => value === true, tValidation(validation.mustBeChecked))
        .when(hasAttachments === false && isEmpty(generatorId))
        .validate();
};

const hasAttachments = (value, formValue) => {
    const { generatorId } = formValue;
    return new Validator(value)
        .must((value) => value !== true, tValidation(validation.mustBeNotChecked))
        .allWhen(notEmpty(generatorId))
        .validate();
};

export const asyncValidate = async (values, dispatch, form) => {
    if (values?.containerName) {
        const containerName = values.containerName;
        if (containerName && form?.initialValues?.containerName !== containerName) {
            const { payload } = await dispatch(getContentContainers());
            if (payload.includes(containerName)) {
                dispatch({
                    type: "containerNameValidation",
                    payload: {
                        containerName: tValidation(validation.containerNameAlreadyInUse),
                    },
                });
            }
        }
    }

    return Promise.resolve(undefined);
};

export const validators = {
    validFrom,
    validTo,
    contestName,
    containerName,
    categoryName,
    sectionName,
    obligatoryFrom,
    optionalFrom,
    name,
    greaterThan0,
    nullOrGreaterThan0,
    products,
    generatorId,
    hasAnswers,
    hasAttachments,
    asyncValidate,
};
