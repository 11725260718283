import { common } from "translations";
import { useTranslation } from "utils-ts/hooks";
import FormSelect from "../FormSelect";

const FormDayOfWeekSelect: React.FC<{ name: string; label: string; readOnly?: boolean }> = ({ name, label, readOnly }) => {
    const { t } = useTranslation();

    return (
        <FormSelect
            name={name}
            label={label}
            readOnly={readOnly}
            items={[
                { value: "Monday", name: t(common.Monday) },
                { value: "Tuesday", name: t(common.Tuesday) },
                { value: "Wednesday", name: t(common.Wednesday) },
                { value: "Thursday", name: t(common.Thursday) },
                { value: "Friday", name: t(common.Friday) },
                { value: "Saturday", name: t(common.Saturday) },
                { value: "Sunday", name: t(common.Sunday) },
            ]}
        />
    );
};

export default FormDayOfWeekSelect;
