import * as categories from "./categories";
import * as categoryTrees from "./categoryTrees";
import * as products from "./products";

const content = {
    categories,
    categoryTrees,
    products,
};

export default content;
