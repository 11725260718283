import types from "./types";

const initialState = {};

export default (state = initialState, { type, payload, meta }) => {
    switch (type) {
        case types.initForm:
            return initialState;
        case types.getGiftChoice.request:
            return { ...initialState, isLoading: true };
        case types.getGiftChoice.success:
            return {
                ...state,
                ...payload,
                id: meta.isCopy ? "" : payload.id,
                isLoading: false,
            };
        case types.getGiftChoice.failure:
            return { ...payload, isLoading: false };
        case types.saveGiftCoice.request:
            return { ...state, isSubbmitRequested: true };
        case types.saveGiftCoice.success:
        case types.saveGiftCoice.failure:
            return { ...state, isSubbmitRequested: false };
        case types.getGiftChoiceActivation.request:
            return { ...state };
        case types.getGiftChoiceActivation.success:
            return { ...state, activationInfo: payload };
        case types.getGiftChoiceActivation.failure:
            return {
                ...state,
                activationInfo: { reservationCount: 0, lastReservedAt: undefined },
            };
        case types.getGiftChoiceValidation.success:
            return { ...state, validation: payload };
        default:
            return state;
    }
};
