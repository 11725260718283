import { Button } from "components/Button";
import SimpleTable, { createColumn } from "components/Table/SimpleTable/SimpleTable";
import tableActionTypes from "components/Table/SimpleTable/TableActions/tableActionTypes";
import { findAllAccountingPeriods } from "store/accountingPeriods";
import {
    getSubsidiaryGainStatuses,
    getSubsidiaryGainUsers,
    getVendorRecipients,
    vendorSubsidiaryGainStatusesSelector,
    vendorSubsidiaryGainUsersSelector,
} from "store/autocomplete";
import { getPwu, getSgs, getSubsidiaryGainReportForVendor } from "store/vendors/subsidiaryGains/action";
import { sgListSelector } from "store/vendors/subsidiaryGains/selector";
import { useDebouncedCallback } from "use-debounce";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useParams, useUserAccountingPeriods } from "utils/hooks";
import { isAccountingPeriodOpen, isQuaterlyAccountingPeriodOpen } from "utils/utilsFunctions";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@mui/material";
import moment from "moment";
import { vendor } from "translations";
import { usePush, useTranslation } from "utils-ts/hooks";
import { IsVendorReadOnly } from "views/vendors/IsVendorReadOnly";
import SubsidiaryGainRemoveDialog from "./SubsidiaryGainRemoveDialog";

const SubsidiaryGains = () => {
    const dispatch = useDispatch();
    const { push } = usePush();
    const { id: vendorId } = useParams();
    const { t } = useTranslation("vendor");
    const isReadonly = IsVendorReadOnly("subsidiary-gain");
    const [subsidiaryGainId, setSubsidiaryGainId] = useState();

    const { accountingPeriods } = useUserAccountingPeriods(vendorId);

    const [dateFrom, setDateFrom] = useState();
    const [reservationKitId, setReservationKitId] = useState();

    const [filter, setFilter] = useState({
        dateFrom: undefined,
        dateTo: undefined,
        invoicedAt: undefined,
        invoiceDocumentNumber: undefined,
        responsibleUserId: undefined,
        decisionStatus: undefined,
    });

    const {
        autocomplete: { vendorRecipients },
        list,
    } = useSelector(sgListSelector);

    const subsidiaryGainUsers = useSelector(vendorSubsidiaryGainUsersSelector);

    const subsidiaryGainStatuses = useSelector(vendorSubsidiaryGainStatusesSelector);

    const handleOpen = (id, dateFrom, reservationKitId) => {
        setSubsidiaryGainId(id);
        setDateFrom(dateFrom);
        setReservationKitId(reservationKitId);
    };

    const handleClose = () => {
        setSubsidiaryGainId(null);
        setDateFrom(null);
    };

    useEffect(() => {
        dispatch(getVendorRecipients(vendorId));
        dispatch(getSubsidiaryGainUsers(vendorId));
        dispatch(getSubsidiaryGainStatuses(vendorId));
        dispatch(findAllAccountingPeriods());
    }, [dispatch, vendorId]);

    useEffect(() => {
        dispatch(
            getSgs(vendorId, {
                ...filter,
                pageSize: list.pageSize,
                pageIndex: list.pageIndex,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vendorId, dispatch, filter]);

    const { items, pageIndex, pageSize, totalCount, isLoading, isGeneratingReport, isDownloadingPwu } = list;

    const columns = useMemo(() => {
        const selectNameForVendorRecipient = (recipientId) => {
            if (!recipientId) return "";

            const recipient = vendorRecipients.find((x) => x.recipientId === recipientId);
            return `[${recipientId}] ${recipient?.name || ""}`;
        };

        return [
            {
                key: "invoiceRecipientId",
                value: t(vendor.invoiceRecipient),
                type: "text",
                transform: selectNameForVendorRecipient,
            },
            createColumn("serviceDocumentNumber", t(vendor.serviceDocumentNumber), formatTypes.text, {}, null, true),
            createColumn(
                "invoicedAt",
                t(vendor.invoicedAt),
                formatTypes.time,
                {
                    timeFormat: timePrecisonFormats.days,
                },
                null,
                true
            ),
            createColumn("invoiceDocumentNumber", t(vendor.invoiceDocumentNumber), formatTypes.text),
            createColumn(
                "dateFrom",
                t(vendor.dateFrom),
                formatTypes.time,
                {
                    timeFormat: timePrecisonFormats.days,
                },
                null,
                true
            ),
            createColumn(
                "dateTo",
                t(vendor.dateTo),
                formatTypes.time,
                {
                    timeFormat: timePrecisonFormats.days,
                },
                null,
                true
            ),
            createColumn("settlementPeriod", t(vendor.settlementPeriod), formatTypes.translatable, {
                translationKey: "vendor",
            }),
            createColumn("value", t(vendor.value), formatTypes.currency, {}),
            createColumn(
                "responsibleUser",
                t(vendor.responsibleUser),
                formatTypes.text,
                {
                    filterType: "select",
                    filterItems: subsidiaryGainUsers.map((x) => {
                        return {
                            value: x.email,
                            name: x.email,
                        };
                    }),
                },
                null,
                true
            ),
            createColumn(
                "action",
                "",
                formatTypes.action,
                {
                    actionType: (item) =>
                        isReadonly ||
                        (item?.current === "Quarterly"
                            ? isQuaterlyAccountingPeriodOpen(accountingPeriods, item?.dateFrom)
                            : isAccountingPeriodOpen(accountingPeriods, item?.dateFrom)) ||
                        item.decisionStatus === "ServiceRemoved"
                            ? tableActionTypes.preview
                            : tableActionTypes.edit,
                    resetButton: true,
                },
                (item) => {
                    const { id } = item;
                    push(`/vendors/${vendorId}/subsidiary-gains/form/${id}`);
                }
            ),
            !isReadonly &&
                createColumn(
                    "action",
                    "",
                    formatTypes.action,
                    { actionType: tableActionTypes.delete },
                    (item) => {
                        const { id, dateFrom: itemDateFrom, reservationKitId } = item;
                        handleOpen(id, moment(itemDateFrom).add(-1, "day"), reservationKitId);
                    },
                    undefined,
                    (item) =>
                        item.decisionStatus === "WaitingForDecision" ||
                        item.decisionStatus === "ServiceRemoved" ||
                        item.status === "InvoiceRequested" ||
                        item.status === "Invoiced" ||
                        item.invoiceDocumentNumber
                ),
            createColumn(
                "action",
                "",
                formatTypes.action,
                {
                    actionType: tableActionTypes.button,
                    buttonText: "Pobierz PWU",
                    isLoading: (item) => {
                        return isDownloadingPwu && item && isDownloadingPwu[item.id];
                    },
                },
                (item) => {
                    const { id } = item;
                    dispatch(getPwu(vendorId, id));
                },
                false,
                (item) =>
                    moment(item.dateTo).isSameOrAfter(moment().add(1, "day"), "day") ||
                    item.decisionStatus === "WaitingForDecision" ||
                    item.decisionStatus === "ServiceRejected" ||
                    item.decisionStatus === "ChangeRejected" ||
                    item.decisionStatus === "ServiceRemoved"
            ),
            createColumn(
                "statusToShow",
                t(vendor.changeStatus),
                formatTypes.translatable,
                {
                    translationKey: "vendor",
                    filterType: "select",
                    filterItems: subsidiaryGainStatuses.map((x) => {
                        return {
                            value: x,
                            name: t(x),
                        };
                    }),
                },
                null,
                true
            ),
        ];
    }, [accountingPeriods, dispatch, isReadonly, isDownloadingPwu, subsidiaryGainUsers, subsidiaryGainStatuses, t, vendorId, vendorRecipients]);

    const handleFilter = useDebouncedCallback((column, value) => {
        if (column) {
            setFilter({ ...filter, [column]: value });
        } else {
            setFilter();
        }
    }, 1500);

    return (
        <>
            <SimpleTable
                tableActions={[
                    !isReadonly && (
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                push(`/vendors/${vendorId}/subsidiary-gains/form`);
                            }}
                        >
                            <Icon>add</Icon>
                        </Button>
                    ),
                ].filter(Boolean)}
                selectable
                onSelectedExport={(selected) => {
                    dispatch(
                        getSubsidiaryGainReportForVendor(
                            vendorId,
                            selected.map((x) => x.id)
                        )
                    );
                }}
                isExportLoading={isGeneratingReport}
                persistentFilter
                isLoading={isLoading}
                columns={columns}
                filterUndefinedColumns
                data={items}
                filterFunction={handleFilter}
                onRowDoubleClick={(_, item) => {
                    const { id } = item;
                    push(`/vendors/${vendorId}/subsidiary-gains/form/${id}`);
                }}
                stripped
                hover
                pagination
                paginationProps={{
                    pageIndex,
                    totalCount,
                    pageSize,
                    onPageChange: (pageIndex) =>
                        dispatch(
                            getSgs(vendorId, {
                                ...filter,
                                pageSize: list.pageSize,
                                pageIndex,
                            })
                        ),
                    onRowsPerPageChange: (pageSize) =>
                        dispatch(
                            getSgs(vendorId, {
                                ...filter,
                                pageIndex: list.pageIndex,
                                pageSize,
                            })
                        ),
                }}
            />
            {subsidiaryGainId && (
                <SubsidiaryGainRemoveDialog
                    getSubsidiaryGains={() =>
                        getSgs(vendorId, {
                            ...filter,
                            pageIndex,
                            pageSize,
                        })
                    }
                    vendorId={vendorId}
                    subsidiaryGainId={subsidiaryGainId}
                    dateFrom={dateFrom}
                    handleClose={handleClose}
                    reservationKitId={reservationKitId}
                />
            )}
        </>
    );
};

export default SubsidiaryGains;
