import ArrayValidationError from "components/Controls/ArrayValidationError";
import { FormField } from "components/Form";
import { decimalNormalize } from "components/FormHelpers/ControlFormaters";
import ListActions from "components/ListActions/ListActions";
import { FormSection } from "redux-form";
import { userInfoWithRoleSelector } from "store/autocomplete";
import { useCommonTranslation } from "utils/hooks";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { validators } from "./validation";

const CatmanBudget = ({ form, fields, meta, readOnly, useDiff }) => {
    const { t, common } = useCommonTranslation();
    const users = useSelector(userInfoWithRoleSelector) || [];

    return (
        <>
            <ArrayValidationError {...meta} />
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="stretch"
            >
                <ListActions
                    renderCondition={!readOnly}
                    noMarginInDiver
                    handleAdd={() => {
                        fields.push({});
                    }}
                    handleRemove={(index) => {
                        fields.remove(index);
                    }}
                >
                    {fields.map((field, i) => {
                        return (
                            <FormSection
                                name={field}
                                key={i}
                            >
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >
                                    <FormField
                                        label={t(common.catman)}
                                        type="select"
                                        name="catman"
                                        useDiff={useDiff}
                                        items={
                                            users?.Catman?.map((u) => {
                                                return {
                                                    value: u?.userId,
                                                    name: `${u?.fullName?.firstName} ${u?.fullName?.lastName}`,
                                                };
                                            }) || []
                                        }
                                        readOnly={readOnly}
                                        validate={validators.validateCatmans}
                                    />
                                    <FormField
                                        name={"budget"}
                                        label={t(common.amount)}
                                        type={"text"}
                                        format={decimalNormalize}
                                        useDiff={useDiff}
                                        validate={validators.notEmptyNumberField}
                                        readOnly={readOnly}
                                    />
                                </Grid>
                            </FormSection>
                        );
                    })}
                </ListActions>
            </Grid>
        </>
    );
};

export default CatmanBudget;
