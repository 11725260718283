import { Button } from "components/Button";
import { AutocompleteField } from "components/Controls/Autocompletes";
import { InfoDialog, useModalState } from "components/Dialog";
import { FormField } from "components/Form";
import { PdfViewer } from "components/PdfViewer";
import fileTypes from "consts/fileTypes";
import { FormSection } from "redux-form";
import { documentArchiveCompanies } from "resource/documentArchiveCompanies";
import { documentCategoriesSelector, documentTypesSelector, vendorsSelector } from "store/autocomplete";
import { withFormName } from "utils/hoc";
import { useChange, useFormValueSelector } from "utils/hooks";
import { notEmptyValidator } from "utils/validators";
import Grid from "@mui/material/Grid";
import _ from "lodash";
import { common, documentArchive } from "translations";
import { useTranslation } from "utils-ts/hooks";
import Dropzone from "./Dropzone";

const createFieldName = (sectionPrefix, name) => `${sectionPrefix ? `${sectionPrefix}.` : ""}${name}`;

const required = (val) => notEmptyValidator(val).validate();

const DocumnetArchiveFile = ({
    form,
    sectionPrefix,
    hiddenFields = [],
    readOnly,
    useDialogPreview,
    hideFileDropZone,
    useDropzone,
    useDiff,
    readOnlyFields = [],
    documentTypeFilter,
    onChangeFile,
    validate = required,
}) => {
    const { modalState, handleOpen, handleClose } = useModalState(false);
    const {
        archiveFile = {},
        barcodePosition = {},
        companyId,
        barcode,
        ...rest
    } = useFormValueSelector(form, sectionPrefix ? [sectionPrefix] : ["archiveFile", "barcodePosition", "companyId", "barcode"], {});

    const { fileContent } = archiveFile;
    const { t } = useTranslation(["common", "documentArchive"]);
    const change = useChange(form);
    const company = documentArchiveCompanies.find((c) => c.value === companyId);

    const handleFileChanged = (file) => {
        const reader = new FileReader();

        if (file) {
            reader.onload = (e) => {
                const archiveFile = {
                    extension: `.${file.name.split(".").slice(-1)[0].toLowerCase()}`,
                    fileName: file.name,
                    fileContent: e.target.result.split(",")[1],
                };
                if (sectionPrefix) {
                    var changeFile = {
                        ...rest,
                        companyId,
                        archiveFile,
                        barcodePosition: {
                            positionX: 66,
                            positionY: 4,
                        },
                        documentFileInput: file,
                    };

                    if (onChangeFile) {
                        onChangeFile(changeFile);
                    }

                    change(sectionPrefix, changeFile);
                } else {
                    change("archiveFile", archiveFile);
                    change("barcodePosition", {
                        positionX: 66,
                        positionY: 4,
                    });
                    change("documentFileInput", file);
                }
            };

            reader.readAsDataURL(file);
        }
    };

    const handleDrop = (acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            var reader = new FileReader();
            reader.onload = (e) => {
                const archiveFile = {
                    extension: `.${file.name.split(".").slice(-1)[0].toLowerCase()}`,
                    fileName: file.name,
                    fileContent: e.target.result.split(",")[1],
                };

                if (sectionPrefix) {
                    change(sectionPrefix, {
                        ...rest,
                        companyId,
                        archiveFile,
                        barcodePosition: {
                            positionX: 66,
                            positionY: 4,
                        },
                        documentFileInput: file,
                    });
                } else {
                    change("archiveFile", archiveFile);
                    change("barcodePosition", {
                        positionX: 66,
                        positionY: 4,
                    });
                    change("documentFileInput", file);
                }
            };

            reader.readAsDataURL(file);
        });
    };

    const hasFile = !_.isEmpty(fileContent);
    const hideFile = hiddenFields.some((x) => x === "archiveFile");

    const pdfViewer = hasFile && (
        <PdfViewer
            mode="base64"
            file={fileContent}
            isLoading={false}
        />
    );

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <FormField
                    useDiff={useDiff}
                    name="companyId"
                    label={t(common.company)}
                    type={"select"}
                    items={documentArchiveCompanies}
                    validate={readOnly ? undefined : validate}
                    readOnly={readOnly || readOnlyFields.some((x) => x === "companyId")}
                    hidden={hiddenFields.some((x) => x === "companyId")}
                    onChange={(_, value) => {
                        change(createFieldName(sectionPrefix, "companyId"), value);
                        change(createFieldName(sectionPrefix, "vendor"), null);
                        change(createFieldName(sectionPrefix, "vendorId"), null);
                    }}
                />
                <AutocompleteField
                    useDiff={useDiff}
                    label={t(common.vendor)}
                    name="vendor"
                    dataSelector={vendorsSelector}
                    getOptionLabel={(option) => (option.name ? option.name + (option.isActive ? " (aktywny)" : " (nieaktywny)") : "")}
                    validate={readOnly ? undefined : validate}
                    readOnly={readOnly || readOnlyFields.some((x) => x === "vendor")}
                    hidden={hiddenFields.some((x) => x === "vendor")}
                    onCustomChange={async (_, newValue) => {
                        change(createFieldName(sectionPrefix, "vendor"), newValue);
                        change(createFieldName(sectionPrefix, "vendorId"), newValue?.vendorId);
                    }}
                    filterItems={(items) => items.filter((v) => v.vendorId.endsWith(company?.merchant))}
                    noOptionsText={
                        companyId
                            ? t(common.noVendorsForMerchant, {
                                  merchant: company.name,
                              })
                            : t(common.selectCompanyFirst)
                    }
                />
                <AutocompleteField
                    useDiff={useDiff}
                    compareOptionId
                    label={t(common.documentCategory)}
                    name="documentCategory"
                    dataSelector={documentCategoriesSelector}
                    validate={readOnly ? undefined : validate}
                    readOnly={readOnly || readOnlyFields.some((x) => x === "documentCategory")}
                    hidden={hiddenFields.some((x) => x === "documentCategory")}
                    onCustomChange={async (_, newValue) => {
                        change(createFieldName(sectionPrefix, "documentCategory"), newValue);
                        change(createFieldName(sectionPrefix, "categoryId"), newValue?.id);
                    }}
                />
                <AutocompleteField
                    useDiff={useDiff}
                    compareOptionId
                    label={t(common.documentType)}
                    name="documentType"
                    dataSelector={documentTypesSelector}
                    validate={readOnly ? undefined : validate}
                    readOnly={readOnly || readOnlyFields.some((x) => x === "documentType")}
                    hidden={hiddenFields.some((x) => x === "documentType")}
                    onCustomChange={async (_, newValue) => {
                        change(createFieldName(sectionPrefix, "documentType"), newValue);
                        change(createFieldName(sectionPrefix, "documentTypeId"), newValue?.id);
                    }}
                    filterItems={documentTypeFilter}
                />
                <FormField
                    useDiff={useDiff}
                    name="externalDocumentNumber"
                    label={t(common.externalDocumentNumber)}
                    type="text"
                    readOnly={readOnly || readOnlyFields.some((x) => x === "externalDocumentNumber")}
                    hidden={hiddenFields.some((x) => x === "externalDocumentNumber")}
                />
                <FormField
                    useDiff={useDiff}
                    name="documentDate"
                    label={t(`documentArchive:${documentArchive.documentDate}`)}
                    type="date"
                    validate={readOnly ? undefined : validate}
                    readOnly={readOnly || readOnlyFields.some((x) => x === "documentDate")}
                    hidden={hiddenFields.some((x) => x === "documentDate")}
                />
                {!hideFile && (
                    <>
                        <FormSection name="archiveFile">
                            <FormField
                                useDiff={useDiff}
                                name="fileContent"
                                label={t(`documentArchive:${documentArchive.documentFile}`)}
                                hidden
                                validate={readOnly ? undefined : validate}
                                readOnly={readOnly}
                            />
                            <FormField
                                useDiff={useDiff}
                                name="extension"
                                label={t(`documentArchive:${documentArchive.documentFile}`)}
                                hidden
                                validate={readOnly ? undefined : validate}
                                readOnly={readOnly}
                            />
                            <FormField
                                name="fileName"
                                type="text"
                                label={"documentFileName"}
                                style={{ display: "none" }}
                                validate={readOnly ? undefined : validate}
                                readOnly
                                hidden
                            />
                        </FormSection>
                        <Grid
                            item
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-end"
                        >
                            {!hideFileDropZone &&
                                (useDropzone ? (
                                    <Dropzone
                                        disabled={readOnly}
                                        onDrop={handleDrop}
                                        hidden={hiddenFields.some((x) => x === "documentFileInput")}
                                        maxFiles={1}
                                        hasFile={hasFile}
                                        required={validate}
                                        accept={{ [fileTypes.pdf.mimeType]: [fileTypes.pdf.extension] }}
                                    />
                                ) : (
                                    <FormField
                                        useDiff={useDiff}
                                        name="documentFileInput"
                                        label={t(`documentArchive:${documentArchive.documentFile}`)}
                                        type={"file"}
                                        onChange={handleFileChanged}
                                        validate={readOnly ? undefined : validate}
                                        readOnly={readOnly}
                                        hidden={hiddenFields.some((x) => x === "documentFileInput")}
                                        multiple={false}
                                        accept="*.pdf"
                                    />
                                ))}
                        </Grid>
                    </>
                )}
            </Grid>
            {!hideFile &&
                (useDialogPreview ? (
                    <>
                        <InfoDialog
                            open={modalState}
                            title={"filePreview"}
                            onClose={handleClose}
                        >
                            <>
                                {pdfViewer}
                                <Button
                                    color="primary"
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    Zapisz i zamknij
                                </Button>
                            </>
                        </InfoDialog>
                    </>
                ) : (
                    <div style={{ marginTop: 20 }}>{pdfViewer}</div>
                ))}
        </>
    );
};

export default withFormName(DocumnetArchiveFile);
