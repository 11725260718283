import { FormField } from "components/Form";
import { useCommonTranslation } from "utils/hooks";
import { notEmptyAndInteger } from "utils/validators/basic";
import React, { Fragment } from "react";

const ChosenProductModifier = () => {
    const { t, common } = useCommonTranslation();

    return (
        <Fragment>
            <FormField
                name="modifier.discountPercent"
                label={t(common.discountPercent)}
                type="number"
                validate={notEmptyAndInteger}
            />
        </Fragment>
    );
};

export default ChosenProductModifier;
