import defaultListState from "store/defaultListState";
import { combineReducers } from "redux";
import types from "./types";

const vendorOperationFormInitialState = {
    isLoading: false,
    operation: {},
};

const listInitialState = defaultListState;

export const form = (state = vendorOperationFormInitialState, { type, payload }) => {
    switch (type) {
        case types.clear:
            return { ...vendorOperationFormInitialState };
        case types.getVendorOperation.request:
            return { ...state, isLoading: true };
        case types.getVendorOperation.success:
            return { ...state, isLoading: false, operation: { ...payload } };
        case types.getVendorOperation.failure:
            return { ...state, isLoading: false };
        default:
            return state;
    }
};

export const list = (state = listInitialState, { type, payload }) => {
    switch (type) {
        case types.findVendorOperations.request:
            return { ...state, isLoading: true };
        case types.findVendorOperations.success:
            return { ...state, isLoading: false, ...payload };
        case types.findVendorOperations.failure:
            return { ...state, isLoading: false };
        default:
            return state;
    }
};

export const vendorOperation = combineReducers({
    form,
    list,
});
