import { makeStyles } from "tss-react/mui";
import React from "react";
import { Icon } from "@mui/material";
import classnames from "classnames";

const useStyles = makeStyles()((theme) => ({
    icon: {
        fontSize: "1.75rem",
    },
    check: {
        color: theme.palette.success.main,
    },
    info: {
        color: theme.palette.primary.light,
    },
    error: {
        color: theme.palette.error.main,
    },
    warning: {
        color: theme.palette.warning.main,
    },
}));

export const iconTypes = {
    success: "check",
    info: "info",
    warrning: "warning",
    danger: "block",
};

const InfoIcon = ({ iconType }) => {
    const { classes } = useStyles();

    return (
        <Icon
            className={classnames(classes.icon, classes[iconType])}
            fontSize="small"
        >
            {iconType}
        </Icon>
    );
};

export default React.memo(InfoIcon);
