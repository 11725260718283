import Button from "components/Button/Button";
import { FormField } from "components/Form";
import Layout from "components/Grid/Layout";
import { reduxForm } from "redux-form";
import { actions } from "store/dictonaries/costReason/form/actions";
import { useCommonTranslation, useMappedDispatch, useParams } from "utils/hooks";
import { notEmptyValidator } from "utils/validators/basic";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { usePush } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { costReaonsFormSelector } from "./selectors";

const validators = {
    campaignName: (value) => notEmptyValidator(value).validate(),
};

const CostReaonsForm = ({ handleSubmit, isSubbmitRequested, costReasonName }) => {
    const { id } = useParams();
    const { initForm, createOrUpdate } = useMappedDispatch(actions);
    const { replace } = usePush();
    useEffect(() => {
        initForm(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    const { t, common } = useCommonTranslation();
    const submit = async (values) => {
        const { payload } = await createOrUpdate(values, id);
        if (!id) {
            replace(Paths.Dictionaries.CostReasonForm, { id: payload.id });
        }
    };

    return (
        <form onSubmit={handleSubmit((values) => submit(values))}>
            <Layout
                main
                headerText={t(common.costReasonForm, {
                    id: id || t(common.newItem),
                })}
                navigationProps={{
                    backProps: { path: "/dictionaries/cost-reasons" },
                }}
                customTitle={id && costReasonName && `${t(common.costReasonForm)} - ${costReasonName}`}
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <FormField
                        name={"costReasonName"}
                        type={"text"}
                        label={t(common.name)}
                        validate={validators.campaignName}
                    />
                    <FormField
                        name={"isActive"}
                        type={"boolean"}
                        label={t(common.isActive)}
                    />
                </Grid>
            </Layout>
            <Button
                actionType="accept"
                type="submit"
                fullWidth
                loading={isSubbmitRequested}
            >
                {t(common.save)}
            </Button>
        </form>
    );
};

const formName = "cost-reasons-form";

const stateToProps = (state) => {
    const { isSubbmitRequested, ...form } = costReaonsFormSelector(state);
    return {
        isSubbmitRequested,
        costReasonName: form.costReasonName,
        initialValues: {
            ...form,
            isActive: form.isActive !== undefined ? form.isActive : true,
        },
    };
};

export default connect(stateToProps)(
    reduxForm({
        form: formName,
        enableReinitialize: true,
    })(CostReaonsForm)
);
