import SimpleTable, { createColumn } from "components/Table/SimpleTable/SimpleTable";
import { actions } from "store/vendors/action";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useMappedDispatch } from "utils/hooks";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { invoicesSelector } from "../selectors";

const Invoices = ({ vendorId }) => {
    const { t } = useTranslation("vendor");
    let { invoices } = useSelector(invoicesSelector);
    const { findVendorInvoices } = useMappedDispatch(actions);
    if (!invoices.items || invoices.items.length === 0) {
        invoices.items = [
            {
                invoiceNumber: "FV/100/12/08/2020",
                invoiceDate: new Date(),
                paymentDate: new Date(),
                deliveryDate: new Date(),
                netValue: 1000,
                grossValue: 1100,
                type: "FV",
            },
            {
                invoiceNumber: "FV/101/12/08/2020",
                invoiceDate: new Date(),
                paymentDate: new Date(),
                deliveryDate: new Date(),
                netValue: 14000,
                grossValue: 1100,
                type: "FV",
            },
            {
                invoiceNumber: "FV/200/12/08/2020",
                invoiceDate: new Date(),
                paymentDate: new Date(),
                deliveryDate: new Date(),
                netValue: 2000,
                grossValue: 1100,
                type: "FV",
            },
            {
                invoiceNumber: "FV/109/12/08/2020",
                invoiceDate: new Date(),
                paymentDate: new Date(),
                deliveryDate: new Date(),
                netValue: 10090,
                grossValue: 13100,
                type: "COR",
            },
            {
                invoiceNumber: "FV/123/12/08/2020",
                invoiceDate: new Date(),
                paymentDate: new Date(),
                deliveryDate: new Date(),
                netValue: 103400,
                grossValue: 11030,
                type: "COR",
            },
        ];
        invoices.totalCount = 5;
    }

    const { pageIndex, pageSize, totalCount, isLoading } = invoices;
    useEffect(() => {
        findVendorInvoices(vendorId, pageIndex, pageSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vendorId]);

    const columns = [
        createColumn("type", t(vendor.InvoiceType), formatTypes.text, {
            translate: true,
        }),

        createColumn("invoiceNumber", t(vendor.invoiceNumber), formatTypes.text),

        createColumn("invoiceDate", t(vendor.invoiceDate), formatTypes.time, {
            timeFormat: timePrecisonFormats.seconds,
        }),
        createColumn("paymentDate", t(vendor.paymentDate), formatTypes.time, {
            timeFormat: timePrecisonFormats.seconds,
        }),
        createColumn("deliveryDate", t(vendor.deliveryDate), formatTypes.time, {
            timeFormat: timePrecisonFormats.seconds,
        }),
        createColumn("netValue", t(vendor.netValue), formatTypes.currency),
        createColumn("grossValue", t(vendor.grossValue), formatTypes.currency),
    ];

    return (
        <SimpleTable
            isLoading={isLoading}
            columns={columns}
            data={invoices.items || []}
            hover
            pagination
            stripped
            paginationProps={{
                pageIndex,
                totalCount,
                pageSize,
                onPageChange: (pageIndex) => findVendorInvoices(vendorId, pageIndex, pageSize),
                onRowsPerPageChange: (pageSize) => findVendorInvoices(vendorId, pageIndex, pageSize),
            }}
        />
    );
};

export default Invoices;
