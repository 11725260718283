import { GetRequest, PutRequest, get, put } from "utils/extensions";
import types from "./types";

const endpoint = (year, month) => `api/accounting-periods${year && month ? `/${year}/${month}` : ""}`;

export const updateQuery = (params) => (dispatch) => {
    return dispatch({ type: types.updateQuery, payload: params });
};

export const getAccountingPeriods = (params) => (dispatch) => {
    return dispatch(
        get(
            new GetRequest(`${endpoint()}`, types.findAccountingPeriods.all, {
                queryParams: params,
            })
        )
    );
};

export const initForm = (year, month) => async (dispatch) => {
    await dispatch({ type: types.formInit });

    if (year && month) {
        return dispatch(get(new GetRequest(endpoint(year, month), types.getAccountingPeriod.all)));
    }
};

export const updateOverrides = (year, month, values) => async (dispatch) => {
    return await dispatch(
        put(
            new PutRequest(endpoint(year, month) + "/update-overrides", types.updateOverides.all, {
                body: values,
            })
        )
    );
};

export const findAllAccountingPeriods = () => async (dispatch) => {
    return dispatch(get(new GetRequest(`${endpoint()}/find-all`, types.findAllAccountingPeriods.all)));
};
