import { makeStyles } from "tss-react/mui";
import React from "react";
import { useFormState } from "react-hook-form";
import { FormHelperText as MuiFormHelperText, Theme } from "@mui/material";
import _ from "lodash";
import { ValidationError } from "control-types";

const useStyles = makeStyles()((theme: Theme) => ({
    error: {
        color: theme.palette.error.main,
        whiteSpace: "pre-wrap",
    },
}));

const FormArrayHelperText: React.FC<{ name: string }> = ({ name }) => {
    const { errors } = useFormState({ name, exact: false });
    const { classes } = useStyles();
    const formError = _.get(errors, name);

    const error: ValidationError | undefined = formError
        ? ({
              hasError: true,
              message: formError.message,
          } as ValidationError)
        : undefined;

    if (!error || !error.hasError) {
        return <></>;
    } else {
        return <MuiFormHelperText className={classes.error}>{error.message}</MuiFormHelperText>;
    }
};

export default FormArrayHelperText;
