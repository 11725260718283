import { RSAA } from "redux-api-middleware";
import { commerce } from "store/actionTypePrefixes";
import { combineIntoQueryParams } from "utils/objectExtensions";

export const types = {
    GET_REVIEWS: `${commerce}/GET_REVIEWS`,
    GET_REVIEWS_SUCCESS: `${commerce}/GET_REVIEWS_SUCCESS`,
    GET_REVIEWS_FAILURE: `${commerce}/GET_REVIEWS_FAILURE`,
};

const getReviews = (params) => async (dispatch) => {
    return dispatch({
        [RSAA]: {
            endpoint: `api/v1/users/orders/reviews?${combineIntoQueryParams(params)}`,
            headers: { "Content-Type": "application/json" },
            method: "GET",
            types: [types.GET_REVIEWS, types.GET_REVIEWS_SUCCESS, types.GET_REVIEWS_FAILURE],
        },
    });
};

export const actions = {
    getReviews,
};
