import { combineReducers } from "redux";
import { types } from "./types";

const initialState = {
    list: {
        isLoading: false,
        items: [],
    },
};

const list = (state = initialState.list, { type, payload }) => {
    switch (type) {
        case types.list.request:
            return { ...state, isLoading: true };
        case types.list.success:
            return { items: payload, isLoading: false };
        case types.list.failure:
            return { ...payload, isLoading: false };
        default:
            return state;
    }
};

export default combineReducers({
    list,
});
