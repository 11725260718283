import { default as isAccountingPeriodOpen } from "./isAccountingPeriodOpen";

const isQuaterlyAccountingPeriodOpen = (accountingPeriods, dateFromQuater) => {
    const monthsInQuater = Array(3)
        .fill()
        .map((_, i) => dateFromQuater.clone().add(i, "M"));

    return monthsInQuater.some((o) => isAccountingPeriodOpen(accountingPeriods, o));
};

export default isQuaterlyAccountingPeriodOpen;
