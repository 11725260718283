import InfoIcon, { iconTypes } from "components/Icons/InfoIcon";
import RatingIcon from "components/Icons/RatingIcon";
import React, { Fragment } from "react";
import { FileCopy } from "@mui/icons-material";
import { Button, Grid, Icon, IconButton, Tooltip, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import moment from "moment";
import _ from "lodash";
import { tValidation } from "utils-ts/validations/translation";
import mask, { vatinMask } from "./mask";
import stringStyle from "./stringStyle";
import timePrecisonFormats from "./timePrecisonFormats";

const formatFullName = ({ firstName, lastName }) => `${firstName} ${lastName}`;

const formatDeliveryWindow = ({ startsAt, endsAt, method, deliveryMethod, isFlexibleSlots }, options = { onlyHour: false }) => {
    if (method === "DPD") {
        return "Kurier";
    }

    let value = formatDateTime(
        `${moment(startsAt).format(!options.onlyHour ? timePrecisonFormats.minutes : "HH-mm")}-${moment(endsAt).format("HH:mm")}`,
        isFlexibleSlots ? (
            <Tooltip
                title="Rozszerzony termin dostawy"
                aria-label="Rozszerzony termin dostawy"
                placement="right-start"
                style={{ display: "inline-block" }}
            >
                <Icon
                    fontSize="small"
                    style={{ color: green[500] }}
                >
                    energy_savings_leaf
                </Icon>
            </Tooltip>
        ) : undefined
    );

    if (options.includeDeliveryMethod) {
        value = (
            <React.Fragment>
                {deliveryMethod}
                <br />
                {value}
            </React.Fragment>
        );
    }

    return value;
};

const fromatShippingAddress = ({ postcode = "", city = "", street = "", buildingNumber = "", apartmentNumber }) => {
    return (
        <>
            <strong>{`${postcode} ${city} `}</strong>
            <br />
            {`${street} ${buildingNumber} ${apartmentNumber ? `m. ${apartmentNumber}` : ""}`}
        </>
    );
};

const formatFloat = (value, options = {}) => {
    const { useThousandsSeparator, decimalSeparator } = options;
    if (useThousandsSeparator) {
        return parseFloat(value)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            .replace(".", decimalSeparator ?? ",");
    } else {
        return parseFloat(value).toFixed(2).toString().replace(".", ",");
    }
};

const formatTranslatable = (key, value) => {
    return tValidation(value.toString(), {}, key);
};

const formatMask = (value, pattern) => {
    return mask(value, pattern);
};

const formatDateTime = (dateTimeString, icon = undefined) => {
    const [date, time] = dateTimeString.split(" ");

    return (
        <React.Fragment>
            <strong>{date}</strong>
            <br />
            {time}
            {icon}
        </React.Fragment>
    );
};

const formatTime = (value, timeFormat) => {
    const formattedString = moment(value).format(timeFormat);

    const timeFormats = [timePrecisonFormats.hours, timePrecisonFormats.minutes, timePrecisonFormats.seconds];
    if (timeFormats.some((x) => x === timeFormat)) {
        return formatDateTime(formattedString);
    }

    return formattedString;
};

const formatLink = (value, options) => {
    const { url, target, addCopyIcon } = options;
    const href = _.isFunction(url) ? url(value) : url;

    return (
        <Fragment>
            {addCopyIcon ? (
                <IconButton
                    onClick={() => {
                        const el = document.createElement("textarea");
                        el.value = url;
                        document.body.appendChild(el);
                        el.select();
                        document.execCommand("copy");
                        document.body.removeChild(el);
                    }}
                >
                    <FileCopy />
                </IconButton>
            ) : (
                <Fragment />
            )}
            <Button
                variant="text"
                href={href}
                target={target}
                color="primary"
            >
                {value}
            </Button>
        </Fragment>
    );
};

const formatString = (value, options) => {
    let val = value;
    if (_.isArray(val)) {
        return val.map((x, i) => (
            <Fragment key={i}>
                <span>{x}</span>
                <br />
            </Fragment>
        ));
    }

    switch (options.style) {
        case stringStyle.imporant:
            return <strong>{val.toString()}</strong>;
        default:
            return val.toString();
    }
};

const formatVoucherKind = (source, options) => {
    let key = "N/A";
    if (source) {
        if (source.voucherValue) {
            key = "value_voucher";
        } else if (source.paymentAmount) {
            key = "amount_voucher";
        } else if (source.voucherPercent) {
            key = "percent_voucher";
        } else if (source.cashbackPercent) {
            key = "cashback_voucher";
        } else {
            key = "promotion_voucher";
        }
    }
    if (options && !options.translate) return key;

    return formatTranslatable("voucherKinds", key);
};

const formatVoucherValue = (definition) => {
    const { voucherPercent, voucherValue, paymentAmount, cashbackPercent } = definition;
    const value = voucherPercent
        ? asPercent(voucherPercent)
        : voucherValue
        ? asCurrency(voucherValue)
        : cashbackPercent
        ? asPercent(cashbackPercent)
        : paymentAmount
        ? asCurrencyAmount(paymentAmount)
        : "";
    return value;
};

const formatRating = (value, { max, numeric }) => {
    if (numeric) return <Typography>{`${value}/${max}`}</Typography>;
    return _.range(0, max).map((x) => (
        <RatingIcon
            key={x}
            outlined={x >= value}
        />
    ));
};

const formatArrayCount = (value) => (Array.isArray(value) ? value.length : "");

const currencyAmountHelper = (text, value, isLost) => {
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            style={{ paddingRight: "3px" }}
        >
            <Grid
                item
                xs="auto"
            >
                <strong>
                    {text}:{text?.length < 4 ? "\u00A0\u00A0\u00A0" : ""}
                </strong>
                &nbsp;
                {isLost ? <s>{value}</s> : value}
            </Grid>
        </Grid>
    );
};

const formatCurrencyAmount = (balanceAmount) => {
    const { _total = 0, FRS = 0, F24 = 0 } = balanceAmount;

    return (
        <Fragment>
            {currencyAmountHelper("Suma", asCurrency(_total))}
            {currencyAmountHelper("FRS", asCurrency(FRS))}
            {currencyAmountHelper("F24", asCurrency(F24))}
        </Fragment>
    );
};

const formatInfoIcon = (value, { tooltip }) => {
    if (tooltip && value) {
        return (
            <Tooltip
                title={tooltip}
                aria-label={tooltip}
                placement="right-start"
            >
                <div>
                    <InfoIcon iconType={value} />
                </div>
            </Tooltip>
        );
    } else {
        return <InfoIcon iconType={value} />;
    }
};

const formatGrammage = (value, options) => {
    let formated = `${value} g`;
    if (_.isEmpty(options)) return formated;

    const { unitOfMeasure } = options;
    if (!unitOfMeasure) return formated;

    if (unitOfMeasure) {
        return `${value} ${unitOfMeasure}`;
    }
};

const formatCredtiLimit = (value, options) => {
    const { creditAmount, paymentTerm, isLost } = value;
    const { useFullLabel } = options;
    return (
        <>
            {currencyAmountHelper(formatTranslatable("common", useFullLabel ? "creditAmount" : "limit"), creditAmount, isLost)}
            {currencyAmountHelper(formatTranslatable("common", useFullLabel ? "paymentTerm" : "timeDays"), paymentTerm, isLost)}
        </>
    );
};

const True = <InfoIcon iconType={iconTypes.success} />;
const False = <InfoIcon iconType={iconTypes.danger} />;

const formatBooleanAsIcon = (value, useIcon, negation) => {
    if (negation) value = !value;
    if (!useIcon) return formatTranslatable("common", value);

    if (value === true) {
        return True;
    } else if (value === false) {
        return False;
    }

    return <></>;
};

const formatCorrection = (value) => {
    if (!value) {
        return <></>;
    } else {
        switch (value) {
            default:
            case "NoOp":
                return "Nie dotyczy";
            case "Corrected":
                return "Skorygowano";
            case "RequiresDecision":
                return "W akceptacji";
            case "Rejected":
                return "Odrzucono";
        }
    }
};

const formatUserInfo = (value) => {
    if (!value || !value.email) {
        return <></>;
    } else {
        return value.email;
    }
};

const formatResource = (value, { items }) => {
    const val = items.find((i) => i.value === value);
    return val ? val.name : value;
};

export const asString = (value, options) => formatString(value, options || {});
export const asFloat = (value, options = {}) => formatFloat(value, options);
export const asCurrency = (value, options) => (
    <span
        style={{
            color: !options?.diff || value === 0 ? "black" : value > 0 ? "#00FF00" : "#FF0000",
        }}
    >
        {value ? formatString(`${!options?.diff || value <= 0 ? "" : "+"}${formatFloat(value, options)} zł`, options || {}) : ""}
    </span>
);

export const asGrammage = (value, options) => formatGrammage(value, options);
export const asTime = (value, options) => formatTime(value, options.timeFormat);
export const asBoolean = (value, options = {}) => formatBooleanAsIcon(value, options.useIcon, options.negation);
export const asCorrection = (value) => formatCorrection(value);
export const asTranslated = (value, options) => formatTranslatable(options.translationKey, value);
export const asFullName = (value) => formatFullName(value);
export const asShippingAddress = (value) => fromatShippingAddress(value);
export const asDeliveryWindow = (value, options) => formatDeliveryWindow(value, options || {});
export const asVatin = (value) => formatMask(value, vatinMask);
export const asLink = (value, options) => formatLink(value, options);
export const asPercent = (value) => `${value} %`;
export const asVoucherKind = (value, options) => formatVoucherKind(value, options);
export const withFunc = (value, options) => options.func(value);
export const asRating = (value, options) => formatRating(value, options);
export const asPoints = (value) => `${value} ptk.`;
export const asQuantity = (value) => `${value} szt.`;
export const asCurrencyAmount = (value) => formatCurrencyAmount(value);
export const asNumeric = (value) => (value === 0 ? "0" : Number(value));
export const asArrayCount = (value) => formatArrayCount(value);
export const asVoucherValue = (value) => formatVoucherValue(value);
export const asInfoIcon = (value, options) => formatInfoIcon(value, options || {});
export const asUserInfo = (value) => formatUserInfo(value);
export const asCreditLimit = (value, options) => formatCredtiLimit(value, options || { useFullLabel: false });
export const asResource = (value, options) => formatResource(value, options);
