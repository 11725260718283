import { withStyles } from "tss-react/mui";
import { withTranslation } from "react-i18next";
import { Icon } from "@mui/material";
import { Grid, Typography } from "@mui/material/";
import Divider from "@mui/material/Divider";

const styles = (theme) => ({
    typhography: {
        fontWeight: "bold",
        textTransform: "uppercase",
        color: theme.palette.secondary.main,
    },
    header: {
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(2),
    },
    icon: {
        marginTop: theme.spacing(2.4),
        color: theme.palette.secondary.main,
    },
    marginBottom: {
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
        height: 1.1,
    },
});

const gridHeader = ({ t, classes, title, iconName, hideBottomLine = false, translateTitle = true }) =>
    title ? (
        <div className={classes.header}>
            <Grid
                container
                direction="row"
                alignItems="center"
            >
                {iconName && (
                    <Grid item>
                        <Typography
                            variant="body1"
                            className={classes.typhography}
                        >
                            <Icon className={classes.icon}>{iconName}</Icon>
                        </Typography>
                    </Grid>
                )}
                <Grid
                    item
                    className={iconName ? classes.header : ""}
                >
                    <Typography
                        variant="body1"
                        className={classes.typhography}
                    >
                        {translateTitle ? t(title) : title}
                    </Typography>
                </Grid>
            </Grid>
            {!hideBottomLine && (
                <Divider
                    className={classes.marginBottom}
                    variant="fullWidth"
                />
            )}
        </div>
    ) : (
        <></>
    );

export default withTranslation()(withStyles(gridHeader, styles));
