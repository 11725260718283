import AcceptRejectDialog from "components/Dialog/AcceptRejectDialog";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TextWithLabel from "components/Text/TextWithLabel";
import paymentMethods from "resource/paymentMethods.json";
import paymentStatuses from "resource/paymentStatuses.json";
import formatTypes from "utils/formating/formatTypes";
import timePrecisonFormats from "utils/formating/timePrecisonFormats";
import React from "react";
import { FormControlLabel, Grid, Switch, Typography } from "@mui/material";
import { common, payments } from "translations";
import { useTranslation } from "utils-ts/hooks";

const Withhold = (props) => {
    const { open, onClose, reloadTable, payment, witholdPayment } = props;
    const [notify, setNotify] = React.useState(false);
    const { t } = useTranslation();
    const paymentFields = [
        { label: payments.paymentId, value: payment.id },
        { label: payments.orderId, value: payment.orderId },
        {
            label: payments.paymentMethod,
            value: payment.paymentMethod,
            format: formatTypes.resource,
            options: { items: paymentMethods },
        },
        {
            label: payments.channelName,
            value: payment.channelName,
            format: formatTypes.text,
        },
        {
            label: payments.paymentAmount,
            value: payment.paymentAmount,
            format: formatTypes.currencyAmount,
        },
        {
            label: payments.status,
            value: payment.status,
            format: formatTypes.resource,
            options: { items: paymentStatuses },
        },
        {
            label: payments.refunded,
            value: payment.refunded,
            format: formatTypes.currencyAmount,
        },
        {
            label: payments.createdAt,
            value: payment.createdAt,
            format: formatTypes.time,
            options: { timeFormat: timePrecisonFormats.seconds },
        },
    ];

    return (
        <AcceptRejectDialog
            open={open}
            handleAccept={async () => {
                await witholdPayment(payment.id, !notify);
                onClose();
                reloadTable();
            }}
            handleReject={onClose}
            buttonAcceptText={t(common.withhold)}
            buttonRejectText={t(common.cancel)}
            title={t(common.withholdPayment, { id: payment.id })}
            maxWidth="sm"
        >
            <Typography>{t(common.areYouSure, { what: "wstrzymać płatność" })}</Typography>

            <GridContainer>
                {paymentFields.map((item) => (
                    <GridItem
                        key={item.label}
                        xs={6}
                    >
                        <TextWithLabel {...item} />
                    </GridItem>
                ))}
            </GridContainer>

            <Grid container>
                <Grid item>
                    <FormControlLabel
                        control={<Switch onChange={(event) => setNotify(event.target.checked)} />}
                        label={t(common.isNotifying)}
                    />
                </Grid>
            </Grid>
        </AcceptRejectDialog>
    );
};

export default Withhold;
