import { decimalCount, isInteger } from "utils/numberExtensions";
import Validator, { greaterThan, lessThan, mustBeNumber, notEmptyArray, notEmptyValidator } from "utils/validators/basic";
import shippingAddressValidator from "utils/validators/shippingAddressValidator";
import validateBillingAddress from "utils/validators/validateBillingAddress";
import _ from "lodash";
import { tValidation, validation } from "utils-ts/validations/translation";

function priceValidator(price, originalPrice) {
    return notEmptyValidator(price)
        .must(mustBeNumber, tValidation(validation.mustBeNumber))
        .must(greaterThan(0), tValidation(validation.mustBePossitive))
        .must(lessThan(originalPrice), tValidation(validation.lessThan, { value: originalPrice }))
        .must(() => decimalCount(price) === 2 || decimalCount(price) === 0, tValidation(validation.mustHaveLessDecimalCount));
}

function upsellPriceValidator(price, originalPrice) {
    return new Validator(price)
        .must(mustBeNumber, tValidation(validation.mustBeNumber))
        .must(greaterThan(0), tValidation(validation.mustBePossitive))
        .must(lessThan(originalPrice), tValidation(validation.lessThan, { value: originalPrice }))
        .must(() => decimalCount(price) === 2 || decimalCount(price) === 0, tValidation(validation.mustHaveLessDecimalCount))
        .allWhen(Boolean(price));
}

function quantityValidator(quantity) {
    return notEmptyValidator(quantity)
        .must(mustBeNumber, tValidation(validation.mustBeNumber))
        .must(isInteger, tValidation(validation.mustBeInteger))
        .must(greaterThan(0), tValidation(validation.mustBePossitive));
}

function realQuantityValidator(quantity) {
    return notEmptyValidator(quantity)
        .must(mustBeNumber, tValidation(validation.mustBeNumber))
        .must(greaterThan(0), tValidation(validation.mustBePossitive));
}

function validateCorrectionItems(correctedItems, maxQuantity, maxRealQuantity, grossPrice) {
    const errors = { correctedItems: [] };
    const currentComplaintQuantity = correctedItems
        .map((x) => x.correctQuantity)
        .reduce((curr, next) => {
            const number = Number(next);
            return isNaN(number) ? curr : curr + Number(next);
        }, 0);
    const currentComplaintRealQuantity = correctedItems
        .map((x) => x.correctRealItems)
        .reduce((curr, next) => {
            const number = Number(next);
            return isNaN(number) ? curr : curr + Number(next);
        }, 0);
    if (!notEmptyArray(correctedItems)) return errors;

    for (let i = 0; i < correctedItems.length; i++) {
        const item = correctedItems[i];
        const { type } = item;
        let error = {};

        error["type"] = notEmptyValidator(type, tValidation("required")).validate();
        if (!type) {
            errors.correctedItems[i] = error;
            break;
        }

        const { reasonCode, correctGrossPrice, correctQuantity, correctRealItems } = item;
        error["reasonCode"] = notEmptyValidator(reasonCode).validate();

        if (type === "Value") {
            error["correctGrossPrice"] = priceValidator(correctGrossPrice, grossPrice).validate();
        } else {
            error["correctQuantity"] = quantityValidator(correctQuantity)
                .must(() => currentComplaintQuantity <= maxQuantity, tValidation(validation.mustBeLessOrEqaulsThanOriginalQuantity))
                .validate();
            error["correctRealItems"] = realQuantityValidator(correctRealItems)
                .must(() => currentComplaintRealQuantity <= maxRealQuantity, tValidation(validation.mustBeLessOrEqaulsThanOriginalQuantity))
                .validate();
            error["correctRealQuantity"] = realQuantityValidator(correctQuantity)
                .must(() => currentComplaintQuantity <= maxRealQuantity, tValidation(validation.mustBeLessOrEqaulsThanOriginalQuantity))
                .validate();
        }

        errors.correctedItems[i] = error;
    }

    return errors;
}

function validateLines(lines) {
    const errors = [];
    if (!notEmptyArray(lines)) return errors;
    for (let i = 0; i < lines.length; i++) {
        const line = lines[i];
        const { correctedItems, quantity, realQuantity, packSize, grossPrice } = line;
        if (!notEmptyArray(correctedItems)) continue;

        errors[i] = validateCorrectionItems(correctedItems, quantity, Math.round(realQuantity * packSize), grossPrice);
    }

    return errors;
}

function validateUpsellLines(upsellLines) {
    const errors = [];
    upsellLines.forEach((line, index) => {
        const { price, quantity, upsellType } = line;
        const error = {};
        error["price"] = upsellPriceValidator(price).validate();
        error["quantity"] = quantityValidator(quantity).validate();
        error["upsellType"] = notEmptyValidator(upsellType).validate();

        errors[index] = error;
    });

    return errors;
}

function validateDelivery(orderInfo) {
    const errors = {};
    const { deliveryMethod, paymentMethod, deliveryWindow, shippingAddress, billingAddress } = orderInfo;

    errors["deliveryMethod"] = notEmptyValidator(deliveryMethod).validate();
    errors["deliveryWindow"] = notEmptyValidator(deliveryWindow).validate();
    errors["paymentMethod"] = notEmptyValidator(paymentMethod).validate();
    errors["recipient"] = notEmptyValidator(paymentMethod).validate();
    errors["shippingAddress"] = shippingAddressValidator(shippingAddress);
    errors["billingAddress"] = validateBillingAddress(billingAddress);

    return errors;
}

function isdeliveryRequired(lines, upsellLines) {
    const correctedItems = _.chain(lines)
        .map((x) => x.correctedItems || [])
        .value();

    return (
        correctedItems.some((x) => {
            return x.some((y) => y.type === "missingProducts");
        }) || upsellLines.some((x) => x.upsellType === "withDelivery")
    );
}

function validate(values) {
    let errors = {};
    const { lines = [], upsellLines = [] } = values;
    const correctedItems = lines.flatMap((x) => (x.correctedItems ? x.correctedItems.flatMap((x) => x) : undefined)).filter((x) => x !== undefined);

    errors["lines"] = validateLines(lines);

    errors["upsellLines"] = validateUpsellLines(upsellLines);

    if (isdeliveryRequired(lines, upsellLines) && values.complaintOrderInfo) {
        errors["complaintOrderInfo"] = validateDelivery(values.complaintOrderInfo);
    }

    if ((!correctedItems || correctedItems.length < 1) && (!values.upsellLines || values.upsellLines.length < 1)) {
        errors["lines"] = { _error: "At least one member must be entered" };
        errors["upsellLines"] = {
            _error: "At least one member must be entered",
        };
    }

    return errors;
}

export default validate;
