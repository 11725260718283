import { RSAA } from "redux-api-middleware";
import { commerce, manager, operations } from "store/actionTypePrefixes";
import { combineIntoQueryParams } from "utils/objectExtensions";

const UPSELL_GET_SEARCH_PRODUCT = `${operations}/UPSELL_GET_SEARCH_PRODUCT`;
const UPSELL_GET_SEARCH_PRODUCT_SUCCESS = `${operations}/UPSELL_GET_SEARCH_PRODUCT_SUCCESS`;
const UPSELL_GET_SEARCH_PRODUCT_FAILURE = `${operations}/UPSELL_GET_SEARCH_PRODUCT_FAILURE`;
const UPSELL_GET_SEARCH_PRODUCT_OFFER = `${commerce}/UPSELL_GET_SEARCH_PRODUCT_OFFER`;
const UPSELL_GET_SEARCH_PRODUCT_OFFER_SUCCESS = `${commerce}/UPSELL_GET_SEARCH_PRODUCT_OFFER_SUCCESS`;
const UPSELL_GET_SEARCH_PRODUCT_OFFER_FAILURE = `${commerce}/UPSELL_GET_SEARCH_PRODUCT_OFFER_FAILURE`;
const CLEAR_SEARCH = `${manager}/CLEAR_SEARCH`;

export const types = {
    UPSELL_GET_SEARCH_PRODUCT,
    UPSELL_GET_SEARCH_PRODUCT_SUCCESS,
    UPSELL_GET_SEARCH_PRODUCT_FAILURE,
    CLEAR_SEARCH,
    UPSELL_GET_SEARCH_PRODUCT_OFFER,
    UPSELL_GET_SEARCH_PRODUCT_OFFER_SUCCESS,
    UPSELL_GET_SEARCH_PRODUCT_OFFER_FAILURE,
};

const search =
    ({ pageIndex, pageSize, term: search }) =>
    (dispatch) => {
        const params = combineIntoQueryParams({
            search,
            pageIndex,
            pageSize,
        });
        const warehouse = "WAW";

        if (/[0-9]{1,6}/.test(search)) {
            return dispatch({
                [RSAA]: {
                    endpoint: `api/v1/offer/products/${search}`,
                    headers: {
                        "Content-Type": "application/json",
                    },
                    method: "GET",
                    types: [UPSELL_GET_SEARCH_PRODUCT_OFFER, UPSELL_GET_SEARCH_PRODUCT_OFFER_SUCCESS, UPSELL_GET_SEARCH_PRODUCT_OFFER_FAILURE],
                },
            });
        }

        return dispatch({
            [RSAA]: {
                endpoint: `api/offer-proxy/search/${warehouse}/query?${params}`,
                headers: {
                    "Content-Type": "application/json",
                },
                method: "GET",
                types: [UPSELL_GET_SEARCH_PRODUCT, UPSELL_GET_SEARCH_PRODUCT_SUCCESS, UPSELL_GET_SEARCH_PRODUCT_FAILURE],
            },
        });
    };

const clearSearch = () => (dispatch) => {
    dispatch({ type: types.CLEAR_SEARCH });
};

export const actions = {
    search,
    clearSearch,
};
