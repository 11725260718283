import defaultListState from "store/defaultListState";
import types from "./types";

const initialState = {
    ...defaultListState,
    filterVendorId: null,
    isSubbmitRequested: false,
    vendorForm: {},
    vendorContractsConfirmForm: [],
    productIds: [],
    invoices: defaultListState,
    users: [],
    contracts: [],
    creditLimits: [],
    hasPendingCreditLimitOperation: false,
    hasPendingDraftContract: false,
    initialUsers: [],
    usersAssigning: false,
};

const defaultAction = (state) => ({ ...state });

export const vendorPms = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case types.getVendorContracts.request:
        case types.getVendorContracts.failure:
            return {
                ...state,
                contracts: [],
            };
        case types.getVendorContracts.success:
            return {
                ...state,
                contracts: action.payload,
            };

        case types.getVendorCreditLimits.request:
        case types.getVendorCreditLimits.failure:
            return {
                ...state,
                creditLimits: [],
            };
        case types.getVendorCreditLimits.success:
            return {
                ...state,
                creditLimits: action.payload,
            };

        case types.getVendorCreditLimitsHasPendingOperation.request:
        case types.getVendorCreditLimitsHasPendingOperation.failure:
            return {
                ...state,
                hasPendingCreditLimitOperation: true,
            };
        case types.getVendorCreditLimitsHasPendingOperation.success:
            return {
                ...state,
                hasPendingCreditLimitOperation: action.payload,
            };

        case types.getVendorContractHasPendingDraft.request:
        case types.getVendorContractHasPendingDraft.failure:
            return {
                ...state,
                hasPendingDraftContract: true,
            };
        case types.getVendorContractHasPendingDraft.success:
            return {
                ...state,
                hasPendingDraftContract: action.payload,
            };

        case types.assignVendorUsers.request:
            return {
                ...state,
                usersAssigning: true,
            };
        case types.assignVendorUsers.success:
            return {
                ...state,
                usersAssigning: false,
                users: action.payload,
                initialUsers: action.payload,
                vendorForm: { ...state.vendorForm, userIds: action.payload },
            };
        case types.assignVendorUsers.failure:
            return {
                ...state,
                usersAssigning: false,
            };

        case types.getVendor.request:
        case types.getVendorsList.request:
        case types.getVendorContractsChanged.request:
            return { ...state, isLoading: true };
        case types.updateVendor.request:
            return { ...state, isSubbmitRequested: true };
        case types.getVendorsList.success:
            return { ...state, ...payload, isLoading: false };
        case types.getVendorsExtList.success:
            return {
                ...state,
                items: payload.map((v) => ({ ...v, vendorId: v.vendorId.split("_")[0] })),
            };
        case types.getVendorInvoices.success:
            return {
                ...state,
                invoices: { ...payload, isLoading: false },
            };
        case types.getVendor.success:
            return {
                ...state,
                isLoading: false,
                vendorForm: { ...payload, userIds: payload.users.map((x) => x.userId) },
            };
        case types.getVendorContractsChanged.success:
            return {
                ...state,
                isLoading: false,
                vendorContractsConfirmForm: (payload || []).map((x) => ({
                    id: x.id,
                    contractStatus: x.contractStatus,
                    ...x.contract,
                })),
            };
        case types.getVendor.failure:
        case types.getVendorsList.failure:
        case types.getVendorContractsChanged.failure:
        case types.updateVendor.failure:
        case types.updateVendor.success:
            return {
                ...state,
                isSubbmitRequested: false,
            };
        default:
            return defaultAction(state);
    }
};
