import { MappedProductIds } from "components/Controls";
import { FormField } from "components/Form";
import { decimalNormalize, integerNormalize } from "components/FormHelpers/ControlFormaters";
import { FormSection } from "redux-form";
import { actions } from "store/cache/products/actions";
import { cachedProductsSelector } from "store/offers/selectors";
import { findProduct, formatProduct } from "utils/formating";
import { useCommonTranslation } from "utils/hooks";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { validators } from "./validators";

const NewProduct = ({ newProduct = {}, readOnly = false }) => {
    const { t, common } = useCommonTranslation();
    const cachedProducts = useSelector(cachedProductsSelector);
    const { productId } = newProduct;

    const dispatch = useDispatch();

    useEffect(() => {
        if (newProduct) {
            if (newProduct.productId) {
                dispatch(actions.getProducts([newProduct.productId]));
            }

            if (newProduct.mappedProductIds) {
                dispatch(actions.getProducts(newProduct.mappedProductIds));
            }
        }
    }, [dispatch, newProduct]);

    const product = formatProduct(productId, findProduct(cachedProducts, productId));

    return (
        <FormSection name="newProduct">
            <Grid
                container
                direction="row"
            >
                <FormField
                    style={{ width: 420 }}
                    name={"productId"}
                    label={t(common.productId)}
                    type={"textWithImage"}
                    item={product}
                    onAdd={(value) => dispatch(actions.getProducts([value]))}
                    validate={validators.productId}
                    readOnly={readOnly}
                />
                <FormField
                    name="quantity"
                    type={"text"}
                    format={integerNormalize}
                    label={t(common.quantity)}
                    validate={validators.quantity}
                    readOnly={readOnly}
                />
                <FormField
                    name="price"
                    type={"text"}
                    format={decimalNormalize}
                    label={t(common.price)}
                    validate={validators.price}
                    readOnly={readOnly}
                />
            </Grid>
            <MappedProductIds name="mappedProductIds" />
        </FormSection>
    );
};

export default NewProduct;
