import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import tableActionTypes from "components/Table/SimpleTable/TableActions/tableActionTypes";
import Actions, { createAction } from "components/Tabs/Actions";
import { B2BDepartmentRoles, ECommerceDepartmentRoles, SupportDepartmentRoles } from "consts/roles";
import { actions } from "store/customers/customer/customerVouchers/action";
import { firstOrEmpty } from "utils/extensions/arrayExtensions";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useCommonTranslation } from "utils/hooks";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useUser } from "context";
import { chain } from "lodash";
import { bindActionCreators } from "redux";
import { usePush } from "utils-ts/hooks";
import CustomerVoucherRow from "./CustomerVoucherRow";

const actionRequiresRoles = [...B2BDepartmentRoles, ...ECommerceDepartmentRoles, ...SupportDepartmentRoles];

const groupVouchers = (items) => {
    const { used, active, inactive } = chain(items)
        .orderBy((x) => x.validFrom, "desc")
        .reduce(
            (c, n) => {
                if (n.useCount === n.definition.useLimit) {
                    return { ...c, used: [...c.used, n] };
                } else if (moment(n.validTo).isBefore(moment())) {
                    return { ...c, inactive: [...c.inactive, n] };
                } else {
                    return { ...c, active: [...c.active, n] };
                }
            },
            { used: [], active: [], inactive: [] }
        )
        .value();

    return [...active, ...inactive, ...used];
};

const CustomerVouchers = ({ customerId: userId, list, getVouchers, customerClearVoucher }) => {
    const { isInAnyRoleOrAdmin } = useUser();
    const { items, pageIndex, pageSize, pageCount, totalCount, isLoading } = list;
    React.useEffect(() => {
        getVouchers({ userId: userId, pageIndex, pageSize });
    }, [getVouchers, pageIndex, pageSize, userId]);
    const { t, common } = useCommonTranslation();
    const { push } = usePush();
    const editVocher = ({ id }) => {
        push(`/customers/${userId}/voucher`, undefined, { voucherId: id });
    };
    const createVoucher = async () => {
        await customerClearVoucher();
        push(`/customers/${userId}/voucher`, undefined, { voucherId: null });
    };
    const columns = [
        { key: "id", value: common.id, type: formatTypes.text },
        { key: "kind", value: common.kind },
        { key: "usage", value: common.usage },
        { key: "value", value: common.value },
        {
            key: "validFrom",
            value: common.validFrom,
            type: formatTypes.time,
            options: { timeFormat: timePrecisonFormats.seconds },
        },
        {
            key: "validTo",
            value: common.validTo,
            type: formatTypes.time,
            options: { timeFormat: timePrecisonFormats.seconds },
        },
        {
            key: "campaignName",
            value: common.campaignName,
            type: formatTypes.text,
        },
        {
            key: "requirements",
            value: common.requirements,
            type: formatTypes.text,
        },
        { key: "createdBy", value: common.createdBy, type: formatTypes.text },
        {
            key: "edit",
            value: "",
            type: formatTypes.action,
            action: editVocher,
            actionType: tableActionTypes.edit,
        },
    ].map((col) => ({ ...col, value: t(col.value) }));

    return (
        <Fragment>
            <Actions actions={[createAction(t(common.createVoucher), createVoucher, "primary", false, actionRequiresRoles)]} />
            <SimpleTable
                isLoading={isLoading}
                columns={columns}
                hover
                pagination
                paginationProps={{
                    pageIndex,
                    totalCount,
                    pageSize,
                    pageCount,
                    onPageChange: (pageIndex) => getVouchers({ userId, pageIndex, pageSize }),
                    onRowsPerPageChange: (pageSize) => getVouchers({ userId, pageIndex, pageSize }),
                }}
            >
                {groupVouchers(items).map((x) => (
                    <CustomerVoucherRow
                        onDobuleClick={(x) => {
                            if (isInAnyRoleOrAdmin(actionRequiresRoles)) {
                                editVocher({ id: x });
                            }
                        }}
                        key={x.id}
                        columns={columns}
                        item={x}
                    />
                ))}
            </SimpleTable>
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    const {
        customer: {
            customerVouchers: { list },
        },
        cache: { userInfos },
    } = state;
    const { items, ...rest } = list;
    const mapped = items.map((b) => {
        const { email } = firstOrEmpty(userInfos, (x) => x.userId === b.createdBy);
        return { ...b, createdBy: email || b.createdBy };
    });
    return {
        list: {
            ...rest,
            items: mapped,
        },
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CustomerVouchers);
