import { IconButton } from "components/Button";
import Button from "components/Button/Button";
import { ArrayValidationError, PromotionProductsFields } from "components/Controls";
import { FormField } from "components/Form";
import { decimalNormalize, integerNormalize } from "components/FormHelpers/ControlFormaters";
import { renderFieldOrText } from "components/FormHelpers/Controls";
import Layout from "components/Grid/Layout";
import { FormSection, change } from "redux-form";
import { usePromotionFormAutocompleteEffect } from "store/autocomplete";
import { actions } from "store/cache/products/actions";
import { makeStyles } from "tss-react/mui";
import { toSelectList } from "utils/extensions/arrayExtensions";
import { useCommonTranslation } from "utils/hooks";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Divider, Grid } from "@mui/material";
import { validators } from "../../validators";

const useStyles = makeStyles()((theme) => ({
    product: {
        width: 350,
        padding: theme.spacing(0.5),
    },
    input: {
        width: 200,
    },
    checkbox: {
        width: 100,
        padding: theme.spacing(0.5),
    },
    divider: {
        margin: 0,
        height: 2,
    },
    dividerItem: {
        margin: "20px 0px 10px 0px",
    },
    productPredicate: {
        padding: theme.spacing(1),
    },
}));

const ProductPredicates = ({ fields, productPredicates, meta }) => {
    usePromotionFormAutocompleteEffect();
    const { t, common } = useCommonTranslation();
    const dispatch = useDispatch();
    const { classes } = useStyles();
    const predicates = [...(productPredicates || [])];

    useEffect(() => {
        if (predicates && predicates.length > 0) {
            const products = [];
            predicates
                .map((p) => p.excludeProducts)
                .filter(Boolean)
                .forEach((val) => {
                    if (val.products) {
                        val.products.forEach((excluded) => {
                            if (!products.some((x) => x === excluded)) {
                                products.push(excluded);
                            }
                        });
                    }
                });

            predicates
                .map((p) => p.includeProducts)
                .filter(Boolean)
                .forEach((val) => {
                    if (val.products) {
                        val.products.forEach((included) => {
                            if (!products.some((x) => x === included)) {
                                products.push(included);
                            }
                        });
                    }
                });

            dispatch(actions.getProducts(products));
        }
    }, [dispatch, predicates]);

    return (
        <Layout
            isEnlarged
            headerText={t(common.productPredicates)}
        >
            <ArrayValidationError {...meta} />
            <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
                direction="column"
            >
                {fields.map((field, index) => {
                    const { type } = productPredicates[index] || {};
                    const isLastItem = index + 1 === fields.length;

                    return (
                        <FormSection
                            key={index}
                            name={field}
                        >
                            <Grid
                                container
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                <Grid
                                    item
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >
                                    <Grid
                                        item
                                        xs={2}
                                    >
                                        <FormField
                                            className={classes.input}
                                            component={renderFieldOrText}
                                            name={"type"}
                                            label={t(common.limit)}
                                            type={"select"}
                                            items={toSelectList(["discountPercent", "discountValue", "newPrice"], t)}
                                            onChange={(_, type) => {
                                                dispatch(change("discount-form", `${field}.modifier`, { [type]: 0 }));
                                            }}
                                            validate={validators.modifierType}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                    >
                                        <FormSection name="modifier">
                                            <FormField
                                                className={classes.input}
                                                hidden={type !== "discountPercent"}
                                                name={"discountPercent"}
                                                component={renderFieldOrText}
                                                type={"text"}
                                                label={t(common.discountPercent)}
                                                format={integerNormalize}
                                                validate={validators.discountPercent}
                                            />

                                            <FormField
                                                className={classes.input}
                                                hidden={type !== "discountValue"}
                                                name={"discountValue"}
                                                component={renderFieldOrText}
                                                type={"text"}
                                                label={t(common.discountValue)}
                                                format={decimalNormalize}
                                                validate={validators.discountValue}
                                            />

                                            <FormField
                                                className={classes.input}
                                                hidden={type !== "newPrice"}
                                                name={"newPrice"}
                                                component={renderFieldOrText}
                                                type={"text"}
                                                label={t(common.newPrice)}
                                                format={decimalNormalize}
                                                validate={validators.newPrice}
                                            />
                                        </FormSection>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={2}
                                    >
                                        <FormField
                                            className={classes.checkbox}
                                            name={"isProductLimitsDisabled"}
                                            component={renderFieldOrText}
                                            type={"boolean"}
                                            label={t(common.isProductLimitsDisabled)}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                    >
                                        <FormField
                                            className={classes.checkbox}
                                            name={"isWaterLimitsDisabled"}
                                            component={renderFieldOrText}
                                            type={"boolean"}
                                            label={t(common.isWaterLimitsDisabled)}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={1}
                                        style={{ marginLeft: "auto" }}
                                    >
                                        <IconButton
                                            icon="delete"
                                            onClick={() => fields.remove(index)}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid
                                    item
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >
                                    <Grid
                                        item
                                        xs={6}
                                        className={classes.productPredicate}
                                    >
                                        <PromotionProductsFields
                                            name="includeProducts"
                                            title={t(common.includeProducts)}
                                            isRequired={true}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        className={classes.productPredicate}
                                    >
                                        <PromotionProductsFields
                                            name="excludeProducts"
                                            title={t(common.excludeProducts)}
                                            isRequired={false}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid
                                    item
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        className={classes.dividerItem}
                                    >
                                        <Divider
                                            variant="middle"
                                            className={classes.divider}
                                            hidden={isLastItem === true}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </FormSection>
                    );
                })}
                <Grid
                    item
                    style={{ marginLeft: "auto" }}
                >
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => fields.push({ type: "discountPercent", limit: "" })}
                    >
                        {t(common.add)}
                    </Button>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default ProductPredicates;
