import { useGetCacheQuery } from "api/hooks";

const url = "api/v1/autocomplete/";

const useFindCampaigns = () => {
    return useGetCacheQuery<string[]>({
        app: "commerce",
        url: url + "shared/campaigns",
    });
};

const useFindContainerCategories = () => {
    return useGetCacheQuery<string[]>({
        app: "commerce",
        url: url + "content/containers/categories",
    });
};

export default {
    useFindCampaigns,
    useFindContainerCategories,
};
