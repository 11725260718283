import types from "./types";

const initialState = {
    isGenerating: false,
};

export const linkingReport = (state = initialState, { type }) => {
    switch (type) {
        case types.generateLinkingReport.request:
            return { ...state, isGenerating: true };
        case types.generateLinkingReport.success:
            return { ...state, isGenerating: false };
        case types.generateLinkingReport.failure:
            return { ...state, isGenerating: false };
        default:
            return state;
    }
};
