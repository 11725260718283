const Draft = "Draft";
const WaitingForVerification = "WaitingForVerification";
const Accepted = "Accepted";
const Rejected = "Rejected";
const Corrected = "Corrected";
const Annexed = "Annexed";
const Closed = "Closed";
const DraftRejected = "DraftRejected";
const ChangeDraft = "ChangeDraft";
const ChangeDraftRejected = "ChangeDraftRejected";
const Mailed = "Mailed";

let contractStatuses = [
    Draft,
    WaitingForVerification,
    Accepted,
    Rejected,
    Corrected,
    Annexed,
    Closed,
    DraftRejected,
    ChangeDraft,
    ChangeDraftRejected,
    Mailed,
];

let contractStatusesToShowDiff = [Accepted, Annexed, Closed, Corrected, Mailed];
let contractStatusesDrafts = [Draft, DraftRejected, ChangeDraft, ChangeDraftRejected];
let contractStatusesRequiredDecision = [WaitingForVerification];
let contractStatusesReadOnly = [Corrected, Closed, Annexed, Mailed];
let contractStatusesRejected = [Rejected, DraftRejected, ChangeDraftRejected];

contractStatuses.isStatusToShowDiff = (status) => {
    return contractStatusesToShowDiff.includes(status);
};

contractStatuses.isDraft = (status) => {
    return contractStatusesDrafts.includes(status);
};

contractStatuses.isDecisionRequired = (status) => {
    return contractStatusesRequiredDecision.includes(status);
};

contractStatuses.isRejected = (status) => {
    return contractStatusesRejected.includes(status);
};

contractStatuses.isReadOnly = (status) => {
    return contractStatusesReadOnly.includes(status);
};

export default contractStatuses;
