const voucher = {
    add: "add",
    addEffect: "addEffect",
    addProgressive: "addProgressive",
    brands: "brands",
    campaignName: "campaignName",
    cartActivationLimit: "cartActivationLimit",
    cartEffectType: "cartEffectType",
    categories: "categories",
    categoryIds: "categoryIds",
    customerId: "customerId",
    dateFrom: "dateFrom",
    dateTo: "dateTo",
    deliveryMethods: "deliveryMethods",
    discount: "discount",
    effectType: "effectType",
    excludedSegments: "excludedSegments",
    haveSegmentsConstraint: "haveSegmentsConstraint",
    hourFrom: "hourFrom",
    hourTo: "hourTo",
    id: "id",
    includeAlcohol: "includeAlcohol",
    includeTobacco: "includeTobacco",
    includedSegments: "includedSegments",
    invoicedOrderCountFrom: "invoicedOrderCountFrom",
    invoicedOrderCountTo: "invoicedOrderCountTo",
    isCumulative: "isCumulative",
    isHidden: "isHidden",
    lastDeliveryDaysAgoFrom: "lastDeliveryDaysAgoFrom",
    lastDeliveryDaysAgoTo: "lastDeliveryDaysAgoTo",
    maximumValue: "maximumValue",
    minimumValue: "minimumValue",
    name: "name",
    onlyIdentityVerifiedFirstOrder: "onlyIdentityVerifiedFirstOrder",
    orderRequirements: "orderRequirements",
    payment: "payment",
    placedOrderCountFrom: "placedOrderCountFrom",
    placedOrderCountTo: "placedOrderCountTo",
    poolEffectMode: "poolEffectMode",
    price: "price",
    priceEffectType: "priceEffectType",
    priceModifierType: "priceModifierType",
    producers: "producers",
    productId: "productId",
    productIds: "productIds",
    products: "products",
    promotionActivationLimit: "promotionActivationLimit",
    promotionType: "promotionType",
    quantity: "quantity",
    remove: "remove",
    removeEffect: "removeEffect",
    removeProgressive: "removeProgressive",
    requiredQuantity: "requiredQuantity",
    requiredValue: "requiredValue",
    requiresConsent: "requiresConsent",
    useLimit: "useLimit",
    userActivationLimit: "userActivationLimit",
    userActivationLimitExpirationPeriod: "userActivationLimitExpirationPeriod",
    validFrom: "validFrom",
    validTo: "validTo",
    voucherPromotions: "voucherPromotions",
    voucherType: "voucherType",
    Monday: "Monday",
    Tuesday: "Tuesday",
    Wednesday: "Wednesday",
    Thursday: "Thursday",
    Friday: "Friday",
    Saturday: "Saturday",
    Sunday: "Sunday",
    progressive: "progressive",
    deliveryDiscount: "deliveryDiscount",
    newDeliveryCost: "newDeliveryCost",
    Membership: "Membership",
    Cart: "Cart",
    Pool: "Pool",
    AdditonalProduct: "AdditonalProduct",
    SmapleProduct: "SmapleProduct",
    Delivery: "Delivery",
    Price: "Price",
    bonusPoint: "bonusPoint",
    monetaryPoints: "monetaryPoints",
    recencyPoints: "recencyPoints",
    newValue: "newValue",
    priceModification: "priceModification",
    discountPercent: "discountPercent",
    discountValue: "discountValue",
    newPrice: "newPrice",
    deliveryDates: "deliveryDates",
    currentDates: "currentDates",
    isNotyfing: "isNotyfing",
    isReminding: "isReminding",
    voucherValue: "voucherValue",
    voucherPercent: "voucherPercent",
    addDeliveryConditions: "addDeliveryConditions",
    removeDeliveryConditions: "removeDeliveryConditions",
    voucherDeliveryItems: "voucherDeliveryItems",
    addPool: "addPool",
    includedProducts: "includedProducts",
    excludedProducts: "excludedProducts",
    pool: "pool",
    removePool: "removePool",
    deliveryConditions: "deliveryConditions",
    segments: "segments",
    requirments: "requirments",
    createVoucher: "createVoucher",
    save: "save",
    postcodes: "postcodes",
    isEarlierMorningDelivery: "isEarlierMorningDelivery",
    isLateMorningDelivery: "isLateMorningDelivery",
    isAfternoonDelivery: "isAfternoonDelivery",
    isSameDayDelivery: "isSameDayDelivery",
    isNonTradeSunday: "isNonTradeSunday",
    closingDates: "closingDates",
    isMondayAfterNonTradeSunday: "isMondayAfterNonTradeSunday",
    isMorningAfterHoliday: "isMorningAfterHoliday",
} as const;

export default voucher;
