import { manager, operations } from "store/actionTypePrefixes";
import { AsyncTypes, asAsyncFormTypes, createAction } from "utils/extensions";

const types = {
    formInit: createAction("FORM_INIT_CAMPAIGNS", manager),
    getJobPosition: new AsyncTypes("GET_CONTACT_TYPE", operations),
    createOrUpdate: new AsyncTypes("CREATE_OR_UPDATE_CONTACT_TYPE", operations, [asAsyncFormTypes]),
};

export default types;
