import { GetRequest, PostRequest, PutRequest, get, post, put } from "utils/extensions";
import downloadFile from "utils/rsaa/downloadFileAction";
import types from "./types";

const endpoint = (operationId) => `api/vendor/operations${operationId ? `/${operationId}` : ""}`;

export const findVendorOperations = (pageIndex, pageSize) => async (dispatch) => {
    return dispatch(
        get(
            new GetRequest(endpoint(), types.findVendorOperations.all, {
                queryParams: { pageIndex, pageSize },
            })
        )
    );
};

export const createVendorOperation = (vendorId, values) => async (dispatch) => {
    return dispatch(
        post(
            new PostRequest(endpoint(), types.createVendorOperation.all, {
                body: {
                    vendorId,
                    ...values,
                    emails: values.emails.filter((x) => x.email),
                },
            })
        )
    );
};

export const clearForm = () => (dispatch) => dispatch({ type: types.clear });

export const getVendorOperation = (vendorOperationId) => async (dispatch) => {
    return await dispatch(get(new GetRequest(endpoint(vendorOperationId), types.getVendorOperation.all)));
};

export const makeVendorOperationDecision = (vendorOperationId, values) => async (dispatch) => {
    return await dispatch(
        put(
            new PutRequest(endpoint(vendorOperationId) + "/make-decision", types.makeVendorOperationDecision.all, {
                body: {
                    ...values,
                },
            })
        )
    );
};

export const getOperationFile = (operationId, fileId, fileName) => async (dispatch) => {
    await dispatch(downloadFile(endpoint(operationId) + "/download-file/" + fileId, undefined, types.downloadFile, fileName));

    return Promise.resolve();
};
